import React from 'react';
import Axios from 'axios';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';

import classnames from 'classnames';
import { ListGroup, ListGroupItem } from 'reactstrap';

import ImportApi from '../../pages/A080/AIKADT005000001/ImportApi';
import { AuditStatusEnum, BatStatusEnum } from '../../../constants/enums';
import { isDspMonth, isMultMidtermClosingInitDisplay, isNormalMonth } from '../../../utils/monthlyUtils';
import Star from '../../../components/atoms/Icon/Star';
import StarAlt from '../../../components/atoms/Icon/StarAlt';
import { MonthSelectInformationViewModel } from '../../../models/monthSelectInformationViewModel';
import { MonthSelectInformationVO } from '../../../models/monthSelectInformationVO';
import { setAlertMessage, AlertKbnEnum } from '../../../stores/AlertMessage';
import { setIsLoading } from '../../../stores/NowLoading';

export enum MODE {
  /** 通常 */
  normal = 0,
  /** 結果表示(ドラッグ可能な画面) */
  resultDisplay = 1,
  /** AI表示 */
  AIDisplay = 2
}

export enum DRAGMODE {
  /** ドラッグでの月範囲選択が無効 */
  off = 0,
  /** ドラッグでの月範囲選択が有効 */
  on = 1,
}

type MonthSelectProps = React.HTMLAttributes<HTMLDivElement> & {
  displayMode?: MODE;
  fiscalYear?: number;
  dataRelationCheck?: boolean;
  refreshCnt?: number;
  onMonthChange?: (nMonth: number[]) => void;

};
type monthSelectData = MonthSelectInformationVO & {
  multiNMonth?: MonthSelectInformationVO[];
};
// 選択情報リスト
type Selected = {
  NMonth: number;
  StNMonth: number;
  EdNMonth: number;
  SortKey: number; // 選択月情報のキーとして使用
  selected: boolean;
};
type reducerType = {
  viewModel: MonthSelectInformationViewModel;
  selecteds: Selected[];
  displayNMonth: monthSelectData[];
};

// 初期表示時のURL
export const initialUrl = '/api/v1/MonthSelect/Initial';

// データ連携確認のURL
export const resultStatusUrl = '/api/v1/MainHome/ResultStatus';

// アイコンのClass
export const iconClass = 'icon-sm -icon';

const reducer = (state: any, action: reducerType) => ({
  viewModel: action.viewModel,
  selecteds: action.selecteds,
  displayNMonth: action.displayNMonth
});

const MonthSelect: React.FC<MonthSelectProps> = props => {

  const { displayMode, refreshCnt, dataRelationCheck, tabIndex } = props;

  const [state, setStates] = React.useReducer(reducer, {
    viewModel: new MonthSelectInformationViewModel(),
    selecteds: [],
    displayNMonth: []
  });
  const { viewModel, selecteds, displayNMonth } = state;
  // 画面でのドラッグ状態管理
  const [drag, setDrag] = React.useState(0);
  // ドラッグ開始した時の基準となる月を管理
  const [dragBaseMonth, setdragBaseMonth] = React.useState(0);
  /** 月度を押下した時の処理 */
  const handleOnClick = (stNMonth: number, edNMonth: number, key: number) => {
    if (drag == DRAGMODE.on) {
      // ドラッグ中は無効
      return;
    }
    if (displayMode == MODE.resultDisplay) {
      // 監査実行・結果表示の場合はドラッグ処理に一任させる。
      return;
    }
    if (dataRelationCheck === true) {
      resultStatusOnGet(resultStatusUrl, stNMonth, edNMonth, key);
    } else {
      selectionMonth(stNMonth, edNMonth, key);
    }
  };

  /** 月度をドラッグ開始時の処理 */
  const handleOnDragStart = (startNMonth: number, endNMonth: number, key: number, mouseEvent: Event) => {
    // モードが監査結果モードの場合のみドラッグを許容する
    if (displayMode != MODE.resultDisplay) {
      return;
    }
    // イベントの型をマウスイベント向けにキャスト
    const castEvent = mouseEvent as MouseEvent;
    // 右クリックの場合無効化
    if (castEvent.which == 3) {
      return;
    }
    // 月ドラッグ選択を有効化
    setDrag(DRAGMODE.on);
    // ドラッグ基準になる月を保持
    setdragBaseMonth(key);
    // ドラッグ選択前の月選択情報バックアップを取る
    let selectedMonths: number[] = [];
    selecteds.filter(data => {
      if (data.selected == true) {
        selectedMonths.push(data.NMonth);
        return true;
      } else {
         return false;
      }
    });
    // 一旦選択状態解除
    selecteds.forEach(item => (item.selected = false));
    // 初期選択状態をセットしておく
    const index = selecteds.findIndex(item => item.StNMonth == startNMonth && item.EdNMonth == endNMonth && item.SortKey == key);
    selecteds[index].selected = true;
    setStates({ ...state });
    
  };

  /** 月度をドラッグ終了時の処理 */
  const handleOnDragEnd = () => {
    if (drag != DRAGMODE.on) {
      // 右クリックの場合は処理させない
      return;
    }
    // 範囲選択状態を解除
    setDrag(0);
    // 複数選択された情報を取得する
    const selectedMonth = selecteds.filter(data => {
      if (data.selected == true) {
        return true;
      } else {
        return false;
      }
    });
    let selectedNMonthList: number[] = [];
    for (const index of selectedMonth) {
      if (index.StNMonth !== index.EdNMonth) {
        // 集計月は開始月と終了月を同時にセット
        selectedNMonthList.push(index.StNMonth);
        selectedNMonthList.push(index.EdNMonth);
      } else {
        // 通常月と決算月は内部月をそのままセット
        selectedNMonthList.push(index.NMonth);
      }
    }

    // 複数月OR監査実行・結果表示に単月選択（その他の場合はクリックイベントで処理される）
    if (selectedNMonthList.length > 1 ||  displayMode == MODE.resultDisplay && selectedNMonthList.length == 1) {
      // 複数選択
      // 月変更イベントを実行
      props.onMonthChange!(selectedNMonthList);
    }
  };


  /** 月度をドラッグされた、ドラッグ外れたの処理 */
  const handleOnDragOver = (stNMonth: number, edNMonth: number, key: number, disabled: boolean, mouseOutFlag: boolean = false) => {
    if (key == dragBaseMonth) {
      // ドラッグ対象が基準月と同じ場合はなにもしない
      return;
    }
    if (drag == DRAGMODE.on) {
      // ドラッグでの複数月選択モードがオンの場合のみ選択状態を更新
      if (disabled) {
        // 非活性コンポーネントをドラッグした、ドラッグ外れた場合
        // 未連携かどうかチェック
        const monthDataIndex = displayNMonth.findIndex(data => data.StNMonth == stNMonth && data.EdNMonth == edNMonth && data.SortKey == key);
        if (displayNMonth[monthDataIndex].ConfStatus == AuditStatusEnum.EmptyData) {
          // 未連携の場合連携済みで最大月をセットする
          // 連携済みの月分取得
          const cooperationedMonthList = displayNMonth.filter(data => {
            if (data.ConfStatus != AuditStatusEnum.EmptyData) {
              return true;
            }
            return false;
          });
          let max = cooperationedMonthList[0];
          for (const oneRecord of cooperationedMonthList) {
            if (oneRecord.NMonth != undefined && max.NMonth != undefined) {
              if (oneRecord.NMonth > max.NMonth) {
                max = oneRecord;
              }
            }
          }
          if (max && max.NMonth != undefined) {
            // 連携済み最大月で変更処理を実施
            // 最大月でマウスオーバーされた扱いにする
            selectionMonthForDrag(max.NMonth, max.NMonth, max.SortKey!, false);
          }
        }
      } else {
        // 通常ドラッグでの複数月選択モードがオンの場合のみ選択状態を更新
        selectionMonthForDrag(stNMonth, edNMonth, key, mouseOutFlag);
      }
    }
  };

  /** コンテキストメニューのコンテンツを押下した時の処理 */
  const handleContextMenuClick = (stNMonth: number, edNMonth: number, key: number) => {
    if (dataRelationCheck === true) {
      resultStatusOnGet(resultStatusUrl, stNMonth, edNMonth, key);
    } else {
      selectionMonth(stNMonth, edNMonth, key);
    }
  };

  // 選択されている月を変更します（ドラッグでの月選択版）
  const selectionMonthForDrag = (stNMonth: number, edNMonth: number, key: number, mouseOutFlag: boolean) => {
    // ドラッグ開始した月のインデックス取得
    const baseIndex = selecteds.findIndex(item => item.SortKey == dragBaseMonth);
    // 選択インデックス取得
    const index = selecteds.findIndex(item => item.StNMonth == stNMonth && item.EdNMonth == edNMonth && item.SortKey == key);
    // 選択された月を全解除
    selecteds.forEach(item => (item.selected = false));
    // 選択情報(selectedsの内容)を更新
    if (index < baseIndex) {
      // 基準となる選択月より左をドラッグした場合
      if (mouseOutFlag) {
        // マウスが離れた場合
        for (var i = baseIndex; i > index; i-- ) {
          selecteds[i].selected = true;
        }
      } else {
        // マウスが当った場合
        for (var i = baseIndex; i >= index; i--) {
          selecteds[i].selected = true;
        }
      }
    } else {
      // 基準となる選択月より右をドラッグした場合
      if (mouseOutFlag) {
        // マウスが離れた場合
        for (var i = baseIndex; i < index; i++) {
          selecteds[i].selected = true;
        }
      } else {
        // マウスが当った場合
        for (var i = baseIndex; i <= index; i++) {
          selecteds[i].selected = true;
        }
      }
    }
    const findActiveNMonthList = state.displayNMonth.filter(data => {
      if (data.StNMonth === stNMonth && data.EdNMonth === edNMonth && data.SortKey === key) {
        return true;
      } else {
        if (typeof data.multiNMonth !== 'undefined') {
          for (const item of data.multiNMonth) {
            if (item.StNMonth === stNMonth && item.EdNMonth === edNMonth && item.SortKey === key) {
              return true;
            }
          }
        }
      }
      return false;
    });
    if (findActiveNMonthList.length > 0) {
      const findActiveNMonth = findActiveNMonthList[0];
      // コンテキストメニューより選択された場合に表示文言を変更する
      if ((findActiveNMonth.StNMonth !== stNMonth
        || findActiveNMonth.EdNMonth !== edNMonth
        || findActiveNMonth.SortKey !== key)
        && typeof findActiveNMonth.multiNMonth !== 'undefined') {
        for (const multiNMonthData of findActiveNMonth.multiNMonth) {
          if (multiNMonthData.StNMonth === stNMonth
            && multiNMonthData.EdNMonth === edNMonth
            && multiNMonthData.SortKey === key) {
            for (const key in multiNMonthData) {
              findActiveNMonth[key] = multiNMonthData[key];
            }
          }
        }
      }
    }
    setStates({ ...state });
  }

  /**
   * 選択されている月を変更します。
   * @param stNMonth 内部月(開始月)
   * @param edNMonth 内部月(終了月)
   */
  const selectionMonth = (stNMonth: number, edNMonth: number, key: number) => {
    // 選択されている月を変更
    const index = selecteds.findIndex(item => item.StNMonth == stNMonth && item.EdNMonth == edNMonth && item.SortKey == key);
    selecteds.forEach(item => (item.selected = false));
    selecteds[index].selected = true;
    const findActiveNMonthList = state.displayNMonth.filter(data => {
      if (data.StNMonth === stNMonth && data.EdNMonth === edNMonth && data.SortKey == key) {
        return true;
      } else {
        if (typeof data.multiNMonth !== 'undefined') {
          for (const item of data.multiNMonth) {
            if (item.StNMonth === stNMonth && item.EdNMonth === edNMonth && item.SortKey == key) {
              return true;
            }
          }
        }
      }
      return false;
    });

    if (findActiveNMonthList.length > 0) {
      const findActiveNMonth = findActiveNMonthList[0];

      // コンテキストメニューより選択された場合に表示文言を変更する
      if ((findActiveNMonth.StNMonth !== stNMonth
        || findActiveNMonth.EdNMonth !== edNMonth
        || findActiveNMonth.SortKey !== key)
        && typeof findActiveNMonth.multiNMonth !== 'undefined') {
        for (const multiNMonthData of findActiveNMonth.multiNMonth) {
          if (multiNMonthData.StNMonth === stNMonth
            && multiNMonthData.EdNMonth === edNMonth
            && multiNMonthData.SortKey === key) {
            for (const key in multiNMonthData) {
              findActiveNMonth[key] = multiNMonthData[key];
            }
          }
        }
      }
    }
    setStates({ ...state })
    if (stNMonth !== edNMonth) {
      // 決算集計月押下された場合は集計期間を設定する
      props.onMonthChange!([stNMonth, edNMonth]);
    } else {
      // それ以外は選択された内部月を設定
      props.onMonthChange!([stNMonth]); // 決算集計月以外の場合、内部月=内部月(開始月)
    }
  };

  // 結果表示のアイコンを定義
  const CompleteIcon = () => <StarAlt className={classnames(iconClass, '-origin')} />;
  const PendingIcon = () => (
    <>
      <StarAlt className={classnames(iconClass, '-white')} />
      <Star className={classnames(iconClass, '-origin')} />
    </>
  );

  /** 月度選択のコンポーネント */
  const FiscalMonthButton: React.FC<monthSelectData> = prop => {
    const index = selecteds.findIndex(item => item.StNMonth === prop.StNMonth && item.EdNMonth === prop.EdNMonth && item.SortKey === prop.SortKey);
    let star = null;
    if (displayMode === MODE.resultDisplay) {
      switch (prop.ConfStatus) {
        case AuditStatusEnum.Completed:
          star = <CompleteIcon />;
          break;
        case AuditStatusEnum.Uncomplete:
          star = <PendingIcon />;
          break;
      }
    }

    const disabled =
        (displayMode === MODE.resultDisplay || displayMode === MODE.AIDisplay) &&
        prop.ConfStatus == AuditStatusEnum.EmptyData && drag == DRAGMODE.off;
    const disabledDesignAndFunc =
      (displayMode === MODE.resultDisplay || displayMode === MODE.AIDisplay) &&
          prop.ConfStatus == AuditStatusEnum.EmptyData;
    const btnTabIndex = disabledDesignAndFunc ? -1 : tabIndex;
    let listItemClass = '-monthly p-0 rounded-0';

    /** コンテキストメニューの設定 */
    const contextMenuID = ['context-menu', prop.StNMonth, prop.EdNMonth, prop.SortKey].join('-');
    const contextMenuItem: React.ReactElement[] = [];
    let didableCounter = disabledDesignAndFunc ? 1 : 0;
    if (prop.multiNMonth != undefined) {
      listItemClass = [listItemClass, '-multi'].join(' ');
      prop.multiNMonth.forEach(monthSelect => {
        // 決算月リストのスタイル設定
        if (monthSelect.SortKey !== prop.SortKey) {
          const contextMenuDisabled =
            (displayMode === MODE.resultDisplay || displayMode === MODE.AIDisplay) &&
            monthSelect.ConfStatus == AuditStatusEnum.EmptyData;
          const contextMenuItemClassnames = classnames(
            'A3-context-menu-item text-left px-3 py-1',
            contextMenuDisabled ? 'disabled' : 'cursor-pointer'
          );
          const handleOnContextMenuClick = () => {
            if (!contextMenuDisabled) {
              handleContextMenuClick(monthSelect.StNMonth!, monthSelect.EdNMonth!, monthSelect.SortKey!);
            }
          };
          contextMenuItem.push(
            <div
              key={['context-menu-item', monthSelect.StNMonth, monthSelect.EdNMonth, monthSelect.SortKey].join('-')}
              className={contextMenuItemClassnames}
              onClick={handleOnContextMenuClick}
            >
              {monthSelect.MonthName}
            </div>
          );
          // コンテキストメニューの「disabled」が有効な値をカウント
          if (contextMenuDisabled) didableCounter++;
        }
      });
    }
    if (contextMenuItem.length > 0 && didableCounter == 3) {
      // コンテキストメニューが存在し、内容がすべて非活性の場合、非活性クラス適用
      listItemClass += ' disable-button '
    } else if (disabledDesignAndFunc) {
      // 非活性の場合、非活性クラス適用
      listItemClass += ' disable-button ';
    }
    const contextMenu =
      contextMenuItem.length > 0 ? (
        <ContextMenu id={contextMenuID} className='A3-context-menu p-1 border rounded-lg'>
          <MenuItem>{contextMenuItem}</MenuItem>
        </ContextMenu>
      ) : null;

    return (
      <ContextMenuTrigger id={contextMenuID} key={prop.SortKey}>
        <ListGroupItem
          tag='button'
          action
          className={listItemClass}
          active={selecteds[index].selected}
          onClick={() => handleOnClick(prop.StNMonth!, prop.EdNMonth!, prop.SortKey!)}
          onMouseDown={() => handleOnDragStart(prop.StNMonth!, prop.EdNMonth!, prop.SortKey!, window.event!)}
          onMouseOver={() => handleOnDragOver(prop.StNMonth!, prop.EdNMonth!, prop.SortKey!, disabledDesignAndFunc)}
          onMouseOut={() => handleOnDragOver(prop.StNMonth!, prop.EdNMonth!, prop.SortKey!, disabledDesignAndFunc, true)}
          disabled={disabled}
          tabIndex={btnTabIndex}
        >
          <span className='fa-layers'>
            {star}
            <span className='fa-layers-text d-inline-block monthly-label text-nowrap small'>{prop.MonthName}</span>
          </span>
        </ListGroupItem>
        {contextMenu}
      </ContextMenuTrigger>
    );
  };

  // ビューモデルの設定します。
  const setViewModel = (newViewModel: MonthSelectInformationViewModel, isInitialSelection: boolean) => {
    if (newViewModel.MonthSelects != undefined) {
      //let selectNMonth = -1;
      // 内部月の代わりに、ソートキーを選択する際のキーとして使用
      let selectKey = -1;
      let selectNMonthArray = [];
      // 選択状態の生成
      const newSelecteds: Selected[] = newViewModel.MonthSelects.map(monthSelect => ({
        NMonth: monthSelect.NMonth!,
        StNMonth: monthSelect.StNMonth!,
        EdNMonth: monthSelect.EdNMonth!,
        SortKey: monthSelect.SortKey!,
        selected: false
      }));

      if (newSelecteds.length > 0) {
        if (isInitialSelection === true && (displayMode === MODE.resultDisplay || displayMode === MODE.AIDisplay)) {
          // 初期選択の設定
          let selectIndex = 0;
          newViewModel.MonthSelects.forEach((monthSelect, i) => {
            // 未完了OR完了した月が対象
            if (
              monthSelect.ConfStatus === AuditStatusEnum.Completed ||
              monthSelect.ConfStatus === AuditStatusEnum.Uncomplete
            ) {
              // 決算集計月を選択から外す
              if (monthSelect.SortKey! % 10 !== 9) {
                selectIndex = i;
              }
            }
          });
          if (newViewModel.MonthSelects[selectIndex].ConfStatus !== AuditStatusEnum.EmptyData) {
            newSelecteds[selectIndex].selected = true;
            //selectNMonth = newSelecteds[selectIndex].NMonth;
            selectKey = newSelecteds[selectIndex].SortKey;
            // 内部月の開始月、終了月を入れる
            selectNMonthArray.push(newSelecteds[selectIndex].StNMonth, newSelecteds[selectIndex].EdNMonth);
          }
        } else {
          // 以下の値は使われていない
          newSelecteds.forEach(item => {
            const oldItem = selecteds.filter(data => data.StNMonth === item.StNMonth && data.EdNMonth === item.EdNMonth);
            if (oldItem.length > 0) {
              item.selected = oldItem[0].selected;
            }
          });

          const selectedNMonths = selecteds.filter(item => item.selected);
          if (selectedNMonths.length > 0) {
            //selectNMonth = selectedNMonths[0].NMonth;
            selectKey = newSelecteds[0].SortKey;
            // 内部月の開始月、終了月を入れる
            selectNMonthArray.push(selectedNMonths[0].StNMonth, selectedNMonths[0].EdNMonth);
          }
        }
      }
      // 画面表示用月度の生成
      const newDisplayNMonth: monthSelectData[] = [];
      const MultMidtermClosingNMonth: monthSelectData[] = [];
      newViewModel.MonthSelects.forEach(monthSelect => {
        if (isDspMonth(monthSelect.NMonth!, newViewModel.MiddleKbn!)) {
          const nMonth = monthSelect.NMonth!;
          const sortKey = monthSelect.SortKey!;
          if (isNormalMonth(nMonth)) {
            // 通常月は表示
            newDisplayNMonth.push(monthSelect);
          } else {
            // 決算月の表示設定
            if (props.displayMode == MODE.resultDisplay) {
              // 「監査実行・結果表示」の場合
              if (sortKey % 10 == 9) {
                // 決算集計月を初期表示にする
                newDisplayNMonth.push(monthSelect);
              } else {
                MultMidtermClosingNMonth.push(monthSelect)
              }
            } else {
              // 「監査実行・結果表示」以外の場合
              if (isMultMidtermClosingInitDisplay(nMonth) && sortKey % 10 != 9) {
                // 決算月1を初期表示にする
                newDisplayNMonth.push(monthSelect);
              } else {
                // 決算集計月を表示しない
                if (sortKey % 10 != 9) {
                  MultMidtermClosingNMonth.push(monthSelect);
                }
              }
            }
          }
        }
      });

      // 複数決算月の設定 選択状況のマッピング
      newDisplayNMonth.forEach(monthSelect => {
        const tensPlace = Math.floor(monthSelect.NMonth! / 10);
        if (isMultMidtermClosingInitDisplay(monthSelect.NMonth!) || monthSelect.SortKey! % 10 === 9) {
          // 複数決算月の取得
          const multiNMonth = [{ ...monthSelect }].concat(
            MultMidtermClosingNMonth.filter(mSelect => tensPlace === Math.floor(mSelect.NMonth! / 10))
          );

          if (multiNMonth.length > 1) {
            monthSelect.multiNMonth = multiNMonth;

            // 初期選択の月度を初期表示させる
            let selectNMonths = [];
            if (isInitialSelection) {
              // 選択状態がクリアされる場合
              selectNMonths = multiNMonth.filter(mSelect => mSelect.SortKey === selectKey);
            } else {
              // 選択状態を引き継ぐ場合

              // 現在表示されている月度を検索
              const displayselectNMonths = displayNMonth.filter(item => {
                if (item.multiNMonth != null && item.multiNMonth.length > 0) {
                  if (item.multiNMonth.filter(data => data.SortKey === monthSelect.SortKey).length > 0) {
                    return true;
                  }
                }
                return false;
              });

              selectNMonths = multiNMonth.filter(
                mSelect => displayselectNMonths.length > 0 && mSelect.SortKey === displayselectNMonths[0].SortKey
              );
            }
            // 各集計月の選択状況をコントロールする処理
            if (selectNMonths.length > 0) {
              for (const key in selectNMonths[0]) {
                monthSelect[key] = selectNMonths[0][key];
              }
            }
          }
        }
      });
      setStates({ viewModel: newViewModel, selecteds: newSelecteds, displayNMonth: newDisplayNMonth });
      if (selectKey >= 0 && isInitialSelection) {
        props.onMonthChange!(selectNMonthArray);
      }
    }
  };

  //通信処理(初期表示データ作成)
  const initialOnGet = (url: string, isInitialSelection: boolean) => {
    setIsLoading(true);
    // WebAPI一覧のURLを作成
    Axios.get<MonthSelectInformationViewModel>(url).then(response => {
      setIsLoading(false);
      setViewModel(response.data, isInitialSelection);
    })
    .catch(error => {
      setIsLoading(false);
      setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
    });
  };

  // データ取り込み中確認
  const [isImportApiOpen, setIsImportApiOpen] = React.useState(false);
  const handleOnImportApiClose = () => {
    setIsImportApiOpen(false);
  };
  /** データ取得確認処理 */
  const resultStatusOnGet = (url: string, nextStNMonth: number, nextEdNMonth: number, nextKey: number) => {
    Axios.get<BatStatusEnum>(url).then(response => {
      const status = response.data;
      if (status == BatStatusEnum.Processing) {
        setIsImportApiOpen(true);
      } else {
        selectionMonth(nextStNMonth, nextEdNMonth, nextKey);
      }
    })
    .catch(error => {
      setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.message });
    });
  };

  // 通信処理の実行
  React.useEffect(() => {
    initialOnGet(initialUrl, true);
  }, [initialUrl, props.fiscalYear]);

  // 月度情報のリフレッシュ
  React.useEffect(() => {
    if (typeof refreshCnt === 'number' && refreshCnt > 0) initialOnGet(initialUrl, false);
  }, [refreshCnt]);
  // 月選択バーのマウスアップイベントは呼ばれたコンポーネント全体に付与する
  React.useEffect(() => {
    window.addEventListener('mouseup', handleOnDragEnd);
    return () => {
      // コンポーネントが破棄されたと同時にイベントも削除する（イベント追加重複対策）
      window.removeEventListener('mouseup', handleOnDragEnd);
    };
  }, [handleOnDragEnd]);

  // divタグに不要なpropsを除去
  const divProps = { ...props };
  delete divProps['displayMode'];
  delete divProps['onMonthChange'];
  delete divProps['fiscalYear'];
  delete divProps['dataRelationCheck'];
  delete divProps['refreshCnt'];
  delete divProps['tabIndex'];
  return viewModel.MonthSelects == undefined || viewModel.MonthSelects.length < 1 ? null : (
    <div {...divProps} className={classnames(props.className, 'align-middle pt-1 pb-1 d-flex flex-row')}>
      <div className='p-1 bg-gray text-nowrap'>月度</div>
      <div className='p-1 bg-gray'>
        <ListGroup className='list-group-horizontal fiscal-month text-center'>
          {displayNMonth.map(FiscalMonthButton)}
        </ListGroup>
      </div>
      <ImportApi activated={isImportApiOpen} onClose={handleOnImportApiClose} />
    </div>
  );
};

export default MonthSelect;
