import React from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import * as Labels from '../../../../constants/labels';

import PageHeader from '../../../../components/molecules/CMN/PageHeader/PageHeader';
import { LowerActionLayout, LowerActionLayoutAction, LowerActionLayoutContents, LowerActionLayoutHeader } from '../../../../components/templates/LowerActionLayout';

import Axios from 'axios';

import { UserInfoEditViewModel } from '../../../../models/userInfoEditViewModel';

import { setAlertMessage, AlertKbnEnum } from '../../../../stores/AlertMessage';
import { bindValueToMessage } from '../../../../utils/messageUtils';
import * as message from '../../../../constants/message';
import { setIsLoading } from '../../../../stores/NowLoading';

// フォーム行ごと表示仕様のCSS Class
const FormColClass = 'd-flex p-3';
// 項目ラベルのCSS Class
const FormColLabelClass = 'col-label';
//項目ラベル(Bizsky)のCSS Class
const FormColLabelIDClass = 'col-label-ID';
// 入力提示LabelのCSS Class
const FormColContentLabelClass = 'col-content-label-control';
// フォーム入力欄のCSS Class
const FormColContentInputClass = 'col-content-input-control';
//メールアドレス入力欄CSS Class
//const FormColContentMailAddrInputClass = 'col-content-input-control col-mailAddr-control';

/** サーバへアクセスする際のURL(ベース部分) */
const BaseURL = 'api/v1/UserInfoGetService';
//** 初期表示時にサーバへアクセスする際のURL */
const InitialURL = [BaseURL, 'Initial'].join('/');
/** 保存ボタン押下時にサーバへアクセスする際のURL */
const UpdateURL = [BaseURL, 'Update'].join('/');
// 起動判定URL
const JudgeURL = 'api/v1/CommonStartJudgement/Judge';
// システム履歴記入
const WriteSysHistoryURL = 'api/v1/CommonSystemHistory/Write';


type UserInfoProps = {
    /** 自画面を閉じる処理 */
    onClose: () => void;
};

const UserInfo: React.FC<UserInfoProps> = props => {
    const { onClose } = props;

    /** 閉じるボタン押下時の処理 */
    const handleOnCloseClick = () => {
        onClose();
    };

    ///** 画面が表示された実行する処理 */
    React.useEffect(() => {
        // 処理判定
        async function JudgeOnPost(url: string, syoriId: string) {
            const status = await Axios.post<number>(url, { syoriId: syoriId });
            if (status.data == 0) {
                // 初期起動
                InitialOnGet(InitialURL);
                await Axios.post(WriteSysHistoryURL, { syoriId: syoriId, operation: '処理開始' });
            } else {
                if (status.data == -101) {
                    setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotUse });
                } else if (status.data == -102) {
                    setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractOver });
                } else if (status.data == -103) {
                    setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_Notauthorized });
                } else if (status.data == -104) {
                    setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotDataSelect });
                } else if (status.data == -105) {
                    setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractEqual });
                }
                props.onClose();
            }
        }
        JudgeOnPost(JudgeURL, 'AIKCMN009000001');
    }, [InitialURL]);


    //通信処理(初期表示データ作成)
    const InitialOnGet = (url: string) => {
        setIsLoading(true);
        let emptyDefortViewModel = new UserInfoEditViewModel();
        emptyDefortViewModel.bizskyID = "";
        emptyDefortViewModel.FirstName = "";
        emptyDefortViewModel.FirstNameKana = "";
        emptyDefortViewModel.LastName = "";
        emptyDefortViewModel.LastNameKana = "";
        //emptyDefortViewModel.Email = "";
        updateViewModel(emptyDefortViewModel);

        // 通信処理の実行
        Axios.post<UserInfoEditViewModel>(url)
            .then(response => {
                let newViewModel = response.data;
                // 初期表示時のデータをバックアップ
                let initial = new UserInfoEditViewModel();
                initial.bizskyID = newViewModel.bizskyID;
                //initial.Email = newViewModel.Email;
                initial.FirstName = newViewModel.FirstName;
                initial.FirstNameKana = newViewModel.FirstNameKana;
                updateViewModel(newViewModel);
            })
            .finally(() => {
              setIsLoading(false);
            })
    };

    /**
     * テキストボックス内変更時の処理
     * @param event
     */
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        switch (event.target.name) {
            case 'LastName':
                updateViewModel({ ...viewModel, LastName: event.target.value });
                break;
            case 'FirstName':
                updateViewModel({ ...viewModel, FirstName: event.target.value });
                break;
            case 'LastNameKana':
                updateViewModel({ ...viewModel, LastNameKana: event.target.value });
                break;
            case 'FirstNameKana':
                updateViewModel({ ...viewModel, FirstNameKana: event.target.value });
                break;
            // case 'Email':
            //     updateViewModel({ ...viewModel, Email: event.target.value });
            //     break;
            // case 'Email2':
            //     updateViewModel({ ...viewModel, Email2: event.target.value });
            //     break;
        }
    }
    const [viewModel, updateViewModel] = React.useState<UserInfoEditViewModel>(
        new UserInfoEditViewModel()
    );

    /** 更新ボタン押下時の処理 */
    const handleOnUpdateClick = () => {
        //必須項目があるか？
        if (viewModel.FirstName.trim() == "" || viewModel.LastName.trim() == "") {
            setAlertMessage({
                alerted: true,
                alertKbn: AlertKbnEnum.danger,
                message: bindValueToMessage(message.UserInfo_Error_Required_Name)
            })
            return;
        }

        if (viewModel.FirstNameKana.trim() == "" || viewModel.LastNameKana.trim() == "") {
            setAlertMessage({
                alerted: true,
                alertKbn: AlertKbnEnum.danger,
                message: bindValueToMessage(message.UserInfo_Error_Required_NameKana)
            })
            return;
        }

        // if (viewModel.Email.trim() == "") {
        //     setAlertMessage({
        //         alerted: true,
        //         alertKbn: AlertKbnEnum.danger,
        //         message: bindValueToMessage(message.UserInfo_Error_Required_MailAddress)
        //     })
        //     return;
        // }
        // //有効なメールアドレスか？
        // //reg → 正規化
        // var reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
        // if (!reg.test(viewModel.Email)) {
        //     setAlertMessage({
        //         alerted: true,
        //         alertKbn: AlertKbnEnum.danger,
        //         message: bindValueToMessage(message.UserInfo_Error_Format_MailAddress)
        //     })
        //     return;
        // }

        // //メールアドレスが確認用と同じか？
        // if (viewModel.Email.trim() != viewModel.Email2) {
        //     setAlertMessage({
        //         alerted: true,
        //         alertKbn: AlertKbnEnum.danger,
        //         message: bindValueToMessage(message.UserInfo_Error_Confirm_MailAddress)
        //     })
        //     return;
        // }

        //問題ないとき更新
        setIsLoading(true);
        Axios.post<string>(UpdateURL, viewModel)
          .then(response => {
            setAlertMessage({
                alerted: true,
                alertKbn: AlertKbnEnum.success,
                message: bindValueToMessage(message.UserInfo_Success_Save)
            });
          })
          .catch(error => {
            if (typeof error.response.data !== 'undefined') {
              setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
            }
          })
          .finally(() => {
            setIsLoading(false);
          })
    }

    //画面表示
    return (
        <div className='user-info'>
            <LowerActionLayout className='vw-100'>
                <LowerActionLayoutHeader>
                    <PageHeader
                        pageTitle={Labels.AIKCMN00900001_FUNCTION_NAME}
                        closeButtonTabIndex={8}
                        onCloseClick={handleOnCloseClick}
                    />
                </LowerActionLayoutHeader>
                <LowerActionLayoutContents className='userinfo-content'>
                    <Form>
                        <FormGroup className={FormColClass}>
                            <Label className={FormColLabelClass}>
                                {Labels.AIKCMN00900001_LABEL_BIZSKYID}
                            </Label>
                            <Label className={FormColLabelIDClass}

                                id='bizskyID'
                                name='bizskyID'
                            >
                                {viewModel.bizskyID}


                            </Label>
                        </FormGroup>
                        <FormGroup>
                            <div className={FormColClass}>
                                <Label className={FormColLabelClass}>
                                    {Labels.AIKCMN00900001_LABEL_NAME}
                                </Label>
                                <Label className={FormColContentLabelClass}>
                                    {Labels.AIKCMN00900001_LABEL_SEIINP}
                                    <Input
                                        type='text'
                                        id='UserLastName'
                                        name='LastName'
                                        className={FormColContentInputClass}
                                        value={viewModel.LastName}
                                        onChange={handleOnChange}
                                        tabIndex={1}
                                        maxLength={50}
                                    ></Input>
                                </Label>
                                <Label className={FormColContentLabelClass}>
                                    {Labels.AIKCMN00900001_LABEL_MEIINP}
                                    <Input
                                        type='text'
                                        id='UserFirstName'
                                        name='FirstName'
                                        className={FormColContentInputClass}
                                        value={viewModel.FirstName}
                                        onChange={handleOnChange}
                                        tabIndex={2}
                                        maxLength={50}
                                    > </Input>
                                </Label>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <div className={FormColClass}>
                                <Label className={FormColLabelClass}>
                                    {Labels.AIKCMN00900001_LABEL_NAMEKANA}
                                </Label>
                                <Label className={FormColContentLabelClass}>
                                    {Labels.AIKCMN00900001_LABEL_SEIKANAINP}
                                    <Input
                                        type='text'
                                        id='UserLastNameKana'
                                        name='LastNameKana'
                                        className={FormColContentInputClass}
                                        value={viewModel.LastNameKana}
                                        onChange={handleOnChange}
                                        tabIndex={3}
                                        maxLength={50}
                                    ></Input></Label>
                                <Label className={FormColContentLabelClass}>
                                    {Labels.AIKCMN00900001_LABEL_MEIKANAINP}
                                    <Input
                                        type='text'
                                        id='UserFirstNameKana'
                                        name='FirstNameKana'
                                        className={FormColContentInputClass}
                                        value={viewModel.FirstNameKana}
                                        onChange={handleOnChange}
                                        tabIndex={4}
                                        maxLength={50}
                                    ></Input></Label>
                            </div>
                        </FormGroup>
                        {/* <FormGroup>
                            <div className={FormColClass}>
                                <Label className={FormColLabelClass}>
                                    {Labels.AIKCMN00900001_LABEL_MAIL}
                                </Label>
                                <Label className={FormColContentLabelClass}>
                                    {Labels.AIKCMN00900001_LABEL_MAILINP}
                                    <Input
                                        type='text'
                                        id='Email'
                                        name='Email'
                                        className={FormColContentMailAddrInputClass}
                                        value={viewModel.Email}
                                        onChange={handleOnChange}
                                        tabIndex={5}
                                        maxLength={256}
                                    /> </Label>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <div className={FormColClass}>
                                <Label className={FormColLabelClass}>
                                    {Labels.AIKCMN00900001_LABEL_MAILKAKUNIN}
                                </Label>
                                <Label className={FormColContentLabelClass}>
                                    {Labels.AIKCMN00900001_LABEL_MAILKAKUNININP}
                                    <Input
                                        type='text'
                                        id='Email2'
                                        name='Email2'
                                        className={FormColContentMailAddrInputClass}
                                        value={viewModel.Email2}
                                        onChange={handleOnChange}
                                        tabIndex={6}
                                        maxLength={256}
                                    /></Label>
                            </div>
                        </FormGroup> */}
                    </Form>
                </LowerActionLayoutContents>
                <LowerActionLayoutAction>
                    <div>
                        <Button
                            id='updatebutton'
                            color='primary'
                            className='update-button'
                            onClick={handleOnUpdateClick}
                            tabIndex={7}

                        >
                            {Labels.AIKCMN00900001_BUTTON_SAVE}
                        </Button>
                    </div>
                </LowerActionLayoutAction>
            </LowerActionLayout>
        </div>
    );
}

export default UserInfo;