import { AxiosResponse } from 'axios';
import * as dateUtils from './dateUtils';

/**
 * レスポンスよりファイルをダウンロードします。
 * @param response レスポンス
 */
export const downloadFileFromResponse = (response: AxiosResponse, isOutputTime?: boolean) => {
  // ファイル名を取得
  let fileName = '';
  const disposition = response.headers['content-disposition'];
  if (disposition != undefined) {
    const dispositions = disposition.split('; ');
    for (let i = 0; i < dispositions.length; i++) {
      const item = dispositions[i];
      if (item.split('=')[0] === 'filename*') {
        fileName = decodeURIComponent(item.replace("filename*=UTF-8''", ''));
        if (isOutputTime === true) {
          const extensionIndex = fileName.lastIndexOf('.');
          const data = new Date();
          fileName =
            fileName.substr(0, extensionIndex) +
            '_' +
            dateUtils.format(data, 'yyyyMMddhhmmdd') +
            fileName.substr(extensionIndex);
        }
        break;
      }
    }
  }

  if (fileName !== '') {
    if (window.navigator.msSaveOrOpenBlob) {
      // IE、Edge
      window.navigator.msSaveOrOpenBlob(response.data, fileName);
    } else {
      // ファイルをダウンロードさせる
      const link = document.createElement('a');
      // 'type'は拡張子毎に異なるが今は'xlsx'のみの為固定
      const type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      link.href = window.URL.createObjectURL(new Blob([response.data], { type: type }));
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    }
  }
};
