import React from 'react';
import * as Enums from '../../../constants/enums';
import * as Labels from '../../../constants/labels';
import * as Maps from '../../../constants/dataMaps';

import Button from 'reactstrap/lib/Button';
import AuditExecPopup, { AEPopupProps } from './AuditExecPopup';
import { FormGroup, Label, Input, ButtonGroup } from 'reactstrap';
import { DblJokenVO } from '../../../models/dblJokenVO';
import ExclusionItemSetting from '../../../containers/organisms/A040/ExclusionItemSetting';

export type AecPopupProps = AEPopupProps & {
  conditionHeading?: string;
  checkpattern?: Array<DblJokenVO>; 
  jdpopSave?: boolean;
  onJDClick?: (jokens: Array<Enums.DblJokenEnum>) => void;
};

type DblJokenType = {
  key: Enums.DblJokenEnum;
  value: string;
  checked: boolean;
};

// tabIndexの初期値
const baseTabIndex = 200;

const AuditExecCheckPopup: React.FC<AecPopupProps> = props => {
  const isDisabled = props.isExecute || props.disabled;
  const [dblJokenList, setDblJoken] = React.useState<DblJokenType[]>(
    Maps.DblJokenEnum.map(dblJoken => ({
      key: dblJoken.key,
      value: dblJoken.value,
      checked: false
    }))
  );
  /**
   * 除外項目設定関連 
   */
  // 除外項目設定ボタン活性状態
  const [excludeItemBtnActived, setExcludeItemBtnActived] = React.useState(true);
  // 除外項目設定ポップアップ起動ステータス管理
  const [excludeItemPopupActived, setxcludeItemPopupActived] = React.useState(false);

  const checkAll = () => {
    dblJokenList.forEach(dblJoken => (dblJoken.checked = true));
    setDblJoken([...dblJokenList]);
    setExcludeItemBtnActived(false);
  };
  const uncheckAll = () => {
    dblJokenList.forEach(dblJoken => (dblJoken.checked = false));
    setDblJoken([...dblJokenList]);
    setExcludeItemBtnActived(true);
  };
  // 除外項目設定ボタン押下時処理
   const excludeItemSettingBtnOnClick = () => {
    setxcludeItemPopupActived(true);
  };


  // 除外項目設定ボタン押下時処理
  const excludeItemSettingClosed = () => {
    setxcludeItemPopupActived(false);
  };

  const handleOnCheckboxChange = (key: Enums.DblJokenEnum) => {
    const index = dblJokenList.findIndex(dblJoken => dblJoken.key == key);
    dblJokenList[index].checked = !dblJokenList[index].checked;

    if (key == Enums.DblJokenEnum.Account) {
      // 勘定科目がチェックされた場合は除外項目設定ボタンの活性状態もコントロールする
      setExcludeItemBtnActived(!dblJokenList[index].checked);
    }
    setDblJoken([...dblJokenList]);
  };

  const handleOnClick = () => {
    const targets: Array<Enums.DblJokenEnum> = [];
    dblJokenList.forEach(dblJoken => {
      if (dblJoken.checked) {
        targets.push(dblJoken.key);
      }
    });
    setExcludeItemBtnActived(true);
    props.onJDClick!(targets);
  };

  const firstFocusElement = React.useRef<any>();
  const lastFocusElement = React.useRef<any>();

  const checkBoxes: React.ReactElement[] = [];
  dblJokenList.forEach(dblJoken => {
    checkBoxes.push(
      <FormGroup check className='w-20' key={[dblJoken.key, 'checkbox'].join('-')}>
        <Label check>
          <Input
            type='checkbox'
            name='dblJoken'
            value={dblJoken.key}
            checked={dblJoken.checked}
            disabled={isDisabled}
            onChange={() => {
              handleOnCheckboxChange(dblJoken.key);
            }}
            tabIndex={baseTabIndex + 4}
          />
          <span>{dblJoken.value}</span>
        </Label>
      </FormGroup>
    );
  });

  React.useEffect(() => {
    // 監査実行ボタンを押下
    var excludeDisable = true;
    if (props.jdpopSave) {
      setDblJoken([...dblJokenList]);
      excludeDisable = dblJokenList.findIndex(dblJoken => dblJoken.key == Enums.DblJokenEnum.Account && dblJoken.checked) != -1 ? false : true ;
      setExcludeItemBtnActived(excludeDisable);
    } else {
      // DBから条件取得済み
      if (props.checkpattern !== undefined && props.checkpattern){
        dblJokenList.forEach(dbljoken => dbljoken.checked = false);
        dblJokenList.forEach(dblJoken => {
          props.checkpattern!.forEach(_dbljoken => {
            if (_dbljoken.DblJoken == dblJoken.key) {
              dblJoken.checked = true;
              if (dblJoken.key == Enums.DblJokenEnum.Account) {
                excludeDisable = false;
              }
            }
          })
        });
      } else {
        dblJokenList.forEach(dbljoken => dbljoken.checked = false);
      }
      setDblJoken([...dblJokenList]);
      setExcludeItemBtnActived(excludeDisable);
    }
  }, [props.isOpen]);

  const conditionHeading = props.conditionHeading ? <label>{props.conditionHeading}</label> : '';

  const popupProps = { ...props };
  delete popupProps['conditionHeading'];
  delete popupProps['checkpattern'];
  delete popupProps['jdpopSave'];
  delete popupProps['onJDClick'];
    
  return (
    <AuditExecPopup
      {...popupProps}
      onClick={handleOnClick}
      execButtonTabIndex={baseTabIndex + 5}
      cancelButtonTabIndex={baseTabIndex + 6}
      closeIconTabIndex={baseTabIndex + 7}
      closeIconRef={lastFocusElement}
      firstFocusElement={firstFocusElement}
      lastFocusElement={lastFocusElement}
    >
      <div>{conditionHeading}</div>
      <div className='d-flex'>
        <div className='button-area-left'>
          <ButtonGroup>
            <Button
              color='primary'
              outline={true}
              className='h5 py-0'
              onClick={checkAll}
              disabled={isDisabled}
              tabIndex={baseTabIndex + 1}
              innerRef={firstFocusElement}
            >
              {Labels.COMMON_BUTTON_FUNCTION_SELECTALL}
            </Button>
            <Button
              color='primary'
              outline={true}
              className='h5 py-0'
              onClick={uncheckAll}
              disabled={isDisabled}
              tabIndex={baseTabIndex + 2}
            >
              {Labels.COMMON_BUTTON_FUNCTION_UNSELECTALL}
            </Button>
          </ButtonGroup>
        </div>
        <div className='flex-row-reverse button-area-right'>
          <Button
            color='secondary'
            className='exclude-item-setting-button'
            onClick={excludeItemSettingBtnOnClick}
            disabled={excludeItemBtnActived}
            tabIndex={baseTabIndex + 3}
          >            
            {Labels.AIKADT002000001_BUTTON_FUNCTION_EXCLUSIONITEMSETTING}
          </Button>
        </div>
      </div>
      <div className='d-flex flex-wrap'>{checkBoxes}</div>
      <div>{props.children}</div>
      <ExclusionItemSetting
        activated={excludeItemPopupActived}
        onClose={excludeItemSettingClosed}
      />
    </AuditExecPopup>
  );
};
export default AuditExecCheckPopup;
