import { Logger } from "../../common/usefuls/logger";
import { Ajax, AjaxSettings } from "../../core/ajax";
import $ from 'jquery';
import { Mjs } from "../../mjs";

//予期せぬ画面遷移時に警告メッセージの出力とログアウト処理をログアウト処理を行う
export class UnloadCheck {

    private static isUnload = true;
    private static isOpener = false;

    /**
     * ページ表示における、共通の初期化処理を行います。
     */
    public static unloadCheck(): any {
        // フォームをサブミットする前にフラグを落とす。
        $("form").submit(function () {
            UnloadCheck.setUnloadFlagOff();
        });

        window.onload = function () {
            if (window.opener) {
                UnloadCheck.isOpener = true;
            }
        }

        //管理サイトより開かれた場合はログアウト処理を実行しない
        /*
        window.onbeforeunload = function () {
            if (UnloadCheck.getUnload() && window.parent.name != "formpost" && !UnloadCheck.getOpener())
                return "ログアウトします。よろしいですか？";
            return undefined!;
        }
        */

        //予期せぬ画面遷移時はログアウト処理を行う
        window.onunload = async function () {
            if (UnloadCheck.getUnload() && window.parent.name != "formpost" && !UnloadCheck.getOpener()) {
                let settings: AjaxSettings = {
                    url: "/api/v1/auth",
                    type: "DELETE",
                    data: "",
                    showBusyIndicator: true,
                    beforeSend: null!
                }
                await Ajax.perform(settings, false);
            }
        };
    }

    /**
     * アンロードフラグをONに設定します。
     * @param val
     */
    public static setUnloadFlagOn(): void {
        UnloadCheck.setUnload(true);
    }

    /**
     * アンロードフラグをOFFに設定します。
     * @param val
     */
    public static setUnloadFlagOff(): void {
        UnloadCheck.setUnload(false);
    }

    /**
     * アンロードフラグを取得します。
     */
    private static getUnload(): boolean {
        return UnloadCheck.isUnload;
    }

    /**
     * アンロードフラグを設定します。
     * @param val
     */
    private static setUnload(val: boolean): void {
        UnloadCheck.isUnload = val;
    }

    /**
     * アンロードフラグを取得します。
     */
    private static getOpener(): boolean {
        return UnloadCheck.isOpener;
    }

    /**
     * 指定したURLに画面遷移する
     * (セッショントークンなど必要なパラメータを設定してPOSTにて移動する)
     * @param url
     * @param data
     */
    public static submitFromMenu(url: string, data = {}, unloadFlagOn = false) {
        if (unloadFlagOn) {
            UnloadCheck.setUnloadFlagOn();
        } else {
            UnloadCheck.setUnloadFlagOff();
        }

        var params = { "__VSVerificationToken": Mjs.hsTokenValue, "selectedSystemDiv": Mjs.selectedSystemDiv, "isDispCreateCompanyData": false, "lastSelectedMenuCategoryId": Mjs.lastSelectedMenuCategoryId };
        $.extend(params, data);

        var $form = $('<form/>', { 'action': url, 'method': 'post' });
        for (var key in params) {
            $form.append($('<input/>', { 'type': 'hidden', 'name': key, 'value': params[key] }));
        }
        $form.appendTo(document.body);
        $form.submit();
        Logger.debugTimeStamp("submitFromMenu");
    }

    public static aLinkCheck(): any {
        //var postForm = function (url, data) {
        //    var $form = $('<form/>', { 'action': url, 'method': 'post' });
        //    for (var key in data) {
        //        $form.append($('<input/>', { 'type': 'hidden', 'name': key, 'value': data[key] }));
        //    }
        //    $form.appendTo(document.body);
        //    $form.submit();
        //};

        $(document).on("click", "ul.drawer-menu a", (function (elem) {
            // メニューのaタグクリック時にヘッダを付加してXHRで画面遷移をさせます。
            var url = $(elem).attr("href");
            if (typeof (url) == "undefined" || url === "#" || url === "javascript:void(0)") {
                return;
            }

            UnloadCheck.submitFromMenu(url,
                { lastSelectedMenuCategoryId: url !== "/" && url !== "/Home" && typeof Mjs.selectedMenuCategoryId !== 'undefined' ? Mjs.selectedMenuCategoryId : '' });
            return false;
        }));

        $(document).on("click", "div.gnav-panel__menu__body a", (function (elem) {
            // メニューのaタグクリック時にヘッダを付加してXHRで画面遷移をさせます。
            var url = $(elem).attr("href");
            if (typeof (url) == "undefined" || url === "#" || url === "javascript:void(0)") {
                return;
            }

            UnloadCheck.submitFromMenu(url,
                { lastSelectedMenuCategoryId: url !== "/" && url !== "/Home" && typeof Mjs.selectedMenuCategoryId !== 'undefined' ? Mjs.selectedMenuCategoryId : '' });
            return false;
        }));
    }
}

$(function () {
    UnloadCheck.aLinkCheck();
    UnloadCheck.unloadCheck();
});