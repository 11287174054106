/**
 * マスターデータ ViewModel クラス
 */
import { KeyValueData, RensouData } from "../../common/usefuls/utilities";
import { DataModelBase } from "../../core/models/dataModelBase";
export class SKmkMACacheViewModel extends DataModelBase implements KeyValueData, RensouData {
    // interface:KeyValueDataの実装
    public get key(): string { return this.code; }
    public get value(): string { return this.SimpleName; }

    // interface:RensouDataの実装
    public get code(): string { return (this.GCode != undefined && this.GCode != null) ? this.GCode.toString() : null!; }
    public get masterKbn(): number { return this.MasterKbn; }
    public get displayValue(): string { return this.SimpleName; }
    public get subNameValue(): string { return this.LongName; }
    public get rensou(): string { return this.RenChar; }
    public get simpleFlag(): boolean { return true; }
    public get detailFlag(): boolean { return true; }
    private _displayFlag!: boolean;
    public set displayFlag(val: boolean) { this._displayFlag = val; }
    public get displayFlag(): boolean { return this._displayFlag; }

    /** マスタ区分 */
    public MasterKbn!: number;

    /** 実在／合計区分・補助区分・部門区分・プロジェクト区分 */
    public SumKbn!: number;

    /** レコード区分 */
    public RecordKbn!: number;

    /** 外部コード */
    public GCode!: number;

    /** 連想シンボル */
    public RenChar!: string;

    /** 正式名称 */
    public LongName!: string;

    /** 簡略名称 */
    public SimpleName!: string;

    /** 内部コード（ユニークKey） */
    public NCode!: number;

    /** 更新日時 */
    public UpdateTime!: Date;

    /** 正残区分・科目区分 */
    public DCKbn!: number;

    /** 分析コード・要約分析コード・科目分類・ＢＳ/ＰＬ区分 */
    public AnalyzeCode!: number;

    /** 装飾区分 */
    public DecoKbn!: number;

    /** マイナス記号 */
    public MinusKbn!: number;

    /** 優先区分・原価合計区分 */
    public KmkKbn!: number;

    /** タイトル種別 */
    public TitleKbn!: number;

    /** 連動科目コード */
    public ConnectCode!: number;

    /** マイナス変換科目名・元帳タイトル */
    public MinusName!: string;

    /** 使用／未使用区分 */
    public UseKbn!: number;

    /** 固定体系区分 */
    public KoteiKbn!: number;

    /** 内部コード２ */
    public NCode2!: number;

}

