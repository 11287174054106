import React from 'react';
import classnames from 'classnames';
import { FlexGrid } from 'wijmo/wijmo.react.grid';
import { SelectionMode } from 'wijmo/wijmo.grid';
import { JDExcludeKmkSettingVO } from '../../../models/jDExcludeKmkSettingVO';
import * as labels from '../../../constants/labels';
import * as wjGrid from 'wijmo/wijmo.grid';
import * as wjFilter from 'wijmo/wijmo.grid.filter';
import { FlexGridFilter } from 'wijmo/wijmo.react.grid.filter';

type ExcludeKmkSettingProps = React.HTMLAttributes<HTMLDivElement> & {
  resultDetail?: JDExcludeKmkSettingVO[];
  tabIndex?: number;
  noFlexDesignFlg?: boolean;
  gridReactRef?: React.RefObject<any>;
  innerRef?: React.RefObject<any>;
  initialGridFunction?: (flexGrid: wjGrid.FlexGrid) => void;
  filter?: wjFilter.FlexGridFilter;
  initialGridFilterFunction?: (flexFilter: wjFilter.FlexGridFilter) => void;
};

const ExcludeKmkSetting: React.FC<ExcludeKmkSettingProps> = props => {
  const { resultDetail, tabIndex, gridReactRef, innerRef, noFlexDesignFlg } = props;
  // グリッド参照用（フォーカスやitemSource参照向け）
  // 外部からも指定可能。
  const girdRef = (!gridReactRef) ? React.createRef<FlexGrid>() : gridReactRef;
  // このコンポーネント内で参照する用
  let grid: wjGrid.FlexGrid;
  
  // Gridの初期化
  const initializedGrid = (flexGrid: wjGrid.FlexGrid) => {
    // 参照を初期化
    grid = flexGrid;
    // このログは確認向けの為本番では出力されないように制御
    console.debug(grid);
    if (props.initialGridFunction) {
      // 追加でグリッド初期化処理にて何か動作させたい場合は外部からも指定可能
      props.initialGridFunction(flexGrid);
    }
  };

  // フィルターの初期化
  const initializedFilter = (flexFilter: wjFilter.FlexGridFilter) => {
    if (props.initialGridFilterFunction) {
      // 追加でフィルター初期化処理にて何か動作させたい場合は外部からも指定可能
      props.initialGridFilterFunction(flexFilter);
    }
  };

  // ItemFormatter
  const handleItemFormatter = (panel: wjGrid.GridPanel, row: number, col: number, cell: HTMLElement) => {
    if (panel.cellType === wjGrid.CellType.ColumnHeader) {
      // ヘッダ
      if (!noFlexDesignFlg) { cell.classList.add('d-flex'); }
      cell.classList.add('justify-content-center');
      cell.classList.add('align-items-center');
      cell.classList.add('font-weight-normal');
    } else if (panel.cellType === wjGrid.CellType.Cell) {
      const item = panel.rows[row].dataItem;
      const column: wjGrid.Column = panel.columns[col];

      if (column.inputType === 'SubDsp') {
        if (item.SubDspFlg === false) {
          cell.classList.add('_grayout');
          cell.innerHTML = '';
        }
      } else if (column.inputType === 'percent') {
        cell.classList.add('_percent');
      }
    }
  };

  // divタグに不要なpropsを除去
  const divProps = { ...props };
  delete divProps['resultDetail'];
  delete divProps['tabIndex'];
  delete divProps['noFlexDesignFlg'];
  delete divProps['gridReactRef'];
  delete divProps['initialGridFunction'];
  delete divProps['innerRef'];
  delete divProps['filter'];
  delete divProps['initialGridFilterFunction'];

  return (
    <div {...divProps} className={classnames(props.className, 'exclude-kmk-setting')}>
      <span ref={innerRef}>
        <FlexGrid
          className='exculde-kmk-setting'
          columns={gridColumns}
          autoGenerateColumns={false}
          selectionMode={SelectionMode.Row}
          allowDragging={wjGrid.AllowDragging.None}
          allowMerging={wjGrid.AllowMerging.ColumnHeaders}
          allowResizing={wjGrid.AllowResizing.None}
          headersVisibility={wjGrid.HeadersVisibility.Column}
          frozenColumns={0}
          alternatingRowStep={0}
          itemsSource={resultDetail}
          itemFormatter={handleItemFormatter}
          tabIndex={tabIndex}
          initialized={initializedGrid}
          ref={girdRef}
        >
          <FlexGridFilter initialized={initializedFilter}/>
        </FlexGrid>
      </span>
    </div>
  );
};
export default ExcludeKmkSetting;

// グリッドのカラム情報
const gridColumns = [
  {
    header: ' ',
    binding: 'ExcludeFlg',
    align: 'center',
    isReadOnly: false,
    width: 60,
    inputType: 'allSelectable'
  },
  {
    header: labels.AIKADT002000001_GRID_HEADER_CODE,
    binding: 'KmkGCode',
    isReadOnly: true,
    align: 'right',
    format: 'd',
    width: 110
  },
  {
    header: labels.AIKADT002000001_GRID_HEADER_NAME,
    binding: 'KmkName',
    isReadOnly: true,
    align: 'left',
    width: 200
  },
  {
    header: labels.AIKADT002000001_GRID_HEADER_RENSO,
    binding: 'Renso',
    isReadOnly: true,
    align: 'left',
    width: 150
  }
];
