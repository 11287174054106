import React from 'react';
import { ComboBox } from 'wijmo/wijmo.react.input';
import * as wjInput from 'wijmo/wijmo.input';

import AuditExecPopup, { AEPopupProps } from './AuditExecPopup';

import * as Enums from '../../../constants/enums';
import { RulePatternViewModel } from '../../../models/rulePatternViewModel';

export type AegPopupProps = AEPopupProps & {
  conditionHeading?: string;
  rulePatternVM?: RulePatternViewModel;
  onExcuteClick?: (rulePattern: Enums.RulePatternEnum | null) => void;
};

// tabIndexの初期値
const baseTabIndex = 200;
const rulePatternCmbId = 'AuditExecComboPopup-rulePatternCmb';

const AuditExecComboPopup: React.FC<AegPopupProps> = props => {
  const [rulePatternCmb, setRulePatternCmb] = React.useState<wjInput.ComboBox>();
  const isDisabled = props.isExecute || props.disabled;

  // 監査実行ボタン押下時の処理
  const handleOnClick = () => {
    if (rulePatternCmb!.selectedItem !== null) {
      props.onExcuteClick!(rulePatternCmb!.selectedItem.RulePattern);
    } else {
      props.onExcuteClick!(null);
    }
  };

  const firstFocusElement = React.useRef<any>();
  const lastFocusElement = React.useRef<any>();

  const conditionHeading = props.conditionHeading ? <label>{props.conditionHeading}</label> : '';

  // patternコンボボックスのItialized
  const rulePatternCmbInitialized = (comboBox: wjInput.ComboBox) => {
    setRulePatternCmb(comboBox);
  };

  React.useEffect(() => {
    // WijmoのコンボボックスにTabIndexを設定してもTab遷移がうまくいかない為
    // コンボボックス内のテキストボックスにTabIndexを設定する
    const rulePatternCmbElement = document.getElementById(rulePatternCmbId);
    if (rulePatternCmbElement) {
      const textElement = rulePatternCmbElement.getElementsByTagName('input');
      const elements = Array.from(textElement);
      for (let item of elements) {
        if (item.type === 'text') {
          item.tabIndex = baseTabIndex + 1;
          firstFocusElement.current = item;
          break;
        }
      }
    }
  }, [rulePatternCmb]);

  const popupProps = { ...props };
  delete popupProps['conditionHeading'];
  delete popupProps['rulePatternVM'];
  delete popupProps['onExcuteClick'];

  return (
    <AuditExecPopup
      {...popupProps}
      onClick={handleOnClick}
      execButtonTabIndex={baseTabIndex + 2}
      cancelButtonTabIndex={baseTabIndex + 3}
      closeIconTabIndex={baseTabIndex + 4}
      closeIconRef={lastFocusElement}
      firstFocusElement={firstFocusElement}
      lastFocusElement={lastFocusElement}
    >
      <div>{conditionHeading}</div>
      <div>
        <ComboBox
          id={rulePatternCmbId}
          className='w-100'
          itemsSource={props.rulePatternVM!.RulePatternItems}
          displayMemberPath={'RulePatternName'}
          selectedValuePath={'RulePattern'}
          selectedValue={props.rulePatternVM!.DefaultRulePattern}
          initialized={rulePatternCmbInitialized}
          isDisabled={isDisabled}
        />
      </div>
      <div>{props.children}</div>
    </AuditExecPopup>
  );
};
export default AuditExecComboPopup;
