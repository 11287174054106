/**
 * マスターデータ ViewModel クラス
 */
import { DataModelBase } from '../../core/models/dataModelBase';

export class DTMAINCacheViewModel extends DataModelBase {
    /** 顧問先管理コード */
    public ClientCode!: number;

    /** 年度 */
    public ClientYear!: number;

    /** 顧問先管理ユーザー */
    public ClientUser!: string;

    /** 更新日時 */
    public UpdateTime!: Date;

    /** 会社コード／ｺｰﾄﾞﾏｽﾀNO */
    public ComCode!: number;

    /** 本支店コード */
    public BraCode!: number;

    /** 正式会社名（屋号）、正式コードマスタ名称 */
    public LComName!: string;

    /** フリガナ会社名 */
    public KanaComName!: string;

    /** 正式本支店名 */
    public LBraName!: string;

    /** フリガナ本支店名 */
    public KanaBraName!: string;

    /** 簡略会社名（屋号）、簡略コードマスタ名称 */
    public SComName!: string;

    /** 簡略本支店名 */
    public SBraName!: string;

    /** 連想シンボル */
    public RenChar!: string;

    /** 法人区分 */
    public CorpKbn!: number;

    /** 決算開始年月日 */
    public KStDate!: Date;

    /** 決算終了年月日 */
    public KEdDate!: Date;

    /** 月中開始日 */
    public MStDay!: number;

    /** 和暦／西暦区分 */
    public YearKbn!: number;

    /** 中間決算区分 */
    public MiddleKbn!: number;

    /** 中間決算月の集計方法 */
    public MCalcKbn!: number;

    /** システム区分 */
    public SysKbn!: number;

    /** データ区分（発生区分） */
    public DataKbn!: number;

    /** 合併データ区分 */
    public MargeKbn!: number;

    /** 仕訳入力開始月 */
    public StrMonth!: number;

    /** 決算確定区分 */
    public ComKbn2!: number;

    /** メール会計区分 */
    public ComKbn3!: number;

    /** 公益法人区分 */
    public ComKbn4!: number;

    /** 郵便番号（基番） */
    public ZipCode1!: number;

    /** 郵便番号（枝番） */
    public ZipCode2!: number;

    /** 郵便番号（バーコード） */
    public Barcode!: string;

    /** 住所（上段） */
    public Address1!: string;

    /** 住所（下段） */
    public Address2!: string;

    /** 住所フリガナ（上段） */
    public KanaAddr1!: string;

    /** 住所フリガナ（下段） */
    public KanaAddr2!: string;

    /** 電話番号 */
    public TelNo!: string;

    /** ＦＡＸ番号 */
    public FaxNo!: string;

    /** 設立年月日 */
    public BldUpDate!: Date;

    /** 期数 */
    public Kisu!: number;

    /** 決算書報告日 */
    public RepoDate!: Date;

    /** 決算書監査日 */
    public CheckDate!: Date;

    /** （財務日報）金種表印刷区分 */
    public EComKbn1!: number;

    /** （財務日報）証憑綴り印刷区分 */
    public EComKbn2!: number;

    /** 振替伝票 査印欄印刷個数 */
    public EComKbn3!: number;

    /** 共通事務所情報参照区分 */
    public EComKbn4!: number;

    /** 翌期更新処理区分、コードマスタ使用区分 */
    public EComKbn5!: number;

    /** パスワード１ */
    public DTPassWD0!: string;

    /** パスワード２ */
    public DTPassWD1!: string;

    /** パスワード３ */
    public DTPassWD2!: string;

    /** パスワード４ */
    public DTPassWD3!: string;

    /** パスワード５ */
    public DTPassWD4!: string;

    /** パスワード６ */
    public DTPassWD5!: string;

    /** パスワード７ */
    public DTPassWD6!: string;

    /** パスワード８ */
    public DTPassWD7!: string;

    /** パスワード９ */
    public DTPassWD8!: string;

    /** パスワード１０ */
    public DTPassWD9!: string;

    /** オフラインデータ取出し端末名称 */
    public DTName1!: string;

    /** オフラインデータ取出し担当者名 */
    public DTName2!: string;

    /**  */
    public DTName3!: string;

    /**  */
    public DTName4!: string;

    /**  */
    public DTName5!: string;

    /**  */
    public DTName6!: string;

    /** ﾌﾘｰｺｰﾄﾞに使用する記号 */
    public DTName7!: string;

    /** 旧データコンバート日時 */
    public MkDate!: Date;

    /** 最終バックアップ日時 */
    public BackUpDTime!: Date;

    /** 最新リストア日時 */
    public RsDate!: Date;

    /** データバージョンＮＯ */
    public DataVer!: number;

    /** 担当者グループＮＯ */
    public GroupCode!: number;

    /** 顧問先コード */
    public ComNCode!: number;

    /** メール会計抽出回数（上位） */
    public DtCode1!: number;

    /** メール会計抽出回数（下位） */
    public DtCode2!: number;

    /** メール会計受信回数（上位） */
    public DtCode3!: number;

    /** メール会計受信回数（下位） */
    public DtCode4!: number;

    /** 顧問先情報更新日時 */
    public ElDate1!: Date;

    /** オフラインデータ取出し日時 */
    public ElDate2!: Date;

    /** メール会計最終抽出日時（上位） */
    public ElDate3!: Date;

    /** メール会計最終抽出日時（下位） */
    public ElDate4!: Date;

    /** 会社パスワード */
    public DTPassWDAll!: string;

    /** 会計事務所名 */
    public AOName!: string;

    /** コードマスタ・コード */
    public CMCode!: number;

    /** メール会計最終連結日時（上位） */
    public ElDate5!: Date;

    /** メール会計最終連結日時（下位） */
    public ElDate6!: Date;

    /** 会社法コンバート日時 */
    public ElDate7!: Date;

    /** 会計事務所用データ作成日時 */
    public ElDate8!: Date;

    /**  */
    public ElDate9!: Date;

    /**  */
    public ElDate10!: Date;

    /** データ抽出した担当者コード */
    public OffUser!: number;

    /** 顧問先更新日時 */
    public CMN_KMNSK_KIHON_UpYMDT!: Date;

    /** バックアップ時のタイムスタンプ */
    public BackUpFileTime!: Date;

    /** オフライン取出先 */
    public UserInpKbn!: number;

    /** 入力完了区分1 */
    public KakuteiKbn1!: number;

    /** 入力完了年月日1 */
    public KakuteiDate1!: Date;

    /** 入力完了者1（内部コード） */
    public KakuteiTantoNCD1!: number;

    /** 入力完了者名1 */
    public KakuteiTantoName1!: string;

    /** 完了解除年月日1 */
    public KakuteikaijoDate1!: Date;

    /** 完了解除者1（内部コード） */
    public KakuteikaijoTantoNCD1!: number;

    /** 完了解除者名1 */
    public KakuteikaijoTantoName1!: string;

    /** 完了解除理由1 */
    public KakuteikaijoRiyuu1!: string;

    /** 入力完了区分2 */
    public KakuteiKbn2!: number;

    /** 入力完了年月日2 */
    public KakuteiDate2!: Date;

    /** 入力完了者2（内部コード） */
    public KakuteiTantoNCD2!: number;

    /** 入力完了者名2 */
    public KakuteiTantoName2!: string;

    /** 完了解除年月日2 */
    public KakuteikaijoDate2!: Date;

    /** 完了解除者2（内部コード） */
    public KakuteikaijoTantoNCD2!: number;

    /** 完了解除者名2 */
    public KakuteikaijoTantoName2!: string;

    /** 完了解除理由2 */
    public KakuteikaijoRiyuu2!: string;

}

