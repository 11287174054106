import { messageUtil } from '../common/messageUtil';
import { Messages } from '../common/message';
import $ from 'jquery';

/**
 * 処理中ダイアログクラス(画面中央に処理の進捗状況を意味するプログレスバーを表示する画面)
 * 
 * 本ダイアログを表示中にESCキーを押してもダイアログは閉じない。
 * 本ダイアログを表示中にダイアログの外をクリックしてもダイアログは閉じない。
 *
 * 本クラスを変更する場合、必要に応じて /Mjs.Acelink.VKZ/Views/Shared/Modal/_ProgressConfirmPartial.cshtml も変更すること。
 */
export class ProgressConfirmDialog {

    /** キャンセルボタンのID */
    public static CANCEL_BUTTON_ID: string = "#mjsCommonProgressCancel";

    /** 本ダイアログのID */
    private static DIALOG_ID: string = "#mjsCommonProgressConfirm";

    /** タイトルのID */
    private static TITLE_ID: string = "#mjsCommonProgressConfirmTitle";

    /** メッセージのID */
    private static MESSAGE_ID: string = "#mjsCommonProgressConfirmMessage";

    /** プログレスバーのID */
    private static PROGRESS_BAR_ID: string = "#mjsCommonProgressConfirmPercentage";

    /**
     * コンストラクタ
     * @param title タイトル文字列
     * @param message メッセージ文字列
     * @param initialPercentage 初期表示時の進捗率(%)
     * @param cancelable true:キャンセルボタンで処理を中断可能(キャンセルボタン有り)、false:処理は中断不可能(キャンセルボタン無し)
     */
    constructor(title: string, message: string, initialPercentage:number, cancelable:boolean) {
        this.init(title, message, initialPercentage, cancelable);
        this.enableCancelButton();
    }

    /**
     * 初期化
     * @param title タイトル文字列
     * @param message メッセージ文字列
     * @param initialPercentage 初期表示時の進捗率(%)
     * @param cancelable true:キャンセルボタンで処理を中断可能(キャンセルボタン有り)、false:処理は中断不可能(キャンセルボタン無し)
     */
    private init(title: string, message: string, initialPercentage: number, cancelable: boolean): void {
        $(ProgressConfirmDialog.CANCEL_BUTTON_ID).text(messageUtil.getMsg(Messages.Common.Mailsupport.Inputmode.CANSELBUTTONCAPTION));

        this.setTitle(title);

        this.setMessage(message);

        this.setPercentage(initialPercentage);

        if (cancelable == true) {
            // キャンセルボタンでキャンセル可能
            this.showCancelButton();
        }
        else {
            // キャンセルボタンでキャンセル不可能
            this.hideCancelButton();
        }
    }

    /**
     * 本ダイアログを表示する
     */
    public open(): void {
        $(ProgressConfirmDialog.DIALOG_ID + " .modal-content").resizable({ disabled: true }); // 本ダイアログをリサイズ不可能にする
        $(ProgressConfirmDialog.DIALOG_ID).modal({backdrop: 'static'}).addClass("in");
    }

    /**
     * 本ダイアログを非表示にする
     */
    public hide(): void {
        $(ProgressConfirmDialog.DIALOG_ID).modal("hide").addClass("out");
    }

    /**
     * タイトルを設定する
     * @param title 設定するタイトル文字列
     */
    public setTitle(title: string): void {
        $(ProgressConfirmDialog.TITLE_ID).text(title);
    }

    /**
     * メッセージを設定する
     * @param message 設定するメッセージ文字列
     */
    public setMessage(message: string): void {
        $(ProgressConfirmDialog.MESSAGE_ID).text(message);
    }

    /**
     * プログレスバーの進捗率を%で指定する
     * @param thePercentage パーセント(%) 0未満、または、100を超える値は無視する
     */
    public setPercentage(thePercentage: number): void {
        //Logger.debug("ProgressConfirmDialog.setPercentage(" + thePercentage + ") before percentage:" + beforeValue);

        if ((0 <= thePercentage) && (thePercentage <= 100)) {
            var afterValue = thePercentage.toString() + "%";
            $(ProgressConfirmDialog.PROGRESS_BAR_ID).css("width", afterValue);
            //Logger.debug("ProgressConfirmDialog.setPercentage(" + thePercentage + ")  after percentage:" + $(ProgressConfirmDialog.PROGRESS_BAR_ID).css("width"));
        }
    }

    /**
     * キャンセルボタンを表示する
     */
    public showCancelButton(): void {
        $(ProgressConfirmDialog.CANCEL_BUTTON_ID).show();
    }

    /**
     * キャンセルボタンを非表示にする
     */
    public hideCancelButton(): void {
        $(ProgressConfirmDialog.CANCEL_BUTTON_ID).hide();
    }

    /**
     * キャンセルボタンを押下可能にする
     */
    public enableCancelButton(): void {
        $(ProgressConfirmDialog.CANCEL_BUTTON_ID).removeAttr("disabled");
    }

    /**
     * キャンセルボタンを押下不可能にする
     */
    public disableCancelButton(): void {
        $(ProgressConfirmDialog.CANCEL_BUTTON_ID).attr("disabled", "");
    }
}
