import React from 'react';
import Axios from 'axios';
import { Input, FormGroup, Label } from 'reactstrap';

import * as fileUtils from '../../../utils/fileUtils';
import * as labels from '../../../constants/labels';
import { PROGRESSINTERVAL } from '../../../constants/constant';
import { BatStatusEnum } from '../../../constants/enums';
import { OutputInformationViewModel } from '../../../models/outputInformationViewModel';
import Popup from '../../../components/molecules/CMN/Popup/Popup';
import Spinner from '../../../components/atoms/Icon/Spinner';
import ExecutingCancel from '../../../components/molecules/CMN/ExecutingCancel/ExecutingCancel';
import AuditTargetManager, { AuditTargetManagerVo } from '../../organisms/A000/AuditTargetManager';
import { MasterKbn } from '../../../VKZ/core/constants/constant';
import * as constants from '../../../constants/constant';
import * as message from '../../../constants/message';
import { bindValueToMessage } from '../../../utils/messageUtils';
import { setIsLoading } from '../../../stores/NowLoading';

type ExcelAuditTargetOutputsettingProps = {
  activated: boolean;
  templateId: string;
  bmnTemplateId: string;
  filters?: string;
  headerLeft?: string;
  headerCenter?: string;
  headerRight?: string;
  footerLeft?: string;
  footerCenter?: string;
  footerRight?: string;
  serviceParams?: string;
  onClose: () => void;
  stNMonth: number;
  edNMonth: number;
  initAuditTarget?: AuditTargetManagerVo;
};

// URL
const baseUrl = '/api/v1/ExcelAuditTargetOutputPreference';
export const createUrl = [baseUrl, 'Create'].join('/');
export const fileResultUrl = [baseUrl, 'FileResult'].join('/');
export const fileUrl = [baseUrl, 'File'].join('/');

export class BmnListVo {
    // 部門リスト
    public bmncode?: Array<string>;
}

// 項目ラベルのCSS Class
const labelClass = 'text-lbl';
// 選択可能部門件数
const selLimitBmn: number = 100;

const ExcelAuditTargetOutputsetting: React.FC<ExcelAuditTargetOutputsettingProps> = props => {
  const defaultAuditTarget: AuditTargetManagerVo = props.initAuditTarget ?? new AuditTargetManagerVo;
 
  const [isPrinting, setIsPrinting] = React.useState(false);
  const [companyCode, setCompanyCode] = React.useState(false);
  const [companyName, setCompanyName] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState('');

  const [auditTarget, setAuditTarget] = React.useState<AuditTargetManagerVo>();
  const [initAuditTarget, setInitAuditTarget] = React.useState<AuditTargetManagerVo>(defaultAuditTarget);
  const [initFlg, setInitFlg] = React.useState(true);
  const [excelPopupServiceParams, setExcelPopupServiceParams] = React.useState('');
  const [disableFlg, setDisableFlg] = React.useState(false);
  const [tabindex, setTabIndex] = React.useState(0);
  const [tabIndexClose, setTabIndexClose] = React.useState(0);
  const [focusFlg, setFocusFlg] = React.useState(false);

  /** 画面のステータスよりビューモデルを作成します。 */
  const createViewModel = () => {
    const viewModel: OutputInformationViewModel = {
      TemplateId: getTemplateId(),
      HeaderLeft: props.headerLeft,
      HeaderCenter: props.headerCenter,
      HeaderRight: props.headerRight,
      FooterLeft: props.footerLeft,
      FooterCenter: props.footerCenter,
      FooterRight: props.footerRight,
      PassFlg: false,
      FilterFlg: false,
      ClientCodeFlg: companyCode,
      ClientNameFlg: companyName,
      ServiceParams: excelPopupServiceParams
    };

    return viewModel;
  };

  /**
   * 表示項目の初期化
   */
  const handleReset = () => {
    setIsPrinting(false);
    setDisableFlg(false);
    setCompanyCode(false);
    setCompanyName(false);
    setErrorMessage('');
    setTabIndex(0);
    setTabIndexClose(0);
    setInitFlg(true);
  };

  /** 出力ボタン押下時の処理 */
  const handleOnPrintClick = () => {

    if (excelPopupServiceParams == '') {
      return;
    }

    //監査対象にて部門選択時、エラーチェックを行う
    if (auditTarget?.target == MasterKbn.Bmn)
    {
      if (auditTargetCheck() == false) {
        return;
      }
    }

    setTabIndex(-1);
    setTabIndexClose(1);
    popupRef.current.focus();

    const viewModel = createViewModel();
    
    createOnPost(createUrl, viewModel);
    setIsPrinting(true);
    setDisableFlg(true);
    
  };

  /** 出力ボタン押下時の処理 */
  const auditTargetCheck = () => {
    let chkFlg = true;
    if (auditTarget?.Exception == constants.AUDITTARGETEXCEPTION_RANGEERR || auditTarget?.items == null) {
      //選択不正エラー
      setErrorMessage(bindValueToMessage(message.ExcelOutputSettingByMonth_Error_Month_InvalidSetting));
      chkFlg = false;
    } else {
      if (auditTarget?.items.length > selLimitBmn) {
        //部門選択オーバーエラー
        setErrorMessage(bindValueToMessage(message.Common_Error_TooManySelect, ["部門", selLimitBmn.toString()]));
        chkFlg = false;
      }
    }
    return chkFlg;
  };

  /** Excel帳票作成処理を実行 */
  const createOnPost = (url: string, viewModel: OutputInformationViewModel) => {
    Axios.post(url, { outputInformationViewModel: viewModel })
      .then(response => {
        fileResultOnPost(fileResultUrl, String(response.data));
      })
      .catch(error => {
        setIsPrinting(false);
        setDisableFlg(false);
        setTabIndex(0);
        setTabIndexClose(0);
        setErrorMessage(error.response.data.message);
      });
  };

  /** バッチの進捗確認を実行 */
  const fileResultOnPost = (url: string, guid: string) => {
    const encodedUrl = [url, encodeURI(guid.toString())].join('/');
    Axios.post(encodedUrl)
      .then(response => {
        if (BatStatusEnum.Processing == response.data) {
          window.setTimeout(fileResultOnPost, PROGRESSINTERVAL, url, guid);
        } else {
          fileOnPost(fileUrl, guid);
        }
      })
      .catch(error => {
        setIsPrinting(false);
        setDisableFlg(false);
        setTabIndex(0);
        setTabIndexClose(0);
        setErrorMessage(error.response.data.message);
      });
  };

  /** ファイルダウンロードを実行 */
  const fileOnPost = (url: string, guid: string) => {
    const encodedUrl = [url, encodeURI(guid.toString())].join('/');
    Axios.get(encodedUrl, { responseType: 'blob' })
      .then(response => {
        if (response.data != undefined) {
          fileUtils.downloadFileFromResponse(response);
        }

        setIsPrinting(false);
        setDisableFlg(false);
        setTabIndex(0);
        setTabIndexClose(0);

        setExcelPopupServiceParams(
              JSON.stringify({}));
        // ポップアップを閉じる
        props.onClose();
      })
      .catch(error => {
        setIsPrinting(false);
        setDisableFlg(false);
        setTabIndex(0);
        setTabIndexClose(0);
        setErrorMessage(error.response.data.message);
      });
  };

  const firstFocusElement = React.useRef<any>();
  const lastFocusElement = React.useRef<any>();
  const popupRef = React.useRef<any>();

  /** キーボード操作（キーダウン） */
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 9) {
      // タブキー押下時
      const target: HTMLElement = event.target as HTMLElement;

      if (target.tabIndex == 1 || (target.className == 'wj-form-control' && disableFlg)) {
                    
        // 最初のエレメントにフォーカス
        popupRef.current.focus();

        // 後のイベントをキャンセル
        event.preventDefault();
        event.stopPropagation();
      }
    }
  };

  /** 画面が表示された実行する処理 */
  React.useEffect(() => {
    if (props.activated) {
      // 表示項目のクリア
      handleReset();
    }

    // 画面表示完了後に実行
    setTimeout(() => {
      if (popupRef.current) {
        // ポップアップにキーダウンイベントのフック
        popupRef.current.addEventListener('keydown', handleKeyDown);
      }
      if (firstFocusElement.current) {
        firstFocusElement.current.focus();
      }
    });
    setFocusFlg(false);
    setInitAuditTarget(defaultAuditTarget);
  }, [props.activated]);

  /** 監査対象制御が変更されたら実行する処理 */
  const handleOnTransAuditTarget = (e: AuditTargetManagerVo) => {
    setIsLoading(true);
    setErrorMessage("");
    if (initFlg) {
      setInitAuditTarget(defaultAuditTarget);
      setInitFlg(false);
    }
      setAuditTarget(e);

      let bmncodeArray : Array<string> = [];

      e?.items?.forEach(f => {
          bmncodeArray.push(f.BmnCode ?? "");
      });

    setExcelPopupServiceParams(
      JSON.stringify({
        StNMonth: props.stNMonth,
        EdNMonth: props.edNMonth,
          AuditTarget: e?.target,
          HojyoList: e?.target == MasterKbn.CountingKmk ? null : bmncodeArray
      })
      );
    
    setIsLoading(false);
  };

  /** ExcelPopupServiceParamsが変更されたら実行する処理 */
  React.useEffect(() => {
    if (firstFocusElement.current) {
      if (!focusFlg) {
        firstFocusElement.current.focus();
        setFocusFlg(true);
      }
    }
  }, [excelPopupServiceParams]);

  /** 会社コードが変更された時の処理を実装 */
  const handleOnCompanyCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyCode(event.target.checked);
  };

  /** 会社名が変更された時の処理を実装 */
  const handleOnCompanyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(event.target.checked);
  };

  /** 閉じるボタン押下時の処理 */
  const handleOnCloseClick = () => {
    if (!isPrinting) {
      setExcelPopupServiceParams(
          JSON.stringify({}));
      props.onClose();
    }
  };

  const getEscClose = () => {
    return !isPrinting;
  };

  const printingLabel = isPrinting ? (
    <span>
      <span className='_spinner _rotation mr-2'>
        <Spinner className='icon-lg' />
      </span>
      {labels.AIKCMN001000002_FUNCTION_NAME_INOUTPUT}
    </span>
  ) : (
    <span className='text-danger'>{errorMessage}</span>
  );

    const getTemplateId = () => {
    if (auditTarget?.target === MasterKbn.Bmn) {
      return props.bmnTemplateId;
    } else {
      return props.templateId;
    }
  };

  const companyCheckboxGroup = (
    <FormGroup>
      <Label className={labelClass}>
        <b>{labels.AIKCMN001000002_INPUT_HEADER_COMPANYINFORMATION}</b>
      </Label>
      <div className='d-flex flex-row'>
        <FormGroup check>
          <Label check>
            <Input
              type='checkbox'
              name='companyCode'
              innerRef={firstFocusElement}
              checked={companyCode}
              onChange={handleOnCompanyCodeChange}
              disabled={isPrinting}
              tabIndex={tabindex}
            />
            {labels.AIKCMN001000002_INPUT_HEADER_COMPANYCODE}
          </Label>
        </FormGroup>
        <FormGroup check className='ml-3'>
          <Label check>
            <Input
              type='checkbox'
              name='companyName'
              checked={companyName}
              onChange={handleOnCompanyNameChange}
              disabled={isPrinting}
              tabIndex={tabindex}
            />
            {labels.AIKCMN001000002_INPUT_HEADER_COMPANYNAME}
          </Label>
        </FormGroup>
      </div>
        <div className='mb-3 d-flex flex-nowrap'>
          <AuditTargetManager
          name={'ExcelAuditTargetOutput-AuditTarget'}
          transmitSelectItems={handleOnTransAuditTarget}
          transmitBmnList={e => { }}
          applyCombo={false}
          applyRange={true}
          defaultSelectItem={initAuditTarget ?? defaultAuditTarget}
          disabled={disableFlg}
          tabIndex={tabindex}
          />
          </div>
    </FormGroup>
  )

  return (
    <Popup
      isOpen={props.activated}
      escClose={getEscClose()}
      onCloseClick={handleOnCloseClick}
      header={labels.AIKCMN001000002_FUNCTION_NAME}
      size='lg'
      closeIconTabIndex={tabIndexClose}
      closeIconRef={lastFocusElement}
      innerRef={popupRef}
    >
      {companyCheckboxGroup}
      <div className='d-flex justify-content-between'>
        <div>{printingLabel}</div>
        <div>
          <ExecutingCancel
            className='text-nowrap'
            executeLabel={labels.AIKCMN001000002_BUTTON_FUNCTION_OUTPUT}
            executeDisabled={isPrinting}
            executeTabIndex={tabindex}
            onExecuteClick={handleOnPrintClick}
            cancelLabel={labels.COMMON_BUTTON_FUNCTION_CLOSE}
            cancelDisabled={isPrinting}
            cancelTabIndex={tabindex}
            onCancelClick={handleOnCloseClick}
          />
        </div>
      </div>
    </Popup>
  );
};
export default ExcelAuditTargetOutputsetting;
