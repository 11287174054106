import { InterimClosingType } from "../../common/defines";
import { MonthInfo, MonthManager } from "../../common/usefuls/monthManager";
import { MonthSelector, MonthSelectorParam } from "../../controls/userControls/monthSelector";
import { MiddleKbn } from "../../core/constants/constant";
import { FinInfoViewModel } from "../../models/common/finInfoViewModel";
import { HasFinInfo } from "../../models/common/modelInterfaces";
import { PageInitializeParameter } from "../../pageController";
import { LinkButton } from "../component/button/linkButton";
import { HeaderBase } from "./headerBase";
import $ from 'jquery';
import jQuery from 'jquery'

/**
 * ヘッダー抽象クラス
 * ＜本クラスに含まれる共通部品＞
 * ・月選択コントロール
 * ・印刷ボタン
 * ・閉じるボタン
 */
export abstract class AbstractHeader extends HeaderBase {

    /** ヘッダー要素ID */
    protected headerId!: string;

    /** 月選択 */
    protected mMonthSelector!: MonthSelector;

    /** 印刷ボタン */
    private mPrintButton: LinkButton = new LinkButton("#head-print-button");

    /** 閉じるボタン */
    private mCloseButton: LinkButton = new LinkButton("#head-close-button");


    constructor(parameter: PageInitializeParameter) {
        super(parameter);
    }

    /**
     * このヘッダーが管理している月選択コントロールを取得する
     */
    public get monthSelector(): MonthSelector {
        return this.mMonthSelector;
    }

    /**
     * このヘッダーが管理している印刷ボタンを取得する
     */
    public get printButton(): LinkButton { return this.mPrintButton; }

    /**
     * このヘッダーが管理している閉じるボタンを取得する
     */
    public get closeButton(): LinkButton { return this.mCloseButton; }

    public initialize() {
        var finInfo = (<HasFinInfo>this.$scope.viewModel).FinInfo;

        // TODO: finInfo（会社決算情報）は必ず存在する前提のため、以下のif判定は削除する
        //if (finInfo === null) {
        if (finInfo) {
            //// 実装が整うまでのエラー回避のためダミーデータを作成
            //var msParam: mjs.acelink.vkz.controls.userControls.MonthSelectorParam = new mjs.acelink.vkz.controls.userControls.MonthSelectorParam();
            //msParam.closingDate = new Date(2016, 2, 31);
            //msParam.monthStartDay = 1;
            //msParam.startMonthIndex = 1;
            //msParam.enabledStartMonthIndex = 1;
            //msParam.committedMonthTypes = null;
            //msParam.multiSelect = this.$scope.monthSelectorMultiSelect;
            //msParam.useAfterClose = false;
            //msParam.interimClosingType = InterimClosingType.Quarter;
            //msParam.hasClosingOption = this.$scope.monthSelectorHasClosingOption;
            //this.mMonthSelector = <MonthSelector>new MonthSelector(msParam).initialize();
            //return;

            // 月選択コントロールの設定
            this.setMonthSelector(finInfo);
        }

        // 固定ヘッダーの横スクロールをブラウザのスクロールに同期させる
        if (document.getElementById(this.headerId) != null && $("#" + this.headerId).css("position") === "fixed") {
            jQuery(this.$window).on("scroll", () => {
                $("#" + this.headerId).css("left", -jQuery(this.$window).scrollLeft())
            })
        }
    }

    /**
     * 月選択コントロールを設定する。
     */
    protected setMonthSelector(finInfo: FinInfoViewModel) {
        var msParam: MonthSelectorParam = new MonthSelectorParam();
        var monthManager = new MonthManager(finInfo.KEdDate, finInfo.MStDay, this.convertInterimClosingType(finInfo.MiddleKbn), finInfo.MonthInfoList);
        msParam.closingDate = finInfo.KEdDate;
        msParam.monthStartDay = finInfo.MStDay;
        msParam.startMonthIndex = finInfo.MonthInfoList.singleOrNull((info: MonthInfo) => { return info.NMonth == 1 }).EndDate.getMonth() > 11 ? 0 : finInfo.MonthInfoList.singleOrNull((info: MonthInfo) => { return info.NMonth == 1 }).EndDate.getMonth();
        msParam.enabledStartMonthIndex = monthManager.toStandardMonthFromInner(finInfo.StrMonth) - 1;
        msParam.committedMonthTypes = finInfo.FixMonths.select((m: number) => monthManager.toMonthTypeFromInner(m));
        msParam.multiSelect = this.$scope.monthSelectorMultiSelect;
        msParam.useAfterClose = finInfo.KStDate.getDate() != finInfo.MStDay;
        msParam.interimClosingType = finInfo.MiddleKbn == MiddleKbn.None ? InterimClosingType.Year : finInfo.MiddleKbn == MiddleKbn.HalfPeriod ? InterimClosingType.Interim : InterimClosingType.Quarter;
        msParam.hasClosingOption = this.$scope.monthSelectorHasClosingOption;
        msParam.monthInfoList = finInfo.MonthInfoList;
        this.mMonthSelector = <MonthSelector>new MonthSelector(msParam).initialize();
    }

    /**
     * MiddleKbnをMonthManagerに渡すInterimClosingTypeに変換する
     */
    protected convertInterimClosingType(middleKbn: MiddleKbn): InterimClosingType {

        switch (middleKbn) {
            case MiddleKbn.None:
                return InterimClosingType.Year;
            case MiddleKbn.HalfPeriod:
                return InterimClosingType.Interim;
            case MiddleKbn.QuarterPeriod:
                return InterimClosingType.Quarter;

        }
        return InterimClosingType.None;
    }
}
