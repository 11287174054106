/**
 * マスタ区分
 */
export class MasterKbn {

    /** なし */
    static readonly None = 0;

    /** 勘定科目 */
    static readonly CountingKmk = 1;

    /** 科目別補助 */
    static readonly EachKmkAuxiliary = 2;

    /** 資金繰 */
    static readonly Financing = 3;

    /** 要約B/S */
    static readonly SummaryBS = 4;

    /** 要約P/L */
    static readonly SummaryPL = 5;

    /** 固定変動 */
    static readonly FixedFluctuation = 6;

    /** タイトル科目 */
    static readonly TitleKmk = 7;

    /** 工事合計 */
    static readonly KojiTotal = 8;

    /** 工事集計科目 */
    static readonly KojiTotalKmk = 9;

    /** 分析科目 */
    static readonly AnalysisKmk = 10;

    /** C/F（直接法） */
    static readonly CFDirect = 11;

    /** C/F（間接法） */
    static readonly CFIndirection = 12;

    /** 要約増減明細 */
    static readonly SummaryFluctuationDetails = 13;

    /** 会計科目 */
    static readonly AccountingKmk = 14;

    /** 会計補助科目 */
    static readonly AccountingHojyoKmk = 15;

    /** 部門元帳合計科目 */
    static readonly BmnTotalKmk = 16;

    /** 補助元帳合計科目 */
    static readonly HojyoTotalKmk = 17;

    /** プロジェクト元帳合計 */
    static readonly ProjectTotalSubject = 18;

    /** 銀行 */
    static readonly Bank = 21;

    /** 取引先 */
    static readonly Client = 22;

    /** 得意先 */
    static readonly Customers = 23;

    /** 仕入先 */
    static readonly Supplier = 24;

    /** 社員 */
    static readonly Staff = 25;

    /** 汎用補助1 */
    static readonly GeneralPurposeAuxiliary1 = 31;

    /** 汎用補助2 */
    static readonly GeneralPurposeAuxiliary2 = 32;

    /** 汎用補助3 */
    static readonly GeneralPurposeAuxiliary3 = 33;

    /** 汎用補助4 */
    static readonly GeneralPurposeAuxiliary4 = 34;

    /** 汎用補助5 */
    static readonly GeneralPurposeAuxiliary5 = 35;

    /** 商品 */
    static readonly Goods = 36;

    /** 部門 */
    static readonly Bmn = 41;

    /** セグメント1 */
    static readonly Segment1 = 42;

    /** セグメント2 */
    static readonly Segment2 = 43;

    /** セグメント3 */
    static readonly Segment3 = 44;

    /** セグメント4 */
    static readonly Segment4 = 45;

    /** セグメント5 */
    static readonly Segment5 = 46;

    /** 部署・担当者グループ */
    static readonly DepartmentPersonGroup = 47;

    /** 工事 */
    static readonly Koji = 51;

    /** 工種 */
    static readonly KojiType = 52;

    /** 工事分類 */
    static readonly KojiClassification = 54;

    /** 固定摘要 */
    static readonly FixingSummary = 61;

    /** 仕訳辞書摘要 */
    static readonly SwkRemarks = 62;

    /** 分類1 */
    static readonly Classification1 = 71;

    /** 分類2 */
    static readonly Classification2 = 72;

    /** 分類3 */
    static readonly Classification3 = 73;

    /** 分類4 */
    static readonly Classification4 = 74;

    /** 分類5 */
    static readonly Classification5 = 75;
}

/**
 * 対象外内部月
 */
export class NotCoveredNMonth {

    /** システム対象外 */
    static readonly SystemNotCovered = -1;
}

/**
 * 内部月
 */
export class NMonth {

    /** 0月 */
    static readonly StartMonth = 0;

    /** 内部月1 */
    static readonly NMonth1 = 1;

    /** 内部月2 */
    static readonly NMonth2 = 2;

    /** 内部月3 */
    static readonly NMonth3 = 3;

    /** 第1四半期締後 */
    static readonly FirstQuarterAfterTightening = 4;

    /** 第1四半期決算1 */
    static readonly FirstQuarterClosing1 = 5;

    /** 第1四半期決算2 */
    static readonly FirstQuarterClosing2 = 6;

    /** 第1四半期決算3 */
    static readonly FirstQuarterClosing3 = 7;

    /** 内部月11 */
    static readonly NMonth11 = 11;

    /** 内部月12 */
    static readonly NMonth12 = 12;

    /** 内部月13 */
    static readonly NMonth13 = 13;

    /** 第2四半期締後 */
    static readonly SecondQuarterAfterTightening = 14;

    /** 第2四半期決算1 */
    static readonly SecondQuarterClosing1 = 15;

    /** 第2四半期決算2 */
    static readonly SecondQuarterClosing2 = 16;

    /** 第2四半期決算3 */
    static readonly SecondQuarterClosing3 = 17;

    /** 内部月21 */
    static readonly NMonth21 = 21;

    /** 内部月22 */
    static readonly NMonth22 = 22;

    /** 内部月23 */
    static readonly NMonth23 = 23;

    /** 第3四半期締後 */
    static readonly ThirdQuarterAfterTightening = 24;

    /** 第3四半期決算1 */
    static readonly ThirdQuarterClosing1 = 25;

    /** 第3四半期決算2 */
    static readonly ThirdQuarterClosing2 = 26;

    /** 第3四半期決算3 */
    static readonly ThirdQuarterClosing3 = 27;

    /** 内部月31 */
    static readonly NMonth31 = 31;

    /** 内部月32 */
    static readonly NMonth32 = 32;

    /** 内部月33 */
    static readonly NMonth33 = 33;

    /** 第4四半期締後 */
    static readonly FourthQuarterAfterTightening = 34;

    /** 第4四半期決算1 */
    static readonly FourthQuarterClosing1 = 35;

    /** 第4四半期決算2 */
    static readonly FourthQuarterClosing2 = 36;

    /** 第4四半期決算3 */
    static readonly FourthQuarterClosing3 = 37;

    /** 来期内部月1 */
    static readonly NextTermNMonth1 = 41;

    /** 来期内部月2 */
    static readonly NextTermNMonth2 = 42;

    /** 来期内部月3 */
    static readonly NextTermNMonth3 = 43;

    /** 来期第1四半期締後 */
    static readonly NextTermFirstQuarterAfterTightening = 44;

    /** 来期第1四半期決算1 */
    static readonly NextTermFirstQuarterClosing1 = 45;

    /** 来期第1四半期決算2 */
    static readonly NextTermFirstQuarterClosing2 = 46;

    /** 来期第1四半期決算3 */
    static readonly NextTermFirstQuarterClosing3 = 47;

    /** 来期内部月11 */
    static readonly NextTermNMonth11 = 51;

    /** 来期内部月12 */
    static readonly NextTermNMonth12 = 52;

    /** 来期内部月13 */
    static readonly NextTermNMonth13 = 53;

    /** 来期第2四半期締後 */
    static readonly NextTermSecondQuarterAfterTightening = 54;

    /** 来期第2四半期決算1 */
    static readonly NextTermSecondQuarterClosing1 = 55;

    /** 来期第2四半期決算2 */
    static readonly NextTermSecondQuarterClosing2 = 56;

    /** 来期第2四半期決算3 */
    static readonly NextTermSecondQuarterClosing3 = 57;

    /** 来期内部月21 */
    static readonly NextTermNMonth21 = 61;

    /** 来期内部月22 */
    static readonly NextTermNMonth22 = 62;

    /** 来期内部月23 */
    static readonly NextTermNMonth23 = 63;

    /** 来期第3四半期締後 */
    static readonly NextTermThirdQuarterAfterTightening = 64;

    /** 来期第3四半期決算1 */
    static readonly NextTermThirdQuarterClosing1 = 65;

    /** 来期第3四半期決算2 */
    static readonly NextTermThirdQuarterClosing2 = 66;

    /** 来期第3四半期決算3 */
    static readonly NextTermThirdQuarterClosing3 = 67;

    /** 来期内部月31 */
    static readonly NextTermNMonth31 = 71;

    /** 来期内部月32 */
    static readonly NextTermNMonth32 = 72;

    /** 来期内部月33 */
    static readonly NextTermNMonth33 = 73;

    /** 来期第4四半期締後 */
    static readonly NextTermFourthQuarterAfterTightening = 74;

    /** 来期第4四半期決算1 */
    static readonly NextTermFourthQuarterClosing1 = 75;

    /** 来期第4四半期決算2 */
    static readonly NextTermFourthQuarterClosing2 = 76;

    /** 来期第4四半期決算3 */
    static readonly NextTermFourthQuarterClosing3 = 77;

    /** 通年翌期の排他 */
    static readonly NextTermExc = 90;

    /** 通年当期の排他 */
    static readonly CurrentTermExc = 91;

    /** 通年翌期 */
    static readonly NextTerm = 98;

    /** 通年当期 */
    static readonly CurrentTerm = 99;

    /** 工事予算内部月 */
    static readonly KojiBudgetInnerMonth = 99;
}

/**
 * 法人区分
 */
export class DTMAINCorpKbn {

    /** 法人基本 */
    static readonly Corporation = 1;

    /** 医療法人 */
    static readonly Medical = 2;

    /** 工事Ｂ／Ｓ */
    static readonly KojiBS = 3;

    /** 工事Ｐ／Ｌ */
    static readonly KojiPL = 4;

    /** 個人 */
    static readonly Personal = 5;

    /** 医療個人 */
    static readonly PersonalMedical = 6;

    /** 個人不動産 */
    static readonly PersonalEstate = 7;

    /** 運送業法人 */
    static readonly CarryingTrade = 8;

    /** 農業 */
    static readonly Agriculture = 9;
}

/**
 * 和暦／西暦区分
 */
export class UserInfoYearKbn {

    /** 和暦 */
    static readonly JPCalendar = 0;

    /** 西暦 */
    static readonly ADCalendar = 1;
}

/**
 * 中間決算区分
 */
export class MiddleKbn {

    /** なし */
    static readonly None = 0;

    /** 半期決算 */
    static readonly HalfPeriod = 1;

    /** 四半期決算 */
    static readonly QuarterPeriod = 2;
}

/**
 * 中間決算月の集計方法
 */
export class MCalcKbn {

    /** 下期の集計に含まない */
    static readonly NotIncHalf = 0;

    /** 下期の集計に含む */
    static readonly IncHalf = 1;
}

/**
 * データ区分（発生区分）
 */
export class DataKbn {

    /** 会計事務所（本店） */
    static readonly OfficeMainStore = 0;

    /** 顧問先（支店） */
    static readonly ClientBranchStore = 1;

    /** 全店 */
    static readonly AllUnit = 2;

    /** 部署 */
    static readonly Department = 3;
}

/**
 * メール会計区分
 */
export class MailAccountKbn {

    /** なし */
    static readonly None = 0;

    /** メール会計 */
    static readonly MailAccount = 1;
}

/**
 * 公益法人区分
 */
export class CharitableKbn {

    /** 一般法人 */
    static readonly General = 0;

    /** 公益法人 */
    static readonly Charitable = 1;

    /** 学校法人 */
    static readonly School = 2;

    /** 宗教法人 */
    static readonly Religious = 3;

    /** 社会福祉法人 */
    static readonly Social = 4;

    /** NPO法人 */
    static readonly NPO = 5;

    /** 病院財務 */
    static readonly Hospital = 8;
}

/**
 * 複合・資金複合区分
 */
export class DenKbn {

    /** 複合 */
    static readonly Composite = 0;

    /** 資金複合 */
    static readonly FundComplex = 1;
}

/**
 * 伝票印刷レイアウトＮＯ
 */
export class DRayNo {

    /** 標準 */
    static readonly Standard = 0;

    /** コクヨ振替伝票（白紙3連） */
    static readonly Kokuyo3 = 1;

    /** コクヨ振替伝票（白紙2連） */
    static readonly Kokuyo2 = 2;

    /** コクヨ振替伝票（ドット連帳） */
    static readonly KokuyoDot = 3;

    /** コクヨ振替伝票（白紙2連・番号あり） */
    static readonly Kokuyo2Number = 4;

    /** ミロク振替伝票（白紙2連） */
    static readonly Miroku2 = 5;

    /** ミロク振替伝票（白紙2連・番号あり） */
    static readonly Miroku2Number = 6;

    /** 入金伝票(白紙2連) */
    static readonly PayingSlip2 = 7;

    /** 出金伝票(白紙2連) */
    static readonly DebitSlip2 = 8;

    /** コクヨ振替伝票(白紙2連・展開科目) */
    static readonly Kokuyo2DevelopKmk = 9;

    /** ミロク振替伝票(白紙2連・展開科目) */
    static readonly Miroku2DevelopKmk = 10;

    /** 入金伝票(白紙3連) */
    static readonly PayingSlip3 = 11;

    /** 出金伝票(白紙3連) */
    static readonly DebitSlip3 = 12;

    /** フリー帳票 */
    static readonly FreeSlip = 101;
}

/**
 * 査印欄の大きさ
 */
export class DenInfoBoxSize {

    /** 標準（大） */
    static readonly Large = 0;

    /** 中 */
    static readonly Medium = 2;

    /** 小 */
    static readonly Small = 1;
}

/**
 * 査印タイトル文字方向
 */
export class BoxKbn {

    /** 横書き */
    static readonly Horizontal = 0;

    /** 縦書き */
    static readonly Vertical = 1;
}

/**
 * 補助区分
 */
export class HojyoKbn {

    /** 設定なし・標準 */
    static readonly None = 0;

    /** 科目 */
    static readonly Kmk = 1;

    /** 科目別補助 */
    static readonly EachKmkAuxiliary = 2;

    /** 銀行 */
    static readonly Bank = 21;

    /** 取引先 */
    static readonly Client = 22;

    /** 得意先 */
    static readonly Customers = 23;

    /** 仕入先 */
    static readonly Supplier = 24;

    /** 社員 */
    static readonly Staff = 25;

    /** 汎用補助1 */
    static readonly GeneralPurposeAuxiliary1 = 31;

    /** 汎用補助2 */
    static readonly GeneralPurposeAuxiliary2 = 32;

    /** 汎用補助3 */
    static readonly GeneralPurposeAuxiliary3 = 33;

    /** 汎用補助4 */
    static readonly GeneralPurposeAuxiliary4 = 34;

    /** 汎用補助5 */
    static readonly GeneralPurposeAuxiliary5 = 35;

    /** 部門 */
    static readonly Bmn = 41;

    /** セグメント1 */
    static readonly Segment1 = 42;

    /** セグメント2 */
    static readonly Segment2 = 43;

    /** セグメント3 */
    static readonly Segment3 = 44;

    /** セグメント4 */
    static readonly Segment4 = 45;

    /** セグメント5 */
    static readonly Segment5 = 46;

    /** 工事（原価） */
    static readonly Koji = 51;

    /** 工種 */
    static readonly KojiType = 52;

    /** 固定摘要 */
    static readonly FixingSummary = 61;

    /** 科目別補助別固定摘要 */
    static readonly EachKmkKmkFixingSummary = 62;

    /** 銀行別固定摘要 */
    static readonly EachBankFixingSummary = 71;

    /** 取引先別固定摘要 */
    static readonly EachClientFixingSummary = 72;

    /** 社員別固定摘要 */
    static readonly EachStaffFixingSummary = 75;

    /** 汎用補助1別固定摘要 */
    static readonly GeneralPurposeAuxiliary1FixingSummary = 81;

    /** 汎用補助2別固定摘要 */
    static readonly GeneralPurposeAuxiliary2FixingSummary = 82;

    /** 汎用補助3別固定摘要 */
    static readonly GeneralPurposeAuxiliary3FixingSummary = 83;

    /** 汎用補助4別固定摘要 */
    static readonly GeneralPurposeAuxiliary4FixingSummary = 84;

    /** 汎用補助5別固定摘要 */
    static readonly GeneralPurposeAuxiliary5FixingSummary = 85;
}

/**
 * 配賦採用区分
 */
export class AllocationAdoptionKbn {

    /** なし */
    static readonly None = 0;

    /** あり（マスタ転記） */
    static readonly SetMasterTransfer = 1;

    /** あり（自動仕訳） */
    static readonly SetAutomaticSwk = 2;
}

/**
 * コード属性
 */
export class CodeAttr {

    /** 数字 */
    static readonly Number = 0;

    /** 数字（前ゼロあり） */
    static readonly NumberZeroFill = 1;

    /** フリー */
    static readonly Free = 2;
}

/**
 * 複合自動ｾｯﾄ区分
 */
export class ComplexAutomaticSetKbn {

    /** なし */
    static readonly None = 0;

    /** 基準割合を超える科目（2つ以上ある時はｾｯﾄしない） */
    static readonly BeyondCriteriaKmk = 1;

    /** 基準割合を超える先頭科目 */
    static readonly BeyondCriteriaTopKmk = 2;

    /** 最大金額の科目 */
    static readonly MaxKmk = 3;
}

/**
 * 資金繰自動ｾｯﾄ区分
 */
export class CashAutomaticSetKbn {

    /** なし */
    static readonly None = 0;

    /** 基準割合を超える科目（2つ以上ある時はｾｯﾄしない） */
    static readonly BeyondCriteriaKmk = 1;

    /** 基準割合を超える先頭科目 */
    static readonly BeyondCriteriaTopKmk = 2;

    /** 最大金額の科目 */
    static readonly MaxKmk = 3;
}

/**
 * レコード区分
 */
export class RecordKbn {

    /** 正規レコード */
    static readonly Regular = 0;

    /** 削除レコード */
    static readonly Delete = 1;

    /** 一時レコード */
    static readonly Temporary = 9;
}

/**
 * 採用レコード区分
 */
export class PartitionRecordKbn {

    /** 採用区分 */
    static readonly Partition = 1;

    /** 仕訳入力条件 */
    static readonly SwkInputCondition = 2;

    /** 印刷条件 */
    static readonly PrintCondition = 3;

    /** メール会計（事務所、本店） */
    static readonly MailAccountOffice = 4;

    /** ﾒｰﾙ会計（顧問先、支店） */
    static readonly MailAccountClient = 5;

    /** メール会計（未使用） */
    static readonly MailAccountUnused = 6;

    /** 分散入力 */
    static readonly AccApproveSwk = 8;

    /** 部署入力 */
    static readonly DepartmentInput = 9;

    /** 部署参照 */
    static readonly DepartmentReference = 10;

    /** 電子帳簿 */
    static readonly EBookObject = 11;

    /** キャッシュフロー */
    static readonly Cashflow = 12;

    /** 公益法人関係 */
    static readonly Charitable = 13;

    /** 部署入力Assist */
    static readonly DepartmentInputAssist = 14;

    /** 本支店付替 */
    static readonly MainAndBranchOfficesReplacement = 15;

    /** 工事基本情報 */
    static readonly KojiBasisInfo = 16;

    /** 担当者部門制限 */
    static readonly PersonBmnRestriction = 19;

    /** セグメント拡張 */
    static readonly SegmentExpansion = 17;

    /** 伺書 */
    static readonly RequestForm = 21;

    /** 仕訳入力条件2 */
    static readonly SwkInputCondition2 = 23;

    /** 消費税 */
    static readonly ConsumptionTax = 24;

    /** NX-CEかんたん！法人会計 */
    static readonly NXCEKantan = 25;

    /** 監査データ差分 */
    static readonly AuditorData = 26;

    /** 税効果 */
    static readonly TaxEffect = 99;

    /** 利益計画 */
    static readonly ProfitPlan = 20;
}

/**
 * 残期間出力区分
 */
export class OutputPtnZanKbn {

    /** なし */
    static readonly None = 0;

    /** 前年 */
    static readonly LastYear = 1;

    /** 前々年 */
    static readonly TwoYearsBefore = 2;

    /** 予算1 */
    static readonly Budget1 = 11;

    /** 予算2 */
    static readonly Budget2 = 12;

    /** 予算3 */
    static readonly Budget3 = 13;

    /** 予算4 */
    static readonly Budget4 = 14;

    /** 期首 */
    static readonly BeginningTerm = 94;
}

/**
 * ＢＳ／ＰＬ区分
 */
export class BPKbn {

    /** Ｂ／Ｓ */
    static readonly BS = 0;

    /** Ｐ／Ｌ */
    static readonly PL = 1;
}

/**
 * 表示／印刷不可区分
 */
export class NonDspKbn {

    /** 可 */
    static readonly Permit = 0;

    /** 不可 */
    static readonly Improper = 1;
}

/**
 * 比率科目区分
 */
export class PerKmkKbn {

    /** 通常科目 */
    static readonly Normal = 0;

    /** 比率科目 */
    static readonly Ratio = 1;
}

/**
 * 固定体系区分
 */
export class KoteiKbn {

    /** 任意 */
    static readonly Any = 0;

    /** 固定 */
    static readonly Kotei = 1;

    /** 固定2 */
    static readonly Kotei2 = 2;

    /** 固定3 */
    static readonly Kotei3 = 3;
}

/**
 * 消費税科目情報区分
 */
export class KmkMATaxKbn {

    /** 対象外 */
    static readonly NonTarget = 0;

    /** 売上科目 */
    static readonly Sales = 1;

    /** 仕入科目 */
    static readonly JournalizeKmk = 2;
}

/**
 * 元帳出力消費税科目区分
 */
export class OutputPtnMotoKbn {

    /** 仮受消費税 */
    static readonly ProvisionalReceptionTax = 0;

    /** 仮払消費税 */
    static readonly ProvisionalPaymentTax = 1;

    /** 両方 */
    static readonly Both = 2;
}

/**
 * 売上／仕入／消費税区分
 */
export class STaxKbn {

    /** なし */
    static readonly None = 0;

    /** 売上 */
    static readonly Sales = 1;

    /** 仕入 */
    static readonly Journalize = 2;
}

/**
 * 売上消費税コード/消費税コード
 */
export class STaxCode {

    /** なし */
    static readonly None = 0;

    /** 課税売上(内税) */
    static readonly TaxationSalesIncluded = 10;

    /** 課税売上控除(内税) */
    static readonly TaxationSalesSubtractionIncluded = 12;

    /** 課税貸倒償却(内税) */
    static readonly TaxationSalesLoanlossRepaymentIncluded = 13;

    /** 課税貸倒回収(内税) */
    static readonly TaxationSalesLoanlossCollectionIncluded = 15;

    /** 課税売上(外税) */
    static readonly TaxationSalesExcluded = 20;

    /** 課税売上控除(外税) */
    static readonly TaxationSalesSubtractionExcluded = 22;

    /** 課税貸倒償却(外税) */
    static readonly TaxationSalesLoanlossRepaymentExcluded = 23;

    /** 課税貸倒回収(外税) */
    static readonly TaxationSalesLoanlossCollectionExcluded = 25;

    /** 非課税売上 */
    static readonly ExemptionSales = 30;

    /** 有価証券売上等(非課税) */
    static readonly ValuablePapersSalesExemption = 34;

    /** 不課税売上(精算取引) */
    static readonly TaxationLessnessSales = 40;

    /** 輸出売上 */
    static readonly ExportSales = 60;

    /** 非課税輸出売上 */
    static readonly ExemptionExportSales = 64;

    /** 課税仕入対応特定収入 */
    static readonly TaxationJournalizeSpecific = 80;

    /** 共通仕入対応特定収入 */
    static readonly CommonJournalizeSpecific = 81;

    /** 非課税仕入対応特定収入 */
    static readonly ExemptionJournalizeSpecific = 84;

    /** 使途不特定の特定収入 */
    static readonly UseObscuritySpecific = 89;

    /** 不明 */
    static readonly Unclear = 99;

    /** 課税取引(内税) */
    static readonly TaxationDealIncluded = 10;

    /** 課税取引(外税) */
    static readonly TaxationDealExcluded = 20;

    /** 非課税取引 */
    static readonly ExemptionDeal = 30;

    /** 不課税取引(精算取引) */
    static readonly TaxationLessnessDeal = 40;
}

/**
 * 仕入消費税コード
 */
export class BTaxCode {

    /** なし */
    static readonly None = 0;

    /** 課税仕入(内税) */
    static readonly TaxationJournalizeIncluded = 10;

    /** 共通仕入(内税) */
    static readonly CommonJournalizeIncluded = 11;

    /** 課税仕入控除(内税) */
    static readonly TaxationJournalizeSubtractionIncluded = 12;

    /** 非課税売上対応仕入(内税) */
    static readonly ExemptionSalseJournalizeIncluded = 14;

    /** 共通仕入控除(内税) */
    static readonly CommonJournalizeSubtractionIncluded = 15;

    /** 非課税売上対応仕入控除(内税) */
    static readonly ExemptionSalseJournalizeSubtractionIncluded = 16;

    /** 課税仕入(外税) */
    static readonly TaxationJournalizeExcluded = 20;

    /** 共通仕入(外税) */
    static readonly CommonJournalizeExcluded = 21;

    /** 課税仕入控除(外税) */
    static readonly TaxationJournalizeSubtractionExcluded = 22;

    /** 非課税売上対応仕入(外税) */
    static readonly ExemptionSalseJournalizeExcluded = 24;

    /** 共通仕入控除(外税) */
    static readonly CommonJournalizeSubtractionExcluded = 25;

    /** 非課税売上対応仕入控除(外税) */
    static readonly ExemptionSalseJournalizeSubtractionExcluded = 26;

    /** 非課税仕入 */
    static readonly ExemptionJournalize = 30;

    /** 不課税仕入(精算取引) */
    static readonly TaxationLessnessStockingCleaningContract = 40;

    /** 棚卸調整消費税(課税) */
    static readonly StocktakingAdjustTaxation = 50;

    /** 棚卸調整消費税(共通) */
    static readonly StocktakingAdjustConsumptionTax = 51;

    /** 棚卸調整消費税(非課税) */
    static readonly StocktakingAdjustExemption = 54;

    /** 輸入仕入(外税) */
    static readonly ImportJournalizeExcluded = 60;

    /** 輸入共通仕入(外税) */
    static readonly ImportCommonJournalizeExcluded = 61;

    /** 非課税売上対応輸入(外税) */
    static readonly JournalizeCotCode64 = 64;

    /** 輸入仕入(地方消費税) */
    static readonly ImportJournalizeConsumptionTax = 65;

    /** 輸入共通仕入(地方消費税) */
    static readonly ImportCommonJournalizeLocalConsumptionTax = 66;

    /** 非課税売上対応輸入(地方) */
    static readonly ExemptionJournalizeImport = 69;

    /** 輸入仕入(内税) */
    static readonly ImportJournalizeIncluded = 70;

    /** 輸入共通仕入(内税) */
    static readonly ImportCommonJournalizeIncluded = 71;

    /** 非課税売上対応輸入(内税) */
    static readonly ExemptionSalseImportIncluded = 74;

    /** 課税売上対応特定課税仕入 */
    static readonly TaxationSalesSpecificJournalize = 80;

    /** 課税非課税売上対応特定課税仕入 */
    static readonly TaxationExemptionSalesSpecificJournalize = 81;

    /** 課税売上対応特定課税仕入の対価の返還等 */
    static readonly TaxationSalesSpecificJournalizeReturn = 82;

    /** 非課税売上対応特定課税仕入 */
    static readonly ExemptionSalseImportSpecificTaxationJournalize = 84;

    /** 課税非課税売上対応特定課税仕入の対価の返還等 */
    static readonly TaxationExemptionSalesSpecificJournalizeReturn = 85;

    /** 非課税売上対応特定課税仕入の対価の返還等 */
    static readonly ExemptionSalseImportSpecificTaxationJournalizeReturn = 86;

    /** 不明 */
    static readonly Unclear = 99;
}

/**
 * 消費税率区分
 */
export class TaxRate {

    /** なし */
    static readonly None = 0;

    /** 標準税率 */
    static readonly StandardTaxRate = 0;

    /** デフォルト（科目設定に従う） */
    static readonly DefaultOption = 0;

    /** 総額 */
    static readonly TotalAmount = 0;

    /** 0.03 */
    static readonly ThreePercent = 1;

    /** 0.05 */
    static readonly FivePercent = 2;

    /** 0.08 */
    static readonly EightPercent = 3;

    /** 0.1 */
    static readonly TenPercent = 4;

    /** 8%軽減 */
    static readonly ReducedEightPercent = 5;

    /** 不課税 */
    static readonly TaxationLessness = 8;

    /** 非課税 */
    static readonly Exemption = 9;

    /** 4%(国税分) */
    static readonly FourPercent = 101;

    /** 1%(地方税分)  */
    static readonly OnePercent = 102;

    /** 6.3%(国税分) */
    static readonly SixPointThreePercent = 103;

    /** 1.7%(地方税分)  */
    static readonly OnePointSevenPercent = 104;

    /** 7.8%(国税分) */
    static readonly SevenPointEightPercent = 105;

    /** 2.2%(地方税分) */
    static readonly TwoPointTwoPercent = 106;

    /** 6.24%(国税分) */
    static readonly SixPointTwoFourPercent = 107;

    /** 1.76%(地方税分) */
    static readonly OnePointSevenSixPercent = 108;

    /** その他 */
    static readonly Other = -1;

    /** 他社データ移行デフォルト自動設定 */
    static readonly MigrationDefaultAutoSet = -2;
}

/**
 * 業種コード
 */
export class TypeCode {

    /** 基準業種または科目設定に従う */
    static readonly StandardOrDefault = 0;

    /** 第1種事業 */
    static readonly FirstProject = 1;

    /** 第2種事業 */
    static readonly SecondProject = 2;

    /** 第3種事業 */
    static readonly ThirdProject = 3;

    /** 第4種事業 */
    static readonly FourthProject = 4;

    /** 第5種事業 */
    static readonly FifthProject = 5;

    /** 第6種事業 */
    static readonly SixthProject = 6;
}

/**
 * 仕訳入力区分
 */
export class InputKbn {

    /** 貸借入力 */
    static readonly DebitCredit = 0;

    /** 正残側のみ */
    static readonly PositiveBalance = 1;

    /** 負残側のみ */
    static readonly NegativeBalance = 2;

    /** 入力不可 */
    static readonly ImpossibleToInput = 9;
}

/**
 * 期日入力区分
 */
export class DInputKbn {

    /** 入力なし */
    static readonly None = 0;

    /** 正残側のみ */
    static readonly PositiveBalance = 1;

    /** 貸借入力 */
    static readonly DebitCredit = 2;

    /** 負残側のみ */
    static readonly NegativeBalance = 3;
}

/**
 * 固定摘要必須入力
 */
export class TkInputKbn {

    /** 任意 */
    static readonly Any = 0;

    /** 固定摘要必須 */
    static readonly MustFixingSummary = 1;

    /** 摘要残必須 */
    static readonly MustRemainingSummary = 2;
}

/**
 * 資金繰コード優先区分
 */
export class CashCodeKbn {

    /** 科目優先 */
    static readonly KmkPriority = 0;

    /** 補助優先 */
    static readonly AuxiliaryPriority = 1;
}

/**
 * 必須入力区分
 */
export class MustInputKbn {

    /** 任意入力 */
    static readonly Any = 0;

    /** 必須入力 */
    static readonly Must = 1;
}

/**
 * 出力情報装飾区分
 */
export class OutputDecoKbn {

    /** なし */
    static readonly OutputDecoKbn1 = 0;

    /** 【 】 */
    static readonly OutputDecoKbn2 = 1;

    /** （ ） */
    static readonly OutputDecoKbn3 = 2;

    /** 〔 〕 */
    static readonly OutputDecoKbn4 = 3;

    /** ＜ ＞ */
    static readonly OutputDecoKbn5 = 4;

    /** (( )) */
    static readonly OutputDecoKbn6 = 5;

    /** ｛ ｝ */
    static readonly OutputDecoKbn7 = 6;
}

/**
 * 名称出力装飾区分
 */
export class NameDecoKbn {

    /** 科目に従う  */
    static readonly NameDecoKbn1 = 0;

    /** 【 】 */
    static readonly NameDecoKbn2 = 1;

    /** （ ） */
    static readonly NameDecoKbn3 = 2;

    /** 〔 〕 */
    static readonly NameDecoKbn4 = 3;

    /** ＜ ＞ */
    static readonly NameDecoKbn5 = 4;

    /** (( )) */
    static readonly NameDecoKbn6 = 5;

    /** ｛ ｝ */
    static readonly NameDecoKbn7 = 6;

    /** なし */
    static readonly NameDecoKbn8 = 99;

    /** なし(コンボボックス表示用) */
    static readonly NameDecoKbn9 = 7;
}

/**
 * 集計単位区分
 */
export class TotalKbn {

    /** 指定した単位で集計 */
    static readonly DesignationUnit = 0;

    /** 無視する */
    static readonly Ignoring = 1;
}

/**
 * 履歴管理区分
 */
export class HisKbn {

    /** 修正履歴管理区分に従う */
    static readonly FollowHistoryManagement = 0;

    /** 無条件に履歴を取る */
    static readonly TakeHistoryUnconditional = 1;
}

/**
 * 配賦適用区分
 */
export class HaifuMCUseKbn {

    /** 年間 */
    static readonly ByYear = 0;

    /** 月別 */
    static readonly ByMonth = 1;
}

/**
 * ウィンドウ表示適用区分
 */
export class WinDspInfoAppKbn {

    /** 会計事務所（共通） */
    static readonly CountinghouseCommon = 1;

    /** 会計事務所（担当者別） */
    static readonly CountinghoAccording = 2;

    /** 顧問先（共通） */
    static readonly ClientCommon = 11;

    /** 顧問先（担当者別） */
    static readonly ClientPerson = 12;

    /** 検証時の一時的用 */
    static readonly Temporarily = 99;
}

/**
 * 貸借区分
 */
export class ChgDCKbn {

    /** 借方 */
    static readonly Debit = 0;

    /** 貸方 */
    static readonly Credit = 1;
}

/**
 * 翌期移送区分
 */
export class MoveKbn {

    /** する */
    static readonly Setting = 0;

    /** しない */
    static readonly None = 1;

    /** 仕訳を含めて移送する */
    static readonly SwkSet = 2;
}

/**
 * 名寄せ出力区分
 */
export class OutputPtnRootKbn {

    /** 名寄せなし */
    static readonly None = 0;

    /** 名寄せあり */
    static readonly Setting = 1;

    /** 名寄せありで内訳も出力 */
    static readonly SetBreakdown = 2;
}

/**
 * 休日区分
 */
export class PHolidayKbn {

    /** 翌営業日 */
    static readonly TheFollowingBusinessDay = 0;

    /** 前営業日 */
    static readonly ThePreviousBusinessDay = 1;

    /** 当日 */
    static readonly TheDay = 2;
}

/**
 * 工事配賦区分
 */
export class KojiInfoHaifuKbn {

    /** 配賦対象 */
    static readonly AllocationTarget = 0;

    /** 配賦対象外 */
    static readonly AllocationApplying = 1;
}

/**
 * 配賦区分
 */
export class HaifuKbn {

    /** 実績配賦（通常） */
    static readonly AllocationNormal = 1;

    /** 実績配賦（原価率） */
    static readonly AllocationProductionCost = 2;

    /** 部門別セグメント別実績配賦（通常） */
    static readonly SegmentResultsAllocationNormal = 3;

    /** 予算配賦（通常） */
    static readonly BudgetAllocationNormal = 11;

    /** 予算配賦（原価率） */
    static readonly BudgetAllocationProductionCost = 12;

    /** 部門別セグメント別予算配賦 */
    static readonly SegmentResultsAllocation = 13;
}

/**
 * 税込み区分
 */
export class TaxKbn {

    /** 税抜 */
    static readonly TaxExcluded = 0;

    /** 税込 */
    static readonly TaxIncluded = 1;
}

/**
 * 内税消費税額自動計算区分
 */
export class UchizeiAutoCalcKbn {

    /** 計算しない */
    static readonly Manual = 0;

    /** 計算する */
    static readonly Auto = 1;
}

/**
 * 情報区分
 */
export class InfoKbn {

    /** 契約情報 */
    static readonly ContractInfo = 1;

    /** 入金予定情報 */
    static readonly ExpectedReceiptMoneyInfo = 2;
}

/**
 * 配賦情報区分
 */
export class HaifuMBInfoKbn {

    /** 配賦先マスタ */
    static readonly Allocation = 1;

    /** 配賦基準科目 */
    static readonly AllocationKmk = 2;
}

/**
 * 加算区分
 */
export class AddKbn {

    /** 加算 */
    static readonly Addition = 0;

    /** 減算 */
    static readonly Subtraction = 1;
}

/**
 * 摘要区分
 */
export class TekiTreeTekiKbn {

    /** 通常摘要（摘要分類） */
    static readonly UsualAbstractAbstractClassification = 1;

    /** 科目連想 */
    static readonly KmkRenso = 2;

    /** 科目摘要分類連想 */
    static readonly KmkAbstractClassificationRenso = 3;
}

/**
 * 月次確定仕訳発生区分（新規入力）
 */
export class MFixInfoSwkInpKbn {

    /** 事務所（本店） */
    static readonly OfficeMainStore = 0;

    /** 顧問先（支店） */
    static readonly ClientBranchStore = 1;

    /** 全店 */
    static readonly AllUnit = 2;

    /** 部署 */
    static readonly Department = 3;
}

/**
 * 仕訳入力形式
 */
export class SwkForm {

    /** 単一仕訳 */
    static readonly SingleSwk = 1;

    /** 出納帳入力 */
    static readonly CashBookInput = 2;

    /** 振替伝票(ﾐﾛｸ型） */
    static readonly CrossSlip = 3;

    /** ｺｸﾖ型振替伝票 */
    static readonly Kokuyo = 4;

    /** 入金伝票 */
    static readonly PayingSlip = 5;

    /** 出金伝票 */
    static readonly DebitSlip = 6;

    /** 部署伝票 */
    static readonly Department = 31;

    /** 仮払伝票 */
    static readonly TemporaryPay = 33;

    /** 仮払精算伝票 */
    static readonly TemporaryPaySettle = 34;

    /** エラー */
    static readonly Error = 0;
}

/**
 * 出納帳定型種別区分
 */
export class TeikeiClass {

    /** 現金出納帳 */
    static readonly CashJournal = 2;

    /** 預金出納帳 */
    static readonly DepositJournal = 3;

    /** 売掛帳 */
    static readonly ReceivableJournal = 11;

    /** 買掛帳 */
    static readonly PayableJournal = 12;

    /** なし */
    static readonly None = 0;
}

/**
 * 非会計データ区分
 */
export class NonAcc {

    /** 会計データ */
    static readonly AccountingData = 0;

    /** 非会計データ */
    static readonly NonAccountingData = 1;
}

/**
 * 借方貸方科目セット区分
 */
export class DebitAndCreditKmkSetKbn {

    /** なし */
    static readonly None = 0;

    /** 借方第2科目 */
    static readonly DebitSecond = 1;

    /** 借方第3科目 */
    static readonly DebitThird = 2;

    /** 貸方第2科目 */
    static readonly CreditSecond = 11;

    /** 貸方第3科目 */
    static readonly CreditThird = 12;
}

/**
 * 修正／削除不可区分
 */
export class ExeKbn {

    /** 可 */
    static readonly Permit = 0;

    /** 削除不可 */
    static readonly ImpossibleToDelete = 1;

    /** 修正・削除不可 */
    static readonly ImpossibleToCorrectAndDelete = 2;

    /** 一時的に使用 */
    static readonly Temporarily = 9;
}

/**
 * 付箋区分
 */
export class FusenKbn {

    /** なし */
    static readonly None = 0;

    /** 赤 */
    static readonly Red = 1;

    /** 緑 */
    static readonly Green = 2;

    /** 青 */
    static readonly Blue = 3;

    /** オレンジ */
    static readonly Orange = 4;

    /** 連絡用付箋 */
    static readonly TagContacts = 9;
}

/**
 * 月次確定区分
 */
export class MonthlyConfirmedKbn {

    /** 未確定 */
    static readonly UnDecided = 0;

    /** 確定済 */
    static readonly Confirmed = 1;

    /** 確定後解除 */
    static readonly ConfirmedAfterRelease = 2;
}

/**
 * 更新区分
 */
export class UpdateKbn {

    /** 新規入力 */
    static readonly NewInput = 0;

    /** 修正 */
    static readonly Modify = 1;

    /** 削除 */
    static readonly Delete = 2;

    /** 入力後削除 */
    static readonly DeleteAfterInput = 3;

    /** 入力後修正 */
    static readonly InputAfterCorrect = 4;
}

/**
 * 処理区分
 */
export class OpeKbn {

    /** 新規登録 */
    static readonly Registration = 0;

    /** 修正 */
    static readonly Correct = 1;

    /** 削除 */
    static readonly Delete = 2;

    /** 入力後削除（送信前削除） */
    static readonly DeleteAfterInput = 3;
}

/**
 * 分析コード
 */
export class AnalyzeCode {

    /** 非会計科目 */
    static readonly NonAccountingKmk = 0;

    /** 現金 */
    static readonly Cash = 1;

    /** 当座預金 */
    static readonly CurrentDeposit = 2;

    /** 普通預金 */
    static readonly UsuallyDeposit = 3;

    /** 通知預金等 */
    static readonly NoticeDeposit = 4;

    /** 外貨預金 */
    static readonly ForeignCurrencyDeposit = 5;

    /** （予約のみ） */
    static readonly Reservation6 = 6;

    /** その他流動性預金 */
    static readonly OthersFloatingDeposit = 7;

    /** 定期預金 */
    static readonly FixedDeposit = 8;

    /** 定期積金 */
    static readonly TermDeposit = 9;

    /** 受取手形 */
    static readonly BillReceipt = 10;

    /** 割引手形 */
    static readonly BillDiscount = 11;

    /** 売掛金 */
    static readonly Credit = 12;

    /** 商品 */
    static readonly Goods = 13;

    /** 製品 */
    static readonly Product = 14;

    /** 材料 */
    static readonly Material = 15;

    /** 仕掛品 */
    static readonly WorkProcess = 16;

    /** 貯蔵品 */
    static readonly Stock = 17;

    /** （予約のみ） */
    static readonly Reservation18 = 18;

    /** （予約のみ） */
    static readonly Reservation19 = 19;

    /** その他棚卸資産 */
    static readonly OthersInventoryStocktaking = 20;

    /** 有価証券 */
    static readonly ValuablePapers = 21;

    /** 前途金　前渡金 */
    static readonly FutureMoney = 22;

    /** 仮払金 */
    static readonly ProvisionalPaymentMoney = 23;

    /** その他流動資産 */
    static readonly OthersCurrentAssets = 24;

    /** 建物 */
    static readonly Building = 25;

    /** 構築物 */
    static readonly Structure = 26;

    /** 車輌運搬具 */
    static readonly DeliveryEquipment = 27;

    /** 土地 */
    static readonly Land = 28;

    /** 機械装置 */
    static readonly Machinery = 29;

    /** 建設仮勘定 */
    static readonly ConstructionCounting = 30;

    /** その他有形固定資産 */
    static readonly OthersTangibleFixedAssets = 31;

    /** 減価償却累計額 */
    static readonly Adjustment = 32;

    /** 無形固定資産 */
    static readonly IntangibleFixedAssets = 33;

    /** 投資 */
    static readonly Investment = 34;

    /** 繰延資産 */
    static readonly DeferredAssets = 35;

    /** （予約のみ） */
    static readonly Reservation36 = 36;

    /** （予約のみ） */
    static readonly Reservation37 = 37;

    /** 支払手形 */
    static readonly BillPayment = 38;

    /** 買掛金 */
    static readonly AccountsPayable = 39;

    /** 短期借入金 */
    static readonly ShortLoan = 40;

    /** 未払金 */
    static readonly UnsettledMoney = 41;

    /** 未払費用 */
    static readonly UnsettledCost = 42;

    /** 前受金 */
    static readonly AdvancePayment = 43;

    /** 仮受金 */
    static readonly ProvisionalReceptionMoney = 44;

    /** その他流動負債 */
    static readonly OthersCurrentLiabilities = 45;

    /** 長期借入金 */
    static readonly LongLoan = 46;

    /** 社債 */
    static readonly Bond = 47;

    /** 退職給付引当金 */
    static readonly RetirementProvisionMortgage = 48;

    /** （予約のみ） */
    static readonly Reservation49 = 49;

    /** その他固定負債 */
    static readonly OthersFundedDebt = 50;

    /** 引当金 */
    static readonly Mortgage = 51;

    /** 少数株主持分 */
    static readonly ShareHolderInterest = 52;

    /** 資本金 */
    static readonly Capital = 53;

    /** 資本準備金 */
    static readonly CapitalReserve = 54;

    /** 利益準備金 */
    static readonly EarnedSurplusReserve = 55;

    /** その他剰余金 */
    static readonly OthersSurplus = 56;

    /** 評価差額金 */
    static readonly ValuationDifference = 57;

    /** 事業主貸 */
    static readonly OrganizerLending = 58;

    /** 事業主借 */
    static readonly ProprietorsFunds = 59;

    /** 借方未処分項目(B/S) */
    static readonly DebitBS = 98;

    /** 貸方未処分項目(B/S) */
    static readonly CreditBS = 99;

    /** 売上高 */
    static readonly NetSales = 101;

    /** 兼業売上高 */
    static readonly SideJobSales = 102;

    /** 商品期首棚卸 */
    static readonly GoodsBeginningTermStocktaking = 103;

    /** 商品仕入 */
    static readonly GoodsJournalize = 104;

    /** 商品期末棚卸 */
    static readonly GoodsEndTermStocktaking = 105;

    /** 材料期首棚卸 */
    static readonly StartMaterialStocktaking = 106;

    /** 材料仕入 */
    static readonly MaterialJournalize = 107;

    /** 材料期末棚卸 */
    static readonly EndMaterialStocktaking = 108;

    /** 労務費 */
    static readonly LaborCost = 109;

    /** 外注費 */
    static readonly OutsideOrderExpenses = 110;

    /** 労務外注費 */
    static readonly OutsideOrderCost = 111;

    /** 製造経費減価償却費 */
    static readonly ManufacturingExpenseDepreciation = 112;

    /** 製造経費交際費 */
    static readonly ManufacturingExpenseSocialExpenses = 113;

    /** 製造経費賃借料 */
    static readonly ManufacturingExpenseHire = 114;

    /** 製造経費租税公課 */
    static readonly ManufacturingExpenseTaxAndpublicImpositions = 115;

    /** 製造粗付加マイナス */
    static readonly CoarsenessAdditionMinus = 116;

    /** 製造経費人件費 */
    static readonly ManufacturingPersonnelExpenses = 117;

    /** その他製造経費 */
    static readonly OthersManufacturingExpense = 118;

    /** 仕掛品期首棚卸 */
    static readonly WorkProcessBeginningStocktaking = 119;

    /** 仕掛品期末棚卸 */
    static readonly WorkProcessFinalStocktaking = 120;

    /** 販売促進費 */
    static readonly SalesPromotionExpense = 121;

    /** 広告宣伝費 */
    static readonly AdvertisementExpenses = 122;

    /** その他販売費 */
    static readonly OthersSellingExpenses = 123;

    /** 役員報酬 */
    static readonly RecompensationOfficials = 124;

    /** 販管費人件費 */
    static readonly SaleManagementPersonnelExpenses = 125;

    /** 販管費減価償却費 */
    static readonly SaleManagementDepreciation = 126;

    /** 販管費接待交際費 */
    static readonly SaleManagementSocialExpenses = 127;

    /** 販管費賃借料 */
    static readonly SaleManagementHire = 128;

    /** 販管費租税公課 */
    static readonly SaleManagementTaxAndpublicImpositions = 129;

    /** 販管費粗付加マイナス */
    static readonly SaleManagementAdditionMinus = 130;

    /** 販管費交通費 */
    static readonly SaleManagementTransportation = 131;

    /** その他一般管理費 */
    static readonly OthersGeneralAdministrativeExpenses = 134;

    /** 受取利息 */
    static readonly ReceiptInterest = 135;

    /** 受取配当金 */
    static readonly ReceiptDividend = 136;

    /** その他営業外収益 */
    static readonly OthersNonOperatingRevenue = 137;

    /** 支払利息 */
    static readonly InterestPayment = 138;

    /** その他営業外費用 */
    static readonly OthersNonOperatingExpenses = 140;

    /** 特別利益 */
    static readonly ExtraordinaryIncome = 141;

    /** 特別損失 */
    static readonly ExtraordinaryLoss = 142;

    /** 法人税等 */
    static readonly Corporation = 143;

    /** 法人税等調整 */
    static readonly CorporationAdjustment = 144;

    /** 家事消費 */
    static readonly HouseworkConsumption = 145;

    /** 雑収入 */
    static readonly MiscellaneousIncome = 146;

    /** 引当金戻入 */
    static readonly AllowanceReserveReimbursement = 147;

    /** 専従者給与 */
    static readonly WagefamilyEmployeeSalary = 148;

    /** 引当金繰入 */
    static readonly AllowanceReserveTransfer = 149;

    /** 入院診療収益 */
    static readonly HospitalizationMedicalExamination = 151;

    /** 室料差額収益 */
    static readonly RentDifference = 152;

    /** 外来診療収益 */
    static readonly ForeignMedicalExamination = 153;

    /** その他医業収益 */
    static readonly OthersMedicalTreatment = 154;

    /** 期首材料棚卸し */
    static readonly BeginningTermMaterialStocktaking = 155;

    /** 材料費 */
    static readonly MaterialCost = 156;

    /** 期末材料棚卸し */
    static readonly EndTermSMaterialStocktaking = 157;

    /** 常勤医師給与 */
    static readonly FulltimeDoctor = 158;

    /** 常勤看護士給与 */
    static readonly FulltimeNurse = 159;

    /** 常勤その他職員給与 */
    static readonly FulltimeOtherStaff = 160;

    /** 非常勤医師給与 */
    static readonly ParttimeDoctor = 161;

    /** 非常勤看護師給与 */
    static readonly ParttimeNurse = 162;

    /** 非常勤その他職員給与 */
    static readonly ParttimeOtherStaff = 163;

    /** 法定福利費 */
    static readonly DecisionWelfare = 164;

    /** 委託費 */
    static readonly Trust = 165;

    /** 減価償却費 */
    static readonly Depreciation = 166;

    /** 賃借料 */
    static readonly Rental = 167;

    /** 固定資産税等 */
    static readonly FixedAssets = 168;

    /** その他設備関係費 */
    static readonly OtherEquipmentRelated = 169;

    /** 研究研修費 */
    static readonly StudyTraining = 170;

    /** 接待交際費 */
    static readonly ReceptionAssociation = 171;

    /** 租税公課 */
    static readonly TaxAndpublicImpositions = 172;

    /** 粗付加ﾏｲﾅｽ */
    static readonly AdditionMinus = 173;

    /** 交通費 */
    static readonly Transportation = 174;

    /** その他経費 */
    static readonly OtherExpenses = 175;

    /** 控除対象外消費税等負担額 */
    static readonly SubtractionNonapplicable = 176;

    /** 本部費配賦額 */
    static readonly HeadOfficeAllocation = 177;

    /** 受取利息および配当 */
    static readonly ReceiptInterestAndDividend = 178;

    /** その他医業外収益 */
    static readonly OtherMedicalOutside = 179;

    /** 支払利息割引料 */
    static readonly InterestPaymentDiscount = 180;

    /** その医業外費用 */
    static readonly OtherMedicalOutsideCost = 181;

    /** 臨時収益 */
    static readonly SpecialProfit = 182;

    /** 臨時費用 */
    static readonly SpecialCost = 183;

    /** 法人税等 */
    static readonly CorporationTax = 184;

    /** 法人税等調整 */
    static readonly CorporationTaxAdjustment = 185;

    /** 医薬品費 */
    static readonly MedicalSupplies = 186;

    /** 借方未処分項目(P/L) */
    static readonly DebitPL = 198;

    /** 貸方未処分項目(P/L) */
    static readonly CreditPL = 199;
}

/**
 * 要約分析コード
 */
export class SumAnaCode {

    /** 流動性現預金 */
    static readonly LiquidDeposit = 1;

    /** 固定性預金 */
    static readonly FixingDeposit = 2;

    /** その他流動資産 */
    static readonly OthersCurrentAssets = 3;

    /** 有形固定資産 */
    static readonly MaterialFixingProperty = 4;

    /** 無形固定資産 */
    static readonly IntangibleFixingProperty = 5;

    /** 流動負債 */
    static readonly CurrentLiabilities = 6;

    /** 固定負債 */
    static readonly FixingDebt = 7;

    /** 引当金 */
    static readonly AllowanceReserve = 8;

    /** 資本金 */
    static readonly Capital = 9;

    /** 剰余金 */
    static readonly Surplus = 10;

    /** 製造原価 */
    static readonly ManufacturingCost = 13;

    /** 事業主貸 */
    static readonly OrganizerLending = 21;

    /** 事業主借 */
    static readonly ProprietorsFunds = 22;

    /** 売上 */
    static readonly Sales = 11;

    /** 営業外収益 */
    static readonly NonoperatingProfit = 15;

    /** 特別利益 */
    static readonly ExtraordinaryIncome = 17;

    /** 引当金戻入 */
    static readonly AllowanceReserveReimbursement = 24;
}

/**
 * 特殊科目コード
 */
export class ExeKmkCode {

    /** (Ｂ／Ｓ科目)前期繰越利益 */
    static readonly BSFirstTermTransferProfit = 10101;

    /** (Ｂ／Ｓ科目)中間配当金 */
    static readonly BSMidtermDividendPayment = 10102;

    /** (Ｂ／Ｓ科目)中間利益 */
    static readonly BSMiddleProfit = 10103;

    /** (Ｂ／Ｓ科目)資産の部 */
    static readonly BSAssetsSection = 10104;

    /** (Ｂ／Ｓ科目)負債の部 */
    static readonly BSLiabilities = 10105;

    /** (Ｂ／Ｓ科目)資本の部 */
    static readonly BSDepartmentOfCapital = 10106;

    /** (Ｂ／Ｓ科目)負債及び資本の部 */
    static readonly BSPartDebtCapital = 10107;

    /** (Ｂ／Ｓ科目)当期未処分利益 */
    static readonly BSCurrentTermUntreated = 10108;

    /** (Ｂ／Ｓ科目)うち当期利益 */
    static readonly BSCurrentProfit = 10109;

    /** (Ｂ／Ｓ科目)受取手形（決算書用） */
    static readonly BSReceiptBill = 10110;

    /** (Ｂ／Ｓ科目)割引手形（決算書用） */
    static readonly BSDiscountBill = 10111;

    /** (Ｂ／Ｓ科目)不渡手形（決算書用） */
    static readonly BSDishonorBill = 10112;

    /** (Ｂ／Ｓ科目)譲渡手形（決算書用） */
    static readonly BSTransferDraft = 10113;

    /** (Ｂ／Ｓ科目)財産移動高 */
    static readonly BSPropertyTransferAssets = 10114;

    /** (Ｂ／Ｓ科目)財産移動後資産 */
    static readonly BSPropertyTransferAssetsRemainingProperty = 10115;

    /** (Ｂ／Ｓ科目)その他資本剰余金 */
    static readonly BSOthersCapitalSurplus = 10116;

    /** (Ｂ／Ｓ科目)その他の包括利益累計額 */
    static readonly BSOthersTheInclusionProfitTotalFrame = 10117;

    /** (Ｐ／Ｌ科目)純売上高 */
    static readonly PLNetSalesRevenue = 10201;

    /** (Ｐ／Ｌ科目)当期製品製造原価 */
    static readonly PLCurrentTermManufacturingCost = 10202;

    /** (Ｐ／Ｌ科目)売上原価 */
    static readonly PLSalesProductionCost = 10203;

    /** (Ｐ／Ｌ科目)売上総利益 */
    static readonly PLSalesGrossProfit = 10204;

    /** (Ｐ／Ｌ科目)販管費合計 */
    static readonly PLSaleManagementTotal = 10205;

    /** (Ｐ／Ｌ科目)営業利益 */
    static readonly PLBusinessProfit = 10206;

    /** (Ｐ／Ｌ科目)経常利益 */
    static readonly PLOrdinaryProfit = 10207;

    /** (Ｐ／Ｌ科目)税引前利益 */
    static readonly PLPreTaxProfit = 10208;

    /** (Ｐ／Ｌ科目)法人税等 */
    static readonly PLCorporation = 10209;

    /** (Ｐ／Ｌ科目)法人税調整額 */
    static readonly PLCorporationAdjustment = 10210;

    /** (Ｐ／Ｌ科目)当期利益 */
    static readonly PLCurrentTermProfit = 10211;

    /** (Ｐ／Ｌ科目)前期繰越利益 */
    static readonly PLFirstTermTransferProfit = 10212;

    /** (Ｐ／Ｌ科目)過年度税効果調整額 */
    static readonly PLPastYearTaxEffectAdjustment = 10213;

    /** (Ｐ／Ｌ科目)目的積立金取崩 */
    static readonly PLPurposeReservingDemolition = 10214;

    /** (Ｐ／Ｌ科目)中間配当金 */
    static readonly PLMidtermDividendPayment = 10215;

    /** (Ｐ／Ｌ科目)利益準備金積立額 */
    static readonly PLEarnedSurplusReserveAmount = 10216;

    /** (Ｐ／Ｌ科目)当期未処分利益 */
    static readonly PLCurrentTermUntreated = 10217;

    /** (Ｐ／Ｌ科目)工事原価合計 */
    static readonly PLKojiCostTotal = 10218;

    /** (Ｐ／Ｌ科目)工事原価集計 */
    static readonly PLKojiCostAggregate = 999999;

    /** (Ｐ／Ｌ科目)総製造費用 */
    static readonly PLTotalManufacturingCost = 10219;

    /** (Ｐ／Ｌ科目)合計 */
    static readonly PLTotal = 10220;

    /** (Ｐ／Ｌ科目)小計 */
    static readonly PLSubTotal = 10221;

    /** (Ｐ／Ｌ科目)当期未収支差額 */
    static readonly PLCurrentTermBalanceDifference = 10223;

    /** (Ｐ／Ｌ科目)包括利益 */
    static readonly PLInclusionProfit = 10224;

    /** (Ｐ／Ｌ科目)その他の包括利益 */
    static readonly PLOthersTheInclusionProfit = 10225;

    /** 複合科目 */
    static readonly ComplexAccounts = 10301;

    /** 資金複合科目 */
    static readonly FundCompoundSubjectKmk = 10302;

    /** 非会計複合科目 */
    static readonly NonAccountingKmk = 10303;

    /** 不明勘定 */
    static readonly UnclearCounting = 10304;

    /** 第一種事業 固定摘要 */
    static readonly FirstProjectFixingRemarks = 10501;

    /** 第二種事業 固定摘要 */
    static readonly SecondProjectFixingRemarks = 10502;

    /** 第三種事業 固定摘要 */
    static readonly ThirdProjectFixingRemarks = 10503;

    /** 第四種事業 固定摘要 */
    static readonly FourthProjectFixingRemarks = 10504;

    /** 第五種事業 固定摘要 */
    static readonly FifthProjectFixingRemarks = 10505;

    /** 経理連結時の期日指定科目1 */
    static readonly AccountingConnectionTimeDesignation1 = 10901;

    /** 経理連結時の期日指定科目2 */
    static readonly AccountingConnectionTimeDesignation2 = 10902;

    /** 経理連結時の期日指定科目3 */
    static readonly AccountingConnectionTimeDesignation3 = 10903;

    /** 期首棚卸科目1 */
    static readonly BeginningTermStocktakingKmk1 = 20101;

    /** 期首棚卸科目2 */
    static readonly BeginningTermStocktakingKmk2 = 20102;

    /** 期首棚卸科目3 */
    static readonly BeginningTermStocktakingKmk3 = 20103;

    /** 期首棚卸科目4 */
    static readonly BeginningTermStocktakingKmk4 = 20104;

    /** 期首棚卸科目5 */
    static readonly BeginningTermStocktakingKmk5 = 20105;

    /** 期首棚卸科目6 */
    static readonly BeginningTermStocktakingKmk6 = 20106;

    /** 期首棚卸科目7 */
    static readonly BeginningTermStocktakingKmk7 = 20107;

    /** 期首棚卸科目8 */
    static readonly BeginningTermStocktakingKmk8 = 20108;

    /** 期首棚卸科目9 */
    static readonly BeginningTermStocktakingKmk9 = 20109;

    /** 期首棚卸科目10 */
    static readonly BeginningTermStocktakingKmk10 = 20110;

    /** 期首棚卸科目11 */
    static readonly BeginningTermStocktakingKmk11 = 20111;

    /** 期首棚卸科目12 */
    static readonly BeginningTermStocktakingKmk12 = 20112;

    /** 期首棚卸科目13 */
    static readonly BeginningTermStocktakingKmk13 = 20113;

    /** 期首棚卸科目14 */
    static readonly BeginningTermStocktakingKmk14 = 20114;

    /** 期首棚卸科目15 */
    static readonly BeginningTermStocktakingKmk15 = 20115;

    /** 期首棚卸科目16 */
    static readonly BeginningTermStocktakingKmk16 = 20116;

    /** 期首棚卸科目17 */
    static readonly BeginningTermStocktakingKmk17 = 20117;

    /** 期首棚卸科目18 */
    static readonly BeginningTermStocktakingKmk18 = 20118;

    /** 期首棚卸科目19 */
    static readonly BeginningTermStocktakingKmk19 = 20119;

    /** 期首棚卸科目20 */
    static readonly BeginningTermStocktakingKmk20 = 20120;

    /** 期末棚卸科目1 */
    static readonly EndTermStocktakingKmk1 = 20201;

    /** 期末棚卸科目2 */
    static readonly EndTermStocktakingKmk2 = 20202;

    /** 期末棚卸科目3 */
    static readonly EndTermStocktakingKmk3 = 20203;

    /** 期末棚卸科目4 */
    static readonly EndTermStocktakingKmk4 = 20204;

    /** 期末棚卸科目5 */
    static readonly EndTermStocktakingKmk5 = 20205;

    /** 期末棚卸科目6 */
    static readonly EndTermStocktakingKmk6 = 20206;

    /** 期末棚卸科目7 */
    static readonly EndTermStocktakingKmk7 = 20207;

    /** 期末棚卸科目8 */
    static readonly EndTermStocktakingKmk8 = 20208;

    /** 期末棚卸科目9 */
    static readonly EndTermStocktakingKmk9 = 20209;

    /** 期末棚卸科目10 */
    static readonly EndTermStocktakingKmk10 = 20210;

    /** 期末棚卸科目11 */
    static readonly EndTermStocktakingKmk11 = 20211;

    /** 期末棚卸科目12 */
    static readonly EndTermStocktakingKmk12 = 20212;

    /** 期末棚卸科目13 */
    static readonly EndTermStocktakingKmk13 = 20213;

    /** 期末棚卸科目14 */
    static readonly EndTermStocktakingKmk14 = 20214;

    /** 期末棚卸科目15 */
    static readonly EndTermStocktakingKmk15 = 20215;

    /** 期末棚卸科目16 */
    static readonly EndTermStocktakingKmk16 = 20216;

    /** 期末棚卸科目17 */
    static readonly EndTermStocktakingKmk17 = 20217;

    /** 期末棚卸科目18 */
    static readonly EndTermStocktakingKmk18 = 20218;

    /** 期末棚卸科目19 */
    static readonly EndTermStocktakingKmk19 = 20219;

    /** 期末棚卸科目20 */
    static readonly EndTermStocktakingKmk20 = 20220;

    /** 仮受消費税 */
    static readonly ProvisionalReceptionTax = 30101;

    /** 仮払消費税 */
    static readonly ProvisionalPaymentTax = 30102;

    /** 売上調整勘定 */
    static readonly AdjustmentCounting = 30103;

    /** 仕入調整勘定 */
    static readonly AdjustmentJournalize = 30104;

    /** 貸倒償却1 */
    static readonly ChargeOff1 = 30105;

    /** 貸倒償却2 */
    static readonly ChargeOff2 = 30106;

    /** 貸倒償却3 */
    static readonly ChargeOff3 = 30107;

    /** 貸倒償却4 */
    static readonly ChargeOff4 = 30108;

    /** 貸倒償却5 */
    static readonly ChargeOff5 = 30109;

    /** （予約のみ） */
    static readonly Reservation031001 = 31001;

    /** （収入）相互相殺科目1 */
    static readonly IncomOffsetKmk1 = 31101;

    /** （収入）相互相殺科目2 */
    static readonly IncomOffsetKmk2 = 31102;

    /** （収入）相互相殺科目3 */
    static readonly IncomOffsetKmk3 = 31103;

    /** （収入）相互相殺科目4 */
    static readonly IncomOffsetKmk4 = 31104;

    /** （収入）相互相殺科目5 */
    static readonly IncomOffsetKmk5 = 31105;

    /** （収入）相互相殺科目6 */
    static readonly IncomOffsetKmk6 = 31106;

    /** （収入）相互相殺科目7 */
    static readonly IncomOffsetKmk7 = 31107;

    /** （収入）相互相殺科目8 */
    static readonly IncomOffsetKmk8 = 31108;

    /** （収入）相互相殺科目9 */
    static readonly IncomOffsetKmk9 = 31109;

    /** （収入）相互相殺科目10 */
    static readonly IncomOffsetKmk10 = 31110;

    /** （収入）相互相殺科目11 */
    static readonly IncomOffsetKmk11 = 31111;

    /** （収入）相互相殺科目12 */
    static readonly IncomOffsetKmk12 = 31112;

    /** （収入）相互相殺科目13 */
    static readonly IncomOffsetKmk13 = 31113;

    /** （収入）相互相殺科目14 */
    static readonly IncomOffsetKmk14 = 31114;

    /** （収入）相互相殺科目15 */
    static readonly IncomOffsetKmk15 = 31115;

    /** （収入）相互相殺科目16 */
    static readonly IncomOffsetKmk16 = 31116;

    /** （収入）相互相殺科目17 */
    static readonly IncomOffsetKmk17 = 31117;

    /** （収入）相互相殺科目18 */
    static readonly IncomOffsetKmk18 = 31118;

    /** （収入）相互相殺科目19 */
    static readonly IncomOffsetKmk19 = 31119;

    /** （収入）相互相殺科目20 */
    static readonly IncomOffsetKmk20 = 31120;

    /** （収入）相互相殺科目21 */
    static readonly IncomOffsetKmk21 = 31121;

    /** （収入）相互相殺科目22 */
    static readonly IncomOffsetKmk22 = 31122;

    /** （収入）相互相殺科目23 */
    static readonly IncomOffsetKmk23 = 31123;

    /** （収入）相互相殺科目24 */
    static readonly IncomOffsetKmk24 = 31124;

    /** （収入）相互相殺科目25 */
    static readonly IncomOffsetKmk25 = 31125;

    /** （収入）相互相殺科目26 */
    static readonly IncomOffsetKmk26 = 31126;

    /** （収入）相互相殺科目27 */
    static readonly IncomOffsetKmk27 = 31127;

    /** （収入）相互相殺科目28 */
    static readonly IncomOffsetKmk28 = 31128;

    /** （収入）相互相殺科目29 */
    static readonly IncomOffsetKmk29 = 31129;

    /** （収入）相互相殺科目30 */
    static readonly IncomOffsetKmk30 = 31130;

    /** （支出）相互相殺科目1 */
    static readonly SpendingOffsetKmk1 = 31201;

    /** （支出）相互相殺科目2 */
    static readonly SpendingOffsetKmk2 = 31202;

    /** （支出）相互相殺科目3 */
    static readonly SpendingOffsetKmk3 = 31203;

    /** （支出）相互相殺科目4 */
    static readonly SpendingOffsetKmk4 = 31204;

    /** （支出）相互相殺科目5 */
    static readonly SpendingOffsetKmk5 = 31205;

    /** （支出）相互相殺科目6 */
    static readonly SpendingOffsetKmk6 = 31206;

    /** （支出）相互相殺科目7 */
    static readonly SpendingOffsetKmk7 = 31207;

    /** （支出）相互相殺科目8 */
    static readonly SpendingOffsetKmk8 = 31208;

    /** （支出）相互相殺科目9 */
    static readonly SpendingOffsetKmk9 = 31209;

    /** （支出）相互相殺科目10 */
    static readonly SpendingOffsetKmk10 = 31210;

    /** （支出）相互相殺科目11 */
    static readonly SpendingOffsetKmk11 = 31211;

    /** （支出）相互相殺科目12 */
    static readonly SpendingOffsetKmk12 = 31212;

    /** （支出）相互相殺科目13 */
    static readonly SpendingOffsetKmk13 = 31213;

    /** （支出）相互相殺科目14 */
    static readonly SpendingOffsetKmk14 = 31214;

    /** （支出）相互相殺科目15 */
    static readonly SpendingOffsetKmk15 = 31215;

    /** （支出）相互相殺科目16 */
    static readonly SpendingOffsetKmk16 = 31216;

    /** （支出）相互相殺科目17 */
    static readonly SpendingOffsetKmk17 = 31217;

    /** （支出）相互相殺科目18 */
    static readonly SpendingOffsetKmk18 = 31218;

    /** （支出）相互相殺科目19 */
    static readonly SpendingOffsetKmk19 = 31219;

    /** （支出）相互相殺科目20 */
    static readonly SpendingOffsetKmk20 = 31220;

    /** （支出）相互相殺科目21 */
    static readonly SpendingOffsetKmk21 = 31221;

    /** （支出）相互相殺科目22 */
    static readonly SpendingOffsetKmk22 = 31222;

    /** （支出）相互相殺科目23 */
    static readonly SpendingOffsetKmk23 = 31223;

    /** （支出）相互相殺科目24 */
    static readonly SpendingOffsetKmk24 = 31224;

    /** （支出）相互相殺科目25 */
    static readonly SpendingOffsetKmk25 = 31225;

    /** （支出）相互相殺科目26 */
    static readonly SpendingOffsetKmk26 = 31226;

    /** （支出）相互相殺科目27 */
    static readonly SpendingOffsetKmk27 = 31227;

    /** （支出）相互相殺科目28 */
    static readonly SpendingOffsetKmk28 = 31228;

    /** （支出）相互相殺科目29 */
    static readonly SpendingOffsetKmk29 = 31229;

    /** （支出）相互相殺科目30 */
    static readonly SpendingOffsetKmk30 = 31230;

    /** 表示用工事集計科目1 */
    static readonly ViewKojiTotalKmk1 = 40101;

    /** 表示用工事集計科目2 */
    static readonly ViewKojiTotalKmk2 = 40102;

    /** 表示用工事集計科目3 */
    static readonly ViewKojiTotalKmk3 = 40103;

    /** 表示用工事集計科目4 */
    static readonly ViewKojiTotalKmk4 = 40104;

    /** 工事集計科目1 */
    static readonly KojiTotalKmk1 = 40201;

    /** 工事集計科目2 */
    static readonly KojiTotalKmk2 = 40202;

    /** 工事集計科目3 */
    static readonly KojiTotalKmk3 = 40203;

    /** 工事集計科目4 */
    static readonly KojiTotalKmk4 = 40204;

    /** 工事集計科目5 */
    static readonly KojiTotalKmk5 = 40205;

    /** 工事集計科目6 */
    static readonly KojiTotalKmk6 = 40206;

    /** 工事集計科目7 */
    static readonly KojiTotalKmk7 = 40207;

    /** 工事集計科目8 */
    static readonly KojiTotalKmk8 = 40208;

    /** 工事集計科目9 */
    static readonly KojiTotalKmk9 = 40209;

    /** 工事集計科目10 */
    static readonly KojiTotalKmk10 = 40210;

    /** 工事集計科目11 */
    static readonly KojiTotalKmk11 = 40211;

    /** 工事集計科目12 */
    static readonly KojiTotalKmk12 = 40201;

    /** 工事集計科目13 */
    static readonly KojiTotalKmk13 = 40213;

    /** 工事集計科目14 */
    static readonly KojiTotalKmk14 = 40214;

    /** 工事集計科目15 */
    static readonly KojiTotalKmk15 = 40215;

    /** 工事集計科目16 */
    static readonly KojiTotalKmk16 = 40216;

    /** 工事集計科目17 */
    static readonly KojiTotalKmk17 = 40217;

    /** 工事集計科目18 */
    static readonly KojiTotalKmk18 = 40218;

    /** 工事集計科目19 */
    static readonly KojiTotalKmk19 = 40219;

    /** 工事集計科目20 */
    static readonly KojiTotalKmk20 = 40220;

    /** 表示用工事集計科目1(計上区分) */
    static readonly ViewKojiTotalKmk1Recorded = 41101;

    /** 表示用工事集計科目2(計上区分) */
    static readonly ViewKojiTotalKmk2Recorded = 41102;

    /** 表示用工事集計科目3(計上区分) */
    static readonly ViewKojiTotalKmk3Recorded = 41103;

    /** 工事集計科目4(計上区分) */
    static readonly ViewKojiTotalKmk4Recorded = 41104;

    /** 工事集計科目1(計上区分) */
    static readonly KojiTotalKmk5Recorded = 41201;

    /** 工事集計科目2(計上区分) */
    static readonly KojiTotalKmk6Recorded = 41202;

    /** 工事集計科目3(計上区分) */
    static readonly KojiTotalKmk7Recorded = 41203;

    /** 工事集計科目4(計上区分) */
    static readonly KojiTotalKmk8Recorded = 41204;

    /** 工事集計科目5(計上区分) */
    static readonly KojiTotalKmk9Recorded = 41205;

    /** 工事集計科目6(計上区分) */
    static readonly KojiTotalKmk10Recorded = 41206;

    /** 工事集計科目7(計上区分) */
    static readonly KojiTotalKmk11Recorded = 41207;

    /** 工事集計科目8(計上区分) */
    static readonly KojiTotalKmk12Recorded = 41208;

    /** 工事集計科目9(計上区分) */
    static readonly KojiTotalKmk13Recorded = 41209;

    /** 工事集計科目10(計上区分) */
    static readonly KojiTotalKmk14Recorded = 41210;

    /** 工事集計科目11(計上区分) */
    static readonly KojiTotalKmk15Recorded = 41211;

    /** 工事集計科目12(計上区分) */
    static readonly KojiTotalKmk16Recorded = 41212;

    /** 工事集計科目13(計上区分) */
    static readonly KojiTotalKmk17Recorded = 41213;

    /** 工事集計科目14(計上区分) */
    static readonly KojiTotalKmk18Recorded = 41214;

    /** 工事集計科目15(計上区分) */
    static readonly KojiTotalKmk19Recorded = 41215;

    /** 工事集計科目16(計上区分) */
    static readonly KojiTotalKmk20Recorded = 41216;

    /** 工事集計科目17(計上区分) */
    static readonly KojiTotalKmk21Recorded = 41217;

    /** 工事集計科目18(計上区分) */
    static readonly KojiTotalKmk22Recorded = 41218;

    /** 工事集計科目19(計上区分) */
    static readonly KojiTotalKmk23Recorded = 41219;

    /** 工事集計科目20(計上区分) */
    static readonly KojiTotalKmk24Recorded = 41220;

    /** 工事集計科目1(工事台帳) */
    static readonly KojiTotalKmk1KojiLedger = 40301;

    /** 工事集計科目2(工事台帳) */
    static readonly KojiTotalKmk2KojiLedger = 40302;

    /** 工事集計科目3(工事台帳) */
    static readonly KojiTotalKmk3KojiLedger = 40303;

    /** 工事集計科目4(工事台帳) */
    static readonly KojiTotalKmk4KojiLedger = 40304;

    /** 工事集計科目5(工事台帳) */
    static readonly KojiTotalKmk5KojiLedger = 40305;

    /** 工事集計科目6(工事台帳) */
    static readonly KojiTotalKmk6KojiLedger = 40306;

    /** 工事集計科目7(工事台帳) */
    static readonly KojiTotalKmk7KojiLedger = 40307;

    /** 工事集計科目8(工事台帳) */
    static readonly KojiTotalKmk8KojiLedger = 40308;

    /** 工事集計科目9(工事台帳) */
    static readonly KojiTotalKmk9KojiLedger = 40309;

    /** 工事集計科目10(工事台帳) */
    static readonly KojiTotalKmk10KojiLedger = 40310;

    /** 工事集計科目11(工事台帳) */
    static readonly KojiTotalKmk11KojiLedger = 40311;

    /** 工事集計科目12(工事台帳) */
    static readonly KojiTotalKmk12KojiLedger = 40312;

    /** 工事集計科目13(工事台帳) */
    static readonly KojiTotalKmk13KojiLedger = 40313;

    /** 工事集計科目14(工事台帳) */
    static readonly KojiTotalKmk14KojiLedger = 40314;

    /** 工事集計科目15(工事台帳) */
    static readonly KojiTotalKmk15KojiLedger = 40315;

    /** 工事集計科目16(工事台帳) */
    static readonly KojiTotalKmk16KojiLedger = 40316;

    /** 工事集計科目17(工事台帳) */
    static readonly KojiTotalKmk17KojiLedger = 40317;

    /** 工事集計科目18(工事台帳) */
    static readonly KojiTotalKmk18KojiLedger = 40318;

    /** 工事集計科目19(工事台帳) */
    static readonly KojiTotalKmk19KojiLedger = 40319;

    /** 工事集計科目20(工事台帳) */
    static readonly KojiTotalKmk20KojiLedger = 40320;

    /** 工事集計科目21(工事台帳) */
    static readonly KojiTotalKmk21KojiLedger = 40321;

    /** 工事集計科目22(工事台帳) */
    static readonly KojiTotalKmk22KojiLedger = 40322;

    /** 工事集計科目23(工事台帳) */
    static readonly KojiTotalKmk23KojiLedger = 40323;

    /** 工事集計科目24(工事台帳) */
    static readonly KojiTotalKmk24KojiLedger = 40324;

    /** 工事集計科目25(工事台帳) */
    static readonly KojiTotalKmk25KojiLedger = 40325;

    /** 工事集計科目26(工事台帳) */
    static readonly KojiTotalKmk26KojiLedger = 40326;

    /** 工事集計科目27(工事台帳) */
    static readonly KojiTotalKmk27KojiLedger = 40327;

    /** 工事集計科目28(工事台帳) */
    static readonly KojiTotalKmk28KojiLedger = 40328;

    /** 工事集計科目29(工事台帳) */
    static readonly KojiTotalKmk29KojiLedger = 40329;

    /** 工事集計科目30(工事台帳) */
    static readonly KojiTotalKmk30KojiLedger = 40330;

    /** 工事合計科目1(工事管理表) */
    static readonly KojiTotalKmk1KojiManagementChart = 40401;

    /** 工事合計科目2(工事管理表) */
    static readonly KojiTotalKmk2KojiManagementChart = 40402;

    /** 工事合計科目3(工事管理表) */
    static readonly KojiTotalKmk3KojiManagementChart = 40403;

    /** 工事合計科目4(工事管理表) */
    static readonly KojiTotalKmk4KojiManagementChart = 40404;

    /** 工事合計科目5(工事管理表) */
    static readonly KojiTotalKmk5KojiManagementChart = 40405;

    /** 工事合計科目6(工事管理表) */
    static readonly KojiTotalKmk6KojiManagementChart = 40406;

    /** 工事合計科目7(工事管理表) */
    static readonly KojiTotalKmk7KojiManagementChart = 40407;

    /** 工事合計科目8(工事管理表) */
    static readonly KojiTotalKmk8KojiManagementChart = 40408;

    /** 工事合計科目9(工事管理表) */
    static readonly KojiTotalKmk9KojiManagementChart = 40409;

    /** 工事合計科目10(工事管理表) */
    static readonly KojiTotalKmk10KojiManagementChart = 40410;

    /** 工事合計科目（構成比分母） */
    static readonly KojiTotalKmkCompositionRatioDenominator = 40411;

    /** 本支店勘定1 */
    static readonly MainBranchOfficeBill1 = 50101;

    /** 本支店勘定2 */
    static readonly MainBranchOfficeBill2 = 50102;

    /** 本支店勘定3 */
    static readonly MainBranchOfficeBill3 = 50103;

    /** 本支店勘定4 */
    static readonly MainBranchOfficeBill4 = 50104;

    /** 本支店勘定5 */
    static readonly MainBranchOfficeBill5 = 50105;

    /** 本支店勘定6 */
    static readonly MainBranchOfficeBill6 = 50106;

    /** 本支店勘定7 */
    static readonly MainBranchOfficeBill7 = 50107;

    /** 本支店勘定8 */
    static readonly MainBranchOfficeBill8 = 50108;

    /** 本支店勘定9 */
    static readonly MainBranchOfficeBill9 = 50109;

    /** 本支店勘定10 */
    static readonly MainBranchOfficeBill10 = 50110;

    /** 本支店勘定11 */
    static readonly MainBranchOfficeBill11 = 50111;

    /** 本支店勘定12 */
    static readonly MainBranchOfficeBill12 = 50112;

    /** 本支店勘定13 */
    static readonly MainBranchOfficeBill13 = 50113;

    /** 本支店勘定14 */
    static readonly MainBranchOfficeBill14 = 50114;

    /** 本支店勘定15 */
    static readonly MainBranchOfficeBill15 = 50115;

    /** 本支店勘定16 */
    static readonly MainBranchOfficeBill16 = 50116;

    /** 本支店勘定17 */
    static readonly MainBranchOfficeBill17 = 50117;

    /** 本支店勘定18 */
    static readonly MainBranchOfficeBill18 = 50118;

    /** 本支店勘定19 */
    static readonly MainBranchOfficeBill19 = 50119;

    /** 本支店勘定20 */
    static readonly MainBranchOfficeBill20 = 50120;

    /** 本支店勘定21 */
    static readonly MainBranchOfficeBill21 = 50121;

    /** 本支店勘定22 */
    static readonly MainBranchOfficeBill22 = 50122;

    /** 本支店勘定23 */
    static readonly MainBranchOfficeBill23 = 50123;

    /** 本支店勘定24 */
    static readonly MainBranchOfficeBill24 = 50124;

    /** 本支店勘定25 */
    static readonly MainBranchOfficeBill25 = 50125;

    /** 本支店勘定26 */
    static readonly MainBranchOfficeBill26 = 50126;

    /** 本支店勘定27 */
    static readonly MainBranchOfficeBill27 = 50127;

    /** 本支店勘定28 */
    static readonly MainBranchOfficeBill28 = 50128;

    /** 本支店勘定29 */
    static readonly MainBranchOfficeBill29 = 50129;

    /** 本支店勘定30 */
    static readonly MainBranchOfficeBill30 = 50130;

    /** 開始 */
    static readonly Start = 700000;

    /** 終了 */
    static readonly End = 799999;

    /** 当期正味財産 */
    static readonly CurrentNetAssets = 720143;

    /** 正味財産 */
    static readonly NetAssets = 720141;

    /** 翌年度繰越消費収入超過額 */
    static readonly TransferredExcessAmount = 710148;

    /** 当期活動収支差額 */
    static readonly CurrentBalance = 740345;

    /** 次期繰越活動収支差額 */
    static readonly TransferredBalance = 740344;
}

/**
 * 要約PL科目コード
 */
export class DigestPLCode {

    /** 純売上高 */
    static readonly NetSalesRevenue = 1;

    /** 期首棚卸高 */
    static readonly BeginningTermStocktaking = 2;

    /** 商品仕入 */
    static readonly GoodsJournalize = 3;

    /** 材料仕入 */
    static readonly MaterialJournalize = 4;

    /** 労務費 */
    static readonly LaborCost = 5;

    /** 外注費 */
    static readonly OutsideOrderExpenses = 6;

    /** 製造経費 */
    static readonly ManufacturingExpense = 7;

    /** 期末棚卸高 */
    static readonly EndTermStocktaking = 8;

    /** 売上原価 */
    static readonly SalesProductionCost = 9;

    /** 売上総利益 */
    static readonly SalesGrossProfit = 10;

    /** 販売費 */
    static readonly SellingCosts = 11;

    /** 人件費 */
    static readonly PersonnelExpenses = 12;

    /** 一般管理費 */
    static readonly GeneralAndAdministrativeStaff = 13;

    /** 販管費合計 */
    static readonly SaleManagementTotal = 14;

    /** 営業利益 */
    static readonly BusinessProfit = 15;

    /** 営業外収益 */
    static readonly NonoperatingProfit = 16;

    /** 支払利息割引料 */
    static readonly tInterestPaymenDiscount = 17;

    /** その他営業外費用 */
    static readonly OthersNonOperatingExpenses = 18;

    /** 営業外費用 */
    static readonly NonoperatingCost = 19;

    /** 経常利益 */
    static readonly OrdinaryIncome = 20;

    /** 特別利益 */
    static readonly ExtraordinaryIncome = 21;

    /** 特別損失 */
    static readonly ExtraordinaryLoss = 22;

    /** 税引前利益 */
    static readonly PreTaxProfit = 23;

    /** 法人税等 */
    static readonly Corporation = 24;

    /** 税引後利益 */
    static readonly ProfitAfterTax = 25;
}

/**
 * 要約BS科目コード
 */
export class DigestBSCode {

    /** 流動性現預金 */
    static readonly LiquidDeposit = 1;

    /** 固定性預金 */
    static readonly FixingDeposit = 2;

    /** 受取手形 */
    static readonly ReceiptBill = 3;

    /** 売掛金 */
    static readonly Credit = 4;

    /** 棚卸資産 */
    static readonly Stocktaking = 5;

    /** その他流動資産 */
    static readonly OthersCurrentAssets = 6;

    /** 流動資産計 */
    static readonly currentAssetsTotal = 7;

    /** 有形固定資産 */
    static readonly MaterialFixingProperty = 8;

    /** 無形固定資産 */
    static readonly IntangibleFixingProperty = 9;

    /** 投資等 */
    static readonly Investments = 10;

    /** 固定資産計 */
    static readonly FixingPropertyTotal = 11;

    /** 繰延資産 */
    static readonly DeferredAssets = 12;

    /** 資産合計 */
    static readonly AssetsTotal = 13;

    /** 支払手形 */
    static readonly BillPayment = 14;

    /** 買掛金 */
    static readonly AccountsPayable = 15;

    /** 借入金 */
    static readonly Loan = 16;

    /** その他流動負債 */
    static readonly OthersCurrentliabilities = 17;

    /** 流動負債計 */
    static readonly CurrentLiabilitiesTotal = 18;

    /** 固定負債 */
    static readonly FixingDebt = 19;

    /** 引当金 */
    static readonly AllowanceReserve = 20;

    /** 負債合計 */
    static readonly LiabilitiesTotal = 21;

    /** 資本金 */
    static readonly Capital = 22;

    /** 剰余金 */
    static readonly Surplus = 23;

    /** 評価差額金 */
    static readonly ValuationDifference = 24;

    /** 負債資本合計 */
    static readonly DebtCapitalTotal = 25;
}

/**
 * 金額欄の網掛け
 */
export class NumHatch {

    /** なし */
    static readonly None = 0;

    /** 淡色 */
    static readonly LightColored = 1;

    /** 中間色 */
    static readonly NeutralColor = 2;

    /** 濃色 */
    static readonly DarkColor = 3;
}

/**
 * (横)インデント
 */
export class HorizonCharKbn {

    /** そのまま */
    static readonly JustAsIs = 0;

    /** 最左端 */
    static readonly LeftJustification = 1;

    /** 1段右へ */
    static readonly StepRight1 = 2;

    /** 2段右へ */
    static readonly StepRight2 = 3;

    /** 3段右へ */
    static readonly StepRight3 = 4;
}

/**
 * 金額出力区分
 */
export class NumKbn {

    /** デフォルト */
    static readonly DefaultOption = 0;

    /** 前行の右側に出力 */
    static readonly OutputRightSide = 1;

    /** 左側に出力 */
    static readonly OutputLeft = 2;
}

/**
 * 予算区分
 */
export class BudgetKbn {

    /** なし */
    static readonly None = 0;

    /** 予算1 */
    static readonly Budget1 = 1;

    /** 予算2 */
    static readonly Budget2 = 2;

    /** 予算3 */
    static readonly Budget3 = 3;

    /** 予算4 */
    static readonly Budget4 = 4;

    /** 予算1（配賦） */
    static readonly Budget1Allocation = 11;

    /** 予算2（配賦） */
    static readonly Budget2Allocation = 12;

    /** 予算3（配賦） */
    static readonly Budget3Allocation = 13;

    /** 予算4（配賦） */
    static readonly Budget4Allocation = 14;
}

/**
 * 月次予算採用区分
 */
export class MBudgetKbn {

    /** なし */
    static readonly None = 0;

    /** 予算1 */
    static readonly BudgetKbn1 = 1;

    /** 予算1～2 */
    static readonly BudgetKbn2 = 2;

    /** 予算1～3 */
    static readonly BudgetKbn3 = 3;

    /** 予算1～4 */
    static readonly BudgetKbn4 = 4;
}

/**
 * 実在／合計区分
 */
export class MasterSumKbn {

    /** 実在 */
    static readonly Real = 0;

    /** 合計 */
    static readonly Total = 1;

    /** 総合計 */
    static readonly GrandTotal = 9;

    /** 強制空白 */
    static readonly CompulsionBlank = 98;
}

/**
 * 出力分類区分
 */
export class HojyoOrderSumKbn {

    /** 工事分類コード1 */
    static readonly KojiClassificationCode1 = 1;

    /** 工事分類コード2 */
    static readonly KojiClassificationCode2 = 2;

    /** 工事分類コード3 */
    static readonly KojiClassificationCode3 = 3;

    /** 工事分類コード4 */
    static readonly KojiClassificationCode4 = 4;

    /** 工事分類コード5 */
    static readonly KojiClassificationCode5 = 5;

    /** 受注先（得意先） */
    static readonly ReceivingDestinationCustomers = 22;

    /** 担当者 */
    static readonly Person = 25;

    /** 部門 */
    static readonly Department = 41;

    /** 工事 */
    static readonly Koji = 51;

    /** 工種 */
    static readonly KojiType = 52;

    /** 統合工事 */
    static readonly IntegrationKoji = 53;
}

/**
 * 標準パターン区分
 */
export class StandardPtnKbn {

    /** 標準以外 */
    static readonly BesidesStandard = 0;

    /** 標準パターン */
    static readonly StandardPtn = 1;
}

/**
 * 入力順・日付順
 */
export class SortKbn {

    /** 入力順 */
    static readonly Input = 0;

    /** 日付順 */
    static readonly Date = 1;

    /** 日付順 伝票NO指定あり */
    static readonly DateDenNo = 2;
}

/**
 * その他条件区分
 */
export class ElseKbn {

    /** なし */
    static readonly None = 0;

    /** 部門・補助範囲指定 */
    static readonly BmnAuxiliaryArea = 1;

    /** 詳細条件指定 */
    static readonly DetailedConditions = 2;
}

/**
 * 部門・補助範囲指定区分
 */
export class BmnHojKbn {

    /** なし */
    static readonly None = 0;

    /** 部門 */
    static readonly Department = 1;

    /** 補助 */
    static readonly Auxiliary = 2;

    /** 工事 */
    static readonly Koji = 3;
}

/**
 * 検索方法
 */
export class SearchKbn {

    /** 貸借一方一致 */
    static readonly FellowAgreement = 0;

    /** 貸借両方一致 */
    static readonly BothAgreement = 1;

    /** 科目・部門検索  */
    static readonly KmkmnSearch = 2;
}

/**
 * 元帳出力処理区分
 */
export class LedgerOutputKbn {

    /** デフォルト */
    static readonly DefaultOption = 0;

    /** 月末一括税抜 */
    static readonly EndMonthLumping = 1;

    /** 月末一括税抜（併記） */
    static readonly EndMonthLumpingWriting = 2;

    /** 取引毎税抜 */
    static readonly EveryDealingsExcludingTax = 3;
}

/**
 * 構成図出力方法
 */
export class OutputPtnOutputKbn {

    /** 全体比較 */
    static readonly AllComparison = 0;

    /** 構成割合比較 */
    static readonly ConfigurationRateComparison = 1;
}

/**
 * 補助出力方法
 */
export class HojRsvKbn {

    /** 出力順序指定 */
    static readonly OutputOrder = 0;

    /** 範囲指定（コード順） */
    static readonly CodeRangeSpecification = 1;

    /** ｼﾝﾎﾞﾙ順 */
    static readonly SymbolOrder = 2;

    /** 金額降順 */
    static readonly AmountDescendingOrder = 3;

    /** 金額昇順 */
    static readonly AmountAscendingOrder = 4;

    /** 金額指定 */
    static readonly AmountSpecified = 5;
}

/**
 * 工事出力方法
 */
export class KojRsvKbn {

    /** 全て */
    static readonly All = 0;

    /** 未成分 */
    static readonly Incomplete = 1;

    /** 完成分 */
    static readonly Completed = 2;

    /** 追加支出 */
    static readonly AdditionalExpenditure = 3;

    /** 当月完成分 */
    static readonly ConthCompletion = 4;

    /** 前期完成 */
    static readonly CompletedPreviousTerm = 5;
}

/**
 * 帳票種別
 */
export class RepoClass {

    /** Ｔ／Ｂ（ﾌｫｰﾑ） */
    static readonly TBForm = 1;

    /** Ｔ／Ｂ（白紙） */
    static readonly TB = 2;

    /** Ｔ／Ｂ（部門別） */
    static readonly TBBmn = 3;

    /** 決算書 */
    static readonly FinancialStatements = 4;

    /** 資金繰表 */
    static readonly FinancingList = 5;

    /** 工事管理表 */
    static readonly KojiManagementChart = 6;

    /** C/F計算書（直接法） */
    static readonly CFStatementDirect = 7;

    /** C/F計算書（間接法） */
    static readonly CFStatementInDirect = 8;

    /** 総勘定元帳 */
    static readonly GeneralLedger = 21;
}

/**
 * 工事台帳出力種類
 */
export class KojiLedgerNo {

    /** 実在工事 */
    static readonly RealityKoji = 0;

    /** 統合工事 */
    static readonly IntegrationKoji = 1;
}

/**
 * 期間集計方法
 */
export class CalcKbn {

    /** 期間累計 */
    static readonly PeriodToDate = 0;

    /** 月別集計（毎月印刷） */
    static readonly Total = 1;
}

/**
 * ウィンドウ表示区分
 */
export class KmkWinDspDspKbn {

    /** 全件表示 */
    static readonly AllIndication = 0;

    /** 科目連想表示 */
    static readonly KmkRensoDisplay = 1;

    /** 摘要残優先表示 */
    static readonly RemarksBalancePriority = 2;

    /** 非表示 */
    static readonly Hidden = 9;
}

/**
 * 会計処理区分
 */
export class TaxInfoAccProKbn {

    /** 免税業者 */
    static readonly TaxFreeTrader = 0;

    /** 税込処理 */
    static readonly TaxIncluded = 1;

    /** 税抜処理 */
    static readonly TaxTreatment = 2;
}

/**
 * 仕入の税額計算区分
 */
export class BCalcKbn {

    /** 期間方式 */
    static readonly PeriodMethod = 0;

    /** 積上方式1 */
    static readonly PilingSystem1 = 1;

    /** 積上方式2 */
    static readonly PilingSystem2 = 2;
}

/**
 * 消費税 端数処理方法
 */
export class UkeAdjust {

    /** 切り捨て */
    static readonly CuttingOff = 0;

    /** 切り上げ */
    static readonly Roundup = 1;

    /** 四捨五入 */
    static readonly RoundOff = 2;
}

/**
 * 端数処理
 */
export class RoundKbn {

    /** 四捨五入 */
    static readonly RoundOff = 0;

    /** 切り上げ */
    static readonly Roundup = 1;

    /** 切り捨て */
    static readonly CuttingOff = 3;
}

/**
 * 簡易課税区分
 */
export class KaniKbn {

    /** 原則課税 */
    static readonly PrincipleTaxation = 0;

    /** 簡易課税 */
    static readonly SimplifiedTax = 1;
}

/**
 * 簡易課税基準業種
 */
export class BasedType {

    /** 卸売業 */
    static readonly WholesaleBusiness = 1;

    /** 小売業 */
    static readonly RetailTrade = 2;

    /** 製造業 */
    static readonly ManufacturingIndustry = 3;

    /** その他 */
    static readonly Others = 4;

    /** サービス業 */
    static readonly ServiceIndustry = 5;

    /** 不動産 */
    static readonly Estate = 6;
}

/**
 * 輸入取引区分
 */
export class ImportKbn {

    /** なし */
    static readonly None = 0;

    /** 税抜処理（消費税一括処理） */
    static readonly ExcludedConsumption = 1;

    /** 税抜処理（国税/地方税分割） */
    static readonly ExcludingTaxNationalLocal = 2;

    /** 税込処理（消費税一括処理） */
    static readonly TaxIncludedConsumption = 3;
}

/**
 * 基準科目区分
 */
export class BaseKbn {

    /** 実在 */
    static readonly Real = 0;

    /** 合計 */
    static readonly Total = 1;

    /** 原価率パターン */
    static readonly ProductionCostPattern = 1;

    /** 配賦基準科目 */
    static readonly AllocationCriteriaSubjects = 2;
}

/**
 * 配賦金額相殺区分
 */
export class OffsetKbn {

    /** 配賦先科目で相殺 */
    static readonly AllocationDestination = 0;

    /** 配賦元科目で相殺 */
    static readonly AllocationOrigin = 1;
}

/**
 * 摘要入力区分
 */
export class TekiInpKbn {

    /** 通過 */
    static readonly Passing = 0;

    /** 前追加 */
    static readonly PreviousAddition = 1;

    /** 後追加 */
    static readonly BackAddition = 2;
}

/**
 * 部門分類
 */
export class BmnClassification {

    /** P/L部門 */
    static readonly PL = 0;

    /** 共通部門（B/S+P/L） */
    static readonly CommonBmnBSPL = 1;

    /** B/S部門 */
    static readonly BS = 2;
}

/**
 * 財務報告書フォーム形式
 */
export class FinanceReportForm {

    /** 法人(A-1,A-2) */
    static readonly CorporationA1A2 = 1;

    /** 医療法人(A-1,B-2) */
    static readonly MedicalA1B2 = 2;

    /** 工事業(A-1,C-2) */
    static readonly KojiA1C2 = 3;

    /** 個人拡大(D-1,D-2) */
    static readonly PersonalExpansionD1D2 = 4;

    /** 個人基本(E-1,E-2) */
    static readonly PersonalBasisE1E2 = 5;

    /** 法人基本 */
    static readonly CorporationBasic = 6;

    /** 旧医療法人 */
    static readonly OldMedical = 7;

    /** 新医療法人 */
    static readonly NewMedical = 8;
}

/**
 * 月次採用区分
 */
export class MonthlyAdoptionKbn {

    /** 年次 */
    static readonly Annual = 0;

    /** 月次 */
    static readonly Monthly = 1;

    /** 半期 */
    static readonly HalfTerm = 2;

    /** 四半期 */
    static readonly QuarterofTheYear = 3;
}

/**
 * 部門採用
 */
export class BmnAdopted {

    /** 通常部門 */
    static readonly NormalBmn = 0;

    /** 営業部門 */
    static readonly BusinessBmn = 1;

    /** 製造部門 */
    static readonly ManufacturingBmn = 2;

    /** 管理部門 */
    static readonly ManagingSection = 3;

    /** 営業+管理部門 */
    static readonly BusinessAndManagementBmn = 4;

    /** 製造+管理部門 */
    static readonly ManufacturingManagingBmn = 5;

    /** 支店部門 */
    static readonly BranchStoreBmn = 9;
}

/**
 * 会計基準
 */
export class AccountingStandard {

    /** 基準 */
    static readonly Standard = 0;

    /** 指針 */
    static readonly Guideline = 1;

    /** 授産 */
    static readonly Vocational = 2;

    /** 保育所 */
    static readonly NurserySchool = 3;

    /** NPO */
    static readonly NPO = 4;

    /** 公益H18基準 */
    static readonly PublicH18 = 5;

    /** 就労支援 */
    static readonly SorkingSupport = 6;

    /** 公益H20基準 */
    static readonly PublicH20 = 7;

    /** 社福H24基準 */
    static readonly SocialWelfareH24 = 8;

    /** 学校H27基準 */
    static readonly SchoolH27 = 9;
}

/**
 * 期日管理採用区分
 */
export class FixedDateManagementAdoptionKbn {

    /** なし */
    static readonly None = 0;

    /** 暦月で管理 */
    static readonly ManagedCalendarMonth = 1;

    /** 月度で管理 */
    static readonly ManagedDegreeMonth = 2;
}

/**
 * ＯＣＲイメージ摘要 印刷サイズ
 */
export class OCRImageRemarksPrintSize {

    /** 標準 */
    static readonly Standard = 0;

    /** 拡大 */
    static readonly Expansion = 1;
}

/**
 * 仕訳修正履歴採用区分
 */
export class SwkCorrectHistoryAdoptionKbn {

    /** なし */
    static readonly None = 0;

    /** 全修正データを管理 */
    static readonly AllDataManagement = 1;

    /** 初回修正分を除いて管理 */
    static readonly ManagedExceptFirstTimeCorrectionMinute = 2;
}

/**
 * 外税同時入力区分
 */
export class ExcludedInputKbn {

    /** 同時入力 */
    static readonly Simultaneous = 0;

    /** 別々入力 */
    static readonly Separate = 1;
}

/**
 * 仕訳データ出力時の科目名区分
 */
export class SwkDataOutputKmkKbn {

    /** 正式科目名称 */
    static readonly FormalCoursesName = 0;

    /** 簡略科目名称 */
    static readonly SimpleifiedAccountName = 1;
}

/**
 * 元帳頁NO区分
 */
export class LedgerNoKbn {

    /** 連番 */
    static readonly SerialNumber = 0;

    /** 当該項目が変わったら振りなおす */
    static readonly ItemConcernedShakenAgain = 1;
}

/**
 * 証憑ＮＯ属性
 */
export class EvidenceNoType {

    /** 数字 */
    static readonly Number = 0;

    /** フリー（左寄せ） */
    static readonly FreeLeftJustification = 1;

    /** フリー（右寄せ） */
    static readonly FreeRightJustification = 2;
}

/**
 * 資金繰コード入力区分
 */
export class CashCodeInputKbn {

    /** 資金繰ｺｰﾄﾞなしの時 */
    static readonly NoneCashCode = 0;

    /** 複合仕訳の時 */
    static readonly WhenCompositeSwk = 1;

    /** 資金科目／非資金科目の組合せの時 */
    static readonly CashAndNonCash = 2;

    /** 入力なし */
    static readonly None = 9;
}

/**
 * 定型仕訳ｳｨﾝﾄﾞｳ表示区分
 */
export class FixedFormSwkDisplayKbn {

    /** 3列 */
    static readonly Line3 = 0;

    /** 2列 */
    static readonly Line2 = 1;
}

/**
 * 元帳 消費税コード出力区分
 */
export class LedgerTaxCodeOutputKbn {

    /** 出力する */
    static readonly Output = 0;

    /** 出力しない */
    static readonly NotOutput = 1;

    /** BSでも出す */
    static readonly BSInput = 2;
}

/**
 * 使用／出力／採用区分
 */
export class UseKbn {

    /** なし/しない */
    static readonly None = 0;

    /** あり/する */
    static readonly Setting = 1;
}

/**
 * 使用／出力／採用区分(0があり)
 */
export class ZeroUseKbn {

    /** あり/する */
    static readonly Setting = 0;

    /** なし/しない */
    static readonly None = 1;
}

/**
 * 単位
 */
export class TaniKbn {

    /** 円 */
    static readonly Yen = 0;

    /** 千円 */
    static readonly ThousandYen = 1;

    /** 万円 */
    static readonly ManYen = 2;

    /** 十万円 */
    static readonly OneHundredThousandYen = 3;

    /** 百万円 */
    static readonly OneMillionYen = 4;
}

/**
 * 諸口
 */
export class SundryInfo {

    /** 諸口 */
    static readonly Sundry = 0;

    /** 諸口以外 */
    static readonly NotSundry = 1;
}

/**
 * P/L、B/S採用区分
 */
export class BSPLAdoptionKbn {

    /** なし */
    static readonly None = 0;

    /** P/Lのみ採用 */
    static readonly PLOnly = 1;

    /** B/S＆P/L採用 */
    static readonly BSAndPL = 2;
}

/**
 * 帳票NO
 */
export class RepoNo {

    /** 日別残 */
    static readonly Daily = 310701;

    /** 日計表 */
    static readonly ValidateSpecificDate = 310702;

    /** 当年推移 */
    static readonly SpecificMonth = 310703;

    /** 科目別集計 */
    static readonly Accounts = 310704;

    /** 残高試算表 */
    static readonly ValidateTrialBalance = 310705;

    /** 要約財務 */
    static readonly CondensedFinancialStatement = 310706;

    /** 比較損益 */
    static readonly ComparativeIncomeStatement = 310707;

    /** 仕訳日記帳 */
    static readonly SwkDiary = 320100;

    /** 振替伝票 */
    static readonly CrossSlip = 320400;

    /** 入金伝票 */
    static readonly PayingSlip = 320410;

    /** 出金伝票 */
    static readonly DebitSlip = 320420;

    /** 財務報告書 */
    static readonly FinancialReports = 330100;

    /** 科目残高一覧 */
    static readonly AccountBalanceList = 330300;

    /** 摘要残高一覧 */
    static readonly TekiyoBalanceList = 330600;

    /** 総勘定元帳 */
    static readonly GeneralLedger = 340100;

    /** 精算表 */
    static readonly WorkingSheet = 350000;

    /** 推移財務報告書 */
    static readonly TransitionFinanceReport = 410140;

    /** 仕訳帳 */
    static readonly Journal = 310101;

    /** 財務報告書（Ａ４縦） */
    static readonly FinancialReportsA4Vertical = 330110;

    /** 部門別集計表 */
    static readonly DepartmentSummary = 330200;

    /** 残高一覧表 */
    static readonly BalanceList = 330300;

    /** 部門残高一覧表 */
    static readonly DepartmentBalanceList = 330400;

    /** 共通補助残高一覧表 */
    static readonly CommonAccountDetailBalanceList = 330500;

    /** 科目別補助残高一覧表 */
    static readonly AccountDetailBalanceList = 330510;

    /** 補助残高一覧 */
    static readonly AccountDetailList = 330510;

    /** 科目別補助元帳 */
    static readonly SubsidiaryLedger = 340310;

    /** 仮受仮払消費税元帳 */
    static readonly ProvisionalReceptionPaymentTaxLedger = 370200;

    /** 消費税コード元帳 */
    static readonly TaxCodeLedger = 370300;

    /** 業種別元帳 */
    static readonly TypeCodeLedger = 370400;

    /** 比較財務報告書 */
    static readonly ComparativeFinancialStatement = 410110;

    /** 四半期比較財務報告書 */
    static readonly QuarterComparativeFinancialStatement = 410120;

    /** 部門別比較財務報告書 */
    static readonly DepartmentComparativeFinancialStatement = 410200;

    /** 科目別部門 */
    static readonly AccountDepartment = 410310;

    /** 月別比較 */
    static readonly MonthlyComparative = 410400;

    /** 資金日計表 */
    static readonly FundingDaily = 420110;

    /** 資金繰実績表 */
    static readonly FundingPerformance = 420120;

    /** 資金繰推移表 */
    static readonly FundingMonthly = 420130;

    /** 資金集計表 */
    static readonly FundingSummary = 420140;

    /** 資金繰実績・予定表 */
    static readonly FundingPerformanceSchedule = 420200;

    /** 仕入先管理表 */
    static readonly SupplierAdministration = 431011;

    /** 得意先管理表 */
    static readonly CustomerAdministration = 431021;

    /** 銀行管理表 */
    static readonly BankAdministration = 431031;

    /** 共通補助管理表 */
    static readonly CommonAccountDetailAdministration = 431100;

    /** 期日管理・明細表示 */
    static readonly DeadlineDetail = 501100;

    /** 期日管理・期日別集計表 */
    static readonly DeadlineAggregate = 501200;

    /** 期日管理・期日管理表 */
    static readonly DeadlineAdministration = 501300;

    /** 工事別集計表 */
    static readonly ConstructionSummary = 502100;

    /** 科目別工事集計表 */
    static readonly AccountConstructionSummary = 502110;

    /** 工事管理表 */
    static readonly ConstructionAdministration = 502120;

    /** 工事元帳 */
    static readonly ConstructionLedger = 502300;

    /** 工事元帳 */
    static readonly KojiLedger = 502300;

    /** 工事別一覧表 */
    static readonly ConstructionList = 502500;

    /** 三期比較損益計算書 */
    static readonly PhaseThreeComparativeIncomeStatement = 504100;

    /** 三期比較推移損益計算書 */
    static readonly PhaseThreeComparativeMonthlyIncomeStatement = 504200;

    /** 受取手形管理表・明細表 */
    static readonly NotesReceivableManagementDetail = 57410101;

    /** 支払手形管理表・明細表 */
    static readonly NotesPayableManagementDetail = 57410111;

    /** 工事登録(簡略) */
    static readonly KojiRegistrationListSimple = 2302100;

    /** 工事登録(詳細) */
    static readonly KojiRegistrationListDetails = 2302101;

    /** 完成工事情報 */
    static readonly KojiCompleteInformation = 2302300;

    /** 補助残高一覧（部門） */
    static readonly BalanceListBmn = 330441;

    /** 補助残高一覧（セグメント1） */
    static readonly BalanceListSegment1 = 330442;

    /** 補助残高一覧（セグメント2） */
    static readonly BalanceListSegment2 = 330443;

    /** 補助残高一覧（セグメント3） */
    static readonly BalanceListSegment3 = 330444;

    /** 補助残高一覧（セグメント4） */
    static readonly BalanceListSegment4 = 330445;

    /** 補助残高一覧（セグメント5） */
    static readonly BalanceListSegment5 = 330446;

    /** 工事台帳 */
    static readonly ConstructionRegister = 502400;

    /** 決算書 */
    static readonly StatementOfAccounts = 360100;

    /** 部門元帳 */
    static readonly DepartmentLedger = 340200;

    /** 補助元帳 */
    static readonly AuxiliaryLedger = 340300;

    /** 摘要元帳 */
    static readonly FixingSummaryLedger = 340400;
}

/**
 * バッチコード
 */
export class BatchCode {

    /** 月次確定処理 */
    static readonly MonthlyConfirmed = 1;

    /** 決算更新 */
    static readonly ClosingUpdate = 2;

    /** 残高再移送 */
    static readonly BalanceRetransfer = 3;

    /** マスタ更新 */
    static readonly MasterUpdate = 4;

    /** マスタ再計算 */
    static readonly MasterRetalculation = 5;

    /** バックアップ */
    static readonly Backup = 6;

    /** リストア */
    static readonly Restore = 7;

    /** データインストール */
    static readonly DataInstal = 8;

    /** 顧問先データ抽出 */
    static readonly ClientDataSelect = 9;

    /** 会計事務所データアッロード */
    static readonly OfficeDataUpload = 10;

    /** 会社情報削除（会計） */
    static readonly CompanyDataDelete = 11;

    /** 給与仕訳作成 */
    static readonly PayrollJournalCreating = 12;

    /** 会社情報削除（給与） */
    static readonly PayrollCompanyDataDelete = 13;

    /** 解約済データ削除(会計) */
    static readonly CancelledDataDelete = 14;

    /** 解約済データ削除(給与) */
    static readonly PayrollCancelledDataDelete = 15;

    /** 解約済データ削除(全解約) */
    static readonly AllCancelledDataDelete = 16;

    /** バックアップ(給与) */
    static readonly PayrollBackup = 17;

    /** リストア(給与) */
    static readonly PayrollRestore = 18;

    /** 直販_事務所会社データ移行 */
    static readonly MigrateMypage2Office = 19;

    /** データ再インストール */
    static readonly DataReinstall = 20;

    /** 給与データ取込(給与) */
    static readonly PayrollPaymentImport = 21;

    /** 年調CSV取込(給与) */
    static readonly PayrollAdjustmentImport = 22;

    /** 年調CSV出力(給与) */
    static readonly PayrollAdjustmentExport = 23;

    /** データ移行 */
    static readonly Migration = 24;
}

/**
 * ステータス
 */
export class Status {

    /** 処理待ち */
    static readonly Waiting = 0;

    /** 実行中 */
    static readonly Runtime = 1;

    /** 完了 */
    static readonly Completion = 2;

    /** 警告 */
    static readonly Warning = 7;

    /** キャンセル済み */
    static readonly Canceled = 8;

    /** エラー終了 */
    static readonly ErrorExit = 9;
}

/**
 * 部門採用区分
 */
export class BmnUseKbn {

    /** なし */
    static readonly None = 0;

    /** あり */
    static readonly Setting = 1;

    /** 共通部門 */
    static readonly Common = 2;
}

/**
 * セグメント採用区分
 */
export class SegUseKbn {

    /** なし */
    static readonly None = 0;

    /** あり */
    static readonly Setting = 1;

    /** 共通セグメント */
    static readonly Common = 2;
}

/**
 * 売上科目コード
 */
export class SalesKmkCode {

    /** 期首棚卸高 */
    static readonly BeginningTermStocktaking = 0;

    /** 原価率 */
    static readonly CostRate = 1;
}

/**
 * 完了区分
 */
export class CompleteKbn {

    /** 未完了 */
    static readonly UnCompleted = 0;

    /** 完了 */
    static readonly Completed = 1;

    /** エラー */
    static readonly Error = 2;
}

/**
 * 新公益法人科目性格コード
 */
export class CharCodeCharitableNew {

    /** 複合 */
    static readonly Composite = 1;

    /** 非会計科目 */
    static readonly NonAccounting = 2;

    /** 非会計単独科目 */
    static readonly NonAccountingIndependent = 3;

    /** 現金預金 */
    static readonly CashDeposits = 31;

    /** 資金科目 */
    static readonly Fund = 32;

    /** 貸借科目 */
    static readonly DebitCredit = 33;

    /** 収支フロー兼用 */
    static readonly BalanceFlow = 34;

    /** フロー専用科目 */
    static readonly FlowOnly = 35;

    /** 収支専用科目 */
    static readonly BalanceOnly = 36;

    /** 特別科目 */
    static readonly Special = 38;

    /** 内部取引科目 */
    static readonly InsiderTrading = 40;
}

/**
 * コードステータス
 */
export class CodeStatus {

    /** 指定なし */
    static readonly None = 0;
}

/**
 * 自動仕訳展開区分
 */
export class AutoSwkDeploymentKbn {

    /** なし */
    static readonly None = 0;

    /** あり */
    static readonly Deployment = 1;

    /** 仕訳組合せチェックなし */
    static readonly NoCheck = 2;
}

/**
 * 検索方法(貸借/固定摘要) 
 */
export class SearchKbnCDFixingSummary {

    /** なし */
    static readonly None = 0;

    /** 自動検索 */
    static readonly AutoSearch = 1;

    /** 同一検索 */
    static readonly SameSearch = 2;

    /** いずれか検索 */
    static readonly AnySearch = 3;
}

/**
 * 特殊摘要置換
 */
export class ExeSummaryReplace {

    /** 借方科目別補助 */
    static readonly DebitKmkHojyo = "$[10]";

    /** 借方補助1 */
    static readonly DebitHojyo1 = "$[11]";

    /** 借方補助2 */
    static readonly DebitHojyo2 = "$[12]";

    /** 借方部門 */
    static readonly DebitBmn = "$[20]";

    /** 借方セグメント1 */
    static readonly DebitSegment1 = "$[21]";

    /** 貸方科目別補助1 */
    static readonly CreditKmkHojyo = "$[50]";

    /** 貸方補助1 */
    static readonly CreditHojyo1 = "$[51]";

    /** 貸方補助2 */
    static readonly CreditHojyo2 = "$[52]";

    /** 貸方部門 */
    static readonly CreditBmn = "$[60]";

    /** 貸方セグメント1 */
    static readonly CreditSegment1 = "$[61]";

    /** 借方工事 */
    static readonly DebitKoji = "$[30]";

    /** 貸方工事 */
    static readonly CreditKoji = "$[70]";
}

/**
 * 固定摘要置換
 */
export class FixingSummaryReplace {

    /** 固定摘要1 */
    static readonly FixingSummary1 = "#[1]";

    /** 固定摘要2 */
    static readonly FixingSummary2 = "#[2]";

    /** 固定摘要3 */
    static readonly FixingSummary3 = "#[3]";

    /** 固定摘要4 */
    static readonly FixingSummary4 = "#[4]";

    /** 固定摘要5 */
    static readonly FixingSummary5 = "#[5]";
}

/**
 * 部門出力方法
 */
export class BmnRsvKbn {

    /** 出力順序指定 */
    static readonly OutputOrder = 0;

    /** 範囲指定（コード順） */
    static readonly CodeRangeSpecification = 1;
}

/**
 * バランスチェック結果コード
 */
export class BalanceCheckResultCode {

    /** 正常 */
    static readonly Normal = 0;

    /** 棚卸科目バランスエラー */
    static readonly TanaKmkError = -1;

    /** 複合科目バランスエラー */
    static readonly ComplexKmkError = -2;

    /** 期首残高バランスエラー */
    static readonly BeginningTermError = -3;

    /** 繰越利益剰余金バランスエラー */
    static readonly TransferProfitError = -4;

    /** 合計科目バランスエラー */
    static readonly TotalKmkError = -8;
}

/**
 * 帳票分類
 */
export class RepoType {

    /** 貸借対照表 */
    static readonly BalanceSheet = 1;

    /** 損益計算書 */
    static readonly ProfitAndLoss = 2;

    /** 販管費内訳 */
    static readonly Selling = 3;

    /** なし */
    static readonly None = 1;

    /** 製造原価報告書 */
    static readonly ProductionCostReport = 4;

    /** 株主資本等変動計算書 */
    static readonly StatementsOfShareholdersEquity = 7;

    /** 個別注記表 */
    static readonly NotesToFinancialStatements = 8;

    /** 報告・監査年月日（役員、監査人） */
    static readonly DateOfReportAndAudit = 10;
}

/**
 * 共通仕入部門区分
 */
export class CmnJournalizeBmnKbn {

    /** 対象外 */
    static readonly NonTarget = 0;

    /** 課税・非課税売上共通課税仕入部門 */
    static readonly TaxCmnJournalize = 1;

    /** 非課税売上対応課税仕入部門 */
    static readonly TaxExemptionJournalize = 2;
}

/**
 * 推移財務報告書レイアウトNO
 */
export class MonthlyFinancialStatementLayoutNo {

    /** 推移 */
    static readonly Change = 0;

    /** 総合推移 */
    static readonly GeneralChange = 1;

    /** 推移（決算あり） */
    static readonly ChangeFourthQuarter = 2;

    /** 総合推移（決算あり） */
    static readonly GeneralChangeFourthQuarter = 3;

    /** 総合推移（前年度末比較） */
    static readonly GeneralChangeLastYear = 4;

    /** 総合推移（収支計算書） */
    static readonly GeneralChangeEarnings = 5;
}

/**
 * 財務報告書レイアウトNO
 */
export class FinancialStatementLayoutNo {

    /** 財務報告書 */
    static readonly FinancialStatement = 0;

    /** 科目コードあり */
    static readonly KmkCodeSetting = 0;

    /** 科目コードなし */
    static readonly KmkCodeNone = 1;

    /** 財務報告書(A4縦) */
    static readonly FinancialStatementA4 = 2;

    /** Ａ４科目コードあり */
    static readonly A4KmkCodeSetting = 3;

    /** Ａ４科目コードなし */
    static readonly A4KmkCodeNone = 4;
}

/**
 * 貸借対照表出力区分
 */
export class BalanceSheetOutputKbn {

    /** 出力なし */
    static readonly None = 0;

    /** 発生を出力 */
    static readonly Inp = 1;

    /** 残高を出力 */
    static readonly Balance = 2;
}

/**
 * 元帳要求動作
 */
export class LedgerAction {

    /** 表示 */
    static readonly Display = 0;

    /** 印刷 */
    static readonly Print = 1;
}

/**
 * 元帳出力形式
 */
export class LedgerFormat {

    /** A4縦白紙 */
    static readonly A4 = 0;

    /** B5縦白紙 */
    static readonly B5 = 1;

    /** A4縦白紙（補助名欄付） */
    static readonly A4HojyoName = 2;

    /** A4縦白紙（補助コード欄付） */
    static readonly A4HojyoCode = 3;

    /** A4縦白紙（仕入欄付） */
    static readonly A4Purchase = 4;

    /** A4縦白紙(相手科目名欄付) */
    static readonly A4KmkName = 2;

    /** A4縦白紙(相手科目コード欄付) */
    static readonly A4KmkCode = 3;
}

/**
 * 元帳区分
 */
export class LedgerKbn {

    /** 出納帳 */
    static readonly CashJournal = 0;

    /** 総勘定元帳 */
    static readonly GeneralLedger = 1;

    /** 科目別補助元帳 */
    static readonly SubsidiaryLedger = 2;

    /** 元帳（検証） */
    static readonly ValidationLedger = 3;
}

/**
 * 合計転記区分
 */
export class TotalPostingKbn {

    /** 通常出力 */
    static readonly NormalOutput = 0;

    /** 合計転記 */
    static readonly TotalPosting = 1;
}

/**
 * 元帳特殊科目区分
 */
export class LedgerSpeKmkKbn {

    /** 通常科目 */
    static readonly NormalKmk = 0;

    /** 損益勘定 */
    static readonly PlAccount = 1;

    /** 当期未処分利益／元入金 */
    static readonly CurrentTermUntreated = 2;
}

/**
 * 科目出力順序
 */
export class OutputOrder {

    /** コード順 */
    static readonly Code = 0;
}

/**
 * 科目出力順序マスタ区分
 */
export class KmkOrderMasterKbn {

    /** 勘定科目 */
    static readonly CountingKmk = 1;

    /** タイトル科目 */
    static readonly TitleKmk = 7;

    /** 段組変更 */
    static readonly StageSetChanges = 97;

    /** 強制空白 */
    static readonly ForceBlank = 98;

    /** 強制改頁 */
    static readonly ForcePageBreak = 99;

    /** 資金繰科目 */
    static readonly Financing = 3;

    /** 工事合計科目 */
    static readonly KojiTotal = 8;

    /** 決算書印刷項目 */
    static readonly FinancialStatements = 91;

    /** 工事特殊科目 */
    static readonly KojiExeKmk = 92;

    /** 資金繰特殊科目 */
    static readonly FinancingExeKmk = 93;
}

/**
 * キー割り当てキーコード
 */
export class KeyAssignmentKeyCode {

    /** 「Tab」キーコード */
    static readonly Tab = 9;

    /** 「*」キーコード */
    static readonly Asterisk = 42;

    /** 「+」キーコード */
    static readonly Plus = 43;

    /** 「,」キーコード */
    static readonly Comma = 44;

    /** 「.」キーコード */
    static readonly Period = 46;

    /** 「/」キーコード */
    static readonly Slash = 47;
}

/**
 * 摘要の表示スタイルクラス
 */
export class SummaryStyleClass {

    /** 摘要残 */
    static readonly SummaryBalance = "tekiyo-zan";

    /** 固定摘要 */
    static readonly Classification2 = "tekiyo-kotei";

    /** 特殊摘要 */
    static readonly SpeSummary = "tekiyo-tokusyu";
}

/**
 * 伝票遷移区分
 */
export class SlipTransitionKbn {

    /** なし */
    static readonly Financing = 0;

    /** 前伝票 */
    static readonly PreviousSlip = 1;

    /** 後伝票 */
    static readonly AfterSlip = 2;

    /** 削除 */
    static readonly SetDown = 3;
}

/**
 * 0円金額出力区分
 */
export class ZeroAmountOutputKbn {

    /** 発生のみ出力 */
    static readonly OccursOnly = 0;

    /** 0円の科目も出力 */
    static readonly IncludeZero = 1;

    /** 貸借発生のみ出力 */
    static readonly LoanOnly = 2;

    /** 実績発生のみ出力 */
    static readonly ResultsOnly = 3;
}

/**
 * 登録最大件数
 */
export class RegistMaxNumber {

    /** 出力パターン */
    static readonly OutputPtn = 10;
}

/**
 * 配賦先マスタ合計区分
 */
export class AllocationSumKbn {

    /** 実在 */
    static readonly Real = 0;

    /** 合計 */
    static readonly Total = 2;

    /** 配賦基準科目 */
    static readonly AllocationKmk = 2;
}

/**
 * 元帳明細区分
 */
export class LedgerDetailKbn {

    /** 前期・前月繰越 */
    static readonly CarryOverPreviousTerm = 0;

    /** 前期・前月ページ */
    static readonly CarryOverPreviousPage = 1;

    /** 通常 */
    static readonly Normal = 2;

    /** 合併合計転記 */
    static readonly MergerTotalPosting = 3;

    /** 内税起票額 */
    static readonly SlipIssuanceTax = 4;

    /** 損益勘定 */
    static readonly PLAccount = 5;

    /** 繰越・振替 */
    static readonly CarryOverNextTerm = 6;

    /** 次頁繰越 */
    static readonly CarryOverNextPage = 7;

    /** 日計 */
    static readonly DailyTotal = 8;

    /** 月計 */
    static readonly MonthlyTotal = 9;

    /** 累計 */
    static readonly Cumulative = 10;

    /** 合計 */
    static readonly Total = 11;

    /** 工事配賦金額 */
    static readonly ConstructionAllocation = 12;

    /** 工事配賦金額 */
    static readonly KojiAllocation = 12;

    /** 月末税抜 */
    static readonly MonthEndTaxExcluded = 13;

    /** 月次構成比 */
    static readonly MonthlyRatio = 14;

    /** 累計構成比 */
    static readonly CumulativeRatio = 15;

    /** 予算 */
    static readonly Budget = 16;

    /** 予算残 */
    static readonly BudgetRemaining = 17;
}

/**
 * 計算年度数
 */
export class CalcYear {

    /** 前年 */
    static readonly LastYear = -10000;

    /** 前々年 */
    static readonly TwoYearsBefore = -20000;

    /** 翌年 */
    static readonly NextYear = 10000;
}

/**
 * 推移集計方法
 */
export class AggregationMethod {

    /** 月次推移 */
    static readonly Monthly = 0;

    /** 年次推移 */
    static readonly Annual = 1;
}

/**
 * 合計行区分
 */
export class TotalRowKbn {

    /** 合計行以外 */
    static readonly None = 0;

    /** 合計行 */
    static readonly Total = 1;
}

/**
 * 基準科目区分
 */
export class HaifuMBKjnKmkKbn {

    /** 実在 */
    static readonly MasterReal = 0;

    /** 合計 */
    static readonly MasterTotal = 2;

    /** 実在 */
    static readonly KizyunKmkReal = 0;

    /** 合計 */
    static readonly KizyunKmkTotal = 1;

    /** 配賦基準科目 */
    static readonly KizyunKmkKizyunKmk = 2;
}

/**
 * 財務報告書出力条件単位
 */
export class FinanceReportOutputTaniKbn {

    /** 円 */
    static readonly Yen = 0;

    /** 千円 */
    static readonly ThousandYen = 1;

    /** 万円 */
    static readonly ManYen = 2;

    /** 百万円 */
    static readonly OneMillionYen = 3;
}

/**
 * 伝票遷移元画面区分
 */
export class OriginSlipTransitionDispKbn {

    /** 仕訳帳 */
    static readonly SwkDiary = 1;

    /** 出納帳 */
    static readonly CashBook = 2;

    /** 元帳 */
    static readonly Ledger = 3;
}

/**
 * 進捗状況区分
 */
export class ProgressSituation {

    /** 印刷 */
    static readonly Print = 0;

    /** バッチ */
    static readonly Batch = 1;
}

/**
 * 摘要残高一覧レイアウトNO
 */
export class AccountMemorandumLayoutNo {

    /** 月次 */
    static readonly Monthly = 0;

    /** 年次 */
    static readonly Annual = 1;

    /** 年次（決算あり） */
    static readonly AnnualFourthQuarter = 2;

    /** 月次（A4縦） */
    static readonly MonthlyA4Vertical = 3;
}

/**
 * 検索ＮＯ印刷区分
 */
export class SNoPrnKbn {

    /** 印刷しない */
    static readonly Notprinting = 0;

    /** 印刷する */
    static readonly Printing = 1;
}

/**
 * 日計を出力する
 */
export class DaySum {

    /** 出力なし */
    static readonly NotOutput = 0;

    /** 日計表を出力する */
    static readonly ValidateSpecificDateOutput = 1;

    /** 伝票計を出力する */
    static readonly SlipOutput = 2;
}

/**
 * マスタ登録ドリルダウン遷移先画面名(URL指定用)
 */
export class MasterDispName {

    /** 科目別補助 */
    static readonly CountingKmk = "RegisterAccountDetail";

    /** 銀行 */
    static readonly Bank = "RegisterBankInformation";

    /** 取引先 */
    static readonly Client = "RegisterCustomerSupplier";

    /** 社員 */
    static readonly Staff = "RegisterEmployee";

    /** 汎用補助 */
    static readonly GeneralPurposeAuxiliary = "RegisterGeneralAccountDetail";

    /** 部門 */
    static readonly Bmn = "RegisterDepartment";

    /** セグメント */
    static readonly Segment1 = "RegisterSegment";

    /** 工事 */
    static readonly Koji = "RegisterConstruction";
}

/**
 * 項目NO
 */
export class UserInfo {

    /** 摘要残表示色 */
    static readonly SummaryDisplayColor = 11;

    /** 固定摘要表示色 */
    static readonly FixingSummaryDisplayColor = 12;

    /** 特殊摘要表示色 */
    static readonly SpeSummaryDisplayColor = 13;

    /** 「CR」キーの割付け */
    static readonly AllocationCR = 23;

    /** 「00」キーの割付け */
    static readonly Allocation00 = 24;

    /** 「000」キーの割付け */
    static readonly Allocation000 = 25;

    /** 標準元帳印刷用紙 */
    static readonly LedgerDefaultPrintLayout = 22;

    /** 標準元帳印刷設定 */
    static readonly LedgerDefaultPrintSetting = 34;

    /** 元帳の消費税率出力区分 */
    static readonly TaxRateOutputKbn = 42;

    /** ウインドウ表示絞込み区分 */
    static readonly WindowDisplayNarrowingKbn = 53;

    /** 管理表見出し文字色 */
    static readonly ReportHeadingColor = 501;

    /** 管理表明細文字色 */
    static readonly ReportDetailColor = 502;

    /** 管理表罫線色 */
    static readonly ReportLineColor = 503;

    /** 管理表ヘッダ・フッタ文字色 */
    static readonly ReportHeaderFooterColor = 504;

    /** 管理表タイトル文字色 */
    static readonly ReportTitleColor = 505;

    /** 管理表の網掛け淡色 */
    static readonly ReportBackLightColor = 506;

    /** 管理表の網掛け中間色 */
    static readonly ReportBackNeutralColor = 507;

    /** 管理表の網掛け濃色 */
    static readonly ReportBackDeepColor = 508;

    /** 摘要ウィンドウ表示区分(ウィンドウ表示区分) */
    static readonly SummaryWindowDisplayKbn = 1011;

    /** 摘要ウィンドウ表示パターン(固定摘要ｳｨﾝﾄﾞｳ表示ﾊﾟﾀｰﾝ) */
    static readonly SummaryWindowDisplayPtn = 1012;

    /** 摘要ウィンドウ選択方法(固定摘要ｳｨﾝﾄﾞｳ選択方法) */
    static readonly SummaryWindowCoiceMethod = 1013;

    /** 摘要カナ漢字優先区分(摘要欄カナ漢字優先区分) */
    static readonly SummaryKanaKanjiKbn = 1014;

    /** 定型仕訳ウィンドウ表示パターン */
    static readonly FixedVoucherWindowDisplayKbn = 1021;

    /** 消費税コードウィンドウ表示区分 */
    static readonly TaxCodeWindowDisplayKbn = 1031;

    /** 製造科目表示区分 */
    static readonly ManufacturingKmkDispKbn = 1041;

    /** 和暦/西暦区分 */
    static readonly CalendarKbn = 1051;

    /** 連想入力の検索方式一覧 */
    static readonly RensoInputkbn = 1061;

    /** 移行システム区分 */
    static readonly MigrationSysKbn = 1071;
}

/**
 * 消費税取引コード
 */
export class TradeTaxCode {

    /** 課税取引(内税) */
    static readonly TaxationDealIncluded = 10;

    /** 課税取引(外税) */
    static readonly TaxationDealExcluded = 20;

    /** 非課税取引 */
    static readonly ExemptionDeal = 30;

    /** 不課税取引(精算取引) */
    static readonly TaxationLessnessDeal = 40;

    /** 10%入力区分 */
    static readonly TenPercent = 8;

    /** 8%軽減入力区分 */
    static readonly ReducedEightPercent = 16;
}

/**
 * 補助マスタ正式名称桁数
 */
export class HojyoMALongNameDigit {

    /** 銀行 */
    static readonly Bank = 40;

    /** 取引先 */
    static readonly Client = 60;

    /** 社員 */
    static readonly Staff = 20;

    /** 汎用補助 */
    static readonly GeneralPurposeAuxiliary = 60;

    /** 部門 */
    static readonly Bmn = 30;

    /** セグメント1 */
    static readonly Segment1 = 30;

    /** 工事 */
    static readonly Koji = 60;
}

/**
 * レイアウト種類
 */
export class LayoutType {

    /** 科目欄2個 */
    static readonly KmkField2 = 0;

    /** 科目欄3個 */
    static readonly KmkField3 = 1;

    /** 科目欄4個 */
    static readonly KmkField4 = 2;
}

/**
 * 摘要表示置き換え文字
 */
export class SummaryReplaceString {

    /** 特殊摘要 */
    static readonly ExeSummary = "##TokuTeki##";

    /** 摘要残高管理 */
    static readonly BalanceManagementSummary = "##TekiZan##";

    /** 固定摘要 */
    static readonly FixSummary = "##KoteiTeki##";

    /** 摘要終了 */
    static readonly SummaryEnd = "##TekiEnd##";
}

/**
 * 出納帳種別
 */
export class CashBookClassification {

    /** 現金出納帳 */
    static readonly CashCashBook = 1;

    /** 預金出納帳 */
    static readonly DepositCashbook = 2;

    /** 売掛帳 */
    static readonly CreditSalesBook = 3;

    /** 買掛帳 */
    static readonly PurchaseCreditBook = 4;
}

/**
 * 期日未定
 */
export class DeadlineUndecided {

    /** 入力値 */
    static readonly InputValue = 999999;

    /** 登録値 */
    static readonly RegisteredValue = 29991231;
}

/**
 * 待機時間
 */
export class PollingTime {

    /** 即時印刷の確認待機時間 */
    static readonly PrintPollingTime = 1000;

    /** 通常印刷後の件数確認待機時間 */
    static readonly UnReadCountPollingTime = 5000;
}

/**
 * 特殊摘要コード
 */
export class ExeSummaryCode {

    /** 借方科目別補助 */
    static readonly DebitKmkHojyo = 10;

    /** 借方補助1 */
    static readonly DebitHojyo1 = 11;

    /** 借方補助2 */
    static readonly DebitHojyo2 = 12;

    /** 借方部門 */
    static readonly DebitBmn = 20;

    /** 借方セグメント1 */
    static readonly DebitSegment1 = 21;

    /** 借方工事 */
    static readonly DebitKoji = 30;

    /** 貸方科目別補助1 */
    static readonly CreditKmkHojyo = 50;

    /** 貸方補助1 */
    static readonly CreditHojyo1 = 51;

    /** 貸方補助2 */
    static readonly CreditHojyo2 = 52;

    /** 貸方部門 */
    static readonly CreditBmn = 60;

    /** 貸方セグメント1 */
    static readonly CreditSegment1 = 61;

    /** 貸方工事 */
    static readonly CreditKoji = 70;
}

/**
 * 配賦モード
 */
export class HaifuMode {

    /** 解除 */
    static readonly Release = 0;

    /** 入力 */
    static readonly Setting = 1;
}

/**
 * ファイル種類
 */
export class FileTypeKbn {

    /** PDF */
    static readonly PDF = 0;

    /** EXCEL */
    static readonly EXCEL = 1;
}

/**
 * ファイル名称
 */
export class FileName {

    /** PDF */
    static readonly PDF = "PDF";

    /** EXCEL */
    static readonly EXCEL = "Excel";
}

/**
 * 標準元帳印刷用紙
 */
export class UserInfoLedgerDefaultPrintLayout {

    /** A4縦白紙 */
    static readonly A4 = 0;

    /** A4専用用紙 */
    static readonly A4Designed = 1;

    /** A4専用用紙専用用紙(両面) */
    static readonly A4DesignedBothSides = 2;

    /** B5縦白紙 */
    static readonly B5 = 10;

    /** B5専用用紙 */
    static readonly B5Designed = 11;

    /** B5専用用紙(両面) */
    static readonly B5DesignedBothSides = 12;

    /** A4白紙(補助名欄あり) */
    static readonly A4HojyoName = 20;

    /** A4白紙(補助コード欄あり) */
    static readonly A4HojyoCode = 21;

    /** A4横白紙 */
    static readonly A4Horizontal = 22;

    /** A4縦白紙（仕入欄付） */
    static readonly A4Purchase = 23;
}

/**
 * 元帳標準科目出力順序NO
 */
export class StandardLedgerKmkOrderEntNo {

    /** コード順 */
    static readonly OrderByCode = 99;

    /** 指定科目 */
    static readonly SpecifyKmk = 100;
}

/**
 * 月次確定解除設定
 */
export class MonthlyConfirmReleaseSetting {

    /** 解除可能 */
    static readonly CanRelease = 0;

    /** 確定済みの為解除不可（顧問先担当者） */
    static readonly ConfirmClient = 1;

    /** 確定済みの為解除不可（事務所担当者） */
    static readonly ConfirmOffice = 2;

    /** 同期済みの為解除不可（顧問先担当者） */
    static readonly SyncClient = 3;

    /** 同期済みの為解除確認（事務所担当者） */
    static readonly SyncOffice = 4;
}

/**
 * 印刷ダイアログ項目
 */
export class PrintDialogItem {

    /** 種類：エクセル */
    static readonly FileExcel = "filetypeexcel";

    /** 日時 */
    static readonly Date = "dateformat";

    /** ページ */
    static readonly Page = "pageformat";
}

/**
 * 原価率区分
 */
export class CostRateKbn {

    /** なし */
    static readonly None = 0;

    /** 入力原価率 */
    static readonly Input = 1;

    /** 登録原価率 */
    static readonly Registration = 2;
}

/**
 * 工事元帳出力形式
 */
export class ConstructionLedgerLayoutNo {

    /** A4横白紙 */
    static readonly A4 = 0;
}

/**
 * 工事元帳出力形式
 */
export class KojiLedgerLayoutNo {

    /** A4横白紙 */
    static readonly A4 = 0;
}

/**
 * 工事残高登録区分
 */
export class KojiBalanceRegistKbn {

    /** 期首残高 */
    static readonly BeginningBalance = 1;

    /** 期首残高(入金勘定) */
    static readonly PaymentAccount = 2;

    /** 入金累計額 */
    static readonly PaymentCumulative = 3;
}

/**
 * 工事管理種別
 */
export class KojiControlType {

    /** なし */
    static readonly None = 0;

    /** B/S型 */
    static readonly BS = 1;

    /** P/L型 */
    static readonly PL = 2;

    /** P/L型(未完・完成区別なし) */
    static readonly PLIncomplete = 3;
}

/**
 * 印刷出力順序
 */
export class PrintOutputOrder {

    /** コード順 */
    static readonly Code = 0;

    /** 連想順 */
    static readonly RenChar = 1;
}

/**
 * 科目残高一覧レイアウトNO
 */
export class AccountBalanceLayoutNo {

    /** 月次 */
    static readonly Monthly = 0;

    /** 年次 */
    static readonly Annual = 1;

    /** 年次（決算あり） */
    static readonly AnnualFourthQuarter = 2;

    /** 月次（A4縦） */
    static readonly MonthlyA4Vertical = 3;
}

/**
 * 補助残高一覧レイアウトNO
 */
export class AccountDetailLayoutNo {

    /** 月次 */
    static readonly Monthly = 0;

    /** 年次 */
    static readonly Annual = 1;

    /** 年次（決算あり） */
    static readonly AnnualFourthQuarter = 2;

    /** 月次（A4縦） */
    static readonly MonthlyA4Vertical = 3;
}

/**
 * 工事入力区分
 */
export class KojiInputKbn {

    /** 入力可 */
    static readonly InputPossible = 0;

    /** 原価科目は不可 */
    static readonly CostitemImpossible = 1;

    /** 入力不可 */
    static readonly InputImpossible = 9;
}

/**
 * 固定摘要ｳｨﾝﾄﾞｳ表示ﾊﾟﾀｰﾝ
 */
export class FixingSummaryDisplayPtn {

    /** 簡略型（4列表示） */
    static readonly SimpleLine4 = 0;

    /** 詳細型（3列表示） */
    static readonly IndepthLine3 = 1;
}

/**
 * 名称管理情報
 */
export class NamInfoKbn {

    /** 連携ID */
    static readonly UserID = 912;

    /** メールアドレス */
    static readonly EMailAddress = 911;

    /** パスワード */
    static readonly Password = 913;

    /** 予算１名称 */
    static readonly Budget1Name = 11;

    /** 予算２名称 */
    static readonly Budget2Name = 12;

    /** 予算３名称 */
    static readonly Budget3Name = 13;

    /** 予算４名称 */
    static readonly Budget4Name = 14;

    /** 第1四半期決算月1 */
    static readonly FirstQuarterClosing1 = 111;

    /** 第1四半期決算月2 */
    static readonly FirstQuarterClosing2 = 112;

    /** 第1四半期決算月3 */
    static readonly FirstQuarterClosing3 = 113;

    /** 中間決算月1 */
    static readonly SecondQuarterClosing1 = 121;

    /** 中間決算月2 */
    static readonly SecondQuarterClosing2 = 122;

    /** 中間決算月3 */
    static readonly SecondQuarterClosing3 = 123;

    /** 第3四半期決算月1 */
    static readonly ThirdQuarterClosing1 = 131;

    /** 第3四半期決算月2 */
    static readonly ThirdQuarterClosing2 = 132;

    /** 第3四半期決算月3 */
    static readonly ThirdQuarterClosing3 = 133;

    /** 決算月1 */
    static readonly FourthQuarterClosing1 = 141;

    /** 決算月2 */
    static readonly FourthQuarterClosing2 = 142;

    /** 決算月3 */
    static readonly FourthQuarterClosing3 = 143;

    /** 軽減税率マーク */
    static readonly ReducedRateMark = 401;

    /** 顧問先ID */
    static readonly ClientID = 800;

    /** MJSキーワード辞書バージョン */
    static readonly KmkDictionaryMjsKeyVersion = 1000;

    /** 業種コード別キーワード辞書バージョン */
    static readonly KmkDictionaryGCodeVersion = 1001;
}

/**
 * 画面網掛け色
 */
export class DisplayBackDeepColor {

    /** 網掛け濃色 */
    static readonly BackDeepColor = "C0C0C0";

    /** 網掛け中間色 */
    static readonly BackNeutralColor = "D0D0D0";

    /** 網掛け淡色 */
    static readonly BackLightColor = "E0E0E0";
}

/**
 * データ区分
 */
export class SwkRegistDecisionKbn {

    /** 取込対象 */
    static readonly Target = 0;

    /** 削除 */
    static readonly Delete = 1;

    /** 重複削除 */
    static readonly DuplicateDelete = 2;

    /** 連結済 */
    static readonly AlreadyConnected = 9;
}

/**
 * 取込対象区分
 */
export class CaptureTargetKbn {

    /** 銀行 */
    static readonly Bank = 0;

    /** クレジットカード */
    static readonly Card = 1;

    /** CSVファイル */
    static readonly CSV = 3;

    /** レシート */
    static readonly Receipt = 4;

    /** CSV（入出金明細） */
    static readonly DWCSV = 5;

    /** XML */
    static readonly XML = 6;
}

/**
 * シーケンス名
 */
export class SequenceName {

    /** 出力パターン */
    static readonly SequenceOutputPtn = "SequenceOutputPtn";

    /** 工事契約情報のEntNo付番用シーケンス名 */
    static readonly SequenceKojiInfo2 = "SequenceKojiInfo2";

    /** 補助加算体系マスタ（２）のRecordNo付番用シーケンス名 */
    static readonly SequenceHojyoTree2Department = "SequenceHojyoTree2Department";

    /** 補助加算体系マスタ（２）のRecordNo付番用シーケンス名 */
    static readonly SequenceHojyoTree2Segment = "SequenceHojyoTree2Segment";

    /** CSV取込履歴のImportID付番用シーケンス名 */
    static readonly SequenceCSVImportHistory = "SequenceCSVImportHistory";

    /** CSV取込パターンのPatternNo付番用シーケンス名 */
    static readonly SequenceCSVImportLayoutPattern = "SequenceCSVImportLayoutPattern";

    /** スクレイピング銀行取引明細のSCSeqNo付番用シーケンス名 */
    static readonly SequenceSCBankDetail = "SequenceSCBankDetail";

    /** スクレイピングカード取引明細のSCSeqNo付番用シーケンス名 */
    static readonly SequenceSCCardDetail = "SequenceSCCardDetail";

    /** 仕訳辞書管理のEntNo付番用シーケンス名 */
    static readonly SequenceSwkDictionary = "SequenceSwkDictionary";

    /** 仕訳補完結果ヘッダのHeaderID付番用シーケンス名 */
    static readonly SequenceSwkCorrectionResultHead = "SequenceCorrectionResultHeaderID";

    /** データ移行履歴のMigrationID付番用シーケンス名 */
    static readonly SequenceMigrationHistory = "SequenceMigrationHistory";
}

/**
 * 決算更新処理タイプ
 */
export class FinalClosingActionType {

    /** 決算更新 */
    static readonly FinalClosing = 0;

    /** 期首残高移送 */
    static readonly OpeningBalanceTransfer = 1;
}

/**
 * 予算登録種類
 */
export class BudgetRegistrationKind {

    /** 全社 */
    static readonly AllCorp = 0;
}

/**
 * 出力期間
 */
export class OutputPeriod {

    /** 年次推移 */
    static readonly Annual = 1;

    /** 半期推移 */
    static readonly HalfTerm = 2;

    /** 四半期推移 */
    static readonly QuarterofTheYear = 3;
}

/**
 * 出力条件(半期推移)
 */
export class OutputConditionHalfTerm {

    /** 上半期 */
    static readonly FirstHalf = 1;

    /** 下半期 */
    static readonly LaterHalf = 2;
}

/**
 * 出力条件(四半期推移)
 */
export class OutputConditionQuarterofTheYear {

    /** 第1四半期 */
    static readonly FirstQuarter = 1;

    /** 第2四半期 */
    static readonly SecondQuarter = 2;

    /** 第3四半期 */
    static readonly ThirdQuarter = 3;

    /** 第4四半期 */
    static readonly FourthQuarter = 4;
}

/**
 * 単位値
 */
export class TaniValue {

    /** 円 */
    static readonly Yen = 1;

    /** 千円 */
    static readonly ThousandYen = 1000;

    /** 万円 */
    static readonly ManYen = 10000;

    /** 十万円 */
    static readonly OneHundredThousandYen = 100000;

    /** 百万円 */
    static readonly OneMillionYen = 1000000;
}

/**
 * 資金繰分析コード
 */
export class FinancingAnalyzeCode {

    /** なし */
    static readonly None = 0;

    /** 経常収入 */
    static readonly OrdinaryIncome = 1;

    /** 経常支出 */
    static readonly OrdinaryExpenditures = 2;

    /** 経常収支差額 */
    static readonly OrdinaryAccountBalance = 3;

    /** 資本的収入 */
    static readonly CapitalIncome = 4;

    /** 資本的支出 */
    static readonly CapitalExpenditures = 5;

    /** 資本収支差額 */
    static readonly CapitalAccountBalance = 6;

    /** 収入合計 */
    static readonly IncomeTotal = 7;

    /** 支出合計 */
    static readonly ExpendituresTotal = 8;

    /** 財務収入 */
    static readonly FinancialIncome = 9;

    /** 財務支出 */
    static readonly FinancialExpenditures = 10;

    /** 財務収支差額 */
    static readonly FinancialAccountBalance = 11;

    /** 前月繰越１ */
    static readonly CarryOverPreviousMonth1 = 12;

    /** 前月繰越２ */
    static readonly CarryOverPreviousMonth2 = 13;

    /** 次月繰越１ */
    static readonly CarryOverNextMonth1 = 14;

    /** 次月繰越２ */
    static readonly CarryOverNextMonth2 = 15;

    /** 預金預入れ */
    static readonly Deposit = 16;

    /** 預金引出し */
    static readonly Withdrawals = 17;
}

/**
 * 集計区分
 */
export class CashBMAddKbn {

    /** 登録 */
    static readonly Registration = 0;

    /** 合計集計 */
    static readonly Total = 1;
}

/**
 * 要求動作
 */
export class AllocationAction {

    /** 表示 */
    static readonly Display = 0;

    /** 印刷 */
    static readonly Print = 1;

    /** パターンコピー */
    static readonly PatternCopy = 2;

    /** パターンNO変更 */
    static readonly PatternNoChange = 3;
}

/**
 * 合計セット種類
 */
export class SumSetKind {

    /** 部門合計 */
    static readonly Bmn = 0;

    /** セグメント合計 */
    static readonly Segment = 1;

    /** 補助合計 */
    static readonly Auxiliary = 2;

    /** 科目別補助合計 */
    static readonly EachKmkAuxiliary = 3;
}

/**
 * 自動付番方法インデックス
 */
export class AutoNoTypeIndex {

    /** 任意入力 */
    static readonly Any = 0;

    /** システム自動付番（会社別） */
    static readonly AutoClient = 1;

    /** システム自動付番（担当者別） */
    static readonly AutoStaff = 2;

    /** 自動付番（前回＋１） */
    static readonly AutoAdd = 3;
}

/**
 * 管理方法インデックス
 */
export class DenNoKanriWayIndex {

    /** 月次型 */
    static readonly Monthly = 0;

    /** 通年型 */
    static readonly AllYear = 1;
}

/**
 * CSV形式
 */
export class BudgetRegistrationKmkCsvType {

    /** 勘定科目予算 */
    static readonly BudgetCountingKmk = 1;

    /** 科目別補助予算 */
    static readonly BudgetEachKmkAuxiliary = 2;

    /** 部門予算 */
    static readonly BudgetBmn = 3;

    /** セグメント1予算 */
    static readonly BudgetSegment1 = 4;

    /** セグメント2予算 */
    static readonly BudgetSegment2 = 5;

    /** セグメント3予算 */
    static readonly BudgetSegment3 = 6;

    /** セグメント4予算 */
    static readonly BudgetSegment4 = 7;

    /** セグメント5予算 */
    static readonly BudgetSegment5 = 8;
}

/**
 * 出力金額
 */
export class OutputAmount {

    /** 当期予算 */
    static readonly CurrentBudget = 1;

    /** 当年実績 */
    static readonly ThisYearResults = 2;

    /** 前年実績 */
    static readonly LastYearResults = 3;

    /** 前々年実績 */
    static readonly TwoYearsBeforeResults = 4;
}

/**
 * 対象マスター
 */
export class BudgetRegistrationTargetMaster {

    /** 勘定科目予算 */
    static readonly BudgetCountingKmk = 1;

    /** 科目別補助予算 */
    static readonly BudgetEachKmkAuxiliary = 2;

    /** 部門予算 */
    static readonly BudgetBmn = 3;

    /** 部門別科目別補助予算 */
    static readonly BudgetEachKmkAuxiliaryByBmn = 4;

    /** セグメント予算 */
    static readonly BudgetSegment = 5;

    /** セグメント別科目別補助予算 */
    static readonly BudgetEachKmkAuxiliaryBySegment = 6;

    /** 資金繰予算 */
    static readonly BudgetFinancing = 7;
}

/**
 * 予算金額最大桁数
 */
export class BudgetSumMaxLength {

    /** 円 */
    static readonly Yen = 14;

    /** 千円 */
    static readonly ThousandYen = 11;

    /** 万円 */
    static readonly ManYen = 10;

    /** 十万円 */
    static readonly OneHundredThousandYen = 9;

    /** 百万円 */
    static readonly OneMillionYen = 8;
}

/**
 * 消費税コード　ウィンドウ表示区分
 */
export class STaxCodeWindowDisplayKbn {

    /** 簡略型 */
    static readonly Simple = 0;

    /** 詳細型 */
    static readonly Indepth = 1;
}

/**
 * 棚卸仕訳入力方法
 */
export class StocktakingSwkInput {

    /** 全社共通に従う */
    static readonly Common = 0;

    /** 従来（期首／期末）方式 */
    static readonly EndOfPeriod = 1;

    /** 期末／期末方式 */
    static readonly EndOfEnd = 2;
}

/**
 * 挿入方法
 */
export class AccessKbn {

    /** コピー */
    static readonly Copy = 0;

    /** 移動 */
    static readonly Move = 1;
}

/**
 * 採用最大件数
 */
export class AdoptMaxCount {

    /** 補助 */
    static readonly Hojyo = 3;
}

/**
 * 残高登録入力区分
 */
export class BalanceInputKbn {

    /** 期首残高 */
    static readonly OpeningBalance = 1;

    /** 経過月発生高 */
    static readonly ElapsedMonthAccruals = 2;

    /** 期中残高 */
    static readonly DuringBalance = 3;
}

/**
 * 内部コード２
 */
export class NCode2 {

    /** 正規レコード */
    static readonly Regular = 1;
}

/**
 * 配置順
 */
export class PlacementOrder {

    /** 公益部門配置順序 */
    static readonly PublicInterestDivisionPlacementOrder = 51;
}

/**
 * 決算確定区分
 */
export class ClosingConfirmedKbn {

    /** 未確定 */
    static readonly UnDecided = 0;

    /** 確定済み */
    static readonly Confirmed = 1;
}

/**
 * マスタ登録権利
 */
export class MasterRegistrationRight {

    /** 参照のみ */
    static readonly Refer = 0;

    /** 通常処理 */
    static readonly Usually = 2;

    /** 決算確定の為参照のみ */
    static readonly Settle = 5;
}

/**
 * 要求
 */
export class ResponseCode {

    /** 不正な要求 */
    static readonly BadRequest = 400;
}

/**
 * 勘定科目帳票区分
 */
export class AccountRepoKbn {

    /** 実在科目登録リスト(簡略) */
    static readonly RealKmkRegisterListSimple = 1;

    /** 実在科目登録リスト(詳細) */
    static readonly RealKmkRegisterListDetail = 2;

    /** 合計科目登録リスト */
    static readonly TotalKmkRegisterList = 3;

    /** 科目体系登録リスト */
    static readonly KmkTreeRegisterList = 4;

    /** 実在科目加算リスト */
    static readonly RealKmkAdditionList = 5;

    /** 合計科目加算リスト */
    static readonly TotalKmkAdditionList = 6;

    /** 実在科目コード一覧 */
    static readonly RealKmkCodeList = 7;

    /** 合計科目コード一覧 */
    static readonly TotalKmkCodeList = 8;
}

/**
 * 性格コード
 */
export class CharCode {

    /** 通常科目型 */
    static readonly NormalSubjects = 0;

    /** 支払債務型 */
    static readonly Payables = 1;

    /** 支払手形型 */
    static readonly NotesPayable = 2;

    /** 当座小切手型 */
    static readonly CurrentCheck = 3;

    /** 支払先日付小切手 */
    static readonly PaymentDateCheck = 4;

    /** 売掛金型 */
    static readonly AccountsReceivable = 5;

    /** 受取手形型 */
    static readonly BillsRecivable = 6;

    /** 割引手形型 */
    static readonly DiscountBills = 7;

    /** 受取先日付小切手 */
    static readonly RecipientDateCheck = 8;

    /** 期日付債権型 */
    static readonly DatedReceivables = 9;

    /** 期日付債務型 */
    static readonly DatedDebt = 10;

    /** 期日付債権型２ */
    static readonly DatedReceivables2 = 11;

    /** 期日付債務型２ */
    static readonly DatedDebt2 = 12;

    /** 番号付債権型 */
    static readonly NumberedReceivables = 13;

    /** 番号付債務型 */
    static readonly NumberedDebt = 14;

    /** 番号付債権型２ */
    static readonly NumberedReceivables2 = 15;

    /** 番号付債務型２ */
    static readonly NumberedDebt2 = 16;

    /** 期日指定振込 */
    static readonly DateSpecifiedTransfer = 17;
}

/**
 * 元帳摘要欄起票者印字区分
 */
export class MTekiPrn {

    /** 印字なし */
    static readonly None = 0;

    /** 印字あり */
    static readonly Setting = 1;
}

/**
 * 出力条件(年次推移)
 */
export class OutputConditionAnnual {

    /** 決算を含む */
    static readonly IncludeSettlement = 1;

    /** 決算を含まない */
    static readonly NotIncludeSettlement = 2;
}

/**
 * 残高登録表示形式
 */
export class BalanceDispFmt {

    /** BS科目表示 */
    static readonly BSDisplay = 1;

    /** PL科目表示 */
    static readonly PLDisplay = 2;
}

/**
 * 手形番号入力区分
 */
export class TInputKbn {

    /** 入力なし */
    static readonly None = 0;

    /** 正残側のみ */
    static readonly PositiveBalance = 1;

    /** 貸借入力 */
    static readonly DebitCredit = 2;

    /** 負残側のみ */
    static readonly NegativeBalance = 3;
}

/**
 * 判定金額
 */
export class JudgmentAmount {

    /** デフォルト */
    static readonly Default = 500000;
}

/**
 * 帳票分類(任意設定)
 */
export class FreeRepoType {

    /** 分類0 */
    static readonly Type0 = 0;

    /** 分類1 */
    static readonly Type1 = 1;

    /** 分類2 */
    static readonly Type2 = 2;

    /** 分類3 */
    static readonly Type3 = 3;

    /** 分類4 */
    static readonly Type4 = 4;

    /** 分類5 */
    static readonly Type5 = 5;

    /** 分類6 */
    static readonly Type6 = 6;
}

/**
 * セグメント自動セット印刷区分
 */
export class SegAutoSetPrintKbn {

    /** 基本情報 */
    static readonly Basic = 1;

    /** 自動セット情報 */
    static readonly AutoSet = 2;
}

/**
 * 科目分類
 */
export class KmkClass {

    /** 資産科目 */
    static readonly AssetsKmk = 1;

    /** 負債・純資産科目 */
    static readonly LiabilitiesStockholdersKmk = 2;

    /** 損益科目 */
    static readonly ProfitAndLossKmk = 3;

    /** 製造科目 */
    static readonly ManufacturingKmk = 4;
}

/**
 * 特定科目区分
 */
export class AccountSpeKbn {

    /** 特定科目 */
    static readonly SpecificAccounts = 1;

    /** 複合科目 */
    static readonly ComplexAccounts = 2;

    /** 消費税科目 */
    static readonly TaxRelationAccounts = 3;

    /** はぐれ科目 */
    static readonly StrayAccounts = 4;
}

/**
 * 手形管理ＮＯ入力区分
 */
export class TMInputKbn {

    /** 入力なし */
    static readonly None = 0;

    /** 正残側のみ */
    static readonly PositiveBalance = 1;

    /** 貸借入力 */
    static readonly DebitCredit = 2;

    /** 負残側のみ */
    static readonly NegativeBalance = 3;
}

/**
 * 性別
 */
export class QyoSex {

    /** 男性 */
    static readonly Male = 1;

    /** 女性 */
    static readonly Female = 2;
}

/**
 * 在職区分
 */
export class QyoTenure {

    /** 在職 */
    static readonly Tenure = 1;

    /** 退職 */
    static readonly Retire = 2;

    /** 休職 */
    static readonly Leave = 3;
}

/**
 * 支払形態
 */
export class QyoPayment {

    /** 月給制 */
    static readonly Monthly = 1;

    /** 日給月給制 */
    static readonly DailyMonthly = 2;

    /** 日給制 */
    static readonly Daily = 3;

    /** 時給制 */
    static readonly Hourly = 4;
}

/**
 * 税額表
 */
export class QyoTaxKbn {

    /** 甲欄 */
    static readonly Kouran = 1;

    /** 乙欄 */
    static readonly Otsuran = 2;
}

/**
 * 給与計算区分
 */
export class QyoQyoKbn {

    /** 必要 */
    static readonly Necessary = 1;

    /** 不要 */
    static readonly Unnecessary = 2;
}

/**
 * 賞与計算区分
 */
export class QyoShoKbn {

    /** 必要 */
    static readonly Necessary = 1;

    /** 不要 */
    static readonly Unnecessary = 2;
}

/**
 * 年調計算区分
 */
export class QyoAdjKbn {

    /** 必要 */
    static readonly Necessary = 1;

    /** 不要 */
    static readonly Unnecessary = 2;
}

/**
 * 配偶者区分
 */
export class QyoHaiguKbn {

    /** なし */
    static readonly None = 0;

    /** 一般 */
    static readonly General = 1;

    /** 老人 */
    static readonly Old = 2;

    /** 控除対象外 */
    static readonly NonTarget = 3;
}

/**
 * 配偶者障害者
 */
export class QyoHaiguHandicap {

    /** なし */
    static readonly None = 0;

    /** 一般障害 */
    static readonly General = 1;

    /** 特別障害 */
    static readonly Special = 2;

    /** 同居特障 */
    static readonly Living = 3;
}

/**
 * 配偶者非居住者
 */
export class QyoHaiguOverseas {

    /** 該当しない */
    static readonly NonApplicable = 0;

    /** 該当する */
    static readonly Applicable = 1;
}

/**
 * 配偶者続柄
 */
export class QyoHaiguRelation {

    /** 妻 */
    static readonly Wife = 1;

    /** 夫 */
    static readonly Husband = 2;
}

/**
 * 扶養区分
 */
export class QyoFuyouKbn {

    /** なし */
    static readonly None = 0;

    /** 一般 */
    static readonly General = 1;

    /** 特定 */
    static readonly Special = 2;

    /** 老人 */
    static readonly Old = 3;

    /** 年少 */
    static readonly Young = 4;

    /** 同居老親等 */
    static readonly Living = 5;
}

/**
 * 扶養障害者
 */
export class QyoFuyouHandicap {

    /** なし */
    static readonly None = 0;

    /** 一般障害 */
    static readonly General = 1;

    /** 特別障害 */
    static readonly Special = 2;

    /** 同居特障 */
    static readonly Living = 3;
}

/**
 * 扶養非居住者
 */
export class QyoFuyouOverseas {

    /** 該当しない */
    static readonly NonApplicable = 0;

    /** 該当する */
    static readonly Applicable = 1;
}

/**
 * 扶養続柄
 */
export class QyoFuyouRelation {

    /** なし */
    static readonly None = 0;

    /** 祖父 */
    static readonly GrandFather = 1;

    /** 祖母 */
    static readonly GrandMother = 2;

    /** 父 */
    static readonly Father = 3;

    /** 母 */
    static readonly Mother = 4;

    /** 子 */
    static readonly Child = 5;

    /** 長男 */
    static readonly FirstSon = 6;

    /** 次男 */
    static readonly SecondSon = 7;

    /** 三男 */
    static readonly ThirdSon = 8;

    /** 四男 */
    static readonly FourthSon = 9;

    /** 五男 */
    static readonly FifthSon = 10;

    /** 長女 */
    static readonly FirstDaughter = 11;

    /** 次女 */
    static readonly SecondDaughter = 12;

    /** 三女 */
    static readonly ThirdDaughter = 13;

    /** 四女 */
    static readonly FourthDaughter = 14;

    /** 五女 */
    static readonly FifthDaughter = 15;

    /** 叔父 */
    static readonly Uncle = 16;

    /** 叔母 */
    static readonly Aunt = 17;

    /** 兄 */
    static readonly Brother = 18;

    /** 姉 */
    static readonly Sister = 19;

    /** 弟 */
    static readonly YoungBrother = 20;

    /** 妹 */
    static readonly YoungSister = 21;
}

/**
 * レコード区分
 */
export class QyoRecordKbn {

    /** 給与 */
    static readonly Qyo = 1;

    /** 賞与 */
    static readonly Sho = 2;

    /** 年調 */
    static readonly Adj = 3;
}

/**
 * 出力条件
 */
export class QyoOutputKbn {

    /** 社員 */
    static readonly Emp = 1;

    /** 部署 */
    static readonly Dept = 2;
}

/**
 * 条件範囲
 */
export class QyoConditionRange {

    /** すべて */
    static readonly All = 1;

    /** 個別指定 */
    static readonly Individ = 2;
}

/**
 * 網掛け
 */
export class QyoHatch {

    /** 網掛け淡色 */
    static readonly LightColor = 12632256;

    /** 網掛け中間色 */
    static readonly NeutralColor = 13684944;

    /** 網掛け濃色 */
    static readonly DeepColor = 14737632;

    /** 透明 */
    static readonly Clear = 16777215;
}

/**
 * 仕訳集計方法
 */
export class QyoSwkSum {

    /** 科目単位に集計 */
    static readonly Kmk = 1;

    /** 項目単位に集計 */
    static readonly Item = 2;
}

/**
 * 明細書項目処理区分
 */
export class QyoKmManage {

    /** 基本給 */
    static readonly Basic = 1;

    /** 雇用 */
    static readonly Employment = 2;

    /** 所得税 */
    static readonly IncomeTax = 3;

    /** 通勤費 */
    static readonly Commute = 4;

    /** 残業手当 */
    static readonly Overtime = 5;

    /** 遅刻控除 */
    static readonly Late = 6;

    /** 欠勤控除 */
    static readonly Absence = 7;

    /** 合計 */
    static readonly Total = 9;

    /** 手当（単価計算） */
    static readonly AllowanceUnitPrice = 11;

    /** 有休残 */
    static readonly Holiday = 21;
}

/**
 * 明細書項目属性
 */
export class QyoKmAttribute {

    /** 金額 */
    static readonly Price = 1;

    /** 日数 */
    static readonly Days = 2;

    /** 時間 */
    static readonly Time = 3;

    /** 単価 */
    static readonly UnitPrice = 4;

    /** 基礎給 */
    static readonly Basic = 5;
}

/**
 * 明細書情報処理区分
 */
export class QyoImPrintClass {

    /** 勤怠 */
    static readonly Attendance = 1;

    /** 支給 */
    static readonly Payment = 2;

    /** 控除 */
    static readonly Deduction = 3;

    /** 合計 */
    static readonly Total = 4;

    /** その他 */
    static readonly Other = 5;

    /** 非表示 */
    static readonly Hidden = 6;

    /** 個人単価 */
    static readonly PersonalUnitPrice = 7;

    /** 勤怠項目 */
    static readonly AttendanceItem = 8;
}

/**
 * 明細書情報印字有無
 */
export class QyoImPrintUse {

    /** 印字なし */
    static readonly PrintNone = 0;

    /** 印字あり */
    static readonly PrintSet = 1;
}

/**
 * 法人区分
 */
export class CorpKbn {

    /** 法人 */
    static readonly Corporate = 0;

    /** 個人 */
    static readonly Personal = 1;
}

/**
 * 時間単位
 */
export class MinutePoint {

    /** 10進 */
    static readonly Notation10 = 0;

    /** 60進 */
    static readonly Notation60 = 1;
}

/**
 * 所得税計算方法
 */
export class ShoTaxCalc {

    /** 税額表 */
    static readonly TaxTable = 0;

    /** 計算機の特例 */
    static readonly Computer = 1;
}

/**
 * 端数種別
 */
export class RoundType {

    /** 端数調整しない */
    static readonly None = 0;

    /** 端数を預かる */
    static readonly Keep = 1;
}

/**
 * 給与金種表エラーフラグ
 */
export class PayCurrencyErrorFlg {

    /** 正常 */
    static readonly Normal = 0;

    /** 社員未登録 */
    static readonly EmpNone = 1;

    /** 現金支給者なし */
    static readonly EmpPaymentNone = 2;

    /** 明細書未作成 */
    static readonly YearCountNone = 3;

    /** 未計算社員あり */
    static readonly CalcNone = 4;

    /** 単独年調以外 */
    static readonly AloneAdjustmentOther = 6;

    /** 年調計算実行 */
    static readonly AdjustmentCalculation = 7;
}

/**
 * 単価区分
 */
export class UnitPriceKbn {

    /** 計算しない */
    static readonly None = 0;

    /** 計算単価 */
    static readonly CalcUnitPrice = 1;

    /** 社員登録で設定 */
    static readonly EmpEntry = 2;
}

/**
 * 口座区分
 */
export class QyoBankKbn {

    /** 普通 */
    static readonly Savings = 1;

    /** 当座 */
    static readonly Current = 2;
}

/**
 * 振込方法
 */
export class QyoBankRequestKbn {

    /** 電信扱 */
    static readonly Telegraph = 1;

    /** 文書扱 */
    static readonly Document = 2;
}

/**
 * 取扱種目
 */
export class QyoBankItems {

    /** 給与振込 */
    static readonly Qyo = 1;

    /** 総合振込 */
    static readonly Overall = 2;
}

/**
 * 給与振込内訳区分
 */
export class QyoTranKbn {

    /** 全額 */
    static readonly Full = 1;

    /** 定額 */
    static readonly Fixed = 2;

    /** 残額 */
    static readonly Remaining = 3;
}

/**
 * 給与振込先口座登録
 */
export class QyoTranEntNo {

    /** 銀行口座１ */
    static readonly Account1 = 1;

    /** 銀行口座２ */
    static readonly Account2 = 2;

    /** 銀行口座３ */
    static readonly Account3 = 3;

    /** 現金支払 */
    static readonly Cash = 4;
}

/**
 * 賞与振込区分
 */
export class QyoShoTranKbn {

    /** 給与と同じ設定にしない。 */
    static readonly NotQyoSetting = 0;

    /** 給与と同じ設定にする。 */
    static readonly QyoSetting = 1;
}

/**
 * 住民税徴収方法
 */
export class QyoResident {

    /** 特別徴収 */
    static readonly Special = 0;

    /** 普通徴収 */
    static readonly Ordinary = 1;
}

/**
 * 通勤費支給方法
 */
export class QyoCommute {

    /** 給与 */
    static readonly Qyo = 1;

    /** 現物 */
    static readonly Actual = 2;
}

/**
 * 通勤費支給単位
 */
export class QyoCommutePoint {

    /** 1ヶ月 */
    static readonly Monthly = 1;

    /** 3ヶ月 */
    static readonly Monthly3 = 2;

    /** 6ヶ月 */
    static readonly Monthly6 = 3;

    /** 1日毎 */
    static readonly Daily = 4;
}

/**
 * 健保種別
 */
export class InsType {

    /** なし */
    static readonly None = 0;

    /** 協会けんぽ */
    static readonly Association = 1;

    /** 組合健保 */
    static readonly Union = 2;
}

/**
 * 基金加入区分
 */
export class KikinKbn {

    /** 未加入 */
    static readonly NotJoin = 0;

    /** 加入 */
    static readonly Join = 1;
}

/**
 * 端数区分
 */
export class QyoRoundKbn {

    /** 切り捨て */
    static readonly CuttingOff = 0;

    /** 切り上げ */
    static readonly Roundup = 1;

    /** 四捨五入 */
    static readonly RoundOff = 2;

    /** 五捨六入 */
    static readonly Rounding = 3;
}

/**
 * 育児休暇
 */
export class Childcare {

    /** 対象外 */
    static readonly NonTarget = 0;

    /** 休暇中 */
    static readonly Target = 1;
}

/**
 * 保険対象者
 */
export class InsKbn {

    /** 対象外 */
    static readonly NonTarget = 0;

    /** 対象 */
    static readonly Target = 1;
}

/**
 * 介護保険対象者
 */
export class CareKbn {

    /** 対象外 */
    static readonly NonTarget = 0;

    /** 対象 */
    static readonly Target = 1;

    /** 適用除外 */
    static readonly Exemption = 2;
}

/**
 * 労災区分
 */
export class WorkersAccidentKbn {

    /** 常用労働者 */
    static readonly Regular = 0;

    /** 役員労働者扱い */
    static readonly Officer = 1;

    /** 臨時労働者 */
    static readonly Extraordinary = 2;

    /** 不要 */
    static readonly Unnecessary = 9;
}

/**
 * 雇用区分
 */
export class EmploymentKbn {

    /** 被保険者 */
    static readonly Insured = 0;

    /** 役員被保険者 */
    static readonly Officer = 1;

    /** 高年齢（被保険者） */
    static readonly OldInsured = 2;

    /** 高年齢（役員） */
    static readonly OldOfficer = 3;

    /** 不要 */
    static readonly Unnecessary = 9;
}

/**
 * 雇用計算区分
 */
export class EmploymentCalc {

    /** 対象外 */
    static readonly NonTarget = 0;

    /** 対象 */
    static readonly Target = 1;
}

/**
 * 未計算状況
 */
export class QyoCalcNoneStatus {

    /** 更新 */
    static readonly Update = 0;

    /** 入力 */
    static readonly Entry = 1;

    /** 計算済 */
    static readonly Calculated = 2;
}

/**
 * 通勤費通勤区分
 */
export class QyoCommuteKbn {

    /** 0:支給なし */
    static readonly None = 0;

    /** 1:交通機関使用 */
    static readonly Use = 1;

    /** 2:交通用具使用（片道2Km未満） */
    static readonly UseUnder2Km = 2;

    /** 3:交通用具使用（片道2～10Km） */
    static readonly UseUnder10Km = 3;

    /** 4:交通用具使用（片道10～15Km） */
    static readonly UseUnder15Km = 4;

    /** 5:交通用具使用（片道15～25Km） */
    static readonly UseUnder25Km = 5;

    /** 6:交通用具使用（片道25～35Km） */
    static readonly UseUnder35Km = 6;

    /** 7:交通用具使用（片道35～45Km） */
    static readonly UseUnder45Km = 7;

    /** 8:交通用具使用（片道45～55Km） */
    static readonly UseUnder55Km = 8;

    /** 9:交通用具使用（片道55Km以上） */
    static readonly UseOver55Km = 9;
}

/**
 * 付箋処理区分
 */
export class FusenProcessKbn {

    /** 取得 */
    static readonly Get = 0;

    /** 備考有り */
    static readonly RemarksSet = 1;

    /** 備考無し */
    static readonly RemarksNone = 2;

    /** はがす */
    static readonly PeelOff = 3;
}

/**
 * 付箋処理結果
 */
export class FusenResResult {

    /** OK */
    static readonly OK = 0;

    /** キャンセル */
    static readonly Cansel = 1;

    /** NG */
    static readonly NG = 2;
}

/**
 * 賞与所得税計算
 */
export class SixOver {

    /** 6ヶ月 */
    static readonly Six = 1;

    /** 12ヶ月 */
    static readonly Twelve = 2;
}

/**
 * 銀行振込処理エラーコード
 */
export class PayTranErrorCode {

    /** 正常 */
    static readonly Normal = 0;

    /** 社員未登録 */
    static readonly EmpNone = 1;

    /** 明細書未作成 */
    static readonly YearCountNone = 3;

    /** 未計算社員あり */
    static readonly CalcNone = 4;

    /** 依頼先銀行未登録 */
    static readonly ReqBankNone = 5;

    /** 単独年調以外 */
    static readonly AloneAdjustmentOther = 6;

    /** 年調計算実行 */
    static readonly AdjustmentCalculation = 7;

    /** 未計算社員あり・年調 */
    static readonly CalcNoneAdjustment = 8;
}

/**
 * 住民税集計表エラーフラグ
 */
export class PayPayResidentErrorFlg {

    /** 正常 */
    static readonly Normal = 0;

    /** 社員未登録 */
    static readonly EmpNone = 1;

    /** 明細書未作成 */
    static readonly YearCountNone = 3;

    /** 未計算社員あり */
    static readonly CalcNone = 4;
}

/**
 * 厚年加入区分
 */
export class KouseiKbn {

    /** 未加入 */
    static readonly NotJoin = 0;

    /** 加入 */
    static readonly Join = 1;
}

/**
 * 仕訳作成エラーコード
 */
export class SwkMakeErrorCode {

    /** 正常 */
    static readonly Normal = 0;

    /** 仕訳月次確定済み */
    static readonly SwkMonthlyClosing = 36;

    /** 仕訳連動中 */
    static readonly SwkRenExce = 37;

    /** 月度範囲外 */
    static readonly MonthRangeOut = 38;

    /** 会計期間範囲外 */
    static readonly YearRangeOut = 39;
}

/**
 * 仕訳連結区分
 */
export class SwkRenKbn {

    /** 0:未連結 */
    static readonly SwkRenNone = 0;

    /** 1:連結済み　 */
    static readonly SwkRenComp = 1;

    /** 9:連結中 */
    static readonly SwkRenExce = 9;
}

/**
 * 精算区分
 */
export class PayOffKbn {

    /** 精算なし */
    static readonly None = 0;

    /** 精算あり */
    static readonly Setting = 1;
}

/**
 * 工事特殊科目
 */
export class KojiExeKmk {

    /** 契約金額 */
    static readonly ContractSum = 1;

    /** 回収高 */
    static readonly CollectBalance = 2;

    /** 回収率 */
    static readonly CollectRate = 3;

    /** 工事収支 */
    static readonly KojiBalance = 4;

    /** バランス検定（収支率） */
    static readonly BalanceVerification = 5;

    /** 希望利益 */
    static readonly RequestProfit = 6;

    /** 消化率 */
    static readonly Digestibility = 7;

    /** 予算評価益 */
    static readonly BudgetAppraisalProfit = 8;

    /** 希望利益率 */
    static readonly RequestProfitRate = 9;

    /** 予算原価率 */
    static readonly BudgetCostRate = 10;

    /** 回収残 */
    static readonly CollectRemaining = 11;

    /** 実現利益 */
    static readonly RealizedProfit = 12;

    /** 利益達成率 */
    static readonly ProfitAchievementRate = 13;

    /** 実現利益率 */
    static readonly RealizedProfitRate = 14;

    /** 原価率 */
    static readonly CostRate = 15;
}

/**
 * 資金繰特殊科目
 */
export class FinancingExeKmk {

    /** 固定性預金 */
    static readonly FixingDeposit = 1;

    /** 受取手形 */
    static readonly BillReceipt = 2;

    /** 売掛金 */
    static readonly Credit = 3;

    /** 支払手形 */
    static readonly BillPayment = 4;

    /** 買掛金 */
    static readonly AccountsPayable = 5;

    /** 短期借入金 */
    static readonly ShortLoan = 6;

    /** 長期借入金 */
    static readonly LongLoan = 7;
}

/**
 * 科目挿入
 */
export class KmkInsert {

    /** 実在/合計 */
    static readonly RealAndTotal = 0;

    /** 実在 */
    static readonly Real = 1;
}

/**
 * 仕入日入力方法
 */
export class SDateInpKbn {

    /** 入力なし */
    static readonly None = 0;

    /** 日付 */
    static readonly Date = 1;

    /** 日付範囲 */
    static readonly DateRange = 2;

    /** 年月 */
    static readonly YM = 3;

    /** 年月範囲 */
    static readonly YMRange = 4;
}

/**
 * 仕入日検索方法
 */
export class SDateSearchKbn {

    /** 指定なし */
    static readonly None = 0;

    /** 年月日 */
    static readonly YMD = 1;

    /** 年月 */
    static readonly YM = 2;

    /** 全て */
    static readonly All = 3;
}

/**
 * レイアウト区分
 */
export class LayoutKbn {

    /** 簡易印刷 */
    static readonly SimpleLayout = 0;

    /** 詳細印刷 */
    static readonly DetailLayout = 1;
}

/**
 * 帳票出力条件レイアウトＮＯ
 */
export class OutputPtn2LayoutNo {

    /** 科目欄３個　仕入付 */
    static readonly SwkDiaryKmk3Purchase = 60;

    /** 科目欄４個　セグメント付 */
    static readonly Kmk4Seg = 40;

    /** 科目欄４個　手形番号付 */
    static readonly Kmk4Bill = 50;
}

/**
 * 仕入税額控除の記載事項の入力方法
 */
export class PurchaseTaxCreditInputMethod {

    /** 摘要に入力 */
    static readonly InputSummary = 0;

    /** 仕入先、仕入日を摘要の他に入力 */
    static readonly InputOtherSummary = 1;
}

/**
 * 番号１入力区分
 */
export class NoInputKbn1 {

    /** 入力なし */
    static readonly None = 0;

    /** 正残側のみ */
    static readonly PositiveBalance = 1;

    /** 貸借入力 */
    static readonly DebitCredit = 2;

    /** 負残側のみ */
    static readonly NegativeBalance = 3;
}

/**
 * 番号２入力区分
 */
export class NoInputKbn2 {

    /** 入力なし */
    static readonly None = 0;

    /** 正残側のみ */
    static readonly PositiveBalance = 1;

    /** 貸借入力 */
    static readonly DebitCredit = 2;
}

/**
 * 合計セット実行区分
 */
export class SetSummaryExecKbn {

    /** 残高登録 */
    static readonly AccountBalance = 1;

    /** 予算登録 */
    static readonly Budget = 2;
}

/**
 * 査印欄の大きさ
 */
export class StampPtnBoxSize {

    /** 標準（大） */
    static readonly Large = 0;

    /** 中 */
    static readonly Medium = 1;

    /** 小 */
    static readonly Small = 2;
}

/**
 * データ区分
 */
export class ReceiptImportDataKbn {

    /** 取込対象外 */
    static readonly NotTarget = -1;

    /** 取込対象 */
    static readonly Target = 0;

    /** 削除済 */
    static readonly Deleted = 1;

    /** 連結済 */
    static readonly Connected = 9;
}

/**
 * 解析ステータス
 */
export class ReceiptImportAnalysisStatus {

    /** 対象外 */
    static readonly NotTarget = -1;

    /** 処理待ち */
    static readonly Waiting = 0;

    /** 解析中 */
    static readonly Analyzing = 1;

    /** 解析済 */
    static readonly Analyzed = 2;

    /** 確認済 */
    static readonly Checked = 3;

    /** 金額不一致 */
    static readonly AmountMismatch = 4;

    /** 一部解析不可 */
    static readonly CannotAnalyzePart = 8;

    /** 解析不可 */
    static readonly CannotAnalyzeAll = 9;
}

/**
 * 分割ステータス
 */
export class ReceiptImportSplitStatus {

    /** 対象外 */
    static readonly NotTarget = -1;

    /** 処理待ち */
    static readonly Waiting = 0;

    /** 分割中 */
    static readonly Splitting = 1;

    /** 分割済 */
    static readonly Splitted = 2;

    /** 分割不可 */
    static readonly CannotSplit = 9;
}

/**
 * OCRサービスリクエストタイプ
 */
export class OCRRequestType {

    /** OCR解析 */
    static readonly OCRAnalysis = 2;

    /** マルチピクチャ分割 */
    static readonly MultiPictureSplit = 1;

    /** 分割＋OCR解析 */
    static readonly AnalysisAndSplit = 3;
}

/**
 * カラー印刷区分
 */
export class PrintColor {

    /** モノクロ */
    static readonly Monochrome = 0;

    /** カラー */
    static readonly Color = 1;
}

/**
 * 元号
 */
export class Era {

    /** 明治 */
    static readonly Meiji = 1;

    /** 大正 */
    static readonly Taisho = 2;

    /** 昭和 */
    static readonly Showa = 3;

    /** 平成 */
    static readonly Heisei = 4;
}

/**
 * 摘要強制入力区分
 */
export class SummaryForceKbn {

    /** 自動 */
    static readonly Auto = 0;

    /** 強制入力 */
    static readonly Force = 1;
}

/**
 * 社会保険料の徴収時期
 */
export class CollectMonth {

    /** 当月 */
    static readonly CurrentMonth = 0;

    /** 翌月 */
    static readonly NextMonth = 1;

    /** 手入力 */
    static readonly HandInput = 9;
}

/**
 * 計算基準
 */
export class CalculationStandard {

    /** 計算なし */
    static readonly None = 0;

    /** 加算 */
    static readonly Addition = 1;

    /** 減算 */
    static readonly Subtraction = 2;

    /** 対象外 */
    static readonly Excluded = 9;
}

/**
 * 年調処理区分
 */
export class QyoAdjProcessKbn {

    /** 未処理 */
    static readonly ProcessNone = 0;

    /** 年調処理 */
    static readonly ProcessAdj = 1;
}

/**
 * 個人区分
 */
export class PersonKbn {

    /** 事業者 */
    static readonly Employer = 1;

    /** 社員または家族 */
    static readonly EmployeeAndFamily = 2;
}

/**
 * 源泉徴収票フォームタイプ
 */
export class QyoAdjFormType {

    /** 2枚 */
    static readonly Sheet2 = 0;

    /** 1枚（税務署提出用/従業員交付用） */
    static readonly Sheet1 = 1;
}

/**
 * 保険区分
 */
export class InsuranceKbn {

    /** 労災保険 */
    static readonly WorkersAccident = 1;

    /** 雇用保険 */
    static readonly Employment = 2;
}

/**
 * 消費税元帳レイアウトNo
 */
export class ConsumptionTaxLedgerLayoutNo {

    /** A4縦白紙 */
    static readonly A4 = 0;

    /** B5縦白紙 */
    static readonly B5 = 3;

    /** A4縦白紙(補助名欄付) */
    static readonly A4HojyoName = 6;

    /** A4縦白紙(補助コード欄付) */
    static readonly A4HojyoCode = 7;
}

/**
 * 消費税区分
 */
export class ConsumptionTaxKbn {

    /** 消費税等 */
    static readonly ConsumptionTax = 1;

    /** 国税 */
    static readonly NationalTax = 2;

    /** 地方税 */
    static readonly LocalTax = 3;
}

/**
 * 売上消費税コード１
 */
export class STaxCode1 {

    /** 課税売上 */
    static readonly TaxationSales = 0;

    /** 課税売上(内税) */
    static readonly TaxationSalesIncluded = 1;

    /** 課税売上(外税) */
    static readonly TaxationSalesExcluded = 2;

    /** 非課税売上 */
    static readonly ExemptionSales = 3;

    /** 不課税売上 */
    static readonly TaxationLessnessSales = 4;

    /** 輸出売上 */
    static readonly ExportSales = 6;

    /** 特定収入 */
    static readonly Specific = 8;
}

/**
 * 売上消費税コード２
 */
export class STaxCode2 {

    /** 課税売上 */
    static readonly TaxationSales = 0;

    /** 課税売上(内税) 総額 */
    static readonly TaxationSalesIncludedTotal = 0;

    /** 課税売上(内税) */
    static readonly TaxationSalesIncluded = 10;

    /** 課税控除(内税) */
    static readonly TaxationSalesSubtractionIncluded = 12;

    /** 課税貸倒償却(内税) */
    static readonly TaxationSalesLoanlossRepaymentIncluded = 13;

    /** 課税貸倒回収(内税) */
    static readonly TaxationSalesLoanlossCollectionIncluded = 15;

    /** 課税売上(外税) 総額 */
    static readonly TaxationSalesExcludedTotal = 0;

    /** 課税売上(外税) */
    static readonly TaxationSalesExcluded = 20;

    /** 課税売上控除(外税) */
    static readonly TaxationSalesSubtractionExcluded = 22;

    /** 課税貸倒償却(外税) */
    static readonly TaxationSalesLoanlossRepaymentExcluded = 23;

    /** 課税貸倒回収(外税) */
    static readonly TaxationSalesLoanlossCollectionExcluded = 25;

    /** 非課税売上 総額 */
    static readonly ExemptionSalesTotal = 0;

    /** 非課税売上 */
    static readonly ExemptionSales = 30;

    /** 有価証券売上等(非課税) */
    static readonly ValuablePapersSalesExemption = 34;

    /** 不課税売上 */
    static readonly TaxationLessnessSales = 0;

    /** 輸出売上 総額 */
    static readonly ExportSalesTotal = 0;

    /** 輸出売上 */
    static readonly ExportSales = 60;

    /** 非課税輸出売上 */
    static readonly ExemptionExportSales = 64;
}

/**
 * 仕入消費税コード１
 */
export class BTaxCode1 {

    /** 課税仕入 */
    static readonly TaxationJournalize = 0;

    /** 課税仕入(内税) */
    static readonly TaxationJournalizeIncluded = 1;

    /** 課税仕入(外税) */
    static readonly TaxationJournalizeExcluded = 2;

    /** 非課税仕入 */
    static readonly ExemptionJournalize = 3;

    /** 不課税仕入 */
    static readonly TaxationLessnessStockingCleaningContract = 4;

    /** 輸入仕入(外税) */
    static readonly ImportJournalizeExcluded = 6;

    /** 輸入仕入(内税) */
    static readonly ImportJournalizeIncluded = 7;

    /** 特定課税仕入 */
    static readonly TaxationSalesSpecificJournalize = 8;
}

/**
 * 仕入消費税コード２
 */
export class BTaxCode2 {

    /** 課税仕入 */
    static readonly TaxationJournalize = 0;

    /** 課税仕入(内税) 総額 */
    static readonly TaxationJournalizeIncludedTotal = 0;

    /** 課税売上対応仕入(内税) */
    static readonly TaxationJournalizeIncluded = 10;

    /** 共通売上対応仕入(内税) */
    static readonly CommonJournalizeIncluded = 11;

    /** 課税売上対応仕入控除(内税) */
    static readonly TaxationJournalizeSubtractionIncluded = 12;

    /** 非課税売上対応仕入(内税) */
    static readonly ExemptionSalseJournalizeIncluded = 14;

    /** 共通売上対応仕入控除(内税) */
    static readonly CommonJournalizeSubtractionIncluded = 15;

    /** 非課売上対応仕入控除(内税) */
    static readonly ExemptionSalseJournalizeSubtractionIncluded = 16;

    /** 課税仕入(外税) 総額 */
    static readonly TaxationJournalizeExcludedTotal = 0;

    /** 課税売上対応仕入(外税) */
    static readonly TaxationJournalizeExcluded = 20;

    /** 共通売上対応仕入(外税) */
    static readonly CommonJournalizeExcluded = 21;

    /** 課税売上対応仕入控除(外税) */
    static readonly TaxationJournalizeSubtractionExcluded = 22;

    /** 非課税売上対応仕入(外税) */
    static readonly ExemptionSalseJournalizeExcluded = 24;

    /** 共通売上対応仕入控除(外税) */
    static readonly CommonJournalizeSubtractionExcluded = 25;

    /** 非課売上対応仕入控除(外税) */
    static readonly ExemptionSalseJournalizeSubtractionExcluded = 26;

    /** 非課税売上対応仕入 */
    static readonly ExemptionJournalize = 0;

    /** 不課税仕入 */
    static readonly TaxationLessnessStockingCleaningContract = 0;

    /** 輸入仕入(外税) 総額 */
    static readonly ImportJournalizeExcludedTotal = 0;

    /** 課税売上対応輸入仕入(外税) */
    static readonly ImportJournalizeExcluded = 60;

    /** 共通売上対応輸入仕入(外税) */
    static readonly ImportCommonJournalizeExcluded = 61;

    /** 非課税売上対応輸入仕入(外税) */
    static readonly JournalizeCotCode64 = 64;

    /** 課税売上対応輸入仕入(地方消費税) */
    static readonly ImportJournalizeConsumptionTax = 65;

    /** 共通売上対応輸入仕入(地方消費税) */
    static readonly ImportCommonJournalizeLocalConsumptionTax = 66;

    /** 非課税売上対応輸入仕入(地方消費税) */
    static readonly ExemptionJournalizeImport = 69;

    /** 輸入仕入(内税) 総額 */
    static readonly ImportJournalizeIncludedTotal = 0;

    /** 課税売上対応輸入仕入(内税) */
    static readonly ImportJournalizeIncluded = 70;

    /** 共通売上対応輸入仕入(内税) */
    static readonly ImportCommonJournalizeIncluded = 71;

    /** 非課税売上対応輸入仕入(内税) */
    static readonly ExemptionSalseImportIncluded = 74;

    /** 特定課税仕入 総額 */
    static readonly TaxationSalesSpecificJournalizeTotal = 0;

    /** 課税売上対応特定課税仕入 */
    static readonly TaxationSalesSpecificJournalize = 80;

    /** 共通売上対応特定課税仕入 */
    static readonly TaxationExemptionSalesSpecificJournalize = 81;

    /** 課税売上対応特定課税仕入控除 */
    static readonly TaxationSalesSpecificJournalizeReturn = 82;

    /** 非課税売上対応特定課税仕入 */
    static readonly ExemptionSalseImportSpecificTaxationJournalize = 84;

    /** 共通売上対応特定課税仕入控除 */
    static readonly TaxationExemptionSalesSpecificJournalizeReturn = 85;

    /** 非課税売上対応特定課税仕入控除 */
    static readonly ExemptionSalseImportSpecificTaxationJournalizeReturn = 86;
}

/**
 * 消費税元帳明細区分
 */
export class TaxLedgerDetailKbn {

    /** 前月繰越 */
    static readonly CarryOverPreviousTerm = 0;

    /** 前頁繰越 */
    static readonly CarryOverPreviousPage = 1;

    /** 通常 */
    static readonly Normal = 2;

    /** 内税起票額 */
    static readonly SlipIssuanceTax = 3;

    /** 次頁繰越 */
    static readonly CarryOverNextPage = 4;

    /** 月計 */
    static readonly MonthlyTotal = 5;

    /** 累計 */
    static readonly Cumulative = 6;

    /** 空白 */
    static readonly Blank = 9;
}

/**
 * 売上／仕入区分
 */
export class SalesJournalizeKbn {

    /** 売上 */
    static readonly Sales = 0;

    /** 仕入 */
    static readonly Journalize = 1;
}

/**
 * 住宅適用区分
 */
export class KyojuKbn {

    /** 住 */
    static readonly Jyutaku = 1;

    /** 認 */
    static readonly Nintei = 2;

    /** 増 */
    static readonly Zouchiku = 3;

    /** 震 */
    static readonly Shinsai = 4;
}

/**
 * 消費税関係資料帳表区分
 */
export class ConsumptionTaxRelatedDocumentKbn {

    /** 消費税精算書 */
    static readonly ConsumptionTaxWorksheet = 1;

    /** 売上・仕入一覧表 */
    static readonly SalesAndJournalizeList = 2;

    /** 基礎資料月別明細 */
    static readonly BasicDocumentMonthlyStatement = 3;

    /** 仮受仮払消費税等 */
    static readonly SuspenseConsumptionTaxReceivedAndPaid = 4;

    /** 月別差引税額 */
    static readonly MonthlyDeductibleTaxAmount = 5;

    /** 業種別売上高 */
    static readonly IndustryClassifiedNetSales = 6;

    /** 業種別精算表 */
    static readonly IndustryClassifiedWorksheet = 7;

    /** 部門別消費税精算表 */
    static readonly SectorallyConsumptionTaxWorksheet = 8;

    /** 部門別売上・仕入一覧表 */
    static readonly SectorallySalesAndJournalizeList = 9;

    /** 税率別消費税精算表 */
    static readonly TaxRateConsumptionTaxWorksheet = 10;
}

/**
 * 基礎資料月別明細表示区分
 */
export class BasicDocumentMonthlyStatementKbn {

    /** 課税売上高 */
    static readonly TaxableNetSales = 1;

    /** 売上控除 */
    static readonly SalesSubtraction = 2;

    /** 貸倒償却 */
    static readonly ChargeOff = 3;

    /** 貸倒回収 */
    static readonly SalesLoanlossCollection = 4;

    /** 売上その他 */
    static readonly SalesOther = 5;

    /** 課税仕入 */
    static readonly TaxationJournalize = 6;

    /** 共通仕入 */
    static readonly CommonJournalize = 7;

    /** 非課税売上対応仕入 */
    static readonly ExemptionSalseJournalizeSubtraction = 8;

    /** 輸入仕入 */
    static readonly ImportJournalize = 9;

    /** 棚卸調整/特定課税仕入 */
    static readonly StocktakingAdjustAndSpecificJournalize = 10;
}

/**
 * 消費税関係資料明細行区分
 */
export class ConsumptionTaxRelatedDocumentRowKbn {

    /** 通常行 */
    static readonly Normal = 0;

    /** 特定課税仕入行 */
    static readonly SpecificJournalize = 1;

    /** 空白行 */
    static readonly Blank = 11;

    /** タイトル行 */
    static readonly Title = 12;

    /** 合計行 */
    static readonly Total = 13;
}

/**
 * 資金繰実績・予定表レイアウトNO
 */
export class FinancingPerformanceScheduleLayoutNo {

    /** 実績・予定表 */
    static readonly PerformanceSchedule = 1;

    /** 月次推移表 */
    static readonly MonthlyTransitionChart = 2;

    /** 月次推移表（実績・予定） */
    static readonly MonthlyTransitionChartPerformanceSchedule = 3;
}

/**
 * 比較対照
 */
export class CmpKbn {

    /** なし */
    static readonly None = 0;

    /** 前年 */
    static readonly LastYear = 1;

    /** 前々年 */
    static readonly TwoYearsBefore = 2;

    /** 予算１ */
    static readonly Budget1 = 11;

    /** 予算２ */
    static readonly Budget2 = 12;

    /** 予算３ */
    static readonly Budget3 = 13;

    /** 予算４ */
    static readonly Budget4 = 14;
}

/**
 * 資金繰集計方法(実績表／推移表)
 */
export class FinancingAggregationPerformanceTransitionMethod {

    /** 実績集計 */
    static readonly Performance = 0;

    /** 推移集計 */
    static readonly Transition = 1;

    /** 銀行集計 */
    static readonly Bank = 2;
}

/**
 * 証憑ファイル保管先
 */
export class SaveType {

    /** 会社DB */
    static readonly CompanyDb = 0;

    /** ストレージ（NX-Pro） */
    static readonly StorageAtNx = 1;

    /** 共有フォルダ */
    static readonly SharedFolder = 2;

    /** ストレージ（記帳くんCloud） */
    static readonly StorageAtVkz = 3;
}

/**
 * 操作種別
 */
export class OperationType {

    /** 登録 */
    static readonly Registration = 1;

    /** 変更 */
    static readonly Modification = 2;

    /** 変更(他) */
    static readonly ModificationOther = 3;

    /** 削除 */
    static readonly Deletion = 4;

    /** 消去 */
    static readonly Erase = 5;

    /** 参照 */
    static readonly Reference = 11;

    /** 参照・履歴なし */
    static readonly ReferenceNoHistory = 12;

    /** 印刷 */
    static readonly Print = 21;

    /** 連携設定 */
    static readonly FederationSetting = 31;

    /** 連携解除 */
    static readonly Unlink = 32;
}

/**
 * 個人番号アクセスアクション
 */
export class ApiAction {

    /** マイナンバー取得 */
    static readonly NumberGet = 10;

    /** マイナンバー存在確認 */
    static readonly NumberConfirmation = 20;

    /** 全件取得 */
    static readonly NumberAllGet = 30;

    /** 履歴書込み */
    static readonly AddHistory = 40;

    /** 個人番号検索 */
    static readonly SearchByMyNumber = 50;

    /** マイナンバー登録 */
    static readonly MyNumberRegister = 60;

    /** マイナンバー削除 */
    static readonly MyNumberDelete = 70;
}

/**
 * 月末税抜処理科目区分
 */
export class TaxExcludingMonthEndKmkKbn {

    /** 仕入 */
    static readonly Journalize = 1;

    /** 仮払消費税等 */
    static readonly SuspenseConsumptionTaxPaid = 2;

    /** 売上 */
    static readonly Sales = 3;

    /** 仮受消費税等 */
    static readonly SuspenseConsumptionTaxReceived = 4;
}

/**
 * 月末税抜処理帳票区分
 */
export class TaxExcludingMonthEndDocumentKbn {

    /** 管理表 */
    static readonly ManageList = 1;

    /** 日記帳 */
    static readonly DailyReport = 2;

    /** 振替伝票 */
    static readonly TransferSlip = 3;
}

/**
 * 科目出力方法
 */
export class KmkRsvKbn {

    /** 出力順序指定 */
    static readonly OutputOrder = 0;

    /** 範囲指定（コード順） */
    static readonly CodeRangeSpecification = 1;

    /** コード指定 */
    static readonly CodeSpecified = 2;
}

/**
 * 年調処理区分
 */
export class NenKbn {

    /** なし */
    static readonly None = 0;

    /** 給与年調 */
    static readonly Qyo = 1;

    /** 賞与年調 */
    static readonly Sho = 2;

    /** 単独年調 */
    static readonly Nen = 3;
}

/**
 * 処理結果
 */
export class ProcessingResult {

    /** 正常終了 */
    static readonly Normal = 0;

    /** 異常終了 */
    static readonly Fault = 1;
}

/**
 * 連携結果
 */
export class LinkResult {

    /** 選択 */
    static readonly Choice = 1;

    /** 解除 */
    static readonly Release = 2;

    /** キャンセル */
    static readonly Cancel = 9;
}

/**
 * 受給者区分
 */
export class QyoRecipientKbn {

    /** なし */
    static readonly None = 0;

    /** 法人の役員等 */
    static readonly CorporateOfficer = 1;
}

/**
 * プログラムコード
 */
export class ProgramCode {

    /** 勘定科目 */
    static readonly RegisterAccount = "VKZMAS005000024";

    /** 財務報告書 */
    static readonly ReportFinancialStatement = "VKZ330100330100";

    /** 部門別集計表 */
    static readonly ReportDepartment = "VKZMAS004000001";

    /** 比較財務報告書 */
    static readonly ReportComparativeFinancialStatement = "VKZMAS004000003";

    /** 四半期比較財務報告書 */
    static readonly ReportQuarterComparativeFinancialStatement = "VKZMAS004000004";

    /** 推移財務報告書 */
    static readonly ReportMonthlyFinancialStatement = "VKZ410140410140";

    /** 部門別比較財務報告書 */
    static readonly ReportDepartmentComparativeFinancialStatement = "VKZMAS004000005";

    /** 工事別集計表 */
    static readonly ConstructionSummary = "VKZMAS003000030";

    /** 科目別工事集計表 */
    static readonly ConstructionAccountSummary = "VKZMAS003000031";

    /** 工事管理表 */
    static readonly ConstructionAdministration = "VKZMAS003000032";

    /** かんたんクラウド確定申告起動 */
    static readonly ReportSystemLauncher = "VKZCMN001000004";

    /** かんたんクラウド確定申告導入設定 */
    static readonly ReportSystemKmkMapping = "VKZMAS006000014";

    /** 決算書 */
    static readonly ReportStatementOfAccounts = "VKZMAS007000001";
}

/**
 * 届出情報の種別
 */
export class NotificationType {

    /** 男子 */
    static readonly Man = 1;

    /** 女子 */
    static readonly Woman = 2;

    /** 坑内員 */
    static readonly Pitcher = 3;

    /** 基金加入男子 */
    static readonly FundMan = 5;

    /** 基金加入女子 */
    static readonly FundWoman = 6;

    /** 基金加入坑内員 */
    static readonly FundPitcher = 7;
}

/**
 * 比較財務報告書レイアウトNo
 */
export class ComparativeFinancialStatementLayoutNo {

    /** 比較 */
    static readonly Comparison = 0;

    /** 総合比較 */
    static readonly OverallComparison = 1;

    /** 総合比較（月次／累計） */
    static readonly OverallComparisonMonthlyCumulative = 2;

    /** 前期／予算比較 */
    static readonly EarlyBudgetComparison = 3;

    /** 比較損益 */
    static readonly ComparisonLoss = 4;

    /** 前年度末比較 */
    static readonly PreviousYearEndComparison = 9;

    /** 収支計算書 */
    static readonly IncomeStatement = 10;
}

/**
 * 連動ステータスコード
 */
export class LinkageStatusCode {

    /** 正常 */
    static readonly Success = 0;

    /** 会計データが見つかりません。 */
    static readonly NotFoundVkzData = 1;
}

/**
 * 年調計算対象ﾌﾗｸﾞ
 */
export class AdjCalcFlag {

    /** 対象 */
    static readonly Target = 0;

    /** 非甲欄 */
    static readonly NotKouran = 1;

    /** 年調不要 */
    static readonly Unnecessary = 2;

    /** 限度額超 */
    static readonly BeyondLimit = 3;

    /** 非当年退職 */
    static readonly NotRetire = 4;
}

/**
 * 決算月データ確認
 */
export class ConfirmClosingData {

    /** 中間決算確認 */
    static readonly SecondClosing = 1;

    /** 第１・第３決算確認 */
    static readonly FirstAndThirdClosing = 2;

    /** 第１・中間・第３決算確認 */
    static readonly ThreeTermsClosing = 3;
}

/**
 * 印刷表示パターン
 */
export class PrintDisplayPattern {

    /** タイトル */
    static readonly Title = 0;

    /** サブタイトル */
    static readonly SubTitle = 1;

    /** 明細表示（3列） */
    static readonly Detail = 2;

    /** タイトル（棚卸科目） */
    static readonly InventoryTitle = 3;

    /** サブタイトル（棚卸科目） */
    static readonly InventorySubTitle = 4;

    /** 明細表示（棚卸科目）（4列） */
    static readonly InventoryDetail = 5;
}

/**
 * 定型仕訳状態
 */
export class FixedStateType {

    /** 定型登録時に全て入力済み（色は黒） */
    static readonly AllEntered = 0;

    /** 定型入力時に一度も入力していない（色は赤） */
    static readonly NotEntered = 1;

    /** 定型入力時に入力を完了している（色は青） */
    static readonly Entered = 2;
}

/**
 * 部門別集計出力形式
 */
export class DepartmentSummaryLayout {

    /** 部門別財務報告書 */
    static readonly DepartmentFinancialStatement = 0;

    /** 部門別集計 */
    static readonly DepartmentSummary = 1;
}

/**
 * マスタデータ用のClientUserを保持する
 */
export class MasterClientUser {

    /** 給与会社データ時の雛形 */
    static readonly MasterClientUserName = "Mz3wI7XETHAT";

    /** 給与の仕訳連動_科目未設定 */
    static readonly SwkLinkageKwkNotSettUserName = "qRVFg5XM85H6";

    /** 会計コードマスターごとの雛形 */
    static readonly CodeMasterClientUserName = "tW0xURIuUlAr";
}

/**
 * マスタデータ用のClientCodeを保持する
 */
export class MasterClientCode {

    /** 給与会社データ時の雛形 */
    static readonly MasterClientUserCode = 201;

    /** 給与の仕訳連動_科目未設定 */
    static readonly SwkLinkageKwkNotSetUserCode = 202;
}

/**
 * マスタデータ用のClientYearを保持する
 */
export class MasterClientYear {

    /** 年度 */
    static readonly ClientYear = 99999999;
}

/**
 * 四半期比較財務報告書レイアウトNO
 */
export class QuarterComparativeFinancialStatementLayoutNo {

    /** 四半期比較 */
    static readonly QuarterComparative = 0;

    /** 半期比較 */
    static readonly HalfComparative = 1;
}

/**
 * 前回の給与連動結果ステータスコード
 */
export class PreviousPayrollLinkageStatusCode {

    /** 前回なし */
    static readonly NoPrevious = 101;

    /** 処理待ち */
    static readonly Waiting = 102;

    /** 実行中 */
    static readonly DuringExecution = 103;

    /** 排他エラー（他で更新中） */
    static readonly Exclusion = 201;

    /** 月次確定済みエラー */
    static readonly MonthlyFixed = 202;

    /** 入力不可の月エラー */
    static readonly InputDisabledMonth = 203;

    /** 仕訳補完エラー */
    static readonly CorrectionError = 291;

    /** 仕訳補完なし */
    static readonly NoCorrection = 301;

    /** 仕訳補完警告 */
    static readonly CorrectionWarning = 351;

    /** 予期せぬエラー */
    static readonly UnexpectedError = 901;
}

/**
 * 表示部門数
 */
export class DisplayDepartmentCount {

    /** 部門別財務報告書 */
    static readonly DepartmentFinancialStatement = 4;

    /** 部門別集計 */
    static readonly DepartmentSummary = 8;
}

/**
 * 科目別補助変更区分
 */
export class IsSubUseChangeKbn {

    /** 変更なし */
    static readonly NoChange = 0;

    /** なし→あり */
    static readonly ChangeFromNoneToYes = 1;

    /** あり→なし */
    static readonly ChangeFromYesToNone = 2;
}

/**
 * 帳票区分
 */
export class JournalHistoryRepoKbn {

    /** 削除履歴 */
    static readonly DeleteHistory = 1;

    /** 修正履歴 */
    static readonly UpdateHistory = 2;

    /** 削除・修正履歴 */
    static readonly DeleteUpdateHistory = 3;
}

/**
 * 期間区分
 */
export class JournalHistoryPeriodKbn {

    /** 月指定 */
    static readonly Month = 1;

    /** 伝票指定 */
    static readonly Slip = 2;
}

/**
 * 部門別比較財務報告書レイアウトNO
 */
export class DepartmentComparativeFinancialStatementLayoutNo {

    /** 部門別比較財務報告書 */
    static readonly DepartmentComparativeFinancialStatement = 0;
}

/**
 * 月別比較出力種類
 */
export class MonthlyComparativeOutputType {

    /** 科目 */
    static readonly Kmk = "000";

    /** 科目・科目別補助 */
    static readonly KmkKmkHojyo = "001";

    /** 部門・科目 */
    static readonly BmnKmk = "410";

    /** 部門・科目・科目別補助 */
    static readonly BmnKmkKmkHojyo = "411";

    /** セグメント１・科目 */
    static readonly Segment1Kmk = "420";

    /** セグメント１・科目・科目別補助 */
    static readonly Segment1KmkKmkHojyo = "421";

    /** セグメント２・科目 */
    static readonly Segment2Kmk = "430";

    /** セグメント２・科目・科目別補助 */
    static readonly Segment2KmkKmkHojyo = "431";

    /** セグメント３・科目 */
    static readonly Segment3Kmk = "440";

    /** セグメント３・科目・科目別補助 */
    static readonly Segment3KmkKmkHojyo = "441";

    /** セグメント４・科目 */
    static readonly Segment4Kmk = "450";

    /** セグメント４・科目・科目別補助 */
    static readonly Segment4KmkKmkHojyo = "451";

    /** セグメント５・科目 */
    static readonly Segment5Kmk = "460";

    /** セグメント５・科目・科目別補助 */
    static readonly Segment5KmkKmkHojyo = "461";

    /** 科目・銀行 */
    static readonly KmkBank = "210";

    /** 科目・取引先 */
    static readonly KmkClient = "220";

    /** 科目・得意先 */
    static readonly KmkCustomers = "230";

    /** 科目・仕入先 */
    static readonly KmkSupplier = "240";

    /** 科目・社員 */
    static readonly KmkStaff = "250";

    /** 科目・汎用補助１ */
    static readonly KmkGeneralPurposeAuxiliary1 = "310";

    /** 科目・汎用補助２ */
    static readonly KmkGeneralPurposeAuxiliary2 = "320";

    /** 科目・汎用補助３ */
    static readonly KmkGeneralPurposeAuxiliary3 = "330";

    /** 科目・汎用補助４ */
    static readonly KmkGeneralPurposeAuxiliary4 = "340";

    /** 科目・汎用補助５ */
    static readonly KmkGeneralPurposeAuxiliary5 = "350";
}

/**
 * 科目体系区分
 */
export class DataCreatorCorpKbn {

    /** 法人（一般） */
    static readonly CorpGeneral = 101;

    /** 法人（工事B/S） */
    static readonly CorpConstructionBS = 102;

    /** 法人（工事P/L） */
    static readonly CorpConstructionPL = 103;

    /** 法人（医療） */
    static readonly CorpMedical = 104;

    /** NPO法人 */
    static readonly CorpNPO = 105;

    /** 個人（一般） */
    static readonly PersonalGeneral = 106;

    /** 個人（医療） */
    static readonly PersonalMedical = 107;

    /** 個人（不動産） */
    static readonly PersonalEstate = 108;
}

/**
 * 消費税区分
 */
export class DataCreatorTaxKbn {

    /** 原則課税 */
    static readonly PrincipleTax = 0;

    /** 簡易課税 */
    static readonly SimplifiedTax = 1;

    /** 免税 */
    static readonly TaxExempted = 2;
}

/**
 * マスター更新実行内容
 */
export class MasterRecalculationExecutionContent {

    /** マスター再計算 */
    static readonly Recalculation = 1;

    /** 仕訳データチェック */
    static readonly CheckOnly = 2;
}

/**
 * マスター再計算印刷タイプ
 */
export class MasterRecalculationPrintType {

    /** 簡略 */
    static readonly Simple = 0;

    /** 詳細 */
    static readonly Detail = 1;
}

/**
 * マスター再計算実行情報ステータスコード
 */
export class MasterRecalculationInfoStatusCode {

    /** 処理待ち */
    static readonly Waiting = 0;

    /** 仕訳補完なし（画面表現は「正常」） */
    static readonly NoCorrection = 1;

    /** 仕訳補完あり（画面表現は「エラー」） */
    static readonly Corrected = 2;

    /** 予期せぬエラー */
    static readonly UnexpectedError = 9;
}

/**
 * 科目別マスター採用登録処理タイプ
 */
export class AdoptAuxiliaryAccountActionType {

    /** 科目別補助採用 */
    static readonly EachKmkAuxiliaryAdoption = 0;

    /** 科目別マスター採用登録 */
    static readonly AdoptAuxiliaryAccount = 1;

    /** データ移行 */
    static readonly Migration = 2;
}

/**
 * 明細書用紙
 */
export class StatementForm {

    /** A4・3連　1人／1頁 */
    static readonly A4OnePerson = 0;

    /** A4・3連　3人／1頁 */
    static readonly A4ThreePersons = 1;
}

/**
 * 領収書欄印字
 */
export class ReceiptPrintKbn {

    /** 出力なし */
    static readonly PrintNone = 0;

    /** 出力あり */
    static readonly PrintSet = 1;
}

/**
 * コードマスタ・コード
 */
export class DTMAINCMCode {

    /** 法人（一般） */
    static readonly CorpGeneral = 1;

    /** 法人（工事B/S） */
    static readonly CorpConstructionBS = 4;

    /** 法人（工事P/L） */
    static readonly CorpConstructionPL = 5;

    /** 法人（医療） */
    static readonly CorpMedical = 19;

    /** NPO法人 */
    static readonly CorpNPO = 41;

    /** 個人（一般） */
    static readonly PersonalGeneral = 6;

    /** 個人（医療） */
    static readonly PersonalMedical = 8;

    /** 個人（不動産） */
    static readonly PersonalEstate = 9;

    /** 個人基本型 */
    static readonly PersonalBasis = 7;

    /** 個人基本型（新4桁） */
    static readonly PersonalBasisFourDigit = 15;

    /** かんたん会計 個人一般 */
    static readonly EasyPersonalGeneral = 87;

    /** かんたん会計 個人不動産 */
    static readonly EasyPersonalRealEstate = 88;
}

/**
 * 給与表示色
 */
export class QyoColorSetting {

    /** 項目表示用の水色 */
    static readonly BackLightBlue = "#dce7f5";
}

/**
 * 銀行管理レイアウトNO
 */
export class BankManagementLayoutNo {

    /** 借入金管理表 */
    static readonly LoanManagementLayout = 0;

    /** 預金・ 借入金残高一覧表 */
    static readonly DepositeLoanManagementLayout = 1;

    /** 預金残高一覧表 */
    static readonly DepositeManagementLayout = 2;
}

/**
 * 工事別集計表レイアウトNO
 */
export class ConstructionSummaryLayoutNo {

    /** 月次 */
    static readonly Monthly = 1;

    /** 実績のみ */
    static readonly ResultsOnly = 2;

    /** 年次 */
    static readonly Annual = 3;
}

/**
 * 科目別工事集計表レイアウトNO
 */
export class AccountConstructionSummaryLayoutNo {

    /** 集計表 */
    static readonly Spreadsheet = 1;
}

/**
 * 工事管理表レイアウトNO
 */
export class ConstructionAdministrationLayoutNo {

    /** 詳細 */
    static readonly Detail = 1;

    /** 簡易 */
    static readonly Simple = 2;

    /** 工事別損益一覧表 */
    static readonly ProfitAndLossByConstruction = 3;

    /** 工事別予算一覧表 */
    static readonly BudgetListByConstruction = 4;

    /** 工事別収支一覧表 */
    static readonly IncomeAndExpenditureByConstruction = 5;
}

/**
 * 工事台帳回収区分
 */
export class ConstructionRegisterCollectKbn {

    /** 合計行 */
    static readonly Total = 1;

    /** 繰越行 */
    static readonly CarryOver = 2;

    /** 累計行 */
    static readonly Cumulative = 3;
}

/**
 * 工事台帳明細区分
 */
export class ConstructionRegisterDetailKbn {

    /** 実在行 */
    static readonly Real = 0;

    /** 合計行 */
    static readonly Total = 1;

    /** 空行 */
    static readonly Blank = 9;
}

/**
 * 未成／完成区分
 */
export class IncompleteCompletedKbn {

    /** 未成 */
    static readonly Incomplete = 0;

    /** 未成・完成 */
    static readonly IncompleteCompleted = 1;

    /** 完成 */
    static readonly Completed = 2;
}

/**
 * 工事集計合計行区分
 */
export class ConstructionAggregateTotalRowKbn {

    /** その他 */
    static readonly None = 0;

    /** 未成合計行 */
    static readonly IncompleteTotal = 1;

    /** 完成合計行 */
    static readonly CompletedTotal = 2;

    /** 合計行 */
    static readonly Total = 3;
}

/**
 * 工事台帳レイアウトNO
 */
export class ConstructionRegisterLayoutNo {

    /** 工事台帳 */
    static readonly Register = 0;
}

/**
 * 工事台帳未成・完成出力区分
 */
export class ConstructionRegisterIncompleteCompletedKbn {

    /** 全て */
    static readonly All = 0;

    /** 未成分 */
    static readonly Incomplete = 1;

    /** 完成分 */
    static readonly Complete = 2;

    /** 出力月完成分 */
    static readonly OutputMonthComplete = 3;
}

/**
 * 回収高計算区分
 */
export class CollectBalanceCalcKbn {

    /** 税込み */
    static readonly TaxIncluded = 0;

    /** 税抜き */
    static readonly TaxExcluded = 1;
}

/**
 * 工事収支計算区分
 */
export class KojiBalanceCalcKbn {

    /** 回収高を税抜きで計算 */
    static readonly CalculatedTaxExcluded = 0;

    /** 税込みで計算 */
    static readonly CalculatedTaxIncluded = 1;
}

/**
 * 実現利益出力区分
 */
export class RealizedProfitCalcKbn {

    /** 完成のみ出力 */
    static readonly CompletedOutput = 0;

    /** 未成・完成出力 */
    static readonly IncompleteCompletedOutput = 1;
}

/**
 * 処理番号
 */
export class ProcessingNo {

    /** チェック処理 */
    static readonly ProcessCheck = 0;

    /** 追加処理 */
    static readonly ProcessAppend = 1;

    /** 共有処理 */
    static readonly ProcessShare = 2;

    /** 変更処理 */
    static readonly ProcessChange = 3;

    /** クリア処理 */
    static readonly ProcessClear = 4;
}

/**
 * 工事比較対照
 */
export class KojiCmpKbn {

    /** 契約金額 */
    static readonly ContractAmount = 0;

    /** 売上金額 */
    static readonly SalesAmount = 1;
}

/**
 * 共通補助管理レイアウトNO
 */
export class CommonAuxiliaryManagementLayoutNo {

    /** 月次集計リスト */
    static readonly MonthlyLayout = 0;

    /** 月次推移集計リスト */
    static readonly MonthlyTransitionLayout = 1;

    /** 推移加算集計リスト */
    static readonly CumulativeTransitionLayout = 2;

    /** 推移残高リスト */
    static readonly YearlyBalanceTransitionLayout = 3;
}

/**
 * 取引内容
 */
export class EasyInputTransactionContents {

    /** 全て */
    static readonly All = 0;

    /** 収入 */
    static readonly Income = 1;

    /** 支出 */
    static readonly Spending = 2;

    /** 振替 */
    static readonly Transfer = 3;
}

/**
 * 科目グループ
 */
export class EasyInputKmkGroup {

    /** グループなし */
    static readonly NoGroup = 0;

    /** 決済方法 */
    static readonly PaymentMethod = 1;
}

/**
 * 取引手段
 */
export class EasyInputTradingMeans {

    /** 共通 */
    static readonly Common = 0;

    /** 収入 */
    static readonly Income = 1;

    /** 支出 */
    static readonly Spending = 2;

    /** 振替 */
    static readonly Transfer = 3;
}

/**
 * かんたん入力形式
 */
export class EasyInputForm {

    /** 表示なし */
    static readonly NoDisplay = 0;

    /** 収入 */
    static readonly Income = 1;

    /** 支出 */
    static readonly Spending = 2;

    /** 振替（振替元借方） */
    static readonly TransferSourceDebit = 3;

    /** 振替（振替元貸方） */
    static readonly TransferSourceCredit = 4;

    /** 表示のみ */
    static readonly DisplayOnly = 9;
}

/**
 * かんたん申告導入設定科目パターン
 */
export class EasyTaxReportKmkPattern {

    /** 一般（製造） */
    static readonly Production = 0;

    /** 一般（建設工事） */
    static readonly Construction = 1;

    /** 農業 */
    static readonly Agriculture = 2;

    /** 不動産 */
    static readonly Estate = 3;
}

/**
 * かんたん申告導入設定青白区分
 */
export class EasyTaxReportBWKbn {

    /** 白色 */
    static readonly White = 0;

    /** 青色 */
    static readonly Blue = 1;
}

/**
 * 確認区分
 */
export class ReadKbn {

    /** 未確認 */
    static readonly UnRead = 0;

    /** 確認 */
    static readonly Read = 1;
}

/**
 * 決算書印刷項目
 */
export class FinancialStatementsPrintItem {

    /** 積立金取崩１ */
    static readonly RefundReserve1 = 1;

    /** 積立金取崩２ */
    static readonly RefundReserve2 = 2;

    /** 積立金取崩３ */
    static readonly RefundReserve3 = 3;

    /** 積立金取崩４ */
    static readonly RefundReserve4 = 4;

    /** 積立金取崩５ */
    static readonly RefundReserve5 = 5;

    /** 積立金取崩６ */
    static readonly RefundReserve6 = 6;

    /** 積立金取崩７ */
    static readonly RefundReserve7 = 7;

    /** 積立金取崩８ */
    static readonly RefundReserve8 = 8;

    /** 積立金取崩９ */
    static readonly RefundReserve9 = 9;

    /** 積立金取崩１０ */
    static readonly RefundReserve10 = 10;

    /** 積立金取崩額合計 */
    static readonly RefundReserveTotal = 91;

    /** 利益金処分額１ */
    static readonly DispositionProfits1 = 101;

    /** 利益金処分額２ */
    static readonly DispositionProfits2 = 102;

    /** 利益金処分額３ */
    static readonly DispositionProfits3 = 103;

    /** 利益金処分額４ */
    static readonly DispositionProfits4 = 104;

    /** 利益金処分額５ */
    static readonly DispositionProfits5 = 105;

    /** 利益金処分額６ */
    static readonly DispositionProfits6 = 106;

    /** 利益金処分額７ */
    static readonly DispositionProfits7 = 107;

    /** 利益金処分額８ */
    static readonly DispositionProfits8 = 108;

    /** 利益金処分額９ */
    static readonly DispositionProfits9 = 109;

    /** 利益金処分額１０ */
    static readonly DispositionProfits10 = 110;

    /** 利益金処分額１１ */
    static readonly DispositionProfits11 = 111;

    /** 利益金処分額１２ */
    static readonly DispositionProfits12 = 112;

    /** 利益金処分額１３ */
    static readonly DispositionProfits13 = 113;

    /** 利益金処分額１４ */
    static readonly DispositionProfits14 = 114;

    /** 利益金処分額１５ */
    static readonly DispositionProfits15 = 115;

    /** 当期利益処分額合計 */
    static readonly CurrentTermDispositionProfitsTotal = 191;

    /** 当期未処分利益 */
    static readonly CurrentTermUndisputedProfits = 192;

    /** 次期繰越利益 */
    static readonly NextTermRetainedEarnings = 193;

    /** 報告年月日 */
    static readonly ReportDate = 201;

    /** 監査年月日 */
    static readonly AuditDate = 202;

    /** Ｂ／Ｓ脚注１ */
    static readonly BSFootNote1 = 301;

    /** Ｂ／Ｓ脚注２ */
    static readonly BSFootNote2 = 302;

    /** Ｂ／Ｓ脚注３ */
    static readonly BSFootNote3 = 303;

    /** Ｂ／Ｓ脚注４ */
    static readonly BSFootNote4 = 304;

    /** Ｂ／Ｓ脚注５ */
    static readonly BSFootNote5 = 305;

    /** Ｂ／Ｓ脚注６ */
    static readonly BSFootNote6 = 306;

    /** Ｂ／Ｓ脚注７ */
    static readonly BSFootNote7 = 307;

    /** Ｂ／Ｓ脚注８ */
    static readonly BSFootNote8 = 308;

    /** Ｂ／Ｓ脚注９ */
    static readonly BSFootNote9 = 309;

    /** Ｂ／Ｓ脚注１０ */
    static readonly BSFootNote10 = 310;

    /** Ｂ／Ｓ脚注１１ */
    static readonly BSFootNote11 = 311;

    /** Ｂ／Ｓ脚注１２ */
    static readonly BSFootNote12 = 312;

    /** Ｂ／Ｓ脚注１３ */
    static readonly BSFootNote13 = 313;

    /** Ｂ／Ｓ脚注１４ */
    static readonly BSFootNote14 = 314;

    /** Ｂ／Ｓ脚注１５ */
    static readonly BSFootNote15 = 315;

    /** Ｂ／Ｓ脚注１６ */
    static readonly BSFootNote16 = 316;

    /** Ｂ／Ｓ脚注１７ */
    static readonly BSFootNote17 = 317;

    /** Ｂ／Ｓ脚注１８ */
    static readonly BSFootNote18 = 318;

    /** Ｂ／Ｓ脚注１９ */
    static readonly BSFootNote19 = 319;

    /** Ｂ／Ｓ脚注２０ */
    static readonly BSFootNote20 = 320;

    /** Ｐ／Ｌ脚注１ */
    static readonly PLFootNote1 = 401;

    /** Ｐ／Ｌ脚注２ */
    static readonly PLFootNote2 = 402;

    /** Ｐ／Ｌ脚注３ */
    static readonly PLFootNote3 = 403;

    /** Ｐ／Ｌ脚注４ */
    static readonly PLFootNote4 = 404;

    /** Ｐ／Ｌ脚注５ */
    static readonly PLFootNote5 = 405;

    /** Ｐ／Ｌ脚注６ */
    static readonly PLFootNote6 = 406;

    /** Ｐ／Ｌ脚注７ */
    static readonly PLFootNote7 = 407;

    /** Ｐ／Ｌ脚注８ */
    static readonly PLFootNote8 = 408;

    /** Ｐ／Ｌ脚注９ */
    static readonly PLFootNote9 = 409;

    /** Ｐ／Ｌ脚注１０ */
    static readonly PLFootNote10 = 410;

    /** Ｐ／Ｌ脚注１１ */
    static readonly PLFootNote11 = 411;

    /** Ｐ／Ｌ脚注１２ */
    static readonly PLFootNote12 = 412;

    /** Ｐ／Ｌ脚注１３ */
    static readonly PLFootNote13 = 413;

    /** Ｐ／Ｌ脚注１４ */
    static readonly PLFootNote14 = 414;

    /** Ｐ／Ｌ脚注１５ */
    static readonly PLFootNote15 = 415;

    /** Ｐ／Ｌ脚注１６ */
    static readonly PLFootNote16 = 416;

    /** Ｐ／Ｌ脚注１７ */
    static readonly PLFootNote17 = 417;

    /** Ｐ／Ｌ脚注１８ */
    static readonly PLFootNote18 = 418;

    /** Ｐ／Ｌ脚注１９ */
    static readonly PLFootNote19 = 419;

    /** Ｐ／Ｌ脚注２０ */
    static readonly PLFootNote20 = 420;

    /** 〔会計方針等〕 */
    static readonly AccountingPolicies = 501;

    /** 棚卸評価方法 */
    static readonly InventoryEvaluationPolicy = 502;

    /** 固定資産償却方法 */
    static readonly FixedAssetDepreciationMethod = 503;

    /** 〔貸借対照表関係〕 */
    static readonly BalanceSheetRelation = 601;

    /** 受取手形割引高 */
    static readonly BillsRecivableDiscount = 602;

    /** 不渡手形 */
    static readonly DishonoredBill = 603;

    /** 裏書譲渡手形 */
    static readonly NoteEndorsed = 604;

    /** 減価償却累計額 */
    static readonly AccumulatedDepreciation = 605;

    /** １株当たりの当期純利益 */
    static readonly EarningsPerShare = 606;

    /** 〔損益計算書関係〕 */
    static readonly ProfitAndLossStatementRelation = 701;

    /** 資本剰余金処分額１ */
    static readonly AmountCapitalSurplusDisposition1 = 801;

    /** 資本剰余金処分額２ */
    static readonly AmountCapitalSurplusDisposition2 = 802;

    /** 資本剰余金処分額３ */
    static readonly AmountCapitalSurplusDisposition3 = 803;

    /** 資本剰余金処分額４ */
    static readonly AmountCapitalSurplusDisposition4 = 804;

    /** 資本剰余金処分額５ */
    static readonly AmountCapitalSurplusDisposition5 = 805;

    /** 資本剰余金処分額６ */
    static readonly AmountCapitalSurplusDisposition6 = 806;

    /** 資本剰余金処分額７ */
    static readonly AmountCapitalSurplusDisposition7 = 807;

    /** 資本剰余金処分額８ */
    static readonly AmountCapitalSurplusDisposition8 = 808;

    /** 資本剰余金処分額９ */
    static readonly AmountCapitalSurplusDisposition9 = 809;

    /** 資本剰余金処分額１０ */
    static readonly AmountCapitalSurplusDisposition10 = 810;

    /** 資本剰余金処分額合計 */
    static readonly AmountCapitalSurplusDispositionTotal = 891;

    /** その他資本剰余金 */
    static readonly OtherCapitalSurplus = 892;

    /** その他資本剰余金次期繰越額 */
    static readonly NextTermOtherCapitalSurplus = 893;
}

/**
 * 決算書タブ
 */
export class FinancialStatementsTab {

    /** 資産の部 */
    static readonly AssetsSection = 0;

    /** 負債・純資産の部 */
    static readonly LiabilitiesStockholders = 1;

    /** 損益計算書 */
    static readonly ProfitAndLoss = 2;

    /** 販管費内訳 */
    static readonly Selling = 3;

    /** 製造原価報告書 */
    static readonly ManufacturingCostReport = 4;
}

/**
 * 辞書検索モード
 */
export class DictionarySearchMode {

    /** 仕訳辞書 */
    static readonly Journal = 0;

    /** ユーザー辞書 */
    static readonly User = 1;

    /** 仕訳辞書、ユーザー辞書 */
    static readonly JournalOrUser = 2;
}

/**
 * 辞書区分
 */
export class DictionaryKbn {

    /** 仕訳辞書（事務所） */
    static readonly OfficeJournal = 0;

    /** 仕訳辞書（顧問先） */
    static readonly ClientJournal = 1;

    /** ユーザー辞書（事務所） */
    static readonly OfficeUser = 10;

    /** ユーザー辞書（顧問先） */
    static readonly ClientUser = 11;
}

/**
 * 辞書検索対象
 */
export class DictionarySearchTarget {

    /** 科目 */
    static readonly Account = 0;

    /** キーワード */
    static readonly Keyword = 1;

    /** 入払区分 */
    static readonly DWKbn = 2;
}

/**
 * ヘルプURL
 */
export class HelpUrl {

    /** バージョン履歴 */
    static readonly VersionHistoryInfo = "NXP90014";

    /** 会社データ作成 */
    static readonly CreateCompanyData = "NXP90056";
}

/**
 * 直販ヘルプURL
 */
export class DirectHelpUrl {

    /** バージョン履歴 */
    static readonly VersionHistoryInfo = "NXC80012";

    /** 会社データ作成 */
    static readonly CreateCompanyData = "NXC80053";
}

/**
 * 決算書タイトル情報
 */
export class FinancialStatementsTitleConfig {

    /** 資産の部 */
    static readonly AssetsSection = 201;

    /** 負債の部 */
    static readonly Liabilities = 202;

    /** 資本の部 */
    static readonly DepartmentOfCapital = 203;

    /** 負債及び純資産の部 */
    static readonly LiabilitiesStockholders = 204;

    /** 営業損益の部 */
    static readonly OperatingProfitAndLoss = 211;

    /** 営業外損益の部 */
    static readonly NonOperatingProfitAndLoss = 212;

    /** 経常損益の部 */
    static readonly OrdinaryIncome = 213;

    /** 特別損益の部 */
    static readonly ExtraordinaryIncome = 214;

    /** 決算報告書 */
    static readonly FinancialStatements = 221;
}

/**
 * 決算書帳票種類
 */
export class FinancialStatementsRepoType {

    /** 資産の部 */
    static readonly AssetsSection = 1;

    /** 負債・純資産の部 */
    static readonly LiabilitiesStockholders = 2;

    /** 損益計算書 */
    static readonly ProfitAndLoss = 3;

    /** 販管費内訳 */
    static readonly Selling = 4;

    /** 製造原価報告書 */
    static readonly ManufacturingCostReport = 5;

    /** 株主資本等変動計算書 */
    static readonly StatementsOfShareholdersEquity = 6;
}

/**
 * 読み取り方式
 */
export class ReadingMethod {

    /** 単方向読み取り */
    static readonly UnidirectionalReading = 1;

    /** 四方向読み取り */
    static readonly FourWayReading = 2;
}

/**
 * 階層区分
 */
export class LevelKbn {

    /** １段表示 */
    static readonly OneStage = 0;

    /** ２段表示 */
    static readonly TwoStage = 1;

    /** ３段表示 */
    static readonly ThreeStage = 2;
}

/**
 * 編集可否区分
 */
export class EditabilityKbn {

    /** 不可 */
    static readonly Improper = 0;

    /** 可 */
    static readonly Permit = 1;
}

/**
 * 親区分
 */
export class ParentKbn {

    /** 子 */
    static readonly Child = 0;

    /** 親 */
    static readonly Parent = 1;
}

/**
 * データ移行システム区分
 */
export class MigrationSystemKbn {

    /** 指定無し */
    static readonly None = 0;

    /** その他会計システム */
    static readonly Other = 99;
}

/**
 * データ移行ファイル種類
 */
export class MigrationFileType {

    /** 種類なし */
    static readonly TypeNotFound = 0;

    /** 科目残高 */
    static readonly Zan = 1;

    /** 補助残高 */
    static readonly HojyoZan = 2;

    /** 仕訳 */
    static readonly Swk = 3;

    /** 部門残高 */
    static readonly BmnZan = 4;

}

/**
 * データ移行ファイルステータス
 */
export class MigrationFileStatus {

    /** 新ファイル */
    static readonly NewFile = 0;

    /** インポート完了 */
    static readonly ImportFinish = 1;

    /** ファイル組合せエラー */
    static readonly FileCombinationError = 2;

    /** ファイル情報登録エラー */
    static readonly FileInfoRegisError = 3;

    /** ファイルアップロードエラー */
    static readonly UploadError = 4;

    /** データ無し */
    static readonly NoData = 5;

    /** ファイル種類複製 */
    static readonly DuplicateFile = 6;

    /** ファイル読込エラー */
    static readonly FileReadError = 7;

    /** ファイルチェックエラー */
    static readonly FileCheckError = 8;

    /** インポートエラー */
    static readonly ImportError = 9;
}

/**
 * データ移行対応設定科目／補助区分
 */
export class MigrationKmkHojyoKbn {

    /** 科目 */
    static readonly Kmk = 0;

    /** 補助 */
    static readonly Hojyo = 1;
}

/**
 * データ移行対応設定補助タブ区分
 */
export class MigrationHojyoTabKbn {

    /** 補助 */
    static readonly Hojyo = 0;

    // 汎用補助
    static readonly GeneralHojyo = 1;

    /** 部門 */
    static readonly Bmn = 2;

     /** セグメント */
    static readonly Segment = 3;

     /** 工事 */
    static readonly Kouji = 4;

    /** なし */
    static readonly None = 99;
}

/**
 * データ移行自動マッピング結果区分
 */
export class MigrationAutoMappingResultKbn {

    /** なし */
    static readonly None = 0;

    /** 自動正式名完全一致 */
    static readonly LongNameExactlyMapped = 1;

    /** 自動正式名部分一致 */
    static readonly LongNamePartialMapped = 2;

    /** 自動簡略名完全一致 */
    static readonly SimpleNameExactlyMapped = 3;

    /** 自動簡略名部分一致 */
    static readonly SimpleNamePartialMapped = 4;

    /** 対応設定済み */
    static readonly AlreadySet = 5;

    /** 対象外 */
    static readonly NotTarget = 9;
}

/**
 * データ移行自マッピング設定区分
 */
export class MigrationMappingSettingKbn {

    /** 未選択 */
    static readonly Unselected = 0;

    /** 新規登録 */
    static readonly NewRegister = 1;

    /** 対応設定 */
    static readonly Mapped = 2;

    /** 登録しない */
    static readonly NotRegister = 3;

    /** 諸口(マスタ未採用時専用) */
    static readonly NewSundry = 4;
}

/**
 * データ移行保存オペレーション区分
 */
export class MigrationSaveOperationKbn {

    /** 変更無し */
    static readonly NoUpdate = 0;

    /** 新規登録 */
    static readonly NewRegister = 1;

    /** マッピング更新 */
    static readonly UpdateMapping = 2;

    /** 期首残高更新 */
    static readonly UpdateBalance = 3;
}

/**
 * 消費税率入力方法
 */
export class TaxRateInputMethod {

    /** ファンクション順次切替方式 */
    static readonly FunctionSequentialSwitching = 0;

    /** 消費税率欄でウィンドウ選択方式 */
    static readonly WindowSelectionInTaxRateField = 1;
}

/**
 * 消費税率欄でウィンドウ選択方式
 */
export class WindowSelectionInTaxRateField {

    /** 消費税率欄でウィンドウ選択方式（変更時に消費税率へ戻る） */
    static readonly BackToSelectTaxRateInChanged = 1;

    /** 消費税率欄でウィンドウ選択方式（常に消費税率を選択する） */
    static readonly AlwaysChooseTaxRate = 2;
}

/**
 * 税率別消費税精算表表示内容
 */
export class TaxRateConsumptionTaxWorksheetKbn {

    /** 売上 */
    static readonly Sales = 1;

    /** 仮受消費税等 */
    static readonly SuspenseConsumptionTaxReceived = 2;

    /** 仕入 */
    static readonly Journalize = 3;

    /** 仮払消費税等 */
    static readonly SuspenseConsumptionTaxPaid = 4;
}

/**
 * 都道府県コード
 */
export class PrefecturesCode {

    /** なし */
    static readonly None = 0;

    /** 北海道 */
    static readonly Hokkaido = 1;

    /** 青森県 */
    static readonly Aomori = 2;

    /** 岩手県 */
    static readonly Iwate = 3;

    /** 宮城県 */
    static readonly Miyagi = 4;

    /** 秋田県 */
    static readonly Akita = 5;

    /** 山形県 */
    static readonly Yamagata = 6;

    /** 福島県 */
    static readonly Fukushima = 7;

    /** 茨城県 */
    static readonly Ibaraki = 8;

    /** 栃木県 */
    static readonly Tochigi = 9;

    /** 群馬県 */
    static readonly Gumma = 10;

    /** 埼玉県 */
    static readonly Saitama = 11;

    /** 千葉県 */
    static readonly Chiba = 12;

    /** 東京都 */
    static readonly Tokyo = 13;

    /** 神奈川県 */
    static readonly Kanagawa = 14;

    /** 新潟県 */
    static readonly Niigata = 15;

    /** 富山県 */
    static readonly Toyama = 16;

    /** 石川県 */
    static readonly Ishikawa = 17;

    /** 福井県 */
    static readonly Fukui = 18;

    /** 山梨県 */
    static readonly Yamanashi = 19;

    /** 長野県 */
    static readonly Nagano = 20;

    /** 岐阜県 */
    static readonly Gifu = 21;

    /** 静岡県 */
    static readonly Shizuoka = 22;

    /** 愛知県 */
    static readonly Aichi = 23;

    /** 三重県 */
    static readonly Mie = 24;

    /** 滋賀県 */
    static readonly Shiga = 25;

    /** 京都府 */
    static readonly Kyoto = 26;

    /** 大阪府 */
    static readonly Osaka = 27;

    /** 兵庫県 */
    static readonly Hyogo = 28;

    /** 奈良県 */
    static readonly Nara = 29;

    /** 和歌山県 */
    static readonly Wakayama = 30;

    /** 鳥取県 */
    static readonly Tottori = 31;

    /** 島根県 */
    static readonly Shimane = 32;

    /** 岡山県 */
    static readonly Okayama = 33;

    /** 広島県 */
    static readonly Hiroshima = 34;

    /** 山口県 */
    static readonly Yamaguchi = 35;

    /** 徳島県 */
    static readonly Tokushima = 36;

    /** 香川県 */
    static readonly Kagawa = 37;

    /** 愛媛県 */
    static readonly Ehime = 38;

    /** 高知県 */
    static readonly Kochi = 39;

    /** 福岡県 */
    static readonly Fukuoka = 40;

    /** 佐賀県 */
    static readonly Saga = 41;

    /** 長崎県 */
    static readonly Nagasaki = 42;

    /** 熊本県 */
    static readonly Kumamoto = 43;

    /** 大分県 */
    static readonly Oita = 44;

    /** 宮崎県 */
    static readonly Miyazaki = 45;

    /** 鹿児島県 */
    static readonly Kagoshima = 46;

    /** 沖縄県 */
    static readonly Okinawa = 47;
}

/**
 * 会計期間内消費税改正区分
 */
export class AccountingPeriod {

    /** 会計期間内 */
    static readonly WithinPeriod = 0;

    /** 改正前 */
    static readonly BeforeAmendment = 1;

    /** 改正後 */
    static readonly AfterAmendment = 2;
}

/**
 * 給与明細書情報項目No
 */
export class QyoimItemNo {

    /** 給与過不足額 */
    static readonly QyoExcessDeficiency = 139;

    /** 賞与過不足額 */
    static readonly ShoExcessDeficiency = 140;

    /** 出勤日数 */
    static readonly AttendanceDays = 601;

    /** 出勤時間 */
    static readonly AttendanceTimes = 602;

    /** 普通時間 */
    static readonly OvertimeHours = 603;

    /** 深夜時間 */
    static readonly LateNightOvertimeHours = 604;

    /** 休日時間 */
    static readonly HolidayOvertimeHours = 605;

    /** 休深時間 */
    static readonly HolidayLateNightOvertimeHours = 606;

    /** 遅早時間 */
    static readonly LateHours = 610;

    /** 欠勤日数 */
    static readonly AbsentDays = 611;

    /** 有休日数 */
    static readonly PaidVacationDays = 612;

    /** 有休残 */
    static readonly PaidVacationRemainingDays = 623;

    /** 勤怠項目1 */
    static readonly AttendanceItem1 = 631;

    /** 勤怠項目2 */
    static readonly AttendanceItem2 = 632;

    /** 勤怠項目3 */
    static readonly AttendanceItem3 = 633;

    /** 勤怠項目4 */
    static readonly AttendanceItem4 = 634;

    /** 勤怠項目5 */
    static readonly AttendanceItem5 = 635;

    /** 勤怠項目6 */
    static readonly AttendanceItem6 = 636;

    /** 勤怠項目7 */
    static readonly AttendanceItem7 = 637;

    /** 基本給 */
    static readonly BasicSalary = 700;

    /** 普通残業手当 */
    static readonly Overtime = 701;

    /** 深夜残業手当 */
    static readonly LateNightOvertime = 702;

    /** 休日残業手当 */
    static readonly HolidayOvertime = 703;

    /** 休深残業手当 */
    static readonly HolidayLateNightOvertime = 704;

    /** 他残業手当1 */
    static readonly OtherOvertime1 = 705;

    /** 他残業手当2 */
    static readonly OtherOvertime2 = 706;

    /** 遅早控除 */
    static readonly LateDeduction = 707;

    /** 欠勤控除 */
    static readonly AbsentDeduction = 708;

    /** 他不就労 */
    static readonly OtherUnemployed = 709;

    /** 手当1（給与） */
    static readonly QyoAllowance1 = 711;

    /** 手当2（給与） */
    static readonly QyoAllowance2 = 712;

    /** 手当3（給与） */
    static readonly QyoAllowance3 = 713;

    /** 手当4（給与） */
    static readonly QyoAllowance4 = 714;

    /** 手当5（給与） */
    static readonly QyoAllowance5 = 715;

    /** 手当6（給与） */
    static readonly QyoAllowance6 = 716;

    /** 手当7（給与） */
    static readonly QyoAllowance7 = 717;

    /** (課)通勤費 */
    static readonly TaxCommutingExpenses = 728;

    /** (課)現物通勤 */
    static readonly InKindTaxCommutingExpenses = 727;

    /** (課)その他 */
    static readonly TaxOtherExpenses = 729;

    /** 税法上支給額 */
    static readonly TaxLawPayment = 730;

    /** (非)通勤費 */
    static readonly NonTaxCommutingExpenses = 731;

    /** (非)現物通勤 */
    static readonly NonInKindTaxCommutingExpenses = 733;

    /** (非)その他 */
    static readonly NonTaxOtherExpenses = 732;

    /** 健康保険（給与） */
    static readonly QyoInsurance = 741;

    /** 基本保険（給与） */
    static readonly QyoBasicInsurance = 746;

    /** 特定保健（給与） */
    static readonly QyoSpecificInsurance = 747;

    /** 厚生年金（給与） */
    static readonly QyoWelfarePension = 742;

    /** 厚生基金（給与） */
    static readonly QyoWelfareFund = 743;

    /** 介護保険（給与） */
    static readonly QyoCareInsurance = 744;

    /** 雇用保険（給与） */
    static readonly QyoEmpInsurance = 745;

    /** 社会保険料計（給与） */
    static readonly QyoInsuranceTotal = 749;

    /** 課税対象額（給与） */
    static readonly QyoTaxAmount = 750;

    /** 所得税（給与） */
    static readonly QyoIncomeTax = 751;

    /** 住民税 */
    static readonly ResidentTax = 752;

    /** 控除1（給与） */
    static readonly QyoDeduction1 = 753;

    /** 控除2（給与） */
    static readonly QyoDeduction2 = 754;

    /** 控除3（給与） */
    static readonly QyoDeduction3 = 755;

    /** 控除4（給与） */
    static readonly QyoDeduction4 = 756;

    /** 個人単価1 */
    static readonly PersonalUnitPrice1 = 791;

    /** 個人単価2 */
    static readonly PersonalUnitPrice2 = 792;

    /** 個人単価3 */
    static readonly PersonalUnitPrice3 = 793;

    /** 個人単価4 */
    static readonly PersonalUnitPrice4 = 794;

    /** 個人単価5 */
    static readonly PersonalUnitPrice5 = 795;

    /** 個人単価6 */
    static readonly PersonalUnitPrice6 = 796;

    /** 個人単価7 */
    static readonly PersonalUnitPrice7 = 797;

    /** 賞与 */
    static readonly ShoBasic = 800;

    /** 手当1（賞与） */
    static readonly ShoAllowance1 = 801;

    /** 手当2（賞与） */
    static readonly ShoAllowance2 = 802;

    /** 手当3（賞与） */
    static readonly ShoAllowance3 = 803;

    /** 手当4（賞与） */
    static readonly ShoAllowance4 = 804;

    /** 手当5（賞与） */
    static readonly ShoAllowance5 = 805;

    /** 手当6（賞与） */
    static readonly ShoAllowance6 = 806;

    /** 手当7（賞与） */
    static readonly ShoAllowance7 = 807;

    /** 手当8（賞与） */
    static readonly ShoAllowance8 = 808;

    /** 手当9（賞与） */
    static readonly ShoAllowance9 = 809;

    /** 手当10（賞与） */
    static readonly ShoAllowance10 = 810;

    /** 手当11（賞与） */
    static readonly ShoAllowance11 = 811;

    /** 手当12（賞与） */
    static readonly ShoAllowance12 = 812;

    /** 手当13（賞与） */
    static readonly ShoAllowance13 = 813;

    /** 手当14（賞与） */
    static readonly ShoAllowance14 = 814;

    /** 手当15（賞与） */
    static readonly ShoAllowance15 = 815;

    /** 現物支給 */
    static readonly ShoInKindPayment = 816;

    /** 健康保険（賞与） */
    static readonly ShoInsurance = 831;

    /** 基本保険（賞与） */
    static readonly ShoBasicInsurance = 836;

    /** 特定保健（賞与） */
    static readonly ShoSpecificInsurance = 837;

    /** 厚生年金（賞与） */
    static readonly ShoWelfarePension = 832;

    /** 厚生基金（賞与） */
    static readonly ShoWelfareFund = 833;

    /** 介護保険（賞与） */
    static readonly ShoCareInsurance = 834;

    /** 雇用保険（賞与） */
    static readonly ShoEmpInsurance = 835;

    /** 社会保険料計（賞与） */
    static readonly ShoInsuranceTotal = 839;

    /** 課税対象額（賞与） */
    static readonly ShoTaxAmount = 840;

    /** 所得税（賞与） */
    static readonly ShoIncomeTax = 841;

    /** 控除1（賞与） */
    static readonly ShoDeduction1 = 842;

    /** 控除2（賞与） */
    static readonly ShoDeduction2 = 843;

    /** 控除3（賞与） */
    static readonly ShoDeduction3 = 844;

    /** 控除4（賞与） */
    static readonly ShoDeduction4 = 845;

    /** 控除5（賞与） */
    static readonly ShoDeduction5 = 846;

    /** 総支給額 */
    static readonly QyoTotalAmountPaid = 740;

    /** 控除合計（給与） */
    static readonly QyoDeductionTotal = 769;

    /** 差引支給額（給与） */
    static readonly QyoDeductionAfterpay = 770;

    /** 賞与支給総額 */
    static readonly ShoTotalAmountPaid = 830;

    /** 控除合計（賞与） */
    static readonly ShoDeductionTotal = 859;

    /** 差引支給額（賞与） */
    static readonly ShoDeductionAfterpay = 860;

    /** 扶養人数 */
    static readonly FuyouNum = 10000001;

    /** 雇用集計区分 */
    static readonly EmploymentKbn = 10000002;

    /** 労働保険集計区分 */
    static readonly WorkersAccidentKbn = 10000003;

    /** 賞与税率 */
    static readonly BonusRate = 10000004;
}

/**
 * 他社給与区分
 */
export class OtherPayrollKbn {

    /** 弥生給与 */
    static readonly Yayoi = 1;

    /** 給料王 */
    static readonly Sorimachi = 2;

    /** MFクラウド給与 */
    static readonly MoneyForward = 3;

    /** 人事労務freee */
    static readonly Free = 4;

    /** 汎用レイアウト */
    static readonly GeneralLayout = 5;
}

/**
 * 給与対象情報区分
 */
export class PayrollConversionTargetInfoKbn {

    /** 従業員 */
    static readonly Employee = 1;

    /** 扶養親族等 */
    static readonly Fuyou = 2;

    /** 年次有給休暇 */
    static readonly AnnualHolidays = 3;

    /** 部門 */
    static readonly Department = 4;

    /** 金融機関 */
    static readonly Bank = 5;

    /** 住民税納付先 */
    static readonly Locations = 6;

    /** 振込口座 */
    static readonly BankAccount = 7;

    /** 賃金台帳（給与1月　支給実績） */
    static readonly PaymentQyo01 = 8;

    /** 賃金台帳（給与2月　支給実績） */
    static readonly PaymentQyo02 = 9;

    /** 賃金台帳（給与3月　支給実績） */
    static readonly PaymentQyo03 = 10;

    /** 賃金台帳（給与4月　支給実績） */
    static readonly PaymentQyo04 = 11;

    /** 賃金台帳（給与5月　支給実績） */
    static readonly PaymentQyo05 = 12;

    /** 賃金台帳（給与6月　支給実績） */
    static readonly PaymentQyo06 = 13;

    /** 賃金台帳（給与7月　支給実績） */
    static readonly PaymentQyo07 = 14;

    /** 賃金台帳（給与8月　支給実績） */
    static readonly PaymentQyo08 = 15;

    /** 賃金台帳（給与9月　支給実績） */
    static readonly PaymentQyo09 = 16;

    /** 賃金台帳（給与10月　支給実績） */
    static readonly PaymentQyo10 = 17;

    /** 賃金台帳（給与11月　支給実績） */
    static readonly PaymentQyo11 = 18;

    /** 賃金台帳（給与12月　支給実績） */
    static readonly PaymentQyo12 = 19;

    /** 賃金台帳（賞与1回　支給実績） */
    static readonly PaymentSho01 = 20;

    /** 賃金台帳（賞与2回　支給実績） */
    static readonly PaymentSho02 = 21;

    /** 賃金台帳（賞与3回　支給実績） */
    static readonly PaymentSho03 = 22;

    /** 賃金台帳（賞与4回　支給実績） */
    static readonly PaymentSho04 = 23;

    /** 賃金台帳（賞与5回　支給実績） */
    static readonly PaymentSho05 = 24;

    /** 賃金台帳（賞与6回　支給実績） */
    static readonly PaymentSho06 = 25;
}

/**
 * 他社給与変換項目
 */
export class OtherPayrollConversionItem {

    /** 扶養人数 */
    static readonly FuyouNum = "扶養人数";

    /** 雇用集計区分 */
    static readonly EmploymentKbn = "雇用集計区分";

    /** 労働保険集計区分 */
    static readonly WorkersAccidentKbn = "労働保険集計区分";

    /** 賞与税率 */
    static readonly BonusRate = "賞与税率";
}

/**
 * 他社給与変換出力順
 */
export class OtherPayrollConversionIndex {

    /** 扶養人数 */
    static readonly FuyouNum = 1;

    /** 賞与税率 */
    static readonly BonusRate = 2;

    /** 雇用集計区分 */
    static readonly EmploymentKbn = 3;

    /** 労働保険集計区分 */
    static readonly WorkersAccidentKbn = 4;
}

/**
 * レシート付加情報区分
 */
export class ReceiptAddKbn {

    /** 標準 */
    static readonly Standard = 0;

    /** 還元 */
    static readonly Reduction = 1;
}

/**
 * 辞書遷移表示区分
 */
export class DictionaryTransitionDispKbn {

    /** 仕訳作成 */
    static readonly EntryCreateForJournal = 0;

    /** 仕訳辞書編集 */
    static readonly EntryJournalDictionary = 1;
}

/**
 * 決済手段
 */
export class PaymentMethod {

    /** 仕訳辞書から適用 */
    static readonly Automatic = 0;

    /** 現金 */
    static readonly Cash = 1;

    /** クレジットカード(未払金) */
    static readonly AccountsPayable = 2;

    /** デビットカード(預金) */
    static readonly CheckingAccounts = 3;

    /** 電子マネー(前払金・前渡金) */
    static readonly AdvancePayments = 4;
}

/**
 * 仕訳分割区分
 */
export class JournalSplitKbn {

    /** 自動分割 */
    static readonly Auto = 1;

    /** その他 */
    static readonly Other = 0;
}

/**
 * 洗替対象
 */
export class CleaningTargetKbn {

    /** 勘定科目 */
    static readonly Account = 1;

    /** 補助科目 */
    static readonly Hojyo = 2;
}

/**
 * データ移行の会計王 部門コード
 */
export class MigrationKkoBmnCode {

    /** 諸口 */
    static readonly Sundry = "0";
}

/**
 * データ移行の会計王 部門名称
 */
export class MigrationKkoBmnName {

    /** 諸口 */
    static readonly Sundry = "全事業所(共通)";
}

/**
 * 科目別マスタ採用可能区分
 */
export class AuxiliaryAccountCanAdoptedKbn {

    /** 正常 */
    static readonly Normal = 0;

    /** 補助科目数上限エラー */
    static readonly ExceedNumberAdopted = 1;

    /** 科目別補助と工事の併用エラー */
    static readonly NoCombinationOfSubKmkAndKoji = 2;

    /** 補助と工事の併用エラー */
    static readonly NoCombinationOfCommonHojyoAndKoji = 3;

    /** 特殊科目の科目別補助採用エラー */
    static readonly SpecialAccountCanNotadoptedForSubKmk = 4;

    /** 取引先同時採用エラー */
    static readonly SameCommonAdoptionForClient = 5;

    /** 銀行コードを設定した科目に銀行採用エラー */
    static readonly NoCombinationOfBankCodeKmkAndBank = 6;
}

/**
 * MWIサービスURL
 */
export class MWIServiceUrl {

    /** MWIインフォメーションURL */
    static readonly MWIInformationUrl = "https://info.369webcash.com/";
}

/**
 * 仕訳辞書入払区分
 */
export class SwkDictionaryDWKbn {

    /** 未設定 */
    static readonly NotSet = 0;

    /** 入金 */
    static readonly Deposit = 1;

    /** 出金 */
    static readonly Withdraw = 2;
}

/**
 * データ移行ステップ
 */
export class MigrationStep {

    /** ファイル取込ステップ */
    static readonly FileImport = 1;

    /** 項目設定ステップ */
    static readonly ItemSetting = 2;

    /** 科目設定ステップ */
    static readonly KmkSetting = 3;

    /** 補助設定ステップ */
    static readonly HojyoSetting =4;

    /** 仕訳インポートステップ */
    static readonly SwkImport = 5;

    /** 完了ステップ */
    static readonly Finish = 6;
}

/**
 * データ移行項目設定クラウド項目（期首残高）
 */
export class MigrationKisyuCloudItem {

    /** 未選択 */
    static readonly None = 0;

    /** 勘定科目名称 */
    static readonly KmkName = 1;

    /** 残高金額 */
    static readonly BeginningBalance = 2;
}

/**
 * データ移行項目設定クラウド項目（補助期首残高）
 */
export class MigrationHojyoKisyuCloudItem {

    /** 未選択 */
    static readonly None = 0;

    /** 勘定科目名称 */
    static readonly KmkName = 1;

    /** 補助種類 */
    static readonly HojyoKbn = 2;

    /** 補助科目名称 */
    static readonly HojyoName = 3;

    /** 残高金額 */
    static readonly BeginningBalance = 4;
}

/**
 * データ移行項目設定クラウド項目（補助期首残高）
 */
 export class MigrationBmnKisyuCloudItem {

    /** 未選択 */
    static readonly None = 0;

    /** 勘定科目名称 */
    static readonly KmkName = 1;

    /** 部門名称 */
    static readonly BmnName = 2;

    /** 残高金額 */
    static readonly BeginningBalance = 3;
}

/**
 * データ移行項目設定クラウド項目（仕訳）
 */
export class MigrationSwkCloudItem {

    /** 未選択 */
    static readonly None = 0;

    /** 日付 */
    static readonly DenDate = 1;

    /** 決算仕訳区分 */
    static readonly Nmonth = 2;

    /** 伝票NO */
    static readonly DenNo = 3;

    /** 借方科目 */
    static readonly DKmkName = 4;

    /** 借方補助科目 */
    static readonly DHojyoName = 5;

    /** 借方部門 */
    static readonly DBmnName = 6;

    /** 借方セグメント */
    static readonly DSegmentName = 7;

    /** 借方取引先 */
    static readonly DBankName = 8;

    /** 借方銀行 */
    static readonly DClientName = 9;

    /** 借方社員 */
    static readonly DStaffName = 10;

    /** 借方汎用補助１ */
    static readonly DGeneralHojyo1Name = 11;

    /** 借方汎用補助２ */
    static readonly DGeneralHojyo2Name = 12;

    /** 借方汎用補助３ */
    static readonly DGeneralHojyo3Name = 13;

    /** 借方汎用補助４ */
    static readonly DGeneralHojyo4Name = 14;

    /** 借方汎用補助５ */
    static readonly DGeneralHojyo5Name = 15;

    /** 借方工事 */
    static readonly DKoujiName = 16;

    /** 貸方科目 */
    static readonly CKmkName = 17;

    /** 貸方補助科目 */
    static readonly CHojyoName = 18;

    /** 貸方部門 */
    static readonly CBmnName = 19;

    /** 貸方セグメント */
    static readonly CSegmentName = 20;

    /** 貸方銀行 */
    static readonly CBankName = 21;

    /** 貸方取引先 */
    static readonly CClientName = 22;

    /** 貸方社員 */
    static readonly CStaffName = 23;

    /** 貸方汎用補助１ */
    static readonly CGeneralHojyo1Name = 24;

    /** 貸方汎用補助２ */
    static readonly CGeneralHojyo2Name = 25;

    /** 貸方汎用補助３ */
    static readonly CGeneralHojyo3Name = 26;

    /** 貸方汎用補助４ */
    static readonly CGeneralHojyo4Name = 27;

    /** 貸方汎用補助５ */
    static readonly CGeneralHojyo5Name = 28;

    /** 貸方工事 */
    static readonly CKoujiName = 29;

    /** 金額 */
    static readonly InpSum = 30;

    /** 消費税額 */
    static readonly TaxSum = 31;

    /** 消費税区分 */
    static readonly TaxCode = 32;

    /** 消費税率 */
    static readonly TaxRate = 33;

    /** 業種 */
    static readonly TypeCd = 34;

    /** 仕入日（自） */
    static readonly ShireStart = 35;

    /** 仕入日（至） */
    static readonly ShireEnd = 36;

    /** 仕入先 */
    static readonly ShireSaki = 37;

    /** 摘要 */
    static readonly Tekiyo = 38;

    /** 事業者区分 */
    static readonly IvJigyoKbn = 39;
}

/**
 * データ移行項目変換設定変換カテゴリ
 */
export class MigrationConvertCategory {

    /** 決算仕訳区分 */
    static readonly Nmonth = 1;

    /** 消費税区分 */
    static readonly TaxCode = 2;

    /** 消費税率 */
    static readonly TaxRate = 3;

    /** 業種 */
    static readonly TypeCd = 4;

    /** 事業者区分 */
    static readonly IvJigyoKbn = 5;
}

/**
 * データ移行項目変換設定変換項目
 */
export class MigrationConvertItem {

    /** 決算仕訳区分 */
    static readonly Nmonth = 1;

    /** 消費税コード */
    static readonly TaxCode = 2;

    /** 消費税率 */
    static readonly TaxRate = 3;

    /** 業種 */
    static readonly TypeCd = 4;

    /** 事業者区分 */
    static readonly IvJigyoKbn = 5;
}

/**
 * データ移行項目変換設定決算仕訳区分
 */
export class MigrationConvertItemNMonth {

    /** 通常月 */
    static readonly Nmonth = 0;

    /** 第1四半期決算1 */
    static readonly FirstQuarterClosing1 = 5;

    /** 第1四半期決算2 */
    static readonly FirstQuarterClosing2 = 6;

    /** 第1四半期決算3 */
    static readonly FirstQuarterClosing3 = 7;

    /** 第2四半期決算1 */
    static readonly SecondQuarterClosing1 = 15;

    /** 第2四半期決算2 */
    static readonly SecondQuarterClosing2 = 16;

    /** 第2四半期決算3 */
    static readonly SecondQuarterClosing3 = 17;

    /** 第3四半期決算1 */
    static readonly ThirdQuarterClosing1 = 25;

    /** 第3四半期決算2 */
    static readonly ThirdQuarterClosing2 = 26;

    /** 第3四半期決算3 */
    static readonly ThirdQuarterClosing3 = 27;

    /** 第4四半期決算1 */
    static readonly FourthQuarterClosing1 = 35;

    /** 第4四半期決算2 */
    static readonly FourthQuarterClosing2 = 36;

    /** 第4四半期決算3 */
    static readonly FourthQuarterClosing3 = 37;
}

/**
 * データ移行項目変換設定消費税区分キー
 */
export class MigrationConvertItemTaxCodeKey {

    /** 消費税区分を選択してください */
    static readonly Unselected = -2;

    /** デフォルト自動セット（会社の設定に従う） */
    static readonly DefaultAutoSet = -1;

    /** なし */
    static readonly None = 0;

    /** 課税売上(内税) */
    static readonly TaxationSalesIncluded = 1;

    /** 課税売上控除(内税) */
    static readonly TaxationSalesSubtractionIncluded = 2;

    /** 課税貸倒償却(内税) */
    static readonly TaxationSalesLoanlossRepaymentIncluded = 3;

    /** 課税貸倒回収(内税) */
    static readonly TaxationSalesLoanlossCollectionIncluded = 4;

    /** 課税売上(外税) */
    static readonly TaxationSalesExcluded = 5;

    /** 課税売上控除(外税) */
    static readonly TaxationSalesSubtractionExcluded = 6;

    /** 課税貸倒償却(外税) */
    static readonly TaxationSalesLoanlossRepaymentExcluded = 7;

    /** 課税貸倒回収(外税) */
    static readonly TaxationSalesLoanlossCollectionExcluded = 8;

    /** 非課税売上 */
    static readonly ExemptionSales = 9;

    /** 有価証券売上等(非課税) */
    static readonly ValuablePapersSalesExemption = 10;

    /** 不課税売上(精算取引) */
    static readonly TaxationLessnessSales = 11;

    /** 輸出売上 */
    static readonly ExportSales = 12;

    /** 非課税輸出売上 */
    static readonly ExemptionExportSales = 13;

    /** 不明 */
    static readonly Unclear = 14;

    /** 課税仕入(内税) */
    static readonly TaxationJournalizeIncluded = 15;

    /** 課税仕入控除(内税) */
    static readonly TaxationJournalizeSubtractionIncluded = 16;

    /** 共通仕入(内税) */
    static readonly CommonJournalizeIncluded = 17;

    /** 非課税売上対応仕入(内税) */
    static readonly ExemptionSalseJournalizeIncluded = 18;

    /** 共通仕入控除(内税) */
    static readonly CommonJournalizeSubtractionIncluded = 19;

    /** 非課税売上対応仕入控除(内税) */
    static readonly ExemptionSalseJournalizeSubtractionIncluded = 20;

    /** 課税仕入(外税) */
    static readonly TaxationJournalizeExcluded = 21;

    /** 課税仕入控除(外税) */
    static readonly TaxationJournalizeSubtractionExcluded = 22;

    /** 共通仕入(外税) */
    static readonly CommonJournalizeExcluded = 23;

    /** 非課税売上対応仕入(外税) */
    static readonly ExemptionSalseJournalizeExcluded = 24;

    /** 共通仕入控除(外税) */
    static readonly CommonJournalizeSubtractionExcluded = 25;

    /** 非課税売上対応仕入控除(外税) */
    static readonly ExemptionSalseJournalizeSubtractionExcluded = 26;

    /** 非課税仕入 */
    static readonly ExemptionJournalize = 27;

    /** 不課税仕入(精算取引) */
    static readonly TaxationLessnessStockingCleaningContract = 28;

    /** 輸入仕入(内税) */
    static readonly ImportJournalizeIncluded = 29;

    /** 輸入共通仕入(内税) */
    static readonly ImportCommonJournalizeIncluded = 30;

    /** 非課税売上対応輸入仕入(内税) */
    static readonly ExemptionSalseImportIncluded = 31;

    /** 輸入仕入(外税) */
    static readonly ImportJournalizeExcluded = 32;

    /** 輸入共通仕入(外税) */
    static readonly ImportCommonJournalizeExcluded = 33;

    /** 非課税売上対応輸入仕入(外税) */
    static readonly JournalizeCotCode64 = 34;

    /** 輸入仕入(地方消費税) */
    static readonly ImportJournalizeConsumptionTax = 35;

    /** 輸入共通仕入(地方消費税) */
    static readonly ImportCommonJournalizeLocalConsumptionTax = 36;

    /** 非課税売上対応輸入仕入(地方消費税) */
    static readonly ExemptionJournalizeImport = 37;

    /** 課税売上対応特定課税仕入 */
    static readonly TaxationSalesSpecificJournalize = 38;

    /** 共通売上対応特定課税仕入 */
    static readonly TaxationExemptionSalesSpecificJournalize = 39;

    /** 課税売上対応特定課税仕入控除 */
    static readonly TaxationSalesSpecificJournalizeReturn = 40;

    /** 非課税売上対応特定課税仕入 */
    static readonly ExemptionSalseImportSpecificTaxationJournalize = 41;

    /** 共通売上対応特定課税仕入控除 */
    static readonly TaxationExemptionSalesSpecificJournalizeReturn = 42;

    /** 非課税売上対応特定課税仕入控除 */
    static readonly ExemptionSalseImportSpecificTaxationJournalizeReturn = 43;

    /** 消費税不明取引 */
    static readonly UnclearBusiness = 44;

    /** 棚卸調整消費税(課税) */
    static readonly StocktakingAdjustTaxation = 45;

    /** 棚卸調整消費税(共通) */
    static readonly StocktakingAdjustConsumptionTax = 46;

    /** 棚卸調整消費税(非課税) */
    static readonly StocktakingAdjustExemption = 47;
}

/**
 * データ移行設定保存ステータス
 */
export class MigrationSettingSaveStatus {

    /** 保存 */
    static readonly Saved = 0;

    /** 未保存 */
    static readonly UnSaved = 1;

    /** 変更 */
    static readonly Changed = 2;
}

/**
 * 工事採用区分
 */
export class KoujiUseKbn {

    /** なし */
    static readonly None = 0;

    /** あり */
    static readonly Setting = 1;
}

export class MigrationErrStatus
{
    /** エラー */
    static readonly Error = "エラー";

    /** 警告 */ 
    static readonly Warning = "警告";
}

