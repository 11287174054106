import React from 'react';
import { ModalHeader, Button } from 'reactstrap';
import Close from '../../../../components/atoms/Icon/Close';
import IconButton from '../../../../components/molecules/CMN/IconButton/IconButton';
import logoImg from '../../../../images/logo_aikansa_R.png';
import Axios from 'axios';
import { CompanyDataListViewModel } from '../../../../models/companyDataListViewModel';
import { AlertKbnEnum, setAlertMessage } from '../../../../stores/AlertMessage';
import { setIsLoading } from '../../../../stores/NowLoading';

// 初期表示時のURL
export const initialUrl = '/api/v1/CompanyDataSelect/GetInitialList';
// 会社切り替えのURL
export const switchCompanyUrl = '/api/v1/CompanyDataSelect/SwitchCompany';

type CompanyDataSelectProps = {
  onClose: () => void;
}

const CompanyDataSelect: React.FC<CompanyDataSelectProps> = props => {
  const [communicating, setCommunicating] = React.useState(true);
  const [selectedClient, setSelectedClient] = React.useState(0);
  const [viewModel, setViewModel] = React.useState<CompanyDataListViewModel>(new CompanyDataListViewModel());
  const [compSelectedFlg, setCompSelectedFlg] = React.useState(false);
  const closeButton = (
    <IconButton className='text-white' icon={<Close className='icon-lg' />} onClick={props.onClose} tabIndex={1} disabled={compSelectedFlg}/>
  );

  var companyList = viewModel.CompanyDataList == undefined ? null : viewModel.CompanyDataList.map((company, i) => {
    return (
      <div className='each-company' onFocus={(e) => handleOnCompanyClick(e)} tabIndex={i+10} onBlur={() => handleOnFocusOut()}>
        <div className='company-name'>
          {company.SComName}
        </div>
        <div className='company-code'>
          {company.CompanyCode}
        </div>
        <span hidden id='clientcode'>
          {company.ClientCode}
        </span>
      </div>
    );
  });

  const handleOnFocusOut = () => {
    setCommunicating(true);
  }

  const handleOnCompanyClick = (e: React.FocusEvent) => {
    var target = e.currentTarget.getElementsByTagName('span')[0].innerText;
    // ClientCodeをセット
    setSelectedClient(Number(target));
    setCommunicating(false);
  }

  // 選択するボタンを押下
  const handleOnSelectClick = () => {
    setCommunicating(true);
    //会社切り替え
    switchCompanyOnPost(switchCompanyUrl, selectedClient);
  }

  /* 通信処理 */
  // 表示データ取得処理（GET）
  const initialOnGet = (url: string) => {
    setIsLoading(true);
    Axios.get<CompanyDataListViewModel>(url)
      .then(response => {
        if (response.data.ErrorMessage == '会社未選択') {
          setCompSelectedFlg(true);
        }
        setViewModel(response.data);
      })
      .catch(error => {
        if (typeof error.response.data !== 'undefined') {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
  };

  // 会社選択切り替え（POST）
  const switchCompanyOnPost = (url: string, clientCode: number) => {
    setIsLoading(true);
    Axios.post(url, {clientCode: clientCode})
      .then(response => {
        setIsLoading(false);
        props.onClose();
      })
      .catch(error => {
        setIsLoading(false);
        if (typeof error.response.data !== 'undefined') {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
        }
      })
  };

  React.useEffect(() => {
      initialOnGet(initialUrl);
  }, [initialUrl]);

    return (
        <div className="wrapper">
          <div className='header text-white'>
            <ModalHeader className='py-1' close={closeButton}>
              {'会社データ選択'}
            </ModalHeader>
          </div>
            <div className="company-center">
              <img src={logoImg} className='logo' />
              <div className='agreement'>
                <span>{'ご利用する会社を選択してください。'}</span>
              </div>
              <div className='company-body'>
                {companyList}
              </div>
              <div>
                <Button 
                  onMouseDown={handleOnSelectClick}
                  className='select-button'
                  disabled={communicating}
                  tabIndex={1000}
                >
                  {'選択する'}
                </Button>
              </div>
            </div>
        </div>
    );
}

export default CompanyDataSelect;