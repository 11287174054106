import { MidtermClosingEnum } from '../constants/enums';

/**
 * 中間決算区分毎に表示する月度かの判定
 * @param nMonth 内部月
 * @param midtermClosingEnum 中間決算区分
 */
export const isDspMonth = (nMonth: number, midtermClosingEnum: MidtermClosingEnum) => {
  let ret = true;
  const tensPlace = Math.floor(nMonth / 10);
  switch (midtermClosingEnum) {
    case MidtermClosingEnum.None: // なし
      if (!isNormalMonth(nMonth) && tensPlace !== 3) {
        // 通常月以外且つ決算以外
        ret = false;
      }
      break;
    case MidtermClosingEnum.Half: // 半期決算
      if (!isNormalMonth(nMonth) && tensPlace !== 3 && tensPlace !== 1) {
        // 通常月以外且つ決算以外
        ret = false;
      }
      break;
  }
  return ret;
};

/**
 * 複数決算月の初期表示時の月かの判定
 * @param nMonth 内部月
 */
export const isMultMidtermClosingInitDisplay = (nMonth: number) => {
  let ret = false;
  if (!isNormalMonth(nMonth)) {
    const onePlace = nMonth! % 10;
    if (onePlace === 5) {
      ret = true;
    }
  }
  return ret;
};

/**
 * 通常月かの判定
 * @param nMonth 内部月
 */
export const isNormalMonth = (nMonth: number) => {
  const onePlace = nMonth % 10;
  return onePlace === 1 || onePlace === 2 || onePlace === 3 || onePlace === 4;
};
