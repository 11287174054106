import { setGlobal, getGlobal } from 'reactn';

const initialState = {
  isLoading: false
};

export const setIsLoading = (isLoading: boolean) => {
  setGlobal({ isLoading: isLoading });
};

const settingGlobal = () => {
  setGlobal(initialState);
};

export const getLodingStatus = () => {
  var getData = getGlobal();
  return getData.isLoading;
};

export default {
  settingGlobal
};
