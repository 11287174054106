import React from 'react';
import Axios from 'axios';
import * as wjGrid from 'wijmo/wijmo.grid';

import JournalLine from '../../A040/AIKADT002000004/JournalLine';
import Popup from '../../../../components/molecules/CMN/Popup/Popup';
import TadLayout, { tabElement } from '../../../../components/templates/TabLayout';
import ExcelOutputsetting from '../../../organisms/A000/ExcelOutputsetting';
import AuditResultGrid from '../../../../components/molecules/CMN/Grid/AuditResultGrid';
import AuditResultJournalLineSumGrid from '../../../../components/molecules/CMN/Grid/AuditResultJournalLineSumGrid';
import IconButton from '../../../../components/molecules/CMN/IconButton/IconButton';
import FileExcel from '../../../../components/atoms/Icon/FileExcel';
import Close from '../../../../components/atoms/Icon/Close';
import * as labels from '../../../../constants/labels';
import * as constants from '../../../../constants/constant';
import { ConfStatusEnum } from '../../../../constants/dataMaps';
import { createWjDataMap } from '../../../../utils/dataMapsUtils';
import { AuditResultJournalLineViewModel } from '../../../../models/auditResultJournalLineViewModel';
import { setAlertMessage, AlertKbnEnum } from '../../../../stores/AlertMessage';
import { AuditTypeEnum, ActivateScreenEnum } from '../../../../constants/enums';

export const initialUrl = '/api/v1/AuditResultJournalLine/Initial';

type AuditResultJournalLineProps = {
  activated: boolean;
  month: number;
  onClose: () => void;
};
const initializationViewModel: AuditResultJournalLineViewModel = {
  TMonth: '',
  Results: [],
  SumCnts: []
};

const initializationActiveTab = { index: 0 };

// tabIndexの初期値
const baseTabIndex = 200;

const AuditResultJournalLine: React.FC<AuditResultJournalLineProps> = props => {
  const { activated, month, onClose } = props;
  const [activeTab, setActiveTab] = React.useState({ ...initializationActiveTab });
  const [excelPopupActivated, setExcelPopupActivated] = React.useState(false);

  //フィルターExcel出力設定
  const [filters, setFilters] = React.useState('');
  //パラメーターExcel出力設定
  const [excelPopupServiceParams, setExcelPopupServiceParams] = React.useState('');

  const [viewModel, setViewModel] = React.useState<AuditResultJournalLineViewModel>(initializationViewModel);

  const handleOnClose = () => {
    setViewModel(new AuditResultJournalLineViewModel());
    setActiveTab({ ...initializationActiveTab });
    onClose();
  };
  const handleOnExcelClick = () => {
    setExcelPopupActivated(true);
  };
  const handleOnExcelClose = () => {
    setExcelPopupActivated(false);
  };

  //仕訳明細アイコンイベント
  const [JournalLineLinkActivated, setJournalLineLinkActivated] = React.useState(false);
  const [seqNo, setSeqNo] = React.useState([0]);
  const [rowNo, setRowNo] = React.useState(0);

  const handleonJournalLineLinkClick = (SeqNo: number, RowNo: number) => {
    setSeqNo([SeqNo]);
    setRowNo(RowNo);
    setJournalLineLinkActivated(true);
  };

  const handleOnJournalLineLinkClose = () => {
    setJournalLineLinkActivated(false);
  };

  const handleOnTabClick = (index: number) => {
    activeTab.index = index;
  };

  //フィルターExcel出力設定
  const handleOnFilterChanged = (filterJson: string) => {
    setFilters(filterJson);
  };

  const firstFocusElement = React.useRef<any>();
  const lastFocusElement = React.useRef<any>();
  const popupRef = React.useRef<any>();
  const excelIconRef = React.useRef<any>();
  const tabLastRef = React.useRef<any>();
  const gridRefResult = React.useRef<any>();
  const gridInnerRefResult = React.useRef<any>();
  const radioAllRef = React.useRef<any>();
  const radioLatestRef = React.useRef<any>();
  const gridRefSummary = React.useRef<any>();
  const gridInnerRefSummary = React.useRef<any>();

  const radiofocus = () => {
    if (radioAllRef.current.checked === true) {
      radioAllRef.current.focus();
    } else {
      radioLatestRef.current.focus();
    }
  };

  /** キーボード操作（キーダウン） */
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 9) {
      if (event.shiftKey === false) {
        // タブキー押下時

        const target: HTMLElement = event.target as HTMLElement;
        if (target.tabIndex === lastFocusElement.current.tabIndex) {
          // 最後のフォーカスエレメントの場合

          // 最初のエレメントにフォーカス
          firstFocusElement.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();

          // 監査結果のラジオボタンの場合
        } else if (radioAllRef.current != null && target.tabIndex === radioAllRef.current.tabIndex) {
          if (gridRefResult.current != null) {
            const flexGrid = gridRefResult.current.control as wjGrid.FlexGrid;
            flexGrid.focus();
            // 後のイベントをキャンセル
            event.preventDefault();
            event.stopPropagation();
          }
          // フォーカスが当たっていない場合
        } else if (
          target.tabIndex < firstFocusElement.current.tabIndex ||
          target.tabIndex > lastFocusElement.current.tabIndex
        ) {
          // 最初のエレメントにフォーカス
          firstFocusElement.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
      } else {
        // シフト＋タブキー押下時

        const target: HTMLElement = event.target as HTMLElement;
        if (target.tabIndex === firstFocusElement.current.tabIndex) {
          // 最後のフォーカスエレメントの場合

          // 最後のエレメントにフォーカス
          lastFocusElement.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();

          // ExcelIconボタン
        } else if (target.tabIndex === excelIconRef.current.tabIndex) {
          // フォーカスを制御

          switch (activeTab.index) {
            case 0:
              if (gridRefResult.current != null) {
                const flexGrid = gridRefResult.current.control as wjGrid.FlexGrid;
                flexGrid.focus();
                // 後のイベントをキャンセル
                event.preventDefault();
                event.stopPropagation();
              }
              break;
            case 1:
              if (gridRefSummary.current != null) {
                const flexGrid = gridRefSummary.current.control as wjGrid.FlexGrid;
                flexGrid.focus();
                // 後のイベントをキャンセル
                event.preventDefault();
                event.stopPropagation();
              }
              break;
          }

          // フォーカスが当たっていない場合
        } else if (
          target.tabIndex < firstFocusElement.current.tabIndex ||
          target.tabIndex > lastFocusElement.current.tabIndex
        ) {
          // 最後のエレメントにフォーカス
          lastFocusElement.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }
  };
  /** Grid（監査結果） */
  const handleKeyDownGridResult = (event: KeyboardEvent) => {
    if (event.keyCode === 9) {
      if (event.shiftKey === false) {
        // タブキー押下時

        excelIconRef.current.focus();
        // 後のイベントをキャンセル
        event.preventDefault();
        event.stopPropagation();
      } else {
        // シフト＋タブキー押下時

        radiofocus();
        // 後のイベントをキャンセル
        event.preventDefault();
        event.stopPropagation();
      }
    }
  };
  /** Grid（集計表） */
  const handleKeyDownGridSummary = (event: KeyboardEvent) => {
    if (event.keyCode === 9) {
      if (event.shiftKey === false) {
        // タブキー押下時

        excelIconRef.current.focus();
        // 後のイベントをキャンセル
        event.preventDefault();
        event.stopPropagation();
      } else {
        // シフト＋タブキー押下時

        tabLastRef.current.focus();
        // 後のイベントをキャンセル
        event.preventDefault();
        event.stopPropagation();
      }
    }
  };

  React.useEffect(() => {
    if (
      viewModel.Results != undefined &&
      viewModel.Results.length > 0 &&
      viewModel.SumCnts != undefined &&
      viewModel.SumCnts.length > 0
    ) {
      // 画面表示完了後に実行
      setTimeout(() => {
        if (popupRef.current) {
          // ポップアップにキーダウンイベントのフック
          popupRef.current.addEventListener('keydown', handleKeyDown);
        }
      });
    }
  }, [viewModel]);

  React.useEffect(() => {
    if (
      viewModel.Results != undefined &&
      viewModel.Results.length > 0 &&
      viewModel.SumCnts != undefined &&
      viewModel.SumCnts.length > 0
    ) {
      // 画面表示完了後に実行
      setTimeout(() => {
        if (gridInnerRefResult.current) {
          // 監査結果グリッドにキーダウンイベントのフック
          gridInnerRefResult.current.addEventListener('keydown', handleKeyDownGridResult);
        }
        if (gridInnerRefSummary.current) {
          // 集計表グリッドにキーダウンイベントのフック
          gridInnerRefSummary.current.addEventListener('keydown', handleKeyDownGridSummary);
        }
      });
    }
  }, [viewModel, activeTab.index]);

  //通信処理(初期表示データ作成)
  const initialOnGet = (url: string, month: number) => {
    // WebAPI一覧のURLを作成
    const encodedUrl = [url, encodeURI(month.toString())].join('/');
    Axios.get<AuditResultJournalLineViewModel>(encodedUrl)
      .then(response => {
        setViewModel(response.data);
      })
      .catch(error => {
        if (typeof error.response.data !== 'undefined') {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
        }
        handleOnClose();
      });
  };

  // 通信処理の実行
  React.useEffect(() => {
    if (activated) {
      setViewModel(initializationViewModel);
      initialOnGet(initialUrl, month);
    }
    setExcelPopupServiceParams(
      JSON.stringify({
        nMonth: month
      })
    );
  }, [initialUrl, month, activated]);

  //タブ切り替え
  const tabData: tabElement[] = [
    //仕訳明細監査結果
    {
      label: labels.AIKADT002000009_TAB_HEADER_JOURNALIZATIONITEMAUDITRESULT,
      tabIndex: baseTabIndex + 1,
      navLinkRef: firstFocusElement,
      element:
        viewModel.Results == undefined || viewModel.Results.length <= 0 ? (
          <div className='d-none' />
        ) : (
          <div>
            <AuditResultGrid
              gridClassName='AuditResultJournalLine-grid'
              gridRef={gridRefResult}
              gridTabIndex={baseTabIndex + 4}
              innerRef={gridInnerRefResult}
              columns={gridColumns}
              sources={viewModel.Results}
              execDate={viewModel.LastExecDate}
              radioTabIndex={baseTabIndex + 3}
              radioAllRef={radioAllRef}
              radioLatestRef={radioLatestRef}
              journalLineLinkTabIndex={-1}
              //フィルターExcel出力設定
              onFilterChanged={handleOnFilterChanged}
              //仕訳明細アイコンイベント
              onJournalLineLinkClick={handleonJournalLineLinkClick}
            />
          </div>
        )
    },

    //仕訳明細監査結果集計表
    {
      label: labels.AIKADT002000009_TAB_HEADER_JOURNALIZATIONITEMAUDITRESULTAGGREGATETABLE,
      tabIndex: baseTabIndex + 2,
      navLinkRef: tabLastRef,
      element:
        viewModel.LastExecDate == undefined || viewModel.SumCnts == undefined ? (
          <div className='d-none' />
        ) : (
          <div>
            <AuditResultJournalLineSumGrid
              gridClassName='AuditResultJournalLine-grid'
              innerRef={gridInnerRefSummary}
              gridRef={gridRefSummary}
              gridTabIndex={baseTabIndex + 3}
              sources={viewModel.SumCnts}
              execDate={viewModel.LastExecDate}
              //フィルターExcel出力設定
              onFilterChanged={handleOnFilterChanged}
            />
          </div>
        )
    }
  ];

  return viewModel.Results == undefined || viewModel.Results.length < 1 || viewModel.SumCnts == undefined ? null : (
    <Popup
      isOpen={activated}
      onCloseClick={handleOnClose}
      header={[
        labels.AIKADT002000009_FUNCTION_NAME,
        labels.AIKADT002000009_FUNCTION_HEADER_TARGETMONTH + viewModel.TMonth
      ].join('　')}
      size='lg'
      innerRef={popupRef}
      headerIcon={
        <div>
          <IconButton
            icon={<FileExcel className='icon-lg' />}
            className='text-white'
            onClick={handleOnExcelClick}
            tabIndex={baseTabIndex + 5}
            innerRef={excelIconRef}
          />
          <IconButton
            icon={<Close className='icon-lg' />}
            className='ml-2 text-white'
            onClick={handleOnClose}
            tabIndex={baseTabIndex + 6}
            innerRef={lastFocusElement}
          />
        </div>
      }
    >
      <TadLayout tabElements={tabData} defaultActiveTab={activeTab.index} onTabClick={handleOnTabClick} />

      <ExcelOutputsetting
        activated={excelPopupActivated}
        onClose={handleOnExcelClose}
        templateId={constants.AIKADT002000009E01}
        //フィルターExcel出力設定
        filters={filters}
        //ヘッダー情報
        headerLeft={[labels.AIKADT002000009_FUNCTION_HEADER_TARGETMONTH, viewModel.TMonth].join('')}
        //パラメーター
        serviceParams={excelPopupServiceParams}
      />

      <JournalLine
        activated={JournalLineLinkActivated}
        onClose={handleOnJournalLineLinkClose}
        auditType={AuditTypeEnum.JournalLineAudit}
        nMonth={month}
        stNMonth={month}
        edNMonth={month}
        seqNo={seqNo}
        rowNo={rowNo}
        activateScreenType={ActivateScreenEnum.Result}
      />
    </Popup>
  );
};

//仕訳明細監査結果タブカラム
const gridColumns = [
  { header: labels.AIKADT002000009_GRID_HEADER_SEQUENCE, binding: 'No', width: 60, align: 'center', format: 'd' },
  {
    header: labels.AIKADT002000009_GRID_HEADER_JOURNALIZATIONITEMRULE,
    inputType: 'JournalLineLinkIcon',
    binding: 'RuleName',
    width: '*',
    minWidth: 300,
    align: 'left'
  },
  {
    header: labels.AIKADT002000009_GRID_HEADER_STATUS,
    binding: 'ConfStatus',
    width: 100,
    align: 'center',
    dataMap: createWjDataMap(ConfStatusEnum),
    inputType: 'status'
  },
  {
    header: labels.AIKADT002000009_GRID_HEADER_EXECUTINGTIME,
    binding: 'ExecDate',
    width: 150,
    align: 'center',
    dataType: 'Date',
    format: 'yyyy/MM/dd HH:mm:ss'
  }
];

export default AuditResultJournalLine;
