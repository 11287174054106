import React from 'react';
import Resizable from 're-resizable';
import classnames from 'classnames';

export type Enable = {
  top: boolean;
  right: boolean;
  bottom: boolean;
  left: boolean;
  topRight: boolean;
  bottomRight: boolean;
  bottomLeft: boolean;
  topLeft: boolean;
};
export type DefaultSize = {
  width?: string | number;
  height?: string | number;
};

export type A3TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  name: string;
  enable?: Enable;
  defaultSize?: DefaultSize;
  resizableClass?: string;
  innerRef?: React.RefObject<any>;
  onInputChange?: (evnet: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onResize?: (el: HTMLDivElement) => void;
};

const A3TextArea: React.FC<A3TextAreaProps> = props => {
  const textareaProps = { ...props };
  delete textareaProps['enable'];
  delete textareaProps['defaultSize'];
  delete textareaProps['resizableClass'];
  delete textareaProps['innerRef'];
  delete textareaProps['onInputChange'];
  delete textareaProps['children'];
  delete textareaProps['onResize'];
  return (
    <Resizable enable={props.enable} className={props.resizableClass} defaultSize={props.defaultSize} onResize={(e, dir, el) => {
      if (props.onResize) {
        props.onResize(el);
      }
    }}>
      <textarea
        key={props.name}
        {...textareaProps}
        className={classnames(props.className, 'form-control resize-none w-100 h-100')}
        onChange={props.onInputChange}
        ref={props.innerRef}
      />
    </Resizable>
  );
};

export default A3TextArea;
