import { CodeAttr } from "../../core/constants/constant";
import { UserDiv } from "../../core/constants/enums";
import { MjsUtilities } from "../usefuls/utilities";
import "../usefuls/utilities";

let ___SessionInfoUserDiv: UserDiv;

/**
 * 管理表出力条件設定ダイアログ抽象クラス
 */
export class BizUtil {
    /** サーバ側のセッション情報のユーザ区分(実体は _Layout.cshtml を参照) */
    
    // Todo : 下記以外のメソッドの実装
    
    /**
     * 今ログインしているユーザが直販ユーザであればtrueを返す
     */
    public static IsPortableUser(): boolean {
        if (MjsUtilities.isNull(___SessionInfoUserDiv)) {
            return false;
        }
        if (___SessionInfoUserDiv == UserDiv.Portable) {
            return true;
        }
        return false;
    }
    /**
     * コードを一時的な値に変換して返す。（画面用）
     * @param code 対象のコード値
     * @param codeAttr コード属性
     * @param codeDigit コード桁数
     */
    public static toTemporaryCode(code: string, codeAttr: number, codeDigit: number): string {
        var temporaryCode: string = null!;
        if (code != null) {
            switch (codeAttr) {
                case CodeAttr.Number:
                case CodeAttr.NumberZeroFill:
                    var numCode = parseInt(code);
                    var tempCode = isNaN(numCode) ? code : numCode.toString();
                    if (codeAttr == CodeAttr.NumberZeroFill) {
                        temporaryCode = tempCode.padLeft(codeDigit, "0");
                    } else {
                        temporaryCode = tempCode;
                    }
                    break;
                default:
                    temporaryCode = code;
                    break;
            }
        }
        return temporaryCode;
    }

    /**
     * コードを永続的な値に変換して返す。（Database保存用）
     * @param code 対象のコード値
     * @param codeAttr コード属性
     */
    public static toPermanentCode(code: string, codeAttr: number): string {
        var permanentCode: string = null!;
        if (code != null) {
            switch (codeAttr) {
                case CodeAttr.Number:
                case CodeAttr.NumberZeroFill:
                    permanentCode = code.padLeft(10, "0");
                    break;
                default:
                    permanentCode = code;
                    break;
            }
        }
        return permanentCode;
    }
}
