import React from 'react';
import classnames from 'classnames';
import { ListGroup, ListGroupItem } from 'reactstrap';

import QuestionCircle from '../../atoms/Icon/QuestionCircle';
import InfoCircle from '../../atoms/Icon/InfoCircle';
import SignOutAlt from '../../atoms/Icon/SignOutAlt';
import FileAlt from '../../atoms/Icon/FileAlt';

import * as labels from '../../../constants/labels';
import { CooperationEnum, AuthorityEnum } from '../../../constants/enums';

type IAppAccordionMenuProps = React.HTMLAttributes<HTMLDivElement> & {
    verInformationOnClick?: () => void;
    verHistoryOnClick?: () => void;
    companyDataSelectOnClick?: () => void;
    logoutOnClick?: () => void;
    passwordChangeOnClick?: () => void;
    cooperation: CooperationEnum | undefined;
    authority: AuthorityEnum | undefined;
    isCompanyCntOver: boolean | undefined;
};

const AppAccordionMenu = React.memo<IAppAccordionMenuProps>(props => {
    const { className, verInformationOnClick, verHistoryOnClick, logoutOnClick, passwordChangeOnClick, companyDataSelectOnClick } = props;

    // divタグに不要なpropsを除去
    const divProps = { ...props };
    delete divProps['verInformationOnClick'];
    delete divProps['verHistoryOnClick'];
    delete divProps['logoutOnClick'];
    delete divProps['passwordChangeOnClick'];
    delete divProps['companyDataSelectOnClick'];

    const changePasswordMenuItem = props.cooperation == CooperationEnum.Other ? (props.authority != AuthorityEnum.Bizsky ? (
        <ListGroupItem
            tag='button'
            action
            onClick={passwordChangeOnClick}
            className='text-white py-2 rounded-0'
            tabIndex={107}>
            <FileAlt /> パスワード変更
        </ListGroupItem>
    ) : (
        <ListGroupItem
            tag='button'
            disabled={true}
            style={{ backgroundColor: '#869dc7' }}
            action
            className = 'text-white py-2 rounded-0 grayout'
            tabIndex = { 107} >
            <FileAlt /> パスワード変更
        </ListGroupItem >
        )) : (null);

    const changeCompanyMenuItem = props.cooperation == CooperationEnum.Other ? (props.authority != AuthorityEnum.Bizsky && !props.isCompanyCntOver  ? (
        <ListGroupItem 
            tag='button'
            action onClick={companyDataSelectOnClick}
            className='text-white py-2 rounded-0'
            tabIndex={108}>
            <SignOutAlt /> 会社を切り替える
        </ListGroupItem>
    ) : (
        <ListGroupItem 
            tag='button'
            disabled={true}
            style={{ backgroundColor: '#869dc7' }}
            action
            className='text-white py-2 rounded-0 grayout'
            tabIndex={108}>
            <SignOutAlt /> 会社を切り替える
        </ListGroupItem>
    )) :
        (null);

    return (
        <div {...divProps} className={classnames('app-other-menu', className)}>

            <ListGroup>
                {changePasswordMenuItem}
                <ListGroupItem
                    tag='button'
                    action
                    onClick={verInformationOnClick}
                    className='text-white py-2 rounded-0'
                    tabIndex={105}
                >
                    <QuestionCircle /> {labels.AIKCMN001000001_BUTTON_FUNCTION_VERSIONINFORMATION}
                </ListGroupItem>
                <ListGroupItem
                    tag='button'
                    action
                    onClick={verHistoryOnClick}
                    className='text-white py-2 rounded-0'
                    tabIndex={106}
                >
                    <InfoCircle /> {labels.AIKCMN001000001_BUTTON_FUNCTION_VERSIONHISTORY}
                </ListGroupItem>
                {changeCompanyMenuItem}
                <ListGroupItem tag='button' action onClick={logoutOnClick} className='text-white py-2 rounded-0' tabIndex={107}>
                    <SignOutAlt /> {labels.AIKCMN001000001_BUTTON_FUNCTION_LOGOUT}
                </ListGroupItem>
            </ListGroup>
        </div>
    );
});
export default AppAccordionMenu;
