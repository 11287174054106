import React from 'react';
import { createWjDataMap } from '../../../utils/dataMapsUtils';
import { DataTypeEnum, TargetJournalEnum } from '../../../constants/dataMaps';

import AuditExecPopup, { AEPopupProps } from './AuditExecPopup';
import AllSelectableGrid, { sortLabel } from '../CMN/Grid/AllSelectableGrid';

import * as Labels from '../../../constants/labels';
import { JournalLineRuleVO } from '../../../models/journalLineRuleVO';
import { JournalLineRuleAuditVO } from '../../../models/journalLineRuleAuditVO';

export type AegPopupProps = AEPopupProps & {
  conditionHeading?: string;
  journalLineRules?: Array<JournalLineRuleVO>;
  onJLClick?: (rules: Array<JournalLineRuleAuditVO>) => void;
};

// tabIndexの初期値
const baseTabIndex = 200;

const sortLabelMapList: sortLabel[] = [
  {
    binding: 'SelectFlg',
    map: [
      { key: true, value: Labels.AIKADT002000001_GRID_SORT_TRUE },
      { key: false, value: Labels.AIKADT002000001_GRID_SORT_FALSE }
    ]
  }
];
const journalLineRuleColumns = [
  {
    header: ' ',
    binding: 'SelectFlg',
    width: 60,
    align: 'center',
    inputType: 'allSelectable'
  },
  {
    header: Labels.AIKADT002000001_GRID_HEADER_SEQUENCE,
    binding: 'No',
    width: 60,
    isReadOnly: true,
    align: 'center'
  },
  {
    header: Labels.AIKADT002000001_GRID_HEADER_TYPE,
    binding: 'DataTypeKbn',
    width: 80,
    isReadOnly: true,
    align: 'center',
    dataMap: createWjDataMap(DataTypeEnum)
  },
  {
    header: Labels.AIKADT002000001_GRID_HEADER_TARGET,
    binding: 'TargetKbn',
    width: 80,
    isReadOnly: true,
    align: 'center',
    dataMap: createWjDataMap(TargetJournalEnum)
  },
  {
    header: Labels.AIKADT002000001_GRID_HEADER_NAME,
    binding: 'RuleName',
    width: '*',
    minWidth: 200,
    isReadOnly: true,
    align: 'center',
    cssClass: 'text-left'
  }
];

const AuditExecGridPopup: React.FC<AegPopupProps> = props => {
  const isDisabled = props.isExecute || props.disabled;

  const handleOnClick = (rules?: Array<JournalLineRuleVO>) => {
    let selected = rules!.filter(rule => rule.SelectFlg === true);
    let targets: Array<JournalLineRuleAuditVO> | undefined = selected!.map(rule => ({
      DataTypeKbn: rule.DataTypeKbn,
      NCode: rule.NCode
    }));
    props.onJLClick!(targets!);
  };

  const firstFocusElement = React.useRef<any>();
  const lastFocusElement = React.useRef<any>();

  const conditionHeading = props.conditionHeading ? <label>{props.conditionHeading}</label> : '';

  const popupProps = { ...props };
  delete popupProps['conditionHeading'];
  delete popupProps['journalLineRules'];
  delete popupProps['onJLClick'];

  return (
    <AuditExecPopup
      {...popupProps}
      onClick={() => handleOnClick(props.journalLineRules)}
      execButtonTabIndex={baseTabIndex + 4}
      cancelButtonTabIndex={baseTabIndex + 5}
      closeIconTabIndex={baseTabIndex + 6}
      closeIconRef={lastFocusElement}
      firstFocusElement={firstFocusElement}
      lastFocusElement={lastFocusElement}
    >
      <div>{conditionHeading}</div>
      <div>
        <AllSelectableGrid
          gridClassName={'AuditExecPopup-grid'}
          columns={journalLineRuleColumns}
          sources={props.journalLineRules}
          sortLabelMapList={sortLabelMapList}
          disabled={isDisabled}
          buttonSelectionRef={firstFocusElement}
          buttonSelectionTabIndex={baseTabIndex + 1}
          buttonDeselectionTabIndex={baseTabIndex + 2}
          gridTabIndex={baseTabIndex + 3}
        />
      </div>
      <div>{props.children}</div>
    </AuditExecPopup>
  );
};
export default AuditExecGridPopup;
