import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import './styles/styles.scss';
import 'animate.css';
import 'wijmo/cultures/wijmo.culture.ja'; // Wijmoコンポネントの日本語化
import configureAxios from './config/axios.config';
import configureWijmo from './config/wijmo.config';
import AlertMessage from './stores/AlertMessage';
import NowLoading from './stores/NowLoading';
import App from './App';

configureAxios.settingGlobal(window.location.search.slice(1));
if (configureAxios.shouldRedirect()) {
  window.location.assign('/');
}

configureWijmo.settingGlobal(); // Wijmoライセンスの読込み
AlertMessage.settingGlobal(); // アラート表示読込み
NowLoading.settingGlobal(); // ロード中表示の読み込み

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
