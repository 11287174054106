import { DataModelBase } from "../../core/models/dataModelBase";

/**
 * マスターデータ ViewModel クラス
 */

export class CSInfoCacheViewModel extends DataModelBase {
    /** 顧問先管理コード */
    public ClientCode!: number;

    /** 年度 */
    public ClientYear!: number;

    /** 顧問先管理ユーザー */
    public ClientUser!: string;

    /** マスタ区分 */
    public MasterKbn!: number;

    /** 外部コード */
    public GCode!: string;

    /** 更新日時 */
    public UpdateTime!: Date;

    /** 郵便番号（基番） */
    public ZipCode1!: number;

    /** 郵便番号（枝番） */
    public ZipCode2!: number;

    /** 郵便番号（バーコード） */
    public Barcode!: string;

    /** 住所（上段） */
    public Address1!: string;

    /** 住所（下段） */
    public Address2!: string;

    /** 電話番号 */
    public TelNo!: string;

    /** 送付先部署名 */
    public SectionName!: string;

    /** 担当者 */
    public PersonName!: string;

    /** 敬称区分 */
    public TitleKbn!: number;

    /** 締日（１） */
    public PCloseDay1!: number;

    /** 支払予定月（１） */
    public PayMonth1!: number;

    /** 支払予定日（１） */
    public PayDay1!: number;

    /** 休日区分（１） */
    public PHolidayKbn1!: number;

    /** 締日（２） */
    public PCloseDay2!: number;

    /** 支払予定月（２） */
    public PayMonth2!: number;

    /** 支払予定日（２） */
    public PayDay2!: number;

    /** 休日区分（２） */
    public PHolidayKbn2!: number;

    /** 締日（３） */
    public PCloseDay3!: number;

    /** 支払予定月（３） */
    public PayMonth3!: number;

    /** 支払予定日（３） */
    public PayDay3!: number;

    /** 休日区分（３） */
    public PHolidayKbn3!: number;

    /** 締日（１） */
    public RCloseDay1!: number;

    /** 入金予定月（１） */
    public RcvMonth1!: number;

    /** 入金予定日（１） */
    public RcvDay1!: number;

    /** 休日区分（１） */
    public RHolidayKbn1!: number;

    /** 締日（２） */
    public RCloseDay2!: number;

    /** 入金予定月（２） */
    public RcvMonth2!: number;

    /** 入金予定日（２） */
    public RcvDay2!: number;

    /** 休日区分（２） */
    public RHolidayKbn2!: number;

    /** 締日（３） */
    public RCloseDay3!: number;

    /** 入金予定月（３） */
    public RcvMonth3!: number;

    /** 入金予定日（３） */
    public RcvDay3!: number;

    /** 休日区分（３） */
    public RHolidayKbn3!: number;

    /** 手形ランク */
    public BillRank!: string;

    /** 手形支払場所１ */
    public ClearHouse1!: string;

    /** 手形支払場所２ */
    public ClearHouse2!: string;

    /** 手形支払場所３ */
    public ClearHouse3!: string;

    /** 手形振出人１ */
    public DrawerDraft1!: string;

    /** 手形振出人２ */
    public DrawerDraft2!: string;

    /** 手形振出人３ */
    public DrawerDraft3!: string;

    /** 手形振出人４ */
    public DrawerDraft4!: string;

    /** 手形振出人５ */
    public DrawerDraft5!: string;

    /** 為替ﾘｽｸ */
    public RiskKbn!: number;

}
