import * as wjGrid from 'wijmo/wijmo.grid';
import moment from 'moment';

/**
 * Gridに表示する一覧データを日付項目を日付型に変換します。
 * カラム情報にdataType='Date'と設定されている列データを変換対象とします。
 * @param coloms カラム情報
 * @param itemsSource 一覧データ
 */
export const convertItemsSourceToDateType = (coloms: any[], itemsSource: any[]) => {
  if (coloms != undefined && coloms.length > 0 && itemsSource != undefined && itemsSource.length > 0) {
    const dataTypeCol = coloms.filter(col => col.dataType === 'Date');
    itemsSource.forEach(source => {
      dataTypeCol.forEach(col => {
        if (source[col.binding]) source[col.binding] = moment(source[col.binding]).toDate();
      });
    });
  }
};

/**
 * MultiRowに表示する一覧データを日付項目を日付型に変換します。
 * カラム情報にdataType='Date'と設定されている列データを変換対象とします。
 * @param coloms カラム情報
 * @param itemsSource 一覧データ
 */
export const convertItemsSourceToDateTypeForMultiRow = (coloms: any[], itemsSource: any[]) => {
  if (coloms != undefined && coloms.length > 0 && itemsSource != undefined && itemsSource.length > 0) {
    const cols: any[] = [];
    coloms.forEach(col => {
      col.cells.forEach((cell: any) => {
        cols.push(cell);
      });
    });
    convertItemsSourceToDateType(cols, itemsSource);
  }
};

/**
 * グリッドヘッダーのソートアイコンを返します。
 * @param column 列情報
 */
export const getGridHeaderSortIcon = (column: wjGrid.Column) => {
  let currentSort = '';
  if (column.currentSort === '+') {
    currentSort = "<span class='wj-glyph-up'></span>";
  } else if (column.currentSort === '-') {
    currentSort = "<span class='wj-glyph-down'></span>";
  }
  return currentSort;
};
