import React from 'react';

type _ProcessMigrationStepKmkSettingPartialProps = {
  scope?: any
};

const _ProcessMigrationStepKmkSettingPartial: React.FC<_ProcessMigrationStepKmkSettingPartialProps> = props => {
  return (
    <>
    <section id="stepKmkSetting" className="dp_none">
    <span className="step-number" >{props.scope && props.scope.stepKmkSettingDispNo}</span>
    <div className="step-title">インポートされた勘定科目、期首残高の設定を行ってください。</div>
    <div id="stepKmkSetting-content" className="step-content">
        <div className="step-description mb10">
            ・AI監査支援に登録されている科目の名称にマッチした科目が自動的に設定されます。<br />
            &ensp;マッチしなかった科目は、AI監査支援に既にある科目と対応付けさせるか、科目の新規登録を行ってください。<br /><br />
            ・「残高をインポートする」ボタンをクリックすると、対応付けされている科目に期首残高がインポートされます。<br />
            &ensp;<span className="red-icon">残高インポートを行う場合は、必ず設定を保存してから行ってください。残高のインポートを行うと、科目の期首残高を全てクリアしてインポートされますのでご注意ください。</span>
        </div>
        <label className="step-description-label">新規勘定科目の登録</label>
        <p className="fa fa-question-circle-o ml-1" data-toggle="tooltip" data-html="true" data-placement="right" title="科目の新規登録を行いたい場合は下のボタンから勘定科目登録を行ってください。（別ウィンドウで勘定科目登録を開きます）<br />勘定科目の追加又は修正を行った場合は、「表示を更新する」ボタンをクリックしてください。「表示を更新する」ボタンをクリックすることで、更新内容が画面に反映されます。"></p>
        <div className="dp_flex mb20">
            <button id="registKmk-btn" type="button" className="btn btn-primary pr10 pl10 wd190"><span className="fa fa-external-link mr10"></span>勘定科目登録を起動</button>
            <button id="refresh-btn" type="button" className="btn btn-primary pr10 pl10 wd190"><span className="fa fa-refresh mr10"></span>表示を更新する</button>
        </div>
        <label className="step-description-label">勘定科目の対応付け</label>
        <div>・製造科目は<span className="migration-blue">青字</span>で表示されます。</div>
        <div id="kmk-required" className="dp_none red-icon"><span className="fa fa-exclamation-circle ml5 mr5"></span>背景色が赤い科目は、対応付けの設定が必要です。</div>
        <div hidden={props.scope && !(props.scope.isKmkSettingChanged)} className="migration-blue"><span className="fa fa-info-circle ml5 mr5"></span>勘定科目の対応付けの設定が変更されています。設定を保存する場合は、「設定を保存する」ボタンをクリックして設定内容を保存してください。</div>
        <div id="kmkSettingTable" className="settingTable migration-table-border"></div>
        <div className="dp_flex pb20">
            <div>
                <button id="kmkSetting-save-btn" type="button" className="btn btn-primary mt10 pr10 pl10 wd190">設定を保存する</button>
                <div id="kmksetting-save-success" className="btn-success-msg" style={{visibility: "hidden",}}><span className="fa fa-check-circle text-success btn-success-icon pl5 pr5"></span>設定を保存しました。</div>
            </div>
            <div>
                <button id="kmkkisyu-import-btn" type="button" className="btn btn-primary mt10 pr10 pl10 wd190">残高をインポートする</button>
                <div id="kmkkisyu-import-success" className="btn-success-msg" style={{visibility: "hidden",}}><span className="fa fa-check-circle text-success btn-success-icon pl5 pr5"></span>残高のインポートが完了しました。</div>
            </div>
        </div>
    </div>
</section>
    </>
  )
};
export default _ProcessMigrationStepKmkSettingPartial;