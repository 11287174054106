import React from 'react';
import classNames from 'classnames';

import * as labels from '../../../../constants/labels';
import IconButton from '../IconButton/IconButton';
import FileAlt from '../../../atoms/Icon/FileAlt';
import ExcelButton from '../../../atoms/Icon/FileExcel';
import Close from '../../../atoms/Icon/Close';
import CloudUploadAlt from '../../../atoms/Icon/CloudUploadAlt';
import CloudDownloadAlt from '../../../atoms/Icon/CloudDownloadAlt';

export type PageHeaderProps = React.HTMLAttributes<HTMLDivElement> & {
  pageTitle?: string;
  excelButtonDisabled?: boolean;
  createButtonDisabled?: boolean;
  importButtonDisabled?: boolean;
  exportButtonDisabled?: boolean;
  closeButtonDisabled?: boolean;
  excelButtonTabIndex?: number;
  importButtonTabIndex?: number;
  exportButtonTabIndex?: number;
  createButtonTabIndex?: number;
  closeButtonTabIndex?: number;
  excelButtonRef?: React.RefObject<any>;
  createButtonRef?: React.RefObject<any>;
  importButtonRef?: React.RefObject<any>;
  exportButtonRef?: React.RefObject<any>;
  closeButtonRef?: React.RefObject<any>;
  onExcelClick?: () => void;
  onCreateClick?: () => void;
  onImportClick?: () => void;
  onExportClick?: () => void;
  onCloseClick?: () => void;
};

const iconButtonClass = '-icon mx-1';
const iconClass = '';
const centerClass = 'd-flex align-items-center';

const PageHeader: React.FC<PageHeaderProps> = props => {
  const {
    pageTitle,
    excelButtonDisabled,
    createButtonDisabled,
    importButtonDisabled,
    exportButtonDisabled,
    closeButtonDisabled,
    excelButtonTabIndex,
    createButtonTabIndex,
    importButtonTabIndex,
    exportButtonTabIndex,
    closeButtonTabIndex,
    excelButtonRef,
    createButtonRef,
    importButtonRef,
    exportButtonRef,
    closeButtonRef,
    onExcelClick,
    onCreateClick,
    onImportClick,
    onExportClick,
    onCloseClick,
    className,
    children
  } = props;

  const iconList = [];
  if (typeof onCreateClick === 'function')
    iconList.push(
      <IconButton
        key='FileAlt'
        data-testid='create-icon'
        onClick={onCreateClick}
        disabled={createButtonDisabled}
        icon={<FileAlt className={iconClass} />}
        className={iconButtonClass}
        tabIndex={createButtonTabIndex}
        innerRef={createButtonRef}
      >
        {labels.COMMON_BUTTON_FUNCTION_NEW}
      </IconButton>
    );
  if (typeof onExcelClick === 'function')
    iconList.push(
      <IconButton
        key='Excel'
        data-testid='excel-icon'
        onClick={onExcelClick}
        disabled={excelButtonDisabled}
        icon={<ExcelButton className={iconClass} />}
        className={iconButtonClass}
        tabIndex={excelButtonTabIndex}
        innerRef={excelButtonRef}
      >
        {labels.COMMON_BUTTON_FUNCTION_EXCEL}
      </IconButton>
    );
  if (typeof onImportClick === 'function')
    iconList.push(
      <IconButton
        key='Import'
        data-testid='Import-icon'
        onClick={onImportClick}
        disabled={importButtonDisabled}
        icon={<CloudUploadAlt className={iconClass} />}
        className={iconButtonClass}
        tabIndex={importButtonTabIndex}
        innerRef={importButtonRef}
      >
        {labels.COMMON_BUTTON_FUNCTION_IMPORT}
      </IconButton>
    );
  if (typeof onExportClick === 'function')
    iconList.push(
      <IconButton
        key='Export'
        data-testid='Export-icon'
        onClick={onExportClick}
        disabled={exportButtonDisabled}
        icon={<CloudDownloadAlt className={iconClass} />}
        className={iconButtonClass}
        tabIndex={exportButtonTabIndex}
        innerRef={exportButtonRef}
      >
        {labels.COMMON_BUTTON_FUNCTION_EXPORT}
      </IconButton>
    );
  if (typeof onCloseClick === 'function')
    iconList.push(
      <IconButton
        key='Close'
        data-testid='close-icon'
        onClick={onCloseClick}
        disabled={closeButtonDisabled}
        icon={<Close className={iconClass} />}
        className={iconButtonClass}
        tabIndex={closeButtonTabIndex}
        innerRef={closeButtonRef}
      >
        {labels.COMMON_BUTTON_FUNCTION_CLOSE}
      </IconButton>
    );

  // divタグに不要なpropsを除去
  const divProps = { ...props };
  delete divProps['pageTitle'];
  delete divProps['excelButtonTabIndex'];
  delete divProps['excelButtonDisabled'];
  delete divProps['excelButtonRef'];
  delete divProps['onExcelClick'];
  delete divProps['createButtonTabIndex'];
  delete divProps['createButtonDisabled'];
  delete divProps['createButtonRef'];
  delete divProps['onCreateClick'];
  delete divProps['importButtonDisabled'];
  delete divProps['importButtonTabIndex'];
  delete divProps['importButtonRef'];
  delete divProps['onImportClick'];
  delete divProps['exportButtonDisabled'];
  delete divProps['exportButtonTabIndex'];
  delete divProps['exportButtonRef'];
  delete divProps['onExportClick'];
  delete divProps['closeButtonTabIndex'];
  delete divProps['closeButtonDisabled'];
  delete divProps['closeButtonRef'];
  delete divProps['onCloseClick'];

  return (
    <div {...divProps} className={classNames(className, 'd-flex page-header')}>
      <div className={classNames(centerClass, 'ml-2 my-0 page-header-title text-nowrap text-lbl h5')}>{pageTitle}</div>
      <div className={classNames(centerClass, 'ml-2 page-header-header flex-grow-1 justify-content-start')}>{children}</div>
      <div className={classNames(centerClass, 'ml-auto page-header-icon')}>{iconList}</div>
    </div>
  );
};
export default PageHeader;
