import { MjsEventContainer } from "../common/usefuls/event";
import { MjsEventArgs } from "../common/event/eventData";
import $ from 'jquery';

export class EventManager {

    // イベント管理
    private _eventHandlerMap!: { [eventName: string]: Array<MjsEventContainer<MjsEventArgs>>; };

    /**
     * イベントハンドラを一元管理配下へ追加する
     * @param eventName インスタンス内で一意となるイベント管理名
     * @param container イベントハンドラ格納クラスのインスタンス
     */
    public addEventHandler(eventName: string, container: MjsEventContainer<MjsEventArgs>): number {
        if (!this._eventHandlerMap) {
            this._eventHandlerMap = {};
        }
        if (!this._eventHandlerMap[eventName]) {
            this._eventHandlerMap[eventName] = new Array<MjsEventContainer<MjsEventArgs>>();
        }
        this._eventHandlerMap[eventName].push(container);
        return this._eventHandlerMap[eventName].length - 1;
    }

    /**
     * イベントを発行する
     * @param eventName addEventHandlerにて登録したイベント管理名
     * @param sender イベント発行元
     * @param e イベント引数
     */
    public fireEvent(eventName: string, sender: any, e: MjsEventArgs): void {
        if (this._eventHandlerMap && this._eventHandlerMap[eventName]) {
            $.each(this._eventHandlerMap[eventName].filter(e => e != null), (idx, ev) => {
                if(ev) ev.invoke(sender, e);
            });
        }
    }

    public removeAllHandler(eventName: string) {
        if (this._eventHandlerMap == null || this._eventHandlerMap[eventName] == null) {
            return;
        }
        for (let i = 0; i < this._eventHandlerMap[eventName].length; i++) {
            this._eventHandlerMap[eventName][i] = null!;
        }
    }

    public removeHandler(eventName: string, index: number) {
        if (this._eventHandlerMap == null || this._eventHandlerMap[eventName] == null) {
            return;
        } 
        this._eventHandlerMap[eventName][index] = null!;
    }

    public setHandler(eventName: string, index: number, container: MjsEventContainer<MjsEventArgs>) {
        if (this._eventHandlerMap == null) {
            return;
        }
        this._eventHandlerMap[eventName][index] = container;
    }

    public getHandlerCount(eventName: string): number {
        if (this._eventHandlerMap == null || this._eventHandlerMap[eventName] == null) {
            return 0;
        } else {
            return this._eventHandlerMap[eventName].length;
        }
    }
}
