import React from 'react';
import { Button, Input, Label } from 'reactstrap';
import ExecutingCancel from '../../../../components/molecules/CMN/ExecutingCancel/ExecutingCancel';
import DropGrid from '../../../../components/molecules/CMN/Grid/TantoByCompanyDropGrid';
import DragGrid from '../../../../components/molecules/CMN/Grid/TantoByCompanyDragGrid';
import * as labels from '../../../../constants/labels';
import { UserCompanySettingViewModel } from '../../../../models/userCompanySettingViewModel';
import { UserVO } from '../../../../models/userVO';
import { UserAvailableCompanyVO } from '../../../../models/userAvailableCompanyVO';
import { UserAffiliationVO } from '../../../../models/userAffiliationVO';
import { setAlertMessage, AlertKbnEnum } from '../../../../stores/AlertMessage';
import PageHeader from '../../../../components/molecules/CMN/PageHeader/PageHeader';
import { LowerActionLayout, LowerActionLayoutAction, LowerActionLayoutContents, LowerActionLayoutHeader } from '../../../../components/templates/LowerActionLayout';
import Axios from 'axios';
import { setIsLoading } from '../../../../stores/NowLoading';
import ExcelOutputsetting from '../../../organisms/A000/ExcelOutputsetting';
import * as constants from '../../../../constants/constant';
import ConfirmationPopup from '../../../../components/molecules/CMN/ConfirmationPopup/ConfirmationPopup';
import { bindValueToMessage } from '../../../../utils/messageUtils';
import * as message from '../../../../constants/message';

// 通信URL
const baseUrl = '/api/v1/UserAvailableCompany';
export const GetInitialUrl = [baseUrl, 'Initial'].join('/'); //初期表示
export const GetUserListUrl = [baseUrl, 'GetUserList'].join('/'); //担当者検索
export const GetCompanyListUrl = [baseUrl, 'GetCompanyList'].join('/'); //会社検索
export const UpdateUrl = [baseUrl, 'Update'].join('/'); //更新
export const ExcelCheckUrl = [baseUrl, 'ExcelCheck'].join('/'); //Excel出力件数チェック
// 起動判定URL
const JudgeURL = 'api/v1/CommonStartJudgement/Judge';
// システム履歴記入
const WriteSysHistoryURL = 'api/v1/CommonSystemHistory/Write';

// 確認ポップアップの実行区分
enum confirmKbn {
  // 取消
  cancel = 0,
  // 設定済のみ会社
  isSetting = 1,
  // 未設定のみ会社
  unSetting = 2,
  // 会社一覧チェックなし
  noSetting = 3
}

type DragDataVO = {
  ClientCode?: number;
  UserCode?: number;
  Code?: string; //LoginID
  Name?: string; //UserName
}

type TantoByCompanyprops = {
  onClose: () => void;
};

const TantoByCompany: React.FC<TantoByCompanyprops> = props => {
  const [confirmPopupKbn, setConfirmPopupKbn] = React.useState<confirmKbn>(0);
  const [confirmationActivated, setConfirmationActivated] = React.useState(false);
  const [confirmMessage, setConfirmMessage] = React.useState('');
  const [dragData, setDragData] = React.useState<DragDataVO[]>();
  //excel出力
  const [excelPopupActivated, setExcelPopupAcrivated] = React.useState(false);
  const [companyList, setCompanyList] = React.useState<UserAvailableCompanyVO[]>();
  const [userList, setUserList] = React.useState<UserVO[]>();
  const [inputData, setInputData] = React.useState('');
  // 変更Flg
  const [changedFlg, setChangedFlg] = React.useState(false);

  // 設定チェックボックスの初期値
  const initializedCheckData = {
    setCheck: '',
  };
  const [checkData, setCheckData] = React.useState(initializedCheckData);

  // Excel出力
  const handleOnExcelClick = () => {
    // 出力件数チェックの対応
    ExcelCheckOnPost(ExcelCheckUrl);
  };
  // Excel出力を閉じる
  const handleExcelOnClose = () => {
    setExcelPopupAcrivated(false);
  }
  
  // 閉じる
  const handleOnCloseClick = () => {
    props.onClose();
  };

  // 保存ボタン押下
  const handleOnSaveClick = () => {
    setChangedFlg(false);
    ComPanyUpdateOnPost(UpdateUrl);
  }

  // 会社一覧の削除ボタン押下
  const handleOnDeleteClick = (item: UserAffiliationVO) => {
    setChangedFlg(true);
    const clientCode = item.ClientCode;
    const userCode = item.UserCode;

    companyList!.forEach(x => {
      if (x.ClientCode == clientCode) {
        const index = x.UserAffiliation!.findIndex(y => y.UserCode === userCode);
        x.UserAffiliation!.splice(index, 1);
      }
    });

    //setViewModel({...viewModel, UserAvailableCompany: companyList});
  }

  // Drag開始
  const handleOnDragStart = (item: any) => {
    let ret = [];
    for (var i = 0; i < item.length; i++) {
      let _ret : DragDataVO = { ClientCode: 0,
                                UserCode: item[i].UserCode, 
                                Code: item[i].LoginID,
                                Name: item[i].UserName}
      ret.push(_ret);
    }
    setDragData(ret);
  }

  //DragGridのSelectMode保存
  const [selectMode, saveSelectMode] = React.useState(3);
  const handleOnSaveSeletedMode = (mode: number) => {
    saveSelectMode(mode);
  }

  // Dropした
  const handleOnDropEnd = (clientCode: number) => {
    for (var i = 0; i < dragData!.length; i++) {
      var addDragFlg = true;
      companyList!.forEach(x => {
        x.UserAffiliation!.forEach(y => {
          if (y.UserCode == dragData![i].UserCode && x.ClientCode == clientCode) {
            addDragFlg = false;
          }
        })
      })
      if( addDragFlg ) {
        dragData![i].ClientCode = clientCode;
        reloadCompanyList(dragData![i]);
      }
    }
  }

  // 会社一覧再描画
  const reloadCompanyList = (dragData: DragDataVO) => {
    setChangedFlg(true);
    const clientCode = dragData.ClientCode;
    companyList!.forEach(x => {
      if(x.ClientCode == clientCode) {
        x.UserAffiliation!.push(dragData);
      }
    })
    //setViewModel({...viewModel, UserAvailableCompany: companyList});
  }

  // 設定チェックボックスのチェック変更
  const handleSettingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = (e.target.value === checkData.setCheck) ? '' : e.target.value;
    const newData = { ...checkData, setCheck: newValue};
    setConfirmMessage(bindValueToMessage(message.TantoByCompany_Confirmation_ChangeCancel));
    switch (newValue) {
      case '':
        setConfirmPopupKbn(confirmKbn.noSetting);
        if (changedFlg){
          setConfirmationActivated(true);
        } else {
          CompanyListOnPost(GetCompanyListUrl, 0);
          setCheckData(newData);
        }
      break;
      case 'isSetting':
        setConfirmPopupKbn(confirmKbn.isSetting);
        if (changedFlg) {
          setConfirmationActivated(true);
        } else {
          CompanyListOnPost(GetCompanyListUrl, 1);
          setCheckData(newData);
        }
        break;
      case 'unSetting':
        setConfirmPopupKbn(confirmKbn.unSetting);
        if (changedFlg) {
          setConfirmationActivated(true);
        } else {
          CompanyListOnPost(GetCompanyListUrl, 2);
          setCheckData(newData);
        }
        break;
      default:
        break;
    }
  };
  
  // 取消ボタン押下
  const handleOnCancleClick = () => {
    if (changedFlg) {
      setConfirmMessage(bindValueToMessage(message.TantoByCompany_Confirmation_ChangeCancel));
      setConfirmPopupKbn(confirmKbn.cancel);
      setConfirmationActivated(true);
    }
  };

  // 確認ポップアップ
  const handleConfirmationPopupClose = () => {
    setConfirmationActivated(false);
  };
  const handleConfirmationPopupYesClick = () => {
    setChangedFlg(false);
    setConfirmationActivated(false);
    switch (confirmPopupKbn) {
      case confirmKbn.noSetting:
        CompanyListOnPost(GetCompanyListUrl, 0);
        setCheckData({ ...checkData, setCheck: ''});
        break;
      case confirmKbn.isSetting:
        CompanyListOnPost(GetCompanyListUrl, 1);
        setCheckData({ ...checkData, setCheck: 'isSetting'});
        break;
      case confirmKbn.unSetting:
        CompanyListOnPost(GetCompanyListUrl, 2);
        setCheckData({ ...checkData, setCheck: 'unSetting'});
        break;
      case confirmKbn.cancel:
        initialOnGet(GetInitialUrl);
        break;
      default:
        break;
    }
  };
  const handleConfirmationPopupNoClick = () => {
    setConfirmationActivated(false);
  };

  // 検索入力
  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputData(e.target.value);
  }
  // 検索ボタンを押下
  const handleSearchClick = () => {
    UserListOnPost(GetUserListUrl, inputData);
  }

  /* 通信処理 */
  // 初期起動 
  const initialOnGet = (url: string) => {
    setIsLoading(true);
    Axios.get<UserCompanySettingViewModel>(url)
      .then(response => {
        const newViewModel = response.data;
        setUserList(newViewModel.Users);
        setCompanyList(newViewModel.UserAvailableCompany);
        //setOldViewModel(newViewModel);
      })
      .catch(error => {
        if (typeof error.response.data !== 'undefined') {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // 担当者検索
  const UserListOnPost = (url:string, conditionWord: string) => {
    setIsLoading(true);
    Axios.post<UserCompanySettingViewModel>( url, { UserSearchWord: conditionWord } )
      .then(response => {
        const newViewModel = response.data.Users;
        if(newViewModel != undefined) {
          setUserList(newViewModel);
        }
      })
      .catch(error => {
        if (typeof error.response.data !== 'undefined') {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  // 会社検索
  const CompanyListOnPost = (url: string, condition: number) => {
    setIsLoading(true);
    Axios.post<UserCompanySettingViewModel>( url, { CompanySearchCondition: condition } )
      .then(response => {
        const newViewModel = response.data.UserAvailableCompany;
        if(newViewModel != undefined && newViewModel[0] != null) {
          setCompanyList(newViewModel);
        } else {
          setCompanyList([]);
        }
      })
      .catch(error => {
        if (typeof error.response.data !== 'undefined') {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  // 会社更新保存
  const ComPanyUpdateOnPost = (url: string) => {
    if (companyList != null && companyList != undefined) {
      setIsLoading(true);
      Axios.post(url, companyList)
        .then(response => {
          setAlertMessage({
            alerted: true,
            alertKbn: AlertKbnEnum.success,
            message: message.TantoByCompany_Success_Save
          });
        })
        .catch(error => {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
        })
        .finally (() => {
          setIsLoading(false);
        });
    }
  };

  // 出力件数チェック
  const ExcelCheckOnPost = (url: string) => {
    setIsLoading(true);
    Axios.post(url)
      .then(response => {
        setExcelPopupAcrivated(true);
      })
      .catch(error => {
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message })
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //初期起動
  React.useEffect(() => {
    // 処理判定
    async function JudgeOnPost(url: string, syoriId: string) {
      const status = await Axios.post<number>(url, {syoriId: syoriId});
      if (status.data == 0) {
        // 初期起動
        initialOnGet(GetInitialUrl);
        await Axios.post(WriteSysHistoryURL, {syoriId: syoriId, operation: '処理開始'});
      } else {
        if(status.data == -101) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotUse});
        } else if (status.data == -102) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractOver});
        } else if (status.data == -103) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_Notauthorized});
        } else if (status.data == -104) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotDataSelect});
        } else if (status.data == -105) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractEqual});
        }
        props.onClose();
      }
    }
    JudgeOnPost(JudgeURL, 'AIKCMN006000001');
  }, [GetInitialUrl]);

  const GRIDGUIDANCELbl: React.ReactElement[] = [];
  labels.AIKCMN006000001_INPUT_FOOTER_GRIDGUIDANCE.split('\n').forEach((lbl, i) => {
    GRIDGUIDANCELbl.push(
      <div className='text-red' key={i}>
        {lbl}
      </div>
    );
  });

  return (
    <div>
      <LowerActionLayout className='vw-100'>
        <LowerActionLayoutHeader>
          <PageHeader
            pageTitle={labels.AIKCMN006000001_FUNCTION_NAME}
            onExcelClick={handleOnExcelClick}
            excelButtonTabIndex={7}
            onCloseClick={handleOnCloseClick}
            closeButtonTabIndex={8}
            />
        </LowerActionLayoutHeader>
        <LowerActionLayoutContents className='vw-100 p-3'>
          <div className='header-msg'>
            <div><span className='text-danger'>{'会社'}</span>{'ごとに利用可能な'}<span className='text-danger'>{'担当者'}</span>{'を設定します。'}</div>
            <div>{'・'}<span className='text-danger'>{'会社'}</span>{'に'}<span className='text-danger'>{'担当者'}</span>{'を設定する場合'}</div>
            <div><span className='ml-1'></span><span className='text-danger'>{'担当者'}</span>{'をマウスで選択し、'}<span className='text-danger'>{'会社'}</span>{'へドラッグ＆ドロップします。'}</div>
            <div>{'・設定済みの'}<span className='text-danger'>{'担当者'}</span>{'を解除する場合'}</div>
            <div><span className='ml-1'></span>{'設定済みの'}<span className='text-danger'>{'担当者'}</span>{'の「解除」をクリックします。'}</div>
            <div>{'・'}<span className='text-danger'>{'担当者'}</span>{'を1つも設定していない'}<span className='text-danger'>{'会社'}</span>{'はすべての'}<span className='text-danger'>{'担当者'}</span>{'で利用可能となります。'}</div>
          </div>
          <div className='d-flex search'>
            <Input
              type='text'
              id='searchinput'
              className='searchinput'
              placeholder={'ログインIDまたは担当者名で検索できます'}
              onChange={handleSearchInputChange}
              tabIndex={1}
              autoFocus={true}
            />
            <Button
              color='primary'
              className='searchbutton'
              onClick={handleSearchClick}
              tabIndex={2}
            >
              {labels.AIKCMN006000001_BUTTON_FUNCTION_SEARCH}
            </Button>
          </div>
          <div className='grid-content d-flex vw-100'>
            <div className='relative'>
              <div className='d-flex'>
                <Label className='font-weight-bold'>{labels.AIKCMN006000001_INPUT_HEADER_OFFICESTAFFLIST}</Label>
              </div>
              <DragGrid
                gridClassName='tanto-company-grid tanto-grid'
                columns={tantoGridCol}
                sources={userList!}
                headersVisi={1}
                onDragStart={handleOnDragStart}
                saveSeletedMode={handleOnSaveSeletedMode}
                selectMode={selectMode}
              />
              <div hidden={userList != undefined && userList.length > 0} className='err-in-grid'>
                {labels.AIKCMN006000001_GRID_OFFICESTAFFNOTFOUND}
              </div>
            </div>
            <div id='arrow-right' className='arrow-right'></div>
            <div className='relative'>
              <div className='d-flex'>
                <Label className='font-weight-bold'>{labels.AIKCMN006000001_INPUT_HEADER_COMPANYLIST}</Label>
                <Label check>
                  <Input
                    type='checkbox'
                    id='unsetting'
                    value='unSetting'
                    checked={checkData.setCheck === 'unSetting'}
                    onChange={handleSettingChange}
                    tabIndex={3}
                  />
                  {'未設定のみ表示'}
                </Label>
                <Label check>
                  <Input
                    type='checkbox'
                    id='issetting'
                    value='isSetting'
                    checked={checkData.setCheck === 'isSetting'}
                    onChange={handleSettingChange}
                    tabIndex={4}
                  />
                  {'設定済のみ表示'}
                </Label>
              </div>
              <DropGrid
                gridClassName='tanto-company-grid company-grid'
                columns={kaishaGridCol}
                sources={companyList!}
                childItemsPath={'UserAffiliation'}
                headersVisi={1}
                onDeleteClick={handleOnDeleteClick}
                onDropEnd={handleOnDropEnd}
              />
              <div hidden={companyList != undefined && companyList.length > 0} className='err-in-grid'>
                {labels.AIKCMN006000001_GRID_COMPANYNOTFOUND}
              </div>
            </div>
          </div>
          {GRIDGUIDANCELbl}
        </LowerActionLayoutContents>
        <LowerActionLayoutAction className='px-3 pb-3'>
          <div className='d-flex justify-content-between'>
            <div className='ml-auto text-red'>{labels.AIKCMN006000001_INPUT_FOOTER_SAVEGUIDANCE}</div>
            <div className='ml-2'>
              <ExecutingCancel
                executeLabel={labels.COMMON_BUTTON_FUNCTION_SAVE}
                onExecuteClick={handleOnSaveClick}
                executeTabIndex={5}
                cancelLabel={labels.COMMON_BUTTON_FUNCTION_CANCEL}
                onCancelClick={handleOnCancleClick}
                cancelTabIndex={6}
              />
            </div>
          </div>
        </LowerActionLayoutAction>
      </LowerActionLayout>
      <ExcelOutputsetting
        activated={excelPopupActivated}
        templateId={constants.AIKCMN006000001E01}
        onClose={handleExcelOnClose}
      />
      <ConfirmationPopup
        isOpen={confirmationActivated}
        onClose={handleConfirmationPopupClose}
        onYesClick={handleConfirmationPopupYesClick}
        onNoClick={handleConfirmationPopupNoClick}
      >
        {confirmMessage}
      </ConfirmationPopup>
    </div>
  );
};

const tantoGridCol = [
  {
    header: labels.AIKCMN006000001_GRID_HEADER_LOGINID,
    binding: 'LoginID',
    isReadOnly: true,
    align: 'left',
    width: 120,
    format: 'd',
    allowMerging: true
  },
  {
    header: labels.AIKCMN006000001_GRID_HEADER_OFFICESTAFFNAME,
    binding: 'UserName',
    isReadOnly: true,
    align: 'left',
    width: 150,
    allowMerging: true
  },
  {
    header: labels.AIKCMN006000001_GRID_HEADER_OFFICESTAFFKANA,
    binding: 'UserNameKana',
    isReadOnly: true,
    align: 'left',
    width: 200,
    allowMerging: true
  }
];

const kaishaGridCol = [
  {
    header: labels.AIKCMN006000001_GRID_HEADER_COMPANYCODE,
    binding: 'Code',
    isReadOnly: true,
    align: 'left',
    width: 120,
    format: 'd',
    allowMerging: true
  },
  {
    header: labels.AIKCMN006000001_GRID_HEADER_COMPANYNAME,
    binding: 'Name',
    isReadOnly: true,
    align: 'left',
    width: 250,
    allowMerging: true
  },
  {
    header: ' ',
    binding: 'DeleteIcon',
    isReadOnly: true,
    align: 'left',
    width: 40,
  }
];

export default TantoByCompany;