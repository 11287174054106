/**
 * マスターデータ ViewModel クラス
 */
import { KeyValueData, RensouData } from "../../common/usefuls/utilities";
import { DataModelBase } from "../../core/models/dataModelBase";
export class SwkFxHeadCacheViewModel extends DataModelBase implements KeyValueData, RensouData {
    // interface:KeyValueDataの実装
    public get key(): string { return this.code; }
    public get value(): string { return this.LongName; }

    // interface:RensouDataの実装
    public get code(): string { return (this.GCode != undefined && this.GCode != null) ? this.GCode.toString() : null!; }
    public get masterKbn(): number { return 0; }
    public get displayValue(): string { return this.LongName; }
    public get subNameValue(): string { return this.LongName; }
    public get rensou(): string { return this.RenChar; }
    public get simpleFlag(): boolean { return true; }
    public get detailFlag(): boolean { return true; }
    private _displayFlag!: boolean;
    public set displayFlag(val: boolean) { this._displayFlag = val; }
    public get displayFlag(): boolean { return this._displayFlag; }

    /** 外部コード */
    public GCode!: number;

    /** 連想シンボル */
    public RenChar!: string;

    /** 正式名称 */
    public LongName!: string;

    /** 入力形式 */
    public SwkForm!: number;

}
