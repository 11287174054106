import React, { useImperativeHandle } from 'react';
import DialogInputText from './DialogInputText';
import * as labels from '../../../constants/labels';
import { compareObject } from "../../../utils/objectUtils";
/** プロパティ */
export type RangeSelectItemsProps = {
  // コントロール識別名
  name: string;
  // リスト
  items: Array<any>;
  // 選択通知関数
  onSelect: (selectItems?: Array<any>) => void;
  // コードバインド領域名
  bindingCode: string;
  // 名称バインド領域名
  bindingName: string;
  // 無効フラグ
  disabled?: boolean;
  // 選択アイテム
  selectItems?: Array<any>;
  // REF
  forwardRef?: React.RefObject<any>;
  // コード属性
  codeAttr?: number;
  // tabIndex
  tabIndex?: number;
};
/**
 * 範囲選択コンポーネント
 * @param props
 */
const RangeSelectItems: React.FC<RangeSelectItemsProps> = props => {
  const { items, onSelect, name, disabled, bindingCode, bindingName, selectItems, forwardRef, codeAttr } = props;
  // 範囲開始コントロールREF
  const startRef = React.useRef<any>();
  // 範囲終了コントロールREF
  const endRef = React.useRef<any>();
  const [startItem, setStartItem] = React.useState({});
  const [endItem, setEndItem] = React.useState({});
  // 定義
  const definition = {
    // 空白Object
    get blank() {
      var _blank = new Object();
      _blank[bindingCode] = '';
      _blank[bindingName] = '';
      return _blank;
    }
  }
  // フォーカス移動
  var setFocus = () => {
    startRef.current.setFocus();
  }
  // 呼び出し可能関数指定
  useImperativeHandle(forwardRef, () => ({
    setFocus
  }));
  // 範囲開始入力通知受け取り
  const noticeStartItem = (item: any, target?: HTMLInputElement) => {
    if (!endRef.current.getItem()[bindingCode] && !endRef.current.getItem()[bindingName]
      && (!endItem[bindingCode] && !endItem[bindingName])) {
      endRef.current.setItem(item);
      setEndItem(item);
    }
    var items = selectList(item, endRef.current.getItem());
    if (items !== undefined && items.length == 0
      && item[bindingCode] != '' && item[bindingName] != '') {
      endRef.current.setItem(definition.blank);
    }
    setStartItem(item);
  }
  // 範囲開始終了通知受け取り
  const noticeEndItem = (item: any, target?: HTMLInputElement) => {
    var items = selectList(startRef.current.getItem(), item);
    if (items !== undefined && items.length == 0
      && item[bindingCode] != '' && item[bindingName] != '') {
      startRef.current.setItem(definition.blank);
    }
    setEndItem(item);
  }
  // 選択範囲設定
  const selectList = (start: any, end: any) => {
    // 入力内容がすべて空白の場合、正常
    if (start[bindingCode] == '' && start[bindingName] == ''
      && end[bindingCode] == '' && end[bindingName] == '') {
      return undefined;
    }
    // 入力内容のいずれかが空白の場合、エラー
    if (start[bindingCode] == '' || start[bindingName] == ''
      || end[bindingCode] == '' || end[bindingName] == '') {
      return [];
    }
    // 検索一致フラグ
    var flg = false;
    // 選択アイテム
    var list: Array<any> = [];
    items.some(i => {
      if (list.length > 0 || i[bindingCode] == start[bindingCode]) {
        list.push(i); 
      }
      // 終了コードが開始コードよりも前に出現する場合
      // listにpushされずにループを抜ける
      return flg = i[bindingCode] == end[bindingCode];
    });
    if (list.length == 0) {
      setStartItem(startRef.current.getItem());
      setEndItem(endRef.current.getItem());
    }
    return flg ? list : [];
  }
  
  // 初期値変更時処理
  React.useEffect(() => {
    // 開始入力値
    var startTxt = definition.blank;
    // 終了入力値
    var endTxt = definition.blank;
    // 初期値が設定されている場合は、リストに設定値があるか確認する
    if (selectItems) {
      // 開始値設定
      startTxt = selectItems[0];
      // 終了値設定
      endTxt = selectItems[selectItems.length - 1];
      // 開始値正常フラグ
      var startMatch = false;
      // 終了値正常フラグ
      var endMatch = false;
      // 開始値位置
      var startIndex = -1;
      // 終了値位置
      var endIndex = -1;
      // 開始設定値リスト内検索
      items.some(item => {
        startIndex++;
        return startMatch = compareObject(item, startTxt);
      });
      // 開始設定値リスト内検索
      items.some(item => {
        endIndex++;
        return endMatch = compareObject(item, endTxt);
      });
      // 設定値が正常か判定
      if (endIndex - startIndex < 0 || !startMatch || !endMatch) {
        // 設定値がリスト内にない場合は空を設定
        startTxt = endTxt = definition.blank;
      }
      // コントロールに初期値を表示
      if (!compareObject(startRef.current.getItem(), startTxt)
        || !compareObject(endRef.current.getItem(), endTxt)
        && startItem[bindingCode] !== undefined && startItem[bindingName] !== undefined
        && endItem[bindingCode] !== undefined && endItem[bindingName] !== undefined
      ) {
        startRef.current.setItem(startTxt);
        endRef.current.setItem(endTxt);
        setStartItem(startTxt);
        setEndItem(endTxt);
      }
    }
  }, [selectItems]);
  React.useEffect(() => {
    onSelect(selectList(startItem, endItem));
  }, [startItem, endItem]);
  return (
    <div className='range-select-items'>
      <span>{labels.AIKCMN0010000013_LABEL_SCOPE}</span>
      <DialogInputText
        name={name + '_start'}
        itemList={items}
        transmitItem={noticeStartItem}
        forwardRef={startRef}
        codeViewContent={bindingCode}
        nameViewContent={bindingName}
        disabled={disabled}
        codeAttr={codeAttr}
        tabIndex={props.tabIndex}
      />
      <span>{labels.AIKCMN0010000013_LABEL_Hani}</span>
      <DialogInputText
        name={name + '_end'}
        itemList={items}
        transmitItem={noticeEndItem}
        forwardRef={endRef}
        codeViewContent={bindingCode}
        nameViewContent={bindingName}
        disabled={disabled}
        codeAttr={codeAttr}
        tabIndex={props.tabIndex}
      />
    </div>
  );
};
export default RangeSelectItems;
