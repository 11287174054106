import { finInfoUtil } from "../../common/Biz/finInfo";
import { MonthRangeData } from "../../common/data/monthRangeData";
import { InterimClosingType, MonthType } from "../../common/defines";
import { SelectMonthChangingEventArgs, SelectMonthChangedEventArgs } from "../../common/event/eventData";
import { Messages } from "../../common/message";
import { messageUtil } from "../../common/messageUtil";
import { MjsEventContainer } from "../../common/usefuls/event";
import { MonthManager } from "../../common/usefuls/monthManager";
import { DateRange } from "../../common/usefuls/utilities";
import { MonthInfoViewModel } from "../../models/common/monthInfoViewModel";
import { EventManager } from "../../support/eventManager";
import { UserControl } from "../interface";
import { AbstractUserControl } from "./abstractUserControl";
import $ from 'jquery';
import "../../common/usefuls/utilities";
import { MjsEventArgs } from "../../common/event/eventData";

/**
 * 月選択コントロールのパラメータ情報クラス
 */
export class MonthSelectorParam {
    public closingDate!: Date;
    public monthStartDay!: number;
    public startMonthIndex: number = 0;
    public enabledStartMonthIndex: number = 0;
    public committedMonthTypes!: Array<MonthType>;
    public useAfterClose: boolean = false;
    public interimClosingType: InterimClosingType = InterimClosingType.None;
    public hasClosingOption: boolean = false;
    public multiSelect: boolean = false;
    public monthInfoList!: Array<MonthInfoViewModel>;
}


/*
* 月度選択コントロール
*/
export class MonthSelector extends AbstractUserControl {
    private _$tag: JQuery;
    private _param: MonthSelectorParam;
    private mEventManager: EventManager;
    private mMonthManager: MonthManager;
    private mPrevSelectedRange!: MonthRangeData;
    private mInstanceRecontructCount = 0;
    private dispOuterMonthList!: Array<number>;

    /**
     * コンストラクタ
     * @param startMonthIndex 開始月インデックス（1月を0とする）
     * @param enabledStartMonthIndex 有効開始月インデックス（1月を0とする）
     * @param committedMonthTypes 確定済みの月種別
     * @param useAfterClose "締後"を使用するならtrue
     * @param interimClosingType 中間決算種別
     * @param multiSelect マウスドラッグによる複数選択を許可するならtrue
     */
    constructor(param: MonthSelectorParam,
        id: string = "monthSelector") {

        super(id);
        this._$tag = $("#" + this.baseTagID);
        this._param = param;
        if (this._param.committedMonthTypes == null) {
            this._param.committedMonthTypes = [];
        }
        this.mEventManager = new EventManager();
        this.mMonthManager = new MonthManager(this._param.closingDate, this._param.monthStartDay, this._param.interimClosingType, this._param.monthInfoList);
    }

    /**
     * コンストラクタで与えられたパラメータクラスを取得する
     */
    public getParam(): MonthSelectorParam {
        return this._param;
    }

    public changeSelectionMode(isMultiSelect: boolean) {
        this.mInstanceRecontructCount++;
        this._param.multiSelect = isMultiSelect;
        this._$tag.children().first().remove();
        this.initialize();
    }
    public initialize(): UserControl {
        // var thisRef = this;

        if (!this._$tag.hasClass("month-selector")) {
            this._$tag.addClass("month-selector");
        }

        var selectorTag: JQuery = this.createTag(this._param.startMonthIndex);
        this._$tag.append(selectorTag);

        $("ul", selectorTag).unbind("mousedown");
        $("ul", selectorTag).off("mousedown");

        if (this._param.multiSelect) {
            // マウスドラッグで選択できるようにjquery-uiのselectableを適用
            // "e.ctrlKey = false" でctrlキーを押しながらの複数選択操作を抑止
            $("ul", selectorTag).bind("mousedown", e => e.ctrlKey = false).selectable({
                filter: ".ui-selectee",
                cancel: ".month-selector-disabled",
                selecting: (event, ui) => {
                    if ($(ui.selecting!).hasClass("month-selector-normal")) {
                        $(ui.selecting!).removeClass("month-selector-normal");
                    }
                    if ($(ui.selecting!).hasClass("month-selector-committed")) {
                        $(ui.selecting!).removeClass("month-selector-committed");
                    }
                },
                unselecting: (event, ui) => {
                    if ($(ui.unselecting).attr("month-committed") == "true") {
                        $(ui.unselecting).addClass("month-selector-committed");
                    } else {
                        $(ui.unselecting).addClass("month-selector-normal");
                    }
                },
                start: (event, ui) => {
                    this.mPrevSelectedRange = this.getSelectedRange();
                },
                stop: (event, ui) => {
                    this.multiSelectStopped();
                }
            });
        }
        else {
            $("ul", selectorTag).on("mousedown", e => {
                if (e.button == 0) { // 左クリックのみ有効
                    if ($(e.target).hasClass("month-selector-disabled")) {
                        return;
                    }
                    this.monthClicked(e.target);
                }
            });
        }
        return this;
    }

    private multiSelectStopped(): void {
        // 単月選択モードのときは処理しない
        if (!this._param.multiSelect) return;

        $(".ui-selected", this._$tag).filter((idx, e) => $(e).hasClass("month-selector-committed")).toArray().forEach(e => $(e).removeClass("month-selector-committed"));

        // 月選択変更イベント発行
        var selectedTypes = this.getSelectedTypes();
        if (selectedTypes.length == 0) {
            return;
        }

        var optionValues = this.getClosingOptionValues();
        var changingArgs = new SelectMonthChangingEventArgs(new MonthRangeData(selectedTypes, this.getSelectedInnerMonths(), optionValues[0], optionValues[1], optionValues[2], optionValues[3]));
        this.onSelectMonthChanging(this, changingArgs);
        if (changingArgs.cancel) {
            this.setSelectedInnerMonths(this.mPrevSelectedRange.innerMonths);
            return;
        }

        var changedArgs = new SelectMonthChangedEventArgs(new MonthRangeData(selectedTypes, this.getSelectedInnerMonths(), optionValues[0], optionValues[1], optionValues[2], optionValues[3]));
        this.onSelectMonthChanged(this, changedArgs);
    }

    private createTag(startMonthIndex: number): JQuery {
        var tableElement: JQuery = $("<ul class='ui-selectable'>");
        // var monthElements: Array<JQuery> = new Array<JQuery>();
        var monthElement: JQuery = null!;
        var monthIndex: number = startMonthIndex;
        var enabled: boolean = this._param.enabledStartMonthIndex == this._param.startMonthIndex;
        var committed: boolean = false;
        var roundMonth: boolean = this._param.startMonthIndex <= this._param.enabledStartMonthIndex;
        for (var i = 0; i < 20; i++) {
            switch (i) {
                // １～１２月
                case 0:
                case 1:
                case 2:
                case 5:
                case 6:
                case 7:
                case 10:
                case 11:
                case 12:
                case 15:
                case 16:
                case 17:
                    monthElement = $("<li>").attr("value", monthIndex).attr("month-type", "month").html((monthIndex + 1).toString());

                    if (!enabled && roundMonth && this._param.enabledStartMonthIndex <= monthIndex) {
                        enabled = true;
                    }

                    committed = this._param.committedMonthTypes.any((e: number) => e == monthIndex);

                    if (monthIndex < 11) {
                        monthIndex++;
                    } else {
                        monthIndex = 0;
                        roundMonth = true;
                    }
                    break;

                // 締後×４
                case 3:
                    if (this._param.useAfterClose) {
                        if (this._param.interimClosingType == InterimClosingType.Quarter) {
                            monthElement = $("<li>").attr("value", MonthType.AfterCloseQuarter1).attr("month-type", "afterCloseQuarter").addClass("extra point").html(messageUtil.getMsg(Messages.Common.Caption.Monthselector.AFTERCLOSE));
                            committed = this._param.committedMonthTypes.any((e: MonthType) => e == MonthType.AfterCloseQuarter1);
                        }
                    }
                    break;
                case 8:
                    if (this._param.useAfterClose) {
                        if (this._param.interimClosingType == InterimClosingType.Interim || this._param.interimClosingType == InterimClosingType.Quarter) {
                            monthElement = $("<li>").attr("value", MonthType.AfterCloseQuarter2).attr("month-type", "afterCloseQuarter").addClass("extra point").html(messageUtil.getMsg(Messages.Common.Caption.Monthselector.AFTERCLOSE));
                            committed = this._param.committedMonthTypes.any((e: MonthType) => e == MonthType.AfterCloseQuarter2);
                        }
                    }
                    break;
                case 13:
                    if (this._param.useAfterClose) {
                        if (this._param.interimClosingType == InterimClosingType.Quarter) {
                            monthElement = $("<li>").attr("value", MonthType.AfterCloseQuarter3).attr("month-type", "afterCloseQuarter").addClass("extra point").html(messageUtil.getMsg(Messages.Common.Caption.Monthselector.AFTERCLOSE));
                            committed = this._param.committedMonthTypes.any((e: MonthType) => e == MonthType.AfterCloseQuarter3);
                        }
                    }
                    break;
                case 18:
                    if (this._param.useAfterClose) {
                        if (this._param.interimClosingType != InterimClosingType.None) {
                            monthElement = $("<li>").attr("value", MonthType.AfterCloseQuarter4).attr("month-type", "afterCloseQuarter").addClass("extra point").html(messageUtil.getMsg(Messages.Common.Caption.Monthselector.AFTERCLOSE));
                            committed = this._param.committedMonthTypes.any((e: MonthType) => e == MonthType.AfterCloseQuarter4);
                        }
                    }
                    break;

                // 第一 ～ 第三
                case 4:
                    if (this._param.interimClosingType == InterimClosingType.Quarter) {
                        monthElement = $("<li>").attr("id", "ms-quarter1" + this.mInstanceRecontructCount).attr("value", MonthType.Quarter1).attr("month-type", "quarter").attr("current-option-type", 1).addClass("extra point closing").html(messageUtil.getMsg(Messages.Common.Caption.Monthselector.QUARTER1));
                        committed = this._param.committedMonthTypes.any((e: MonthType) => e == MonthType.Quarter1);
                        if (this._param.hasClosingOption && enabled) {
                            // オプション選択コンテキストを使用可能に
                            this.registerOptionContext(monthElement, messageUtil.getMsg(Messages.Common.Caption.Monthselector.QUARTER1), "#ms-quarter1" + this.mInstanceRecontructCount, 1);
                        }
                    }
                    break;
                case 9:
                    if (this._param.interimClosingType == InterimClosingType.Interim || this._param.interimClosingType == InterimClosingType.Quarter) {
                        monthElement = $("<li>").attr("id", "ms-quarter2" + this.mInstanceRecontructCount).attr("value", MonthType.Quarter2).attr("month-type", "quarter").attr("current-option-type", 1).addClass("extra point closing").html(messageUtil.getMsg(Messages.Common.Caption.Monthselector.QUARTER2));
                        committed = this._param.committedMonthTypes.any((e: MonthType) => e == MonthType.Quarter2);
                        if (this._param.hasClosingOption && enabled) {
                            // オプション選択コンテキストを使用可能に
                            this.registerOptionContext(monthElement, messageUtil.getMsg(Messages.Common.Caption.Monthselector.QUARTER2), "#ms-quarter2" + this.mInstanceRecontructCount, 1);
                        }
                    }
                    break;
                case 14:
                    if (this._param.interimClosingType == InterimClosingType.Quarter) {
                        monthElement = $("<li>").attr("id", "ms-quarter3" + this.mInstanceRecontructCount).attr("value", MonthType.Quarter3).attr("month-type", "quarter").attr("current-option-type", 1).addClass("extra point closing").html(messageUtil.getMsg(Messages.Common.Caption.Monthselector.QUARTER3));
                        committed = this._param.committedMonthTypes.any((e: MonthType) => e == MonthType.Quarter3);
                        if (this._param.hasClosingOption && enabled) {
                            // オプション選択コンテキストを使用可能に
                            this.registerOptionContext(monthElement, messageUtil.getMsg(Messages.Common.Caption.Monthselector.QUARTER3), "#ms-quarter3" + this.mInstanceRecontructCount, 1);
                        }
                    }
                    break;

                // 決算
                case 19:
                    if (this._param.interimClosingType != InterimClosingType.None) {
                        monthElement = $("<li>").attr("id", "ms-closing" + this.mInstanceRecontructCount).attr("value", MonthType.Closing).attr("month-type", "closing").attr("current-option-type", 1).addClass("extra point closing").html(messageUtil.getMsg(Messages.Common.Caption.Monthselector.CLOSING));
                        committed = this._param.committedMonthTypes.any((e: MonthType) => e == MonthType.Closing);
                        if (this._param.hasClosingOption && enabled) {
                            // オプション選択コンテキストを使用可能に
                            this.registerOptionContext(monthElement, messageUtil.getMsg(Messages.Common.Caption.Monthselector.CLOSING), "#ms-closing" + this.mInstanceRecontructCount, 1);
                        }
                    }
                    break;
            }

            if (monthElement) {
                if (enabled) {
                    monthElement.addClass("ui-selectee");

                    if (committed) {
                        monthElement.addClass("month-selector-committed").attr("month-committed", "true");
                    } else {
                        monthElement.addClass("month-selector-normal").attr("month-committed", "false");
                    }
                } else {
                    monthElement.addClass("month-selector-disabled");
                }

                tableElement.append(monthElement);
            }
        }

        var itemStyle = "item-count-" + tableElement.children().length;

        // 表示している月のMonthTypeListを作成する
        this.dispOuterMonthList = this.createDispOuterMonths(tableElement);

        // 基底の要素(div)にタイトル要素(div)と月要素(table)を設定し、これを月選択コントロール要素とする
        var baseElement: JQuery = $("<div>").addClass("monthSelector");
        var titleElement = $("<span>").html(messageUtil.getMsg(Messages.Common.Caption.Monthselector.HEAD));
        baseElement.append(titleElement);
        baseElement.append(tableElement);
        baseElement.addClass(itemStyle);
        return baseElement;
    }

    /**
     *  createTag()で作成されたtableElementを元に、表示している月の外部月リストを作成する
     */
    private createDispOuterMonths(tableElement: JQuery): Array<number> {

        let dispOuterMonthList: Array<number> = new Array<number>();
        //var enabled: boolean = this._param.enabledStartMonthIndex == this._param.startMonthIndex;

        $.each(tableElement.children().toArray(), (index: number, value: Element) => {
            // Disableになっている時は選択できない月なので、ここでははじく
            if ($(value).hasClass("month-selector-disabled")) {
                return;
            }

            let monthIndex: number = parseInt(value.attributes["value"].value);
            let outerMonth: number = this.convertMonthTypeToOuterMonth(monthIndex, null!);

            // 決算日の場合は、フラグを見てオプション決算を追加
            if (this.mMonthManager.isClosingOuterMonth(outerMonth)) {

                // 決算月を入力
                dispOuterMonthList.push(outerMonth);

                // 決算オプション月を入力
                //if (this._param.hasClosingOption && enabled) {
                if (this._param.hasClosingOption) {
                    dispOuterMonthList.push(outerMonth + 1);
                    dispOuterMonthList.push(outerMonth + 2);
                }
                return;
            }

            dispOuterMonthList.push(outerMonth);
            return;
        });

        return dispOuterMonthList;
    }

    // private cMenu: any;
    //private contextTargetElem;
    /**
     * 右クリックで使用するオプション用のコンテキストを登録する
     * @param element 登録する要素
     * @param label
     * @param selector
     * @param defaultIndex
     */
    private registerOptionContext(element: JQuery, label: string, selector: string, defaultIndex: number) {
        element.addClass("has-option");

        // デフォルトで有効にするオプション値はコンテキストに表示しない
        $(element).attr("option1-visible", "true");
        $(element).attr("option2-visible", "true");
        $(element).attr("option3-visible", "true");
        $(element).attr("option" + defaultIndex + "-visible", "false");
        element.attr("current-option-type", defaultIndex);

        // let eid = $(element).attr("id");
        let isMouseClicked: boolean = false;
        $.contextMenu({
            selector: selector,
            callback: (key, options) => {
                let contextMenu = options.$menu.data().contextMenu;
                let optionType = contextMenu.items[key].optionType;

                let elemId = options.items[key].elemId;
                let elem = $("#" + elemId, this._$tag);

                // コンテキストメニューがクリックされて選択された場合
                if (isMouseClicked) {
                    // 項目選択処理の前に、現在の選択情報を復元用に保持する
                    this.mPrevSelectedRange = this.getSelectedRange();
                }

                // 選択前のOptionTypeを取得
                // let oldOptionType = parseInt($(elem).attr("current-option-type"));

                $(elem).attr("current-option-type", optionType);
                $(elem).html(contextMenu.items[key].name);

                // 選択された項目は非表示
                $(elem).attr("option1-visible", "true");
                $(elem).attr("option2-visible", "true");
                $(elem).attr("option3-visible", "true");
                $(elem).attr("option" + optionType + "-visible", "false");

                // 旧OptionType(決算～決算3)と、次のOptionTypeが異なるときのみ選択処理を変える。
                //if (optionType != oldOptionType) {
                //    // 特殊月を選択状態にするのに、どうしても本イベントが走ってしまうので、そのまま使用すると永久ループしてしまう
                //    // そのため、違う月から選択された時のみ、有効とする。
                //    this.setMonthSelectedSingle($(elem));

                //}

                // コンテキストメニューがクリックされて選択された場合の処理
                //Logger.debug("closing option contextmenu callback : " + optionType);
                if (isMouseClicked) {
                    isMouseClicked = false;
                    this.setMonthSelectedSingle($(elem), false);
                }

            },
            items: {
                "option1": {
                    name: label, optionType: 1, visible: (key: any, opt: any) => $(element).attr("option1-visible") == "true", className: MonthType[parseInt($(element).attr("value"))] + " option1 month-selector-context-menu-item", elemId: $(element).attr("id")
                },
                "option2": {
                    name: label + "2", optionType: 2, visible: (key: any, opt: any) => $(element).attr("option2-visible") == "true", className: MonthType[parseInt($(element).attr("value"))] + " option2 month-selector-context-menu-item", elemId: $(element).attr("id")
                },
                "option3": {
                    name: label + "3", optionType: 3, visible: (key: any, opt: any) => $(element).attr("option3-visible") == "true", className: MonthType[parseInt($(element).attr("value"))] + " option3 month-selector-context-menu-item", elemId: $(element).attr("id")
                }
            },
            position: (opt: any, x, y) => {
                // 表示位置調整
                let e = $(document.elementFromPoint(x, y)!);
                var offset = { top: e.offset()!.top + e.height() + 4, left: e.position().left };
                opt.$menu.css(offset);

            },
            className: "month-selector-context-menu-root",
            zIndex: 101
        });
        // 左クリック時イベント
        // フラグをオンにする
        //$(".month-selector-context-menu-item." + MonthType[parseInt($(element).attr("value"))]).on("mousedown", e => {
        //    if (e.button == 0) { // 左クリックのみ有効
        //        //Logger.debug("closing option contextmenu mouse down : " + MonthType[parseInt($(element).attr("value"))]);
        //        //isMouseClicked = true;
        //    }
        //});
        $(".month-selector-context-menu-item." + MonthType[parseInt($(element).attr("value"))]).on("mouseup", e => {
            if (e.button == 0) { // 左クリックのみ有効
                //Logger.debug("closing option contextmenu mouse up : " + MonthType[parseInt($(element).attr("value"))]);
                isMouseClicked = true;
            }
        });
    }

    private monthClicked(target: Element | JQuery<HTMLElement>) {
        // 複数選択モードのときは処理しない
        if (this._param.multiSelect) return;

        // ターゲットにたいして、選択処理を実行する
        this.setMonthSelectedSingle(target as JQuery<HTMLElement>, true);

    }

    /**
     * ターゲット(クリックされた月のJQueryオブジェクト)
     * @param target
     * @param doSavePrevSelRange
     */
    private setMonthSelectedSingle(target: JQuery, doSavePrevSelRange: boolean) {
        if (doSavePrevSelRange) {
            this.mPrevSelectedRange = this.getSelectedRange();
        }

        // 全解除しておいてから選択制御
        $.each($(".ui-selectee", this._$tag), (idx, ele) => {
            $(ele).removeClass("ui-selected");
            if ($(ele).attr("month-committed") == "true") {
                $(ele).addClass("month-selector-committed");
            } else {
                $(ele).addClass("month-selector-normal");
            }
        });

        $(target).addClass("ui-selected");

        if ($(target).hasClass("month-selector-normal")) {
            $(target).removeClass("month-selector-normal");
        }
        if ($(target).hasClass("month-selector-committed")) {
            $(target).removeClass("month-selector-committed");
        }

        // 月選択変更イベント発行
        var selectedTypes = this.getSelectedTypes();
        var optionValues = this.getClosingOptionValues();
        var changingArgs = new SelectMonthChangingEventArgs(new MonthRangeData(selectedTypes, this.getSelectedInnerMonths(), optionValues[0], optionValues[1], optionValues[2], optionValues[3]));
        this.onSelectMonthChanging(this, changingArgs);
        if (changingArgs.cancel) {
            //this.setSelectedInnerMonths(this.mPrevSelectedRange.innerMonths);
            this.undoSelectedRange(); // mod by m.nakamura
            return;
        }
        var args = new SelectMonthChangedEventArgs(new MonthRangeData(selectedTypes, this.getSelectedInnerMonths(), optionValues[0], optionValues[1], optionValues[2], optionValues[3]));
        this.onSelectMonthChanged(this, args);
    }

    /**
     * 各種決算の1～3表示を切り替える
     * @param monthType: 切り替え対象の決算（Quarter1/2/3、およびClosingのいずれか。それ以外が指定された場合は何も処理しない）
     * @param optionNumber: 1～3のいずれか
     */
    public setClosingOption(monthType: MonthType, optionNumber: number) {
        $(".context-menu-item." + MonthType[monthType] + ".option" + optionNumber, $(document.documentElement)).trigger("mouseup.contextMenu", this);
    }

    /**
     * 選択中の月で、最も若いIndexの月を対象にDate型を作成して返す。
     * 複数の時は上記の通り、一番若いIndexのDate型を返す。
     * 選択されていない時は、nullを返します。
     */
    public getSelctedMonthDate(): Date {
        let innerMonths: number[] = this.getSelectedInnerMonths();
        let innerMonth: number = innerMonths.length > 0 ? innerMonths[0] : -1;

        if (innerMonth == -1) {
            return null!;
        }

        // InnerMonthから取得出来ればそのまま返す。そうでない場合はできる限りの情報で作成して返す。
        let range: DateRange = this.mMonthManager.getDateRangeFromInner(innerMonth);
        if (range) {
            return range.from;
        } else {
            let day: number = finInfoUtil.getMStDay();
            let month: number = this.mMonthManager.toOuterMonth(innerMonth);
            let year: number = this.mMonthManager.getClientYear(month, day)!;

            let retDate: Date = this.mMonthManager.getStandardDateFromInner(
                year,
                innerMonth,
                1);
            return retDate;
        }
    }

    /**
     * from～toに指定された月範囲を選択状態にする。範囲内に"締後"や"四半期"が存在する場合、それらも含めて選択状態にする。ただし"決算"については別途パラメータで指定された内容に基づく。
     * ・月インデックスはjavascriptの慣習に従い 『ゼロ開始』とする（1月なら0を指定すること）
     * ・単月選択の場合はfrom,toに同値を指定すること
     * ・単月選択モードのときは、closingがtrueであれば決算選択、そうでなければfromの月を選択状態とする
     * @param fromIndex 選択開始月のインデックス
     * @param toIndex 選択終了月のインデックス
     * @param closing 決算を選択状態とするならtrue
     */
    public setSelectedMonthRange(fromIndex: number, toIndex: number, closing: boolean) {
        // まず全解除
        $.each($(".ui-selectee", this._$tag), (idx, ele) => {
            $(ele).removeClass("ui-selected");
            if ($(ele).attr("month-committed") == "true") {
                $(ele).addClass("month-selector-committed");
            } else {
                $(ele).addClass("month-selector-normal");
            }
        });

        // 決算の選択
        if (closing) {
            $("[value='" + MonthType.Closing + "']", this._$tag).addClass("ui-selected");
            if (!this._param.multiSelect) {
                return;
            }
        }

        // 月範囲の選択
        if (!this._param.multiSelect || fromIndex == toIndex) {
            // 単月選択モードもしくはFromToが同値のときはFrom値を採用して選択
            $("[value='" + fromIndex + "']", this._$tag).addClass("ui-selected");
            $("[value='" + fromIndex + "']", this._$tag).removeClass("month-selector-normal");
            $("[value='" + fromIndex + "']", this._$tag).removeClass("month-selector-committed");
        } else {
            // 単月でない場合はループ処理で範囲選択
            var selecting: boolean = false;
            $.each($(".ui-selectee", this._$tag), (idx, ele) => {
                var value = parseInt($(ele).attr("value"));
                if (value == fromIndex) {
                    selecting = true;
                } else if (value == toIndex) {
                    $(ele).addClass("ui-selected");
                    $(ele).removeClass("month-selector-normal");
                    $(ele).removeClass("month-selector-committed");
                    return; // break
                }

                if (selecting) {
                    $(ele).addClass("ui-selected");
                    $(ele).removeClass("month-selector-normal");
                    $(ele).removeClass("month-selector-committed");
                }
            });
        }
    }

    public setSelectedInnerMonthRange(fromInnerMonth: number, toInnerMonth: number) {
        let months: Array<number> = this.getInnerMonthListFromToValue(fromInnerMonth, toInnerMonth);
        this.setSelectedInnerMonths(months);
    }

    /**
     * ３、４桁の日付数値から選択状態にする（外部月もOK）
     * @param fromDMNumber
     * @param toDMNumber
     */
    public setSelectedDMNumberMonthRange(fromDMNumber: number, toDMNumber: number) {
        let from = parseInt(fromDMNumber.padLeft(4, "0").substr(0, 2));
        let to = parseInt(toDMNumber.padLeft(4, "0").substr(0, 2));
        this.setSelectedInnerMonthRange(
            this.mMonthManager.toInnerMonth(from)
            , this.mMonthManager.toInnerMonth(to)
        );
    }

    /**
     * Fromの値と、Toの値から、InnerMonthのArrayを取得する。
     * @param fromInnerMonth
     * @param toInnerMonth
     */
    public getInnerMonthListFromToValue(fromInnerMonth: number, toInnerMonth: number): Array<number> {
        var validValues = [1, 2, 3, 4, 5, 6, 7, 11, 12, 13, 14, 15, 16, 17, 21, 22, 23, 24, 25, 26, 27, 31, 32, 33, 34, 35, 36, 37];
        var months: Array<number> = [];
        for (let i = fromInnerMonth; i <= toInnerMonth; i++) {
            if (validValues.any((m: number) => i == m)) {
                months.push(i);
            }
        }
        return months;
    }

    public setSelectedType(...monthTypes: Array<MonthType>) {
        $(".ui-selectee", this._$tag).toArray().forEach((elem, idx, array) => {
            if (monthTypes.any((e: MonthType) => e == <MonthType>parseInt($(elem).attr("value")))) {
                $(elem).addClass("ui-selected");
                $(elem).removeClass("month-selector-normal");
                $(elem).removeClass("month-selector-committed");
            } else {
                $(elem).removeClass("ui-selected");
                if ($(elem).attr("month-committed") == "true") {
                    $(elem).addClass("month-selector-committed");
                } else {
                    $(elem).addClass("month-selector-normal");
                }
            }
        });
    }

    public setSelectedInnerMonth(innerMonth: number) {
        this.setSelectedInnerMonths([innerMonth]);
    }

    public setSelectedInnerMonths(innerMonths: Array<number>) {
        var outerMonths: Array<number> = innerMonths.select<number>((m: number) => this.mMonthManager.toOuterMonth(m));
        this.setSelectedOuterMonths(outerMonths);
    }

    public setSelectedOuterMonth(outerMonth: number) {
        this.setSelectedOuterMonths([outerMonth]);
    }

    public setSelectedOuterMonths(outerMonths: Array<number>) {
        // var elm = this._$tag.find('[value = 16]').attr('current-option-type');

        let monthLength: number = outerMonths ? outerMonths.length : 0;
        let selectedFirstOuterMonth: number = monthLength > 0 ? outerMonths[0] : 0;
        let selectedLastOuterMonth: number = monthLength > 0 ? outerMonths[monthLength - 1] : 0;

        var monthTypes: Array<MonthType> =
            outerMonths.select((e: number) => {

                switch (e) {
                    case 20:
                        return MonthType.AfterCloseQuarter1;
                    case 80:
                        return MonthType.AfterCloseQuarter2;
                    case 30:
                        return MonthType.AfterCloseQuarter3;
                    case 90:
                        return MonthType.AfterCloseQuarter4;
                    case 21:
                        return this.setSelectedMonthsForClosing(selectedFirstOuterMonth, selectedLastOuterMonth, e, MonthType.Quarter1, 1);
                    case 22:
                        return this.setSelectedMonthsForClosing(selectedFirstOuterMonth, selectedLastOuterMonth, e, MonthType.Quarter1, 2);
                    case 23:
                        return this.setSelectedMonthsForClosing(selectedFirstOuterMonth, selectedLastOuterMonth, e, MonthType.Quarter1, 3);
                    case 81:
                        return this.setSelectedMonthsForClosing(selectedFirstOuterMonth, selectedLastOuterMonth, e, MonthType.Quarter2, 1);
                    case 82:
                        return this.setSelectedMonthsForClosing(selectedFirstOuterMonth, selectedLastOuterMonth, e, MonthType.Quarter2, 2);
                    case 83:
                        return this.setSelectedMonthsForClosing(selectedFirstOuterMonth, selectedLastOuterMonth, e, MonthType.Quarter2, 3);
                    case 31:
                        return this.setSelectedMonthsForClosing(selectedFirstOuterMonth, selectedLastOuterMonth, e, MonthType.Quarter3, 1);
                    case 32:
                        return this.setSelectedMonthsForClosing(selectedFirstOuterMonth, selectedLastOuterMonth, e, MonthType.Quarter3, 2);
                    case 33:
                        return this.setSelectedMonthsForClosing(selectedFirstOuterMonth, selectedLastOuterMonth, e, MonthType.Quarter3, 3);
                    case 91:
                        return this.setSelectedMonthsForClosing(selectedFirstOuterMonth, selectedLastOuterMonth, e, MonthType.Closing, 1);
                    case 92:
                        return this.setSelectedMonthsForClosing(selectedFirstOuterMonth, selectedLastOuterMonth, e, MonthType.Closing, 2);
                    case 93:
                        return this.setSelectedMonthsForClosing(selectedFirstOuterMonth, selectedLastOuterMonth, e, MonthType.Closing, 3);
                    default:
                        return <MonthType>(e - 1);
                }
            });
        $(".ui-selectee", this._$tag).toArray().forEach((elem, idx, array) => {
            if (monthTypes.any((e: MonthType) => e == <MonthType>parseInt($(elem).attr("value")))) {
                $(elem).addClass("ui-selected");
                $(elem).removeClass("month-selector-normal");
                $(elem).removeClass("month-selector-committed");
            } else {
                $(elem).removeClass("ui-selected");
                if ($(elem).attr("month-committed") == "true") {
                    $(elem).addClass("month-selector-committed");
                } else {
                    $(elem).addClass("month-selector-normal");
                }
            }
        });
    }

    /**
     * 決算(第1～第3四半期/決算)用の、UIの選択状態を設定する処理
     * 決算系の科目を選択する際は本処理が仕様となる。
     * @param selectedFirstMonth
     * @param selectedLastMonth
     * @param targetMonth
     * @param targetMonthType
     * @param closingOption
     */
    private setSelectedMonthsForClosing(
        selectedFirstOuterMonth: number,
        selectedLastOuterMonth: number,
        targetMonth: number,
        targetMonthType: MonthType,
        closingOption: number
    ): MonthType {

        // 単数/複数選択時に選択月の先頭か、最後が決算月の場合、決算～決算3のいずれかに選択状態を指定する必要がある。
        let closingOptionValue = this.getClosingSet(selectedFirstOuterMonth, selectedLastOuterMonth, targetMonth, closingOption, targetMonthType);

        this.setClosingOption(targetMonthType, closingOptionValue);

        // monthタイプを返す。
        return targetMonthType;
    }

    /**
     *  選択先の「決算系」アイテムを[決算]とするか、[決算2/3]とするかを決定する。
     * targetMonthパラメータは得に決算月かどうかの判定は行っていないため、targetMonthは確実に決算月が指定されるように
     * 実装する必要があるため、注意。
     * @return true: [決算(1)/2/3]の分岐表示あり false: [決算(1)]の表示のみ
     */
    private getClosingSet(
        selectedFirstOuterMonth: number,
        selectedLastOuterMonth: number,
        targetMonth: number,
        closingOption: number,
        targetMonthType: MonthType
    ): number {

        // 決算1～2の表記が必要な場合は、
        // ①単数で決算2/3が選択された場合は表記する
        // ②複数選択で、決算(1～3)～その他の月のアイテムが選択された場合は表記する
        // ③複数選択で、その他の月のアイテム～決算(1～3)が選択された場合は表記する
        // ④選択された月のfrom～toが、同じ決算月内(81～83とか92～93とか)の場合だけ②/③のfrom～toルールとは異なり違うルールとなる。
        // それ以外は[決算(1)]を表記する

        // まずは①の判定
        // 単数指定の場合は、firstMonthとLastMonthが同じ値。その場合は指定されたclosingOptinを返す。
        if (selectedFirstOuterMonth === selectedLastOuterMonth) {

            return closingOption;

        }

        // 次に④の判定。判定方法として、firstMonthとlastMonthが違う事(①の判定で実行済み)、
        // firstMonthとlastMonthの月種別列挙体(MonthType)を比較して同じ場合として判定する。
        if (this.mMonthManager.toMonthTypeFromOuter(selectedFirstOuterMonth) ==
            this.mMonthManager.toMonthTypeFromOuter(selectedLastOuterMonth)) {

            // FirstMonthと一致する場合のみ、指定されたオプションを返し、それ以外は設定済みの値を返す。
            if (selectedFirstOuterMonth === targetMonth) {

                return closingOption;

            } else {

                return this.getCurrentOptionType(targetMonthType);

            }
        }

        // 【④/①でない場合の処理】
        // ②複数選択で、決算(1～3)～その他の月のアイテムが選択された場合は表記する
        // ③複数選択で、その他の月のアイテム～決算(1～3)が選択された場合は表記する
        // ※ ①については②/③の処理で賄える。
        if (selectedFirstOuterMonth === targetMonth ||       // 選択月のfromが決算1/2/3の場合 (②の場合)
            selectedLastOuterMonth === targetMonth) {          // 選択月のtoが決算1/2/3の場合 (③の場合)

            // この場合はTargetMonthに応じた決算1/2/3を表示する
            return closingOption;

        }

        // それ以外は現在表示されているものをそのまま表示する
        return this.getCurrentOptionType(targetMonthType);

    }

    /**
     * monthTypeから現在クリックされたコンテキストミューのオプションを返す
     * @param monthType
     */
    public getCurrentOptionType(monthType: number): number {
        var current_option: string = this._$tag.find("[value=" + monthType + "]").attr('current-option-type');
        return parseInt(current_option);
    };

    /**
     * 選択情報をMonthTypeの配列で取得する
     */
    public getSelectedTypes(): Array<MonthType> {
        var selecteds = $(".ui-selected", this._$tag).toArray().select<MonthType>((e: string) => <MonthType>parseInt($(e).attr("value")));
        return selecteds;
    }

    /**
     * 選択状態を内部月の配列で取得する
     */
    public getSelectedInnerMonths(): Array<number> {
        // var optionValues = this.getClosingOptionValues();

        let outerMonth: Array<number> = this.getSelectedOuterMonths();
        return outerMonth.select((r: number) => this.mMonthManager.toInnerMonth(r));
    }

    /**
     * MonthTypeから、外部月を取得する
     * @param monthType
     */
    private convertMonthTypeToOuterMonth(monthType: MonthType, optionValues: Array<number>): number {

        // 変換する
        let adjustValue: number;
        switch (monthType) {
            case MonthType.AfterCloseQuarter1:
                return 20;

            case MonthType.AfterCloseQuarter2:
                return 80;

            case MonthType.AfterCloseQuarter3:
                return 30;

            case MonthType.AfterCloseQuarter4:
                return 90;

            case MonthType.Quarter1:
                adjustValue = (optionValues && optionValues[0]) ? parseInt(optionValues[0].toString()) : 1;
                return (20 + adjustValue);

            case MonthType.Quarter2:
                adjustValue = (optionValues && optionValues[1]) ? parseInt(optionValues[1].toString()) : 1;
                return (80 + adjustValue);

            case MonthType.Quarter3:
                adjustValue = (optionValues && optionValues[2]) ? parseInt(optionValues[2].toString()) : 1;
                return (30 + adjustValue);

            case MonthType.Closing:
                adjustValue = (optionValues && optionValues[3]) ? parseInt(optionValues[3].toString()) : 1;
                return (90 + adjustValue);

        }

        return (monthType + 1);
    }

    /**
     * 選択情報を外部月の配列で取得する
     */
    public getSelectedOuterMonths(): Array<number> {
        let optionValues = this.getClosingOptionValues();

        // 選択状態を取得
        let monthTypeList = this.getSelectedTypes();

        // 選択状態がないときは空のリストを返す。
        if (monthTypeList.length === 0) {
            return new Array<number>();
        }

        // 最初と最後を取得する
        let firstOuterMonth: number = this.convertMonthTypeToOuterMonth(monthTypeList[0], optionValues);
        let lastOuterMonth: number = this.convertMonthTypeToOuterMonth(monthTypeList[monthTypeList.length - 1], optionValues);

        // 全表示OuterMonthから、それぞれのOuterMonthのIndexを取得する
        let firstIndex: number = this.dispOuterMonthList.indexOf(firstOuterMonth);
        let lasttIndex: number = this.dispOuterMonthList.indexOf(lastOuterMonth) + 1;

        // リストの中から取り出す。
        return this.dispOuterMonthList.slice(firstIndex, lasttIndex);
    }

    /**
     * 表示中のすべての月の外部月を配列で受け取る
     */
    public getAllOuterMonths(): Array<number> {
        return this.dispOuterMonthList;
    }

    private getClosingOptionValues(): Array<number> {
        var selectedInfos = $(".ui-selected", this._$tag).toArray().select<any>((e: string) => { return { value: <MonthType>parseInt($(e).attr("value")), optionValue: $(e).attr("current-option-type") } });
        var getOptionValue = (monthType: MonthType) => {
            var e = selectedInfos.filter((value, index, array) => value.value == monthType);
            return e.length > 0 ? e[0].optionValue : null;
        };

        var quarter1OptionValue = getOptionValue(MonthType.Quarter1);
        var quarter2OptionValue = getOptionValue(MonthType.Quarter2);
        var quarter3OptionValue = getOptionValue(MonthType.Quarter3);
        var closingOptionValue = getOptionValue(MonthType.Closing);

        return [quarter1OptionValue, quarter2OptionValue, quarter3OptionValue, closingOptionValue];
    }

    /**
     * 選択情報を mjs.acelink.vkz.common.data.MonthRangeData の型として取得する
     */
    public getSelectedRange(): MonthRangeData {
        var optionValues = this.getClosingOptionValues();
        return new MonthRangeData(this.getSelectedTypes(), this.getSelectedInnerMonths(), optionValues[0], optionValues[1], optionValues[2], optionValues[3]);
    }

    /**
     * 直前の選択情報で復元する
     * Add by m.nakamura
     */
    public undoSelectedRange(): void {
        this.setSelectedInnerMonths(this.mPrevSelectedRange.innerMonths);
    }


    // イベント定義
    public addSelectMonthChangingEventHandler(handler: MjsEventContainer<SelectMonthChangingEventArgs>): void {
        this.mEventManager.addEventHandler("SelectMonthChanging", handler as MjsEventContainer<MjsEventArgs>);
    }
    private onSelectMonthChanging(sender: any, e: SelectMonthChangingEventArgs) {
        this.mEventManager.fireEvent("SelectMonthChanging", sender, e);
    }

    public addSelectMonthChangedEventHandler(handler: MjsEventContainer<SelectMonthChangedEventArgs>): void {
        this.mEventManager.addEventHandler("SelectMonthChanged", handler as MjsEventContainer<MjsEventArgs>);
    }
    private onSelectMonthChanged(sender: any, e: SelectMonthChangedEventArgs) {
        this.mEventManager.fireEvent("SelectMonthChanged", sender, e);
    }
}

