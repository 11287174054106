
var logoutCallBack: () => void;

export const setLogoutCallBack = (x: () => void) => {
    logoutCallBack = x;
}

export const isLogoutCallBackDefined = () => {
    return logoutCallBack != null;
}

export const callLogoutCallBack = () => {
    logoutCallBack();
}
