/**
 * マスターデータ ViewModel クラス
 */
import { KeyValueData, RensouData } from "../../common/usefuls/utilities";
import { DataModelBase } from "../../core/models/dataModelBase";
export class TekiMACacheViewModel extends DataModelBase implements KeyValueData, RensouData {
    // interface:KeyValueDataの実装
    public get key(): string { return this.code; }
    public get value(): string { return this.TekiChar; }

    // interface:RensouDataの実装
    public get code(): string { return (this.Gcode != undefined && this.Gcode != null) ? this.Gcode.toString() : null!; }
    public get masterKbn(): number { return this.MasterKbn; }
    public get displayValue(): string { return this.TekiChar; }
    public get subNameValue(): string { return this.TekiChar; }
    public get rensou(): string { return this.RenChar; }
    public get simpleFlag(): boolean { return true; }
    public get detailFlag(): boolean { return true; }
    private _displayFlag!: boolean;
    public set displayFlag(val: boolean) { this._displayFlag = val; }
    public get displayFlag(): boolean { return this._displayFlag; }

    /** マスタ区分 */
    public MasterKbn!: number;

    /** 実在／合計区分 */
    public SumKbn!: number;

    /** レコード区分 */
    public RecordKbn!: number;

    /** 外部コード */
    public Gcode!: number;

    /** 連想シンボル */
    public RenChar!: string;

    /** 摘要文字列 */
    public TekiChar!: string;

    /** 付加摘要文字列 */
    public AddTekiChar!: string;

    /** 内部コード（ユニークＫｅｙ） */
    public NCode!: number;

    /** 入力部署コード */
    public SectionCode!: string;

    /** 残高管理区分 */
    public ZanKbn!: number;

    /** 取引先区分/イメージNO */
    public ExgKbn!: number;

    /** 取引先分類 */
    public BunCode!: number;

    /** 仕訳辞書摘要区分 */
    public SwDicKbn!: number;

    /** 内部コード２ */
    public NCode2!: number;

    /** 部署伝票辞書区分 */
    public BusDicKbn!: number;

}
