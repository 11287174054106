import { MjsDelegateContainer } from "../../common/usefuls/delegate";
import { Logger } from "../../common/usefuls/logger";
import $ from 'jquery';

/**
 * IndexedDBの管理クラス
 * ※このクラスはフレームワークが参照利用するのみとし、各機能の実装者は利用しないこと
 */
export class IndexedDBManager {

    private static DB_NAME: string = "Kichoukun_DB";
    private _targetStores: Array<string>;
    private _dbFactory: IDBFactory;
    private _db!: IDBDatabase;

    constructor(...targetStores: Array<string>) {
        this._targetStores = targetStores;
        this._dbFactory = IndexedDBManager.getIndexdDB();
    }

    // ------------- static methods ----------------- //
    public static isIDBSupport(): boolean {
        var indexedDB: IDBFactory = IndexedDBManager.getIndexdDB();
        return (indexedDB != null);
    }

    public static deleteDatabase(): void {
        IndexedDBManager.getIndexdDB().deleteDatabase(IndexedDBManager.DB_NAME);
    }

    private static getIndexdDB(): IDBFactory {
        return window.indexedDB;
    }
    // ---------------------------------------------- //

    public getRecord(storeName: string, key: any, getCompleteCallback: MjsDelegateContainer<DbRecord, void>): DbRecord {
        var tran = this._db.transaction(storeName, 'readonly');
        var store: IDBObjectStore = tran.objectStore(storeName);

        var result: DbRecord = undefined!;
        var request: IDBRequest = store.get(key);
        request.onsuccess = (event) => {
            result = new DbRecord(key, request.result);
            getCompleteCallback.invoke(result);
        };
        request.onerror = (event) => {
            Logger.debug('error occurred at IndexedDBManager#getRecord. [' + event + '] storeName:' + storeName + ' key:' + key);
            getCompleteCallback.invoke(null!);
        };

        return result;
    }

    public updateRecord(storeName: string, record: DbRecord): void {
        var tran = this._db.transaction(storeName, 'readwrite');
        var store: IDBObjectStore = tran.objectStore(storeName);

        var request = store.put(record.record, record.key);
        request.onerror = (event) => {
            Logger.debug('error occurred at IndexedDBManager#updateRecord. [' + event + '] storeName:' + storeName + ' record.key:' + record.key + ' record.record:' + record.record);
            throw Error;
        };
    }

    public openOrCreate(openCompleteCallback: MjsDelegateContainer<boolean, void>) {
        var openRequest: IDBOpenDBRequest = this._dbFactory.open(IndexedDBManager.DB_NAME, 1);

        // 接続成功（初回 or DBのバージョンが変わったとき）
        openRequest.onupgradeneeded = (event) => {
            this._db = (<IDBRequest>event.target).result;
            // 存在していないストアの作成
            this.createStoresIfNotExist();
        };

        // 接続成功（すでにDBが存在する場合）
        openRequest.onsuccess = (event) => {
            this._db = (<IDBRequest>event.target).result;
            openCompleteCallback.invoke(true);
        };

        openRequest.onerror = (event) => {
            Logger.debug('error occurred at IndexedDBManager#openOrCreate. [' + event + ']');
            openCompleteCallback.invoke(false);
        };
    }

    private createStoresIfNotExist() {
        $.each(this._targetStores, (idx, store) => {
            if (!this._db.objectStoreNames.contains(store)) {
                this._db.createObjectStore(store, { autoIncrement: true });
            }
        });
    }
}

export class DbRecord {
    constructor(key: string, record: any) {
        this.key = key;
        this.record = record;
    }
    public key: string;
    public record: any;
}
