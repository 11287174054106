/**
 * マスターデータ ViewModel クラス
 */
import { DataModelBase } from "../../core/models/dataModelBase";
export class UserInfoCacheViewModel extends DataModelBase {
    /**
     * 項目ＮＯ
     */
    public ItemNo!: number;

    /**
     * ユーザ区分
     */
    public UserKbn!: number;

    /**
     * 
     */
    public UserChar!: string;

    /**
     * 
     */
    public UserDate!: Date;

}
