import React, { RefObject, useEffect, useRef } from 'react';

import * as wjInput from 'wijmo/wijmo.react.input';
import * as wjGrid from 'wijmo/wijmo.react.grid';
import * as wjGridFilter from 'wijmo/wijmo.react.grid.filter';

type _ExplorerEasyInputAccountItemPartialProps = {
  scope: any, 
  explorerRef: RefObject<any>
};

const _ExplorerEasyInputAccountItemPartial: React.FC<_ExplorerEasyInputAccountItemPartialProps> = props => {

    const explorerGrid = useRef<any>();
    let componentDidMount = false;

    useEffect(() => {
        if(!componentDidMount && props.scope && props.scope.explorerGridInitialized && props.scope.explorerGridInitialized) {
            props.scope.explorerGridInitialized(explorerGrid.current.control);
            componentDidMount = true;
        }
    }, [props.scope] );

    return (
      <>
<wjInput.Popup
          showTrigger="None" 
          hideTrigger="Blur" 
          ref={props && props.explorerRef}
          showing={props.scope && props.scope.explorerShowing}
          shown={props.scope && props.scope.explorerShown}
          hiding={props.scope && props.scope.explorerHiding}
          hidden={props.scope && props.scope.explorerHidden}
          className="migration">

    <div style={{margin: "2px"}}>
        <wjGrid.FlexGrid id="explorerGrid" 
                      ref={explorerGrid}
                      allowResizing="Columns" 
                      allowSorting={true} 
                      cellEditEnded={props.scope && props.scope.explorerGridCellEditEnded}
                      headersVisibility="Column" 
                      itemsSource={props.scope && props.scope.explorerItemsSource}
                      itemFormatter={props.scope && props.scope.explorerItemFormatter}
                      selectionMode="Row" 
                      style={{height:"310px", minHeight:"310px"}}>
            <wjGridFilter.FlexGridFilter  filterColumns={null}></wjGridFilter.FlexGridFilter>
            {/*<wj-flex-grid-column header="" binding="selected" align="center" width={50} isReadOnly={true}={false}></wj-flex-grid-column>*/}
            {/*<wj-flex-grid-column header="コード" binding="code" align="left" width={90} isReadOnly={true}={true}></wj-flex-grid-column>*/}
            <wjGrid.FlexGridColumn header="名称" binding="name" align="left" width={160} isReadOnly={true}></wjGrid.FlexGridColumn>
            <wjGrid.FlexGridColumn header="連想" binding="association" align="left" width={120} isReadOnly={true}></wjGrid.FlexGridColumn>
        </wjGrid.FlexGrid>
    </div>
    <div>
        <div className="pull-left" style={{margin:"0 0 4px 2px"}} hidden={props.scope && props.scope.explorerIsSingleSelect}>
            <button type="button" className="btn btn-success" onClick={props.scope && props.scope.explorerSelectAll}>全選択</button>
            <button type="button" className="btn btn-danger" onClick={props.scope && props.scope.explorerCancelSelect}>全解除</button>
        </div>
        <div className="pull-right" style={{margin:"0 2px 6px 0"}}>
            <button type="button" className="btn btn-primary" onClick={props.scope && props.scope.explorerSelected}>選択</button>
            <button type="button" className="btn btn-default wj-hide" onClick={props.scope && props.scope.explorerCanceles}>キャンセル</button>
        </div>
    </div>
</wjInput.Popup>
      </>
    )
};
export default _ExplorerEasyInputAccountItemPartial;