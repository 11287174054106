import * as wijmo from 'wijmo/wijmo';
import * as wjInput from 'wijmo/wijmo.input';
import * as wjGrid from 'wijmo/wijmo.grid';
import { CodeAttr } from '../../core/constants/constant';
import $ from 'jquery';

/**
 * エクスプローラーに表示するアイテムのインターフェースです。
 */
export interface ExplorerItem {
    /** コード */
    code: string,
    /** 名称 */
    name: string,
    /** 連想ワード */
    association: string,
    /** コードと名称以外にノードに保存しておきたい追加データ。 */
    additionalData?: any
}

/**
 * ExplorerClosed イベントの引数を提供します。
 */
export class ExplorerExpansionClosedEventArgs extends wijmo.EventArgs {
    private selected: boolean;
    private selectedItemArray: any[]

    /**
     * コンストラクター。
     * @param isSelected 「選択」ボタンが押された場合、<code>true</code> を指定します
     * @param selectedItems 「選択」ボタンが押された場合、選択した科目の配列を指定します
     */
    constructor(isSelected: boolean, selectedItems: any[]) {
        super();

        this.selected = isSelected;
        this.selectedItemArray = selectedItems;
    }

    /**
     * 「選択」ボタンが押されてクローズされたかを取得します。
     */
    public get isSelected(): boolean {
        return this.selected;
    }

    /**
     * 「キャンセル」ボタンが押されてクローズされたかを取得します。
     */
    public get isCanceld(): boolean {
        return !this.selected;
    }

    /**
     * 選択された科目の配列を取得します。
     */
    public get selectedItems(): any[] {
        return this.selectedItemArray;
    }
}

/**
 * エクスプローラーです。
 */
export class ExplorerUIExpansion {
    private $scope: any;
    private $timeout: any;
    private controlName: string;
    private popup!: wjInput.Popup;
    private static hideHandled = false;
    /** 表示中フラグ */
    private static isShowed = false;

    public get isVisible(): boolean {
        let popup: wjInput.Popup = this.$scope[this.controlName].current.control;
        if (popup != null) return popup.isVisible;
        return undefined!;
    }

    /**
     * コンストラクター。
     * @param $scope
     */
    constructor($scope: any, $timeout: any, controlName: string) {

        this.$scope = $scope;
        this.$timeout = $timeout;
        this.controlName = controlName;

        if (!ExplorerUIExpansion.hideHandled) {
            let self = this;
            $.each(['hide'], function (i, val) {
                var _org = $.fn[val];
                $.fn[val] = function () {
                    if (self.popup != null) {
                        self.popup.hide();
                    }
                    this.trigger(val);
                    _org.apply(this, arguments);
                };
            });
            let modal = $(".modal-dialog");
            if (modal.css("display") == "block") {
                modal.click(e => {
                    if (ExplorerUIExpansion.isShowed && self.popup != null) {
                        self.popup.hide();
                    }
                });
            }
            ExplorerUIExpansion.hideHandled = true;
        }
    }

    private get flexGrid(): wjGrid.FlexGrid {
        return <wjGrid.FlexGrid>this.$scope[this.controlName + "Grid"];
    }

    /**
     * エクスプローラーを表示します。
     * @param $ownerElement エクスプローラーのオーナー
     * @param items エクスプローラーに表示する科目データの配列
     * @param selectedItems 選択状態にするデータの配列
     * @param isSingleSelect 単一選択の場合は<code>true</code>を指定します
     * @param codeAttr
     * @param scrollToCurrItem 現在選択中のアイテムまでスクロールするかどうか
     * @param selectRowMode
     */
    public show(
        $ownerElement: JQuery,
        items: any[],
        selectedItems: any[],
        isSingleSelect: boolean,
        codeAttr: number = CodeAttr.Number,
        scrollToCurrItem: boolean = false,
        selectRowMode: boolean = false): void {

        // コード属性に合わせて右寄/左寄を設定する
        let codeCol: wjGrid.Column = this.flexGrid ? this.flexGrid.columns.getColumn("code") : null!;
        if (codeCol) {
            this.setDataType(codeAttr, codeCol);
        }

        this.popup = this.$scope[this.controlName].current.control;
        if (this.popup.isVisible)
            return;

        let dataSource = items || [];
        let selectedItemArray = selectedItems || [];

        this.$scope[this.controlName + "CurrentInstance"] = this;
        this.$scope[this.controlName + "IsSingleSelect"] = isSingleSelect;
        this.$scope[this.controlName + "ClosedByEvent"] = false;
        this.$scope[this.controlName + "selectRowMode"] = selectRowMode;

        let currentIndex = -1;
        $.each(dataSource, (i: number, item: any) => {
            item.selected = selectedItemArray.some(value => value.code === item.code);
            if (currentIndex === -1 && item.selected)
                currentIndex = i;
        });

        this.$scope[this.controlName + "ItemSource"] = new wijmo.CollectionView(dataSource);
        if (this.$scope.$$phase == null) {
            // this.$scope.$apply();
        }

        this.popup.owner = $ownerElement[0] as HTMLElement;
        this.popup.show();

        ExplorerUIExpansion.isShowed = false;
        this.$timeout(() => {
            setTimeout(() => {
                // エクスプローラーが表示しきった後を見計らって、表示中フラグを立てる（Shownイベントでフラグが折られなかったとき用）
                ExplorerUIExpansion.isShowed = true;
            }, 500);

            setTimeout(() => {
                // スクロール位置（選択中のアイテムor最上段のアイテム）
                let scrollPos = scrollToCurrItem ? Math.max(0, currentIndex) : 0;
                // スクロール位置を画面に反映するため、いったんpos=-1にしてからposを設定する（pos変更扱いにする）
                this.$scope[this.controlName + "ItemSource"].moveCurrentToPosition(-1);
                this.$scope[this.controlName + "ItemSource"].moveCurrentToPosition(scrollPos);
            }, 100);
        });
    }

    /**
     * エクスプローラーを非表示にする。
     */
    public hide() {
        let popup: wjInput.Popup = this.$scope[this.controlName].current.control;
        if (popup != null) {
            popup.hide();
            ExplorerUIExpansion.isShowed = false;
        }
    }

    /**
     * コード属性から、右寄り、左寄りのスタイルを返す。
     * @param codeAttr
     * @param codeCol
     */
    private setDataType(codeAttr: number, codeCol: wjGrid.Column): void {
        switch (codeAttr) {
            case CodeAttr.Free:
                codeCol.dataType = wijmo.DataType.String;
                codeCol.align = "left";
                break;
            case CodeAttr.Number:
                codeCol.dataType = wijmo.DataType.Number;
                codeCol.align = "right";
                break;
            case CodeAttr.NumberZeroFill:
                codeCol.dataType = wijmo.DataType.String;
                codeCol.align = "right";
                break;
        }
    }

    /**
     * エクスプローラーの初期化処理を行います。
     * 当メソッドは scope.initialize メソッド内にて呼び出す必要があります。
     * @param $scope
     * @param $timeout
     */
    public static initialize($scope: any, $timeout: any, id: string, controlName: string): void {

        if (wijmo.isUndefined($scope[controlName + "Showing"]))
            $scope[controlName + "Showing"] = (sender: wjInput.Popup, e: wijmo.CancelEventArgs) => {
                // ※explorer表示中の処理があればここに記述
            }

        if (wijmo.isUndefined($scope[controlName + "Shown"]))
            $scope[controlName + "Shown"] = (sender: wjInput.Popup, e: wijmo.EventArgs) => {
                $timeout(() => {
                    // explorer表示時に他コントロールにフォーカスが移されているとexplorerのhide-trigger="Blur"が動かなくなるため、
                    // わざとexplorerにフォーカスを当てる
                    $("[control='" + controlName + "']").focus();
                    // wijmoの動作で１行目のチェックボックスにフォーカスが当たってしまう場合があるので、それを外しておく。
                    // 画面内に複数ExplorerUIExpansionがある場合、セレクタ指定に「.wj-state-focused」を指定しておかないと
                    // うまく該当の1グリッドが取得できない為、以下の指定としている。
                    $("div.wj-state-focused[id='" + id + "']").focus();

                    // エクスプローラーが表示しきった後を見計らって、表示中フラグを立てる
                    ExplorerUIExpansion.isShowed = true;
                }, 500);
            }

        if (wijmo.isUndefined($scope[controlName + "Hiding"]))
            $scope[controlName + "Hiding"] = (sender: wjInput.Popup, e: wijmo.CancelEventArgs) => {
                if (!ExplorerUIExpansion.isShowed) {
                    // explorerPopupが不正に閉じられた後で再度表示しようとすると、
                    //  表示処理中にHide処理が動いてしまうため、その動作を抑制
                    e.cancel = true;
                    return;
                }

                e.cancel = ($(".wj-columnfiltereditor").length > 0);
            }

        if (wijmo.isUndefined($scope[controlName + "Hidden"]))
            $scope[controlName + "Hidden"] = (sender: wjInput.Popup, e: wijmo.CancelEventArgs) => {
                if (!ExplorerUIExpansion.isShowed) {
                    // explorerPopupが不正に閉じられた後で再度表示しようとすると、
                    //  表示処理中にHide処理が動いてしまうため、その動作を抑制
                    e.cancel = true;
                    return;
                }

                ExplorerUIExpansion.isShowed = false;
                if (!$scope[controlName + "ClosedByEvent"]) {
                    let instance: ExplorerUIExpansion = $scope[controlName + "CurrentInstance"];
                    instance.onClosed(new ExplorerExpansionClosedEventArgs(false, []));
                }
            };

        if (wijmo.isUndefined($scope[controlName + "GridInitialized"])) {
            $scope[controlName + "GridInitialized"] = (sender: wjGrid.FlexGrid) => {
                $scope[controlName + "Grid"] = sender;
                sender.alternatingRowStep = 0;
                // var isSelectionChangedFired: boolean; //selectionChangedイベント発火通知フラグ
                // var selectedCellRngBefSelectionChanged: wjGrid.CellRange; //selectionChanged前の選択行
                // var selectedCellRngAftSelectionChanged: wjGrid.CellRange; //selectionChanged後の選択行

                // イベントハンドラーの追加・削除が簡単なので、jQueryを利用してイベントハンドラーの ON/OFF を行います。
                // （wijmo の場合は、EventTarget.addEventListener​ & EventTarget.removeEventListener）
                // https://developer.mozilla.org/ja/docs/Web/API/EventTarget/addEventListener
                // https://developer.mozilla.org/ja/docs/Web/API/EventTarget/removeEventListener
                let host = sender.hostElement;
                $(host).off(".ExplorerUIExpansion");

                // dblclick イベントハンドラーです。
                $(host).on("dblclick.ExplorerUIExpansion", (e: JQueryEventObject) => {
                    let ht = sender.hitTest(e.pageX, e.pageY);
                    if (ht.cellType === wjGrid.CellType.Cell) {

                        if ($scope[controlName + "IsSingleSelect"]) {
                            sender.collectionView.beginUpdate();
                            let currentItem = sender.collectionView.currentItem;
                            $.each(sender.collectionView.items, (index: number, item: any) => { item.selected = false; });

                            currentItem.selected = true;
                            sender.collectionView.endUpdate();

                            let popup: wjInput.Popup = $scope[controlName].current.control;
                            popup.hide();
                            $scope[controlName + "Selected"]();
                        }
                    }
                });

                sender.selectionChanging.addHandler((s, e) => {
                    // 選択行変更前の選択行を保持
                    // selectedCellRngBefSelectionChanged = sender.selection;
                });

                sender.selectionChanged.addHandler((s, e) => {
                    // 選択行変更後の選択行を保持
                    // selectedCellRngAftSelectionChanged = sender.selection;
                    // selectionChangedイベントが発火したことをフラグ保持
                    // isSelectionChangedFired = true;
                    // 後発のイベント処理(特にkeydownのEnter押下)終了後を見計らってフラグを折る
                    // setTimeout(() => { isSelectionChangedFired = false }, 300);
                });

                // Enterキー押下時のデフォルトActionを設定
                sender.keyActionEnter = wjGrid.KeyAction.None;

                // keydown イベントハンドラーです。
                $(host).on("keydown.ExplorerUIExpansion", (e: JQueryEventObject) => {
                    switch (e.which) {
                        case wijmo.Key.Enter:
                            // ※※Enter押下時に選択行を選択した状態で検索エクスプローラーを閉じる仕様とする場合は、以下のコメントアウトを解除する。

                            //// Enter(Shift+Enter)キー押下の場合は選択行が変わってしまうため、選択行をEnter押下前の選択行に戻す
                            //let selectedCellRange = sender.selection;
                            //selectedCellRange.col = selectedCellRange.col;
                            //if (e.shiftKey && (selectedCellRange.row <= 0) && !isSelectionChangedFired) {
                            //    // 先頭行でShift+Enter押下時（selectionChangedイベントは非発火）
                            //    // noop
                            //} else if (!e.shiftKey && (selectedCellRange.row == sender.rows.length - 1) && !isSelectionChangedFired) {
                            //    // 最終行でEnter押下時（selectionChangedイベントは非発火）
                            //    // noop
                            //} else if (selectedCellRngBefSelectionChanged.row != selectedCellRngAftSelectionChanged.row) {
                            //    // Enter押下前後で選択行が異なる場合は、Enter押下前（選択行移動前）の選択行を採用する
                            //    selectedCellRange.row = Math.max(0, selectedCellRngBefSelectionChanged.row);
                            //} else {
                            //    selectedCellRange.row = Math.max(0, selectedCellRngAftSelectionChanged.row);
                            //}
                            //selectedCellRange.col2 = selectedCellRange.col;
                            //selectedCellRange.row2 = selectedCellRange.row;
                            //sender.select(selectedCellRange, true);

                            //e.preventDefault();
                            //e.stopPropagation();

                            //if ($scope.explorerIsSingleSelect) {
                            //    sender.collectionView.beginUpdate();
                            //    let currentItem = sender.collectionView.currentItem;
                            //    $.each(sender.collectionView.items, (index: number, item: any) => { item.selected = false; });

                            //    currentItem.selected = true;
                            //    sender.collectionView.endUpdate();

                            //    let popup: wjInput.Popup = $scope.explorer;
                            //    popup.hide();
                            //    $scope.explorerSelected();
                            //}

                            break;

                        case wijmo.Key.Tab:
                            e.preventDefault();
                            break;

                        default:
                            break;
                    }
                });

                // グリッドフィルターのロストフォーカスイベントをハンドリングします。
                // グリッドフィルターの要素は表示される際に生成され、非表示になったタイミングで削除されるので、
                // イベントハンドラーの追加は特殊な方法で行っています。
                $(document).off("focusout.ExplorerUIExpansion", ".wj-columnfiltereditor");
                $(document).on("focusout.ExplorerUIExpansion", ".wj-columnfiltereditor", () => {
                    let popup: wjInput.Popup = $scope[controlName].current.control;
                    if (popup && popup.isVisible)
                        // グリッドフィルターのロストフォーカスイベントが発行され、500ミリ秒後にグリッドフィルターの要素が
                        // 存在しない場合は、エクスプローラーのグリッドにフォーカスを設定します。
                        // これはグリッドのフィルターが閉じられた際にポップアップ内にフォーカスが無いと、
                        // ポップアップがロストフォーカス時に閉じられる設定が効かなくなってしまうためです。
                        $timeout(() => {
                            if ($(".wj-columnfiltereditor").length === 0) {
                                let flex = <wjGrid.FlexGrid>wijmo.Control.getControl(id);
                                flex.focus();
                            }
                        }, 500);
                });
            }
        }

        if (wijmo.isUndefined($scope[controlName + "ItemFormatter"]))
            // グリッドのセルをカスタマイズします。
            $scope[controlName + "ItemFormatter"] = (panel: wjGrid.GridPanel, rowIndex: number, columnIndex: number, cell: HTMLElement) => {
                let cellType = panel.cellType;
                if (cellType === wjGrid.CellType.ColumnHeader) {
                    let innerHTML = cell.innerHTML;
                    // 選択列はヘッダーテキストが不要なので、空文字列に変換します。
                    if (columnIndex === 0 && innerHTML.indexOf("selected") === 0)
                        cell.innerHTML = innerHTML.replace("selected", "");
                }

                // デフォルトの設定ではエクスプローラーには大きすぎるので、スタイルの調整を行います。
                cell.style.paddingTop = "3px";
            }

        if (wijmo.isUndefined($scope[controlName + "GridCellEditEnded"]))
            // グリッドの選択セルの動作（単一選択の場合）を、定義します
            $scope[controlName + "GridCellEditEnded"] = (sender: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs) => {
                // 単一選択の場合は、選択セル（チェックボックス）をチェックボックスのように排他的な選択となるように操作します。
                if ($scope[controlName + "IsSingleSelect"]) {
                    let rowIndex = e.row;
                    let rows = sender.rows;
                    let row = rows[rowIndex];

                    if (row.dataItem.selected) {
                        let code = row.dataItem.code;
                        let dataSource: wijmo.CollectionView = $scope[controlName + "ItemSource"];
                        let dataSourceItems = dataSource.items;

                        dataSource.beginUpdate();
                        $.each(dataSourceItems.filter((item: any) => { return item.code != code; }), (_: number, item: any) => {
                            item.selected = false;
                        });
                        dataSource.endUpdate();
                        sender.invalidate();
                    }
                }
            }

        // 「全選択」ボタン click イベントハンドラー
        if (wijmo.isUndefined($scope[controlName + "SelectAll"]))
            $scope[controlName + "SelectAll"] = () => {
                ExplorerUIExpansion.updateAllSelectedFlag($scope, true, controlName);
            }

        // 「全解除」ボタン click イベントハンドラー
        if (wijmo.isUndefined($scope[controlName + "CancelSelect"]))
            $scope[controlName + "CancelSelect"] = () => {
                ExplorerUIExpansion.updateAllSelectedFlag($scope, false, controlName);
            }

        // 「選択」ボタン click イベントハンドラー
        if (wijmo.isUndefined($scope[controlName + "Selected"]))
            $scope[controlName + "Selected"] = () => {

                let result = [];
                if ($scope[controlName + "selectRowMode"]) {
                    let item = $scope[controlName + "Grid"].selectedItems[0];
                    result.push({ code: item.code, name: item.name, additionalData: item.additionalData });
                } else {
                    let itemsSource: wijmo.CollectionView = $scope[controlName + "ItemSource"];
                    $.each(itemsSource.items, (i: number, item: any) => {
                        if (item.selected)
                            result.push({ code: item.code, name: item.name, additionalData: item.additionalData });
                    });
                }


                if (!$scope[controlName + "IsSingleSelect"] || result.length === 1) {
                    $scope[controlName + "ClosedByEvent"] = true;
                    let popup: wjInput.Popup = $scope[controlName].current.control;
                    popup.hide();

                    let instance: ExplorerUIExpansion = $scope[controlName + "CurrentInstance"];
                    instance.onClosed(new ExplorerExpansionClosedEventArgs(true, result));
                }
            }

        // 「キャンセル」ボタン click イベントハンドラー
        if (wijmo.isUndefined($scope[controlName + "Canceles"]))
            $scope[controlName + "Canceles"] = () => {
                $scope[controlName + "ClosedByEvent"] = true;
                let popup: wjInput.Popup = $scope[controlName].current.control;
                popup.hide();

                let instance: ExplorerUIExpansion = $scope[controlName + "CurrentInstance"];
                instance.onClosed(new ExplorerExpansionClosedEventArgs(false, []));
            }
    }

    /**
     * エクスプローラーが閉じられた後に発生します。
     */
    public closed = new wijmo.Event();

    /**
     * closed イベントを発生させます。
     *
     * @param e イベントデータを含む @see:ExplorerExpansionClosedEventArgs
     */
    public onClosed(e: ExplorerExpansionClosedEventArgs): void {
        this.closed.raise(this, e);
    }

    /**
     * レコードの全選択または全解除を行います。
     * @param $scope
     * @param flag
     */
    private static updateAllSelectedFlag($scope: any, flag: boolean, controlName: string): void {
        let dataSource: wijmo.CollectionView = $scope[controlName + "ItemSource"];
        let dataSourceItems = dataSource.items;

        dataSource.beginUpdate();
        $.each(dataSourceItems, (i: number, item: any) => {
            dataSource.editItem(item);
            item.selected = flag;
        });

        dataSource.commitEdit();
        dataSource.moveCurrentToFirst();
        dataSource.endUpdate();
    }
}

