import {DataTypeEnum} from "../constants/enums";
import {TMonthVO} from "./tMonthVO";
/**
 * チェックリスト設定ViewModel ViewModel クラス
 */

export class ChecklistSettingViewModel {
    /** 内部コード(チェックリスト) */
    public NCode?:number;

    /** 種別区分 */
    public DataTypeKbn?:DataTypeEnum;

    /** 種別区分名称 */
    public DataTypeName?:string;

    /** チェックリスト名称 */
    public CklName!:string;

    /** 実行対象月区分 */
    public TargetTMonthKbn!:number;

    /** 内部月(対象月) */
    public NMonth!:Array<number>;

    /** チェックリスト内容 */
    public CklContent!:string;

    /** 有効フラグ */
    public YukoFlg?:boolean;

    /** コピー元内部コード(チェックリスト) */
    public OrgNCode?:number;

    /** コピー元種別区分 */
    public OrgTypeKbn?:DataTypeEnum;

    /** 編集可能フラグ  */
    public EditableFlg?:boolean;

    /** 対象月VO */
    public TMonths?:Array<TMonthVO>;

}
