import { MonthType } from "../defines";
import "../usefuls/utilities";

/**
 * 月範囲データモデル
 */
export class MonthRangeData {
    private _types: Array<MonthType>;
    private _innerMonths: Array<number>;
    private _quarter1Option: number;
    private _quarter2Option: number;
    private _quarter3Option: number;
    private _closingOption: number;

    constructor(selectedTypes: Array<MonthType>, selectedInnerMonths: Array<number>, quarter1Option: number, quarter2Option: number, quarter3Option: number, closingOption: number) {
        this._types = selectedTypes;
        this._innerMonths = selectedInnerMonths;
        this._quarter1Option = quarter1Option;
        this._quarter2Option = quarter2Option;
        this._quarter3Option = quarter3Option;
        this._closingOption = closingOption;
    }

    /**
     * 範囲内の内部月をすべて取得する
     */
    public get innerMonths(): Array<number> {
        return this._innerMonths;
    }

    /**
     * 範囲内の種別をすべて取得する
     */
    public get allTypes(): Array<MonthType> {
        return this._types;
    }

    /**
     * 範囲内の外部月をすべて取得する
     */
    public get allOuterMonths(): Array<number> {

        //外部月を配列で保持する
        var outerMonths: Array<number> = new Array<number>();
        var allTypes = this.allTypes;
        var typeLength: number = allTypes.length;

        //選択範囲内の種別から外部月を取得する
        this.allTypes.forEach((monthType: number, outerMonth: number) => {

            var monthTypeIndex = allTypes.indexOf(monthType) + 1;
            var chkNum: number = (typeLength - monthTypeIndex);
            var selectionType: number;

            if (typeLength == 1) {
                //単選択
                selectionType = 2;
            } else if (chkNum == 0) {
                //一番後ろ
                selectionType = 0;
            } else if ((typeLength - chkNum) == 1) {
                //一番前
                selectionType = 1;
            } else {
                //その他
                selectionType = 3;
            };

            switch (monthType) {
                case MonthType.AfterCloseQuarter1:
                    outerMonth = 20;
                    break;
                case MonthType.AfterCloseQuarter2:
                    outerMonth = 80;
                    break;
                case MonthType.AfterCloseQuarter3:
                    outerMonth = 30;
                    break;
                case MonthType.AfterCloseQuarter4:
                    outerMonth = 90;
                    break;
                case MonthType.Quarter1:
                    outerMonth = this.addOuterMonthbyOption(outerMonths, 20, parseInt(this.quarter1Option.toString()), selectionType);
                    break;
                case MonthType.Quarter2:
                    outerMonth = this.addOuterMonthbyOption(outerMonths, 80, parseInt(this.quarter2Option.toString()), selectionType);
                    break;
                case MonthType.Quarter3:
                    outerMonth = this.addOuterMonthbyOption(outerMonths, 30, parseInt(this.quarter3Option.toString()), selectionType);
                    break;
                case MonthType.Closing:
                    outerMonth = this.addOuterMonthbyOption(outerMonths, 90, parseInt(this.closingOption.toString()), selectionType);
                    break;
                default:
                    outerMonth = (monthType + 1);
                    break;
            }
            outerMonths.push(outerMonth);
        }, allTypes);

        return outerMonths;
    }

    /**
     * quarterOptionによって判別し、外部月を追加する
     * @param outerMonth
     * @param baseNum
     * @param option
     */
    private addOuterMonthbyOption(outerMonth: Array<number>, baseNum: number, option: number, selectionType: number): number {
        switch (selectionType) {
            case 0:
                //一番後ろ
                for (var i = 1; i < option; i++) {
                    outerMonth.push(baseNum + i);
                }
                return (baseNum + option);
            case 1:
                //一番前
                for (var i = option; i < 3; i++) {
                    outerMonth.push(baseNum + i);
                }
                return (baseNum + 3);
            case 2:
                return (baseNum + option);
            case 3:
                //その他
                for (var i = 1; i < 3; i++) {
                    outerMonth.push(baseNum + i);
                }
                return (baseNum + 3);
        }
        return undefined!;
    }


    /**
     * 開始月を取得する（1月を0とするインデックス値）
     */
    public get monthIndexFrom(): number {
        return this.monthTypes[0];
    }

    /**
     * 終了月 を取得する（1月を0とするインデックス値）
     */
    public get monthIndexTo(): number {
        return this.monthTypes[this.monthTypes.length - 1];
    }

    /**
     * "月" の種別をすべて取得する
     */
    public get monthTypes(): Array<MonthType> {
        return this._types.filter((e, index, array) =>
            e == MonthType.M1
            || e == MonthType.M2
            || e == MonthType.M3
            || e == MonthType.M4
            || e == MonthType.M5
            || e == MonthType.M6
            || e == MonthType.M7
            || e == MonthType.M8
            || e == MonthType.M9
            || e == MonthType.M10
            || e == MonthType.M11
            || e == MonthType.M12);
    }

    /**
     * "締後" の種別をすべて取得する
     */
    public get afterCloseQuarterTypes(): Array<MonthType> {
        return this._types.filter((e, index, array) =>
            e == MonthType.AfterCloseQuarter1
            || e == MonthType.AfterCloseQuarter2
            || e == MonthType.AfterCloseQuarter3
            || e == MonthType.AfterCloseQuarter4);
    }

    /**
     * "四半期" の種別をすべて取得する
     */
    public get quarterTypes(): Array<MonthType> {
        return this._types.filter((e, index, array) =>
            e == MonthType.Quarter1
            || e == MonthType.Quarter2
            || e == MonthType.Quarter3);
    }

    /**
     * 範囲内に "決算" が含まれているかどうかを取得する
     */
    public get hasClosing(): boolean {
        return this._types.any((e: MonthType) => e == MonthType.Closing);
    }

    /**
     * 第一四半期のオプション値（1～3）を取得する
     */
    public get quarter1Option(): number {
        return this._quarter1Option;
    }

    /**
     * 中間期（第二四半期）のオプション値（1～3）を取得する
     */
    public get quarter2Option(): number {
        return this._quarter2Option;
    }

    /**
     * 第三四半期のオプション値（1～3）を取得する
     */
    public get quarter3Option(): number {
        return this._quarter3Option;
    }

    /**
     * 決算のオプション値（1～3）を取得する
     */
    public get closingOption(): number {
        return this._closingOption;
    }

    /**
     * 指定された決算期（第一期～第三期、もしくは決算）のオプション値（1～3）を取得する
     * @param closingType 取得したい決算期種別。Quarter1/Quarter2/Quarter3/Closing のいずれか
     */
    public getClosingOptionValue(closingType: MonthType): number {
        switch (closingType) {
            case MonthType.Quarter1:
                return this.quarter1Option;
            case MonthType.Quarter2:
                return this.quarter2Option;
            case MonthType.Quarter3:
                return this.quarter3Option;
            case MonthType.Closing:
                return this.closingOption;
        }

        throw Error; // ここに来たら実装バグ

    }
}
