import React from 'react';
import Axios from 'axios';
import * as wj from 'wijmo/wijmo';
import * as wjInput from 'wijmo/wijmo.input';
import * as wjGrid from 'wijmo/wijmo.grid';
import { FlexGrid } from 'wijmo/wijmo.react.grid';
import { ComboBox } from 'wijmo/wijmo.react.input';
import { Input, FormGroup, Label } from 'reactstrap';

import {
  LowerActionLayout,
  LowerActionLayoutHeader,
  LowerActionLayoutContents,
  LowerActionLayoutAction
} from '../../../../components/templates/LowerActionLayout';
import PageHeader from '../../../../components/molecules/CMN/PageHeader/PageHeader';
import MonthlyBalanceSelectGrid, {
  sortLabel,
  setFilterDefinition,
  setSortCriteria,
  enumSortLabel
} from '../../../../components/molecules/CMN/Grid/MonthlyBalanceSelectGrid';
import AuditTargetManager, { AuditTargetManagerVo } from '../../../organisms/A000/AuditTargetManager';
import ExcelOutputsetting from '../../../organisms/A000/ExcelOutputsetting';
import ExecutingCancel from '../../../../components/molecules/CMN/ExecutingCancel/ExecutingCancel';
import ConfirmationPopup from '../../../../components/molecules/CMN/ConfirmationPopup/ConfirmationPopup';
import RuleImport from '../../A000/AIKCMN001000005/AuditRuleImport';
import RuleExport from '../../A000/AIKCMN001000005/AuditRuleExport';

import * as labels from '../../../../constants/labels';
import * as message from '../../../../constants/message';
import * as constants from '../../../../constants/constant';
import * as enums from '../../../../constants/enums';
import * as dataMaps from '../../../../constants/dataMaps';
import { MonthlyBalanceRuleSettingViewModel } from '../../../../models/monthlyBalanceRuleSettingViewModel';
import { MonthlyBalanceRuleItemVO } from '../../../../models/monthlyBalanceRuleItemVO';
import { createWjDataMap, getValueFromDataMap } from '../../../../utils/dataMapsUtils';
import { bindValueToMessage } from '../../../../utils/messageUtils';
import { setAlertMessage, AlertKbnEnum } from '../../../../stores/AlertMessage';
import { SelectionMode } from 'wijmo/wijmo.grid';
import Button from 'reactstrap/lib/Button';
import { MasterKbn } from '../../../../VKZ/core/constants/constant';
import { setIsLoading } from '../../../../stores/NowLoading';
import { MonthlyBalanceRulePatternItemVO } from '../../../../models/monthlyBalanceRulePatternItemVO';

// 基本URL
const BaseUrl = '/api/v1/MonthlyBalanceRuleSetting';
// 加工後URL
export const initialUrl = (rulePattern: enums.RulePatternEnum) => [BaseUrl, 'Initial', rulePattern].join('/');
export const saveUrl = (rulePattern: enums.RulePatternEnum, auditTarget: number) => [BaseUrl, 'Rule', rulePattern, auditTarget].join('/');
export const deleteUrl = (rulePattern: enums.RulePatternEnum) => [BaseUrl, 'Rule', rulePattern].join('/');
// 処理判定
const JudgeURL = 'api/v1/CommonStartJudgement/Judge';
// システム履歴記入
const WriteSysHistoryURL = 'api/v1/CommonSystemHistory/Write';

// ルールパターンのID
const rulePatternCmbId = 'MonthlyBalanceSetting-rulePatternCmb';

// 初期表示のパターン
const initialRulePattern = enums.RulePatternEnum.RulePattern1;

// パターンコンボボックスのリスト
const rulePatternItemsSource = dataMaps.RulePatternEnum.map(item => ({ ...item }));

// チェックボックス項目のソート文言
const wjCheckboxMap = [
  { key: true, value: labels.AIKADT001000003_GRID_SORT_TRUE },
  { key: false, value: labels.AIKADT001000003_GRID_SORT_FALSE }
];

// チェックボックス項目のソート文言
const defaultViewModel: MonthlyBalanceRuleSettingViewModel = {
  RulePatternName: '',
  RulePatternDefaultSettingFlg: false,
  AuditTarget: 1,
  RuleItems: [],
  RuleItemKmks: [],
  RuleItemBmns: [],
  UpdateLimit: 100
};

// 確認ポップアップの表示区分
enum confirmKbn {
  cancel = 0,
  excelOutput = 1,
  ruleImport = 2,
  ruleExport = 3,
  rulePattern = 4,
  delete = 5,
  auditTarget = 6
}

type MonthlyBalanceSettingProps = {
  onClose: () => void;
  fiscalYear: number;
};

const defaultAuditTarget: AuditTargetManagerVo = {
    target: MasterKbn.CountingKmk
}

export const settingAuditTarget: AuditTargetManagerVo = {
    target: MasterKbn.CountingKmk
}

const MonthlyBalanceSetting: React.FC<MonthlyBalanceSettingProps> = props => {
  const [excelPopupActivated, setExcelPopupAcrivated] = React.useState(false);
  const [communicating, setCommunicating] = React.useState(false);
  const [confirmActivated, setConfirmActivated] = React.useState(false);
  const [confirmPopupKbn, setConfirmPopupKbn] = React.useState<confirmKbn>();
  const [confirmMessage, setConfirmMessage] = React.useState('');
  const [excelServiceParams, setExcelServiceParams] = React.useState('');
  const [excelheaderLeft, setExcelheaderLeft] = React.useState('');
  const [filters, setFilters] = React.useState('');
  const [chkMessage, setChkMessage] = React.useState('');
  const [viewModel, updateViewModel] = React.useState<MonthlyBalanceRuleSettingViewModel>(defaultViewModel);
  const [oldViewModel, updateOldViewModel] = React.useState<MonthlyBalanceRuleSettingViewModel>(defaultViewModel);
  const [rulePattern, setRulePattern] = React.useState<enums.RulePatternEnum>(initialRulePattern);
  const [tmpRulePattern, setTmpRulePattern] = React.useState<enums.RulePatternEnum>();
  const [rulePatternCmb, setRulePatternCmb] = React.useState<wjInput.ComboBox>();
  const [auditTarget, setAuditTarget] = React.useState<AuditTargetManagerVo>(defaultAuditTarget);
  const [tmpAuditTarget, setTmpAuditTarget] = React.useState<AuditTargetManagerVo>();
  const [iniAuditTarget, setIniAuditTarget] = React.useState<AuditTargetManagerVo>();
  const [auditTargetCancelflg, setAuditTargetCancelflg] = React.useState<boolean>(false);
  const [auditDisabled, setAuditDisabled] = React.useState<boolean>(false);
  const [initialFlg, setInitialFlg] = React.useState<boolean>(true);
  const [dblCheckFlg, setDblCheckFlg] = React.useState<boolean>(false);
  
  const ref = React.createRef<FlexGrid>();

  // 閉じる
  const handleOnCloseClick = () => {
    props.onClose();
  };

  // Excel出力ボタン押下
  const handleOnExcelClick = () => {
    if (
      ref.current != null &&
      checkEditedViewModel({ ...viewModel, RuleItems: ref.current.props.itemsSource }, oldViewModel, 0)
    ) {
      // 未保存の行が存在する
      setConfirmMessage(message.Common_Confirmation_Edited_Excel);
      setConfirmPopupKbn(confirmKbn.excelOutput);
    } else {
      excelOutput();
    }
  };

  // Excel出力表示
  const excelOutput = () => {
    setExcelPopupAcrivated(true);
  };

  // Excel出力非表示
  const handleExcelOnClose = () => {
    setExcelPopupAcrivated(false);
  };

  // フィルター情報
  const handleOnFilterChanged = (filterJson: string) => {
    setFilters(filterJson);
    };

  // patternコンボボックスのItialized
  const rulePatternCmbinItialized = (comboBox: wjInput.ComboBox) => {
    setRulePatternCmb(comboBox);
  };

  // パターンコンボボックス変更時の処理
  const handleSelectedIndexChangedRulePattern = () => {
    if (rulePatternCmb!.selectedItem.key === rulePattern) {
      // パターンの変更が取消された場合
      setTmpRulePattern(undefined);
    } else {
      setTmpRulePattern(rulePatternCmb!.selectedItem.key);
    }
  };

  // ルールパターン変更
  const rulePatternChange = () => {
    if (typeof tmpRulePattern !== 'undefined') {
      setTmpRulePattern(undefined);
      setRulePattern(tmpRulePattern);
      setDblCheckFlg(true);
    }
  };

  // ルールパターン変更キャンセル
  const rulePatternChangeCancel = () => {
    rulePatternCmb!.selectedValue = rulePattern;
  };

  // パターン名称変更時の処理
  const handleOnRulePatternNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateViewModel({ ...viewModel, RulePatternName: e.target.value });
  };

  // 標準設定変更時の処理
  const handleOnRulePatternDefaultChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateViewModel({ ...viewModel, RulePatternDefaultSettingFlg: e.target.checked });
  };

    // 監査対象変更未保存OK
    const auditTargetChange = () => {
      if (typeof tmpAuditTarget !== 'undefined') {
        setTmpAuditTarget(auditTarget);
        setAuditTargetCancelflg(false);
        setChkMessage('');

        let vm = new MonthlyBalanceRuleSettingViewModel();
        let oldvm = new MonthlyBalanceRuleSettingViewModel();
        vm = viewModel;
        oldvm = oldViewModel;

        switch (auditTarget.target) {
          case MasterKbn.Bmn:
            vm.AuditTarget = MasterKbn.Bmn;
            vm.RuleItems = oldvm.RuleItemBmns;
            vm.RuleItemKmks = oldvm.RuleItems;
            updateViewModel(vm);
            break;
          case MasterKbn.CountingKmk:
          default:
            vm.AuditTarget = MasterKbn.CountingKmk;
            vm.RuleItems = oldvm.RuleItemKmks;
            vm.RuleItemBmns = oldvm.RuleItems;
            updateViewModel(vm);
            break;
        }
        updateOldViewModel(deepCopyViewModel(vm));
      }
    };

    // 監査対象変更未保存キャンセル
    const auditTargetChangeCancel = () => {
      setAuditTargetCancelflg(true);
      setIniAuditTarget({ ...settingAuditTarget, target: tmpAuditTarget?.target });
    };

  // 初期処理
  const initialOnGet = (url: string) => {
    setCommunicating(true);
    setAuditDisabled(true);
    setIsLoading(true);
    setChkMessage('');
    Axios.get<MonthlyBalanceRuleSettingViewModel>(url)
        .then(response => {
        var newViewModel = response.data;

        if (newViewModel.RuleItemBmns != null && newViewModel.AuditTarget != MasterKbn.CountingKmk) {
            if (newViewModel.MasterInfoItems?.MasterKbn == MasterKbn.Bmn && newViewModel.MasterInfoItems?.UseKbn != 0) {
                var codeAttr = newViewModel.MasterInfoItems.CodeAttr;

                // 科目コード→表示用科目コードの対応表を作成
                var kmkCodeDictionary: { [prop: number]: number} = {};
                newViewModel.AccountInfoItems?.forEach(kmk => {
                    kmkCodeDictionary[kmk.KmkCode!] = kmk.KmkGCode!;
                });

                newViewModel.AccountInfoItems?.where(w => w.BmnUse == 1 || w.BmnUse == 2).forEach(kmk => {
                    if (kmk.KmkGCode != null) {
                        if (kmk.BPKbn == 0 &&
                            (checkKmkCode(kmkCodeDictionary, newViewModel.MasterInfoItems?.KmkCode1, true) > kmk.KmkGCode || 
                            checkKmkCode(kmkCodeDictionary, newViewModel.MasterInfoItems?.KmkCode2, false) < kmk.KmkGCode)) {
                            //BS/PL区分=0(BS)で、マスタ基本情報kmkcode1～kmkcode2(部門B/S科目コード開始～終了)範囲内にいなければ対象外
                            return;
                        }
                        if (kmk.BPKbn == 1 && 
                            (checkKmkCode(kmkCodeDictionary, newViewModel.MasterInfoItems?.KmkCode3, true) > kmk.KmkGCode || 
                            checkKmkCode(kmkCodeDictionary, newViewModel.MasterInfoItems?.KmkCode4, false) < kmk.KmkGCode)) {
                            //BS/PL区分=1(PL)で、マスタ基本情報kmkcode3～kmkcode4(部門P/L科目コード開始～終了)範囲内にいなければ対象外
                            return;
                        }
                    }

                    if (newViewModel.RuleItemBmns != null) {
                        var kmklist = newViewModel.RuleItemBmns.where(f => f.KmkCode == kmk.KmkCode);

                        if (newViewModel.HojyoInfoItems != null) {
                            newViewModel.HojyoInfoItems.forEach(hojyo => {
                                var model = new MonthlyBalanceRuleItemVO();
                                if (kmk.BPKbn == 0 && hojyo.HojyoKbn2 == 0)  //BPKbn=0(BS),HojyoKbn2=0(BS不採用)は対象外
                                {
                                    return;
                                }
                                if (kmk.BPKbn == 1 && hojyo.HojyoKbn2 == 2)  //BPKbn=1(PL),HojyoKbn2=1(PL不採用)は対象外
                                {
                                    return;
                                }

                                if (newViewModel.RuleItemBmns != null) {
                                    var bmnlist = kmklist.where(w => w.HojyoCode == hojyo.HojyoCode);
                                    if (bmnlist.length == 0) {
                                        model.AddUpdKbn = 0;
                                        model.HojyoCode = hojyo.HojyoCode;
                                        model.HojyoGCode = hojyo.HojyoGCode;
                                        model.HojyoGCodeNum = (codeAttr == 0 ? +(hojyo.HojyoGCode ?? 0) : 99999999999);
                                        model.HojyoName = hojyo.SimpleName;
                                        model.KmkCode = kmk.KmkCode;
                                        model.KmkGCode = kmk.KmkGCode
                                        model.KmkName = kmk.SimpleName
                                        model.NBHojyoFlg = false;
                                        model.NBKmkFlg = false;
                                        model.RidHojyoFlg = false;
                                        model.RidKmkFlg = false;
                                        model.RulePatternKbn = rulePattern;
                                        model.SubDspFlg = false;

                                        newViewModel.RuleItemBmns.push(model);
                                    }
                                }
                            });
                        }
                    }
                });
            }
        }
            // コード属性が 1(前ゼロ)の場合は HojyoGCode でソート(文字列比較によるソート)で問題無し。
            if (newViewModel.MasterInfoItems?.CodeAttr == 0) {
                newViewModel.RuleItemBmns?.sort((a, b) => {
                    if ((a.KmkGCode ?? 0) > (b.KmkGCode ?? 0)) return 1;
                    if ((a.KmkGCode ?? 0) < (b.KmkGCode ?? 0)) return -1;
                    if ((a.HojyoGCodeNum ?? 0) > (b.HojyoGCodeNum ?? 0)) return 1;
                    if ((a.HojyoGCodeNum ?? 0) < (b.HojyoGCodeNum ?? 0)) return -1;
                    return 0
                });
            } else {
                newViewModel.RuleItemBmns?.sort((a, b) => {
                    if ((a.KmkGCode ?? 0) > (b.KmkGCode ?? 0)) return 1;
                    if ((a.KmkGCode ?? 0) < (b.KmkGCode ?? 0)) return -1;
                    if ((a.HojyoGCode ?? "") > (b.HojyoGCode ?? "")) return 1;
                    if ((a.HojyoGCode ?? "") < (b.HojyoGCode ?? "")) return -1;
                    return 0
                });
            }


        // 月次残高ルール設定(科目)がnullの場合、部門に置き換え
        if (newViewModel.RuleItemKmks?.length == 0) {
          newViewModel.AuditTarget = MasterKbn.Bmn;
          newViewModel.RuleItems = newViewModel.RuleItemBmns;
          
        } else {
          newViewModel.AuditTarget = MasterKbn.CountingKmk;
          newViewModel.RuleItems = newViewModel.RuleItemKmks;

            if (newViewModel.RuleItemBmns?.length != 0) {
                setAuditDisabled(false);
            }
        }
        setIniAuditTarget({ ...defaultAuditTarget, target: newViewModel.AuditTarget });
        setAuditTarget({ ...defaultAuditTarget, target: newViewModel.AuditTarget });
        updateViewModel(newViewModel);
        updateOldViewModel(deepCopyViewModel(newViewModel));
      })
      .catch(error => {
        if (typeof error.response.data !== 'undefined') {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
        }
        setIsLoading(false);
      })
      .finally(() => {
        setCommunicating(false);
        setIsLoading(false);
      });
  };


  // 内部科目コードに対応する外部コードを kmkCodeDictionary から取得する。
  // kmkCodeDictionary isFirst がtrue の場合は開始科目、そうでない場合は終了科目を返す。
  const checkKmkCode = (kmkCodeDictionary: { [prop: number]: number }, kmkCode: number | undefined, isFirst: boolean) => {

      if (!kmkCode) {
        return 0;
      } else if (kmkCode in kmkCodeDictionary) { // 内部科目コードに対応する外部コードが存在する場合は辞書に収容された値を返す
        return kmkCodeDictionary[kmkCode];
      } else if(isFirst) { // 存在しない場合で、開始科目フラグが true の場合、開始科目を返す
        return Object.keys(kmkCode).map(Number).sort()[0];
      } else { // 上記以外の場合、終了科目を返す
        return Object.keys(kmkCode).map(Number).sort((a, b) => b - a)[0];
      }
  };

  // ビューモデルのディープコピー
  const deepCopyViewModel = (vm: MonthlyBalanceRuleSettingViewModel) => {
    const copyViewModel = { ...vm };

    // 月次残高ルールをディープコピー
    if (vm.RuleItems != null && vm.RuleItems.length > 0) {
      copyViewModel.RuleItems = [];
      vm.RuleItems.forEach(vo => {
        copyViewModel.RuleItems!.push({ ...vo });
      });
    }

    return copyViewModel;
  };

  // 保存処理
  const saveOnPost = (url: string) => {
    if (ref.current != null) {
      // 件数が5000件以下の場合、個別DeleteInsertを行う(syoriKbn == 1)
      let modRuleItems = getEditedViewModel({ ...viewModel, RuleItems: ref.current.props.itemsSource }, oldViewModel);
      let syoriKbn = 1;
      let updLimit = 0;
      //更新行数リミット取得
      if (viewModel.UpdateLimit != null) {
        updLimit = viewModel.UpdateLimit.valueOf();
        }
      if (modRuleItems.where(w => w.AddUpdKbn == 1).length > updLimit || auditTarget.target == MasterKbn.CountingKmk) {
        //更新件数が更新行数リミットより大きい場合 全削除Insertを行う  監査対象:科目も同じ
        syoriKbn = 0;
        modRuleItems = ref.current.props.itemsSource.slice(0);
      }

      // 更新用リストの作成
      const viewModelData_post = {
        ...viewModel,
         RuleItems: modRuleItems
        ,RuleItemBmns: Array<MonthlyBalanceRuleItemVO>()
        ,RuleItemKmks: Array<MonthlyBalanceRuleItemVO>()
      };

      // 更新後表示用リストの作成
      const viewModelData = {
        ...viewModel,
         RuleItems: ref.current.props.itemsSource.slice(0)
        ,RuleItemBmns: Array<MonthlyBalanceRuleItemVO>()
        ,RuleItemKmks: Array<MonthlyBalanceRuleItemVO>()
      };
        
      setCommunicating(true);
      setIsLoading(true);
      if (!auditDisabled) {
        setAuditDisabled(true);
      }
        const flexGrid = ref.current!.control as wjGrid.FlexGrid;
        Axios.post<MonthlyBalanceRuleSettingViewModel>(url + '/' + syoriKbn.toString(), viewModelData_post)
        .then(response => {
          setAlertMessage({
            alerted: true,
            alertKbn: AlertKbnEnum.success,
            message: bindValueToMessage(message.Common_Success_Registration, [constants.MONTHLYBALANCE])
          });

          if (viewModelData.RulePatternDefaultSettingFlg === true) {
            if (viewModelData.AuditTarget == MasterKbn.Bmn) {
              viewModelData.DefaultRulePatternBmn = rulePattern;
            } else {
              viewModelData.DefaultRulePattern = rulePattern;
            }
          } else {
            if (viewModelData.DefaultRulePattern === rulePattern) {
              if (viewModelData.AuditTarget == MasterKbn.Bmn) {
                viewModelData.DefaultRulePatternBmn = undefined;
              } else {
                viewModelData.DefaultRulePattern = undefined;
              }
            }
          }

          if (viewModelData.RulePatternItems?.where(w => w.RulePatternKbn == rulePattern).length == 0) {
            let patternItem = new MonthlyBalanceRulePatternItemVO();
            patternItem.AuditTarget = auditTarget.target;
            patternItem.RulePatternKbn = rulePattern;
            viewModelData.RulePatternItems.push(patternItem);
          }

          if (syoriKbn == 1) {
            viewModel.RuleItems?.forEach(f => {
              if (modRuleItems.where(w => w.KmkCode == f.KmkCode && w.HojyoCode == f.HojyoCode && w.RulePatternKbn == f.RulePatternKbn).length != 0) {
                f.AddUpdKbn = 1;
              }
            });
          }

          // フィルタ条件を保持
          setFilterDefinition(filters);

          // ソート条件を保持
          const sortDesc = flexGrid.collectionView.sortDescriptions[0];
          if (typeof sortDesc !== 'undefined') {
            setSortCriteria({ property: sortDesc.property, ascending: sortDesc.ascending });
          } else {
            setSortCriteria({ property: undefined, ascending: undefined });
          }
            updateViewModel(viewModelData);
            updateOldViewModel(deepCopyViewModel(viewModelData));
        })
        .catch(error => {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
          setIsLoading(false);
        })
        .finally(() => {
          setCommunicating(false);
          setIsLoading(false);
        });
      }
    };

    async function JudgeOnPost(url: string, syoriId: string) {
        const status = await Axios.post<number>(url, { syoriId: syoriId });
        if (status.data == 0) {
            // 初期起動
            initialOnGet(initialUrl(rulePattern));
            await Axios.post(WriteSysHistoryURL, { syoriId: syoriId, operation: '処理開始' });
        } else {
            if (status.data == -101) {
                setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotUse });
            } else if (status.data == -102) {
                setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractOver });
            } else if (status.data == -103) {
                setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_Notauthorized });
            } else if (status.data == -104) {
                setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotDataSelect });
            } else if (status.data == -105) {
                setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractEqual });
            }
            props.onClose();
        }
    }


  // 初期処理の通信処理実行
  React.useEffect(() => {
    // 処理判定
    JudgeOnPost(JudgeURL, 'AIKADT001000003');
  }, [initialUrl, props.fiscalYear, rulePattern]);

    React.useEffect(() => {
        //パターンリストの内容を更新する
        if (rulePatternCmb) {
            rulePatternCmb.collectionView.beginUpdate();
            rulePatternMod(rulePatternCmb);
            rulePatternCmb.collectionView.endUpdate();
        }
    }, [viewModel]);

  // 保存処理の通信処理実行
  const handleOnExecuteClick = () => {
    if (ref.current == null || ref.current.props.itemsSource == null) {
      return;
    }
    const viewModelData = ref.current.props.itemsSource as MonthlyBalanceRuleItemVO[];

    // メッセージ欄初期化
    setChkMessage('');

    // 入力検証

    // パターン名称チェック
    if (viewModel.RulePatternName != undefined) {
      // 入力禁止文字チェック
      const regexp = new RegExp(constants.FILENAMEREGULAREXPRESSION);
      if (viewModel.RulePatternName.match(regexp) != null) {
        setChkMessage(bindValueToMessage(message.Common_Error_ProhibitedCharacter, ['パターン名称']));
        return;
      }
    }

    // 一覧内容のチェック
    for (const vo of viewModelData) {
      // 著増減チェック対象の場合
      if (vo.RidKmkFlg === true || (vo.RidHojyoFlg === true && vo.SubDspFlg === true)) {
        if (vo.CmpTMonthKbn == undefined || vo.CmpValueKbn == undefined || vo.SkiSign == undefined) {
          // 比較対象月、比較値、符号が入力されていない場合
          setChkMessage(bindValueToMessage(message.MonthlyBalanceCheck_Error_TargetRemarkIncDec, [String(vo.KmkName)]));
          if (vo.CmpTMonthKbn == undefined) {
            gridOnSelect(vo, 'CmpTMonthKbn');
          } else if (vo.CmpValueKbn == undefined) {
            gridOnSelect(vo, 'CmpValueKbn');
          } else if (vo.SkiSign == undefined) {
            gridOnSelect(vo, 'SkiSign');
          }
          return;
        }
        if (vo.SkiValue == undefined && vo.SkiValueSum == undefined) {
          // しきい値、しきい金額の両方が入力されていない場合
          setChkMessage(
            bindValueToMessage(message.MonthlyBalanceCheck_Error_TargetRemarkIncDec_Ski, [String(vo.KmkName)])
          );
          gridOnSelect(vo, 'SkiValue');
          return;
        }
        if (vo.CmpTMonthKbn === enums.CmpTMonthEnum.CurrentMonth && vo.SkiValue != undefined) {
          // 比較対象月が当月でしきい値が入力されている場合
          setChkMessage(
            bindValueToMessage(message.MonthlyBalanceCheck_Error_TargetRemarkIncDec_CurrentMonth, [String(vo.KmkName)])
          );
          gridOnSelect(vo, 'SkiValue');
          return;
        }
      } else {
        // 著増減チェック対象外の場合
        if (
          vo.CmpTMonthKbn != undefined || // 比較対象月が入力されている
          vo.CmpValueKbn != undefined || // 比較値が入力されている
          vo.SkiSign != undefined || // 符号が入力されている
          vo.SkiValue != undefined || // しきい値が入力されている
          vo.SkiValueSum != undefined // しきい値金額が入力されている
        ) {
          setChkMessage(
            bindValueToMessage(message.MonthlyBalanceCheck_Error_ExcludedRemarkIncDec, [String(vo.KmkName)])
          );
          if (vo.CmpTMonthKbn != undefined) {
            gridOnSelect(vo, 'CmpTMonthKbn');
          } else if (vo.CmpValueKbn != undefined) {
            gridOnSelect(vo, 'CmpValueKbn');
          } else if (vo.SkiSign != undefined) {
            gridOnSelect(vo, 'SkiSign');
          } else if (vo.SkiValue != undefined) {
            gridOnSelect(vo, 'SkiValue');
          } else if (vo.SkiValueSum != undefined) {
            gridOnSelect(vo, 'SkiValueSum');
          }
          return;
        }
      }

      // 比較対象月の区分が、比較対象月区分値に存在すること
      if (!dataMaps.CmpTMonthEnum.find(x => x.key == vo.CmpTMonthKbn) == undefined) {
        setChkMessage(message.MonthlyBalanceCheck_Error_ComparisonTargetMonth_OutOfRange);
        gridOnSelect(vo, 'CmpTMonthKbn');
        return;
      }

      // 増減率しきい値が、0.00～999.99の範囲であること
      if (vo.SkiValue != undefined && (vo.SkiValue < 0 || constants.CALCULATEMAX < vo.SkiValue)) {
        setChkMessage(message.MonthlyBalanceCheck_Error_Threshold_OutOfRange);
        gridOnSelect(vo, 'SkiValue');
        return;
      }

      // 増減率しきい値金額が、0～9999999999999の範囲であること
      if (
        vo.SkiValueSum != undefined &&
        (vo.SkiValueSum < 0 || 9999999999999 < vo.SkiValueSum || !Number.isInteger(vo.SkiValueSum))
      ) {
        setChkMessage(message.MonthlyBalanceCheck_Error_SkiValueSum_OutOfRange);
        gridOnSelect(vo, 'SkiValueSum');
        return;
      }
    }

      // 保存処理
      saveOnPost(saveUrl(rulePattern, auditTarget?.target ?? MasterKbn.CountingKmk));
  };

  // 指定されたセルを選択する
  const gridOnSelect = (vo: MonthlyBalanceRuleItemVO, binding: string) => {
    if (ref.current) {
      const flexGrid = ref.current.control as wjGrid.FlexGrid;
      const items = flexGrid.collectionView.items;
      const selectionRow = items.indexOf(vo);
      const selectionCol = gridColumns.indexOf(gridColumns.filter(column => column.binding === binding)[0]);
      if (selectionRow > -1 && selectionCol > -1) {
        flexGrid.selection = new wjGrid.CellRange(selectionRow, selectionCol);
        flexGrid.focus();
        // スクロールの移動
        setTimeout(() => {
          const rc = flexGrid.cells.getCellBoundingRect(selectionRow, selectionCol, true);
          flexGrid.scrollPosition = new wj.Point(-rc.left, -rc.top);
        }, 100);
      }
    }
  };

  // 取消ボタン押下
  const handleOnCancelClick = () => {
    if (
      ref.current != null &&
      checkEditedViewModel({ ...viewModel, RuleItems: ref.current.props.itemsSource }, oldViewModel, 0)
    ) {
      // 未保存の行が存在する
      setConfirmMessage(message.Common_Confirmation_Cancel);
      setConfirmPopupKbn(confirmKbn.cancel);
    }
  };

  // 取消処理
  const cancel = () => {
    setChkMessage('');
    setDblCheckFlg(true);
    setIniAuditTarget(auditTarget);
    initialOnGet(initialUrl(rulePattern));
  };

  // 確認メッセージ
  React.useEffect(() => {
    if (typeof confirmPopupKbn !== 'undefined') {
      setConfirmActivated(true);
    } else {
      setConfirmActivated(false);
    }
  }, [confirmPopupKbn]);

  // 確認メッセージ非表示
  const handleConfirmOnClose = () => {
    setConfirmPopupKbn(undefined);
  };

  // 確認メッセージ(Yes)処理
  const handleConfirmOnYesClick = () => {
    handleConfirmOnClose();
    switch (confirmPopupKbn) {
      case confirmKbn.cancel:
        cancel();
        break;
      case confirmKbn.excelOutput:
        excelOutput();
        break;
      case confirmKbn.ruleImport:
        ruleImportOpen();
        break;
      case confirmKbn.ruleExport:
        ruleExportOpen();
        break;
      case confirmKbn.rulePattern:
        rulePatternChange();
        break;
      case confirmKbn.delete:
        execDelete();
        break;
      case confirmKbn.auditTarget:
        auditTargetChange();
        break;
    }
  };

  // 確認メッセージ(No)処理
  const handleConfirmOnNoClick = () => {
    switch (confirmPopupKbn) {
      case confirmKbn.rulePattern:
        rulePatternChangeCancel();
        break;
      case confirmKbn.auditTarget:
        auditTargetChangeCancel();
        break;
    }
    handleConfirmOnClose();
  };

  // インポート
  const [ruleImportActivated, setRuleImportActivated] = React.useState(false);
  const handleOnImportClick = () => {
    if (
      ref.current != null &&
      checkEditedViewModel({ ...viewModel, RuleItems: ref.current.props.itemsSource }, oldViewModel, 0)
    ) {
      // 未保存の行が存在する
      setConfirmMessage(message.Common_Confirmation_Edited_Import);
      setConfirmPopupKbn(confirmKbn.ruleImport);
    } else {
      ruleImportOpen();
    }
  };
  const ruleImportOpen = () => {
    setRuleImportActivated(true);
  };
  const handleOnRuleImportClose = () => {
    setRuleImportActivated(false);
    initialOnGet(initialUrl(rulePattern));
  };

  // エクスポート
  const [ruleExportActivated, setRuleExportActivated] = React.useState(false);
  const handleOnExportClick = () => {
    if (
      ref.current != null &&
      checkEditedViewModel({ ...viewModel, RuleItems: ref.current.props.itemsSource }, oldViewModel, 0)
    ) {
      // 未保存の行が存在する
      setConfirmMessage(message.Common_Confirmation_Edited_Excel);
      setConfirmPopupKbn(confirmKbn.ruleExport);
    } else {
      ruleExportOpen();
    }
  };
  const ruleExportOpen = () => {
    setRuleExportActivated(true);
  };
  const handleOnRuleExportClose = () => {
    setRuleExportActivated(false);
  };

  // ビューモデルの変更チェック
  const checkEditedViewModel = (
    newVM: MonthlyBalanceRuleSettingViewModel,
    oldVM: MonthlyBalanceRuleSettingViewModel,
    kbn: number
  ) => {
    let result = true;
    if (
      (newVM.RulePatternName === oldVM.RulePatternName && // パターン名称が同じ
       newVM.RulePatternDefaultSettingFlg === oldVM.RulePatternDefaultSettingFlg)  // 標準に設定が同じ
      || kbn == 1
    ) {
      if (
        (newVM.RuleItems == null || newVM.RuleItems.length === 0) &&
        (oldVM.RuleItems == null || oldVM.RuleItems.length === 0)
      ) {
        // 両方ともルールが存在しない場合は変更なし
        result = false;
      } else if (
        newVM.RuleItems != null &&
        newVM.RuleItems.length > 0 &&
        oldVM.RuleItems != null &&
        oldVM.RuleItems.length > 0
      ) {
        // 両方ともルールが存在する場合は一覧データをチェック
        let newEqualOld = true;
        for (let i = 0; i < newVM.RuleItems.length; i++) {
          for (const key in newVM.RuleItems[i]) {
            if (newVM.RuleItems[i][key] != oldVM.RuleItems[i][key]) {
              newEqualOld = false;
              break;
            }
          }
          if (!newEqualOld) {
            break;
          }
        }
        if (newEqualOld) {
          result = false;
        }
      }
    }
    return result;
  };

  // ビューモデルの変更行取得
  const getEditedViewModel = (
        newVM: MonthlyBalanceRuleSettingViewModel,
        oldVM: MonthlyBalanceRuleSettingViewModel,
  ) => {
    let modRuleItems = Array<MonthlyBalanceRuleItemVO>();
    let cnt = 0;
    if (
        newVM.RuleItems != null &&
        newVM.RuleItems.length > 0 &&
        oldVM.RuleItems != null &&
        oldVM.RuleItems.length > 0
    ) {
        // 両方ともルールが存在する場合は一覧データをチェック
        for (let i = 0; i < newVM.RuleItems.length; i++) {
          for (const key in newVM.RuleItems[i]) {
            if (newVM.RuleItems[i][key] != oldVM.RuleItems[i][key]) {
              modRuleItems[cnt] = newVM.RuleItems[i];
              cnt++;
              break;
            }
          }
        }
    }
    return modRuleItems;
  };

  const gridInnerRef = React.useRef<any>();
  const gridNextTabRef = React.useRef<any>();

  /** キーボード操作（キーダウン） */
  const handleGrigKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 9) {
      if (event.shiftKey === false) {
        // タブキー押下時
        gridNextTabRef.current.focus();
        event.preventDefault();
        event.stopPropagation();
      }
    }
  };

  React.useEffect(() => {
    if (viewModel.RuleItems != undefined && viewModel.RuleItems.length > 0) {
      // 画面表示完了後に実行
      setTimeout(() => {
        if (gridInnerRef.current) {
          // gridにキーダウンイベントのフック
          gridInnerRef.current.addEventListener('keydown', handleGrigKeyDown);
        }

        // WijmoのコンボボックスにTabIndexを設定してもTab遷移がうまくいかない為
        // コンボボックス内のテキストボックスにTabIndexを設定する
        const rulePatternCmbElement = document.getElementById(rulePatternCmbId);
        if (rulePatternCmbElement) {
          rulePatternCmbElement.tabIndex = -1;
          const textElement = rulePatternCmbElement.getElementsByTagName('input');
          const elements = Array.from(textElement);
          for (let item of elements) {
            if (item.type === 'text') {
              item.tabIndex = 4;
              break;
            }
          }
        }
      });
    }
  }, [viewModel.RuleItems]);

  React.useEffect(() => {
    // 標準パターンが変更されたらコンボボックスの文言を変更
    if (rulePatternCmb) {
      rulePatternCmb.collectionView.beginUpdate();

      //パターンリストの内容を更新する
      rulePatternMod(rulePatternCmb);
      rulePatternCmb.collectionView.endUpdate();
    }
  }, [viewModel.DefaultRulePattern, viewModel.DefaultRulePatternBmn]);

  // パターンリスト更新
  const rulePatternMod = (rulePatternCmb: wjInput.ComboBox) => {
    rulePatternCmb.collectionView.sourceCollection.forEach((item: typeof dataMaps.RulePatternEnum[0]) => {
      item.value = getValueFromDataMap(dataMaps.RulePatternEnum, item.key);

      var ptnItem = viewModel.RulePatternItems?.find(f => f.RulePatternKbn == item.key);

      if (item.key === viewModel.DefaultRulePattern || item.key === viewModel.DefaultRulePatternBmn) {
            item.value += labels.AIKADT001000003_INPUT_NAME_DEFAULT;  //(標準)
      } else {
        item.value += "　　　　　　";
      }
      if (ptnItem?.AuditTarget == MasterKbn.CountingKmk) {
        item.value += " :" + labels.AIKCMN0010000013_LABEL_SUBJECTS;  //:科目
      }
      if (ptnItem?.AuditTarget == MasterKbn.Bmn) {
        item.value += " :" + labels.AIKCMN0010000013_LABEL_SECTION; //:部門
      }
    });
  }

  React.useEffect(() => {
    if (typeof tmpRulePattern !== 'undefined') {
      // パターンが変更された場合
      if (
        ref.current != null &&
        checkEditedViewModel({ ...viewModel, RuleItems: ref.current.props.itemsSource }, oldViewModel, 0)
      ) {
        // 画面項目に変更がある場合は確認ポップアップを表示
        setConfirmMessage(message.MonthlyBalanceCheck_Confirmation_Edited_RulePatternkbnChanged);
        setConfirmPopupKbn(confirmKbn.rulePattern);
      } else {
        // 画面項目に変更がない場合はパターンの変更を確定
        setTmpRulePattern(undefined);
        setRulePattern(tmpRulePattern);
      }
    }
  }, [tmpRulePattern]);

  React.useEffect(() => {
    const rulePatternName =
      typeof oldViewModel.RulePatternName === 'undefined' || oldViewModel.RulePatternName == ''
        ? getValueFromDataMap(dataMaps.RulePatternEnum, rulePattern)
        : oldViewModel.RulePatternName;

    setExcelServiceParams(
      JSON.stringify({
        RulePattern: rulePattern,
        RulePatternName: rulePatternName,
        AuditTarget: auditTarget.target
      })
    );
    setExcelheaderLeft(rulePatternName);
  }, [rulePattern, oldViewModel]);

  const getTemplateId = (a: number) => {
    if (a === MasterKbn.Bmn) {
      return constants.AIKADT001000003E02;
    } else {
      return constants.AIKADT001000003E01;
    }
  };

  const getRuleItems = (vm: MonthlyBalanceRuleSettingViewModel) => (vm.RuleItems == undefined ? [] : vm.RuleItems);

  // 削除確認のダイアログ表示
  const handleOnDeleteClick = () => {
    setConfirmMessage(bindValueToMessage(message.MonthlyBalanceCheck_Confirmation_Delete_RulePatternDelete, [rulePattern.toString()]));
    setConfirmPopupKbn(confirmKbn.delete);
  }

  // 削除処理の通信処理実行
  const execDelete = () => {
      setCommunicating(true);
      setDblCheckFlg(true);
      setIsLoading(true);
      Axios.delete(deleteUrl(rulePattern))
          .then(response => { JudgeOnPost(JudgeURL, 'AIKADT001000003'); })
      .catch(error => {
        const { message } = error.response.data;
        setAlertMessage(message);
        setIsLoading(false);
      })
      .finally(() => {
        setCommunicating(false);
        setIniAuditTarget(defaultAuditTarget);
        setIsLoading(false);
      });
  };

  //監査対象の値が変更された時
  const handleOnTransAuditTarget = (e: AuditTargetManagerVo) => {
    let vm = new MonthlyBalanceRuleSettingViewModel();
    //値変更チェック(未保存メッセージ表示用チェック)
    if (tmpAuditTarget?.target != e.target &&  //監査対象の値が変わった場合
      !dblCheckFlg &&       //パターン区分変更時の値変更チェック済は対象外
      initialFlg == false &&
      ref.current != null &&
      checkEditedViewModel({ ...viewModel, RuleItems: ref.current.props.itemsSource }, oldViewModel, 1)
    ) {
      // 画面項目に変更がある場合は確認ポップアップを表示
      setConfirmMessage(message.MonthlyBalanceCheck_Confirmation_Edited_RulePatternkbnChanged);
      setConfirmPopupKbn(confirmKbn.auditTarget);
    } else {
      vm = viewModel;
      switch (e.target) {
        case MasterKbn.Bmn:
          vm.AuditTarget = MasterKbn.Bmn;
          vm.RuleItems = vm.RuleItemBmns;
          updateViewModel(vm);
          break;
        case MasterKbn.CountingKmk:
        default:
          vm.AuditTarget = MasterKbn.CountingKmk;
          vm.RuleItems = vm.RuleItemKmks;
          updateViewModel(vm);
          break;
      }
      if (auditTargetCancelflg == true) {
        //未保存メッセージ(はい)
      } else {
        //未保存メッセージ(いいえ)
        updateOldViewModel(deepCopyViewModel(vm));
        setTmpAuditTarget(e);
      }
    }
    setAuditTarget(e);
    //setIniAuditTarget(e);
    setDblCheckFlg(false);
    setInitialFlg(false);
  };

  // 画面レイアウト
  return iniAuditTarget == undefined || iniAuditTarget == null ? null : (
    <div className='monthly-balance-setting'>
      <LowerActionLayout className='vw-100'>
        <LowerActionLayoutHeader>
          <PageHeader
            pageTitle={labels.AIKADT001000003_FUNCTION_NAME}
            excelButtonTabIndex={9}
            onExcelClick={handleOnExcelClick}
            importButtonTabIndex={10}
            onImportClick={handleOnImportClick}
            exportButtonTabIndex={11}
            onExportClick={handleOnExportClick}
            closeButtonTabIndex={12}
            onCloseClick={handleOnCloseClick}
          />
        </LowerActionLayoutHeader>
        <LowerActionLayoutContents className='vw-100 p-3'>
          <div className='mb-3 d-flex flex-nowrap'>
            <ComboBox
              id={rulePatternCmbId}
              className='rulePatternkbn'
              itemsSource={rulePatternItemsSource}
              displayMemberPath={'value'}
              selectedValuePath={'key'}
              selectedValue={rulePattern}
              initialized={rulePatternCmbinItialized}
              onSelectedIndexChanged={handleSelectedIndexChangedRulePattern}
              isDisabled={communicating}
            />
            <Input
              type='text'
              className='rulePatternName ml-3'
              name={'RulePatternName'}
              value={viewModel.RulePatternName}
              onChange={handleOnRulePatternNameInputChange}
              disabled={communicating}
              maxLength={25}
              tabIndex={5}
            />
            <FormGroup check className='ml-3 d-flex align-items-center mb-0'>
              <Label check>
                <Input
                  type='checkbox'
                  name='Rule'
                  checked={viewModel.RulePatternDefaultSettingFlg}
                  disabled={communicating}
                  tabIndex={6}
                  onChange={handleOnRulePatternDefaultChange}
                />
                {labels.AIKADT001000003_INPUT_HEADER_DEFAULTSETTING}
              </Label>
            </FormGroup>
            <Button
              color='primary'
              className='ml-3 mr-1 A3-btn'
              onClick={handleOnDeleteClick}
              tabIndex={7}
              disabled={communicating}
            >
              {labels.COMMON_BUTTON_FUNCTION_DELETE}
            </Button>
          </div>
            <div className='mb-3 d-flex flex-nowrap'>
              <AuditTargetManager
                name={'MonthlyBalanceSetting-AuditTarget'}
                defaultSelectItem={iniAuditTarget}
                transmitSelectItems={handleOnTransAuditTarget}
                transmitBmnList={(e) => { }}
                disabled={auditDisabled}
                tabIndex={8}
              />
            </div>
          <MonthlyBalanceSelectGrid
            gridClassName='MonthlyBalanceSetting-grid'
            columns={gridBmnColumns}
            selectionMode={SelectionMode.Cell}
            headerMerges={headers}
            selectAllBinding={selectAllBinding}
            imeEnabledBinding={imeEnabledBinding}
            sources={getRuleItems(viewModel)}
            frozenColumns={2}
            sortLabelMapList={sortLabelMapList}
            tabIndex={1}
            innerRef={gridInnerRef}
            gridRef={ref}
            onFilterChanged={handleOnFilterChanged}
            auditTarget={auditTarget?.target ?? MasterKbn.CountingKmk}
          />
        </LowerActionLayoutContents>
        <LowerActionLayoutAction className='px-3 pb-3'>
          <div className={'d-flex justify-content-between'}>
            <div className={'ml-auto text-red'}>{chkMessage}</div>
            <div className={'ml-4'}>
              <ExecutingCancel
                executeLabel={labels.COMMON_BUTTON_FUNCTION_SAVE}
                executeDisabled={communicating}
                executeTabIndex={2}
                executeRef={gridNextTabRef}
                onExecuteClick={handleOnExecuteClick}
                cancelLabel={labels.COMMON_BUTTON_FUNCTION_CANCEL}
                cancelDisabled={communicating}
                cancelTabIndex={3}
                onCancelClick={handleOnCancelClick}
              />
            </div>
          </div>
        </LowerActionLayoutAction>
      </LowerActionLayout>
      <ExcelOutputsetting
        activated={excelPopupActivated}
        templateId={getTemplateId(auditTarget?.target ?? MasterKbn.CountingKmk)}
        serviceParams={excelServiceParams}
        headerLeft={excelheaderLeft}
        filters={filters}
        onClose={handleExcelOnClose}
      />
      <ConfirmationPopup
        isOpen={confirmActivated}
        onClose={handleConfirmOnClose}
        onYesClick={handleConfirmOnYesClick}
        onNoClick={handleConfirmOnNoClick}
      >
        <span className='white-space-pre-wrap'>{confirmMessage}</span>
      </ConfirmationPopup>
      <RuleImport activated={ruleImportActivated} onClose={handleOnRuleImportClose} />
      <RuleExport activated={ruleExportActivated} onClose={handleOnRuleExportClose} />
    </div>
  );
};

/** 比較値のDataMapを取得 */
const getCmpValueKbnDataMap = () => {
  const cmpValueKbnDataMap = createWjDataMap(dataMaps.CmpValueEnum, true);
  cmpValueKbnDataMap.getDisplayValues = (dataItem: MonthlyBalanceRuleItemVO) => {
    switch (dataItem.CmpTMonthKbn) {
      case enums.CmpTMonthEnum.BeginningBalance:
        return ['', getValueFromDataMap(dataMaps.CmpValueEnum, enums.CmpValueEnum.Balance)];
      default:
        return cmpValueKbnDataMap.collectionView.items.map(e => e.value);
    }
  };
  return cmpValueKbnDataMap;
};

const sortLabelMapList: sortLabel[] = [
  {
    binding: 'CmpTMonthKbn',
    map: enumSortLabel.concat(dataMaps.CmpTMonthEnum)
  },
  {
    binding: 'CmpValueKbn',
    map: enumSortLabel.concat(dataMaps.CmpValueEnum)
  },
  {
    binding: 'SkiSign',
    map: enumSortLabel.concat(dataMaps.SkiSignEnum)
  },
  {
    binding: 'RidKmkFlg',
    map: wjCheckboxMap
  },
  {
    binding: 'RidHojyoFlg',
    map: wjCheckboxMap
  },
  {
    binding: 'NBKmkFlg',
    map: wjCheckboxMap
  },
  {
    binding: 'NBHojyoFlg',
    map: wjCheckboxMap
  }
];

const gridColumns = [
  {
    header: labels.AIKADT001000003_GRID_HEADER_CODE,
    binding: 'KmkCode',
    isReadOnly: true,
    align: 'center',
    width: 80,
    format: 'd',
    allowMerging: true
  },
  {
    header: labels.AIKADT001000003_GRID_HEADER_SUBJECTS,
    binding: 'KmkName',
    isReadOnly: true,
    align: 'left',
    width: '*',
    minWidth: 150,
    allowMerging: true
  },
  {
    header: labels.AIKADT001000003_GRID_HEADER_ACCOUNT,
    binding: 'RidKmkFlg',
    isReadOnly: false,
    align: 'center',
    width: 60
  },
  {
    header: labels.AIKADT001000003_GRID_HEADER_ASSIST,
    binding: 'RidHojyoFlg',
    isReadOnly: false,
    align: 'center',
    width: 80,
      inputType: 'SubDsp'
  },
  {
    header: labels.AIKADT001000003_GRID_HEADER_COMPARISONMONTH,
    binding: 'CmpTMonthKbn',
    isReadOnly: false,
    align: 'left',
    width: 150,
    dataMap: createWjDataMap(dataMaps.CmpTMonthEnum, true),
    isRequired: false
  },
  {
    header: labels.AIKADT001000003_GRID_HEADER_COMPARISONVALUE,
    binding: 'CmpValueKbn',
    isReadOnly: false,
    align: 'left',
    width: 135,
    dataMap: getCmpValueKbnDataMap(),
    isRequired: false
  },
  {
    header: labels.AIKADT001000003_GRID_HEADER_SIGN,
    binding: 'SkiSign',
    isReadOnly: false,
    align: 'center',
    width: 60,
    dataMap: createWjDataMap(dataMaps.SkiSignEnum, true),
    isRequired: false
  },
  {
    header: labels.AIKADT001000003_GRID_HEADER_THRESHOLDVALUE,
    binding: 'SkiValue',
    isReadOnly: false,
    align: 'right',
    width: 120,
    format: 'f2',
    isRequired: false,
    dataType: wj.DataType.Number,
    inputType: 'percent'
  },
  {
    header: labels.AIKADT001000003_GRID_HEADER_THRESHOLDVALUEMONEY,
    binding: 'SkiValueSum',
    isReadOnly: false,
    align: 'right',
    width: 175,
    isRequired: false,
    dataType: wj.DataType.Number
  },
  {
    header: labels.AIKADT001000003_GRID_HEADER_ACCOUNT,
    binding: 'NBKmkFlg',
    isReadOnly: false,
    align: 'center',
    width: 80
  },
  {
    header: labels.AIKADT001000003_GRID_HEADER_ASSIST,
    binding: 'NBHojyoFlg',
    isReadOnly: false,
    align: 'center',
    width: 80,
    inputType: 'SubDsp'
  }
];

const gridBmnColumns = [
    {
        header: labels.AIKADT001000003_GRID_HEADER_CODE,
        binding: 'KmkGCode',
        isReadOnly: true,
        align: 'center',
        width: 80,
        format: 'd',
        allowMerging: true
    },
    {
        header: labels.AIKADT001000003_GRID_HEADER_SUBJECTS,
        binding: 'KmkName',
        isReadOnly: true,
        align: 'left',
        width: '*',
        minWidth: 150,
        allowMerging: true
    },
    {
        header: labels.AIKADT001000003_GRID_HEADER_BMNCODE,
        binding: 'HojyoGCode',
        isReadOnly: true,
        align: 'center',
        width: 130,
        format: 'd',
        allowMerging: true
    },
    {
        header: " " + labels.AIKADT001000003_GRID_HEADER_BMNCODE + " ",
        binding: 'HojyoGCodeNum',
        isReadOnly: true,
        align: 'center',
        width: 130,
        format: 'd',
        allowMerging: true
    },
    {
        header: labels.AIKADT001000003_GRID_HEADER_BMNSUBJECTS,
        binding: 'HojyoName',
        isReadOnly: true,
        align: 'left',
        width: '*',
        minWidth: 150,
        allowMerging: true
    },
    {
        header: labels.AIKADT001000003_GRID_HEADER_ACCOUNTRECRUIT,
        binding: 'RidKmkFlg',
        isReadOnly: false,
        align: 'center',
        width: 60
    },
    {
        header: labels.AIKADT001000003_GRID_HEADER_ASSIST,
        binding: 'RidHojyoFlg',
        isReadOnly: false,
        align: 'center',
        width: 80,
        inputType: 'SubDsp'
    },
    {
        header: labels.AIKADT001000003_GRID_HEADER_COMPARISONMONTH,
        binding: 'CmpTMonthKbn',
        isReadOnly: false,
        align: 'left',
        width: 150,
        dataMap: createWjDataMap(dataMaps.CmpTMonthEnum, true),
        isRequired: false
    },
    {
        header: labels.AIKADT001000003_GRID_HEADER_COMPARISONVALUE,
        binding: 'CmpValueKbn',
        isReadOnly: false,
        align: 'left',
        width: 135,
        dataMap: getCmpValueKbnDataMap(),
        isRequired: false
    },
    {
        header: labels.AIKADT001000003_GRID_HEADER_SIGN,
        binding: 'SkiSign',
        isReadOnly: false,
        align: 'center',
        width: 60,
        dataMap: createWjDataMap(dataMaps.SkiSignEnum, true),
        isRequired: false
    },
    {
        header: labels.AIKADT001000003_GRID_HEADER_THRESHOLDVALUE,
        binding: 'SkiValue',
        isReadOnly: false,
        align: 'right',
        width: 120,
        format: 'f2',
        isRequired: false,
        dataType: wj.DataType.Number,
        inputType: 'percent'
    },
    {
        header: labels.AIKADT001000003_GRID_HEADER_THRESHOLDVALUEMONEY,
        binding: 'SkiValueSum',
        isReadOnly: false,
        align: 'right',
        width: 175,
        isRequired: false,
        dataType: wj.DataType.Number
    },
    {
        header: labels.AIKADT001000003_GRID_HEADER_ACCOUNTRECRUIT,
        binding: 'NBKmkFlg',
        isReadOnly: false,
        align: 'center',
        width: 80
    },
    {
        header: labels.AIKADT001000003_GRID_HEADER_ASSIST,
        binding: 'NBHojyoFlg',
        isReadOnly: false,
        align: 'center',
        width: 80,
        inputType: 'SubDsp'
    }
];

const headers = [
  {
    header: labels.AIKADT001000003_GRID_HEADER_REMARKABLEINCREASEANDDECREASE,
    binding: ['RidKmkFlg', 'RidHojyoFlg', 'CmpTMonthKbn', 'CmpValueKbn', 'SkiSign', 'SkiValue', 'SkiValueSum']
  },
  {
    header: labels.AIKADT001000003_GRID_HEADER_MINUSBALANCE,
    binding: ['NBKmkFlg', 'NBHojyoFlg']
  }
];

const selectAllBinding = ['NBKmkFlg', 'NBHojyoFlg'];
const imeEnabledBinding = ['CmpTMonthKbn', 'CmpValueKbn', 'SkiSign'];

export default MonthlyBalanceSetting;
