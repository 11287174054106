export class Util {
    public static isUndefined(obj: any): boolean {
        return (typeof obj) === 'undefined';
    }

    public static isNull(value: any): boolean {
        if (value == 'undefined' || value == null || value == "") {
            return true;
        } else {
            return false;
        }
    }
}
