import React from 'react';
import Axios from 'axios';

import * as fileUtils from '../../../../utils/fileUtils';
import * as labels from '../../../../constants/labels';
import * as message from '../../../../constants/message';
import * as constant from '../../../../constants/constant';
import { BatStatusEnum } from '../../../../constants/enums';
import { PROGRESSINTERVAL } from '../../../../constants/constant';

// コンポーネント
import Popup from '../../../../components/molecules/CMN/Popup/Popup';
import ExecutingCancel from '../../../../components/molecules/CMN/ExecutingCancel/ExecutingCancel';
import Spinner from '../../../../components/atoms/Icon/Spinner';

// Model
import { OutputInformationViewModel } from '../../../../models/outputInformationViewModel';

type RuleExportProps = {
  activated: boolean;
  onClose: () => void;
};

// URL
// Excel出力と同じURLを使用
const baseUrl = '/api/v1/ExcelOutputPreference';
export const createUrl = [baseUrl, 'Create'].join('/');
export const fileResultUrl = [baseUrl, 'FileResult'].join('/');
export const fileUrl = [baseUrl, 'File'].join('/');

// tabIndexの初期値
const baseTabIndex = 1000;

const RuleExport: React.FC<RuleExportProps> = props => {
  const [isPrinting, setIsPrinting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  /** 出力ボタン押下時の処理 */
  const handleOnPrintClick = () => {
    setIsPrinting(true);
    const vm: OutputInformationViewModel = {
      TemplateId: constant.AIKCMN001000005E02,
      PassFlg: false,
      FilterFlg: false,
      ClientCodeFlg: false,
      ClientNameFlg: false
    };
    createOnPost(createUrl, vm);
  };

  /** Excel帳票作成処理を実行 */
  const createOnPost = (url: string, vm: OutputInformationViewModel) => {
    Axios.post<string>(url, { outputInformationViewModel: vm })
      .then(response => {
        fileResultOnPost(fileResultUrl, response.data);
      })
      .catch(error => {
        setIsPrinting(false);
        setErrorMessage(error.response.data.message);
      });
  };

  /** バッチの進捗確認を実行 */
  const fileResultOnPost = (url: string, guid: string) => {
    const encodedUrl = [url, encodeURI(guid.toString())].join('/');
    Axios.post<BatStatusEnum>(encodedUrl)
      .then(response => {
        switch (response.data) {
          case BatStatusEnum.Processing:
            window.setTimeout(fileResultOnPost, PROGRESSINTERVAL, url, guid);
            break;
          case BatStatusEnum.Success:
            fileOnPost(fileUrl, guid);
            break;
          default:
            setErrorMessage(message.Common_Error_InternalServerError);
            setIsPrinting(false);
            break;
        }
      })
      .catch(error => {
        setIsPrinting(false);
        setErrorMessage(error.response.data.message);
      });
  };

  /** ファイルダウンロードを実行 */
  const fileOnPost = (url: string, guid: string) => {
    const encodedUrl = [url, encodeURI(guid.toString())].join('/');
    Axios.get(encodedUrl, { responseType: 'blob' })
      .then(response => {
        if (response.data != undefined) {
          // Excelファイルをダウンロードさせる
          fileUtils.downloadFileFromResponse(response, true);
        }
        // ポップアップを閉じる
        props.onClose();
      })
      .catch(error => {
        setIsPrinting(false);
        setErrorMessage(error.response.data.message);
      });
  };

  /** 閉じるボタン押下時の処理 */
  const handleOnCloseClick = () => {
    if (!isPrinting) {
      props.onClose();
    }
  };

  const firstFocusElement = React.useRef<any>();
  const lastFocusElement = React.useRef<any>();
  const popupRef = React.useRef<any>();

  /** キーボード操作（キーダウン） */
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 9) {
      if (event.shiftKey === false) {
        // タブキー押下時

        const target: HTMLElement = event.target as HTMLElement;

        if (target.tabIndex === lastFocusElement.current.tabIndex) {
          // 最後のフォーカスエレメントの場合

          // 最初のエレメントにフォーカス
          firstFocusElement.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        } else if (
          target.tabIndex < firstFocusElement.current.tabIndex ||
          target.tabIndex > lastFocusElement.current.tabIndex
        ) {
          // フォーカスが当たっていない場合

          // 最初のエレメントにフォーカス
          firstFocusElement.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
      } else {
        // シフト＋タブキー押下時

        const target: HTMLElement = event.target as HTMLElement;
        if (target.tabIndex === firstFocusElement.current.tabIndex) {
          // 最後のフォーカスエレメントの場合

          // 最後のエレメントにフォーカス
          lastFocusElement.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        } else if (
          target.tabIndex < firstFocusElement.current.tabIndex ||
          target.tabIndex > lastFocusElement.current.tabIndex
        ) {
          // フォーカスが当たっていない場合

          // 最後のエレメントにフォーカス
          lastFocusElement.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }
  };

  /** 画面が表示された実行する処理 */
  React.useEffect(() => {
    if (props.activated) {
      setIsPrinting(false);
      setErrorMessage('');
      // 画面表示完了後に実行
      setTimeout(() => {
        if (popupRef.current) {
          // ポップアップにキーダウンイベントのフック
          popupRef.current.addEventListener('keydown', handleKeyDown);
        }
      });
    }
  }, [props.activated]);

  const printingLabel = isPrinting ? (
    <span>
      <span className='_spinner _rotation mr-2'>
        <Spinner className='icon-lg' />
      </span>
      {labels.AIKCMN001000005_FUNCTION_NAME_INOUTPUT}
    </span>
  ) : (
    <span className='text-danger'>{errorMessage}</span>
  );

  return (
    <Popup
      className='rule-export'
      isOpen={props.activated}
      escClose={!isPrinting}
      onCloseClick={handleOnCloseClick}
      header={labels.AIKCMN001000005_EXPORT_HEADER_NAME}
      size='sm'
      closeIconTabIndex={baseTabIndex + 3}
      closeIconRef={lastFocusElement}
      innerRef={popupRef}
    >
      <div className='mb-3'>{message.RuleExport_Information_Message}</div>
      <div className='d-flex justify-content-between'>
        <div>{printingLabel}</div>
        <div>
          <ExecutingCancel
            className='text-nowrap'
            executeLabel={labels.AIKCMN001000005_BUTTON_FUNCTION_OUTPUT}
            onExecuteClick={handleOnPrintClick}
            executeDisabled={isPrinting}
            executeRef={firstFocusElement}
            executeTabIndex={baseTabIndex + 1}
            cancelLabel={labels.AIKCMN001000005_BUTTON_FUNCTION_CLOSE}
            onCancelClick={handleOnCloseClick}
            cancelDisabled={isPrinting}
            cancelTabIndex={baseTabIndex + 2}
          />
        </div>
      </div>
    </Popup>
  );
};
export default RuleExport;
