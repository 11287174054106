import { DataModelBase } from "../core/models/dataModelBase";

export abstract class MasterDataContainerBase {

    private innerTargetYear: number;
    private innerVersion: Date;

    constructor(targetYear: number, version: Date) {
        this.innerTargetYear = targetYear;
        this.innerVersion = version;
    }

    /**
    * データの年度を取得する
    */
    public get targetYear(): number {
        return this.innerTargetYear;
    }

    /**
    * データのバージョンを取得する
    */
    public get version(): Date {
        return this.innerVersion;
    }
}

/**
 * マスターデータモデル群を格納し、共通の振る舞いを提供するクラス
 */
export abstract class MasterDataContainer<T extends DataModelBase> extends MasterDataContainerBase {

    private innerModels: Array<T>;

    constructor(models: Array<T>, targetYear: number, version: Date) {
        super(targetYear, version);
        this.innerModels = models;
    }

    /**
     * モデル群を取得する
     */
    public get models(): Array<T> {
        return this.innerModels;
    }

    public set models(val: Array<T>) {
        this.innerModels = val;
    }
}
