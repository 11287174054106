import React from 'react';
import axios from 'axios';

import { Button, FormGroup, Label, Input } from 'reactstrap';
import InputTextArea from '../../../../components/atoms/InputTextArea/InputTextArea';
import Popup from '../../../../components/molecules/CMN/Popup/Popup';
import AngleCircleFrameLine from '../../../../components/atoms/AngleCircleFrameLine/AngleCircleFrameLine';
import IconButton from '../../../../components/molecules/CMN/IconButton/IconButton';
import Close from '../../../../components/atoms/Icon/Close';
import FileExcel from '../../../../components/atoms/Icon/FileExcel';
import CommentSlash from '../../../../components/atoms/Icon/CommentSlash';
import CommentDots from '../../../../components/atoms/Icon/CommentDots';
import JournalLineGrid from '../../../../components/molecules/CMN/Grid/JournalLineGrid';
import ExcelOutputsetting from '../../../organisms/A000/ExcelOutputsetting';
import * as labels from '../../../../constants/labels';
import * as constans from '../../../../constants/constant';
import { AuditTypeEnum, ActivateScreenEnum } from '../../../../constants/enums';
import { ConfStatusEnum } from '../../../../constants/enums';
import { JournalLineListViewModel } from '../../../../models/journalLineListViewModel';
import { setAlertMessage, AlertKbnEnum } from '../../../../stores/AlertMessage';
import { setIsLoading } from '../../../../stores/NowLoading';
import { JournalLineVO } from '../../../../models/journalLineVO';
import { compareObject } from '../../../../utils/objectUtils';
// WebApiUrl
export const InitialRemarkIncDecUrl = '/api/v1/JournalLineRemarkIncDec/Initial';       // 仕訳明細[著増減]
export const InitialNegativeBalanceUrl = '/api/v1/JournalLineNegativeBalance/Initial'; // 仕訳明細[マイナス残高]
export const InitialJournalLineUrl = '/api/v1/JournalLineJournalLine/Initial';         // 仕訳明細[仕訳明細]
export const InitialJournalDoubleUrl = '/api/v1/JournalLineJournalDouble/Initial';     // 仕訳明細[仕訳重複]
export const InitialTaxKubunUrl = '/api/v1/JournalLineTaxKubun/Initial';               // 仕訳明細[消費税区分]

export const InitialExpansionDataRemarkIncDecUrl = '/api/v1/JournalLineRemarkIncDec/InitialExpansionData';       // 仕訳明細[著増減]
export const InitialExpansionDataNegativeBalanceUrl = '/api/v1/JournalLineNegativeBalance/InitialExpansionData'; // 仕訳明細[マイナス残高]
export const InitialExpansionDataJournalLineUrl = '/api/v1/JournalLineJournalLine/InitialExpansionData';         // 仕訳明細[仕訳明細]
export const InitialExpansionDataJournalDoubleUrl = '/api/v1/JournalLineJournalDouble/InitialExpansionData';     // 仕訳明細[仕訳重複]
export const InitialExpansionDataTaxKubunUrl = '/api/v1/JournalLineTaxKubun/InitialExpansionData';               // 仕訳明細[消費税区分]

//確認ステータス更新用***************************************************************
export const ConfirmationRemarkIncDecUrl = '/api/v1/JournalLineRemarkIncDec/Confirmation';       // 仕訳明細[著増減]
export const ConfirmationNegativeBalanceUrl = '/api/v1/JournalLineNegativeBalance/Confirmation'; // 仕訳明細[マイナス残高]
export const ConfirmationJournalLineUrl = '/api/v1/JournalLineJournalLine/Confirmation';         // 仕訳明細[仕訳明細]
export const ConfirmationJournalDoubleUrl = '/api/v1/JournalLineJournalDouble/Confirmation';     // 仕訳明細[仕訳重複]
export const ConfirmationTaxKubunUrl = '/api/v1/JournalLineTaxKubun/Confirmation';               // 仕訳明細[消費税区分]
//*****************************************************************************

//コメント更新用***************************************************************
export const CommentRemarkIncDecUrl = '/api/v1/JournalLineRemarkIncDec/Comment';       // 仕訳明細[著増減]
export const CommentNegativeBalanceUrl = '/api/v1/JournalLineNegativeBalance/Comment'; // 仕訳明細[マイナス残高]
export const CommentJournalLineUrl = '/api/v1/JournalLineJournalLine/Comment';         // 仕訳明細[仕訳明細]
export const CommentJournalDoubleUrl = '/api/v1/JournalLineJournalDouble/Comment';     // 仕訳明細[仕訳重複]
export const CommentTaxKubunUrl = '/api/v1/JournalLineTaxKubun/Comment';               // 仕訳明細[消費税区分]
//*****************************************************************************

// ステータス・コメント表示領域の調整数
const FIX_HEIGHT = 4;

// 幅サイズ
const PopupSize = 'xl';

type JournalLineprops = {
  activated: boolean;
  auditType: AuditTypeEnum;
  nMonth: number;
  stNMonth: number;
  edNMonth: number;
  seqNo: number[];
  rowNo: number;
  onClose: (isRefresh: boolean) => void;
  // 科目コード
  kmkCode?: number;
  // 遷移元画面
  activateScreenType?: ActivateScreenEnum;
};
const initializationViewModel: JournalLineListViewModel = {
  NMonth: '',
  RowNo: undefined,
  ConfStatus: undefined,
  AuditMess: '',
  CmpTMonth: '',
  CmpSum: undefined,
  InpSum: undefined,
  SrchNo: undefined,
  Comment: undefined,
  JournalLines: []
};

// tabIndexの初期値
const baseTabIndex = 300;

const confirmRadios = [
  { label: labels.AIKADT002000002_BUTTON_FUNCTION_CONFIRMATIONOK, value: ConfStatusEnum.Complete },
  { label: labels.AIKADT002000002_BUTTON_FUNCTION_CONFIRMATIONNG, value: ConfStatusEnum.Admitted },
  { label: labels.AIKADT002000002_BUTTON_FUNCTION_PENDING, value: ConfStatusEnum.Pending }
];

const JournalLine: React.FC<JournalLineprops> = props => {
  const { activated, onClose, auditType, nMonth, stNMonth, edNMonth, seqNo, rowNo, kmkCode, activateScreenType } = props;
  const [excelPopupActivated, setExcelPopupActivated] = React.useState(false);
  const [excelPopupServiceParams, setExcelPopupServiceParams] = React.useState('');
  const [viewModel, setViewModel] = React.useState<JournalLineListViewModel>(initializationViewModel);
  const [isCommentChange, setIsCommentChange] = React.useState(false);
  const [commentIconFlg, setCommentIconFlg] = React.useState(
    viewModel.Comment == undefined || viewModel.Comment === ''
  );
  const [confirmDisabled, setConfirmDisabled] = React.useState(false);
  const [parentRefresh, setParentRefresh] = React.useState(false);
  const [comment, setComment] = React.useState(viewModel.Comment);
  const [backdropClassNameIE, setBackdropClassNameIE] = React.useState('');
  const [selectNmonth, setSelectNmonth] = React.useState(0);
  const handleOnClose = () => {
    setViewModel(new JournalLineListViewModel());
    onClose(parentRefresh);
    setParentRefresh(false);
  };
  const handleOnExcelClick = () => {
    var dSrchNoList: number[] = [];
    //var auditMessList: { [key: string]: string; } = {}
    if (viewModel.JournalLines != undefined) {
      viewModel.JournalLines!.forEach((v) => {
        if (v.DSrchNo != undefined) {
          dSrchNoList.push(v.DSrchNo);
      //    auditMessList[v.DSrchNo] = v.AuditMess == undefined ? '' : v.AuditMess;
        }
      });
      } 
    if (props.activateScreenType! != ActivateScreenEnum.JounalLine) {
      // 通常モード向けExcel出力パラメータ生成。
      setExcelPopupServiceParams(
        JSON.stringify({
          nMonth: nMonth,
          seqNo: seqNo[0],
          rowNo: rowNo,
          stNMonth: stNMonth,
          edNMonth: edNMonth,
          kmkCode: kmkCode
          })
        );
      } else {
        // 通常モード向けExcel出力パラメータ生成。
        setExcelPopupServiceParams(
          JSON.stringify({
            nMonth: nMonth,
            seqNo: seqNo,
            rowNo: rowNo,
            stNMonth: stNMonth,
            edNMonth: edNMonth,
            kmkCode: kmkCode
          })
        );
      }
    
    setExcelPopupActivated(true);
  };
  const handleExcelOnClose = () => {
    setExcelPopupActivated(false);
  };

  const closeButtonElement = React.useRef<any>();
  const excelIconFocusElement = React.useRef<any>();
  const closeIconFocusElement = React.useRef<any>();
  const popupRef = React.useRef<any>();
  const journalLineGridRef = React.useRef<any>();
  const gridComponentRef = React.useRef<any>();
  const elementRefList: React.MutableRefObject<any>[] = [];
  elementRefList.push(closeButtonElement);
  if (props.activateScreenType! != ActivateScreenEnum.JounalLine) {
    elementRefList.push(excelIconFocusElement);
  } else {
    elementRefList.push(journalLineGridRef);
  }
  elementRefList.push(closeIconFocusElement);

  /** キーボード操作（キーダウン） */
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 9) {
      if (event.shiftKey === false) {
        // タブキー押下時

        const target: HTMLElement = event.target as HTMLElement;

        // tabindexの昇順でソート
        elementRefList.sort(
          (a: React.MutableRefObject<any>, b: React.MutableRefObject<any>) =>
            (a.current.tabIndex || a.current.control.hostElement.tabIndex) - (b.current.tabIndex || b.current.control.hostElement.tabIndex)
        );

        // フォーカスされているエレメントを検索
        const elementIndex = elementRefList.indexOf(
          elementRefList.filter(item => (item.current.tabIndex || item.current.control.hostElement.tabIndex) === target.tabIndex)[0]
        );
        if (elementIndex < 0 || elementIndex + 1 >= elementRefList.length) {
          // フォーカスが当たっていない又は最後のエレメントの場合は最初のエレメントをフォーカス
          elementRefList[0].current.focus ? elementRefList[0].current.focus() : elementRefList[0].current.control.hostElement.focus();
        } else {
          // 次のエレメントにフォーカス
          elementRefList[elementIndex + 1].current.focus ? elementRefList[elementIndex + 1].current.focus() : elementRefList[elementIndex + 1].current.control.hostElement.focus();
        }
        // 後のイベントをキャンセル
        event.preventDefault();
        event.stopPropagation();
      } else {
        // シフト＋タブキー押下時

        const target: HTMLElement = event.target as HTMLElement;
        // tabindexの降順でソート
        elementRefList.sort(
          (a: React.MutableRefObject<any>, b: React.MutableRefObject<any>) =>
            (b.current.tabIndex || b.current.control.hostElement.tabIndex) - (a.current.tabIndex || a.current.control.hostElement.tabIndex)
        );
        // フォーカスされているエレメントを検索
        const elementIndex = elementRefList.indexOf(
          elementRefList.filter(item => item.current.tabIndex === target.tabIndex)[0]
        );
        if (elementIndex < 0 || elementIndex + 1 >= elementRefList.length) {
          // フォーカスが当たっていない又は最初のエレメントの場合は最後のエレメントをフォーカス
          elementRefList[0].current.focus ? elementRefList[0].current.focus() : elementRefList[0].current.control.hostElement.focus();
        } else {
          // 次のエレメントにフォーカス
          elementRefList[elementIndex + 1].current.focus ? elementRefList[elementIndex + 1].current.focus() : elementRefList[elementIndex + 1].current.control.hostElement.focus();
        }

        // 後のイベントをキャンセル
        event.preventDefault();
        event.stopPropagation();
      }
    }
  };
  /**
   * 表示切替ボタン押下時の処理
   */
  const displaySwitchBtnClick = () => {
    gridComponentRef.current.switchHeader();
  };

  React.useEffect(() => {
    if (activated && viewModel.JournalLines != undefined && viewModel.JournalLines.length > 0) {
      // 画面表示完了後に実行
      setTimeout(() => {
        if (popupRef.current) {
          // ポップアップにキーダウンイベントのフック
          popupRef.current.addEventListener('keydown', handleKeyDown);
        }
        if (activateScreenType == ActivateScreenEnum.JounalLine) {
          // 直接表示時は明細にフォーカスをセット
          journalLineGridRef.current.control.hostElement.focus();
        }

        // IEの為の実装
        if (backdropClassNameIE !== '') {
          const elements = document.getElementsByClassName('popup-wrap-JournalLines');
          if (elements.length > 0) {
            const element = (elements[0] as HTMLDivElement).parentNode as HTMLDivElement;
            element.style.position = '';
            setTimeout(() => {
              element.style.position = 'relative';
              setBackdropClassNameIE('');
            }, 500);
          }
        }
      });
    }
  }, [viewModel.JournalLines]);

    var selectionChangeFunc = (selectionVo: JournalLineVO) => {
    // テキストエリアのフォーカスアウトより先に走る可能性がある
    // SelectionChangeでデータが確定するより先にテキストエリアのフォーカスアウトが処理するように対応
    handleOnCommentBlur();
    if (props.activateScreenType == ActivateScreenEnum.JounalLine) {
      // インスタンスを新規で用意する
      var updateNewViewModel = viewModel;
      // 明細直接起動の場合は内容を更新
      updateNewViewModel.AuditMess = selectionVo.AuditMess;
      updateNewViewModel.ConfStatus = selectionVo.ConfStatus;
      updateNewViewModel.Comment = selectionVo.Comment || '';
      // 金額を更新
      updateNewViewModel.InpSum = selectionVo.InpSum;
      updateNewViewModel.CmpSum = selectionVo.CmpSum;
      viewModel.RowNo = selectionVo.RowNo;
      setSelectNmonth(selectionVo.NMonth!);
      // 以下監査種類ごとの切り替え内容更新
      // viewModel更新更新(監査メッセージ、監査ステータス再描画)
      setViewModel({ ...updateNewViewModel });
      // コメントアイコン更新
      setCommentIconFlg(updateNewViewModel.Comment === '');
      setComment(updateNewViewModel.Comment);
      setConfirmDisabled(false);
    }
  };

  //通信処理(初期表示データ作成)
  const initialOnGet = (url: string, stNMonth: number, edNMonth: number, SeqNo: number, RowNo: number) => {
    const encodedUrl = [url, encodeURI(stNMonth.toString()), encodeURI(edNMonth.toString())].join('/');
    setIsLoading(true);
    // パラメータを追加
    axios
      .post<JournalLineListViewModel>(encodedUrl, { seqNo: SeqNo, rowNo: RowNo })
      .then(response => {
        setIsLoading(false);
        setViewModel(response.data);
      })
      .catch(error => {
        setIsLoading(false);
        if (typeof error.response.data !== 'undefined') {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
        }
        handleOnClose();
      });
  };

  //通信処理(初期表示データ作成（情報拡張版）)
  const initialExpansionDataOnPost = (url: string, stNMonth: number, edNMonth: number, seqNo: number[], kmkCode: number, activateScreenType: ActivateScreenEnum) => {
    const encodedUrl = [url, encodeURI(stNMonth.toString()), encodeURI(edNMonth.toString())].join('/');
    setIsLoading(true);
    // パラメータを追加
    axios
      .post<JournalLineListViewModel>(encodedUrl, { seqNo: seqNo, kmkCode: kmkCode, activateScreenType: activateScreenType }) // Postパラメータ設定しつつリクエスト送信
      .then(response => {
        setIsLoading(false);
          setViewModel(response.data);
      })
      .catch(error => {
        setIsLoading(false);
        if (typeof error.response.data !== 'undefined') {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
        }
        handleOnClose();
      });
  };

  const { auditNm, initialUrl, templateId, initialExpansionDataUrl } = getConstantsEachAuditType(auditType, props.activateScreenType);
  // 通信処理の実行
  React.useEffect(() => {
    // 遷移元を判別して、実行するURLをコントロールできるようにする
    if (activated) {
      // 表示クリア
      setViewModel(initializationViewModel);
      // 以下初期表示用パラメータ取得
      var activateScreen = ActivateScreenEnum.Result;
      // 確認用で一旦蓋するマン
      if (activateScreenType) {
        activateScreen = activateScreenType;
      }
      if (activateScreen == ActivateScreenEnum.JounalLine) {
        // 遷移先画面が仕訳明細を指定した場合、監査実行・結果表示画面から直接起動向け拡張されたデータを取得できるようにする
        initialExpansionDataOnPost(initialExpansionDataUrl, stNMonth, edNMonth, seqNo, kmkCode!, ActivateScreenEnum.JounalLine);
      } else {
        // 従来の処理
        initialOnGet(initialUrl, stNMonth, edNMonth, seqNo[0], rowNo);
      }
    }
  }, [initialUrl, nMonth, stNMonth, edNMonth, seqNo, rowNo, activated, kmkCode, activateScreenType]);

  // IEの為の実装(第二引数のURLはRender毎に処理を実行させない為の引数であり内容は何でもよい。今回は著増減のInitialを設定。)
  React.useEffect(() => {
    setBackdropClassNameIE('popup-backdrop-JournalLineJItem-IE');
  }, [InitialRemarkIncDecUrl]);

  const { confStatusNm } = getConstantsEachConfStatus(viewModel.ConfStatus, props.activateScreenType);

  // 監査情報
  const auditInfo: React.ReactElement[] = [];
  if (auditType === AuditTypeEnum.RemarkIncDecAudit) {
    // 著増減監査の場合に表示
    auditInfo.push(
      // 残高
      <div key='comparisonmonth'>
        <strong>
          {viewModel.CmpTMonth}
          {labels.AIKADT002000004_FUNCTION_HEADER_COMPARISONMONTH}
        </strong>
        <div className='pl-3'>{priceFormat(viewModel.CmpSum)}</div>
      </div>
    );
    auditInfo.push(
      // 当月残高
      <div key='currentmonthbalance'>
        <strong>{labels.AIKADT002000004_FUNCTION_HEADER_CURRENTMONTHBALANCE}</strong>
        <div className='pl-3'>{priceFormat(viewModel.InpSum)}</div>
      </div>
    );
  } else if (auditType === AuditTypeEnum.NegativeBalanceAudit) {
    // マイナス残高監査の場合に表示
    auditInfo.push(
      // 当月残高
      <div key='currentmonthbalance'>
        <strong>{labels.AIKADT002000004_FUNCTION_HEADER_CURRENTMONTHBALANCE}</strong>
        <div className='pl-3'>{priceFormat(viewModel.InpSum)}</div>
      </div>
    );
  }
  //ステータス更新URL決定
  const GetConfUrl = (auditType: AuditTypeEnum) => {
    switch (auditType) {
      case AuditTypeEnum.RemarkIncDecAudit:
        return ConfirmationRemarkIncDecUrl;
      case AuditTypeEnum.NegativeBalanceAudit:
        return ConfirmationNegativeBalanceUrl;
      case AuditTypeEnum.JournalLineAudit:
        return ConfirmationJournalLineUrl;
      case AuditTypeEnum.JournalDoubleAudit:
        return ConfirmationJournalDoubleUrl;
      case AuditTypeEnum.TaxKubunAudit:
        return ConfirmationTaxKubunUrl;
      default:
        return '';
    }
  };
  //コメント更新URL決定
  const GetCommentUrl = (auditType: AuditTypeEnum) => {
    switch (auditType) {
      case AuditTypeEnum.RemarkIncDecAudit:
        return CommentRemarkIncDecUrl;
      case AuditTypeEnum.NegativeBalanceAudit:
        return CommentNegativeBalanceUrl;
      case AuditTypeEnum.JournalLineAudit:
        return CommentJournalLineUrl;
      case AuditTypeEnum.JournalDoubleAudit:
        return CommentJournalDoubleUrl;
      case AuditTypeEnum.TaxKubunAudit:
        return CommentTaxKubunUrl;
      default:
        return '';
    }
  };
  //ステータス更新WebApi
  const updateStatus = (url: string, data: Array<any>) => {
    setConfirmDisabled(true);
    axios
      .post(url, { data: data})
      .then(response => {
        setParentRefresh(true);
      })
      .catch(error => {
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
      })
      .finally(() => {
        setConfirmDisabled(false);
        // ラジオボタンのフォーカスを戻す
        const confirmRadioList = document.getElementsByName(['confirm', rowNo].join('_'));
        if (confirmRadioList) {
          for (let i = 0; i < confirmRadioList.length; i++) {
            const confirmRadio = confirmRadioList[i] as HTMLInputElement;
            if (confirmRadio.checked === true) {
              confirmRadio.focus();
              break;
            }
          }
        }
      });
  };
  //コメント更新WebApi
  const updateComment = (url: string, data: Array<any>) => {
    axios
      .post(url, { data: data })
      .catch(error => {
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
      });
  };

  const handleOnCommentInputCapture = (e: React.ChangeEvent<HTMLTextAreaElement>) => {    
    var txt = e.target.value || '';
    if (viewModel.JournalLines && viewModel.RowNo !== undefined) {
      viewModel.Comment = txt;
      setCommentIconFlg(txt === '');
      }
    setIsCommentChange(true);
    setViewModel({ ...viewModel });
  };
  const comentIcon = commentIconFlg ? (
    <span className='nocomment'>
      <CommentSlash />
    </span>
  ) : (
      <CommentDots />
    );
  const confirmRadiosList: React.ReactElement[] = [];
  const commentTextArea: React.ReactElement[] = [];
  const updateArea: React.ReactElement[] = [];
  const handleOnConfirmChange = (confStatus: ConfStatusEnum) => {
    viewModel.ConfStatus = confStatus;
    var updateList:Array<any> = [];
    if (viewModel.JournalLines && viewModel.RowNo !== undefined) {
      var rowCnt = -1;
      var rowCntList: number[] = [];
      viewModel.JournalLines.forEach((r) => {
        rowCnt++;
        if (viewModel.RowNo == r.RowNo && selectNmonth == r.NMonth) {
          rowCntList.push(rowCnt);
        }
      });
      rowCntList.forEach((rowNum) => {
        viewModel.JournalLines![rowNum].ConfStatus = confStatus;
        var dupeRowNo = viewModel.JournalLines![rowNum].DupeRowNoList;
        seqNo.forEach((no) => {
          // 更新データ
          var updateForSeq = {
            nMonth: viewModel.JournalLines![rowNum].NMonth!,
            seqNo: no,
            rowNo: viewModel.RowNo!,
            confirmationStatus: confStatus,
          };
          // 重複データは更新しない
          if (!isIncludeInArray(updateList, updateForSeq)) {
            updateList.push(updateForSeq);
          }
          if (dupeRowNo != undefined && dupeRowNo.length > 0) {
            dupeRowNo.forEach((n) => {
              if (viewModel.JournalLines![rowNum].RowNo != n) {
                var updateForRow = {
                  nMonth: viewModel.JournalLines![rowNum].NMonth!,
                  seqNo: no,
                  rowNo: n,
                  confirmationStatus: confStatus,
                };
                if (!isIncludeInArray(updateList, updateForRow)) {
                  updateList.push(updateForRow);
                }
              }
            });
          }
        });
      });
    }
    updateStatus(GetConfUrl(auditType), updateList);
    setViewModel({ ...viewModel });    
  };
  const handleOnCommentBlur = (e?: React.ChangeEvent<HTMLTextAreaElement>) => {
    // グリッド変更時にSelectionChangeが先に走る可能性がある
    // SelectionChangeで呼べるように引数をnull許容型にする
    var txt = e != undefined ? e.target.value : viewModel.Comment;
    var updateList: Array<any> = [];
    if (comment !== txt && isCommentChange) {
      if (seqNo != undefined && viewModel.RowNo != undefined && viewModel.NMonth != undefined) {
        viewModel.Comment = txt;
        if (viewModel.JournalLines && viewModel.RowNo !== undefined) {
          var rowCnt = -1;
          var rowCntList: number[] = [];
          viewModel.JournalLines.forEach((r) => {
            rowCnt++;
            if (viewModel.RowNo == r.RowNo && selectNmonth == r.NMonth) {
              rowCntList.push(rowCnt);
            }  
          });
          rowCntList.forEach((rowNum) => {
            viewModel.JournalLines![rowNum].Comment = viewModel.Comment;
            var dupeRowNo = viewModel.JournalLines![rowNum].DupeRowNoList;
            seqNo.forEach((no) => {
              // 更新データ
              var updateForSeq = {
                nMonth: viewModel.JournalLines![rowNum].NMonth!,
                seqNo: no,
                rowNo: viewModel.RowNo!,
                comment: viewModel.Comment || ''
              };
              // 重複データは更新しない
              if (!isIncludeInArray(updateList, updateForSeq)) {
                updateList.push(updateForSeq);
              }
              if (dupeRowNo != undefined && dupeRowNo.length > 0) {
                dupeRowNo.forEach((n) => {
                  if (viewModel.JournalLines![rowNum].RowNo != n) {
                    // 更新データ
                    var updateForRow = {
                      nMonth: viewModel.JournalLines![rowNum].NMonth!,
                      seqNo: no,
                      rowNo: n,
                      comment: viewModel.Comment || ''
                    };
                    if (!isIncludeInArray(updateList, updateForRow)) {
                      updateList.push(updateForRow);
                    }
                  }
                });
              }
            });
          });
        }
      }
    }
    updateComment(GetCommentUrl(auditType), updateList);
    setIsCommentChange(false);
    setViewModel({ ...viewModel });
  };
  if (activateScreenType == ActivateScreenEnum.JounalLine) {
    confirmRadios.forEach(confirm => {
      confirmRadiosList.push(
        <FormGroup check className='mr-3' key={['confirm', confirm.value].join('_')}>
          <Label check className='text-nowrap'>
            <Input
              type='radio'
              disabled={confirmDisabled}
              name={['confirm', viewModel.NMonth, viewModel.RowNo].join('_')}
              checked={confirmRadioChecked(confirm.value, viewModel.ConfStatus!)}
              onChange={() => handleOnConfirmChange(confirm.value)}
            />
            <span className={['text', String(ConfStatusEnum[confirm.value])].join('-')}>{confirm.label}</span>
          </Label>
          </FormGroup>        
      );
    });
    commentTextArea.push(
      <div className='mb-1'>
        <div className='message-comment-label' style={{ width: '100px' }}>
          <strong>コメント</strong>
          <span className='ml-1'>{comentIcon}</span>
        </div>         
        <InputTextArea
          name={'AuditMessageComment'}
          enable={{
            top: false,
            right: false,
            bottom: true,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          className='message-comment-textarea'
          placeholder='コメントを入力してください'
          rows={3}
          maxLength={200}
          value={viewModel.Comment}
          defaultValue={viewModel.Comment}
          onInputCapture={handleOnCommentInputCapture}
          onBlur={handleOnCommentBlur}
        />
      </div>
    );
    updateArea.push(
      <div>
        <div>
            <strong>ステータス</strong>
          </div>
        <div className='retult-item-confirm'>
          <AngleCircleFrameLine className='border-dashed pl-3 pt-1 pb-1 d-flex'>
            {confirmRadiosList}
          </AngleCircleFrameLine>
        </div>
        {commentTextArea}
      </div>
    );
  }
  const heightKbnClass = ['-auditInfoPlas', auditInfo.length + updateArea.length * FIX_HEIGHT].join('');

  const jlHeaderIcons: React.ReactElement[] = [];
  jlHeaderIcons.push(
    <IconButton
      key={'popup-header-excel-button'}
      icon={<FileExcel className='icon-lg' />}
      className='ml-2 text-white'
      onClick={handleOnExcelClick}
      tabIndex={baseTabIndex + 2}
      innerRef={excelIconFocusElement} />
  );
  jlHeaderIcons.push(
    <IconButton
      key={'popup-header-close-button'}
      icon={<Close className='icon-lg' />}
      className='ml-2 text-white'
      onClick={handleOnClose}
      tabIndex={baseTabIndex + 3}
      innerRef={closeIconFocusElement} />
  );
    return viewModel.JournalLines == undefined || viewModel.JournalLines.length < 1 ? null : (
        <Popup
            id='JournalLine-popup'
            wrapClassName='popup-wrap-JournalLines'
            backdropClassName={backdropClassNameIE}
            isOpen={activated}
            onCloseClick={handleOnClose}
            size={PopupSize}
            innerRef={popupRef}
            headerColor={String(ConfStatusEnum[viewModel.ConfStatus!])}
            header={[
                labels.AIKADT002000004_FUNCTION_NAME,
                labels.AIKADT002000004_FUNCTION_HEADER_TARGETMONTH + viewModel.NMonth,
                confStatusNm
            ].join('　　　')}
            headerIcon={
                <div>
                    {jlHeaderIcons}
                </div>
            }
        footer={
          <div className='w-100'>
            <Button
              id='display-switch-btn'
              className='A3-btn'
              color='secondary'
              onClick={displaySwitchBtnClick}
            >
              {labels.AIKADT002000004_BUTTON_FUNCTION_DISPLAYSWITCH}
            </Button>
            <Button
              className='A3-btn'
              color='secondary'
              tabIndex={baseTabIndex + 4}
              onClick={handleOnClose}
              innerRef={closeButtonElement}
            >
              {labels.COMMON_BUTTON_FUNCTION_CLOSE}
            </Button>
          </div>
      }
    >
      <span className={heightKbnClass}>
        <AngleCircleFrameLine className='-auditInfo overflow-auto mb-3 px-3 py-1'>
          <div>
            <strong>{labels.AIKADT002000004_FUNCTION_HEADER_AUDITTYPE}</strong>
          </div>
          <div className='pl-3'>{auditNm}</div>
          {auditInfo}
          <div>
            <strong>{labels.AIKADT002000004_FUNCTION_HEADER_AUDITMESSAGE}</strong>
          </div>
          <div className='pl-3 white-space-pre-wrap'>{viewModel.AuditMess}</div>
          {updateArea}
        </AngleCircleFrameLine>
          <JournalLineGrid gridClassName='JournalLine-grid'
            sources={viewModel.JournalLines}
            onSelectionChanged={selectionChangeFunc}
            activateScreenType={props.activateScreenType}
            innerRef={journalLineGridRef}
            tabIndex={baseTabIndex + 1}
            ref={gridComponentRef}
          />
          <ExcelOutputsetting
            activated={excelPopupActivated}
            templateId={templateId}
            serviceParams={excelPopupServiceParams}
            onClose={handleExcelOnClose}
          />
        </span>
    </Popup>
  );
};

const priceFormat = (price?: number) => {
  let ret = '';
  if (typeof price === 'number') {
    ret = [price.toLocaleString(), '円'].join('');
  }
  return ret;
};
/**
 * AuditTypeEnum毎の定数を返します。
 * @param auditType AuditTypeEnum
 */
const getConstantsEachAuditType = (auditType: AuditTypeEnum, activeScreenType?: ActivateScreenEnum) => {
  let auditNm = '';                 // 監査種類名称
  let initialUrl = '';              // WebApiUrl(初期処理)
  let initialExpansionDataUrl = ''; // WebApiUrl初期表示データ作成（情報拡張版）
  let templateId = '';              // ExcelテンプレートID

  switch (auditType) {
    case AuditTypeEnum.RemarkIncDecAudit:
      // 著増減監査
      auditNm = labels.AIKADT002000004_FUNCTION_HEADER_REMARKINCDECAUDIT;
      initialUrl = InitialRemarkIncDecUrl;
      initialExpansionDataUrl = InitialExpansionDataRemarkIncDecUrl;
      templateId = activeScreenType == ActivateScreenEnum.Result ? constans.AIKADT002000004E01 : constans.AIKADT002000004E06;
      break;
    case AuditTypeEnum.NegativeBalanceAudit:
      // マイナス残高監査
      auditNm = labels.AIKADT002000004_FUNCTION_HEADER_NEGATIVEBALANCEAUDIT;
      initialUrl = InitialNegativeBalanceUrl;
      initialExpansionDataUrl = InitialExpansionDataNegativeBalanceUrl;
      templateId = activeScreenType == ActivateScreenEnum.Result ? constans.AIKADT002000004E02 : constans.AIKADT002000004E07;
      break;
    case AuditTypeEnum.JournalLineAudit:
      // 仕訳明細監査
      auditNm = labels.AIKADT002000004_FUNCTION_HEADER_JOURNALLINEAUDIT;
      initialUrl = InitialJournalLineUrl;
      initialExpansionDataUrl = InitialExpansionDataJournalLineUrl;
      templateId = activeScreenType == ActivateScreenEnum.Result ? constans.AIKADT002000004E03 : constans.AIKADT002000004E08;
      break;
    case AuditTypeEnum.JournalDoubleAudit:
      // 仕訳重複監査
      auditNm = labels.AIKADT002000004_FUNCTION_HEADER_JOURNALDOUBLEAUDIT;
      initialUrl = InitialJournalDoubleUrl;
      initialExpansionDataUrl = InitialExpansionDataJournalDoubleUrl;
      templateId = activeScreenType == ActivateScreenEnum.Result ? constans.AIKADT002000004E04 : constans.AIKADT002000004E09;
      break;
    case AuditTypeEnum.TaxKubunAudit:
      // 消費税区分監査
      auditNm = labels.AIKADT002000004_FUNCTION_HEADER_TAXKUBUNAUDIT;
      initialUrl = InitialTaxKubunUrl;
      initialExpansionDataUrl = InitialExpansionDataTaxKubunUrl;
      templateId = activeScreenType == ActivateScreenEnum.Result ? constans.AIKADT002000004E05 : constans.AIKADT002000004E10;
      break;
  }
  return { auditNm, initialUrl, templateId, initialExpansionDataUrl };
};

/**
 * ConfStatusEnum毎の定数を返します。
 * @param confStatus ConfStatusEnum
 */
const getConstantsEachConfStatus = (confStatus?: ConfStatusEnum, activeScreenType?: ActivateScreenEnum) => {
  let confStatusNm = ''; // 確認ステータス名称
  if (activeScreenType! == ActivateScreenEnum.JounalLine) {
      return { confStatusNm };
  }
  switch (confStatus) {
    case ConfStatusEnum.Auditable:
      // 未確認
      confStatusNm = labels.AIKADT002000004_FUNCTION_HEADER_CONFIRMATIONSTATUS + labels.AIKADT002000004_FUNCTION_HEADER_UNCONFIRMED;
      break;
    case ConfStatusEnum.Admitted:
      // 確認NG
      confStatusNm = labels.AIKADT002000004_FUNCTION_HEADER_CONFIRMATIONSTATUS + labels.AIKADT002000004_FUNCTION_HEADER_CONFIRMATIONNG;
      break;
    case ConfStatusEnum.Complete:
      // 確認OK
      confStatusNm = labels.AIKADT002000004_FUNCTION_HEADER_CONFIRMATIONSTATUS + labels.AIKADT002000004_FUNCTION_HEADER_CONFIRMATIONOK;
      break;
    case ConfStatusEnum.Pending:
      // 保留
      confStatusNm = labels.AIKADT002000004_FUNCTION_HEADER_CONFIRMATIONSTATUS + labels.AIKADT002000004_FUNCTION_HEADER_PENDING;
      break;
  }
  return { confStatusNm };
};
export default JournalLine;
const confirmRadioChecked = (value: ConfStatusEnum, target: ConfStatusEnum) => value === target;
// 配列中にデータが存在するか判定する処理
const isIncludeInArray = (a: Array<any>, b: any) => {
  return a.some(i => {
    return compareObject(i, b);
  });
}