export class AntiForgeryToken {
    public static tokenValue: string;

    /**
     * リクエストヘッダーに偽造防止トークン情報を追加します。
     * @param data JQueryXHR
     */
    public static setHeader(jqXHR: JQueryXHR): void {
        jqXHR.setRequestHeader("__RequestVerificationToken", AntiForgeryToken.tokenValue);
    }
}
