import React, { RefObject, useEffect, useRef } from 'react';

import * as wjInput from 'wijmo/wijmo.react.input';
import * as wjGrid from 'wijmo/wijmo.react.grid';
import * as wjGridFilter from 'wijmo/wijmo.react.grid.filter';

type _ExplorerMigraionTaxKbnPartialProps = {
  scope: any, 
  taxKbnExprolerRef: RefObject<any>
};

const _ExplorerMigraionTaxKbnPartial: React.FC<_ExplorerMigraionTaxKbnPartialProps> = props => {

    const taxKbnExplorerRef = useRef<any>();
    let componentDidMount = false;

    useEffect(() => {
        if(!componentDidMount && props.scope && props.scope.taxKbnExprolerGridInitialized && props.scope.taxKbnExprolerGridInitialized) {
            props.scope.taxKbnExprolerGridInitialized(taxKbnExplorerRef.current.control);
            componentDidMount = true;
        }
    }, [props.scope] );

    return (
      <>
<wjInput.Popup
          showTrigger="None"
          hidTrigger="Blur"
          ref={props.taxKbnExprolerRef}
          showing={props.scope && props.scope.taxKbnExprolerShowing}
          shown={props.scope && props.scope.taxKbnExprolerShown}
          hiding={props.scope && props.scope.taxKbnExprolerHiding}
          hidden={props.scope && props.scope.taxKbnExprolerHidden}
          className="migration">

    <div style={{margin: "2px"}}>
        <wjGrid.FlexGrid id="taxKbnExproler"
                      ref={taxKbnExplorerRef}
                      allowResizing="Columns"
                      allowSorting={true}
                      cellEditEnded={props.scope && props.scope.taxKbnExprolerGridCellEditEnded}
                      headersVisibility="Column"
                      itemsSource={props.scope && props.scope.taxKbnExprolerItemSource}
                      itemFormatter={props.scope && props.scope.taxKbnExprolerItemFormatter}
                      selectionMode="Row"
                      style={{height:"310px", minHeight:"310px"}}>
            <wjGridFilter.FlexGridFilter filterColumns={null}></wjGridFilter.FlexGridFilter>
            {/* <wj-flex-grid-column header="" binding="selected" align="center" width={50} isReadOnly={false}></wj-flex-grid-column> */}
            <wjGrid.FlexGridColumn header="" binding="code" align="left" width={0} isReadOnly={true}></wjGrid.FlexGridColumn>
            <wjGrid.FlexGridColumn header="種類" binding="name" align="left" width={70} isReadOnly={true}></wjGrid.FlexGridColumn>
            <wjGrid.FlexGridColumn header="消費税区分" binding="association" align="left" width={250} isReadOnly={true}></wjGrid.FlexGridColumn>
        </wjGrid.FlexGrid>
        </div>
        <div>
        <div className="pull-left" style={{margin:"0 0 4px 2px"}} hidden={props.scope && props.scope.taxKbnExprolerIsSingleSelect}>
            <button type="button" className="btn btn-success" onClick={props.scope && props.scope.taxKbnExprolerSelectAll}>全選択</button>
            <button type="button" className="btn btn-danger" onClick={props.scope && props.scope.taxKbnExprolerCancelSelect}>全解除</button>
        </div>
        <div className="pull-right" style={{margin:"0 2px 6px 0"}}>
            <button type="button" className="btn btn-primary" onClick={props.scope && props.scope.taxKbnExprolerSelected}>選択</button>
            <button type="button" className="btn btn-default wj-hide" onClick={props.scope && props.scope.taxKbnExprolerCanceles}>キャンセル</button>
        </div>
    </div>
</wjInput.Popup>
      </>
    )
};
export default _ExplorerMigraionTaxKbnPartial;