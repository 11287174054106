import React from 'react';
import classNames from 'classnames';

import * as labels from '../../../constants/labels';
import IconButton from '../CMN/IconButton/IconButton';

import FileAlt from '../../atoms/Icon/FileAlt';
import Insert from '../../atoms/Icon/Insert';
import Delete from '../../atoms/Icon/Delete';
import ExcelButton from '../../atoms/Icon/FileExcel';
import Close from '../../atoms/Icon/Close';

export type PageHeaderAccountRegisterProps = React.HTMLAttributes<HTMLDivElement> & {
  pageTitle?: string;

  createButtonDisabled?: boolean;
  insertButtonDisabled?: boolean;
  deleteButtonDisabled?: boolean;
  excelButtonDisabled?: boolean;
  closeButtonDisabled?: boolean;

  createButtonTabIndex?: number;
  insertButtonTabIndex?: number;
  deleteButtonTabIndex?: number;
  excelButtonTabIndex?: number;
  closeButtonTabIndex?: number;

  createButtonRef?: React.RefObject<any>;
  insertButtonRef?: React.RefObject<any>;
  deleteButtonRef?: React.RefObject<any>;
  excelButtonRef?: React.RefObject<any>;
  closeButtonRef?: React.RefObject<any>;

  onCreateClick?: () => void;
  onInsertClick?: () => void;
  onDeleteClick?: () => void;
  onExcelClick?: () => void;
  onCloseClick?: () => void;
};

const iconButtonClass = '-icon mx-1';
const iconClass = '';
const centerClass = 'd-flex align-items-center';

const PageHeaderAccountRegister: React.FC<PageHeaderAccountRegisterProps> = props => {
  const {
    pageTitle,

    createButtonDisabled,
    insertButtonDisabled,
    deleteButtonDisabled,
    excelButtonDisabled,
    closeButtonDisabled,

    createButtonTabIndex,
    insertButtonTabIndex,
    deleteButtonTabIndex,
    excelButtonTabIndex,
    closeButtonTabIndex,

    createButtonRef,
    insertButtonRef,
    deleteButtonRef,
    excelButtonRef,
    closeButtonRef,

    onCreateClick,
    onInsertClick,
    onDeleteClick,
    onExcelClick,
    onCloseClick,

    className,
    children
  } = props;

  const iconList = [];
  if (typeof onCreateClick === 'function') {
    iconList.push(
      <IconButton
        key='FileAlt'
        data-testid='create-icon'
        onClick={onCreateClick}
        disabled={createButtonDisabled}
        icon={<FileAlt className={iconClass} />}
        className={iconButtonClass}
        tabIndex={createButtonTabIndex}
        innerRef={createButtonRef}
      >
        {labels.COMMON_BUTTON_FUNCTION_NEW}
      </IconButton>
    );
  }
  if (typeof onInsertClick === 'function') {
    iconList.push(
      <IconButton
        key='Insert'
        data-testid='Insert-icon'
        onClick={onInsertClick}
        disabled={insertButtonDisabled}
        icon={<Insert className={iconClass} />}
        className={iconButtonClass}
        tabIndex={insertButtonTabIndex}
        innerRef={insertButtonRef}
      >
        {labels.COMMON_BUTTON_FUNCTION_INSERT}
      </IconButton>
    );
  }
  if (typeof onDeleteClick === 'function') {
    iconList.push(
      <IconButton
        key='Delete'
        data-testid='Delete-icon'
        onClick={onDeleteClick}
        disabled={deleteButtonDisabled}
        icon={<Delete className={iconClass} />}
        className={iconButtonClass}
        tabIndex={deleteButtonTabIndex}
        innerRef={deleteButtonRef}
      >
        {labels.COMMON_BUTTON_FUNCTION_DELETE}
      </IconButton>
    );
  }
  if (typeof onExcelClick === 'function') {
    iconList.push(
      <IconButton
        key='Excel'
        data-testid='excel-icon'
        onClick={onExcelClick}
        disabled={excelButtonDisabled}
        icon={<ExcelButton className={iconClass} />}
        className={iconButtonClass}
        tabIndex={excelButtonTabIndex}
        innerRef={excelButtonRef}
      >
        {labels.COMMON_BUTTON_FUNCTION_EXCEL}
      </IconButton>
    );
  }
  if (typeof onCloseClick === 'function') {
    iconList.push(
      <IconButton
        key='Close'
        data-testid='close-icon'
        onClick={onCloseClick}
        disabled={closeButtonDisabled}
        icon={<Close className={iconClass} />}
        className={iconButtonClass}
        tabIndex={closeButtonTabIndex}
        innerRef={closeButtonRef}
      >
        {labels.COMMON_BUTTON_FUNCTION_CLOSE}
      </IconButton>
    );
  }

  // divタグに不要なpropsを除去
  const divProps = { ...props };
  delete divProps['pageTitle'];
  delete divProps['createButtonTabIndex'];
  delete divProps['createButtonDisabled'];
  delete divProps['createButtonRef'];
  delete divProps['onCreateClick'];

  delete divProps['insertButtonDisabled'];
  delete divProps['insertButtonTabIndex'];
  delete divProps['insertButtonRef'];
  delete divProps['onInsertClick'];

  delete divProps['deleteButtonDisabled'];
  delete divProps['deleteButtonTabIndex'];
  delete divProps['deleteButtonRef'];
  delete divProps['onDeleteClick'];

  delete divProps['excelButtonTabIndex'];
  delete divProps['excelButtonDisabled'];
  delete divProps['excelButtonRef'];
  delete divProps['onExcelClick'];

  delete divProps['closeButtonTabIndex'];
  delete divProps['closeButtonDisabled'];
  delete divProps['closeButtonRef'];
  delete divProps['onCloseClick'];

  return (
    <div {...divProps} className={classNames(className, 'd-flex page-header')}>
      <div className={classNames(centerClass, 'ml-2 my-0 page-header-title text-nowrap text-lbl h5')}>{pageTitle}</div>
      <div className={classNames(centerClass, 'ml-2 page-header-header flex-grow-1 justify-content-start')}>{children}</div>
      <div className={classNames(centerClass, 'ml-auto page-header-icon')}>{iconList}</div>
    </div>
  );
};
export default PageHeaderAccountRegister;
