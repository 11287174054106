/**
 * 日付を指定されてフォーマットで返します。
 * 年：yyyy
 * 月：MM
 * 日：dd
 * 時：hh
 * 分：mm
 * 秒：ss
 * @param date 変換する日付
 * @param format フォーマット
 */
export const format = (date: Date, format: string) => {
  let result = format;
  result = result.replace('yyyy', leadingZero(String(date.getFullYear()), 4));
  result = result.replace('MM', leadingZero(String(date.getMonth() + 1), 2));
  result = result.replace('dd', leadingZero(String(date.getDate()), 2));
  result = result.replace('hh', leadingZero(String(date.getHours()), 2));
  result = result.replace('mm', leadingZero(String(date.getMinutes()), 2));
  result = result.replace('dd', leadingZero(String(date.getSeconds()), 2));
  return result;
};

/**
 * 変換文字を前ゼロして返します。
 * @param str 変換文字
 * @param length 桁数
 */
export const leadingZero = (str: string, length: number) => {
  let pre = '';
  for (let i = 0; i < length; i++) {
    pre += '0';
  }
  return (pre + str).substr(-length);
};
