/**
 * 担当者ViewModel ViewModel クラス
 */

import { IdNameList } from "./idNameList";

export class UserViewModel {
    /** ID名前リストVO */
    public IdNameList?:Array<IdNameList>;

    /** 担当者コード */
    public UserCode?:number;

    /** ログインID */
    public LoginID?:string;

    /** パスワード(ハッシュ) */
    public PasswordHash?:string;

    /** 担当者名 */
    public UserName?:string;

    /** 担当者（姓） */
    public LastName?:string;

    /** 担当者（名） */
    public FirstName?:string;

    /** 担当者カナ（姓） */
    public LastNameKana?:string;

    /** 担当者カナ（名） */
    public FirstNameKana?:string;

    /** メールアドレス */
    public Email?:string;

    /** ログイン有効 */
    public LoginEnabled?:boolean;

    /** 権限 */
    public Authority?:boolean;

}
