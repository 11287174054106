import { Ajax, AjaxSettings } from "../../core/ajax";
import { UnReadCountsViewModel } from "../../models/printing/unReadCountsViewModel";
import { PageInitializeParameter } from "../../pageController";
import { HeaderBase } from "./headerBase";
import $ from 'jquery';

/**
 * システムヘッダー管理クラス
 */
export class SystemHeader extends HeaderBase {

    //TODO:ポーリング処理は見送りの為コメント化 対応時は、定数 PollingTimeに追加
    //private static POLLING_INTERVAL: number = 40000;//30000=30秒
    //private UnReadCount: number = 0;
    //private HasDataLock: boolean = false;

    constructor(parameter: PageInitializeParameter) {
        super(parameter);
    }

    public initialize() {
        this.pollingUnReadCount();
        this.pollingdataLockCheck();
    }

    /**
    *印刷件数確認
    */
    private pollingUnReadCount() {
        //AI監査では「印刷BOX」が存在しないため、処理不要であることからコメント化
        //SystemHeader.getUnReadCount();
        //TODO:ポーリング処理は見送りの為コメント化　2次対応以降で再検討
        //setTimeout(() => this.pollingUnReadCount(), SystemHeader.POLLING_INTERVAL);
    }


    /**
    *排他制御
    */
    private pollingdataLockCheck() {
        //現状ではデータロック中はページ遷移でエラーになる
        //ここでチェックする意味はないためコメントアウトする
        //this.getDataLockCheck();
        //TODO:ポーリング処理は見送りの為コメント化　2次対応以降で再検討
        //setTimeout(() => this.pollingdataLockCheck(), SystemHeader.POLLING_INTERVAL);
    }

    /**
    *排他制御確認
    */
   /*
    private getDataLockCheck() {
        let success = (data: DataLockViewModel, textStatus: string, jqXHR: JQueryXHR) => {
            this.HasDataLock = data.DataLock;
            return this.updateDataLockCheck(this.HasDataLock, null!, null!);
        }
        let settings: AjaxSettings = {
            url: "/api/v1/Batch/ClentHasLock",
            type: "GET",
            data: "",
            showBusyIndicator: false,
            beforeSend: null!,
            success: success,
            complete: null!,
            error: null!
        }
        Ajax.perform(settings);
    }
    */

    /**
    *排他制御表示切替
    */
    /*
    private updateDataLockCheck(data: any, textStatus: string, jqXHR: JQueryXHR) {
        var hasDataLock = data;
        var haslock = document.getElementById("datahaslock");

        if (hasDataLock) {
            haslock!.style.display = "";
        }
        else {
            haslock!.style.display = "none";
        }
    }
    */

    /**
    *未読印刷物の表示を行う
    */
    public static getUnReadCount() {
        let success = (data: UnReadCountsViewModel, textStatus: string, jqXHR: JQueryXHR) => {
            var UnReadCount = data.UnReadCounts;
            //return mjs.acelink.vkz.support.pageHeaders.SystemHeader.updateUnReadCount();
            return SystemHeader.updateUnReadCount(UnReadCount, null!, null!);


        }
        let settings: AjaxSettings = {
            url: "/api/v1/printing/unreadcounts",
            type: "GET",
            data: "",
            showBusyIndicator: false,
            beforeSend: null!,
            success: success,
            complete: null!,
            error: null!
        }
        Ajax.perform(settings);
    }

    /**
     *未読件数表示切替
        */
    public static  updateUnReadCount(data: any, textStatus: string, jqXHR: JQueryXHR) {
        var newCount = data;
        var $count = $("#batchProgressCurrentCount");
        var current = $count.text();

        //リクエストの件数が無い場合、及びNullの場合は非表示
        if (!newCount || newCount == "" || newCount == "0") {
            $count.css('display', 'none');
            $count.text("0");
        }
        else {
            //印刷リクエストに変更があるか確認し、あれば数値変更とともにアニメーション
            if (current != newCount) {
                $count.css('display', 'block');
                $count.removeClass().addClass('number animated bounce').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function (elem) {
                    $(elem).removeClass();
                    $(elem).addClass("number");
                });
            }
            $count.text(newCount);
        }
    }
}
