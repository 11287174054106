import Axios from 'axios';
import React, { ChangeEvent } from 'react';
import { Button, Label, Input } from 'reactstrap';
import DisplayOnlyGrid from '../../../../components/molecules/CMN/Grid/DisplayOnlyGrid';
import PageHeader from '../../../../components/molecules/CMN/PageHeader/PageHeader';
import { LowerActionLayout, LowerActionLayoutContents, LowerActionLayoutHeader } from '../../../../components/templates/LowerActionLayout';
import { AlertKbnEnum, setAlertMessage } from '../../../../stores/AlertMessage';
import { SystemHistoryViewModel} from '../../../../models/systemHistoryViewModel';
import ExcelOutputsetting from '../../../organisms/A000/ExcelOutputsetting';
import * as constants from '../../../../constants/constant';
import * as message from '../../../../constants/message';
import * as labels from '../../../../constants/labels';
import * as wjInput from 'wijmo/wijmo.input';
import { InputDate } from 'wijmo/wijmo.react.input';

// 基本URL
const BaseURL = 'api/v1/SystemHistory';
export const InitialUrl = [BaseURL, 'Initial'].join('/'); // アクセスはしない。初期表示呼び出す変数として保持。
export const SearchUrl = [BaseURL, 'Search'].join('/');
export const ExcelCheckUrl = [BaseURL, 'ExcelCheck'].join('/'); //Excel出力件数チェック
// 起動判定URL
const JudgeURL = 'api/v1/CommonStartJudgement/Judge';
// システム履歴記入
const WriteSysHistoryURL = 'api/v1/CommonSystemHistory/Write';

//CSS Class
const labelClass = 'mr-sm-2 d-flex search-label';
const inputClass = 'ml-1';

// 今日と昨日の日付を取得
const today = new Date();
const yesterday = new Date(Date.now() - 86400000);

// 検索項目Viewmodel

class SearchViewModel {
  CompanyName?: string;
  ProgramName?: string;
  Operation?: string;
  UserName?: string;
  StartDate?: Date = yesterday;
  EndDate?: Date = today;
};

type SystemHistoryprops = {
  onClose: () => void;
};

const SystemHistory: React.FC<SystemHistoryprops> = props => {
  // excel出力
  const [excelPopupActivated, setExcelPopupAcrivated] = React.useState(false);
  const [excelServiceParams, setExcelServiceParams] = React.useState('');
  // 通信中のボタン制御
  const [communicating, setCommunicating] = React.useState(false);
  const [resultMsg, setResultMsg] = React.useState('');
  const [viewModel, updateViewModel] = React.useState<SystemHistoryViewModel>(
    new SystemHistoryViewModel()
  );
  const [searchViewModel, updateSearchViewModel] = React.useState<SearchViewModel>(
    new SearchViewModel()
  );

  // excel出力
  const handleOnExcelClick = () => {
    const saveViewModel = {
      ...searchViewModel,
      CompanyName: searchViewModel.CompanyName,
      ProgramName: searchViewModel.ProgramName,
      Operation: searchViewModel.Operation,
      UserName: searchViewModel.UserName,
      StartDate: typeof startDate === 'undefined'? changeStartTime(yesterday) : changeStartTime(startDate.value),
      EndDate: typeof endDate === 'undefined' ? changeEndTime(today) : changeEndTime(endDate.value)
    };
    // 出力件数チェックの対応
    ExcelCheckOnPost(ExcelCheckUrl, saveViewModel);
  };

  // Excel出力を閉じる
  const handleExcelOnClose = () => {
    setExcelPopupAcrivated(false);
  }

  // システム履歴を閉じる
  const handleOnCloseClick = () => {
    props.onClose();
  };

  // 出力件数チェック
  const ExcelCheckOnPost = (url: string, condition: SystemHistoryViewModel) => {
    setCommunicating(true);
    Axios.post(url, condition)
      .then(response => {
        setExcelPopupAcrivated(true);
      })
      .catch(error => {
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message })
      })
      .finally(() => {
        setCommunicating(false);
      });
  };

  //検索する
  const ResultOnPost = (url:string, condition: SystemHistoryViewModel) => {
    setResultMsg('');
    setCommunicating(true);
    Axios.post<SystemHistoryViewModel>(url, condition)
      .then(response => {
        const newViewModel = response.data;
        setResultMsg('');
        updateViewModel(newViewModel);
      })
      .catch(error => {
        if (typeof error.response.data !== 'undefined') {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message })
        }
      })
      .finally(() => {
        setCommunicating(false);
      });
  };

  /**
   * 開始時刻変換
   * return yyyy/mm/dd 00:00:00
   */
  const changeStartTime = (startTime: any) => {
    if (startTime !== null) {
      var year = startTime.getFullYear();
      var month = startTime.getMonth();
      var day = startTime.getDate();
      return new Date(year, month, day);
    }
    return startTime;
  }

  /**
   * 終了時刻変換
   * return yyyy/mm/dd 23:59:59
   */
  const changeEndTime = (endTime: any) => {
    if (endTime !== null) {
      var year = endTime.getFullYear();
      var month = endTime.getMonth();
      var day = endTime.getDate();
      return new Date(year, month, day, 23, 59, 59);
    }
    return endTime;
  }
  
  // 検索ボタンクリック
  const handleonSearchClick = () => {
    const saveViewModel = {
      ...searchViewModel,
      CompanyName: searchViewModel.CompanyName,
      ProgramName: searchViewModel.ProgramName,
      Operation: searchViewModel.Operation,
      UserName: searchViewModel.UserName,
      StartDate: typeof startDate === 'undefined'? changeStartTime(yesterday) : changeStartTime(startDate.value),
      EndDate: typeof endDate === 'undefined' ? changeEndTime(today) : changeEndTime(endDate.value)
    };
    ResultOnPost(SearchUrl, saveViewModel);
  };

  React.useEffect(() => {
    // 処理判定
    async function JudgeOnPost(url: string, syoriId: string) {
      const status = await Axios.post<number>(url, {syoriId: syoriId});
      if (status.data == 0) {
        // 初期起動
        handleonSearchClick();
        await Axios.post(WriteSysHistoryURL, {syoriId: syoriId, operation: '処理開始'});
      } else {
        if(status.data == -101) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotUse});
        } else if (status.data == -102) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractOver});
        } else if (status.data == -103) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_Notauthorized});
        } else if (status.data == -104) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotDataSelect});
        } else if (status.data == -105) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractEqual});
        }
        props.onClose();
      }
    }
    JudgeOnPost(JudgeURL, 'AIKCMN007000001');
  }, [InitialUrl]);

  React.useEffect(() => {
    setExcelServiceParams(
      JSON.stringify({
        CompanyName: searchViewModel.CompanyName,
        ProgramName: searchViewModel.ProgramName,
        Operation: searchViewModel.Operation,
        UserName: searchViewModel.UserName,
        StartDate: typeof startDate === 'undefined'? changeStartTime(yesterday) : changeStartTime(startDate.value),
        EndDate: typeof endDate === 'undefined' ? changeEndTime(today) : changeEndTime(endDate.value)
      })
    );
  }, [handleonSearchClick]);

  /**
   * 検索項目設定
   */
   const [startDate, setStartDate] = React.useState<wjInput.InputDate>();
  const handelOnStartDateChange = (e: wjInput.InputDate) => {
    setStartDate(e);
  }

  const [endDate, setEndDate] = React.useState<wjInput.InputDate>();
  const handelOnEndDateChange = (e: wjInput.InputDate) => {
    setEndDate(e);
  }

  const handleOnInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    const name = target.name;
    const value = target.value;

    var searchNewViewModel = searchViewModel;
    searchNewViewModel[name] = value;
    
    updateSearchViewModel({...searchNewViewModel});
  }

  return (
    <div className='system-history'>
      <LowerActionLayout className='vw-100'>
        <LowerActionLayoutHeader>
          <PageHeader
            pageTitle={labels.AIKCMN007000001_FUNCTION_NAME}
            excelButtonTabIndex={2}
            onExcelClick={handleOnExcelClick}
            closeButtonTabIndex={3}
            onCloseClick={handleOnCloseClick}
          />
        </LowerActionLayoutHeader>
        <LowerActionLayoutContents className='vw-100 p-3'>
          <div className='d-flex'>
            <Label className={labelClass}>
              {labels.AIKCMN007000001_INPUT_HEADER_DATETIME}
              <InputDate
                mask={'9999/99/99'}
                isRequired={false}
                value={searchViewModel.StartDate}
                className={inputClass}
                valueChanged={handelOnStartDateChange}
                isDisabled={communicating}
                format={'yyyy/MM/dd'}
                autoExpandSelection={false}
              />
              </Label>
            <Label className='p-1 mr-sm-2'>{labels.AIKCMN007000001_INPUT_HEADER_TILDE}</Label>
            <Label>
              <InputDate
                mask={'9999/99/99'}
                isRequired={false}
                value={searchViewModel.EndDate}
                className={inputClass}
                valueChanged={handelOnEndDateChange}
                isDisabled={communicating}
                format={'yyyy/MM/dd'}
                autoExpandSelection={false}
              />
            </Label>
            </div>
          <div className='d-flex'>
            <Label className={labelClass}>
              {labels.AIKCMN007000001_INPUT_HEADER_FUNCTION}
              <Input
                type='text'
                name='ProgramName'
                value={searchViewModel.ProgramName}
                className={inputClass}
                onChange={handleOnInputChange}
                disabled={communicating}
              />
              </Label>
            <Label className={labelClass}>
              {labels.AIKCMN007000001_INPUT_HEADER_OFFICESTAFF}
              <Input
                type='text'
                name='UserName'
                value={searchViewModel.UserName}
                className={inputClass}
                onChange={handleOnInputChange}
                disabled={communicating}
              />
              </Label>
            <Label className={labelClass}>
              {labels.AIKCMN007000001_INPUT_HEADER_COMPANYNAME}
              <Input
                type='text'
                name='CompanyName'
                value={searchViewModel.CompanyName}
                className={inputClass}
                onChange={handleOnInputChange}
                disabled={communicating}
              />
            </Label>
            <Label className={labelClass}>
              {labels.AIKCMN007000001_INPUT_HEADER_OPERATION}
              <Input
                type='text'
                name='Operation'
                value={searchViewModel.Operation}
                className={inputClass}
                onChange={handleOnInputChange}
                disabled={communicating}
                />
              </Label>
              <Button
                id='searchbutton'
                color='primary'
                className='search-button'
                onClick={handleonSearchClick}
              >
                {labels.AIKCMN007000001_BUTTON_FUNCTION_SEARCH}
              </Button>
            </div>
          <DisplayOnlyGrid
            gridClassName='SystemHistory'
            columns={GridColumns}
            sources={viewModel.Results!}
          />
          <Label>{resultMsg}</Label>
        </LowerActionLayoutContents>
      </LowerActionLayout>
      <ExcelOutputsetting
        activated={excelPopupActivated}
        templateId={constants.AIKCMN007000001E01}
        serviceParams={excelServiceParams}
        onClose={handleExcelOnClose}
      />
    </div>
  );
}

/**
 * Gridカラム
 */
 const GridColumns = [
  {
    header: labels.AIKCMN007000001_GRID_HEADER_DATETIME,
    binding: 'StartDateDisplay',
    align: 'center',
    width: 200
  },
  {
    header: labels.AIKCMN007000001_GRID_HEADER_FISCALYEAR,
    binding: 'DataYear',
    align: 'center',
    width: 150
  },
  {
    header: labels.AIKCMN007000001_GRID_HEADER_COMPANYNAME,
    binding: 'CompanyName',
    align: 'left',
    width: '*',
    minWidth: 150
  },
  {
    header: labels.AIKCMN007000001_GRID_HEADER_FUNCTIONNAME,
    binding: 'ProgramName',
    align: 'left',
    width: 170
  },
  {
    header: labels.AIKCMN007000001_GRID_HEADER_OPERATION,
    binding: 'Operation',
    align: 'left',
    width: 150
  },
  {
    header: labels.AIKCMN007000001_GRID_HEADER_OFFICESTAFFKBN,
    binding: 'UserKbn',
    align: 'center',
    width: 120
  },
  {
    header: labels.AIKCMN007000001_GRID_HEADER_OFFICESTAFF,
    binding: 'UserName',
    align: 'left',
    width: 150
  }
];

export default SystemHistory;