import {SystemHistoryResultVO} from "./systemHistoryResultVO";
/**
 * システム履歴ViewModel ViewModel クラス
 */

export class SystemHistoryViewModel {
    /** システム履歴結果VO */
    public Results?:Array<SystemHistoryResultVO>;

    /** 会社名 */
    public CompanyName?:string;

    /** 処理名 */
    public ProgramName?:string;

    /** 操作 */
    public Operation?:string;

    /** 担当者名 */
    public UserName?:string;

    /** 開始日時 */
    public StartDate?:Date;

    /** 終了日時 */
    public EndDate?:Date;

}
