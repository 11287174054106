import React, { useImperativeHandle }from 'react';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import GridDialog from './GridDialog';
import { CodeAttr } from '../../../VKZ/core/constants/constant';
/** プロパティ */
export type DialogInputTextProps = {
  itemList: Array<any>;
  name: string;
  transmitItem: (item: any, target?: HTMLInputElement) => void;
  forwardRef?: React.RefObject<any>;
  codeViewContent: string;
  nameViewContent: string;
  disabled?: boolean;
  codeAttr?: number;
  tabIndex?: number;
};
/**
 * ダイアログ表示入力コントロール
 * @param props
 */
const DialogInputText: React.FC<DialogInputTextProps> = props => {
  const { itemList, transmitItem, name, forwardRef, codeViewContent, nameViewContent, disabled, codeAttr } = props;
  // ダイアログ表示フラグ
  const [activated, setActivated] = React.useState(false);
  // コントロール識別名
  const [contorolNames, setContorolNames] = React.useState({});
  // コード属性
  const [codeAlign, setCodeAlign] = React.useState('');
  // 入力アイテム
  const [inputItem, setInputItem] = React.useState({});
  // フォーカス設定
  var setFocus = () => {
    // コード入力コントロールにフォーカスを移動する
    document.getElementById(name + '-' + contorolNames['Code'])?.focus();
  }
  /** コントロール入力 */
  var setItem = (item: any) => {
    setInputItem(item);
    Object.keys(contorolNames).forEach((key) => {
      // keyからコントロールを指定する
      const element = document.getElementById(name + '-' + contorolNames[key])! as HTMLInputElement;
      // コントロールに値を設定する
      element.value = item[contorolNames[key]] || '';
    });
  }
  /** コントロール入力値取得 */
  var getItem = () => {
    // 返却値初期化
    var item = {};
    Object.keys(contorolNames).forEach((key) => {
      // keyから取得コントロールを指定する
      const element = document.getElementById(name + '-' + contorolNames[key])! as HTMLInputElement;
      // コントロールから値を取得する
      item[contorolNames[key]] = element.value;
    });
    return item;
  }
  /** 呼び出し可能関数指定 */
  useImperativeHandle(forwardRef, () => ({
    setFocus, getItem, setItem
  }));
  /**
   * ダイアログクローズイベントハンドラー
   * @param flg 設定フラグ
   * @param v 選択値
   */
  const handleOnDlgClose = (flg: boolean, v: any) => {
    // フラグがtrueの場合は、コントロールに選択値を設定する
    if (flg) {
      // コード入力コントロール
      const codeElement = document.getElementById(name + '-' + contorolNames['Code'])! as HTMLInputElement;
      // 名称入力コントロール
      const nameElement = document.getElementById(name + '-' + contorolNames['Name'])! as HTMLInputElement;
      // 値設定
      codeElement.value = v[codeViewContent] || '';
      nameElement.value = v[nameViewContent] || '';
      // 選択値通知
      transmitItem(v, undefined);
      // 入力値を設定する
      setInputItem(v);
    }
    // ダイアログ非表示
    setActivated(false);
  };
  /**
   * フォーカスアウトイベントハンドラー
   * @param e
   */
  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    // フォーカスアウトしたコントロールID
    const actorId = e.currentTarget.id == name + '-' + contorolNames['Code'] ? contorolNames['Code'] : contorolNames['Name'];
    // フォーカスアウトしたコントロールと対となるコントロールID
    const otherId = actorId == contorolNames['Code'] ? contorolNames['Name'] : contorolNames['Code'];
    // フォーカスアウトしたコントロールと対となるコントロール
    const otherElement = document.getElementById(name + '-' + otherId)! as HTMLInputElement;
    // 一致フラグ
    var flg = false;
    // 通知アイテム
    var data = {};
    // 対となる入力値
    var targetName = '';
    // リストに入力値が存在するか確認する
    itemList.some(item => {
      // 値の対比
      targetName = item[otherId];
      // 一致する値がある場合はループを抜ける
      return flg = item[actorId] == e.currentTarget.value;
    });
    if (!flg) {
      // リストに入力値がない場合は、入力を戻す
      e.currentTarget.value = e.currentTarget.value == '' ? '' : inputItem[actorId] || '';
      otherElement.value = e.currentTarget.value == '' ? '' : inputItem[otherId] || '';
    } else {
      // 入力値がリストにある場合は、対の入力をする
      otherElement.value = targetName;
    }
    // 通知用のデータを作成する
    data[actorId] = e.currentTarget.value;
    data[otherId] = otherElement.value;
    // 入力値を通知する
    transmitItem(data, e.currentTarget);
    // 入力値を設定する
    setInputItem(data);
  }
  /**
   * 入力変更イベントハンドラー
   * @param e
   */
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // 入力しているコントロールと対となるコントロールID
    const otherId = e.currentTarget.id == name + '-' + contorolNames['Code'] ? contorolNames['Name'] : contorolNames['Code'];
    // 入力しているコントロールと対となるコントロール
    const otherElement = document.getElementById(name + '-' + otherId)! as HTMLInputElement;
    // 対となるコントロールの値をクリアする
    otherElement.value = '';
  }
  // バインド値変更時処理
  React.useEffect(() => {
    // コントロールにバインドする領域名を設定する
    setContorolNames({
      Code: codeViewContent,
      Name: nameViewContent
    });
    // コード属性が設定されている場合
    if (codeAttr != null) {
      // コード属性からコードの調整位置文字列を設定する
      setCodeAlign(codeAttr == CodeAttr.Number ? 'right' : 'left');
    }
  }, [codeViewContent, nameViewContent, codeAttr]);
  return (
    <div className='d-inline dialog-input-text'>
      <Input type='text'
        id={name + '-' + contorolNames['Code']}
        className='code-input'
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
      />
      <Button
        className={disabled ? 'disabled-btn' : 'btn'}
        onClick={() => setActivated(true)}
        disabled={disabled}
        tabIndex={props.tabIndex}
      >...</Button>
      <Input type='text'
        id={name + '-' + contorolNames['Name']}
        className='name-input'
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
      />
      <GridDialog
        activated={activated}
        onClose={handleOnDlgClose}
        itemSource={itemList}
        codeViewContent={codeViewContent}
        nameViewContent={nameViewContent}
        codeAttr={codeAlign}
        selectedItem={inputItem}
      />
    </div>
  );
};
export default DialogInputText;