import { Logger } from "../common/usefuls/logger";
import { MjsUtilities } from "../common/usefuls/utilities"
import { UserAgentUtil } from "../common/usefuls/useragentUtil"
import { MessageBox } from "./messageBox";
import { ApiResultViewModel } from "../models/common/apiResultViewModel";
import { AntiForgeryToken } from "./antiForgeryToken";
import { BusyIndicator } from "./busyIndicator";
import { Messages } from "../common/message";
import { messageUtil } from "../common/messageUtil";
import { SessionToken } from "./sessionToken";
import $ from 'jquery';
import Axios, { AxiosRequestConfig, ResponseType, AxiosResponse, Method } from 'axios';
import { AlertKbnEnum, setAlertMessage } from "../../stores/AlertMessage";

/**
 * Ajax通信の設定インターフェースです。
 */
export interface AjaxSettings {
    /** リクエストURL */
    url: string;
    /** リクエストタイプ */
    type: string;
    /** リクエストデータ */
    data: any;
    /** 処理中にビジーインジケーターを表示する場合は<code>true</code>を、表示しない場合は<code>true</code>を指定する。 */
    showBusyIndicator: boolean;
    /** リクエスト送信前のコールバック関数 */
    beforeSend?(jqXHR: JQueryXHR, settings: JQueryAjaxSettings): any;
    /** リクエスト送信成功後のコールバック関数 */
    success?(data: any, textStatus: string, jqXHR: JQueryXHR): any;
    /** リクエスト送信完了後のコールバック関数 */
    complete?(jqXHR: JQueryXHR, textStatus: string): any;
    /** リクエスト送信エラー時のコールバック関数 */
    error?(data: any, jqXHR: JQueryXHR, textStatus: string, errorThrown: string): any;
}

export interface ValidateFalsViewModel {
    Success: boolean;
    Message: string;
    ModelState: any;
}

export class Ajax {
    private static isPageBeingRefreshed: boolean = false;

    public static beforeunloadEvent(e: any): void {
        Ajax.isPageBeingRefreshed = true;
    }

    /**
     * サーバーサイドと非同期通信を行います。
     * @param ajaxSettings Ajax通信の設定情報
     * @param async: 非同期通信を行うか？
     * @param dataType: データタイプ (json または blob が指定可能。指定が無い場合は json とする)
     */
    public static async perform(ajaxSettings: AjaxSettings, async: boolean = true, dataType = "json"): Promise<void> {
        var data = ajaxSettings.data;
        if (data == null)
            throw new Error("mjs.acelink.vkz.core.Ajax.perform パラメーターエラー");

        // 日付型をサーバーが受け取れる文字列に変換する（日付型がない場合はそのまま）
        data = MjsUtilities.stringify(data);
        var type = ajaxSettings.type.toUpperCase();
        var beforeSend = ajaxSettings.beforeSend;
        var showBusyIndicator = ajaxSettings.showBusyIndicator;
        var error = ajaxSettings.error;
        var settings: JQueryAjaxSettings = {
            type: type,
            async: async,
            //cache: ((type === "HEAD" || type === "GET") ? true : false),
            cache: false,
            data: typeof data === "string" ? data : JSON.stringify(data),
            dataType: dataType,
            contentType: "application/json; charset=utf-8",
            beforeSend: (jqXHR: JQueryXHR, settings: JQueryAjaxSettings) => { if (beforeSend) beforeSend(jqXHR, settings); return Ajax.beforeSendCallback(jqXHR, settings, showBusyIndicator); },
            success: (data: any, textStatus: string, jqXHR: JQueryXHR) => { return Ajax.successProcess(data, textStatus, jqXHR, ajaxSettings); },
            complete: (jqXHR: JQueryXHR, textStatus: string) => { return Ajax.completeProcess(textStatus, jqXHR, ajaxSettings); },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                // 無視できるエラーはエラー処理を行わない
                if (Ajax.checkIsIgnorableError(jqXHR)) return;

                Ajax.errorCallback(jqXHR, textStatus, errorThrown);
                if (error) error(data, jqXHR, textStatus, errorThrown);
            }
        };

        Ajax.debugRequest(
            ajaxSettings.url
            , JSON.parse(settings.data)
            , settings);

        if (async) {
            Ajax.communicate(ajaxSettings.url, settings);
        } else {
            await Ajax.communicate(ajaxSettings.url, settings);
        }
    }

    /**
     * サーバーサイドとの非同期通信でファイルのアップロードを行います。
     * @param ajaxSettings Ajax通信の設定情報
     */
    public static async performForFileUpload(ajaxSettings: AjaxSettings, async: boolean = true): Promise<void> {
        var data = ajaxSettings.data;
        if (data == null)
            throw new Error("mjs.acelink.vkz.core.Ajax.perform パラメーターエラー");

        var type = ajaxSettings.type.toUpperCase();
        var beforeSend = ajaxSettings.beforeSend;
        var showBusyIndicator = ajaxSettings.showBusyIndicator;
        var error = ajaxSettings.error;

        var settings: JQueryAjaxSettings = {
            type: type,
            async: async,
            cache: false,
            data: data,
            dataType: "json",
            processData: false,　// クエリ文字列変換はしない(processData=false)
            contentType: false,  // 自動で設定されるため、content-typeヘッダの値は指定しない(contentType=falseにする)
            beforeSend: (jqXHR: JQueryXHR, settings: JQueryAjaxSettings) => { if (beforeSend) beforeSend(jqXHR, settings); return Ajax.beforeSendCallback(jqXHR, settings, showBusyIndicator); },
            success: (data: any, textStatus: string, jqXHR: JQueryXHR) => { return Ajax.successProcess(data, textStatus, jqXHR, ajaxSettings); },
            complete: (jqXHR: JQueryXHR, textStatus: string) => { return Ajax.completeProcess(textStatus, jqXHR, ajaxSettings); },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                // 無視できるエラーはエラー処理を行わない
                if (Ajax.checkIsIgnorableError(jqXHR)) return;

                Ajax.errorCallback(jqXHR, textStatus, errorThrown);
                if (error) error(data, jqXHR, textStatus, errorThrown);
            }
        };

        Ajax.debugRequest(ajaxSettings.url, null, settings);
        if (async) {
            Ajax.communicate(ajaxSettings.url, settings);
        } else {
            await Ajax.communicate(ajaxSettings.url, settings);
        }
    }

    /**
     * サーバーサイドと非同期通信を行います。
     * @param ajaxSettings Ajax通信の設定情報
     */
    public static async performNoErrorDisp(ajaxSettings: AjaxSettings, async: boolean = true): Promise<void> {
        var data = ajaxSettings.data;
        if (data == null)
            throw new Error("mjs.acelink.vkz.core.Ajax.perform パラメーターエラー");

        // 日付型をサーバーが受け取れる文字列に変換する（日付型がない場合はそのまま）
        data = MjsUtilities.stringify(data);
        var type = ajaxSettings.type.toUpperCase();
        var beforeSend = ajaxSettings.beforeSend;
        var showBusyIndicator = ajaxSettings.showBusyIndicator;
        var error = ajaxSettings.error;
        var settings: JQueryAjaxSettings = {
            type: type,
            async: async,
            //cache: ((type === "HEAD" || type === "GET") ? true : false),
            cache: false,
            data: typeof data === "string" ? data : JSON.stringify(data),
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            beforeSend: (jqXHR: JQueryXHR, settings: JQueryAjaxSettings) => { if (beforeSend) beforeSend(jqXHR, settings); return Ajax.beforeSendCallback(jqXHR, settings, showBusyIndicator); },
            success: (data: any, textStatus: string, jqXHR: JQueryXHR) => { return Ajax.successProcess(data, textStatus, jqXHR, ajaxSettings); },
            complete: (jqXHR: JQueryXHR, textStatus: string) => { return Ajax.completeProcess(textStatus, jqXHR, ajaxSettings); },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                // 無視できるエラーはエラー処理を行わない
                if (Ajax.checkIsIgnorableError(jqXHR)) return;

                if (error) error(data, jqXHR, textStatus, errorThrown);
            }
        };

        if (async) {
            Ajax.debugRequest(
                ajaxSettings.url
                , JSON.parse(settings.data)
                , settings);
        } else {
            await Ajax.debugRequest(
                ajaxSettings.url
                , JSON.parse(settings.data)
                , settings);
        }
    }

    /**
     * サーバーサイドと非同期通信を行います。
     * @param ajaxSettings Ajax通信の設定情報
     */
    public static async performNoErrorHide(ajaxSettings: AjaxSettings, async: boolean = true): Promise<void> {
        var data = ajaxSettings.data;
        if (data == null)
            throw new Error("mjs.acelink.vkz.core.Ajax.perform パラメーターエラー");

        // 日付型をサーバーが受け取れる文字列に変換する（日付型がない場合はそのまま）
        data = MjsUtilities.stringify(data);
        var type = ajaxSettings.type.toUpperCase();
        var beforeSend = ajaxSettings.beforeSend;
        var showBusyIndicator = ajaxSettings.showBusyIndicator;
        var error = ajaxSettings.error;
        var settings: JQueryAjaxSettings = {
            type: type,
            async: async,
            //cache: ((type === "HEAD" || type === "GET") ? true : false),
            cache: false,
            data: typeof data === "string" ? data : JSON.stringify(data),
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            beforeSend: (jqXHR: JQueryXHR, settings: JQueryAjaxSettings) => { if (beforeSend) beforeSend(jqXHR, settings); return Ajax.beforeSendCallback(jqXHR, settings, showBusyIndicator); },
            success: (data: any, textStatus: string, jqXHR: JQueryXHR) => { return Ajax.successProcess(data, textStatus, jqXHR, ajaxSettings); },
            complete: (jqXHR: JQueryXHR, textStatus: string) => { return Ajax.completeProcess(textStatus, jqXHR, ajaxSettings); },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                // 無視できるエラーはエラー処理を行わない
                if (Ajax.checkIsIgnorableError(jqXHR)) return;

                Ajax.errorCallback(jqXHR, textStatus, errorThrown);
                if (error) error(data, jqXHR, textStatus, errorThrown);
            }
        };

        Ajax.debugRequest(ajaxSettings.url, null, settings);
        if (async) {
            Ajax.communicate(ajaxSettings.url, settings, false)
        } else {
            await Ajax.communicate(ajaxSettings.url, settings, false);
        }
    }

    private static debugRequest(url: string, data: any, settings: JQueryAjaxSettings): void {
        Logger.debugTimeStamp("ajax request");
        Logger.info(">>>>>>>>>>>>>>>>>>>>> ajax request info >>>>>>>>>>>>>>>>>>>>>>>");
        Logger.info("url =", url);
        Logger.info("method =", settings.type);
        Logger.info("data = ");
        Logger.info(JSON.stringify(data, null, 2));
        if (UserAgentUtil.isMsie()) {
            Logger.warn("注）IEだと出力の途中で切れるので全て確認したい方はChromeで確認を");
        }
        Logger.info(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    }

    private static debugResponse(url: string, data: any, xhr: JQueryXHR): void {
        Logger.debugTimeStamp("ajax response");
        Logger.info("<<<<<<<<<<<<<<<<<<<<< ajax response info <<<<<<<<<<<<<<<<<<<<<");
        if (url != null) {
            Logger.info("url =", url);
        }
        Logger.info("status =", xhr.statusText + "(" + xhr.status + ")");
        Logger.info("reponse data = ");
        Logger.info(JSON.stringify(data, null, 2));
        if (UserAgentUtil.isMsie()) {
            Logger.warn("注）IEだと出力の途中で切れるので全て確認したい方はChromeで確認を");
        }
        Logger.info("<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<");
    }

    /**
     * 最小限の引数でAjaxGetを実行する
     * 注）第二引数にObject（ViewModel含む）を渡すとクエリパラメーターを生成しURLに付加してアクセスを行います
     *
     * @param url
     * @param data
     * @param successCB
     */
    public static async simpleGet(
        url: string
        , data: any
        , successCB: (data: any) => void
        , completeCB?: () => void
    ): Promise<void> {

        var settings = Ajax.genSettings(
            "GET"
            , url
            , data
            , successCB
            , true
            , true
            , null!
            , (completeCB == null ? null! : completeCB)
            , null!
        );

        Ajax.debugRequest(url, data, settings);
        
        await Ajax.communicate(settings.url!, settings);
    }

    /**
     * AjaxGetを実行する
     * 注）第二引数にObject（ViewModel含む）を渡すとクエリパラメーターを生成しURLに付加してアクセスを行います
     *
     * @param url
     * @param data
     * @param successCB
     */
    public static async get(
        url: string
        , data: any
        , successCB: (data: any) => void
        , isShowIndicator: boolean = true
        , isShowError: boolean = true
        , errorCB?: () => void
        , completeCB?: () => void
        , beforeCB?: () => void): Promise<void> {

        var settings = Ajax.genSettings(
            "GET"
            , url
            , data
            , successCB
            , isShowIndicator
            , isShowError
            , errorCB
            , completeCB
            , beforeCB
        );

        Ajax.debugRequest(url, data, settings);
        await Ajax.communicate(settings.url!, settings);
    }

    /**
     * 最小限の引数でAjaxPostを実行する
     * @param url
     * @param data
     * @param successCB
     */
    public static async simplePost(
        url: string
        , data: any
        , successCB: (data: any) => void
    ): Promise<void> {

        var settings = Ajax.genSettings(
            "POST"
            , url
            , data
            , successCB
            , true
            , true
            , null!
            , null!
            , null!
        );

        Ajax.debugRequest(url, data, settings);
        await Ajax.communicate(settings.url!, settings);
    }

    /**
     * AjaxPostを実行する
     * @param url
     * @param data
     * @param successCB
     */
    public static async post(
        url: string
        , data: any
        , successCB: (data: any) => void
        , isShowIndicator: boolean = true
        , isShowError: boolean = true
        , errorCB?: () => void
        , completeCB?: () => void
        , beforeCB?: () => void): Promise<void> {

        var settings = Ajax.genSettings(
            "POST"
            , url
            , data
            , successCB
            , isShowIndicator
            , isShowError
            , errorCB
            , completeCB
            , beforeCB
        );

        Ajax.debugRequest(url, data, settings);
        await Ajax.communicate(settings.url!, settings);
    }


    /**
     * 最小限の引数でAjaxGetを実行する
     * 注）第二引数にObject（ViewModel含む）を渡すとクエリパラメーターを生成しURLに付加してアクセスを行います
     *
     * @param url
     * @param data
     * @param successCB
     */
    public static async simpleDelete(
        url: string
        , data: any
        , successCB: (data: any) => void
        , completeCB?: () => void
    ): Promise<void> {

        var settings = Ajax.genSettings(
            "DELETE"
            , url
            , data
            , successCB
            , true
            , true
            , null!
            , (completeCB == null ? null! : completeCB)
            , null!
        );

        Ajax.debugRequest(url, data, settings);
        await Ajax.communicate(settings.url!, settings);
    }

    /**
     * Ajax通信を行う
     * エラーメッセージが表示されている場合は閉じる
     * @param url
     * @param settings
     */
    private static communicate(url: string, settings: JQueryAjaxSettings, isHideError: boolean = true): Promise<void> {
        if (isHideError) {
            MessageBox.close();
        }
        Ajax.isPageBeingRefreshed = false;
        if (settings.beforeSend) {
            settings.beforeSend(Ajax.convertAxios2JQueryXHR(settings), settings);
        }
        return Axios.request({
            url: url,
            method: settings.type as (Method | undefined),
            headers: $.extend(settings.headers, {
                'Content-Type': settings.contentType,
            }),
            responseType: settings.dataType as (ResponseType | undefined),
            data: settings.data,
        }).then((e) => {
            settings.success && settings.success(e.data, e.statusText, Ajax.convertAxios2JQueryXHR(e) as JQueryXHR);
        }).catch((e: any) => {
            settings.error && settings.error(Ajax.convertAxios2JQueryXHR(e.response) as JQueryXHR, "", e);
        }).finally(() => {
            settings.complete && settings.complete(null!, "");
        });
    }

    private static convertAxios2JQueryXHR(e: JQueryAjaxSettings | AxiosRequestConfig | AxiosResponse) {
        return $.extend({
            responseJSON: e && e.data,
            abort: () => {},
            getAllResponseHeaders: () => {
                return e && e.headers;
            },
            getResponseHeader: (name: string) => {
                return e && e.headers[name];
            },
            setRequestHeader: (name: string, value: string)  => {
                if (!e.headers) {
                    e.headers = {};
                }
                e.headers[name] = value;
            }
        }, e);
    }

    private static genSettings(
        type: string
        , url: string
        , data: any
        , successCB: (data: any) => void
        , isShowIndicator: boolean = true
        , isShowError: boolean = true
        , errorCB?: () => void
        , completeCB?: () => void
        , beforeCB?: () => void
    ): JQueryAjaxSettings {

        if (type == "GET") {
            url = Ajax.appendQueryString(url, data);
            data = "";
        }

        // 日付型をサーバーが受け取れる文字列に変換する（日付型がない場合はそのまま）
        data = MjsUtilities.stringify(data);
        var settings: JQueryAjaxSettings = {
            url: url,
            type: type,
            cache: false,
            data: data,
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            async: false,
            beforeSend: (jqXHR: JQueryXHR, settings: JQueryAjaxSettings) => {
                if (beforeCB) {
                    beforeCB();
                } else {
                    return Ajax.beforeSendCallback(jqXHR, settings, isShowIndicator);
                }
            },
            success: (data: any, textStatus: string, jqXHR: JQueryXHR) => {
                MjsUtilities.rebuildModelData(data);
                Ajax.debugResponse(url, data, jqXHR);
                successCB(data);
            },
            complete: (jqXHR: JQueryXHR, textStatus: string) => {

                if (isShowIndicator) {
                    BusyIndicator.close();
                }
                if (completeCB) {
                    completeCB();
                }
            },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
                Ajax.debugResponse(url, jqXHR.responseJSON, jqXHR);
                if (isShowError) {
                    Ajax.showError(jqXHR);
                }
                if (errorCB) {
                    errorCB();
                }
            }
        };

        return settings;
    }

    /**
     * GET用のQueryパラメーターを生成する
     */
    private static appendQueryString(url: string, params: any): string {
        if (params != null) {
            let query = "";
            for (var key in params) {
                query += "&" + key + "=" + params[key]
            }

            // 先頭の「&」をカットする
            if (query != null) {
                url += "?" + query.slice(1);
            }

            Logger.debug("url=", url);
        }

        return url;
    }


    /**
     * 通信が成功した際に実行される
     * @param data
     * @param textStatus
     * @param jqXHR
     * @param ajaxSettings
     */
    private static successProcess(data: any, textStatus: string, jqXHR: JQueryXHR, ajaxSettings: AjaxSettings): any {
        Ajax.successCallback(data, textStatus, jqXHR);
        if (jqXHR.status == 200) {// 成功
            if (ajaxSettings.success) {
                MjsUtilities.rebuildModelData(data);
                return ajaxSettings.success(data, textStatus, jqXHR);
            }
        } else if (jqXHR.status == 400) {// 失敗
            let errorData = <ValidateFalsViewModel>data;
            alert(errorData.Message);
            if (ajaxSettings.error) {
                return ajaxSettings.error(errorData, jqXHR, textStatus, "");
            }
        } else {// その他の失敗
            if (ajaxSettings.error) {
                return ajaxSettings.error(null, jqXHR, textStatus, "");
            }
        }
    }

    /**
     * 通信が完了した際に実装される
     * @param data
     * @param textStatus
     * @param jqXHR
     * @param ajaxSettings
     */
    private static completeProcess(textStatus: string, jqXHR: JQueryXHR, ajaxSettings: AjaxSettings): any {
        Ajax.completeCallback(jqXHR, textStatus, ajaxSettings.showBusyIndicator);
        if (ajaxSettings.complete) {
            return ajaxSettings.complete(jqXHR, textStatus);
        }
    }


    /**
     * データ送信前のコールバック関数です。
     * @param jqXHR
     * @param settings
     * @param showBusyIndicator
     */
    private static beforeSendCallback(jqXHR: JQueryXHR, settings: JQueryAjaxSettings, showBusyIndicator: boolean): any {
        AntiForgeryToken.setHeader(jqXHR);
        SessionToken.setHeader(jqXHR);

        if (showBusyIndicator)
            BusyIndicator.open();

        return null;
    }

    /**
     * データ送信成功時のコールバック関数です。
     * @param data
     * @param textStatus
     * @param jqXHR
     */
    private static successCallback(data: any, textStatus: string, jqXHR: JQueryXHR): void {
        Ajax.debugResponse(null!, data, jqXHR);
    }

    /**
     * データ送信完了（success または error 関数実行後）のコールバック関数です。
     * @param jqXHR
     * @param textStatus
     * @param shownBusyIndicator
     */
    private static completeCallback(jqXHR: JQueryXHR, textStatus: string, shownBusyIndicator: boolean): any {
        if (shownBusyIndicator)
            BusyIndicator.close();

        return null;
    }

    /**
     * データ送信エラーのコールバック関数です。
     * @param jqXHR
     * @param textStatus
     * @param errorThrown
     */
    private static errorCallback(jqXHR: JQueryXHR, textStatus: string, errorThrown: string): void {
        Ajax.debugResponse(null!, jqXHR.responseJSON, jqXHR);
        Ajax.showError(jqXHR);
    }

    /**
     * 無視できるエラーかどうか
     * @param jqXHR
     */
    private static checkIsIgnorableError(jqXHR: JQueryXHR) {
        // Firefoxで非同期通信処理中（画面読込中）に別の通信が発生した際の事象はエラーとしない
        // https://stackoverflow.com/questions/699941/handle-ajax-error-when-a-user-clicks-refresh#
        if (!jqXHR.getAllResponseHeaders()) {
            jqXHR.abort();
            if (Ajax.isPageBeingRefreshed) {
                return true; // not an error
            }
        }

        return false;
    }

    /**
     * エラーを表示する
     * @param jqXHR
     */
    private static showError(jqXHR: JQueryXHR): void {
        let result: ApiResultViewModel;
        if (jqXHR.status === undefined && jqXHR.responseJSON == undefined) {
            // サーバー通信エラー
            result = new ApiResultViewModel();
            result.Message = messageUtil.getMsg(Messages.Common.Error.Server.CONNECTION);
        } else {
            result = <ApiResultViewModel>jqXHR.responseJSON;
        }
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: result.message, timeout: 100000 });
    }
    
}

$(function () {
    window.addEventListener('beforeunload', Ajax.beforeunloadEvent, false);
});
