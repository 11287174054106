import React from 'react';

type _ProcessMigrationStepFinishPartialProps = {
  scope?: any
};

const _ProcessMigrationStepFinishPartial: React.FC<_ProcessMigrationStepFinishPartialProps> = props => {
  return (
    <>
    <section id="stepFinish" className="dp_none">
    <span className="step-number" >{props.scope && props.scope.stepFinishDispNo}</span>
      <div className="step-title mb20">完了</div>
    </section>
    </>
  )
};
export default _ProcessMigrationStepFinishPartial;