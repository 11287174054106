import React from 'react';

import { Navbar, NavbarToggler, NavItem, ListGroupItem, ListGroup, Collapse } from 'reactstrap';

import ArrowCircleLeft from '../../atoms/Icon/ArrowCircleLeft';
import Bars from '../../atoms/Icon/Bars';
import EllipsisV from '../../atoms/Icon/EllipsisV';
import QuestionCircle from '../../atoms/Icon/QuestionCircle';

import * as labels from '../../../constants/labels';
import { ClientYearVO } from '../../../models/clientYearVO';

type AppHeaderProps = {
  // 決算期間関連
  selectClientYear: number | undefined;
  clientYears: ClientYearVO[] | undefined;
  isFiscalYearOpen: boolean;
  fiscalYearOnClick: () => void;
  fiscalYearOnChange: (fiscalYear: ClientYearVO) => void;
  // 会社名
  companyName: string | undefined;
  // メニュー関連
  menuOnToggle: () => void;
  isOpenMenu: boolean;
  // ヘルプ
  helpOnClick: () => void;
  // その他メニュー
  otherMenuOnToggle: () => void;
};

const AppHeader = React.memo<AppHeaderProps>(props => {
  const {
    selectClientYear,
    clientYears,
    isFiscalYearOpen,
    fiscalYearOnClick,
    fiscalYearOnChange,
    companyName,
    menuOnToggle,
    helpOnClick,
    otherMenuOnToggle
  } = props;

  const handleFiscalYearOnClick = () => {
    fiscalYearOnClick();
  };

  let fiscalYearSelect = null;
    if (clientYears != undefined && clientYears.length > 0) {
    const fiscalYearList: React.ReactElement[] = [];
    clientYears.forEach(fiscalYear => {
      fiscalYearList.push(
        <ListGroupItem
          key={['fiscal-year', fiscalYear.ClientYear].join('-')}
          tag='button'
          action
          onClick={() => fiscalYearOnChange(fiscalYear)}
          className='text-white py-2 rounded-0'
          tabIndex={102}
        >
          {fiscalYear.ClientYearName}
        </ListGroupItem>
      );
    });
    fiscalYearSelect = <ListGroup>{fiscalYearList}</ListGroup>;
  }
  const getSelectClientYearName = (clientYearList: ClientYearVO[], seletClientYear: number) => {
    const selectClientYearVo = clientYearList.filter(clientYear => clientYear.ClientYear === seletClientYear);
    return selectClientYearVo.length > 0 ? selectClientYearVo[0].ClientYearName : '';
  };


    const fiscalYearDropDown = selectClientYear != undefined && selectClientYear != 0 && clientYears != undefined ? (

        <div className='h-100'>
            <NavItem
                tag='button'
                className='-fiscalyear -btn h-100 border-0 text-white outline-none'
                onClick={handleFiscalYearOnClick}
                tabIndex={102}
            >
                <span>{getSelectClientYearName(clientYears, selectClientYear)}</span>
                <span className='ml-2 small'>▼</span>
            </NavItem>
            <Collapse isOpen={isFiscalYearOpen} className='position-fixed overflow-hidden app-header-fiscalyear-list'>
                {fiscalYearSelect}
            </Collapse>
        </div>

    )
        :
        (null);


    const companyNamePanel = companyName != undefined ? (
        <NavItem className='-companyname ml-3 d-flex justify-content-center align-items-center'>{companyName}</NavItem>
    )
        :
        (null);

  const menu = props.isOpenMenu ? <ArrowCircleLeft className='arrow-circle-left' /> : <Bars className='bars' />;
  return (
    <header>
      <Navbar color='tertiary' dark={true} className='app-header d-flex w-100 m-0 p-0 text-white'>
        <NavbarToggler onClick={menuOnToggle} className='-toggler -btn border-0 outline-none' tabIndex={101}>
          <span className='side-menu-toggler'>{menu}</span>
        </NavbarToggler>
        {fiscalYearDropDown}
        {companyNamePanel}
        <NavItem
          tag='button'
          onClick={helpOnClick}
          className='-btn ml-auto border-0 text-white outline-none'
          tabIndex={103}
        >
          <QuestionCircle className='icon-lg' />
          <br />
          <span className='header-help-lbl'>{labels.AIKCMN001000001_BUTTON_FUNCTION_HELP}</span>
        </NavItem>
        <NavItem
          tag='button'
          onClick={otherMenuOnToggle}
          className='-toggler -btn border-0 text-white outline-none'
          tabIndex={104}
        >
          <EllipsisV />
        </NavItem>
      </Navbar>
    </header>
  );
});
export default AppHeader;
