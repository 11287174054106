/**
 * 契約情報 ViewModel クラス
 */

export class ContractInfoViewModel {
    /** 契約NO */
    public ContractNo?:string;

    /** 契約日 */
    public ContractDate?:Date;

    /** 利用開始日 */
    public RiyouStrDate?:Date;

    /** 契約数 */
    public LicenseCountPurchased?:number;

    /** 作成会社数 */
    public MakeKaisyaCnt?:number;

    /** AI監査支援URL */
    public TenantURL?:URL;

}
