/**
 * 担当者パスワードViewmodel ViewModel クラス
 */

export class UserPasswordViewModel {
    /** 現在のパスワード */
    public OldPassword?:string;

    /** 新しいパスワード */
    public NewPassword?: string;

    /** パスワード確認 */
    public NewPasswordKakunin?: string;

}
