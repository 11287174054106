import { DataModelBase } from "../../core/models/dataModelBase";

/**
 * マスターデータ ViewModel クラス
 */
export class KmkInfoCacheViewModel extends DataModelBase {
    /** 顧問先管理コード */
    public ClientCode!: number;

    /** 年度 */
    public ClientYear!: number;

    /** 顧問先管理ユーザー */
    public ClientUser!: string;

    /** 特定科目コード */
    public SpeCode!: number;

    /** マスタ区分 */
    public MasterKbn!: number;

    /** 実在／合計区分 */
    public SumKbn!: number ;

    /** 科目コード／固定摘要コード */
    public KmkCode!: number ;

    /** 細目コード */
    public SubCode!: number ;

    /** 更新日時 */
    public UpdateTime!: Date;

}
