import { CharitableKbn, TaxRate } from "../../core/constants/constant";
import { SwkControlInfoViewModel } from "../../models/entry/swkControlInfoViewModel";
import { TaxRateViewModel } from "../../models/masTaxRate/taxRateViewModel";
import { kbnInfoUtil } from "./kbnInfoUtil";
import "../usefuls/utilities";

export class journalControlUtil {
    /** 消費税率入力区分定義：入力可 */
    static readonly InpKbnOK: number = 0;

    /** 消費税率入力区分定義：入力不可 */
    static readonly InpKbnNG: number = 1;

    /** 軽減税率の場合のクラスコード */
    static readonly ReducedTaxClassCD: number = 1;

    /** 平成31年9月の10%入力区分定義：入力可 */
    static readonly MaeInpKbnEnable: number = 1;

    /** 平成31年9月の10%入力区分定義：入力不可 */
    static readonly MaeInpKbnDisable: number = 0;

    private static swkControlInfo: SwkControlInfoViewModel;
    public static initialize(swkControlInfoData: SwkControlInfoViewModel) {
        journalControlUtil.swkControlInfo = swkControlInfoData;
    }

    /** 会社基本情報.決算開始年月日 */
    public static getKStDate(): Date {
        return journalControlUtil.swkControlInfo.KStDate;
    }

    /** 会社基本情報.決算終了年月日 */
    public static getKEdDate(): Date {
        return journalControlUtil.swkControlInfo.KEdDate;
    }

    /** 会社基本情報.データ区分（発生区分） */
    public static getDataKbn(): number {
        return journalControlUtil.swkControlInfo.DataKbn;
    }

    /** 会社基本情報.メール会計区分 */
    public static getComKbn3(): number {
        return journalControlUtil.swkControlInfo.ComKbn3;
    }

    /** 会社基本情報.公益法人区分 */
    public static getComKbn4(): number {
        return journalControlUtil.swkControlInfo.ComKbn4;
    }

    /** 会社基本情報.ﾌﾘｰｺｰﾄﾞに使用する記号 */
    public static getDTName7(): string {
        return journalControlUtil.swkControlInfo.DTName7;
    }

    /** 消費税基本情報.会計処理区分 */
    public static getAccProKbn(): number {
        return journalControlUtil.swkControlInfo.AccProKbn;
    }

    /** 消費税基本情報.仮受消費税 端数処理方法 */
    public static getUkeAdjust(): number {
        return journalControlUtil.swkControlInfo.UkeAdjust;
    }

    /** 消費税基本情報.仮払消費税 端数処理方法 */
    public static getHaraiAdjust(): number {
        return journalControlUtil.swkControlInfo.HaraiAdjust;
    }

    /** 消費税基本情報.簡易課税区分 */
    public static getKaniKbn(): number {
        return journalControlUtil.swkControlInfo.KaniKbn;
    }

    /** 消費税基本情報.簡易課税基準業種 */
    public static getBasedType(): number {
        return journalControlUtil.swkControlInfo.BasedType;
    }

    /** 消費税基本情報.税抜処理区分 */
    public static getMonthEnd(): number {
        return journalControlUtil.swkControlInfo.MonthEnd;
    }

    /** 消費税基本情報.輸入取引区分 */
    public static getImportKbn(): number {
        return journalControlUtil.swkControlInfo.ImportKbn;
    }

    /** 消費税基本情報.元帳出力処理区分 */
    public static getTaxKbn4(): number {
        return journalControlUtil.swkControlInfo.TaxKbn4;
    }

    /**
     * 会社の仕入税額控除の記載事項の入力方法
     */
    public static getCompanySInpKbn(): number {
        return journalControlUtil.swkControlInfo.CompanySInpKbn;
    }

    /**
     * 定型仕訳ウィンドウ表示区分
     */
    public static getFixedWindowDisplayKbn(): number {
        return journalControlUtil.swkControlInfo.FixedWindowDisplayKbn;
    }

    /** 特定科目情報.複合科目コード */
    public static getComplexAccounts(): number {
        return journalControlUtil.swkControlInfo.ComplexAccounts;
    }

    /** 特定科目情報.資金複合科目コード */
    public static getFundCompoundSubjectKmk(): number {
        return journalControlUtil.swkControlInfo.FundCompoundSubjectKmk;
    }

    /** 特定科目情報.非会計複合科目コード */
    public static getNonAccountingKmk(): number {
        return journalControlUtil.swkControlInfo.NonAccountingKmk;
    }

    /** 特定科目情報.仮受消費税コード */
    public static getProvisionalReceptionTax(): number {
        return journalControlUtil.swkControlInfo.ProvisionalReceptionTax;
    }

    /** 特定科目情報.仮払消費税コード */
    public static getProvisionalPaymentTax(): number {
        return journalControlUtil.swkControlInfo.ProvisionalPaymentTax;
    }

    /** 特定科目情報.売上調整勘定 */
    public static getAdjustmentCounting(): number {
        return journalControlUtil.swkControlInfo.AdjustmentCounting;
    }

    /** 特定科目情報.仕入調整勘定 */
    public static getAdjustmentJournalize(): number {
        return journalControlUtil.swkControlInfo.AdjustmentJournalize;
    }

    /** 特定科目情報.貸倒償却1 */
    public static getChargeOff1(): number {
        return journalControlUtil.swkControlInfo.ChargeOff1;
    }

    /** 特定科目情報.貸倒償却2 */
    public static getChargeOff2(): number {
        return journalControlUtil.swkControlInfo.ChargeOff2;
    }

    /** 特定科目情報.貸倒償却3 */
    public static getChargeOff3(): number {
        return journalControlUtil.swkControlInfo.ChargeOff3;
    }

    /** 特定科目情報.貸倒償却4 */
    public static getChargeOff4(): number {
        return journalControlUtil.swkControlInfo.ChargeOff4;
    }

    /** 特定科目情報.貸倒償却5 */
    public static getChargeOff5(): number {
        return journalControlUtil.swkControlInfo.ChargeOff5;
    }

    /** ユーザ基本情報.CRキーの割付け */
    public static getKeyAllocationCR(): number {
        return journalControlUtil.swkControlInfo.KeyAllocationCR;
    }

    /** ユーザ基本情報.00キーの割付け */
    public static getKeyAllocation00(): number {
        return journalControlUtil.swkControlInfo.KeyAllocation00;
    }

    /** ユーザ基本情報.000キーの割付け */
    public static getKeyAllocation000(): number {
        return journalControlUtil.swkControlInfo.KeyAllocation000;
    }

    /** ユーザ基本情報.ウインドウ表示絞込み区分 */
    public static getWindowDisplayRefinersKbn(): number {
        return journalControlUtil.swkControlInfo.WindowDisplayRefinersKbn;
    }

    /** ユーザ基本情報.摘要ウィンドウ表示区分 */
    public static getKmkWinDspDspKbn(): number {
        return journalControlUtil.swkControlInfo.KmkWinDspDspKbn;
    }

    /** ユーザ基本情報.摘要ウィンドウ表示パターン */
    public static getFixingSummaryDisplayPtn(): number {
        return journalControlUtil.swkControlInfo.FixingSummaryDisplayPtn;
    }

    /** ユーザ基本情報.摘要ウィンドウ選択方法 */
    public static getFixingSummaryChoice(): number {
        return journalControlUtil.swkControlInfo.FixingSummaryChoice;
    }

    /** ユーザ基本情報.摘要カナ漢字優先区分 */
    public static getRemarksKanaKanjiKbn(): number {
        return journalControlUtil.swkControlInfo.RemarksKanaKanjiKbn;
    }

    /** ユーザ基本情報.消費税コードウィンドウ表示区分 */
    public static getTaxCodeDisplayKbn(): number {
        return journalControlUtil.swkControlInfo.TaxCodeDisplayKbn;
    }

    /** ユーザ基本情報.製造科目表示区分 */
    public static getManufacturingKmkDispKbn(): number {
        return journalControlUtil.swkControlInfo.ManufacturingKmkDispKbn;
    }

    /** ユーザ基本情報.和暦/西暦区分 */
    public static getYearKbn(): number {
        return journalControlUtil.swkControlInfo.YearKbn;
    }

    /** システム基本情報.仕訳件数/1ヶ月 */
    public static getMaxNumberSwkNumberPerMonth(): number {
        return journalControlUtil.swkControlInfo.MaxNumberSwkNumberPerMonth;
    }

    /** システム基本情報.伝票明細件数 */
    public static getMaxNumberDenDetailsNumber(): number {
        return journalControlUtil.swkControlInfo.MaxNumberDenDetailsNumber;
    }

    /** システム基本情報.仕訳金額桁数 */
    public static getMaxNumberSwkSumLength(): number {
        return journalControlUtil.swkControlInfo.MaxNumberSwkSumLength;
    }

    /** システム基本情報.摘要文字数 */
    public static getMaxNumberTekiyoWordCount(): number {
        return journalControlUtil.swkControlInfo.MaxNumberTekiyoWordCount;
    }

    /** システム基本情報.伝票NO桁数 */
    public static getMaxNumberDenNoLength(): number {
        return journalControlUtil.swkControlInfo.MaxNumberDenNoLength;
    }

    /** システム基本情報.伝票NO属性 */
    public static getMaxNumberDenNoAttribute(): number {
        return journalControlUtil.swkControlInfo.MaxNumberDenNoAttribute;
    }

    /** システム基本情報.本店名称 */
    public static getNameMainStore(): string {
        return journalControlUtil.swkControlInfo.NameMainStore;
    }

    /** システム基本情報.支店名称 */
    public static getNameBranchStore(): string {
        return journalControlUtil.swkControlInfo.NameBranchStore;
    }

    /** 仕訳入力ウィンドウ表示区分.ウィンドウ表示区分(固定摘要) */
    public static getFixingTekiyoDispKbn(): number {
        return journalControlUtil.swkControlInfo.FixingTekiyoDispKbn;
    }

    /** 消費税率リスト */
    public static getRawTaxRateList(): TaxRateViewModel[] {
        return journalControlUtil.swkControlInfo.TaxRate;
    }

    /**
     * イニシャライズが終了しているかを判定する。
     * swkControlViewModelが読み込まれていれば、true、読み込まれていなければfalseを返す。
     */
    public static isInitialized(): boolean {
        if (journalControlUtil.swkControlInfo) {
            return true;
        }
        return false;
    }

    /**
     * 伝票日付に対応した消費税率リストを返す。
     * /Mjs.Acelink.VKZ.Business/Common/MasTaxRate/MasTaxRateInfo.cs の MasTaxRateInfo.GetTaxRateList(DateTime? targetDate) をクライアントに移植した関数。
     * @param denDate 伝票日付
     * @param addStdFlg 標準税率を返すフラグ(true:標準税率を返す、false:標準税率を返さない、未指定の場合true)
     * @param addInpNGRateFlg 入力不可税率を返すフラグ(true:入力不可税率を返す、false:入力不可税率を返さない、未指定の場合true)
     * @param addReducedTaxRate 軽減税率を返すフラグ(true:軽減税率を返す、false:軽減税率を返さない、未指定の場合true)
     * @param addMaeInp 平成31年9月の10%入力許可フラグ
     */
    public static getTaxRateList(denDate: Date, addStdFlg: boolean = false, addInpNGRateFlg: boolean = false, addReducedTaxRate: boolean = true, addMaeInp: boolean = true): TaxRateViewModel[] {

        // Filter結果を返す。
        return journalControlUtil.swkControlInfo.TaxRate.filter((value: TaxRateViewModel, index: number, source: TaxRateViewModel[]) => {

            // 標準税率なので不要
            if ((addStdFlg == false) && (value.RateCD == TaxRate.StandardTaxRate)) {
                return false;
            }

            // 入力不可税率なので不要
            if ((addInpNGRateFlg == false) && (value.InpKbn == journalControlUtil.InpKbnNG)) {
                return false;
            }

            // 軽減税率なので不要
            if ((addReducedTaxRate == false) && (value.ClassCD == journalControlUtil.ReducedTaxClassCD)) {
                return false;
            }

            // 伝票日付が取得できない/指定されていない時は、その会社のその会計年度が対象としている消費税率を全て返す。
            if (!denDate) {
                return true;
            }

            /** その消費税率の適用開始年月日 */
            let startYMD = value.StartYMD;

            if (kbnInfoUtil.getTenPercentInputSegmentInSep() == journalControlUtil.MaeInpKbnEnable && addMaeInp) {
                startYMD = value.StartYMD_MaeInp;
            }
            // StartYMDが上のものはすべて含める。
            if (startYMD <= denDate) {
                return true;
            }

            return false;
        });
    }

    /**
     * 伝票日付に対応したソート済みの消費税リストを返却する
     * @param denDates 消費税率取得の基準となる伝票日付
     * @param addStdFlg 標準税率取得フラグ
     * @param addInpNGRateFlg 入力不可税率取得フラグ
     * @param addReducedTaxRate 軽減税率取得フラグ
     * @param addMaeInp 9月の10%入力フラグ
     */
    public static getSortedTaxRateList(denDates: Array<Date>, addStdFlg: boolean = false, addInpNGRateFlg: boolean = false, addReducedTaxRate: boolean = true, addMaeInp = !addStdFlg): TaxRateViewModel[] {

        let taxRateList = journalControlUtil.getTaxRateList(denDates[0], addStdFlg, addInpNGRateFlg, addReducedTaxRate, addMaeInp);

        // 消費税率リストが取得できなかった時、第二日付で取得しなおす
        if ((taxRateList == null || taxRateList.length === 0) && denDates[1])
            taxRateList = journalControlUtil.getTaxRateList(denDates[1], addStdFlg, addInpNGRateFlg, addReducedTaxRate, addStdFlg);

        // 空の配列を返す
        if (taxRateList == null || taxRateList.length === 0)
            return new Array<TaxRateViewModel>();

        return taxRateList.sort((a: TaxRateViewModel, b: TaxRateViewModel) => {

            if (a.TaxName === "標準税率") return -1;
            if (b.TaxName === "標準税率") return 1;

            const aT = a.StartYMD.getTime();
            const bT = b.StartYMD.getTime();

            if (aT < bT) return 1;

            if (aT > bT) return -1;

            if (aT === bT) return a.TaxRate < b.TaxRate ? 1 : -1;

            return undefined!;
        });
    }

    /**
     * 伝票日付に対応した、通常の消費税率ViewModelを返却する
     * ※標準税率、入力不可税率、軽減税率は対象外
     * 例：2019/09/30→8.00%" ／ 2019/10/01→"10.00%"
     * @param denDate 消費税率取得の基準となる伝票日付
     */
    public static getTaxRateViewModelByDenDate(denDate: Date): TaxRateViewModel {
        if (denDate == null || !journalControlUtil.isInitialized()) return null!;
        // ソート済み消費税リストを取得する
        let sortedTaxRateList = journalControlUtil.getSortedTaxRateList([denDate], false, false, false, false);
        // 最も最近の消費税率ViewModelを返す
        return (sortedTaxRateList && sortedTaxRateList.length > 0) ? sortedTaxRateList[0] : null!;
    }

    /**
     * 標準日付を取得する
     * @param sortedList
     */
    private static getStandardDate(sortedList: TaxRateViewModel[]): Date {

        if (sortedList == null || sortedList.length === 0) return null!;

        const vm = sortedList.singleOrNull((v: TaxRateViewModel) => v.StartYMD != null);

        return vm == null ? null! : vm.StartYMD;
    }

    private static getTaxRatebyDenDate(denDate: Date, stdFlg: boolean = false): TaxRateViewModel {

        let record: Array<TaxRateViewModel> = journalControlUtil.getSortedTaxRateList(stdFlg ? [null!] : [denDate], false, false, true, !stdFlg);

        // TaxRateレコードを返す(念のため、Length=0を警戒)。
        if (record.length > 0) {
            return record[0];
        }
        return undefined!;
    }

    private static getTaxRatebyTaxRateKbn(taxRateKbn: number): TaxRateViewModel {

        // TaxRateでFilterする。その際、taxRateKbnには国税/地方税の区分が入力されていても、区分は被ることが
        // ないため、orでつないだ式で取得する。
        // 1~3：消費税
        // 101、103：国税
        // 102、 104：地方税
        let record: Array<TaxRateViewModel> = journalControlUtil.swkControlInfo.TaxRate.where(r => {

            if (r.TaxName === "標準税率" && taxRateKbn === 999) return true;

            if (taxRateKbn === 999 || r.TaxName === "標準税率") return false;

            if (taxRateKbn === 0) {
                // 0の場合は調査はRateCDのみ
                return r.RateCD === taxRateKbn;   // 消費税の条件
            } else {
                // それ以外は全区分をチェックする
                return r.RateCD === taxRateKbn || // 消費税の条件
                    r.LoRateCD === taxRateKbn ||  // 地方税の条件
                    r.StRateCD === taxRateKbn;    // 国税の条件
            }
        });

        // TaxRateレコードを返す(念のため、Length=0を警戒)。
        if (record.length > 0) {
            return record[0];
        }
        return null!;
    }

    /**
     * 消費税率区分から、ViewModelListを特定し返す。valueにDateを指定する場合、function getTaxRateViewModelByDenDate(denDate: Date): models.masTaxRate.TaxRateViewModel も検討すること。
     * @param value number型で指定する場合TaxRateKbn、Date型で指定する場合DenDate
     * @param stdFlg
     */
    public static getTaxRateViewModel(value: number | Date, stdFlg: boolean = false): TaxRateViewModel {

        if (value != null && value != undefined) {
            if (typeof (value) == "number") {
                // rateKbnで取得
                return journalControlUtil.getTaxRatebyTaxRateKbn(<number>value);
            } else {
                // denDateで取得
                return journalControlUtil.getTaxRatebyDenDate(<Date>value, stdFlg);
            }
        }
        return undefined!;
    }

    /**
     * 消費税率区分と日付から例外税率か判定する
     * true: 例外税率, false: 標準税率
     * @param taxRateKbn
     * @param dates
     * @param stdFlg
     */
    public static isExcptRate(taxRateKbn: number, dates: Array<Date>, stdFlg: boolean = false): boolean {

        if (taxRateKbn == null) return false;

        // 定型登録時は会計年度を取得
        dates = stdFlg ? [null!] : dates;

        // ソートされた消費税率リストを取得する
        let sortedList = journalControlUtil.getSortedTaxRateList(dates, false, false, true, !stdFlg);

        if (sortedList == null || sortedList.length === 0) return false;

        let date = dates[0] ? dates[0] : dates[1];

        if (date) sortedList = sortedList.filter(v => v.StartYMD.getTime() <= date.getTime());

        // 最新の適用開始年月日を取得する
        const latestStartDate = journalControlUtil.getStandardDate(sortedList);

        const rateCdList: number[][] = sortedList
            .filter(v => v.StartYMD.getTime() === latestStartDate.getTime())
            .sort((a, b) => b.TaxRate - a.TaxRate)
            .map((v: TaxRateViewModel) => [v.RateCD, v.StRateCD, v.LoRateCD, 999]);

        return !rateCdList.some(v => v.some(v => v === taxRateKbn));
    }

    /**
     * 消費税率区分と伝票日付から消費税率改正施行前か判断する
     * @param taxRateKbn
     * @param dates
     * @param stdFlg
     */
    public static isBeforeEnforcementRate(taxRateKbn: number, dates: Date[], stdFlg: boolean = false): boolean {

        const sortedList = journalControlUtil.getSortedTaxRateList(stdFlg ? [null!] : dates, false, false, true, !stdFlg);

        // 標準日付を取得する
        const standardDate = journalControlUtil.getStandardDate(sortedList);

        const taxRateViewModel = journalControlUtil.getTaxRatebyTaxRateKbn(taxRateKbn);

        if (!taxRateViewModel) return false;

        const afterStartDate = taxRateViewModel.StartYMD;

        if (!standardDate || !afterStartDate) return false;

        return standardDate.getTime() < afterStartDate.getTime();
    }

    /**
     * 指定されたコードが複合科目か、資金複合化をチェックする
     * @param gCode
     */
    public static isComplexAccounts(gCode: number): boolean;
    public static isComplexAccounts(gCode: string): boolean;
    public static isComplexAccounts(gCode: any): boolean {

        // コードが文字列の可能性があるので、一旦文字列化して数値に変換
        let code: number = parseInt(gCode.toString());

        // 複合科目
        if (code === journalControlUtil.swkControlInfo.ComplexAccounts) {
            return true;
        }

        // 資金複合
        if (code === journalControlUtil.swkControlInfo.FundCompoundSubjectKmk) {
            return true;
        }

        // 資金複合/複合ではない
        return false;
    }

    /**
     * 公益法人かどうかを返す
     */
    public static isCharitable(): boolean {
        // 公益法人区分を取得
        let comKbn4 = journalControlUtil.getComKbn4();

        switch (comKbn4) {
            case CharitableKbn.Charitable: // 公益法人
            case CharitableKbn.School:     // 学校法人
            case CharitableKbn.Religious:  // 宗教法人
            case CharitableKbn.Social:     // 社会福祉法人
                // 公益法人である
                return true;

            default:
                return false;
        }
    }

}
