import Axios, { AxiosInstance } from 'axios';

/**
 * ISO形式の日付時刻文字列であればtrueを返す
 * @param value 判定対象のデータ
 */
function isIsoDateString(value: any): boolean {
  const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?$/;
  return value && (typeof value === "string") && isoDateFormat.test(value);
}

/**
 * サーバから受信したレスポンスデータ内にISO形式の日付時刻文字列があれば、Dateオブジェクトに置き換える。
 * https://stackoverflow.com/questions/65692061/casting-dates-properly-from-an-api-response-in-typescript
 * @param body サーバから受信したレスポンスデータ(の一部)
 */
function handleDates(body: any) {
  if ((body === null) || (body === undefined) || (typeof body !== "object")) {
    return body;
  }
  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) {
      body[key] = new Date(value);
    }
    else if (typeof value === "object") {
      handleDates(value);
    }
  }
}

/** 
 * Axiosのインスタンスを取得する。
 * このインスタンスを使って通信した場合、サーバから受信したレスポンスデータ内にISO形式の日付時刻文字列があれば、Dateオブジェクトに置き換える。
 * 
 * 使い方
 * getAxiosInstance().get<AbcXyzViewModel>(url, { params: { abc: 123, def: 456 } })
 * .then(response => { aaa })
 * .catch(error => { bbb })
 * .finally(() => { ccc });
 * 
 * getAxiosInstance().post<AbcXyzViewModel>(url, defViewModel)
 * .then(response => { ddd })
 * .catch(error => { eee })
 * .finally(() => { fff });
 */
export function getAxiosInstance(): AxiosInstance {
  let axiosInstance = Axios.create();
  axiosInstance.interceptors.response.use(originalResponse => {
    handleDates(originalResponse.data);
    return originalResponse;
  });
  return axiosInstance;
}