import axios, { AxiosRequestConfig } from 'axios';
/* eslint-disable import/first */
import { session as store } from 'store2';
import { isLogoutCallBackDefined, callLogoutCallBack } from '../stores/LogoutCallbck';

const tokenQueryKey = 'token';
const tokenStoreKey = 'ai-audit-token';
const shouldRedirectStoreKey = 'ai-audit-should-redirect';

const settingGlobal = (urlQueries: string) => {
  axios.defaults.headers['Cache-Control'] = 'no-cache, no-store';
  axios.defaults.headers['Pragma'] = 'no-cache';

  const queries = {};
  if (urlQueries) {
    urlQueries.split('&').forEach((query: string) => {
      const queryValue = query.split('=');
      queries[queryValue[0]] = queryValue[1];
    });
  }

  if (queries[tokenQueryKey]) {
    store.set(tokenStoreKey, queries[tokenQueryKey]);
    store.set(shouldRedirectStoreKey, true);
  }

  if (store.has(tokenStoreKey)) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.get(tokenStoreKey);
  }

  // 通信処理の同時実行防止
  const syncRequestQueue: string[] = [];
  async function syncRequest(key: string) {
    const id = key + Date.now();
    syncRequestQueue.push(id);
    while (syncRequestQueue.indexOf(id) > 0) {
      await sleep(100);
    }
  }

  // 通信処理前の共通処理
  async function preRequest(config: AxiosRequestConfig) {
    if (config.url) {
      await syncRequest(config.url);
    }
    return config;
  }
  axios.interceptors.request.use(preRequest);

  // 通信処理完了を通知
  const syncRequestRelease = () => {
    syncRequestQueue.shift();
  };

  // 通信完了時の共通処理
  axios.interceptors.response.use(
    response => {
      syncRequestRelease();
      return response;
    },
    error => {
      syncRequestRelease();
      if (error.message == "Network Error") {
        return Promise.reject(error);  
      }
      if (error.response.status == 401 && isLogoutCallBackDefined()) { 
        // 認証エラーであり、認証情報がクライアントに残っている場合はログアウトを実行
        callLogoutCallBack();
        return Promise.reject(error);  
      }
      if (error.response.data === null || typeof error.response.data === 'undefined' || typeof error.response.data.message === 'undefined') {
        // 想定外のエラーの場合はエラー画面へ遷移する
        window.location.href = window.location.origin + '/StatusError?statusCode=' + error.response.status;
      }
      return Promise.reject(error);
    }
  );
};
const shouldRedirect = () => {
  var should = store.has(shouldRedirectStoreKey);
  store.remove(shouldRedirectStoreKey);
  return should;
};
const clearTokenHeader = () => {
  if (store.has(tokenStoreKey)) {
    store.remove(tokenStoreKey)
  }
};
const sleep = (ms: number) =>
  new Promise<void>(resolve => {
    setTimeout(() => resolve(), ms);
  });

export default {
  settingGlobal,
  shouldRedirect,
  clearTokenHeader
};
