import React from 'react';
import Axios from 'axios';
import * as wjGrid from 'wijmo/wijmo.grid';

import IconButton from '../../../../components/molecules/CMN/IconButton/IconButton';
import FileExcel from '../../../../components/atoms/Icon/FileExcel';
import Close from '../../../../components/atoms/Icon/Close';
import Popup from '../../../../components/molecules/CMN/Popup/Popup';
import AuditResultGrid from '../../../../components/molecules/CMN/Grid/AuditResultGrid';
import ExcelOutputsetting from '../../../organisms/A000/ExcelOutputsetting';
import * as labels from '../../../../constants/labels';
import * as constants from '../../../../constants/constant';
import { ConfStatusEnum, CmpValueEnum } from '../../../../constants/dataMaps';
import { createWjDataMap } from '../../../../utils/dataMapsUtils';
import { AuditResultRemarkIncDecViewModel } from '../../../../models/auditResultRemarkIncDecViewModel';
import { setAlertMessage, AlertKbnEnum } from '../../../../stores/AlertMessage';
import { MasterKbn } from '../../../../VKZ/core/constants/constant';

export const initialUrl = '/api/v1/AuditResultRemarkIncDec/Initial';

type AuditResultRemarkIncDecprops = {
  activated: boolean;
  month: number;
  onClose: () => void;
  auditTarget: number;
  hojyoCode: string;
};
const initializationViewModel: AuditResultRemarkIncDecViewModel = {
  TMonth: '',
  Results: []
};

// tabIndexの初期値
const baseTabIndex = 200;

const AuditResultRemarkIncDec: React.FC<AuditResultRemarkIncDecprops> = props => {
  const { activated, month, onClose, auditTarget, hojyoCode } = props;
  const [excelPopupActivated, setExcelPopupActivated] = React.useState(false);
  const [excelPopupServiceParams, setExcelPopupServiceParams] = React.useState('');

  //フィルターExcel出力設定
  const [filters, setFilters] = React.useState('');

  const [viewModel, setViewModel] = React.useState<AuditResultRemarkIncDecViewModel>(initializationViewModel);

  const handleOnClose = () => {
    setViewModel(new AuditResultRemarkIncDecViewModel());
    onClose();
  };

  const handleOnExcelClick = () => {
    setExcelPopupActivated(true);
  };

  const handleOnExcelClose = () => {
    setExcelPopupActivated(false);
  };

  //フィルターExcel出力設定
  const handleOnFilterChanged = (filterJson: string) => {
    setFilters(filterJson);
  };

  const firstFocusElement = React.useRef<any>();
  const radioAllRef = React.useRef<any>();
  const radioLatestRef = React.useRef<any>();
  const gridRef = React.useRef<any>();
  const gridInnerRef = React.useRef<any>();
  const popupRef = React.useRef<any>();

  const radiofocus = () => {
    if (radioAllRef.current.checked === true) {
      radioAllRef.current.focus();
    } else {
      radioLatestRef.current.focus();
    }
  };

  /** キーボード操作（キーダウン） */
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 9) {
      if (event.shiftKey === false) {
        // タブキー押下時

        const target: HTMLElement = event.target as HTMLElement;
        if (target.tabIndex < baseTabIndex || target.tabIndex >= baseTabIndex + 4) {
          // 最後のフォーカス又はフォーカスが当たっていない場合

          firstFocusElement.current.focus();
          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
      } else {
        // シフト＋タブキー押下時

        const target: HTMLElement = event.target as HTMLElement;
        if (target.tabIndex <= firstFocusElement.current.tabIndex || target.tabIndex > baseTabIndex + 4) {
          // 最初のフォーカス又はフォーカスが当たっていない場合

          // 最後のエレメントにフォーカス
          if (gridRef.current) {
            const flexGrid = gridRef.current.control as wjGrid.FlexGrid;
            flexGrid.focus();
          }
          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }
  };
  /** Grid */
  const handleKeyDownGrid = (event: KeyboardEvent) => {
    if (event.keyCode === 9) {
      if (event.shiftKey === false) {
        // タブキー押下時

        firstFocusElement.current.focus();
        // 後のイベントをキャンセル
        event.preventDefault();
        event.stopPropagation();
      } else {
        // シフト＋タブキー押下時

        radiofocus();
        // 後のイベントをキャンセル
        event.preventDefault();
        event.stopPropagation();
      }
    }
  };

  React.useEffect(() => {
    if (activated && viewModel.Results != undefined && viewModel.Results.length > 0) {
      // 画面表示完了後に実行
      setTimeout(() => {
        if (popupRef.current) {
          // ポップアップにキーダウンイベントのフック
          popupRef.current.addEventListener('keydown', handleKeyDown);
        }
        if (gridInnerRef.current) {
          // ポップアップにキーダウンイベントのフック
          gridInnerRef.current.addEventListener('keydown', handleKeyDownGrid);
        }
      });
    }
  }, [viewModel.Results]);

  //通信処理(初期表示データ作成)
  const initialOnGet = (url: string, month: number) => {
      // 「\」「/」を退避するために、全角に変換する
      let hojyoCodeForUrl = hojyoCode.replaceAll('/', '／').replaceAll('\\', '￥');
      // WebAPI一覧のURLを作成
      const encodedUrl = [url, encodeURI(month.toString()), encodeURI(auditTarget.toString()), encodeURIComponent(hojyoCodeForUrl)].join('/');
    Axios.get<AuditResultRemarkIncDecViewModel>(encodedUrl)
      .then(response => {
        setViewModel(response.data);
      })
      .catch(error => {
        if (typeof error.response.data !== 'undefined') {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
        }
        handleOnClose();
      });
  };

  // 通信処理の実行
  React.useEffect(() => {
    if (activated) {
      setViewModel(initializationViewModel);
      initialOnGet(initialUrl, month);
    }
    setExcelPopupServiceParams(
      JSON.stringify({
          NMonth: month,
          AuditTarget: auditTarget,
          HojyoCode: hojyoCode
      })
    );
  }, [initialUrl, month, activated]);

  return viewModel.Results == undefined || viewModel.Results.length < 1 ? null : (
    <Popup
      isOpen={activated}
      onCloseClick={handleOnClose}
      header={[
        labels.AIKADT002000007_FUNCTION_NAME,
        labels.AIKADT002000007_FUNCTION_HEADER_TARGETMONTH + viewModel.TMonth
      ].join('　')}
      size='xl'
      innerRef={popupRef}
      headerIcon={
        <div>
          <IconButton
            icon={<FileExcel className='icon-lg' />}
            className='text-white'
            onClick={handleOnExcelClick}
            tabIndex={baseTabIndex + 1}
            innerRef={firstFocusElement}
          />
          <IconButton
            icon={<Close className='icon-lg' />}
            className='ml-2 text-white'
            onClick={handleOnClose}
            tabIndex={baseTabIndex + 2}
          />
        </div>
      }
    >
      {/* フィルターExcel出力設定 */}
      <AuditResultGrid
        gridClassName='AuditResultRemarkIncDec-grid'
        columns={auditTarget == MasterKbn.CountingKmk ? gridColumns : gridBmnColumns}
        sources={viewModel.Results}
        execDate={viewModel.LastExecDate}
        radioTabIndex={baseTabIndex + 3}
        radioAllRef={radioAllRef}
        radioLatestRef={radioLatestRef}
        innerRef={gridInnerRef}
        gridRef={gridRef}
        gridTabIndex={baseTabIndex + 4}
        onFilterChanged={handleOnFilterChanged}
      />

      <ExcelOutputsetting
        activated={excelPopupActivated}
        onClose={handleOnExcelClose}
        templateId={constants.AIKADT002000007E01}
        // フィルターExcel出力設定
        filters={filters}
        // ヘッダー情報
        headerLeft={[labels.AIKADT002000007_FUNCTION_HEADER_TARGETMONTH, viewModel.TMonth].join('')}
        // パラメーター
        serviceParams={excelPopupServiceParams}
      />
    </Popup>
  );
};
export default AuditResultRemarkIncDec;

const gridColumns = [
  { header: labels.AIKADT002000007_GRID_HEADER_SEQUENCE, binding: 'No', width: 60, align: 'center', format: 'd' },
  { header: labels.AIKADT002000007_GRID_HEADER_PATTERN, binding: 'RulePatternName', width: 170, inputType: 'tooltip' },
  {
    header: labels.AIKADT002000007_GRID_HEADER_SUBJECTSCODE,
    binding: 'KmkCode',
    width: 100,
    align: 'center',
    format: 'd'
  },
  { header: labels.AIKADT002000007_GRID_HEADER_SUBJECTSNAME, binding: 'KmkName', width: '*', minWidth: 140 },
  {
    header: labels.AIKADT002000007_GRID_HEADER_AUXILIARYCODE,
    binding: 'HojyoCode',
    width: 140,
    align: 'center',
    inputType: 'blankIfGray'
  },
  {
    header: labels.AIKADT002000007_GRID_HEADER_AUXILIARYNAME,
    binding: 'HojyoName',
    width: '*',
    minWidth: 140,
    inputType: 'blankIfGray'
  },
  {
    header: labels.AIKADT002000007_GRID_HEADER_PRICE,
    binding: 'InpSum',
    width: 110,
    align: 'right',
    inputType: 'price'
  },
  {
    header: labels.AIKADT002000007_GRID_HEADER_COMPARISONMONTH,
    binding: 'CmpTMonth',
    width: 110,
    align: 'center'
  },
  {
    header: labels.AIKADT002000007_GRID_HEADER_COMPARISONVALUE,
    binding: 'CmpValue',
    width: 130,
    dataMap: createWjDataMap(CmpValueEnum)
  },
  {
    header: labels.AIKADT002000007_GRID_HEADER_INCREASEANDDECREASERATETHRESHOLD,
    binding: 'SkiValue',
    width: 85,
    align: 'right',
    inputType: 'blankIfGray'
  },
  {
    header: labels.AIKADT002000007_GRID_HEADER_INCREASEANDDECREASERATE,
    binding: 'IncDecRate',
    width: 85,
    align: 'right',
    inputType: 'rate'
  },
  {
    header: labels.AIKADT002000007_GRID_HEADER_THRESHOLDVALUEMONEY,
    binding: 'SkiValueSum',
    width: 145,
    align: 'right',
    inputType: 'blankIfGray'
  },
  {
    header: labels.AIKADT002000007_GRID_HEADER_DIFFERENCE,
    binding: 'Difference',
    width: 145,
    align: 'right',
    inputType: 'difference'
  },
  {
    header: labels.AIKADT002000007_GRID_HEADER_STATUS,
    binding: 'ConfStatus',
    width: 100,
    align: 'center',
    dataMap: createWjDataMap(ConfStatusEnum),
    inputType: 'status'
  },
  {
    header: labels.AIKADT002000007_GRID_HEADER_EXECUTINGTIME,
    binding: 'ExecDate',
    width: 150,
    align: 'center',
    dataType: 'Date',
    format: 'yyyy/MM/dd HH:mm:ss'
  }
];

const gridBmnColumns = [
    { header: labels.AIKADT002000007_GRID_HEADER_SEQUENCE, binding: 'No', width: 60, align: 'center', format: 'd' },
    { header: labels.AIKADT002000007_GRID_HEADER_PATTERN, binding: 'RulePatternName', width: 170, inputType: 'tooltip' },
    {
        header: labels.AIKADT002000007_GRID_HEADER_SUBJECTSCODE,
        binding: 'KmkCode',
        width: 100,
        align: 'center',
        format: 'd'
    },
    { header: labels.AIKADT002000007_GRID_HEADER_SUBJECTSNAME, binding: 'KmkName', width: '*', minWidth: 140 },
    {
        header: labels.AIKADT002000007_GRID_HEADER_BMNCODE,
        binding: 'HojyoCode',
        width: 140,
        align: 'center',
        inputType: 'blankIfGray'
    },
    {
        header: labels.AIKADT002000007_GRID_HEADER_BMNSUBJECTS,
        binding: 'HojyoName',
        width: '*',
        minWidth: 140,
        inputType: 'blankIfGray'
    },
    {
        header: labels.AIKADT002000007_GRID_HEADER_PRICE,
        binding: 'InpSum',
        width: 110,
        align: 'right',
        inputType: 'price'
    },
    {
        header: labels.AIKADT002000007_GRID_HEADER_COMPARISONMONTH,
        binding: 'CmpTMonth',
        width: 110,
        align: 'center'
    },
    {
        header: labels.AIKADT002000007_GRID_HEADER_COMPARISONVALUE,
        binding: 'CmpValue',
        width: 130,
        dataMap: createWjDataMap(CmpValueEnum)
    },
    {
        header: labels.AIKADT002000007_GRID_HEADER_INCREASEANDDECREASERATETHRESHOLD,
        binding: 'SkiValue',
        width: 85,
        align: 'right',
        inputType: 'blankIfGray'
    },
    {
        header: labels.AIKADT002000007_GRID_HEADER_INCREASEANDDECREASERATE,
        binding: 'IncDecRate',
        width: 85,
        align: 'right',
        inputType: 'rate'
    },
    {
        header: labels.AIKADT002000007_GRID_HEADER_THRESHOLDVALUEMONEY,
        binding: 'SkiValueSum',
        width: 145,
        align: 'right',
        inputType: 'blankIfGray'
    },
    {
        header: labels.AIKADT002000007_GRID_HEADER_DIFFERENCE,
        binding: 'Difference',
        width: 145,
        align: 'right',
        inputType: 'difference'
    },
    {
        header: labels.AIKADT002000007_GRID_HEADER_STATUS,
        binding: 'ConfStatus',
        width: 100,
        align: 'center',
        dataMap: createWjDataMap(ConfStatusEnum),
        inputType: 'status'
    },
    {
        header: labels.AIKADT002000007_GRID_HEADER_EXECUTINGTIME,
        binding: 'ExecDate',
        width: 150,
        align: 'center',
        dataType: 'Date',
        format: 'yyyy/MM/dd HH:mm:ss'
    }
];
