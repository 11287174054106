import React from 'react';
import classnames from 'classnames';
import {ListGroup, ListGroupItem } from 'reactstrap';

import * as labels from '../../../constants/labels';
import { CooperationEnum } from '../../../constants/enums';

type SideMenuProps = {
  isOpen: boolean;
  className?: string;
  homeMenuOnClick: () => void;
  monthlyMenuOnClick: () => void;
  registerMenuOnClick: () => void;
  masterMenuOnClick: () => void;
  settingMenuOnClick: () => void;
  adminManagementOnClick: () => void;
  cooperation : CooperationEnum | undefined;
};

const SideMenu: React.FC<SideMenuProps> = props => {
  const { isOpen, className, homeMenuOnClick, monthlyMenuOnClick,　registerMenuOnClick, masterMenuOnClick, settingMenuOnClick, adminManagementOnClick } = props;
  const divClassName = isOpen ? 'slideInLeft' : 'slideOutLeft';

  // button
  /*
  const [AdminFlg, setIsAdminFlg] = React.useState(false);
  const onAdminFlgClick = () =>{
    if(AdminFlg){
      setIsAdminFlg(false);
    }else {
      setIsAdminFlg(true);
    }
  };
  const Adminmodal = AdminFlg ? <ListGroupItem tag='button' action onClick={adminManagementOnClick} className='text-white' tabIndex={111}>
                                  {'管理者メニュー'}
                                </ListGroupItem> :
                                '';
  */


    const registerListGroupItem = props.cooperation == CooperationEnum.Other ? (
        <ListGroupItem tag='button' action onClick={registerMenuOnClick} className='text-white' tabIndex={110}>
            {'導入・登録処理'}
        </ListGroupItem>
    ): (null);

    const settingListGroupItem = props.cooperation == CooperationEnum.Other ? (
        <ListGroupItem tag='button' action onClick={settingMenuOnClick} className='text-white' tabIndex={112}>
            {'環境設定'}
        </ListGroupItem>
    ) : (null);

    const adminManagementListGroupItem = props.cooperation == CooperationEnum.Other ? (
        <ListGroupItem tag='button' action onClick={adminManagementOnClick} className='text-white' tabIndex={113}>
            {'管理者用'}
        </ListGroupItem>
    ): (null);

  return (
    <div className={classnames('side-menu animated bg-tertiary h-100 ', divClassName, className)}>
      <ListGroup>
        <ListGroupItem tag='button' action onClick={homeMenuOnClick} className='text-white' tabIndex={108}>
          {labels.AIKCMN001000001_BUTTON_FUNCTION_HOME}
        </ListGroupItem>
        <ListGroupItem tag='button' action onClick={monthlyMenuOnClick} className='text-white' tabIndex={109}>
          {labels.AIKCMN001000001_BUTTON_FUNCTION_MONTHLYAUDIT}
        </ListGroupItem>
        {registerListGroupItem}
        <ListGroupItem tag='button' action onClick={masterMenuOnClick} className='text-white' tabIndex={111}>
          {labels.AIKCMN001000001_BUTTON_FUNCTION_VARIOUSMASTERREGISTRATION}
        </ListGroupItem>
        {settingListGroupItem}
        {adminManagementListGroupItem}
        {/*<Button onClick={onAdminFlgClick}>{'管理者メニューの表示変換'}</Button>*/}
      </ListGroup>
    </div>
  );
};
export default SideMenu;
