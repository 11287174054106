import React from 'react';
import { Input, Label, Button, FormGroup, Form} from 'reactstrap';
import * as wjInput from 'wijmo/wijmo.input';
import PageHeader from '../../../../components/molecules/CMN/PageHeader/PageHeader';
import { LowerActionLayout, LowerActionLayoutAction, LowerActionLayoutContents, LowerActionLayoutHeader } from '../../../../components/templates/LowerActionLayout';
import ExecutingCancel from '../../../../components/molecules/CMN/ExecutingCancel/ExecutingCancel';
import * as labels from '../../../../constants/labels';
import Axios from 'axios';
import { UserViewModel } from '../../../../models/userViewModel';
import { setAlertMessage, AlertKbnEnum } from '../../../../stores/AlertMessage';
import { ComboBox, AutoComplete } from 'wijmo/wijmo.react.input';
import { IdNameList } from '../../../../models/idNameList';
import { bindValueToMessage } from '../../../../utils/messageUtils';
import * as message from '../../../../constants/message';
import ConfirmationPopup from '../../../../components/molecules/CMN/ConfirmationPopup/ConfirmationPopup';
import { setIsLoading } from '../../../../stores/NowLoading';

// フォーム行ごと表示仕様のCSS Class
const FormColClass = 'd-flex';
// 項目ラベルのCSS Class
const FormColLabelClass = 'label-control';
const FormColLabelOldTantoClass = 'label-control-oldtanto';

// バリデートエラーメッセージのCSS Class
const validatErrClass = 'text-danger valiadErrMsg';

// 通信URL
const baseUrl = '/api/v1/TantoManagment';
export const GetInitialUrl = [baseUrl, 'Initial'].join('/'); //初期表示担当者一覧取得
export const GetFormInfoUrl = [baseUrl, 'Get'].join('/'); //選択された担当者情報取得
export const DeleteUserUrl = [baseUrl, 'Delete'].join('/'); //選択された担当者削除
export const SaveUserUrl = [baseUrl, 'CreateUpdate'].join('/'); //担当者保存
// 起動判定URL
const JudgeURL = 'api/v1/CommonStartJudgement/Judge';
// システム履歴記入
const WriteSysHistoryURL = 'api/v1/CommonSystemHistory/Write';

type TantoManagementprops = {
  onClose: () => void;
};

type TantoManagementFormEvent = React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>;

// ViewModelを拡張したクラス
// 拡張箇所は画面では入力し、サーバー側に送る前に入力チェック+登録パラメータ生成に使用する情報です
class ExpansionUserViewModel extends UserViewModel {
  // パスワード（入力用）担当者更新の場合ハッシュされたパスワードが入っている為、入力用は別途管理する
  Password?: string;
  // パスワード（確認）
  PasswordConfirm?: string;
  // メールアドレス（確認）
  MailConfirm?: string;
};

/**
 * 担当者登録・更新画面モード
 */
export enum TantoManageMentPageModeEnum {
  /** 新規登録 */
  New = 0,
  /** 担当者更新 */
  TantoEdit = 1
};

/**
 * 確認ダイアログ呼び出す元区分
 */
 export enum TantoManagementCallEnum {
  /** 新規担当者 */
  New = 0,
  /** 担当者切り替え */
  TantoChange = 1
 }

let prevSelectedUserNameAutoCompleteValue: any = null;

const TantoManagement: React.FC<TantoManagementprops> = props => {
  // 編集中破棄確認ダイアログ表示状態
  const [confirmationActivated, setConfirmationActivated] = React.useState(false);
  // 編集中破棄確認ダイアログ呼び出す元区分
  const [confirmationCallKbn, setConfirmationCallKbn] = React.useState<TantoManagementCallEnum>(TantoManagementCallEnum.New);
  // 編集Flg
  const [inputChangedFlg, setInputChangedFlg] = React.useState(false);
  // 担当者登録・更新の画面モード
  const [pageMode, setPageMode] = React.useState<TantoManageMentPageModeEnum>(TantoManageMentPageModeEnum.New);
  // 担当者変更Flg
  const [changedTantoFlg, setChangedTantoFlg] = React.useState(false);
  // パスワード変更Flg
  const [changePW, setChangePW] = React.useState<boolean>(false);
  // パスワードを表示して入力するFlg
  const [passwordDispKbn, setPasswordDispKbn] = React.useState(false);
  // 担当者情報ViewModel
  const [viewModel, setViewModel] = React.useState<ExpansionUserViewModel>(
    new ExpansionUserViewModel()
  );
  // 担当者一覧用Viewmodel
  const [listViewModel, setListViewModel] = React.useState<UserViewModel>(
    new UserViewModel()
  );
  const [oldLoginId, setOldLoginId] = React.useState('');
  // 初期化処理中Flg
  const [intializeFlg, setIntializeFlg] = React.useState<boolean>(false);

  // 担当者一覧
  const [listCmb, setListCmb] = React.useState<wjInput.AutoComplete>();
  const [selectedTanto, setSelectedTanto] = React.useState<IdNameList>();
  const TantoCmbInitialized = (autoComplete: wjInput.AutoComplete) => {
    setListCmb(autoComplete);
  }

  // 「パスワード変更する」チェックハンドラ
  const handleonChangwPWFlg = (Flg: boolean) => {
    setChangePW(Flg);
    if (!Flg) {
      // パスワード入力情報系をクリア
      setViewModel({ ...viewModel, Password: '', PasswordConfirm: '' });
      setPasswordDispKbn(false);
    }
  }

  // 入力欄の変更によるViewModel更新イベント（子要素から呼び出される）
  const handleInputChange = (event: TantoManagementFormEvent) => {
    const target = event.currentTarget;
    const name = target.name;
    const value = target.value;
    // 入力コンポーネントに応じたViewModelのキーを更新する
    var updateNewViewModel = viewModel;
    updateNewViewModel[name] = value;
    // Wijmo+通常コンポーネント絡みのViewModel場合、
    // 更新した内容が子コンポーネントで呼び出しているWijmoに伝番されないので、選択値キープしつつViewModelを更新する
    if (typeof AuthCmb !== 'undefined') {
      updateNewViewModel.Authority = AuthCmb.selectedValue;
    }
    if (typeof LoginValidCmb !== 'undefined') {
      updateNewViewModel.LoginEnabled = LoginValidCmb.selectedValue;
    }
    setViewModel({ ...updateNewViewModel });

    setInputChangedFlg(true);
  };
  // パスワード表示入力OnOff切り替え
  const handlePasswordDispKbnChange = (value: boolean) => {
    setPasswordDispKbn(value);
  }

  // 権限のコンポーネントを設定（ViewModel伝番されない対策）
  const [AuthCmb, setAuthCmb] = React.useState<wjInput.ComboBox>();
  const [Auth, setAuth] = React.useState<boolean>(AuthEnum[0].value);
  const initializedAuthCmb = (comboBox: wjInput.ComboBox) => {
    if (typeof AuthCmb == 'undefined') {
      setAuthCmb(comboBox);
    }
  };
  // 権限区分ComboBox変更
  const handleOnAuthChanged = () => {
    if((typeof AuthCmb !== 'undefined') && (AuthCmb.selectedItem != undefined)) {
      setInputChangedFlg(true);
      let auth: boolean = AuthCmb.selectedItem.value;
      setAuth(auth);
    }
  }

  // ログインのコンポーネントを設定（ViewModel伝番されない対策）
  const [LoginValidCmb, setLoginValidCmb] = React.useState<wjInput.ComboBox>();
  const [LoginValid, setLoginValid] = React.useState<boolean>(LoginValidEnum[0].value);
  const initializedLoginValidCmb = (comboBox: wjInput.ComboBox) => {
    if (typeof LoginValidCmb == 'undefined') {
      setLoginValidCmb(comboBox);
    }
  };
  // ログイン区分ComboBox変更
  const handleOnLoginValidChanged = () => {
    if((typeof LoginValidCmb !== 'undefined') && (LoginValidCmb.selectedItem != undefined)) {
      setInputChangedFlg(true);
      let loginValid: boolean = LoginValidCmb.selectedItem.value;
      setLoginValid(loginValid);
    }
  }

  // 閉じる
  const handleOnCloseClick = () => {
    props.onClose();
  };

  // 「保存」ボタンクリックハンドラ
  const handleonExecuteClick = () => {
    // メッセージをクリア
    setErrMessage('');
    setValidMsgClear(false);
    // 入力検証開始
    setDoValid(true);
  };

  // 「削除」ボタンクリックハンドラ
  const handleonDeleteClick = () => {
    if(selectedTanto != null){
      deleteOnPost(DeleteUserUrl, selectedTanto);
    }
  };

  // 担当者選択を変更
  const handleSelectedIndexChangedTanto = () => {
    if (inputChangedFlg) {
      setConfirmationCallKbn(TantoManagementCallEnum.TantoChange);
      setConfirmationActivated(true);
    } else {
      selectedIndexChangedTanto();
    }
  }
  // 担当者切り替え処理
  const selectedIndexChangedTanto = () => {
    // 初期化処理中は担当者変更イベントを無効化する
    if (!intializeFlg) {
      // 担当者編集モードに変更
      setPageMode(TantoManageMentPageModeEnum.TantoEdit);
      // 選択された担当者を設定する
      setSelectedTanto(listCmb!.selectedItem);
      // パスワードを変更しない
      setChangePW(false);
      // パスワードを非表示
      setPasswordDispKbn(false);
    } else {
      // 初期化処理中はテキストをクリアしておく
      // これにより初期化中は常に未選択な状態をキープできる
      listCmb!.text = "";
    }
    prevSelectedUserNameAutoCompleteValue = listCmb?.selectedValue;
  }

  // 「新規担当者」ボタンクリックハンドラ
  const handleOnnewTantoClick = () => {
    if (inputChangedFlg) {
      setConfirmationCallKbn(TantoManagementCallEnum.New);
      setConfirmationActivated(true);
    } else {
      newTantoClick();
    }
  }
  // 新規担当者に遷移する処理
  const newTantoClick = () => {
    setValidMsgClear(true);
    setConfirmationActivated(false); 
    // 新規担当者用モデルを設定する
    var newTantoViewModel = new ExpansionUserViewModel();

    // 権限とログイン情報は初期値を設定
    newTantoViewModel.Authority = AuthEnum[0].value;
    newTantoViewModel.LoginEnabled = LoginValidEnum[0].value;
    setViewModel({ ...newTantoViewModel });
    // Wijmoコンポーネントも初期化処理として表示と値をコントロールする
    // viewModelの伝番がwijmoコンポーネントだと失敗するので、
    // 権限とログインは初期値を別途設定する
    if (typeof AuthCmb !== 'undefined') {
      AuthCmb.selectedValue = AuthEnum[0].value;
    }
    if (typeof LoginValidCmb !== 'undefined') {
      LoginValidCmb.selectedValue = LoginValidEnum[0].value;
    }
    // 担当者コンボボックスのテキストをクリア
    if (typeof listCmb !== 'undefined') {
      listCmb.text = "";
    }
    // 新規担当者向け画面モード切替
    setPageMode(TantoManageMentPageModeEnum.New);
    setChangePW(true);
    setPasswordDispKbn(false);
    setInputChangedFlg(false);

    prevSelectedUserNameAutoCompleteValue = null;
  }

  // 確認ダイアログの「はい」ボタンクリックハンドラ
  const handleConfirmationPopupYesClick = () => {
    if (confirmationCallKbn == TantoManagementCallEnum.New) {
      newTantoClick();
    } else {
      selectedIndexChangedTanto();
    }
    setConfirmationActivated(false);
  };
  // 確認ダイアログの「×」ボタンクリックハンドラ
  const handleConfirmationPopupClose = () => {
    if(listCmb) listCmb.selectedValue = prevSelectedUserNameAutoCompleteValue; // 「担当者」の入力を元に戻す
    setConfirmationActivated(false);
  };
　// 確認ダイアログの「いいえ」ボタンクリックハンドラ
  const handleConfirmationPopupNoClick = () => {
    if(listCmb) listCmb.selectedValue = prevSelectedUserNameAutoCompleteValue; // 「担当者」の入力を元に戻す
    setConfirmationActivated(false);
  };

  /* 通信処理 */
  // 初期起動 担当者一覧取得
  const initialOnGet = (url: string) => {
    setIsLoading(true);
    setIntializeFlg(true);
    setErrMessage('');
    Axios.get<UserViewModel>(url)
      .then(response => {
        const newListViewModel = response.data;
        setListViewModel(newListViewModel);
        // 初期化されたら新規担当者モード相当と同じ処理を実行する
        newTantoClick();

        //setTmpListViewModel(deepCopyViewModel(newListViewModel));
        //setTmpListViewModel({...newListViewModel});
      })
      .catch(error => {
        if (typeof error.response.data !== 'undefined') {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
        }
      })
      .finally(() => {
        setIsLoading(false);
        setIntializeFlg(false);
        setInputChangedFlg(false);
      });
  };

  // ビューモデルのディープコピー
  /*const deepCopyViewModel = (vm: UserViewModel) => {
    const copyViewModel = { ...vm };

    // 担当者リストをディープコピー
    if (vm.IdNameList!.length > 0) {
      copyViewModel.IdNameList = [];
      vm.IdNameList!.forEach(vo => {
        copyViewModel.IdNameList!.push({ ...vo });
      });
    }

    return copyViewModel;
  };*/

  // 担当者を変更 担当者情報取得
  const formInfoOnPost = (url: string, selectedVo: IdNameList) => {
    setPageMode(TantoManageMentPageModeEnum.TantoEdit);
    setIsLoading(true);
    setErrMessage('');
    setChangedTantoFlg(true);
    Axios.post<ExpansionUserViewModel>(url, { idNameList: selectedVo })
      .then(response => {
        // 拡張した状態でレスポンス結果を受け取る
        var newViewModel = response.data;
        // メール入力欄（確認）の値もセット
        newViewModel.MailConfirm = newViewModel.Email;
        setViewModel(newViewModel);
        setOldLoginId(response.data.LoginID!);

        // 権限とログインのコンポーネントを設定（ViewModel伝番されない対策）
        if (response.data.Authority != undefined) {
          setAuth(response.data.Authority);
        }
        if (response.data.LoginEnabled != undefined) {
          setLoginValid(response.data.LoginEnabled);
        }
      })
      .catch(error => {
        if (typeof error.response.data !== 'undefined') {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
        }
        // エラーの場合は編集モードに切り替えさせない(デフォルトの新規モードとする)
        setPageMode(TantoManageMentPageModeEnum.New);
      })
      .finally(() => {
        setIsLoading(false);
        setChangedTantoFlg(false);
        setInputChangedFlg(false);
      });
  };

  // 担当者情報削除
  const deleteOnPost = (url: string, selectedVo: IdNameList) => {
    setIsLoading(true);
    setErrMessage('');
    Axios.post(url, { idNameList: selectedVo })
      .then(response => {
        setAlertMessage({
          alerted: true,
          alertKbn: AlertKbnEnum.success,
          message: message.TantoManagement_Success_Delete
        });
        initialOnGet(GetInitialUrl);
      })
      .catch(error => {
        if (typeof error.response.data !== 'undefined') {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
        }
      })
      .finally(() => {
        setIsLoading(false);
        setInputChangedFlg(false);
      });
  };

  // 担当者保存・更新
  const tantoSaveOnPost = (url: string, saveModel: UserViewModel, changePWflg: boolean) => {
    setIsLoading(true);
    setErrMessage('');
    Axios.post<ExpansionUserViewModel>(url, { userViewModel: saveModel, changePWflg: changePWflg })
      .then(response => {
        if (pageMode == TantoManageMentPageModeEnum.TantoEdit) {
          setAlertMessage({
            alerted: true,
            alertKbn: AlertKbnEnum.success,
            message: message.TantoManagement_Success_Update
          });
        } else {
          setAlertMessage({
            alerted: true,
            alertKbn: AlertKbnEnum.success,
            message: message.TantoManagement_Success_Add
          });
        }
        // 担当者の更新処理に成功した場合は、担当者コンボボックスの選択肢情報更新も兼ねて再初期化する
        initialOnGet(GetInitialUrl);
        //const newViewModel = response.data;
        //setViewModel(newViewModel);
        //setSelectedTanto({...IdNameList, UserCode: newViewModel.UserCode, LoginID: newViewModel.LoginID});
      })
      .catch(error => {
        if (typeof error.response.data !== 'undefined') {
          setErrMessage(error.response.data.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
        setInputChangedFlg(false);
      });
  };

  //初期起動
  React.useEffect(() => {
    // 処理判定
    async function JudgeOnPost(url: string, syoriId: string) {
      const status = await Axios.post<number>(url, {syoriId: syoriId});
      if (status.data == 0) {
        // 初期起動
        initialOnGet(GetInitialUrl);
        await Axios.post(WriteSysHistoryURL, {syoriId: syoriId, operation: '処理開始'});
      } else {
        if(status.data == -101) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotUse});
        } else if (status.data == -102) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractOver});
        } else if (status.data == -103) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_Notauthorized});
        } else if (status.data == -104) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotDataSelect});
        } else if (status.data == -105) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractEqual});
        }
        props.onClose();
      }
    }
    JudgeOnPost(JudgeURL, 'AIKCMN005000001');
  }, [GetInitialUrl]);

  //担当者を変更した際
  React.useEffect(() => {
    if(selectedTanto != null || selectedTanto != undefined)
    {
      formInfoOnPost(GetFormInfoUrl, selectedTanto);
      setValidMsgClear(true);
    }
  }, [selectedTanto]);

  /**
   * vaild設定
   */
  const [doValid, setDoValid] = React.useState(false);
  const [validMsgClear, setValidMsgClear] = React.useState(false);
  const [errMessage, setErrMessage] = React.useState('');
  const [isValidLoginID, setIsValidLoginID] = React.useState<boolean>();
  const [isValidPassword, setIsValidPassword] = React.useState<boolean>();
  const [isValidPasswordConfirm, setIsValidPasswordConfirm] = React.useState<boolean>();
  const [isValidTantoname, setIsValidTantoname] = React.useState<boolean>();
  const [isValidMail, setIsValidMail] = React.useState<boolean>();
  const [isValidMailConfirm, setIsValidMailConfirm] = React.useState<boolean>();

  // LoginId検証後処理
  const handleLoginIdValidationEnded = (validationResult: boolean) => {
    setIsValidLoginID(validationResult);
  };

  // パスワード検証後処理
  const handlePasswordValidationEnded = (validationResult: boolean) => {
    setIsValidPassword(validationResult);
  };
  const handlePasswordConfirmValidationEnded = (validationResult: boolean) => {
    setIsValidPasswordConfirm(validationResult);
  };

  // 担当者名検証後処理
  const handleTantonameValidationEnded = (validationResult: boolean) => {
    setIsValidTantoname(validationResult);
  };

  // メール検証後処理
  const handleMailValidationEnded = (validationResult: boolean) => {
    setIsValidMail(validationResult);
  };
  const handleMailConfirmValidationEnded = (validationResult: boolean) => {
    setIsValidMailConfirm(validationResult);
  };
  // 検証後処理
  React.useEffect(() => {
    if (
      typeof isValidLoginID !== 'undefined' &&
      typeof isValidPassword !== 'undefined' &&
      typeof isValidPasswordConfirm !== 'undefined' &&
      typeof isValidTantoname !== 'undefined' &&
      typeof isValidMail !== 'undefined' &&
      typeof isValidMailConfirm !== 'undefined'
    ) {
      if (
        isValidLoginID &&
        isValidPassword &&
        isValidPasswordConfirm &&
        isValidTantoname &&
        isValidMail &&
        isValidMailConfirm
      ) {
        const authKbn = typeof AuthCmb === 'undefined' ? 0 : AuthCmb.selectedValue;
        const loginValidKbn = typeof LoginValidCmb === 'undefined' ? 0 : LoginValidCmb.selectedValue;
        const saveViewModel = {
          ...viewModel,
          UserCode: viewModel.UserCode === undefined ? -1 : viewModel.UserCode,
          LoginID: viewModel.LoginID,
          PasswordHash: (!changePW && pageMode == TantoManageMentPageModeEnum.TantoEdit) ? viewModel.PasswordHash : viewModel.Password,
          UserName: viewModel.UserName === undefined ? viewModel.LastName + ' ' + viewModel.FirstName : viewModel.UserName,
          LastName: viewModel.LastName,
          FirstName: viewModel.FirstName,
          LastNameKana: viewModel.LastNameKana === undefined ? '' : viewModel.LastNameKana,
          FirstNameKana: viewModel.FirstNameKana === undefined ? '' : viewModel.FirstNameKana,
          Email: viewModel.Email === undefined ? '' : viewModel.Email,
          LoginEnabled: loginValidKbn,
          Authority: authKbn
        };
        var changePWflg = (pageMode == TantoManageMentPageModeEnum.New) ? true : changePW;
       
        // 保存呼び出し
        tantoSaveOnPost(SaveUserUrl, saveViewModel, changePWflg);
      }

      // 検証結果をリセット
      setDoValid(false);
      setIsValidLoginID(undefined);
      setIsValidPassword(undefined);
      setIsValidPasswordConfirm(undefined);
      setIsValidTantoname(undefined);
      setIsValidMail(undefined);
      setIsValidMailConfirm(undefined);
    }
  }, [isValidLoginID, isValidPassword, isValidPasswordConfirm, isValidTantoname, isValidMail, isValidMailConfirm]);

  return (
    <div className='tanto-account-control'>
      <LowerActionLayout className='vw-100'>
        <LowerActionLayoutHeader>
          <PageHeader
            pageTitle={labels.AIKCMN005000001_FUNCTION_NAME}
            closeButtonTabIndex={1}
            onCloseClick={handleOnCloseClick}
          />
        </LowerActionLayoutHeader>
        <LowerActionLayoutContents className='vw-100 p-3'>
          <div className='d-flex tanto-control'>
            <Label className='tanto-label-control font-weight-bold'>
              {labels.AIKCMN005000001_INPUT_HEADER_OFFICESTAFF}
            </Label>
            <Label className='ml-4'>
              <AutoComplete
                name={'UserName'}
                itemsSource={listViewModel.IdNameList}
                displayMemberPath={'UserName'}
                selectedValue={listViewModel.UserCode}
                initialized={TantoCmbInitialized}
                onSelectedIndexChanged={handleSelectedIndexChangedTanto}
                isRequired={false}
                tabIndex={2}
              />
            </Label>
            <Button
              color='primary'
              className='ml-2 tanto-button-control'
              onClick={handleOnnewTantoClick}
              tabIndex={0}
            >
              {labels.AIKCMN005000001_BUTTON_FUNCTION_CREATE}
            </Button>
          </div>
          <hr className='border-line'/>
          <div className='tanto-account-content'>
            <TantoManagementForm
              mode={true}
              model={viewModel}
              Auth={Auth}
              LoginValid={LoginValid}
              listModel={listViewModel.IdNameList!}
              oldLoginId={oldLoginId}
              onInputChange={handleInputChange}
              initializedAuthCmb={initializedAuthCmb}
              initializedLoginValidCmb={initializedLoginValidCmb}
              pageMode={pageMode}
              changedTantoFlg={changedTantoFlg}
              changePWFlg={handleonChangwPWFlg}
              doValidate={doValid}
              validMsgClear={validMsgClear}
              passwordDispKbn={passwordDispKbn}
              onLoginIdValidationEnded={handleLoginIdValidationEnded}
              onPasswordValidationEnded={handlePasswordValidationEnded}
              onPasswordConfirmValidationEnded={handlePasswordConfirmValidationEnded}
              onTantonameValidationEnded={handleTantonameValidationEnded}
              onMailValidationEnded={handleMailValidationEnded}
              onMailConfirmValidationEnded={handleMailConfirmValidationEnded}
              onHandlePasswordDispKbnChange={handlePasswordDispKbnChange}
              handleOnAuthChanged={handleOnAuthChanged}
              handleOnLoginValidChanged={handleOnLoginValidChanged}
            />
            <ConfirmationPopup
              isOpen={confirmationActivated}
              onClose={handleConfirmationPopupClose}
              onYesClick={handleConfirmationPopupYesClick}
              onNoClick={handleConfirmationPopupNoClick}
            >
              {message.Common_Confirmation_Cancel}
            </ConfirmationPopup>
          </div>
        </LowerActionLayoutContents>
        <LowerActionLayoutAction className='px-3 pb-3'>
          <div className='d-flex justify-content-between'>
            <div>
              <div className={validatErrClass}>{errMessage}</div>
            </div>
            <ExecutingCancel
              executeLabel={labels.COMMON_BUTTON_FUNCTION_SAVE}
              onExecuteClick={handleonExecuteClick}
              cancelLabel={labels.AIKADT002000005_GRID_TOOLTIP_DELETE}
              onCancelClick={handleonDeleteClick}
              cancelDisabled={(pageMode == TantoManageMentPageModeEnum.New)}
            />
          </div>
        </LowerActionLayoutAction>
      </LowerActionLayout>
    </div>
  );
};

export default TantoManagement;

// HOWTO: フォーム入力は
//    [React標準実装](https://ja.reactjs.org/docs/forms.html)を使用します。
//    [reactstrap標準実装](https://reactstrap.github.io/components/form/)を使用します。
// HOWTO: 入力フォームを別だしにして定義します。
// HOWTO: 　ここでは引数だけを使って正しく表示することに専念します。
// 入力欄の仕様の変更がある際に、対応する入力欄プロジェクトを変更すること。
type TantoManagementFormProps = {
  mode: boolean;
  model: ExpansionUserViewModel;
  Auth: boolean;
  LoginValid: boolean;
  listModel: IdNameList[];
  oldLoginId: string;
  onInputChange: (event: TantoManagementFormEvent) => void;
  initializedAuthCmb: (sender: wjInput.ComboBox) => void;
  initializedLoginValidCmb: (sender: wjInput.ComboBox) => void;
  pageMode: TantoManageMentPageModeEnum;
  changedTantoFlg: boolean;
  changePWFlg: (Flg: boolean) => void;
  doValidate: boolean;
  validMsgClear: boolean;
  passwordDispKbn: boolean;
  onLoginIdValidationEnded: (result: boolean) => void;
  onPasswordValidationEnded: (result: boolean) => void;
  onPasswordConfirmValidationEnded: (result: boolean) => void;
  onTantonameValidationEnded: (result: boolean) => void;
  onMailValidationEnded: (result: boolean) => void;
  onMailConfirmValidationEnded: (result: boolean) => void;
  onHandlePasswordDispKbnChange: (result: boolean) => void;
  handleOnAuthChanged: () => void;
  handleOnLoginValidChanged: () => void;
};

const TantoManagementForm: React.FC<TantoManagementFormProps> = props => {
  
  //const[PasswordEditKbn, setPasswordEditKbn] = React.useState(true);
  const[checked, setchecked] = React.useState(false);
  const handleOnPWChanged = (e: TantoManagementFormEvent) => {
    props.onInputChange(e);
  }
  const handleOnPWConfirmChanged = (e: TantoManagementFormEvent) => {
    props.onInputChange(e);
  }
  const handleOnPWChangeKbn = (e: React.ChangeEvent<HTMLInputElement>) => {
    //setPasswordEditKbn(!e.currentTarget.checked);
    props.changePWFlg(e.currentTarget.checked);
    setchecked(e.currentTarget.checked);
  };

　// 新規/担当者変更モード切替時または担当者変更時の処理
  React.useEffect (() => {
    setchecked(false);
  }, [props.pageMode, props.changedTantoFlg]);
  // 選択された事業者
  const editTargetTanto: React.ReactElement = (
    <div>
      <FormGroup check>
        <div className='d-flex'>
          <Label check className='password-change-kbn'>
            <Input
              type='checkbox'
              id='passwordChangeKbn'
              checked={checked}
              onChange={handleOnPWChangeKbn}
            />
            <span className='font-weight-bold'>{labels.AIKCMN005000001_INPUT_HEADER_PWDCHANGE}</span>
          </Label>
        </div>
      </FormGroup>
      <FormGroup id='password'>
        <Password
          classname={FormColLabelOldTantoClass}
          model={props.model}
          isRevealPassword={props.passwordDispKbn}
          onInputChange={handleOnPWChanged}
          disable={!checked}
          doValidate={props.doValidate}
          validMsgClear={props.validMsgClear}
          onValidationEnded={props.onPasswordValidationEnded}
        />
      </FormGroup>
      <FormGroup id='passwordConfirm'>
        <PasswordConfirm
          classname={FormColLabelOldTantoClass}
          model={props.model}
          onInputChange={handleOnPWConfirmChanged}
          isRevealPassword={props.passwordDispKbn}
          disable={!checked}
          doValidate={props.doValidate}
          validMsgClear={props.validMsgClear}
          onValidationEnded={props.onPasswordConfirmValidationEnded}
        />
      </FormGroup>
      <FormGroup check id='passwordDisp'>
        <div className='d-flex'>
          <Label check className='password-disp-check'>
            <Input
              type='checkbox'
              id='passwordDispKbn'
              checked={props.passwordDispKbn}
              onChange = {(event) => props.onHandlePasswordDispKbnChange(event.currentTarget.checked)}
              disabled={!checked}
            />
            <span className='font-weight-bold'>{labels.AIKCMN001000001_LABEL_PASSWORDISP}</span>
          </Label>
        </div>
      </FormGroup>
    </div>
  );
  // 新規担当者
  const newTanto: React.ReactElement = (
    <div>
      <FormGroup id='password'>
        <Password
          classname={FormColLabelClass}
          model={props.model}
          isRevealPassword={props.passwordDispKbn}
          onInputChange={handleOnPWChanged}
          disable={false}
          doValidate={props.doValidate}
          validMsgClear={props.validMsgClear}
          onValidationEnded={props.onPasswordValidationEnded}
        />
      </FormGroup>
      <FormGroup id='passwordConfirm'>
        <PasswordConfirm
          classname={FormColLabelClass}
          model={props.model}
          onInputChange={handleOnPWConfirmChanged}
          isRevealPassword={props.passwordDispKbn}
          disable={false}
          doValidate={props.doValidate}
          validMsgClear={props.validMsgClear}
          onValidationEnded={props.onPasswordConfirmValidationEnded}
        />
      </FormGroup>
      <FormGroup check id='passwordDisp'>
        <div className='d-flex'>
          <Label check className='password-disp-check'>
            <Input
              type='checkbox'
              id='passwordDispKbn'
              checked={props.passwordDispKbn}
              onChange={(event) => props.onHandlePasswordDispKbnChange(event.currentTarget.checked)}
              disabled={false}
            />
            <span className='font-weight-bold'>{labels.AIKCMN001000001_LABEL_PASSWORDISP}</span>
          </Label>
        </div>
      </FormGroup>
    </div>
  );

  return(
    <Form>
      <FormGroup id='loginID'>
        <LoginID
          model={props.model}
          oldLoginId={props.oldLoginId}
          listModel={props.listModel}
          onInputChange={props.onInputChange}
          doValidate={props.doValidate}
          validMsgClear={props.validMsgClear}
          onValidationEnded={props.onLoginIdValidationEnded}
        />
      </FormGroup>
      {props.pageMode == TantoManageMentPageModeEnum.New ? newTanto : editTargetTanto}
      <FormGroup id='tantoName'>
        <TantoName
          model={props.model}
          onInputChange={props.onInputChange}
          doValidate={props.doValidate}
          validMsgClear={props.validMsgClear}
          onValidationEnded={props.onTantonameValidationEnded}
        />
      </FormGroup>
      <FormGroup id='tantoNameKana'>
        <TantoNameKana
          model={props.model}
          onInputChange={props.onInputChange}
        />
      </FormGroup>
      <FormGroup id='mailAddr'>
        <MailAddr
          model={props.model}
          onInputChange={props.onInputChange}
          doValidate={props.doValidate}
          validMsgClear={props.validMsgClear}
          onValidationEnded={props.onMailValidationEnded}
        />
      </FormGroup>
      <FormGroup id='mailAddrConfirm'>
        <MailAddrConfirm
          model={props.model}
          doValidate={props.doValidate}
          validMsgClear={props.validMsgClear}
          onValidationEnded={props.onMailConfirmValidationEnded}
          onInputChange={props.onInputChange}
        />
      </FormGroup>
      <FormGroup id='auth'>
        <div className={FormColClass}>
          <div>
            <Label className={FormColLabelClass}>{labels.AIKCMN005000001_INPUT_HEADER_AUTHORITY}</Label>
          </div>
          <Label>
            <ComboBox
              itemsSource={AuthEnum}
              displayMemberPath={'key'}
              selectedValuePath={'value'}
              selectedValue={props.Auth}
              initialized={props.initializedAuthCmb}
              onSelectedIndexChanged={props.handleOnAuthChanged}
            />
          </Label>
        </div>
      </FormGroup>
      <FormGroup id='loginValid'>
        <div className={FormColClass}>
          <div>
            <Label className={FormColLabelClass}>{labels.AIKCMN005000001_INPUT_HEADER_LOGINENABLED}</Label>
          </div>
          <Label>
            <ComboBox
              itemsSource={LoginValidEnum}
              displayMemberPath={'key'}
              selectedValuePath={'value'}
              selectedValue={props.LoginValid}
              initialized={props.initializedLoginValidCmb}
              onSelectedIndexChanged={props.handleOnLoginValidChanged}
            />
          </Label>
        </div>
      </FormGroup>
    </Form>
  );
};

type LoginIDProps = {
  model: UserViewModel;
  listModel: IdNameList[];
  oldLoginId: string;
  onInputChange: (event: TantoManagementFormEvent) => void;
  doValidate: boolean;
  validMsgClear: boolean;
  onValidationEnded: (result: boolean) => void;
};

const LoginID: React.FC<LoginIDProps> = props => {
  const [errMsg, setErrMsg] = React.useState('');

  React.useEffect(() => {
    if (!props.doValidate) {
      return;
    }
    setErrMsg('');
    const regexp = /^[a-zA-Z0-9 -/:-\@\[-\`\{-\~\.]{1,20}$/;
    let input = props.model.LoginID;
    // 未入力
    if (input == undefined || input.trim().length == 0) {
      setErrMsg(bindValueToMessage(message.TantoManagement_Error_Required_Empty, [labels.AIKCMN005000001_INPUT_HEADER_LOGINID]));
      props.onValidationEnded(false);
      return;
    }
    //ログインIDを20文字以内で入力します。半角の英数字および記号の使用が可能です。
    //ログインIDは半角英数字記号で入力してください。
    if (input.match(regexp) == null) {
      setErrMsg(message.TantoManagement_Error_Format_PolicyErr);
      props.onValidationEnded(false);
      return;
    }
    // 既存かをチェック
    if (input != props.oldLoginId) {
      var valiFlg = false;
      props.listModel.forEach(x => {
        if (input == x.LoginID) {
          valiFlg = true;
        };})
      if (valiFlg) {
        //既に使用されているログインIDです。
        setErrMsg(message.TantoManagement_Error_Conflict_Check);
        props.onValidationEnded(false);
        return;
      }
    }
    props.onValidationEnded(true);
  }, [props.doValidate]);

  React.useEffect(() => {
    if (props.validMsgClear) {
      setErrMsg('');
    }
  }, [props.validMsgClear]);

  return(
    <div className='mt-2'>
      <div className={FormColClass}>
        <div>
          <Label className={FormColLabelClass}>{labels.AIKCMN005000001_INPUT_HEADER_LOGINID}</Label>
        </div>
        <Label className='vw-40'>
          <Input
            type='text'
            name={'LoginID'}
            value={props.model.LoginID == undefined ? '' : props.model.LoginID}
            onChange={props.onInputChange}
            autoFocus={true}
            maxLength={20}
          />
        </Label>
      </div>
      <span hidden={errMsg == ''} className={validatErrClass}>{errMsg}</span>
    </div>
  )
};

type PasswordProps = {
  classname: string;
  model: ExpansionUserViewModel;
  isRevealPassword: boolean;
  onInputChange: (event: TantoManagementFormEvent) => void;
  disable: boolean;
  doValidate: boolean;
  validMsgClear: boolean;
  onValidationEnded: (result: boolean) => void;
};

const Password: React.FC<PasswordProps> = props => {
  const [errMsg, setErrMsg] = React.useState('');

  React.useEffect(() => {
    if (!props.doValidate) {
      return;
    }
    setErrMsg('');
    const regexp = /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!-/:-@¥[-`{-~]{8,20}$/;
    let input = props.model.Password;
    if (!props.disable) {
      // パスワード未入力
      if (input == undefined || input.trim().length == 0) {
        setErrMsg(bindValueToMessage(message.TantoManagement_Error_Required_Empty, [labels.AIKCMN005000001_INPUT_HEADER_PWD]));
        props.onValidationEnded(false);
        return;
      }
      //半角の英数字および記号を使用し、8文字以上20文字以内で入力します。
      //必ず、アルファベットの大文字と小文字を含めてください。
      if (input.match(regexp) == null) {
        setErrMsg(bindValueToMessage(message.Common_Error_PasswordPolicy_PasswordPolicyErr));
        props.onValidationEnded(false);
        return;
      }
    }
    props.onValidationEnded(true);
  }, [props.doValidate]);

  React.useEffect(() => {
    if (props.validMsgClear) {
      setErrMsg('');
    }
  }, [props.validMsgClear]);
  return(
    <div>
      <div className={FormColClass}>
        <div>
          <Label className={props.classname}>{labels.AIKCMN005000001_INPUT_HEADER_PWD}</Label>
        </div>
        <Label className='vw-50'>
          <Input
            type={props.isRevealPassword ? 'text' : 'password'}
            name={'Password'}
            value={props.model.Password == undefined ? '' : props.model.Password}
            onChange={props.onInputChange}
            disabled={props.disable}
          />
        </Label>
      </div>
      <span hidden={errMsg == ''} className={validatErrClass}>{errMsg}</span>
    </div>
  )
};

type PasswordConfirmProps = {
  classname: string;
  model: ExpansionUserViewModel;
  onInputChange: (event: TantoManagementFormEvent) => void;
  isRevealPassword: boolean;
  disable: boolean;
  doValidate: boolean;
  validMsgClear: boolean;
  onValidationEnded: (result: boolean) => void;
};

const PasswordConfirm: React.FC<PasswordConfirmProps> = props => {
  const [errMsg, setErrMsg] = React.useState('');
  React.useEffect(() => {
    if (!props.doValidate) {
      return;
    }
    setErrMsg('');
    if (!props.disable) {
      // パスワード確認用未入力
      if (props.model.PasswordConfirm == undefined || props.model.PasswordConfirm!.trim().length == 0) {
        setErrMsg(bindValueToMessage(message.TantoManagement_Error_Required_Empty, [labels.AIKCMN005000001_INPUT_HEADER_PWDCONFIRM]));
        props.onValidationEnded(false);
        return;
      }
      // passwordと一致しているか
      if (props.model.Password != props.model.PasswordConfirm) {
        setErrMsg(bindValueToMessage(message.Common_Error_PasswordPolicy_PasswordMatchErr));
        props.onValidationEnded(false);
        return;
      }
    }
    props.onValidationEnded(true);
  }, [props.doValidate]);

  React.useEffect(() => {
    if (props.validMsgClear) {
      setErrMsg('');
    }
  }, [props.validMsgClear]);

  return(
    <div>
      <div className={FormColClass}>
        <div>
          <Label className={props.classname}>{labels.AIKCMN005000001_INPUT_HEADER_PWDCONFIRM}</Label>
        </div>
        <Label className='vw-50'>
          <Input
            id={'PasswordConfirm'}
            name={'PasswordConfirm'}
            type={props.isRevealPassword ? 'text' : 'password'}
            disabled={props.disable}
            value={props.model.PasswordConfirm == undefined ? '' : props.model.PasswordConfirm}
            onChange={props.onInputChange}
          />
        </Label>
      </div>
      <span hidden={errMsg == ''} className={validatErrClass}>{errMsg}</span>
    </div>
  )
};

type TantoNameProps = {
  model: UserViewModel;
  onInputChange: (event: TantoManagementFormEvent) => void;
  doValidate: boolean;
  validMsgClear: boolean;
  onValidationEnded: (result: boolean) => void;
};
const TantoName: React.FC<TantoNameProps> = props => {
  const [errMsg, setErrMsg] = React.useState('');

  React.useEffect(() => {
    if (!props.doValidate) {
      return;
    }
    setErrMsg('');
    let LastNameinput = props.model.LastName;
    let FirstNameinput = props.model.FirstName;
    // 姓未入力
    if (LastNameinput == undefined || LastNameinput.trim().length == 0) {
      setErrMsg(bindValueToMessage(message.TantoManagement_Error_Required_Empty, ['担当者名（姓）']));
      props.onValidationEnded(false);
      return;
    }
    // 名未入力
    if (FirstNameinput == undefined || FirstNameinput.trim().length == 0) {
      setErrMsg(bindValueToMessage(message.TantoManagement_Error_Required_Empty, ['担当者名（名）']));
      props.onValidationEnded(false);
      return;
    }
    // 名称桁超過
    /*if (input.length > 25) {
      setErrMsg(bindValueToMessage(message.Common_Error_LengthExcess, ['名称', '25']));
      props.onValidationEnded(false);
      return;
    }*/
    props.onValidationEnded(true);
  }, [props.doValidate]);

  React.useEffect(() => {
    if (props.validMsgClear) {
      setErrMsg('');
    }
  }, [props.validMsgClear]);

  return (
    <div>
      <div className={FormColClass}>
        <div>
          <Label className={FormColLabelClass}>{labels.AIKCMN005000001_INPUT_HEADER_OFFICESTAFFNAME}</Label>
        </div>
        <Label>
          <Input
            type='text'
            name={'LastName'}
            id='TantoLastName'
            placeholder={'姓'}
            value={props.model.LastName == undefined ? '' : props.model.LastName}
            onChange={props.onInputChange}
            maxLength={12}
          />
        </Label>
        <Label className='ml-1'>
          <Input
            type='text'
            name={'FirstName'}
            id='TantoFirstName'
            placeholder={'名'}
            value={props.model.FirstName == undefined ? '' : props.model.FirstName}
            onChange={props.onInputChange}
            maxLength={12}
          />
        </Label>
      </div>
      <span hidden={errMsg == ''} className={validatErrClass}>{errMsg}</span>
    </div>
  );
};

type TantoNameKanaProps = {
  model: UserViewModel;
  onInputChange: (event: TantoManagementFormEvent) => void;
};
const TantoNameKana: React.FC<TantoNameKanaProps> = props => {
  return (
    <div className={FormColClass}>
      <div>
        <Label className={FormColLabelClass}>{labels.AIKCMN005000001_INPUT_HEADER_OFFICESTAFFKANA}</Label>
      </div>
      <Label>
        <Input
          type='text'
          id='TantoLastNameKana'
          name={'LastNameKana'}
          placeholder={'ｾｲ'}
          value={props.model.LastNameKana == undefined ? '' : props.model.LastNameKana}
          onChange={props.onInputChange}
          maxLength={12}
        />
      </Label>
      <Label className='ml-1'>
        <Input
          type='text'
          id='TantoFirstNameKana'
          name={'FirstNameKana'}
          placeholder={'ﾒｲ'}
          value={props.model.FirstNameKana == undefined ? '' : props.model.FirstNameKana}
          onChange={props.onInputChange}
          maxLength={12}
        />
      </Label>
    </div>
  );
};

type MailAddrProps = {
  model: UserViewModel;
  onInputChange: (event: TantoManagementFormEvent) => void;
  doValidate: boolean;
  validMsgClear: boolean;
  onValidationEnded: (result: boolean) => void;
};

const MailAddr: React.FC<MailAddrProps> = props => {
  const [errMsg, setErrMsg] = React.useState('');

  React.useEffect(() => {
    if (!props.doValidate) {
      return;
    }
    setErrMsg('');
    var input = props.model.Email;
    const regexp = /^([a-zA-Z0-9_/\.\-\?\+])+\@([a-zA-Z0-9]+[a-zA-Z0-9\-]*\.)+[a-zA-Z0-9\-]+$/;
    // 有効なメールアドレスではありません。
    if (input != undefined && input.trim().length > 0) {
      if (input.match(regexp) == null) {
        setErrMsg(bindValueToMessage(message.UserInfo_Error_Format_MailAddress));
        props.onValidationEnded(false);
        return;
      }
    }
    props.onValidationEnded(true);
  }, [props.doValidate]);

  React.useEffect(() => {
    if (props.validMsgClear) {
      setErrMsg('');
    }
  }, [props.validMsgClear]);

  return(
    <div>
      <div className={FormColClass}>
        <div>
          <Label className={FormColLabelClass}>{labels.AIKCMN005000001_INPUT_HEADER_MAIL}</Label>
        </div>
        <Label className='vw-80'>
          <Input
            type='text'
            name={'Email'}
            value={props.model.Email == undefined ? '' : props.model.Email}
            onChange={props.onInputChange}
            maxLength={256}
          />
        </Label>
      </div>
      <span hidden={errMsg == ''} className={validatErrClass}>{errMsg}</span>
    </div>
  );
};

type MailAddrConfirmProps = {
  model: ExpansionUserViewModel;
  doValidate: boolean;
  validMsgClear: boolean;
  onValidationEnded: (result: boolean) => void;
  onInputChange: (event: TantoManagementFormEvent) => void;
};

const MailAddrConfirm: React.FC<MailAddrConfirmProps> = props => {
  const [errMsg, setErrMsg] = React.useState('');
  React.useEffect(() => {
    if (!props.doValidate) {
      return;
    }
    setErrMsg('');
    // メールアドレス入力した場合
    if (props.model.Email != undefined && props.model.Email.trim().length > 0) {
      // メールアドレス(確認用)が一致しません。
      if (props.model.MailConfirm != props.model.Email) {
        setErrMsg(bindValueToMessage(message.UserInfo_Error_Confirm_MailAddress));
        props.onValidationEnded(false);
        return;
      }
    }
    props.onValidationEnded(true);
  }, [props.doValidate]);

  React.useEffect(() => {
    if (props.validMsgClear) {
      setErrMsg('');
    }
  }, [props.validMsgClear]);

  return(
    <div>
      <div className={FormColClass}>
        <div>
          <Label className={FormColLabelClass}>{labels.AIKCMN005000001_INPUT_HEADER_MAILCONFIRM}</Label>
        </div>
        <Label className='vw-80'>
          <Input
            type='text'
            name={'MailConfirm'}
            onChange={props.onInputChange}
            value={props.model.MailConfirm == undefined ? '' : props.model.MailConfirm}
          />
        </Label>
      </div>
      <span hidden={errMsg == ''} className={validatErrClass}>{errMsg}</span>
    </div>
  );
};

/**
 * ログイン区分、権限区分
 */
const AuthEnum = [
  { key: '一般', value: false  },
  { key: '管理者', value: true },
];

const LoginValidEnum = [
  { key: '有効', value: false },
  { key: '無効', value: true },
];
