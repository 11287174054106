/*
 * 月情報種別
 */
export enum MonthType {
    M1 = 0, // 1月～
    M2 = 1,
    M3 = 2,
    M4 = 3,
    M5 = 4,
    M6 = 5,
    M7 = 6,
    M8 = 7,
    M9 = 8,
    M10 = 9,
    M11 = 10,
    M12 = 11,
    AfterCloseQuarter1, // 締め後（第１四半期）
    AfterCloseQuarter2, // 締め後（第２四半期＝中間）
    AfterCloseQuarter3, // 締め後（第３四半期）
    AfterCloseQuarter4, // 締め後（第４四半期＝最終）
    Quarter1, // 第１四半期
    Quarter2, // 第２四半期＝中間
    Quarter3, // 第３四半期
    Closing // 決算
}

/*
 * 中間決算種別
 */
export enum InterimClosingType {
    None, // なし
    Interim, // 中間
    Quarter, // 四半期
    Year // 年度
}

/**
 * 日付種別
 */
export enum DateFormatType {
    SeirekiYM,
    SeirekiYMD,
    WarekiYM,
    WarekiYMD,
}

/**
 * 和暦種別
 */
export enum WarekiType {
    Meiji,
    Taisho,
    Showa,
    Heisei,
    Reiwa,
}
