import {MonthSelectInformationVO} from "./monthSelectInformationVO";
import {MidtermClosingEnum} from "../constants/enums";
/**
 * 月選択情報ViewModel ViewModel クラス
 */

export class MonthSelectInformationViewModel {
    /** 月選択情報VO */
    public MonthSelects?:Array<MonthSelectInformationVO>;

    /** 中間決算区分 */
    public MiddleKbn?:MidtermClosingEnum;

    /** 月次確定内部月 */
    public FixMonths?:number;

    /** 決算開始年月日 */
    public KStDate?:Date;

    /** 決算終了年月日 */
    public KEdDate?:Date;

}
