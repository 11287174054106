import React from 'react';
import Axios from 'axios';
import logoImg from '../../../../images/logo_aikansa_R.png';
import * as labels from '../../../../constants/labels';
import Button from 'reactstrap/lib/Button';
import { Input } from 'reactstrap';

import { TantoLoginViewModel } from '../../../../models/tantoLoginViewModel';
//import { RouteComponentProps } from 'react-router';
//import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';

//import * as H from 'history';

// 初期表示時のURL
export const initialUrl = '/api/v1/Login/Initialize';
export const postUrl = '/api/v1/Login/Login';

const BaseInitialUrl = '/api/v1/ChecklistSetting';
export const GetInitialUrl = [BaseInitialUrl, 'Initialize'].join('/'); // 初期表示新規データ作成用

// バリデートエラーメッセージのCSS Class
const validatErrClass = 'ml-3 text-danger';

//interface RouteComponentProps<Params extends { [K in keyof Params]?: string } = {}, C extends StaticContext = StaticContext, S = H.LocationState> {
//    history: H.History;
//    location: H.Location<S>;
//    match: match<Params> | null;
//    staticContext?: C;
//}



type LoginProps =
    {
    }
 //   &
 //   RouteComponentProps<{ id?: string }>;




const Login: React.FC<LoginProps> = props => {
    

    const [viewModel, setViewModel] = React.useState<TantoLoginViewModel>(new TantoLoginViewModel());



    const [errorMessage, setErrorMessage] = React.useState('');

    const initializationViewModel: TantoLoginViewModel = {
    };



    /* 通信処理 */
    // 表示データ取得処理（GET）
    const initialOnGet = (url: string) => {
        var args = new URL(document.URL);
        var encryptedMjsAuthId = args.searchParams.get('id');

        Axios.get<TantoLoginViewModel>(url, { params: { id: encryptedMjsAuthId } } )
            .then(response => {
                setViewModel(response.data);
            })
            .catch(error => {
                setErrorMessage(error.response.data.message);
                setViewModel(initializationViewModel);
            });
    };


    
    const handleChangeLoginID = (event: { target: { value: any; }; }) => {
        setViewModel({ ...viewModel, LoginID: event.target.value, ErrorMessage: "" });
    };

    const handleChangePassword = (event: { target: { value: any; }; }) => {
        setViewModel({ ...viewModel, Password: event.target.value, ErrorMessage: "" });
    };


    /**
     * ログインボタンクリック
     */
    const handleLoginClick = () => {
        var args = new URL(document.URL);
        var encryptedMjsAuthId = args.searchParams.get('id');
        if (encryptedMjsAuthId != null) {
            viewModel.EncryptedMjsAuthId = encryptedMjsAuthId;
        }


        Axios.post(postUrl, viewModel)
            .then(response => {
                if (response.data.ErrorMessage != undefined && response.data.ErrorMessage != "") {
                    setViewModel(response.data);
                }
                else {
                    window.location.assign("/?token=" + response.data.AIAuditToken);
                }
            })
            .catch(error => {
                setErrorMessage(error.response.data.message);
                setViewModel(initializationViewModel);
            });
    };



    React.useEffect(() => {
        initialOnGet(initialUrl);
    }, [initialUrl]);



    return (
        
        
        <div className="wrapper">
            <div className="login-center">
                <img src={logoImg} className='logo' />
                <br />
                <br />
                <br />
                <br />
                <br />
                <form id="vis_login_form">
                    <div>
                        <span className={validatErrClass}>{errorMessage}</span>
                        <span className={validatErrClass}>{viewModel.ErrorMessage}</span><br/>
                        <label htmlFor="auth_email">{labels.AIKCMN002000004_LABEL_LOGINID}</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <Input
                            type='text'
                            className='login'
                            id={'auth_email'}
                            name={'auth_email'}
                            value={viewModel.LoginID}
                            onChange={handleChangeLoginID}
                        />
                    </div>
                    <div>
                        <label htmlFor="exampleInputPassword1">{labels.AIKCMN002000004_LABEL_LOGINPASS}</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Input
                            type='password'
                            className='login'
                            id={'exampleInputPassword1'}
                            name={'exampleInputPassword1'}
                            value={viewModel.Password}
                            onChange={handleChangePassword}
                        />
                    </div>
                    <br />
                    <br />
                    <Button className="A3-btn btn-primary" onClick={handleLoginClick}>{labels.AIKCMN002000004_BUTTON_LOGIN}</Button>
                    <hr />
                    <a href={viewModel != null ? viewModel.bizskyLoginURL : ""}>{labels.AIKCMN002000004_FUNCTION_KANRISYALINK}</a>
                </form>
            </div>
            <div className="login-footer">
                <a target={'_blank'} href="https://info.support.mjs.co.jp/" className="login-footer-a">{labels.AIKCMN002000004_FUNCTION_MAINTENANCE}</a>&nbsp;｜&nbsp;<a target={'_blank'} href="https://www.mjs.co.jp/office/corporate_overview.html" className="login-footer-a">{labels.AIKCMN002000004_FUNCTION_OPERATINGCOMPANY}</a>&nbsp;｜&nbsp;<a target={'_blank'} href="/Riyokiyaku/riyokiyaku.pdf" className="login-footer-a">{labels.AIKCMN002000004_FUNCTION_TERMSOFSERVICE}</a>&nbsp;｜&nbsp;<a target={'_blank'} href="https://www.mjs.co.jp/securitypolicy/" className="login-footer-a">{labels.AIKCMN002000004_FUNCTION_INFORMATIONSECURITY}</a>&nbsp;&nbsp;&nbsp;
            </div>
        </div>
        );

}

export default Login;