import React from 'react';

import * as wjGrid from 'wijmo/wijmo.grid';
import { MultiRow } from 'wijmo/wijmo.react.grid.multirow';

import * as labels from '../../../../constants/labels';
import { convertItemsSourceToDateTypeForMultiRow } from '../../../../utils/wijmoGridUtils';
import { JournalLineVO } from '../../../../models/journalLineVO';
import { ActivateScreenEnum } from '../../../../constants/enums';
import GridHeaderRenewalManager, { GridHeaderVo } from '../../../../containers/organisms/A000/GridHeaderRenewalManager';
import * as constants from '../../../../constants/constant';

type JournalLineGridProp = {
  sources: Array<JournalLineVO>;
  gridClassName?: string;
  // グリッド選択レコード変更イベント処理（呼び出し元から設定）
  onSelectionChanged?: (selectionVo: JournalLineVO) => void;
  // 遷移元画面
  activateScreenType?: ActivateScreenEnum;
  innerRef?: React.RefObject<any>;
  tabIndex?: number;
};
type JournalLineGridState = {
  popup: boolean,
  colums: any
};

class JournalLineGrid extends React.Component<JournalLineGridProp, JournalLineGridState> {
  grid?: wjGrid.FlexGrid;

  constructor(props: JournalLineGridProp) {
    super(props);
    this.initializedGrid = this.initializedGrid.bind(this);
    this.handleItemFormatter = this.handleItemFormatter.bind(this);
    this.selectionChanged = this.selectionChanged.bind(this);
    this.state = {
      popup: false,
      colums: colums
    };
  }

  // Gridの初期化
  public initializedGrid = (flexGrid: wjGrid.FlexGrid) => {
    if (this.props.activateScreenType! == ActivateScreenEnum.JounalLine) {
      flexGrid.selectionMode = wjGrid.SelectionMode.RowRange;
      // 先頭行をデフォルトとする(0,0 以外で行が0 or 1であれば任意の値でselectionChangeが発火する為)
      flexGrid.select(new wjGrid.CellRange(0, 1), true);
    } else {
      flexGrid.selectionMode = wjGrid.SelectionMode.None;
    };
      this.grid = flexGrid;
  };

  // ItemFormatter
  public handleItemFormatter = (panel: wjGrid.GridPanel, row: number, col: number, cell: HTMLElement) => {
    if (panel.cellType === wjGrid.CellType.ColumnHeader) {
      // ヘッダ
      cell.classList.add('d-flex');
      cell.classList.add('justify-content-center');
      cell.classList.add('align-items-center');
      cell.classList.add('font-weight-normal');
    } else if (panel.cellType === wjGrid.CellType.Cell) {
      const item = panel.rows[row].dataItem;
      const colItem = panel.columns[col];
      var backcolorFlg = 0;

      if (colItem.binding === 'TaxCode' && row % 2 === 0) {
        if (item.ExcptRate === 1) {  //例外税率
          cell.classList.add('_excptrate-target');
        }
        if (item.TaxRate === 5 || item.TaxRate === 107 || item.TaxRate === 108) {  //軽減税率
          cell.classList.add('_mitigterate-target');
          backcolorFlg = 1;
        }
      }

      if (item.AuditFlg) {
        if (backcolorFlg === 0) { //backColorが変更されていないcolを選択行のカラーとする対応
          cell.classList.add('_target');
        }
      }
    }
  };
  /**
   * ヘッダー情報受信
   * @param s ヘッダー情報
   */
  public takeColumns = (s: Array<GridHeaderVo>) => {      
    this.setState(() => {
      return { colums: s }
    });
  }
  /** ヘッダー更新 */
  public switchHeader = () => {
    this.setState((state) => {
      return { popup: !state.popup }
    });

  };
  // レコード変更イベント処理
  public selectionChanged = (flexGrid: wjGrid.FlexGrid, event: wjGrid.CellRangeEventArgs) => {
    // イベント伝播禁止
    // 選択セルを1レコード単位に変換
    if (flexGrid.selection.row % 2 === 1) {
      flexGrid.selection = new wjGrid.CellRange(flexGrid.selection.row - 1, 0, flexGrid.selection.row, 0);
    } else {
      flexGrid.selection = new wjGrid.CellRange(flexGrid.selection.row, 0, flexGrid.selection.row + 1, 0);
    }
    if (this.grid != null) {
      // グリッドのリフレッシュ
      this.grid.refresh();
    }
    if (flexGrid.selectedItems) {
      if (flexGrid.selectedItems.length == 2) {
        // 2行選択されている場合のみ処理
        var oneRecord: JournalLineVO = flexGrid.selectedItems[0];
        if (this.props.onSelectionChanged != null) {
          this.props.onSelectionChanged(oneRecord);
        }
      }
    }
  }

  public render() {
    const { sources, gridClassName, innerRef, tabIndex } = this.props;
    convertItemsSourceToDateTypeForMultiRow(this.state.colums, sources);
    return (
      <span className='journal-line-grid'>
        <MultiRow
          className={gridClassName}
          autoGenerateColumns={false}
          layoutDefinition={this.state.colums}
          headersVisibility={wjGrid.HeadersVisibility.Column}
          collapsedHeaders={true}
          isReadOnly={true}
          allowMerging={wjGrid.AllowMerging.All}
          allowResizing={wjGrid.AllowResizing.None}
          allowDragging={wjGrid.AllowDragging.None}
          allowSorting={false}
          alternatingRowStep={0}
          itemsSource={sources}
          initialized={this.initializedGrid}
          itemFormatter={this.handleItemFormatter}
          selectionChanged={this.selectionChanged}
          ref={innerRef}
          tabIndex={tabIndex}
        />
        <GridHeaderRenewalManager
          featureId={constants.AIKADT002000004}
          sendColumn={this.takeColumns}
          activated={this.state.popup}
          defaultColumns={colums}
          share={[constants.AIKADT002000013]}
        />
      </span>
    );
  }
}

export default JournalLineGrid;
// デフォルトヘッダー情報
export const colums: Array<GridHeaderVo> = [
  // 検索No.
  {
    header: labels.AIKADT002000004_GRID_HEADER_SEARCHNO,
    cells: [{ binding: 'DSrchNo', width: 80, align: 'right', format: 'd' }]
  },
  // 伝票No.
  { header: labels.AIKADT002000004_GRID_HEADER_SLIPNO, cells: [{ binding: 'DenNo', width: 85, align: 'right' }] },
  // 月日
  {
    header: labels.AIKADT002000004_GRID_HEADER_MONTHANDDAY,
    cells: [{ binding: 'DispDenDate', width: 50, align: 'right' }]
  },
  // 借方科目
  {
    header: labels.AIKADT002000004_GRID_HEADER_DEBITSUBJECTS,
    cells: [{ binding: 'DKmkCode', width: 120, align: 'right', format: 'd' }, { binding: 'KdSimpleName' }]
  },
  // 借方部門
  {
    header: labels.AIKADT002000004_GRID_HEADER_DEBITSECTION,
    cells: [{ binding: 'DBmnGCode', width: 140, align: 'right', format: 'd' }, { binding: 'DBmnSimpleName' }]
  },
  // 借方補助
  {
    header: labels.AIKADT002000004_GRID_HEADER_DEBITAUXILIARY,
    cells: [{ binding: 'DSubCode', width: 140, align: 'right', format: 'd' }, { binding: 'DSubSimpleName' }]
  },
  // 借方金額
  {
    header: labels.AIKADT002000004_GRID_HEADER_DEBITAMOUNT,
    cells: [{ binding: 'DInpSum', width: 150, align: 'right' }, { binding: 'DTaxSum', align: 'right' }]
  },
  // 貸方科目
  {
    header: labels.AIKADT002000004_GRID_HEADER_CREDITSUBJECTS,
    cells: [{ binding: 'CKmkCode', width: 120, align: 'right', format: 'd' }, { binding: 'KcSimpleName' }]
  },
  // 貸方部門
  {
    header: labels.AIKADT002000004_GRID_HEADER_CREDITSECTION,
    cells: [{ binding: 'CBmnGCode', width: 140, align: 'right', format: 'd' }, { binding: 'CBmnSimpleName' }]
  },
  // 貸方補助
  {
    header: labels.AIKADT002000004_GRID_HEADER_CREDITAUXILIARY,
    cells: [{ binding: 'CSubCode', width: 140, align: 'right', format: 'd' }, { binding: 'CSubSimpleName' }]
  },
  // 貸方金額
  {
    header: labels.AIKADT002000004_GRID_HEADER_CREDITAMOUNT,
    cells: [{ binding: 'CInpSum', width: 150, align: 'right' }, { binding: 'CTaxSum', align: 'right' }]
  },
  // 資金科目
  {
    header: labels.AIKADT002000004_GRID_HEADER_TAXFUNDSUBJECTS,
    colspan: 3,
    cells: [
      { binding: 'TaxKbnName', width: 45 },
      { binding: 'TaxCode', width: 30, align: 'right', format: 'd' },
      { binding: 'DispTaxSum', width: 150, align: 'right' },
      { binding: '', width: 45 },
      { binding: 'CashCode', align: 'right', format: 'd' },
      { binding: 'CashName' }
    ]
  },
  // 期日
  {
    header: labels.AIKADT002000004_GRID_HEADER_DEADLINE,
    cells: [{ binding: 'DispLimitDate', width: 100, align: 'right' }]
  },
  // 摘要
  { header: labels.AIKADT002000004_GRID_HEADER_REMARKS, cells: [{ binding: 'Tekiyo', width: '*', minWidth: 1030 }] }
];
