import React from 'react';
import Popup from '../../../components/molecules/CMN/Popup/Popup';
import * as wjGrid from 'wijmo/wijmo.grid';
import * as wijmo from 'wijmo/wijmo';
import { FlexGrid } from 'wijmo/wijmo.react.grid';
import SimpleGrid from '../../../components/molecules/CMN/Grid/SimpleGrid';
import { Button } from 'reactstrap';
//import { compareObject } from "../../../utils/objectUtils";
/** プロパティ */
type GridDialogProps = {
  // 表示フラグ
  activated: boolean;
  // クローズ通知関数
  onClose: (flg: boolean, selectValue?: any) => void;
  // リスト
  itemSource: Array<any>;
  // コード列にbindするデータ名称
  codeViewContent: string;
  // コード列の属性
  codeAttr?: string;
  // 名称列にbindするデータ名称
  nameViewContent: string;
  // 選択アイテム
  selectedItem?: any;
};
/**
 * コード名称表示グリッドダイアログ
 * @param props
 */
const GridDialog: React.FC<GridDialogProps> = props => {
  const { activated, onClose, itemSource, codeViewContent, nameViewContent, codeAttr, selectedItem } = props;
  // popupのREF
  const popupRef = React.useRef<any>();
  // gridのREF
  const grid = React.useRef<FlexGrid>();
  // デフォルトグリッド列情報
  const defaultGridColumns = [
    {
      header: 'コード',
      align: 'center',
      binding: 'Code',
      width: 165,
      isReadOnly: true
    },
    {
      header: '名称',
      align: 'left',
      binding: 'Name',
      isReadOnly: true,
      width: '*'
    }
  ];
  // グリッド列情報
  const [col, setCol] = React.useState(defaultGridColumns);
  /** 閉じるボタン押下時の処理 */
  const handleOnCloseClick = (flg: boolean) => {
    // クローズと選択アイテムを通知する
    onClose(flg, grid.current!.control.selectedItems[0]);
  };
  /** bind変更時の処理 */
  React.useEffect(() => {
    // アドレス変換のため、Object→JSON文字列→Objectの変換を行う
    var cols = JSON.parse(JSON.stringify(defaultGridColumns));
    // バインドする領域名を設定
    cols[0]['binding'] = codeViewContent;
    cols[1]['binding'] = nameViewContent;
    // コード属性が設定されている場合
    if (codeAttr != null) {
      // コード列の調整位置を変更する
      cols[0]['align'] = codeAttr;
    }
    // 列情報設定
    setCol(cols);
  }, [codeViewContent, nameViewContent, codeAttr]);
  /** 画面が表示された際に実行する処理 */
  React.useEffect(() => {
    if (activated && selectedItem) {
      setTimeout(() => {
        // 初期選択
        const flexGrid = grid.current!.control as wjGrid.FlexGrid;
        const items = flexGrid.collectionView.items;
        const selectionRow = items.indexOf(items.filter(item => item[codeViewContent] == selectedItem[codeViewContent]
                                                                  && item[nameViewContent] == selectedItem[nameViewContent])[0]);
        if (selectionRow > -1) {
          flexGrid.selection = new wjGrid.CellRange(selectionRow);
          setTimeout(() => {
            // スクロールの移動
            const rc = flexGrid.cells.getCellBoundingRect(selectionRow, 0, true);
            flexGrid.scrollPosition = new wijmo.Point(0, -rc.top);
          }, 100);
        }
      });
    }
  }, [activated]);
  return (
    <Popup
      className='grid-dialog'
      isOpen={activated}
      onCloseClick={() => handleOnCloseClick(false)}
      header={'選択'}
      size='sm'
      innerRef={popupRef}
      footer={
        <Button
          color='secondary'
          onClick={() => handleOnCloseClick(true)}
        >
          {'選択'}
        </Button>
      }
    >
      <SimpleGrid
        gridClassName='flex-grid'
        columns={col}
        frozenColumns={0}
        sources={itemSource}        
        gridRef={grid}
      />
    </Popup>
  );
};
export default GridDialog;
