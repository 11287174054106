import { MjsCancelEventArgs, MjsEventArgs } from "../common/event/eventData";
import { Logger } from "../common/usefuls/logger";
import { InputBase } from "../controls/userControls/inputControls/inputBase";
import { BusyIndicator } from "../core/busyIndicator";
import $ from 'jquery';

/**
 * メディエータクラスのインタフェース
 */
export interface MediatorItem {
    setMediator(mediator: Mediator, itemIndex: number): void;
    setFocus(isForward: boolean): boolean;
    setEventHandler(): void;
    hasControl(ctrl: any): boolean;
    getFocusControl(): JQuery;
    getParentMediator(): Mediator;
    beforeBlur(isForward: boolean): boolean;
    clearEvent(): void;
    getItemIndex(): number;
    canFocus(): boolean;
}
interface EventHandlerList {
    focus: (eventObject: JQueryEventObject) => any;
    keydown: (eventObject: JQueryEventObject) => any;
    keyUp: (eventObject: JQueryEventObject) => any;
}
export class mediatorBeforeBlurArgs extends MjsCancelEventArgs {
    protected item: MediatorItem;
    public get Item(): MediatorItem { return this.item; }
    protected isForward: boolean;
    public get IsForward(): boolean { return this.isForward; }
    constructor(item: MediatorItem, isForward: boolean) {
        super();
        this.item = item;
        this.isForward = isForward;
    }
}
export class mediatorBeforeFocusArgs extends MjsCancelEventArgs {
    /**
     * 次のアイテムにFocusを当てずにスキップし、次のFocusに移動する
     */
    private skipItem: boolean;
    public get skipToAfterNextItem(): boolean { return this.skipItem; }
    public set skipToAfterNextItem(skipItem: boolean) { this.skipItem = skipItem; }
    protected item: MediatorItem;
    public get Item(): MediatorItem { return this.item; }
    protected isForward: boolean;
    public get IsForward(): boolean { return this.isForward; }
    constructor(item: MediatorItem, isForward: boolean) {
        super();
        this.item = item;
        this.skipItem = false;
        this.isForward = isForward;
    }
}
export class mediatorBeforeFocusMoveProcessArgs extends MjsCancelEventArgs {
    private item: MediatorItem;
    public get activeItem(): MediatorItem { return this.item; }
    private next!: MediatorItem;
    public get nextItem(): MediatorItem { return this.next; }
    public set nextItem(next: MediatorItem) { this.next = next; }
    private isFirstCtrl: boolean;
    public get isFirst(): boolean { return this.isFirstCtrl; }
    private isLastCtrl: boolean;
    public get isLast(): boolean { return this.isLastCtrl; }
    constructor(item: MediatorItem, isFirst: boolean, isLast: boolean) {
        super();
        this.item = item;
        this.isFirstCtrl = isFirst;
        this.isLastCtrl = isLast;
    }
}

export class mediatorAfterBlurProcessArgs extends MjsEventArgs {
    private item: MediatorItem;
    public get activeItem(): MediatorItem { return this.item; }
    // private next: MediatorItem;
    private isFirstCtrl: boolean;
    public get isFirst(): boolean { return this.isFirstCtrl; }
    private isLastCtrl: boolean;
    public get isLast(): boolean { return this.isLastCtrl; }
    private isForward: boolean;
    public get IsForward(): boolean { return this.isForward; }
    constructor(item: MediatorItem, isFirst: boolean, isLast: boolean, isForward: boolean) {
        super();
        this.item = item;
        this.isFirstCtrl = isFirst;
        this.isLastCtrl = isLast;
        this.isForward = isForward;
    }
}

export class mediatorAfterFocusMoveProcessArgs extends MjsEventArgs {
    private item: MediatorItem;
    public get activeItem(): MediatorItem { return this.item; }
    public set activeItem(item: MediatorItem) { this.item = item; }
    constructor(item: MediatorItem/*, isFirst: boolean, isLast: boolean*/) {
        super();
        this.item = item;
    }
}

export class mediatorAfterLastItemBlurArgs extends MjsEventArgs {
    private isForward: boolean;
    public get IsForward(): boolean { return this.isForward; }
    constructor(isForward: boolean) {
        super();
        this.isForward = isForward;
    }
}

export class mediatorKeyDownEvnetHandler extends MjsEventArgs {
    private mediatorObj: Mediator
    private eObj: JQueryKeyEventObject
    public get mediator(): Mediator { return this.mediatorObj; }
    public get e(): JQueryKeyEventObject { return this.eObj; }
    constructor(mediator: Mediator, e: JQueryKeyEventObject) {
        super();
        this.mediatorObj = mediator;
        this.eObj = e;
    }
}

var focusedCount: number = 0;

/**
 * MediatorItemのベースクラス
 * 基本的な機能をパッケージングしたMediatorItemクラス
 */
abstract class AbstractMediatorItem implements MediatorItem {

    /**
     * アイテムがMediatorに登録された時のIndexを返す。
     */
    private itemIndex: number = 0;
    public getItemIndex(): number { return this.itemIndex; }

    /**
     * 主にInputタグを保持するJQueryが指定される
     */
    protected focusCtrl: JQuery;
    public getFocusControl(): JQuery { return this.focusCtrl; }
    /**
     * キーダウンのイベントをハンドラを設定する
     * remark:親Mediatorクラスに設定される前にハンドラを定義しておく必要がある。
     */
    protected keyDownEventHandler: (mediator: Mediator, e: JQueryKeyEventObject) => void;
    public set delegateKeyDownEvent(handler: (mediator: Mediator, e: JQueryKeyEventObject) => void) {
        this.keyDownEventHandler = handler;
    }
    /**
     * キーアップのイベントをハンドラを設定する
     * remark:親Mediatorクラスに設定される前にハンドラを定義しておく必要がある。
     */
    protected keyUpEventHandler: (mediator: Mediator, e: JQueryKeyEventObject) => void;
    public set delegateKeyUpEvent(handler: (mediator: Mediator, e: JQueryKeyEventObject) => void) {
        this.keyUpEventHandler = handler;
    }
    /**
     * 次のフォーカスに行くための判定を行う関数を指定する
     * TODO : Delegate定義して、APIを追加することを明示的にする
     */
    protected beforeBlurEventHandler: (args: mediatorBeforeBlurArgs) => void;
    public set delegateBeforeBlur(handler: (args: mediatorBeforeBlurArgs) => void) {
        this.beforeBlurEventHandler = handler;
    }
    /**
     * Focus移動する前に必要な処理を行う。
     * TODO : Delegate定義して、APIを追加することを明示的にする
     */
    protected beforeFocusEventHandler: (args: mediatorBeforeFocusArgs) => void;
    public set delegateBeforeFocus(handler: (args: mediatorBeforeFocusArgs) => void) {
        this.beforeFocusEventHandler = handler;
    }
    /**
     * 親となるMediatorのインスタンスを取得する
     */
    protected parentMediator!: Mediator;
    public getParentMediator(): Mediator { return this.parentMediator; }
    /**
     * イベントハンドラを保持するための変数
     */
    private eventHandlerList!: EventHandlerList;
    /**
     * コンストラクタ
     * @param control : 対象となるJQueryオブジェクト
     * @param beforeBlurEventHandler : フォーカス移動となる判定となるAPIを追加する
     */
    constructor(control: JQuery) {
        this.focusCtrl = control;
        this.beforeBlurEventHandler = undefined!;
        this.beforeFocusEventHandler = undefined!
        this.keyDownEventHandler = undefined!;
        this.keyUpEventHandler = undefined!;
    }
    /**
     * 自身をリストに追加したMediatorクラスのインスタンスを保持する
     * @param mediator
     */
    public setMediator(mediator: Mediator, itemIndex: number): void {
        this.parentMediator = mediator;
        this.itemIndex = itemIndex;
        if(itemIndex < 99) {
            this.focusCtrl.attr("tabindex", itemIndex);
        } else {
            this.focusCtrl.attr("tabindex", 99);
        }
    }
    /**
     * 対象となるコントローラにフォーカスを設定する
     * @return : Skipして次のコントロールに移動する場合は、trueを返す。
     */
    public setFocus(isForward: boolean): boolean {
        var args: mediatorBeforeFocusArgs = this.beforeFocus(isForward);
        if (!args.cancel && !args.skipToAfterNextItem) {
            this.focusCtrl.focus();

            focusedCount ++;
            Logger.debugTimeStamp("setFocus() " + focusedCount);
        }
        return args.skipToAfterNextItem;
    }
    /**
     * 対象のコントロールを持つMediatorItemかどうかを判断する
     * @param ctrl
     */
    public hasControl(ctrl: any): boolean {
        return this.focusCtrl == <JQuery>ctrl;
    }
    /**
     * コントロールにイベントハンドラを設定する
     */
    public setEventHandler(): void {
        this.eventHandlerList = <EventHandlerList>{
            focus: this.setFocusEventHandler(),
            keydown: this.setKeyDownEvnetHandler(),
            keyUp: this.setKeyUpEvnetHandler()
        };
    }
    /**
     * フォーカスアウト可能かどうかの判断を行う。
     * @return : true:Focus移動可能 false:Focus移動不可
     */
    public beforeBlur(isForward: boolean): boolean {
        if (this.beforeBlurEventHandler) {
            var args: mediatorBeforeBlurArgs = new mediatorBeforeBlurArgs(this, isForward);
            this.beforeBlurEventHandler(args);
            return !args.cancel;
        }
        return true;
    }
    /**
     * 自身にFocusを当てることができるかどうかを判定する
     */
    public canFocus(): boolean {
        // 自身がFocsuが当てられる状態かどうかを判定する
        
        // 自身がDisableだった場合はFocsuを当てることができないので、falseを返す。
        if (this.focusCtrl.attr("disabled") == "disabled") {
            return false;
        }

        return true;
    }
    /**
     * フォーカス可能かどうかの判断を行う。
     * @return : true:Focus移動可能 false:Focus移動不可
     */
    private beforeFocus(isForward: boolean): mediatorBeforeFocusArgs {
        var args: mediatorBeforeFocusArgs = new mediatorBeforeFocusArgs(this, isForward);
        if (this.beforeFocusEventHandler) {
            //args.Item = this;
            this.beforeFocusEventHandler(args);
            return args;
        }
        return args;
    }
    /**
     * MediatorItem内で設定されたイベントを削除する
     */
    public clearEvent() {
        if (this.eventHandlerList.focus) {
            this.focusCtrl.off("focus", this.eventHandlerList.focus);
        }
        if (this.eventHandlerList.keydown) {
            this.focusCtrl.off("keydown", this.eventHandlerList.keydown);
        }
        if (this.eventHandlerList.keyUp) {
            this.focusCtrl.off("keyup", this.eventHandlerList.keyUp);
        }
    }
    /**
     * Focus時のイベントハンドラーを設定
     */
    private setFocusEventHandler(): (eventObject: JQueryEventObject) => any {
        var handler: (eventObject: JQueryEventObject) => any = (e: JQueryEventObject) => {
            this.setActiveItemToMediator();
        };
        this.focusCtrl.on("focus", handler);
        return handler;
    }
    /**
     * KeyDown時のイベントハンドラーを設定
     */
    private setKeyDownEvnetHandler(): (eventObject: JQueryEventObject) => any {
        var handler: (eventObject: JQueryEventObject) => any = (e: JQueryKeyEventObject) => {
            //LoadingPanelが開いている時はメディエータイベントを発生させない。
            if (BusyIndicator.checkOpening()) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                return;
            }
            // ユーザが設定するKeyEventHandler
            if (this.keyDownEventHandler) {
                this.keyDownEventHandler(this.parentMediator, e);
            }
        };
        this.focusCtrl.on("keydown", handler);
        return handler;
    }
    /**
     * KeyUp時のイベントハンドラーを設定
     */
    private setKeyUpEvnetHandler(): (eventObject: JQueryEventObject) => any {
        var handler: (eventObject: JQueryEventObject) => any = (e: JQueryKeyEventObject) => {
            //LoadingPanelが開いている時はメディエータイベントを発生させない。
            if (BusyIndicator.checkOpening()) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                return;
            }
            // ユーザが設定するKeyEventHandler
            if (this.keyUpEventHandler) {
                this.keyUpEventHandler(this.parentMediator, e);
            }
        };
        this.focusCtrl.on("keyup", handler);
        return handler;
    }
    /**
     * 自分自身をリストとして保持するMediatorに対してActiveを設定する
     */
    protected setActiveItemToMediator(): void {
        if (this.parentMediator) {
            this.parentMediator.setActiveItem(this);
        }
    }
}
/**
 * 簡易なFocusを制御するためのMediatorクラスを作成
 */
export class Mediator {
    private mediatorItemList: Array<MediatorItem>;
    public get mediatorItems(): Array<MediatorItem> { return this.mediatorItemList; }
    private activeItem!: MediatorItem;

    // ひとつ前にFocusを持っていたItemのインデックスを保持する
    private setFirstItemIndex: number;

    private delegateBeforeFocusMoveProcess!: (args: mediatorBeforeFocusMoveProcessArgs) => void;
    public set beforeFocusMoveProcess(callBackApi: (args: mediatorBeforeFocusMoveProcessArgs) => void) { this.delegateBeforeFocusMoveProcess = callBackApi; }
    private delegateAfterBlurProcess!: (args: mediatorAfterBlurProcessArgs) => void;
    public set afterBlurProcess(callBackApi: (args: mediatorAfterBlurProcessArgs) => void) { this.delegateAfterBlurProcess = callBackApi; }
    private delegateAfterFocusMoveProcess!: (args: mediatorAfterFocusMoveProcessArgs) => void;
    public set afterFocusMoveProcess(callBackApi: (args: mediatorAfterFocusMoveProcessArgs) => void) { this.delegateAfterFocusMoveProcess = callBackApi; }
    private delegateRefreshSelectWindow!: (args: mediatorAfterFocusMoveProcessArgs) => void;
    public set refreshSelectWindowProcess(callBackApi: (args: mediatorAfterFocusMoveProcessArgs) => void) { this.delegateRefreshSelectWindow = callBackApi; }
    private delegateAfterLastItemBlur!: (args: mediatorAfterLastItemBlurArgs) => void;
    public set afterLastItemBlur(callBackApi: (args: mediatorAfterLastItemBlurArgs) => void) { this.delegateAfterLastItemBlur = callBackApi; }

    constructor() {
        this.mediatorItemList = new Array<MediatorItem>();
        this.setFirstItemIndex = -1;
    }

    /**
     * フォーカス制御を行うコントロールを追加する
     * @param item
     */
    public addControl(item: MediatorItem): void {

        // インデックスとして、追加前のLengthを指定する
        item.setMediator(this, this.mediatorItemList.length);

        item.setEventHandler();

        this.mediatorItemList.push(item);
    }

    /**
     * 末尾のコントロールを削除する
     */
    public removeLastControl() {
        const lastItem = this.mediatorItemList.pop();
        if(lastItem) {
            lastItem.clearEvent();
        }
    }

    /**
     * アクティブとなるコントロールを設定する
     * @param ctrl
     */
    public setActiveItem(ctrl: MediatorItem, isFireEvent: boolean = false): void {

        // 自身にFocusを当てれる状態でないときはFocusを当てる処理は行わない
        if (ctrl && !ctrl.canFocus()) {
            return;
        }

        //そうでないときはFocusを当てる処理を実行するk
        if (this.activeItem != ctrl || isFireEvent) {
            this.activeItem = ctrl;
            // ActiveItemの設定はnullも指定可能。
            if (this.activeItem) {
                this.callBackAfterFocusMoveProcess();
            }
        }
    }

    /**
     * SelectWindowを設定する
     * @param ctrl
     */
    public refreshSelectWindow(ctrl: MediatorItem): void {
        this.activeItem = ctrl;
        this.callBackRefreshSelectWindow();
    }

    /**
     * 引数に渡されるコントロールアイテムによってindexを返すアイテムが存在しない場合nullを返す
     */
    public getMediatorItemIndexByFocusControl(ctrl: JQuery): number {
        let itemControl = this.mediatorItemList.filter((val, idx) => {
            if (val.getFocusControl()[0] == ctrl[0]) {
                return true;
            }
            return false;
        });

        if (itemControl.length) {
            return itemControl[0].getItemIndex();
        }

        return null!;
    }

    /**
     * 現在の状態で、delegateBeforeFocusMoveProcess実行用のEventArgsを作成する
     * @param nextCtrl
     */
    private createBeforeArgs(nextItem: MediatorItem, isFirst: boolean, isLast: boolean): mediatorBeforeFocusMoveProcessArgs {
        var args: mediatorBeforeFocusMoveProcessArgs = new mediatorBeforeFocusMoveProcessArgs(this.activeItem, isFirst, isLast);
        //if (this.activeItem) {
        //    args.activeItem = this.activeItem;
        //}
        args.nextItem = nextItem;
        args.cancel = false;
        return args;
    }

    /**
     * アイテムレベルでBeforeBlurした後、MediatorとしてBeforeBlurを発行し、最終的な確認を行う。
     * @param isFirst
     * @param isLast
     */
    private createAfterBlurArgs(isFirst: boolean, isLast: boolean, isForward: boolean): mediatorAfterBlurProcessArgs {
        var args: mediatorAfterBlurProcessArgs = new mediatorAfterBlurProcessArgs(this.activeItem, isFirst, isLast, isForward);
        return args;
    }

    /**
     * 現在の状態で、delegateAfterFocusMoveProcess実行用のeventargsを作成する
     */
    private createAfterArgs(): mediatorAfterFocusMoveProcessArgs {
        var args: mediatorAfterFocusMoveProcessArgs = new mediatorAfterFocusMoveProcessArgs(this.activeItem);
        if (this.activeItem) {
            args.activeItem = this.activeItem;
        }
        return args;
    }
    /**
     * delegate(Before)が指定されている場合に実行してその結果のargsを返す。
     * @param nextCtrl
     */
    private callBackBeforeFocusMoveProcess(nextItem: MediatorItem, isFirst: boolean, isLast: boolean): mediatorBeforeFocusMoveProcessArgs {
        var args: mediatorBeforeFocusMoveProcessArgs = this.createBeforeArgs(nextItem, isFirst, isLast);
        args.cancel = nextItem === undefined;
        if (this.delegateBeforeFocusMoveProcess) {
            this.delegateBeforeFocusMoveProcess(args);
        }
        return args;
    }


    /**
     * delegate(AfterLastItemBlur)が指定されている場合に実行してその結果のargsを返す。
     * @param nextCtrl
     */
    private callBackAfterLastItemBlur(isForward: boolean): mediatorAfterLastItemBlurArgs {
        var args: mediatorAfterLastItemBlurArgs = this.createAfterLastItemBlurArgs(isForward);
        if (this.delegateAfterLastItemBlur) {
            this.delegateAfterLastItemBlur(args);
        }
        return args;
    }

    /**
     * 現在の状態で、delegateBeforeFocusMoveProcess実行用のEventArgsを作成する
     * @param nextCtrl
     */
    private createAfterLastItemBlurArgs(isForward: boolean): mediatorAfterLastItemBlurArgs {
        var args: mediatorAfterLastItemBlurArgs = new mediatorAfterLastItemBlurArgs(isForward);
        return args;
    }

    /**
     * delegate(Before)が指定されている場合に実行してその結果のargsを返す。
     * @param nextCtrl
     */
    private callBackAfterBlurProcess(isFirst: boolean, isLast: boolean, isForward: boolean): mediatorAfterBlurProcessArgs {
        var args: mediatorAfterBlurProcessArgs = this.createAfterBlurArgs(isFirst, isLast, isForward);
        if (this.delegateAfterBlurProcess) {
            this.delegateAfterBlurProcess(args);
        }
        return args;
    }

    /**
     * delegate(After)が指定されている場合に実行してその結果のargsを返す。
     * @param nextCtrl
     */
    private callBackAfterFocusMoveProcess(): void {
        if (this.delegateAfterFocusMoveProcess) {
            var args: mediatorAfterFocusMoveProcessArgs = this.createAfterArgs();
            this.delegateAfterFocusMoveProcess(args);
        }
    }
    /**
     * delegate(After)が指定されている場合に実行してその結果のargsを返す。
     * @param nextCtrl
     */
    private callBackRefreshSelectWindow(): void {
        if (this.delegateRefreshSelectWindow) {
            var args: mediatorAfterFocusMoveProcessArgs = this.createAfterArgs();
            this.delegateRefreshSelectWindow(args);
        }
    }
    /**
     * MediatorItemを通じて、ValidationCheckを実行する
     */
    private checkValidateItem(isForward: boolean): boolean {
        if (this.activeItem) {
            return this.activeItem.beforeBlur(isForward);
        }
        return true;
    }

    /**
     * 現在Focus中のアイテムを返す。
     */
    public getActiveItem(): MediatorItem {
        return this.activeItem;
    }

    /**
     * フォーカス移動のための処理
     * @param nextItem
     */
    private setFocusMoveProcess(isForward: boolean, isNeedValidateCheck: boolean) {
        var actIndex: number = this.mediatorItemList.indexOf(this.activeItem);
        // ItemごとのValidationチェックを最初に行う。
        if (isNeedValidateCheck && !this.checkValidateItem(isForward)) {
            return;
        }

        // 各アイテムではなく、Mediator管理のAfterBlur処理を行う。
        this.afterBlur(isForward, actIndex);

        // 方向と次の対象アイテムを取得
        var direction: number = isForward ? 1 : -1;

        // ループして次のコントロールにFocusを移動する
        for (var i: number = actIndex; true; i = i + direction) {
            if (actIndex === i) { continue; }
            var nextItem: MediatorItem = this.mediatorItemList[i];
            if (this.setFocusProcess(isForward, nextItem, i)) {
                break;
            }
            if (i >= this.mediatorItemList.length || i < 0) {
                break;
            }
        }

        // 最後のアイテムのBlur処理が行われていた場合の処理
        if (i >= (this.mediatorItemList.length) || i < 0) {
            this.callBackAfterLastItemBlur(isForward);
        }
    }
    /**
         * リストの先頭のコントロールにFocusを当てる
         * 主に画面表示時の初期フォーカス位置の指定に使用する
         */
    private setFocusToLastOrFirstProcess(toFirstCtrl: boolean, isNeedValidateCheck: boolean): void {
        // 方向と次の対象アイテムを取得
        //var actIndex: number = this.mediatorItemList.indexOf(this.activeItem);
        var index: number = toFirstCtrl ? 0 : this.mediatorItemList.length - 1;
        var actIndex = index;
        var direction: number = toFirstCtrl ? 1 : -1;
        // ループしてFocusを移動する
        for (var i: number = index; i < this.mediatorItemList.length; i = i + direction) {
            var nextItem: MediatorItem = this.mediatorItemList[i];
            if (this.setFocusProcess(true, nextItem, actIndex)) {
                break;
            }
        }
    }
    protected setFocusProcess(isForward: boolean, nextItem: MediatorItem, actIndex: number): boolean {
        var isFirst: boolean = false;
        var isLast: boolean = false;
        if (actIndex > -1) {
            isFirst = this.mediatorItemList[actIndex - 1] === undefined;
            isLast = this.mediatorItemList[actIndex + 1] === undefined;
        }
        // MediatorレベルのBeforeイベントを実行し、cancelフラグが立っていなければFocus移動処理を行う。
        var args = this.callBackBeforeFocusMoveProcess(nextItem, isFirst, isLast);
        // 最終コントロールから次、最初のコントロールから前のコントロールが選択された場合はイベントのみ発行となる
        if (args.nextItem && !args.nextItem.setFocus(isForward)) {
            if (!args.cancel) {

                // 保持するアイテムで、最初にFocusを当てたアイテムを保持しておく
                // MediatorのActiveItemのItemIndexが移動しているかどうかの判定するため。
                if (this.setFirstItemIndex == -1) {
                    // 初回のみ、最初に当たるIndexとして保持する
                    this.setFirstItemIndex = args.nextItem.getItemIndex();
                }
                //else {
                //    // それ以外は、Focusが当たっていたIndexとして保持
                //    if (this.activeItem) {
                //        this.setFirstItemIndex = this.activeItem.getItemIndex();
                //    }
                //}

                return true;
            }
        }
        return false;
    }

    private afterBlur(isForward: boolean, actIndex: number): void {
        var isFirst: boolean = false;
        var isLast: boolean = false;
        if (actIndex > -1) {
            isFirst = this.mediatorItemList[actIndex - 1] === undefined;
            isLast = this.mediatorItemList[actIndex + 1] === undefined;
        }
        // MediatorレベルのBeforeイベントを実行し、cancelフラグが立っていなければFocus移動処理を行う。
        this.callBackAfterBlurProcess(isFirst, isLast, isForward);
    }

    /**
     * リストの最初のコントロールにFocusを当てる
     * 【注意点】
     *  Focusが外れてしまった場合の処理となるため、再Focus時にはイベントは
     *  発生しない。
     */
    public setFocusToActiveItem(): void {
        if (this.activeItem) {
            this.activeItem.setFocus(false);
        }
    }
    /**
     * リストの最初のコントロールにFocusを当てる
     */
    public setFocusToFirstItem(): void {
        if (this.mediatorItemList.length == 0) { return; }
        this.setFocusToLastOrFirstProcess(true, false);
    }
    /**
     * リストの最後尾のコントロールにFocusを当てる
     */
    public setFocusToLastItem(): void {
        if (this.mediatorItemList.length == 0) { return; }
        this.setFocusToLastOrFirstProcess(false, false);
    }
    /**
     * 次のコントロールにフォーカスを移動する
     */
    public setFocusToNextItem(isNeedValidateCheck: boolean = true): void {
        this.setFocusMoveProcess(true, isNeedValidateCheck);
    }
    /**
     * 前のコントロールにフォーカスを移動する
     */
    public setFocusToPrevItem(isNeedValidateCheck: boolean = true): void {
        this.setFocusMoveProcess(false, isNeedValidateCheck);
    }
    public isActiveItem(item: MediatorItem): boolean {
        return (this.activeItem == item);
    }
    /**
     * 登録されているすべてのMediatorItemを削除する
     */
    public removeAllMediatoritems() {
        $.each(this.mediatorItemList, (i, v) => {
            v.clearEvent();
        });
        this.mediatorItemList = new Array<MediatorItem>();
    }

    /**
     * 最初にFocusが当たったコントロールからFocusが移動しているかどうかを判断する。
     * 判断できるのは、Mediatorを使用してFocus移動している場合に限り、JQueryでのFocus設定は
     * 無視される。
     */
    public isFocusMoved() {
        if (this.activeItem) {
            let activeIndex = this.activeItem.getItemIndex();
            return this.setFirstItemIndex != activeIndex;
        }

        // 判定できない場合は「false」で固定
        return false;
    }

}

/**
 * Focusのあるコントロール以降のコントロールおすべてDisableにする機能を付加したMediator
 * 主に入力Grid用に使用する。
 */
/* todo: 下記クラスの実装
export class MediatorModeDisable extends Mediator {
    public setActiveItem(ctrl: MediatorItem, isFireEvent: boolean = false): void {

        // 元の処理を行う。
        super.setActiveItem(ctrl, isFireEvent);
        // 現状Activeなアイテムを取得
        let activeItem: MediatorItem = this.getActiveItem();

        // その後のアイテムをDisable化する
        if (activeItem) {
            let activeIndex: number = this.getActiveItem().getItemIndex();

            // 移行のコントロールをすべてDisable化する
            for (let i: number = activeIndex + 1; i < this.mediatorItems.length; i++) {
                let item: MediatorItem = this.mediatorItems[i];
                
                if (item) {
                    let colKey = item.getFocusControl().attr(controls.userControls.journalGrid.journalGridCol.gridColKey);
                    let $colName = item.getFocusControl().parent().find(`input[colkey="${colKey}_name"]`);
                    
                    if ($colName.length !== 0) $colName.attr("disabled", "disabled");
                    item.getFocusControl().attr("disabled", "disabled");
                }
            }
        }

        let tekiyoCodeInputPopup = controls.userControls.journalGrid.journalGridCol.ColTekiyo.tekiyoCodeInputPopup;
        let supplierCodeInputPopup = controls.userControls.journalGrid.journalGridCol.ColTekiyo.supplierCodeInputPopup;

        if (tekiyoCodeInputPopup) tekiyoCodeInputPopup.hide();
        if (supplierCodeInputPopup) supplierCodeInputPopup.hide();
    }

}
*/

//摘要コードコントロールを非表示にする
/**
 * Focusのあるコントロール以降のコントロールおすべてDisableにする機能を付加したMediator
 * 主に入力Grid用に使用する。
 */
/* Todo：下記の実装
export class MediatorModeDisableForPurchase extends Mediator {
    public setActiveItem(ctrl: MediatorItem, isFireEvent: boolean = false): void {

        // 元の処理を行う。
        super.setActiveItem(ctrl, isFireEvent);
        // 現状Activeなアイテムを取得
        let activeItem: MediatorItem = this.getActiveItem();

        // その後のアイテムをDisable化する
        if (activeItem) {
            let activeIndex: number = this.getActiveItem().getItemIndex();

            // 移行のコントロールをすべてDisable化する
            for (let i: number = activeIndex + 1; i < this.mediatorItems.length; i++) {
                let item: MediatorItem = this.mediatorItems[i];

                if (item) {
                    if (activeIndex == 1 || activeIndex == 4) {
                        continue;
                    }
                    item.getFocusControl().attr("disabled", "disabled");
                }
            }
        }

        let tekiyoCodeInputPopup = controls.userControls.journalGrid.journalGridCol.ColTekiyo.tekiyoCodeInputPopup;
        let supplierCodeInputPopup = controls.userControls.journalGrid.journalGridCol.ColTekiyo.supplierCodeInputPopup;
        
        //摘要コードコントロールと仕入先コードコントロールを非表示にする
        if (tekiyoCodeInputPopup) tekiyoCodeInputPopup.hide();
        if (supplierCodeInputPopup) supplierCodeInputPopup.hide();
    }

}
*/

export enum fixedItem {
    fxNo,
    fxName,
    fxRenso,
    fxKmk,
    fxHojyo1,
    fxHojyo2,
    fxHojyo3,
    denNo,
    grid
}

export enum fixedItemVoucher {
    fxNo,
    fxName,
    fxRenso,
    denNo,
    grid
}

export class MediatorModeDisableForFixed extends Mediator {


    public setActiveItem(ctrl: MediatorItem, isFireEvent: boolean = false): void {
        // 元の処理を行う。
        super.setActiveItem(ctrl, isFireEvent);

        // 現状Activeなアイテムを取得
        let activeItem: MediatorItem = this.getActiveItem();

        if (activeItem) {
            let activeIndex: number = this.getActiveItem().getItemIndex();
            switch (activeIndex) {
                case fixedItem.fxNo:
                case fixedItem.fxName:
                case fixedItem.fxRenso:
                    this.disableAfterAll(activeIndex);
                    break;
                case fixedItem.fxKmk:
                case fixedItem.fxHojyo1:
                case fixedItem.fxHojyo2:
                case fixedItem.fxHojyo3:
                    this.disableAfterKmk();
                    break
                case fixedItem.denNo:
                    this.disableAfterDenNo();
                    break;
                case fixedItem.grid:
                    this.disableFocusGrid();
                    break;
            }
        }
    }

    private disableAfterAll(activeIndex: number) {
        // 移行のコントロールをすべてDisable化する
        for (let i: number = activeIndex + 1; i < this.mediatorItems.length; i++) {
            let item: MediatorItem = this.mediatorItems[i];

            if (item) {
                item.getFocusControl().attr("disabled", "disabled");
            }
        }
    }

    private disableAfterKmk() {
        $("#detailBlock").attr("disabled", "disabled");
    }

    private disableAfterDenNo() {
        $("#detailBlock").attr("disabled", "disabled");
    }

    private disableFocusGrid() {
        for (let i: number = 3; i < this.mediatorItems.length - 1; i++) {
            let item: MediatorItem = this.mediatorItems[i];

            if (item) {
                item.getFocusControl().attr("disabled", "disabled");
            }
        }
    }
}

export class MediatorModeDisableForFiexedVourcher extends Mediator {
    public setActiveItem(ctrl: MediatorItem, isFireEvent: boolean = false): void {
        // 元の処理を行う。
        super.setActiveItem(ctrl, isFireEvent);

        // 現状Activeなアイテムを取得
        let activeItem: MediatorItem = this.getActiveItem();

        if (activeItem) {
            let activeIndex: number = this.getActiveItem().getItemIndex();
            switch (activeIndex) {
                case fixedItem.fxNo:
                case fixedItem.fxName:
                case fixedItem.fxRenso:
                    this.disableAfterAll(activeIndex);
                    break;
                case fixedItem.denNo:
                    this.disableAfterDenNo();
                    break;
                case fixedItem.grid:
                    this.disableFocusGrid();
                    break;
            }
        }
    }

    private disableAfterAll(activeIndex: number) {
        // 移行のコントロールをすべてDisable化する
        for (let i: number = activeIndex + 1; i < this.mediatorItems.length; i++) {
            let item: MediatorItem = this.mediatorItems[i];

            if (item) {
                item.getFocusControl().attr("disabled", "disabled");
            }
        }
        $("#detailBlock").attr("disabled", "disabled");

    }

    private disableAfterDenNo() {
        $("#detailBlock").attr("disabled", "disabled");
    }

    private disableFocusGrid() {
        for (let i: number = 3; i < this.mediatorItems.length - 1; i++) {
            let item: MediatorItem = this.mediatorItems[i];

            if (item) {
                item.getFocusControl().attr("disabled", "disabled");
            }
        }
    }
}

/**
     * 標準的なメディエータアイテム。
     * キーイベントの変更に対応しており、キーイベントハンドラをページ側で指定したものに変更できる。
     * 対応しているのは
     *
     * キープレス字のイベントハンドラは指定しない場合は、親Mediatorクラスに追加する前に設定しておく必要がある。
     */
export class MediatorGeneralItem extends AbstractMediatorItem {
    protected iosKeyDownCode: number = null!;

    constructor(control: JQuery) {
        super(control);
    }

    /**
     * キー入力をチェック
     * true: 
     */
    protected isStartPosition(): boolean {
        let $input: JQuery = this.focusCtrl;

        // 文字列系かそれ以外かで処理を分ける
        if ($input.attr(InputBase.inputType) != InputBase.typeStrInput) {
            // 数値系入力、コンボボックスの場合はキャレットが移動しないため、常にtrueを返す。
            return true;
        }

        let elm: HTMLInputElement = <HTMLInputElement>$input[0];
        if (elm) {
            // スタート/エンドがともに開始位置の時のみ
            return elm.selectionStart === 0 &&
                elm.selectionEnd === 0;
        }
        return false;
    }

    /**
     * キー入力をチェック
     * true: 
     */
    protected isEndPosition(): boolean {
        let $input: JQuery = this.focusCtrl;

        // 文字列系かそれ以外かで処理を分ける
        if ($input.attr(InputBase.inputType) != InputBase.typeStrInput) {
            // 数値系入力、コンボボックスの場合はキャレットが移動しないため、常にtrueを返す。
            return true;
        }

        let elm: HTMLInputElement = <HTMLInputElement>$input[0];
        if (elm) {
            return elm.selectionStart === elm.value.length &&
                elm.selectionEnd === elm.value.length;
        }
        return false;
    }

    /**
     * キー入力をチェック
     * true: 
     */
    protected isAllSelected(): boolean {
        let $input: JQuery = this.focusCtrl;
        let elm: HTMLInputElement = <HTMLInputElement>$input[0];
        if (elm) {
            return elm.selectionStart === 0 &&
                elm.selectionEnd === elm.value.length;
        }
        return false;
    }

    /**
     * キャレットの位置インデックスを1に設定する
     */
    protected setCaretToFirst(): void {
        let $input: JQuery = this.focusCtrl;
        let elm: HTMLInputElement = <HTMLInputElement>$input[0];
        if (elm && elm.value && elm.value.length > 0) {
            elm.selectionStart = 1;
            elm.selectionEnd = 1;
        }
    }
}

/**
 * TabEnterで次のFocusに移動する本機能では標準動作を行うMediator
 */
export class MediatorTabEnterItem extends MediatorGeneralItem {
    constructor(control: JQuery) {
        super(control)
        this.delegateKeyDownEvent = (mediator: Mediator, e: JQueryKeyEventObject) => {
            this.mediatorKeyDownEventBasic(mediator, e);
            this.mediatorKeyDownArrowEventBasic(mediator, e);
        };
    }
    /**
     * Mediatorで呼ばれるキーイベント処理
     * @param mediatorItem
     * @param e
     */
    private mediatorKeyDownEventBasic(mediator: Mediator, e: JQueryKeyEventObject): void {
        // デフォルトのイベントハンドラ
        switch (e.keyCode) {
            case 9:
                if (e.shiftKey) {
                    mediator.setFocusToPrevItem();
                } else {
                    mediator.setFocusToNextItem();
                }
                e.preventDefault();
                break;
            case 13:
                mediator.setFocusToNextItem();
                e.preventDefault();
                break;
        }
    }

    /**
    * Mediatorで呼ばれる矢印キーイベント処理
    * @param mediatorItem
    * @param e
    */
    protected mediatorKeyDownArrowEventBasic(mediator: Mediator, e: JQueryKeyEventObject): void {
        // デフォルトのイベントハンドラ
        switch (e.keyCode) {
            case $.ui.keyCode.LEFT:
                mediator.setFocusToPrevItem();
                e.preventDefault();
                break;

            case $.ui.keyCode.RIGHT:
                mediator.setFocusToNextItem();
                e.preventDefault();
                break;

            // ↑↓キーでのFocus移動なし
            case $.ui.keyCode.UP:
            case $.ui.keyCode.DOWN:
                // コンボボックスはリストを選択するためキャンセルしない
                if (this.focusCtrl.attr(InputBase.inputType) != InputBase.typeComboInput) {
                    e.preventDefault();
                }
                break;


        }
    }
}

/**
 * グリッド用のTabEnterItem
 */
export class MediatorGridTabEnterItem extends MediatorTabEnterItem {
    /**
    * Mediatorで呼ばれる矢印キーイベント処理
    * @param mediatorItem
    * @param e
    */
    protected mediatorKeyDownArrowEventBasic(mediator: Mediator, e: JQueryKeyEventObject): void {
        // デフォルトのイベントハンドラ
        switch (e.keyCode) {
            case $.ui.keyCode.LEFT:
                mediator.setFocusToPrevItem();
                e.preventDefault();
                break;

            case $.ui.keyCode.RIGHT:
                mediator.setFocusToNextItem();
                e.preventDefault();
                break;
        }
    }
}

/**
* Tabで次のFocusに移動する本機能ではボタンの制御を行うMediator
*/
export class MediatorButtonTabItem extends MediatorGeneralItem {
    constructor(control: JQuery) {
        super(control)
        this.delegateKeyDownEvent = (mediator: Mediator, e: JQueryKeyEventObject) => {
            this.mediatorKeyDownEventBasic(mediator, e);
            this.mediatorKeyDownArrowEventBasic(mediator, e);
        };
    }
    /**
     * Mediatorで呼ばれるキーイベント処理
     * @param mediatorItem
     * @param e
     */
    private mediatorKeyDownEventBasic(mediator: Mediator, e: JQueryKeyEventObject): void {
        // デフォルトのイベントハンドラ
        switch (e.keyCode) {
            case 9:
                if (e.shiftKey) {
                    mediator.setFocusToPrevItem();
                } else {
                    mediator.setFocusToNextItem();
                }
                e.preventDefault();
                break;
        }
    }

    /**
    * Mediatorで呼ばれる矢印キーイベント処理
    * @param mediatorItem
    * @param e
    */
    private mediatorKeyDownArrowEventBasic(mediator: Mediator, e: JQueryKeyEventObject): void {
        // デフォルトのイベントハンドラ
        switch (e.keyCode) {
            case 37:
                mediator.setFocusToPrevItem();
                e.preventDefault();
                break;
            case 39:
                mediator.setFocusToNextItem();
                e.preventDefault();
                break;
            // ↑↓キーでのFocus移動なし
            case 38:
            case 40:
                e.preventDefault();
                break;
        }
    }
}

/**
 * TabEnterで次のFocusに移動し、左右キーでキャレット移動可能な MediatorItem
 */
export class CaretMovableMediatorItem extends MediatorGeneralItem {
    constructor(control: JQuery) {
        super(control)
        this.delegateKeyDownEvent = (mediator: Mediator, e: JQueryKeyEventObject) => {
            this.mediatorKeyDownEventBasic(mediator, e);
            this.mediatorKeyDownArrowEventBasic(mediator, e);
        };
    }
    /**
     * KeyDown イベントを制御する
     * @param mediator
     * @param e
     */
    private mediatorKeyDownEventBasic(mediator: Mediator, e: JQueryKeyEventObject): void {
        switch (e.keyCode) {
            case $.ui.keyCode.TAB:
                if (e.shiftKey) {
                    // フォーカスを前に移す
                    mediator.setFocusToPrevItem();
                } else {
                    // フォーカスを次に移す
                    mediator.setFocusToNextItem();
                }
                e.preventDefault();
                break;
            case $.ui.keyCode.ENTER:
                // フォーカスを次に移す
                mediator.setFocusToNextItem();
                e.preventDefault();
                break;
            default:
                break;

        }
    }
    /**
     * Mediatorで呼ばれる矢印キーイベント処理
     * @param mediatorItem
     * @param e
     */
    protected mediatorKeyDownArrowEventBasic(mediator: Mediator, e: JQueryKeyEventObject): void {
        // デフォルトのイベントハンドラ
        switch (e.keyCode) {
            case $.ui.keyCode.LEFT:
                // キャレットが先頭にあればフォーカスを前に移す
                if (this.isStartPosition()) {
                    mediator.setFocusToPrevItem();
                    e.preventDefault();
                }
                break;
            case $.ui.keyCode.RIGHT:
                // キャレットが末尾にあればフォーカスを次に移す
                if (this.isEndPosition()) {
                    mediator.setFocusToNextItem();
                    e.preventDefault();
                }
                break;
            case $.ui.keyCode.UP:
            case $.ui.keyCode.DOWN:
                e.preventDefault();
                break;
        }
    }
}

/**
 * コード入力用に適したフォーカス制御を含めたメディエータItem
 */
export class CaretMovableMediatorItemForCodeInput extends CaretMovableMediatorItem {
    protected mediatorKeyDownArrowEventBasic(mediator: Mediator, e: JQueryKeyEventObject): void {
        // デフォルトのイベントハンドラ
        switch (e.keyCode) {
            case $.ui.keyCode.LEFT:
                // キャレットが先頭にあればフォーカスを前に移す
                if (this.isAllSelected() || this.isStartPosition()) {
                    mediator.setFocusToPrevItem();
                    e.preventDefault();
                }
                break;
            case $.ui.keyCode.RIGHT:
                // キャレットが末尾にあればフォーカスを次に移す
                if (this.isEndPosition()) {
                    mediator.setFocusToNextItem();
                    e.preventDefault();
                } else if (this.isAllSelected()) {
                    this.setCaretToFirst();
                    e.preventDefault();
                }
                break;
            case $.ui.keyCode.UP:
            case $.ui.keyCode.DOWN:
                e.preventDefault();
                break;
        }
    }
}
