import React from 'react';
// import * as ReactN from 'reactn';
import Axios from 'axios';
import { Form, FormGroup, Label, Input } from 'reactstrap';

// リソース(定数)関連のインポート
import * as labels from '../../../../constants/labels';
import * as message from '../../../../constants/message';
import { DisplayModeEnum, AbleEnum, DataTypeEnum, TargetTMonthEnum } from '../../../../constants/enums';
import { DataTypeEnum as DataTypeMap } from '../../../../constants/dataMaps';
import { getValueFromDataMap } from '../../../../utils/dataMapsUtils';

// 型のインポート
import { ChecklistSettingViewModel } from '../../../../models/checklistSettingViewModel';

// コンポーネントのインポート
import ConfirmationPopup from '../../../../components/molecules/CMN/ConfirmationPopup/ConfirmationPopup';
import ExecutingCancel from '../../../../components/molecules/CMN/ExecutingCancel/ExecutingCancel';
import InputTextArea from '../../../../components/atoms/InputTextArea/InputTextArea';
import Popup from '../../../../components/molecules/CMN/Popup/Popup';
import ProcessMonthListSelection from '../../../../components/molecules/CMN/ProcessMonthListSelection/ProcessMonthListSelection';
import ValidInvalidRadio from '../../../../components/molecules/CMN/ValidInvalidRadio/ValidInvalidRadio';

// 基本URL
// const BaseGetUrl = '/api/v1/ChecklistSetting';
// const BasePostUrl = '/api/v1/ChecklistSetting/Checklist';
const BaseInitialUrl = '/api/v1/ChecklistSetting';
const BaseExistentUrl = '/api/v1/ChecklistSetting/Checklist';
// 加工後URL
export const GetInitialUrl = [BaseInitialUrl, 'Initial'].join('/'); // 初期表示新規データ作成用
export const PostInitialUrl = [BaseInitialUrl, 'Checklist'].join('/'); // チェックリスト登録（新規）用
export const EditionUrl = (ncode: number) => [BaseExistentUrl, ncode.toString()].join('/'); // 編集用
export const CopyingUrl = (ncode: number) => [BaseExistentUrl, ncode.toString(), 'Copy'].join('/'); // コピー用

// 項目ラベルのCSS Class
const labelClass = 'text-lbl';

// 引数の型定義
type ChecklistSettingProps = {
  activated: boolean;
  onClose: () => void;
  displayMode: DisplayModeEnum;
  code: number;
  dataType: DataTypeEnum;
};
type ChecklistSettingFormEvent = React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>;

// コンポーネントの定義
const ChecklistSetting: React.FC<ChecklistSettingProps> = props => {
  // 取消確認ダイアログ
  const [cancelActivated, setCancelActivated] = React.useState(false);

  const handleCancelClose = () => {
    setCancelActivated(false);
  };
  const handleCancelYes = () => {
    Promise.all([AjaxTask()]);
    setCancelActivated(false);
  };
  const handleCancelNo = () => {
    setCancelActivated(false);
  };
  // チェックリスト設定ViewModel
  const [viewModel, updateViewModel] = React.useState(new ChecklistSettingViewModel());

  // 保存処理
  const handleSaveClick = async () => {
    switch (props.displayMode) {
      case DisplayModeEnum.Copy:
        await ViewModelOnPost(CopyingUrl(props.code));
      // case DisplayModeEnum.New:
      case DisplayModeEnum.Update:
        await ViewModelOnPost(EditionUrl(props.code));
      default:
        // 新規
        await ViewModelOnPost(PostInitialUrl);
        break;
    }
  };
  const handleCancelClick = () => {
    setCancelActivated(true);
  };
  const handleInputChange = (event: ChecklistSettingFormEvent) => {
    const target = event.currentTarget;
    const name = target.name;
    const value =
      target.type === 'checkbox'
        ? (target as HTMLInputElement).checked
        : target.type === 'radio' && target.dataset.type === 'AbleEnum'
        ? target.value === AbleEnum.Enable.toString()
        : target.value;

    updateViewModel({ ...viewModel!, [name]: value });
  };
  const handleSelectChange = (targetTMonth: TargetTMonthEnum, tMonthCustomize: number[]) => {
    updateViewModel({ ...viewModel!, TargetTMonthKbn: targetTMonth, NMonth: tMonthCustomize });
  };

  const handleRadioChange = (isValid: boolean) => {
    updateViewModel({ ...viewModel!, YukoFlg: isValid });
  };

  // 通信処理
  const InitialOnGet = async (url: string) => {
    try {
      const response = await Axios.get<ChecklistSettingViewModel>(url);
      updateViewModel({ ...response.data });
    } catch (error) {}
  };
  const ExistentOnGet = async (url: string, dataType: DataTypeEnum) => {
    try {
      const response = await Axios.get<ChecklistSettingViewModel>(url, { data: dataType });
      updateViewModel({ ...response.data });
    } catch (error) {}
  };
  const ViewModelOnPost = async (url: string) => {
    try {
      await Axios.post(url, viewModel);
    } catch (error) {}
  };
  const AjaxTask = async () => {
    switch (props.displayMode) {
      case DisplayModeEnum.New:
        await InitialOnGet(GetInitialUrl);
        break;
      case DisplayModeEnum.Update:
        await ExistentOnGet(EditionUrl(props.code), props.dataType);
        break;
      case DisplayModeEnum.Copy:
        await ExistentOnGet(CopyingUrl(props.code), props.dataType);
      default:
        // error
        break;
    }
  };

  React.useEffect(() => {
    AjaxTask();
  }, [props.displayMode]);

  return typeof viewModel.NCode === 'undefined' ? (
    <div />
  ) : (
    <Popup
      id='ChecklistSetting'
      isOpen={props.activated}
      onCloseClick={props.onClose}
      header={labels.AIKADT001000005_FUNCTION_NAME}
      footer={
        <ExecutingCancel
          executeLabel={labels.COMMON_BUTTON_FUNCTION_SAVE}
          onExecuteClick={handleSaveClick}
          cancelLabel={labels.COMMON_BUTTON_FUNCTION_CANCEL}
          onCancelClick={handleCancelClick}
        />
      }
    >
      <ChecklistSettingForm
        mode={props.displayMode}
        model={viewModel}
        onInputChange={handleInputChange}
        onSelectChange={handleSelectChange}
        onRadioSelectChange={handleRadioChange}
      />
      <ConfirmationPopup
        isOpen={cancelActivated}
        onClose={handleCancelClose}
        onYesClick={handleCancelYes}
        onNoClick={handleCancelNo}
      >
        {message.Common_Confirmation_Cancel}
      </ConfirmationPopup>
    </Popup>
  );
};

export default ChecklistSetting;

// HOWTO: フォーム入力は
//    [React標準実装](https://ja.reactjs.org/docs/forms.html)を使用します。
//    [reactstrap標準実装](https://reactstrap.github.io/components/form/)を使用します。
// HOWTO: 入力フォームを別だしにして定義します。
// HOWTO: 　ここでは引数だけを使って正しく表示することに専念します。
type ChecklistSettingFormProps = {
  mode: DisplayModeEnum;
  model: ChecklistSettingViewModel;
  onInputChange: (event: ChecklistSettingFormEvent) => void;
  onSelectChange: (targetTMonth: TargetTMonthEnum, tMonthCustomize: number[]) => void;
  onRadioSelectChange: (isValid: boolean) => void;
};
const ChecklistSettingForm: React.FC<ChecklistSettingFormProps> = props => {
  // TODO: 決算月の複数シナリオ用内部月を除外する？？？場合
  const months = props.model.TMonths!.filter(
    item => ![6, 7, 16, 17, 26, 27, 36, 37].some(month => month === item.NMonth)
  );
  return (
    <Form>
      <FormGroup>
        <Label className={labelClass}>
          <b>{labels.AIKADT001000005_INPUT_HEADER_TYPE}</b>
        </Label>
        <Label className='w-100 m-0'>{getValueFromDataMap(DataTypeMap, props.model.DataTypeKbn!)}</Label>
      </FormGroup>
      <FormGroup>
        <Label for={'CklName'} className={labelClass}>
          <b>{labels.AIKADT001000005_INPUT_HEADER_NAME}</b>
        </Label>
        <Input
          type='text'
          id={'CklName'}
          name={'CklName'}
          placeholder={labels.AIKADT001000005_INPUT_PLACEHOLDER_NAME}
          value={props.model.CklName}
          onChange={props.onInputChange}
          disabled={!props.model.EditableFlg}
        />
      </FormGroup>
      <FormGroup>
        <Label className={labelClass}>
          <b>{labels.AIKADT001000005_INPUT_HEADER_TARGETSELECT}</b>
        </Label>
        <ProcessMonthListSelection
          TMonth={months}
          targetTMonth={props.model.TargetTMonthKbn}
          tMonthCustomize={props.model.NMonth}
          onChangeItem={props.onSelectChange}
          disabled={!props.model.EditableFlg}
        />
      </FormGroup>
      <FormGroup>
        <Label for={'CklContent'} className={labelClass}>
          <b>{labels.AIKADT001000005_INPUT_HEADER_CONTENT}</b>
        </Label>
        <InputTextArea
          enable={{
            top: false,
            right: false,
            bottom: true,
            left: false,
            topRight: false,
            bottomRight: false,
            topLeft: false,
            bottomLeft: false
          }}
          name={'CklContent'}
          placeholder={labels.AIKADT001000005_INPUT_PLACEHOLDER_CONTENT}
          value={props.model.CklContent}
          onInputChange={props.onInputChange}
          disabled={!props.model.EditableFlg}
        />
      </FormGroup>
      <FormGroup>
        <Label className={labelClass}>
          <b>{labels.AIKADT001000005_INPUT_HEADER_VALID}</b>
        </Label>
        <ValidInvalidRadio name='YukoFlg' isValid={props.model.YukoFlg} onRadioChange={props.onRadioSelectChange} />
      </FormGroup>
    </Form>
  );
};
