/**
 * マスターデータ ViewModel クラス
 */
import { DataModelBase } from "../../core/models/dataModelBase";
export class HojyoKmkInfoCacheViewModel extends DataModelBase {
    /** 顧問先管理コード */
    public ClientCode!: number;

    /** 年度 */
    public ClientYear!: number;

    /** 顧問先管理ユーザー */
    public ClientUser!: string;

    /** 適用区分 */
    public AppKbn!: number;

    /** マスタ区分 */
    public MasterKbn!: number;

    /** コード（外部） */
    public GCode!: string;

    /** 科目コード（外部） */
    public KmkCode!: number;

    /** 売上消費税率 */
    public Kbn1!: number;

    /** 仕入消費税率 */
    public Kbn2!: number;

    /** 業種コード */
    public Kbn3!: number;

    /**  */
    public Kbn4!: number;

    /**  */
    public Kbn5!: number;

    /**  */
    public Kbn6!: number;

    /**  */
    public Kbn7!: number;

    /**  */
    public Kbn8!: number;

    /**  */
    public Kbn9!: number;

    /**  */
    public Kbn10!: number;

    /**  */
    public Kbn11!: number;

    /**  */
    public Kbn12!: number;

    /**  */
    public Kbn13!: number;

    /**  */
    public Kbn14!: number;

    /**  */
    public Kbn15!: number;

    /**  */
    public Kbn16!: number;

    /**  */
    public Kbn17!: number;

    /**  */
    public Kbn18!: number;

    /**  */
    public Kbn19!: number;

    /**  */
    public Kbn20!: number;

    /**  */
    public Kbn21!: number;

    /** 売上消費税コード */
    public Kbn22!: number;

    /** 仕入消費税コード */
    public Kbn23!: number;

    /**  */
    public Kbn24!: number;

    /**  */
    public Kbn25!: number;

    /**  */
    public Kbn26!: number;

    /**  */
    public Kbn27!: number;

    /**  */
    public Kbn28!: number;

    /**  */
    public Kbn29!: number;

    /**  */
    public Kbn30!: number;

    /**  */
    public Kbn31!: number;

    /**  */
    public Kbn32!: number;

    /**  */
    public Kbn33!: number;

    /**  */
    public Kbn34!: number;

    /**  */
    public Kbn35!: number;

    /**  */
    public Kbn36!: number;

    /**  */
    public Kbn37!: number;

    /**  */
    public Kbn38!: number;

    /**  */
    public Kbn39!: number;

    /**  */
    public Kbn40!: number;

    /**  */
    public Free1!: string;

    /**  */
    public Free2!: string;

    /**  */
    public Free3!: string;

    /**  */
    public Date1!: Date;

    /**  */
    public Date2!: Date;

    /**  */
    public Date3!: Date;

    /** 更新日時 */
    public UpdateTime!: Date;

}
