/** タイムアウト時間 */
export const TIMEOUT = 180000;
/** 進捗取得間隔 */
export const PROGRESSINTERVAL = 1500;
/** コメント最大桁数 */
export const COMMENTMAXDIGITNUMBER = 200;
/** 内部月件数 */
export const NMONTHCOUNT = 61;
/** 主キー制約エラーNumber */
export const SQLEXCEPTIONPK = 2627;
/** 外部月20名称 */
export const MONTHNAME20 = "締後";
/** 外部月21名称 */
export const MONTHNAME21 = "第一1";
/** 外部月22名称 */
export const MONTHNAME22 = "第一2";
/** 外部月23名称 */
export const MONTHNAME23 = "第一3";
/** 外部月30名称 */
export const MONTHNAME30 = "締後";
/** 外部月31名称 */
export const MONTHNAME31 = "第三1";
/** 外部月32名称 */
export const MONTHNAME32 = "第三2";
/** 外部月33名称 */
export const MONTHNAME33 = "第三3";
/** 外部月80名称 */
export const MONTHNAME80 = "締後";
/** 外部月81名称 */
export const MONTHNAME81 = "中間1";
/** 外部月82名称 */
export const MONTHNAME82 = "中間2";
/** 外部月83名称 */
export const MONTHNAME83 = "中間3";
/** 外部月90名称 */
export const MONTHNAME90 = "締後";
/** 外部月91名称 */
export const MONTHNAME91 = "決算1";
/** 外部月92名称 */
export const MONTHNAME92 = "決算2";
/** 外部月93名称 */
export const MONTHNAME93 = "決算3";
/** 仕訳明細ルール設定一覧(Get2) */
export const AIKADT001000001E01 = "AIKADT001000001_E01";
/** チェックリスト設定一覧(Get2) */
export const AIKADT001000004E01 = "AIKADT001000004_E01";
/** 月次残高ルール設定(Get2) */
export const AIKADT001000003E01 = "AIKADT001000003_E01";
/** 月次残高ルール設定部門対応(Get2) */
export const AIKADT001000003E02 = "AIKADT001000003_E02";
/** チェックリスト(Get2) */
export const AIKADT002000005E01 = "AIKADT002000005_E01";
/** 監査実行・結果表示(Get2) */
export const AIKADT002000001E01 = "AIKADT002000001_E01";
/** 監査実行・結果表示(Get2) */
export const AIKADT002000001E02 = "AIKADT002000001_E02";
/** 監査実行・結果表示_部門対応(Get2) */
export const AIKADT002000001E11 = "AIKADT002000001_E11";
/** 監査実行・結果表示_部門対応(Get2) */
export const AIKADT002000001E12 = "AIKADT002000001_E12";
/** 監査結果[著増減](Get2) */
export const AIKADT002000007E01 = "AIKADT002000007_E01";
/** 監査結果[著増減]部門対応(Get2) */
export const AIKADT002000007E02 = "AIKADT002000007_E02";
/** 監査結果[マイナス残高](Get2) */
export const AIKADT002000008E01 = "AIKADT002000008_E01";
/** 監査結果[マイナス残高]部門対応(Get2) */
export const AIKADT002000008E02 = "AIKADT002000008_E02";
/** 監査結果[仕訳明細](Get2) */
export const AIKADT002000009E01 = "AIKADT002000009_E01";
/** 監査結果[仕訳重複](Get2) */
export const AIKADT002000010E01 = "AIKADT002000010_E01";
/** 監査結果[消費税区分](Get2) */
export const AIKADT002000011E01 = "AIKADT002000011_E01";
/** 監査結果[チェックリスト・特記事項](Get2) */
export const AIKADT002000012E01 = "AIKADT002000012_E01";
/** 監査結果一覧(Get2) */
export const AIKADT002000006E01 = "AIKADT002000006_E01";
/** 監査結果一覧(Get3) */
export const AIKADT002000006E02 = "AIKADT002000006_E02";
/** 監査結果一覧(Get2)部門対応 */
export const AIKADT002000006E11 = "AIKADT002000006_E11";
/** 仕訳明細(Get6) */
export const AIKADT002000004E01 = "AIKADT002000004_E01";
/** 仕訳明細(Get7) */
export const AIKADT002000004E02 = "AIKADT002000004_E02";
/** 仕訳明細(Get8) */
export const AIKADT002000004E03 = "AIKADT002000004_E03";
/** 仕訳明細(Get9) */
export const AIKADT002000004E04 = "AIKADT002000004_E04";
/** 仕訳明細(Get10) */
export const AIKADT002000004E05 = "AIKADT002000004_E05";
/** 仕訳明細(Get11) */
export const AIKADT002000004E06 = "AIKADT002000004_E06";
/** 仕訳明細(Get12) */
export const AIKADT002000004E07 = "AIKADT002000004_E07";
/** 仕訳明細(Get13) */
export const AIKADT002000004E08 = "AIKADT002000004_E08";
/** 仕訳明細(Get14) */
export const AIKADT002000004E09 = "AIKADT002000004_E09";
/** 仕訳明細(Get15) */
export const AIKADT002000004E10 = "AIKADT002000004_E10";
/** 監査ルールインポート */
export const AIKCMN001000005E01 = "AIKCMN001000005_E01";
/** 監査ルールインポート部門対応 */
export const AIKCMN001000005E02 = "AIKCMN001000005_E02";
/** 会社別担当者設定 */
export const AIKCMN006000001E01 = "AIKCMN006000001_E01";
/** システム履歴 */
export const AIKCMN007000001E01 = "AIKCMN007000001_E01";
/** 勘定科目登録一覧 */
export const AIKADT008000001E01 = "AIKADT008000001_E01";
/** 期首残高[部門] */
export const AIKADT006000001E01 = "AIKADT006000001_E01";
/** 期首残高[補助] */
export const AIKADT006000001E02 = "AIKADT006000001_E02";
/** 取引先補助区分 */
export const HOJYOCLIENTKBN = 22;
/** 最小日付 */
export const MINDATE = 19000101;
/** 最大日付 */
export const MAXDATE = 20991231;
/** かんたんクラウドAzureStorageKey情報 */
export const ACELINAZURESTORAGEKEY = "BatchStorageConnectionString";
/** パラメーター */
export const PARAMETA = "パラメーター";
/** NX-PRO Version 文字列の整合性確認用正規表現文字列 */
export const NXPROVERSIONMATCHINGPATTERN = "^(NXPro Ver)?([0-9]+\\.[0-9]+\\.[0-9]{4})$";
/** 仕訳明細ルール設定 */
export const JOURNALLINERULESETTING = "仕訳明細ルール設定";
/** 仕訳日記帳前表示数 */
export const JOURNALDAYBOOKBFDISPROWCOUNT = 3;
/** 仕訳日記帳後表示数 */
export const JOURNALDAYBOOKAFDISPROWCOUNT = 3;
/** 仕訳日記帳初回検索件数(前後) */
export const JOURNALDAYBOOKSEARCHROWCOUNT = 100;
/** しきい値最大値 */
export const CALCULATEMAX = 999.99;
/** しきい値最小値 */
export const CALCULATEMIN = -999.99;
/** グループ外項目の値 */
export const JOURNALLINERULENOTGROUP = "0-0";
/** 仕訳明細監査明細切り分け件数 */
export const EXECJOURNALLINERANGECOUNT = 1000;
/** コメント文字列 */
export const COMMENT = "コメント";
/** 選択年度 */
export const SELECTYEAR = "選択年度";
/** 仕訳重複監査条件メッセージ文字列 */
export const AUDITJOURNALDOUBLECRITERIA = "仕訳重複監査条件";
/** 仕訳明細監査ルールメッセージ文字列 */
export const AUDITJOURNALLINERULE = "仕訳明細監査ルール";
/** 年度名のフォーマット */
export const CLIENTYEARNAMEFORMAT = "yyyy年度";
/** 月次残高ルール */
export const MONTHLYBALANCE = "月次残高ルール";
/** アラートタイムアウト */
export const ALERTTIMEOUT = 3000;
/** 監査実行完了の待ち時間 */
export const AUDITCOMPLETEWAIT = 500;
/** 監査対象 */
export const AUDITTARGET = "▶";
/** 監査ルールインポート可能な拡張子 */
export const AUDITRULEIMPORTEXTENSION = "xlsx";
/** 監査ルールI/Oの月次残高ルール設定のシート名 */
export const AUDITRULEIOBALANCESHEETNAME = "月次残高ルール設定";
/** 監査ルールI/Oの月次残高ルール設定(部門)のシート名 */
export const AUDITRULEIOBALANCEBMNSHEETNAME = "月次残高ルール設定 (部門)";
/** 監査ルールI/Oの月次残高ルール設定(ルールパターン)のシート名 */
export const AUDITRULEIOBALANCEPATTERNNAMESHEETNAME = "月次残高ルール設定 (ルールパターン)";
/** 監査ルールI/Oの仕訳明細ルール設定のシート名 */
export const AUDITRULEIOJLRSHEETNAME = "仕訳明細ルール設定";
/** 監査ルールI/Oの仕訳明細ルール設定 (個別指定月)のシート名 */
export const AUDITRULEIOJLRMONTHSHEETNAME = "仕訳明細ルール設定 (個別指定月)";
/** 監査ルールI/Oの仕訳明細ルール設定 (条件)のシート名 */
export const AUDITRULEIOJLRCONDITIONSHEETNAME = "仕訳明細ルール設定 (条件)";
/** 監査ルールI/Oの仕訳明細ルール設定 (条件グループ)のシート名 */
export const AUDITRULEIOJLRGROUPSHEETNAME = "仕訳明細ルール設定 (条件グループ)";
/** 監査ルールI/Oの仕訳明細ルール設定 (システム有効)のシート名 */
export const AUDITRULEIOJLSYSTEMSHEETNAME = "仕訳明細ルール設定 (システム有効)";
/** 監査ルールI/Oの月次残高ルール設定シートの範囲 */
export const AUDITRULEIOBALANCESHEETRENGE = "A2:L2";
/** 監査ルールI/Oの月次残高ルール設定(部門)シートの範囲 */
export const AUDITRULEIOBALANCEBMNSHEETRENGE = "A2:L2";
/** 監査ルールI/Oの月次残高ルール設定(ルールパターン)シートの範囲 */
export const AUDITRULEIOBALANCEPATTERNSHEETRENGE = "A2:D2";
/** 監査ルールI/Oの仕訳明細ルール設定シートの範囲 */
export const AUDITRULEIOJLRSHEETRENGE = "A2:F2";
/** 監査ルールI/Oの仕訳明細ルール設定 (個別指定月)シートの範囲 */
export const AUDITRULEIOJLRMONTHSHEETRENGE = "A3:B3";
/** 監査ルールI/Oの仕訳明細ルール設定 (条件)シートの範囲 */
export const AUDITRULEIOJLRCONDITIONSHEETRENGE = "A2:H2";
/** 監査ルールI/Oの仕訳明細ルール設定 (条件グループ)シートの範囲 */
export const AUDITRULEIOJLRGROUPSHEETRENGE = "A2:D2";
/** 監査ルールI/Oの仕訳明細ルール設定 (システム有効)シートの範囲 */
export const AUDITRULEIOJLSYSTEMSHEETRENGE = "A2:B2";
/** ファイル名の正規表現 */
export const FILENAMEREGULAREXPRESSION = "[\\x00-\\x1f<>:\"/\\\\|?*]";
/** データ未送信文言 */
export const NOTSENTDATA = "（データ送信未実施）";
/** 著増減監査エクセル範囲 */
export const AUDITRESULTREMARKINCDECEXCELRENGE = "A3:Q3";
/** マイナス残高監査エクセル範囲 */
export const AUDITRESULTNEGATIVEBALANCEEXCELRENGE = "A3:K3";
/** 仕訳監査エクセル範囲 */
export const AUDITRESULTJOURNALLINEEXCELRENGE = "A3:BE3";
/** 仕訳監査（簡易）エクセル範囲 */
export const AUDITRESULTJOURNALLINESIMPLEEXCELRENGE = "A3:L3";
/** 仕訳監査（簡易）エクセル印刷範囲 */
export const AUDITRESULTJOURNALLINESIMPLEEXCELPRINTAREA = "A1:L";
/** 重複監査エクセル範囲 */
export const AUDITRESULTJOURNALDOUBLEEXCELRENGE = "A3:I3";
/** 消費税区分監査エクセル範囲 */
export const AUDITRESULTTAXKUBUNEXCELRENGE = "A3:K3";
/** 監査報告エクセル範囲（仕訳明細以外共通） */
export const OUTPUT_REPORT_COMMON_EXCELRENGE = "A7:I7";
/** 監査報告エクセル印刷範囲（仕訳明細以外共通） */
export const OUTPUT_REPORT_COMMON_EXCELPRINTAREA = "A7:I";
/** 監査報告出力 仕訳明細監査エクセル範囲 */
export const OUTPUT_REPORT_AUDITRESULTJOURNALLINEEXCELRENGE = "A7:K7";
/** 監査報告出力 仕訳監査エクセル範囲 印刷範囲 */
export const OUTPUT_REPORT_AUDITRESULTJOURNALLINEEXCELPRINTAREA = "A7:K";
/** ヘッダー情報機能名(仕訳明細) */
export const HEADER_INFO_FUNCTION_JOURNALLINE = "JOURNALLINE";
/** 月次残高ルール設定のエクスポートファイルバージョン */
export const AUDITRULEMONTHLYBALANCEEXCELVERSION = 1.09;
/** 仕訳明細機能ID */
export const AIKADT002000004 = "AIKADT002000004";
/** AI仕訳チェック機能ID */
export const AIKADT002000013 = "AIKADT002000013";
/** 監査対象制御_範囲指定不正 */
export const AUDITTARGETEXCEPTION_RANGEERR = 1;
/** 課税事業者(事業者区分=0) */
export const KAZEI = "課税事業者";
/** 免税事業者等(事業者区分=1) */
export const MENZEI = "免税事業者等";
