import React from 'react';
import classNames from 'classnames';

import loadingImg from '../../../../images/loading.gif';

export type LoadingProps = {
  activated: boolean;
};

const defaultClassName = 'vw-100 vh-100 position-fixed top-0 left-0 justify-content-center align-items-center loading';

const Loading: React.FC<LoadingProps> = props => {
  const className = props.activated == false ? 'd-none' : 'd-flex';
  return (
    <div className={classNames(className, defaultClassName)}>
      <img className='loading-img' src={loadingImg} alt='loading' />
    </div>
  );
};
export default Loading;
