/**
 * マスターデータ ViewModel クラス
 */
import { DataModelBase } from "../../core/models/dataModelBase";
export class TekiTreeCacheViewModel extends DataModelBase {
    /** マスタ区分 */
    public MasterKbn!: number;

    /** 摘要区分 */
    public TekiKbn!: number;

    /** 摘要分類コード
    実在科目コード
    伝票入力形式＋種別 */
    public SumCode!: number;

    /** 固定摘要コード
    摘要分類コード */
    public BasedCode!: number;

}

