import React from 'react';
import Axios from 'axios';
import { Label } from 'reactstrap';
import { ComboBox } from 'wijmo/wijmo.react.input';
import * as wjInput from 'wijmo/wijmo.input';

import * as fileUtils from '../../../utils/fileUtils';
import * as labels from '../../../constants/labels';
import * as Messages from '../../../constants/message';
import { PROGRESSINTERVAL } from '../../../constants/constant';
import { BatStatusEnum, AuditStatusEnum } from '../../../constants/enums';
import { OutputInformationViewModel } from '../../../models/outputInformationViewModel';
import { ExcelOutputByMonthViewModel } from '../../../models/excelOutputByMonthViewModel';
import Popup from '../../../components/molecules/CMN/Popup/Popup';
import Spinner from '../../../components/atoms/Icon/Spinner';
import ExecutingCancel from '../../../components/molecules/CMN/ExecutingCancel/ExecutingCancel';
import { HonorificEnum } from '../../../constants/dataMaps';
import { setIsLoading, getLodingStatus } from '../../../stores/NowLoading';

type ExcelOutputSettingByMonthProps = {
  activated: boolean;
  fiscalYear: number;
  auditTarget: number;
  shareIsLoading?: boolean;
  templateId: string;
  filters?: string;
  headerLeft?: string;
  headerCenter?: string;
  headerRight?: string;
  footerLeft?: string;
  footerCenter?: string;
  footerRight?: string;
  popupTitle?: string;
  onClose: () => void;
};

// Excel出力系URL
// Excel出力設定と共用できるURLです。
const excelOutputBaseUrl = '/api/v1/ExcelOutputPreference';
export const createUrl = [excelOutputBaseUrl, 'Create'].join('/');
export const fileResultUrl = [excelOutputBaseUrl, 'FileResult'].join('/');
export const fileUrl = [excelOutputBaseUrl, 'File'].join('/');

// Excel月選択設定出力向けコンポーネント初期化処理URL
const baseUrl = '/api/v1/ExcelOutputByMonth';
export const initialUrl = [baseUrl, 'Initial'].join('/');

// 項目ラベルのCSS Class
const labelClass = 'text-lbl';

// tabIndexの初期値
const baseTabIndex = 2000;

// 敬称の初期設定の判断基準
// 法人の場合
const corpIndexStr = HonorificEnum[1].value;
// 個人の場合
const parsonalIndexStr = HonorificEnum[2].value;

const initializationViewModel: ExcelOutputByMonthViewModel = {
  MonthSelects: []
};

const ExcelOutputSettingByMonth: React.FC<ExcelOutputSettingByMonthProps> = props => {
  // viewModel(月選択コンボボックスの元ネタ等)
  const [viewModel, setViewModel] = React.useState<ExcelOutputByMonthViewModel>(initializationViewModel);
  // Excel出力中ステータス管理
  const [isPrinting, setIsPrinting] = React.useState(false);
  // 開始対象月コンボボックス選択状態
  const [startMonthSelected, setStartMonthSelected] = React.useState(0);
  // 終了対象月コンボボックス選択状態
  const [endMonthSelected, setEndtMonthSelected] = React.useState(0);
  // 敬称コンボボックス初期選択状態
  const [honorificFirstSelected, setHonorificFirstSelected] = React.useState(0);
  // DOM要素をwindow.documntから取得するのは好ましくないので変数にインスタンスを保持
  // 開始対象月のコンボボックスを保持
  const [startMonthCmb, setStartMonthCmb] = React.useState<wjInput.ComboBox>();
  // 終了対象月のコンボボックスを保持
  const [endMonthCmb, setEndMonthCmb] = React.useState<wjInput.ComboBox>();
  // 敬称コンボボックスを保持
  const [honorificCmb, setHonorificCmb] = React.useState<wjInput.ComboBox>();
  // エラーメッセージ
  const [errorMessage, setErrorMessage] = React.useState('');
  /** 画面のステータスよりビューモデルを作成します。 */
  const createExcelViewModel = () => {
    const excelViewModel: OutputInformationViewModel = {
        TemplateId: props.templateId,
        HeaderLeft: props.headerLeft,
        HeaderCenter: props.headerCenter,
        HeaderRight: props.headerRight,
        FooterLeft: props.footerLeft,
        FooterCenter: props.footerCenter,
        FooterRight: props.footerRight,
        PassFlg: false,
        FilterFlg: false,
        ClientCodeFlg: true,
        ClientNameFlg: true,
        ServiceParams: ''
    };
    return excelViewModel;
  };

  /**
   * 初期データ取得 
   **/
  const getInitialData = (url: string) => {
    if (!props.shareIsLoading) {
      // 呼び出し元と「setIsLoading」を共有していない場合は「setIsLoading」をオフに設定
      setIsLoading(true);
    }
    
    Axios.post<ExcelOutputByMonthViewModel>(url).then(response => {
      // 初期表示用ViewModelを設定
      setViewModel(response.data);
      if (!props.shareIsLoading && getLodingStatus()) {
        // 呼び出し元と「setIsLoading」を共有せず、ロード中の場合は「setIsLoading」をオフに設定
        setIsLoading(false);
      }
      // 開始月は決済開始月が初期選択なので先頭が初期選択値となる
      setStartMonthSelected(response.data.MonthSelects[0].NMonth!);

      // 終了月の初期選択値は監査状況を判断して初期選択を決定する
      var endMonthFirstIndex = 0;
      //会計期間全体で 監査実行済みかどうかをチェックする
      var auditedStatus = false;
      for (var i = 0; i < response.data.MonthSelects.length; i++) {
        if (
          response.data.MonthSelects[i].ConfStatus != AuditStatusEnum.Auditable
          && response.data.MonthSelects[i].ConfStatus != AuditStatusEnum.EmptyData
        ) {
          // 監査実行済みの場合は
          auditedStatus = true;
          // 実行済みの月情報を保存する
          endMonthFirstIndex = response.data.MonthSelects[i].NMonth!;
        }
      }
      if (auditedStatus) {
        // 監査実行ありの場合は監査実行済み月の最大を初期選択とする
        setEndtMonthSelected(endMonthFirstIndex);
      } else {
        // 監査実行なしの場合は連携されたデータの最大月を初期選択とする
        setEndtMonthSelected(response.data.MonthSelects[response.data.MonthSelects.length - 1].NMonth!);
      }

      // 敬称コンボボックス初期選択設定
      if (response.data.CorpKbn == 1) {
        // 法人区分の場合は「御中」を初期設定
        var corpIndex = HonorificEnum.find(e => e.value == corpIndexStr);
        if (corpIndex) {
          setHonorificFirstSelected(corpIndex.key);
        }
      } else {
        // 個人の場合は「様」を初期設定
        var parsonalIndex = HonorificEnum.find(e => e.value == parsonalIndexStr);
        if (parsonalIndex) {
          setHonorificFirstSelected(parsonalIndex.key);
        }
      }
    }).catch(error => {
        console.log(error);
    });
  };

  /**
   * 表示項目の初期化
   */
  const handleReset = () => {
    setIsPrinting(false);
    setErrorMessage('');
  };

  /** 出力ボタン押下時の処理 */
  const handleOnPrintClick = () => {
    setErrorMessage('');
    const excelViewModel = createExcelViewModel();
    // 出力に使用する敬称設定
    var honorificString = "";
    if (honorificCmb!.selectedItem.key != 0) {
      honorificString = honorificCmb!.selectedItem.value;
    }

    var stNMonth = startMonthCmb!.selectedItem.NMonth;
    var edNMonth = endMonthCmb!.selectedItem.NMonth;
    if (stNMonth > edNMonth) {
      // 開始月が終了月の選択が大きい場合、右下にアラート出す。
      setErrorMessage(Messages.ExcelOutputSettingByMonth_Error_Month_InvalidSetting);
      return;
    }
    // 開始対象月、終了対象月、敬称をExcelVoの追加パラメータとして設定する。
    const serviceParams = JSON.stringify({
      stNMonth: stNMonth,        // 開始対象月
      edNMonth: edNMonth,        // 終了対象月
      honorific: honorificString, // 敬称
      AuditTarget: props.auditTarget   //監査対象
    });
    excelViewModel.ServiceParams = serviceParams;
    createOnPost(createUrl, excelViewModel);
    setIsPrinting(true);
  };

  /** Excel帳票作成処理を実行 */
  const createOnPost = (url: string, viewModel: OutputInformationViewModel) => {
    Axios.post(url, { outputInformationViewModel: viewModel })
      .then(response => {
        fileResultOnPost(fileResultUrl, String(response.data));
      })
      .catch(error => {
        setIsPrinting(false);
        setErrorMessage(error.response.data.message);
      });
  };

  /** バッチの進捗確認を実行 */
  const fileResultOnPost = (url: string, guid: string) => {
    const encodedUrl = [url, encodeURI(guid.toString())].join('/');
    Axios.post(encodedUrl)
      .then(response => {
        if (BatStatusEnum.Processing == response.data) {
          window.setTimeout(fileResultOnPost, PROGRESSINTERVAL, url, guid);
        } else {
          fileOnPost(fileUrl, guid);
        }
      })
      .catch(error => {
        setIsPrinting(false);
        setErrorMessage(error.response.data.message);
      });
  };

  /** ファイルダウンロードを実行 */
  const fileOnPost = (url: string, guid: string) => {
    const encodedUrl = [url, encodeURI(guid.toString())].join('/');
    Axios.get(encodedUrl, { responseType: 'blob' })
      .then(response => {
        if (response.data != undefined) {
          fileUtils.downloadFileFromResponse(response);
        }
        // 印刷向けロック並びに「出力中」文字列をクリア
        setIsPrinting(false);
        setErrorMessage("");
        // ポップアップを閉じる
        props.onClose();
      })
      .catch(error => {
        setIsPrinting(false);
        setErrorMessage(error.response.data.message);
      });
  };

  let firstFocusElement: HTMLElement;
  const lastFocusElement = React.useRef<any>();
  const popupRef = React.useRef<any>();

  /** キーボード操作（キーダウン） */
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 9) {
      if (event.shiftKey === false) {
        // タブキー押下時
        const target: HTMLElement = event.target as HTMLElement;
        if (target.tabIndex === lastFocusElement.current.tabIndex) {
          // 最後のフォーカスエレメントの場合
          // 最初のエレメントにフォーカス
          firstFocusElement.focus();
          
          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        } else if (
          target.tabIndex < firstFocusElement.tabIndex ||
          target.tabIndex > lastFocusElement.current.tabIndex
        ) {
          // フォーカスが当たっていない場合
          // 最初のエレメントにフォーカス
          firstFocusElement.focus();
          
          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
      } else {
        // シフト＋タブキー押下時
        const target: HTMLElement = event.target as HTMLElement;
        if (target.tabIndex === firstFocusElement.tabIndex) {
          // 最後のフォーカスエレメントの場合
          // 最後のエレメントにフォーカス
          lastFocusElement.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        } else if (
          target.tabIndex < firstFocusElement.tabIndex ||
          target.tabIndex > lastFocusElement.current.tabIndex
        ) {
          // フォーカスが当たっていない場合
          // 最後のエレメントにフォーカス
          lastFocusElement.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }
  };

  /** 画面が表示された実行する処理(月選択コンボボックス内容更新) */
  React.useEffect(() => {
    // 初期データ取得処理起動
    getInitialData(initialUrl);
  }, [initialUrl, props.fiscalYear]);

  /** 画面が表示された実行する処理(ポップアップ表示時のtabキーボタン設定) */
  React.useEffect(() => {
    if (props.activated) {
      // 表示項目のクリア
      handleReset();
      // 画面表示完了後に実行
      setTimeout(() => {
        if (popupRef.current) {
          // ポップアップにキーダウンイベントのフック
          popupRef.current.addEventListener('keydown', handleKeyDown);
        }
      });
    }
  }, [props.activated]);

  /** 閉じるボタン押下時の処理 */
  const handleOnCloseClick = () => {
    if (!isPrinting) {
      props.onClose();
    }
  };
  // Escキー押下時の終了処理
  const getEscClose = () => {
    return !isPrinting;
  };
  // Excel出力中（印刷中）のラベル
  const printingLabel = isPrinting ? (
    <span>
      <span className='_spinner _rotation mr-2'>
        <Spinner className='icon-lg' />
      </span>
      {labels.AIKCMN001000002_FUNCTION_NAME_INOUTPUT}
    </span>
  ) : (
    <span className='text-danger'>{errorMessage}</span>
  );
  // コンボボックス系のInitialized
  // wijmo系コンポーネントに対して直接「tabIndex」を指定できないのでwijmoのInitializedイベント時に設定するようにする
  const stratMonthCmbInitialized = (comboBox: wjInput.ComboBox) => {
    comboBox.inputElement.tabIndex = baseTabIndex + 1;
    // wijmo系はReactの「useRef」等を取得できないのでその代わりに入力コンポーネント自体の要素を取得する
    firstFocusElement = comboBox.inputElement;
    setStartMonthCmb(comboBox); 
  }; 

  const endMonthCmbInitialized = (comboBox: wjInput.ComboBox) => {
     comboBox.inputElement.tabIndex = baseTabIndex + 2;
    setEndMonthCmb(comboBox);
  };

  const honorificCmbInitialized = (comboBox: wjInput.ComboBox) => {
    comboBox.inputElement.tabIndex = baseTabIndex + 3;
    setHonorificCmb(comboBox);
  };

  return (
    <Popup
      isOpen={props.activated}
      escClose={getEscClose()}
      onCloseClick={handleOnCloseClick}
      header={props.popupTitle}
      size='sm'
      closeIconTabIndex={baseTabIndex + 6}
      closeIconRef={lastFocusElement}
      innerRef={popupRef}>
      <Label className={labelClass}>
        <b>{'出力月'}</b>
      </Label>
        <div className='d-flex flex-row mb-3'>
          <div className='w-40' >
            <ComboBox
              id={'startMonth'}
              itemsSource={viewModel.MonthSelects}
              displayMemberPath={'MonthName'}
              selectedValuePath={'NMonth'}
              selectedValue={startMonthSelected}
              initialized={stratMonthCmbInitialized}
            />
          </div>
          <div className='w-20 from-to-str'><p>～</p></div>
          <div className='w-40'>
            <ComboBox
              id={'endMonth'}
              itemsSource={viewModel.MonthSelects}
              displayMemberPath={'MonthName'}
              selectedValuePath={'NMonth'}
              selectedValue={endMonthSelected}
              initialized={endMonthCmbInitialized}
            />
          </div>
        </div>
      <Label className={labelClass}>
        <b>{'敬称'}</b>
      </Label>
      <div className='d-flex flex-row mb-3'>
        <div className='w-40'>
           <ComboBox
             id={'honorific'}
             itemsSource={HonorificEnum}
             displayMemberPath={'value'}
             selectedValuePath={'key'}
             selectedValue={honorificFirstSelected}
             initialized={honorificCmbInitialized}/>
        </div>
      </div>
      <div className='d-flex justify-content-between'>
        <div>{printingLabel}</div>
        <div>
          <ExecutingCancel
            className='text-nowrap'
            executeLabel={labels.AIKCMN001000002_BUTTON_FUNCTION_OUTPUT}
            executeDisabled={isPrinting}
            executeTabIndex={baseTabIndex + 4}
            onExecuteClick={handleOnPrintClick}
            cancelLabel={labels.COMMON_BUTTON_FUNCTION_CLOSE}
            cancelDisabled={isPrinting}
            cancelTabIndex={baseTabIndex + 5}
            onCancelClick={handleOnCloseClick}
          />
        </div>
      </div>
    </Popup>
  );
};
export default ExcelOutputSettingByMonth;
