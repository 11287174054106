import { MasterDataContainer } from "../../../dataAccess/masterDataContainer";
import { HojyoMACacheViewModel } from "../hojyoMACacheViewModel";
import "../../../common/usefuls/utilities";

export class HojyoMA_CacheContainerBase extends MasterDataContainer<HojyoMACacheViewModel> {
    constructor(models: Array<HojyoMACacheViewModel>, dataYear: number, version: Date) {
        super(models, dataYear, version);
    }

    /**
     * 外部コードをキーに特定のモデルを取得する
     * @param gcode
     */
    public getByGCode(gcode: number): HojyoMACacheViewModel {
        return this.models.singleOrNull((m: { GCode: number; }) => m.GCode == gcode);
    }

    /**
     * 内部コードをキーに特定のモデルを取得する
     * @param ncode
     */
    public getByNCode(ncode: number): HojyoMACacheViewModel {
        return this.models.singleOrNull((m: { NCode: number; }) => m.NCode == ncode);
    }

    /**
     * 実在科目のモデル一覧を取得する
     */
    public get realKamokuModels() {
        return this.models.filter(m => m.isRealKmk);
    }

    /**
     * 合計科目のモデル一覧を取得する
     */
    public get totalKamokuModels() {
        return this.models.filter(m => m.isTotalKmk);
    }
}
