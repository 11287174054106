import React, { RefObject, useEffect } from 'react';
import * as wjGrid from 'wijmo/wijmo.react.grid';
import { MigrationSettingSaveStatus, UchizeiAutoCalcKbn } from '../../../../../VKZ/core/constants/constant';
import { TaxKbn } from '../../../../../VKZ/core/constants/constant';
import { MigrationErrStatus } from '../../../../../VKZ/core/constants/constant'

type _ProcessMigrationStepItemSettingPartialProps = {
  scope?: any, 
  kisyuGridControlRef: RefObject<any>,
  hojyoKisyuGridControlRef: RefObject<any>,
  bmnKisyuGridControlRef: RefObject<any>,
  swkGridControlRef: RefObject<any>
};

const _ProcessMigrationStepItemSettingPartial: React.FC<_ProcessMigrationStepItemSettingPartialProps> = props => {

    useEffect(() => {
    }, []);
        
   return (
    <>
<section id="stepItemSetting" className="dp_none">
    <span className="step-number" >{props.scope && props.scope.stepItemSettingDispNo}</span>
    <div className="step-title">インポートする項目の設定を行ってください。</div>
    <div id="stepItemSetting-content" className="step-content">
        <div className="step-description mb10">
            ・AI監査支援にインポートする項目を設定します。インポートしたファイルの項目を、AI監査支援のどの項目としてインポートするか、各タブのヘッダー部分をクリックして設定してください。<br />
            &ensp;項目設定の画面では、インポートしたファイルの内容を30行目まで表示しています。<br />
            <span className="red-icon">
                &ensp;選択項目が赤字で「＊」がついている項目は、選択が必須の項目になります。選択していない場合は、インポートすることができません。<br />
                &ensp;免税事業者は消費税の設定項目は選択できません。
            </span>
        </div>

        {/* 項目の設定 */}
        <div id="stepItemSetting-ItemSetting">
            <label className="step-description-label">項目の設定</label>
            <div hidden={!(props.scope && props.scope.itemSettingSaveStatus == MigrationSettingSaveStatus.Changed)} className="migration-blue"><span className="fa fa-info-circle ml5 mr5"></span>項目の設定が変更されています。設定を保存する場合は、「設定を保存する」ボタンをクリックして設定内容を保存してください。</div>
            <div className="migration-nav mt5" style={{borderBottom: "4px solid #337AB7"}}>
                <ul className="nav nav-tabs" role="tablist">
                    <li id="li-kisyu-tab" className="nav-item" hidden={props.scope && !props.scope.kisyuItemSetting}>
                        <a className="nav-link" style={{fontWeight: "bold",}} id="kisyu-tab" data-toggle="tab" href="#kisyu-tab-pane" role="tab" aria-controls="kisyu" aria-selected="true" onClick={() => props.scope.itemSettingTabClick(0)}>期首残高<span hidden={props.scope && !(props.scope.itemSettingKisyuErrCnt != 0)}>（<span className="tab-errcnt">未設定：{props.scope && props.scope.itemSettingKisyuErrCnt}件</span>）</span></a>
                    </li>
                    <li id="li-hojyokisyu-tab" className="nav-item" hidden={props.scope && !props.scope.hojyoKisyuItemSetting}>
                        <a className="nav-link" style={{fontWeight: "bold",}} id="hojyokisyu-tab" data-toggle="tab" href="#hojyokisyu-tab-pane" role="tab" aria-controls="hojyokisyu" aria-selected="true" onClick={() => props.scope.itemSettingTabClick(1)}>補助期首残高<span hidden={props.scope && !(props.scope.itemSettingHojyoKisyuErrCnt != 0)}>（<span className="tab-errcnt">未設定：{props.scope && props.scope.itemSettingHojyoKisyuErrCnt}件</span>）</span></a>
                    </li>
                    <li id="li-bmnkisyu-tab" className="nav-item" hidden={props.scope && !props.scope.bmnKisyuItemSetting}>
                        <a className="nav-link" style={{fontWeight: "bold",}} id="bmnkisyu-tab" data-toggle="tab" href="#bmnkisyu-tab-pane" role="tab" aria-controls="bmnkisyu" aria-selected="true" onClick={() => props.scope.itemSettingTabClick(2)}>部門期首残高<span hidden={props.scope && !(props.scope.itemSettingBmnKisyuErrCnt != 0)}>（<span className="tab-errcnt">未設定：{props.scope && props.scope.itemSettingBmnKisyuErrCnt}件</span>）</span></a>
                    </li>
                    <li id="li-swk-tab" className="nav-item" hidden={props.scope && !props.scope.swkItemSetting}>
                        <a className="nav-link" style={{fontWeight: "bold",}} id="swk-tab" data-toggle="tab" href="#swk-tab-pane" role="tab" aria-controls="swk" aria-selected="true" onClick={() => props.scope.itemSettingTabClick(3)}>仕訳<span hidden={props.scope && !(props.scope.itemSettingSwkErrCnt != 0)}>（<span className="tab-errcnt">未設定：{props.scope && props.scope.itemSettingSwkErrCnt}件</span>）</span></a>
                    </li>
                </ul>
            </div>
            <div className="tab-content">
                <div className="tab-pane fade" id="kisyu-tab-pane" role="tabpanel" aria-labelledby="kisyu-tab" hidden={props.scope && !props.scope.kisyuItemSetting}>
                    <div className="mt10">
                        <span style={{fontWeight: "bold",}}>インポート開始行</span>
                        <p className="fa fa-question-circle-o ml-1" data-toggle="tooltip" data-html="true" data-placement="right" title="インポート開始行を設定します。指定したファイルにヘッダー項目がある場合に変更してください。"></p>
                    </div>
                    <div>
                        <span id="kisyu-import-row" className="easy-input hankaku number-input"></span>
                        <span>行目からインポート</span>
                    </div>
                    <div className="mt5 red-icon" hidden={!(props.scope && props.scope.kisyuRequiredItem != undefined)} >
                        <span className="fa fa-exclamation-circle ml5 mr5"></span>項目の選択が不足しています。{props.scope && props.scope.kisyuRequiredItem}を選択してください。
                    </div>
                    <div id="kisyu-grid-area" className="mt10 no-scrollbar-x" style={{ border: "1px solid #bbbbbb", maxWidth: "calc(100vw - 100px)", maxHeight: "400px", minHeight: "100px", overflowX: "auto", overflowY: "hidden", fontSize: "0",}}>
                        <div style= {{display: "inline-block",}}>
                            <div className="mt5 mb5">
                                {props.scope && props.scope.kisyuGridColumns && props.scope.kisyuGridColumns.items && props.scope.kisyuGridColumns.items.map((col: any, $index: number) => {
                                    return (
                                <span >
                                    <span id={`kisyuCmbColum${$index+1}`} className="easy-input"></span>
                                </span>
                                );
                            })}
                            </div>
                            <wjGrid.FlexGrid id="kisyuGrid"
                                          ref={props.kisyuGridControlRef}
                                          headersVisibility="None"
                                          itemsSource={props.scope && props.scope.kisyuGridControlitemsSource}
                                          itemFormatter={props.scope && props.scope.kisyuGridControlitemFormatter}
                                          selectionMode="None"
                                          style={{minHeight: "270px", maxHeight: "270px", width: "auto", verticalAlign:"bottom", fontSize: "14px"}}>
                                {props.scope && props.scope.kisyuGridColumns && props.scope.kisyuGridColumns.items && props.scope.kisyuGridColumns.items.map((col: any, $index: number) => {
                                return (
                                <wjGrid.FlexGridColumn binding={col} align="center" isReadOnly={true} width={154}></wjGrid.FlexGridColumn>
                                );
                                })}
                            </wjGrid.FlexGrid>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="hojyokisyu-tab-pane" role="tabpanel" aria-labelledby="hojyokisyu-tab" hidden={props.scope && !props.scope.hojyoKisyuItemSetting}>
                    <div className="mt10">
                        <span style={{fontWeight: "bold",}}>インポート開始行</span>
                        <p className="fa fa-question-circle-o ml-1" data-toggle="tooltip" data-html="true" data-placement="right" title="インポート開始行を設定します。指定したファイルにヘッダー項目がある場合に変更してください。"></p>
                    </div>
                    <div>
                        <span id="hojyokisyu-import-row" className="easy-input hankaku number-input"></span>
                        <span>行目からインポート</span>
                    </div>
                    <div className="mt5 red-icon" hidden={!(props.scope && props.scope.hojyoKisyuRequiredItem != undefined)} >
                        <span className="fa fa-exclamation-circle ml5 mr5"></span>項目の選択が不足しています。{props.scope && props.scope.hojyoKisyuRequiredItem}を選択してください。
                    </div>
                    <div id="hojyokisyu-grid-area" className="mt10 no-scrollbar-x" style={{ border: "1px solid #bbbbbb", maxWidth: "calc(100vw - 100px)", maxHeight: "400px", minHeight: "100px", overflowX: "auto", overflowY: "hidden", fontSize: "0",}}>
                        <div style= {{display: "inline-block",}}>
                            <div className="mt5 mb5">
                                {props.scope && props.scope.hojyoKisyuGridColumns && props.scope.hojyoKisyuGridColumns.items && props.scope.hojyoKisyuGridColumns.items.map((col: any, $index: number) => {
                                    return (
                                <span >
                                    <span id={`hojyoKisyuCmbColum${$index+1}`} className="easy-input"></span>
                                </span>
                                );
                            })}
                            </div>
                            <wjGrid.FlexGrid id="hojyoKisyuGrid"
                                          ref={props.hojyoKisyuGridControlRef}
                                          headersVisibility="None"
                                          itemsSource={props.scope && props.scope.hojyoKisyuGridControlitemsSource}
                                          itemFormatter={props.scope && props.scope.hojyoKisyuGridControlitemFormatter}
                                          selectionMode="None"
                                          style={{minHeight: "270px", maxHeight: "270px", width: "auto", verticalAlign:"bottom", fontSize: "14px"}}>
                                {props.scope && props.scope.hojyoKisyuGridColumns && props.scope.hojyoKisyuGridColumns.items && props.scope.hojyoKisyuGridColumns.items.map((col: any, $index: number) => {
                                return (
                                <wjGrid.FlexGridColumn binding={col} align="center" isReadOnly={true} width={154}></wjGrid.FlexGridColumn>
                                );
                                })}
                            </wjGrid.FlexGrid>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="bmnkisyu-tab-pane" role="tabpanel" aria-labelledby="bmnkisyu-tab" hidden={props.scope && !props.scope.bmnKisyuItemSetting}>
                    <div className="mt10">
                        <span style={{fontWeight: "bold",}}>インポート開始行</span>
                        <p className="fa fa-question-circle-o ml-1" data-toggle="tooltip" data-html="true" data-placement="right" title="インポート開始行を設定します。指定したファイルにヘッダー項目がある場合に変更してください。"></p>
                    </div>
                    <div>
                        <span id="bmnkisyu-import-row" className="easy-input hankaku number-input"></span>
                        <span>行目からインポート</span>
                    </div>
                    <div className="mt5 red-icon" hidden={!(props.scope && props.scope.bmnKisyuRequiredItem != undefined)} >
                        <span className="fa fa-exclamation-circle ml5 mr5"></span>項目の選択が不足しています。{props.scope && props.scope.bmnKisyuRequiredItem}を選択してください。
                    </div>
                    <div id="bmnkisyu-grid-area" className="mt10 no-scrollbar-x" style={{ border: "1px solid #bbbbbb", maxWidth: "calc(100vw - 100px)", maxHeight: "400px", minHeight: "100px", overflowX: "auto", overflowY: "hidden", fontSize: "0",}}>
                        <div style= {{display: "inline-block",}}>
                            <div className="mt5 mb5">
                                {props.scope && props.scope.bmnKisyuGridColumns && props.scope.bmnKisyuGridColumns.items && props.scope.bmnKisyuGridColumns.items.map((col: any, $index: number) => {
                                    return (
                                <span >
                                    <span id={`bmnKisyuCmbColum${$index+1}`} className="easy-input"></span>
                                </span>
                                );
                            })}
                            </div>
                            <wjGrid.FlexGrid id="bmnKisyuGrid"
                                          ref={props.bmnKisyuGridControlRef}
                                          headersVisibility="None"
                                          itemsSource={props.scope && props.scope.bmnKisyuGridControlitemsSource}
                                          itemFormatter={props.scope && props.scope.bmnKisyuGridControlitemFormatter}
                                          selectionMode="None"
                                          style={{minHeight: "270px", maxHeight: "270px", width: "auto", verticalAlign:"bottom", fontSize: "14px"}}>
                                {props.scope && props.scope.bmnKisyuGridColumns && props.scope.bmnKisyuGridColumns.items && props.scope.bmnKisyuGridColumns.items.map((col: any, $index: number) => {
                                return (
                                <wjGrid.FlexGridColumn binding={col} align="center" isReadOnly={true} width={154}></wjGrid.FlexGridColumn>
                                );
                                })}
                            </wjGrid.FlexGrid>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="swk-tab-pane" role="tabpanel" aria-labelledby="swk-tab" hidden={props.scope && !props.scope.swkItemSetting}>
                    <div className="mt10">
                        <span style={{fontWeight: "bold",}}>インポート開始行</span>
                        <p className="fa fa-question-circle-o ml-1" data-toggle="tooltip" data-html="true" data-placement="right" title="インポート開始行を設定します。指定したファイルにヘッダー項目がある場合に変更してください。"></p>
                    </div>
                    <div>
                        <span id="swk-import-row" className="easy-input hankaku number-input"></span>
                        <span>行目からインポート</span>
                    </div>
                    <div className="mt10">
                        <span style={{fontWeight: "bold",}}>金額の設定</span>
                        <p className="fa fa-question-circle-o ml-1" data-toggle="tooltip" data-html="true" data-placement="right" title="金額の設定を行います。<br />「金額」に設定した列の金額に税額が含まれている場合は「税込みでインポート」、含まれていない場合は「税抜きでインポート」を選択してください。"></p>
                    </div>
                    <div>
                        <input type="radio" id="radio-taxIncluded" name="taxKbn" value={TaxKbn.TaxIncluded} onClick={() => props.scope && props.scope.taxKbnBtnClick(TaxKbn.TaxIncluded)} style={{margin: "-3px 3px 0px 3px",}} /><label htmlFor="radio-taxIncluded" style={{fontWeight: "normal",}}>税込みでインポート</label>
                        <input type="radio" id="radio-taxExcluded" name="taxKbn" value={TaxKbn.TaxExcluded} onClick={() => props.scope && props.scope.taxKbnBtnClick(TaxKbn.TaxExcluded)} style={{margin: "-3px 3px 0px 3px",}} className="ml-2"/><label htmlFor="radio-taxExcluded" style={{fontWeight: "normal",}}>税抜きでインポート</label>
                    </div>
                    <div className="mt10">
                        <span style={{fontWeight: "bold",}}>内税消費税額自動計算区分</span>
                        <p className="fa fa-question-circle-o ml-1" data-toggle="tooltip" data-html="true" data-placement="right" title="内税消費税額自動計算区分の設定を行ないます。<br />内税消費税額を自動計算する場合は「計算する」、消費税額をそのまま取込む場合は「計算しない」を選択してください。<br />※「計算する」とした場合、消費税額は切り捨て処理されます。"></p>
                    </div>
                    <div>
                        <input type="radio" id="radio-uchizeiAutoCalcKbnOn" name="uchizeiAutoCalcKbn" value={UchizeiAutoCalcKbn.Auto} onClick={() => props.scope && props.scope.uchizeiAutoCalcKbnBtnClick(UchizeiAutoCalcKbn.Auto)} style={{margin: "-3px 3px 0px 3px",}} /><label htmlFor="radio-uchizeiAutoCalcKbnOn" style={{fontWeight: "normal",}}>計算する</label>
                        <input type="radio" id="radio-uchizeiAutoCalcKbnOff" name="uchizeiAutoCalcKbn" value={UchizeiAutoCalcKbn.Manual} onClick={() => props.scope && props.scope.uchizeiAutoCalcKbnBtnClick(UchizeiAutoCalcKbn.Manual)} style={{margin: "-3px 3px 0px 3px",}} className="ml-2"/><label htmlFor="radio-uchizeiAutoCalcKbnOff" style={{fontWeight: "normal",}}>計算しない</label>
                    </div>
                    <div className="mt5 red-icon" hidden={!(props.scope && props.scope.swkRequiredItem != undefined)}>
                        <span className="fa fa-exclamation-circle ml5 mr5"></span>項目の選択が不足しています。{props.scope && props.scope.swkRequiredItem}を選択してください。
                    </div>
                    <div id="swk-grid-area" className="mt10 no-scrollbar-x" style={{ border: "1px solid #bbbbbb", maxWidth: "calc(100vw - 100px)", maxHeight: "400px", minHeight: "100px", overflowX: "auto", overflowY: "hidden", fontSize: "0",}}>
                        <div style= {{display: "inline-block",}}>
                            <div className="mt5 mb5">
                                {props.scope && props.scope.swkGridColumns && props.scope.swkGridColumns.items && props.scope.swkGridColumns.items.map((col: any, $index: number) => {
                                    return (
                                <span >
                                    <span id={`swkCmbColum${$index+1}`} className="swkCmbColum easy-input"></span>
                                </span>
                                );
                                })}
                            </div>
                            <wjGrid.FlexGrid id="swkGrid"
                                          ref={props.swkGridControlRef}
                                          headersVisibility="None"
                                          itemsSource={props.scope && props.scope.swkGridControlitemsSource}
                                          itemFormatter={props.scope && props.scope.swkGridControlitemFormatter}
                                          selectionMode="None"
                                          style={{minHeight:"270px", maxHeight:"270px", width: "auto", verticalAlign:"bottom", fontSize: "14px"}}>
                                {props.scope && props.scope.swkGridColumns && props.scope.swkGridColumns.items && props.scope.swkGridColumns.items.map((col: any, i: number) => {
                                    return (
                                <wjGrid.FlexGridColumn  binding={col} align="center" isReadOnly={true} width={154}></wjGrid.FlexGridColumn>
                                );
                                })}
                            </wjGrid.FlexGrid>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pb20 mb10">
                <button id="itemSetting-save-btn" type="button" className="btn btn-primary mt10 pr10 pl10 wd190">設定を保存する</button><br />
                <div hidden={!(props.scope && props.scope.itemSettingSaveStatus == MigrationSettingSaveStatus.Saved)} className="btn-success-msg"><span className="fa fa-check-circle text-success btn-success-icon pl5 pr5"></span>設定を保存しました。</div>
            </div>
        </div>

        {/* 項目設定エラーログ */}
        <div id="stepItemSetting-ErrLog" hidden={!(props.scope && (props.scope.kisyuItemSettingErrLogList || props.scope.hojyoKisyuItemSettingErrLogList || props.scope.bmnKisyuItemSettingErrLogList || props.scope.swkItemSettingErrLogList))}>
            <label className="step-description-label">項目設定エラーログ</label>
            <div className="migration-nav mt5" style={{borderBottom: "4px solid #337AB7"}}>
                <ul className="nav nav-tabs" role="tablist">
                    <li id="li-kisyu-err-tab" className="nav-item" hidden={props.scope && !props.scope.kisyuItemSettingErrLogList}>
                        <a className="nav-link" style={{fontWeight: "bold",}} id="kisyu-err-tab" data-toggle="tab" href="#kisyu-err-tab-pane" role="tab" aria-controls="kisyu" aria-selected="true" onClick={() => props.scope.itemSettingErrLogTabClick(0)}>期首残高</a>
                    </li>
                    <li id="li-hojyokisyu-err-tab" className="nav-item" hidden={props.scope && !props.scope.hojyoKisyuItemSettingErrLogList}>
                        <a className="nav-link" style={{fontWeight: "bold",}} id="hojyokisyu-err-tab" data-toggle="tab" href="#hojyokisyu-err-tab-pane" role="tab" aria-controls="hojyokisyu" aria-selected="true" onClick={() => props.scope.itemSettingErrLogTabClick(1)}>補助期首残高</a>
                    </li>
                    <li id="li-bmnkisyu-err-tab" className="nav-item" hidden={props.scope && !props.scope.bmnKisyuItemSettingErrLogList}>
                        <a className="nav-link" style={{fontWeight: "bold",}} id="bmnkisyu-err-tab" data-toggle="tab" href="#bmnkisyu-err-tab-pane" role="tab" aria-controls="bmnkisyu" aria-selected="true" onClick={() => props.scope.itemSettingErrLogTabClick(2)}>部門期首残高</a>
                    </li>
                    <li id="li-swk-err-tab" className="nav-item" hidden={props.scope && !props.scope.swkItemSettingErrLogList}>
                        <a className="nav-link" style={{fontWeight: "bold",}} id="swk-err-tab" data-toggle="tab" href="#swk-err-tab-pane" role="tab" aria-controls="swk" aria-selected="true" onClick={() => props.scope.itemSettingErrLogTabClick(3)}>仕訳</a>
                    </li>
                </ul>
            </div>
            <div className="tab-content">
                <div className="tab-pane fade" id="kisyu-err-tab-pane" role="tabpanel" aria-labelledby="kisyu-err-tab" hidden={props.scope && !props.scope.kisyuItemSettingErrLogList}>
                    <div className="errLog">
                        <div id="kisyuItemSettingErrLogArea">
                            {props.scope && props.scope.kisyuItemSettingErrLogList && props.scope.kisyuItemSettingErrLogList.map((errlog: any) => {
                                return (
                            <div className="dp_flex">
                                <div className="wd20">
                                    <span className={(errlog.Status == MigrationErrStatus.Error) ? 'fa fa-exclamation-circle fa-fw red-icon' : ''}></span>
                                    <span className={(errlog.Status == MigrationErrStatus.Warning) ? 'migration-warning fa fa-warning fa-fw' : 'migration-warning'}></span>
                                </div>
                                <div className="wd100">{errlog.RecNo}</div>
                                <div>{errlog.Msg}</div>
                            </div>
                            );
                            })}
                            <div hidden={props.scope && !props.scope.isKisyuItemSettingErrLogCntOver} className="pl20">エラーログが1000件を超えたため、これ以上は表示できません。エラーログをダウンロードしてご確認ください。</div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="hojyokisyu-err-tab-pane" role="tabpanel" aria-labelledby="hojyokisyu-err-tab" hidden={props.scope && !props.scope.hojyoKisyuItemSettingErrLogList}>
                    <div className="errLog">
                        <div id="hojyoKisyuItemSettingErrLogArea">
                            {props.scope && props.scope.hojyoKisyuItemSettingErrLogList && props.scope.hojyoKisyuItemSettingErrLogList.map((errlog: any) => {
                                return (
                            <div className="dp_flex">
                                <div className="wd20">
                                    <span className={(errlog.Status == MigrationErrStatus.Error) ? 'fa fa-exclamation-circle fa-fw red-icon' : ''}></span>
                                    <span className={(errlog.Status == MigrationErrStatus.Warning) ? 'migration-warning fa fa-warning fa-fw' : 'migration-warning'}></span>
                                </div>
                                <div className="wd100">{errlog.RecNo}</div>
                                <div>{errlog.Msg}</div>
                            </div>
                            );
                            })}
                            <div hidden={props.scope && !props.scope.isHojyoKisyuItemSettingErrLogCntOver} className="pl20">エラーログが1000件を超えたため、これ以上は表示できません。エラーログをダウンロードしてご確認ください。</div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="bmnkisyu-err-tab-pane" role="tabpanel" aria-labelledby="bmnkisyu-err-tab" hidden={props.scope && !props.scope.bmnKisyuItemSettingErrLogList}>
                    <div className="errLog">
                        <div id="bmnKisyuItemSettingErrLogArea">
                            {props.scope && props.scope.bmnKisyuItemSettingErrLogList && props.scope.bmnKisyuItemSettingErrLogList.map((errlog: any) => {
                                return (
                            <div className="dp_flex">
                                <div className="wd20">
                                    <span className={(errlog.Status == MigrationErrStatus.Error) ? 'fa fa-exclamation-circle fa-fw red-icon' : ''}></span>
                                    <span className={(errlog.Status == MigrationErrStatus.Warning) ? 'migration-warning fa fa-warning fa-fw' : 'migration-warning'}></span>
                                </div>
                                <div className="wd100">{errlog.RecNo}</div>
                                <div>{errlog.Msg}</div>
                            </div>
                            );
                            })}
                            <div hidden={props.scope && !props.scope.isBmnKisyuItemSettingErrLogCntOver} className="pl20">エラーログが1000件を超えたため、これ以上は表示できません。エラーログをダウンロードしてご確認ください。</div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="swk-err-tab-pane" role="tabpanel" aria-labelledby="swk-err-tab" hidden={props.scope && !props.scope.swkItemSettingErrLogList}>
                    <div className="errLog">
                        <div id="swkItemSettingErrLogArea">
                            {props.scope && props.scope.swkItemSettingErrLogList && props.scope.swkItemSettingErrLogList.map((errlog: any) => {
                                return (
                            <div className="dp_flex">
                                <div className="wd20">
                                    <span className={(errlog.Status == MigrationErrStatus.Error) ? 'fa fa-exclamation-circle fa-fw red-icon' : ''}></span>
                                    <span className={(errlog.Status == MigrationErrStatus.Warning) ? 'migration-warning fa fa-warning fa-fw' : 'migration-warning'}></span>
                                </div>
                                <div className="wd100">{errlog.RecNo}</div>
                                <div>{errlog.Msg}</div>
                            </div>
                            );
                            })}                        
                            <div hidden={props.scope && !props.scope.isSwkItemSettingErrLogCntOver} className="pl20">エラーログが1000件を超えたため、これ以上は表示できません。エラーログをダウンロードしてご確認ください。</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pb20">
                <button id="itemsetting-errlog-download-btn" type="button" className="btn btn-primary mt10 mb10 pr10 pl10 wd190">エラーログをダウンロードする</button>
            </div>
        </div>

        {/* 項目の変換 */}
        <div id="stepItemSetting-ItemConvert" hidden={props.scope && !(props.scope.kessanList || props.scope.taxKbnList || props.scope.taxRateList || props.scope.gyosyuList || props.scope.ivJigyoKbnList)}>
            <label className="step-description-label">項目の変換</label>
            <div hidden={props.scope && !(props.scope.itemConvertSettingSaveStatus == MigrationSettingSaveStatus.Changed)} className="migration-blue"><span className="fa fa-info-circle ml5 mr5"></span>項目の変換の設定が変更されています。設定を保存する場合は、「設定を保存する」ボタンをクリックして設定内容を保存してください。</div>
            <div className="migration-nav mt5" style={{borderBottom: "4px solid #337AB7"}}>
                <ul className="nav nav-tabs" role="tablist">
                    <li id="li-kessan-tab" className="nav-item" hidden={props.scope && !props.scope.kessanList}>
                        <a className="nav-link" style={{fontWeight: "bold",}} id="kessan-tab" data-toggle="tab" href="#kessan-tab-pane" role="tab" aria-controls="kessan" aria-selected="true" onClick={() => props.scope && props.scope.itemConvertSettingTabClick(0)}>決算仕訳区分</a>
                    </li>
                    <li id="li-taxKbn-tab" className="nav-item" hidden={props.scope && !props.scope.taxKbnList}>
                        <a className="nav-link" style={{fontWeight: "bold",}} id="taxKbn-tab" data-toggle="tab" href="#taxKbn-tab-pane" role="tab" aria-controls="taxKbn" aria-selected="true" onClick={() => props.scope && props.scope.itemConvertSettingTabClick(1)}>消費税区分<span hidden={props.scope && !(props.scope.itemConvertSettingTaxKbnErrCnt != 0 || (props.scope && !props.scope.taxRateList && props.scope.itemConvertSettingTaxRateErrCnt != 0))} className="tab-errcnt">（<span className="tab-errcnt" hidden={props.scope && props.scope.taxRateList!= undefined}>未設定：{(props.scope && props.scope.itemConvertSettingTaxKbnErrCnt? props.scope.itemConvertSettingTaxKbnErrCnt : 0)! + (props.scope && props.scope.itemConvertSettingTaxRateErrCnt? props.scope.itemConvertSettingTaxRateErrCnt : 0)!}件</span><span className="tab-errcnt" hidden={props.scope && !props.scope.taxRateList}>未設定：{props.scope && props.scope.itemConvertSettingTaxKbnErrCnt}件</span>）</span></a>
                    </li>
                    <li id="li-taxRate-tab" className="nav-item" hidden={props.scope && !props.scope.taxRateList}>
                        <a className="nav-link" style={{fontWeight: "bold",}} id="taxRate-tab" data-toggle="tab" href="#taxRate-tab-pane" role="tab" aria-controls="taxRate" aria-selected="true" onClick={() => props.scope && props.scope.itemConvertSettingTabClick(2)}>消費税率<span hidden={props.scope && !(props.scope.itemConvertSettingTaxRateErrCnt != 0)} className="tab-errcnt">（<span className="tab-errcnt">未設定：{props.scope && props.scope.itemConvertSettingTaxRateErrCnt}件</span>）</span></a>
                    </li>
                    <li id="li-gyosyu-tab" className="nav-item" hidden={props.scope && !props.scope.gyosyuList}>
                        <a className="nav-link" style={{fontWeight: "bold",}} id="gyosyu-tab" data-toggle="tab" href="#gyosyu-tab-pane" role="tab" aria-controls="gyosyu" aria-selected="true" onClick={() => props.scope && props.scope.itemConvertSettingTabClick(3)}>業種</a>
                    </li>
                    <li id="li-ivJigyoKbn-tab" className="nav-item" hidden={props.scope && !props.scope.ivJigyoKbnList}>
                        <a className="nav-link" style={{fontWeight: "bold",}} id="ivJigyoKbn-tab" data-toggle="tab" href="#ivJigyoKbn-tab-pane" role="tab" aria-controls="ivJigyoKbn" aria-selected="true" onClick={() => props.scope && props.scope.itemConvertSettingTabClick(4)}>事業者区分</a>
                    </li>
                </ul>
            </div>
            <div className="tab-content">
                {/* 決算仕訳区分 */}
                <div className="tab-pane fade" id="kessan-tab-pane" role="tabpanel" aria-labelledby="kessan-tab" hidden={props.scope && !props.scope.kessanList}>
                    <div className="step-description mt10 mb10">
                        ・項目の設定でインポートされた、「決算仕訳区分」の変換設定を行います。インポートされた決算仕訳区分を、AI監査支援のどの決算仕訳区分に変換するか設定してください。
                    </div>
                    <div className="settingTable migration-table-border">
                        <table className="migration-table">
                            <tr className="migration-table-border-head">
                                <th style={{width: "15%"}}>インポートされた決算仕訳区分</th>
                                <th style={{width: "3%"}}></th>
                                <th style={{width: "20%"}}>AI監査支援の決算仕訳区分</th>
                                <th style={{width: "50%"}}></th>
                            </tr>
                            {props.scope && props.scope.kessanList && props.scope.kessanList.map((kessan: any, i: number) => {
                            return (
                            <tr>
                                <td><div style={{whiteSpace: "nowrap",}}><span className="dp_iblock" style={{fontWeight:"bold", minWidth:"80px"}}>{kessan.DisplayBeforeValue}</span></div></td>
                                <td><span className="fa fa-arrow-right"></span></td>
                                <td style={{minWidth:"245px",}}>
                                    <div id={`cmbSelectKessan${i}`} className="easy-input"></div>
                                </td>
                                <td></td>
                            </tr>
                            );
                            })}
                        </table>
                    </div>
                </div>
                {/* 消費税区分 */}
                <div className="tab-pane fade" id="taxKbn-tab-pane" role="tabpanel" aria-labelledby="taxKbn-tab" hidden={props.scope && !props.scope.taxKbnList}>
                    <div className="step-description mt10 mb10">
                        ・項目の設定でインポートされた、「消費税区分」の変換設定を行います。インポートされた消費税区分を、AI監査支援のどの消費税区分に変換するか設定してください。<br />
                    </div>
                    <div hidden={props.scope && !(props.scope.itemConvertSettingTaxKbnErrCnt != 0 && props.scope.taxRateList)} className="red-icon"><span className="fa fa-exclamation-circle ml5 mr5"></span>AI監査支援の消費税区分の中に未設定のものがあります。消費税区分を設定してください。</div>
                    <div hidden={props.scope && !(props.scope.itemConvertSettingTaxKbnErrCnt != 0 && !props.scope.taxRateList && props.scope.itemConvertSettingTaxRateErrCnt == 0)} className="red-icon"><span className="fa fa-exclamation-circle ml5 mr5"></span>AI監査支援の消費税区分の中に未設定のものがあります。消費税区分を設定してください。</div>
                    <div hidden={props.scope && !(props.scope.itemConvertSettingTaxKbnErrCnt == 0 && !props.scope.taxRateList && props.scope.itemConvertSettingTaxRateErrCnt != 0)} className="red-icon"><span className="fa fa-exclamation-circle ml5 mr5"></span>AI監査支援の消費税率の中に未設定のものがあります。消費税率を設定してください。</div>
                    <div hidden={props.scope && !(props.scope.itemConvertSettingTaxKbnErrCnt != 0 && !props.scope.taxRateList && props.scope.itemConvertSettingTaxRateErrCnt != 0)} className="red-icon"><span className="fa fa-exclamation-circle ml5 mr5"></span>AI監査支援の消費税区分、消費税率の中に未設定のものがあります。消費税区分、消費税率を設定してください。</div>
                    <div className="settingTable migration-table-border">
                        <table className="migration-table">
                            <tr className="migration-table-border-head">
                                <th style={{width: "15%"}}>インポートされた消費税区分</th>
                                <th style={{width: "3%"}}></th>
                                <th style={{width: "15%"}}>AI監査支援の消費税区分</th>
                                <th style={{width: "15%"}}></th>
                                <th style={{width: "15%"}}></th>
                                <th style={{width: "25%"}}></th>
                            </tr>
                            {props.scope && props.scope.taxKbnList && props.scope.taxKbnList.map((taxKbn: any, i: number) => {
                            return (
                            <tr>
                                <td><div style={{whiteSpace: "nowrap",}}><span className="dp_iblock" style={{fontWeight:"bold", minWidth:"80px"}}>{taxKbn.DisplayBeforeValue}</span></div></td>
                                <td><span className="fa fa-arrow-right"></span></td>
                                <td style={{minWidth: "240px",}}>
                                    <div className="dp_flex">
                                        <div className="flex-autocomplete zenkaku">
                                            <span id={`cmbSelectTaxKbn${i}`} className="easy-input easy-input-autocomplete"></span>
                                            <button id={`cmbSelectBtnTaxKbn${i}`} className="btn-easy-input-explorer-combo"><span className="custom-icon-StackedBooks"></span></button>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    {props.scope && props.scope.IsItemTaxRate && <div id={`cmbSelectTaxRate${i}`} className="easy-input"></div>}
                                </td>
                                <td>
                                    {props.scope && props.scope.IsItemTypeCd && <div id={`cmbSelectGyosyu${i}`} className="easy-input" ></div>}
                                </td>
                                <td></td>
                            </tr>
                            );
                            })}
                        </table>
                    </div>
                </div>
                {/* 消費税率 */}
                <div className="tab-pane fade" id="taxRate-tab-pane" role="tabpanel" aria-labelledby="taxRate-tab" hidden={props.scope && !props.scope.taxRateList}>
                    <div className="step-description mt10 mb10">
                        ・項目の設定でインポートされた、「消費税率」の変換設定を行います。インポートされた消費税率を、AI監査支援のどの消費税率に変換するか設定してください。
                    </div>
                    <div hidden={props.scope && !(props.scope.itemConvertSettingTaxRateErrCnt != 0)} className="red-icon"><span className="fa fa-exclamation-circle ml5 mr5"></span>AI監査支援の消費税率の中に未設定のものがあります。消費税率を設定してください。</div>
                    <div className="settingTable migration-table-border">
                        <table className="migration-table">
                            <tr className="migration-table-border-head">
                                <th style={{width: "15%"}}>インポートされた消費税率</th>
                                <th style={{width: "3%"}}></th>
                                <th style={{width: "20%"}}>AI監査支援の消費税率</th>
                                <th style={{width: "50%"}}></th>
                            </tr>
                            {props.scope && props.scope.taxRateList && props.scope.taxRateList.map((taxRate: any, i: number) => {
                            return (
                            <tr>
                                <td><div style={{whiteSpace: "nowrap",}}><span className="dp_iblock" style={{fontWeight:"bold", minWidth:"80px"}}>{taxRate.DisplayBeforeValue}</span></div></td>
                                <td><span className="fa fa-arrow-right"></span></td>
                                <td style={{minWidth:"245px",}}>
                                    <div id={`cmbSelectTaxRate${i}`} className="easy-input"></div>
                                </td>
                                <td></td>
                            </tr>
                            );
                            })}
                        </table>
                    </div>
                </div>
                {/* 業種 */}
                <div className="tab-pane fade" id="gyosyu-tab-pane" role="tabpanel" aria-labelledby="gyosyu-tab" hidden={props.scope && !props.scope.gyosyuList}>
                    <div className="step-description mt10 mb10">
                        ・項目の設定でインポートされた、「業種」の変換設定を行います。インポートされた業種を、AI監査支援のどの業種に変換するか設定してください。
                    </div>
                    <div className="settingTable migration-table-border">
                        <table className="migration-table">
                            <tr className="migration-table-border-head">
                                <th style={{width: "15%"}}>インポートされた業種</th>
                                <th style={{width: "3%"}}></th>
                                <th style={{width: "20%"}}>AI監査支援の業種</th>
                                <th style={{width: "50%"}}></th>
                            </tr>
                            {props.scope && props.scope.gyosyuList && props.scope.gyosyuList.map((gyosyu: any, i: number) => {
                            return (
                            <tr>
                                <td><div style={{whiteSpace: "nowrap",}}><span className="dp_iblock" style={{fontWeight:"bold", minWidth:"80px"}}>{gyosyu.DisplayBeforeValue}</span></div></td>
                                <td><span className="fa fa-arrow-right"></span></td>
                                <td style={{minWidth:"245px",}}>
                                    <div id={`cmbSelectGyosyu${i}`} className="easy-input"></div>
                                </td>
                                <td></td>
                            </tr>
                            );
                            })}
                        </table>
                    </div>
                </div>
                {/* 事業者区分 */}
                <div className="tab-pane fade" id="ivJigyoKbn-tab-pane" role="tabpanel" aria-labelledby="ivJigyoKbn-tab" hidden={props.scope && !props.scope.ivJigyoKbnList}>
                    <div className="step-description mt10 mb10">
                        ・項目の設定でインポートされた、「事業者区分」の変換設定を行います。インポートされた事業者区分を、AI監査支援のどの事業者区分に変換するか設定してください。
                    </div>
                    <div className="settingTable migration-table-border">
                        <table className="migration-table">
                            <tr className="migration-table-border-head">
                                <th style={{width: "15%"}}>インポートされた事業者区分</th>
                                <th style={{width: "3%"}}></th>
                                <th style={{width: "20%"}}>AI監査支援の事業者区分</th>
                                <th style={{width: "50%"}}></th>
                            </tr>
                            {props.scope && props.scope.ivJigyoKbnList && props.scope.ivJigyoKbnList.map((ivJigyoKbn: any, i: number) => {
                            return (
                            <tr>
                                <td><div style={{whiteSpace: "nowrap",}}><span className="dp_iblock" style={{fontWeight:"bold", minWidth:"80px"}}>{ivJigyoKbn.DisplayBeforeValue}</span></div></td>
                                <td><span className="fa fa-arrow-right"></span></td>
                                <td style={{minWidth:"245px",}}>
                                    <div id={`cmbSelectIvJigyoKbn${i}`} className="easy-input"></div>
                                </td>
                                <td></td>
                            </tr>
                            );
                            })}
                        </table>
                    </div>
                </div>
                <div className="pb20 mb10">
                    <button id="itemConvertSetting-save-btn" type="button" className="btn btn-primary mt10 pr10 pl10 wd190">設定を保存する</button><br />
                    <div hidden={props.scope && !(props.scope.itemConvertSettingSaveStatus == MigrationSettingSaveStatus.Saved)} className="btn-success-msg"><span className="fa fa-check-circle text-success btn-success-icon pl5 pr5"></span>設定を保存しました。</div>
                </div>
            </div>
        </div>
    </div>
</section>
    </>
  )
};
export default _ProcessMigrationStepItemSettingPartial;