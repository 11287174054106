import React from 'react';
import { MigrationErrStatus, MigrationHojyoTabKbn } from '../../../../../VKZ/core/constants/constant';


type _ProcessMigrationStepHojyoSettingPartialProps = {
  scope?: any
};
  
const _ProcessMigrationStepHojyoSettingPartial: React.FC<_ProcessMigrationStepHojyoSettingPartialProps> = props => {
  return (
    <>
<section id="stepHojyoSetting" className="dp_none">
    <span className="step-number" >{props.scope && props.scope.stepHojyoSettingDispNo}</span>
    <div className="step-title">インポートされた補助科目、部門、期首残高の設定を行ってください。</div>
    <div id="stepHojyoSetting-content" className="step-content">
        <div className="step-description mb10">
            ・AI監査支援に登録されている補助科目の名称にマッチした補助科目が自動的に設定されます。<br />
            &ensp;マッチしなかった補助科目は、AI監査支援に既にある補助科目と対応付けさせるか、「新規登録」を選択してください。<br />
            &ensp;「新規登録」を選択した補助は、「設定を保存する」ボタンをクリックした時に追加されます。<br /><br />
            ・「残高をインポートする」ボタンをクリックすると、対応付けされている補助科目に期首残高がインポートされます。<br />
            &ensp;<span className="red-icon">残高インポートを行う場合は、必ず設定を保存してから行ってください。残高のインポートを行うと、補助科目の期首残高を全てクリアしてインポートされますのでご注意ください。</span>
        </div>
        <label className="step-description-label">補助科目の対応付け</label>
        <div id="setting-errmsg" className="dp_none red-icon"><span className="fa fa-exclamation-circle ml5 mr5"></span>背景色が赤い{props.scope && props.scope.settingErr}は、対応付けの設定が必要です。</div>
        <div id="save-infomsg" className="dp_none migration-blue"><span className="fa fa-info-circle ml5 mr5"></span>{props.scope && props.scope.saveInfo}の対応付けの設定が変更されています。設定を保存する場合は、「設定を保存する」ボタンをクリックして設定内容を保存してください。</div>
        <div className="migration-nav mt5" style={{borderBottom: "4px solid #337AB7"}}>
            <ul className="nav nav-tabs" role="tablist">
                <li id="li-hojyo-tab" className="nav-item active">
                    <a className="nav-link" style={{fontWeight: "bold",}} id="hojyo-tab" data-toggle="tab" href="#hojyo-tab-pane" role="tab" aria-controls="hojyo" aria-selected="true" onClick={() => props.scope.tabClick(MigrationHojyoTabKbn.Hojyo)}>補助科目<span hidden={props.scope && !(props.scope.hojyoErrCnt != 0)} >（<span className="tab-errcnt">エラー：{props.scope && props.scope.hojyoErrCnt}件</span>）</span></a>
                </li>
                <li id="li-generalhojyo-tab" className="nav-item">
                    <a className="nav-link" style={{fontWeight: "bold",}} id="generalhojyo-tab" data-toggle="tab" href="#generalhojyo-tab-pane" role="tab" aria-controls="generalhojyo" aria-selected="true" onClick={() => props.scope.tabClick(MigrationHojyoTabKbn.GeneralHojyo)}>汎用補助<span hidden={props.scope && !(props.scope.generalhojyoErrCnt != 0)} >（<span className="tab-errcnt">エラー：{props.scope && props.scope.generalhojyoErrCnt}件</span>）</span></a>
                </li>
                <li id="li-bmn-tab" className="nav-item">
                    <a className="nav-link" style={{fontWeight: "bold",}} id="bmn-tab" data-toggle="tab" href="#bmn-tab-pane" role="tab" aria-controls="bmn" aria-selected="true" onClick={() => props.scope.tabClick(MigrationHojyoTabKbn.Bmn)}>部門<span hidden={props.scope && !(props.scope.bmnErrCnt != 0)} >（<span className="tab-errcnt">エラー：{props.scope && props.scope.bmnErrCnt}件</span>）</span></a>
                </li>
                <li id="li-segment-tab" className="nav-item">
                    <a className="nav-link" style={{fontWeight: "bold",}} id="segment-tab" data-toggle="tab" href="#segment-tab-pane" role="tab" aria-controls="segment" aria-selected="true" onClick={() => props.scope.tabClick(MigrationHojyoTabKbn.Segment)}>セグメント<span hidden={props.scope && !(props.scope.segmentErrCnt != 0)} >（<span className="tab-errcnt">エラー：{props.scope && props.scope.segmentErrCnt}件</span>）</span></a>
                </li>
                <li id="li-kouji-tab" className="nav-item">
                    <a className="nav-link" style={{fontWeight: "bold",}} id="kouji-tab" data-toggle="tab" href="#kouji-tab-pane" role="tab" aria-controls="kouji" aria-selected="true" onClick={() => props.scope.tabClick(MigrationHojyoTabKbn.Kouji)}>工事<span hidden={props.scope && !(props.scope.koujiErrCnt != 0)} >（<span className="tab-errcnt">エラー：{props.scope && props.scope.koujiErrCnt}件</span>）</span></a>
                </li>
            </ul>
        </div>
        <div className="tab-content">
            <div className="tab-pane fade in active" id="hojyo-tab-pane" role="tabpanel" aria-labelledby="hojyo-tab">
                <div className="settingTable migration-table-border">
                    <table className="migration-table">
                        <tr className="migration-table-border-head">
                            <th style={{width: "15%"}}>インポートされた補助科目</th>
                            <th style={{width: "3%"}}></th>
                            <th style={{width: "20%"}}>AI監査支援での登録先</th>
                            <th style={{width: "20%"}}></th>
                            <th style={{width: "30%"}}>期首残高</th>
                        </tr>
                        {props.scope && props.scope.hojyoList && props.scope.hojyoList.map((hojyo: any, i: number) => {
                        return (
                        <tr>
                            <td><div style={{whiteSpace: "nowrap",}}><span hidden={!(hojyo.SrcKmkName != '')}><span className="dp_iblock" style={{fontWeight:"bold", minWidth:"80px"}}>{hojyo.SrcKmkName}</span>:</span>{hojyo.SrcHojyoNameDisp}</div></td>
                            <td><span className="fa fa-arrow-right"></span></td>
                            <td style={{minWidth:"252px",}}>
                                <div id={`cmbHowRegist_hojyo${i}`} className="easy-input"></div>
                                <span id={`tooltipHojyoNotKmk${i}`} className="dp_none"><span className="fa fa-info-circle migration-blue ml10" data-toggle="tooltip" data-html="true" data-placement="top" title="補助を採用している科目の対応付けが行われていません。科目の対応付けを行うことで、補助として登録することができます。"></span></span>
                                <span id={`tooltipMasterByAccount${i}`} className="dp_none"><span className="fa fa-exclamation-circle red-icon ml10" data-toggle="tooltip" data-html="true" data-placement="top" title=""></span></span>
                            </td>
                            <td style={{minWidth:"252px",}}>
                                <div id={`cmbSelectHojyo_hojyo${i}`} className="easy-input"></div>
                                <span id={`tooltipHojyoSameRegist${i}`} className="dp_none"><span className="fa fa-info-circle migration-blue ml10" data-toggle="tooltip" data-html="true" data-placement="top" title="同一名称の補助を同一種類の補助に新規登録する場合、補助は一つしか追加されません。"></span></span>
                                <span id={`tooltipHojyoDup${i}`} className="dp_none"><span className="fa fa-exclamation-circle red-icon ml10" data-toggle="tooltip" data-html="true" data-placement="top" title="同一補助が設定されています。設定を見直してください。"></span></span>
                            </td>
                            <td style={{minWidth:"220px",}}>
                                <span id={`inpHojyoSum${i}`} className="easy-input hankaku number-input"></span>
                                <span id={`tooltipHojyoKisyu${i}`} className="dp_none"><span className="fa fa-info-circle migration-blue ml10" data-toggle="tooltip" data-html="true" data-placement="top" title="勘定科目の期首残区分がなしの状態となっているため、残高インポートできません。インポートしたい場合は勘定科目登録で該当の勘定科目の期首残区分をありに更新してください。"></span></span>
                            </td>
                        </tr>
                        );
                        })}
                    </table>
                </div>
            </div>
            <div className="tab-pane fade" id="generalhojyo-tab-pane" role="tabpanel" aria-labelledby="generalhojyo-tab">
                <div className="settingTable migration-table-border">
                    <table className="migration-table">
                        <tr className="migration-table-border-head">
                            <th style={{width: "15%"}}>インポートされた汎用補助</th>
                            <th style={{width: "3%"}}></th>
                            <th style={{width: "20%"}}>AI監査支援での登録先</th>
                            <th style={{width: "20%"}}></th>
                            <th style={{width: "30%"}}></th>
                        </tr>
                        {props.scope && props.scope.generalHojyoList && props.scope.generalHojyoList.map((generalhojyo: any, i: number) => {
                        return (
                        <tr >
                            <td><div style={{whiteSpace: "nowrap",}}><span hidden={!(generalhojyo.SrcKmkName != '')}><span className="dp_iblock" style={{fontWeight:"bold", minWidth:"80px"}}>{generalhojyo.SrcKmkName}</span>:</span>{generalhojyo.SrcHojyoNameDisp}</div></td>
                            <td><span className="fa fa-arrow-right"></span></td>
                            <td style={{minWidth:"252px",}}>
                                <div id={`cmbHowRegist_generalhojyo${i}`} className="easy-input"></div>
                                <span id={`tooltipGeneralHojyoNotKmk${i}`} className="dp_none"><span className="fa fa-info-circle migration-blue ml10" data-toggle="tooltip" data-html="true" data-placement="top" title="汎用補助を採用している科目の対応付けが行われていません。科目の対応付けを行うことで、汎用補助として登録することができます。"></span></span>
                                <span id={`tooltipGeneralHojyoMasterByAccount${i}`} className="dp_none"><span className="fa fa-exclamation-circle red-icon ml10" data-toggle="tooltip" data-html="true" data-placement="top" title=""></span></span>
                            </td>
                            <td style={{minWidth:"252px",}}>
                                <div id={`cmbSelectHojyo_generalhojyo${i}`} className="easy-input"></div>
                                <span id={`tooltipGeneralHojyoSameRegist${i}`} className="dp_none"><span className="fa fa-info-circle migration-blue ml10" data-toggle="tooltip" data-html="true" data-placement="top" title="同一名称の補助を同一種類の補助に新規登録する場合、補助は一つしか追加されません。"></span></span>
                                <span id={`tooltipGeneralHojyoDup${i}`} className="dp_none"><span className="fa fa-exclamation-circle red-icon ml10" data-toggle="tooltip" data-html="true" data-placement="top" title="同一補助が設定されています。設定を見直してください。"></span></span>
                            </td>
                            <td></td>
                        </tr>
                        );
                        })}
                    </table>
                </div>
            </div>
            <div className="tab-pane fade" id="bmn-tab-pane" role="tabpanel" aria-labelledby="bmn-tab">
                <div className="settingTable migration-table-border">
                    <table className="migration-table">
                        <tr className="migration-table-border-head">
                            <th style={{width: "15%"}}>インポートされた部門</th>
                            <th style={{width: "3%"}}></th>
                            <th style={{width: "20%"}}>AI監査支援での登録先</th>
                            <th style={{width: "20%"}}></th>
                            <th style={{width: "30%"}}></th>
                        </tr>
                        {props.scope && props.scope.bmnList && props.scope.bmnList.map((bmn: any, i: number) => {
                        return (
                        <tr >
                            <td><div style={{whiteSpace: "nowrap",}}>{bmn.SrcHojyoNameDisp}</div></td>
                            <td><span className="fa fa-arrow-right"></span></td>
                            <td><div id={`cmbHowRegist_bmn${i}`} className="easy-input"></div></td>
                            <td><div id={`cmbSelectHojyo_bmn${i}`} className="easy-input"></div></td>
                            <td></td>
                        </tr>
                        );
                        })}
                    </table>
                </div>
            </div>
            <div className="tab-pane fade" id="segment-tab-pane" role="tabpanel" aria-labelledby="segment-tab">
                <div className="settingTable migration-table-border">
                    <table className="migration-table">
                        <tr className="migration-table-border-head">
                            <th style={{width: "15%"}}>インポートされたセグメント</th>
                            <th style={{width: "3%"}}></th>
                            <th style={{width: "20%"}}>AI監査支援での登録先</th>
                            <th style={{width: "20%"}}></th>
                            <th style={{width: "30%"}}></th>
                        </tr>
                        {props.scope && props.scope.segmentList && props.scope.segmentList.map((segment: any, i: number) => {
                        return (
                        <tr >
                            <td><div style={{whiteSpace: "nowrap",}}>{segment.SrcHojyoNameDisp}</div></td>
                            <td><span className="fa fa-arrow-right"></span></td>
                            <td><div id={`cmbHowRegist_segment${i}`} className="easy-input"></div></td>
                            <td><div id={`cmbSelectHojyo_segment${i}`} className="easy-input"></div></td>
                            <td></td>
                        </tr>
                        );
                        })}
                    </table>
                </div>
            </div>
            <div className="tab-pane fade" id="kouji-tab-pane" role="tabpanel" aria-labelledby="kouji-tab">
                <div className="settingTable migration-table-border">
                    <table className="migration-table">
                        <tr className="migration-table-border-head">
                            <th style={{width: "15%"}}>インポートされた工事</th>
                            <th style={{width: "3%"}}></th>
                            <th style={{width: "20%"}}>AI監査支援での登録先</th>
                            <th style={{width: "20%"}}></th>
                            <th style={{width: "30%"}}></th>
                        </tr>
                        {props.scope && props.scope.koujiList && props.scope.koujiList.map((kouji: any, i: number) => {
                        return (
                        <tr >
                            <td><div style={{whiteSpace: "nowrap",}}>{kouji.SrcHojyoNameDisp}</div></td>
                            <td><span className="fa fa-arrow-right"></span></td>
                            <td><div id={`cmbHowRegist_kouji${i}`} className="easy-input"></div></td>
                            <td><div id={`cmbSelectHojyo_kouji${i}`} className="easy-input"></div></td>
                            <td></td>
                        </tr>
                        );
                        })}
                    </table>
                </div>
            </div>
            <div className="dp_flex pb20">
                <div>
                    <button id="hojyokmkSetting-save-btn" type="button" className="btn btn-primary mt10 pr10 pl10 wd190">設定を保存する</button>
                    <div id="hojyokmksetting-save-success" className="btn-success-msg" style={{visibility: "hidden",}}><span className="fa fa-check-circle text-success btn-success-icon pl5 pr5"></span>設定を保存しました。</div>
                </div>
                <div style={{position: "relative"}}>
                    <button id="hojyokmkkisyu-import-btn" type="button" className="btn btn-primary mt10 pr10 pl10 wd190">残高をインポートする</button>
                    <div id="hojyokmkkisyu-import-success" className="btn-success-msg" style={{visibility: "hidden", position:"absolute", width: "220px"}}><span className="fa fa-check-circle text-success btn-success-icon pl5 pr5"></span>残高のインポートが完了しました。</div>
                </div>
                <div>
                    <button id="hojyokmkkisyu-check-btn" type="button" className="btn btn-primary mt10 pr10 pl10 wd190">補助期首残高を確認する</button>
                </div>
                <div>
                    <button id="bmnkisyu-check-btn" type="button" className="btn btn-primary mt10 pr10 pl10 wd190">部門期首残高を確認する</button>
                </div>
            </div>
        </div>
        {/* 残高インポートエラーログ */}
        <div id="stepHojyoSetting-ErrLog" hidden={!(props.scope && (props.scope.hojyoKisyuImportErrLogList || props.scope.bmnKisyuImportErrLogList))}>
            <label className="step-description-label">残高インポートエラーログ</label>
            <div className="migration-nav mt5" style={{borderBottom: "4px solid #337AB7"}}>
                <ul className="nav nav-tabs" role="tablist">
                    <li id="li-hojyokisyu-import-err-tab" className="nav-item" hidden={props.scope && !props.scope.hojyoKisyuImportErrLogList}>
                        <a className="nav-link" style={{fontWeight: "bold",}} id="hojyokisyu-import-err-tab" data-toggle="tab" href="#hojyokisyu-import-err-tab-pane" role="tab" aria-controls="hojyokisyuimport" aria-selected="true" onClick={() => props.scope.importErrLogTabClick(0)}>補助期首残高</a>
                    </li>
                    <li id="li-bmnkisyu-import-err-tab" className="nav-item" hidden={props.scope && !props.scope.bmnKisyuImportErrLogList}>
                        <a className="nav-link" style={{fontWeight: "bold",}} id="bmnkisyu-import-err-tab" data-toggle="tab" href="#bmnkisyu-import-err-tab-pane" role="tab" aria-controls="bmnkisyuimport" aria-selected="true" onClick={() => props.scope.importErrLogTabClick(1)}>部門期首残高</a>
                    </li>
                </ul>
            </div>
            <div className="tab-content">
                <div className="tab-pane fade" id="hojyokisyu-import-err-tab-pane" role="tabpanel" aria-labelledby="hojyokisyu-import-err-tab" hidden={props.scope && !props.scope.hojyoKisyuImportErrLogList}>
                    <div className="errLog">
                        <div id="hojyoKisyuImportErrLogArea">
                            {props.scope && props.scope.hojyoKisyuImportErrLogList && props.scope.hojyoKisyuImportErrLogList.map((errlog: any) => {
                                return (
                            <div className="dp_flex">
                                <div className="wd20">
                                    <span className={(errlog.Status == MigrationErrStatus.Error) ? 'fa fa-exclamation-circle fa-fw red-icon' : ''}></span>
                                    <span className={(errlog.Status == MigrationErrStatus.Warning) ? 'migration-warning fa fa-warning fa-fw' : 'migration-warning'}></span>
                                </div>
                                <div className="wd100">{errlog.RecNo}</div>
                                <div>{errlog.Msg}</div>
                            </div>
                            );
                            })}
                            <div hidden={props.scope && !props.scope.isHojyoKisyuImportErrLogCntOver} className="pl20">エラーログが1000件を超えたため、これ以上は表示できません。エラーログをダウンロードしてご確認ください。</div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="bmnkisyu-import-err-tab-pane" role="tabpanel" aria-labelledby="bmnkisyu-import-err-tab" hidden={props.scope && !props.scope.bmnKisyuImportErrLogList}>
                    <div className="errLog">
                        <div id="bmnKisyuImportErrLogArea">
                            {props.scope && props.scope.bmnKisyuImportErrLogList && props.scope.bmnKisyuImportErrLogList.map((errlog: any) => {
                                return (
                            <div className="dp_flex">
                                <div className="wd20">
                                    <span className={(errlog.Status == MigrationErrStatus.Error) ? 'fa fa-exclamation-circle fa-fw red-icon' : ''}></span>
                                    <span className={(errlog.Status == MigrationErrStatus.Warning) ? 'migration-warning fa fa-warning fa-fw' : 'migration-warning'}></span>
                                </div>
                                <div className="wd100">{errlog.RecNo}</div>
                                <div>{errlog.Msg}</div>
                            </div>
                            );
                            })}
                            <div hidden={props.scope && !props.scope.isbmnKisyuImportErrLogCntOver} className="pl20">エラーログが1000件を超えたため、これ以上は表示できません。エラーログをダウンロードしてご確認ください。</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pb20">
                <button id="kisyu-import-errlog-download-btn" type="button" className="btn btn-primary mt10 mb10 pr10 pl10 wd190">エラーログをダウンロードする</button>
            </div>
        </div>
    </div>
</section>
    </>
  )
};
export default _ProcessMigrationStepHojyoSettingPartial;