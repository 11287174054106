/**
 * メッセージに文字をバインドします。
 * @param message メッセージ
 * @param values バインドする文字の配列
 */
export const bindValueToMessage = (message: string, values?: string[]) => {
  let ret = message;
  if (values) {
    values.forEach((val, index) => {
      ret = ret.split(['{', index, '}'].join('')).join(val);
    });
  }
  return ret;
};
