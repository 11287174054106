import React from 'react';
import { MigrationErrStatus } from '../../../../../VKZ/core/constants/constant';

type _ProcessMigrationStepSwkImportPartialProps = {
  scope?: any
};

const _ProcessMigrationStepSwkImportPartial: React.FC<_ProcessMigrationStepSwkImportPartialProps> = props => {
  return (
    <>
<section id="stepSwkImport" className="dp_none">
    <span className="step-number" >{props.scope && props.scope.stepSwkImportDispNo}</span>
    <div className="step-title">仕訳データをインポートしてください。</div>
    <div id="stepSwkImport-content" className="step-content">
        <div className="step-description mb10">
            {props.scope && props.scope.stepSwkImportDescription}<br />
            &ensp;<span className="red-icon">インポートする対象の月度に既に仕訳が登録されている場合、その月度の仕訳は削除されますのでご注意ください。</span>
        </div>
        <label className="step-description-label mt10">取込月の選択</label>
        <div className="migration-table-border">
            <table className="migration-table">
                <tr className="migration-table-border-head">
                    <th style={{width: "1%"}}></th>
                    <th style={{width: "1%"}}></th>
                    <th style={{width: "15%",minWidth:"210px"}} >仕訳の登録月</th>
                    <th style={{width: "1%"}}></th>
                    <th style={{width: "10%",textAlign:"right"}}>仕訳件数</th>
                    <th style={{width: "10%",textAlign:"right"}}><span hidden={!(props.scope &&  props.scope.swkSetting && props.scope.swkSetting.TotalErrorAmount != 0)}>エラー件数</span></th>
                    <th style={{width: "60%"}}></th>
                </tr>
                {props.scope && props.scope.swkSetting && props.scope.swkSetting.SwkInfoList && props.scope.swkSetting.SwkInfoList.map((swkinfo: any, i: number) => {
                    return (
                <tr>
                    <td><span hidden={!(swkinfo.YearMonth != "対象外")}><input id={`swkinfo-checkbox${i}`} name={`swkinfo-checkbox${i}`} type="checkbox" onClick={() => props.scope && props.scope.checkStatusClick(i)} style={{margin:"-3px 0 0"}}/></span></td>
                    <td><span hidden={!(swkinfo.ErrorAmount != 0)} className="fa fa-exclamation-circle red-icon"></span></td>
                    <td>{swkinfo.YearMonth}</td>
                    <td><span className="fa fa-arrow-right"></span></td>
                    <td align="right"><span hidden={!(swkinfo.YearMonth != "対象外")}>{swkinfo.JournalAmount} 件</span></td>
                    <td align="right"><span hidden={!(swkinfo.ErrorAmount != 0)} className="red-icon">{swkinfo.ErrorAmount} 件</span></td>
                    <td>
                        <div hidden={!(swkinfo.ErrorMessage != "" && !swkinfo.isMultiErr)} className="red-icon"><span className="fa fa-exclamation-circle ml5 mr5"></span>{swkinfo.ErrorMessage}</div>
                        <div hidden={!(swkinfo.isMultiErr)} className="red-icon">
                            <div>
                                <span id={`tooltip-MultiErr${i}`} className="fa fa-exclamation-circle ml5 mr5" data-toggle="tooltip" data-html="true" data-placement="right" title=""></span>複数のエラーが発生しています。<span className="fa fa-exclamation-circle ml5 mr5 red-icon"></span>にマウスカーソルを合わせてエラーを確認してください。
                            </div>
                        </div>
                    </td>
                </tr>
                );
                })}
                <tr>
                    <th></th>
                    <th></th>
                    <th>合計</th>
                    <th></th>
                    <th className="pr10" style={{textAlign:"right"}}>{props.scope && props.scope.swkSetting && props.scope.swkSetting.TotalJournalAmount} 件</th>
                    <th className="pr10 red-icon" style={{textAlign:"right"}}><span hidden={!(props.scope && props.scope.swkSetting && props.scope.swkSetting.TotalErrorAmount != 0)}>{props.scope && props.scope.swkSetting && props.scope.swkSetting.TotalErrorAmount} 件</span></th>
                    <th></th>
                </tr>
            </table>
        </div>
        <label className="mt10 ml5" style={{fontWeight: "normal"}}><input id="checkbox-errCancelFlg" type="checkbox" onClick={props.scope && props.scope.errCancelFlgClick} style={{margin:"-3px 3px 0 0"}} />エラーがある場合はインポートをキャンセルする</label>
        <p className="fa fa-question-circle-o ml-1" data-toggle="tooltip" data-html="true" data-placement="right" title="チェックして仕訳をインポートすると、取り込めない仕訳が1件でもあった場合にインポートがキャンセルされます。チェックせずに仕訳をインポートすると、エラーが発生していない仕訳のみがインポートされます。"></p>
        <div>
            <button id="swk-import-btn" type="button" className="btn btn-primary pr10 pl10 wd190">仕訳をインポートする</button>
            <div id="swk-import-success" className="dp_none btn-success-msg"><span className="fa fa-check-circle fa-fw text-success btn-success-icon pl5 mr5"></span>仕訳のインポートが完了しました。</div>
            <div id="swk-import-cancel" className="dp_none btn-success-msg"><span className="fa fa-warning fa-fw migration-warning btn-success-icon pl5 mr5"></span>仕訳のインポートがキャンセルされました。</div>
            <div id="swk-import-err" className="dp_none btn-success-msg"><span className="fa fa-times-circle fa-fw red-icon btn-success-icon pl5 mr5"></span>仕訳のインポートが失敗しました。</div>
        </div>
        <div id="swkErrLogArea" className="dp_none mt20">
            <label className="step-description-label">仕訳インポートエラーログ</label>
            <div className="swkErrLog">
            {props.scope && props.scope.swkErrLogList && props.scope.swkErrLogList.map((errlog: any) => {
                return (
                <div className="dp_flex">
                    <div className="wd20">
                        <span className={errlog.Status == MigrationErrStatus.Error ? 'fa fa-exclamation-circle fa-fw red-icon' : ''}></span>
                        <span className = {'migration-warning '+ (errlog.Status == MigrationErrStatus.Warning ? 'fa fa-warning fa-fw' : '')}></span>
                    </div>
                    <div style={{minWidth: "100px",}}>{errlog.RecNo}</div>
                    <div>{errlog.Msg}</div>
                </div>
                );
            })}
                <div hidden={props.scope && !(props.scope.isSwkErrLogListCntOver)} className="pl20">エラーログが1000件を超えたため、これ以上は表示できません。エラーログをダウンロードしてご確認ください。</div>
            </div>
            <div className="pb20">
                <button id="swkerrlog-download-btn" type="button" className="btn btn-primary mt10 mb10 pr10 pl10 wd190">エラーログをダウンロードする</button>
            </div>
        </div>
    </div>
</section>
    </>
  )
};
export default _ProcessMigrationStepSwkImportPartial;