import React from 'react';
import Axios from 'axios';
import { FormGroup, Label, Input } from 'reactstrap';

import * as messageUtils from '../../../../utils/messageUtils';
import * as labels from '../../../../constants/labels';
import * as message from '../../../../constants/message';
import * as constant from '../../../../constants/constant';
import * as enums from '../../../../constants/enums';
import * as dataMaps from '../../../../constants/dataMaps';

// コンポーネント
import Popup from '../../../../components/molecules/CMN/Popup/Popup';
import ExecutingCancel from '../../../../components/molecules/CMN/ExecutingCancel/ExecutingCancel';
import AngleCircleFrameLine from '../../../../components/atoms/AngleCircleFrameLine/AngleCircleFrameLine';
import Spinner from '../../../../components/atoms/Icon/Spinner';

// model
import { AuditRuleImportViewModel } from '../../../../models/auditRuleImportViewModel';
import { AuditRuleImportResultViewModel } from '../../../../models/auditRuleImportResultViewModel';

type RuleImportProps = {
  activated: boolean;
  onClose: () => void;
};

// URL
const baseUrl = '/api/v1/AuditRuleImport';
export const importUrl = [baseUrl, 'Import'].join('/');
export const ImportResultUrl = [baseUrl, 'ImportResult'].join('/');

// 項目ラベルのCSS Class
const labelClass = 'text-lbl';
// バリデートエラーメッセージのCSS Class
const validatErrClass = 'ml-3 text-danger';

// インポート可能なファイル拡張子
const fileAccept = constant.AUDITRULEIMPORTEXTENSION.split(',')
  .map(extension => '.' + extension)
  .join(',');

// tabIndexの初期値
const baseTabIndex = 1000;

// 初期表示用のViewModel
const initializationViewModel: AuditRuleImportViewModel = {
  File: '',
  FileName: '',
  Rule: dataMaps.ImportRuleEnum.map(item => item.key),
  ImportMethod: undefined
};

const RuleImport: React.FC<RuleImportProps> = props => {
  const [isImporting, setIsImporting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [viewModel, setViewModel] = React.useState<AuditRuleImportViewModel>(initializationViewModel);
  const [resultMessage, setResultMessage] = React.useState<React.ReactElement[]>([]);

  /** インポート処理を実行 */
  const createOnPost = (url: string, vm: AuditRuleImportViewModel) => {
    Axios.post<string>(url, vm)
      .then(response => {
        fileResultOnPost(ImportResultUrl, { Guid: response.data });
      })
      .catch(error => {
        setIsImporting(false);
        setErrorMessage(error.response.data.message);
      });
  };

  /** バッチの進捗確認を実行 */
  const fileResultOnPost = (url: string, vm: AuditRuleImportResultViewModel) => {
    Axios.post<AuditRuleImportResultViewModel>(url, vm)
      .then(response => {
        if (
          response.data.BatStatus == enums.BatStatusEnum.Processing ||
          response.data.BatStatus == enums.BatStatusEnum.Waiting
        ) {
          window.setTimeout(fileResultOnPost, constant.PROGRESSINTERVAL, url, vm);
        } else {
          setIsImporting(false);
          const resultMessage: React.ReactElement[] = [];
          if (response.data.ResultMess != null) {
            // 1行単位でメッセージを確認する
            response.data.ResultMess.split('\n').forEach((item: string, i: number) => {
              // 文字色を変更する場合はカンマ区切りで色情報が来るため
              // 分割して文字色を変更する
              const items = item.split(',');
              const className = items.length < 2 ? '' : 'text-' + items[1];
              resultMessage.push(
                <div key={'ResultMess-' + i} className={className}>
                  {items[0]}
                </div>
              );
            });
          }
          setResultMessage(resultMessage);
        }
      })
      .catch(error => {
        setErrorMessage(error.response.data.message);
        setIsImporting(false);
      });
  };

  /** 取込ファイル変更時の処理 */
  const handleOnImportFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    if (target.files && target.files.length > 0) {
      const file = target.files[0];

      // ファイルをbase64に変換
      const reader = new FileReader();
      reader.onload = e => {
        setViewModel({
          ...viewModel,
          File: String(reader.result).replace(/^.*base64,/, ''),
          FileName: file.name
        });
      };
      reader.readAsDataURL(file);
    } else {
      // ファイルが選択されなかった場合
      setViewModel({ ...viewModel, File: '', FileName: '' });
    }
  };

  /** 取込ファイル押下時の処理 */
  const handleOnImportFileClick = (e: any) => {
    const target = e.currentTarget;
    target.value = '';
  };

  /** 取込ファイルドロップ時の処理 */
  const handleOnImportFileDrop = (e: any) => {
    const target = e.currentTarget;
    target.value = '';
  };

  /** 閉じるボタン押下時の処理 */
  const handleOnCloseClick = () => {
    if (!isImporting) {
      props.onClose();
    }
  };

  /** 出力ボタン押下時の処理 */
  const handleOnImportClick = () => {
    // 入力項目のエラーチェック
    if (!validationCheck(viewModel)) {
      setResultMessage([]);
      setErrorMessage('');
      setIsImporting(true);
      createOnPost(importUrl, viewModel);
    }
  };

  /** バリデーションチェック */
  const validationCheck = (vm: AuditRuleImportViewModel) => {
    // ファイルチェック
    const fileErr = fileValidationCheck(vm);

    // 取込ルールチェック
    const ruleErr = ruleValidationCheck(vm);

    // インポート方法チェック
    const importMethodErr = importMethodValidationCheck(vm);

    return fileErr || ruleErr || importMethodErr;
  };

  /** ファイルチェック */
  const [fileErrMsg, setFileErrMsg] = React.useState('');
  const fileValidationCheck = (vm: AuditRuleImportViewModel) => {
    // ファイルの存在チェック
    if (vm.FileName == undefined || vm.FileName === '') {
      setFileErrMsg(
        messageUtils.bindValueToMessage(message.Common_Error_Required_Empty, [
          labels.AIKCMN001000005_INPUT_HEADER_IMPORTFILE
        ])
      );
      return true;
    }

    // 拡張子チェック
    const extension = vm.FileName.split('.').pop();
    if (
      extension == undefined ||
      extension === vm.FileName ||
      constant.AUDITRULEIMPORTEXTENSION.split(',').indexOf(extension) < 0
    ) {
      setFileErrMsg(message.RuleImport_Error_Extension);
      return true;
    }
    setFileErrMsg('');
    return false;
  };

  /** 取込ルールチェック */
  const [ruleErrMsg, setRuleErrMsg] = React.useState('');
  const ruleValidationCheck = (vm: AuditRuleImportViewModel) => {
    if (vm.Rule == undefined || vm.Rule.length < 1) {
      setRuleErrMsg(
        messageUtils.bindValueToMessage(message.Common_Error_Required_Empty, [
          labels.AIKCMN001000005_INPUT_HEADER_IMPORTRULE
        ])
      );
      return true;
    }
    setRuleErrMsg('');
    return false;
  };

  /** インポート方法チェック */
  const [importMethodErrMsg, setImportMethodErrMsg] = React.useState('');
  const importMethodValidationCheck = (vm: AuditRuleImportViewModel) => {
    if (vm.ImportMethod == undefined) {
      setImportMethodErrMsg(
        messageUtils.bindValueToMessage(message.Common_Error_Required_Empty, [
          labels.AIKCMN001000005_INPUT_HEADER_IMPORTMETHOD
        ])
      );
      return true;
    }
    setImportMethodErrMsg('');
    return false;
  };

  /** バリデーションチェックエラーメッセージをクリアする */
  const resetValidationErrMsg = () => {
    setFileErrMsg('');
    setRuleErrMsg('');
    setImportMethodErrMsg('');
  };

  /** 取込ルール変更時の処理 */
  const handleOnRuleChange = (importRule: enums.ImportRuleEnum) => {
    const rule = viewModel.Rule == undefined ? [] : viewModel.Rule.slice();
    const index = rule.indexOf(importRule);
    if (index < 0) {
      rule.push(importRule);
    } else {
      rule.splice(index, 1);
    }

    setViewModel({
      ...viewModel,
      Rule: rule
    });
  };
  // 取込ルールコンポーネント
  const ruleList: React.ReactElement[] = [];
  dataMaps.ImportRuleEnum.forEach(data => {
    const checked = viewModel.Rule == undefined ? false : viewModel.Rule.filter(rule => rule === data.key).length > 0;
    ruleList.push(
      <FormGroup check className='mr-3' key={[data.key, 'Rule'].join('-')}>
        <Label check>
          <Input
            type='checkbox'
            name='Rule'
            value={data.key}
            checked={checked}
            disabled={isImporting}
            tabIndex={baseTabIndex + 2}
            onChange={() => {
              handleOnRuleChange(data.key);
            }}
          />
          {data.value}
        </Label>
      </FormGroup>
    );
  });

  /** インポート方法変更時の処理 */
  const handleOnImportMethodChange = (importMethod: enums.ImportMethodEnum) => {
    setViewModel({ ...viewModel, ImportMethod: importMethod });
  };
  // インポート方法コンポーネント
  const importMethodList: React.ReactElement[] = [];
  dataMaps.ImportMethodEnum.forEach(data => {
    const checked = viewModel.ImportMethod === data.key;
    importMethodList.push(
      <FormGroup check className='mr-3' key={[data.key, 'importMethod'].join('-')}>
        <Label check>
          <Input
            type='radio'
            name='ImportMethod'
            checked={checked}
            disabled={isImporting}
            tabIndex={baseTabIndex + 3}
            onChange={() => {
              handleOnImportMethodChange(data.key);
            }}
          />
          {data.value}
        </Label>
      </FormGroup>
    );
  });

  const printingLabel = isImporting ? (
    <span>
      <span className='_spinner _rotation mr-2'>
        <Spinner className='icon-lg' />
      </span>
      {labels.AIKCMN001000005_FUNCTION_NAME_ININPUT}
    </span>
  ) : errorMessage.length > 0 ? (
      <span className='text-danger'>{errorMessage}</span>
  ) :
  (
      <label className='white-space-pre-wrap text-danger'>{labels.AIKCMN001000005_LABEL_ALERT}</label>
  );

  const firstFocusElement = React.useRef<any>();
  const lastFocusElement = React.useRef<any>();
  const popupRef = React.useRef<any>();

  /** キーボード操作（キーダウン） */
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 9) {
      if (event.shiftKey === false) {
        // タブキー押下時

        const target: HTMLElement = event.target as HTMLElement;

        if (target.tabIndex === lastFocusElement.current.tabIndex) {
          // 最後のフォーカスエレメントの場合

          // 最初のエレメントにフォーカス
          firstFocusElement.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        } else if (
          target.tabIndex < firstFocusElement.current.tabIndex ||
          target.tabIndex > lastFocusElement.current.tabIndex
        ) {
          // フォーカスが当たっていない場合

          // 最初のエレメントにフォーカス
          firstFocusElement.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
      } else {
        // シフト＋タブキー押下時

        const target: HTMLElement = event.target as HTMLElement;
        if (target.tabIndex === firstFocusElement.current.tabIndex) {
          // 最後のフォーカスエレメントの場合

          // 最後のエレメントにフォーカス
          lastFocusElement.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        } else if (
          target.tabIndex < firstFocusElement.current.tabIndex ||
          target.tabIndex > lastFocusElement.current.tabIndex
        ) {
          // フォーカスが当たっていない場合

          // 最後のエレメントにフォーカス
          lastFocusElement.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }
  };

  /** 画面が表示された実行する処理 */
  React.useEffect(() => {
    if (props.activated) {
      resetValidationErrMsg();
      setResultMessage([]);
      setIsImporting(false);
      setViewModel(initializationViewModel);
      setErrorMessage('');
      // 画面表示完了後に実行
      setTimeout(() => {
        if (popupRef.current) {
          // ポップアップにキーダウンイベントのフック
          popupRef.current.addEventListener('keydown', handleKeyDown);
        }
      });
    }
  }, [props.activated]);

  return (
    <Popup
      className='rule-import'
      isOpen={props.activated}
      escClose={!isImporting}
      onCloseClick={handleOnCloseClick}
      header={labels.AIKCMN001000005_IMPORT_HEADER_NAME}
      size='lg'
      closeIconTabIndex={baseTabIndex + 6}
      closeIconRef={lastFocusElement}
      innerRef={popupRef}
    >
      <FormGroup>
        <Label>
          <b className={labelClass}>{labels.AIKCMN001000005_INPUT_HEADER_IMPORTFILE}</b>
          <span className={validatErrClass}>{fileErrMsg}</span>
        </Label>
        <Input
          type='file'
          name='File'
          accept={fileAccept}
          onChange={handleOnImportFileChange}
          onClick={handleOnImportFileClick}
          onDrop={handleOnImportFileDrop}
          disabled={isImporting}
          innerRef={firstFocusElement}
          tabIndex={baseTabIndex + 1}
        />
      </FormGroup>
      <FormGroup>
        <Label>
          <b className={labelClass}>{labels.AIKCMN001000005_INPUT_HEADER_IMPORTRULE}</b>
          <span className={validatErrClass}>{ruleErrMsg}</span>
        </Label>
        <div className='d-flex flex-wrap'>{ruleList}</div>
      </FormGroup>
      <FormGroup>
        <Label>
          <b className={labelClass}>{labels.AIKCMN001000005_INPUT_HEADER_IMPORTMETHOD}</b>
          <span className={validatErrClass}>{importMethodErrMsg}</span>
        </Label>
        <div className='d-flex flex-wrap'>{importMethodList}</div>
      </FormGroup>
      <FormGroup>
        <Label>
          <b className={labelClass}>{labels.AIKCMN001000005_INPUT_HEADER_IMPORTRESULTMESS}</b>
        </Label>
        <AngleCircleFrameLine className='result-message px-1 py-1 overflow-auto'>{resultMessage}</AngleCircleFrameLine>
      </FormGroup>
      <div className='d-flex justify-content-between'>
        <div>{printingLabel}</div>
        <div>
          <ExecutingCancel
            className='text-nowrap'
            executeLabel={labels.AIKCMN001000005_BUTTON_FUNCTION_INPUT}
            onExecuteClick={handleOnImportClick}
            executeDisabled={isImporting}
            executeTabIndex={baseTabIndex + 4}
            cancelLabel={labels.AIKCMN001000005_BUTTON_FUNCTION_CLOSE}
            onCancelClick={handleOnCloseClick}
            cancelDisabled={isImporting}
            cancelTabIndex={baseTabIndex + 5}
          />
        </div>
      </div>
    </Popup>
  );
};

export default RuleImport;
