export interface MjsDelegate<TParam, TReturn> {
    (args: TParam): TReturn;
}

export class MjsDelegateContainer<TParam, TReturn> {
    private _dlgt: MjsDelegate<TParam, TReturn>;
    private _self: any;

    constructor(dlgt: MjsDelegate<TParam, TReturn>, self: any) {
        this._dlgt = dlgt;
        this._self = self;
    }

    get dlgt(): MjsDelegate<TParam, TReturn> {
        return this._dlgt;
    }

    get self(): any {
        return this._self;
    }

    public invoke(arg: TParam): TReturn {
        return this.dlgt.call(this.self, arg);
    }
}
