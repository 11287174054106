/**
 * 月選択VO ViewModel クラス
 */

export class MonthSelectVO {
    /** 内部月 */
    public NMonth?:number;

    /** 月名称 */
    public MonthName?:string;

}
