import { InterimClosingType, MonthType } from "../defines";
import { DateRange } from "./utilities";
import $ from 'jquery';
import "./utilities";

export interface MonthInfo {
    StrDate: Date;
    EndDate: Date;
    NMonth: number;
}

/**
 * 月管理クラス
 * 【クラス内で使用する用語】
 *   ・標準月：1～12で表現される、一般的な月
 *   ・外部月：標準月である1～12にくわえて、2x、8x、3x、9x（xは0～3）で各種決算を示すことのできる、システム独自表現の月
 *   ・内部月：標準月/外部月の順列位置に対応する値として示される、システム独自表現の月
 *
 *   ■例：期末月が3月の場合の、月対応マップ
 *     標準月：4,  5,  6,                  7,  8,  9,                 10, 11, 12,                  1,  2,  3
 *     外部月：4,  5,  6, 20, 21, 22, 23,  7,  8,  9, 80, 81, 82, 83, 10, 11, 12, 30, 31, 32, 33,  1,  2,  3, 90, 91, 92, 93
 *     内部月：1,  2,  3,  4,  5,  6,  7, 11, 12, 13, 14, 15, 16, 17, 21, 22, 23, 24, 25, 26, 27, 31, 32, 33, 34, 35, 36, 37
 * 
 *     ※ 上記マップのうち、'標準月'と'外部月'は期末月によって順列が変化する。'内部月'は常に上記順列で固定。
 */
export class MonthManager {

    private static INNER_MONTHS: Array<number> = [1, 2, 3, 4, 5, 6, 7, 11, 12, 13, 14, 15, 16, 17, 21, 22, 23, 24, 25, 26, 27, 31, 32, 33, 34, 35, 36, 37];
    private mOuterMonths: Array<number>;
    private mStandardMonths: Array<number>;
    private mClosingMonths: Array<number>;
    private mDateRanges: Array<DateRange>;
    private mOpeningDate: Date; // 期首日
    private mClosingDate: Date; // 期末日
    private mMonthStartDay: number;
    //private mIsClosingLastDay: boolean; // 期末日が月末を指す場合はtrue
    private mInterimClosingType: InterimClosingType; // 中間決算区分

    constructor(closingDate: Date, monthStartDay: number, interimClosingType: InterimClosingType, monthInfoList: Array<MonthInfo>) {
        this.mOpeningDate = closingDate.clone();
        this.mOpeningDate.setDate(this.mOpeningDate.getDate() + 1);
        this.mOpeningDate.setFullYear(this.mOpeningDate.getFullYear() - 1);
        //this.mOpeningDate.setFullYear(closingDate.getFullYear() - 1);
        //if (closingDate.getMonth() == 1 && closingDate.getDate() == 29) {
        //    this.mOpeningDate.setDate(1);
        //} else {
        //    this.mOpeningDate.setDate(closingDate.getDate() + 1);
        //}
        this.mClosingDate = closingDate;
        this.mMonthStartDay = monthStartDay;
        this.mInterimClosingType = interimClosingType;
        //this.mIsClosingLastDay = new Date(closingDate.getFullYear(), closingDate.getMonth() + 1, 0).getDate() == closingDate.getDate();
        this.mStandardMonths = this.createStandardMonthMap(monthInfoList);
        this.mOuterMonths = this.createOuterMonthMap(closingDate);
        this.mClosingMonths = this.createCloseMonths(closingDate);
        this.mDateRanges = this.createDateRanges(closingDate, monthInfoList);
    }

    /**
     * 日付範囲群を取得する
     */
    public get dateRanges(): Array<DateRange> {
        return this.mDateRanges;
    }

    /**
     * 外部月（もしくは標準月）に対応する内部月を取得する
     * @param outerMonth
     */
    public toInnerMonth(outerMonth: number) {
        return MonthManager.INNER_MONTHS[this.mOuterMonths.positionOrNull((m: number) => m == outerMonth)];
    }

    /**
     * 内部月に対応する外部月を取得する
     * @param innerMonth
     */
    public getOuterMonths(): Array<number> {
        return this.mOuterMonths;
    }

    /**
    * 外部月を取得する
    */
    public toOuterMonth(innerMonth: number) {
        return this.mOuterMonths[MonthManager.INNER_MONTHS.positionOrNull((m: number) => m == innerMonth)];
    }

    /**
     * 使用非推奨。外部月が決算月の場合のみ可。外部月に対応する標準月を取得する。外部月が各種決算月を示している場合は、期末日をもとに解釈された標準月が取得される。（期末が12月の場合、外部月として91が指定されたなら12を、31が指定されたなら9を取得する）
     * @param outerMonth
     */
    public toStandardMonthFromOuter(outerMonth: number): number {
        var standardMonth: number;
        switch (outerMonth) {
            // 第一
            case 20:
            case 21:
            case 22:
            case 23:
                standardMonth = this.mStandardMonths[2];
                break;

            // 中間
            case 80:
            case 81:
            case 82:
            case 83:
                standardMonth = this.mStandardMonths[5];
                break;

            // 第三
            case 30:
            case 31:
            case 32:
            case 33:
                standardMonth = this.mStandardMonths[8];
                break;

            // 年度末
            case 90:
            case 91:
            case 92:
            case 93:
                standardMonth = this.mStandardMonths[11];
                break;

            default:
                standardMonth = outerMonth;
                break;
        }
        return standardMonth;
    }

    /**
     * 指定された外部月が標準月(1～12)かどうか問い合わせる
     * @param outerMonth
     * @return true: 標準月、false: 特殊月
     */
    public isStandardMonthFromOuter(outerMonth: number): boolean {
        let stdmonth: number = this.toStandardMonthFromOuter(outerMonth);
        return outerMonth == stdmonth;
    }

    /**
     * 使用非推奨。内部月が決算月の場合のみ可。内部月に対応する標準月を取得する。内部月が各種決算月を示している場合は、期末日をもとに解釈された標準月が取得される。（期末が12月の場合、内部月として35が指定されたなら12を、25が指定されたなら9を取得する）
     * @param innerMonth
     */
    public toStandardMonthFromInner(innerMonth: number): number {
        var outerMonth = this.toOuterMonth(innerMonth);
        return this.toStandardMonthFromOuter(outerMonth);
    }

    /**
     * 外部月に対応する月種別列挙体を取得する。
     * @param innerMonth
     */
    public toMonthTypeFromOuter(outerMonth: number): MonthType {

        // outerMonth→innerMonthに変換する
        let innerMonth: number = this.toInnerMonth(outerMonth);

        // 変換したinnerMonthから、MonthTypeを取得する
        return this.toMonthTypeFromInner(innerMonth);
    }

    /**
     * MonthSelector専用。内部月に対応する月種別列挙体を取得する。
     * @param innerMonth
     */
    public toMonthTypeFromInner(innerMonth: number): MonthType {
        var outerMonth = this.toOuterMonth(innerMonth);
        var monthType: MonthType = undefined!;
        switch (outerMonth) {
            case 1:
                monthType = MonthType.M1;
                break;
            case 2:
                monthType = MonthType.M2;
                break;
            case 3:
                monthType = MonthType.M3;
                break;
            case 4:
                monthType = MonthType.M4;
                break;
            case 5:
                monthType = MonthType.M5;
                break;
            case 6:
                monthType = MonthType.M6;
                break;
            case 7:
                monthType = MonthType.M7;
                break;
            case 8:
                monthType = MonthType.M8;
                break;
            case 9:
                monthType = MonthType.M9;
                break;
            case 10:
                monthType = MonthType.M10;
                break;
            case 11:
                monthType = MonthType.M11;
                break;
            case 12:
                monthType = MonthType.M12;
                break;
            case 20:
                monthType = MonthType.AfterCloseQuarter1;
                break;
            case 21:
            case 22:
            case 23:
                monthType = MonthType.Quarter1;
                break;
            case 80:
                monthType = MonthType.AfterCloseQuarter2;
                break;
            case 81:
            case 82:
            case 83:
                monthType = MonthType.Quarter2;
                break;
            case 30:
                monthType = MonthType.AfterCloseQuarter3;
                break;
            case 31:
            case 32:
            case 33:
                monthType = MonthType.Quarter3;
                break;
            case 90:
                monthType = MonthType.AfterCloseQuarter4;
                break;
            case 91:
            case 92:
            case 93:
                monthType = MonthType.Closing;
                break;
        }

        return monthType;
    }

    /**
     * MonthSelector専用。月種別に対応する内部月を取得する。
     * @param monthType
     */
    public toInnerMonthFromMonthType(monthType: MonthType, closingOptionValues: Array<number> = null!): number {
        var innerMonth: number = undefined!;
        switch (monthType) {
            case MonthType.M1:
                innerMonth = this.toInnerMonth(1);
                break;
            case MonthType.M2:
                innerMonth = this.toInnerMonth(2);
                break;
            case MonthType.M3:
                innerMonth = this.toInnerMonth(3);
                break;
            case MonthType.M4:
                innerMonth = this.toInnerMonth(4);
                break;
            case MonthType.M5:
                innerMonth = this.toInnerMonth(5);
                break;
            case MonthType.M6:
                innerMonth = this.toInnerMonth(6);
                break;
            case MonthType.M7:
                innerMonth = this.toInnerMonth(7);
                break;
            case MonthType.M8:
                innerMonth = this.toInnerMonth(8);
                break;
            case MonthType.M9:
                innerMonth = this.toInnerMonth(9);
                break;
            case MonthType.M10:
                innerMonth = this.toInnerMonth(10);
                break;
            case MonthType.M11:
                innerMonth = this.toInnerMonth(11);
                break;
            case MonthType.M12:
                innerMonth = this.toInnerMonth(12);
                break;
            case MonthType.AfterCloseQuarter1:
                innerMonth = this.toInnerMonth(20);
                break;
            case MonthType.Quarter1:
                innerMonth = this.toInnerMonth((20 + parseInt(closingOptionValues[0].toString())));
                break;
            case MonthType.AfterCloseQuarter2:
                innerMonth = this.toInnerMonth(80);
                break;
            case MonthType.Quarter2:
                innerMonth = this.toInnerMonth((80 + parseInt(closingOptionValues[1].toString())));
                break;
            case MonthType.AfterCloseQuarter3:
                innerMonth = this.toInnerMonth(30);
                break;
            case MonthType.Quarter3:
                innerMonth = this.toInnerMonth((30 + parseInt(closingOptionValues[2].toString())));
                break;
            case MonthType.AfterCloseQuarter4:
                innerMonth = this.toInnerMonth(90);
                break;
            case MonthType.Closing:
                innerMonth = this.toInnerMonth((90 + parseInt(closingOptionValues[3].toString())));
                break;
        }

        return innerMonth;
    }

    /**
     * 指定内部月内の指定日付に該当するDateを取得する。(現在、yearは無視されます)
     * 年月日が不正な値（内部月内に指定日付が存在しない、日付に変換できない）の場合はnullが取得される。
     * @param year: 年
     * @param innerMonth: 内部月
     * @param day: 日
     */
    public getStandardDateFromInner(year: number, innerMonth: number, day: number): Date {

        //var standardMonth = this.toStandardMonthFromInner(innerMonth);
        //var monthLastDay = new Date(year, standardMonth, 0).getDate(); // 月末日

        //var tmpDay;
        //if ([5, 6, 7, 15, 16, 17, 25, 26, 27, 35, 36, 37].any(m => m == innerMonth)) {
        //    if (this.mIsClosingLastDay) {
        //        tmpDay = monthLastDay;
        //    } else {
        //        tmpDay = this.mClosingDate.getDate();
        //    }
        //} else {
        //    if (day < 1 || day > monthLastDay) {
        //        return null;
        //    }
        //    tmpDay = day;
        //}

        //return new Date(year, standardMonth - 1, tmpDay);

        var innerMonthDateRange = this.dateRanges.singleOrNull((range: DateRange) => { return range.innerMonth == innerMonth });
        if (!innerMonthDateRange) {
            return null!;
        }
        //if (year < innerMonthDateRange.from.getFullYear() || innerMonthDateRange.to.getFullYear() < year) {
        //    return null;
        //}
        var tmpDate = innerMonthDateRange.from.clone();

        var resultDate: Date = null!;
        for (let i = 0; i < 31; i++) {
            if (tmpDate.getDate() == day) {
                resultDate = tmpDate.clone();
                break;
            }
            if (tmpDate.getTime() >= innerMonthDateRange.to.getTime()) {
                break;
            }
            tmpDate.setDate(tmpDate.getDate() + 1);
        }
        if (resultDate != null) {
            if ((resultDate < innerMonthDateRange.from) || (innerMonthDateRange.to < resultDate)) {
                resultDate = null!;
            }
        }

        return resultDate;
    }

    /**
     * 指定外部月内の指定日付に該当するDateを取得する。(現在、yearは無視されます)
     * 年月日が不正な値（外部月内に指定日付が存在しない、日付に変換できない）の場合はnullが取得される。
     * @param year: 年
     * @param outerMonth: 外部月
     * @param day: 日
     */
    public getStandardDateFromOuter(year: number, outerMonth: number, day: number): Date {
        return this.getStandardDateFromInner(year, this.toInnerMonth(outerMonth), day);
    }

    /**
     * 使用非推奨。外部月で示される締後月(20 or 80 or 30 or 90)に対応する標準月を取得する
     */
    public getStandardMonthByAfterCloseOuterMonth(closeOuterMonth: number): number {
        var pos = [20, 80, 30, 90].positionOrNull((m: number) => m == closeOuterMonth);
        if (pos == null) {
            // パラメータ不正
            throw Error("argument is invalid: closeOuterMonth[" + closeOuterMonth + "]");
        }
        return this.mClosingMonths[pos];
    }

    /**
     * 外部月が締後月を示すかどうかを取得する
     * @param outerMonth
     */
    public isAfterCloseOuterMonth(outerMonth: number) {
        return [20, 80, 30, 90].any((m: number) => m == outerMonth);
    }

    /**
     * 外部月が決算月を示すかどうかを取得する
     * @param outerMonth
     */
    public isClosingOuterMonth(outerMonth: number) {
        return [21, 22, 23, 81, 82, 83, 31, 32, 33, 91, 92, 93].any((m: number) => m == outerMonth);
    }

    /**
     * 入力された外部月が、特殊月(決算系/締後月系)かどうかを返す。
     * @param outerMonth
     */
    public isSpecialOuterMonth(outerMonth: number) {
        return this.isAfterCloseOuterMonth(outerMonth) || this.isClosingOuterMonth(outerMonth);
    }

    /**
     * 外部月が第１四半期決算を示すかどうかを取得する
     * @param outerMonth
     * @param hasClosingOption
     */
    public isFirstOuterMonth(outerMonth: number, hasClosingOption: boolean): boolean {
        if (hasClosingOption) {
            return [21, 22, 23].any((m: number) => m == outerMonth);
        } else {
            return [21].any((m: number) => m == outerMonth);
        }
    }

    /**
     * 外部月が第2四半期(中間決算)を示すかどうかを取得する
     * @param outerMonth
     * @param hasClosingOption
     */
    public isSecondOuterMonth(outerMonth: number, hasClosingOption: boolean): boolean {
        if (hasClosingOption) {
            return [81, 82, 83].any((m: number) => m == outerMonth);
        } else {
            return [81].any((m: number) => m == outerMonth);
        }
    }

    /**
     * 外部月が第3四半期決算を示すかどうかを取得する
     * @param outerMonth
     * @param hasClosingOption
     */
    public isThirdOuterMonth(outerMonth: number, hasClosingOption: boolean): boolean {
        if (hasClosingOption) {
            return [31, 32, 33].any((m: number) => m == outerMonth);
        } else {
            return [31].any((m: number) => m == outerMonth);
        }
    }

    /**
     * 外部月が第4四半期(決算)を示すかどうかを取得する
     * @param outerMonth
     * @param hasClosingOption
     */
    public isFourthOuterMonth(outerMonth: number, hasClosingOption: boolean): boolean {
        if (hasClosingOption) {
            return [91, 92, 93].any((m: number) => m == outerMonth);
        } else {
            return [91].any((m: number) => m == outerMonth);
        }
    }

    /**
     * 内部月に対応した、日付範囲を示すオブジェクトを取得する
     * @param innerMonth
     */
    public getDateRangeFromInner(innerMonth: number): DateRange {
        return this.dateRanges.singleOrNull((r: DateRange) => r.innerMonth == innerMonth);
    }

    /**
     * 外部月に対応した、日付範囲を示すオブジェクトを取得する
     * @param outerMonth
     */
    public getDateRangeFromOuter(outerMonth: number): DateRange {
        return this.dateRanges.singleOrNull((r: DateRange) => r.outerMonth == outerMonth);
    }

    /**
     * 指定したDate型の値から、対応するDateRange型を返す。
     * @param outerMonth
     */
    public getDateRangeFromDate(date: Date): DateRange[] {
        return this.dateRanges.where(r => r.from <= date && r.to >= date);
    }

    private createOuterMonthMap(closingDate: Date): Array<number> {

        var to = this.mOpeningDate.clone();

        if (this.mMonthStartDay == this.mOpeningDate.getDate()) {
            to.setMonth(to.getMonth() + 1);
            to.setDate(to.getDate() - 1);
        } else {
            if (this.mMonthStartDay == 1) {
                to.setDate(new Date(to.getFullYear(), to.getMonth() + 1, 0).getDate());
            } else {
                to.setDate(this.mMonthStartDay - 1);
                if (this.mOpeningDate.getDate() > this.mMonthStartDay || to.getTime() < this.mOpeningDate.getTime()) {
                    to.setMonth(to.getMonth() + 1);
                }
            }
        }
        var currentMonth: number = to.getMonth() + 1;

        var map = [];
        for (let i = 0; i < 4; i++) {

            for (let j = 0; j < 3; j++) {
                map.push(currentMonth);
                currentMonth++;
                if (currentMonth == 13) {
                    currentMonth = 1;
                }
            }

            switch (i) {
                case 0:
                    map.push(20, 21, 22, 23);
                    break;
                case 1:
                    map.push(80, 81, 82, 83);
                    break;
                case 2:
                    map.push(30, 31, 32, 33);
                    break;
                case 3:
                    map.push(90, 91, 92, 93);
                    break;
            }
        }
        return map;
    }

    private createStandardMonthMap(monthInfoList: Array<MonthInfo>): Array<number> {
        //var tmp = closingDate.clone();
        //tmp.setDate(tmp.getDate() + 1);
        //tmp.setMonth(tmp.getMonth() + 1);

        //var currentMonth: number = tmp.getMonth();
        //if (currentMonth == 13) {
        //    currentMonth = 1;
        //}

        //var map = [];
        //for (let i = 0; i < 12; i++) {
        //    map.push(currentMonth);
        //    currentMonth++;
        //    if (currentMonth == 13) {
        //        currentMonth = 1;
        //    }
        //}
        //return map;

        var map = [];
        map.push(monthInfoList.singleOrNull((info: MonthInfo) => { return info.NMonth == 1 }).EndDate.getMonth() + 1)
        map.push(monthInfoList.singleOrNull((info: MonthInfo) => { return info.NMonth == 2 }).EndDate.getMonth() + 1)
        map.push(monthInfoList.singleOrNull((info: MonthInfo) => { return info.NMonth == 3 }).EndDate.getMonth() + 1)
        map.push(monthInfoList.singleOrNull((info: MonthInfo) => { return info.NMonth == 11 }).EndDate.getMonth() + 1)
        map.push(monthInfoList.singleOrNull((info: MonthInfo) => { return info.NMonth == 12 }).EndDate.getMonth() + 1)
        map.push(monthInfoList.singleOrNull((info: MonthInfo) => { return info.NMonth == 13 }).EndDate.getMonth() + 1)
        map.push(monthInfoList.singleOrNull((info: MonthInfo) => { return info.NMonth == 21 }).EndDate.getMonth() + 1)
        map.push(monthInfoList.singleOrNull((info: MonthInfo) => { return info.NMonth == 22 }).EndDate.getMonth() + 1)
        map.push(monthInfoList.singleOrNull((info: MonthInfo) => { return info.NMonth == 23 }).EndDate.getMonth() + 1)
        map.push(monthInfoList.singleOrNull((info: MonthInfo) => { return info.NMonth == 31 }).EndDate.getMonth() + 1)
        map.push(monthInfoList.singleOrNull((info: MonthInfo) => { return info.NMonth == 32 }).EndDate.getMonth() + 1)
        map.push(monthInfoList.singleOrNull((info: MonthInfo) => { return info.NMonth == 33 }).EndDate.getMonth() + 1)
        return map;
    }

    private createCloseMonths(closingDate: Date): Array<number> {
        var map = [];
        for (let i = 3; i >= 0; i--) {
            var month = (closingDate.getMonth() + 1) - (3 * i);
            if (month <= 0) {
                month += 12
            }
            map.push(month);
        }
        return map;
    }

    /**
     * MonthInfo の内容をもとに 月度管理情報を生成する
     * @param closingDate
     * @param monthInfoList
     */
    private createDateRanges(closingDate: Date, monthInfoList: Array<MonthInfo>): Array<DateRange> {
        if (!monthInfoList || !monthInfoList.any((month: MonthInfo) => month.NMonth === 1)) {
            return this.createDateRangesFromClosingDate(closingDate);
        }

        let map: Array<DateRange> = [];
        let innerMonths = [1, 2, 3, 4, 5, 6, 7, 11, 12, 13, 14, 15, 16, 17, 21, 22, 23, 24, 25, 26, 27, 31, 32, 33, 34, 35, 36, 37];

        $.each(innerMonths, (idx, innerMonth) => {
            let from: Date;
            let to: Date;

            let monthInfo = monthInfoList.singleOrNull((month: MonthInfo) => { return month.NMonth === innerMonth });
            if (monthInfo) {
                from = monthInfo.StrDate.clone();
                to = monthInfo.EndDate.clone();
            } else {
                from = map[idx - 1].from.clone();
                to = map[idx - 1].to.clone();
            }

            let outerMonth = this.toOuterMonth(innerMonth);
            map.push(new DateRange(innerMonth, outerMonth, from, to));
        });

        return map;
    }

    /**
     * 決算日付から月度管理情報を生成する。基本的には使用しない。
     * @param closingDate
     */
    private createDateRangesFromClosingDate(closingDate: Date): Array<DateRange> {
        var map: Array<DateRange> = [];
        var innerMonths = [1, 2, 3, 4, 5, 6, 7, 11, 12, 13, 14, 15, 16, 17, 21, 22, 23, 24, 25, 26, 27, 31, 32, 33, 34, 35, 36, 37];
        //var year = closingDate.getMonth() == 11 && closingDate.getDate() == 31 ? closingDate.getFullYear() : closingDate.getFullYear() - 1;
        var isClosingLastDay = this.getLastDayOfMonth(closingDate.getFullYear(), closingDate.getMonth()) == closingDate.getDate();

        $.each(innerMonths, (idx, innerMonth) => {
            let from: Date = undefined!;
            let to: Date = undefined!;
            if ([1, 11, 21, 31].any((m: number) => m == innerMonth)) { // 決算開始月の場合
                if (innerMonth == 1) {
                    from = this.mOpeningDate;
                } else {
                    if (this.mInterimClosingType == InterimClosingType.Quarter) {
                        from = map[idx - 4].to.clone();
                        from.setDate(from.getDate() + 1);
                    } else if (this.mInterimClosingType == InterimClosingType.Interim && innerMonth == 21) {
                        from = map[idx - 4].to.clone();
                        from.setDate(from.getDate() + 1);
                    } else {
                        from = map[idx - 5].to.clone();
                        from.setDate(from.getDate() + 1);
                    }
                    //from = map[idx - 4].to.clone();
                    //from.setDate(from.getDate() + 1);
                }

                to = from.clone();

                if (this.mMonthStartDay == this.mOpeningDate.getDate()) {
                    to.setMonth(to.getMonth() + 1);
                    to.setDate(to.getDate() - 1);
                } else {
                    if (this.mMonthStartDay == 1) {
                        to.setDate(new Date(to.getFullYear(), to.getMonth() + 1, 0).getDate());
                    } else {
                        to.setDate(this.mMonthStartDay - 1);
                        if (this.mOpeningDate.getDate() > this.mMonthStartDay || to.getTime() < from.getTime()) {
                            to.setMonth(to.getMonth() + 1);
                        }
                    }
                }
            } else if ([4, 14, 24, 34].any((m: number) => m == innerMonth)) { // 締後の場合
                if (this.mMonthStartDay == this.mOpeningDate.getDate()) {
                    from = map[idx - 1].from.clone();
                    to = map[idx - 1].to.clone();
                } else {
                    from = map[idx - 1].to.clone();
                    from.setDate(from.getDate() + 1);
                    to = from.clone();
                    let tmpToMonth = to.getMonth();
                    if (this.mMonthStartDay == 1) {
                        if (this.getLastDayOfMonth(to.getFullYear(), to.getMonth()) <= closingDate.getDate()) {
                            to.setDate(this.getLastDayOfMonth(to.getFullYear(), to.getMonth()) - 1);
                        } else {
                            to.setDate(closingDate.getDate());
                        }
                        //to.setDate(closingDate.getDate());
                        //to.setDate(this.getLastDayOfMonth(to.getFullYear(), to.getMonth()) - 1);
                    }
                    else {
                        to.setDate(closingDate.getDate());
                        if (to.getMonth() != tmpToMonth) {
                            to.setDate(to.getDate() - 1);
                        }

                        if (isClosingLastDay) {
                            to.setDate(this.getLastDayOfMonth(to.getFullYear(), to.getMonth()));
                        } else {
                            if (to.getDate() != closingDate.getDate()) {
                                // 当該月の末日が決算日付と異なる場合は調整
                                to.setDate(to.getDate() - 1);
                            }
                        }

                        if (!(innerMonth == 34 && to.getTime() == closingDate.getTime())) {
                            if (closingDate.getDate() < this.mMonthStartDay) {
                                to.setMonth(to.getMonth() + 1);
                            }
                        }
                    }
                }
            } else if ([5, 6, 7, 15, 16, 17, 25, 26, 27, 35, 36, 37].any((m: number) => m == innerMonth)) { // 決算月の場合
                switch (innerMonth) {
                    case 5:
                    case 6:
                    case 7:
                        from = map.singleOrNull((r: DateRange) => r.innerMonth == 4).to.clone();
                        to = from.clone();
                        break;
                    case 15:
                    case 16:
                    case 17:
                        from = map.singleOrNull((r: DateRange) => r.innerMonth == 14).to.clone();
                        to = from.clone();
                        break;
                    case 25:
                    case 26:
                    case 27:
                        from = map.singleOrNull((r: DateRange) => r.innerMonth == 24).to.clone();
                        to = from.clone();
                        break;
                    case 35:
                    case 36:
                    case 37:
                        from = this.mClosingDate.clone();
                        to = from.clone();
                        break;
                }


            } else {
                from = map[idx - 1].to.clone();
                from.setDate(from.getDate() + 1);
                to = from.clone();
                to.setMonth(from.getMonth() + 1);
                to.setDate(to.getDate() - 1);
            }

            from.setHours(0);
            to.setHours(0);
            let outerMonth = this.toOuterMonth(innerMonth);
            map.push(new DateRange(innerMonth, outerMonth, from, to));
        });

        return map;
    }

    /**
     * 月末日を取得する
     * @param year
     * @param month
     */
    public getLastDayOfMonth(year: number, month: number): number {
        return new Date(year, month + 1, 0).getDate();
    }

    /**
     * 使用非推奨。
     * 指定された月・日からActiveとなっている年度のどの年に当たるかを返す。
     * 注) 「2017年度」となった場合に月/日の組み合わせによっては2017年だったり2018年だったりするため
     * @param outerMonth
     * @param day
     */
    public getClientYear(outerMonth: number, day: number) {

        let validDate: DateRange;

        if (0 < outerMonth && outerMonth < 13) {

            validDate = this.dateRanges.singleOrNull((range: DateRange) => {
                let isSameFromMonth: boolean = range.from.getMonth() == outerMonth - 1;
                let isSameToMonth: boolean = range.to.getMonth() == outerMonth - 1;
                if (isSameFromMonth && isSameToMonth) {
                    let tmpDate = range.from.clone();
                    tmpDate.setDate(day);
                    if (range.from.getTime() <= tmpDate.getTime() && tmpDate.getTime() <= range.to.getTime()) {
                        return true;
                    }
                } else if (isSameFromMonth) {
                    let tmpDate = range.from.clone();
                    tmpDate.setDate(day);
                    if (range.from.getTime() <= tmpDate.getTime()) {
                        return true;
                    }
                } else if (isSameToMonth) {
                    let tmpDate = range.to.clone();
                    tmpDate.setDate(day);
                    if (tmpDate.getTime() <= range.to.getTime()) {
                        return true;
                    }
                }
                return false;
            });

        } else {
            validDate = this.getDateRangeFromOuter(outerMonth);
        }

        let clientYear: number = undefined!;
        if (validDate) {
            //// 入力日も範囲内であれば、そのまま年を返す。
            //if (day >= validDate.from.getDate() &&
            //    day <= validDate.to.getDate()) {
            //    // 範囲内にあるものは期間内確実なので、そのまま「年」を返す。
            //    return validDate.from.getFullYear();
            //} else {
            //    // 範囲内にない場合、日を比較して小さければfromを、大きければtoの年を返す。
            //    let fromYear: number = validDate.from.getFullYear();
            //    let toYear: number = validDate.to.getFullYear();

            //    let sm: number = this.toStandardMonthFromOuter(outerMonth) - 1;
            //    let fromYearDate: Date = new Date(fromYear, sm, day);
            //    let toYearDate: Date = new Date(toYear, sm, day);

            //    if ((validDate.from <= fromYearDate) && (validDate.to >= toYearDate)) {
            //        return fromYear;

            //    } else {
            //        return toYear;

            //    }

            //}

            let currentDate: Date = validDate.from.clone();

            for (let i = 0; i < 31; i++) {
                if (currentDate.getDate() == day) {
                    clientYear = currentDate.getFullYear();
                    break;
                }
                if (currentDate.getTime() > validDate.to.getTime()) {
                    break;
                }
                currentDate.setDate(currentDate.getDate() + 1);
            }

            return clientYear;
        }
        return null;
    }
}
