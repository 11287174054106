import React from 'react';
import Axios from 'axios';
import { FormGroup, Input, Label, Form } from 'reactstrap';
import * as wjInpot from 'wijmo/wijmo.input';
import { ComboBox } from 'wijmo/wijmo.react.input';
import { FlexGrid } from 'wijmo/wijmo.react.grid';

import * as labels from '../../../../constants/labels';
import * as message from '../../../../constants/message';
import * as enums from '../../../../constants/enums';
import { TargetJournalEnum, TargetTMonthEnum } from '../../../../constants/dataMaps';
import { JournalLineRuleSettingViewModel } from '../../../../models/journalLineRuleSettingViewModel';
import { RuleJokenVO } from '../../../../models/ruleJokenVO';
import { bindValueToMessage } from '../../../../utils/messageUtils';
import { setAlertMessage, AlertKbnEnum } from '../../../../stores/AlertMessage';
import { setIsLoading } from '../../../../stores/NowLoading';

import Popup from '../../../../components/molecules/CMN/Popup/Popup';
import ProcessMonthListSelection from '../../../../components/molecules/CMN/ProcessMonthListSelection/ProcessMonthListSelection';
import InputTextArea from '../../../../components/atoms/InputTextArea/InputTextArea';
import ValidInvalidRadio from '../../../../components/molecules/CMN/ValidInvalidRadio/ValidInvalidRadio';
import ExecutingCancel from '../../../../components/molecules/CMN/ExecutingCancel/ExecutingCancel';
import CheckRule from '../../../../components/molecules/A020/CheckRule';
import ConfirmationPopup from '../../../../components/molecules/CMN/ConfirmationPopup/ConfirmationPopup';
import { CellRange } from 'wijmo/wijmo.grid';

// 基本URL
const BaseInitialUrl = '/api/v1/JournalLineRuleSetting';
const BaseExistentUrl = '/api/v1/JournalLineRuleSetting/Rule';
// 加工後URL
export const GetInitialUrl = [BaseInitialUrl, 'Initial'].join('/'); // 初期表示新規データ作成用
export const PostInitialUrl = [BaseInitialUrl, 'Rule'].join('/'); // チェックリスト登録（新規）用
export const EditionUrl = (ncode: number) => [BaseExistentUrl, ncode.toString()].join('/'); // 編集用
export const CopyingUrl = (ncode: number) => [BaseExistentUrl, ncode.toString(), 'Copy'].join('/'); // コピー用

// 項目ラベルのCSS Class
const labelClass = 'text-lbl';
// バリデートエラーメッセージのCSS Class
const validatErrClass = 'ml-3 text-danger';

type JournalLineRuleSettingProps = {
  activated: boolean;
  onClose: (isRefresh: boolean, code?: number) => void;
  dispMode: enums.DisplayModeEnum;
  code: number;
  dataType: enums.DataTypeEnum;
};
type JournalLineRuleSettingFormEvent = React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>;

const JournalLineRuleSetting: React.FC<JournalLineRuleSettingProps> = props => {
  const { activated, onClose, dispMode, code, dataType } = props;
  const [viewModel, updateViewModel] = React.useState<JournalLineRuleSettingViewModel>(
    new JournalLineRuleSettingViewModel()
  );

  const [communicating, setCommunicating] = React.useState(false);
  const [errMessage, setErrMessage] = React.useState('');

  const gridRef = React.createRef<FlexGrid>();
  const [confirmationActivated, setConfirmationActivated] = React.useState(false);

  const [targetKbnCmb, setTargetKbnCmb] = React.useState<wjInpot.ComboBox>();
  const initializedTargetKbnCmb = (comboBox: wjInpot.ComboBox) => {
    setTargetKbnCmb(comboBox);
  };

  const initializationViewModel = (vm: JournalLineRuleSettingViewModel, displayMode: enums.DisplayModeEnum) => {
    const newViewModel = { ...vm };
    switch (displayMode) {
      case enums.DisplayModeEnum.New:
        newViewModel.RuleName = '';
        newViewModel.TargetKbn = undefined;
        newViewModel.TargetTMonthKbn = undefined;
        newViewModel.NMonth = [];
        newViewModel.AuditMess = '';
        newViewModel.YukoFlg = undefined;
        break;
      case enums.DisplayModeEnum.Update:
        break;
      case enums.DisplayModeEnum.Copy:
        break;
      default:
        break;
    }

    return newViewModel;
  };

  const setViewModel = (newViewModel: JournalLineRuleSettingViewModel) => {
    updateViewModel(newViewModel);
    if (typeof targetKbnCmb !== 'undefined') {
      targetKbnCmb.selectedValue = newViewModel.TargetKbn;
    }
  };

  /* 通信処理 */
  // 表示データ取得処理（GET）
  const initialOnGet = (url: string) => {
    setIsLoading(true);
    setCommunicating(true);
    setErrMessage('');
    Axios.get<JournalLineRuleSettingViewModel>(url, { params: { dataTypeCode: dataType } })
      .then(response => {
        setViewModel(initializationViewModel(response.data, dispMode));
      })
      .catch(error => {
        if (typeof error.response.data !== 'undefined') {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
        }
        handleOnClose(false);
      })
      .finally(() => {
        setIsLoading(false);
        setCommunicating(false);
      });
  };

  const getGetUrl = (displayMode: enums.DisplayModeEnum, prmCode: number) => {
    switch (displayMode) {
      case enums.DisplayModeEnum.New:
        return GetInitialUrl;
      case enums.DisplayModeEnum.Update:
        return EditionUrl(prmCode);
      case enums.DisplayModeEnum.Copy:
        return CopyingUrl(prmCode);
      default:
        return '';
    }
  };

  React.useEffect(() => {
    if (activated) {
      initialOnGet(getGetUrl(dispMode, code));
    }
  }, [dispMode, code, activated]);

  // 保存処理（POST）
  const ruleOnPost = (url: string, savingViewModel: JournalLineRuleSettingViewModel) => {
    setCommunicating(true);
    Axios.post<number>(url, savingViewModel)
      .then(response => {
        setAlertMessage({
          alerted: true,
          alertKbn: AlertKbnEnum.success,
          message: bindValueToMessage(message.Common_Success_Registration, ['仕訳明細ルール'])
        });
        const newCode = dispMode === enums.DisplayModeEnum.Update ? code : response.data;
        handleOnClose(true, newCode);
      })
      .catch(error => {
        if (typeof error.response.data !== 'undefined') {
          setErrMessage(error.response.data.message);
        }
      })
      .finally(() => {
        setCommunicating(false);
      });
  };

  const getPostUrl = (displayMode: enums.DisplayModeEnum, prmCode: number) => {
    switch (displayMode) {
      case enums.DisplayModeEnum.New:
        return PostInitialUrl;
      case enums.DisplayModeEnum.Update:
        return EditionUrl(prmCode);
      case enums.DisplayModeEnum.Copy:
        return CopyingUrl(prmCode);
      default:
        return '';
    }
  };

  // 保存ボタンクリック時
  const handleExecuteClick = () => {
    // メッセージをクリア
    setErrMessage('');
    // 入力検証開始
    setDoValid(true);
  };

  const [doValid, setDoValid] = React.useState(false);
  const [validMsgClear] = React.useState(false);
  const [isValidName, setIsValidName] = React.useState<boolean>();
  const [isValidTargetKbn, setIsValidTargetKbn] = React.useState<boolean>();
  const [isValidTargetMonth, setIsValidTargetMonth] = React.useState<boolean>();
  const [isValidAuditMsg, setIsValidAuditMsg] = React.useState<boolean>();
  const [isValidValidInvalid, setIsValidValidInvalid] = React.useState<boolean>();
  const [isValidCheckRule, setIsValidCheckRule] = React.useState<boolean>();

  // 名称検証後処理
  const handleNameValidationEnded = (validationResult: boolean) => {
    setIsValidName(validationResult);
  };
  // 対象仕訳データ検証後処理
  const handleTargetKbnValidationEnded = (validationResult: boolean) => {
    setIsValidTargetKbn(validationResult);
  };
  // 対象月選択検証後処理
  const handleTargetMonthValidationEnded = (validationResult: boolean) => {
    setIsValidTargetMonth(validationResult);
  };
  // 監査メッセージ検証後処理
  const handleAuditMessageValidationEnded = (validationResult: boolean) => {
    setIsValidAuditMsg(validationResult);
  };
  // 有効検証後処理
  const handleValidInvalidValidationEnded = (validationResult: boolean) => {
    setIsValidValidInvalid(validationResult);
  };
  // 条件指定検証後処理
  const handleCheckRuleValidationEnded = (validationResult: boolean) => {
    setIsValidCheckRule(validationResult);
  };

  // 検証後処理
  React.useEffect(() => {
    if (
      typeof isValidName !== 'undefined' &&
      typeof isValidTargetKbn !== 'undefined' &&
      typeof isValidTargetMonth !== 'undefined' &&
      typeof isValidAuditMsg !== 'undefined' &&
      typeof isValidValidInvalid !== 'undefined' &&
      typeof isValidCheckRule !== 'undefined'
    ) {
      if (
        isValidName &&
        isValidTargetKbn &&
        isValidTargetMonth &&
        isValidAuditMsg &&
        isValidValidInvalid &&
        isValidCheckRule
      ) {
        // 保存Model作成
        const newRuleJokens: RuleJokenVO[] = [];
        const newGroupNos: string[][] = [];
        gridRef.current!.control.itemsSource.items.forEach((item: any) => { 
          newRuleJokens.push(item.ruleJoken);
          newGroupNos.push(item.groups);
        });

        const targetKbn = typeof targetKbnCmb === 'undefined' ? viewModel.TargetKbn : targetKbnCmb.selectedValue;
        const savingViewModel = {
          ...viewModel,
          NMonth: viewModel.TargetTMonthKbn === enums.TargetTMonthEnum.Customize ? viewModel.NMonth : [],
          RuleJokens: newRuleJokens,
          GroupNos: newGroupNos,
          TargetKbn: targetKbn
        };
        // 保存呼び出し
        ruleOnPost(getPostUrl(dispMode, code), savingViewModel);
      }

      // 検証結果をリセット
      setDoValid(false);
      setIsValidName(undefined);
      setIsValidTargetKbn(undefined);
      setIsValidTargetMonth(undefined);
      setIsValidAuditMsg(undefined);
      setIsValidValidInvalid(undefined);
      setIsValidCheckRule(undefined);
    }
  }, [isValidName, isValidTargetKbn, isValidTargetMonth, isValidAuditMsg, isValidValidInvalid, isValidCheckRule]);

  // 取消ボタンクリック時
  const handleCancelClick = () => {
    setConfirmationActivated(true);
  };

  const handleInputChange = (event: JournalLineRuleSettingFormEvent) => {
    const target = event.currentTarget;
    const name = target.name;
    const value = target.value;

    updateViewModel({ ...viewModel!, [name]: value });
  };

  const handleOnClose = (isRefresh: boolean, code?: number) => {
    updateViewModel(new JournalLineRuleSettingViewModel());
    setErrMessage('');
    onClose(isRefresh, code);
  };
  const handleRadioChange = (isValid: boolean) => {
    updateViewModel({ ...viewModel, YukoFlg: isValid });
  };
  const handleSelectChange = (rad: enums.TargetTMonthEnum, months: number[]) => {
    updateViewModel({ ...viewModel, TargetTMonthKbn: rad, NMonth: months });
  };

  const handleConfirmationPopupClose = () => {
    setConfirmationActivated(false);
  };
  const handleConfirmationPopupYesClick = () => {
    setConfirmationActivated(false);
    handleOnClose(false);
  };
  const handleConfirmationPopupNoClick = () => {
    setConfirmationActivated(false);
  };
  const [ConfirmationPopupMessage] = React.useState(bindValueToMessage(message.Common_Confirmation_Cancel));

  const popupRef = React.useRef<any>();
  const validRef = React.useRef<any>();
  const invalidRef = React.useRef<any>();
  const executeRef = React.useRef<any>();
  const cancelRef = React.useRef<any>();

  const validRadioFocus = () => {
    if (validRef.current.checked === true) {
      validRef.current.focus();
    } else {
      invalidRef.current.focus();
    }
  };

  /** キーボード操作（キーダウン） */
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 9) {
      if (event.shiftKey === false) {
        // タブキー押下時
        const target: HTMLElement = event.target as HTMLElement;

        if (!viewModel.EditableFlg) {
          // 編集不可の場合のTabフォーカス

          // 有効・無効
          if ((target as HTMLInputElement).name) {
            executeRef.current.focus();

            // 後のイベントをキャンセル
            event.preventDefault();
            event.stopPropagation();

            // 実行ボタン
          } else if (target.className === executeRef.current.className) {
            cancelRef.current.focus();

            // 後のイベントをキャンセル
            event.preventDefault();
            event.stopPropagation();

            // キャンセルボタン
          } else if (target.className === cancelRef.current.className) {
            validRadioFocus();

            // 後のイベントをキャンセル
            event.preventDefault();
            event.stopPropagation();

            // 初期フォーカスに遷移する場合
          } else {
            validRadioFocus();
            // 後のイベントをキャンセル
            event.preventDefault();
            event.stopPropagation();
          }
        }
      } else {
        // シフト＋タブキー押下時

        const target: HTMLElement = event.target as HTMLElement;

        if (!viewModel.EditableFlg) {
          // 編集不可の場合のTabフォーカス

          // 有効・無効
          if ((target as HTMLInputElement).name) {
            cancelRef.current.focus();

            // 後のイベントをキャンセル
            event.preventDefault();
            event.stopPropagation();

            // 実行ボタン
          } else if (target.className === executeRef.current.className) {
            validRadioFocus();

            // 後のイベントをキャンセル
            event.preventDefault();
            event.stopPropagation();

            // キャンセルボタン
          } else if (target.className === cancelRef.current.className) {
            executeRef.current.focus();

            // 後のイベントをキャンセル
            event.preventDefault();
            event.stopPropagation();

            // 初期フォーカスに遷移する場合
          } else {
            cancelRef.current.focus();
            // 後のイベントをキャンセル
            event.preventDefault();
            event.stopPropagation();
          }
        }
      }
    }
  };

  React.useEffect(() => {
    if (viewModel != undefined && viewModel.NCode != undefined && props.activated) {
      // 画面表示完了後に実行
      setTimeout(() => {
        if (popupRef.current) {
          // ポップアップにキーダウンイベントのフック
          popupRef.current.addEventListener('keydown', handleKeyDown);
        }
      });
    }
  }, [viewModel.NCode]);

  const footer = (
    <div className={'d-flex justify-content-between w-100'}>
      <div className={'text-danger'}>{errMessage}</div>
      <ExecutingCancel
        executeLabel={labels.COMMON_BUTTON_FUNCTION_SAVE}
        executeDisabled={communicating}
        executeRef={executeRef}
        onExecuteClick={handleExecuteClick}
        cancelLabel={labels.COMMON_BUTTON_FUNCTION_CANCEL}
        cancelDisabled={communicating}
        cancelRef={cancelRef}
        onCancelClick={handleCancelClick}
      />
    </div>
  );

  return viewModel == undefined || viewModel.NCode == undefined ? null : (
    <Popup
      id='JournalLineRuleSetting'
      isOpen={activated}
      onCloseClick={handleCancelClick}
      header={labels.AIKADT001000002_FUNCTION_NAME}
      innerRef={popupRef}
      footer={footer}
    >
      <ChecklistSettingForm
        mode={dispMode}
        model={viewModel}
        gridRef={gridRef}
        validRef={validRef}
        invalidRef={invalidRef}
        onInputChange={handleInputChange}
        onSelectChange={handleSelectChange}
        onRadioSelectChange={handleRadioChange}
        initializedTargetKbnCmb={initializedTargetKbnCmb}
        doValidate={doValid}
        validMsgClear={validMsgClear}
        onNameValidationEnded={handleNameValidationEnded}
        onTargetKbnValidationEnded={handleTargetKbnValidationEnded}
        onTargetMonthValidationEnded={handleTargetMonthValidationEnded}
        onAuditMessageValidationEnded={handleAuditMessageValidationEnded}
        onValidInvalidValidationEnded={handleValidInvalidValidationEnded}
        onCheckRuleValidationEnded={handleCheckRuleValidationEnded}
      />
      <ConfirmationPopup
        isOpen={confirmationActivated}
        onClose={handleConfirmationPopupClose}
        onYesClick={handleConfirmationPopupYesClick}
        onNoClick={handleConfirmationPopupNoClick}
      >
        {ConfirmationPopupMessage}
      </ConfirmationPopup>
    </Popup>
  );
};

export default JournalLineRuleSetting;

// HOWTO: フォーム入力は
//    [React標準実装](https://ja.reactjs.org/docs/forms.html)を使用します。
//    [reactstrap標準実装](https://reactstrap.github.io/components/form/)を使用します。
// HOWTO: 入力フォームを別だしにして定義します。
// HOWTO: 　ここでは引数だけを使って正しく表示することに専念します。
type ChecklistSettingFormProps = {
  mode: enums.DisplayModeEnum;
  model: JournalLineRuleSettingViewModel;
  gridRef: React.RefObject<any>;
  validRef?: React.RefObject<any>;
  invalidRef?: React.RefObject<any>;
  doValidate: boolean;
  validMsgClear: boolean;
  onInputChange: (event: JournalLineRuleSettingFormEvent) => void;
  onSelectChange: (targetTMonth: enums.TargetTMonthEnum, tMonthCustomize: number[]) => void;
  onRadioSelectChange: (isValid: boolean) => void;
  initializedTargetKbnCmb: (sender: wjInpot.ComboBox) => void;
  onNameValidationEnded: (result: boolean) => void;
  onTargetKbnValidationEnded: (result: boolean) => void;
  onTargetMonthValidationEnded: (result: boolean) => void;
  onAuditMessageValidationEnded: (result: boolean) => void;
  onValidInvalidValidationEnded: (result: boolean) => void;
  onCheckRuleValidationEnded: (result: boolean) => void;
};
const ChecklistSettingForm: React.FC<ChecklistSettingFormProps> = props => {
  return (
    <Form>
      <FormGroup>
        <Label>
          <b className={labelClass}>{labels.AIKADT001000002_INPUT_HEADER_TYPE}</b>
        </Label>
        <Label className='w-100 m-0'>{props.model.DataTypeName}</Label>
      </FormGroup>
      <FormGroup>
        <JournalLineRuleName
          model={props.model}
          onInputChange={props.onInputChange}
          doValidate={props.doValidate}
          validMsgClear={props.validMsgClear}
          onValidationEnded={props.onNameValidationEnded}
        />
      </FormGroup>
      <FormGroup>
        <JournalLineRuleTargetKbn
          model={props.model}
          initializedCmb={props.initializedTargetKbnCmb}
          validMsgClear={props.validMsgClear}
          doValidate={props.doValidate}
          onValidationEnded={props.onTargetKbnValidationEnded}
        />
      </FormGroup>
      <FormGroup>
        <JournalLineRuleTargetMonth
          model={props.model}
          onSelectChange={props.onSelectChange}
          doValidate={props.doValidate}
          validMsgClear={props.validMsgClear}
          onValidationEnded={props.onTargetMonthValidationEnded}
        />
      </FormGroup>
      <FormGroup>
        <JournalLineRuleAuditMsg
          model={props.model}
          onInputChange={props.onInputChange}
          doValidate={props.doValidate}
          validMsgClear={props.validMsgClear}
          onValidationEnded={props.onAuditMessageValidationEnded}
        />
      </FormGroup>
      <FormGroup>
        <ValidInvalid
          model={props.model}
          onInputChange={props.onRadioSelectChange}
          validRef={props.validRef}
          invalidRef={props.invalidRef}
          doValidate={props.doValidate}
          validMsgClear={props.validMsgClear}
          onValidationEnded={props.onValidInvalidValidationEnded}
        />
      </FormGroup>
      <FormGroup>
        <CheckRuleComponent
          model={props.model}
          gridRef={props.gridRef}
          doValidate={props.doValidate}
          validMsgClear={props.validMsgClear}
          onValidationEnded={props.onCheckRuleValidationEnded}
        />
      </FormGroup>
    </Form>
  );
};

// ルール名称入力欄
type JournalLineRuleNameProps = {
  model: JournalLineRuleSettingViewModel;
  onInputChange: (event: JournalLineRuleSettingFormEvent) => void;
  doValidate: boolean;
  validMsgClear: boolean;
  onValidationEnded: (result: boolean) => void;
};
const JournalLineRuleName: React.FC<JournalLineRuleNameProps> = props => {
  const [errMsg, setErrMsg] = React.useState('');

  React.useEffect(() => {
    if (!props.doValidate) {
      return;
    }
    setErrMsg('');
    let input = props.model.RuleName;
    // 名称未入力
    if (input == undefined || input.trim().length == 0) {
      setErrMsg(bindValueToMessage(message.Common_Error_RequiredInput_Empty, ['名称']));
      props.onValidationEnded(false);
      return;
    }
    // 名称桁超過
    if (input.length > 25) {
      setErrMsg(bindValueToMessage(message.Common_Error_LengthExcess, ['名称', '25']));
      props.onValidationEnded(false);
      return;
    }
    props.onValidationEnded(true);
  }, [props.doValidate]);

  React.useEffect(() => {
    setErrMsg('');
  }, [props.validMsgClear]);

  return (
    <div>
      <Label for={'RuleName'}>
        <b className={labelClass}>{labels.AIKADT001000002_INPUT_HEADER_NAME}</b>
        <span className={validatErrClass}>{errMsg}</span>
      </Label>
      <Input
        type='text'
        id={'RuleName'}
        name={'RuleName'}
        placeholder={labels.AIKADT001000002_INPUT_PLACEHOLDER_NAME}
        value={props.model.RuleName}
        onChange={props.onInputChange}
        disabled={!props.model.EditableFlg}
        maxLength={25}
      />
    </div>
  );
};

// 対象仕訳データ選択欄
type JournalLineTargetKbnProps = {
  model: JournalLineRuleSettingViewModel;
  initializedCmb: (sender: wjInpot.ComboBox) => void;
  doValidate: boolean;
  validMsgClear: boolean;
  onValidationEnded: (result: boolean) => void;
};
const JournalLineRuleTargetKbn: React.FC<JournalLineTargetKbnProps> = props => {
  const [targetKbnCmb, setTargetKbnCmb] = React.useState<wjInpot.ComboBox>();
  const [errMsg, setErrMsg] = React.useState('');

  const initialized = (comboBox: wjInpot.ComboBox) => {
    setTargetKbnCmb(comboBox);
    props.initializedCmb(comboBox);
  };

  React.useEffect(() => {
    if (!props.doValidate) {
      return;
    }
    setErrMsg('');
    const targetKbn = typeof targetKbnCmb === 'undefined' ? props.model.TargetKbn : targetKbnCmb.selectedValue;
    // 対象仕訳データ選択値異常
    if (TargetJournalEnum.find(x => x.key == targetKbn) == undefined) {
      setErrMsg(bindValueToMessage(message.Common_Error_InvalidSetting, ['対象仕訳データ']));
      props.onValidationEnded(false);
      return;
    }
    props.onValidationEnded(true);
  }, [props.doValidate]);

  React.useEffect(() => {
    setErrMsg('');
  }, [props.validMsgClear]);

  return (
    <div>
      <Label>
        <b className={labelClass}>{labels.AIKADT001000002_INPUT_HEADER_TARGET}</b>
        <span className={validatErrClass}>{errMsg}</span>
      </Label>
      <ComboBox
        itemsSource={TargetJournalEnum}
        displayMemberPath={'value'}
        selectedValuePath={'key'}
        placeholder={labels.AIKADT001000002_INPUT_PLACEHOLDER_TARGET}
        isRequired={false}
        selectedValue={props.model.TargetKbn}
        className='w-100'
        initialized={initialized}
        isDisabled={!props.model.EditableFlg}
      />
    </div>
  );
};

// 実行対象月区分選択欄
type JournalLineTargetMonthProps = {
  model: JournalLineRuleSettingViewModel;
  onSelectChange: (targetTMonth: enums.TargetTMonthEnum, tMonthCustomize: number[]) => void;
  doValidate: boolean;
  validMsgClear: boolean;
  onValidationEnded: (result: boolean) => void;
};
const JournalLineRuleTargetMonth: React.FC<JournalLineTargetMonthProps> = props => {
  const [errMsg, setErrMsg] = React.useState('');

  React.useEffect(() => {
    if (!props.doValidate) {
      return;
    }
    setErrMsg('');
    let savingViewModel = props.model;
    // 実行対象月区分選択値異常
    if (TargetTMonthEnum.find(x => x.key == savingViewModel.TargetTMonthKbn) == undefined) {
      setErrMsg(bindValueToMessage(message.Common_Error_InvalidSetting, ['実行対象月区分']));
      props.onValidationEnded(false);
      return;
    }
    // 実行対象月・個別指定対象無し
    if (
      savingViewModel.TargetTMonthKbn == enums.TargetTMonthEnum.Customize &&
      (savingViewModel.NMonth == undefined || savingViewModel.NMonth.length == 0)
    ) {
      setErrMsg(bindValueToMessage(message.Common_Error_Unchecked, ['実行対象月']));
      props.onValidationEnded(false);
      return;
    }
    props.onValidationEnded(true);
  }, [props.doValidate]);

  React.useEffect(() => {
    setErrMsg('');
  }, [props.validMsgClear]);

  return (
    <div>
      <Label>
        <b className={labelClass}>{labels.AIKADT001000002_INPUT_HEADER_TARGETSELECT}</b>
        <span className={validatErrClass}>{errMsg}</span>
      </Label>
      <ProcessMonthListSelection
        TMonth={props.model.MonthSelects != undefined ? props.model.MonthSelects : []}
        targetTMonth={props.model.TargetTMonthKbn}
        tMonthCustomize={props.model.NMonth}
        onChangeItem={props.onSelectChange}
        disabled={!props.model.EditableFlg}
      />
    </div>
  );
};

// 監査メッセージ入力欄
type JournalLineRuleAuditMsgProps = {
  model: JournalLineRuleSettingViewModel;
  onInputChange: (event: JournalLineRuleSettingFormEvent) => void;
  doValidate: boolean;
  validMsgClear: boolean;
  onValidationEnded: (result: boolean) => void;
};
const JournalLineRuleAuditMsg: React.FC<JournalLineRuleAuditMsgProps> = props => {
  const [errMsg, setErrMsg] = React.useState('');

  React.useEffect(() => {
    if (!props.doValidate) {
      return;
    }
    setErrMsg('');
    let input = props.model.AuditMess;
    // 監査メッセージ未入力
    if (input == undefined || input.trim().length == 0) {
      setErrMsg(bindValueToMessage(message.Common_Error_RequiredInput_Empty, ['監査メッセージ']));
      props.onValidationEnded(false);
      return;
    }
    // 監査メッセージ桁超過
    if (input.length > 100) {
      setErrMsg(bindValueToMessage(message.Common_Error_LengthExcess, ['監査メッセージ', '100']));
      props.onValidationEnded(false);
      return;
    }
    props.onValidationEnded(true);
  }, [props.doValidate]);

  React.useEffect(() => {
    setErrMsg('');
  }, [props.validMsgClear]);

  return (
    <div>
      <Label for='AuditMess'>
        <b className={labelClass}>{labels.AIKADT001000002_INPUT_HEADER_AUDITMESSAGE}</b>
        <span className={validatErrClass}>{errMsg}</span>
      </Label>
      <InputTextArea
        data-testid='AuditMess'
        name='AuditMess'
        enable={{
          top: false,
          right: false,
          bottom: true,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false
        }}
        placeholder={labels.AIKADT001000002_INPUT_PLACEHOLDER_AUDITMESSAGE}
        rows={4}
        onInputChange={props.onInputChange}
        value={props.model.AuditMess}
        disabled={!props.model.EditableFlg}
        maxLength={100}
      />
    </div>
  );
};

// 有効入力欄
type ValidInvalidProps = {
  model: JournalLineRuleSettingViewModel;
  onInputChange: (isValid: boolean) => void;
  validRef?: React.RefObject<any>;
  invalidRef?: React.RefObject<any>;
  doValidate: boolean;
  validMsgClear: boolean;
  onValidationEnded: (result: boolean) => void;
};
const ValidInvalid: React.FC<ValidInvalidProps> = props => {
  const [errMsg, setErrMsg] = React.useState('');

  React.useEffect(() => {
    if (!props.doValidate) {
      return;
    }
    setErrMsg('');
    let input = props.model.YukoFlg;
    // 監査メッセージ未入力
    if (input == undefined) {
      setErrMsg(bindValueToMessage(message.Common_Error_Required_Empty, ['有効']));
      props.onValidationEnded(false);
      return;
    }

    props.onValidationEnded(true);
  }, [props.doValidate]);

  React.useEffect(() => {
    setErrMsg('');
  }, [props.validMsgClear]);

  return (
    <div>
      <Label>
        <b className={labelClass}>{labels.AIKADT001000002_INPUT_HEADER_VALID}</b>
        <span className={validatErrClass}>{errMsg}</span>
      </Label>
      <ValidInvalidRadio
        name='validInvalid'
        isValid={props.model.YukoFlg}
        validRef={props.validRef}
        invalidRef={props.invalidRef}
        onRadioChange={props.onInputChange}
      />
    </div>
  );
};

// チェックルール欄
type CheckRuleProps = {
  model: JournalLineRuleSettingViewModel;
  gridRef: React.RefObject<any>;
  doValidate: boolean;
  validMsgClear: boolean;
  onValidationEnded: (result: boolean) => void;
};
const CheckRuleComponent: React.FC<CheckRuleProps> = props => {
  const [errMsg, setErrMsg] = React.useState('');

  React.useEffect(() => {
    if (!props.doValidate) {
      return;
    }
    setErrMsg('');

    //グループがある場合に、フォーカスを当てる列を調整する
    const groupColumenOffset = props.gridRef.current.control.columns.length - 7;

    const ruleJokens: RuleJokenVO[] = [];
    const groupNos: string[][] = [];
    props.gridRef.current!.control.itemsSource.items.forEach((item: any) => {
      ruleJokens.push(item.ruleJoken);
      groupNos.push(item.groups);
    });

    if (ruleJokens.length < 1) {
      setErrMsg(message.JournalLineRuleSetting_Error_CheckRule_Zero);
      props.onValidationEnded(false);
      return;
    }
    // 条件指定のチェック
    for (let i = 0; i < ruleJokens.length; i++) {
      const ruleJoken = ruleJokens[i];
      if (i !== 0) {
        if (ruleJoken.AndOr == undefined) {
          setErrMsg(message.JournalLineRuleSetting_Error_CheckRule_Empty);
          props.onValidationEnded(false);
          props.gridRef.current.control.select(new CellRange(i, 3 + groupColumenOffset), true);
          return;
        }
      }
      if (ruleJoken.JItemName == undefined) {
        setErrMsg(message.JournalLineRuleSetting_Error_CheckRule_Empty);
        props.onValidationEnded(false);
        props.gridRef.current.control.select(new CellRange(i, 4 + groupColumenOffset), true);
        return;
      }
      if (ruleJoken.JokenKbn == undefined) {
        setErrMsg(message.JournalLineRuleSetting_Error_CheckRule_Empty);
        props.onValidationEnded(false);
        props.gridRef.current.control.select(new CellRange(i, 5 + groupColumenOffset), true);
        return;
      }
      // タイプ別入力値チェック
      switch (ruleJoken.JItemTypeKbn) {
        case enums.ItemTypeEnum.Text: // テキスト項目
          if (ruleJoken.Value == undefined || ruleJoken.Value === '') {
            // 未入力
            setErrMsg(message.JournalLineRuleSetting_Error_CheckRule_Empty);
            props.onValidationEnded(false);
            props.gridRef.current.control.select(new CellRange(i, 6 + groupColumenOffset), true);
            return;
          } else if (ruleJoken.Value.length > 100) {
            // 桁数オーバー
            setErrMsg(message.JournalLineRuleSetting_Error_CheckRule_DigitOver);
            props.onValidationEnded(false);
            props.gridRef.current.control.select(new CellRange(i, 6 + groupColumenOffset), true);
            return;
          }
          break;
        case enums.ItemTypeEnum.Number: // 数値項目
          if (ruleJoken.Value == undefined || ruleJoken.Value === '') {
            // 未入力
            setErrMsg(message.JournalLineRuleSetting_Error_CheckRule_Empty);
            props.onValidationEnded(false);
            props.gridRef.current.control.select(new CellRange(i, 6 + groupColumenOffset), true);
            return;
          } else if (ruleJoken.Value.length > 17) {
            // 桁数オーバー
            setErrMsg(message.JournalLineRuleSetting_Error_CheckRule_DigitOver);
            props.onValidationEnded(false);
            props.gridRef.current.control.select(new CellRange(i, 6 + groupColumenOffset), true);
            return;
          }
          break;
        case enums.ItemTypeEnum.Date: // 日付項目
          if (ruleJoken.Value == undefined || ruleJoken.Value === '') {
            // 未入力
            setErrMsg(message.JournalLineRuleSetting_Error_CheckRule_Empty);
            props.onValidationEnded(false);
            props.gridRef.current.control.select(new CellRange(i, 6 + groupColumenOffset), true);
            return;
          }
          break;
        default:
          break;
      }

    }
    props.onValidationEnded(true);
  }, [props.doValidate]);

  React.useEffect(() => {
    setErrMsg('');
  }, [props.validMsgClear]);

  return (
    <div>
      <Label>
        <b className={labelClass}>{labels.AIKADT001000002_GRID_HEADER_CONDITIONSPECIFICATION}</b>
        <span className={validatErrClass}>{errMsg}</span>
      </Label>
      <CheckRule
        ruleJokens={props.model.RuleJokens != undefined ? props.model.RuleJokens : []}
        groupNos={props.model.GroupNos != undefined ? props.model.GroupNos : []}
        disabled={!props.model.EditableFlg}
        gridRef={props.gridRef}
      />
    </div>
  );
};
