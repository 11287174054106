/**
 * 対象仕訳区分
 */
export const TargetJournalEnum = [

    { key:0, value: 'すべて' },
    { key:1, value: '入力仕訳' },
    { key:2, value: '自動仕訳' },
]
/**
 * 実行対象月区分
 */
export const TargetTMonthEnum = [

    { key:0, value: 'すべて' },
    { key:1, value: '通常月' },
    { key:2, value: '決算月' },
    { key:3, value: '個別指定' },
]
/**
 * 有効無効
 */
export const AbleEnum = [

    { key:0, value: '無効' },
    { key:1, value: '有効' },
]
/**
 * ANDOR
 */
export const AndOrEnum = [

    { key:0, value: 'AND' },
    { key:1, value: 'OR' },
]
/**
 * 条件（文字列）
 */
export const CondiStringEnum = [

    { key:0, value: '一致' },
    { key:1, value: '一致しない' },
    { key:2, value: '含む' },
    { key:3, value: '含まない' },
    { key:4, value: '始まる' },
    { key:5, value: '終わる' },
]
/**
 * 条件（数値）
 */
export const CondiNumberEnum = [

    { key:6, value: '一致' },
    { key:7, value: '一致しない' },
    { key:8, value: 'より大きい' },
    { key:9, value: 'より小さい' },
    { key:10, value: '以上' },
    { key:11, value: '以下' },
]
/**
 * 条件（日付）
 */
export const CondiDateEnum = [

    { key:12, value: '一致' },
    { key:13, value: 'より前' },
    { key:14, value: 'より後' },
    { key:15, value: '以前' },
    { key:16, value: '以降' },
]
/**
 * 種別区分
 */
export const DataTypeEnum = [

    { key:0, value: 'システム' },
    { key:1, value: 'ユーザー' },
]
/**
 * 確認ステータス
 */
export const ConfStatusEnum = [

    { key:0, value: '未確認' },
    { key:1, value: '確認NG' },
    { key:2, value: '確認OK' },
    { key:3, value: '保留' },
]
/**
 * 項目種別区分
 */
export const ItemTypeEnum = [

    { key:0, value: 'テキスト' },
    { key:1, value: '数値' },
    { key:2, value: '日付' },
]
/**
 * バッチステータス
 */
export const BatStatusEnum = [

    { key:0, value: '処理待ち' },
    { key:1, value: '処理中' },
    { key:2, value: '正常終了' },
    { key:9, value: '異常終了' },
]
/**
 * 監査種類
 */
export const AuditTypeEnum = [

    { key:1, value: '著増減監査' },
    { key:2, value: 'マイナス残高監査' },
    { key:3, value: '仕訳明細監査' },
    { key:4, value: '仕訳重複監査' },
    { key:5, value: '消費税区分監査' },
]
/**
 * 比較対象年度区分
 */
export const CmpYearEnum = [

    { key:0, value: '前年度' },
    { key:1, value: '同年度' },
]
/**
 * 変換用条件（文字列）
 */
export const JokenStingEnum = [

    { key:0, value: 'と一致' },
    { key:1, value: 'と一致しない' },
    { key:2, value: 'を含む' },
    { key:3, value: 'を含まない' },
    { key:4, value: 'から始まる' },
    { key:5, value: 'で終わる' },
]
/**
 * 変換用条件（数値）
 */
export const JokenNumberEnum = [

    { key:6, value: 'と一致' },
    { key:7, value: 'と一致しない' },
    { key:8, value: 'より大きい' },
    { key:9, value: 'より小さい' },
    { key:10, value: '以上' },
    { key:11, value: '以下' },
]
/**
 * 変換用条件（日付）
 */
export const JokenDateEnum = [

    { key:12, value: 'と一致' },
    { key:13, value: 'より前' },
    { key:14, value: 'より後' },
    { key:15, value: '以前' },
    { key:16, value: '以降' },
]
/**
 * 貸借区分
 */
export const DCEnum = [

    { key:0, value: '貸方' },
    { key:1, value: '借方' },
    { key:2, value: '両方' },
]
/**
 * しきい値符号区分
 */
export const SkiSignEnum = [

    { key:0, value: '±' },
    { key:1, value: '＋' },
    { key:2, value: '－' },
    { key:3, value: '＝' },
]
/**
 * NG有無
 */
export const AuditCaughtEnum = [

    { key:0, value: 'なし' },
    { key:1, value: 'あり' },
]
/**
 * 表示モード
 */
export const DisplayModeEnum = [

    { key:0, value: '新規作成' },
    { key:1, value: '更新' },
    { key:2, value: 'コピー' },
]
/**
 * 売上仕入区分
 */
export const SalesPurchaseEnum = [

    { key:0, value: 'なし' },
    { key:1, value: '売上' },
    { key:2, value: '仕入' },
]
/**
 * 条件項目補助区分
 */
export const JItemHojyoEnum = [

    { key:0, value: 'なし' },
    { key:1, value: '科目別補助' },
    { key:2, value: '部門' },
    { key:3, value: '工事' },
    { key:4, value: 'セグメント1' },
    { key:5, value: 'セグメント2' },
    { key:6, value: 'セグメント3' },
    { key:7, value: 'セグメント4' },
    { key:8, value: 'セグメント5' },
    { key:9, value: '全セグメント' },
    { key:10, value: '第1補助' },
    { key:11, value: '第2補助' },
    { key:12, value: '全補助' },
]
/**
 * 仕訳重複条件項目
 */
export const DblJokenEnum = [

    { key:0, value: '伝票No' },
    { key:1, value: '証憑No' },
    { key:2, value: '伝票日付' },
    { key:3, value: '勘定科目' },
    { key:4, value: '部門' },
    { key:5, value: '工事' },
    { key:6, value: 'セグメント' },
    { key:7, value: '補助' },
    { key:8, value: '金額' },
    { key:9, value: '消費税' },
    { key:10, value: '摘要' },
    { key:11, value: '仕入日' },
    { key:12, value: '仕入先' },
]
/**
 * 比較対象月度区分
 */
export const CmpTMonthEnum = [

    { key:0, value: '前月' },
    { key:1, value: '前年同月' },
    { key:2, value: '前半期同月' },
    { key:3, value: '前四半期同月' },
    { key:4, value: '当月' },
    { key:5, value: '期首残' },
]
/**
 * 結果ステータス区分
 */
export const AuditStatusEnum = [

    { key:0, value: '未連携' },
    { key:1, value: '完了' },
    { key:2, value: '結果確認中' },
    { key:3, value: '未実行' },
]
/**
 * ユーザ種別
 */
export const UserTypeEnum = [

    { key:0, value: 'CSユーザー' },
    { key:1, value: '一般ユーザー' },
]
/**
 * 中間決算区分
 */
export const MidtermClosingEnum = [

    { key:0, value: 'なし' },
    { key:1, value: '半期決算' },
    { key:2, value: '四半期決算' },
]
/**
 * バッチID
 */
export const BatIdEnum = [

    { key:1, value: '著増減監査' },
    { key:2, value: 'マイナス残高監査' },
    { key:3, value: '仕訳明細監査' },
    { key:4, value: '仕訳重複監査' },
    { key:5, value: '消費税区分監査' },
    { key:6, value: '顧問先インストール' },
    { key:7, value: '顧問先差分取込' },
    { key:8, value: '顧問先削除' },
    { key:9, value: 'エクセル出力' },
    { key:10, value: 'AI仕訳チェック' },
    { key:11, value: '監査ルールインポート' },
    { key:12, value: 'MigrateSwkの後処理' },
]
/**
 * データ書出し方向種別
 */
export const DataWritingDirectionEnum = [

    { key:0, value: '縦' },
    { key:1, value: '横' },
]
/**
 * 取込ルール区分
 */
export const ImportRuleEnum = [

    { key:0, value: '仕訳明細ルール' },
    { key:1, value: '月次残高ルール' },
]
/**
 * インポート方法区分
 */
export const ImportMethodEnum = [

    { key:0, value: '追加' },
    { key:1, value: 'ルールを削除してからインポートする' },
]
/**
 * ＢＳ／ＰＬ区分
 */
export const BPEnum = [

    { key:9, value: '全科目' },
    { key:0, value: 'B/S科目' },
    { key:1, value: 'P/L科目' },
]
/**
 * ルール設定パターン区分
 */
export const RulePatternEnum = [

    { key:1, value: 'パターン1' },
    { key:2, value: 'パターン2' },
    { key:3, value: 'パターン3' },
    { key:4, value: 'パターン4' },
    { key:5, value: 'パターン5' },
    { key:6, value: 'パターン6' },
    { key:7, value: 'パターン7' },
    { key:8, value: 'パターン8' },
    { key:9, value: 'パターン9' },
    { key:10, value: 'パターン10' },
    { key:11, value: 'パターン11' },
    { key:12, value: 'パターン12' },
    { key:13, value: 'パターン13' },
    { key:14, value: 'パターン14' },
    { key:15, value: 'パターン15' },
]
/**
 * 比較値区分
 */
export const CmpValueEnum = [

    { key:0, value: '残高' },
    { key:1, value: '実績' },
    { key:2, value: '実績（借方発生）' },
    { key:3, value: '実績（貸方発生）' },
]
/**
 * 対象月区分
 */
export const TargetMonthEnum = [

    { key:0, value: '実行月' },
    { key:1, value: '比較対象月' },
]
/**
 * 月次確定区分
 */
export const MonthlyFixEnum = [

    { key:0, value: '解除' },
    { key:1, value: '確定' },
]
/**
 * 敬称
 */
export const HonorificEnum = [

    { key:0, value: 'なし' },
    { key:1, value: '御中' },
    { key:2, value: '様' },
    { key:3, value: '殿' },
]
/**
 * 遷移先画面
 */
export const ActivateScreenEnum = [

    { key:0, value: '一覧' },
    { key:1, value: '明細' },
]
/**
 * 連携区分
 */
export const CooperationEnum = [

    { key:0, value: 'MJSシステム連携' },
    { key:1, value: '他社システム連携' },
]
/**
 * 権限区分
 */
export const AuthorityEnum = [

    { key:0, value: '一般' },
    { key:1, value: '管理者' },
    { key:2, value: 'Bizsky' },
]
/**
 * 科目分類
 */
export const KmkClassEnum = [

    { key:1, value: '資産科目' },
    { key:2, value: '負債・純資産科目' },
    { key:3, value: '損益科目' },
    { key:4, value: '製造科目' },
]
/**
 * 中間決算区分集計方法
 */
export const MidtermClosingCalcKbnEnum = [

    { key:0, value: '下期に含まない' },
    { key:1, value: '下期に含む' },
]
/**
 * 科目体系
 */
export const CorpKbnEnum = [

    { key:1, value: '一般' },
    { key:2, value: '医療' },
    { key:3, value: '工事Ｂ／Ｓ' },
    { key:4, value: '工事Ｐ／Ｌ' },
]
/**
 * 正残区分
 */
export const DCKbnEnum = [

    { key:0, value: '借方' },
    { key:1, value: '貸方' },
]
/**
 * BS/PL区分
 */
export const BPKbnEnum = [

    { key:0, value: 'Ｂ／Ｓ' },
    { key:1, value: 'Ｐ／Ｌ' },
]
/**
 * 期首残区分
 */
export const KisyuKbnEnum = [

    { key:0, value: 'なし' },
    { key:1, value: 'あり' },
]
/**
 * 消費税科目区分
 */
export const TaxKbnEnum = [

    { key:0, value: '対象外' },
    { key:1, value: '売上' },
    { key:2, value: '仕入' },
]
/**
 * 消費税率区分
 */
export const TaxRateEnum = [

    { key:0, value: '標準税率' },
    { key:1, value: '3.0%' },
    { key:2, value: '5.0%' },
    { key:3, value: '8.0%' },
    { key:4, value: '10.0%' },
    { key:5, value: '8.0%軽' },
]
/**
 * 業種コード
 */
export const TypeCodeEnum = [

    { key:0, value: '基準業種' },
    { key:1, value: '第一種' },
    { key:2, value: '第二種' },
    { key:3, value: '第三種' },
    { key:4, value: '第四種' },
    { key:5, value: '第五種' },
    { key:6, value: '第六種' },
]
/**
 * 会計処理区分
 */
export const AccProKbnEnum = [

    { key:0, value: '免税業者' },
    { key:1, value: '税込処理' },
    { key:2, value: '税抜処理' },
]
/**
 * 簡易課税区分
 */
export const KaniKbnEnum = [

    { key:0, value: '原則課税' },
    { key:1, value: '簡易課税' },
]
/**
 * 簡易課税基準業種
 */
export const BasedTypeEnum = [

    { key:1, value: '卸売業' },
    { key:2, value: '小売業' },
    { key:3, value: '製造業' },
    { key:4, value: 'その他' },
    { key:5, value: 'サービス業' },
    { key:6, value: '不動産' },
]
/**
 * 輸入取引区分
 */
export const ImportKbnEnum = [

    { key:0, value: 'なし' },
    { key:1, value: '税抜処理（消費税一括処理）' },
    { key:2, value: '税抜処理（国税/地方税分割）' },
    { key:3, value: '税込処理（消費税一括処理）' },
]
/**
 * サービス死活監視区分
 */
export const ServiceAliveKbnEnum = [

    { key:0, value: '正常' },
    { key:11, value: 'データベース接続異常' },
    { key:12, value: 'Blob接続異常' },
    { key:13, value: 'Queue接続異常' },
    { key:14, value: 'Redis接続異常' },
    { key:15, value: 'Redis排他ロック異常' },
    { key:20, value: 'バッチ異常' },
]
/**
 * 科目部門区分
 */
export const KmkBmnKbn = [

    { key:0, value: '科目' },
    { key:1, value: '部門' },
]

