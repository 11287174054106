import React from 'react';
import classnames from 'classnames';
import { Nav, NavItem, NavLink } from 'reactstrap';

export type tabElement = {
  label: string;
  tabIndex?: number;
  navLinkRef?: React.RefObject<any>;
  element: React.ReactElement;
};
type TadLayout = React.HTMLAttributes<HTMLDivElement> & {
  tabElements?: tabElement[];
  defaultActiveTab?: number;
  onTabClick?: (index: number) => void;
};

const TadLayout: React.FC<TadLayout> = props => {
  const { tabElements, defaultActiveTab: defaultActiveTabIndex, onTabClick, className } = props;
  const [activeTab, setActiveTab] = React.useState(defaultActiveTabIndex);
  const HandleTavClick = (index: number) => {
    setActiveTab(index);
    if (onTabClick) onTabClick(index);
  };
  const navItemList: React.ReactElement[] = [];
  const displayElementList: React.ReactElement[] = [];
  tabElements!.forEach((data, index) => {
    const isActive = activeTab === index;
    navItemList.push(
      <NavItem key={['NavItem', index].join('-')}>
        <NavLink
          active={isActive}
          href='javascript:void(0);'
          onClick={() => HandleTavClick(index)}
          tabIndex={data.tabIndex}
          innerRef={data.navLinkRef}
        >
          {data.label}
        </NavLink>
      </NavItem>
    );
    displayElementList.push(
      isActive ? <span key={['span', index].join('-')}>{data.element}</span> : <span key={['span', index].join('-')} />
    );
  });

  // divタグに不要なpropsを除去
  const divProps = { ...props };
  delete divProps['tabElements'];
  delete divProps['defaultActiveTab'];
  delete divProps['onTabClick'];

  return (
    <div {...divProps} className={classnames(className, 'tab-layout')}>
      <Nav tabs={true}>{navItemList}</Nav>
      <div>{displayElementList}</div>
    </div>
  );
};

export default TadLayout;
