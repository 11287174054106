import { Logger } from "../../common/usefuls/logger";
import { IPageMode } from "../functionPanel/mode/IPageMode";


export interface IEventLinkParams {
    selector: string;
    pageMode: IPageMode;
}


export interface IEventLink {
    eventLink(params: IEventLinkParams): void;
}

/*
export interface IEventLink {
    eventLink(mode: IPageMode): void;
}
*/

export interface IEventAdapter {
    eventAdapter(adapter: IEventLink): void;
}

export abstract class Component implements IEventAdapter {

    private _eventLinks: IEventLink[];
    private _selector: string;
    private _label: string;

    constructor(selector: string, label?: string) {
        this._selector = selector;
        this._label = label!;
        this._eventLinks = [];
    }

    public getEventLinks(): IEventLink[] {
        return this._eventLinks;
    }

    public eventAdapter(target: IEventLink): void {
        this._eventLinks.push(target);
    }

    protected eventLinkNum(): number {
        if (this.getEventLinks() == null) {
            return 0;
        } else {
            return this.getEventLinks().length;
        }
    }

    protected findElement(): Element {
        var element: Element = this.findElementById();
        if (element == null) {
            element = this.findElementByClass();
            if (element != null) {
                return element;
            } else {
                return null!;
            }
        } else {
            return element;
        }
    }

    protected findElementById(): Element {
        var element: HTMLElement =
            document.getElementById(
                this.getElementNameFromSelectorId())!;
        return element;
    }

    /**
     * クラス属性で要素を探し、最初を返す
     * [注意]複数件ヒットする場合は注意してください
     */
    protected findElementByClass(): Element {
        var element: HTMLCollectionOf<Element> =
            document.getElementsByClassName(
                this.getElementNameFromSelectorClass());
        if (element == null || element.item == null) {
            Logger.debug("element not found [name=" + this.getSelector() + "]");
            return null!;
        } else {
            return element.item(0)!;
        }
    }

    protected getElementNameFromSelectorId(): string {
        return this.getSelector().replace("#", "");
    }

    protected getElementNameFromSelectorClass(): string {
        return this.getSelector().replace(".", "");
    }

    public executeEventLink(mode: IPageMode): void {
        var linksTarget = this.getEventLinks();
        for (var i = 0; i < this.eventLinkNum(); i++) {
            //linksTarget[i].eventLink(mode); 
            linksTarget[i].eventLink(<IEventLinkParams>({ selector: this.getSelector(), pageMode: mode }));
        }
    }

    public setSelector(selector: string): void {
        this._selector = selector;
    }

    public getSelector(): string {
        return this._selector;
    }

    public setLabel(label: string): void { this._label = label; }
    public getLabel(): string { return this._label; }

    public abstract execute(): void;
}
