import React from 'react';
import classnames from 'classnames';
import { Modal, ModalHeader, ModalBody, ModalProps, ModalFooter } from 'reactstrap';

import IconButton from '../IconButton/IconButton';
import Close from '../../../atoms/Icon/Close';

export type popupProps = ModalProps & {
  header?: string | React.ReactElement;
  headerIcon?: React.ReactElement;
  headerColor?: string;
  footer?: string | React.ReactElement;
  closeIconTabIndex?: number;
  closeIconRef?: React.RefObject<any>;
  centered?: boolean;
  notMaxHeight?: boolean;
  escClose?: boolean;
  innerRef?: React.RefObject<any>;
  scrollDisabled?: boolean;
  onCloseClick?: () => void;
  onBackdropClick?: () => void;
};

const Popup: React.FC<popupProps> = props => {
  const {
    header,
    headerIcon,
    headerColor,
    footer,
    className,
    wrapClassName,
    children,
    closeIconTabIndex,
    closeIconRef,
    scrollDisabled,
    onCloseClick,
    onBackdropClick
  } = props;

  // イベントのコールバック関数にて使用する状態を保持
  const [eventState] = React.useState({
    escClose: props.escClose
  });
  React.useEffect(() => {
    eventState.escClose = props.escClose;
  }, [props.escClose]);

  const tempInnerRef =  React.useRef<any>()
  const innerRef = typeof props.innerRef === 'undefined' ? tempInnerRef : props.innerRef;
  /** キーボード操作（キーダウン） */
  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.keyCode === 27) {
      if (eventState.escClose !== false) {
        if (typeof onCloseClick === 'function') {
          onCloseClick();
        }
      }
      // イベントをキャンセル
      event.preventDefault();
      event.stopPropagation();
    }
  };

  React.useEffect(() => {
    if (props.isOpen) {
      setTimeout(() => {
        if (innerRef.current) {
          // ポップアップにキーダウンイベントのフック
          innerRef.current.addEventListener('keyup', handleKeyUp);
        }
      });
    }
  }, [props.isOpen]);

  const headerColorClass = 'bg-' + (headerColor ? headerColor : 'tertiary');
  const headerClose = headerIcon ? (
    headerIcon
  ) : (
    <IconButton
      className='text-white'
      icon={<Close className='icon-lg' />}
      onClick={onCloseClick}
      innerRef={closeIconRef}
      tabIndex={closeIconTabIndex}
    />
  );
  const footerElement = footer ? <ModalFooter>{footer}</ModalFooter> : '';
  const centered = typeof props.centered === 'undefined' ? true : props.centered;
  const modalClass = classnames(className, props.notMaxHeight === true ? '' : '_popupMaxHeight');

  // Modalタグに不要なpropsを除去
  const modalProps = { ...props };
  delete modalProps['header'];
  delete modalProps['headerIcon'];
  delete modalProps['headerColor'];
  delete modalProps['footer'];
  delete modalProps['escClose'];
  delete modalProps['notMaxHeight'];
  delete modalProps['onCloseClick'];
  delete modalProps['onBackdropClick'];
  delete modalProps['closeIconTabIndex'];
  delete modalProps['closeIconRef'];
  delete modalProps['scrollDisabled'];

  return (
    <Modal
      {...modalProps}
      innerRef={innerRef}
      className={modalClass}
      toggle={onBackdropClick}
      centered={centered}
      wrapClassName={classnames(wrapClassName, 'popup-wrap')}
    >
      <ModalHeader
        className={classnames(headerColorClass, 'text-white d-flex align-items-center py-1')}
        close={headerClose}
      >
        {header}
      </ModalHeader>
      <ModalBody className={scrollDisabled ? '' : 'overflow-auto'}>{children}</ModalBody>
      {footerElement}
    </Modal>
  );
};
export default Popup;
