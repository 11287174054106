import React from 'react';
import Axios from 'axios';
import { Button } from 'reactstrap';
import * as labels from '../../../constants/labels';
import Popup from '../../../components/molecules/CMN/Popup/Popup';
import Spinner from '../../../components/atoms/Icon/Spinner';
import ExecutingCancel from '../../../components/molecules/CMN/ExecutingCancel/ExecutingCancel';
import { AuditJournalDoubleExcludeItemSettingViewModel } from '../../../models/auditJournalDoubleExcludeItemSettingViewModel';
import ExcludeKmkSetting from '../../../components/molecules/A040/ExcludeKmkSetting';
import { FlexGrid } from 'wijmo/wijmo.react.grid';
import { JDExcludeKmkSettingVO } from '../../../models/jDExcludeKmkSettingVO';
import * as wjGrid from 'wijmo/wijmo.grid';
import ConfirmationPopup from '../../../components/molecules/CMN/ConfirmationPopup/ConfirmationPopup';
import { UpperActionLayout } from '../../../components/templates/UpperActionLayout';
import { Common_Confirmation_Edited_Import } from '../../../constants/message';

type ExclusionItemSettingProps = {
  activated: boolean;
  shareIsLoading?: boolean;
  onClose: () => void;
};

// 仕訳重複除外項目設定向けコンポーネント初期化処理URL
const baseUrl = '/api/v1/AuditJournalDouble';
// 初期化
export const initialUrl = [baseUrl, 'IntialExclusioItemSetting'].join('/');
// 登録・更新
export const registAndUpdateUrl = [baseUrl, 'UpdateExclusioItemSetting'].join('/');
// tabIndexの初期値
const baseTabIndex = 8000;

// viewModel
const initializationViewModel: AuditJournalDoubleExcludeItemSettingViewModel = {
  // 除外科目(勘定科目)
  JDExcludeKmkItemList: []
};

const ExclusionItemSetting: React.FC<ExclusionItemSettingProps> = props => {
  // viewModel
  const [viewModel, setViewModel] = React.useState<AuditJournalDoubleExcludeItemSettingViewModel>(initializationViewModel);
  // 保存前（更新前）のviewModel
  const [oldViewModel, setOldViewModel] = React.useState<AuditJournalDoubleExcludeItemSettingViewModel>(initializationViewModel);
  // リクエスト実行中ステータス管理
  const [isRunningRequest, setIsRunningRequest] = React.useState(false);
  // 編集確認アラート起動ステータス
  const [confirmActivated, setConfirmActivated] = React.useState(false);
  // エラーメッセージ
  const [errorMessage, setErrorMessage] = React.useState('');
  // popup自体の要素格納
  const popupRef = React.useRef<any>();
  // グリッド参照用（itemSource参照向け）
  const gridRef = React.createRef<FlexGrid>();
  // グリッド参照（フォーカス遷移向け）
  const gridInnerRef = React.useRef<any>();
  const gridInnerRefFocusIndex = baseTabIndex + 1;
  // 全解除ボタンの要素（フォーカス遷移向け）
  const unSelectAllButtonRef = React.useRef<any>();
  const unSelectAllButtonRefFocusIndex = baseTabIndex + 2;
  // 保存ボタンの要素（フォーカス遷移向け）
  const saveButtonRef = React.useRef<any>();
  const saveButtonRefFocusIndex = baseTabIndex + 3;
  // 取消ボタンの要素（フォーカス遷移向け）
  const cancelButtonRef = React.useRef<any>();
  const cancelButtonRefFocusIndex = baseTabIndex + 4;
  // tabキーの最後のtabIndexの要素格納
  const lastFocusElement = React.useRef<any>();
  const lastFocusElementFocusIndex = baseTabIndex + 5;

  // フォーカス遷移配列(ポップアップ二重表示による裏要素にフォーカスが当たる問題を回避する向け)
  // インデックスがフォーカス遷移順
  const focusRefArray = [
    {
      "tabIndex" : gridInnerRefFocusIndex,
      "ref"      : gridInnerRef
    },
    {
      "tabIndex" : unSelectAllButtonRefFocusIndex,
      "ref"      : unSelectAllButtonRef
    },
    {
      "tabIndex" : saveButtonRefFocusIndex,
      "ref"      : saveButtonRef,
    },
    {
      "tabIndex" : cancelButtonRefFocusIndex,
      "ref"      : cancelButtonRef,
    },
    {
      "tabIndex" : lastFocusElementFocusIndex,
      "ref"      : lastFocusElement
    },
  ];
    
  /**
   * 初期データ取得 
   */
  const getInitialData = (url: string) => {
    if (!props.shareIsLoading) {
      // 呼び出し元と「setIsLoading」を共有していない場合は「setIsLoading」をオフに設定
      setIsRunningRequest(true);
    }
    Axios.get<AuditJournalDoubleExcludeItemSettingViewModel>(url).then(response => {
      // 初期表示用ViewModelを設定
      setViewModel(response.data);
      // 同時に編集前の情報も保持
      setOldViewModel(deepCopyViewModel(response.data));
      if (!props.shareIsLoading && isRunningRequest) {
        // 呼び出し元と「setIsLoading」を共有せず、ロード中の場合は「setIsLoading」をオフに設定
        setIsRunningRequest(false);
      }
    }).catch(error => {
      console.log(error);
    });
  };

  /**
   * 編集前のViewModel保存向けディープコピー処理
   * 環境構築手順書的にpackage.jsonを修正を実施したくないのでライブラリ使わず実装
   * 後の改良でライブラリを使ってUtilを作成してもよいかもしれません
   * @param vm
   * @returns AuditJournalDoubleExcludeItemSettingViewModel(deepClonedViewModel)
   */
  const deepCopyViewModel = (vm: AuditJournalDoubleExcludeItemSettingViewModel) => {
    // 大元をDeepCopyする（配列やオブジェクトはShallow Clone扱い）
    const copyViewModel = { ...vm };
    // 除外科目をdeepCopy
    if (vm.JDExcludeKmkItemList != null && vm.JDExcludeKmkItemList.length > 0) {
      // 一旦配列をクリア（Shallow Clone防止）
      copyViewModel.JDExcludeKmkItemList = [];
      // 配列再設定（これでDeep Cloneらしく動作）
      vm.JDExcludeKmkItemList.forEach(vo => {
        copyViewModel.JDExcludeKmkItemList!.push({ ...vo });
      });
    }
    return copyViewModel;
  };

  /**
   * 表示項目の初期化
   */
  const handleReset = () => {
    setIsRunningRequest(false);
    setErrorMessage('');
  };

  /**
   * 保存ボタン押下時の処理
   */
  const handleOnSaveClick = () => {
    setErrorMessage('');
    if (gridRef.current != null) {
      setIsRunningRequest(true);
      var registViewModel: AuditJournalDoubleExcludeItemSettingViewModel = {
        JDExcludeKmkItemList: gridRef.current.props.itemsSource.slice(0) as JDExcludeKmkSettingVO[]
      };
      // リクエスト実行
      saveOnPost(registAndUpdateUrl, registViewModel);
    }
  };

  /**
   * 保存処理
   * @param url
   * @param reqParam
   */
  const saveOnPost = (url: string, reqParam: AuditJournalDoubleExcludeItemSettingViewModel) => {
    Axios.post(url, reqParam)
      .then(response => {
        if (response.status == 200) {
          // 正常終了の場合はポップアップを終了する
          // 初期表示用ViewModelを設定
          setViewModel(reqParam);
          // 同時に編集前の情報も保持
          setOldViewModel(deepCopyViewModel(reqParam));
          // ボタン系を活性状態に変更
          setIsRunningRequest(false);
          // 除外項目設定終了
          props.onClose();
        }
    }).catch(error => {
      // 異常終了（更新処理の為、更新処理中にLANケーブル抜く等レアケースだが念には念をで用意だけしている。）
      // キャッチしてメッセージを表示する
      setErrorMessage(error.response.data.message);
      // ボタン系を活性状態に変更
      setIsRunningRequest(false);
      // 再初期化する
      getInitialData(initialUrl);
    });
  };

  /**
   * キーボード操作（キーダウン
   */
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 9) {
      // tabキーによるフォーカス遷移を一部コントロールする
      // 以下の処理がないとブラウザの枠等不要なフォーマット遷移をしてしまう為
      if (gridInnerRef.current != null) {
        if (gridInnerRef.current.children) {
          // グリッドが最初の子要素
          // 本当は「gridRef」を使用した方がよいがなぜかaddEventListenerでは参照失敗するためこの方法を使用。
          var firstFocusElement = gridInnerRef.current.children[0];
          // グリッドの親要素が取得できた場合のみフォーカス遷移を実行
          if (event.shiftKey === false) {
            // タブキー押下時
            const target: HTMLElement = event.target as HTMLElement;
            if (target.tabIndex === lastFocusElement.current.tabIndex) {
              // 最後のフォーカスエレメントの場合
              // 最初のエレメントにフォーカス
              firstFocusElement.focus();

              // 後のイベントをキャンセル
              event.preventDefault();
              event.stopPropagation();
            } else if (
              target.tabIndex < firstFocusElement.tabIndex ||
              target.tabIndex > lastFocusElement.current.tabIndex
            ) {
              // フォーカスが当たっていない場合
              // 最初のエレメントにフォーカス
              firstFocusElement.focus();

              // 後のイベントをキャンセル
              event.preventDefault();
              event.stopPropagation();
            } else {
              var targetIndex = focusRefArray.findIndex(element => element.tabIndex == target.tabIndex);
              if (targetIndex + 1 == focusRefArray.length) {
                // 先頭に移動する場合はグリッドにフォーカス
                firstFocusElement.focus();
                // 後のイベントをキャンセル
                event.preventDefault();
                event.stopPropagation();
              } else if (targetIndex > 0) {
                focusRefArray[targetIndex + 1].ref.current.focus();

                // 後のイベントをキャンセル
                event.preventDefault();
                event.stopPropagation();
              }
            }
          } else {
            // シフト＋タブキー押下時
            const target: HTMLElement = event.target as HTMLElement;
            if (target.tabIndex === firstFocusElement.tabIndex) {
              // 最後のフォーカスエレメントの場合
              // 最後のエレメントにフォーカス
              lastFocusElement.current.focus();

              // 後のイベントをキャンセル
              event.preventDefault();
              event.stopPropagation();
            } else if (
              target.tabIndex < firstFocusElement.tabIndex ||
              target.tabIndex > lastFocusElement.current.tabIndex
            ) {
               // フォーカスが当たっていない場合
               // 最後のエレメントにフォーカス
               lastFocusElement.current.focus();

               // 後のイベントをキャンセル
               event.preventDefault();
               event.stopPropagation();
            } else {
              var targetIndex = focusRefArray.findIndex(element => element.tabIndex == target.tabIndex);
              if (targetIndex - 1 == 0) {
                // 先頭に移動する場合はグリッドにフォーカス
                firstFocusElement.focus();
                // 後のイベントをキャンセル
                event.preventDefault();
                event.stopPropagation();
              } else if (targetIndex > 0) {
                focusRefArray[targetIndex - 1].ref.current.focus();

                // 後のイベントをキャンセル
                event.preventDefault();
                event.stopPropagation();
              }
            }
          }
        } 
      }
    }
  };

  /**
   * 画面が表示された実行する処理(月選択コンボボックス内容更新) 
   */
  React.useEffect(() => {
    // 初期データ取得処理起動
    getInitialData(initialUrl);
  }, [initialUrl]);

  /** 
   *  画面が表示された実行する処理(ポップアップ表示時のtabキーボタン設定)
   */
  React.useEffect(() => {
    if (props.activated) {
      // 表示項目のクリア
      handleReset();
      // 画面表示完了後に実行
      setTimeout(() => {
        if (popupRef.current) {
          // ポップアップにキーダウンイベントのフック
          popupRef.current.addEventListener('keydown', handleKeyDown);
        }
      });
    }
  }, [props.activated]);

  /** 
   *  全解除ボタン押下時の処理
   */
  const onUnSelectAllClick = () => {
    if (gridRef.current != null) {
      // グリッド参照が正しく設定された場合に処理させる
      // ViewModelを更新させて、チェックボックスの値をコントロールさせる
      const controlGrid = gridRef.current.control as wjGrid.FlexGrid;
      controlGrid.beginUpdate();
      // viewModelの除外科目設定チェックを「false」に変更する
      var updateModel = viewModel;
      updateModel.JDExcludeKmkItemList!.forEach(oneData => (oneData.ExcludeFlg = false));
      // viewModel更新
      setViewModel(updateModel);
      // グリッドの更新終了→チェック解除がこのタイミングで実施される
      controlGrid.endUpdate();
    }
  };

  /** 
   *  閉じるボタン押下時の処理
   */
  const handleOnCloseClick = () => {
    editCheckerFunc();
  };

  /**
   * Escキー押下時の終了処理
   */ 
  const getEscClose = () => {
    return !isRunningRequest;
  };

  /**
   * 未保存のデータがある場合のチェック処理+事後処理
   */
  const editCheckerFunc = () => {
    if (checkEditedViewModel(viewModel, oldViewModel)) {
      setConfirmActivated(true);
    } else {
      // 変更差分がなく、リクエスト実施なしの場合は除外項目終了
      if (!isRunningRequest) {
        props.onClose();
      }
    }
  };

  /**
   * 編集前後の状態をチェックしその結果を返却する
   * @param newVM 入力後パラメータ
   * @param oldVM 入力前パラメータ
   * @returns result (true…編集差分あり / false…編集差分なし)
   */
  const checkEditedViewModel = (
    newVM: AuditJournalDoubleExcludeItemSettingViewModel,
    oldVM: AuditJournalDoubleExcludeItemSettingViewModel
  ) => {
    let result = false;
    // キーリスト取得
    let keyList = Object.getOwnPropertyNames(newVM);
    // 新旧データ比較
    for (let i = 0; i < keyList.length; i++){
      const oneNewData = newVM[keyList[i]];
      const oneOldData = oldVM[keyList[i]];
      if (
        oneNewData != null
        && oneOldData != null
        && oneNewData.length == oneOldData.length
      ) {
        for (let i2 = 0; i2 < oneNewData.length ;i2++) {
          if (oneNewData[i2].ExcludeFlg != oneOldData[i2].ExcludeFlg) {
            // 編集差分がある
            result = true;
            // 編集差分が一つ以上あればよいのでこれ以上は処理させない
            break;
          }
        }
      }
      if (result) {
        // 編集差分が一つ以上あればよいのでこれ以上は処理させない
        break;
      }
    }
    return result;
  };

  /**
   * 編集差分アラートの「×」押下時
   */
  const handleConfirmOnClose = () => {
    // いいえと同じで何も処理させない
    handleConfirmOnNoClick();
  };

  /**
   * 編集差分アラートの「はい」押下時
   */
  const handleConfirmOnYesClick = () => {
    // 編集モデルを初期化する
    setViewModel(deepCopyViewModel(oldViewModel));
    // 編集アラートを非表示にする
    setConfirmActivated(false);
    // 除外科目設定ウィンドウを終了
    props.onClose();
  };

  /**
   * 編集差分アラートの「いいえ」押下時
   */
  const handleConfirmOnNoClick = () => {
    // 編集アラートを非表示にする（何もしない）
    setConfirmActivated(false);
  };

  /**
   * リクエスト実行中のラベル
   */ 
  const printingLabel = isRunningRequest ? (
    <span>
      <span className='_spinner _rotation mr-2'>
        <Spinner className='icon-lg' />
      </span>
      {'保存中'}
    </span>
  ) : (
    <span className='text-danger'>{errorMessage}</span>
  );

  return (
    <UpperActionLayout className='exclude-item-setting'>
      <Popup
        isOpen={props.activated}
        escClose={getEscClose()}
        onCloseClick={handleOnCloseClick}
        header={labels.AIKADT002000001_BUTTON_FUNCTION_EXCLUSIONITEMSETTING}
        size='lg'
        closeIconTabIndex={lastFocusElementFocusIndex}
        closeIconRef={lastFocusElement}
        innerRef={popupRef}
        className={'execlusion-item-settins-popup'}
        footer={
        <div className='footer-area'>
          <div className='alert-area'>
            {printingLabel}
          </div>
          <div className='button-area'>
            <Button
              onClick={onUnSelectAllClick}
              tabIndex={unSelectAllButtonRefFocusIndex}
              color='primary'
              className='unselect-all-button A3-btn'
              disabled={isRunningRequest}
              innerRef={unSelectAllButtonRef}
            >
              {labels.COMMON_BUTTON_FUNCTION_UNSELECTALL}
            </Button>
            <ExecutingCancel
              className='text-nowrap'
              executeLabel={labels.COMMON_BUTTON_FUNCTION_SAVE}
              executeDisabled={isRunningRequest}
              executeTabIndex={saveButtonRefFocusIndex}
              onExecuteClick={handleOnSaveClick}
              executeRef={saveButtonRef}
              cancelLabel={labels.COMMON_BUTTON_FUNCTION_CLOSE}
              cancelDisabled={isRunningRequest}
              cancelTabIndex={cancelButtonRefFocusIndex}
              onCancelClick={handleOnCloseClick}
              cancelRef={cancelButtonRef}
              />
          </div>
        </div>
        }
      >
        <div className='d-flex flex-row kmk-grid-area'>
          <ExcludeKmkSetting
            resultDetail={viewModel.JDExcludeKmkItemList!}
            tabIndex={gridInnerRefFocusIndex}
            noFlexDesignFlg={true}
            gridReactRef={gridRef}
            innerRef={gridInnerRef}>
          </ExcludeKmkSetting>
        </div>
      </Popup>
      <ConfirmationPopup
        isOpen={confirmActivated}
        onClose={handleConfirmOnClose}
        onYesClick={handleConfirmOnYesClick}
        onNoClick={handleConfirmOnNoClick}>
        <span className='white-space-pre-wrap'>{Common_Confirmation_Edited_Import}</span>
      </ConfirmationPopup>
    </UpperActionLayout>
  );
};
export default ExclusionItemSetting;
