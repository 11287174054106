import React from 'react';
import * as wj from 'wijmo/wijmo';
import * as wjGrid from 'wijmo/wijmo.grid';
import * as wjFilter from 'wijmo/wijmo.grid.filter';
import { FlexGrid } from 'wijmo/wijmo.react.grid';
import { FlexGridFilter } from 'wijmo/wijmo.react.grid.filter';

import * as labels from '../../../../constants/labels';
import { convertItemsSourceToDateType } from '../../../../utils/wijmoGridUtils';
import { Button, ButtonGroup } from 'reactstrap';

export type sortLabelMap = {
  key: any;
  value: string;
};
export type sortLabel = {
  binding: string;
  map: sortLabelMap[];
};

export type AllSelectableGridProp = {
  columns: any[];
  sources?: any[];
  disabled?: boolean;
  frozenColumns?: number;
  sortLabelMapList?: sortLabel[];
  gridClassName?: string;
  gridTabIndex?: number;
  buttonSelectionTabIndex?: number;
  buttonDeselectionTabIndex?: number;
  buttonSelectionRef?: React.RefObject<any>;
  buttonDeselectionRef?: React.RefObject<any>;
  onFilterChanged?: (filterJson: string) => void;
};

type AllSelectableGridState = {};

class AllSelectableGrid extends React.Component<AllSelectableGridProp, AllSelectableGridState> {
  grid?: wjGrid.FlexGrid;
  filter?: wjFilter.FlexGridFilter;

  constructor(props: AllSelectableGridProp) {
    super(props);
    this.initializedFilter = this.initializedFilter.bind(this);
    this.handleFilterApplied = this.handleFilterApplied.bind(this);
    this.initializedGrid = this.initializedGrid.bind(this);
    this.handleItemFormatter = this.handleItemFormatter.bind(this);
    this.handleOnAllSelectonClick = this.handleOnAllSelectonClick.bind(this);
  }

  // フィルターの初期設定
  public initializedFilter = (flexFilter: wjFilter.FlexGridFilter) => {
    this.filter = flexFilter;
  };
  // フィルターが変更されたときの処理
  public handleFilterApplied = (f: wjFilter.FlexGridFilter) => {
    if (typeof this.props.onFilterChanged === 'function') {
      this.props.onFilterChanged(f.filterDefinition);
    }
  };

  // Gridの初期設定
  public initializedGrid = (flexGrid: wjGrid.FlexGrid) => {
    this.grid = flexGrid;
  };

  // ItemFormatter
  public handleItemFormatter = (panel: wjGrid.GridPanel, row: number, col: number, cell: HTMLElement) => {
    if (panel.cellType === wjGrid.CellType.ColumnHeader) {
      // ヘッダ
      cell.classList.add('d-flex');
      cell.classList.add('justify-content-center');
      cell.classList.add('align-items-center');
      cell.classList.add('font-weight-normal');
    }
  };

  // 全選択／選択ボタン押下時の処理
  public handleOnAllSelectonClick = (flg: boolean) => {
    if (this.grid) {
      this.grid.beginUpdate();
      const cv = this.grid.collectionView as wj.CollectionView;
      const allSelectableCol = this.grid.columns.filter(col => col.inputType === 'allSelectable');
      cv.items.forEach(item => {
        allSelectableCol.forEach(col => (item[col.binding] = flg));
      });
      this.grid.endUpdate();
    }
  };

  private filterSetting() {
    if (this.filter != null) {
      if (this.props.sortLabelMapList != null) {
        this.props.sortLabelMapList.forEach(item => {
          this.filter!.getColumnFilter(item.binding).dataMap = new wjGrid.DataMap(item.map, 'key', 'value');
        });
      }
    }
  }

  public componentDidMount() {
    this.filterSetting();
  }

  public componentDidUpdate() {
    this.filterSetting();
  }

  public render() {
    const {
      columns,
      sources,
      disabled,
      gridClassName,
      gridTabIndex,
      buttonSelectionTabIndex,
      buttonDeselectionTabIndex,
      buttonSelectionRef,
      buttonDeselectionRef
    } = this.props;
    convertItemsSourceToDateType(columns, sources!);

    const frozenColumns = this.props.frozenColumns == null ? 0 : this.props.frozenColumns;
    return (
      <span>
        <ButtonGroup>
          <Button
            className='h5 py-0'
            color='primary'
            outline={true}
            disabled={disabled}
            tabIndex={buttonSelectionTabIndex}
            innerRef={buttonSelectionRef}
            onClick={() => this.handleOnAllSelectonClick(true)}
          >
            {labels.COMMON_BUTTON_FUNCTION_SELECTALL}
          </Button>
          <Button
            className='h5 py-0'
            color='primary'
            outline={true}
            disabled={disabled}
            tabIndex={buttonDeselectionTabIndex}
            innerRef={buttonDeselectionRef}
            onClick={() => this.handleOnAllSelectonClick(false)}
          >
            {labels.COMMON_BUTTON_FUNCTION_UNSELECTALL}
          </Button>
        </ButtonGroup>
        <FlexGrid
          className={gridClassName}
          autoGenerateColumns={false}
          columns={columns}
          allowResizing={wjGrid.AllowResizing.None}
          allowDragging={wjGrid.AllowDragging.None}
          selectionMode={wjGrid.SelectionMode.Row}
          headersVisibility={wjGrid.HeadersVisibility.Column}
          alternatingRowStep={0}
          frozenColumns={frozenColumns}
          isReadOnly={disabled}
          itemsSource={sources}
          initialized={this.initializedGrid}
          itemFormatter={this.handleItemFormatter}
          tabIndex={gridTabIndex}
        >
          <FlexGridFilter initialized={this.initializedFilter} filterApplied={this.handleFilterApplied} />
        </FlexGrid>
      </span>
    );
  }
}

export default AllSelectableGrid;
