import React from 'react';
import Axios from 'axios';
import { Input, FormGroup, Label } from 'reactstrap';

import * as fileUtils from '../../../utils/fileUtils';
import * as labels from '../../../constants/labels';
import { PROGRESSINTERVAL } from '../../../constants/constant';
import { BatStatusEnum } from '../../../constants/enums';
import { OutputInformationViewModel } from '../../../models/outputInformationViewModel';
import Popup from '../../../components/molecules/CMN/Popup/Popup';
import Spinner from '../../../components/atoms/Icon/Spinner';
import ExecutingCancel from '../../../components/molecules/CMN/ExecutingCancel/ExecutingCancel';

type ExcelOutputsettingProps = {
  activated: boolean;
  templateId: string;
  filters?: string;
  headerLeft?: string;
  headerCenter?: string;
  headerRight?: string;
  footerLeft?: string;
  footerCenter?: string;
  footerRight?: string;
  serviceParams?: string;
  popupTitle?: string;
  onClose: () => void;
};

// URL
const baseUrl = '/api/v1/ExcelOutputPreference';
export const createUrl = [baseUrl, 'Create'].join('/');
export const fileResultUrl = [baseUrl, 'FileResult'].join('/');
export const fileUrl = [baseUrl, 'File'].join('/');

// 項目ラベルのCSS Class
const labelClass = 'text-lbl';

// tabIndexの初期値
const baseTabIndex = 1000;

const ExcelOutputsetting: React.FC<ExcelOutputsettingProps> = props => {
  const [isPrinting, setIsPrinting] = React.useState(false);
  const [companyCode, setCompanyCode] = React.useState(false);
  const [companyName, setCompanyName] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState('');

  /** 画面のステータスよりビューモデルを作成します。 */
  const createViewModel = () => {
    const viewModel: OutputInformationViewModel = {
      TemplateId: props.templateId,
      HeaderLeft: props.headerLeft,
      HeaderCenter: props.headerCenter,
      HeaderRight: props.headerRight,
      FooterLeft: props.footerLeft,
      FooterCenter: props.footerCenter,
      FooterRight: props.footerRight,
      PassFlg: false,
      FilterFlg: false,
      ClientCodeFlg: companyCode,
      ClientNameFlg: companyName,
      ServiceParams: props.serviceParams
    };

    return viewModel;
  };

  /**
   * 表示項目の初期化
   */
  const handleReset = () => {
    setIsPrinting(false);
    setCompanyCode(false);
    setCompanyName(false);

    setErrorMessage('');
  };

  /** 出力ボタン押下時の処理 */
  const handleOnPrintClick = () => {
    const viewModel = createViewModel();
    createOnPost(createUrl, viewModel);
    setIsPrinting(true);
  };

  /** Excel帳票作成処理を実行 */
  const createOnPost = (url: string, viewModel: OutputInformationViewModel) => {
    Axios.post(url, { outputInformationViewModel: viewModel })
      .then(response => {
        fileResultOnPost(fileResultUrl, String(response.data));
      })
      .catch(error => {
        setIsPrinting(false);
        setErrorMessage(error.response.data.message);
      });
  };

  /** バッチの進捗確認を実行 */
  const fileResultOnPost = (url: string, guid: string) => {
    const encodedUrl = [url, encodeURI(guid.toString())].join('/');
    Axios.post(encodedUrl)
      .then(response => {
        if (BatStatusEnum.Processing == response.data) {
          window.setTimeout(fileResultOnPost, PROGRESSINTERVAL, url, guid);
        } else {
          fileOnPost(fileUrl, guid);
        }
      })
      .catch(error => {
        setIsPrinting(false);
        setErrorMessage(error.response.data.message);
      });
  };

  /** ファイルダウンロードを実行 */
  const fileOnPost = (url: string, guid: string) => {
    const encodedUrl = [url, encodeURI(guid.toString())].join('/');
    Axios.get(encodedUrl, { responseType: 'blob' })
      .then(response => {
        if (response.data != undefined) {
          fileUtils.downloadFileFromResponse(response);
        }

        // ポップアップを閉じる
        props.onClose();
      })
      .catch(error => {
        setIsPrinting(false);
        setErrorMessage(error.response.data.message);
      });
  };

  const firstFocusElement = React.useRef<any>();
  const lastFocusElement = React.useRef<any>();
  const popupRef = React.useRef<any>();

  /** キーボード操作（キーダウン） */
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 9) {
      if (event.shiftKey === false) {
        // タブキー押下時

        const target: HTMLElement = event.target as HTMLElement;

        if (target.tabIndex === lastFocusElement.current.tabIndex) {
          // 最後のフォーカスエレメントの場合

          // 最初のエレメントにフォーカス
          firstFocusElement.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        } else if (
          target.tabIndex < firstFocusElement.current.tabIndex ||
          target.tabIndex > lastFocusElement.current.tabIndex
        ) {
          // フォーカスが当たっていない場合

          // 最初のエレメントにフォーカス
          firstFocusElement.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
      } else {
        // シフト＋タブキー押下時

        const target: HTMLElement = event.target as HTMLElement;
        if (target.tabIndex === firstFocusElement.current.tabIndex) {
          // 最後のフォーカスエレメントの場合

          // 最後のエレメントにフォーカス
          lastFocusElement.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        } else if (
          target.tabIndex < firstFocusElement.current.tabIndex ||
          target.tabIndex > lastFocusElement.current.tabIndex
        ) {
          // フォーカスが当たっていない場合

          // 最後のエレメントにフォーカス
          lastFocusElement.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }
  };

  /** 画面が表示された実行する処理 */
  React.useEffect(() => {
    if (props.activated) {
      // 表示項目のクリア
      handleReset();

      // 画面表示完了後に実行
      setTimeout(() => {
        if (popupRef.current) {
          // ポップアップにキーダウンイベントのフック
          popupRef.current.addEventListener('keydown', handleKeyDown);
        }
      });
    }
  }, [props.activated]);

  /** 会社コードが変更された時の処理を実装 */
  const handleOnCompanyCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyCode(event.target.checked);
  };

  /** 会社名が変更された時の処理を実装 */
  const handleOnCompanyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(event.target.checked);
  };

  /** 閉じるボタン押下時の処理 */
  const handleOnCloseClick = () => {
    if (!isPrinting) {
      props.onClose();
    }
  };

  const getEscClose = () => {
    return !isPrinting;
  };

  const printingLabel = isPrinting ? (
    <span>
      <span className='_spinner _rotation mr-2'>
        <Spinner className='icon-lg' />
      </span>
      {labels.AIKCMN001000002_FUNCTION_NAME_INOUTPUT}
    </span>
  ) : (
    <span className='text-danger'>{errorMessage}</span>
  );

  const companyCheckboxGroup = ( props.templateId == 'AIKCMN006000001_E01' || props.templateId == 'AIKCMN007000001_E01' || props.templateId == 'AIKADT006000001_E01' || props.templateId == 'AIKADT006000001_E02') ? (
    <FormGroup></FormGroup>
  ) : (
    <FormGroup>
      <Label className={labelClass}>
        <b>{labels.AIKCMN001000002_INPUT_HEADER_COMPANYINFORMATION}</b>
      </Label>
      <div className='d-flex flex-row'>
        <FormGroup check>
          <Label check>
            <Input
              type='checkbox'
              name='companyCode'
              innerRef={firstFocusElement}
              checked={companyCode}
              onChange={handleOnCompanyCodeChange}
              disabled={isPrinting}
              tabIndex={baseTabIndex + 1}
            />
            {labels.AIKCMN001000002_INPUT_HEADER_COMPANYCODE}
          </Label>
        </FormGroup>
        <FormGroup check className='ml-3'>
          <Label check>
            <Input
              type='checkbox'
              name='companyName'
              checked={companyName}
              onChange={handleOnCompanyNameChange}
              disabled={isPrinting}
              tabIndex={baseTabIndex + 2}
            />
            {labels.AIKCMN001000002_INPUT_HEADER_COMPANYNAME}
          </Label>
        </FormGroup>
      </div>
    </FormGroup>
  )

  return (
    <Popup
      isOpen={props.activated}
      escClose={getEscClose()}
      onCloseClick={handleOnCloseClick}
      header={props.popupTitle ?? labels.AIKCMN001000002_FUNCTION_NAME}
      size='sm'
      closeIconTabIndex={baseTabIndex + 5}
      closeIconRef={lastFocusElement}
      innerRef={popupRef}
    >
      {companyCheckboxGroup}
      <div className='d-flex justify-content-between'>
        <div>{printingLabel}</div>
        <div>
          <ExecutingCancel
            className='text-nowrap'
            executeLabel={labels.AIKCMN001000002_BUTTON_FUNCTION_OUTPUT}
            executeDisabled={isPrinting}
            executeTabIndex={baseTabIndex + 3}
            onExecuteClick={handleOnPrintClick}
            cancelLabel={labels.COMMON_BUTTON_FUNCTION_CLOSE}
            cancelDisabled={isPrinting}
            cancelTabIndex={baseTabIndex + 4}
            onCancelClick={handleOnCloseClick}
          />
        </div>
      </div>
    </Popup>
  );
};
export default ExcelOutputsetting;
