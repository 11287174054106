import { HashMap } from "../../functionPanel/collection/HashMap";
import { ButtonCell, IButtonCell, ICell } from "./ICell";
import * as wjGrid from 'wijmo/wijmo.grid';
import * as wijmo from 'wijmo/wijmo';
import { Logger } from "../../../common/usefuls/logger";

/**
 * TODO:
    [ ]
 */
export abstract class AbstractGrid {
    private $scope: any;
    protected $timeout: any;
    private gridOption!: GridOption;
    private selectionMode!: number;
    //private allowDragging: number;
    //private allowResizing: number;
    private controlName: string;
    private gridBindName!: string;
    private timerForAsyncRefresh: any;

    protected cellMaps: HashMap<string, ICell>;
    /** セルチェンジでの移動かどうか（true: セルチェンジ、false: それ以外（クリックでの移動も含む） */
    protected isCellChange = false;

    private _isCellEditing!: boolean;
    public get isCellEditing(): boolean {
        return this._isCellEditing;
    }

    private _allowAsyncRefreshWhenSelectionChanged!: boolean;
    /** 選択セル移動時に非同期更新（grid.invalidateメソッド）を行うかどうか */
    public get allowAsyncRefreshWhenSelectionChanged(): boolean { return this._allowAsyncRefreshWhenSelectionChanged; }
    public set allowAsyncRefreshWhenSelectionChanged(value: boolean) { this._allowAsyncRefreshWhenSelectionChanged = value; }

    private _timeoutOfAsyncRefresh!: number;
    /** 選択セル移動時の非同期更新におけるtimeout時間 */
    public get timeoutOfAsyncRefresh(): number { return this._timeoutOfAsyncRefresh; }
    public set timeoutOfAsyncRefresh(value: number) { this._timeoutOfAsyncRefresh = value; }

    /**
     * コンストラクタ
     * @param $scope
     * @param gridOption
     */
    constructor($scope: any, $timeout: any, controlName: string = "flex", gridOption?: GridOption) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.controlName = controlName;
        this.cellMaps = new HashMap<string, ICell>();
        if (gridOption != null) {
            this.setGridOption(gridOption);
        }
        this.init();
        this.gridBindScope();
    }

    /**
     * 初期化処理
     */
    private init(): void {

        var self = this;

        this.allowAsyncRefreshWhenSelectionChanged = true;
        this.timeoutOfAsyncRefresh = 50;

        // グリッドの初期化
        this.scope[this.getFuncNameInitialized()] = (grid: wjGrid.FlexGrid) => {
            this.initialize();
            this.settingGridStyle(grid);
            this.eventBinding(grid);
        }

        // 初期表示に値がないとエラーが出るので一旦仮設定（実値はGridOptionの内容）
        this.scope[this.getNameSelectionMode()] = "None";
        this.scope[this.getNameAllowDragging()] = "Both";
        this.scope[this.getNameAllowResizing()] = "Both";
        this.scope[this.getNameAllowMerging()] = "None";

        // セルの選択イベント（選択変更されないとコールされない）
        this.scope[this.getFuncNameSelectionChanging()] = (grid: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs) => {
            this.beforeCellChangingEvent(e);
        }

        // セルの選択イベント（選択変更されないとコールされない）
        this.scope[this.getFuncNameSelectionChanged()] = (grid: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs) => {
            // 仕訳日記帳などでカスタムマージを生成するとrow -1でイベントが発生し、
            // StackOverflowの原因となるためここで回避
            if (e.row < 0) {
                return;
            }
            // セルクリック時と同じ処理をコール
            this.beforeCellChangeEvent(e.row, e.col);
        }

        // セルリサイズイベント（セルサイズが変更されていてクリックを話した時にイベントが発行される（サイズ変更ごとに呼ばれるわけではない））
        this.scope[this.getFuncNameResizedColum()] = (grid: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs) => {
            self.beforeCellResizeEvent(e);
        }

        // スクロールイベント（スクロールするごとにイベントが発生する）
        this.scope[this.getFuncNameScrollPositionChange()] = (sender: wjGrid.FlexGrid, e: wijmo.EventArgs) => {
            self.scrollEvent(e);
        }

        // 列入れ替え完了イベント（開始も取ること可能、必要があればいれます）
        this.scope[this.getFuncNameDraggedColumn()] = (sender: wjGrid.FlexGrid, e: wijmo.EventArgs) => {
            //Logger.debug("drag complete");
        }

        // アイテムフォーマッターの設定
        this.scope[this.getFuncNameItemFormatter()] = (gridPanel: wjGrid.GridPanel, rowIndex: number, columnIndex: number, cell: HTMLElement) => {
            if (gridPanel.cellType === wjGrid.CellType.ColumnHeader) {
                self.headStaticStyle(rowIndex, columnIndex, cell);
                self.headActiveStyle(rowIndex, columnIndex, cell);
                self.headerItemFormatter(rowIndex, columnIndex, cell);

            } else if (gridPanel.cellType === wjGrid.CellType.Cell) {
                self.cellStaticStyle(rowIndex, columnIndex, cell);
                self.cellActiveStyle(rowIndex, columnIndex, cell);
                self.cellItemFormatter(rowIndex, columnIndex, cell);
                self.cellItemEventHandler(rowIndex, columnIndex, cell);

            } else {
                //Logger.debug("基底クラスに処理が定義されていません[セルタイプ=" + gridPanel.cellType + "]");
            }
        }

        this.scope[this.getFuncNameGotFocus()] = (e: wijmo.EventArgs) => {
            this.gotFocusEvent(e);
        }

        this.scope[this.getFuncNameLostFocus()] = (e: wijmo.EventArgs) => {
            this.lostFocusEvent(e);
        }

        /**
         *
         * セルの編集処理終了イベント
         * 発火タイミングはcell-edit-endedより少し早いだけで、用途が思いつかないのでとりあえずこのまま
         *
         */
        this.scope[this.getFuncNameCellEditEnding()] = (grid: wjGrid.FlexGrid, e: wijmo.EventArgs) => {
            //Logger.debug("cell edit ending");
        }

        /**
         *
         * セルの編集処理終了中イベント
         *
         */
        this.scope[this.getFuncNameCellEditEnding()] = (grid: wjGrid.FlexGrid, e: wijmo.EventArgs) => {
            //Logger.debug("cell edit ended");
            var row = e["row"];
            var col = e["col"];
            var value = this.getValueByPosition(row, col, false);
            this.cellEditEndingHandler(value, row, col);
        }

        /**
         *
         * セルの編集処理終了イベント
         *
         */
        this.scope[this.getFuncNameCellEditEnded()] = (grid: wjGrid.FlexGrid, e: wijmo.EventArgs) => {
            //Logger.debug("cell edit ended");
            var row = e["row"];
            var col = e["col"];
            var value = this.getValueByPosition(row, col, false);
            this.cellEditEndedHandler(value, row, col);
        }
    }

    protected headStaticStyle(rowIndex: number, columnIndex: number, cell: HTMLElement): void {
        var bindingOrName = this.getCellId(columnIndex);
        var cellObject: ICell = this.getCellMapObject(bindingOrName);
        if (cellObject != null) {
            var style = cellObject.getHeadStyle();
            this.reflectStyle(style, cell);
        }
    }

    protected cellStaticStyle(rowIndex: number, columnIndex: number, cell: HTMLElement): void {
        var bindingOrName = this.getCellId(columnIndex);
        var cellObject: ICell = this.getCellMapObject(bindingOrName);
        if (cellObject != null) {
            var style = cellObject.getCellStyle();
            this.reflectStyle(style, cell);
        }
    }

    protected headActiveStyle(rowIndex: number, columnIndex: number, cell: HTMLElement): void {
    }

    protected cellActiveStyle(rowIndex: number, columnIndex: number, cell: HTMLElement): void {
        var bindingOrName = this.getCellId(columnIndex);
        var cellObject: ICell = this.getCellMapObject(bindingOrName);
        if (cellObject != null && this.rowSize() > 0) {
            var activeStyle = cellObject.reflectCellActiveStyle(
                rowIndex
                , columnIndex
                , this.getValue(bindingOrName, rowIndex, false)
                , cell
                , this
            );
            this.reflectStyle(activeStyle, cell);
        }
    }

    protected reflectStyle(style: CSSStyleDeclaration, cell: HTMLElement): void {
        if (style != null) {
            var properties = Object.getOwnPropertyNames(style);
            for (var i = 0; i < properties.length; i++) {
                var name = properties[i];
                cell.style[name] = style[name];
            }
        }
    }

    /**
     * wj-flex-grid-columディレクティブの属性：initializedに指定された関数名を返す
     * 注） initialized="gridInitialized(s)"となっていても返すのは「gridInitialized」となります
     * 画面内に複数グリッドが存在する場合はgetFuncName～メソッドをすべてオーバーライドする必要があります
     */
    protected getFuncNameInitialized() {
        return "gridInitialized";
    }

    /**
     * wj-flex-grid-columディレクティブの属性：resized-columnに指定された関数名を返す
     * 注） resized-column="gridInitialized(s)"となっていても返すのは「gridInitialized」となります
     * 画面内に複数グリッドが存在する場合はgetFuncName～メソッドをすべてオーバーライドする必要があります
     */
    protected getFuncNameResizedColum() {
        return "resizedColumn";
    }

    /**
     * wj-flex-grid-columディレクティブの属性：scroll-position-changeに指定された関数名を返す
     * 注） scroll-position-change="gridInitialized(s)"となっていても返すのは「gridInitialized」となります
     * 画面内に複数グリッドが存在する場合はgetFuncName～メソッドをすべてオーバーライドする必要があります
     */
    protected getFuncNameScrollPositionChange() {
        return "scrollPositionChanged";
    }

    /**
     * wj-flex-grid-columディレクティブの属性：dragged-columnに指定された関数名を返す
     * 注） dragged-column="gridInitialized(s)"となっていても返すのは「gridInitialized」となります
     * 画面内に複数グリッドが存在する場合はgetFuncName～メソッドをすべてオーバーライドする必要があります
     */
    protected getFuncNameDraggedColumn() {
        return "draggedColumn";
    }

    /**
     * wj-flex-grid-columディレクティブの属性：item-formatterに指定された関数名を返す
     * 注） item-formatter="gridInitialized(s)"となっていても返すのは「gridInitialized」となります
     * 画面内に複数グリッドが存在する場合はgetFuncName～メソッドをすべてオーバーライドする必要があります
     */
    protected getFuncNameItemFormatter() {
        return "itemFormatter";
    }

    /**
     * wj-flex-grid-columディレクティブの属性：cell-edit-endinに指定された関数名を返す
     * 注） cell-edit-endin="gridInitialized(s)"となっていても返すのは「gridInitialized」となります
     * 画面内に複数グリッドが存在する場合はgetFuncName～メソッドをすべてオーバーライドする必要があります
     */
    protected getFuncNameCellEditEnding() {
        return "cellEditEnding";
    }

    /**
     * wj-flex-grid-columディレクティブの属性：cell-edit-endeに指定された関数名を返す
     * 注） cell-edit-ende="gridInitialized(s)"となっていても返すのは「gridInitialized」となります
     * 画面内に複数グリッドが存在する場合はgetFuncName～メソッドをすべてオーバーライドする必要があります
     */
    protected getFuncNameCellEditEnded() {
        return "cellEditEnded";
    }

    /**
     * wj-flex-grid-columディレクティブの属性：selection-changingに指定された関数名を返す
     * 注） selection-changign="selectionChanging(e, s)"となっていても返すのは「selectionChanging」となります
     * 画面内に複数グリッドが存在する場合はgetFuncName～メソッドをすべてオーバーライドする必要があります
     */
    protected getFuncNameSelectionChanging() {
        return "selectionChanging";
    }

    /**
     * wj-flex-grid-columディレクティブの属性：selection-changedに指定された関数名を返す
     * 注） selection-changed="gridInitialized(s)"となっていても返すのは「gridInitialized」となります
     * 画面内に複数グリッドが存在する場合はgetFuncName～メソッドをすべてオーバーライドする必要があります
     */
    protected getFuncNameSelectionChanged() {
        return "selectionChanged";
    }

    /**
     * wj-flex-grid-columディレクティブの属性：got-focusに指定された関数名を返す
     * 注） selection-changed="gridInitialized(s)"となっていても返すのは「gridInitialized」となります
     * 画面内に複数グリッドが存在する場合はgetFuncName～メソッドをすべてオーバーライドする必要があります
     */
    protected getFuncNameGotFocus() {
        return "gotFocus";
    }

    /**
     * wj-flex-grid-columディレクティブの属性：lost-focusに指定された関数名を返す
     * 注） selection-changed="gridInitialized(s)"となっていても返すのは「gridInitialized」となります
     * 画面内に複数グリッドが存在する場合はgetFuncName～メソッドをすべてオーバーライドする必要があります
     */
    protected getFuncNameLostFocus() {
        return "lostFocus";
    }

    /**
     * wj-flex-grid-columディレクティブの属性：items-sourcedに指定された関数名を返す
     * 注） items-sourced="gridInitialized(s)"となっていても返すのは「gridInitialized」となります
     * 画面内に複数グリッドが存在する場合はgetFuncName～メソッドをすべてオーバーライドする必要があります
     */
    protected getNameItemSource() {
        return "itemsSource";
    }

    protected getNameSelectionMode() {
        return "selectionMode";
    }

    protected getNameAllowDragging() {
        return "allowDragging";
    }

    protected getNameAllowResizing() {
        return "allowResizing";
    }

    protected getNameAllowMerging() {
        return "allowMerging";
    }

    protected getFusenKbnCellName() {
        return "FusenKbn";
    }

    protected getFusenCmntCellName() {
        return "FusenCmnt";
    }

    protected getErrorCellName() {
        return "Error";
    }

    /**
     * イベントのバインド処理
     * @param grid
     */
    protected eventBinding(grid: wjGrid.FlexGrid) {
        var self = this;
        var clicked = false;

        grid.beginningEdit.addHandler(() => {
            this._isCellEditing = true;
        });
        grid.cellEditEnded.addHandler(() => {
            this._isCellEditing = false;
        });

        grid.lostFocus.addHandler(() => {
            // グリッド描画の非同期更新を行う場合
            if (this.allowAsyncRefreshWhenSelectionChanged) {
                // グリッド表示を非同期で更新する
                this.asyncRefresh(this.timeoutOfAsyncRefresh);
            }
        });

        // グリッド上でのpastedイベント
        grid.pasted.addHandler((sender: wjGrid.FlexGrid, ea: wijmo.EventArgs) => {
            let e = ea as  wjGrid.CellRangeEventArgs;
            var cellMap: ICell = null!;
            if (e.col >= 0) {
                var column = sender.columns[e.col];
                var binding = column.binding;
                cellMap = this.cellMaps.get(binding);
            }
            // カスタムエディタセルクラスのpastedイベントメソッドを実行
            if (cellMap && cellMap.pastedEventHandler != null)
                cellMap.pastedEventHandler(sender, e, this);
        }, this);

        // セル編集できる場合は、フォーマッターにも必要なので要注意！
        grid.hostElement.addEventListener("click", (e: MouseEvent) => {
            Logger.debug("click");
            let cellType = grid.hitTest(e.pageX, e.pageY).cellType;
            let row = grid.hitTest(e.pageX, e.pageY).row;
            let col = grid.hitTest(e.pageX, e.pageY).col;

            // クリックとダブルクリックイベントを識別するための仕掛け
            if (clicked) {
                clicked = false;
                return;
            }

            clicked = true;
            setTimeout(() => {
                if (clicked) {
                    // MEMO: クリックでのセルチェンジイベントは寝かす（他に影響があるかも・・・）
                    Logger.debug("isCellChange=", this.isCellChange);
                    if (!this.isCellChange) {
                        if (cellType == wjGrid.CellType.Cell) {
                            self.beforeCellChangeEvent(row, col);
                        }
                    }
                    // クリック時はセルチェンジでの移動フラグを寝かす
                    this.isCellChange = false;
                    clicked = false;
                    return;
                }
            }, 300);
        });

        // セル編集できる場合は、フォーマッターにも必要なので要注意！
        grid.hostElement.addEventListener("dblclick", (e: MouseEvent) => {
            // シングルクリックイベントを走らせないようにする
            clicked = false;
            let cellType = grid.hitTest(e.pageX, e.pageY).cellType;
            let row = grid.hitTest(e.pageX, e.pageY).row;
            let col = grid.hitTest(e.pageX, e.pageY).col;
            if (cellType == wjGrid.CellType.ColumnHeader) {
                self.beforeHeaderDoubleClickEvent(col, row);
                return;

            } else if (cellType == wjGrid.CellType.Cell) {
                self.beforeCellDoubleClickEvent(col, row);
                return;

            }
        });

        grid.hostElement.addEventListener("contextmenu", (e: MouseEvent) => {
            //Logger.debug("context menu event");
            let cellType = grid.hitTest(e.pageX, e.pageY).cellType;
            let row = grid.hitTest(e.pageX, e.pageY).row;
            let col = grid.hitTest(e.pageX, e.pageY).col;
            if (cellType == wjGrid.CellType.ColumnHeader) {
                self.beforeHeaderContextMenuEvent(col, row);
            } else if (cellType == wjGrid.CellType.Cell) {
                self.beforeCellContextMenuEvent(col, row);
            }
            // イベント伝播停止（ブラウザの右クリックメニューを表示しないため）
            e.preventDefault();
            return false;
        });

        // 必要があればご連絡ください by 中村
        grid.hostElement.addEventListener("keydown", (e: KeyboardEvent) => {
            //var cell: wjGrid.CellRange = this.getSelectedCell();
            //this.beforeKeydownEvent(e.keyCode, cell.row, cell.col);
        });

        grid.hostElement.addEventListener("keyup", (e: KeyboardEvent) => {
            // 方向キーだと移動後の値になる
            var cell: wjGrid.CellRange = this.getSelectedCell();
            this.beforeKeyupEvent(e.keyCode, cell.row, cell.col);
        });

        grid.selectionChanged.addHandler((s: wjGrid.FlexGrid, e) => {
            // グリッド描画の非同期更新を行う場合
            if (this.allowAsyncRefreshWhenSelectionChanged) {
                // グリッド表示を非同期で更新する
                this.asyncRefresh(this.timeoutOfAsyncRefresh);
            }
        }, this);

        grid.scrollPositionChanged.addHandler((s: wjGrid.FlexGrid, e) => {
            // グリッド描画の非同期更新を行う場合
            if (this.allowAsyncRefreshWhenSelectionChanged) {
                // グリッドの列固定時に、固定列のセルとデータセル（本体セル）の位置がずれてしまう問題の対策
                // (参考)Wijmoナレッジ84033：https://dev.grapecity.co.jp/support/kb/detail.asp?id=84033
                if (s.frozenColumns) {
                    // グリッド表示を非同期で更新する
                    this.asyncRefresh(this.timeoutOfAsyncRefresh);
                }
            }
        }, this);
    }

    /**
     * グリッドを生成するためのオプションを設定する
     * @param grid
     * @param option
     */
    private settingGridOption(grid: wjGrid.FlexGrid, option: GridOption): void {

        // Gridのセレクションモードの設定
        this.changeSelectionMode(option.selectionMode);

        grid.allowSorting = option.isSort;
        grid.allowDragging = option.alloDragging;
        grid.allowResizing = option.allowResizing;

        // 全ての行の高さを30pxに設定する
        grid.rows.defaultSize = option.rowsHeight;

        // 背景色が異なる「交互行」の間の通常行の数を設定
        grid.alternatingRowStep = option.alternatingRowStep;
    }

    /**
     * グリッドにスタイルを適用する
     * @param grid
     */
    private settingGridStyle(grid: wjGrid.FlexGrid): void {
        //Logger.debug("setting style");
        if (this.isSetGridOption()) {
            this.settingGridOption(grid, this.gridOption);
        } else {
            this.settingGridOption(grid, new GridOption());
        }
    }

    /**
     * Gridの選択モードを文字列で返す
     * @param mode
     */
    private getSelectionModeString(mode: number): string {
        let value = null;
        switch (mode) {
            case wjGrid.SelectionMode.Cell:
                value = "Cell";
                break;
            case wjGrid.SelectionMode.CellRange:
                value = "CellRange";
                break;
            case wjGrid.SelectionMode.ListBox:
                value = "ListBox";
                break;
            case wjGrid.SelectionMode.None:
                value = "None";
                break;
            case wjGrid.SelectionMode.Row:
                value = "Row";
                break;
            case wjGrid.SelectionMode.RowRange:
                value = "RowRange";
                break;
            default:
                value = "None";
                break;
        }
        return value;
    }

    /**
     * 列入れ替えモードを文字列で返す
     * @param mode
     */
    private getAllowDraggingString(mode: number): string {
        let value = null;
        switch (mode) {
            case wjGrid.AllowDragging.Both:
                value = "Both";
                break;
            case wjGrid.AllowDragging.Columns:
                value = "Columns";
                break;
            case wjGrid.AllowDragging.None:
                value = "None";
                break;
            case wjGrid.AllowDragging.Rows:
                value = "Row";
                break;
            default:
                value = "None";
                break;
        }
        return value;
    }

    /**
     * Gridの選択モードを文字列で返す
     * @param mode
     */
    private getAllowResizingString(mode: number): string {
        let value = null;
        switch (mode) {
            case wjGrid.AllowResizing.Both:
                value = "Both";
                break;
            case wjGrid.AllowResizing.Columns:
                value = "Columns";
                break;
            case wjGrid.AllowResizing.None:
                value = "None";
                break;
            case wjGrid.AllowResizing.Rows:
                value = "Row";
                break;
            default:
                value = "None";
                break;
        }
        return value;
    }



    /**
     * Scopeオブジェクトを取得する
     */
    protected get scope(): any {
        return this.$scope;
    }

    /**
     * Gridオブジェクトにアクセスるための名称を取得する
     * <wj-flex-grid class="WijmoGrid" control="flex"　にあるcontrolに指定された「flex」でアクセスする前提です
     * もし、別の名前でアクセスしたい場合は、本メソッドをオーバーライドするかコンストラクタの第二引数に文字列で指定してください。
     * 上記のディレクティブ内のcontrolと一致する必要があります
     */
    protected getControlName(): string {
        return this.controlName;
    }

    /**
     * Gridの選択モードを変更する
     * @param mode
     */
    protected changeSelectionMode(mode: number): void {
        this.selectionMode = mode;
        //this.scope.$apply(() => {
        this.$timeout(() => {
            this.scope[this.getNameSelectionMode()] = this.getSelectionModeString(mode);
        });
    }

    /**
     * Gridの列入れ替えモードを変更する
     * @param mode
     */
    protected changeAllowDragging(mode: number): void {
        //this.allowDragging = mode;
        //this.scope.$apply(() => {
        this.$timeout(() => {
            this.scope[this.getNameAllowDragging()] = this.getAllowDraggingString(mode);
        });
    }

    /**
     * Gridの列リサイズモードを変更する
     * @param mode
     */
    protected changeAllowResizing(mode: number): void {
        //this.allowResizing = mode;
        //this.scope.$apply(() => {
        this.$timeout(() => {
            this.scope[this.getNameAllowResizing()] = this.getAllowResizingString(mode);
        });
    }

    /**
     * Gridオブジェクトを取得する
     * <wj-flex-grid class="WijmoGrid" control="flex"　にあるcontrolに指定された「flex」でアクセスする前提です
     * もし、別の名前でアクセスしたい場合は、本メソッドをオーバーライドしてください。
     */
    public get grid(): wjGrid.FlexGrid {
        return this.scope[this.getControlName()].current.control;
    }

    public refresh(isFullUpdate: boolean): void {
        this.grid.refresh(isFullUpdate);
    }

    /**
     * Gridの非同期更新を行う
     * @param timeout
     * @param fullUpdate
     */
    public asyncRefresh(timeout: number, fullUpdate: boolean = null!): void {
        clearTimeout(this.timerForAsyncRefresh);
        this.timerForAsyncRefresh =
            setTimeout(() => {
                // グリッド表示を更新する
                this.grid.invalidate(fullUpdate);
            }, timeout);
    }

    /**
     * scopeにGridを登録する
     * 注）明示的に通常時の開発で使用することはありません
     */
    public gridBindScope(): void {
        this.gridBindName = this.getControlName() + "CustomGrid";
        this.scope[this.gridBindName] = this;
    }

    /**
     * scopeからCustomeGrid(FlexGridではないので注意)を取得する
     * bindNameはinstance生成時に決定します。
     * 本クラスのコンストラクタにある引数controlName + "CustomGrid"となります。
     * 未指定の場合は、flexCustomGridとなり、AbstractGrid.getCustomGrid();で取得が可能です。
     * Todo: ng.IScope error
     */
    //public static getCustomGrid(sc: ng.IScope, bindName: string = "flexCustomGrid"): AbstractGrid {
    //   return sc[bindName];
    //}

    /**
     * グリッドに表示するデータをセットする
     * @param viewData
     */
    public setViewData<T>(viewData: T): void {
        this.scope[this.getNameItemSource()] = new wijmo.CollectionView(viewData);
        /*
        if (this.isSetGridOption()) {
            this.$timeout(() => {
                this.scope[this.getNameItemSource()].pageSize = this.gridOption.pageSize;
            });
        }
        */
    }

    /**
     * グリッドに表示されているViewDataをすべて取得する
     */
    public getViewDatas<T>(): T[] {
        return <T[]>this.scope[this.getNameItemSource()]["items"];
    }

    public getCollectionView(): wijmo.CollectionView {
        return this.scope[this.getNameItemSource()];
    }

    public setCollectionView(collectionView: wijmo.CollectionView): void {
        this.scope[this.getNameItemSource()] = collectionView;
    }

    /**
     * グリッド用オプションを設定する
     * @param option
     */
    public getGridOption() {
        return this.gridOption;
    }

    /**
     * グリッド用オプションを設定する
     * @param option
     */
    public setGridOption(option: GridOption) {
        this.gridOption = option;
    }

    /**
     * グリッド用オプションが指定されているか判定する
     * @return true: 指定あり、false: 指定なし
     */
    public isSetGridOption(): boolean {
        return this.gridOption != null;
    }


    /**
     * Grid全体の必須入力／任意入力を設定する
     * @param isRequired
     */
    public isRequiredForGrid(isRequired: boolean): void {
        if (this.grid == null) return;

        for (var i = 0; i < (this.grid.columns || []).length; i++) {
            var col: wjGrid.Column = this.grid.columns[i];
            if (col) col.isRequired = isRequired;
        }
    }

    /**
     * 現在の選択行を取得する
     * <例>
     *  this.journalGrid.selectedRows<EntryJournalModel>()
     */
    public selectedRows<T>(): T {
        // 2017/04/21 BugFixBranch
        //let row = this.grid.selectedRows;
        //return <T>(row[0].dataItem);

        let row = this.grid.selectedRows;
        if (row && row.length > 0 && row[0].dataItem) {
            return <T>(row[0].dataItem);
        }
        return null!;
    }

    public selectedRowsIndex(): number {
        let row: wjGrid.Row[] = this.grid.selectedRows;
        if (row.length > 0) {
            //return row[0]._idx;
            return row[0].index;
        } else {
            return 0;
        }
    }


    /**
     * 行インデックス（0始まり）を指定して取得する
     * @param rowIndex
     * <例>
     *  this.journalGrid.getRowAt<EntryJournalModel>(5)
     */
    public getRowAt<T>(rowIndex: number): T {
        let row = this.grid.rows[rowIndex];
        return <T>(row.dataItem);
    }

    /**
     * 最終行を取得する
     * <例>
     *  this.journalGrid.selectedRows<EntryJournalModel>()
     */
    public getLastRow<T>(): T {
        let rowIndex = this.rowSize() - 1;
        //let row = this.grid.rows[rowIndex];
        //return <T>(row[0].dataItem);
        return this.getRowAt<T>(rowIndex);
    }

    /* 選択セルの値を取得できることを期待したが、
       選択しているセルの行を取得するため、
       selectedRrowsと同じ役割のためコメントアウト
    public selectedItems<T>(): T {
        Logger.debug(this.grid.selectedItems);
        return <T>this.grid.selectedItems[0];
    }
    */

    /**
     * グリッドのヘッダーを取得する
     */
    public getHeader(): wjGrid.ColumnCollection {
        return this.grid.columnHeaders.columns;
    }

    /**
     * ヘッダーのラベルを変更する
     * 注）セルのインデックス指定にしていないのは、列の入れ替えがあると
     * 　　インデックスがずれるので、bindingおよびnameでアクセスしています
     * @param binding wj-flex-grid-column bindingまたはnameに定義した値
     * @param label 設定したいラベル
     */
    public changeHeaderLabel(bindingOrName: string, label: string) {
        var columns = this.getHeader();
        for (var i = 0; i < columns.length; i++) {
            if (columns[i].binding == bindingOrName || columns[i].name == bindingOrName) {
                columns[i].header = label;
                break;
            }
        }
    }

    /**
     * wj-flex-grid-columnディレクティブ内に定義されているbinding属性の値を取得する
     * @param cellIndex
     */
    public getBinding(cellIndex: number): string {
        //Logger.debug("index=" + cellIndex);
        let headers = this.getHeader();
        return headers[cellIndex].binding;
    }

    /**
     * wj-flex-grid-columnディレクティブ内に定義されているname属性の値を取得する
     * @param cellIndex
     */
    public getName(cellIndex: number): string {
        let headers = this.getHeader();
        return headers[cellIndex].name;
    }

    /**
     * bindingを見て未設定の場合、name属性の値を確認し値を返す
     * @param cellIndex
     */
    public getCellId(cellIndex: number): string {
        // binding属性の値を取得
        let result = this.getBinding(cellIndex);
        // 設定されている場合はその値を使用
        if (result != null) {
            return result;
        } else {
            return this.getName(cellIndex);
        }
    }

    /**
     * セルの値を取得する
     * @param nameOrBinding
     * @param rowIndex
     * @param formatter true: 表示時に使用したフォーマッターを使用する　false: 使用しない
     */
    public getValue(nameOrBinding: string, rowIndex: number, formatter: boolean) {
        if (nameOrBinding != null) {
            return this.grid.cells.getCellData(rowIndex, nameOrBinding, formatter);
        } else {
            Logger.error("Name not found! Please check name in wj-flex-grid-column");
            return null;
        }
    }

    /**
     * 付箋セルの値を取得する
     * @param rowIndex
     * @param formatter true: 表示時に使用したフォーマッターを使用する　false: 使用しない
     */
    public getFusenCellValue(rowIndex: number, formatter: boolean) {
        return this.grid.cells.getCellData(
            rowIndex
            , this.getFusenKbnCellName()
            , formatter
        );
    }

    /**
     * 付箋セルの値を取得する
     * @param rowIndex
     * @param formatter true: 表示時に使用したフォーマッターを使用する　false: 使用しない
     */
    public getFusenCmntCellValue(rowIndex: number, formatter: boolean) {
        if (rowIndex >= 0) {
            return this.grid.cells.getCellData(
                rowIndex
                , this.getFusenCmntCellName()
                , formatter
            );
        }
    }

    /**
     * 付箋セルの値を設定する
     * @param rowIndex
     * @param color 
     */
    public setFusenCmntCellValue(rowIndex: number, comment: string) {
        return this.setValueByBinding(
            this.getFusenCmntCellName()
            , rowIndex
            , comment
        );
    }

    /**
     * 付箋セルの値を設定する
     * @param rowIndex
     * @param color 
     */
    public setFusenCellValue(rowIndex: number, color: string) {
        return this.setValueByBinding(
            this.getFusenKbnCellName()
            , rowIndex
            , color
        );
    }

    /**
     * エラーの値を取得する
     * @param rowIndex
     * @param formatter true: 表示時に使用したフォーマッターを使用する　false: 使用しない
     */
    public getErrorCellValue(rowIndex: number, formatter: boolean) {
        if (rowIndex >= 0) {
            return this.grid.cells.getCellData(
                rowIndex
                , this.getErrorCellName()
                , formatter
            );
        }
    }

    /**
     * wj-flex-grid-columnディレクティブ内に定義されているname属性の値でセルの値を取得する
     * @param event
     * @param formatter true: 表示時に使用したフォーマッターを使用する　false: 使用しない
     */
    public getValueByName(event: wjGrid.CellRangeEventArgs, formatter: boolean = false) {
        let name = this.getName(event.col);
        if (name != null) {
            return this.getValue(
                name
                , event.row
                , formatter
            );
        } else {
            Logger.warn("Name not found! Please check name in wj-flex-grid-column");
        }
    }

    /**
     * wj-flex-grid-columnディレクティブ内に定義されているbinding属性の値でセルの値を取得する
     * @param event
     * @param formatter true: 表示時に使用したフォーマッターを使用する　false: 使用しない
     */
    public getValueByBinding(event: wjGrid.CellRangeEventArgs, formatter: boolean = false) {
        return this.getValue(
            this.getBinding(event.col)
            , event.row
            , formatter
        );
    }

    /**
     * 行位置、セル位置の情報を指定して値を取得する
     * 注）位置情報はピクセルではないので注意してください。ピクセルの場合はgetValueByHitCellを使用してください
     * @param row
     * @param col
     * @param formatter
     */
    public getValueByPosition(row: number, col: number, formatter: boolean = false) {
        return this.getValue(
            this.getBinding(col)
            , row
            , formatter
        );
    }

    /**
     * 位置情報（ピクセル）からセルの値を取得する
     * @param x マウスイベントのpageX
     * @param y マウスイベントのpageY
     * @param formatter
     */
    public getValueByHitCell(x: number, y: number, formatter: boolean = false) {
        let hitCell: wjGrid.HitTestInfo = this.hitTest(x, y);
        if (hitCell == null) {
            Logger.error("指定された位置にセルが存在しません（位置情報：x(px)=" + x + "y(px)=" + y + "）");
            return null;
        }
        //Logger.debug("hit cell col=", hitCell.col);
        //Logger.debug("hit cell row=", hitCell.row);
        let binding = this.getBinding(hitCell.col);
        return this.getValue(binding, hitCell.row, formatter);
    }
    /*
            定義系のDIVが取得できた
            public getCellHtmlElement() {
                return this.grid.cells.hostElement;
            }
    */
    /**
     * wj-flex-grid-columnディレクティブ内に定義されているname属性を指定してセルに値を設定する
     * @param name
     * @param rowIndex
     * @param value
     */
    public setValueByName(name: string, rowIndex: number, value: string): void {
        // TODO: 指定Nameの存在チェック
        this.grid.cells.setCellData(rowIndex, name, value);
    }

    /**
     * wj-flex-grid-columnディレクティブ内に定義されているbinding属性を指定してセルに値を設定する
     * @param name
     * @param rowIndex
     * @param value
     */
    public setValueByBinding(binding: string, rowIndex: number, value: string): boolean {
        // TODO: 指定Bindingの存在チェック
        let result = false;
        this.$timeout(() => {
            //this.scope.$apply(() => {
            result = this.grid.cells.setCellData(rowIndex, binding, value, false);
            //this.grid.itemsSource.items[rowIndex][binding] = value;
            //Logger.debug(this.scope[this.getNameItemSource()]);
            //this.grid.itemsSource
            //this.refresh(true);
            //this.grid.refreshCells(true);
            //Logger.debug("cell data =", result);
            //Logger.debug("cell data =", this.getValue(binding, rowIndex, false));
        });
        return result;
    }

    /**
     * wj-flex-grid-columnディレクティブ内に定義されているbinding属性を指定してセルに値を設定する
     * 注）ボタンセルで使用しているので変更しないこと　その他でも使用しないこと
     * @param name
     * @param rowIndex
     * @param value
     */
    public setValueByBindingSync(binding: string, rowIndex: number, value: string): boolean {
        // TODO: 指定Bindingの存在チェック
        let result = this.grid.cells.setCellData(rowIndex, binding, value, false);
        return result;
    }

    /**
     * 座標位置を指定してセルに値を設定する
     * @param x マウスイベントのpageX
     * @param y マウスイベントのpageY
     * @param value
     */
    public setValueHitCellByBinding(x: number, y: number, value: any): void {
        let hitCell: wjGrid.HitTestInfo = this.hitTest(x, y);
        let binding = this.getBinding(hitCell.col);
        this.setValueByBinding(binding, hitCell.row, value);
    }

    /**
     * WijmoのCellRangeを取得する
     * @param x マウスイベントのpageX
     * @param y マウスイベントのpageY
     */
    public getWijmoCellRange(x: number, y: number): wjGrid.CellRange {
        let hitCell: wjGrid.HitTestInfo = this.hitTest(x, y);
        return new wjGrid.CellRange(hitCell.row, hitCell.col);
    }

    /**
     * 単一のセルを選択状態にする
     * @param row
     * @param col
     */
    public selectedCell(row: number, col: number, isShow: boolean = true): void {
        this.$timeout(() => {
            this.grid.select(new wjGrid.CellRange(row, col), isShow);
            this.grid.refresh(false);   // これfalseでないと移動しないのでTrueにしないこと
            this.isCellChange = false;
        });
    }

    public selectedCellByBinding(bindingOrName: string, row: number, isShow: boolean = true) {
        var colIndex = this.findCellByBinding(bindingOrName);
        if (colIndex >= 0) {
            this.selectedCell(row, colIndex, isShow);
        }
    }

    /**
     * Bindingからセル位置のインデックスを取得する
     * @param bindingOrName
     * @return 見つかった場合はセルのインデックス、見つからない場合は-1
     */
    public findCellByBinding(bindingOrName: string) {
        var columns = this.getHeader();
        var cellIndex = -1;
        for (var i = 0; i < columns.length; i++) {
            if (columns[i].binding == bindingOrName || columns[i].name == bindingOrName) {
                cellIndex = i;
                break;
            }
        }
        return cellIndex;
    }

    /**
     * 現在の選択セルを取得する
     * CellRangeはwijimoのオブジェクトです。独自のオブジェクトにしようと思ったのですが、内容はシンプルなのでそのまま
     */
    public getSelectedCell(): wjGrid.CellRange {
        return this.grid.selection;
    }

    /**
     * activeCellのHTMLElementを取得する
     */
    public getActiveCellElement(): HTMLElement {
        return this.grid._activeCell;
    }

    /**
     * Gridの選択モードを「マウスやキーボードでセルを選択できない(None)」モードに切り替える
     */
    public selectionModeNone(): void {
        this.changeSelectionMode(wjGrid.SelectionMode.None);
        this.changeAllowDragging(wjGrid.AllowDragging.None);
        this.changeAllowResizing(wjGrid.AllowResizing.None);
    }

    /**
     * Gridの選択モードが「None」モードか問い合わせる
     * @return true: Noneモード false: それ以外
     */
    public isSelectionModeNone(): boolean {
        return this.selectionMode == wjGrid.SelectionMode.None;
    }

    /**
     * Gridの選択モードを「一度に1つのセルだけを選択できる(Cell)」モードに切り替える
     */
    public selectionModeCell(): void {
        this.changeSelectionMode(wjGrid.SelectionMode.Cell);
        this.selectionMode = wjGrid.SelectionMode.Cell;
    }

    /**
     * セルを結合する
     * @param colSource 結合元
     * @param colIndex 結合したいカラムのインデックス
     */
    public generateMultiHeader(columnGroups: any) {

        // カラム作成
        this.grid.autoGenerateColumns = false;
        this.createColumnGroups(columnGroups, 0);

        // 列結合
        this.mergeColumnGroups();
    }

    /**
     * セル位置を固定する
     * @param cell
     */
    public fixedColumn(cell: number) {
        this.grid.frozenColumns = cell;
    }

    /**
     * 行位置を固定する
     * @param row
     */
    public fixedRow(row: number) {
        this.grid.frozenRows = row;
    }

    /**
     * グループ化されたカラムを作成する。
     * @param flexGrid
     * @param columnGroups
     * @param level
     */
    private createColumnGroups(columnGroups: string | any[], level: number) {

        // 設定先のヘッダーを取得
        var colHdrs: wjGrid.GridPanel = this.grid.columnHeaders;

        // グループ階層に従って、必要であればヘッダー行を挿入する。
        if (level >= colHdrs.rows.length) {
            colHdrs.rows.splice(colHdrs.rows.length, 0, new wjGrid.Row());
        }

        // 各グループの階層を辿りながら再帰的にヘッダーにセルを設定していく。
        for (var i = 0; i < columnGroups.length; i++) {
            var group = columnGroups[i];

            if (!group.columns) {

                // カラムの定義がない場合
                // カラムを作成し、グループの定義をコピー
                var col = new wjGrid.Column();
                for (var prop in group) {
                    if (prop in col) {
                        col[prop] = group[prop];
                    }
                }

                // セルに値を設定してヘッダーにセットする
                this.grid.columns.push(col);
                colHdrs.setCellData(level, colHdrs.columns.length - 1, group.header);

            } else {

                // カラムの定義がある場合、一つ下の階層のグループを作成する。
                var colIndex = colHdrs.columns.length;
                this.createColumnGroups(group.columns, level + 1);

                // グループの親としてヘッダーにセットする（科目が"親"、コードと名称が"子"）
                for (var j = colIndex; j < colHdrs.columns.length; j++) {
                    colHdrs.setCellData(level, j, group.header);
                }
            }
        }
    }

    /**
     * ヘッダーカラムを結合する
     * 科目（コード・名称）、当月迄累計（金額・構成比）、月次推移（期首月～期末月まで各月）
     * @param flexGrid
     */
    private mergeColumnGroups() {

        // グループ化されたヘッダーを取得
        var colHdrs: wjGrid.GridPanel = this.grid.columnHeaders;

        // ヘッダー列の結合を許可するよう設定
        this.grid.allowMerging = wjGrid.AllowMerging.ColumnHeaders;

        // 横方向の結合を許可
        for (var r = 0; r < colHdrs.rows.length; r++) {
            colHdrs.rows[r].allowMerging = true;
        }

    }


    /**
     * セルを結合する
     * @param colSource 結合元
     * @param colIndex 結合したいカラムのインデックス
     */
    public headerMarging(colSource: number, ...colIndex: number[]) {
        this.grid.allowMerging = wjGrid.AllowMerging.ColumnHeaders;

        this.grid.columnHeaders.rows[0].allowMerging = true;
        this.grid.columnHeaders.columns[colSource].allowMerging = true;

        for (var i = 0; i < colIndex.length; i++) {
            this.grid.columnHeaders.setCellData(
                0   // 行数
                , colIndex[i] // 対象のセル
                , this.grid.columnHeaders.getCellData(0, colSource, false)  // 埋め込み先のセルを指定
            );
        }
    }

    /**
     * Gridの選択モードが「Cell」モードか問い合わせる
     * @return true: Cellモード false: それ以外
     */
    public isSelectionModeCell(): boolean {
        return this.selectionMode == wjGrid.SelectionMode.Cell;
    }

    /**
     * Gridの選択モードを「隣接するセルのブロックを選択できる(CellRange)」モードに切り替える
     */
    public selectionModeCellRange(): void {
        this.changeSelectionMode(wjGrid.SelectionMode.CellRange);
        this.selectionMode = wjGrid.SelectionMode.CellRange;
    }

    /**
     * Gridの選択モードが「CellRange」モードか問い合わせる
     * @return true: CellRangeモード false: それ以外
     */
    public isSelectionModeCellRange(): boolean {
        return this.selectionMode == wjGrid.SelectionMode.CellRange;
    }

    /**
     * Gridの選択モードを「一度に1つの行を選択できる(Row)」モードに切り替える
     * 明細最終行を選択状態にする。移動は明示的に行なう、または別の行を選択状態にする場合は
     * selectionModeRowOnlyを使用してください。
     */
    public selectionModeRow(): void {
        this.changeSelectionMode(wjGrid.SelectionMode.Row);
        this.changeAllowDragging(this.getGridOption().alloDragging);
        this.changeAllowResizing(this.getGridOption().allowResizing);
        // 最終行に移動
        this.moveCellToLastRow();
    }

    /**
     * Gridの選択モードを「一度に1つの行を選択できる(Row)」モードに切り替える
     */
    public selectionModeRowSelectedAt(row: number, col: number): void {
        this.changeSelectionMode(wjGrid.SelectionMode.Row);
        this.changeAllowDragging(this.getGridOption().alloDragging);
        this.changeAllowResizing(this.getGridOption().allowResizing);

        this.$timeout(() => {
            this.selectedCell(row, col, true);
        }, 300);
    }

    /**
     * Gridの選択モードが「Row」モードか問い合わせる
     * @return true: Rowモード false: それ以外
     */
    public isSelectionModeRow(): boolean {
        return this.selectionMode == wjGrid.SelectionMode.Row;
    }

    /**
     * Gridの選択モードを「隣接する行を選択できる(RowRange)」モードに切り替える
     */
    public selectionModeRowRange(): void {
        this.changeSelectionMode(wjGrid.SelectionMode.RowRange);
        this.selectionMode = wjGrid.SelectionMode.RowRange;
    }

    /**
     * Gridの選択モードが「RowRange」モードか問い合わせる
     * @return true: RowRangeモード false: それ以外
     */
    public isSelectionModeRowRange(): boolean {
        return this.selectionMode == wjGrid.SelectionMode.RowRange;
    }

    /**
     * Gridの選択モードを「隣接しない行を選択できる(ListBox)」モードに切り替える
     */
    public selectionModeListBox(): void {
        this.changeSelectionMode(wjGrid.SelectionMode.ListBox);
        this.selectionMode = wjGrid.SelectionMode.ListBox;
    }

    /**
     * Gridの選択モードが「ListBox」モードか問い合わせる
     * @return true: RowRangeモード false: それ以外
     */
    public isSelectionModeListBox(): boolean {
        return this.selectionMode == wjGrid.SelectionMode.ListBox;
    }

    /**
     * グリッド内の行数を返す
     * 注）非表示の行まで数えられます。都合が悪い場合は「原田」まで。
     */
    public rowSize(): number {
        return this.grid.rows == null ? 0 : this.grid.rows.length;
    }

    /**
     * グリッド内の最終行の指定セルを選択状態にする
     * @param col セルの位置（0から始まる）指定なしの場合は、先頭が選択される
     */
    public moveCellToLastRow(col: number = 0, setFocus: boolean = true): void {
        //this.$timeout(() => {
        this.selectedCell(this.rowSize() - 1, col);
        this.refresh(false);
        if (setFocus) {
            this.focus();
        }
        //}, 300);
    }

    /**
     * 明細グリッドを未選択状態にする
     * 注）グリッドは選択可能状態だが、未選択にする場合に使用する
     */
    public noneSelectedRows(): void {
        this.selectedCell(-1, 0);
    }

    /**
     * 指定行にスクロールする
     * @param row
     */
    public moveScrollAt(row: number, col: number = 0): void {
        this.$timeout(() => {
            this.selectedCell(row, col);
            this.grid.scrollIntoView(row, col);
        });
    }

    /**
     * 選択行にスクロールする
     * @param row
     */
    public moveScrollActiveRow(): void {
        let row = this.selectedRowsIndex();
        let colRange = this.getSelectedCell();
        this.moveScrollAt(row, colRange.col);
    }

    public focus(): void {
        this.grid.focus();
    }

    /**
     * 指定行が最終行か問い合わせる
     * @param row
     * @return true: 最終行、false: それ以外
     */
    public isSelectedLastRow(row: number): boolean {
        return this.rowSize() == row + 1;
    }

    /**
     * 選択行が最終行か問い合わせる
     * @return true: 最終行、false: それ以外
     */
    public isLastActiveRow(): boolean {
        //Logger.debug("index=", this.selectedRowsIndex());
        return this.isSelectedLastRow(this.selectedRowsIndex());
    }

    /**
     * セルの定義を行う（セルオブジェクトの設定）
     * @param cell
     */
    public define(cell: ICell): AbstractGrid {
        if (cell) {
            this.cellMaps.add(cell.binding, cell);
        }
        return this;
    }

    /**
     * セルオブジェクトを取得する
     * @param binding
     */
    public getCellMapObject(key: string): ICell {
        return this.cellMaps.get(key);
    }

    /**
     * ボタンセルオブジェクトを取得する（ディレクティブで使用するので原則開発者がコールすることはありません）
     * 以下のようにディレクティブ（vkz-grid-custom-editor）を設定してください
     * ＜Gridのカラム定義＞
     *  <wj-flex-grid-column header="金額" binding="amount" format="n2" vkz-grid-custom-editor="edtSample" align="right" width="150"></wj-flex-grid-column>
     * ＜カスタムエディタの定義＞
     *  <vkz-editor-button id="edtSample"></vkz-editor-button>
     * 
     * @param binding
     */
    public getCellButtonObject(binding: string): IButtonCell {
        return <ButtonCell>this.cellMaps.get(binding);
    }

    public getHitCellMapObject(mouseEvent: any): ICell {
        let hitCell: wjGrid.HitTestInfo = this.hitTestWithEvent(mouseEvent);
        let cellId: string = this.getCellId(hitCell.col);
        return this.getCellMapObject(cellId);
    }

    public getHitButtonCellMapObject(mouseEvent: any): IButtonCell {
        return <ButtonCell>this.getHitCellMapObject(mouseEvent);
    }

    /**
     * すべての行を取得する
     */
    public getRows<T>(): T[] {
        return this.scope[this.getNameItemSource()];
    }

    /**
     * グリッドの明細をクリアにする
     */
    public clear(): void {
        this.grid.rows.clear();
    }

    /**
     * 指定行の表示・非表示かを確認する
     * @param row
     * @return true: 表示、false: 非表示
     */
    public isRowVisible(row: number): boolean {
        return this.grid.rows[row].visible;
    }

    /**
     * 行の表示・非表示設定を行う
     * 注）itemSourceないであれば、非表示の行もインデックスでアクセス可能です（グリッドに表示されていない行もインデックスが割り当てられています）
     */
    public setRowVisible(row: number, b: boolean): void {
        this.grid.rows[row].visible = b;
    }

    /**
     * 指定位置に行を追加する
     * @param item
     */
    //public insertRowAt<T>(row: number, item: T) {
    public editRowAt(row: number, item: any) {
        let cv: wijmo.CollectionView = this.scope[this.getNameItemSource()];
        //var sd = new wijmo.SortDescription('HSrchNo', true);
        cv.beginUpdate();
        $.extend(cv.currentItem, item);
        cv.endUpdate();
        cv.refresh();
        this.moveCurrentToPosition(row);
    }

    public moveCurrentToPosition(row: number): boolean {
        let cv: wijmo.CollectionView = this.scope[this.getNameItemSource()];
        let b: boolean = cv.moveCurrentToPosition(row);
        this.$timeout(() => {
            this.focus();
        }, 300);
        return b;
    }

    /**
     * 指定位置に行を追加し、指定行にスクロールする
     * 注）wijmoのinsertを実行すると行が削除され挿入されないため、擬似的に挿入を行っています
     *     スクロールまでするので行を選択状態にできないため明示的にselectedCellをコールしていただく必要があります
     * @param item
     */
    public insertRowScrollAt<T>(row: number, item: T) {
        this.addRow(item);
        this.$timeout(() => {
            this.grid.rows.moveElement(this.rowSize() - 1, row);
            this.moveScrollAt(row);
        });
    }

    /**
     * 行を追加する
     * @param item
     */
    public addRow<T>(item: T) {
        //            this.grid.rows.insert(this.rowSize() -1, new wjGrid.Row(item));
        var newItem = this.scope[this.getNameItemSource()].addNew();
        $.extend(newItem, item);
        this.scope[this.getNameItemSource()].commitNew();
        this.scope[this.getNameItemSource()].refresh();
    }

    /**
     * 行を削除する
     * @param row
     */
    public removeRowAt(row: number) {
        this.scope[this.getNameItemSource()].removeAt(row);
        this.scope[this.getNameItemSource()].commitEdit();
        //this.grid.rows.removeAt(row);
    }

    /**
     * 空行を指定位置に指定行数追加する
     * @param addRowIndex
     * @param addRows
     */
    public addEmptyRowAt(addRowIndex: number, addRows: number) {
        for (var i = 0; i < addRows; i++) {
            //var newItem = this.scope[this.getNameItemSource()].addNew();
            this.scope[this.getNameItemSource()].commitNew();
        }
        this.$timeout(() => {
            this.grid.rows.moveElement(this.rowSize() - 1, addRowIndex);
            this.moveScrollAt(addRowIndex);
        });
    }

    /**
     * 要素をある位置から別の位置に移動します。
     * @param src　移動する要素のインデックス。
     * @param dst　要素の移動先を示す位置（末尾に移動する場合は-1）
     */
    public moveElement(src: number, dst: number): void {
        // HACK:CollectionViewも併せて更新した方が良い
        //　　　【現状】※ver.2.08時点
        //      　これを使用している処理は仕訳辞書編集のみ
        //　　　　仕訳辞書編集では、このメソッド実行後にCollectionViewを更新している。
        //      　■理由：仕訳辞書編集はMultiRowのグリッドになっており、
        //　　　　　　　　共通部品への落とし込みが難しかったため
        this.grid.rows.moveElement(src, dst);
    }

    /**
     * 空行を指定行数追加する
     *
     * @param row
     */
    public addEmptyRow(row: number) {
        for (var i = 0; i < row; i++) {
            //var newItem = this.scope[this.getNameItemSource()].addNew();
            this.scope[this.getNameItemSource()].commitNew();
        }
        this.$timeout(() => {
            this.moveScrollActiveRow();
        });
    }

    /**
     * 指定セルがNULLか判別する
     * @param binding
     * @param row
     * @return TRUE: NULL、FALSE: 非NULL
     */
    public isNullCell(binding: string, row: number): boolean {
        let value = this.getValue(binding, row, false);
        return value == null;
    }

    /**
     * 指定行を削除する
     * @param row
     */
    public removeAt(row: number) {
        this.scope[this.getNameItemSource()].removeAt(row);
    }

    /**
     * セルのリサイズを不可にする
     * 注）setViewDataのあとに本メソッドをコールしてください
     * @param cells
     */
    public disableResizeCell(...cells: number[]) {
        for (var i = 0; cells[0] != null && i < cells.length; i++) {
            let index = cells[i];
            this.grid.cells.columns[index].allowResizing = false;
        }
    }

    /**
     * グリッドに表示するデータが存在するかどうかを問い合わせる
     * @return true: 存在する　false：存在しない
     */
    public isDataExist(): boolean {
        Logger.debug("row size =" + this.rowSize());
        return this.rowSize() > 0;
        //return this.getViewDatas() != null;
    }

    /**
     * 付箋ポップアップ削除
     */
    protected fusenPopupRemove() {
        var $fusenNote = $(".fusen-note-hover");
        if ($fusenNote.length > 0) $fusenNote.hide().remove();
    }

    // -------------------------------------------------------------------------------
    // pager
    // -------------------------------------------------------------------------------
    public moveToLastPage(): void {
        this.scope[this.getNameItemSource()].moveToLastPage();
    }

    public moveToFirstPage(): void {
        this.scope[this.getNameItemSource()].moveToFirstPage();
    }

    protected setPageSizeFromOption(): void {
        this.scope[this.getNameItemSource()].pageSize = this.gridOption.pageSize;
    }
    // -------------------------------------------------------------------------------

    /**
     * X,Y座標を基に、本グリッド内の該当箇所を求める。
     * @param x wjGrid.FlexGrid.hitTest(x: number, y: number)のX座標(マウスイベントのpageX)
     * @param y wjGrid.FlexGrid.hitTest(x: number, y: number)のY座標(マウスイベントのpageY)
     */
    protected hitTest(x: number, y: number): wjGrid.HitTestInfo {
        let theResult: wjGrid.HitTestInfo = this.grid.hitTest(x, y);
        Logger.debug("AbstractGrid.hitTest() x:" + x +
            " y:" + y +
            " -> col:" + theResult.col +
            " row:" + theResult.row +
            " cellType(0:None, 1:Cell, 2:ColumnHeader, 3:RowHeader, 4:TopLeft):" + theResult.cellType +
            " FlexGrid HostElement ID:" + theResult._g.hostElement.id);
        return theResult;
    }

    /**
     * マウスイベントを基に、本グリッド内の該当箇所を求める。
     * @param mouseEvent wjGrid.FlexGrid.hitTest(MouseEvent)のマウスイベント
     */
    protected hitTestWithEvent(mouseEvent: any): wjGrid.HitTestInfo {
        let theResult: wjGrid.HitTestInfo = this.grid.hitTest(mouseEvent);
        Logger.debug("AbstractGrid.hitTestWithEvent()" +
            " -> col:" + theResult.col +
            " row:" + theResult.row +
            " cellType(0:None, 1:Cell, 2:ColumnHeader, 3:RowHeader, 4:TopLeft):" + theResult.cellType +
            " FlexGrid HostElement ID:" + theResult._g.hostElement.id);
        return theResult;
    }

    /**
     * セル選択イベントの前処理を行う
     * セル選択モードが「None」の場合はイベントを発火しない
     * @param row
     * @param col
     */
    protected beforeCellChangingEvent(e: wjGrid.CellRangeEventArgs): void {
        Logger.debug("beforeCellChangingEvent row=" + e.row);
        // SelectionMode==Noneの場合はイベントを発火しない
        if (!this.isSelectionModeNone() && e.col >= 0) {
            var bindingOrName = this.getBinding(e.col);
            //Logger.debug("bidingname=" + bindingOrName);
            if (bindingOrName != null) {
                var cell: ICell = this.getCellMapObject(bindingOrName);
                if (cell != null) {
                    if (cell.isSelectable()) {
                        this.cellChangingEvent(e);
                    } else {
                        this.selectedCell(e.row, cell.forceMoveCell());
                    }
                }
            }
        }
    }

    /**
     * セル選択イベントの前処理を行う
     * セル選択モードが「None」の場合はイベントを発火しない
     * @param row
     * @param col
     */
    protected beforeCellChangeEvent(row: number, col: number): void {
        this.isCellChange = true;
        // SelectionMode==Noneの場合はイベントを発火しない
        if (!this.isSelectionModeNone()) {
            this.cellChangeEvent(row, col);
        }
        // drag操作でCellの選択状態がついてくる現象を回避
        // この状態を回避するのは、Cellの単一選択とRowの単一選択時のみ
        if (this.grid.selectionMode === wjGrid.SelectionMode.Cell ||
            this.grid.selectionMode === wjGrid.SelectionMode.Row) {

            // マウスのイベント状態をリセットする
            this.grid._mouseHdl.resetMouseState();
        }
    }

    /**
     * セルリサイズイベントの前処理を行う
     * セル選択モードが「None」の場合はイベントを発火しない
     * @param row
     * @param col
     */
    protected beforeCellResizeEvent(e: wjGrid.CellRangeEventArgs): void {
        // SelectionMode==Noneの場合はイベントを発火しない
        if (!this.isSelectionModeNone()) {
            Logger.debug(">>> beforeCellResizeEvent");
            Logger.debug(e);
            this.cellResizeEvent(e);
        }
    }

    /**
     * ヘッダーダブルクリックイベントの前処理を行う
     * セル選択モードが「None」の場合はイベントを発火しない
     * @param row
     * @param col
     */
    protected beforeHeaderDoubleClickEvent(row: number, col: number): void {
        // SelectionMode==Noneの場合はイベントを発火しない
        if (!this.isSelectionModeNone()) {
            this.headerDoubleClickEvent(row, col);
        }
    }

    /**
     * セルダブルクリックイベントの前処理を行う
     * セル選択モードが「None」の場合はイベントを発火しない
     * @param row
     * @param col
     */
    protected beforeCellDoubleClickEvent(row: number, col: number): void {
        // SelectionMode==Noneの場合はイベントを発火しない
        if (!this.isSelectionModeNone()) {
            this.cellDoubleClickEvent(row, col);
        }
    }

    /**
     * ヘッダー右クリックイベントの前処理を行う
     * セル選択モードが「None」の場合はイベントを発火しない
     * @param row
     * @param col
     */
    protected beforeHeaderContextMenuEvent(row: number, col: number): void {
        // SelectionMode==Noneの場合はイベントを発火しない
        if (!this.isSelectionModeNone()) {
            this.headerContextMenuEvent(row, col);
        }
    }

    /**
     * セル右クリックイベントの前処理を行う
     * セル選択モードが「None」の場合はイベントを発火しない
     * @param row
     * @param col
     */
    protected beforeCellContextMenuEvent(row: number, col: number): void {
        // SelectionMode==Noneの場合はイベントを発火しない
        if (!this.isSelectionModeNone()) {
            this.cellContextMenuEvent(row, col);
        }
    }

    /**
     * セル内でのキーダウンイベントの前処理を行う
     * セル選択モードが「None」の場合はイベントを発火しない
     * @param row
     * @param col
     */
    /*
    protected beforeKeydownEvent(keyCode: number, row: number, col: number) {
        // SelectionMode==Noneの場合はイベントを発火しない
        if (!this.isSelectionModeNone()) {
            this.cellKeydownEvent(keyCode, row, col);
        }
    }
    */

    /**
     * セル内でのキーアップイベントの前処理を行う
     * セル選択モードが「None」の場合はイベントを発火しない
     * @param row
     * @param col
     */
    protected beforeKeyupEvent(keyCode: number, row: number, col: number) {
        // SelectionMode==Noneの場合はイベントを発火しない
        if (!this.isSelectionModeNone()) {
            this.cellKeyupEvent(keyCode, row, col);
        }
    }

    /**
     * セル内でのキーアップイベントでのキーに応じた処理を振り分ける
     * @param keyCode
     * @param row
     * @param col
     */
    /*
    protected cellKeydownEvent(keyCode: number, row: number, col: number) {
        Logger.debug("keyCode="+keyCode);
        switch (keyCode) {
            // エンターキー
            case wijmo.Key.Enter:
                this.enterKeydownEvent(row, col);
                break;
        }
        
    }
    */

    /**
     * セル内でのキーアップイベントでのキーに応じた処理を振り分ける
     * @param keyCode
     * @param row
     * @param col
     */
    protected cellKeyupEvent(keyCode: number, row: number, col: number) {
        //Logger.debug("keyCode="+keyCode);
        switch (keyCode) {
            // 方向キー↑
            case wijmo.Key.Up:
                this.arrowUpKeyupEvent(row + 1, col);
                break;
            // 方向キー↓
            case wijmo.Key.Down:
                this.arrowDownKeyupEvent(row, col);
                break;
            // エンターキー
            case wijmo.Key.Enter:
                this.enterKeyupEvent(row, col);
                break;
            // スペースキー
            case wijmo.Key.Space:
                this.spaceKeyupEvent(row, col);
                break;
            // タブキー
            case wijmo.Key.Tab:
                this.tabKeyupEvent(row, col);
                break;
            // デリートキー
            case wijmo.Key.Delete:
                this.deleteKeyupEvent(row, col);
                break;
            // バックスペースキー
            case wijmo.Key.Back:
                this.backspaceKeyupEvent(row, col);
                break;
        }

    }

    //------------------------------------------------------------------------
    // キーイベント
    //------------------------------------------------------------------------
    /**
     * 方向キー下のイベント処理を行う
     * @param row
     * @param col
     */
    protected arrowDownKeyupEvent(row: number, col: number): void {
        //Logger.debug("key up arrow down");
    }

    /**
     * 方向キー上のイベント処理を行う
     * @param row
     * @param col
     */
    protected arrowUpKeyupEvent(row: number, col: number): void {
        //Logger.debug("key up arrow up");
    }

    /**
     * エンターキーのダウンイベント処理を行う
     * @param row
     * @param col
     */
    /*
    protected enterKeydownEvent(row: number, col: number): void {
        Logger.debug("key down enter");
        if (this.isLastActiveRow()) {
            this.enterKeyEventOfLastRow(row, col);
        }
        //this.enterKeyupEventOfLastRow(row, col);
    }
    */

    /**
     * エンターキーのイベント処理を行う
     * @param row
     * @param col
     */
    protected enterKeyupEvent(row: number, col: number): void {
        if (!this.isCellChange && this.isLastActiveRow()) {
            //this.enterKeyEventOfLastRow(row, col);
            Logger.debug("last row!");
            this.enterKeyupEventCorrectRow(row, col);
        }
        else {
            //this.enterKeyEventOfLastRow(row, col);
            Logger.debug("not last row!");
            this.enterKeyupEventCorrectRow(row - 1, col);
        }
        this.isCellChange = false;
    }

    /**
     * エンターキーのイベント処理を行う
     * @param row
     * @param col
     */
    //protected enterKeyEventOfLastRow(row: number, col: number): void {
    //    Logger.debug("enter key in last row");
    //}

    /**
     * エンターキーのイベント処理を行なう
     * ＊エンターキーイベントの発火位置が正確にとれます
     */
    protected enterKeyupEventCorrectRow(row: number, col: number): void {
    }

    /**
     * スペースキーのイベント処理を行う
     * @param row
     * @param col
     */
    protected spaceKeyupEvent(row: number, col: number): void {
        //Logger.debug("key up space");

    }

    /**
     * タブキーのイベント処理を行う
     * @param row
     * @param col
     */
    protected tabKeyupEvent(row: number, col: number): void {
        Logger.debug("key up tab");

    }

    /**
     * DELキーのイベント処理を行う
     * @param row
     * @param col
     */
    protected deleteKeyupEvent(row: number, col: number): void {
        Logger.debug("key up delete");
        Logger.debug("row, col=", row, col);
    }

    /**
     * Backspaceキーのイベント処理を行う
     * @param row
     * @param col
     */
    protected backspaceKeyupEvent(row: number, col: number): void {
        //Logger.debug("key up BS");
        Logger.debug("key up BS");
        Logger.debug("row, col=", row, col);
    }

    //------------------------------------------------------------------------

    protected headerItemFormatter(rowIndex: number, columnIndex: number, cell: HTMLElement): void {
    }

    protected gotFocusEvent(e: wijmo.EventArgs): void {
        // 印刷ダイアログなどを表示して、メインのグリッドが選択できなくなる不具合の回避処理
        this.changeSelectionMode(this.selectionMode);
    }

    protected lostFocusEvent(e: wijmo.EventArgs): void {
    }

    /**
     * セルにアイテムフォーマッターを1度だけ設定する
     * 主に全セルに対し処理を行いたいときにオーバーライドをしてください
     * 外部からもコールすることが可能です。
     * @param rowIndex
     * @param columnIndex
     * @param cell
     */
    //protected abstract onlyOnceCellItemFormatter(rowIndex: number, columnIndex: number, cell: HTMLElement): void; 

    //----------------------------------------------------------------------------------
    // 各サブクラスでオーバーライド
    //----------------------------------------------------------------------------------
    /**
     * Gridの初期化処理
     */
    protected abstract initialize(): void;

    /**
     * セル選択イベント
     * 注）cellChangeEventより早いイベント発火
     * @param row
     * @param col
     */
    protected abstract cellChangingEvent(e: wjGrid.CellRangeEventArgs): void;

    /**
     * セル選択イベント
     * @param row
     * @param col
     */
    protected abstract cellChangeEvent(row: number, col: number): void;

    /**
     * セルリサイズイベント
     * @param grid
     * @param e
     */
    protected abstract cellResizeEvent(e: wjGrid.CellRangeEventArgs): void;

    /**
     * セルにイベントをバインドする
     * 現時点ではチェックボックスセルの選択イベントのバインドを行ったりしている
     * 注意）この処理はcellItemFormatterと同じだけ処理が走ります。かなり多いのでいい感じでやってくださいｗ
     * なぜ、ここでイベントのバインドをするのか・・・それはcellItemFormatterでDOMを生成するとイベントが走らなかったため（調べるのに疲れました by 中村個々の声）
     * @param rowIndex
     * @param columnIndex
     * @param cell
     */
    protected abstract cellItemEventHandler(row: number, col: number, cell: HTMLElement): void;

    /**
     * セルにアイテムフォーマッターを設定する
     * セルの描画時など頻繁に走ります。
     * @param rowIndex
     * @param columnIndex
     * @param cell
     */
    protected abstract cellItemFormatter(rowIndex: number, columnIndex: number, cell: HTMLElement): void;

    /**
     * セルの編集完了中に呼ばれる
     * @param value セルで確定した入力内容（セルの値）
     * @param rowIndex
     * @param columnIndex
     */
    protected cellEditEndingHandler(value: any, rowIndex: number, columnIndex: number): void {
        return;
    }

    /**
     * セルの編集完了後に呼ばれる
     * @param value セルで確定した入力内容（セルの値）
     * @param rowIndex
     * @param columnIndex
     */
    protected abstract cellEditEndedHandler(value: any, rowIndex: number, columnIndex: number): void;

    /**
     * ヘッダーにアイテムフォーマッターを設定するが
     * @param rowIndex
     * @param columnIndex
     * @param cell
     */
    //protected abstract headerItemFormatter(rowIndex: number, columnIndex: number, cell: HTMLElement): void;

    /**
     * スクロールイベント
     * @param sender
     * @param e
     */
    protected abstract scrollEvent(e: wijmo.EventArgs): void;

    /**
     * ヘッダーダブルクリックイベント
     * @param col
     * @param row
     */
    protected abstract headerDoubleClickEvent(col: number, row: number): void;

    /**
     * セルダブルクリックイベント
     * @param col
     * @param row
     */
    protected abstract cellDoubleClickEvent(col: number, row: number): void;

    /**
     * ヘッダーコンテキストメニューイベント
     * @param col
     * @param row
     */
    protected abstract headerContextMenuEvent(col: number, row: number): void;

    /**
     * セルコンテキストメニューイベント
     * @param col
     * @param row
     * セルの描画時など頻繁に走ります。
     */
    protected abstract cellContextMenuEvent(col: number, row: number): void;

}



/**
 * このクラスはサンプル実装です
 * 
 */
//export class EntryJounalGrid extends AbstractGrid {
//
//    private timerForScrollEvent: any;
//    protected adapter: GridEventAdapter;
//    public cellChanged: wijmo.Event;
//
//    constructor($scope: any, $timeout: any, adapter: GridEventAdapter, name: string = "flex", option?: GridOption) {
//        super($scope, $timeout, name, option);
//        this.adapter = adapter;
//        this.cellChanged = new wijmo.Event();
//    }
//
//    protected initialize() {
//        if (this.scope.viewModel.hojyoPriorityInfoDto.length >= 1) {
//            this.scope.isPattern1_hojo1 = true;
//        } else {
//            this.scope.isPattern1_hojo1 = false;
//        }
//        this.scope.isPattern3_hojo2 = false;
//        this.scope.isPattern3_hojo3 = false;
//        this.scope.isPattern4 = false;
//        this.scope.isPattern4_hojo4 = false;
//        this.scope.isPattern4_hojo5 = false;
//        this.scope.isPattern5 = false;
//        this.scope.isNotPattern5 = true;
//
//        this.scope.isShowCodeCommonPattern = false;
//
//        this.scope.isShowCodePattern1_hojo1 = false;
//        this.scope.isShowCodePattern3_hojo2 = false;
//        this.scope.isShowCodePattern3_hojo3 = false;
//        this.scope.isShowCodePattern4_hojo4 = false;
//        this.scope.isShowCodePattern4_hojo5 = false;
//
//        this.scope.isShowFxPattern1 = true;
//        this.scope.isShowFxPattern2 = false;
//    }
//
//    protected cellChangingEvent(e: wjGrid.CellRangeEventArgs): void {
//        let binding = this.getBinding(e.col);
//    }
//
//    protected cellEditEndedHandler(value: any, rowIndex: number, columnIndex: number): void {
//        //Logger.debug("value=" + value);
//    }
//
//    /**
//     * 最終行が選択状態で検NOがNULLでない場合は↓で空行の追加を行う
//     * @param row
//     * @param col
//     */
//    protected arrowDownKeyupEvent(row: number, col: number) {
//        //Logger.debug("arrow down keyup!");
//        //if (this.isSelectedLastRow(row) && !this.isNullCell("DSrchNo", row)) {
//        //if (this.isLastActiveRow()) {
//        //    this.addEmptyRow(1);
//        //}
//    }
//
//    /**
//     * 最終行が選択状態で検NOがNULLの場合は↑で空行削除を行う
//     * @param row
//     * @param col
//     */
//    protected arrowUpKeyupEvent(row: number, col: number) {
//        //Logger.debug("arrow up keyup!");
//        //if (this.isSelectedLastRow(row) && this.isNullCell("DSrchNo", row)) {
//        //    this.removeRowAt(row);
//        //}
//    }
//
//    //protected enterKeyupEvent(row: number, col: number): void {
//    //    Logger.debug(" enter key up row=" + row);
//    //    if (!this.isCellChange && this.isLastActiveRow()) {
//    //        Logger.debug("row=" + row);
//    //    }
//    //    else {
//    //        Logger.debug("row=" + (row -1));
//    //    }
//    //    /*
//    //    let selectedIndex: number = row;
//    //    Logger.debug("row=" + row);
//    //    if (this.isCellChange) {
//    //        //Logger.debug("row=" + (selectedIndex - 1));
//    //        Logger.debug("is cell change!");
//    //        selectedIndex -= 1;
//    //    }
//    //    else {
//    //        Logger.debug("is not cell change!");
//    //        Logger.debug("row=" + row);
//    //    }
//    //    Logger.debug("selected index=" + selectedIndex);
//    //    */
//    //    Logger.debug("is cell change=" + this.isCellChange);
//    //    Logger.debug("is last index=" + this.isLastActiveRow());
//    //    this.isCellChange = false;
//    //}
//
//    /**
//     * 単一のセルを選択状態にする
//     * @param row
//     * @param col
//     */
//    public selectedCell(row: number, col: number, isShow: boolean = true): void {
//        this.$timeout(() => {
//            this.grid.select(new wjGrid.CellRange(row, col), isShow);
//
//            // CellChangeを行うので、CellChangeイベントを発火させる
//            this.cellChangeEvent(row, col);
//
//            this.grid.refresh(false);   // これfalseでないと移動しないのでTrueにしないこと
//            this.isCellChange = false;
//
//        });
//    }
//
//    /**
//     * 入力グリッドでの初期表示時に行を選択する
//     * @param row
//     * @param col
//     * @param isShow
//     */
//    public selectedCellInit(row: number, col: number, isShow: boolean = true): void {
//        this.$timeout(() => {
//            this.grid.select(new wjGrid.CellRange(row, col), isShow);
//            this.grid.refresh(false);   // これfalseでないと移動しないのでTrueにしないこと
//            this.isCellChange = false;
//
//        });
//    }
//
//    /**
//     * moveCellToLastRowだと初期表示時にセルチェンジイベントが走りファンクションパネルがリビルドされてしまう為、別で作成
//     * @param col
//     * @param setFocus
//     */
//    public moveCellToLastRowInit(col: number = 0, setFocus: boolean = true): void {
//        //this.$timeout(() => {
//        this.selectedCellInit(this.rowSize() - 1, col);
//        this.refresh(false);
//        if (setFocus) {
//            this.focus();
//        }
//        //}, 300);
//    }
//
//    /**
//     * 引数で指定された区分が伝票入力された仕訳なのかどうかを判断する。
//     * 2017/04/21 BugFixBranch
//     * @param swkForm
//     */
//    public isSlipSwk(swkForm: number): boolean {
//        if (swkForm == null || swkForm == undefined) {
//            false;
//        }
//        let isSlip: boolean = (swkForm == constant.SwkForm.CrossSlip) || (swkForm == constant.SwkForm.Kokuyo) || (swkForm == constant.SwkForm.PayingSlip) || (swkForm == constant.SwkForm.DebitSlip);
//
//        return isSlip;
//    }
//
//    protected cellChangeEvent(row: number, col: number): void {
//        if (this.cellChanged.hasHandlers) {
//            this.cellChanged.raise(this, row);
//        }
//
//        if (row < 0) {
//            return;
//        }
//
//        let currentMode = support.functionPanel.FunctionPanelCommon.currentMode(this.scope);
//        if (currentMode.isInputSkipMode()) {
//            // F3削除判定
//            let rowSize = this.rowSize();
//            if (rowSize > 1) {
//                mjs.acelink.vkz.support.functionPanel.FunctionPanelBroadcast.enabledF3(this.scope);
//            } else {
//                mjs.acelink.vkz.support.functionPanel.FunctionPanelBroadcast.disabledF3(this.scope);
//            }
//            return;
//        }
//
//        var rowModel: mjs.acelink.vkz.models.entry.ProcessedJournalDispInfoViewModel = this.selectedRows<mjs.acelink.vkz.models.entry.ProcessedJournalDispInfoViewModel>();
//
//        // 2017/04/21 BugFixBranch
//
//        // 振替伝票のファンクションキーの設定
//        //if ((rowModel.SwkForm == constant.SwkForm.CrossSlip) || (rowModel.SwkForm == constant.SwkForm.Kokuyo) || (rowModel.SwkForm == constant.SwkForm.PayingSlip) || (rowModel.SwkForm == constant.SwkForm.DebitSlip)) {
//        //    mjs.acelink.vkz.support.functionPanel.FunctionPanelBroadcast.enabledF4(this.scope);
//        //} else {
//        //    mjs.acelink.vkz.support.functionPanel.FunctionPanelBroadcast.disabledF4(this.scope);
//        //}
//
//        if (rowModel && mjs.acelink.vkz.pages.entry.journal.model.ViewModelUtil.getInstance(this.scope).isFixMonth(rowModel.NMonth)) {
//            // 確定月仕訳の場合
//            mjs.acelink.vkz.support.functionPanel.FunctionPanelBroadcast.noneF2(this.scope);
//            mjs.acelink.vkz.support.functionPanel.FunctionPanelBroadcast.noneF3(this.scope);
//        } else {
//            mjs.acelink.vkz.support.functionPanel.FunctionPanelBroadcast.rebuildFunctionPanel(this.scope);
//        }
//        let pMode = mjs.acelink.vkz.support.functionPanel.FunctionPanelCommon.getCurrentMode(this.scope);
//        if (rowModel && this.isSlipSwk(rowModel.SwkForm)) {
//            mjs.acelink.vkz.support.functionPanel.FunctionPanelBroadcast.enabledF4(this.scope);
//            // 表示モードのF4伝票表示非活性化
//            //} else {
//        } else if (pMode == mjs.acelink.vkz.support.functionPanel.mode.PageMode.DISP_MODE) {
//            mjs.acelink.vkz.support.functionPanel.FunctionPanelBroadcast.disabledF4(this.scope);
//        }
//        if ((MjsUtilities.isNull(rowModel)) &&
//            (pMode == support.functionPanel.mode.PageMode.DISP_MODE)) {
//            // 選択中の仕訳が無い かつ 表示モード
//            support.functionPanel.FunctionPanelBroadcast.disabledF2(this.scope); // F2 修正
//            support.functionPanel.FunctionPanelBroadcast.disabledF7(this.scope); // F7 削除
//        }
//
//
//        //Logger.debug("changed index=", this.selectedRowsIndex());
//        /*
//        this.grid.columns[col].inputType = "text";
//
//        var column = this.grid.columns[col];
//        if (column.dataType === wijmo.DataType.Number) {
//
//            var editorRoot = document.createElement('div');
//            //var input = new wijmo.input.InputDate(editorRoot);
//            var input = new wijmo.input.InputNumber(editorRoot);
//            //var cell = this.getCellHtmlElement();
//            //Logger.debug(cell);
//            //cell.appendChild(editorRoot);
//            //input.value = this.grid.getCellData(row, col, false);
//        }
//
//
//        //Logger.debug(this.getHeader());
//        //Logger.debug("cell value=" + grid.cells.getCellData(0, "date", false));
//        Logger.debug(this.getValue(this.getName(col), row, false));
//        Logger.debug(this.getValue(this.getBinding(col), row, false));
//        Logger.debug(this.getValue(this.getBinding(col), row, true));
//        */
//        //Logger.debug(this.getValueByName(e));
//        //Logger.debug(this.getValueByBinding(e));
//        //Logger.debug("cell name=" + grid.columns[0].name);
//        //Logger.debug(grid.columnHeaders.columns[2].name);
//        //Logger.debug(grid.columns(0));
//        //Logger.debug(grid.columns.getColumn("abc"));
//        //Logger.debug(grid.getCellData(0, 1, false));
//        //Logger.debug(grid.columns.getColumn("date").grid.getCellData(0, 2, false));
//        //Logger.debug(grid.columns.getColumn("date").collectionView.items[0]);
//        /*
//        Logger.debug("cell=" + e.col);
//        Logger.debug("row=" + e.row);
//        Logger.debug("data=" + e.data); // undefined
//        Logger.debug("cell edit range >>>");
//        Logger.debug(grid.editRange);
//        Logger.debug(grid.columns.getColumn("debitAccName"));
//        */
//    }
//
//    protected cellResizeEvent(e: wjGrid.CellRangeEventArgs): void {
//        Logger.debug("resize events");
//        //Logger.debug(e.panel.columns[e.col].allowResizing);
//    }
//
//    protected headerItemFormatter(row: number, col: number, cell: HTMLElement): void {
//        cell.style.verticalAlign = "middle";
//        cell.style.textAlign = "center";
//
//        var binding = this.getBinding(col);
//        //Logger.debug("binding =", binding);
//        //if ("DFusenKbn" == binding) {
//        if (this.getFusenKbnCellName() == binding) {
//            cell.innerHTML = '<span class="custom-icon-tool_tag"></span>';
//        }
//    }
//
//    protected cellItemEventHandler(row: number, col: number, cell: HTMLElement) {
//        // TODO: イベントのバインド処理走りすぎ！　by m.nakamura
//        var self = this;
//        var binding = this.getBinding(col);
//        //this.grid.frozenColumns = 2;
//        /*
//        if ("creditAccName" == binding) {
//            var checkBoxCell: CheckboxCell = <CheckboxCell>this.getCellMapObject(binding);
//            checkBoxCell.bindCheckboxEvent(this, binding, row, col);
//        }
//        */
//    }
//
//    protected cellItemFormatter(rowIndex: number, columnIndex: number, cell: HTMLElement): void {
//        cell.style.fontSize = "15px";
//        var binding = this.getBinding(columnIndex);
//        /*
//        if ("DenDate" == binding) {
//            var value = this.getValue(binding, rowIndex, true);
//            if (!MjsUtilities.isNull(value)) {
//                // TODO: あとでユーティリティを作成すること
//                //var date = new Date(value);
//                //cell.innerText = (date.getMonth() + 1).toString() + (date.getDate()).toString();
//                //cell.innerText = DateUtil.format(value, "MMDD");
//            }
//        }
//        */
//
//        //if ("TekiyoDisp" == binding) {
//        //    cell.innerHTML = MjsUtilities.decodeTekiyo(this.grid.getCellData(rowIndex, columnIndex, false));
//        //}
//
//        if ("DKmkName" == binding || "CKmkName" == binding) {
//            //let item = this.grid.collectionView.items[rowIndex];
//            let item = this.getRowAt(rowIndex);
//            let kmkCode = "DKmkName" == binding ? item["DKmkCode"] : item["CKmkCode"];
//            if (!kmkCode) return;
//            let kmkMA = this.adapter.getKmkMACache().singleOrNull(e => e.GCode == kmkCode);
//
//            // 製造原価系の科目は青に。
//            if (kmkMA.SumAnaCode == constant.SumAnaCode.ManufacturingCost) {
//                cell.classList.add("blue");
//            }
//
//        }
//
//        if ("DSrchNo" == binding) {
//            let item = this.getRowAt(rowIndex);
//            if (item["FixedState"]) {
//                if (item["FixedState"] == 1) {
//                    cell.style.color = "#f00";
//                } else if (item["FixedState"] == 2) {
//                    cell.style.color = "#0088CE";
//                } else if (item["FixedState"] == 0) {
//                    cell.style.color = "#202020";
//                }
//            }
//        }
//
//        /*
//        if ("InpSum" == binding) {
//            //let item = this.grid.collectionView.items[rowIndex];
//            let item = this.getRowAt(rowIndex);
//            let taxInpKbn = item["TaxInpKbn"];
//            if (taxInpKbn) {
//                cell.classList.add("blue");
//            }
//        }
//        */
//
//        /*
//        if ("TaxCode" == binding) {
//            //let item = this.grid.collectionView.items[rowIndex];
//            let item = this.getRowAt(rowIndex);
//            if (item["IsExcptRate"]) {
//                cell.classList.add("red");
//            }
//        }
//        */
//    }
//
//    protected scrollEvent(e: wijmo.EventArgs): void {
//        //Logger.debug("scroll event");
//        // コール頻度が高いため、負荷軽減措置でsetTimeoutを使用
//        clearTimeout(this.timerForScrollEvent);
//        this.timerForScrollEvent = setTimeout(() => {
//            // 付箋コメントポップアップを画面から削除
//            super.fusenPopupRemove();
//        }, 300);
//    }
//
//    protected headerDoubleClickEvent(col: number, row: number): void {
//        Logger.debug("header double click event");
//    }
//
//    protected cellDoubleClickEvent(col: number, row: number): void {
//
//        var rowModel: mjs.acelink.vkz.models.entry.ProcessedJournalDispInfoViewModel =
//            this.selectedRows<mjs.acelink.vkz.models.entry.ProcessedJournalDispInfoViewModel>();
//
//        this.cellDoubleClickEventProcess(rowModel);
//
//        /*
//        Logger.debug("cell double click event");
//        var self = this;
//        let success = (data: models.JournalViewModel) => {
//            self.adapter.doubleClickEventAdapt(data);
//        }
//        let complete = () => {
//            self.adapter.doubleClickEventAdaptAfterProcess();
//        }
//
//        var rowModel: mjs.acelink.vkz.models.entry.ProcessedJournalDispInfoViewModel =
//            this.selectedRows<mjs.acelink.vkz.models.entry.ProcessedJournalDispInfoViewModel>();
//
//        mjs.acelink.vkz.pages.entry.journal.util.JournalAjax.communicateFindByJournal(
//            rowModel
//            , success
//            , complete
//        );
//        */
//    }
//
//    protected cellDoubleClickEventProcess(rowModel: mjs.acelink.vkz.models.entry.ProcessedJournalDispInfoViewModel) {
//        //var self = this;
//        //let success = (data: models.JournalViewModel) => {
//        //    self.adapter.doubleClickEventAdapt(data);
//        //}
//        //let complete = () => {
//        //    self.adapter.doubleClickEventAdaptAfterProcess();
//        //}
//
//        //mjs.acelink.vkz.pages.entry.journal.util.JournalAjax.communicateFindByJournal(
//        //    rowModel
//        //    , success
//        //    , complete
//        //);
//
//        // Modeごとに処理を変更する為、entryJournal側に移動
//        var self = this;
//        self.adapter.doubleClickEventAdapt(rowModel);
//    }
//
//    protected headerContextMenuEvent(col: number, row: number): void {
//        Logger.debug("header right click event");
//    }
//
//    protected cellContextMenuEvent(col: number, row: number): void {
//        // 付箋コメントを画面から削除
//        $(".fusen-note-hover").hide().remove();
//
//        //this.selectedCell(row, col);
//        //var cellId = this.getCellId(col);
//        this.selectedCell(row, col);
//        var binding = this.getCellId(col);
//        // セルオブジェクトを取得する
//        var cell: ICell = this.cellMaps.get(binding);
//        if (cell != null) {
//            // コンテキストメニューオブジェクトを取得する
//            let context: IContextMenu =
//                cell.getContextMenu(
//                    row
//                    , col
//                    , this.getValueByPosition(row, col)
//                );
//            if (context != null) {
//                context.show();
//            }
//        }
//    }
//
//    protected enterKeyupEventCorrectRow(row, col): void {
//        // wijmo.gridの行移動を優先するため削除
//        /*
//        var rowModel: mjs.acelink.vkz.models.entry.ProcessedJournalDispInfoViewModel =
//            this.getRowAt<mjs.acelink.vkz.models.entry.ProcessedJournalDispInfoViewModel>(row);
//
//        this.cellDoubleClickEventProcess(rowModel);
//        */
//    }
//
//    //-------------------------------------------------
//    // 関数名オーバーライド
//    //-------------------------------------------------
//    protected getNameItemSource() {
//        return "journalItemsSource";
//    }
//
//    protected getFuncNameInitialized() {
//        return "gridInit";
//    }
//
//    protected getFuncNameResizedColum() {
//        return "resizedColumn";
//    }
//
//    protected getFuncNameScrollPositionChange() {
//        return "scrollPositionChanged";
//    }
//
//    protected getFuncNameDraggedColumn() {
//        return "draggedColumn";
//    }
//
//    protected getFuncNameItemFormatter() {
//        return "journalItemFormatter";
//    }
//
//    protected getFuncNameCellEditEnding() {
//        return "cellEditEnding";
//    }
//
//    protected getFuncNameCellEditEnded() {
//        return "cellEditEnded";
//    }
//
//    protected getFuncNameSelectionChanging() {
//        return "journalSelectionChanging";
//    }
//
//    protected getFuncNameSelectionChanged() {
//        return "journalSelectionChanged";
//    }
//
//    protected getFuncNameGotFocus() {
//        return "gotFocus";
//    }
//
//    protected getFuncNameLostFocus() {
//        return "lostFocus";
//    }
//
//    protected getFusenKbnCellName() {
//        return "DFusenKbn";
//    }
//
//    protected getFusenCmntCellName() {
//        return "DFusenCmnt";
//    }
//}

/**
 * Grid生成時のオプションクラス、のちにインタフェース化
 */
export class GridOption {
    private _selectionMode: number;
    private _isSort: boolean;
    private _alloDragging: number;
    private _allowResizing: number;
    private _rowsHeight: number;
    private _alternatingRowStep: number;
    private _pageSize: number;

    constructor() {
        this._selectionMode = wjGrid.SelectionMode.None;
        this._isSort = false;
        this._alloDragging = wjGrid.AllowDragging.None;
        this._allowResizing = wjGrid.AllowResizing.Columns;
        this._rowsHeight = 30;
        this._alternatingRowStep = 1;
        this._pageSize = 0;
    }

    public get selectionMode(): number { return this._selectionMode; }
    public get isSort(): boolean { return this._isSort; }
    public get alloDragging(): number { return this._alloDragging; }
    public get allowResizing(): number { return this._allowResizing; }
    public get rowsHeight(): number { return this._rowsHeight; }
    public get alternatingRowStep(): number { return this._alternatingRowStep; }
    public get pageSize(): number { return this._pageSize; }

    public set selectionMode(value: number) { this._selectionMode = value; }
    public set isSort(value: boolean) { this._isSort = value; }
    public set alloDragging(value: number) { this._alloDragging = value; }
    public set allowResizing(value: number) { this._allowResizing = value; }
    public set rowsHeight(value: number) { this._rowsHeight = value; }
    public set alternatingRowStep(value: number) { this._alternatingRowStep = value; }
    public set pageSize(value: number) { this._pageSize = value; }

}
