import Axios from 'axios';

import React from 'react';
import { Button, Label } from 'reactstrap';

import * as wjGrid from 'wijmo/wijmo.grid';
import { FlexGrid } from 'wijmo/wijmo.react.grid';
import * as wjInput from 'wijmo/wijmo.input';
import { ComboBox } from 'wijmo/wijmo.react.input';

import * as Labels from '../../../../constants/labels';
import * as enums from '../../../../constants/enums';
import * as dataMaps from '../../../../constants/dataMaps';
import * as message from '../../../../constants/message';
import { setAlertMessage, AlertKbnEnum } from '../../../../stores/AlertMessage';
import { validateKanaRenChar } from '../../../../utils/stringUtils';

import {
  LowerActionLayout,
  LowerActionLayoutHeader,
  LowerActionLayoutContents,
  LowerActionLayoutAction
} from '../../../../components/templates/LowerActionLayout';
import PageHeaderAccountRegister from '../../../../components/molecules/A130/PageHeaderAccountRegister';
import ExecutingCancel from '../../../../components/molecules/CMN/ExecutingCancel/ExecutingCancel';

import SimpleGrid2 from '../../../../components/molecules/CMN/Grid/SimpleGrid2';

import { createWjDataMap } from '../../../../utils/dataMapsUtils';
import * as messageUtils from '../../../../utils/messageUtils';

import ConfirmationPopup from '../../../../components/molecules/CMN/ConfirmationPopup/ConfirmationPopup';
import Popup from '../../../../components/molecules/CMN/Popup/Popup';
import ExcelOutputsetting from '../../../organisms/A000/ExcelOutputsetting';
import { setIsLoading } from '../../../../stores/NowLoading';

import { AccountRegisterViewModel } from '../../../../models/accountRegisterViewModel';
import { AccuntRegisterListVO } from '../../../../models/accuntRegisterListVO';
import { AccountExcelViewModel } from '../../../../models/accountExcelViewModel';
import { TaxKubunRelationInfoViewModel } from '../../../../models/taxKubunRelationInfoViewModel';

/**
 * 科目分類(ローカル定義版)
 */
enum LocalKmkClassEnum {
  /** なし(未選択)(enums.KmkClassEnumには存在しないがGUI上必要なので追加) */
  None = 0,

  /** 資産科目 */
  AssetsKmk = 1,

  /** 負債・純資産科目 */
  LliabilitiesKmk = 2,

  /** 損益科目 */
  ProfitLossKmk = 3,

  /** 製造科目 */
  manufacKmk = 4,
}

/**
 * 科目分類(ローカル定義版)
 */
const LocalKmkClassDataMaps = [
  { key: 0, value: '' },
  { key: 1, value: '資産科目' },
  { key: 2, value: '負債・純資産科目' },
  { key: 3, value: '損益科目' },
  { key: 4, value: '製造科目' },
]

/**
 * 科目分類(Wijmoコンボボックス選択肢版)
 */
const kmkClassItemsSource = LocalKmkClassDataMaps.map(item => ({ ...item }));

/**
 * 消費税率区分(ローカル定義版)
 * dataMaps.tsのTaxRateEnumの順序のみ入れ替えた。
 * ジェネレーター向け定義ではkey値を昇順に定義する必要がある。
 * (昇順ではない場合ジェネレーターが異常終了しdataMaps.tsを出力できない。)
 * そのため任意の順序で定義出来ない。
 */
const LocalTaxRateEnum = [
  { key: 0, value: '標準税率' },
  { key: 4, value: '10.0%' },
  { key: 5, value: '8.0%軽' },
  { key: 3, value: '8.0%' },
  { key: 2, value: '5.0%' },
  { key: 1, value: '3.0%' },
]
enum ModeFlg {
  /** 編集前 */
  NotEdited = 0,

  /** 新規 挿入 */
  NewInsert = 1,

  /** 編集 */
  Edit = 2
}

/**
 * 仕入消費税コード
 */
enum BTaxCode {

  /** なし */
  None = 0,

  /** 課税仕入(内税) */
  TaxationJournalizeIncluded = 10,

  /** 共通仕入(内税) */
  CommonJournalizeIncluded = 11,

  /** 課税仕入控除(内税) */
  TaxationJournalizeSubtractionIncluded = 12,

  /** 非課税売上対応仕入(内税) */
  ExemptionSalseJournalizeIncluded = 14,

  /** 共通仕入控除(内税) */
  CommonJournalizeSubtractionIncluded = 15,

  /** 非課税売上対応仕入控除(内税) */
  ExemptionSalseJournalizeSubtractionIncluded = 16,

  /** 課税仕入(外税) */
  TaxationJournalizeExcluded = 20,

  /** 共通仕入(外税) */
  CommonJournalizeExcluded = 21,

  /** 課税仕入控除(外税) */
  TaxationJournalizeSubtractionExcluded = 22,

  /** 非課税売上対応仕入(外税) */
  ExemptionSalseJournalizeExcluded = 24,

  /** 共通仕入控除(外税) */
  CommonJournalizeSubtractionExcluded = 25,

  /** 非課税売上対応仕入控除(外税) */
  ExemptionSalseJournalizeSubtractionExcluded = 26,

  /** 非課税仕入 */
  ExemptionJournalize = 30,

  /** 不課税仕入(精算取引) */
  TaxationLessnessStockingCleaningContract = 40,

  /** 棚卸調整消費税(課税) */
  StocktakingAdjustTaxation = 50,

  /** 棚卸調整消費税(共通) */
  StocktakingAdjustConsumptionTax = 51,

  /** 棚卸調整消費税(非課税) */
  StocktakingAdjustExemption = 54,

  /** 輸入仕入(外税) */
  ImportJournalizeExcluded = 60,

  /** 輸入共通仕入(外税) */
  ImportCommonJournalizeExcluded = 61,

  /** 非課税売上対応輸入(外税) */
  JournalizeCotCode64 = 64,

  /** 輸入仕入(地方消費税) */
  ImportJournalizeConsumptionTax = 65,

  /** 輸入共通仕入(地方消費税) */
  ImportCommonJournalizeLocalConsumptionTax = 66,

  /** 非課税売上対応輸入(地方) */
  ExemptionJournalizeImport = 69,

  /** 輸入仕入(内税) */
  ImportJournalizeIncluded = 70,

  /** 輸入共通仕入(内税) */
  ImportCommonJournalizeIncluded = 71,

  /** 非課税売上対応輸入(内税) */
  ExemptionSalseImportIncluded = 74,

  /** 課税売上対応特定課税仕入 */
  TaxationSalesSpecificJournalize = 80,

  /** 課税非課税売上対応特定課税仕入 */
  TaxationExemptionSalesSpecificJournalize = 81,

  /** 課税売上対応特定課税仕入の対価の返還等 */
  TaxationSalesSpecificJournalizeReturn = 82,

  /** 非課税売上対応特定課税仕入 */
  ExemptionSalseImportSpecificTaxationJournalize = 84,

  /** 課税非課税売上対応特定課税仕入の対価の返還等 */
  TaxationExemptionSalesSpecificJournalizeReturn = 85,

  /** 非課税売上対応特定課税仕入の対価の返還等 */
  ExemptionSalseImportSpecificTaxationJournalizeReturn = 86,

  /** 不明 */
  Unclear = 99
}

/** 通信処理ID AIKADT008000001_Post1 勘定科目削除 のレスポンスを保持するクラス */
class Post1Response {
  /** 勘定科目一覧 */
  Item1?: AccountRegisterViewModel;

  /** ステータス(サーバ側の処理結果) */
  Item2?: number;
}

/** 通信処理ID AIKADT008000001_Post2 勘定科目登録 のレスポンスを保持するクラス */
class Post2Response {
  /** 勘定科目一覧 */
  Item1?: AccountRegisterViewModel;

  /** ステータス(サーバ側の処理結果) */
  Item2?: number;

  /** 外部コード */
  Item3?: number;
}

/** 勘定科目登録画面 機能ID AIKADT008000001 を開く際の引数群 */
type AccountRegisterProps = {
  /** 自画面を閉じる処理 */
  onClose: () => void;

  /** 表示年度 */
  fiscalYear: number;
}

/**
 * 勘定科目登録画面 機能ID AIKADT008000001
 * @param props FirmInfoProps {自画面を閉じる処理, 表示年度}
 */
const AccountRegister: React.FC<AccountRegisterProps> = props => {

  /** サーバへアクセスする際のURL(ベース部分) */
  const BaseURL = '/api/v1/AccountRegister';

  /** 初期表示時にサーバへアクセスする際のURL 通信処理ID AIKADT008000001_Get1 ? 消費税関連情報取得 */
  const InitialURL = [BaseURL, 'Initial'].join('/');

  /** 科目分類を変更時にサーバへアクセスする際のURL 通信処理ID AIKADT008000001_Get2 科目一覧作成 */
  const KmkListURL = [BaseURL, 'KmkList'].join('/');

  /** Excel出力ボタンを押下時にサーバへアクセスする際のURL 通信処理ID AIKADT008000001_Get3 出力データ作成 */
  const OutputURL = [BaseURL, 'Output'].join('/');

  /** 削除ボタンを押下時にサーバへアクセスする際のURL 通信処理ID AIKADT008000001_Post1 勘定科目削除 */
  const DeleteURL = [BaseURL, 'Delete'].join('/');

  /** 保存ボタンを押下時にサーバへアクセスする際のURL 通信処理ID AIKADT008000001_Post2 勘定科目登録 */
  const UpdateURL = [BaseURL, 'Update'].join('/');

  /** 共通処理 */
  // 処理起動判定
  const JudgeURL = '/api/v1/CommonStartJudgement/Judge';
  // システム履歴記入
  const WriteSysHistoryURL = '/api/v1/CommonSystemHistory/Write';

  /** 消費税コード一覧(仕入/売上)
   * TaxKbnCode:一覧グリッドの消費税コード列の内部キー値 = (TaxKbn * 100) + TaxCode
   * TaxKbn:一覧グリッドの科目区分列の内部キー値(0～2)
   * TaxCode:サーバ向けの消費税コード(0～99)
   * TaxName::一覧グリッドの消費税コード列に表示する文言
   **/
  const TaxCodeList = [
    { TaxKbnCode:200, TaxKbn: 2, TaxCode:  0, TaxName: 'なし' },
    { TaxKbnCode:210, TaxKbn: 2, TaxCode: 10, TaxName: '課税仕入(内税)' },
    { TaxKbnCode:211, TaxKbn: 2, TaxCode: 11, TaxName: '共通仕入(内税)' },
    { TaxKbnCode:212, TaxKbn: 2, TaxCode: 12, TaxName: '課税仕入控除(内税)' },
    { TaxKbnCode:214, TaxKbn: 2, TaxCode: 14, TaxName: '非課税売上対応仕入(内税)' },
    { TaxKbnCode:215, TaxKbn: 2, TaxCode: 15, TaxName: '共通仕入控除(内税)' },
    { TaxKbnCode:216, TaxKbn: 2, TaxCode: 16, TaxName: '非課税売上対応仕入控除(内税)' },
    { TaxKbnCode:220, TaxKbn: 2, TaxCode: 20, TaxName: '課税仕入(外税)' },
    { TaxKbnCode:221, TaxKbn: 2, TaxCode: 21, TaxName: '共通仕入(外税)' },
    { TaxKbnCode:222, TaxKbn: 2, TaxCode: 22, TaxName: '課税仕入控除(外税)' },
    { TaxKbnCode:224, TaxKbn: 2, TaxCode: 24, TaxName: '非課税売上対応仕入(外税)' },
    { TaxKbnCode:225, TaxKbn: 2, TaxCode: 25, TaxName: '共通仕入控除(外税)' },
    { TaxKbnCode:226, TaxKbn: 2, TaxCode: 26, TaxName: '非課税売上対応仕入控除(外税)' },
    { TaxKbnCode:230, TaxKbn: 2, TaxCode: 30, TaxName: '非課税仕入' },
    { TaxKbnCode:240, TaxKbn: 2, TaxCode: 40, TaxName: '不課税仕入(精算取引)' },
    { TaxKbnCode:250, TaxKbn: 2, TaxCode: 50, TaxName: '棚卸調整消費税(課税)' },
    { TaxKbnCode:251, TaxKbn: 2, TaxCode: 51, TaxName: '棚卸調整消費税(共通)' },
    { TaxKbnCode:254, TaxKbn: 2, TaxCode: 54, TaxName: '棚卸調整消費税(非課税)' },
    { TaxKbnCode:260, TaxKbn: 2, TaxCode: 60, TaxName: '輸入仕入(外税)' },
    { TaxKbnCode:261, TaxKbn: 2, TaxCode: 61, TaxName: '輸入共通仕入(外税)' },
    { TaxKbnCode:264, TaxKbn: 2, TaxCode: 64, TaxName: '非課税売上対応輸入仕入(外税)' },
    { TaxKbnCode:265, TaxKbn: 2, TaxCode: 65, TaxName: '輸入仕入(地方消費税)' },
    { TaxKbnCode:266, TaxKbn: 2, TaxCode: 66, TaxName: '輸入共通仕入(地方消費税)' },
    { TaxKbnCode:269, TaxKbn: 2, TaxCode: 69, TaxName: '非課税売上対応輸入仕入(地方消費税)' },
    { TaxKbnCode:270, TaxKbn: 2, TaxCode: 70, TaxName: '輸入仕入(内税)' },
    { TaxKbnCode:271, TaxKbn: 2, TaxCode: 71, TaxName: '輸入共通仕入(内税)' },
    { TaxKbnCode:274, TaxKbn: 2, TaxCode: 74, TaxName: '非課税売上対応輸入仕入(内税)' },
    { TaxKbnCode:280, TaxKbn: 2, TaxCode: 80, TaxName: '課税売上対応特定課税仕入' },
    { TaxKbnCode:281, TaxKbn: 2, TaxCode: 81, TaxName: '共通売上対応特定課税仕入' },
    { TaxKbnCode:282, TaxKbn: 2, TaxCode: 82, TaxName: '課税売上対応特定課税仕入控除' },
    { TaxKbnCode:284, TaxKbn: 2, TaxCode: 84, TaxName: '非課税売上対応特定課税仕入' },
    { TaxKbnCode:285, TaxKbn: 2, TaxCode: 85, TaxName: '共通売上対応特定課税仕入控除' },
    { TaxKbnCode:286, TaxKbn: 2, TaxCode: 86, TaxName: '非課税売上対応特定課税仕入控' },
    { TaxKbnCode:299, TaxKbn: 2, TaxCode: 99, TaxName: '不明' },
    { TaxKbnCode:100, TaxKbn: 1, TaxCode:  0, TaxName: 'なし' },
    { TaxKbnCode:110, TaxKbn: 1, TaxCode: 10, TaxName: '課税売上(内税)' },
    { TaxKbnCode:112, TaxKbn: 1, TaxCode: 12, TaxName: '課税売上控除(内税)' },
    { TaxKbnCode:113, TaxKbn: 1, TaxCode: 13, TaxName: '課税貸倒償却(内税)' },
    { TaxKbnCode:115, TaxKbn: 1, TaxCode: 15, TaxName: '課税貸倒回収(内税)' },
    { TaxKbnCode:120, TaxKbn: 1, TaxCode: 20, TaxName: '課税売上(外税)' },
    { TaxKbnCode:122, TaxKbn: 1, TaxCode: 22, TaxName: '課税売上控除(外税)' },
    { TaxKbnCode:123, TaxKbn: 1, TaxCode: 23, TaxName: '課税貸倒償却(外税)' },
    { TaxKbnCode:125, TaxKbn: 1, TaxCode: 25, TaxName: '課税貸倒回収(外税)' },
    { TaxKbnCode:130, TaxKbn: 1, TaxCode: 30, TaxName: '非課税売上' },
    { TaxKbnCode:134, TaxKbn: 1, TaxCode: 34, TaxName: '有価証券売上等(非課税)' },
    { TaxKbnCode:140, TaxKbn: 1, TaxCode: 40, TaxName: '不課税売上(精算取引)' },
    { TaxKbnCode:160, TaxKbn: 1, TaxCode: 60, TaxName: '輸出売上' },
    { TaxKbnCode:164, TaxKbn: 1, TaxCode: 64, TaxName: '非課税輸出売上' },
    { TaxKbnCode:199, TaxKbn: 1, TaxCode: 99, TaxName: '不明' },
    { TaxKbnCode:  0, TaxKbn: 0, TaxCode:  0, TaxName: 'なし' },
  ];

  /** 自画面を閉じる処理 */
  const { onClose } = props;

  // State
  /* A3 科目分類 */
  const [kmkClass, setKmkClass] = React.useState<LocalKmkClassEnum>(
    LocalKmkClassEnum.None
  );
  /* 通信処理ID AIKADT008000001_Get2 科目一覧作成のレスポンス */
  const [accountRegisterViewModel, setAccountRegisterViewModel] = React.useState<AccountRegisterViewModel>(
    new AccountRegisterViewModel()
  );
  // グリッドを編集中は、accountRegisterViewModel.AccuntRegisterList[0~n].ModeFlgを別のState(modeFlgList)で管理する。
  const [modeFlgList, setModeFlgList] = React.useState<ModeFlg[]>(
    []
  );
  /* Gridで現在編集中の行index */
  const [currentIndex, setCurrentIndex] = React.useState<number>(
    -1
  );

  /* A24 消費税コード コンボボックスの選択肢 */
  const getTaxCodeDataMap = () => {
    let taxCodeDataMap = new wjGrid.DataMap(TaxCodeList, 'TaxKbnCode', 'TaxName');
    taxCodeDataMap.getDisplayValues = (dataItem: AccuntRegisterListVO) => {
      let validTaxCodes = TaxCodeList.filter(taxInfo => taxInfo.TaxKbn == dataItem.TaxKbn);
      return validTaxCodes.map(taxInfo => taxInfo.TaxName);
    };
    return taxCodeDataMap;
  };

  const getGCodeIsReadOnly = () => {
    /** 現在選択中の勘定科目 */
    let currentAccount = getCurrentAccount(1);
    if ((currentAccount) && (currentAccount.ModeFlg == ModeFlg.NewInsert)) {
      return false;
    }
    return true;
  }

  /** A27 新規ボタン A29 挿入ボタン A31 削除ボタンのDisabled */
  function getHeaderButtonsDisabled(index: number): boolean {
    /** 現在選択中の勘定科目 */
    let currentAccount = getCurrentAccount(index);
    if (currentAccount) {
      if (index == 31 && currentAccount.GCode == 999999999999) {
        return true; // disabled = true -> 押下不可能
      } else {
        return false; // disabled = false -> 押下可能
      }
    }
    return true; // disabled = true -> 押下不可能
  }

  function getAccount(index: number): AccuntRegisterListVO | undefined {
    // 科目分類コンボボックスを選択済、勘定科目一覧が存在する
    if ((kmkClass != LocalKmkClassEnum.None) &&
      (accountRegisterViewModel.AccuntRegisterList) &&
      (0 < accountRegisterViewModel.AccuntRegisterList.length) &&
      (index != undefined) &&
      (0 <= index) && (index < accountRegisterViewModel.AccuntRegisterList.length)) {

      let theAccount = deepCopyAccount(accountRegisterViewModel.AccuntRegisterList[index]);
      theAccount.ModeFlg = modeFlgList[index];
      return theAccount;
    }
    return undefined;
  }

  /** 現在選択中の勘定科目を返す */
  function getCurrentAccount(index: number): AccuntRegisterListVO | undefined {
    // 科目分類コンボボックスを選択済、勘定科目一覧が存在する
    if ((kmkClass != LocalKmkClassEnum.None) &&
      (accountRegisterViewModel.AccuntRegisterList) &&
      (0 < accountRegisterViewModel.AccuntRegisterList.length) &&
      (0 <= currentIndex) && (currentIndex < accountRegisterViewModel.AccuntRegisterList.length)) {

      let theAccount = deepCopyAccount(accountRegisterViewModel.AccuntRegisterList[currentIndex]);
      theAccount.ModeFlg = modeFlgList[currentIndex];

      //挿入29・削除31は画面にレコードが無い場合、Enable=Falseとする ⇒新規27はTrueとする
      if (index == 29 || index == 31 || index == 37 || index == 38) {
        if (accountRegisterViewModel.AccuntRegisterList.length == 1) {
          if (theAccount.GCode == 999999999999) {
            return undefined;
          }
        }
      }

      return theAccount;
    }
    return undefined;
  }

  /** 全ての勘定科目が保存済の場合true、保存してない勘定科目がある場合falseを返す */
  function isAllSaved(): boolean {
    let result: boolean = true; // 全て保存済
    // 科目分類コンボボックスを選択済、勘定科目一覧が存在する
    if ((kmkClass != LocalKmkClassEnum.None) &&
      (accountRegisterViewModel.AccuntRegisterList) &&
      (0 < accountRegisterViewModel.AccuntRegisterList.length) &&
      (currentIndex < accountRegisterViewModel.AccuntRegisterList.length)) {

      modeFlgList.forEach(it => {
        switch (it) {
          case ModeFlg.NewInsert:
          case ModeFlg.Edit:
            result = false; // 保存してない勘定科目あり
        }
      });
    }
    return result;
  }

  /** 修正中の勘定科目があればtrue、それ以外はfalseを返す */
  function isEdited(): boolean {
    let result: boolean = false;
    // 科目分類コンボボックスを選択済、勘定科目一覧が存在する
    if ((kmkClass != LocalKmkClassEnum.None) &&
      (accountRegisterViewModel.AccuntRegisterList) &&
      (0 < accountRegisterViewModel.AccuntRegisterList.length) &&
      (currentIndex < accountRegisterViewModel.AccuntRegisterList.length)) {

      modeFlgList.forEach(it => {
        switch (it) {
          case ModeFlg.Edit:
            result = true;
        }
      });
    }
    return result;
  }

  const gridColumns = [
    {
      header: Labels.AIKADT008000001_GRID_HEADER_Gcode /* A5 コード ラベル */,
      binding: 'GCode' /* A16 コード */,
      isReadOnly: getGCodeIsReadOnly(),
      align: 'left',
      width: '1*',
      format: 'd',
      maxLength: 8,
      allowMerging: false
    },
    {
      header: Labels.AIKADT008000001_GRID_HEADER_LongName /* A6 正式名称 ラベル */,
      binding: 'LongName' /* A17 正式名称 */,
      isReadOnly: false,
      align: 'left',
      width: '3*',
      maxLength: 24,
      allowMerging: false
    },
    {
      header: Labels.AIKADT008000001_GRID_HEADER_SimpleName /* A7 簡略名称 ラベル */,
      binding: 'SimpleName' /* A18 簡略名称 */,
      isReadOnly: false,
      align: 'left',
      width: '2*',
      maxLength: 14,
      allowMerging: false
    },
    {
      header: Labels.AIKADT008000001_GRID_HEADER_RenChar /* A8 連想 ラベル */,
      binding: 'RenChar' /* A19 連想 */,
      isReadOnly: false,
      align: 'left',
      width: '0.5*',
      maxLength: 10,
      allowMerging: false
    },
    {
      header: Labels.AIKADT008000001_GRID_HEADER_DCKbn /* A9 正残 ラベル */,
      binding: 'DCKbn' /* A20 正残 */,
      isReadOnly: getGCodeIsReadOnly(),
      align: 'left',
      width: '0.5*',
      allowMerging: false,
      dataMap: createWjDataMap(dataMaps.DCKbnEnum, false)
    },
    {
      header: Labels.AIKADT008000001_GRID_HEADER_BPKbn /* A10 ＢＳ／ＰＬ ラベル */,
      binding: 'BPKbn' /* A21 ＢＳ／ＰＬ */,
      isReadOnly: true,
      align: 'left',
      width: '1*',
      allowMerging: false,
      dataMap: createWjDataMap(dataMaps.BPKbnEnum, false)
    },
    {
      header: Labels.AIKADT008000001_GRID_HEADER_KisyuKbn /* A11 期首残 ラベル */,
      binding: 'KisyuKbn' /* A22 期首残 */,
      isReadOnly: getGCodeIsReadOnly(),
      align: 'left',
      width: '0.75*',
      allowMerging: false,
      dataMap: createWjDataMap(dataMaps.KisyuKbnEnum, false)
    },
    {
      header: Labels.AIKADT008000001_GRID_HEADER_TaxKbn /* A12 科目区分 ラベル */,
      binding: 'TaxKbn' /* A23 科目区分 */,
      isReadOnly: false,
      align: 'left',
      width: '0.75*',
      allowMerging: false,
      dataMap: createWjDataMap(dataMaps.TaxKbnEnum, false)
    },
    {
      header: Labels.AIKADT008000001_GRID_HEADER_TaxCode /* A13 消費税コード ラベル */,
      binding: 'TaxKbnCode' /* A24 消費税コード */,
      isReadOnly: false,
      align: 'left',
      width: '2.5*',
      allowMerging: false,
      dataMap: getTaxCodeDataMap()
    },
    {
      header: Labels.AIKADT008000001_GRID_HEADER_TaxRate /* A14 消費税率 ラベル */,
      binding: 'TaxRate' /* A25 消費税率 */,
      isReadOnly: false,
      align: 'left',
      width: '1*',
      allowMerging: false,
      dataMap: createWjDataMap(LocalTaxRateEnum, false)
    },
    {
      header: Labels.AIKADT008000001_GRID_HEADER_TypeCode /* A15 業種コード ラベル */,
      binding: 'TypeCode' /* A26 業種コード */,
      isReadOnly: false,
      align: 'left',
      width: '1*',
      allowMerging: false,
      dataMap: createWjDataMap(dataMaps.TypeCodeEnum, false)
    },
  ];  

  // 初期表示処理
  React.useEffect(() => {
    // 処理判定
    async function JudgeOnPost(url: string, syoriId: string) {
      const status = await Axios.post<number>(url, {syoriId: syoriId});
      if (status.data == 0) {
        // 初期起動
        InitialOnGet();
        await Axios.post(WriteSysHistoryURL, {syoriId: syoriId, operation: '処理開始'});
      } else {
        if(status.data == -101) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotUse});
        } else if (status.data == -102) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractOver});
        } else if (status.data == -103) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_Notauthorized});
        } else if (status.data == -104) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotDataSelect});
        } else if (status.data == -105) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractEqual});
        }
        props.onClose();
      }
    }
    JudgeOnPost(JudgeURL, 'AIKADT008000001');
  }, [props.fiscalYear]);

  const InitialOnGet = () => {
    let url: string = InitialURL;
    //console.debug('AccountRegister.InitialOnGet() URL=' + url);
    Axios.get<TaxKubunRelationInfoViewModel>(url)
      .then(response => {
        //console.debug('AccountRegister.InitialOnGet() then URL=' + url);
        setTaxKubunRelationInfo(response.data);
      })
      .catch(error => {
        //console.debug('AccountRegister.InitialOnGet() catch URL=' + url + ' error=' + error);
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_InternalServerError });
      })
      .finally(() => {
        //console.debug('AccountRegister.InitialOnGet() finally URL=' + url);

        // フォーカス順序を制御するための初期処理
        if ((thisPage != undefined) && (thisPage.current != undefined)) {
          thisPage.current.removeEventListener('keydown', handleKeyDown);
          thisPage.current.addEventListener('keydown', handleKeyDown);
        }
        if ((firstFocusElement != undefined) && (firstFocusElement.current != undefined)) {
          firstFocusElement.current.focus();
        }

        // 科目分類を選択し、勘定科目一覧がグリッドに表示されている状態で会計年度(会計期間)を変更された場合
        // 新しい会計年度で勘定科目一覧グリッドを再表示する
        //if (kmkClass != LocalKmkClassEnum.None) {
        //  KmkListOnGet(castKmkClassEnum(kmkClass));
        //
        //  setCurrentIndex(-1);
        //  setTaxKmkListInit([]);
        //  setDeletedSavedAccountConfirmMessage('');
        //  setDeletedSavedAccountConfirmActivated(false);
        //  setChangeKmkClassConfirmActivated(false);
        //  setNewKmkClass(LocalKmkClassEnum.None);
        //  setCurrentGCode('');
        //  setDeleteAccountUnsavedConfirmActivated(false);
        //  setDeleteAccountNewInsertConfirmActivated(false);
        //  setDeleteAccountEditConfirmActivated(false);
        //  setOutputExcelUnsavedConfirmActivated(false);
        //  setExcelOutputsettingActivated(false);
        //  setCheckResultMessage('');
        //}

        // 科目分類を選択し、勘定科目一覧がグリッドに表示されている状態で会計年度(会計期間)を変更された場合も考慮し
        // 画面を初期表示状態(科目分類=未選択状態)にする。
        setKmkClass(LocalKmkClassEnum.None);
        setAccountRegisterViewModel(new AccountRegisterViewModel());
        setModeFlgList([]);
        setCurrentIndex(-1);
        setTaxKmkListInit([]);
        setDeletedSavedAccountConfirmMessage('');
        setDeletedSavedAccountConfirmActivated(false);
        setChangeKmkClassConfirmActivated(false);
        setNewKmkClass(LocalKmkClassEnum.None);
        setCurrentGCode('');
        setDeleteAccountUnsavedConfirmActivated(false);
        setDeleteAccountNewInsertConfirmActivated(false);
        setDeleteAccountEditConfirmActivated(false);
        setOutputExcelUnsavedConfirmActivated(false);
        setExcelOutputsettingActivated(false);
        setCheckResultMessage('');
      });
  }

  /** キーボード操作（キーダウン） */
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 9) { // タブキー押下
      /** キー押下時点でフォーカスが存在する要素 */
      const target: HTMLElement = event.target as HTMLElement;
      /** targetが勘定科目グリッドだった場合true それ以外の場合false */
      const targetIsGrid: boolean = ((0 <= target.className.search(/wj-/)) && (target.tabIndex == 0)) ? true : false;

      if (event.shiftKey === false) {
        // タブキー押下時
        if (lastFocusElement.current.disabled == false) {
          // 最後のエレメントが使用可能(入力可能・押下可能)の場合
          if (targetIsGrid == true) {
            // グリッド→(グリッドの次の)新規ボタンへ
            createButton.current.focus();
            // 後のイベントをキャンセル
            event.preventDefault();
            event.stopPropagation();
          }
          else if ((target.tabIndex === lastFocusElement.current.tabIndex) ||
            (target.tabIndex < firstFocusElement.current.tabIndex) ||
            (target.tabIndex > lastFocusElement.current.tabIndex)) {
            // 最後のフォーカスエレメントの場合
            // 最初のエレメントにフォーカス
            firstFocusElement.current.focus();
            // 後のイベントをキャンセル
            event.preventDefault();
            event.stopPropagation();
          }
        }
        else {
          // 最後のエレメントが使用不可能(入力不可能・押下不可能)の場合
          if (target.tabIndex == firstFocusElement.current.tabIndex) {
            // 科目分類コンボボックスの場合、(タブ押下→)次のグリッドをスキップする
            excelButton.current.focus();
            // 後のイベントをキャンセル
            event.preventDefault();
            event.stopPropagation();
          }
          else if ((target.tabIndex === lastFocusElementSub.current.tabIndex) ||
            (target.tabIndex < firstFocusElement.current.tabIndex) ||
            (target.tabIndex > lastFocusElementSub.current.tabIndex)) {
            // 最後のフォーカスエレメントの場合
            // 最初のエレメントにフォーカス
            firstFocusElement.current.focus();
            // 後のイベントをキャンセル
            event.preventDefault();
            event.stopPropagation();
          }
        }
      }
      else {
        // シフト＋タブキー押下時
        if ((lastFocusElement.current.disabled == true) &&
          (target.tabIndex == excelButton.current.tabIndex)) {
          // 最後のエレメントが使用不可能(入力不可能・押下不可能) &
          // Excel出力ボタンの場合、(シフト＋タブ押下→)前のグリッドをスキップする
          firstFocusElement.current.focus();
          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
        else if (targetIsGrid == true) {
          // グリッド→(グリッドの前の)科目分類コンボボックスへ
          firstFocusElement.current.focus();
          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
        else if ((target.tabIndex === firstFocusElement.current.tabIndex) ||
          (target.tabIndex < firstFocusElement.current.tabIndex) ||
          (target.tabIndex > lastFocusElement.current.tabIndex)) {
          // 最後のフォーカスエレメントの場合
          // 最後のエレメントにフォーカス
          if (lastFocusElement.current.disabled == false) {
            // 最後のエレメントが使用可能(入力可能・押下可能)の場合
            lastFocusElement.current.focus();
          }
          else {
            // 最後のエレメントが使用不可能(入力不可能・押下不可能)の場合
            lastFocusElementSub.current.focus();
          }
          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }
  };

  // 画面初期表示時にサーバから取得した消費税関連の情報(入力値チェック用の各種マスターデータ)
  const [taxKubunRelationInfo, setTaxKubunRelationInfo] = React.useState<TaxKubunRelationInfoViewModel>(
    new TaxKubunRelationInfoViewModel()
  );

  //入力値チェックに使用するList
  const [taxKmkListInit, setTaxKmkListInit] = React.useState<AccuntRegisterListVO[]>([]);
  const [sysReservetaxKmkListInit, setSysReservetaxKmkListInit] = React.useState<AccuntRegisterListVO[]>([]);

  /**
   * 科目分類を変更時にサーバへアクセスする処理 通信処理ID AIKADT008000001_Get2 科目一覧作成
   * @param kmkClass 科目分類
   */
  const KmkListOnGet = (kmkClass: enums.KmkClassEnum) => {
    setIsLoading(true);
    let url: string = KmkListURL;
    //console.debug('AccountRegister.KmkListOnGet() URL=' + url + ' kmkClass=' + kmkClass);
    Axios.get<AccountRegisterViewModel>(url, { params: { KmkClass: kmkClass }})
      .then(response => {
        //console.debug('AccountRegister.KmkListOnGet() then URL=' + url);
        if (response.data.AccuntRegisterList != undefined) {
          setTaxKbnCodeFromTaxKbnTaxCode(response.data.AccuntRegisterList);
        }
        setAccountRegisterViewModel({ ...accountRegisterViewModel, AccuntRegisterList: response.data.AccuntRegisterList });

        // グリッドを編集中は、accountRegisterViewModel.AccuntRegisterList[0~n].ModeFlgを別のState(modeFlgList)で管理する。
        saveModeFlgList(response.data.AccuntRegisterList);

        setCheckResultMessage('');

        // 消費税科目の消費税科目区分(TaxKbn)をtaxKmkListInitに保存する(入力値チェックに使うため)
        if ((response.data.AccuntRegisterList != undefined) && (taxKubunRelationInfo.TaxKmkList != undefined)) {
          let accuntRegisterList = response.data.AccuntRegisterList;
          let initList: AccuntRegisterListVO[] = [];
          taxKubunRelationInfo.TaxKmkList.forEach(it => {
            for (let i: number = 0; i < accuntRegisterList.length; i++) {
              if (accuntRegisterList[i].GCode == it.TaxKmkGCode) {
                let theAccount = new AccuntRegisterListVO();
                theAccount.GCode = accuntRegisterList[i].GCode;
                theAccount.TaxKbn = accuntRegisterList[i].TaxKbn;
                initList.push(theAccount);
              }
            }
          });
          setTaxKmkListInit(initList);
        }
        //システム予約科目の消費税科目区分(TaxKbn)をsysReservetaxKmkListInitに保存する(入力値チェックに使うため)
        if (response.data.AccuntRegisterList != undefined) {
          let accuntRegisterList = response.data.AccuntRegisterList;
          let sysReservetaInitList: AccuntRegisterListVO[] = [];
          for (let i: number = 0; i < accuntRegisterList.length; i++) {
            if (accuntRegisterList[i].DeleteNgFlag) {
              let theAccount = new AccuntRegisterListVO();
              theAccount.GCode = accuntRegisterList[i].GCode;
              theAccount.TaxKbn = accuntRegisterList[i].TaxKbn;
              sysReservetaInitList.push(theAccount);
            }
          }
          setSysReservetaxKmkListInit(sysReservetaInitList);
        }
      })
      .catch(error => {
        //console.debug('AccountRegister.KmkListOnGet() catch URL=' + url + ' error=' + error);
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_InternalServerError });
      })
      .finally(() => {
        //console.debug('AccountRegister.KmkListOnGet() finally URL=' + url);
        setIsLoading(false);
      });
  }

  /**
   * サーバから取得した科目区分(TaxKbn)と消費税コード(TaxCode)を基にして、一覧グリッドの消費税コードの内部キー値(TaxKbnCode)を設定する
   * @param accountRegisterList
   */
  function setTaxKbnCodeFromTaxKbnTaxCode(accountRegisterList: Array<AccuntRegisterListVO>): void {
    accountRegisterList.forEach((account, index, array) => {
      if ((account.TaxKbn != undefined) && (account.TaxCode != undefined)) {
        account["TaxKbnCode"] = account.TaxKbn * 100 + account.TaxCode;
      }
    });
  }

  /**
   * 一覧グリッドの消費税コードの内部キー値(TaxKbnCode)を基にして、サーバ向けの消費税コード(TaxCode)を設定する
   * @param accountRegisterList
   */
  function setTaxCodeFromTaxKbnCode(accountRegisterList: Array<AccuntRegisterListVO>): void {
    accountRegisterList.forEach((account, index, array) => {
      if ((account["TaxKbnCode"] != undefined) && (typeof account["TaxKbnCode"] == 'number')) {
        account.TaxCode = (account["TaxKbnCode"] % 100);
      }
    });
  }

  function saveModeFlgList(accuntRegisterList?: Array<AccuntRegisterListVO>): void {
    // グリッドを編集中は、accountRegisterViewModel.AccuntRegisterList[0~n].ModeFlgを別のState(modeFlgList)で管理する。
    // accountRegisterViewModel.AccuntRegisterList[0~n].ModeFlgを更新するためにsetAccountRegisterViewModel()すると、
    // グリッド全体を再設定・再描画するため、グリッドの1行目を選択・表示してしまう→グリッドの使い勝手が悪い。
    let newModeFlgList: number[] = Array<number>(0);
    if (accuntRegisterList != undefined) {
      newModeFlgList = Array<number>(accuntRegisterList.length);
      for (let i: number = 0; i < newModeFlgList.length; i++) {
        switch (accuntRegisterList[i].ModeFlg) {
          case ModeFlg.NotEdited:
            newModeFlgList[i] = ModeFlg.NotEdited;
            break;
          case ModeFlg.NewInsert:
            newModeFlgList[i] = ModeFlg.NewInsert;
            break;
          case ModeFlg.Edit:
            newModeFlgList[i] = ModeFlg.Edit;
            break;
        }
      }
    }
    setModeFlgList(newModeFlgList);
  }

  /**
   * Excel出力ボタンを押下時にサーバへアクセスする処理 通信処理ID AIKADT008000001_Get3 出力データ作成
   */
  const OutputOnGet = () => {
    let url: string = OutputURL;
    Axios.get<AccountExcelViewModel[]>(url, { params: { KmkClass: 0 } })
      .then(response => {
        //console.debug('AccountRegister.OutputOnGet() then URL=' + url);
      })
      .catch(error => {
        //console.debug('AccountRegister.OutputOnGet() catch URL=' + url + ' error=' + error);
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_InternalServerError });
      })
      .finally(() => {
        //console.debug('AccountRegister.OutputOnGet() finally URL=' + url);
      });
  }

  /**
   * 削除ボタンを押下時にサーバへアクセスする処理 通信処理ID AIKADT008000001_Post1 勘定科目削除
   * @param kmkClass 科目分類
   * @param GCode 削除対象の(現在選択している)勘定科目の外部コード
   */
  const DeleteOnPost = (kmkClass: enums.KmkClassEnum, gCode: number) => {
    setIsLoading(true);
    let url: string = DeleteURL;

    let currentAccount = getCurrentAccount(31);
    //選択中の勘定科目がシステム予約科目だった場合はエラー
    if (currentAccount?.DeleteNgFlag) {
      /** 現在選択中の勘定科目 */
      let gCode: string = '';
      if ((currentAccount) && (currentAccount.GCode)) {
        gCode = currentAccount.GCode.toString();
      }
      setIsLoading(false);
        setDeletedSavedAccountConfirmMessage(messageUtils.bindValueToMessage(message.RegisterAccount_Error_Delete_DeleteNg, [gCode]));
      setDeletedSavedAccountConfirmActivated(true);
      return;
    }

    //console.debug('AccountRegister.DeleteOnPost() URL=' + url + ' gCode=' + gCode);
    Axios.post<Post1Response>(url, { kmkClass, gCode })
      .then(response => {
        //console.debug('AccountRegister.DeleteOnPost() then URL=' + url);
        /** 現在選択中の勘定科目 */
        let currentAccount = getCurrentAccount(31);
        let gCode: string = '';
        if ((currentAccount) && (currentAccount.GCode)) {
          gCode = currentAccount.GCode.toString();
        }
        //setPost1Response(response.data);
        switch (response.data.Item2) {
          case 0:
            if (response.data.Item1) {
              if (response.data.Item1.AccuntRegisterList != undefined) {
                setTaxKbnCodeFromTaxKbnTaxCode(response.data.Item1.AccuntRegisterList);
              }
              setAccountRegisterViewModel(response.data.Item1);
              saveModeFlgList(response.data.Item1.AccuntRegisterList);
              setCheckResultMessage('');
            }
            setAlertMessage({
              alerted: true,
              alertKbn: AlertKbnEnum.success,
              message: messageUtils.bindValueToMessage(message.RegisterAccount_Error_DeleteComplete, [gCode == "" ? "選択行" : gCode])
            });
            break;
          case 1:
            setDeletedSavedAccountConfirmMessage(messageUtils.bindValueToMessage(message.RegisterAccount_Error_DeleteAlready, [gCode, '削除']));
            setDeletedSavedAccountConfirmActivated(true);
            break;
          case 2:
            setDeletedSavedAccountConfirmMessage(messageUtils.bindValueToMessage(message.RegisterAccount_Error_Delete_JournalExist, [gCode]));
            setDeletedSavedAccountConfirmActivated(true);
            break;
          case 3:
            setDeletedSavedAccountConfirmMessage(messageUtils.bindValueToMessage(message.RegisterAccount_Error_Delete_BalanceExist, [gCode]));
            setDeletedSavedAccountConfirmActivated(true);
            break;
          default:
            break;
        }
      })
      .catch(error => {
        //console.debug('AccountRegister.DeleteOnPost() catch URL=' + url + ' error=' + error);
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_InternalServerError });
      })
      .finally(() => {
        //console.debug('AccountRegister.DeleteOnPost() finally URL=' + url);
        setIsLoading(false);
      });
  }

  /** 確認メッセージのOKボタンへの参照 */
  const refer2DeletedSavedAccountConfirmButton = React.useRef<any>();
  // 確認メッセージ本文
  const [deletedSavedAccountConfirmMessage, setDeletedSavedAccountConfirmMessage] = React.useState<string>(
    ''
  );
  // 確認メッセージを表示するフラグ(true=表示する)
  const [deletedSavedAccountConfirmActivated, setDeletedSavedAccountConfirmActivated] = React.useState(false);
  /** 確認メッセージを表示した際の処理 */
  const handleDeletedSavedAccountConfirmOpened = () => {
    // OKボタンへフォーカスをセットする
    if (refer2DeletedSavedAccountConfirmButton.current != undefined) {
      refer2DeletedSavedAccountConfirmButton.current.focus();
    }
  }
  /** 確認メッセージを閉じた際の処理 */
  const handleDeletedSavedAccountConfirmClose = () => {
    setDeletedSavedAccountConfirmActivated(false);
  };
  /** 確認メッセージを閉じる際の処理 */
  const handleDeletedSavedAccountConfirmCloseClick = () => {
    setDeletedSavedAccountConfirmActivated(false);
  };


  /**
   * 保存ボタンを押下時にサーバへアクセスする処理 通信処理ID AIKADT008000001_Post2 勘定科目登録
   * @param kmkClass 科目分類
   * @param accounts 勘定科目一覧
   */
  const UpdateOnPost = (kmkClass: enums.KmkClassEnum, accounts: AccountRegisterViewModel) => {
    setIsLoading(true);

    // サーバへ送信する前に、サーバ向けに中身を調整する
    if (accounts.AccuntRegisterList != undefined) {
      setTaxCodeFromTaxKbnCode(accounts.AccuntRegisterList);

      for (let i: number = 0; i < accounts.AccuntRegisterList.length; i++) {
        switch (accounts.AccuntRegisterList[i].TaxKbn) {
          case enums.TaxKbnEnum.Earnings:
            accounts.AccuntRegisterList[i].STaxCode = accounts.AccuntRegisterList[i].TaxCode;
            accounts.AccuntRegisterList[i].STaxRate = accounts.AccuntRegisterList[i].TaxRate;
            accounts.AccuntRegisterList[i].BTaxCode = BTaxCode.None;
            accounts.AccuntRegisterList[i].BTaxRate = enums.TaxRateEnum.Normal;
            break;
          case enums.TaxKbnEnum.Purchase:
            accounts.AccuntRegisterList[i].STaxCode = 0;
            accounts.AccuntRegisterList[i].STaxRate = enums.TaxRateEnum.Normal;
            accounts.AccuntRegisterList[i].BTaxCode = accounts.AccuntRegisterList[i].TaxCode;
            accounts.AccuntRegisterList[i].BTaxRate = accounts.AccuntRegisterList[i].TaxRate;
            break;
          case enums.TaxKbnEnum.Nothing:
            accounts.AccuntRegisterList[i].STaxCode = 0;
            accounts.AccuntRegisterList[i].STaxRate = enums.TaxRateEnum.Normal;
            accounts.AccuntRegisterList[i].BTaxCode = BTaxCode.None;
            accounts.AccuntRegisterList[i].BTaxRate = enums.TaxRateEnum.Normal;
            break;
        }
        accounts.AccuntRegisterList[i].ModeFlg = modeFlgList[i];
      }
    }

    let url: string = UpdateURL;
    Axios.post<Post2Response>(url, {kmkClass, accounts})
      .then(response => {
        //console.debug('AccountRegister.UpdateOnPost() then URL=' + url);
        /** エラー対象の勘定科目 */
        let gCode: string = '';
        if ((response.data) && (response.data.Item3)) {
          gCode = response.data.Item3.toString();
        }
        //setPost2Response(response.data);
        switch (response.data.Item2) {
          case 0:
            if (response.data.Item1) {
              if (response.data.Item1.AccuntRegisterList != undefined) {
                setTaxKbnCodeFromTaxKbnTaxCode(response.data.Item1.AccuntRegisterList)
              }
              setAccountRegisterViewModel(response.data.Item1);
              saveModeFlgList(response.data.Item1.AccuntRegisterList);
              setCheckResultMessage('');
            }
            setAlertMessage({
              alerted: true,
              alertKbn: AlertKbnEnum.success,
              message: messageUtils.bindValueToMessage(message.Common_Success_Registration, ['勘定科目'])
            });
            //setDeletedSavedAccountConfirmMessage(messageUtils.bindValueToMessage(message.Common_Success_Registration, ['勘定科目']));
            //setDeletedSavedAccountConfirmActivated(true);
            break;
          case 1:
            setDeletedSavedAccountConfirmMessage(messageUtils.bindValueToMessage(message.RegisterAccount_Error_Insert_Duplicate, [gCode]));
            setDeletedSavedAccountConfirmActivated(true);
            break;
          case 2:
            setDeletedSavedAccountConfirmMessage(messageUtils.bindValueToMessage(message.RegisterAccount_Error_Insert_UsageProhibited, [gCode]));
            setDeletedSavedAccountConfirmActivated(true);
            break;
          case 3:
            setDeletedSavedAccountConfirmMessage(messageUtils.bindValueToMessage(message.RegisterAccount_Error_Insert_AlreadyDeleted, [gCode, '編集']));
            setDeletedSavedAccountConfirmActivated(true);
            break;
          default:
            break;
        }
      })
      .catch(error => {
        //console.debug('AccountRegister.UpdateOnPost() catch URL=' + url + ' error=' + error);
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_InternalServerError });
      })
      .finally(() => {
        //console.debug('AccountRegister.UpdateOnPost() finally URL=' + url);
        setIsLoading(false);
      });
  }

  /**
   * LocalKmkClassEnum を KmkClassEnum に変換する
   * @param local 変換元 LocalKmkClassEnum
   */
  function castKmkClassEnum(local : LocalKmkClassEnum): enums.KmkClassEnum {
    switch (local) {
      case LocalKmkClassEnum.AssetsKmk:
        return enums.KmkClassEnum.AssetsKmk;
      case LocalKmkClassEnum.LliabilitiesKmk:
        return enums.KmkClassEnum.LliabilitiesKmk;
      case LocalKmkClassEnum.ProfitLossKmk:
        return enums.KmkClassEnum.ProfitLossKmk;
      case LocalKmkClassEnum.manufacKmk:
        return enums.KmkClassEnum.manufacKmk;
    }
    // case LocalKmkClassEnum.None
    return enums.KmkClassEnum.AssetsKmk;
  }

  // A3 科目分類ComboBox
  const [kmkClassCmb, setKmkClassCmb] = React.useState<wjInput.ComboBox>();

  /**
   * A3 科目分類が初期化された際の処理
   * @param comboBox 初期化されたA3 科目分類ComboBox
   */
  const kmkClassInitialized = (comboBox: wjInput.ComboBox) => {
    if ((kmkClassCmb == undefined) || (kmkClassCmb == null)) {
      setKmkClassCmb(comboBox);
      // tabindexを設定する
      comboBox.inputElement.tabIndex = 1;
      // firstFocusElementを設定する
      firstFocusElement.current = comboBox.inputElement;
    }
  };

  /**
   * A3 科目分類を変更した際の処理
   */
  const handleOnKmkClassChange = () => {
    if ((kmkClassCmb != undefined) && (kmkClassCmb.selectedItem != undefined)) {
      /** 変更後の値 */
      let newValue: LocalKmkClassEnum = parseInt(kmkClassCmb.selectedItem.key);
      if (kmkClass != newValue) {
        handleOnKmkClassChange2(newValue);
      }
    }
  };
  /**
   * A3 科目分類を変更した際の処理 ... handleOnKmkClassChange()とhandleOnCancelClick()から呼び出される
   * @param newValue 変更後の科目分類の値
   */
  const handleOnKmkClassChange2 = (newValue: LocalKmkClassEnum) => {
    setNewKmkClass(newValue); // このタイミングでは科目分類を更新せず、変更後の値をいったん保存する

    if (isAllSaved()) {
      // 未保存の項目が存在しない場合
      if (kmkClass != newValue) {
        setKmkClass(newValue); // 科目分類を変更後の値で更新する
        changeKmkClass(newValue);
      }
    }
    else {
      // 未保存の項目が存在する場合、確認メッセージを表示する
      setChangeKmkClassConfirmActivated(true);
    }
  }

  // 科目分類を変更する際の確認メッセージ
  const [changeKmkClassConfirmActivated, setChangeKmkClassConfirmActivated] = React.useState<boolean>(false);
  const handleChangeKmkClassConfirmClose = () => {
    setChangeKmkClassConfirmActivated(false);
  };
  const handleChangeKmkClassConfirmYes = () => {
    setKmkClass(newKmkClass); // 科目分類を変更後の値で更新する
    changeKmkClass(newKmkClass);
    setChangeKmkClassConfirmActivated(false);
  };
  const handleChangeKmkClassConfirmNo = () => {
    if (kmkClassCmb != undefined) {
      // 科目分類コンボボックスを変更前の状態に戻す
      // (Wijmo ComboBoxの場合、State(kmkClass)の値は変わらないが見た目が変更後に変わってしまっているので、見た目を変更前に戻す)
      kmkClassCmb.selectedValue = kmkClass;
    }
    setChangeKmkClassConfirmActivated(false);
  };

  // A3 科目分類の変更後の値
  const [newKmkClass, setNewKmkClass] = React.useState<LocalKmkClassEnum>(LocalKmkClassEnum.None);

  /**
   * 科目分類を変更する際の処理(一覧グリッドを更新する)
   * @param newValue 変更後の科目分類の値
   */
  const changeKmkClass = (newValue: LocalKmkClassEnum): void => {
    if (newValue != LocalKmkClassEnum.None) {
      KmkListOnGet(castKmkClassEnum(newValue));
    }
    else {
      setAccountRegisterViewModel(new AccountRegisterViewModel());
      saveModeFlgList(undefined);
      setCheckResultMessage('');
    }
  }

  /** A27 新規ボタン押下時の処理 */
  const handleOnNewClick = () => {
    /** 現在選択中の勘定科目 */
    let currentAccount = getCurrentAccount(27);
    if (currentAccount) {
      if (accountRegisterViewModel.AccuntRegisterList) {
        // 最終行をコピー元にする
        currentAccount = getAccount(accountRegisterViewModel.AccuntRegisterList.length - 1);
        if (accountRegisterViewModel.AccuntRegisterList.length > 1) {//グリッドが2行以上の場合(ダミー以外に1行以上ある場合)
          if (currentAccount?.GCode == 999999999999) {
            //コピー元がダミーだった場合、もう1行上の行をコピー元とする
            currentAccount = getAccount(accountRegisterViewModel.AccuntRegisterList.length - 2);
          }
        }
        if (currentAccount) {
          let newOne = deepCopyAccount(currentAccount);
          newOne.GCode = undefined;
          newOne.LongName = '';
          newOne.SimpleName = '';
          newOne.RenChar = '';
          newOne.ModeFlg = ModeFlg.NewInsert;
          newOne.CopyGCode = currentAccount.CopyGCode;

          let newList = accountRegisterViewModel.AccuntRegisterList.slice();
          newList.push(newOne);
          setAccountRegisterViewModel({ ...accountRegisterViewModel, AccuntRegisterList: newList });

          let newModeFlgList = modeFlgList.slice();
          newModeFlgList.push(ModeFlg.NewInsert);
          setModeFlgList(newModeFlgList);

          // 最終行にフォーカスを移動する
          setFocusToGird(accountRegisterViewModel.AccuntRegisterList.length, 0, 250); // col=0:コード
        }
      }
    }
  }

  /** A29 挿入ボタン押下時の処理 */
  const handleOnInsertClick = () => {
    /** 現在選択中の勘定科目 */
    let currentAccount = getCurrentAccount(29);
    if (currentAccount) {
      let newOne = deepCopyAccount(currentAccount);
      newOne.GCode = undefined;
      newOne.LongName = '';
      newOne.SimpleName = '';
      newOne.RenChar = '';
      newOne.ModeFlg = ModeFlg.NewInsert;
      newOne.CopyGCode = currentAccount.CopyGCode;

      if (accountRegisterViewModel.AccuntRegisterList) {
        let newList = accountRegisterViewModel.AccuntRegisterList.slice();
        newList.splice(currentIndex, 0, newOne);
        setAccountRegisterViewModel({ ...accountRegisterViewModel, AccuntRegisterList: newList });

        let newModeFlgList = modeFlgList.slice();
        newModeFlgList.splice(currentIndex, 0, ModeFlg.NewInsert);
        setModeFlgList(newModeFlgList);

        // その行にフォーカスを移動する
        setFocusToGird(currentIndex, 0, 250); // col=0:コード
      }
    }
  }

  function deepCopyAccount(src: AccuntRegisterListVO): AccuntRegisterListVO {
    let dest = new AccuntRegisterListVO();
    dest.ClientCode = src.ClientCode;
    dest.ClientYear = src.ClientYear;
    dest.ClientUser = src.ClientUser;
    dest.KmkClass = src.KmkClass;
    dest.ModeFlg = src.ModeFlg;
    dest.CopyGCode = src.CopyGCode;
    dest.MasterKbn = src.MasterKbn;
    dest.SumKbn = src.SumKbn;
    dest.RecordKbn = src.RecordKbn;
    dest.GCode = src.GCode;
    dest.SubCode = src.SubCode;
    dest.RenChar = src.RenChar;
    dest.LongName = src.LongName;
    dest.SimpleName = src.SimpleName;
    dest.NCode = src.NCode;
    dest.UpdateTime = src.UpdateTime ? new Date(src.UpdateTime) : src.UpdateTime;
    dest.StartDate = src.StartDate ? new Date(src.StartDate) : src.StartDate;
    dest.EndDate = src.EndDate ? new Date(src.EndDate) : src.EndDate;
    dest.AnalyzeCode = src.AnalyzeCode;
    dest.SumAnaCode = src.SumAnaCode;
    dest.CharCode = src.CharCode;
    dest.ECharCode1 = src.ECharCode1;
    dest.ECharCode2 = src.ECharCode2;
    dest.ECharCode3 = src.ECharCode3;
    dest.DCKbn = src.DCKbn;
    dest.BPKbn = src.BPKbn;
    dest.KisyuKbn = src.KisyuKbn;
    dest.NonDspKbn = src.NonDspKbn;
    dest.PerKmkKbn = src.PerKmkKbn;
    dest.KmkKbn1 = src.KmkKbn1;
    dest.KmkKbn2 = src.KmkKbn2;
    dest.KmkKbn3 = src.KmkKbn3;
    dest.KmkKbn4 = src.KmkKbn4;
    dest.KmkKbn5 = src.KmkKbn5;
    dest.KmkKbn6 = src.KmkKbn6;
    dest.KmkKbn7 = src.KmkKbn7;
    dest.KmkKbn8 = src.KmkKbn8;
    dest.UseKbn = src.UseKbn;
    dest.KoteiKbn = src.KoteiKbn;
    dest.DebitCash = src.DebitCash;
    dest.CreditCash = src.CreditCash;
    dest.TaxKbn = src.TaxKbn;
    dest.STaxCode = src.STaxCode;
    dest.STaxRate = src.STaxRate;
    dest.BTaxCode = src.BTaxCode;
    dest.BTaxRate = src.BTaxRate;
    dest.TaxCode = src.TaxCode;
    dest["TaxKbnCode"] = src["TaxKbnCode"];
    dest.TaxRate = src.TaxRate;
    dest.TypeCode = src.TypeCode;
    dest.TaxKbn2 = src.TaxKbn2;
    dest.TaxKbn3 = src.TaxKbn3;
    dest.TaxKbn4 = src.TaxKbn4;
    dest.TaxKbn5 = src.TaxKbn5;
    dest.TaxKbn6 = src.TaxKbn6;
    dest.SubKmkUse = src.SubKmkUse;
    dest.HojyoUse1 = src.HojyoUse1;
    dest.HojyoUse2 = src.HojyoUse2;
    dest.BmnUse = src.BmnUse;
    dest.SegUse1 = src.SegUse1;
    dest.SegUse2 = src.SegUse2;
    dest.SegUse3 = src.SegUse3;
    dest.SegUse4 = src.SegUse4;
    dest.KoujiUse = src.KoujiUse;
    dest.KUseKbn1 = src.KUseKbn1;
    dest.KUseKbn2 = src.KUseKbn2;
    dest.KUseKbn3 = src.KUseKbn3;
    dest.KUseKbn4 = src.KUseKbn4;
    dest.KUseKbn5 = src.KUseKbn5;
    dest.KUseKbn6 = src.KUseKbn6;
    dest.BHSumKbn1 = src.BHSumKbn1;
    dest.BHSumKbn2 = src.BHSumKbn2;
    dest.InputKbn = src.InputKbn;
    dest.DInputKbn = src.DInputKbn;
    dest.DInputAlw = src.DInputAlw;
    dest.TInputKbn = src.TInputKbn;
    dest.TInputAlw = src.TInputAlw;
    dest.TMInputKbn = src.TMInputKbn;
    dest.TMInputAlw = src.TMInputAlw;
    dest.NoInputKbn1 = src.NoInputKbn1;
    dest.NoInputAlw1 = src.NoInputAlw1;
    dest.NoInputKbn2 = src.NoInputKbn2;
    dest.NoInputAlw2 = src.NoInputAlw2;
    dest.EInputKbn1 = src.EInputKbn1;
    dest.EInputAlw1 = src.EInputAlw1;
    dest.EInputKbn2 = src.EInputKbn2;
    dest.EInputAlw2 = src.EInputAlw2;
    dest.TkInputKbn = src.TkInputKbn;
    dest.TkClassCode = src.TkClassCode;
    dest.DPointNum = src.DPointNum;
    dest.CashCodeKbn = src.CashCodeKbn;
    dest.EInpKbn1 = src.EInpKbn1;
    dest.EInpKbn2 = src.EInpKbn2;
    dest.EInpKbn3 = src.EInpKbn3;
    dest.EInpKbn4 = src.EInpKbn4;
    dest.EInpKbn5 = src.EInpKbn5;
    dest.DupCodeN4 = src.DupCodeN4;
    dest.DupCode4 = src.DupCode4;
    dest.DupCode3 = src.DupCode3;
    dest.BankCode = src.BankCode;
    dest.SectionCode = src.SectionCode;
    dest.NCode2 = src.NCode2;
    dest.NoName1 = src.NoName1;
    dest.NoName2 = src.NoName2;
    dest.NoInputKbn3 = src.NoInputKbn3;
    dest.NoInputAlw3 = src.NoInputAlw3;
    dest.DeleteNgFlag = src.DeleteNgFlag;
    return dest;
  }

  /** A31 削除ボタン押下時の処理 */
  const handleOnDeleteClick = () => {
    /** 現在選択中の勘定科目 */
    let currentAccount = getCurrentAccount(31);
    if (currentAccount) {
      if (currentAccount.GCode != undefined) {
        setCurrentGCode(currentAccount.GCode.toString());
      }
      else {
        setCurrentGCode('');
      }
      if (isEdited()) {
        setDeleteAccountUnsavedConfirmActivated(true);
      }
      else {
        if (currentAccount.ModeFlg != undefined) {
          if (currentAccount.ModeFlg == ModeFlg.NewInsert) {
            // クライアントで削除する場合(まだサーバへ保存してない勘定科目)
            setDeleteAccountNewInsertConfirmActivated(true);
          }
          else {
            // サーバで削除する場合(サーバに登録済の勘定科目)
            setDeleteAccountEditConfirmActivated(true);
          }
        }
      }
    }
  }

  /* 現在選択中の勘定科目のGCode */
  const [currentGCode, setCurrentGCode] = React.useState<string>('');

  const [deleteAccountUnsavedConfirmActivated, setDeleteAccountUnsavedConfirmActivated] = React.useState(false);
  const handleDeleteAccountUnsavedConfirmClose = () => {
    setDeleteAccountUnsavedConfirmActivated(false);
  };
  const handleDeleteAccountUnsavedConfirmYes = () => {
    let currentAccount = getCurrentAccount(31);
    if ((currentAccount) && (currentAccount.ModeFlg != undefined)) {
      if (currentAccount.ModeFlg == ModeFlg.NewInsert) {
        // クライアントで削除する場合(まだサーバへ保存してない勘定科目)
        setDeleteAccountNewInsertConfirmActivated(true);
      }
      else {
        // サーバで削除する場合(サーバに登録済の勘定科目)
        setDeleteAccountEditConfirmActivated(true);
      }
    }
    setDeleteAccountUnsavedConfirmActivated(false);
  };
  const handleDeleteAccountUnsavedConfirmNo = () => {
    setDeleteAccountUnsavedConfirmActivated(false);
  };

  const [deleteAccountNewInsertConfirmActivated, setDeleteAccountNewInsertConfirmActivated] = React.useState(false);
  const handleDeleteAccountNewInsertConfirmClose = () => {
    setDeleteAccountNewInsertConfirmActivated(false);
  };
  const handleDeleteAccountNewInsertConfirmYes = () => {
    // まだサーバへ保存してない勘定科目をクライアントで削除する
    if (accountRegisterViewModel.AccuntRegisterList) {
      let newList = accountRegisterViewModel.AccuntRegisterList.slice();
      newList.splice(currentIndex, 1);
      setAccountRegisterViewModel({ ...accountRegisterViewModel, AccuntRegisterList: newList });

      let newModeFlgList = modeFlgList.slice();
      newModeFlgList.splice(currentIndex, 1);
      setModeFlgList(newModeFlgList);

      setAlertMessage({
        alerted: true,
        alertKbn: AlertKbnEnum.success,
        message: messageUtils.bindValueToMessage(message.RegisterAccount_Error_DeleteComplete, [currentGCode == "" ? "選択行" : currentGCode])
      });

      setCurrentGCode('');
      setCheckResultMessage('');
    }
    setDeleteAccountNewInsertConfirmActivated(false);
  };
  const handleDeleteAccountNewInsertConfirmNo = () => {
    setDeleteAccountNewInsertConfirmActivated(false);
  };

  const [deleteAccountEditConfirmActivated, setDeleteAccountEditConfirmActivated] = React.useState(false);
  const handleDeleteAccountEditConfirmClose = () => {
    setDeleteAccountEditConfirmActivated(false);
  };
  const handleDeleteAccountEditConfirmYes = () => {
    // サーバに登録済の勘定科目をサーバで削除する
    /** 現在選択中の勘定科目 */
    let currentAccount = getCurrentAccount(31);
    if ((currentAccount) && (currentAccount.GCode)) {
      DeleteOnPost(castKmkClassEnum(kmkClass), currentAccount.GCode);
    }
    setDeleteAccountEditConfirmActivated(false);
  };
  const handleDeleteAccountEditConfirmNo = () => {
    setDeleteAccountEditConfirmActivated(false);
  };

  /** A33 Excel出力ボタン押下時の処理 */
  const handleOnExcelClick = () => {
    if (isAllSaved()) {
      // Excel出力設定画面を表示
      setExcelOutputsettingActivated(true);
    }
    else {
      setOutputExcelUnsavedConfirmActivated(true);
    }
  }

  const [outputExcelUnsavedConfirmActivated, setOutputExcelUnsavedConfirmActivated] = React.useState(false);
  const handleOutputExcelUnsavedConfirmClose = () => {
    setOutputExcelUnsavedConfirmActivated(false);
  };
  const handleOutputExcelUnsavedConfirmYes = () => {
    setOutputExcelUnsavedConfirmActivated(false);

    OutputOnGet();

    // Excel出力設定画面を表示
    setExcelOutputsettingActivated(true);
  };
  const handleOutputExcelUnsavedConfirmNo = () => {
    setOutputExcelUnsavedConfirmActivated(false);
  };

  const [excelOutputsettingActivated, setExcelOutputsettingActivated] = React.useState<boolean>(false);
  const excelOutputsettingClose = () => {
    setExcelOutputsettingActivated(false);
  }

  /** A35 閉じるボタン押下時の処理 */
  const handleOnCloseClick = () => {
    onClose();
  }

  /** A37 保存ボタン押下時の処理 */
  const handleOnExecuteClick = () => {
    if (accountRegisterViewModel.AccuntRegisterList != undefined) {
      setTaxCodeFromTaxKbnCode(accountRegisterViewModel.AccuntRegisterList);
    }
    // 入力値チェック
    if (isValidAccounts()) {
      // 入力値チェックOKなので、サーバへ送信する
      UpdateOnPost(castKmkClassEnum(kmkClass), accountRegisterViewModel);
    }
  }

  const [checkResultMessage, setCheckResultMessage] = React.useState<string>('');

  /** 入力値チェック */
  function isValidAccounts(): boolean {
    let errorMessage: string = '';
    if ((accountRegisterViewModel.AccuntRegisterList != undefined) && (taxKmkListInit != undefined)) {
      accountRegisterViewModel.AccuntRegisterList.forEach((account, index, array) => {
        let modeFlg = modeFlgList[index];
        if ((modeFlg == ModeFlg.NewInsert) || (modeFlg == ModeFlg.Edit)) { // 追加・編集した勘定科目のみチェック対象
          // コード
          let gCode = '';
          if (account.GCode != undefined) {
            gCode = account.GCode.toString();
          }
          if (gCode.length == 0) {
            if (errorMessage == '') {
              errorMessage += messageUtils.bindValueToMessage(message.Common_Error_RequiredInput_Empty, [Labels.AIKADT008000001_GRID_HEADER_Gcode]);
            }
          }
          // 正式名称
          let longName = '';
          if (account.LongName != undefined) {
            longName = account.LongName.trim();
          }
          if (longName.length == 0) {
            if (errorMessage == '') {
              errorMessage += gCode + ' ' + messageUtils.bindValueToMessage(message.Common_Error_RequiredInput_Empty, [Labels.AIKADT008000001_GRID_HEADER_LongName]);
            }
          }
          // 簡略名称
          let simpleName = '';
          if (account.SimpleName != undefined) {
            simpleName = account.SimpleName.trim();
          }
          if (simpleName.length == 0) {
            if (errorMessage == '') {
              errorMessage += gCode + ' ' + messageUtils.bindValueToMessage(message.Common_Error_RequiredInput_Empty, [Labels.AIKADT008000001_GRID_HEADER_SimpleName]);
            }
          }
          // 消費税科目チェック
          let taxKmkInit = taxKmkListInit.find(taxKmk => taxKmk.GCode == account.GCode);
          if (taxKmkInit != undefined) {
            if (taxKmkInit.TaxKbn != account.TaxKbn) {
              if (errorMessage == '') {
                errorMessage += messageUtils.bindValueToMessage(message.RegisterAccount_Error_Insert_FrozenTaxKbn, [gCode]);
              }
            }
          }
          // システム予約科目チェック
          let taxKmkSysReserve = sysReservetaxKmkListInit.find(taxKmk => taxKmk.GCode == account.GCode);
          if (account.DeleteNgFlag && taxKmkSysReserve != undefined && taxKmkSysReserve.TaxKbn != account.TaxKbn) {
            if (errorMessage == '') {
                errorMessage += messageUtils.bindValueToMessage(message.RegisterAccount_Error_Insert_FrozenTaxKbnSysReserve, [gCode]);
            }
          }
          // 仕入消費税コードチェック(輸入取引区分による設定可否チェック)
          if ((taxKubunRelationInfo.ImportKbn == 0) &&
            (account.TaxKbn == enums.TaxKbnEnum.Purchase) &&
            (account.TaxCode != undefined) &&
            (account.TaxCode >= BTaxCode.ImportJournalizeExcluded) &&
            (account.TaxCode <= BTaxCode.ExemptionSalseImportIncluded)) {
            if (errorMessage == '') {
              errorMessage += messageUtils.bindValueToMessage(message.RegisterAccount_Error_Insert_InvalidTaxCodeImportKbn0, [gCode]);
            }
          }
          // 仕入消費税コードチェック(仮払消費税科目による設定可否チェック)
          if ((account.GCode != taxKubunRelationInfo.ProvisionalPaymentTaxKmk) &&
            (account.TaxKbn == enums.TaxKbnEnum.Purchase) &&
            (account.TaxCode != undefined) &&
            (((account.TaxCode >= BTaxCode.StocktakingAdjustTaxation) && (account.TaxCode < BTaxCode.ImportJournalizeExcluded)) ||
              ((account.TaxCode >= BTaxCode.ImportJournalizeConsumptionTax) && (account.TaxCode <= BTaxCode.ExemptionJournalizeImport)))) {
            if (errorMessage == '') {
              errorMessage += messageUtils.bindValueToMessage(message.RegisterAccount_Error_Insert_InvalidTaxCodeProvisionalPayment, [gCode]);
            }
          }
          // 仕入消費税コードチェック(仕入消費率による設定可否チェック)
          if ((account.TaxKbn == enums.TaxKbnEnum.Purchase) &&
            (account.TaxCode != undefined) &&
            (account.TaxRate != undefined) &&
            (((account.TaxCode == BTaxCode.CommonJournalizeSubtractionIncluded || account.TaxCode == BTaxCode.ExemptionSalseJournalizeSubtractionIncluded ||
              account.TaxCode == BTaxCode.CommonJournalizeSubtractionExcluded || account.TaxCode == BTaxCode.ExemptionSalseJournalizeSubtractionExcluded) &&
              account.TaxRate == enums.TaxRateEnum.Three) ||
              ((account.TaxCode >= BTaxCode.TaxationSalesSpecificJournalize && account.TaxCode <= BTaxCode.ExemptionSalseImportSpecificTaxationJournalizeReturn) &&
            (account.TaxRate == enums.TaxRateEnum.Three || account.TaxRate == enums.TaxRateEnum.Five || account.TaxRate == enums.TaxRateEnum.EightK)) ||
              ((account.TaxCode >= BTaxCode.ImportJournalizeIncluded && account.TaxCode <= BTaxCode.ExemptionSalseImportIncluded) &&
                account.TaxRate == enums.TaxRateEnum.Three))) {
            if (errorMessage == '') {
              errorMessage += messageUtils.bindValueToMessage(message.RegisterAccount_Error_Insert_InvalidTaxRate, [gCode]);
            }
          }
        }
      });
    }
    if (errorMessage != '') {
      setCheckResultMessage(errorMessage);
      return false;
    }
    setCheckResultMessage('');
    return true;
  }

  /** A38 取消ボタン押下時の処理 */
  const handleOnCancelClick = () => {
    handleOnKmkClassChange2(kmkClass);
  }

  /**
   * グリッドのセル選択範囲が変更されたタイミングのselectionChangedイベント処理
   * https://demo.grapecity.com/wijmo/api/classes/wijmo_grid.flexgrid.html#selectionchanged
   * @param grid 勘定科目一覧グリッド
   * @param e イベント発生時の詳細情報
   */
  function handleGridOnSelectionChanged(grid: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs): void {
    //console.debug('handleGridOnSelectionChanged() col=' + e.col + ' / row=' + e.row + ' / selectionMode=' + grid.selectionMode + ' / currentIndex=' + currentIndex);
    setCurrentIndex(e.row);
    //console.debug('handleGridOnSelectionChanged() col=' + e.col + ' / row=' + e.row + ' / selectionMode=' + grid.selectionMode + ' / currentIndex=' + currentIndex);
  }

  /**
   * グリッドのセルが編集モードに入る前のタイミングのbeginningEditイベント処理
   * https://demo.grapecity.com/wijmo/api/classes/wijmo_grid.flexgrid.html#beginningedit
   * @param grid 勘定科目一覧グリッド
   * @param e イベント発生時の詳細情報
   */
  function handleGridOnBeginningEdit(grid: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs): void {
    if (modeFlgList[currentIndex] != ModeFlg.NewInsert) {
      // 既存の勘定科目(編集前 or 編集中)の場合
      let theBinding = grid.columns[e.col].binding;
      if ((theBinding == 'DCKbn') ||
        (theBinding == 'BPKbn') ||
        (theBinding == 'KisyuKbn')) {
        // 正残 BS/PL 期首残 は編集させない
        e.cancel = true;
      }
    }
    const item = grid.collectionView.items[e.row];
    if (grid.columns[e.col].binding === 'TaxKbnCode') {
      if (item.TaxKbn == enums.TaxKbnEnum.Nothing) {
        // 科目区分＝対象外の場合、消費税コードは編集させない
        e.cancel = true;
      }
    }
    if (grid.columns[e.col].binding === 'TaxRate') {
      if (item.TaxKbn == enums.TaxKbnEnum.Nothing) {
        // 科目区分＝対象外の場合、消費税率は編集させない
        e.cancel = true;
      }
    }
    if (grid.columns[e.col].binding === 'TypeCode') {
      if (item.TaxKbn != enums.TaxKbnEnum.Earnings) {
        // 科目区分≠売上の場合、業種コードは編集させない
        e.cancel = true;
      }
    }
  }

  /**
   * グリッドのセル編集が確定またはキャンセルされたタイミングのcellEditEndedイベント処理
   * https://demo.grapecity.com/wijmo/api/classes/wijmo_grid.flexgrid.html#celleditended
   * @param grid 勘定科目一覧グリッド
   * @param e イベント発生時の詳細情報
   */
  function handleGridOnCellEditEnded(grid: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs): void {
    //console.debug('handleGridOnCellEditEnded()'
    //  + ' / [X] e.col=' + e.col
    //  + ' / [Y] e.row=' + e.row
    //  + ' / e.cancel=' + e.cancel
    //  + ' / [Before] e.data=' + e.data
    //  + ' / [After] grid.getCellData(e.row, e.col, false)=' + grid.getCellData(e.row, e.col, false)
    //  + ' / [After] grid.getCellData(e.row, e.col, true)=' + grid.getCellData(e.row, e.col, true)
    //);
    setCurrentIndex(e.row);

    /** ユーザーが編集キャンセルしたか否か(true:ESCキー押下し明示的にキャンセル操作した true以外:明示的にキャンセル操作してない) */
    let isCancel = e.cancel;

    /** 編集前のセルの値(コンボボックス等の場合、画面には見えない内部キー値) */
    let valueBeforeEdit = e.data;

    /** 編集後のセルの値(コンボボックス等の場合、画面には見えない内部キー値) */
    let valueAfterEdit = grid.getCellData(e.row, e.col, false);

    if ((isCancel == true) || (valueBeforeEdit == valueAfterEdit)) {
      // 編集確定せずキャンセルした場合、以下の編集確定後の処理を実行しない
      //console.debug('handleGridOnCellEditEnded() NOT Changed');
      return;
    }
    //console.debug('handleGridOnCellEditEnded() Changed');

    // 連想(RenChar)を変更した場合、文字種チェックし入力不可能な文字は削除する
    if (grid.columns[e.col].binding === 'RenChar') {
      const item = grid.collectionView.items[e.row];
      let valueBefore = item.RenChar;
      let valueAfter = validateKanaRenChar(valueBefore);
      grid.beginUpdate();
      item.RenChar = valueAfter;
      grid.endUpdate();
    }

    // 科目区分(TaxKbn)を変更した場合、消費税コード(TaxKbnCode)・消費税率(TaxRate)・業種コード(TypeCode)を初期化する
    if (grid.columns[e.col].binding === 'TaxKbn') {
      const item = grid.collectionView.items[e.row];
      let newValue = item.TaxKbn;
      grid.beginUpdate();
      item.TaxKbnCode = 0; // 消費税コードを初期化
      if (newValue == enums.TaxKbnEnum.Nothing) {
        item.TaxRate = enums.TaxRateEnum.Normal; // 消費税率を初期化
      }
      item.TypeCode = enums.TypeCodeEnum.Normal; // 業種コードを初期化
      grid.endUpdate();
    }

    // 既存の勘定科目のセルを編集した場合、その勘定科目のModeFlgを更新する
    if (modeFlgList[currentIndex] != ModeFlg.NewInsert) {
      let newModeFlgList = modeFlgList.slice();
      newModeFlgList[currentIndex] = ModeFlg.Edit;
      setModeFlgList(newModeFlgList);
    }
  }

  /**
   * グリッドの任意のセルにフォーカスを移動する
   * @param rowNumber 行番号(Y:0～)
   * @param colNumber 列番号(X:0～)
   * @param timeOutMilliSec フォーカスを移動するまでの待ち時間:ミリ秒(この関数を呼ぶ前にグリッドの中身を更新した場合、グリッドの中身が再描画された後にフォーカスを移動するため)
   */
  function setFocusToGird(rowNumber: number, colNumber: number, timeOutMilliSec?: number): void {
    let flexGrid: wjGrid.FlexGrid;
    if ((ref) && (ref.current)) {
      flexGrid = ref.current.control as wjGrid.FlexGrid;
      if (timeOutMilliSec == undefined) {
        flexGrid.select(new wjGrid.CellRange(rowNumber, colNumber), true);
      }
      else {
        setTimeout(() => {
          flexGrid.select(new wjGrid.CellRange(rowNumber, colNumber), true);
        }, timeOutMilliSec);
      }
    }
  }

  const ref = React.createRef<FlexGrid>();

  /** フォーカス順序の最初の入力欄(科目分類コンボボックス) */
  const firstFocusElement = React.useRef<any>();

  /** A27 新規ボタン */
  const createButton = React.useRef<any>();

  /** A33 Excel出力ボタン */
  const excelButton = React.useRef<any>();

  /** フォーカス順序の最後の入力欄(A38 取消ボタン) */
  const lastFocusElement = React.useRef<any>();

  /** フォーカス順序の最後の入力欄(A35 閉じるボタン) */
  const lastFocusElementSub = React.useRef<any>();

  /** 本画面(会社基本情報)全体の<div> */
  const thisPage = React.useRef<any>();

  return (
    <div className='account-register' ref={thisPage}>

      <LowerActionLayout className='vw-100 h-100'>
        <LowerActionLayoutHeader>
          <PageHeaderAccountRegister
            pageTitle={Labels.AIKADT008000001_FUNCTION_NAME /* A1 メニュー名ラベル */}

            createButtonTabIndex={13 /* A27 新規ボタン */}
            onCreateClick={handleOnNewClick}
            createButtonDisabled={getHeaderButtonsDisabled(27)}
            createButtonRef={createButton}

            insertButtonTabIndex={14 /* A29 挿入ボタン */}
            onInsertClick={handleOnInsertClick}
            insertButtonDisabled={getHeaderButtonsDisabled(29)}

            deleteButtonTabIndex={15 /* A31 削除ボタン */}
            onDeleteClick={handleOnDeleteClick}
            deleteButtonDisabled={getHeaderButtonsDisabled(31)}

            excelButtonTabIndex={16 /* A33 Excel出力ボタン */}
            onExcelClick={handleOnExcelClick}
            excelButtonDisabled={false}
            excelButtonRef={excelButton}

            closeButtonTabIndex={17 /* A35 閉じるボタン */}
            onCloseClick={handleOnCloseClick}
            closeButtonDisabled={false}
            closeButtonRef={lastFocusElementSub}
          >
          </PageHeaderAccountRegister>
        </LowerActionLayoutHeader>

        <LowerActionLayoutContents className='vw-100 px-3 pt-3'>

          <div className='kmk-class'>
            <div className='float-left mr-2'>
              <Label for='kmkClass' className='label-control kmk-class-label'>{Labels.AIKADT008000001_INPUT_KmkClass /* A2 科目分類ラベル */}</Label>
            </div>
            <div className='float-left mr-2'>
              <ComboBox
                id={'kmkClass'}
                name={'KmkClass'}
                itemsSource={kmkClassItemsSource}
                displayMemberPath={'value'}
                selectedValuePath={'key'}
                selectedValue={kmkClass}
                initialized={kmkClassInitialized}
                onSelectedIndexChanged={handleOnKmkClassChange}
                isContentHtml={false}
                isDisabled={false}
                isEditable={false}
                isReadOnly={false}
                //tabIndex={1} kmkClassInitialized()で設定する
              />
            </div>
          </div>

          <SimpleGrid2
            gridClassName='account-grid'
            tabIndex={2}
            selectionMode={wjGrid.SelectionMode.Cell}
            columns={gridColumns}
            sources={(accountRegisterViewModel.AccuntRegisterList) ? accountRegisterViewModel.AccuntRegisterList : []}
            onSelectionChanged={handleGridOnSelectionChanged}
            onBeginningEdit={handleGridOnBeginningEdit}
            onCellEditEnded={handleGridOnCellEditEnded}
            gridRef={ref}
          />

        </LowerActionLayoutContents>

        <LowerActionLayoutAction className='px-3 pb-3'>
          <div className={'d-flex justify-content-between'}>
            <div className='ml-auto text-red'>
              {checkResultMessage}
            </div>
            <div className={'ml-4'}>
              <ExecutingCancel
                executeLabel={Labels.COMMON_BUTTON_FUNCTION_SAVE /* A37 保存ボタン */}
                executeTabIndex={18}
                executeDisabled={getHeaderButtonsDisabled(37)}
                onExecuteClick={handleOnExecuteClick}

                cancelLabel={Labels.COMMON_BUTTON_FUNCTION_CANCEL /* A38 取消ボタン */}
                cancelTabIndex={19}
                cancelRef={lastFocusElement}
                cancelDisabled={getHeaderButtonsDisabled(38)}
                onCancelClick={handleOnCancelClick}
              />
            </div>
          </div>
        </LowerActionLayoutAction>

        <ConfirmationPopup
          isOpen={changeKmkClassConfirmActivated}
          onClose={handleChangeKmkClassConfirmClose}
          onYesClick={handleChangeKmkClassConfirmYes}
          onNoClick={handleChangeKmkClassConfirmNo}
        >
          {message.Common_Confirmation_Edited_Import}
        </ConfirmationPopup>

        <ConfirmationPopup
          isOpen={deleteAccountUnsavedConfirmActivated}
          onClose={handleDeleteAccountUnsavedConfirmClose}
          onYesClick={handleDeleteAccountUnsavedConfirmYes}
          onNoClick={handleDeleteAccountUnsavedConfirmNo}
        >
          {message.Common_Confirmation_Edited_Import}
        </ConfirmationPopup>

        <ConfirmationPopup
          isOpen={deleteAccountNewInsertConfirmActivated}
          onClose={handleDeleteAccountNewInsertConfirmClose}
          onYesClick={handleDeleteAccountNewInsertConfirmYes}
          onNoClick={handleDeleteAccountNewInsertConfirmNo}
        >
          {messageUtils.bindValueToMessage(message.Common_Confirmation_Delete, [currentGCode == "" ? "選択行" : currentGCode])}
        </ConfirmationPopup>

        <ConfirmationPopup
          isOpen={deleteAccountEditConfirmActivated}
          onClose={handleDeleteAccountEditConfirmClose}
          onYesClick={handleDeleteAccountEditConfirmYes}
          onNoClick={handleDeleteAccountEditConfirmNo}
        >
          {messageUtils.bindValueToMessage(message.Common_Confirmation_Delete, [currentGCode == "" ? "選択行" : currentGCode])}
        </ConfirmationPopup>

        <Popup
          header={Labels.AIKADT008000001_FUNCTION_NAME}
          isOpen={deletedSavedAccountConfirmActivated}
          onOpened={handleDeletedSavedAccountConfirmOpened}
          onClosed={handleDeletedSavedAccountConfirmClose}
          onCloseClick={handleDeletedSavedAccountConfirmCloseClick}
          footer={
            <div className='w-100 text-center'>
              <Button
                color='primary'
                className='mx-auto px-5 py-1'
                onClick={handleDeletedSavedAccountConfirmCloseClick}
                innerRef={refer2DeletedSavedAccountConfirmButton}
              >
                {Labels.AIKCMN001000001_BUTTON_FUNCTION_OK}
              </Button>
            </div>
          }
        >
          {deletedSavedAccountConfirmMessage}
        </Popup>

        <ExcelOutputsetting
          activated={excelOutputsettingActivated}
          templateId={'AIKADT008000001_E01' /* TODO ../../../../constants/constant */}
          filters={''}
          serviceParams={''}
          onClose={excelOutputsettingClose}
        />

        <ConfirmationPopup
          isOpen={outputExcelUnsavedConfirmActivated}
          onClose={handleOutputExcelUnsavedConfirmClose}
          onYesClick={handleOutputExcelUnsavedConfirmYes}
          onNoClick={handleOutputExcelUnsavedConfirmNo}
        >
          {message.Common_Confirmation_Edited_Excel}
        </ConfirmationPopup>

      </LowerActionLayout>

    </div>
  );
}

export default AccountRegister;