import React from 'react';
import reactn from 'reactn';
import Axios from 'axios';

import {
  DefaultLayout,
  DefaultLayoutHeader,
  DefaultLayoutContents
} from '../../../../components/templates/DefaultLayout';

import PageHeader from '../../../../components/molecules/CMN/PageHeader/PageHeader';
import CommonGrid from '../../../../components/molecules/CMN/Grid/CommonGrid';
import ChecklistSetting from '../AIKADT001000005/ChecklistSetting';

import { createWjDataMap, getValueFromDataMap } from '../../../../utils/dataMapsUtils';
import { bindValueToMessage } from '../../../../utils/messageUtils';
import * as labels from '../../../../constants/labels';
import { AIKADT001000004E01 } from '../../../../constants/constant';

import * as message from '../../../../constants/message';
import ExcelOutputsetting from '../../../organisms/A000/ExcelOutputsetting';
import ConfirmationPopup from '../../../../components/molecules/CMN/ConfirmationPopup/ConfirmationPopup';
import { DataTypeEnum, TargetTMonthEnum } from '../../../../constants/dataMaps';
import { DataTypeEnum as datatypeEnum, DisplayModeEnum as DispMode } from '../../../../constants/enums';
// 初期表示データ作成用ViewModel
import { ChecklistSettingListViewModel } from '../../../../models/checklistSettingListViewModel';
import { ChecklistItemVO } from '../../../../models/checklistItemVO';

// 基本URL
const baseGetUrl = '/api/v1/ChecklistSettingList';
const basePostUrl = '/api/v1/ChecklistSettingList/Checklist/';
// 加工後URL
// export var initialUrl: string; // 初期表示データ作成用
export const initialUrl = [baseGetUrl, 'Initial'].join('/'); // TODO 仮修正
export var existURL: string; // チェックリスト存在確認
export var enableURL: string; // チェックリスト有効化
export var deleteURL: string; // チェックリスト論理削除

// 削除確認後に使用する条件
var deleteNcode: number;
var deleteDatatype: number;

type ChecklistSettingListProps = {
  onClose: () => void;
};
const ChecklistSettingList: React.FC<ChecklistSettingListProps> = props => {
  // TODO:チェックリスト設定が出来上がったら、コメント解除
  const [ChecklistSettingActivated, setChecklistSettingActivated] = React.useState(false);
  const [ChecklistSettingDispMode, setChecklistSettingDispMode] = React.useState(DispMode.New);

  // チェックリスト設定（新規モード）
  const handleOnCreateClick = () => {
    setChecklistSettingActivated(true);
    setChecklistSettingDispMode(DispMode.New);
  };
  const handleChecklistSettingOnClose = () => {
    setChecklistSettingActivated(false);
  };

  // 閉じる
  const handleOnCloseClick = () => {
    props.onClose();
  };

  // メッセージ文言の埋め込み文字
  const [msgarray, setMsgarray] = React.useState(['']);

  // Excel出力
  const [excelPopupActivated, setExcelPopupActivated] = React.useState(false);
  const handleOnExcelClick = () => {
    setExcelPopupActivated(true);
  };
  const handleExcelOnClose = () => {
    setExcelPopupActivated(false);
  };

  // グリッドフィルター情報設定
  const [filterInfo, setfilterInfo] = React.useState('');

  // 削除確認メッセージ設定
  const [confirmationActivated, setconfirmationActivated] = React.useState(false);

  // 初期表示データ作成用ViewModel
  const [viewModel, updateViewModel] = React.useState<ChecklistSettingListViewModel>({ ChecklistItems: [] });

  // 異常処理(アクション以外のエラー)
  const [, setErrorMessage] = reactn.useGlobal('errorMessage');
  // 異常処理(アクション時のエラー)
  // const [errorMessage, setErrorMessage] = React.useState('');

  // 一覧Grid
  const gridColumns = [
    // No.
    { header: labels.AIKADT001000004_GRID_HEADER_NO, binding: 'No', isReadOnly: true, width: 60 },
    // 種別
    {
      header: labels.AIKADT001000004_GRID_HEADER_TYPE,
      binding: 'DataTypeKbn',
      isReadOnly: true,
      width: 80,
      dataMap: createWjDataMap(DataTypeEnum)
    },
    // 名称
    { header: labels.AIKADT001000004_GRID_HEADER_NAME, binding: 'CklName', isReadOnly: true, width: 200 },
    // 実行対象月
    {
      header: labels.AIKADT001000004_GRID_HEADER_TARGETMONTH,
      binding: 'TMonth',
      isReadOnly: true,
      dataMap: createWjDataMap(TargetTMonthEnum),
      width: 150
    },
    // 内容
    {
      header: labels.AIKADT001000004_GRID_HEADER_CONTENT,
      binding: 'CklContent',
      isReadOnly: true,
      minWidth: 250,
      width: '*'
    },
    // 有効
    {
      header: labels.AIKADT001000004_GRID_HEADER_VALID,
      binding: 'YukoFlg',
      isReadOnly: false,
      width: 60,
      inputType: 'validFlag'
    },
    // コマンド
    {
      header: labels.AIKADT001000004_GRID_HEADER_COMMAND,
      name: 'CopyIcon',
      isReadOnly: true,
      align: 'center',
      cssClassAll: 'border-right-0',
      inputType: 'button',
      width: 25
    },
    {
      header: labels.AIKADT001000004_GRID_HEADER_COMMAND,
      name: 'DeleteIcon',
      isReadOnly: true,
      align: 'center',
      inputType: 'button',
      width: 25
    }
  ];

  // 通信処理

  // 初期表示データ作成
  const initialOnGet = (url: string) => {
    // URL作成
    // initialUrl = [url, 'Initial'].join('/'); // TODO 仮修正

    Axios.get<ChecklistSettingListViewModel>(initialUrl)
      .then(response => {
        updateViewModel(response.data);
      })
      .catch(error => {
        const { message } = error.response.data;
        setErrorMessage(message);
      });
  };
  // 通信処理実行(初期表示データ作成)
  React.useEffect(() => {
    initialOnGet(baseGetUrl);
  }, [baseGetUrl]);

  // チェックリスト存在確認
  const existsOnPost = (url: string, rowData: ChecklistItemVO) => {
    var code: number = 0;
    var dataType: datatypeEnum = datatypeEnum.User;

    if (
      typeof rowData !== 'undefined' &&
      typeof rowData.NCode !== 'undefined' &&
      typeof rowData.DataTypeKbn !== 'undefined'
    ) {
      code = rowData.NCode;
      dataType = rowData.DataTypeKbn;
    }

    // URL作成
    //'/api/v1/ChecklistSettingList/Checklist/{コード}/Exists
    existURL = [url, encodeURI(code.toString()), 'Exists'].join('/');

    // 異常処理
    //const [, setErrorMessage] = reactn.useGlobal('errorMessage');
    Axios.post(existURL, { DataType: dataType }).catch(error => {
      const { message } = error.response.data;
      setErrorMessage(message);
    });
  };

  // チェックリスト有効化
  const enableOnPost = (url: string, rowData: ChecklistItemVO) => {
    var code: number = 0;
    var dataType: datatypeEnum = datatypeEnum.User;
    var yukoflag: Boolean = false;

    if (
      typeof rowData !== 'undefined' &&
      typeof rowData.NCode !== 'undefined' &&
      typeof rowData.DataTypeKbn !== 'undefined' &&
      typeof rowData.YukoFlg !== 'undefined'
    ) {
      code = rowData.NCode;
      dataType = rowData.DataTypeKbn;
      yukoflag = rowData.YukoFlg;
    }

    // 異常処理
    //const [, setErrorMessage] = reactn.useGlobal('errorMessage');
    ///api/v1 / ChecklistSettingList / Checklist / { コード } / Enable / { フラグ }
    // URL作成
    enableURL = [url, encodeURI(code.toString()), 'Enable', encodeURI(String(yukoflag))].join('/');
    Axios.post(enableURL, { DataType: dataType }).catch(error => {
      const { message } = error.response.data;
      setErrorMessage(message);
    });
  };

  // チェックリスト論理削除
  const checklistOnDelete = (url: string, code: number, dataType: datatypeEnum) => {
    // 異常処理
    //const [, setErrorMessage] = reactn.useGlobal('errorMessage');

    ////api/v1/ChecklistSettingList/Checklist/{コード}
    // URL作成
    deleteURL = [url, encodeURI(code.toString())].join('/');

    Axios.delete(deleteURL, { data: { DataType: dataType } }).catch(error => {
      const { message } = error.response.data;
      setErrorMessage(message);
    });
  };

  // 初期表示データ作成
  const dataSources = viewModel.ChecklistItems;

  const handleOnGridDoubleClick = (rowData: ChecklistItemVO) => {
    //業務チェック
    existsOnPost(baseGetUrl, rowData);

    setChecklistSettingActivated(true);
    setChecklistSettingDispMode(DispMode.Update);
  };
  // コピー処理
  const handleOnCopyClick = (rowData: ChecklistItemVO) => {
    //業務チェック
    existsOnPost(baseGetUrl, rowData);
    setChecklistSettingActivated(true);
    setChecklistSettingDispMode(DispMode.Copy);
  };

  // 削除処理
  const handleOnDeleteClick = (rowData: ChecklistItemVO) => {
    var deleteDataTypeNm: string = '';
    var deleteDataItemNm: string = '';

    if (
      typeof rowData !== 'undefined' &&
      typeof rowData.NCode !== 'undefined' &&
      typeof rowData.DataTypeKbn !== 'undefined' &&
      typeof rowData.CklName !== 'undefined'
    ) {
      deleteNcode = rowData.NCode;
      deleteDatatype = rowData.DataTypeKbn;
      deleteDataTypeNm = getValueFromDataMap(DataTypeEnum, deleteDatatype);
      deleteDataItemNm = rowData.CklName;
    }
    var strmessage: string = ['『' + deleteDataTypeNm, deleteDataItemNm + '』'].join('：');
    setMsgarray([strmessage]);

    // 確認メッセージ表示設定
    setconfirmationActivated(true);
  };
  // 確認メッセージ：終了時
  const confirmationOnclose = () => {
    setconfirmationActivated(false);
  };
  // 確認メッセージ：Yesボタン押下時
  const confirmationOnYes = () => {
    //チェックリスト論理削除
    checklistOnDelete(basePostUrl, deleteNcode, deleteDatatype);
    //確認メッセージを終了
    setconfirmationActivated(false);
  };
  // 確認メッセージ：Noボタン押下時
  const confirmationOnNo = () => {
    //確認メッセージを終了
    setconfirmationActivated(false);
  };

  // 有効フラグ選択処理
  const handleValidFlagClick = (rowData: ChecklistItemVO) => {
    enableOnPost(basePostUrl, rowData);
  };

  // グリッドフィルタ情報取得処理
  const handleonFilterChanged = (filterInfo: string) => {
    setfilterInfo(filterInfo);
  };

  return typeof dataSources === 'undefined' ? (
    <div />
  ) : (
    <div>
      <DefaultLayout className='vw-100 h-100'>
        <DefaultLayoutHeader>
          <PageHeader
            pageTitle={labels.AIKADT001000004_FUNCTION_NAME}
            onCreateClick={handleOnCreateClick}
            onExcelClick={handleOnExcelClick}
            onCloseClick={handleOnCloseClick}
          />
        </DefaultLayoutHeader>
        <DefaultLayoutContents className='vw-100 p-3'>
          <CommonGrid
            gridClassName='ChecklistSettingList-grid'
            columns={gridColumns}
            sources={dataSources}
            onGridDoubleClick={handleOnGridDoubleClick}
            onCopyClick={handleOnCopyClick}
            onDeleteClick={handleOnDeleteClick}
            onValidFlagClick={handleValidFlagClick}
            onFilterChanged={handleonFilterChanged}
          />
        </DefaultLayoutContents>
      </DefaultLayout>
      <ChecklistSetting
        activated={ChecklistSettingActivated}
        onClose={handleChecklistSettingOnClose}
        displayMode={ChecklistSettingDispMode}
        code={100} // TODO 正しいコード値を設定すること
        dataType={datatypeEnum.User} // TODO 正しいデータタイプを設定すること
      />
      <ExcelOutputsetting
        activated={excelPopupActivated}
        templateId={AIKADT001000004E01}
        filters={filterInfo}
        onClose={handleExcelOnClose}
      />
      <ConfirmationPopup
        isOpen={confirmationActivated}
        onClose={confirmationOnclose}
        onYesClick={confirmationOnYes}
        onNoClick={confirmationOnNo}
      >
        {bindValueToMessage(message.Common_Confirmation_Delete, msgarray)}
      </ConfirmationPopup>
    </div>
  );
};
export default ChecklistSettingList;
