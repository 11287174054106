import React from 'react';
import classnames from 'classnames';

import Popup, { popupProps } from '../CMN/Popup/Popup';
import ExecutingCancel from '../CMN/ExecutingCancel/ExecutingCancel';
import { Progress } from 'reactstrap';
import * as Labels from '../../../constants/labels';

export type AEPopupProps = popupProps & {
  description?: string;
  disabled?: boolean;
  errorMessage?: string;
  isExecute?: boolean;
  isBatchExec?: boolean;
  prgMessage?: string;
  prgRate?: number;
  execButtonTabIndex?: number;
  cancelButtonTabIndex?: number;
  firstFocusElement?: React.RefObject<any>;
  lastFocusElement?: React.RefObject<any>;
  onClick?: () => void;
};

// tabIndexの初期値
const baseTabIndex = 200;

const AuditExecPopup: React.FC<AEPopupProps> = props => {
  // イベントのコールバック関数にて使用する状態を保持
  const [eventState] = React.useState({
    isExecute: props.isExecute
  });
  React.useEffect(() => {
    eventState.isExecute = props.isExecute;
  }, [props.isExecute]);

  const firstFocusElement = React.useRef<any>();
  const lastFocusTemp = React.useRef<any>();
  const lastFocusElement = typeof props.closeIconRef === 'undefined' ? lastFocusTemp : props.closeIconRef;
  const popupRef = React.useRef<any>();

  /** キーボード操作（キーダウン） */
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 9) {
      if (eventState.isExecute) {
        // イベントをキャンセル
        event.preventDefault();
        event.stopPropagation();
      } else {
        const firstFocusEle =
          typeof props.firstFocusElement === 'undefined' ? firstFocusElement : props.firstFocusElement;
        const lastFocusEle = typeof props.lastFocusElement === 'undefined' ? lastFocusElement : props.lastFocusElement;

        if (event.shiftKey === false) {
          // タブキー押下時

          const target: HTMLElement = event.target as HTMLElement;
          if (target.tabIndex === lastFocusEle.current.tabIndex) {
            // 最後のフォーカスエレメントの場合

            // 最初のエレメントにフォーカス
            firstFocusEle.current.focus();

            // 後のイベントをキャンセル
            event.preventDefault();
            event.stopPropagation();

            // フォーカスが当たっていない場合
          } else if (
            target.tabIndex < firstFocusEle.current.tabIndex ||
            target.tabIndex > lastFocusEle.current.tabIndex
          ) {
            // 最初のエレメントにフォーカス
            firstFocusEle.current.focus();

            // 後のイベントをキャンセル
            event.preventDefault();
            event.stopPropagation();
          }
        } else {
          // シフト＋タブキー押下時

          const target: HTMLElement = event.target as HTMLElement;
          if (target.tabIndex === firstFocusEle.current.tabIndex) {
            // 最後のフォーカスエレメントの場合

            // 最後のエレメントにフォーカス
            lastFocusEle.current.focus();

            // 後のイベントをキャンセル
            event.preventDefault();
            event.stopPropagation();

            // フォーカスが当たっていない場合
          } else if (
            target.tabIndex < firstFocusEle.current.tabIndex ||
            target.tabIndex > lastFocusEle.current.tabIndex
          ) {
            // 最後のエレメントにフォーカス
            lastFocusEle.current.focus();

            // 後のイベントをキャンセル
            event.preventDefault();
            event.stopPropagation();
          }
        }
      }
    }
  };

  React.useEffect(() => {
    if (props.isOpen) {
      // 画面表示完了後に実行
      setTimeout(() => {
        if (popupRef.current) {
          // ポップアップにキーダウンイベントのフック
          popupRef.current.addEventListener('keydown', handleKeyDown);
        }
      });
    }
  }, [props.isOpen]);

  const execButtonTabIndex =
    typeof props.execButtonTabIndex === 'undefined' ? baseTabIndex + 1 : props.execButtonTabIndex;
  const cancelButtonTabIndex =
    typeof props.cancelButtonTabIndex === 'undefined' ? baseTabIndex + 2 : props.cancelButtonTabIndex;
  const closeIconTabIndex = typeof props.closeIconTabIndex === 'undefined' ? baseTabIndex + 3 : props.closeIconTabIndex;
  const isDisabled = props.isExecute || props.disabled;
  const myHeaderIcon = props.isExecute ? <></> : undefined;

  const getEscClose = () => {
    return !props.isExecute;
  };

  const execButton = props.isBatchExec ? (
    <ExecutingCancel
      className='text-nowrap'
      isBatchExec={props.isBatchExec}
      cancelLabel={Labels.COMMON_BUTTON_FUNCTION_CLOSE}
      onCancelClick={props.onClick!}
    />
  ) : (
    <ExecutingCancel
      className='text-nowrap'
      executeLabel={Labels.AIKADT002000001_BUTTON_FUNCTION_AUDITEXECUTION}
      executeDisabled={isDisabled}
      executeTabIndex={execButtonTabIndex}
      executeRef={firstFocusElement}
      onExecuteClick={props.onClick!}
      cancelLabel={Labels.COMMON_BUTTON_FUNCTION_CLOSE}
      cancelDisabled={isDisabled}
      cancelTabIndex={cancelButtonTabIndex}
      onCancelClick={props.onCloseClick!}
    />
  );
  const myFooter = props.isExecute ? (
    <>
      <div className='flex-grow-1'>
        <label className='small'>{props.prgMessage ? props.prgMessage : ''}</label>
        <div className='d-flex'>
          <Progress
            className='w-75'
            value={props.prgRate ? props.prgRate : 0}
            color='success'
            striped={true}
            animated={true}
          />
          <label className='w-25'>{props.prgRate ? props.prgRate + '%' : ''}</label>
        </div>
      </div>
      {execButton}
    </>
  ) : (
    <>
      <div className='flex-grow-1'>
        {props.errorMessage ? <label className='text-danger white-space-pre-wrap'>{props.errorMessage}</label> : ''}
      </div>
      {execButton}
    </>
  );

  const popupProps = { ...props };
  delete popupProps['description'];
  delete popupProps['disabled'];
  delete popupProps['errorMessage'];
  delete popupProps['isExecute'];
  delete popupProps['isBatchExec'];
  delete popupProps['prgMessage'];
  delete popupProps['prgRate'];
  delete popupProps['onClick'];
  delete popupProps['execButtonTabIndex'];
  delete popupProps['firstFocusElement'];
  delete popupProps['lastFocusElement'];
  delete popupProps['cancelButtonTabIndex'];
    

  return (
    <Popup
      {...popupProps}
      escClose={getEscClose()}
      headerIcon={myHeaderIcon}
      footer={myFooter}
      innerRef={popupRef}
      closeIconTabIndex={closeIconTabIndex}
      closeIconRef={lastFocusElement}
      className={classnames(popupProps.className, 'AuditExecPopup')}
      size='lg'
    >
      <div>{props.description!.split('\n').map((str, index) => (<React.Fragment key={index}>{str}<br /></React.Fragment>))}</div>
      <div>{props.children}</div>
    </Popup>
  );
};
export default AuditExecPopup;
