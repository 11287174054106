
import React from 'react';
const _AlertPartial: React.FC<{}> = props => {
    return (
      <>

<div className="modal fade hogemodal" id="mjsCommonAlert" tabIndex={-1}>
    <div className="modal-dialog" role="dialog">
        <div className="modal-content">
            <div className="modal-header">
                <div className="dp_flex wp100">
                    <div className="wp50">
                        <h4 className="modal-title">環境設定</h4>
                    </div>
                    <div className="wp50">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{float: "right"}}><span className="custom-icon custom-icon-20 custom-icon-close white"></span></button>
                    </div>
                </div>
            </div>
            <div className="modal-body">
                <p className="modal-alert-message"></p>
            </div>
            <div className="modal-footer">
                <button type="button" id="" className="on-ok btn btn-primary">はい</button>
            </div>
        </div>{/* /.modal-content */}
    </div>{/*  /.modal-dialog */}
</div>{/* /.modal */}
    </>
    )
};
export default _AlertPartial;