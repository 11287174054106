import React from 'react';

import * as wjGrid from 'wijmo/wijmo.grid';
import { FlexGrid } from 'wijmo/wijmo.react.grid';
import * as wjFilter from 'wijmo/wijmo.grid.filter';
import { convertItemsSourceToDateType } from '../../../../utils/wijmoGridUtils';
import { FlexGridFilter } from 'wijmo/wijmo.react.grid.filter';

// ソート条件のテンポラリー
export type sortCriteriaStore = {
  property?: string;
  ascending?: boolean;
};

export type sortLabelMap = {
  key: any;
  value: string;
};
export type sortLabel = {
  binding: string;
  map: sortLabelMap[];
};

type DisplayOnlyGridProp = {
  columns: any[];
  sources: any[];
  sortLabelMapList?: sortLabel[];
  gridClassName?: string;
  gridRef?: React.RefObject<any>;
  onFilterChanged?: (filterJson: string) => void;
};

type DisplayOnlyGridState = {};

class DisplayOnlyGrid extends React.Component<DisplayOnlyGridProp, DisplayOnlyGridState> {
  grid?: wjGrid.FlexGrid;
  filter?: wjFilter.FlexGridFilter;
  isChangeFilterAndSort = false;

  constructor(props: DisplayOnlyGridProp) {
    super(props);

    this.initializedFilter = this.initializedFilter.bind(this);
    this.handleFilterApplied = this.handleFilterApplied.bind(this);
    this.initializedGrid = this.initializedGrid.bind(this);
    this.handleItemFormatter = this.handleItemFormatter.bind(this);
  }
  // フィルターの初期化
  public initializedFilter(flexFilter: wjFilter.FlexGridFilter) {
    this.filter = flexFilter;
  }

  // フィルターが変更された時の処理
  public handleFilterApplied(f: wjFilter.FlexGridFilter) {
    if (typeof this.props.onFilterChanged === 'function') {
      this.props.onFilterChanged(f.filterDefinition);
    }
  }

  // Gridの初期化
  public initializedGrid(flexGrid: wjGrid.FlexGrid) {
    this.grid = flexGrid;
  }

  // ItemFormatter
  public handleItemFormatter = (panel: wjGrid.GridPanel, row: number, col: number, cell: HTMLElement) => {
    if (panel.cellType === wjGrid.CellType.ColumnHeader) {
      // ヘッダ
      cell.classList.add('d-flex');
      cell.classList.add('justify-content-center');
      cell.classList.add('align-items-center');
      cell.classList.add('font-weight-normal');
    }
  };

  public render() {
    const { columns, sources, gridClassName, gridRef } = this.props;
    convertItemsSourceToDateType(columns, sources);
    return (
      <FlexGrid
        className={gridClassName}
        autoGenerateColumns={false}
        columns={columns}
        headersVisibility={wjGrid.HeadersVisibility.Column}
        isReadOnly={true}
        selectionMode={wjGrid.SelectionMode.Row}
        allowResizing={wjGrid.AllowResizing.None}
        allowDragging={wjGrid.AllowDragging.None}
        itemsSource={sources}
        alternatingRowStep={0}
        initialized={this.initializedGrid}
        itemFormatter={this.handleItemFormatter}
        ref={gridRef}
      >
        <FlexGridFilter initialized={this.initializedFilter} filterApplied={this.handleFilterApplied} />
      </FlexGrid>
    )
  }
}

export default DisplayOnlyGrid;