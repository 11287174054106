import React from 'react';
import classnames from 'classnames';

const LowerActionDoubleContentsLayout: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
  <div {...props} className={classnames(props.className, 'lower-action-double-content-layout')}>
    {props.children}
  </div>
);

const LowerActionDoubleContentsLayoutHeader: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
  <div {...props} className={classnames(props.className, '-header')}>
    {props.children}
  </div>
);

const LowerActionDoubleContentsLayoutContentsTop: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
  <div {...props} className={classnames(props.className, '-main')}>
    {props.children}
  </div>
);

const LowerActionDoubleContentsLayoutContentsBottom: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
  <div {...props} className={classnames(props.className, '-sub')}>
    {props.children}
  </div>
);

const LowerActionDoubleContentsLayoutAction: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
  <div {...props} className={classnames(props.className, '-action')}>
    {props.children}
  </div>
);

export {
  LowerActionDoubleContentsLayout,
  LowerActionDoubleContentsLayoutHeader,
  LowerActionDoubleContentsLayoutContentsTop,
  LowerActionDoubleContentsLayoutContentsBottom,
  LowerActionDoubleContentsLayoutAction
};
