import React from 'react';
import classnames from 'classnames';
import { default as Icon } from '../../../images/icon/28-1_insert.svg';
import SVG from 'react-inlinesvg';

type IconProps = {
  className?: string;
};
const Insert: React.FC<IconProps> = props => <SVG src={Icon} className={classnames(props.className, 'icon')} />;
export default Insert;
