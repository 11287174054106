import { DataModelBase } from "../../core/models/dataModelBase";

/**
 * マスターデータ ViewModel クラス
 */
    export class KbnInfoCacheViewModel extends DataModelBase {
    /** レコード区分 */
    public RecKbn!: number;

    /** 画面パターンＮＯ（１画面目） */
    public GnPuKbn1!: number;

    /** 画面パターンＮＯ（２画面目） */
    public GnPuKbn2!: number;

    /** 画面パターンＮＯ（３画面目） */
    public GnPuKbn3!: number;

    /** 画面パターンＮＯ（４画面目） */
    public GnPuKbn4!: number;

    /** 画面パターンＮＯ（５画面目） */
    public GnPuKbn5!: number;

    /** 単一仕訳入力摘要先行型 */
    public GnPuKbn6!: number;

    /** 出納帳入力摘要先行型 */
    public GnPuKbn7!: number;

    /** 外税同時入力区分 */
    public GnPuKbn8!: number;

    /** 入力時のバランスチェック */
    public GnPuKbn9!: number;

    /** 受取手形自動消込み */
    public GnPuKbn10!: number;

    /** 伝票ＮＯ入力区分/（単一仕訳入力、出納帳入力） */
    public GnPuKbn11!: number;

    /** 伝票ＮＯ入力区分/（伝票型入力） */
    public GnPuKbn12!: number;

    /** 証憑ＮＯ入力区分 */
    public GnPuKbn13!: number;

    /** 証憑ＮＯ属性 */
    public GnPuKbn14!: number;

    /** 消費税ｺｰﾄﾞ  ｳｨﾝﾄﾞｳ表示区分 */
    public GnPuKbn15!: number;

    /** 仕訳入力不可区分 */
    public GnPuKbn16!: number;

    /** 資金繰コード入力区分 */
    public GnPuKbn17!: number;

    /** 伝票ＮＯシステム自動付番方法 */
    public GnPuKbn18!: number;

    /** 仕訳修正／削除不可区分 */
    public GnPuKbn19!: number;

    /** 定型仕訳採用区分（仕訳入力） */
    public GnPuKbn20!: number;

    /** 定型仕訳採用区分（出納帳） */
    public GnPuKbn21!: number;

    /** 定型仕訳ｳｨﾝﾄﾞｳ表示区分 */
    public GnPuKbn22!: number;

    /** 固定摘要ｳｨﾝﾄﾞｳ選択方法 */
    public GnPuKbn23!: number;

    /** 固定摘要ｳｨﾝﾄﾞｳ表示ﾊﾟﾀｰﾝ */
    public GnPuKbn24!: number;

    /** 摘要欄カナ漢字優先区分 */
    public GnPuKbn25!: number;

    /** 補助名摘要自動表示 */
    public GnPuKbn26!: number;

    /** 工事名摘要自動表示 */
    public GnPuKbn27!: number;

    /** 部門名摘要自動表示 */
    public GnPuKbn28!: number;

    /** 定型仕訳採用区分（伝票型入力） */
    public GnPuKbn29!: number;

    /** 伝票NO管理区分 */
    public GnPuKbn30!: number;

    /** 期日自動表示 休日カレンダー */
    public GnPuKbn31!: number;

    /** 仕訳帳印刷帳票ＮＯ */
    public GnPuKbn32!: number;

    /** 元帳印刷帳票ＮＯ */
    public GnPuKbn33!: number;

    /** 仕訳入力初期画面表示 */
    public GnPuKbn34!: number;

    /** 元帳前残出力区分 */
    public GnPuKbn35!: number;

    /** コクヨ振替伝票の複合区分 */
    public GnPuKbn36!: number;

    /** 仮払精算伝票の複合区分 */
    public GnPuKbn37!: number;

    /** 伝票更新時バランスチェック区分 */
    public GnPuKbn38!: number;

    /** 業種入力区分 */
    public GnPuKbn39!: number;

    /** 発生区分別伝票No付番（仕訳入力） */
    public GnPuKbn40!: number;

}

