import React from 'react';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import * as wjInput from 'wijmo/wijmo.input';
import { ComboBox } from 'wijmo/wijmo.react.input';
import { getAxiosInstance } from '../../../../utils/axiosUtils';
import * as Labels from '../../../../constants/labels';
import * as enums from '../../../../constants/enums';
import * as dataMaps from '../../../../constants/dataMaps';
import * as message from '../../../../constants/message';
import { setAlertMessage, AlertKbnEnum } from '../../../../stores/AlertMessage';
import { bindValueToMessage } from '../../../../utils/messageUtils';

import ConfirmationPopup from '../../../../components/molecules/CMN/ConfirmationPopup/ConfirmationPopup';

import {
  LowerActionLayout,
  LowerActionLayoutHeader,
  LowerActionLayoutContents,
  LowerActionLayoutAction
} from '../../../../components/templates/LowerActionLayout';

import PageHeader from '../../../../components/molecules/CMN/PageHeader/PageHeader';

import ExecutingCancel from '../../../../components/molecules/CMN/ExecutingCancel/ExecutingCancel';

import { FirmInfoViewModel } from '../../../../models/firmInfoViewModel';

import { format } from '../../../../utils/dateUtils';
import { validateKanaRenChar } from '../../../../utils/stringUtils';
import { setIsLoading } from '../../../../stores/NowLoading';

/** サーバへアクセスする際のURL(ベース部分) */
const BaseURL = 'api/v1/FirmInfo';

/** 初期表示時にサーバへアクセスする際のURL */
const InitialURL = [BaseURL, 'Initial'].join('/');

/** 保存ボタン押下時にサーバへアクセスする際のURL */
const UpdateURL = [BaseURL, 'Update'].join('/');

/** A23 科目体系 コンボボックスの選択肢 */
const corpKbnItemSource = dataMaps.CorpKbnEnum.map(item => ({ ...item }));

/** 共通処理 */
// 処理起動判定
const JudgeURL = 'api/v1/CommonStartJudgement/Judge';
// システム履歴記入
const WriteSysHistoryURL = 'api/v1/CommonSystemHistory/Write';

type FirmInfoProps = {
  /** 自画面を閉じる処理 */
  onClose: () => void;

  /** 表示年度 */
  fiscalYear: number;
};

/**
 * 会社基本情報画面 機能ID AIKADT007000001
 * @param props FirmInfoProps {自画面を閉じる処理, 表示年度}
 */
const FirmInfo: React.FC<FirmInfoProps> = props => {
  const { onClose } = props;

  // State 1 画面初期表示時の値
  const [initialViewModel, updateInitialViewModel] = React.useState<FirmInfoViewModel>(
    new FirmInfoViewModel()
  );

  // State 2 (初期処理時にサーバからの取得&現在編集中の)会社データ一式
  const [viewModel, updateViewModel] = React.useState<FirmInfoViewModel>(
    getInitialValue()
  );

  // State 3 A23 科目体系ComboBox
  const [corpKbnCmb, setCorpKbnCmb] = React.useState<wjInput.ComboBox>();

  // State 4 取消確認メッセージを表示するフラグ
  const [cancelActivated, setCancelActivated] = React.useState(false);

  // State 5 フォーカス順序の最初の入力欄
  const firstFocusElement = React.useRef<any>();

  // State 6 フォーカス順序の最後の入力欄
  const lastFocusElement = React.useRef<any>();

  // State 7 本画面(会社基本情報)全体の<div>
  const thisPage = React.useRef<any>();

  /** 会計期間(年月日～年月日)を画面に表示する際のフォーマット */
  const kaikeiKikanDispFormat = 'yyyy/MM/dd';

  /** 閉じるボタン押下時の処理 */
  const handleOnCloseClick = () => {
    onClose();
  };

  /** キーボード操作（キーダウン） */
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 9) {
      const target: HTMLElement = event.target as HTMLElement;
      if (event.shiftKey === false) {
        // タブキー押下時
        if ((target.tabIndex === lastFocusElement.current.tabIndex) ||
          (target.tabIndex < firstFocusElement.current.tabIndex) ||
          (target.tabIndex > lastFocusElement.current.tabIndex)) {
          // 最後のフォーカスエレメントの場合

          // 最初のエレメントにフォーカス
          firstFocusElement.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
      }
      else {
        // シフト＋タブキー押下時
        if ((target.tabIndex === firstFocusElement.current.tabIndex) ||
          (target.tabIndex < firstFocusElement.current.tabIndex) ||
          (target.tabIndex > lastFocusElement.current.tabIndex)) {
          // 最後のフォーカスエレメントの場合

          // 最後のエレメントにフォーカス
          lastFocusElement.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }
  };

  /** 保存ボタン押下時の処理 */
  const handleOnExecuteClick = () => {
    // 入力値チェック
    let requiredButEmpty = false;
    let validLComName = true;
    if ((viewModel.LComName != undefined) && ((viewModel.LComName.length == 0) || (60 < viewModel.LComName.length))) {
      validLComName = false;
      if (viewModel.LComName.length == 0) {
        requiredButEmpty = true;
      }
    }
    let validKanaComName = true;
    if ((viewModel.KanaComName != undefined) && (60 < viewModel.KanaComName.length)) {
      validKanaComName = false;
    }
    let validSComName = true;
    if ((viewModel.SComName != undefined) && ((viewModel.SComName.length == 0) || (30 < viewModel.SComName.length))) {
      validSComName = false;
      if (viewModel.SComName.length == 0) {
        requiredButEmpty = true;
      }
    }
    if ((validLComName) && (validKanaComName) && (validSComName)) {
      // 入力値チェックOK
      setIsLoading(true);
      getAxiosInstance().post(UpdateURL, viewModel)
        .then(response => {
          //console.debug('FirmInfo.handleOnExecuteClick() then URL=' + UpdateURL);
          setAlertMessage({
            alerted: true,
            alertKbn: AlertKbnEnum.success,
            message: bindValueToMessage(message.Common_Success_Registration, [Labels.AIKADT007000001_FUNCTION_NAME])
          });
        })
        .catch(error => {
          //console.debug('FirmInfo.handleOnExecuteClick() catch URL=' + UpdateURL + ' error=' + error);
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_InternalServerError });
        })
        .finally(() => {
          //console.debug('FirmInfo.handleOnExecuteClick() finally URL=' + UpdateURL);
          setIsLoading(false);
        });
    }
    else {
      // 入力値チェックNG
      let where: string = '';
      if (validLComName == false) {
        where = Labels.AIKADT007000001_INPUT_LComName;
      }
      else if (validKanaComName == false) {
        where = Labels.AIKADT007000001_INPUT_KanaComName;
      }
      else if (validSComName == false) {
        where = Labels.AIKADT007000001_INPUT_SComName;
      }
      setAlertMessage({
        alerted: true,
        alertKbn: AlertKbnEnum.warning,
        message: bindValueToMessage((requiredButEmpty ? message.Common_Error_RequiredInput_Empty : message.Common_Error_InvalidData), [where])
      });
    }
  }

  /** 取消ボタン押下時の処理 */
  const handleOnCancelClick = () => {
    if (isSameViewModel(initialViewModel, viewModel)) {
      // 未保存の項目が存在しない場合、処理なし
      return;
    }

    //未保存の項目が存在する場合、取消確認メッセージを表示する
    setCancelActivated(true);
  }

  /**
   * 会社データを比較して一致している場合trueを返す
   * @param initial 初期処理時の会社データ
   * @param current 現時点の会社データ
   */
  function isSameViewModel(initial: FirmInfoViewModel, current: FirmInfoViewModel): boolean {
    if (initial.LComName != current.LComName) {
      return false;
    }
    if (initial.KanaComName != current.KanaComName) {
      return false;
    }
    if (initial.SComName != current.SComName) {
      return false;
    }
    if (initial.MiddleKbn != current.MiddleKbn) {
      return false;
    }
    if ((initial.MiddleKbn != enums.MidtermClosingEnum.None) &&
      (initial.MCalcKbn != current.MCalcKbn)) {
      return false;
    }
    return true;
  }

  /** 会社名からコピーボタン押下時の処理 */
  const copyClick = () => {
    // 会社名の値を略式会社名にコピーする
    let newName: string = '';
    if (viewModel.LComName != undefined) {
      newName = viewModel.LComName;
      if (15 < newName.length) {
        newName = newName.slice(0, 15);
      }
    }
    updateViewModel({ ...viewModel, SComName: newName });
  }

  // 画面初期表示時の処理
  React.useEffect(() => {
    // 処理判定
    async function JudgeOnPost(url: string, syoriId: string) {
      const status = await getAxiosInstance().post<number>(url, {syoriId: syoriId});
      if (status.data == 0) {
        // 初期起動
        InitialOnGet(InitialURL);
        await getAxiosInstance().post(WriteSysHistoryURL, {syoriId: syoriId, operation: '処理開始'});
      } else {
        if(status.data == -101) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotUse});
        } else if (status.data == -102) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractOver});
        } else if (status.data == -103) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_Notauthorized});
        } else if (status.data == -104) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotDataSelect});
        } else if (status.data == -105) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractEqual});
        }
        props.onClose();
      }
    }
    JudgeOnPost(JudgeURL, 'AIKADT007000001');
  }, [InitialURL, props.fiscalYear]);

  /**
   * 会社基本情報画面 初期表示時データ取得処理
   * @param url サーバへアクセスするURL
   */
  const InitialOnGet = (url: string) => {
    getAxiosInstance().get<FirmInfoViewModel>(url)
      .then(response => {
        //console.debug('FirmInfo.InitialOnGet() then URL=' + url);
        let newViewModel = response.data;

        // 初期表示時のデータをバックアップ
        let initial = new FirmInfoViewModel();
        initial.LComName = newViewModel.LComName;
        initial.KanaComName = newViewModel.KanaComName;
        initial.SComName = newViewModel.SComName;
        initial.MiddleKbn = newViewModel.MiddleKbn;
        initial.MCalcKbn = newViewModel.MCalcKbn;
        updateInitialViewModel(initial);

        updateViewModel(newViewModel);
      })
      .catch(error => {
        //console.debug('FirmInfo.InitialOnGet() then URL=' + url + ' error=' + error);
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_InternalServerError });
      })
      .finally(() => {
        //console.debug('FirmInfo.InitialOnGet() finally URL=' + url);

        // フォーカス順序を制御するための初期処理
        if ((thisPage != undefined) && (thisPage.current != undefined)) {
          thisPage.current.removeEventListener('keydown', handleKeyDown);
          thisPage.current.addEventListener('keydown', handleKeyDown);
        }
        if ((firstFocusElement != undefined) && (firstFocusElement.current != undefined)) {
          firstFocusElement.current.focus();
        }
      });
  }

  /** サーバから取得前の初期値となる会社データ一式を返す
   * (ViewModel内の項目がnull undefinedだとReactがWarningをブラウザのConsoleに出力するため) */
  function getInitialValue(): FirmInfoViewModel {
    let init = new FirmInfoViewModel();
    init.ComCode = -1;
    init.LComName = '';
    init.KanaComName = '';
    init.SComName = '';
    init.KStDate = new Date();
    init.KEdDate = new Date();
    init.MStDay = -1;
    init.MiddleKbn = -1;
    init.MCalcKbn = -1;
    init.CorpKbn = -1;
    init.CorpKbnName = '';
    return init;
  }

  /** A19 中間決算区分ラジオボタン(群) */
  const middleKbnList: React.ReactElement[] = [];
  dataMaps.MidtermClosingEnum.forEach(data => {
    const checked = (viewModel.MiddleKbn === data.key);
    middleKbnList.push(
      <FormGroup check className='mr-5 my-label float-left' key={[data.key, 'middleKbn'].join('-')}>
        <Label check className='margin-right-0-5'>
          <Input
            type='radio'
            name='MiddleKbn'
            checked={checked}
            onChange={() => {
              handleOnMiddleKbnChange(data.key);
            }}
            disabled={true}
          />
          {data.value}
        </Label>
      </FormGroup>
    );
  });

  /**
   * A19 中間決算区分を変更した際の処理
   * @param middleKbn 中間決算区分(変更後の新しい値)
   */
  const handleOnMiddleKbnChange = (middleKbn: enums.MidtermClosingEnum) => {
    updateViewModel({ ...viewModel, MiddleKbn: middleKbn });
  };

  const mCalcKbnIsDisabled = (viewModel.MiddleKbn === enums.MidtermClosingEnum.None);

  /** A21 中間決算区分の集計方法ラジオボタン(群) */
  const mCalcKbnList: React.ReactElement[] = [];
  dataMaps.MidtermClosingCalcKbnEnum.forEach(data => {
    const checked = (viewModel.MCalcKbn === data.key);
    mCalcKbnList.push(
      <FormGroup check className='mr-5 my-label float-left' key={[data.key, 'middleKbn'].join('-')}>
        <Label check className='margin-right-0-5'>
          <Input
            type='radio'
            name='MCalcKbn'
            checked={checked}
            tabIndex={5}
            onChange={() => {
              handleOnMCalcKbnChange(data.key);
            }}
            disabled={mCalcKbnIsDisabled}
          />
          {data.value}
        </Label>
      </FormGroup>
    );
  });

  /**
   * A21 中間決算区分の集計方法を変更した際の処理
   * @param middleKbn 中間決算区分の集計方法(変更後の新しい値)
   */
  const handleOnMCalcKbnChange = (mCalcKbn: enums.MidtermClosingCalcKbnEnum) => {
    updateViewModel({ ...viewModel, MCalcKbn: mCalcKbn });
  };

  /**
   * A23 科目体系が初期化された際の処理
   * @param comboBox 初期化されたA23 科目体系ComboBox
   */
  const corpKbnInitialized = (comboBox: wjInput.ComboBox) => {
    if (corpKbnCmb == undefined) {
      setCorpKbnCmb(comboBox);
    }
  };

  /** 取消確認メッセージClose */
  const handleCancelClose = () => {
    setCancelActivated(false);
  };

  /** 取消確認メッセージYes */
  const handleCancelYes = () => {
    InitialOnGet(InitialURL);
    setCancelActivated(false);
  }

  /** 取消確認メッセージNo */
  const handleCancelNo = () => {
    // 何もしない
    setCancelActivated(false);
  }

  /**
   * 会社名・フリガナ・簡略会社名を変更時の処理
   * @param event
   */
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case 'LComName': // 会社名
        updateViewModel({ ...viewModel, LComName: event.target.value });
        break;

      case 'KanaComName': // フリガナ
        updateViewModel({ ...viewModel, KanaComName: event.target.value });
        break;

      case 'SComName': // 簡略会社名
        updateViewModel({ ...viewModel, SComName: event.target.value });
        break;
    }
  }

  /**
   * 会社名・簡略会社名Blur時(Focus out)の処理
   * @param event Blur時のイベント
   */
  const handleOnBlurComName = (event: React.FocusEvent<HTMLInputElement>) => {
    let newValue = event.target.value.trim();
    switch (event.target.name) {
      case 'LComName': // 会社名
        updateViewModel({ ...viewModel, LComName: newValue });
        break;

      case 'SComName': // 簡略会社名
        updateViewModel({ ...viewModel, SComName: newValue });
        break;
    }
  }

  /**
   * フリガナBlur時(Focus out)の処理
   * @param event フリガナBlur時のイベント
   */
  const handleOnBlurKanaComName = (event: React.FocusEvent<HTMLInputElement>) => {
    /** ユーザーが入力した値(入力不可能な文字も含む) */
    let valueBefore = event.target.value;
    /** ユーザーが入力した値から入力不可能な文字を削除した結果 */
    let valueAfter = validateKanaRenChar(valueBefore);
    updateViewModel({ ...viewModel, KanaComName: valueAfter });
  };

  return (
    <div className='firm-info' ref={thisPage}>
      <LowerActionLayout className='vw-100 h-100'>
        <LowerActionLayoutHeader>
          <PageHeader
            pageTitle={Labels.AIKADT007000001_FUNCTION_NAME /* A1 メニュー名 ラベル */}
            closeButtonTabIndex={6 /* A24 閉じる ボタン */}
            onCloseClick={handleOnCloseClick}
          >
          </PageHeader>
        </LowerActionLayoutHeader>
        <LowerActionLayoutContents className='vw-100 px-3 pt-3'>

          <div className='d-flex mb-3'>
            <div className='flex-auto'>
              <div>
                <div className='d-flex'>
                  <div className='table-th'><Label for='ComCode' className='label-control my-label'>{Labels.AIKADT007000001_INPUT_ComCode /* A2 会社コード ラベル */}</Label></div>
                  <div className='table-td'>
                    <Input
                      id='ComCode'
                      name='ComCode'
                      className='comCode'
                      readOnly
                      disabled={true}
                      value={viewModel.ComCode /* A3 会社コード */}
                    />
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='table-th'><Label for='LComName' className='label-control my-label'>{Labels.AIKADT007000001_INPUT_LComName /* A4 会社名 ラベル */}</Label></div>
                  <div className='table-td'>
                    <Input
                      id='LComName'
                      name='LComName'
                      className='lComName'
                      tabIndex={1}
                      innerRef={firstFocusElement}
                      maxLength={60}
                      value={viewModel.LComName /* A5 会社名 入力欄 */}
                      onChange={handleOnChange}
                      onBlur={handleOnBlurComName}
                    />
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='table-th'><Label for='KanaComName' className='label-control my-label'>{Labels.AIKADT007000001_INPUT_KanaComName /* A6 フリガナ ラベル */}</Label></div>
                  <div className='table-td'>
                    <Input
                      id='KanaComName'
                      name='KanaComName'
                      className='kanaComName'
                      tabIndex={2}
                      maxLength={60}
                      value={viewModel.KanaComName /* A7 フリガナ 入力欄 */}
                      onChange={handleOnChange}
                      onBlur={handleOnBlurKanaComName}
                    />
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='table-th'><Label for='SComName' className='label-control my-label'>{Labels.AIKADT007000001_INPUT_SComName /* A8 簡略会社名 ラベル */}</Label></div>
                  <div className='table-td'>
                    <div className='float-left'>
                      <Input
                        id='SComName'
                        name='SComName'
                        className='sComName mr-3'
                        tabIndex={3}
                        maxLength={30}
                        value={viewModel.SComName /* A9 簡略会社名 入力欄 */}
                        onChange={handleOnChange}
                        onBlur={handleOnBlurComName}
                      />
                    </div>
                    <div className='float-left'>
                      <Button onClick={copyClick} tabIndex={4}>
                        {Labels.AIKADT007000001_BUTTON_COPY /* A10 会社名からコピー ボタン */}
                      </Button>
                    </div>
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='table-th'><Label for='KStDate' className='label-control my-label'>{Labels.AIKADT007000001_INPUT_KStDate /* A11 会計期間 ラベル */}</Label></div>
                  <div className='table-td'>
                    <div className='float-left'>
                      <Input
                        id='KStDate'
                        name='KStDate'
                        className='kStDate'
                        readOnly
                        disabled={true}
                        value={viewModel.KStDate ? format(viewModel.KStDate, kaikeiKikanDispFormat) : '' /* A12 会計期間開始日 */}
                      />
                    </div>
                    <div className='float-left'>
                      <Label className='label-control my-label padding-like-a-form-control'>{Labels.AIKADT007000001_INPUT_Hani /* A13 範囲 ラベル */}</Label>
                    </div>
                    <div className='float-left'>
                      <Input
                        id='KEdDate'
                        name='KEdDate'
                        className='kEdDate'
                        readOnly
                        disabled={true}
                        value={viewModel.KEdDate ? format(viewModel.KEdDate, kaikeiKikanDispFormat) : '' /* A14 会計期間終了日 */}
                      />
                    </div>
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='table-th'><Label for='MStDay' className='label-control my-label'>{Labels.AIKADT007000001_INPUT_MStDay /* A15 月次開始日 ラベル */}</Label></div>
                  <div className='table-td'>
                    <div className='float-left'>
                      <Input
                        id='MStDay'
                        name='MStDay'
                        className='mStDay'
                        readOnly
                        disabled={true}
                        value={viewModel.MStDay /* A16 月次開始日 */}
                      />
                    </div>
                    <div className='float-left'>
                      <Label className='label-control my-label padding-like-a-form-control'>{Labels.AIKADT007000001_INPUT_Day /* A17 日 ラベル */}</Label>
                    </div>
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='table-th'><Label for='MiddleKbn' className='label-control my-label'>{Labels.AIKADT007000001_INPUT_MiddleKbn /* A18 中間決算区分 ラベル */}</Label></div>
                  <div className='table-td'>
                    <div className='float-left'>
                      {middleKbnList /* A19 中間決算区分 ラジオボタン */}
                    </div>
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='table-th'><Label for='MCalcKbn' className='label-control my-label'>{Labels.AIKADT007000001_INPUT_MCalcKbn /* A20 中間決算区分の集計方法 ラベル */}</Label></div>
                  <div className='table-td'>
                    <div className='float-left'>
                      {mCalcKbnList /* A21 中間決算区分の集計方法 ラジオボタン */}
                    </div>
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='table-th'><Label for='CorpKbn' className='label-control my-label'>{Labels.AIKADT007000001_INPUT_CorpKbn /* A22 科目体系 ラベル */}</Label></div>
                  <div className='table-td'>
                    <div className='float-left'>
                      <ComboBox
                        id={'CorpKbn'}
                        name={'CorpKbn'}
                        className='corpKbn'
                        itemsSource={corpKbnItemSource}
                        displayMemberPath={'value'}
                        selectedValuePath={'key'}
                        selectedValue={viewModel.CorpKbn /* A23 科目体系 コンボボックス */}
                        initialized={corpKbnInitialized}
                        isDisabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </LowerActionLayoutContents>

        <LowerActionLayoutAction className='px-3 pb-3'>
          <div className={'d-flex justify-content-between'}>
            <div className='justify-content-start'>
            </div>
            <div className={'ml-4'}>
              <ExecutingCancel
                executeLabel={Labels.COMMON_BUTTON_FUNCTION_SAVE /* A26 保存ボタン */}
                executeTabIndex={7}
                onExecuteClick={handleOnExecuteClick}

                cancelLabel={Labels.COMMON_BUTTON_FUNCTION_CANCEL /* A27 取消ボタン */}
                cancelTabIndex={8}
                cancelRef={lastFocusElement}
                onCancelClick={handleOnCancelClick}
              />
            </div>
          </div>
        </LowerActionLayoutAction>

        <ConfirmationPopup
          isOpen={cancelActivated}
          onClose={handleCancelClose}
          onYesClick={handleCancelYes}
          onNoClick={handleCancelNo}
        >
          {message.Common_Confirmation_Cancel}
        </ConfirmationPopup>

      </LowerActionLayout>
    </div>
  );
}
export default FirmInfo;