/**
 * マスターデータ ViewModel クラス
 */
import { DataModelBase } from "../../core/models/dataModelBase";
export class WinDspInfoCacheViewModel extends DataModelBase {

    public AppKbn!: number;
    public StaffNCode!: number;
    public DspNo!: number;
    public KmkCode!: number;
    public WinDspKbn!: number;

}
