import React from 'react';
import * as wjInput from 'wijmo/wijmo.input';
import { ComboBox } from 'wijmo/wijmo.react.input';
import ListSelectButton from '../../../containers/organisms/A000/ListSelectButton';
import { BmnSepAuditInfoViewModel } from '../../../models/bmnSepAuditInfoViewModel';
import DisplayOnlyGrid from '../CMN/Grid/DisplayOnlyGrid';
import Popup, { popupProps } from '../CMN/Popup/Popup';
import { createWjDataMap } from '../../../utils/dataMapsUtils';
import { AuditStatusEnum } from '../../../constants/dataMaps';
import { MonthSelectVO } from '../../../models/monthSelectVO';
import { CodeAttr } from '../../../VKZ/core/constants/constant';

export type BmnAuditInfoPopupProps = popupProps & {
  activated: boolean;
  onClose: () => void;
  viewModel: BmnSepAuditInfoViewModel;
  stNMonth: number;
  codeAttr: number;
}
// tabIndexの初期値
const baseTabIndex = 350;

const BmnAuditInfoPopup: React.FC<BmnAuditInfoPopupProps> = props => {
  const popupProps = { ...props };
  const [headerColumns, setHeaderColums] = React.useState(defHeaderColumns);
  const [selectedMonth, setSelectedMonth] = React.useState<MonthSelectVO>(new MonthSelectVO);
  // 月選択の初期設定
  const [monthIndex, setMonthIndex] = React.useState(0);
  // 保持用ViewModel
  const [originViewModel, setOriginViewModel] = React.useState<BmnSepAuditInfoViewModel>(
    new BmnSepAuditInfoViewModel()
  );
  // 表示用ViewModel
  const [viewModel, setViewModel] = React.useState<BmnSepAuditInfoViewModel>(
    new BmnSepAuditInfoViewModel()
  );

  const handleOnCloseClick = () => {
    props.onClose();
  }

  /**
   * 画面が表示された実行する処理(部門別監査状況内容更新) 
   */
  React.useEffect(() => {
    // 先頭月を設定
    const firstMonth = props.stNMonth;
    if (props.viewModel.BmnSepAuditInfoList !== undefined) {
      // 初期表示用ViewModelを設定
      setViewModel({...viewModel, MonthSelectList: props.viewModel.MonthSelectList!, BmnSepAuditInfoList: props.viewModel.BmnSepAuditInfoList!.filter(item => item.NMonth === firstMonth)});
      setOriginViewModel(props.viewModel);
    }
    if (props.viewModel.MonthSelectList !== undefined) {
      const monthSelIndex = props.viewModel.MonthSelectList!.findIndex(month => month.NMonth === firstMonth);
      setMonthIndex(monthSelIndex); //選択月設定
      setSelectedMonth(props.viewModel.MonthSelectList![monthSelIndex]); //切り替え月ボタン値設定
    }

    if (props.activated) {
      // 画面表示完了後に実行
      setTimeout(() => {
        if (popupRef.current) {
          // ポップアップにキーダウンイベントのフック
          popupRef.current.addEventListener('keydown', handleKeyDown);
        }
      });
    }
  }, [props.activated]);

  // codeAttrにより、部門コードのAlignを設定
  React.useEffect(() => {
    // set headerColunm by codeAttr
    var col = defHeaderColumns;
    if (props.codeAttr == CodeAttr.Free) {
      col[0]['align'] = 'left';
    } else {
      col[0]['align'] = 'right';
    }
    setHeaderColums(col);
  }, [props.codeAttr]);

  // 月選択が変更されたときの処理
  const handleSelectedIndexChangedMonth = (cmb: wjInput.ComboBox) => {
    setSelectedMonth(cmb.selectedItem);
    const nMonth = cmb.selectedItem.NMonth;
    const bmnList = originViewModel.BmnSepAuditInfoList!.filter(item => item.NMonth === nMonth);
    setViewModel({ ...viewModel, BmnSepAuditInfoList: bmnList });
    if (cmb.selectedIndex != monthIndex) {
      setMonthIndex(cmb.selectedIndex);
    }
  }
  // 月選択切り替えボタン選択変更
  const handleonListSelectButtonClick = (v: any) => {
    var cnt = -1;
    viewModel.MonthSelectList!.some(month => {
      cnt++;
      return JSON.stringify(month) == JSON.stringify(v);
    });
    setMonthIndex(cnt);
  };

  React.useEffect(() => {
    // 月コンボ
    const monthCmnElement = document.getElementById('monthCmb');
    if(monthCmnElement) {
      const textElement = monthCmnElement.getElementsByTagName('input');
      const elements = Array.from(textElement);
      for (let item of elements) {
        if (item.type === 'text') {
          item.tabIndex = baseTabIndex + 1;
          break;
        }
      }
    }

    // listSelectButton
    const listSelBtnElement = document.getElementById('listSel-Button');
    if (listSelBtnElement) {
      const buttonElement = listSelBtnElement.getElementsByTagName('button');
      const elements = Array.from(buttonElement);
      leftRef.current = elements[0];
      rightRef.current = elements[1];
    }
  }, [viewModel.MonthSelectList]);

  const closeIconRef = React.useRef<any>();
  const gridRef = React.useRef<any>();
  const popupRef = React.useRef<any>();
  const leftRef = React.useRef<any>();
  const rightRef = React.useRef<any>();
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      if (event.shiftKey === false) {
        // タブキー押下時

        const target: HTMLElement = event.target as HTMLElement;
        if (target.tabIndex === rightRef.current.tabIndex) {
          // 右ボタンの場合
          
          // 閉じるボタンにフォーカス
          closeIconRef.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        } else if (target.tabIndex === leftRef.current.tabIndex) {
          // 左ボタンの場合
          if (rightRef.current.disabled) {
            // 右ボタンが無効の場合、閉じるボタンにフォーカス
            closeIconRef.current.focus();
          } else {
            // 右ボタンにフォーカス
            rightRef.current.focus();
          }
          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
      } else {
        // shift + タブキー押下時
        const target: HTMLElement = event.target as HTMLElement;
        if (target.tabIndex === closeIconRef.current.tabIndex) {
          // 閉じるボタンの場合

          if(rightRef.current.disabled) {
            // 右ボタンが無効の場合
            // 左ボタンにフォーカス
            leftRef.current.focus();
          } else if (leftRef.current.disabled) {
            // 左ボタンが無効の場合
            // 右ボタンにフォーカス
            rightRef.current.focus();
          } else if (!rightRef.current.disabled && !leftRef.current.disabled) {
            // 左・右ボタン両方有効
            // 右ボタンにフォーカス
            rightRef.current.focus();
          }

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }
  };

  return (
    <Popup
      {...popupProps}
      header={'部門別監査状況確認'}
      isOpen={props.activated}
      onCloseClick={handleOnCloseClick}
      closeIconTabIndex={baseTabIndex}
      closeIconRef={closeIconRef}
      innerRef={popupRef}
    >
      <div className='d-inline'>
        {/* 月リスト */}
        <ComboBox
          id={'monthCmb'}
          className='mr-2'
          itemsSource={viewModel.MonthSelectList!}
          displayMemberPath={'MonthName'}
          selectedValuePath={'NMonth'}
          selectedIndex={monthIndex}
          selectedIndexChanged={handleSelectedIndexChangedMonth}
          allowSpin={true}
        />
        <div className='d-inline' id='listSel-Button'>
          {/* 月切り替えボタン */}
          <ListSelectButton
            target={viewModel.MonthSelectList!}
            value={selectedMonth}
            onClick={handleonListSelectButtonClick}
            disabled={false}
            tabIndex={baseTabIndex + 2}
          />
        </div>
      </div>
      <DisplayOnlyGrid
        gridClassName='mt-2 bmn-audit-info'
        columns={headerColumns}
        sources={viewModel.BmnSepAuditInfoList!}
        gridRef={gridRef}
      />
    </Popup>
  )
};

const defHeaderColumns = [
  {
    header: 'コード',
    binding: 'BmnCode',
    isReadOnly: true,
    align: 'right',
    width: 110,
    format: 'd'
  },
  {
    header: '名称',
    binding: 'BmnName',
    isReadOnly: true,
    align: 'left',
    width: '*'
  },
  {
    header: '著増減監査',
    binding: 'RemarkIncDecConfStatus',
    isReadOnly: true,
    align: 'left',
    dataMap: createWjDataMap(AuditStatusEnum),
    width: 120
  },
  {
    header: 'マイナス残高監査',
    binding: 'NegativeBalanceConfStatus',
    isReadOnly: true,
    align: 'left',
    dataMap: createWjDataMap(AuditStatusEnum),
    width: 120
  },
];

export default BmnAuditInfoPopup;