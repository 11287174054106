import React from 'react';
import classnames from 'classnames';

const DefaultLayout: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
  <div {...props} className={classnames(props.className, 'default-layout')}>
    {props.children}
  </div>
);

const DefaultLayoutHeader: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
  <div {...props} className={classnames(props.className, '-header')}>
    {props.children}
  </div>
);

const DefaultLayoutContents: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
  <div {...props} className={classnames(props.className, '-content')}>
    {props.children}
  </div>
);

export { DefaultLayout, DefaultLayoutHeader, DefaultLayoutContents };
