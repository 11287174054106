import {AccuntRegisterListVO} from "./accuntRegisterListVO";
/**
 * 勘定科目登録ViewModel ViewModel クラス
 */

export class AccountRegisterViewModel {
    /** 科目情報リスト */
    public AccuntRegisterList?:Array<AccuntRegisterListVO>;

    /** 科目分類名称 */
    public KmkClassName?:string;

}
