export class SessionToken {

    public static hsTokenValue: string;

    private static tokenKey = "__VSVerificationToken";

    /**
     * セッションの正当性確認用トークン情報を追加します。
     * @param data JQueryXHR
     */
    public static setHeader(jqXHR: JQueryXHR): void {
        jqXHR.setRequestHeader(SessionToken.tokenKey, SessionToken.hsTokenValue);
    }

    public static createFormJson(): any {
        let ret: any = {};
        ret[SessionToken.tokenKey] = SessionToken.hsTokenValue;
        return ret;
    }
}
