/**
 * データ移行 ViewModel クラス
 */
export class MigrationSettingDetailViewModel {
    /** 対応元科目コード */
    public SrcKmkCode!: string;

    /** 対応元科目名 */
    public SrcKmkName!: string;

    /** 対応元科目連想 */
    public SrcKmkRenChar!: string;

    /** 対応元補助区分 */
    public SrcHojyoKbn!: number;

    /** 対応元補助コード */
    public SrcHojyoCode!: string;

    /** 対応元補助名 */
    public SrcHojyoName!: string;

    /** 対応元補助連想 */
    public SrcHojyoRenChar!: string;

    /** 部門ＢＳ／ＰＬ区分 */
    public BmnBPKbn!: number;

    /** 自動マッピング結果区分 */
    public AutoMappingResultKbn!: number;

    /** 対応先補助区分1 */
    public DstHojyoKbn1!: number;

    /** 対応先補助区分2 */
    public DstHojyoKbn2!: number;

    /** 対応先科目コード */
    public DstKmkCode!: number;

    /** 対応先科目別補助コード */
    public DstKmkHojyoCode!: number;

    /** 対応先補助コード */
    public DstHojyoCode!: string;

    /** マッピング設定区分 */
    public MappingSettingKbn!: number;

    /** 期首残高金額 */
    public OpeningBalance!: number;

    /** 保存オペレーション区分 */
    public SaveOperationKbn!: number;

    /** 科目別マスタ採用可能区分 */
    public AuxiliaryAccountCanAdoptedKbn!: number;

    /** 行バージョン */
    public MappingVersionNo!: string;

}

