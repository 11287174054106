import {RulePatternEnum} from "../constants/enums";
import {CmpTMonthEnum} from "../constants/enums";
import {CmpValueEnum} from "../constants/enums";
import {SkiSignEnum} from "../constants/enums";
/**
 * 月次残高ルールVO ViewModel クラス
 */

export class MonthlyBalanceRuleItemVO {
    /** パターン区分 */
    public RulePatternKbn?:RulePatternEnum;

    /** 科目コード */
    public KmkCode?:number;

    /** 科目外部コード */
    public KmkGCode?:number;

    /** 科目名称 */
    public KmkName?:string;

    /** 科目フラグ(著増減) */
    public RidKmkFlg?:boolean;

    /** 補助フラグ(著増減) */
    public RidHojyoFlg?:boolean;

    /** 比較対象月区分 */
    public CmpTMonthKbn?:CmpTMonthEnum;

    /** 比較値区分 */
    public CmpValueKbn?:CmpValueEnum;

    /** しきい値符号 */
    public SkiSign?:SkiSignEnum;

    /** しきい値 */
    public SkiValue?:number;

    /** しきい値金額 */
    public SkiValueSum?:number;

    /** 科目フラグ(マイナス) */
    public NBKmkFlg?:boolean;

    /** 補助フラグ(マイナス) */
    public NBHojyoFlg?:boolean;

    /** 補助表示フラグ */
    public SubDspFlg?:boolean;

    /** 補助コード */
    public HojyoCode?:string;

    /** 補助外部コード */
    public HojyoGCode?:string;

    /** 補助外部コード数値 */
    public HojyoGCodeNum?:number;

    /** 補助名称 */
    public HojyoName?:string;

    /** 追加更新区分 */
    public AddUpdKbn?:number;

}
