import React from 'react';

import * as labels from '../../../constants/labels';
import * as message from '../../../constants/message';
import { bindValueToMessage } from '../../../utils/messageUtils';
import { createWjDataMap, getValueFromDataMap, getDataMapFromValue } from '../../../utils/dataMapsUtils';
import { RuleJokenVO } from '../../../models/ruleJokenVO';
import { RuleJItemVO } from '../../../models/ruleJItemVO';
import { AndOrEnum, CondiStringEnum, CondiNumberEnum, CondiDateEnum } from '../../../constants/dataMaps';
import { ItemTypeEnum } from '../../../constants/enums';

import Button from 'reactstrap/lib/Button';
import * as wj from 'wijmo/wijmo';
import * as wjGrid from 'wijmo/wijmo.grid';
import * as wjInput from 'wijmo/wijmo.input';
import { FlexGrid, FlexGridColumn } from 'wijmo/wijmo.react.grid';

import JournalLineJItem from '../../../containers/organisms/A020/JournalLineJItem';

import externallinkSvg from '../../../images/icon/16_externallink.svg';

type SourceData = {
  groupingCheck: boolean;
  ruleJoken: RuleJokenVO;
  groups: string[];
};

type GroupSetting = {
  level: number;
  groupNo: number;
};

type GroupRange = {
  group: GroupSetting;
  from: number;
  to: number;
};

declare global {
  interface Window {
    handleUngroupButtonClick: (r: number, level: number) => void;
    handleJItemClick: (r: number) => void;
  }
}

type CheckRuleProps = {
  ruleJokens: RuleJokenVO[];
  groupNos: Array<Array<string>>;
  disabled: boolean;
  gridRef?: React.RefObject<any>;
};
type CheckRuleState = {
  sources: wj.CollectionView;
  groupSettings: GroupSetting[][];
  jItemActivated: boolean;
  jItemInitialSelectedJItemNo?: number;
  errorText: string;
  groupable: boolean;
  grid?: wjGrid.FlexGrid;
  tooltip: wj.Tooltip;
};

const htmlJournalLineLinkIcon = (rowNo: number) =>
  `<button type="button" class="-gridIcon btn bg-svg-icon" style="background-image: url(${externallinkSvg})" tabIndex="-1" onclick=window.handleJItemClick(${rowNo}) />`;

const htmlGroupIcon = (level: number, groupNo: number) =>
  `<span class='cursor-pointer' onClick={window.handleUngroupButtonClick(${level},${groupNo})}>✖</span>`;

class CheckRule extends React.Component<CheckRuleProps, CheckRuleState> {
  constructor(props: CheckRuleProps) {
    super(props);
    const { sources, groups } = this.convertIntoData(props.ruleJokens, props.groupNos);
    this.state = {
      sources: new wj.CollectionView(sources),
      groupSettings: groups,
      jItemActivated: false,
      errorText: '',
      groupable: false,
      tooltip: new wj.Tooltip()
    };

    this.setFromGroupSettingsToSources = this.setFromGroupSettingsToSources.bind(this);
    this.initializedGrid = this.initializedGrid.bind(this);
    this.itemFormatter = this.itemFormatter.bind(this);
    this.pasting = this.pasting.bind(this);
    this.getGroupingColor = this.getGroupingColor.bind(this);
    this.layoutGrouping = this.layoutGrouping.bind(this);
    this.handleDateCellEditEnded = this.handleDateCellEditEnded.bind(this);
    this.handleCellEditEnding = this.handleCellEditEnding.bind(this);
    this.handleCellEditEnded = this.handleCellEditEnded.bind(this);
    this.handlePlusButtonClick = this.handlePlusButtonClick.bind(this);
    this.handleMinusButtonClick = this.handleMinusButtonClick.bind(this);
    this.handleUngroupButtonClick = this.handleUngroupButtonClick.bind(this);
    this.handleJItemClick = this.handleJItemClick.bind(this);
    this.handleConditionAdditionClick = this.handleConditionAdditionClick.bind(this);
    this.handleGroupingClick = this.handleGroupingClick.bind(this);
    this.switchGroupability = this.switchGroupability.bind(this);
    this.addRow = this.addRow.bind(this);
    this.addRowToGroupSettings = this.addRowToGroupSettings.bind(this);
    this.canGroup = this.canGroup.bind(this);
    this.group = this.group.bind(this);
    this.relocateLevel = this.relocateLevel.bind(this);
    this.exchangeGroups = this.exchangeGroups.bind(this);
    this.ungroup = this.ungroup.bind(this);
    this.fillGroupWithZero = this.fillGroupWithZero.bind(this);
    this.shiftGroups = this.shiftGroups.bind(this);
    this.getChildGroups = this.getChildGroups.bind(this);
    this.deduplicateLevels = this.deduplicateLevels.bind(this);
    this.deleteEmptyLevel = this.deleteEmptyLevel.bind(this);
    this.ungroupSingleGroup = this.ungroupSingleGroup.bind(this);
    this.fillGroupSettings = this.fillGroupSettings.bind(this);
    this.getParentGroup = this.getParentGroup.bind(this);
    this.getRangeOfGroup = this.getRangeOfGroup.bind(this);
    this.getGroupRanges = this.getGroupRanges.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
    this.handelOnSelectClick = this.handelOnSelectClick.bind(this);

    window.handleUngroupButtonClick = this.handleUngroupButtonClick;
    window.handleJItemClick = this.handleJItemClick;
  }

  // props.ruleJokensをstates.sourcesに変換
  private convertIntoData(ruleJokens: RuleJokenVO[], groupNos: Array<Array<string>>) {
    const sources: SourceData[] = ruleJokens.map((joken, i) => {
      return {
        groupingCheck: false,
        ruleJoken: joken,
        groups: groupNos[i]
      };
    });
    let groups: GroupSetting[][] = [];
    if (groupNos == undefined || groupNos.length < 1) {
      for (let i = 0; i < ruleJokens.length; i++) {
        groups.push([]);
      }
    } else {
      groups = groupNos.map(row => {
        return row.map(groupNo => {
          const splitted = groupNo.split('-');
          return splitted.length === 2
            ? {
                level: parseInt(splitted[0]),
                groupNo: parseInt(splitted[1])
              }
            : {
                level: 0,
                groupNo: 0
              };
        });
      });
    }
    return { sources, groups };
  }

  /**
   * グループ化の情報をCollectionViewに反映します
   */
  private setFromGroupSettingsToSources(from: GroupSetting[][], to: wj.CollectionView) {
    const groupNos = from.map(row => row.map(group => [group.level.toString(), group.groupNo.toString()].join('-')));

    to.items.forEach((item, i) => {
      item.groups = groupNos[i];
    });
  }

  public initializedGrid(flexGrid: wjGrid.FlexGrid) {
    this.setState({ grid: flexGrid });
  }
  public itemFormatter(panel: wjGrid.GridPanel, r: number, c: number, cell: HTMLElement) {
    // ヘッダ
    cell.style.fontSize = '12px';
    if (panel.cellType === wjGrid.CellType.ColumnHeader) {
      cell.style.textAlign = 'center';
    }

    // ヘッダーではなく、セルの場合
    else if (panel.cellType === wjGrid.CellType.Cell) {
      const name: string = panel.columns[c].name;
      // ＋ボタンの列（列名が"plusButton"である列）
      if (name === 'plusButton') {
        cell.style.textAlign = 'center';
        cell.style.fontWeight = 'bold';
        cell.style.fontSize = '20px';
        cell.style.lineHeight = '20px';
        cell.onclick = () => this.handlePlusButtonClick(r);
        cell.innerHTML = '+';
      }
      // -ボタンの列（列名が"minusButton"である列）
      else if (name === 'minusButton') {
        cell.style.textAlign = 'center';
        cell.style.fontWeight = 'bold';
        cell.style.fontSize = '20px';
        cell.style.lineHeight = '20px';
        cell.onclick = () => this.handleMinusButtonClick(r);
        cell.innerHTML = '-';
      }
      // AndOrの列（列名が"andOr"である列）
      else if (name === 'andOr' && r === 0) {
        // 最初の行には何も表示しない
        cell.innerHTML = '';
      }
      // 項目の列（列名が"item"である列）
      else if (name === 'item') {
        const value = panel.getCellData(r, c, false);
        if (!this.props.disabled) {
          const icon = htmlJournalLineLinkIcon(r);
          cell.innerHTML = [value, icon].join('');
          cell.onkeydown = (event: KeyboardEvent) => {
            if (event.keyCode === 13) {
              this.handleJItemClick(r);
              event.preventDefault();
              event.stopPropagation();
            }
          };
        }
        this.state.tooltip.setTooltip(cell, value);
      }
      // 値の列（列名が"value"である列）
      else if (name === 'value') {
        const value = panel.getCellData(r, c, false);

        const type = panel.rows[r].dataItem.ruleJoken.JItemTypeKbn;
        const commaFlg: boolean = panel.rows[r].dataItem.ruleJoken.CommaFlg;
        if (type != undefined) {
          if (type === ItemTypeEnum.Text) {
            // テキスト項目
          } else if (type === ItemTypeEnum.Number) {
            // 数値項目
            // 編集中のセルである場合は適用しない
            if (panel.grid.editRange && panel.grid.editRange.contains(r, c)) {
              return;
            }
            if (commaFlg && value !== '') {
              cell.innerHTML = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
          } else {
            // 日付項目
            if (panel.grid.editRange && panel.grid.editRange.contains(r, c)) {
              cell.innerHTML = '';
              cell.style.padding = '0';
              const editorRoot = document.createElement('div');
              editorRoot.setAttribute('row', r.toString());
              const input = new wjInput.InputDate(editorRoot);
              if (!value || value === '') {
                input.value = new Date();
              } else {
                input.value = new Date(value);
              }
              editorRoot.style.border = 'none';
              editorRoot.style.borderRadius = ' inherit';
              editorRoot.style.width = '100%';

              input.lostFocus.addHandler(this.handleDateCellEditEnded);
              cell.appendChild(editorRoot);
              editorRoot.focus();
            }
          }
        }
      }
      // グループ設定の列（列名が"level"で始まる列）
      else if (name.startsWith('level')) {
        const level: number = Number(name.replace('level', ''));

        // 対象の階層・対象の条件Noで、グループNoが設定されているか
        if (this.state.groupSettings[r] && this.state.groupSettings[r].length > 0) {
          // 当該セルのグループ設定を取得
          const levelNo = this.state.groupSettings[0].length;
          const groupSetting = this.state.groupSettings[r][levelNo - level];

          const groupRange = this.getRangeOfGroup(this.state.groupSettings, groupSetting);

          const color = this.getGroupingColor(groupSetting.level);
          // セルの位置と、登録されている階層が合っているか
          if (groupSetting.level === level) {
            if (r === groupRange.from) {
              cell.innerHTML =
                '<div class="w-100 h-100 border-top border-left border-dark text-center ' +
                color +
                '">' +
                htmlGroupIcon(groupSetting.level, groupSetting.groupNo) +
                '</div>';
              cell.style.padding = '1px 0px 0px 1px';
            } else if (r === groupRange.to) {
              cell.innerHTML = '<div class="w-100 h-100 border-bottom border-left border-dark ' + color + ' "/>';
              cell.style.padding = '0px 0px 1px 1px';
            } else {
              cell.innerHTML = '<div class="w-100 h-100 border-left border-dark ' + color + '"/>';
              cell.style.padding = '0px 0px 0px 1px';
            }
          } else if (groupSetting.level !== 0) {
            // 合っていないかつ0-0でない場合は横棒
            if (r === groupRange.from) {
              cell.innerHTML = '<div class="w-100 h-100 border-top border-dark ' + color + '"/>';
              cell.style.padding = '1px 0px 0px 0px';
            } else if (r === groupRange.to) {
              cell.innerHTML = '<div class="w-100 h-100 border-bottom border-dark ' + color + '"/>';
              cell.style.padding = '0px 0px 1px 0px';
            } else {
              cell.innerHTML = '<div class="w-100 h-100 ' + color + '"/>';
              cell.style.padding = '0px';
            }
          }
          // 0-0なら何も書かない
        }
      }
    }
  }
  public pasting(grid: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs) {
    // ペーストを無効
    e.cancel = true;
  }

  /**
   * レベル度毎の色を返します。
   */
  private getGroupingColor(level: number) {
    switch (level % 3) {
      case 0:
        return '_level1';
      case 1:
        return '_level2';
      case 2:
        return '_level3';
      default:
        return '';
    }
  }

  private layoutGrouping() {
    const groupingColumns: JSX.Element[] = [];
    // 今ある階層の数
    const levelNo = this.state.groupSettings.length > 0 ? this.state.groupSettings[0].length : 0;
    for (let i = levelNo; i > 0; i--) {
      groupingColumns.push(<FlexGridColumn header=' ' key={i} width={20} name={'level' + i} isReadOnly={true} />);
    }
    return groupingColumns;
  }

  // #region ハンドラー

  /**
   * 日付セル編集後
   */
  public handleDateCellEditEnded(sender: wjInput.InputDate) {
    const row = parseInt(sender.hostElement.getAttribute('row')!);
    this.state.sources.items[row].ruleJoken.Value = sender.text;
    return true;
  }

  /**
   * グリッド編集時
   */
  public handleCellEditEnding(grid: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs) {
    const panel = e.panel;

    if (panel.columns[e.col].binding === 'ruleJoken.JokenKbn') {
      // 条件区分が見つからなかったら入力をキャンセルする
      let jokenKbn;
      const ruleJoken = grid.collectionView.items[e.row].ruleJoken;
      if (grid.activeEditor) {
        jokenKbn = this.getJokenKbnKeyFromValue(grid.activeEditor.value, ruleJoken.JItemTypeKbn);
      }
      if (jokenKbn == undefined) {
        e.cancel = true;
        return;
      }
    } else if (panel.columns[e.col].name === 'value') {
      // 数値項目の「値」列の編集時、数値以外のデータがあれば編集をキャンセル
      // (日付の不正チェックはwijmoのInputDateがやってくれるためここには書かない)
      const type = grid.collectionView.items[e.row].ruleJoken.JItemTypeKbn;
      if (type === ItemTypeEnum.Number) {
        if (!grid.activeEditor) {
          grid.setCellData(e.row, e.col, '0');
          return;
        }
        const newVal = this.toIntStr(grid.activeEditor.value);
        if (newVal === '') {
          e.cancel = true;
          return;
        } else {
          grid.activeEditor.value = newVal;
        }
      }
      e.cancel = false;
    }
  }

  /**
   * 整数値の文字列を返します。
   * 変換できなかった場合はブランクを返します。
   * @param val 変換する文字列
   */
  private toIntStr(val: string) {
    let newVal = '';
    let initial = val.slice(0, 1);
    const value = initial === '+' || initial === '-' ? val.slice(1) : val;
    initial = initial === '-' ? '-' : '';
    let zeroFlg = true;
    let maeZeroFlg = false;
    const valList = value.split('');

    for (let i = 0; i < valList.length; i++) {
      const char = valList[i];
      if (char.match(/[^0-9]/)) {
        break;
      }

      if (zeroFlg) {
        if (char === '0') {
          maeZeroFlg = true;
          continue;
        } else {
          zeroFlg = false;
        }
      }

      newVal += char;
    }

    return newVal === '' ? (maeZeroFlg ? '0' : '') : initial + newVal;
  }

  /**
   * グリッド編集後
   */
  public handleCellEditEnded(grid: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs) {
    const panel = e.panel;
    if (panel.columns[e.col].binding === 'ruleJoken.JokenKbn') {
      // ラベル名が重複すると先勝ちで値が指定されてしまう為、正しい条件区分を設定する
      const ruleJoken = grid.collectionView.items[e.row].ruleJoken;
      const condiDataMap = CondiStringEnum.concat(CondiNumberEnum).concat(CondiDateEnum);
      const lbl = getValueFromDataMap(condiDataMap, Number(ruleJoken.JokenKbn));
      grid.beginUpdate();
      ruleJoken.JokenKbn = this.getJokenKbnKeyFromValue(lbl, ruleJoken.JItemTypeKbn);
      grid.endUpdate();
    } else if (panel.columns[e.col].binding === 'ruleJoken.Value') {
      const ruleJoken = grid.collectionView.items[e.row].ruleJoken;
      const type = ruleJoken.JItemTypeKbn;
      if (type === ItemTypeEnum.Date) {
        if (typeof e.data === 'string') {
          grid.beginUpdate();
          ruleJoken.Value = e.data;
          grid.endUpdate();
        }
      }
    }
    this.setState({ groupable: this.switchGroupability() });
  }

  /**
   * 条件項目のvalueと項目種別区分から条件項目のkeyを取得します
   * @param value 表示文言
   * @param jItemTypeKbn 項目種別区分
   */
  private getJokenKbnKeyFromValue(value: string, jItemTypeKbn: ItemTypeEnum) {
    switch (jItemTypeKbn) {
      case ItemTypeEnum.Text: //テキスト項目
        return getDataMapFromValue(CondiStringEnum, value);
      case ItemTypeEnum.Number: //数値項目
        return getDataMapFromValue(CondiNumberEnum, value);
      case ItemTypeEnum.Date: //日付項目
        return getDataMapFromValue(CondiDateEnum, value);
      default:
        return undefined;
    }
  }
  /**
   * 行追加ボタンクリック
   * @param r クリックされた行追加ボタンの位置
   */
  private handlePlusButtonClick(r: number) {
    // 編集不可時は何もしない
    if (this.props.disabled) {
      return;
    }
    this.addRow(r);
    this.state.grid!.selection = new wjGrid.CellRange(r, 0);
    this.setState({ groupable: this.switchGroupability() });
  }

  /**
   * 削除ボタンクリック
   * @param r クリックされた削除ボタンの位置
   */
  private handleMinusButtonClick(r: number) {
    // 編集不可時は何もしない
    if (this.props.disabled) {
      return;
    }
    this.state.sources.removeAt(r);

    // グループ設定も削除する
    let groupArray = this.state.groupSettings;
    if (groupArray.length > 0) {
      groupArray = [...groupArray];

      groupArray.splice(r, 1);

      // 行の削除により同じ形になってしまった階層があった場合、そのうちの左側を消す
      groupArray = this.deduplicateLevels(groupArray);

      // 一番左側の階層にグループが存在しなくなった場合、階層ごと消す
      groupArray = this.deleteEmptyLevel(groupArray);

      // 要素が1つになってしまった階層があった場合、削除する
      groupArray = this.ungroupSingleGroup(groupArray);
    }

    // 表示順振り直し
    this.state.sources.items.forEach((x, i) => (x.ruleJoken.DspNo = i + 1));

    this.setFromGroupSettingsToSources(groupArray, this.state.sources);
    this.setState({ groupSettings: groupArray });
  }

  /**
   * グループ解除ボタンクリック
   * @param r クリックされた解除ボタンの行
   * @param level クリックされた解除ボタンの階層
   */
  public handleUngroupButtonClick(level: number, groupNo: number) {
    //編集不可時は何もしない
    if (this.props.disabled) {
      return;
    }
    const targetGroup = { level: level, groupNo: groupNo };
    const newGroupSettings = this.ungroup([...this.state.groupSettings], targetGroup);

    this.setFromGroupSettingsToSources(newGroupSettings, this.state.sources);
    this.setState({ groupSettings: newGroupSettings });
  }

  /**
   * 条件項目選択ボタンクリック
   */
  public handleJItemClick(r: number) {
    // 編集不可時は何もしない
    if (this.props.disabled) {
      return;
    }
    this.setState({
      jItemActivated: true,
      jItemInitialSelectedJItemNo: this.state.sources.items[r].ruleJoken.JItemNo
    });
  }

  /**
   * 条件追加ボタンクリック
   */
  public handleConditionAdditionClick() {
    const r = this.state.sources.items.length;
    this.addRow(r);
    this.state.grid!.selection = new wjGrid.CellRange(0, 0);
  }

  /**
   * グループ化ボタンクリック
   */
  public handleGroupingClick = () => {
    // チェックがついている最初の行と最後の行を出す
    let checkedFrom = this.state.groupSettings.length;
    let checkedTo = 0;
    this.state.sources.items.forEach((x, i) => {
      if (x.groupingCheck) {
        checkedFrom = i < checkedFrom ? i : checkedFrom;
        checkedTo = i > checkedTo ? i : checkedTo;
      }
    });

    // グループ化可能かどうかのチェック
    if (!this.canGroup(checkedFrom, checkedTo)) {
      return;
    }

    // グループ化を行う
    const newGroupSettings = this.group([...this.state.groupSettings], checkedFrom, checkedTo);

    // 全てのチェックを外す
    const array = this.state.sources.items.slice();
    const uncheckedArray = array.map(x => Object.assign(x, { groupingCheck: false }));
    const collectionView = new wj.CollectionView(uncheckedArray);

    this.setFromGroupSettingsToSources(newGroupSettings, collectionView);
    this.setState({
      sources: collectionView,
      groupSettings: newGroupSettings,
      groupable: this.switchGroupability()
    });
  };

  // #endregion

  // #region ボタン制御

  /**
   * グループ化ボタンの活性/非活性を操作
   */
  private switchGroupability() {
    // 複数チェックがなかったらグループ化不可
    const checked = this.state.sources.items.filter(x => x.groupingCheck);
    if (checked.length < 2) {
      return false;
    }
    // チェックの位置が全て繋がっていなかったらグループ化不可
    const checkInterval = checked.reduce((maxInterval: number, data: SourceData, i: number) => {
      if (i === 0) {
        return 0;
      }
      const dspNo = data.ruleJoken.DspNo != undefined ? Number(data.ruleJoken.DspNo) : 0;
      const previousDspNo = checked[i - 1].ruleJoken.DspNo != undefined ? Number(checked[i - 1].ruleJoken.DspNo) : 0;
      const interval = dspNo - previousDspNo;
      return interval > maxInterval ? interval : maxInterval;
    }, 0);

    if (checkInterval > 1) {
      return false;
    }

    return true;
  }

  // #endregion

  // #region 条件追加関連

  /**
   * グリッドの行を追加する
   * @param r 行を追加する位置
   */
  private addRow = (r: number) => {
    // グループ設定を追加する
    const newGroupSettings = this.addRowToGroupSettings(r);

    // 今ある条件Noの最大値 + 1
    const newJokenNo: number =
      this.state.sources.items.reduce((num, b) => {
        const jokenNo: number = b.ruleJoken.JokenNo != undefined ? Number(b.ruleJoken.JokenNo) : 0;
        return num > jokenNo ? num : jokenNo;
      }, 0) + 1;

    const newRuleJoken: RuleJokenVO = {
      JokenNo: newJokenNo
    };
    // 新しい行を作成して追加する
    const newRow: SourceData = {
      groupingCheck: false,
      ruleJoken: newRuleJoken,
      groups: []
    };

    this.state.sources.items.splice(r, 0, newRow);
    // 表示順振り直し
    this.state.sources.items.forEach((x, i) => (x.ruleJoken.DspNo = i + 1));

    this.setFromGroupSettingsToSources(newGroupSettings, this.state.sources);
    this.setState({
      sources: new wj.CollectionView(this.state.sources.items),
      groupSettings: newGroupSettings
    });
  };

  /**
   * 行の追加と同時にグループ設定も増える
   * @param r 行を加える位置
   */
  private addRowToGroupSettings(r: number) {
    let newRow: GroupSetting[];

    if (this.state.groupSettings.length < 1) {
      newRow = [];
    } else {
      const previousRow = this.state.sources.items[r - 1];
      const nextRow = this.state.sources.items[r];

      // 下の行のグループ設定をコピーする
      newRow = nextRow == undefined ? this.state.groupSettings[r - 1].slice() : this.state.groupSettings[r].slice();

      if (previousRow == undefined || nextRow == undefined) {
        // 一番上・もしくは一番下に加える場合、どのグループにも属さない
        newRow.fill({
          level: 0,
          groupNo: 0
        });
      } else {
        newRow.forEach((x, i) => {
          // グループの先頭行だった場合
          if (this.getRangeOfGroup(this.state.groupSettings, x).from === r) {
            if (i !== 0) {
              // ひとつ上の階層のものを返す
              newRow[i] = { ...newRow[i - 1] };
            } else {
              // 上の階層がなければグループ化無し
              newRow[i] = { groupNo: 0, level: 0 };
            }
          }
        });
      }
    }

    const newGroupSettings = [...this.state.groupSettings];
    newGroupSettings.splice(r, 0, newRow);
    return newGroupSettings;
  }

  // #endregion

  // #region グループ化関連

  /**
   * グループ化可能かどうかのチェック
   * @param groupingFrom グループ化したい最初の行
   * @param groupingTo グループ化したい最後の行
   */
  private canGroup(groupingFrom: number, groupingTo: number) {
    // 既存グループの位置をリストアップ
    const groupRanges = this.getGroupRanges(this.state.groupSettings);

    // fromとtoが同じグループ設定が既にいたらエラー
    if (groupRanges.findIndex(x => x.from === groupingFrom && x.to === groupingTo) >= 0) {
      this.setState({ errorText: bindValueToMessage(message.Common_Error_InvalidSetting, ['グループ']) });
      return false;
    }

    // 既存グループの境界をまたごうとしていたらエラー
    if (
      groupRanges.findIndex(
        x =>
          (x.from > groupingFrom && x.from <= groupingTo && x.to > groupingTo) ||
          (x.from < groupingFrom && x.to >= groupingFrom && x.to < groupingTo)
      ) >= 0
    ) {
      this.setState({ errorText: bindValueToMessage(message.Common_Error_InvalidSetting, ['グループ']) });
      return false;
    }
    this.setState({ errorText: '' });
    return true;
  }

  /**
   * グループ化を行う
   */
  private group(groups: GroupSetting[][], groupingFrom: number, groupingTo: number) {
    // 既存の階層の中に入れられるかどうかを判定する
    const levelNo = groups[0].length;
    let targetlevelIndex = -1;
    let newGroupNo = 1;
    for (let i = 0; i < levelNo; i++) {
      let maxGroupNo = 0;
      let canUseThislevel = true;
      groups.forEach((row, rowIndex) => {
        if (levelNo - i === row[i].level) {
          maxGroupNo = row[i].groupNo > maxGroupNo ? row[i].groupNo : maxGroupNo;
          if (rowIndex >= groupingFrom && rowIndex <= groupingTo) {
            canUseThislevel = false;
          }
        }
      });

      if (canUseThislevel) {
        targetlevelIndex = i;
        newGroupNo = maxGroupNo + 1;
      }
    }

    let addedGroup: GroupSetting;

    if (targetlevelIndex < 0) {
      // 新しい階層を追加する場合
      addedGroup = { level: levelNo + 1, groupNo: 1 };

      // 一旦一番外側の階層に追加する
      groups.forEach((x, i) => {
        if (i >= groupingFrom && i <= groupingTo) {
          x.unshift(addedGroup);
        } else {
          x.unshift({ level: 0, groupNo: 0 });
        }
      });
    } else {
      // 既存の階層に追加する場合
      addedGroup = { level: levelNo - targetlevelIndex, groupNo: newGroupNo };

      groups.forEach((x, i) => {
        if (i >= groupingFrom && i <= groupingTo) {
          x.forEach((y, i) => {
            if (i == targetlevelIndex) {
              x[i] = addedGroup;
            }
          });
        }
      });
    }

    // 内側の階層に移動させることができれば移動させる
    groups = this.relocateLevel(groups, addedGroup);

    return groups;
  }

  /**
   * 対象のグループを内側に移動させる
   */
  private relocateLevel(groups: GroupSetting[][], group: GroupSetting) {
    groups = this.fillGroupSettings(groups);

    if (group.level === 1) {
      // 既に対象のグループが一番目の階層にある場合、
      // それ以上内側にはいけないので処理終了
      return groups;
    }

    // 対象のグループの範囲を取得
    const range = this.getRangeOfGroup(groups, group);

    // ひとつ内側の階層に、対象のグループをすっぽり納められるグループが存在するか検証する
    const groupRanges = this.getGroupRanges(groups).filter(
      x => x.group.level === group.level - 1 && x.from <= range.from && x.to >= range.to
    );

    if (groupRanges.length != 1) {
      // なければ処理終了
      return groups;
    }

    // あれば入れ替え
    const exchangedGroupSettings = this.exchangeGroups(groups, group, groupRanges[0].group);

    // 移動後のグループをさらに移動させる
    groups = this.relocateLevel(exchangedGroupSettings, groupRanges[0].group);
    return groups;
  }

  /**
   * 左右のグループを入れ替える
   */
  private exchangeGroups(groups: GroupSetting[][], left: GroupSetting, right: GroupSetting) {
    const levelNo = groups[0].length;

    const leftRange = this.getRangeOfGroup(groups, left);
    const rightRange = this.getRangeOfGroup(groups, right);

    const leftlevelIndex = levelNo - left.level;
    const rightlevelIndex = levelNo - right.level;

    const exchangedGroupSettings = groups.map((x, i) => {
      // 入れ替え対象のグループと関係のない行はそのまま
      if ((i < rightRange.from && i < leftRange.from) || (i > rightRange.to && leftRange.to)) {
        return x;
      }

      if (i >= rightRange.from && i <= rightRange.to) {
        x[leftlevelIndex] = left;
      } else {
        x[leftlevelIndex] = { level: 0, groupNo: 0 };
      }

      if (i >= leftRange.from && i <= leftRange.to) {
        x[rightlevelIndex] = right;
      } else {
        x[rightlevelIndex] = { level: 0, groupNo: 0 };
      }
      return x;
    });

    return exchangedGroupSettings;
  }

  // #endregion

  // #region グループ解除関連

  /**
   * グループを解除する
   */
  private ungroup(groups: GroupSetting[][], group: GroupSetting) {
    let newGroupSettings = this.fillGroupWithZero(groups, group);

    newGroupSettings = this.fillGroupSettings(newGroupSettings);
    // 子グループがないグループがあった場合、右に詰める
    newGroupSettings = this.shiftGroups(newGroupSettings);

    return newGroupSettings;
  }

  /**
   * 指定のグループを0-0にする
   */
  private fillGroupWithZero = (groups: GroupSetting[][], group: GroupSetting) => {
    return groups.map(x => {
      return x.map(y => {
        if (JSON.stringify(y) === JSON.stringify(group)) {
          return { level: 0, groupNo: 0 };
        }
        return y;
      });
    });
  };

  /**
   * 一つ下の階層に子グループがないグループがあった場合、右に詰める
   */
  private shiftGroups(groups: GroupSetting[][]) {
    let isShifted = false;

    this.getGroupRanges(groups).forEach(x => {
      const children = this.getChildGroups(groups, x.group);
      // 階層1以外のグループで、一つ下の階層に子グループがいない場合
      if (x.group.level > 1 && children.length === 0) {
        isShifted = true;
        // 一つ下の階層に同じ範囲を持ったグループを作る
        groups = this.group(groups, x.from, x.to);
        // 同じ範囲を持ったグループが複数できてしまったので、外側を消す
        groups = this.deduplicateLevels(groups);
      }
    });

    if (isShifted) {
      // 詰めた後、さらに詰められる列ができているかもしれないので繰り返す
      groups = this.shiftGroups(groups);
    } else {
      // 全て詰め終わると一番上の階層（一番左の列）が
      // 空になってしまっている場合があるので、その列を消す
      groups = this.deleteEmptyLevel(groups);
      groups = this.fillGroupSettings(groups);
    }
    return groups;
  }

  /**
   * 一つ下の階層の子グループを返す
   */
  private getChildGroups(groups: GroupSetting[][], group: GroupSetting) {
    const childGroups: GroupSetting[] = [];

    // 階層1なら子供はいないので処理終了
    if (group.level <= 1) {
      return childGroups;
    }

    const range = this.getRangeOfGroup(groups, group);
    groups.forEach((row, i) => {
      if (i < range.from || i > range.to) {
        return;
      }
      row.forEach(g => {
        if (g.level === group.level - 1 && childGroups.findIndex(x => JSON.stringify(x) === JSON.stringify(g)) < 0) {
          childGroups.push(g);
        }
      });
    });
    return childGroups;
  }

  /**
   * 同じ形のグループ階層ができてしまったときに、左側をを解除する（0-0にする）
   */
  private deduplicateLevels(groups: GroupSetting[][]) {
    // グループごとの範囲のリストを階層の昇順に並べる
    const ranges = this.getGroupRanges(groups).sort((a, b) => (a.group.level < b.group.level ? -1 : 1));

    const rangeStack: { from: number; to: number }[] = [];
    ranges.forEach(x => {
      if (rangeStack.findIndex(y => y.from === x.from && y.to === x.to) < 0) {
        // 同じ範囲を持ったグループが存在していなかった場合
        rangeStack.push({ from: x.from, to: x.to });
      } else {
        // 存在していた場合、グループを解除
        groups = this.ungroup(groups, x.group);
      }
    });

    return groups;
  }

  /**
   * 一番上の階層が空になってしまう場合があるので、削除する
   */
  private deleteEmptyLevel = (groups: GroupSetting[][]) => {
    // 一番上の階層が空(level=0)でない行がなかった場合
    if (
      groups.findIndex(x => {
        return x[0] != undefined && x[0].level > 0;
      }) < 0
    ) {
      groups.forEach(x => x.splice(0, 1));
    }
    return groups;
  };

  /**
   * 行の削除により要素が1つになってしまったグループを削除する
   */
  private ungroupSingleGroup(groups: GroupSetting[][]) {
    this.getGroupRanges(groups).forEach(x => {
      if (x.from === x.to) {
        groups = this.ungroup(groups, x.group);
      }
    });

    return groups;
  }

  // #endregion

  // #region グループ関連共通

  /**
   * グループ設定二次元配列の穴埋めをする
   */
  private fillGroupSettings(groups: GroupSetting[][]) {
    const filledGroupSettings = groups.map((x, i) => {
      return x.map((y, j) => {
        return this.getParentGroup(groups, i, j);
      });
    });
    return filledGroupSettings;
  }

  /**
   * グループ設定二次元配列の穴埋めに際し、どのグループNoを採用するかを返す
   * @param groups 対象のグループ設定二次元配列
   * @param row 対象の行
   * @param levelIndex 対象の階層のインデックス
   */
  private getParentGroup(groups: GroupSetting[][], row: number, levelIndex: number) {
    const level = groups[0].length - levelIndex;
    let groupSetting: GroupSetting;
    if (groups[row][levelIndex].level === level) {
      groupSetting = groups[row][levelIndex];
    } else if (levelIndex <= 0) {
      groupSetting = { level: 0, groupNo: 0 };
    } else {
      groupSetting = this.getParentGroup(groups, row, levelIndex - 1);
    }
    return groupSetting;
  }

  /**
   * グループの先頭行と最後行のインデックスを返す
   * @param groups グループ設定二次元配列
   * @param group 検索対象のグループ
   */
  private getRangeOfGroup(groups: GroupSetting[][], group: GroupSetting) {
    const from = groups.reduce((index: number, x: GroupSetting[], i: number) => {
      const filtered = x.filter(y => JSON.stringify(y) === JSON.stringify(group));
      if (filtered.length > 0 && i < index) {
        return i;
      } else {
        return index;
      }
    }, groups.length);
    const to = groups.reduce((index: number, x: GroupSetting[], i: number) => {
      const filtered = x.filter(y => JSON.stringify(y) === JSON.stringify(group));
      if (filtered.length > 0 && i > index) {
        return i;
      } else {
        return index;
      }
    }, 0);

    return {
      group: group,
      from: from,
      to: to
    };
  }

  /**
   * グループ設定二次元配列内のグループの位置をfrom~toのリストにして返す
   * @param groups グループ設定二次元配列
   */
  private getGroupRanges(groups: GroupSetting[][]) {
    const groupRanges: GroupRange[] = [];
    groups.forEach(x => {
      x.forEach(y => {
        if (y.level === 0) {
          return;
        }
        const range = this.getRangeOfGroup(groups, y);
        if (groupRanges.findIndex(z => JSON.stringify(z) === JSON.stringify(range)) < 0) {
          groupRanges.push(range);
        }
      });
    });
    return groupRanges;
  }

  // 条件項目選択
  public handleOnClose() {
    this.setState({ jItemActivated: false });
  }

  public handelOnSelectClick(selectedItem: RuleJItemVO) {
    if (this.state.grid != undefined) {
      const ruleJoken = this.state.sources.items[this.state.grid.selection.row].ruleJoken;
      ruleJoken.JItemNo = selectedItem.JItemNo;
      ruleJoken.JItemName = selectedItem.JItemName;
      ruleJoken.JItemTypeKbn = selectedItem.JItemTypeKbn;
      ruleJoken.JokenKbn = undefined;
      ruleJoken.Value = '';
      ruleJoken.CommaFlg = selectedItem.CommaFlg;
      setTimeout(() => {
        this.state.grid!.focus();
      });
      this.handleOnClose();
    }
  }

  public shouldComponentUpdate(nextProps: CheckRuleProps, nextState: CheckRuleState) {
    if (this.props.ruleJokens !== nextProps.ruleJokens || this.props.groupNos !== nextProps.groupNos) {
      // 親で表示データが変更された場合はステータスの表示データを更新させる
      const { sources, groups } = this.convertIntoData(nextProps.ruleJokens, nextProps.groupNos);
      this.setState({
        sources: new wj.CollectionView(sources),
        groupSettings: groups
      });
      return false;
    }
    return true;
  }

  public render() {
    const { disabled, gridRef } = this.props;
    /**
     * 条件用のデータマップ。項目によって表示内容を変える
     */
    const condiDataMap = createWjDataMap(CondiStringEnum.concat(CondiNumberEnum).concat(CondiDateEnum));
    condiDataMap.getDisplayValues = dataItem => {
      const type = this.state.sources.currentItem.ruleJoken.JItemTypeKbn;
      switch (type) {
        case ItemTypeEnum.Text: //テキスト項目
          return CondiStringEnum.map(e => {
            return e.value;
          });
        case ItemTypeEnum.Number: //数値項目
          return CondiNumberEnum.map(e => {
            return e.value;
          });
        case ItemTypeEnum.Date: //日付項目
          return CondiDateEnum.map(e => {
            return e.value;
          });
        default:
          return [];
      }
    };
    return (
      <div>
        <FlexGrid
          itemsSource={this.state.sources}
          headersVisibility={wjGrid.HeadersVisibility.Column}
          initialized={this.initializedGrid}
          itemFormatter={this.itemFormatter}
          pasting={this.pasting}
          cellEditEnding={this.handleCellEditEnding}
          cellEditEnded={this.handleCellEditEnded}
          allowResizing={wjGrid.AllowResizing.None}
          allowDragging={wjGrid.AllowDragging.None}
          allowSorting={false}
          keyActionTab={wjGrid.KeyAction.CycleOut}
          selectionMode={wjGrid.SelectionMode.Cell}
          isReadOnly={disabled}
          imeEnabled={true}
          ref={gridRef}
        >
          <FlexGridColumn
            header={labels.AIKADT001000002_GRID_HEADER_ADD}
            name='plusButton'
            width={30}
            align='center'
            isReadOnly={true}
          />
          <FlexGridColumn
            header={labels.AIKADT001000002_GRID_HEADER_DELETE}
            name='minusButton'
            width={30}
            isReadOnly={true}
          />
          {this.layoutGrouping()}
          <FlexGridColumn
            header={labels.AIKADT001000002_GRID_HEADER_GROUP}
            name='groupingCheck'
            binding='groupingCheck'
            width={30}
          />
          <FlexGridColumn
            header={labels.AIKADT001000002_GRID_HEADER_ANDOR}
            name='andOr'
            binding='ruleJoken.AndOr'
            width={80}
            isReadOnly={false}
            dataMap={createWjDataMap(AndOrEnum)}
          />
          <FlexGridColumn
            header={labels.AIKADT001000002_GRID_HEADER_ITEM}
            name='item'
            binding='ruleJoken.JItemName'
            width={160}
            isReadOnly={true}
          />
          <FlexGridColumn
            header={labels.AIKADT001000002_GRID_HEADER_CONDITION}
            name='condition'
            binding='ruleJoken.JokenKbn'
            width={100}
            isReadOnly={false}
            dataMap={condiDataMap}
          />
          <FlexGridColumn
            header={labels.AIKADT001000002_GRID_HEADER_VALUE}
            name='value'
            binding='ruleJoken.Value'
            minWidth={100}
            width='*'
            isReadOnly={false}
          />
        </FlexGrid>
        <Button
          color='primary'
          className='mt-1 mr-3 CheckRuleSummarySetting-btn'
          onClick={this.handleConditionAdditionClick}
          disabled={disabled}
        >
          {labels.AIKADT001000002_BUTTON_FUNCTION_CONDITIONADDITION}
        </Button>
        <Button
          color='primary'
          className='mt-1 CheckRuleSummarySetting-btn'
          onClick={this.handleGroupingClick}
          disabled={!this.state.groupable}
        >
          {labels.AIKADT001000002_BUTTON_FUNCTION_GROUP}
        </Button>
        <span className='pl-4'>
          <span className='text-danger'>{this.state.errorText}</span>
        </span>
        <JournalLineJItem
          activated={this.state.jItemActivated}
          initialSelectedJItemNo={this.state.jItemInitialSelectedJItemNo}
          onClose={this.handleOnClose}
          onSelectClick={this.handelOnSelectClick}
        />
      </div>
    );
  }
}

export default CheckRule;
