import {AuditResultVO} from "./auditResultVO";
/**
 * 監査結果管理ViewModel ViewModel クラス
 */

export class AuditResultViewModel {
    /** 対象月 */
    public TMonth?:string;

    /** 科目名称 */
    public KmkName?:string;

    /** 監査結果VO */
    public Results?:Array<AuditResultVO>;

    /** 件数 */
    public Count?:number;

}
