import React from 'react';
import store from 'store2';

import * as wj from 'wijmo/wijmo';
import * as wjGrid from 'wijmo/wijmo.grid';
import * as wjFilter from 'wijmo/wijmo.grid.filter';
import { FlexGrid } from 'wijmo/wijmo.react.grid';
import { FlexGridFilter } from 'wijmo/wijmo.react.grid.filter';
import { SortDescription } from 'wijmo/wijmo';

import { JournalLineRuleItemVO } from '../../../../models/journalLineRuleItemVO';
import { ChecklistItemVO } from '../../../../models/checklistItemVO';
import { RuleJItemVO } from '../../../../models/ruleJItemVO';
import { convertItemsSourceToDateType } from '../../../../utils/wijmoGridUtils';

import cloneSvg from '../../../../images/icon/14-1_clone.svg';
import trashSvg from '../../../../images/icon/15-1_trash.svg';

type CommonGridVO = JournalLineRuleItemVO & ChecklistItemVO & RuleJItemVO & any;

export type headerMerge = {
  header: string;
  binding: string[];
};
export type sortLabelMap = {
  key: any;
  value: string;
};
export type sortLabel = {
  binding: string;
  map: sortLabelMap[];
};

export type CommonGridProp = {
  columns: any[];
  sources: CommonGridVO[];
  headerMerges?: headerMerge[];
  frozenColumns?: number;
  sortLabelMapList?: sortLabel[];
  tabIndex?: number;
  innerRef?: React.RefObject<any>;
  gridRef?: React.RefObject<any>;
  gridClassName?: string;
  onCopyClick?: (item: CommonGridVO) => void;
  onDeleteClick?: (item: CommonGridVO) => void;
  onGridDoubleClick?: (item: CommonGridVO) => void;
  onValidFlagClick?: (item: CommonGridVO) => void;
  onFilterChanged?: (filterJson: string) => void;
  initializedGrid?: (flexGrid: wjGrid.FlexGrid) => void;
  selectionGridCell?: (flexGrid: wjGrid.FlexGrid) => void;
};
type CommonGridState = {
  tooltip: wj.Tooltip;
};

const iconClass = 'cursor-pointer d-inline-block w-90 h-90';
const htmlCopyIcon = () =>
  `<span title="コピー" class="bg-svg-icon ${iconClass}" style="background-image: url(${cloneSvg})">　</span>`;
const htmlDeleteIcon = () =>
  `<span title="削除" class="bg-svg-icon ${iconClass}" style="background-image: url(${trashSvg})">　</span>`;

// フィルタ情報のテンポラリー
const filterDefinitionStoreKey = 'filter-CommonGrid';

// ソート条件のテンポラリー
const sortCriteriaStoreKey = 'sortCriteria-CommonGrid';
// ソート条件のテンポラリー
export type sortCriteriaStore = {
  property?: string;
  ascending?: boolean;
};

class CommonGrid extends React.Component<CommonGridProp, CommonGridState> {
  grid?: wjGrid.FlexGrid;
  filter?: wjFilter.FlexGridFilter;
  isChangeFilterAndSort = false;

  constructor(props: CommonGridProp) {
    super(props);
    this.state = {
      tooltip: new wj.Tooltip()
    };

    this.initializedFilter = this.initializedFilter.bind(this);
    this.handleFilterApplied = this.handleFilterApplied.bind(this);
    this.initializedGrid = this.initializedGrid.bind(this);
    this.handleItemFormatter = this.handleItemFormatter.bind(this);
    this.handleCellEditEnded = this.handleCellEditEnded.bind(this);
    this.setFilterAndSortFromStore = this.setFilterAndSortFromStore.bind(this);
  }

  // フィルターの初期化
  public initializedFilter = (flexFilter: wjFilter.FlexGridFilter) => {
    this.filter = flexFilter;
  };

  // フィルターが変更された時の処理
  public handleFilterApplied = (f: wjFilter.FlexGridFilter) => {
    if (typeof this.props.onFilterChanged === 'function') {
      this.props.onFilterChanged(f.filterDefinition);
    }
  };

  // Gridの初期化
  public initializedGrid = (flexGrid: wjGrid.FlexGrid) => {
    this.grid = flexGrid;
    const { headerMerges } = this.props;

    if (flexGrid.columnHeaders.rows.length > 0) {
      flexGrid.columnHeaders.rows[0].allowMerging = true;
    }

    if (headerMerges && headerMerges.length > 0) {
      const extraRow = new wjGrid.Row();
      extraRow.allowMerging = true;

      const panel = flexGrid.columnHeaders;
      panel.rows.splice(0, 0, extraRow);

      const columns = flexGrid.columns;
      columns.forEach(col => {
        let headerLbl = col.header;

        headerMerges.forEach(item => {
          const binding = item.binding.filter(binding => binding === col.binding);
          if (binding.length > 0) {
            headerLbl = item.header;
          }
        });
        panel.setCellData(0, col.index, headerLbl);
      });
    }

    if (typeof this.props.initializedGrid === 'function') {
      this.props.initializedGrid(flexGrid);
    }
  };

  // ItemFormatter
  public handleItemFormatter = (panel: wjGrid.GridPanel, row: number, col: number, cell: HTMLElement) => {
    if (panel.cellType === wjGrid.CellType.ColumnHeader) {
      // ヘッダ
      cell.classList.add('d-flex');
      cell.classList.add('justify-content-center');
      cell.classList.add('align-items-center');
      cell.classList.add('font-weight-normal');
    } else if (panel.cellType === wjGrid.CellType.Cell) {
      const item: CommonGridVO = panel.rows[row].dataItem;
      const column: wjGrid.Column = panel.columns[col];

      if ('button,validFlag'.split(',').indexOf(column.inputType) < 0) {
        if (typeof this.props.onGridDoubleClick === 'function') {
          cell.ondblclick = evt => this.props.onGridDoubleClick!(item);
        }
      }

      if (column.inputType === 'tooltip') {
        this.state.tooltip.setTooltip(cell, panel.grid.getCellData(row, col, false));
      }
      if (column.inputType === 'button') {
        cell.classList.add('p-1');
        switch (column.name) {
          case 'CopyIcon':
            cell.innerHTML = htmlCopyIcon();
            if (typeof this.props.onCopyClick === 'function') {
              cell.onclick = evt => this.props.onCopyClick!(item);
            }
            break;
          case 'DeleteIcon':
            if (item.DeletableFlg === true) {
              cell.innerHTML = htmlDeleteIcon();
              if (typeof this.props.onDeleteClick === 'function') {
                cell.onclick = evt => this.props.onDeleteClick!(item);
              }
            } else {
              if (typeof this.props.onDeleteClick === 'function') {
                cell.onclick = evt => null;
              }
            }
            break;
        }
      }
    }
  };

  // セルが変更された時の処理
  public handleCellEditEnded = (sender: wjGrid.FlexGrid, cellRangeEventArgs: wjGrid.CellRangeEventArgs) => {
    if (sender.columns[cellRangeEventArgs.col].inputType === 'validFlag') {
      if (typeof this.props.onValidFlagClick === 'function') {
        const item: CommonGridVO = (sender.collectionView as wj.CollectionView).currentEditItem;
        if (item.NCode != undefined && item.DataTypeKbn != undefined && item.YukoFlg != undefined) {
          this.props.onValidFlagClick(item);
        }
      }
    }
  };

  private filterSetting() {
    if (this.filter != null) {
      if (this.props.sortLabelMapList != null) {
        this.props.sortLabelMapList.forEach(item => {
          this.filter!.getColumnFilter(item.binding).dataMap = new wjGrid.DataMap(item.map, 'key', 'value');
        });
        this.filter.apply();
      }
    }
  }

  // storeよりフィルターとソートを設定する
  private setFilterAndSortFromStore() {
    if (this.filter != null && this.grid != null) {
      if (this.isChangeFilterAndSort === true) {
        this.isChangeFilterAndSort = false;
        const filterDefinition = getFilterDefinition();
        if (filterDefinition) {
          removeFilterDefinition();

          // フィルターを設定
          this.filter.filterDefinition = filterDefinition;
          this.filter.apply();
        }

        const sortCriteriaStore = getSortCriteria();
        if (sortCriteriaStore) {
          removeSortCriteria();
          if (sortCriteriaStore.property != undefined && sortCriteriaStore.ascending != undefined) {
            var view = this.grid.collectionView;
            // ソートを設定
            view.deferUpdate(() => {
              view.sortDescriptions.clear();
              view.sortDescriptions.push(
                new SortDescription(sortCriteriaStore.property!, sortCriteriaStore.ascending!)
              );
            });
          }
        }

        // 選択セルを設定
        setTimeout(() => {
          if (typeof this.props.selectionGridCell === 'function') {
            this.props.selectionGridCell(this.grid!);
          } else {
            this.grid!.selection = new wjGrid.CellRange(0, 0);
          }
        });
      }
    }
  }

  public componentDidMount() {
    this.filterSetting();
  }

  public shouldComponentUpdate(nextProps: CommonGridProp, nextState: CommonGridState) {
    if (this.props.sources !== nextProps.sources) {
      // sourcesが変更された場合にフィルター、ソート情報を設定する
      this.isChangeFilterAndSort = true;
    }
    return true;
    // return false;
  }

  public componentDidUpdate() {
    this.filterSetting();

    // フィルタとソートを設定
    this.setFilterAndSortFromStore();
  }

  public render() {
    const { columns, sources, tabIndex, gridRef, innerRef, gridClassName } = this.props;
    convertItemsSourceToDateType(columns, sources);
    const frozenColumns = this.props.frozenColumns == null ? 0 : this.props.frozenColumns;
    return (
      <span ref={innerRef}>
        <FlexGrid
          className={gridClassName}
          autoGenerateColumns={false}
          columns={columns}
          allowMerging={wjGrid.AllowMerging.ColumnHeaders}
          allowResizing={wjGrid.AllowResizing.None}
          allowDragging={wjGrid.AllowDragging.None}
          selectionMode={wjGrid.SelectionMode.Row}
          headersVisibility={wjGrid.HeadersVisibility.Column}
          alternatingRowStep={0}
          frozenColumns={frozenColumns}
          cloneFrozenCells={false}
          itemsSource={sources}
          initialized={this.initializedGrid}
          itemFormatter={this.handleItemFormatter}
          cellEditEnded={this.handleCellEditEnded}
          tabIndex={tabIndex}
          ref={gridRef}
        >
          <FlexGridFilter initialized={this.initializedFilter} filterApplied={this.handleFilterApplied} />
        </FlexGrid>
      </span>
    );
  }
}

/**
 * フィルタ情報をストアに設定します。
 * @param filterDefinition フィルタ情報
 */
export const setFilterDefinition = (filterDefinition: string) => {
  store.session.set(filterDefinitionStoreKey, filterDefinition);
};

/**
 * ストアからフィルタ情報を取得します。
 */
export const getFilterDefinition = () => {
  return store.session.has(filterDefinitionStoreKey)
    ? (store.session.get(filterDefinitionStoreKey) as string)
    : undefined;
};

/**
 * ストアからフィルタ情報を削除します。
 */
export const removeFilterDefinition = () => {
  if (store.session.has(filterDefinitionStoreKey)) {
    store.session.remove(filterDefinitionStoreKey);
  }
};

/**
 * ソート条件をストアに設定します。
 * @param sortCriteria ソート条件
 */
export const setSortCriteria = (sortCriteria: sortCriteriaStore) => {
  store.session.set(sortCriteriaStoreKey, sortCriteria);
};

/**
 * ストアからソート条件を取得します。
 */
export const getSortCriteria = () => {
  return store.session.has(sortCriteriaStoreKey)
    ? (store.session.get(sortCriteriaStoreKey) as sortCriteriaStore)
    : undefined;
};

/**
 * ストアからソート条件を削除します。
 */
export const removeSortCriteria = () => {
  if (store.session.has(sortCriteriaStoreKey)) {
    store.session.remove(sortCriteriaStoreKey);
  }
};

export default CommonGrid;
