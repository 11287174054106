import { MjsEventArgs } from "../event/eventData";

export interface MjsEventHandler<T extends MjsEventArgs> {
    (sender: any, e: T): void;
}

/**
 * イベント情報のコンテナクラス
 */
export class MjsEventContainer<T extends MjsEventArgs> {
    private _hander: MjsEventHandler<T>;
    private _self: any;

    constructor(hander: MjsEventHandler<T>, self: any) {
        this._hander = hander;
        this._self = self;
    }

    get handler(): MjsEventHandler<T> {
        return this._hander;
    }

    get self(): any {
        return this._self;
    }

    public invoke(sender: any, e: T) {
        this._hander.call(this.self, sender, e);
    }
}
