import React from 'react';
import Axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import decode from 'html-entities-decode';

import * as labels from '../../../constants/labels';
import newsSetting from '../../../newsSetting.json';
import { AppInformationViewModel } from '../../../models/appInformationViewModel';
import logoImg from '../../../images/logo_aikansa_R.png';

export const newsURL = newsSetting.URL;
const initializationViewModel: AppInformationViewModel = {
  Message: undefined
};
const defaultNewsTmp = (msg: string) => <div className='text-center'>{msg}</div>;

type AppInformationProps = {
  isSideMenuOpen?: boolean;
  openMaintenaceClick: () => void;
};

const AppInformation: React.FC<AppInformationProps> = props => {
  const { openMaintenaceClick } = props;
  const [viewModel, setViewModel] = React.useState<AppInformationViewModel>();
  /** お知らせ取得処理 */
  const newsOnGet = (url: string) => {
    Axios.get<AppInformationViewModel>(url)
      .then(response => {
        setViewModel(response.data);
      })
      .catch(error => {
        setViewModel(initializationViewModel);
      });
  };

  React.useEffect(() => {
    newsOnGet(newsURL);
  }, [newsURL]);

  const getNews = (vm?: AppInformationViewModel) => {
    let ret;
    if (vm === undefined) {
      ret = '';
    } else {
      if (vm.Message != null) {
        const html = ReactHtmlParser(decode(vm.Message));
        html.forEach(item => {
          if (item != null && item.type === 'html') {
            item.props.children.forEach((element: typeof item) => {
              if (element != null && element.type === 'body') {
                ret = element.props.children;
              }
            });
          }
        });
      }
    }
    return typeof ret === 'undefined' ? defaultNewsTmp(newsSetting.busyMessage) : ret;
  };

  const mainClassName = props.isSideMenuOpen === true ? 'side-menu-open' : '';
  return (
    <div className={'app-infomation vw-100 p-3 '}>
      <div className={mainClassName}>
        <div className='d-flex justify-content-center'>
          <img src={logoImg} className='-logo position-absolute' />
          <div className='-label h3 text-center border border-top-0 border-right-0 border-left-0 '>
            {labels.AIKCMN001000001_FUNCTION_NAME_NEWS}
          </div>
          <div className='app-infomation-margin-left app-maintenance-buttton' onClick={openMaintenaceClick}>{labels.AIKCMN001000001_MAINTENANCE_OBSTACLE_NEWS}</div>
        </div>
        <div className='-infomation mt-3 p-2 border white-space-pre-wrap overflow-auto'>{getNews(viewModel)}</div>
      </div>
    </div>
  );
};
export default AppInformation;
