import React from 'react';
import * as wjGrid from 'wijmo/wijmo.grid';
import * as wjFilter from 'wijmo/wijmo.grid.filter';
import { FlexGrid } from 'wijmo/wijmo.react.grid';
import { FlexGridFilter } from 'wijmo/wijmo.react.grid.filter';

import * as labels from '../../../../constants/labels';
import { AuditResultJournalLineSumVO } from '../../../../models/auditResultJournalLineSumVO';
import { convertItemsSourceToDateType } from '../../../../utils/wijmoGridUtils';
import moment from 'moment';

export type AuditResultJournalLineSumGridProp = {
  sources: AuditResultJournalLineSumVO[];
  execDate: Date;
  gridClassName?: string;
  gridTabIndex?: number;
  gridRef?: React.RefObject<any>;
  innerRef?: React.RefObject<any>;
  onFilterChanged?: (filterJson: string) => void;
};

type AuditResultJournalLineSumGridState = {};

class AuditResultJournalLineSumGrid extends React.Component<
  AuditResultJournalLineSumGridProp,
  AuditResultJournalLineSumGridState
> {
  grid?: wjGrid.FlexGrid;
  filter?: wjFilter.FlexGridFilter;

  constructor(props: AuditResultJournalLineSumGridProp) {
    super(props);

    this.initializedFilter = this.initializedFilter.bind(this);
    this.handleFilterApplied = this.handleFilterApplied.bind(this);
    this.initializedGrid = this.initializedGrid.bind(this);
    this.handleItemFormatter = this.handleItemFormatter.bind(this);
  }

  // フィルターの初期化
  public initializedFilter = (flexFilter: wjFilter.FlexGridFilter) => {
    this.filter = flexFilter;
  };

  // フィルターが変更された時の処理
  public handleFilterApplied = (f: wjFilter.FlexGridFilter) => {
    if (typeof this.props.onFilterChanged === 'function') {
      this.props.onFilterChanged(f.filterDefinition);
    }
  };

  // Gridの初期化
  public initializedGrid = (flexGrid: wjGrid.FlexGrid) => {
    this.grid = flexGrid;

    if (headerMerges && headerMerges.length > 0) {
      // 2段ヘッダー
      const extraRow = new wjGrid.Row();
      extraRow.allowMerging = true;

      const panel = flexGrid.columnHeaders;
      panel.rows.splice(0, 0, extraRow);

      const columns = flexGrid.columns;
      columns.forEach(col => {
        let headerLbl = col.header;

        headerMerges.forEach(item => {
          const binding = item.binding.filter(binding => binding === col.binding);
          if (binding.length > 0) {
            headerLbl = item.header;
          }
        });
        panel.setCellData(0, col.index, headerLbl);
      });
    }
  };

  // ItemFormatter
  public handleItemFormatter = (panel: wjGrid.GridPanel, row: number, col: number, cell: HTMLElement) => {
    if (panel.cellType === wjGrid.CellType.ColumnHeader) {
      // ヘッダ
      cell.classList.add('d-flex');
      cell.classList.add('justify-content-center');
      cell.classList.add('align-items-center');
      cell.classList.add('font-weight-normal');

      if (row === 1) {
        const column: wjGrid.Column = panel.columns[col];
        if (column.binding === 'AdmittedCnt') {
          cell.classList.add('text-Admitted');
        } else if (column.binding === 'CompleteCnt') {
          cell.classList.add('text-Complete');
        } else if (column.binding === 'PendingCnt') {
          cell.classList.add('text-Pending');
        }
      }
    } else if (panel.cellType === wjGrid.CellType.Cell) {
      const column: wjGrid.Column = panel.columns[col];

      if (column.binding.match('Cnt')) {
        cell.innerHTML = [panel.getCellData(row, col, false), labels.COMMON_FUNCTION_GRID_TOTALCOUNT].join(' ');
      }
    }
  };

  public render() {
    const { sources, execDate, gridClassName, gridTabIndex, gridRef, innerRef } = this.props;
    convertItemsSourceToDateType(columns, sources);

    const dispExecDate = [
      labels.AIKADT002000009_FUNCTION_GRID_LASTEXECUTIONTIME,
      moment(execDate)
        .format('YYYY/MM/DD HH:mm:ss')
        .toString()
    ].join(' ');

    return (
      <span ref={innerRef}>
        <div className='d-flex'>
          <div className='ml-auto'>{dispExecDate}</div>
        </div>
        <FlexGrid
          className={gridClassName}
          autoGenerateColumns={false}
          columns={columns}
          allowMerging={wjGrid.AllowMerging.ColumnHeaders}
          allowResizing={wjGrid.AllowResizing.None}
          allowDragging={wjGrid.AllowDragging.None}
          selectionMode={wjGrid.SelectionMode.Row}
          headersVisibility={wjGrid.HeadersVisibility.Column}
          alternatingRowStep={0}
          itemsSource={sources}
          initialized={this.initializedGrid}
          itemFormatter={this.handleItemFormatter}
          tabIndex={gridTabIndex}
          ref={gridRef}
        >
          <FlexGridFilter initialized={this.initializedFilter} filterApplied={this.handleFilterApplied} />
        </FlexGrid>
      </span>
    );
  }
}

const columns = [
  {
    header: labels.AIKADT002000009_GRID_HEADER_SEQUENCE,
    binding: 'No',
    width: 60,
    align: 'center',
    format: 'd',
    allowMerging: true
  },
  {
    header: labels.AIKADT002000009_GRID_HEADER_JOURNALIZATIONITEMRULE,
    binding: 'RuleName',
    width: '*',
    minWidth: 300,
    allowMerging: true
  },
  { header: labels.AIKADT002000009_GRID_HEADER_UNCONFIRMED, binding: 'AuditableCnt', width: 80, align: 'right' },
  { header: labels.AIKADT002000009_GRID_HEADER_CONFIRMATIONOK, binding: 'CompleteCnt', width: 80, align: 'right' },
  { header: labels.AIKADT002000009_GRID_HEADER_CONFIRMATIONNG, binding: 'AdmittedCnt', width: 80, align: 'right' },
  { header: labels.AIKADT002000009_GRID_HEADER_PENDING, binding: 'PendingCnt', width: 80, align: 'right' },
  {
    header: labels.AIKADT002000009_GRID_HEADER_TOTAL,
    binding: 'SumCnt',
    width: 80,
    align: 'right',
    allowMerging: true
  }
];

const headerMerges = [
  {
    header: labels.AIKADT002000009_GRID_HEADER_STATUS,
    binding: ['AuditableCnt', 'CompleteCnt', 'AdmittedCnt', 'PendingCnt']
  }
];

export default AuditResultJournalLineSumGrid;
