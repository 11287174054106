import React from 'react';
import { ModalHeader, ModalBody } from 'reactstrap';
import IconButton from '../CMN/IconButton/IconButton';
import Close from '../../atoms/Icon/Close';

type menuModalProps = React.HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  title: string;
  onClose: () => void;
};

const MenuPopup: React.FC<menuModalProps> = props => {
  const { isOpen, title, onClose } = props;
  const closeButton = (
    <IconButton className='text-white' icon={<Close className='icon-lg' />} onClick={onClose} tabIndex={111} />
  );
  return (
    <div className='menu-modal position-fixed' aria-hidden={!isOpen}>
      <div className='modal-content'>
        <ModalHeader className='bg-tertiary text-white d-flex align-items-center py-1' close={closeButton}>
          {title}
        </ModalHeader>
        <ModalBody>{props.children}</ModalBody>
      </div>
    </div>
  );
};

export default MenuPopup;
