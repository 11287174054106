import React from 'react';
import classnames from 'classnames';

const AngleCircleFrameLine: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
  const { className, children } = props;
  return (
    <div {...props} className={classnames(className, 'border rounded-lg')}>
      {children}
    </div>
  );
};

export default AngleCircleFrameLine;
