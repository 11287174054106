import React from 'react';

import { Card, CardImg, CardText } from 'reactstrap';
import { Nav, NavItem, NavLink } from 'reactstrap';

import CompanyCreatIcon from '../../../images/vkz/ico_menu_company_make.png';
import CompanyDelIcon from '../../../images/vkz/ico_menu_company_delete.png';
import TantoManagementIcon from '../../../images/vkz/ico_menu_tantousya_touroku.png';
import TantoByCompanyIcon from '../../../images/vkz/ico_menu_staff_setting.png';
import SystemHistoryIcon from '../../../images/vkz/ico_menu_system_data.png';

type ISettingMenuProps = {
    onTantoManagementClick: () => void;
    onTantoByCompanyClick: () => void;
    onSystemHistoryClick: () => void;
    onCreateCompanyDataClick: () => void;
    onDeleteCompanyDataClick: () => void;
};

const headerClass = 'h6 text-lbl';
const navLinkClass = 'cursor-pointer p-0 mx-3 my-2';
const lblClass = 'small text-black d-block text-center';
const cardClass = 'border-0 justify-content-center align-items-center _item';
const cardTextClass = 'm-2';

const AuditAdminMenu = (props: ISettingMenuProps) => {
  const companyCreatLbl: React.ReactElement[] = [];
  '会社データ作成'.split('\n').forEach((lbl, i) => {
    companyCreatLbl.push(
      <span className={lblClass} key={'companyCreatLbl' + i}>
        {lbl}
      </span>
    );
  });
  const companyDelLbl: React.ReactElement[] = [];
  '会社データ削除'.split('\n').forEach((lbl, i) => {
    companyDelLbl.push(
      <span className={lblClass} key={'companyDelLbl' + i}>
        {lbl}
      </span>
    );
  });
  const tantoManagementLbl: React.ReactElement[] = [];
  '担当者アカウント登録・変更'.split('\n').forEach((lbl, i) => {
    tantoManagementLbl.push(
      <span className={lblClass} key={'tantoManagementLbl' + i}>
        {lbl}
      </span>
    );
  });
  const tantoByCompanyLbl: React.ReactElement[] = [];
  '会社別担当者設定'.split('\n').forEach((lbl, i) => {
    tantoByCompanyLbl.push(
      <span className={lblClass} key={'tantoByCompanyLbl' + i}>
        {lbl}
      </span>
    );
  });
  const systemHistoryLbl: React.ReactElement[] = [];
  'システム履歴'.split('\n').forEach((lbl, i) => {
    systemHistoryLbl.push(
      <span className={lblClass} key={'systemHistoryLbl' + i}>
        {lbl}
      </span>
    );
  });
  return (
    <div className='app-menu'>
      <section>
        <h2 className={headerClass}>{'データ関係処理'}</h2>
        <Nav>
          <NavItem>
            <NavLink
              className={navLinkClass}
              onClick={props.onCreateCompanyDataClick}
              tabIndex={120}
              href='javascript:void(0);'
            >
              <Card className={cardClass}>
                <CardImg src={CompanyCreatIcon} />
                <CardText className={cardTextClass}>{companyCreatLbl}</CardText>
              </Card>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={navLinkClass}
              onClick={props.onDeleteCompanyDataClick}
              tabIndex={121}
              href='javascript:void(0);'
            >
              <Card className={cardClass}>
                <CardImg src={CompanyDelIcon} />
                <CardText className={cardTextClass}>{companyDelLbl}</CardText>
              </Card>
            </NavLink>
          </NavItem>
        </Nav>
      </section>
      <section>
        <h2 className={headerClass}>{'担当者設定'}</h2>
        <Nav>
          <NavItem>
            <NavLink
              className={navLinkClass}
              onClick={props.onTantoManagementClick}
              tabIndex={122}
              href='javascript:void(0);'
            >
              <Card className={cardClass}>
                <CardImg src={TantoManagementIcon} />
                <CardText className={cardTextClass}>{tantoManagementLbl}</CardText>
              </Card>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={navLinkClass}
              onClick={props.onTantoByCompanyClick}
              tabIndex={123}
              href='javascript:void(0);'
            >
              <Card className={cardClass}>
                <CardImg src={TantoByCompanyIcon} />
                <CardText className={cardTextClass}>{tantoByCompanyLbl}</CardText>
              </Card>
            </NavLink>
          </NavItem>
        </Nav>
      </section>
      <section>
        <h2 className={headerClass}>{'その他情報'}</h2>
        <Nav>
          <NavItem>
            <NavLink
              className={navLinkClass}
              onClick={props.onSystemHistoryClick}
              tabIndex={124}
              href='javascript:void(0);'
            >
              <Card className={cardClass}>
                <CardImg src={SystemHistoryIcon} />
                <CardText className={cardTextClass}>{systemHistoryLbl}</CardText>
              </Card>
            </NavLink>
          </NavItem>
        </Nav>
      </section>
    </div>
  );
};
export default AuditAdminMenu;
