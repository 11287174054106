import React from 'react';
import Axios from 'axios';

import Popup from '../../../../components/molecules/CMN/Popup/Popup';
import Spinner from '../../../../components/atoms/Icon/Spinner';
import { BatStatusEnum } from '../../../../constants/enums';
import { PROGRESSINTERVAL } from '../../../../constants/constant';
import * as Labels from '../../../../constants/labels';
import * as Messages from '../../../../constants/message';
import { setAlertMessage, AlertKbnEnum } from '../../../../stores/AlertMessage';

export const resultStatusUrl = '/api/v1/MainHome/ResultStatus';

type ImportApiProps = {
  activated: boolean;
  onClose: () => void;
};

const ImportApi: React.FC<ImportApiProps> = props => {
  const { activated, onClose } = props;

  const initialOnGet = (url: string) => {
    Axios.get<BatStatusEnum>(url)
      .then(response => {
        const status = response.data;
        if (status == BatStatusEnum.Processing) {
          setTimeout(initialOnGet, PROGRESSINTERVAL, resultStatusUrl);
        } else {
          onClose();
        }
      })
      .catch(error => {
        if (typeof error.response.data !== 'undefined') {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
        }
        onClose();
      });
  };

  const popupRef = React.useRef<any>();

  /** キーボード操作（キーダウン） */
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 9) {
      // イベントをキャンセル
      event.preventDefault();
      event.stopPropagation();
    }
  };

  React.useEffect(() => {
    if (activated) {
      initialOnGet(resultStatusUrl);
      // 画面表示完了後に実行
      setTimeout(() => {
        if (popupRef.current) {
          // ポップアップにキーダウンイベントのフック
          popupRef.current.addEventListener('keydown', handleKeyDown);
        }
      });
    }
  }, [activated]);

  return (
    <Popup
      isOpen={activated}
      escClose={false}
      onCloseClick={onClose}
      size='sm'
      header={Labels.AIKADT005000001_FUNCTION_NAME}
      headerIcon={<div />}
      innerRef={popupRef}
    >
      <div>
        <div className='mb-3 white-space-pre-wrap'>{Messages.ImportApi_Information_Message}</div>
        <div>
          <span className='_spinner _rotation mr-2'>
            <Spinner className='icon-lg' />
          </span>
          {Messages.ImportApi_Information_Importing}
        </div>
      </div>
    </Popup>
  );
};
export default ImportApi;
