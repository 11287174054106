import { MasterDataContainer, MasterDataContainerBase } from "./masterDataContainer";
import { ErrorInfoEventArgs, MjsEventArgs } from "../common/event/eventData";
import { MjsDelegateContainer } from "../common/usefuls/delegate";
import { Logger } from "../common/usefuls/logger";
import { Tuple } from "../common/usefuls/utilities";
import { CacheDataType } from "../core/constants/enums";
import { DbRecord, IndexedDBManager } from "../core/localStorage/indexedDBManager";
import { DataModelBase } from "../core/models/dataModelBase";
import { CSInfoCacheContainer } from "../models/cache/cSInfoCacheContainer";
import { CSInfoCacheViewModel } from "../models/cache/cSInfoCacheViewModel";
import { DTMAINCacheContainer } from "../models/cache/dTMAINCacheContainer";
import { DTMAINCacheViewModel } from "../models/cache/dTMAINCacheViewModel";
import { HojyoKmkInfoCacheContainer } from "../models/cache/hojyoKmkInfoCacheContainer";
import { HojyoKmkInfoCacheViewModel } from "../models/cache/hojyoKmkInfoCacheViewModel";
import { HojyoMACacheViewModel } from "../models/cache/hojyoMACacheViewModel";
import { HojyoMA_BankCacheContainer } from "../models/cache/hojyoMA_BankCacheContainer";
import { HojyoMA_BmnCacheContainer } from "../models/cache/hojyoMA_BmnCacheContainer";
import { HojyoMA_ClientCacheContainer } from "../models/cache/hojyoMA_ClientCacheContainer";
import { HojyoMA_GenHojo1CacheContainer } from "../models/cache/hojyoMA_GenHojo1CacheContainer";
import { HojyoMA_GenHojo2CacheContainer } from "../models/cache/hojyoMA_GenHojo2CacheContainer";
import { HojyoMA_GenHojo3CacheContainer } from "../models/cache/hojyoMA_GenHojo3CacheContainer";
import { HojyoMA_GenHojo4CacheContainer } from "../models/cache/hojyoMA_GenHojo4CacheContainer";
import { HojyoMA_GenHojo5CacheContainer } from "../models/cache/hojyoMA_GenHojo5CacheContainer";
import { HojyoMA_KojiCacheContainer } from "../models/cache/hojyoMA_KojiCacheContainer";
import { HojyoMA_Seg2CacheContainer } from "../models/cache/hojyoMA_Seg2CacheContainer";
import { HojyoMA_Seg3CacheContainer } from "../models/cache/hojyoMA_Seg3CacheContainer";
import { HojyoMA_Seg4CacheContainer } from "../models/cache/hojyoMA_Seg4CacheContainer";
import { HojyoMA_Seg5CacheContainer } from "../models/cache/hojyoMA_Seg5CacheContainer";
import { HojyoMA_SegCacheContainer } from "../models/cache/hojyoMA_SegCacheContainer";
import { HojyoMA_StaffCacheContainer } from "../models/cache/hojyoMA_StaffCacheContainer";
import { KbnInfoCacheContainer } from "../models/cache/kbnInfoCacheContainer";
import { KbnInfoCacheViewModel } from "../models/cache/kbnInfoCacheViewModel";
import { KmkInfoCacheContainer } from "../models/cache/kmkInfoCacheContainer";
import { KmkInfoCacheViewModel } from "../models/cache/kmkInfoCacheViewModel";
import { KmkMACacheViewModel } from "../models/cache/kmkMACacheViewModel";
import { KmkMA_KmkCacheContainer } from "../models/cache/kmkMA_KmkCacheContainer";
import { KmkMA_SubCacheContainer } from "../models/cache/kmkMA_SubCacheContainer";
import { KmkMBCacheContainer } from "../models/cache/kmkMBCacheContainer";
import { KmkMBCacheViewModel } from "../models/cache/kmkMBCacheViewModel";
import { MasterInfoCacheContainer } from "../models/cache/masterInfoCacheContainer";
import { MasterInfoCacheViewModel } from "../models/cache/masterInfoCacheViewModel";
import { SKmkMACacheContainer } from "../models/cache/sKmkMACacheContainer";
import { SKmkMACacheViewModel } from "../models/cache/sKmkMACacheViewModel";
import { SwkFxHeadCacheContainer } from "../models/cache/swkFxHeadCacheContainer";
import { SwkFxHeadCacheViewModel } from "../models/cache/swkFxHeadCacheViewModel";
import { TaxInfoCacheContainer } from "../models/cache/taxInfoCacheContainer";
import { TaxInfoCacheViewModel } from "../models/cache/taxInfoCacheViewModel";
import { TekiMACacheContainer } from "../models/cache/tekiMACacheContainer";
import { TekiMACacheViewModel } from "../models/cache/tekiMACacheViewModel";
import { TekiTreeCacheContainer } from "../models/cache/tekiTreeCacheContainer";
import { TekiTreeCacheViewModel } from "../models/cache/tekiTreeCacheViewModel";
import { UserInfoCacheContainer } from "../models/cache/userInfoCacheContainer";
import { UserInfoCacheViewModel } from "../models/cache/userInfoCacheViewModel";
import { WinDspInfoCacheContainer } from "../models/cache/winDspInfoCacheContainer";
import { WinDspInfoCacheViewModel } from "../models/cache/winDspInfoCacheViewModel";
import { EventManager } from "../support/eventManager";
import { Mjs } from "../mjs";
import { Ajax, AjaxSettings } from "../core/ajax";
import { MjsEventContainer } from "../common/usefuls/event";
import $ from 'jquery';
import "../common/usefuls/utilities";


/**
 * マスターデータを管理するクラス。データのキャッシュ要否判定およびキャッシュアクセス制御も担う。
 */
export class MasterDataManager {
    public static ERROR_IDX_DB_OPEN: number = 101;
    public static ERROR_API_CALL: number = 201;

    private static STORE_NAME_MASTER_DATA: string = "MasterData";
    private static STORE_NAME_MASTER_DATA_VERSION_INFO: string = "MasterDataVersionInfo";

    // イベント管理
    private eventManager: EventManager;

    private innerTargetYear!: number;
    private targetDataTypes: CacheDataType[];
    private idbManager!: IndexedDBManager;
    private masterDataMap!: Array<Tuple<CacheDataType, MasterDataContainerBase>>;

    constructor(targetTypes: CacheDataType[]) {
        this.eventManager = new EventManager();
        this.targetDataTypes = targetTypes;
    }

    /**
     * 管理中の年度を取得する
     */
    public get targetYear(): number { return this.innerTargetYear; }

    public refresh() {
        this.changeYearAsync(Mjs.clientYear);
    }

    /**
     * 管理年度の変更を非同期で実施する。変更の完了はMasterLoadCompleteイベントにて通知される。
     * MasterLoadCompleteイベントの通知が行われた時点より、getDataContainerメソッドにて外部から管理データへアクセスすることができる。
     * @param targetYear : 管理年度
     */
    public changeYearAsync(targetYear: number, fromServer: boolean = false): void {
        this.masterDataMap = new Array<Tuple<CacheDataType, any>>();

        // 各種コールバック待ち受け関数の定義
        var openCompleteCallback: MjsDelegateContainer<boolean, void> = new MjsDelegateContainer<boolean, void>(result => {
            if (result) {
                this.loadFromCacheOrServer(targetYear, loadCompleteCallback, errorCallback);
            } else {
                this.onMasterLoadComplete(this, new ErrorInfoEventArgs(true, MasterDataManager.ERROR_IDX_DB_OPEN, "indexeddb open error."));
            }
        }, this);

        var loadCompleteCallback: MjsDelegateContainer<Array<Tuple<CacheDataType, any>>, void> = new MjsDelegateContainer<Array<Tuple<CacheDataType, any>>, void>(result => {
            this.masterDataMap = result;
            this.innerTargetYear = targetYear;
            this.onMasterLoadComplete(this, new ErrorInfoEventArgs(false, 0, null!));
        }, this);

        var errorCallback: MjsDelegateContainer<string, void> = new MjsDelegateContainer<string, void>(e => {
            this.onMasterLoadComplete(this, new ErrorInfoEventArgs(true, MasterDataManager.ERROR_API_CALL, e));
        }, this);

        if (!fromServer && IndexedDBManager.isIDBSupport && Mjs.isPersonalPc) {
            // ローカルキャッシュから取得
            if (this.idbManager == null) {
                // データストア（テーブル）の用意。マスターデータそのものを格納するストアと、そのバージョン情報を格納するストアの２ストア構成で管理
                this.idbManager = new IndexedDBManager(MasterDataManager.STORE_NAME_MASTER_DATA, MasterDataManager.STORE_NAME_MASTER_DATA_VERSION_INFO);
            }

            this.idbManager.openOrCreate(openCompleteCallback);
        } else {
            // サーバーから取得
            var targetInfoModels: Array<CacheInfoModel> = this.targetDataTypes.select((t: CacheDataType) => {
                var model: CacheInfoModel = new CacheInfoModel();
                model.CacheDataType = t;
                model.TargetYear = targetYear;
                model.Version = null!; // 強制的に取得させるためバージョン情報はnull
                return model;
            });

            this.loadFromServer(targetInfoModels, loadCompleteCallback, errorCallback);
        }
    }

    private loadFromServer(targetInfoModels: Array<CacheInfoModel>, loadCompleteCallback: MjsDelegateContainer<Array<Tuple<CacheDataType, MasterDataContainerBase>>, void>, errorCallback: MjsDelegateContainer<string, void>) {
        var requestModel = new CacheDataRequestModel();
        requestModel.CacheInfoModelList = targetInfoModels;

        var map: Array<Tuple<CacheDataType, any>> = new Array<Tuple<CacheDataType, MasterDataContainerBase>>();
        this.callGetMasterApi(
            requestModel,
            (result: CacheDataResultViewModel) => {

                $.each(result.CacheUpdateInfoModelList, (idx, infoModel) => {
                    var container: MasterDataContainerBase = this.createDataContainer(infoModel.CacheDataType, infoModel.ModelList, infoModel.TargetYear, infoModel.Version);
                    map.push(new Tuple(infoModel.CacheDataType, container));
                });

                loadCompleteCallback.invoke(map);
            },
            (e) => { errorCallback.invoke(e) });
    }

    private loadFromCacheOrServer(targetYear: number, loadCompleteCallback: MjsDelegateContainer<Array<Tuple<CacheDataType, MasterDataContainerBase>>, void>, errorCallback: MjsDelegateContainer<string, void>) {
        Logger.debugTimeStamp("MasterDataManager.loadFromCacheOrServer");
        var map: Array<Tuple<CacheDataType, MasterDataContainerBase>> = new Array<Tuple<CacheDataType, any>>();

        //var getCompleteCallback: MjsDelegateContainer<DbRecord, void> = new MjsDelegateContainer<DbRecord, void>(result => {

        //    var model: CacheInfoModel = new CacheInfoModel();

        //    model.CacheDataType = this.getMasterTypeByKey(result.key);
        //    model.TargetYear = this.getYearByKey(result.key);
        //    model.Version = result.record;
        //    targetInfoModels.push(model);

        //    // 対象マスター分すべてのバージョン取得が完了したかどうかを件数で判定
        //    if (targetInfoModels.length == this.targetDataTypes.length) {

        //        // サーバーへDBへ更新有無の問い合わせ、および更新あり時のデータ取得
        //        var requestModel = new CacheDataRequestModel();
        //        requestModel.CacheInfoModelList = targetInfoModels;
        //        this.callGetMasterApi(requestModel, (result: CacheDataResultViewModel) => {

        //            // 取得結果から、更新バージョンがあったものとなかったもので選り分けておく
        //            var updatedList: Array<CacheUpdateInfoModel> = result.CacheUpdateInfoModelList.filter(e => e.HasUpdate);
        //            var noUpdatedList: Array<CacheUpdateInfoModel> = result.CacheUpdateInfoModelList.filter(e => !e.HasUpdate);

        //            // 更新ありのデータは受け取った結果をそのままメモリキャッシュへ格納（同期的に処理）
        //            $.each(updatedList, (idx, infoModel) => {
        //                // バージョンに更新があった場合はサーバーから取得した内容を採用、かつキャッシュも更新
        //                var container: MasterDataContainerBase = this.createDataContainer(infoModel.CacheDataType, infoModel.ModelList, infoModel.TargetYear, infoModel.Version);
        //                map.push(new Tuple<CacheDataType, MasterDataContainerBase>(infoModel.CacheDataType, container));

        //                this.idbManager.updateRecord(MasterDataManager.STORE_NAME_MASTER_DATA, new DbRecord(this.createKey(infoModel.CacheDataType, targetYear, clientCode), infoModel.ModelList));
        //                this.idbManager.updateRecord(MasterDataManager.STORE_NAME_MASTER_DATA_VERSION_INFO, new DbRecord(this.createKey(infoModel.CacheDataType, targetYear, clientCode), infoModel.Version));
        //            });

        //            // 更新なしのデータはローカルキャッシュから取得してメモリキャッシュへ格納（DBアクセスを伴うため非同期に処理）
        //            $.each(noUpdatedList, (idx, infoModel) => {
        //                this.idbManager.getRecord(MasterDataManager.STORE_NAME_MASTER_DATA, this.createKey(infoModel.CacheDataType, targetYear, clientCode), new MjsDelegateContainer<DbRecord, void>(e => {
        //                    var container: MasterDataContainerBase = this.createDataContainer(infoModel.CacheDataType, e.record, infoModel.TargetYear, infoModel.Version);
        //                    map.push(new Tuple<CacheDataType, MasterDataContainerBase>(infoModel.CacheDataType, container));

        //                    // すべてのデータが揃ったタイミングでロード完了コールバックの通知
        //                    if (map.length == requestModel.CacheInfoModelList.length) {
        //                        loadCompleteCallback.invoke(map);
        //                    }
        //                }, this));
        //            });

        //            if (noUpdatedList.length == 0) {
        //                // 非同期処理がなければロード完了コールバックの通知
        //                loadCompleteCallback.invoke(map);
        //            }
        //        }, (e) => { errorCallback.invoke(e) });
        //    }

        //}, this);

        var getCompleteCallback: MjsDelegateContainer<DbRecord, void> = new MjsDelegateContainer<DbRecord, void>(result => {
            Logger.debugTimeStamp("MasterDataManager.getCompleteCallback IndexedDBからバージョン情報取得");
            var cashBaseDbRecord: DbRecord = result;

            var targetInfoModels = new Array<CacheInfoModel>();
            $.each(this.targetDataTypes, (idx, dataType: CacheDataType) => {
                let model: CacheInfoModel = new CacheInfoModel();
                model.CacheDataType = dataType;
                model.TargetYear = this.getYearByKey(result.key);
                if (result.record) {
                    let data: any = result.record.filter((m: any, index: number): boolean => {
                        return dataType == m.CacheDataType
                    });
                    if (data && data.length > 0) {
                        model.Version = data[0].Version;
                    } else {
                        model.Version = null!;
                    }
                } else {
                    model.Version = null!;
                }
                targetInfoModels.push(model);
            });

            // サーバーへDBへ更新有無の問い合わせ、および更新あり時のデータ取得
            var requestModel = new CacheDataRequestModel();
            requestModel.CacheInfoModelList = targetInfoModels;

            this.callGetMasterApi(requestModel, (result: CacheDataResultViewModel) => {

                // 取得結果から、更新バージョンがあったものとなかったもので選り分けておく
                var updatedList: Array<CacheUpdateInfoModel> = result.CacheUpdateInfoModelList.filter(e => e.HasUpdate);
                var noUpdatedList: Array<CacheUpdateInfoModel> = result.CacheUpdateInfoModelList.filter(e => !e.HasUpdate);
                Logger.debugTimeStamp("callGetMasterApi結果/IndexedDB更新あり" + updatedList.length + "、更新なし" + noUpdatedList.length);

                // 更新ありのデータは受け取った結果をそのままメモリキャッシュへ格納（同期的に処理）
                var updateVirsionInfoModels = new Array<CacheInfoModel>();
                $.each(updatedList, (idx, infoModel) => {
                    // バージョンに更新があった場合はサーバーから取得した内容を採用、かつキャッシュも更新
                    // マスタ情報
                    var container: MasterDataContainerBase = this.createDataContainer(infoModel.CacheDataType, infoModel.ModelList, infoModel.TargetYear, infoModel.Version);
                    map.push(new Tuple<CacheDataType, MasterDataContainerBase>(infoModel.CacheDataType, container));
                    Logger.debugTimeStamp("MasterDataManager.updateRecordIndexedDB更新" + MasterDataManager.STORE_NAME_MASTER_DATA + "." + infoModel.CacheDataType);
                    this.idbManager.updateRecord(MasterDataManager.STORE_NAME_MASTER_DATA, new DbRecord(this.createKey(infoModel.CacheDataType, targetYear, Mjs.clientCode), infoModel.ModelList));

                    // version情報
                    let versionInfoData: CacheInfoModel = new CacheInfoModel();
                    versionInfoData.CacheDataType = infoModel.CacheDataType;
                    versionInfoData.TargetYear = infoModel.TargetYear;
                    versionInfoData.Version = infoModel.Version;
                    updateVirsionInfoModels.push(versionInfoData);
                    // すべてのデータが揃ったタイミングでVirsion情報更新
                    if (updateVirsionInfoModels.length == updatedList.length) {
                        // 既存の情報
                        $.each(cashBaseDbRecord.record, (idx, record: any) => {
                            let data: any = updateVirsionInfoModels.filter((m: any, index: number): boolean => {
                                return record.CacheDataType == m.CacheDataType
                            });
                            if (!data || data.length == 0) {
                                updateVirsionInfoModels.push(record);
                            }
                        });
                        Logger.debugTimeStamp("MasterDataManager.updateRecordIndexedDB更新" + MasterDataManager.STORE_NAME_MASTER_DATA_VERSION_INFO + "." + infoModel.CacheDataType);
                        this.idbManager.updateRecord(MasterDataManager.STORE_NAME_MASTER_DATA_VERSION_INFO, new DbRecord(this.createDKeydataVersionInfo(targetYear, Mjs.clientCode), updateVirsionInfoModels));
                    }
                });

                // 更新なしのデータはローカルキャッシュから取得してメモリキャッシュへ格納（DBアクセスを伴うため非同期に処理）
                $.each(noUpdatedList, (idx, infoModel) => {
                    this.idbManager.getRecord(MasterDataManager.STORE_NAME_MASTER_DATA, this.createKey(infoModel.CacheDataType, targetYear, Mjs.clientCode), new MjsDelegateContainer<DbRecord, void>(e => {
                        var container: MasterDataContainerBase = this.createDataContainer(infoModel.CacheDataType, e.record, infoModel.TargetYear, infoModel.Version);
                        map.push(new Tuple<CacheDataType, MasterDataContainerBase>(infoModel.CacheDataType, container));

                        // すべてのデータが揃ったタイミングでロード完了コールバックの通知
                        if (map.length == requestModel.CacheInfoModelList.length) {
                            loadCompleteCallback.invoke(map);
                        }
                    }, this));
                });

                if (noUpdatedList.length == 0) {
                    // 非同期処理がなければロード完了コールバックの通知
                    loadCompleteCallback.invoke(map);
                }
            }, (e) => { errorCallback.invoke(e) });

        }, this);

        // ストレージキャッシュからバージョン情報を取得
        var targetInfoModels = new Array<CacheInfoModel>();
        //$.each(this.targetDataTypes, (idx, dataType: CacheDataType) => {
        //    this.idbManager.getRecord(MasterDataManager.STORE_NAME_MASTER_DATA_VERSION_INFO, this.createKey(dataType, targetYear, clientCode), getCompleteCallback);
        //});
        if (this.targetDataTypes.length > 0) {
            // キャッシュ対象がある場合
            this.idbManager.getRecord(MasterDataManager.STORE_NAME_MASTER_DATA_VERSION_INFO, this.createDKeydataVersionInfo(targetYear, Mjs.clientCode), getCompleteCallback);
        }

        // キャッシュ対象が無い場合も通信を行う
        // ※給与の場合にインジケータを出すための考慮
        // これをしないと、ヘッダーの年度表示が非常に遅く見えてしまう
        // HACK:ヘッダーの年度表示をサーバー側のレンダリングで行う。ここは臨時対応になるため削除する。
        if (this.targetDataTypes.length == 0) {
            var requestModel = new CacheDataRequestModel();
            requestModel.CacheInfoModelList = targetInfoModels;

            this.callGetMasterApi(requestModel,
                (result: CacheDataResultViewModel) => {
                    loadCompleteCallback.invoke([]);
                }, (e) => { errorCallback.invoke(e) });
        }
    }

    private createDataContainer(dataType: CacheDataType, modelList: Array<JSON>, targetYear: number, version: Date): MasterDataContainerBase {
        var container: MasterDataContainerBase = undefined!;
        switch (dataType) {
            case CacheDataType.MasterInfo:
                container = new MasterInfoCacheContainer(DataModelBase.fillFromJsons<MasterInfoCacheViewModel>(modelList, MasterInfoCacheViewModel), targetYear, version);
                break;
            case CacheDataType.KbnInfo:
                container = new KbnInfoCacheContainer(DataModelBase.fillFromJsons<KbnInfoCacheViewModel>(modelList, KbnInfoCacheViewModel), targetYear, version);
                break;
            case CacheDataType.KmkMA_Kmk:
                container = new KmkMA_KmkCacheContainer(DataModelBase.fillFromJsons<KmkMACacheViewModel>(modelList, KmkMACacheViewModel), targetYear, version);
                break;
            case CacheDataType.KmkMA_Sub:
                container = new KmkMA_SubCacheContainer(DataModelBase.fillFromJsons<KmkMACacheViewModel>(modelList, KmkMACacheViewModel), targetYear, version);
                break;
            case CacheDataType.HojyoMA_Bank:
                container = new HojyoMA_BankCacheContainer(DataModelBase.fillFromJsons<HojyoMACacheViewModel>(modelList, HojyoMACacheViewModel), targetYear, version);
                break;
            case CacheDataType.HojyoMA_Client:
                container = new HojyoMA_ClientCacheContainer(DataModelBase.fillFromJsons<HojyoMACacheViewModel>(modelList, HojyoMACacheViewModel), targetYear, version);
                break;
            case CacheDataType.HojyoMA_Staff:
                container = new HojyoMA_StaffCacheContainer(DataModelBase.fillFromJsons<HojyoMACacheViewModel>(modelList, HojyoMACacheViewModel), targetYear, version);
                break;
            case CacheDataType.HojyoMA_GenHojo1:
                container = new HojyoMA_GenHojo1CacheContainer(DataModelBase.fillFromJsons<HojyoMACacheViewModel>(modelList, HojyoMACacheViewModel), targetYear, version);
                break;
            case CacheDataType.HojyoMA_GenHojo2:
                container = new HojyoMA_GenHojo2CacheContainer(DataModelBase.fillFromJsons<HojyoMACacheViewModel>(modelList, HojyoMACacheViewModel), targetYear, version);
                break;
            case CacheDataType.HojyoMA_GenHojo3:
                container = new HojyoMA_GenHojo3CacheContainer(DataModelBase.fillFromJsons<HojyoMACacheViewModel>(modelList, HojyoMACacheViewModel), targetYear, version);
                break;
            case CacheDataType.HojyoMA_GenHojo4:
                container = new HojyoMA_GenHojo4CacheContainer(DataModelBase.fillFromJsons<HojyoMACacheViewModel>(modelList, HojyoMACacheViewModel), targetYear, version);
                break;
            case CacheDataType.HojyoMA_GenHojo5:
                container = new HojyoMA_GenHojo5CacheContainer(DataModelBase.fillFromJsons<HojyoMACacheViewModel>(modelList, HojyoMACacheViewModel), targetYear, version);
                break;
            case CacheDataType.HojyoMA_Bmn:
                container = new HojyoMA_BmnCacheContainer(DataModelBase.fillFromJsons<HojyoMACacheViewModel>(modelList, HojyoMACacheViewModel), targetYear, version);
                break;
            case CacheDataType.HojyoMA_Seg:
                container = new HojyoMA_SegCacheContainer(DataModelBase.fillFromJsons<HojyoMACacheViewModel>(modelList, HojyoMACacheViewModel), targetYear, version);
                break;
            case CacheDataType.HojyoMA_Koji:
                container = new HojyoMA_KojiCacheContainer(DataModelBase.fillFromJsons<HojyoMACacheViewModel>(modelList, HojyoMACacheViewModel), targetYear, version);
                break;
            case CacheDataType.TekiMA:
                container = new TekiMACacheContainer(DataModelBase.fillFromJsons<TekiMACacheViewModel>(modelList, TekiMACacheViewModel), targetYear, version);
                break;
            case CacheDataType.SwkFxHead:
                container = new SwkFxHeadCacheContainer(DataModelBase.fillFromJsons<SwkFxHeadCacheViewModel>(modelList, SwkFxHeadCacheViewModel), targetYear, version);
                break;
            case CacheDataType.WinDspInfo:
                container = new WinDspInfoCacheContainer(DataModelBase.fillFromJsons<WinDspInfoCacheViewModel>(modelList, WinDspInfoCacheViewModel), targetYear, version);
                break;
            case CacheDataType.SKmkMA:
                container = new SKmkMACacheContainer(DataModelBase.fillFromJsons<SKmkMACacheViewModel>(modelList, SKmkMACacheViewModel), targetYear, version);
                break;
            case CacheDataType.UserInfo:
                container = new UserInfoCacheContainer(DataModelBase.fillFromJsons<UserInfoCacheViewModel>(modelList, UserInfoCacheViewModel), targetYear, version);
                break;
            case CacheDataType.TaxInfo:
                //container = new TaxInfoCacheContainer(DataModelBase.fillFromJsons<TaxInfoCacheViewModel>(modelList, UserInfoCacheViewModel), targetYear, version);
                container = new TaxInfoCacheContainer(DataModelBase.fillFromJsons<TaxInfoCacheViewModel>(modelList, TaxInfoCacheViewModel), targetYear, version);
                break;
            case CacheDataType.TekiTree:
                container = new TekiTreeCacheContainer(DataModelBase.fillFromJsons<TekiTreeCacheViewModel>(modelList, TekiTreeCacheViewModel), targetYear, version);

            case CacheDataType.HojyoMA_Seg2:
                container = new HojyoMA_Seg2CacheContainer(DataModelBase.fillFromJsons<HojyoMACacheViewModel>(modelList, HojyoMACacheViewModel), targetYear, version);
                break;
            case CacheDataType.HojyoMA_Seg3:
                container = new HojyoMA_Seg3CacheContainer(DataModelBase.fillFromJsons<HojyoMACacheViewModel>(modelList, HojyoMACacheViewModel), targetYear, version);
                break;
            case CacheDataType.HojyoMA_Seg4:
                container = new HojyoMA_Seg4CacheContainer(DataModelBase.fillFromJsons<HojyoMACacheViewModel>(modelList, HojyoMACacheViewModel), targetYear, version);
                break;
            case CacheDataType.HojyoMA_Seg5:
                container = new HojyoMA_Seg5CacheContainer(DataModelBase.fillFromJsons<HojyoMACacheViewModel>(modelList, HojyoMACacheViewModel), targetYear, version);
                break;
            case CacheDataType.CSInfo:
                container = new CSInfoCacheContainer(DataModelBase.fillFromJsons<CSInfoCacheViewModel>(modelList, CSInfoCacheViewModel), targetYear, version);
                break;
            case CacheDataType.HojyoKmkInfo:
                container = new HojyoKmkInfoCacheContainer(DataModelBase.fillFromJsons<HojyoKmkInfoCacheViewModel>(modelList, HojyoKmkInfoCacheViewModel), targetYear, version);
                break;
            case CacheDataType.KmkInfo:
                container = new KmkInfoCacheContainer(DataModelBase.fillFromJsons<KmkInfoCacheViewModel>(modelList, KmkInfoCacheViewModel), targetYear, version);
                break;
            case CacheDataType.DTMAIN:
                container = new DTMAINCacheContainer(DataModelBase.fillFromJsons<DTMAINCacheViewModel>(modelList, DTMAINCacheViewModel), targetYear, version);
                break;
            case CacheDataType.KmkMB:
                container = new KmkMBCacheContainer(DataModelBase.fillFromJsons<KmkMBCacheViewModel>(modelList, KmkMBCacheViewModel), targetYear, version);
                break;
            default:
                // Error;
        }
        return container;
    }

    private callGetMasterApi(param: CacheDataRequestModel, success: (result: CacheDataResultViewModel) => void, fail: (error: any) => void) {

        var url = "/api/v1/CacheData/Index";
        let successFunc = (data: any, textStatus: string, jqXHR: JQueryXHR) => {
            success(data);
        }
        let errorFunc = (data: any, jqXHR: JQueryXHR, status: string, errorThrown: string) => {
            //コメント化しました。（原田さんに確認済）
            //var msg = "ajax failed: " + url + " \n[" + status + " " + jqXHR + "]";
            //fail(msg);
        }
        let completeFunc = (jqXHR: JQueryXHR, textStatus: string) => {
        }

        let settings: AjaxSettings = {
            url: url,
            type: "POST",
            data: param,
            showBusyIndicator: false,
            beforeSend: undefined,
            success: successFunc,
            complete: completeFunc,
            error: errorFunc
        }

        Ajax.perform(settings);
    }

    public getDataContainer<TModel extends DataModelBase, TResult extends MasterDataContainer<TModel>>(dataType: CacheDataType): TResult {
        var target = this.masterDataMap.filter(i => i.Item1 == dataType);
        if (target.length == 0) {
            // 取得できないときは呼び出し元の実装バグの可能性が高い
            throw Error("管理配下にないキャッシュコンテナへアクセスしようとしました。'page#targetCacheTypes'の定義内容が矛盾している可能性があります。");
        }
        var container = <TResult>(<MasterDataContainer<TModel>>target[0].Item2);
        return container;
    }

    private static RECORD_KEY_SEPARATOR: string = "*";
    private createKey(dataType: CacheDataType, targetYear: number, clientCode: number): string {
        // マスター種別名と対象年を繋げたものをキーとする
        return CacheDataType[dataType] + MasterDataManager.RECORD_KEY_SEPARATOR + targetYear + MasterDataManager.RECORD_KEY_SEPARATOR + clientCode;
    }
    private createDKeydataVersionInfo(targetYear: number, clientCode: number): string {
        // 対象年を繋げたものをキーとする
        return MasterDataManager.RECORD_KEY_SEPARATOR + targetYear + MasterDataManager.RECORD_KEY_SEPARATOR + clientCode;
    }

    //private getMasterTypeByKey(recordKey: string): CacheDataType {
    //    return CacheDataType[recordKey.split(MasterDataManager.RECORD_KEY_SEPARATOR)[0]];
    //}
    private getYearByKey(recordKey: string): number {
        return parseInt(recordKey.split(MasterDataManager.RECORD_KEY_SEPARATOR)[1]);
    }
    // イベント
    public addMasterLoadCompleteEventHandler(handler: MjsEventContainer<MjsEventArgs>): void {
        this.eventManager.addEventHandler("MasterLoadComplete", handler);
    }

    private onMasterLoadComplete(sender: any, e: ErrorInfoEventArgs): void {
        this.eventManager.fireEvent("MasterLoadComplete", sender, e);
    }
}


export class dataVersionInfo {
    public key!: string;
    public version: any;
}
export class CacheDataRequestModel {
    public CacheInfoModelList!: Array<CacheInfoModel>;
}
export class CacheInfoModel {
    public TargetYear!: number;
    public CacheDataType!: CacheDataType;
    public Version!: Date;
}

export class CacheDataResultViewModel {
    public CacheUpdateInfoModelList!: Array<CacheUpdateInfoModel>;
}
export class CacheUpdateInfoModel {
    public CacheDataType!: CacheDataType;
    public HasUpdate!: boolean;
    public TargetYear!: number;
    public Version!: Date;
    public ModelList!: Array<JSON>;
}