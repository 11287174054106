import { HtmlUtil } from "../common/usefuls/htmlUtil";
import { ApiResultViewModel } from "../models/common/apiResultViewModel";
import $ from 'jquery';

export class MessageBox {
    public static success(message: string): void {
        let jobject: JQuery = $(MessageBox.genMessageTemplate()).addClass("alert-success");
        MessageBox.slideDownAutoUp(jobject, message);
    }

    public static info(message: string): void {
        let jobject = $(MessageBox.genMessageTemplate()).addClass("alert-info");
        MessageBox.slideDownAutoUp(jobject, message);
    }

    public static warning(message: string): void {
        let jobject = $(MessageBox.genMessageTemplate()).addClass("alert-warning");
        MessageBox.slideDownAutoUp(jobject, message);
    }

    // オーバーロード
    public static error(message: string): void;
    public static error(message: ApiResultViewModel): void;

    /**
     * 
     * @param message
     */
    public static error(message: any): void {
        let jobject = $(MessageBox.genMessageTemplate()).addClass("alert-danger");
        if (typeof (message) == 'string') {
            MessageBox.slideDown(jobject, message);
        }
        else {
            MessageBox.genMessage(jobject, message);
        }
    }

    public static close(): void {
        $(MessageBox.getMessageBoxObject()).remove();
    }

    public static getMessageBoxObject(): JQuery {
        return $(".mjs-common-message-box");
    }

    public static slideDown(jobject: JQuery, message: string): void {
        jobject.find(".main-message-wrapper").text(HtmlUtil.toBR(message));
        $("body").append(jobject);
        MessageBox.getMessageBoxObject().draggable({ cursor: "move" });
        MessageBox.getMessageBoxObject().slideDown("slow");
    }

    public static slideDownAutoUp(jobject: JQuery, message: string): void {
        jobject.find(".main-message-wrapper").text(HtmlUtil.toBR(message));
        $("body").append(jobject);
        MessageBox.getMessageBoxObject().draggable({ cursor: "move" });
        MessageBox.getMessageBoxObject().slideDown("slow", () => {
            window.setTimeout(() => {
                MessageBox.getMessageBoxObject().slideUp("slow", () => {
                    window.setTimeout(() => {
                        MessageBox.getMessageBoxObject().remove();
                    }, 300);    // 0.3秒後削除
                });
            }, 3000);   // ３秒間表示
        });
    }

    public static genMessage(jobject: JQuery, result: ApiResultViewModel): void {
        if (result != null) {
            $(jobject).find(".main-message-wrapper .message").html(HtmlUtil.toBR(result.Message));
            MessageBox.genDetailMessage(jobject, result);
            $("body").append(jobject);
            MessageBox.getMessageBoxObject().draggable({ cursor: "move" });
            MessageBox.getMessageBoxObject().slideDown("slow");
        }
    }

    public static genDetailMessage(target: JQuery, result: ApiResultViewModel): void {
        let jobject = $(MessageBox.genMessageTemplate()).find(".detail-message-wrapper");
        var targetField = result.ModelState;
        if (typeof result.ModelState === "undefined") {
            return;
        }
        Object.keys(result.ModelState).forEach((key) => {
            let template = jobject.clone(false);
            template.text(targetField[key][0]);
            target.append(template);
        });
    }

    public static genMessageTemplate(): string {
        let template: string =
        '<div class="mjs-common-message-box alert alert-dismissible fade in" role="alert" style="display: none">'
        +    '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
        +        '<span aria-hidden="true">&times;</span>'
        +    '</button>'
        +    '<div class="main-message-wrapper"><strong><span class="title"></span><span class="message"></span></strong></div>'
        +    '<div class="detail-message-wrapper"></div>'
        +'</div>';

        return template;
    }

}