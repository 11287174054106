import React from 'react';
import * as wjGrid from 'wijmo/wijmo.grid';
import { FlexGrid } from 'wijmo/wijmo.react.grid';
import * as wjFilter from 'wijmo/wijmo.grid.filter';
import { convertItemsSourceToDateType } from '../../../../utils/wijmoGridUtils';
import { UserAffiliationVO } from '../../../../models/userAffiliationVO';
import { UserVO } from '../../../../models/userVO';
import { UserAvailableCompanyVO } from '../../../../models/userAvailableCompanyVO';
import personSvg from '../../../../images/icon/26-1_person.svg';
import buildingSvg from '../../../../images/icon/27-1_building.svg';

type TantoByCompanyGridDropVO = UserAvailableCompanyVO & UserAffiliationVO & UserVO & any;

// ソート条件のテンポラリー
export type sortCriteriaStore = {
  property?: string;
  ascending?: boolean;
};

export type sortLabelMap = {
  key: any;
  value: string;
};
export type sortLabel = {
  binding: string;
  map: sortLabelMap[];
};

type TantoByCompanyGridDropProp = {
  columns: TantoByCompanyGridDropVO[];
  sources: TantoByCompanyGridDropVO[];
  headersVisi: number;
  childItemsPath?: string;
  sortLabelMapList?: sortLabel[];
  gridClassName?: string;
  gridRef?: React.RefObject<any>;
  onDeleteClick?: (item: TantoByCompanyGridDropVO) => void;
  onDragStart?: (item: TantoByCompanyGridDropVO) => void;
  onDropEnd?: (clientCode: number) => void;
  onFilterChanged?: (filterJson: string) => void;
}

type TantoByCompanyGridDropState = {};

const htmlPersonSvg = () =>
  `<span class='mr-2 bg-svg-icon' title="担当者" style="background-image: url(${personSvg})">　</span>`;
const htmlBuildingSvg = () =>
  `<span class='mr-2 bg-svg-icon' title="会社" style="background-image: url(${buildingSvg})">　</span>`;
const htmlDeleteIcon = () =>
  `<span id='deleteIcon_01' class='text-primary bg-svg-icon cursor-pointer deleteIcon_01' title="削除">解除</span>`;

class TantoByCompanyGridDrop extends React.Component<TantoByCompanyGridDropProp, TantoByCompanyGridDropState> {
  grid?: wjGrid.FlexGrid;
  filter?: wjFilter.FlexGridFilter;
  isChangeFilterAndSort = false;

  constructor(props: TantoByCompanyGridDropProp) {
    super(props);

    this.initializedGrid = this.initializedGrid.bind(this);
    this.handleItemFormatter = this.handleItemFormatter.bind(this);
  }

  // Gridの初期化
  public initializedGrid(flexGrid: wjGrid.FlexGrid) {
    this.grid = flexGrid;

    /*flexGrid.hostElement.addEventListener('mouseover', e => {
      var ht = flexGrid.hitTest(e);
      if (ht.cellType == wjGrid.CellType.Cell) {
      }
    })*/

    // 会社一覧へのみDropできる
// enable drop operations on an element
    flexGrid.hostElement.addEventListener('dragover', e => {
      var dragRow = e.dataTransfer!.getData('text');
      if(dragRow != null) {
        e.preventDefault();
      }
    });
    flexGrid.hostElement.addEventListener('drop', e => {
      // マウスオーバー
      var ht = flexGrid.hitTest(e);
      if (ht.cellType == wjGrid.CellType.Cell) {
        flexGrid.select(new wjGrid.CellRange(ht.row, ht.row, flexGrid.columns.length));
        if (typeof this.props.onDropEnd === 'function') {
          this.props.onDropEnd!(flexGrid.selectedItems[0]['ClientCode']);
          flexGrid.collectionView.refresh();
        }
      }
    });
  };

  // ItemFormatter
  public handleItemFormatter = (panel: wjGrid.GridPanel, row: number, col: number, cell: HTMLElement) => {
    // 縦罫線を消す
    cell.style.borderRight = 'none';

    if (panel.cellType === wjGrid.CellType.Cell) {

      const column: wjGrid.Column = panel.columns[col];
      const r: wjGrid.Row = panel.rows[row];
      const item: TantoByCompanyGridDropVO = r.dataItem;
      // マークをつける
      switch (column.binding) {
        case 'Code':
          if (item.UserCode != undefined) {
            cell.innerHTML = htmlPersonSvg() + cell.innerHTML;
            cell.classList.add('pl-4');
          } else {
            cell.innerHTML = htmlBuildingSvg() + cell.innerHTML;
          }
          break;
        case 'Name':
          if (item.UserCode != undefined) {
            cell.classList.add('pl-4');
          }
          break;
        case 'DeleteIcon':
          if (item.UserCode != undefined) {
          cell.innerHTML = htmlDeleteIcon();
            if (typeof this.props.onDeleteClick === 'function') {
                const deleteIcon = cell.querySelector('span');
                deleteIcon!.addEventListener('click', e => {
                  this.props.onDeleteClick!(item);
                  r.visible = false;
                });
            }
          }
          break;
      }
    }
  }

  public handleOnDeleteClick = () => {

  }

  public render() {
    const { columns, sources, gridClassName, gridRef  } = this.props;
    convertItemsSourceToDateType(columns,sources);
    return (
      <FlexGrid
        className={gridClassName}
        autoGenerateColumns={false}
        columns={columns}
        itemsSource={sources}
        childItemsPath={this.props.childItemsPath}
        headersVisibility={this.props.headersVisi}
        isReadOnly={true}
        selectionMode={wjGrid.SelectionMode.Row}
        allowResizing={wjGrid.AllowResizing.None}
        allowDragging={wjGrid.AllowDragging.None}
        alternatingRowStep={0}
        initialized={this.initializedGrid}
        itemFormatter={this.handleItemFormatter}
        ref={gridRef}
      >
      </FlexGrid>
    )
  }
}

export default TantoByCompanyGridDrop;