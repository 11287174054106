import * as wjGrid from 'wijmo/wijmo.grid';

/**
 * 列挙型のデータマップから名称を取得します。
 * @param dataMap データマップ
 * @param key 列挙型
 */
export const getValueFromDataMap = (dataMap: Array<{ key: number; value: string }>, key: number) => {
  let value = dataMap.filter(data => data.key === key);
  return value.length === 1 ? value[0].value : '';
};

/**
 * 名称から列挙型のデータマップを取得します。
 * @param dataMap データマップ
 * @param value 名称
 */
export const getDataMapFromValue = (dataMap: Array<{ key: number; value: string }>, value: string) => {
  let key = dataMap.filter(data => data.value === value);
  return key.length === 1 ? key[0].key : undefined;
};

/**
 * データマップをWijmoGridで使用する方に変換します。
 * @param dataMap データマップ
 * @param isAddBlank ブランク追加フラグ
 */
export const createWjDataMap = (dataMap: Array<{ key: number; value: string }>, isAddBlank?: boolean) => {
  const maps: typeof dataMap = [];
  if (isAddBlank === true) {
    maps.push({ key: -1, value: '' });
  }
  return new wjGrid.DataMap(maps.concat(dataMap), 'key', 'value');
};
