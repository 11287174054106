import React from 'react';
import Axios from 'axios';
import * as Labels from '../../../../constants/labels';
import * as message from '../../../../constants/message';
import { setAlertMessage, AlertKbnEnum } from '../../../../stores/AlertMessage';
import { setIsLoading } from '../../../../stores/NowLoading';

import {
  DefaultLayout,
  DefaultLayoutHeader,
  DefaultLayoutContents
} from '../../../../components/templates/DefaultLayout';
import PageHeader from '../../../../components/molecules/CMN/PageHeader/PageHeader';
import MonthSelect, { MODE } from '../../../organisms/A000/MonthSelect';
import AIJournalCheckLineGrid from '../../../../components/molecules/CMN/Grid/AIJournalCheckLineGrid';
import AngleCircleFrameLine from '../../../../components/atoms/AngleCircleFrameLine/AngleCircleFrameLine';

import { AIJournalCheckResultViewModel } from '../../../../models/aIJournalCheckResultViewModel';
import { AIJournalCheckJournalLineVO } from '../../../../models/aIJournalCheckJournalLineVO';
import { AIJournalCheckSuggestVO } from '../../../../models/aIJournalCheckSuggestVO';
import { AIJournalCheckSuggestHeaderVO } from '../../../../models/aIJournalCheckSuggestHeaderVO';
import Button from 'reactstrap/lib/Button';

// URL
export const monthUrl = (nMonth: number) => ['/api/v1/AIJournalCheckResult/Initial', nMonth.toString()].join('/');
// 処理判定
const JudgeURL = 'api/v1/CommonStartJudgement/Judge';
// システム履歴記入
const WriteSysHistoryURL = 'api/v1/CommonSystemHistory/Write';

type AIJournalCheckProps = {
  onClose: () => void;
  fiscalYear: number;
};
const initializationViewModel: AIJournalCheckResultViewModel = {
  SourceLinesCount: 0,
  SuggestedLinesCount: 0,
  JournalLines: []
};
const initializationSuggestHeaderVO: AIJournalCheckSuggestHeaderVO = {
  Debit: [],
  Credit: []
};
const AIJournalCheck: React.FC<AIJournalCheckProps> = props => {
  const { onClose } = props;

  const [viewModel, setViewModel] = React.useState<AIJournalCheckResultViewModel>(initializationViewModel);
  const [suggests, setSuggests] = React.useState<AIJournalCheckSuggestHeaderVO>(initializationSuggestHeaderVO);

  const gridRef = React.useRef<any>();

  /**
   * 月度選択
   */
  const handleOnMonthChange = (nMonth: number[]) => {
    monthOnGet(monthUrl(nMonth[0]));
  };

  /**
   * 閉じるボタン押下時の処理
   */
  const handleOnCloseClick = () => {
    onClose();
  };

  /**
   * 表示切替ボタン押下時の処理
   */
  const displaySwitchBtnClick = () => {
    gridRef.current.switchHeader();
  };

  /**
   * 選択行変更時の処理
   */
  const handleSelectionChanged = (item: AIJournalCheckJournalLineVO) => {
    setSuggests(item.Suggests);
  };

  const displaySuggestsDetail = (suggestList: AIJournalCheckSuggestVO[]) => {
    const displaySuggests: React.ReactElement[] = [];
    suggestList.forEach((item, i) => {
      displaySuggests.push(
        <div className='d-flex flex-row' key={['suggests', i].join('-')}>
          <div className='-phrase'>{item.Phrase}</div>
        </div>
      );
    });
    return <div>{displaySuggests}</div>;
  };

  const monthOnGet = (url: string) => {
    setIsLoading(true);
    Axios.get<AIJournalCheckResultViewModel>(url)
      .then(response => {
        setViewModel(response.data);
      })
      .catch(error => {
        if (typeof error.response.data !== 'undefined') {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
        }
        setViewModel(initializationViewModel);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    if (typeof viewModel.JournalLines === 'undefined' || viewModel.JournalLines.length < 1) {
      // ビューモデルがクリアされた場合、修正提案もクリア
      setSuggests(initializationSuggestHeaderVO);
    }
  }, [viewModel.JournalLines]);

  //権限チェックFlg 0:起動可能 その他:起動不可
  const [authCheckFlg, setAuthCheckFlg] = React.useState(-1);
  React.useEffect(() => {
    // 処理判定
    async function JudgeOnPost(url: string, syoriId: string) {
      const status = await Axios.post<number>(url, {syoriId: syoriId});
      setAuthCheckFlg(status.data);
      if (status.data == 0) {
        //システム履歴記入
        await Axios.post(WriteSysHistoryURL, {syoriId: syoriId, operation: '処理開始'});
      } else {
        if(status.data == -101) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotUse});
        } else if (status.data == -102) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractOver});
        } else if (status.data == -103) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_Notauthorized});
        } else if (status.data == -104) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotDataSelect});
        } else if (status.data == -105) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractEqual});
        }
        props.onClose();
      }
    }
    JudgeOnPost(JudgeURL, 'AIKADT002000013');
  }, []);

  return (authCheckFlg != 0)? (<div></div>) : (
    <DefaultLayout className='vw-100 h-100 AIJournalCheck'>
      <DefaultLayoutHeader>
        <PageHeader
          pageTitle={Labels.AIKADT002000013_FUNCTION_NAME}
          closeButtonTabIndex={3}
          onCloseClick={handleOnCloseClick}
        >
          <MonthSelect
            displayMode={MODE.AIDisplay}
            onMonthChange={handleOnMonthChange}
            dataRelationCheck={true}
            tabIndex={1}
            fiscalYear={props.fiscalYear}
          />
          <span className='ml-3 text-nowrap large'>
            {Labels.AIKADT002000013_FUNCTION_HEADER_SUGGESTEDCOUNT} {viewModel.SuggestedLinesCount} /{' '}
            {Labels.AIKADT002000013_FUNCTION_HEADER_JOURNALLINECOUNT} {viewModel.SourceLinesCount}
          </span>
        </PageHeader>
      </DefaultLayoutHeader>
      <DefaultLayoutContents className='vw-100 px-3 pt-3'>
        <AngleCircleFrameLine className='-suggests overflow-auto px-3 py-1'>
          <div className='text-red large'>
            <b>{Labels.AIKADT002000013_FUNCTION_HEADER_MODIFICATIONSUGGESTED}</b>
          </div>
          <div className='d-flex'>
            <div className='w-40'>
              <div className='text-red'>
                <b>{Labels.AIKADT002000013_FUNCTION_HEADER_DEBTOR}</b>
              </div>
              {displaySuggestsDetail(suggests.Debit)}
            </div>
            <div className='w-40'>
              <div className='text-red'>
                <b>{Labels.AIKADT002000013_FUNCTION_HEADER_CREDIT}</b>
              </div>
              {displaySuggestsDetail(suggests.Credit)}
            </div>
          </div>
        </AngleCircleFrameLine>
        <AIJournalCheckLineGrid
          gridClassName='AIJournalCheckLine-grid mt-3'
          sources={viewModel.JournalLines}
          selectionChanged={handleSelectionChanged}
          tabIndex={2}
          ref={gridRef}
        />

        <div className='-switchbutton'>
          <Button
            className='A3-btn'
            color='secondary'
            onClick={displaySwitchBtnClick}
          >
            {Labels.AIKADT002000013_BUTTON_FUNCTION_DISPLAYSWITCH}
          </Button>
        </div>
      </DefaultLayoutContents>
    </DefaultLayout>
  );
};
export default AIJournalCheck;
