import React from 'react';
import Axios from 'axios';
import * as wjGrid from 'wijmo/wijmo.grid';

import Popup from '../../../../components/molecules/CMN/Popup/Popup';
import IconButton from '../../../../components/molecules/CMN/IconButton/IconButton';
import Close from '../../../../components/atoms/Icon/Close';
import FileExcel from '../../../../components/atoms/Icon/FileExcel';
import AuditResultGrid from '../../../../components/molecules/CMN/Grid/AuditResultGrid';
import ExcelOutputsetting from '../../../organisms/A000/ExcelOutputsetting';
import * as labels from '../../../../constants/labels';
import * as constants from '../../../../constants/constant';
import { ConfStatusEnum } from '../../../../constants/dataMaps';
import { createWjDataMap } from '../../../../utils/dataMapsUtils';
import { AuditResultJournalDoubleViewModel } from '../../../../models/auditResultJournalDoubleViewModel';
import { setAlertMessage, AlertKbnEnum } from '../../../../stores/AlertMessage';

export const initialUrl = '/api/v1/AuditResultJournalDouble/Initial';

type AuditResultJournalDoubleProps = {
  activated: boolean;
  month: number;
  onClose: () => void;
};
const initializationViewModel: AuditResultJournalDoubleViewModel = {
  TMonth: '',
  Results: []
};

// tabIndexの初期値
const baseTabIndex = 200;

const AuditResultJournalDouble: React.FC<AuditResultJournalDoubleProps> = props => {
  const { activated, month, onClose } = props;
  const [viewModel, setViewModel] = React.useState<AuditResultJournalDoubleViewModel>(initializationViewModel);

  const [excelPopupActivated, setExcelPopupActivated] = React.useState(false);
  const [excelPopupServiceParams, setExcelPopupServiceParams] = React.useState('');
  const [filters, setFilters] = React.useState('');

  const firstFocusElement = React.useRef<any>();
  const radioAllRef = React.useRef<any>();
  const radioLatestRef = React.useRef<any>();
  const gridRef = React.useRef<any>();
  const gridInnerRef = React.useRef<any>();
  const popupRef = React.useRef<any>();

  const radiofocus = () => {
    if (radioAllRef.current.checked === true) {
      radioAllRef.current.focus();
    } else {
      radioLatestRef.current.focus();
    }
  };

  /** キーボード操作（キーダウン） */
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 9) {
      if (event.shiftKey === false) {
        // タブキー押下時

        const target: HTMLElement = event.target as HTMLElement;
        if (target.tabIndex < baseTabIndex || target.tabIndex >= baseTabIndex + 4) {
          // 最後のフォーカス又はフォーカスが当たっていない場合

          firstFocusElement.current.focus();
          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
      } else {
        // シフト＋タブキー押下時

        const target: HTMLElement = event.target as HTMLElement;
        if (target.tabIndex <= firstFocusElement.current.tabIndex || target.tabIndex > baseTabIndex + 4) {
          // 最初のフォーカス又はフォーカスが当たっていない場合

          // 最後のエレメントにフォーカス
          if (gridRef.current) {
            const flexGrid = gridRef.current.control as wjGrid.FlexGrid;
            flexGrid.focus();
          }
          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }
  };
  /** Grid */
  const handleKeyDownGrid = (event: KeyboardEvent) => {
    if (event.keyCode === 9) {
      if (event.shiftKey === false) {
        // タブキー押下時

        firstFocusElement.current.focus();
        // 後のイベントをキャンセル
        event.preventDefault();
        event.stopPropagation();
      } else {
        // シフト＋タブキー押下時

        radiofocus();
        // 後のイベントをキャンセル
        event.preventDefault();
        event.stopPropagation();
      }
    }
  };

  React.useEffect(() => {
    if (activated && viewModel.Results != undefined && viewModel.Results.length > 0) {
      // 画面表示完了後に実行
      setTimeout(() => {
        if (popupRef.current) {
          // ポップアップにキーダウンイベントのフック
          popupRef.current.addEventListener('keydown', handleKeyDown);
        }
        if (gridInnerRef.current) {
          // ポップアップにキーダウンイベントのフック
          gridInnerRef.current.addEventListener('keydown', handleKeyDownGrid);
        }
      });
    }
  }, [viewModel.Results]);

  const initialOnGet = (url: string, month: number) => {
    // WebAPI一覧のURLを作成
    const encodedUrl = [url, encodeURI(month.toString())].join('/');

    Axios.get<AuditResultJournalDoubleViewModel>(encodedUrl)
      .then(response => {
        setViewModel(response.data);
      })
      .catch(error => {
        if (typeof error.response.data !== 'undefined') {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
        }
        handleOnClose();
      });
  };

  // 通信処理の実行
  React.useEffect(() => {
    if (activated) {
      setViewModel(initializationViewModel);
      initialOnGet(initialUrl, month);
    }
    setExcelPopupServiceParams(
      JSON.stringify({
        nMonth: month
      })
    );
  }, [initialUrl, month, activated]);

  const handleOnClose = () => {
    setViewModel(new AuditResultJournalDoubleViewModel());
    onClose();
  };

  const handleOnExcelClick = () => {
    setExcelPopupActivated(true);
  };

  const handleExcelOnClose = () => {
    setExcelPopupActivated(false);
  };

  const handleOnFilterChanged = (filterJson: string) => {
    setFilters(filterJson);
  };

  return viewModel.Results == undefined || viewModel.Results.length < 1 ? null : (
    <Popup
      isOpen={activated}
      onCloseClick={handleOnClose}
      header={[
        labels.AIKADT002000010_FUNCTION_NAME,
        labels.AIKADT002000010_FUNCTION_HEADER_TARGETMONTH + viewModel.TMonth
      ].join('　')}
      size='lg'
      innerRef={popupRef}
      headerIcon={
        <div>
          <IconButton
            icon={<FileExcel className='icon-lg' />}
            className='text-white'
            onClick={handleOnExcelClick}
            tabIndex={baseTabIndex + 1}
            innerRef={firstFocusElement}
          />
          <IconButton
            icon={<Close className='icon-lg' />}
            className='ml-2 text-white'
            onClick={handleOnClose}
            tabIndex={baseTabIndex + 2}
          />
        </div>
      }
    >
      <AuditResultGrid
        gridClassName='AuditResultJournalDouble-grid'
        columns={gridColumns}
        sources={viewModel.Results}
        execDate={viewModel.LastExecDate}
        radioTabIndex={baseTabIndex + 3}
        radioAllRef={radioAllRef}
        radioLatestRef={radioLatestRef}
        innerRef={gridInnerRef}
        gridRef={gridRef}
        gridTabIndex={baseTabIndex + 4}
        groupDescriptions={['DoubleSrchNo']}
        onFilterChanged={handleOnFilterChanged}
      />
      <ExcelOutputsetting
        activated={excelPopupActivated}
        onClose={handleExcelOnClose}
        filters={filters}
        templateId={constants.AIKADT002000010E01}
        headerLeft={[labels.AIKADT002000010_FUNCTION_HEADER_TARGETMONTH, viewModel.TMonth].join('')}
        serviceParams={excelPopupServiceParams}
      />
    </Popup>
  );
};
export default AuditResultJournalDouble;

const gridColumns = [
  { header: labels.AIKADT002000010_GRID_HEADER_SEQUENCE, binding: 'No', width: 55, align: 'center', format: 'd' },
  {
    header: labels.AIKADT002000010_GRID_HEADER_SUBJECTSCODE,
    binding: 'KmkCode',
    width: 95,
    align: 'center',
    format: 'd'
  },
  {
    header: labels.AIKADT002000010_GRID_HEADER_SUBJECTSNAME,
    binding: 'KmkName',
    width: '*',
    minWidth: 120,
    align: 'left'
  },
  {
    header: labels.AIKADT002000010_GRID_HEADER_SEARCHNO,
    binding: 'SrchNo',
    width: 100,
    align: 'center',
    inputType: 'blankIfGray',
    format: 'd'
  },
  {
    header: labels.AIKADT002000010_GRID_HEADER_JOURNALIZATIONDUPLICATESEARCHNO,
    binding: 'DoubleSrchNo',
    width: '*',
    minWidth: 170,
    align: 'left',
    inputType: 'price'
  },
  {
    header: labels.AIKADT002000010_GRID_HEADER_STATUS,
    binding: 'ConfStatus',
    width: 100,
    align: 'center',
    dataMap: createWjDataMap(ConfStatusEnum),
    inputType: 'status'
  },
  {
    header: labels.AIKADT002000010_GRID_HEADER_EXECUTINGTIME,
    binding: 'ExecDate',
    width: 145,
    align: 'center',
    dataType: 'Date',
    format: 'yyyy/MM/dd HH:mm:ss'
  }
];
