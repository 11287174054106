import { setGlobal } from 'reactn';

export enum AlertKbnEnum {
  success = 0,
  danger = 1,
  warning = 2
}

const initialState = {
  alertMessage: {
    alerted: false,
    alertKbn: AlertKbnEnum.success,
    message: ''
  }
};

export type AlertMessage = {
  alerted: boolean,
  alertKbn: number,
  message: string,
  timeout?: number
} ;

export const setAlertMessage = (alertMessage: AlertMessage) => {
  setGlobal({ alertMessage: alertMessage });
};

const settingGlobal = () => {
  setGlobal(initialState);
};

export default {
  settingGlobal
};
