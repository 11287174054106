export class DataModelBase {

    /**
     * Jsonからモデルのインスタンスを生成する
     * @param jsonArray
     * @param cls
     */
    public static fillFromJson<T extends DataModelBase>(json: Array<JSON>, cls: typeof DataModelBase): T {
        var model = new cls();
        for (var propName in json) {
            model[propName] = json[propName];
        }
        return <T>model;
    }

    /**
     * Jsonからモデルのインスタンスを生成する
     * @param jsonArray
     * @param cls
     */
    public static fillFromJsons<T extends DataModelBase>(jsonArray: Array<JSON>, cls: typeof DataModelBase): Array<T> {
        var array: Array<DataModelBase> = new Array<DataModelBase>();
        var dataCount = jsonArray == null ? 0 : jsonArray.length;
        for (var i = 0; i < dataCount; i++) {
            var json = jsonArray[i];
            var model = new cls();
            for (var propName in json) {
                model[propName] = json[propName];
            }
            array.push(model);
        }
        return <Array<T>>array;
    }
}
