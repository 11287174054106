
export class ApiResultViewModel {
    public Success!: boolean;
    public Message!: string;
    public ModelState!: Object[];

    public set message (value: string) {
        this.Message = value;
    }

    public get message() {
        return this.Message;
    }
}
