/**
 * Object比較
 * null同士、undefined同士も同じとみなす
 * @param a
 * @param b
 */
export function compareObject<T>(a: T, b: T): boolean {
  if ((a === undefined && b === undefined)
    || (a === null && b === null)) {
    return true;
  } else if (!a || !b) {
    return false;
  }
  var flg = a === b;
  Object.keys(a).some(key => {
    flg = b[key] !== undefined;
    if (flg) {
      if (typeof a[key] == 'object' && typeof b[key] == 'object') {
        flg = compareObject(a[key], b[key]);
      } else {
        flg = b[key] == a[key];
      }
    }
    return !flg;
  });
  return flg;
}