import {RulePatternEnum} from "../constants/enums";
/**
 * 月次残高ルールパターンVO ViewModel クラス
 */

export class MonthlyBalanceRulePatternItemVO {
    /** パターン区分 */
    public RulePatternKbn?:RulePatternEnum;

    /** 名称 */
    public Name?:string;

    /** 標準設定フラグ */
    public DefaultSettingFlg?:boolean;

    /** 監査対象 */
    public AuditTarget?:number;

}
