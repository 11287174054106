import React from 'react';
import { Button } from 'reactstrap';
import { DefaultLayout, DefaultLayoutHeader, DefaultLayoutContents } from '../../../../components/templates/DefaultLayout';
import PageHeader from '../../../../components/molecules/CMN/PageHeader/PageHeader';
import { getAxiosInstance } from '../../../../utils/axiosUtils';
import { setIsLoading } from '../../../../stores/NowLoading';
import { DeleteCompanyDataViewModel } from '../../../../models/deleteCompanyDataViewModel';
import CommonGrid from '../../../../components/molecules/CMN/Grid/CommonGrid';
import ConfirmationPopup from '../../../../components/molecules/CMN/ConfirmationPopup/ConfirmationPopup';
import * as Labels from '../../../../constants/labels';
import * as message from '../../../../constants/message';
import * as messageUtils from '../../../../utils/messageUtils';
import { format } from '../../../../utils/dateUtils';
import { setAlertMessage, AlertKbnEnum } from '../../../../stores/AlertMessage';
import { bindValueToMessage } from '../../../../utils/messageUtils';
import Popup from '../../../../components/molecules/CMN/Popup/Popup';

/** グリッド表示用 */
class CompanyData4Grid {
  /** 会社コード */
  public ComCode?: number;

  /** 会社名 */
  public LComName?: string;

  /** 会計期間（自）～（至） */
  public KStEdDate?: string;

  /** 内部会社コード */
  public ClientCode?: number;

  /** 会計年度 */
  public ClientYear?: number;

  /** 削除可能フラグ */
  public DeletableFlg?: boolean;
}

/** 会社データ削除画面のプロパティ */
type DeleteCompanyDataProps = {
  /** 自画面を閉じる処理 */
  onClose: () => void;

  /** 表示年度 */
  fiscalYear: number;
};

/**
 * 会社データ削除画面
 * @param props
 */
const DeleteCompanyData: React.FC<DeleteCompanyDataProps> = props => {
  const { onClose } = props;

  /** A2 閉じるボタン押下時の処理 */
  const handleOnCloseClick = () => {
    onClose();
  };

  /* グリッド表示用 */
  const [gridSource, setGridSource] = React.useState<CompanyData4Grid[]>([]);

  /* 削除対象の会社 */
  const [currentCompany, setCurrentCompany] = React.useState<CompanyData4Grid>(new CompanyData4Grid());

  /* 削除対象の会社名 */
  const [currentCompanyName, setCurrentCompanyName] = React.useState<string>('');

  // 初期処理時
  React.useEffect(() => {
    // 処理判定
    async function JudgeOnPost(url: string, syoriId: string) {
      const status = await getAxiosInstance().post<number>(url, {syoriId: syoriId});
      if (status.data == 0) {
        // 初期起動
        InitialProc();
        await getAxiosInstance().post(WriteSysHistoryURL, {syoriId: syoriId, operation: '処理開始'});
      } else {
        if(status.data == -101) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotUse});
        } else if (status.data == -102) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractOver});
        } else if (status.data == -103) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_Notauthorized});
        } else if (status.data == -104) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotDataSelect});
        } else if (status.data == -105) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractEqual});
        }
        props.onClose();
      }
    }
    JudgeOnPost(JudgeURL, 'AIKCMN004000001');
  }, [props.fiscalYear]);

  /** 初期表示処理 */
  const InitialProc = (): void => {
    setCurrentCompany(new CompanyData4Grid());
    setCurrentCompanyName('');
    CreateInitialOnGet();
  }

  /** サーバへアクセスする際のURL(ベース部分) */
  const BaseURL = 'api/v1/DeleteCompanyData';

  /** 初期表示時にサーバへアクセスする際のURL */
  const InitialURL = [BaseURL, 'CreateInitial'].join('/');

  /** 会社削除時にサーバへアクセスする際のURL */
  const DeleteURL = [BaseURL, 'Delete'].join('/');

  /** 共通処理 */
  // 処理起動判定
  const JudgeURL = 'api/v1/CommonStartJudgement/Judge';
  // システム履歴記入
  const WriteSysHistoryURL = 'api/v1/CommonSystemHistory/Write';

  /** 会計期間(年月日～年月日)を画面に表示する際のフォーマット */
  const kaikeiKikanDispFormat = 'yyyy/MM/dd';

  /** 初期処理時にサーバへ会社データ一覧を問い合わせ→取得する */
  const CreateInitialOnGet = () => {
    setIsLoading(true);
    //let method: string = 'DeleteCompanyData.CreateInitialOnGet()';
    let url = InitialURL;
    getAxiosInstance().get<DeleteCompanyDataViewModel[]>(url, { params: { compCode: -1 } })
      .then(response => {
        //console.debug(method + ' then URL=' + url);
        let companyData4Grid: CompanyData4Grid[] = [];
        if (response.data) {
          // グリッド表示用に加工してStateに保存する
          for (let i: number = 0; i < response.data.length; i++) {
            let data = new CompanyData4Grid();
            data.ComCode = response.data[i].ComCode;
            data.LComName = response.data[i].LComName;
            let stDate = response.data[i].KStDate;
            let edDate = response.data[i].KEdDate;
            if ((stDate != undefined) && (edDate != undefined)) {
              data.KStEdDate = format(stDate, kaikeiKikanDispFormat) + Labels.AIKCMN004000001_INPUT_Hani + format(edDate, kaikeiKikanDispFormat);
            }
            data.ClientCode = response.data[i].ClientCode;
            data.ClientYear = response.data[i].ClientYear;
            data.DeletableFlg = (data.ClientCode != 0) ? true : false;
            companyData4Grid.push(data);
          }
          setGridSource(companyData4Grid);
        }
      })
      .catch(error => {
        //console.debug(method + ' catch URL=' + url + ' error=' + error);
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_InternalServerError });
      })
      .finally(() => {
        //console.debug(method + ' finally URL=' + url);
        setIsLoading(false);
      });
  }

  /** 会社データ削除をサーバに依頼する */
  const DeleteOnPost = () => {
    setIsLoading(true);
    //let method: string = 'DeleteCompanyData.DeleteOnPost()';
    let url = DeleteURL;
    let success = false;
    let successMessage = '';
    if (currentCompany.LComName != undefined) {
      successMessage = bindValueToMessage(message.Common_Success_Delete, [currentCompany.LComName]);
    }
    getAxiosInstance().post(
      url,
      {
        clientCode: currentCompany.ClientCode,
        clientYear: currentCompany.ClientYear,
        compCode: currentCompany.ComCode
      }
    )
      .then(response => {
        //console.debug(method + ' then URL=' + url);
        success = true;
      })
      .catch(error => {
        //console.debug(method + ' catch URL=' + url + ' error=' + error);
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_InternalServerError });
      })
      .finally(() => {
        //console.debug(method + ' finally URL=' + url);
        setIsLoading(false);
        // 削除完了した場合、setIsLoading()が完了したであろうタイミングで初期表示処理を呼ぶ
        if (success) {
          setTimeout(() => {
            InitialProc();
            setAllPurposePopupMessage(successMessage);
            setAllPurposePopupActivated(true);
          }, 500);
        }
      });
  }

  // 削除前の確認メッセージ  
  const [deleteCompanyConfirmActivated, setDeleteCompanyConfirmActivated] = React.useState(false);
  const handleDeleteCompanyConfirmClose = () => {
    setDeleteCompanyConfirmActivated(false);
  };
  const handleDeleteCompanyConfirmYes = () => {
    DeleteOnPost();
    setDeleteCompanyConfirmActivated(false);
  };
  const handleDeleteCompanyConfirmNo = () => {
    setDeleteCompanyConfirmActivated(false);
  };

  // 通信処理(削除処理)の実行
  const handleOnDeleteClick = (theCompany: CompanyData4Grid) => {
    if (theCompany.ClientCode != 0) {
      // 削除可能な会社データ(ClientCode != 0)
      let lComName = theCompany.LComName;
      if (lComName != undefined) {
        setCurrentCompanyName(lComName);
      }
      setCurrentCompany(theCompany);
      // 削除前の確認メッセージを表示する
      setDeleteCompanyConfirmActivated(true);
    }
  };

  // 汎用メッセージ
  const [allPurposePopupMessage, setAllPurposePopupMessage] = React.useState<string>('');

  // 汎用メッセージを表示するフラグ
  const [allPurposePopupActivated, setAllPurposePopupActivated] = React.useState<boolean>(false);

  /** 汎用メッセージのOKボタンへの参照 */
  const refer2AllPurposePopupMessageButton = React.useRef<any>();

  /** 汎用メッセージを表示した際の処理 */
  const handleAllPurposePopupOpened = () => {
    // OKボタンへフォーカスをセットする
    if (refer2AllPurposePopupMessageButton.current != undefined) {
      refer2AllPurposePopupMessageButton.current.focus();
    }
  }

  /** 汎用メッセージを閉じた際の処理 */
  const handleAllPurposePopupClose = () => {
    setAllPurposePopupActivated(false);
  };

  /** 汎用メッセージを閉じる際の処理 */
  const handleAllPurposePopupCloseClick = () => {
    setAllPurposePopupActivated(false);
  };

  /** グリッドのカラム定義 */
  const gridColumns = [
    {
      header: Labels.AIKCMN004000001_INPUT_ComCode /* B2 会社コード ラベル */,
      binding: 'ComCode' /* B6 会社コード */,
      isReadOnly: true,
      align: 'right',
      width: '0.75*',
      format: 'd',
      maxLength: 10
    },
    {
      header: Labels.AIKCMN004000001_INPUT_LComName /* B3 会社名 ラベル */,
      binding: 'LComName' /* B7 会社名 */,
      isReadOnly: true,
      align: 'left',
      width: '4*'
    },
    { 
      header: Labels.AIKCMN004000001_INPUT_KStDate /* B4 会計期間 ラベル */,
      binding: 'KStEdDate' /* B8 会計期間 */,
      isReadOnly: true,
      align: 'center',
      width: '2*'
    },
    {
      header: Labels.AIKCMN004000001_INPUT_DELETE /* B5 削除 ラベル */,
      name: 'DeleteIcon' /* B9 削除アイコン */,
      isReadOnly: true,
      align: 'center',
      cssClass: 'delete-icon',
      inputType: 'button',
      width: '0.75*'
    },
  ];

  return (
    <div className='delete-company-data'>
      <DefaultLayout>
        <DefaultLayoutHeader>
          <PageHeader
            pageTitle={Labels.AIKCMN004000001_FUNCTION_NAME /* A1 機能名 ラベル */}
            onCloseClick={handleOnCloseClick /* A2 閉じる ボタン */}>
          </PageHeader>
        </DefaultLayoutHeader>
        <DefaultLayoutContents className='vw-100 px-3 pt-3'>
          <CommonGrid /* B1 会社一覧 */
            gridClassName='company-grid'
            columns={gridColumns}
            sources={gridSource}
            onDeleteClick={handleOnDeleteClick}
          />
      </DefaultLayoutContents>
      </DefaultLayout>

      <ConfirmationPopup
        isOpen={deleteCompanyConfirmActivated}
        onClose={handleDeleteCompanyConfirmClose /* B32 閉じる ボタン */}
        onYesClick={handleDeleteCompanyConfirmYes /* C32 はい ボタン */}
        onNoClick={handleDeleteCompanyConfirmNo /* C33 いいえ ボタン */}
      >
        {messageUtils.bindValueToMessage(message.Common_Confirmation_Delete, [currentCompanyName])}
      </ConfirmationPopup>

      <Popup
        header={Labels.AIKCMN004000001_FUNCTION_NAME}
        isOpen={allPurposePopupActivated}
        onOpened={handleAllPurposePopupOpened}
        onClosed={handleAllPurposePopupClose}
        onCloseClick={handleAllPurposePopupCloseClick}
        footer={
          <div className='w-100 text-center'>
            <Button
              color='primary'
              className='mx-auto px-5 py-1'
              onClick={handleAllPurposePopupCloseClick}
              innerRef={refer2AllPurposePopupMessageButton}
              tabIndex={202}
            >
              {Labels.AIKCMN001000001_BUTTON_FUNCTION_OK}
            </Button>
          </div>
        }
        closeIconTabIndex={201}
      >
        {allPurposePopupMessage}
      </Popup>

    </div>
  );
}
export default DeleteCompanyData;