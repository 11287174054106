import React from 'react';

import Popup from '../Popup/Popup';
import ExecutingCancel from '../ExecutingCancel/ExecutingCancel';

export type popupProps = {
  isOpen: boolean;
  onClose: () => void;
  onYesClick: () => void;
  onNoClick: () => void;
  errorMessage?: string
};

// tabIndexの初期値
const baseTabIndex = 1100;

const ConfirmationPopup: React.FC<popupProps> = props => {
  const { isOpen, onClose, onYesClick, onNoClick, children } = props;

  const executeRef = React.useRef<any>();
  const cancelRef = React.useRef<any>();
  const closeIconRef = React.useRef<any>();
  const popupRef = React.useRef<any>();

  /** キーボード操作（キーダウン） */
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      if (event.shiftKey === false) {
        // タブキー押下時

        const target: HTMLElement = event.target as HTMLElement;
        if (target.tabIndex === closeIconRef.current.tabIndex) {
          // 閉じるボタンの場合

          // はいボタンにフォーカス
          executeRef.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        } else if (target.tabIndex === executeRef.current.tabIndex) {
          // はいボタンの場合

          // いいえボタンにフォーカス
          cancelRef.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        } else if (target.tabIndex === cancelRef.current.tabIndex) {
          // いいえボタンの場合

          // 閉じるボタンにフォーカス
          closeIconRef.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();

          // フォーカスが当たっていない場合
        } else {
          // 最初のエレメントにフォーカス
          closeIconRef.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
      } else {
        // シフト＋タブキー押下時

        const target: HTMLElement = event.target as HTMLElement;
        if (target.tabIndex === closeIconRef.current.tabIndex) {
          // 閉じるボタンの場合

          // いいえボタンにフォーカス
          cancelRef.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        } else if (target.tabIndex === executeRef.current.tabIndex) {
          // はいボタンの場合

          // いいえボタンにフォーカス
          closeIconRef.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        } else if (target.tabIndex === cancelRef.current.tabIndex) {
          // いいえボタンの場合

          // 閉じるボタンにフォーカス
          executeRef.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();

          // フォーカスが当たっていない場合
        } else {
          // 最初のエレメントにフォーカス
          cancelRef.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      // 画面表示完了後に実行
      setTimeout(() => {
        if (popupRef.current) {
          // ポップアップにキーダウンイベントのフック
          popupRef.current.addEventListener('keydown', handleKeyDown);
        }
      });
    }
  }, [isOpen]);

  const popupFooter = (
    <>
    <div className='flex-grow-1'>
        {props.errorMessage ? <label className='text-danger white-space-pre-wrap'>{props.errorMessage}</label> : ''}
    </div>
    <ExecutingCancel
      className='text-nowrap mt-auto'
      executeLabel='はい'
      executeTabIndex={baseTabIndex + 2}
      executeRef={executeRef}
      onExecuteClick={onYesClick}
      cancelLabel='いいえ'
      cancelTabIndex={baseTabIndex + 3}
      cancelRef={cancelRef}
      onCancelClick={onNoClick}
    />
    </>
  );

  return (
    <Popup
      className='confirmation-popup'
      isOpen={isOpen}
      header='確認'
      onCloseClick={onClose}
      footer={popupFooter}
      innerRef={popupRef}
      closeIconRef={closeIconRef}
      closeIconTabIndex={baseTabIndex + 1}
    >
      {children}
    </Popup>
  );
};
export default ConfirmationPopup;
