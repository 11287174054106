import React from 'react';

import { Card, CardImg, CardText } from 'reactstrap';
import { Nav, NavItem, NavLink } from 'reactstrap';

import RuleSettingIcon from '../../../images/vkz/ico_menu_tekiyou_touroku.png';
import RuleSystemIcon from '../../../images/vkz/ico_menu_sisutemu_kihon_zyouhou_touroku.png';
// import ItemSettingIcon from '../../../images/vkz/ico_menu_hannyou_hojo.png';

import * as labels from '../../../constants/labels';

type IAuditMasterMenuProps = {
  onJournalLineRuleSettingListClick: () => void;
  onMonthlyBalanceSettingClick: () => void;
  onChecklistSettingListClick: () => void;
};

const headerClass = 'h6 text-lbl';
const navLinkClass = 'cursor-pointer p-0 mx-3 my-2';
const cardClass = 'border-0 justify-content-center align-items-center _item';
const lblClass = 'small text-black d-block text-center';
const cardTextClass = 'm-2';

const AuditMasterMenu = (props: IAuditMasterMenuProps) => {
  const journalizationitemLbl: React.ReactElement[] = [];
  labels.AIKCMN001000001_BUTTON_FUNCTION_JOURNALIZATIONITEMAUDITRULESETTINGLIST.split('\n').forEach((lbl, i) => {
    journalizationitemLbl.push(
      <span className={lblClass} key={'journalizationitemLbl' + i}>
        {lbl}
      </span>
    );
  });

  const monthlybalanceLbl: React.ReactElement[] = [];
  labels.AIKCMN001000001_BUTTON_FUNCTION_MONTHLYBALANCEAUDITRULEPREFERENCE.split('\n').forEach((lbl, i) => {
    monthlybalanceLbl.push(
      <span className={lblClass} key={'monthlybalanceLbl' + i}>
        {lbl}
      </span>
    );
  });

  const checklistpreferenceLbl: React.ReactElement[] = [];
  labels.AIKCMN001000001_BUTTON_FUNCTION_CHECKLISTSETTINGLIST.split('\n').forEach((lbl, i) => {
    checklistpreferenceLbl.push(
      <span className={lblClass} key={'checklistpreferenceLbl' + i}>
        {lbl}
      </span>
    );
  });

  return (
    <div className='app-menu'>
      <section>
        <h2 className={headerClass}>{labels.AIKCMN001000001_BUTTON_HEADER_AUDITRULEPREFERENCE}</h2>
        <Nav>
          <NavItem>
            <NavLink
              className={navLinkClass}
              onClick={props.onJournalLineRuleSettingListClick}
              tabIndex={116}
              href='javascript:void(0);'
            >
              <Card className={cardClass}>
                <CardImg src={RuleSettingIcon} />
                <CardText className={cardTextClass}>{journalizationitemLbl}</CardText>
              </Card>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={navLinkClass}
              onClick={props.onMonthlyBalanceSettingClick}
              tabIndex={117}
              href='javascript:void(0);'
            >
              <Card className={cardClass}>
                <CardImg src={RuleSystemIcon} />
                <CardText className={cardTextClass}>{monthlybalanceLbl}</CardText>
              </Card>
            </NavLink>
          </NavItem>
        </Nav>
      </section>
      {/*
      <section>
        <h2 className={headerClass}>{labels.AIKCMN001000001_BUTTON_HEADER_CHECKLISTPREFERENCE}</h2>
        <Nav>
          <NavItem>
            <NavLink className={navLinkClass} onClick={props.onChecklistSettingListClick} tabIndex={118}  href='javascript:void(0);'>
              <Card className={cardClass}>
                <CardImg src={ItemSettingIcon} />
                <CardText className={cardTextClass}>{checklistpreferenceLbl}</CardText>
              </Card>
            </NavLink>
          </NavItem>
        </Nav>
      </section>
      */}
    </div>
  );
};
export default AuditMasterMenu;
