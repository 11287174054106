import {TaxKmkListVO} from "./taxKmkListVO";
/**
 * 消費税区分関連情報ViewModel ViewModel クラス
 */

export class TaxKubunRelationInfoViewModel {
    /** 消費税科目情報リストVO */
    public TaxKmkList?:Array<TaxKmkListVO>;

    /** 輸入取引区分 */
    public ImportKbn?:number;

    /** 仮払消費税科目コード */
    public ProvisionalPaymentTaxKmk?:number;

}
