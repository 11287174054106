import { DataModelBase } from "../../core/models/dataModelBase";

/**
 * マスターデータ ViewModel クラス
 */
export class TaxInfoCacheViewModel extends DataModelBase {
    /**  */
    public AccProKbn!: number;

    /**  */
    public CotKbn!: number;

    /**  */
    public SCalcKbn!: number;

    /**  */
    public BCalcKbn!: number;

    /**  */
    public KojyoKbn!: number;

    /**  */
    public UkeAdjust!: number;

    /**  */
    public HaraiAdjust!: number;

    /**  */
    public KaniKbn!: number;

    /**  */
    public BasedType!: number;

    /**  */
    public ShortKbn!: number;

    /**  */
    public MonthEnd!: number;

    /**  */
    public ImportKbn!: number;

    /**  */
    public UTaxKbn!: number;

    /**  */
    public STaxKbn!: number;

    /**  */
    public TaxKbn1!: number;

    /**  */
    public TaxKbn2!: number;

    /**  */
    public TaxKbn3!: number;

    /**  */
    public TaxKbn4!: number;

    /**  */
    public TaxKbn5!: number;

    /**  */
    public TaxKbn6!: number;

    /**  */
    public TaxKbn7!: number;

    /**  */
    public TaxKbn8!: number;

    /**  */
    public TaxKbn9!: number;

    /**  */
    public TaxKbn10!: number;

}

