import {MonthSelectVO} from "./monthSelectVO";
import {AuditResultCountVO} from "./auditResultCountVO";
/**
 * 監査結果一覧ViewModel ViewModel クラス
 */

export class AuditResultListViewModel {
    /** 月選択VO */
    public Months?:Array<MonthSelectVO>;

    /** 監査結果件数VO */
    public Counts?:Array<AuditResultCountVO>;

}
