import React from 'react';
import { Alert, AlertProps } from 'reactstrap';
import classnames from 'classnames';

const A3Alert: React.FC<AlertProps> = props => {
  const { className, isOpen, children } = props;
  let divClass = isOpen ? 'fadeInDown' : '';
  return (
    <div className={classnames(divClass, 'A3-alert animated faster position-absolute w-75 m-auto left-0 right-0')}>
      <Alert {...props} fade={false} className={classnames(className, 'm-0')}>
        {children}
      </Alert>
    </div>
  );
};
export default A3Alert;
