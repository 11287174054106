import React from 'react';

import classnames from 'classnames';
import { FormGroup, Label, Input } from 'reactstrap';

import { TargetTMonthEnum } from '../../../../constants/enums';
import { TargetTMonthEnum as TargetTMonthDataMaps } from '../../../../constants/dataMaps';
import { TMonthVO } from '../../../../models/tMonthVO';
import { getValueFromDataMap } from '../../../../utils/dataMapsUtils';
import { isNormalMonth } from '../../../../utils/monthlyUtils';
import AngleCircleFrameLine from '../../../atoms/AngleCircleFrameLine/AngleCircleFrameLine';

export type ProcessMonthListSelectionProps = React.HTMLAttributes<HTMLDivElement> & {
  TMonth?: TMonthVO[];
  targetTMonth?: TargetTMonthEnum;
  tMonthCustomize?: number[];
  disabled?: boolean;
  onChangeItem?: (targetTMonth: TargetTMonthEnum, tMonthCustomize: number[]) => void;
};

const targetRadios = [
  { id: TargetTMonthEnum.All, label: getValueFromDataMap(TargetTMonthDataMaps, TargetTMonthEnum.All) },
  { id: TargetTMonthEnum.Normal, label: getValueFromDataMap(TargetTMonthDataMaps, TargetTMonthEnum.Normal) },
  { id: TargetTMonthEnum.Account, label: getValueFromDataMap(TargetTMonthDataMaps, TargetTMonthEnum.Account) },
  { id: TargetTMonthEnum.Customize, label: getValueFromDataMap(TargetTMonthDataMaps, TargetTMonthEnum.Customize) }
];

const ProcessMonthListSelection: React.FC<ProcessMonthListSelectionProps> = props => {
  const { TMonth, targetTMonth, tMonthCustomize, disabled, className, onChangeItem } = props;

  const [monthlyList, setMonthlyList] = React.useState(TMonth);
  const [targetRadioSelect, setTargetRadioSelect] = React.useState(targetTMonth);
  const [isDisabled, setDisabled] = React.useState(disabled);
  const [isMonthlyDisabled, setMonthlyDisabled] = React.useState(
    isDisabled === true || !monthlyCustomize(targetRadioSelect)
  );

  // 実行対象月区分より対象の月度を返す処理
  const refreshCheckListSelects = (value: TargetTMonthEnum | undefined) => {
    const createCheckListSelects: number[] = [];
    switch (value) {
      case TargetTMonthEnum.All:
        monthlyList!.forEach(data => createCheckListSelects.push(data.NMonth!));
        break;
      case TargetTMonthEnum.Normal:
        monthlyList!.forEach(data => {
          if (isNormalMonth(data.NMonth!)) {
            createCheckListSelects.push(data.NMonth!);
          }
        });
        break;
      case TargetTMonthEnum.Account:
        monthlyList!.forEach(data => {
          if (!isNormalMonth(data.NMonth!)) {
            createCheckListSelects.push(data.NMonth!);
          }
        });
        break;
    }
    return createCheckListSelects;
  };
  const [checkListSelects, setCheckListSelects] = React.useState<number[]>(
    monthlyCustomize(targetTMonth) ? tMonthCustomize! : refreshCheckListSelects(targetTMonth)
  );

  React.useEffect(() => {
    const isMonthlyCustomize = monthlyCustomize(targetTMonth);
    setMonthlyList(TMonth);
    setTargetRadioSelect(targetTMonth);
    setMonthlyDisabled(isDisabled === true || !isMonthlyCustomize);
    if (isMonthlyCustomize) {
      setCheckListSelects(tMonthCustomize!);
    } else {
      setCheckListSelects(refreshCheckListSelects(targetTMonth));
    }
  }, [TMonth, targetTMonth, tMonthCustomize]);

  React.useEffect(() => {
    setDisabled(disabled);
    setMonthlyDisabled(disabled === true || !monthlyCustomize(targetRadioSelect));
  }, [disabled]);

  // ラジオボタン変更時の処理
  const handleOnTargetRadioChange = (targetTMonth: TargetTMonthEnum) => {
    const createCheckListSelects = refreshCheckListSelects(targetTMonth);

    setTargetRadioSelect(targetTMonth);
    setCheckListSelects(createCheckListSelects);
    setMonthlyDisabled(isDisabled === true || !monthlyCustomize(targetTMonth));
    onChangeItem!(targetTMonth, createCheckListSelects);
  };

  // チェックボックス変更時の処理
  const handleOnMonthlyCheckboxChange = (selectKey: number) => {
    const index = checkListSelects.indexOf(selectKey);
    if (index < 0) {
      checkListSelects.push(selectKey);
    } else {
      checkListSelects.splice(index, 1);
    }
    onChangeItem!(targetRadioSelect!, checkListSelects);
    setCheckListSelects(checkListSelects.slice());
  };

  // 実行対象月区分のラジオボタンを生成
  const radioList: React.ReactElement[] = [];
  targetRadios.forEach(data => {
    radioList.push(
      <FormGroup check className='mr-3' key={[data.id, 'radio'].join('-')}>
        <Label check>
          <Input
            type='radio'
            name='target'
            value={data.id}
            checked={targetRadioChecked(data.id, targetRadioSelect)}
            disabled={isDisabled}
            onChange={() => {
              handleOnTargetRadioChange(data.id);
            }}
          />
          {data.label}
        </Label>
      </FormGroup>
    );
  });

  // 月度チェックボックスの生成
  const checkLists: React.ReactElement[][] = [];
  if (monthlyList != undefined) {
    let checkListWork: React.ReactElement[];
    let oldKeyBreak = -1;
    let oldNormalMonthFlg = false;
    monthlyList.forEach(data => {
      const nMonth = data.NMonth!;
      const normalMonthFlg = isNormalMonth(nMonth);
      const keyBreak = Math.floor(nMonth / 10);
      if (oldKeyBreak !== keyBreak || oldNormalMonthFlg !== normalMonthFlg) {
        oldKeyBreak = keyBreak;
        oldNormalMonthFlg = normalMonthFlg;
        checkListWork = [];
        checkLists.push(checkListWork);
      }

      const checked = checkListSelects.indexOf(nMonth) >= 0;
      checkListWork.push(
        <FormGroup check className='mx-2' key={[data.NMonth, 'checkbox'].join('-')}>
          <Label check>
            <Input
              type='checkbox'
              name='monthly'
              value={nMonth}
              checked={checked}
              disabled={isMonthlyDisabled}
              onChange={() => {
                handleOnMonthlyCheckboxChange(nMonth);
              }}
            />
            <span className='d-inline-block monthly-label'>{data.MonthName}</span>
          </Label>
        </FormGroup>
      );
    });
  }

  const checkList: React.ReactElement[] = [];
  checkLists.forEach((data, index) => {
    checkList.push(
      <div className='d-flex flex-nowrap' key={[index, 'flex-nowrap'].join('-')}>
        {data}
      </div>
    );
  });

  // divタグに不要なpropsを除去
  const divProps = { ...props };
  delete divProps['TMonth'];
  delete divProps['targetTMonth'];
  delete divProps['tMonthCustomize'];
  delete divProps['onChangeItem'];
  delete divProps['onRadioChangeHoge'];

  return (
    <div {...divProps} className={classnames(className, 'process-month-selection')}>
      <div className='mb-2 d-flex flex-row'>{radioList}</div>
      <AngleCircleFrameLine className='d-flex flex-wrap'>{checkList}</AngleCircleFrameLine>
    </div>
  );
};

//ラジオボタンのチェック状態を返す
const targetRadioChecked = (value: TargetTMonthEnum, target: TargetTMonthEnum | undefined) => value === target;

// 実行対象月より月度選択をするかを返す
const monthlyCustomize = (targetTMonth: TargetTMonthEnum | undefined) => targetTMonth === TargetTMonthEnum.Customize;

export default ProcessMonthListSelection;
