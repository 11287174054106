import { MjsUtilities } from "../../common/usefuls/utilities";
import { NumericInput } from "../../controls/userControls/inputControls/numericInput";
import { AnalyzeCode, AuxiliaryAccountCanAdoptedKbn, BmnClassification, BPKbn, CleaningTargetKbn, ExcludedInputKbn, ExeKmkCode, HojyoKbn, ImportKbn, InputKbn, MasterKbn, MasterSumKbn, MigrationConvertCategory, MigrationConvertItem, MigrationConvertItemTaxCodeKey, MigrationFileStatus, MigrationFileType, MigrationHojyoTabKbn, MigrationKisyuCloudItem, MigrationHojyoKisyuCloudItem, MigrationBmnKisyuCloudItem, MigrationMappingSettingKbn, MigrationSettingSaveStatus, MigrationStep, MigrationSwkCloudItem, MigrationSystemKbn, PartitionRecordKbn, RecordKbn, Status, SumAnaCode, SundryInfo, TaxInfoAccProKbn, TaxKbn, TaxRate, TkInputKbn, UseKbn, MigrationErrStatus, UchizeiAutoCalcKbn } from "../../core/constants/constant";
import { KbnInfoCacheViewModel } from "../../models/cache/kbnInfoCacheViewModel";
import { TaxInfoCacheViewModel } from "../../models/cache/taxInfoCacheViewModel";
import { MigrationFileInfoViewModel } from "../../models/process/migrationFileInfoViewModel";
import { MigrationHojyoSettingViewModel } from "../../models/process/migrationHojyoSettingViewModel";
import { MigrationHojyoGroupViewModel } from "../../models/process/migrationHojyoGroupViewModel";
import { MigrationItemConvertSettingDetailViewModel } from "../../models/process/migrationItemConvertSettingDetailViewModel";
import { MigrationItemConvertSettingGroupViewModel } from "../../models/process/migrationItemConvertSettingGroupViewModel";
import { MigrationItemSettingGroupViewModel } from "../../models/process/migrationItemSettingGroupViewModel";
import { MigrationJournalSettingViewModel } from "../../models/process/migrationJournalSettingViewModel";
import { MigrationKmkSettingViewModel } from "../../models/process/migrationKmkSettingViewModel";
import { MigrationSettingDetailViewModel } from "../../models/process/migrationSettingDetailViewModel";
import { MigrationStepCheckResultViewModel } from "../../models/process/migrationStepCheckResultViewModel";
import { Button, SimpleButton } from "../../support/component/button/simpleButton";
import { AlertDialog, ConfirmDialog } from "../../support/component/overlay/abstractDialog";
import { Mediator, mediatorAfterLastItemBlurArgs, mediatorBeforeBlurArgs, mediatorBeforeFocusArgs, MediatorGeneralItem, MediatorItem } from "../../support/mediator";
import { ProcessMigrationHeader } from "../../support/pageHeaders/processMigrationHeader";
import { AbstractPage } from "../abstract";
import { CurrencyInput } from "../../controls/userControls/inputControls/currencyInput";
import { ProgressConfirmDialog } from "../../dialogs/progressConfirmDialog";
import { ExplorerClosedEventArgs, ExplorerUI } from "../../controls/userControls/explorer";
import { ExplorerUIExpansion } from "../../controls/userControls/explorerExpansion";
import { CacheDataType } from "../../core/constants/enums";
import { DTMAINCacheViewModel } from "../../models/cache/dTMAINCacheViewModel";
import { MasterInfoCacheViewModel } from "../../models/cache/masterInfoCacheViewModel";
import { KmkMACacheViewModel } from "../../models/cache/kmkMACacheViewModel";
import { KmkInfoCacheViewModel } from "../../models/cache/kmkInfoCacheViewModel";
import { HojyoMACacheViewModel } from "../../models/cache/hojyoMACacheViewModel";
import { PageInitializeParameter } from "../../pageController";
import { MigrationInitialViewModel } from "../../models/process/migrationInitialViewModel";
import { MigrationItemSettingDetailViewModel } from "../../models/process/migrationItemSettingDetailViewModel";
import { MigrationItemSettingViewModel } from "../../models/process/migrationItemSettingViewModel";
import { MjsDelegateContainer } from "../../common/usefuls/delegate";
import { KeyValueViewModel } from "../../models/common/keyValueViewModel";
import { DTMAINCacheContainer } from "../../models/cache/dTMAINCacheContainer";
import { TaxInfoCacheContainer } from "../../models/cache/taxInfoCacheContainer";
import { KbnInfoCacheContainer } from "../../models/cache/kbnInfoCacheContainer";
import { MasterInfoCacheContainer } from "../../models/cache/masterInfoCacheContainer";
import { KmkMA_KmkCacheContainer } from "../../models/cache/kmkMA_KmkCacheContainer";
import { KmkMA_SubCacheContainer } from "../../models/cache/kmkMA_SubCacheContainer";
import { KmkInfoCacheContainer } from "../../models/cache/kmkInfoCacheContainer";
import { HojyoMA_BankCacheContainer } from "../../models/cache/hojyoMA_BankCacheContainer";
import { HojyoMA_ClientCacheContainer } from "../../models/cache/hojyoMA_ClientCacheContainer";
import { HojyoMA_StaffCacheContainer } from "../../models/cache/hojyoMA_StaffCacheContainer";
import { HojyoMA_GenHojo1CacheContainer } from "../../models/cache/hojyoMA_GenHojo1CacheContainer";
import { HojyoMA_GenHojo2CacheContainer } from "../../models/cache/hojyoMA_GenHojo2CacheContainer";
import { HojyoMA_GenHojo3CacheContainer } from "../../models/cache/hojyoMA_GenHojo3CacheContainer";
import { HojyoMA_GenHojo4CacheContainer } from "../../models/cache/hojyoMA_GenHojo4CacheContainer";
import { HojyoMA_GenHojo5CacheContainer } from "../../models/cache/hojyoMA_GenHojo5CacheContainer";
import { HojyoMA_BmnCacheContainer } from "../../models/cache/hojyoMA_BmnCacheContainer";
import { HojyoMA_SegCacheContainer } from "../../models/cache/hojyoMA_SegCacheContainer";
import { HojyoMA_KojiCacheContainer } from "../../models/cache/hojyoMA_KojiCacheContainer";
import { Ajax, AjaxSettings } from "../../core/ajax";
import { UserAgentUtil } from "../../common/usefuls/useragentUtil";
import { MigrationUploadViewModel } from "../../models/process/migrationUploadViewModel";
import { MigrationDeleteFileParamViewModel } from "../../models/process/migrationDeleteFileParamViewModel";
import { MigrationFileImportViewModel } from "../../models/process/migrationFileImportViewModel";
import { MigrationStepInfoViewModel } from "../../models/process/migrationStepInfoViewModel";
import { MigrationItemConvertSettingViewModel } from "../../models/process/migrationItemConvertSettingViewModel";
import { MigrationOpeningBalanceViewModel } from "../../models/process/migrationOpeningBalanceViewModel";
import { DataModelBase } from "../../core/models/dataModelBase";
import { MigrationErrViewModel } from "../../models/process/migrationErrViewModel";
import { BizUtil } from "../../common/Biz/bizUtil";
import { MigrationKmkGroupViewModel } from "../../models/process/migrationKmkGroupViewModel";
import { Messages } from "../../common/message";
import { messageUtil } from "../../common/messageUtil";
import { AbstractGrid, GridOption } from "../../support/component/grid/abstractGrid";
import { StringInput } from "../../controls/userControls/inputControls/stringInput";
import * as wijmo from 'wijmo/wijmo';
import * as wjGrid from 'wijmo/wijmo.grid';
import * as wjInput from 'wijmo/wijmo.input';
import $ from 'jquery';
import 'bootstrap/js/dist/tooltip.js';
import "../../common/usefuls/utilities";
import { WindowOption, WindowUtil } from "../../support/component/drillDown/windowUtil";
import { LinkButton } from "../../support/component/button/linkButton";
import { TooltipOptions } from "bootstrap";

// #region interface
export interface settingKmkGrpData {
    GroupNo: number,
    GroupName: string,
    KmkSettingDetails: settingKmkDetailData[],
}

export interface settingKmkDetailData {
    GroupNo: number,
    DetailNo: number,
    SrcKmkCode: string,
    SrcKmkName: string,
    DstKmkCode: number,
    OpeningBalance: number,
    IsEdited: boolean
}

export interface settingHojyoDetailData {
    DetailNo: number,
    SrcHojyoKbn: number,
    SrcKmkCode: string,
    SrcKmkName: string,
    SrcHojyoCode: string,
    SrcHojyoName: string,
    SrcHojyoNameDisp: string,
    SrcHojyoRenChar: string,
    BmnBPKbn: number,
    DstKmkCode: number,
    DstKmkHojyoCode: number,
    DstHojyoCode: string,
    HowRegist: number,
    MappingSettingKbn: number,
    OpeningBalance: number,
    IsEdited: boolean
    AuxiliaryAccountCanAdoptedKbn: number
}

export interface settingSwkData {
    SwkInfoList: swkInfoData[],
    TotalJournalAmount: number,
    TotalErrorAmount: number,
    ErrCancelFlg: boolean,
}

export interface swkInfoData {
    CheckStatus: boolean,
    YearMonth: string,
    JournalAmount: number,
    ErrorAmount: number,
    ErrorMessage: string,
    isMultiErr: boolean,
}

export interface newRegisterData {
    SrcKmkCode: string,
    SrcHojyoCode: string,
    DstKmkCode: number,
    DstHojyoKbn1: number,
    DstHojyoKbn2: number,
    SrcHojyoName: string,
    SrcHojyoRenChar: string,
    DstKmkHojyoCode: number,
    DstHojyoCode: string,
}
// #endregion interface

export class ProcessMigration extends AbstractPage {

    private $scope: any;
    private $compile: any;
    private $timeout: any;
    private $window: any;

    // dialog
    private static readonly dlgAlertMsg_FileCntOver: string = "指定されたファイルの数が4件を超えるためインポートできません。\nもう一度、手順に従ってエクスポートしてください。";
    private static readonly dlgAlertMsg_FileSizeOver: string = "指定されたファイルの合計サイズが50MBを超えるためアップロードできません。\n一度に取り込むファイル数を減らして、もう一度アップロードしてください。";
    private static readonly dlgAlertMsg_SwkImportErr: string = "仕訳インポート中に想定外のエラーが発生しました。\nしばらく時間をおいてからお試しください。";
    private static readonly dlgConfirmMsg_Close: string = "画面の内容が変更されていますが保存されていません。\n画面を移動すると入力内容が破棄されます。\nよろしいですか？";
    private static readonly dlgConfirmMsg_DeletePreSetting: string = "前回インポートしたファイルとフォーマットが異なるため、保存されている設定が全て失われます。\nよろしいですか？\n※この処理には数分かかる場合があります。";
    private static readonly dlgConfirmMsg_DeleteItemSetting: string = "項目の設定を変更すると、既に保存されている設定が全て失われます。\nよろしいですか？\n※この処理には数分かかる場合があります。";
    private static readonly dlgConfirmMsg_ConfirmTaxKbn: string = "金額の設定で税抜きでインポートが設定されていますが、項目に税額が設定されていません。\nこのまま仕訳をインポートすると金額が正しくインポートされない可能性があります。\nよろしいですか？";
    private static readonly dlgConfirmMsg_ConfirmSetting: string = "補助科目の設定を既に保存しています。\n変更する科目に科目別補助がある場合、その科目別補助の設定が初期化されますが、\nよろしいですか？";
    private static readonly dlgConfirmMsg_ConfirmSwkImport: string = "設定が変更されています。\n最後に保存された設定で仕訳のインポートを行います。\nよろしいですか？";
    private alertDialog_FileCntOver: AlertDialog = null!;     // ファイルアップロード件数警告ダイアログ
    private alertDialog_FileSizeOver: AlertDialog = null!;    // ファイルアップロードサイズ警告ダイアログ
    private alertDialog_SwkImportErr: AlertDialog = null!;    // 仕訳インポートエラーダイアログ
    private confirmDialog_Close: ConfirmDialog = null!;             // 閉じる確認ダイアログ
    private confirmDialog_DeletePreSetting: ConfirmDialog = null!;  // 前回設定削除確認ダイアログ
    private confirmDialog_DeleteItemSetting: ConfirmDialog = null!; // 項目設定削除確認ダイアログ
    private confirmDialog_ConfirmTaxKbn: ConfirmDialog = null!;     // 金額設定確認ダイアログ
    private confirmDialog_ConfirmSetting: ConfirmDialog = null!;    // 対応設定変更確認ダイアログ
    private confirmDialog_ConfirmSwkImport: ConfirmDialog = null!;  // 仕訳インポート確認ダイアログ

    // header
    private header!: ProcessMigrationHeader;

    // ViewModel
    private m_SystemKbn!: number;
    private m_StepCheckResult!: MigrationStepCheckResultViewModel;
    private m_CurrentStep!: number;
    private m_FileInfos: MigrationFileInfoViewModel[] = null!;
    private m_ItemSettingGroup: MigrationItemSettingGroupViewModel = null!;
    private m_ItemConvertSettingGroup: MigrationItemConvertSettingGroupViewModel = null!;
    private m_TaxKbn!: number;
    private m_UchizeiAutoCalcKbn!: number;
    private m_KmkSetting: MigrationKmkSettingViewModel = null!;
    private m_HojyoSetting: MigrationHojyoSettingViewModel = null!;
    private m_HojyoSettingHojyoList: MigrationSettingDetailViewModel[] = null!;
    private m_HojyoSettingGeneralHojyoList: MigrationSettingDetailViewModel[] = null!;
    private m_HojyoSettingBmnList: MigrationSettingDetailViewModel[] = null!;
    private m_HojyoSettingSegmentList: MigrationSettingDetailViewModel[] = null!;
    private m_HojyoSettingKoujiList: MigrationSettingDetailViewModel[] = null!;
    private m_JournalSetting: MigrationJournalSettingViewModel = null!;
    private m_BatchRequestId!: number;

    private dispStepNoMap: { [key: number]: number; } = {}; // key:MigrationStep, value:dispStepNumber
    private isRefreshBtnClick: boolean = false; // 表示を更新するボタンからの呼び出しかどうか
    private isHojyoKmkSettingSaveBtnClick: boolean = false; // 対応設定を保存するボタンからの呼び出しかどうか
    private isSwkImportBtnClick: boolean = false; // 仕訳をインポートするボタンからの呼び出しかどうか
    private static inputFilterRegexpXSS: RegExp = RegExp("[<>&\"'\x00-\x1f\x7f]+"); // <,>,&,",',制御文字(0x00～0x1f, 0x7f)は入力禁止
    private static inputFilterRegexpXSSDell: RegExp = RegExp("^[^<>&\"'\x00-\x1f\x7f]*$"); // <,>,&,",',制御文字(0x00～0x1f, 0x7f)は入力禁止なので削除する
    private scrollOK: boolean = false; // true:スクロール可能
    private scrollTimerid: any; // スクロール可能か観測する
    private oldStep!: number;

    // mediator
    private mediator!: Mediator;
    private topStepMediatorMap: { [key: number]: MediatorItem; } = {}; // key:MigrationStep, value:topStepMediator

    // stepFileImport
    private isFileImport!: boolean; // ファイルインポート済みかどうか visibleBtnSuccessFileImport()で更新する
    private dragEventObject!: FileList; // ファイルドラッグ用の一時変数
    private fileImportBtn!: Button;
    private fileImport_clickEventInProg!: boolean; // シングルクリック処理中フラグ(ダブルクリック対策用)

    // stepItemSetting(ItemSetting)
    private taxInfoDataCacheForItemSetting!: TaxInfoCacheViewModel;
    private kbnInfoDataCacheForItemSetting!: KbnInfoCacheViewModel;
    private kisyuStartRowNo!: NumericInput;
    private hojyoKisyuStartRowNo!: NumericInput;
    private bmnKisyuStartRowNo!: NumericInput;
    private swkStartRowNo!: NumericInput;
    private mediator_radioTaxIncluded!: MediatorItem;
    private mediator_radioTaxExcluded!: MediatorItem;
    private mediator_uchizeiAutoCalcKbnOn!: MediatorItem;
    private mediator_uchizeiAutoCalcKbnOff!: MediatorItem;
    private itemSettingKisyuColumCmbList: wjInput.ComboBox[] = [];
    private itemSettingHojyoKisyuColumCmbList: wjInput.ComboBox[] = [];
    private itemSettingBmnKisyuColumCmbList: wjInput.ComboBox[] = [];
    private itemSettingSwkColumCmbList: wjInput.ComboBox[] = [];
    private kisyuFileContentGrid!: fileContentGrid;
    private hojyoKisyuFileContentGrid!: fileContentGrid;
    private bmnKisyuFileContentGrid!: fileContentGrid;
    private swkFileContentGrid!: fileContentGrid;
    private itemSettingSaveBtn!: Button;
    private itemSettingErrLogDownLoadBtn!: Button;
    private isMigrationItemSettingGroupSaved!: boolean; // 項目設定保存処理を呼び出した場合にtrueとなる（更新抑制フラグ）
    private mediator_stepItemSettingMediatorsKisyuOK: boolean = false; // true:mediator作成可能
    private mediator_stepItemSettingMediatorsHojyoKisyuOK: boolean = false; // true:mediator作成可能
    private mediator_stepItemSettingMediatorsBmnKisyuOK: boolean = false; // true:mediator作成可能
    private mediator_stepItemSettingMediatorsSwkOK: boolean = false; // true:mediator作成可能
    private mediator_stepItemSettingTimerid: any; // mediator作成可能か観測する
    private isItemSettingSaveBtnClick: boolean = false; // 項目設定を保存するボタンからの呼び出しかどうか

    // stepItemSetting(ItemConvertSetting)
    private input_cmbKessans: { [key: string]: wjInput.ComboBox; } = {};     // key:BeforeValue
    private input_cmbTaxKbns: { [key: string]: wjInput.AutoComplete; } = {}; // key:BeforeValue
    private taxKbnExplorer!: ExplorerUIExpansion;
    private input_cmbTaxRates: { [key: string]: wjInput.ComboBox; } = {};    // key:BeforeValue
    private input_cmbTypeCds: { [key: string]: wjInput.ComboBox; } = {};     // key:BeforeValue
    private input_cmbIvJigyoKbns: { [key: string]: wjInput.ComboBox; } = {};     // key:BeforeValue
    private itemConvertSettingSaveBtn!: Button;
    private registKmkBtn!: Button;
    private refreshBtn!: Button;
    private mediator_stepItemSettingMediatorsKessanOK: boolean = false; // true:mediator作成可能
    private mediator_stepItemSettingMediatorsTaxKbnOK: boolean = false; // true:mediator作成可能
    private mediator_stepItemSettingMediatorsTaxRateOK: boolean = false; // true:mediator作成可能
    private mediator_stepItemSettingMediatorsGyosyuOK: boolean = false; // true:mediator作成可能
    private mediator_stepItemSettingMediatorsIvJigyoKbnOK: boolean = false; // true:mediator作成可能
    private sTaxCodeKeyList: number[] = [  // 売上消費税コードキー
        MigrationConvertItemTaxCodeKey.TaxationSalesIncluded,
        MigrationConvertItemTaxCodeKey.TaxationSalesSubtractionIncluded,
        MigrationConvertItemTaxCodeKey.TaxationSalesLoanlossRepaymentIncluded,
        MigrationConvertItemTaxCodeKey.TaxationSalesLoanlossCollectionIncluded,
        MigrationConvertItemTaxCodeKey.TaxationSalesExcluded,
        MigrationConvertItemTaxCodeKey.TaxationSalesSubtractionExcluded,
        MigrationConvertItemTaxCodeKey.TaxationSalesLoanlossRepaymentExcluded,
        MigrationConvertItemTaxCodeKey.TaxationSalesLoanlossCollectionExcluded,
        MigrationConvertItemTaxCodeKey.ExemptionSales,
        MigrationConvertItemTaxCodeKey.ValuablePapersSalesExemption,
        MigrationConvertItemTaxCodeKey.TaxationLessnessSales,
        MigrationConvertItemTaxCodeKey.ExportSales,
        MigrationConvertItemTaxCodeKey.ExemptionExportSales,
        MigrationConvertItemTaxCodeKey.Unclear,
    ];
    private sTaxCodeKeyListByUseGyosyu: number[] = [ // 業種が使用できる売上消費税コードキー
        MigrationConvertItemTaxCodeKey.TaxationSalesIncluded,
        MigrationConvertItemTaxCodeKey.TaxationSalesSubtractionIncluded,
        MigrationConvertItemTaxCodeKey.TaxationSalesExcluded,
        MigrationConvertItemTaxCodeKey.TaxationSalesSubtractionExcluded,
    ];
    private taxRatePatternUnusable: number[] = []; // 利用不可
    private taxRatePatternUsable: number[] = [ // 条件なし
        TaxRate.None,
        TaxRate.TenPercent,
        TaxRate.ReducedEightPercent,
        TaxRate.EightPercent,
        TaxRate.FivePercent,
        TaxRate.ThreePercent,
        TaxRate.SevenPointEightPercent,
        TaxRate.SixPointTwoFourPercent,
        TaxRate.SixPointThreePercent,
        TaxRate.FourPercent,
        TaxRate.TwoPointTwoPercent,
        TaxRate.OnePointSevenSixPercent,
        TaxRate.OnePointSevenPercent,
        TaxRate.OnePercent
    ];
    private taxRatePatternRate: number[] = [ // 一般
        TaxRate.None,
        TaxRate.TenPercent,
        TaxRate.ReducedEightPercent,
        TaxRate.EightPercent,
        TaxRate.FivePercent,
        TaxRate.ThreePercent
    ];
    private taxRatePatternRateThreeUnusable: number[] = [ // 3%不可
        TaxRate.None,
        TaxRate.TenPercent,
        TaxRate.ReducedEightPercent,
        TaxRate.EightPercent,
        TaxRate.FivePercent
    ]
    private taxRatePatternRateEightTenUsable: number[] = [ // 8%10%
        TaxRate.None,
        TaxRate.TenPercent,
        TaxRate.EightPercent
    ];
    private taxRatePatternStateRate: number[] = [ // 国税
        TaxRate.None,
        TaxRate.SevenPointEightPercent,
        TaxRate.SixPointTwoFourPercent,
        TaxRate.SixPointThreePercent,
        TaxRate.FourPercent
    ];
    private taxRatePatternLocalRate: number[] = [ // 地方税
        TaxRate.None,
        TaxRate.TwoPointTwoPercent,
        TaxRate.OnePointSevenSixPercent,
        TaxRate.OnePointSevenPercent,
        TaxRate.OnePercent
    ];
    private taxCodeTaxRateMap: { [key: number]: number[]; } = {};

    // stepKmkSetting
    private clickEventInProg!: boolean; // シングルクリック処理中フラグ(ダブルクリック対策用)
    private isKmkSettingSave: boolean = false; // 対応設定が保存済みかどうか visibleBtnSuccessKmkSettingSave()で更新する
    private isKmkKisyuImported: boolean = false;
    private m_kmkSettingDatas: settingKmkGrpData[] = []; // ⇔ m_KmkSetting
    private kmkSettingExplorer!: ExplorerUI;
    private input_cmbDstkmks: { [key: string]: wjInput.AutoComplete; } = {};    // key:groupNo_detailNo
    private input_inpKmkSums: { [key: string]: CurrencyInput; } = {}; // key:groupNo_detailNo
    private kmkSettingSaveBtn!: Button;
    private kmkKisyuImportBtn!: Button;
    private mediator_stepKmkSettingMediatorsOK: boolean = false; // true:mediator作成可能
    private mediator_stepKmkSettingTimerid: any; // mediator作成可能か観測する

    // stepHojyoSetting
    private isHojyoKmkSettingSave: boolean = false; // 対応設定が保存済みかどうか visibleBtnSuccessHojyoKmkSettingSave()で更新する
    private isHojyoKisyuImported: boolean = false;
    private isGeneralHojyoSettingSave: boolean = false; // 対応設定が保存済みかどうか visibleBtnSuccessGeneralHojyoSettingSave()で更新する
    private isBmnSettingSave: boolean = false; // 対応設定が保存済みかどうか visibleBtnSuccessBmnSettingSave()で更新する
    private isSegmentSettingSave: boolean = false; // 対応設定が保存済みかどうか visibleBtnSuccessSegmentSettingSave()で更新する
    private isKoujiSettingSave: boolean = false; // 対応設定が保存済みかどうか visibleBtnSuccessKoujiSettingSave()で更新する
    private currentTab: number = MigrationHojyoTabKbn.Hojyo;
    private hojyokmkSettingSaveIsErr: boolean = false; // 対応設定保存ajax通信エラーが発生したかどうか
    private m_hojyokmkSettingDatas: settingHojyoDetailData[] = []; // ⇔ m_HojyoSettingHojyoList
    private m_generalhojyoSettingDatas: settingHojyoDetailData[] = []; // ⇔ m_HojyoSettingGeneralHojyoList
    private m_bmnSettingDatas: settingHojyoDetailData[] = [];      // ⇔ m_HojyoSettingBmnList
    private m_segmentSettingDatas: settingHojyoDetailData[] = [];      // ⇔ m_HojyoSettingSegmentList
    private m_koujiSettingDatas: settingHojyoDetailData[] = [];      // ⇔ m_HojyoSettingKoujiList
    private newRegisterHojyoList: newRegisterData[] = []; // 新規登録の重複登録考慮用キャッシュ(補助)
    private newRegisterGeneralHojyoList: newRegisterData[] = []; // 新規登録の重複登録考慮用キャッシュ(取引先)
    private newRegisterBmnList: newRegisterData[] = []; // 新規登録の重複登録考慮用キャッシュ(部門)
    private newRegisterSegmentList: newRegisterData[] = []; // 新規登録の重複登録考慮用キャッシュ(セグメント)
    private newRegisterKoujiList: newRegisterData[] = []; // 新規登録の重複登録考慮用キャッシュ(工事)
    private input_cmbHowRegistHojyos: { [key: number]: wjInput.ComboBox; } = {};  // key:detailNo
    private input_cmbHowRegistGeneralHojyos: { [key: number]: wjInput.ComboBox; } = {};  // key:detailNo
    private input_cmbHowRegistBmns: { [key: number]: wjInput.ComboBox; } = {};    // key:detailNo
    private input_cmbHowRegistSegments: { [key: number]: wjInput.ComboBox; } = {};    // key:detailNo
    private input_cmbHowRegistKoujis: { [key: number]: wjInput.ComboBox; } = {};    // key:detailNo
    private input_cmbSelectHojyos: { [key: number]: wjInput.ComboBox; } = {};     // key:detailNo
    private input_cmbSelectGeneralHojyos: { [key: number]: wjInput.ComboBox; } = {};     // key:detailNo
    private input_cmbSelectBmns: { [key: number]: wjInput.ComboBox; } = {};       // key:detailNo
    private input_cmbSelectSegments: { [key: number]: wjInput.ComboBox; } = {};       // key:detailNo
    private input_cmbSelectKoujis: { [key: number]: wjInput.ComboBox; } = {};       // key:detailNo
    private input_inpHojyoSums: { [key: number]: CurrencyInput; } = {}; // key:detailNo
    private hojyokmkSettingSaveBtn!: Button;
    private hojyokmkKisyuImportBtn!: Button;
    private hojyokmkKisyuCheckBtn!: Button;
    private bmnKisyuCheckBtn!: Button;
    private kisyuImportErrLogDownLoadBtn!: Button;
    private mediator_stepHojyoSettingMediatorsOK: boolean = false; // true:mediator作成可能
    private mediator_stepHojyoSettingMediatorsHojyoOK: boolean = false; // true:mediator作成可能
    private mediator_stepHojyoSettingMediatorsGeneralHojyoOK: boolean = false; // true:mediator作成可能
    private mediator_stepHojyoSettingMediatorsBmnOK: boolean = false;   // true:mediator作成可能
    private mediator_stepHojyoSettingMediatorsSegmentOK: boolean = false;   // true:mediator作成可能
    private mediator_stepHojyoSettingMediatorsKoujiOK: boolean = false;   // true:mediator作成可能
    private mediator_stepHojyoSettingMediatorsHojyo: MediatorItem[] = [];
    private mediator_stepHojyoSettingMediatorsGeneralHojyo: MediatorItem[] = [];
    private mediator_stepHojyoSettingMediatorsBmn: MediatorItem[] = [];
    private mediator_stepHojyoSettingMediatorsSegment: MediatorItem[] = [];
    private mediator_stepHojyoSettingMediatorsKouji: MediatorItem[] = [];
    private mediator_stepHojyoSettingTimerid: any; // mediator作成可能か観測する

    // stepSwkImport
    private isSwkInfoSave: boolean = false; // 仕訳情報が保存済みかどうか visibleBtnSuccessSwkImport()で更新する
    // <settingSwkData>this.$scope.swkSetting ⇔ m_JournalSetting
    private swkImportBtn!: Button;
    private swkErrLogDownLoadBtn!: Button;
    private timerid: any = null!; // 仕訳インポートの進捗を観測する
    private batchCanceling!: boolean; // 仕訳インポートキャンセル中
    private progressBarDialog: ProgressConfirmDialog = null!;
    private mediator_stepSwkImportMediatorsOK: boolean = false;   // true:mediator作成可能
    private mediator_stepSwkImportMediators: MediatorItem[] = [];
    private mediator_stepSwkImportTimerid: any; // mediator作成可能か観測する

    protected get targetCacheTypes(): CacheDataType[] {
        return [
            CacheDataType.DTMAIN,
            CacheDataType.TaxInfo,
            CacheDataType.KbnInfo,
            CacheDataType.MasterInfo,
            CacheDataType.KmkMA_Kmk,
            CacheDataType.KmkMA_Sub,
            CacheDataType.KmkInfo,
            CacheDataType.HojyoMA_Bank,
            CacheDataType.HojyoMA_Client,
            CacheDataType.HojyoMA_Staff,
            CacheDataType.HojyoMA_GenHojo1,
            CacheDataType.HojyoMA_GenHojo2,
            CacheDataType.HojyoMA_GenHojo3,
            CacheDataType.HojyoMA_GenHojo4,
            CacheDataType.HojyoMA_GenHojo5,
            CacheDataType.HojyoMA_Bmn,
            CacheDataType.HojyoMA_Seg,
            CacheDataType.HojyoMA_Koji,
        ];
    }

    private dtMainDataCache!: Array<DTMAINCacheViewModel>;
    private taxInfoDataCache!: Array<TaxInfoCacheViewModel>;
    private kbnInfoDataCache!: Array<KbnInfoCacheViewModel>;
    private masterInfoDataCache!: Array<MasterInfoCacheViewModel>;
    private kmkMaKmkDataCache!: Array<KmkMACacheViewModel>;
    private kmkMaSubDataCache!: Array<KmkMACacheViewModel>;
    private kmkInfoDataCache!: Array<KmkInfoCacheViewModel>;
    private bankDataCache!: Array<HojyoMACacheViewModel>;
    private clientDataCache!: Array<HojyoMACacheViewModel>;
    private staffDataCache!: Array<HojyoMACacheViewModel>;
    private general1DataCache!: Array<HojyoMACacheViewModel>;
    private general2DataCache!: Array<HojyoMACacheViewModel>;
    private general3DataCache!: Array<HojyoMACacheViewModel>;
    private general4DataCache!: Array<HojyoMACacheViewModel>;
    private general5DataCache!: Array<HojyoMACacheViewModel>;
    private bmnDataCache!: Array<HojyoMACacheViewModel>;
    private segmentDataCache!: Array<HojyoMACacheViewModel>;
    private koujiDataCache!: Array<HojyoMACacheViewModel>;

    private static readonly TOOLTIP_OPTIONS: TooltipOptions = {
        container: '#migration-root'
    };

    /**
     * 「閉じる」押下後のアクションを指定
     */
    public onClose = () => {};

    /**
     * ページのイニシャライズを行う。
     * この関数は、pageController.tsで実行されるProcessMigration.initialize(parameter)処理の一つとしてコールされる。
     */
    public pageInitialize(parameter: PageInitializeParameter): void {
        var viewModel: MigrationInitialViewModel = <MigrationInitialViewModel>parameter.$scope.viewModel;

        this.$scope = parameter.$scope;
        this.$compile = parameter.$compile;
        this.$timeout = parameter.$timeout;
        this.$window = parameter.$window;

        // BootstrapのTooltipsを初期化する
        $('[data-toggle="tooltip"]').tooltip(ProcessMigration.TOOLTIP_OPTIONS).off("show.bs.tooltip").on("show.bs.tooltip", this.onShowToolTip.bind(this));

        this.header = this.getHeader<ProcessMigrationHeader>();

        this.m_SystemKbn = viewModel.SystemKbn;
        this.m_StepCheckResult = viewModel.StepCheckResult;
        this.m_CurrentStep = viewModel.CurrentStep;
        this.m_FileInfos = viewModel.FileInfos != null ? viewModel.FileInfos : [];
        this.m_ItemSettingGroup = viewModel.ItemSettingGroup;
        this.m_ItemConvertSettingGroup = viewModel.ItemConvertSettingGroup;
        if (this.m_ItemSettingGroup != null) {
            let errDetailList: MigrationErrViewModel[] = [];
            for (let setting of this.m_ItemSettingGroup.MigrateItemSettingList) {
                errDetailList = errDetailList.concat(setting.MigrateItemSettingErrDetailList);
            }
            // 項目設定にエラーがある場合、項目変換設定は表示しない
            let isItemSettingErr = errDetailList.any((n: { Status: string; }) => n.Status == MigrationErrStatus.Error);
            if (isItemSettingErr) {
                this.m_ItemConvertSettingGroup = null!;
            }
        }
        this.m_TaxKbn = viewModel.ItemSettingGroup != null && viewModel.ItemSettingGroup.MigrateItemSettingList.singleOrNull((n: { FileType: number; }) => n.FileType == MigrationFileType.Swk) != null ? viewModel.ItemSettingGroup.MigrateItemSettingList.singleOrNull((n: { FileType: number; }) => n.FileType == MigrationFileType.Swk).TaxInc : null!;
        this.m_UchizeiAutoCalcKbn = viewModel.ItemSettingGroup != null && viewModel.ItemSettingGroup.MigrateItemSettingList.singleOrNull((n: { FileType: number; }) => n.FileType == MigrationFileType.Swk) != null ? viewModel.ItemSettingGroup.MigrateItemSettingList.singleOrNull((n: { FileType: number; }) => n.FileType == MigrationFileType.Swk).UchizeiAutoCalcKbn : null!;
        this.m_KmkSetting = viewModel.KmkSetting;
        this.m_HojyoSetting = viewModel.HojyoSetting;
        this.m_HojyoSettingHojyoList = [];
        this.m_HojyoSettingGeneralHojyoList = [];
        this.m_HojyoSettingBmnList = [];
        this.m_HojyoSettingSegmentList = [];
        this.m_HojyoSettingKoujiList = [];
        if (viewModel.HojyoSetting != null) {
            for (let setting of this.m_HojyoSetting.HojyoGroupList) {
                switch (setting.HojyoTabKbn) {
                    case MigrationHojyoTabKbn.Hojyo: this.m_HojyoSettingHojyoList = setting.HojyoList; this.setHojyoKisyuImportrLog(setting); break;
                    case MigrationHojyoTabKbn.GeneralHojyo: this.m_HojyoSettingGeneralHojyoList = setting.HojyoList; break;
                    case MigrationHojyoTabKbn.Bmn: this.m_HojyoSettingBmnList = setting.HojyoList; this.setBmnKisyuImportErrLog(setting); break;
                    case MigrationHojyoTabKbn.Segment: this.m_HojyoSettingSegmentList = setting.HojyoList; break;
                    case MigrationHojyoTabKbn.Kouji: this.m_HojyoSettingKoujiList = setting.HojyoList; break;
                }
            }
        }
        this.m_JournalSetting = viewModel.JournalSetting;
        this.m_BatchRequestId = viewModel.JournalSetting != null ? viewModel.JournalSetting.BatchRequestId : null!;

        this.fileImportBtn = new Button("#file-import-btn");
        this.itemSettingSaveBtn = new Button("#itemSetting-save-btn");
        this.itemSettingErrLogDownLoadBtn = new Button("#itemsetting-errlog-download-btn");
        this.kisyuImportErrLogDownLoadBtn = new Button("#kisyu-import-errlog-download-btn");
        this.itemConvertSettingSaveBtn = new Button("#itemConvertSetting-save-btn");
        this.registKmkBtn = new Button("#registKmk-btn");
        this.refreshBtn = new Button("#refresh-btn");
        this.kmkSettingSaveBtn = new Button("#kmkSetting-save-btn");
        this.kmkKisyuImportBtn = new Button("#kmkkisyu-import-btn");
        this.hojyokmkSettingSaveBtn = new Button("#hojyokmkSetting-save-btn");
        this.hojyokmkKisyuImportBtn = new Button("#hojyokmkkisyu-import-btn");
        this.hojyokmkKisyuCheckBtn = new Button("#hojyokmkkisyu-check-btn");
        this.bmnKisyuCheckBtn = new Button("#bmnkisyu-check-btn");
        this.swkImportBtn = new Button("#swk-import-btn");
        this.swkErrLogDownLoadBtn = new Button("#swkerrlog-download-btn");

        this.$scope.initialize = () => {
            // 科目エクスプローラー初期化
            ExplorerUI.initialize(this.$scope, this.$timeout);

            // 消費税区分エクスプローラー初期化
            let controlName = "taxKbnExproler";
            this.$scope[controlName + "ItemFormatter"] = (panel: wjGrid.GridPanel, rowIndex: number, columnIndex: number, cell: HTMLElement) => {
                let cellType = panel.cellType;
                if (cellType === wjGrid.CellType.ColumnHeader) {
                    let innerHTML = cell.innerHTML;
                    // 選択列はヘッダーテキストが不要なので、空文字列に変換します。
                    if (columnIndex === 0 && innerHTML.indexOf("selected") === 0)
                        cell.innerHTML = innerHTML.replace("selected", "");
                }

                // デフォルトの設定ではエクスプローラーには大きすぎるので、スタイルの調整を行います。
                cell.style.paddingTop = "3px";

                // セルのカスタマイズ
                if (cellType === wjGrid.CellType.Cell) {
                    if (cell.textContent == "会社の設定に従う" || cell.textContent == "売上") {
                        cell.innerHTML = `<span class="migration-blue">${cell.textContent}</span>`;
                    } else if (cell.textContent == "仕入") {
                        cell.innerHTML = `<span class="migration-red">${cell.textContent}</span>`;
                    }
                }
            }
            ExplorerUIExpansion.initialize(this.$scope, this.$timeout, "#taxKbnExproler", controlName);
        }

        this.initializeProcessMigration();

        this.setEventHeader();
        this.setEventStepFileImport();
        this.setEventStepItemSetting();
        this.setEventStepKmkSetting();
        this.setEventStepHojyoSetting();
        this.setEventStepSwkImport();

        // 前回処理実行中の場合プログレスバーを表示
        if (this.isBeforeCurrentStep(MigrationStep.SwkImport, true) && this.m_StepCheckResult.IsSwkImportStepEnable) {
            if (this.m_JournalSetting.BatchStatus == Status.Waiting || this.m_JournalSetting.BatchStatus == Status.Runtime) {
                // 進捗確認ダイアログを表示
                this.progressBarDialog = new ProgressConfirmDialog("仕訳インポート中", "仕訳インポート準備中...", 0, true);
                $(ProgressConfirmDialog.CANCEL_BUTTON_ID).click(() => { this.cancelProgress(); });
                this.progressBarDialog.open();
                this.batchCanceling = false;
                this.timerid = setInterval(() => { this.observeProgress(this.m_BatchRequestId); }, 1000);
            }
        }
    }

    /**
    * カレントステップ以前(or より前)か判定
    * @param step 比較するステップ
    * @param equal 同一をtrueとするか
    */
    private isBeforeCurrentStep(step: number, equal: boolean): boolean {
        if (this.m_CurrentStep == step) {
            return equal;
        }

        switch (this.m_CurrentStep) {
            case MigrationStep.FileImport:
                return false;
            case MigrationStep.ItemSetting:
                return step == MigrationStep.FileImport;
            case MigrationStep.KmkSetting:
                return step == MigrationStep.FileImport
                    || step == MigrationStep.ItemSetting;
            case MigrationStep.HojyoSetting:
                return step == MigrationStep.FileImport
                    || step == MigrationStep.ItemSetting
                    || step == MigrationStep.KmkSetting;
            case MigrationStep.SwkImport:
                return step == MigrationStep.FileImport
                    || step == MigrationStep.ItemSetting
                    || step == MigrationStep.KmkSetting
                    || step == MigrationStep.HojyoSetting;
            case MigrationStep.Finish:
                return true;
        }

        return false;
    }

    private getExecuteStepList(): number[] {
        let stepInfoList: any[] = [
            { "step": MigrationStep.FileImport, "enable": this.m_StepCheckResult.IsFileImportStepEnable, },
            { "step": MigrationStep.ItemSetting, "enable": this.m_StepCheckResult.IsItemSettingStepEnable },
            { "step": MigrationStep.KmkSetting, "enable": this.m_StepCheckResult.IsKmkSettingStepEnable },
            { "step": MigrationStep.HojyoSetting, "enable": this.m_StepCheckResult.IsHojyoSettingStepEnable },
            { "step": MigrationStep.SwkImport, "enable": this.m_StepCheckResult.IsSwkImportStepEnable },
            { "step": MigrationStep.Finish, "enable": this.m_StepCheckResult.IsFinishStepEnable }
        ];

        // 実行対象のステップを取得する
        let retList: number[] = [];
        for (let stepInfo of stepInfoList) {
            // カレントステップ以前かつStepCheckResultがtrueかつ画面が非表示の場合に取得
            if (stepInfo.step == MigrationStep.ItemSetting) {
                if (this.isItemSettingSaveBtnClick) {
                    // 項目設定保存押下の場合は必ず取得
                    retList.push(stepInfo.step);
                } else {
                    // 項目設定の場合は項目変換の表示も判定
                    if (this.isBeforeCurrentStep(stepInfo.step, true) && stepInfo.enable) {
                        if (!$('#step' + this.getStepName(stepInfo.step)).is(':visible')) {
                            retList.push(stepInfo.step);
                        } else {
                            let swk = this.m_ItemSettingGroup.MigrateItemSettingList.singleOrNull((n: { FileType: number; }) => n.FileType == MigrationFileType.Swk);
                            if (swk != null && swk.MigrateItemSettingDetailList.any((n: { CloudItem: number; }) => n.CloudItem == MigrationSwkCloudItem.Nmonth || n.CloudItem == MigrationSwkCloudItem.TaxCode || n.CloudItem == MigrationSwkCloudItem.TaxRate || n.CloudItem == MigrationSwkCloudItem.TypeCd) && !$('#step' + this.getStepName(stepInfo.step) + '-ItemConvert').is(':visible')) {
                                retList.push(stepInfo.step);
                            }
                        }
                    }
                }
            } else {
                if (this.isBeforeCurrentStep(stepInfo.step, true) && stepInfo.enable && !$('#step' + this.getStepName(stepInfo.step)).is(':visible')) {
                    retList.push(stepInfo.step);
                }
            }
        }

        return retList;
    }

    // #region initialize
    private initializeProcessMigration(): void {
        // Mediator生成
        this.mediator = new Mediator();

        // 最終要素、最初要素のフォーカスが外れたらヘッダにフォーカスを遷移
        this.mediator.afterLastItemBlur = (args: mediatorAfterLastItemBlurArgs) => {
            if (args.IsForward) {
                const elems = $('[tabindex]');
                const lastElem = this.mediator.mediatorItems[this.mediator.mediatorItems.length - 1].getFocusControl();
                let min = 30000; 
                let hasMinIndex = 0;
                for (let i=0;i<elems.length;i++) {
                    if($(elems[i]).is(":hidden") || $(elems[i]).is(":disabled")) {
                        continue;
                    }
                    const tabIndex = parseInt(elems[i].getAttribute('tabindex')!)
                    if( tabIndex > parseInt(lastElem.attr('tabindex'))  && tabIndex < min) {
                        hasMinIndex = i;
                        min = tabIndex;
                    }
                }
                (elems[hasMinIndex] as HTMLElement).focus();
                // this.mediator.setFocusToFirstItem();
            } else {
                const elems = $('[tabindex]');
                let max = 0; 
                let hasMaxIndex = 0;
                for (let i=0;i<elems.length;i++) {
                    if($(elems[i]).is(":hidden") || $(elems[i]).is(":disabled")) {
                        continue;
                    }
                    const tabIndex = parseInt(elems[i].getAttribute('tabindex')!)
                    if(tabIndex > max) {
                        hasMaxIndex = i;
                        max = tabIndex;
                    }
                }
                (elems[hasMaxIndex] as HTMLElement).focus();
                // this.mediator.setFocusToLastItem();
            }
        };

        // ステップナビ
        this.header.initStepNav(this.m_StepCheckResult);

        // ステップ番号Map作成(内部で管理しているステップ番号と画面に表示するステップ番号が異なるため、Mapを作成する)
        this.createDispStepNoMap(this.m_StepCheckResult);

        this.initializeStep(this.m_CurrentStep)
    }

    private initializeStep(migrationstep: number): void {
        // ファイルインポート
        if (this.m_StepCheckResult.IsFileImportStepEnable) {
            this.header.activeStepNav(MigrationStep.FileImport);
            this.initializeStepFileImport();
        }
        if (migrationstep == MigrationStep.FileImport) {
            return;
        }

        // 項目設定
        if (this.m_StepCheckResult.IsItemSettingStepEnable) {
            this.header.activeStepNav(MigrationStep.ItemSetting);
            this.initializeStepItemSetting();
        }
        if (migrationstep == MigrationStep.ItemSetting) {
            return;
        }

        // 科目設定
        if (this.m_StepCheckResult.IsKmkSettingStepEnable) {
            if (!this.m_StepCheckResult.IsItemSettingStepEnable) {
                this.mediator_stepKmkSettingMediatorsOK = true;
            }
            this.header.activeStepNav(MigrationStep.KmkSetting);
            this.initializeStepKmkSetting();
        }
        if (migrationstep == MigrationStep.KmkSetting) {
            return;
        }

        // 補助設定
        if (this.m_StepCheckResult.IsHojyoSettingStepEnable) {
            if (!this.m_StepCheckResult.IsKmkSettingStepEnable) {
                this.mediator_stepHojyoSettingMediatorsOK = true;
            }
            this.header.activeStepNav(MigrationStep.HojyoSetting);
            this.initializeStepHojyoSetting();
        }
        if (migrationstep == MigrationStep.HojyoSetting) {
            return;
        }

        // 仕訳設定
        if (this.m_StepCheckResult.IsSwkImportStepEnable) {
            if (!this.m_StepCheckResult.IsKmkSettingStepEnable && !this.m_StepCheckResult.IsHojyoSettingStepEnable) {
                this.mediator_stepSwkImportMediatorsOK = true;
            }
            this.header.activeStepNav(MigrationStep.SwkImport);
            this.initializeStepSwkImport();
        }
        if (migrationstep == MigrationStep.SwkImport) {
            return;
        }

        // 完了
        if (this.m_StepCheckResult.IsFinishStepEnable) {
            this.header.activeStepNav(MigrationStep.Finish);
            this.initializeStepFinish();
        }
    }

    private createDispStepNoMap(stepCheckResult: MigrationStepCheckResultViewModel): void {
        this.dispStepNoMap = {};
        let dispNo = 1;

        if (stepCheckResult.IsFileImportStepEnable) {
            this.dispStepNoMap[MigrationStep.FileImport] = dispNo++;
        }
        if (stepCheckResult.IsItemSettingStepEnable) {
            this.dispStepNoMap[MigrationStep.ItemSetting] = dispNo++;
        }
        if (stepCheckResult.IsKmkSettingStepEnable) {
            this.dispStepNoMap[MigrationStep.KmkSetting] = dispNo++;
        }
        if (stepCheckResult.IsHojyoSettingStepEnable) {
            this.dispStepNoMap[MigrationStep.HojyoSetting] = dispNo++;
        }
        if (stepCheckResult.IsSwkImportStepEnable) {
            this.dispStepNoMap[MigrationStep.SwkImport] = dispNo++;
        }
        if (stepCheckResult.IsFinishStepEnable) {
            this.dispStepNoMap[MigrationStep.Finish] = dispNo++;
        }
    }

    private initializeStepFileImport(): void {
        if (this.m_FileInfos.length > 0) {
            this.initImportTarget(this.m_FileInfos);

            if (this.m_FileInfos.any((file: { Status: number; }) =>
                file.Status == MigrationFileStatus.FileCombinationError ||
                file.Status == MigrationFileStatus.NoData ||
                file.Status == MigrationFileStatus.DuplicateFile ||
                file.Status == MigrationFileStatus.FileReadError ||
                file.Status == MigrationFileStatus.FileCheckError ||
                file.Status == MigrationFileStatus.UploadError ||
                file.Status == MigrationFileStatus.ImportError)) {
                this.fileImportBtn.disable();
            } else {
                this.fileImportBtn.enable();
            }
            this.showTargetLack(this.m_FileInfos.select((n: { FileType: any; }) => n.FileType));
            $("#file-import").show();
            if (this.isBeforeCurrentStep(MigrationStep.FileImport, false)) {
                // ファイルインポート済み

                // ヘッダステップナビ更新
                this.header.fadeOutStepNav(false);
                this.header.fadeInStepNav(this.m_StepCheckResult);

                this.fileImportBtn.disable();
                this.hiddenAllBtnTrash();
                this.visibleBtnSuccessFileImport(true);
            } else {
                this.visibleBtnSuccessFileImport(false);
            }
        } else {
            $("#file-import").hide();
            this.visibleBtnSuccessFileImport(false);
        }

        this.showStep(MigrationStep.FileImport);
        this.scrollOK = this.m_CurrentStep == MigrationStep.FileImport;

        // Mediatorセット
        this.setMediator(this.createMediatorItemStepFileImport());
    }

    private initializeStepItemSetting(): void {
        // 項目設定
        this.initializeItemSetting();

        // 変換設定
        this.initializeItemConvertSetting();

        this.mediator_stepItemSettingTimerid = setInterval(() => {
            if (this.mediator_stepItemSettingMediatorsKisyuOK && this.mediator_stepItemSettingMediatorsHojyoKisyuOK && this.mediator_stepItemSettingMediatorsBmnKisyuOK && this.mediator_stepItemSettingMediatorsSwkOK &&
                this.mediator_stepItemSettingMediatorsKessanOK && this.mediator_stepItemSettingMediatorsTaxKbnOK && this.mediator_stepItemSettingMediatorsTaxRateOK && this.mediator_stepItemSettingMediatorsGyosyuOK && this.mediator_stepItemSettingMediatorsIvJigyoKbnOK) {
                clearInterval(this.mediator_stepItemSettingTimerid);

                // Mediatorセット
                this.setMediator(this.createMediatorItemStepItemSetting());

                if (this.isItemSettingSaveBtnClick) {
                    setTimeout(() => {
                        $(this.itemSettingSaveBtn.getSelector()).focus();
                        this.isItemSettingSaveBtnClick = false;
                    });
                }

                // 画面表示
                this.showStep(MigrationStep.ItemSetting);
                this.scrollOK = this.m_CurrentStep == MigrationStep.ItemSetting;

                this.mediator_stepKmkSettingMediatorsOK = true;
            }
        }, 200);
    }

    private initializeItemSetting(): void {
        this.$scope.itemSettingKisyuErrCnt = 0;
        this.$scope.itemSettingHojyoKisyuErrCnt = 0;
        this.$scope.itemSettingSwkErrCnt = 0;
        if (this.m_ItemSettingGroup.MigrateItemSettingList.any((n: { MigrateItemSettingSaved: number; }) => n.MigrateItemSettingSaved == MigrationSettingSaveStatus.Changed)) {
            this.$scope.itemSettingSaveStatus = MigrationSettingSaveStatus.Changed;
        } else if (this.m_ItemSettingGroup.MigrateItemSettingList.every(n => n.MigrateItemSettingSaved == MigrationSettingSaveStatus.Saved)) {
            this.$scope.itemSettingSaveStatus = MigrationSettingSaveStatus.Saved;
        }

        // 期首残高タブ
        if (this.m_ItemSettingGroup.MigrateItemSettingList.any((n: { FileType: number; }) => n.FileType == MigrationFileType.Zan)) {
            let kisyu = this.m_ItemSettingGroup.MigrateItemSettingList.where(n => n.FileType == MigrationFileType.Zan)[0];
            this.$scope.kisyuItemSetting = kisyu;

            if (!this.isMigrationItemSettingGroupSaved) {
                // 開始行
                if (this.kisyuStartRowNo == null) {
                    let inp = this.initImportRowNumericInput("kisyu-import-row");
                    inp.lostFocus.addHandler(((e: { mInputControl: { value: number | null; }; }) => {
                        this.$timeout(() => {
                            this.kisyuFileContentGrid.setViewData(this.convertGridViewData(this.$scope.kisyuItemSetting.FileContent, inp.getValue()));
                        });

                        if (e.mInputControl.value == null) {
                            e.mInputControl.value = 1;
                        }
                        if (this.$scope.kisyuItemSetting.StartRowNo == e.mInputControl.value) {
                            // 選択内容が変更していない場合は何もしない
                            return;
                        }

                        if (this.$scope.itemSettingSaveStatus == MigrationSettingSaveStatus.Saved) {
                            this.$scope.itemSettingSaveStatus = MigrationSettingSaveStatus.Changed;
                        }
                    }));
                    this.kisyuStartRowNo = inp;
                }
                this.kisyuStartRowNo.maxValue = Math.min(30, kisyu.FileContent.length);
                this.kisyuStartRowNo.setValue(kisyu.StartRowNo);

                // 列情報設定
                let kisyuGridColumns = new wijmo.CollectionView();
                for (let i = 0; i < kisyu.MigrateItemSettingDetailList.length; i++) {
                    kisyuGridColumns.sourceCollection.push("csvData" + (i + 1));
                }
                this.$scope.kisyuGridColumns = kisyuGridColumns;

                // 項目設定コンボボックス
                this.$timeout(() => {
                    if (this.itemSettingKisyuColumCmbList) {
                        this.itemSettingKisyuColumCmbList.forEach((item: wjInput.ComboBox) => {
                            item.dispose();
                        });
                    }
                    this.itemSettingKisyuColumCmbList = [];
                    kisyu.MigrateItemSettingDetailList.forEach(n => {
                        let cmb = this.createKisyuItemSettingWijmoCombBox(n);
                        this.itemSettingKisyuColumCmbList.push(cmb);
                    });
                    this.checkKisyuRequiredItem();
                    this.mediator_stepItemSettingMediatorsKisyuOK = true;
                });

                // グリッド
                if (this.kisyuFileContentGrid == null) {
                    this.kisyuFileContentGrid = new fileContentGrid(this.$scope, this.$timeout, "kisyuGridControl");

                    // グリッドのアイテムフォーマッターを登録
                    this.$scope.kisyuGridControlitemFormatter = (gridPanel: wjGrid.GridPanel, rowIndex: number, columnIndex: number, cell: HTMLElement) => {
                        let cellType = gridPanel.cellType;
                        if (cellType == wjGrid.CellType.Cell) {
                            let value = this.kisyuFileContentGrid.getValueByPosition(rowIndex, columnIndex, false);
                            if (this.isNumberCell(value)) {
                                cell.style.textAlign = "right";
                            } else {
                                cell.style.textAlign = "center";
                            }
                        }
                    }
                }

                this.$timeout(() => {
                    this.kisyuFileContentGrid.setViewData(this.convertGridViewData(kisyu.FileContent, kisyu.StartRowNo));
                    this.$timeout(() => {
                        this.kisyuFileContentGrid.refresh(true);
                    }, 300);
                });
            } else {
                this.mediator_stepItemSettingMediatorsKisyuOK = true;
            }

            // エラーログ
            this.setKisyuItemSettingErrLog(kisyu);
        } else {
            this.$scope.kisyuItemSetting = null;
            this.mediator_stepItemSettingMediatorsKisyuOK = true;
        }

        // 補助期首残高タブ
        if (this.m_ItemSettingGroup.MigrateItemSettingList.any((n: { FileType: number; }) => n.FileType == MigrationFileType.HojyoZan)) {
            let hojyoKisyu = this.m_ItemSettingGroup.MigrateItemSettingList.where(n => n.FileType == MigrationFileType.HojyoZan)[0];
            this.$scope.hojyoKisyuItemSetting = hojyoKisyu;

            if (!this.isMigrationItemSettingGroupSaved) {
                // 開始行
                if (this.hojyoKisyuStartRowNo == null) {
                    let inp = this.initImportRowNumericInput("hojyokisyu-import-row");
                    inp.lostFocus.addHandler(((e: { mInputControl: { value: number | null; }; }) => {
                        this.$timeout(() => {
                            this.hojyoKisyuFileContentGrid.setViewData(this.convertGridViewData(this.$scope.hojyoKisyuItemSetting.FileContent, inp.getValue()));
                        });

                        if (e.mInputControl.value == null) {
                            e.mInputControl.value = 1;
                        }
                        if (this.$scope.hojyoKisyuItemSetting.StartRowNo == e.mInputControl.value) {
                            // 選択内容が変更していない場合は何もしない
                            return;
                        }

                        if (this.$scope.itemSettingSaveStatus == MigrationSettingSaveStatus.Saved) {
                            this.$scope.itemSettingSaveStatus = MigrationSettingSaveStatus.Changed;
                        }
                    }));
                    this.hojyoKisyuStartRowNo = inp;
                }
                this.hojyoKisyuStartRowNo.maxValue = Math.min(30, hojyoKisyu.FileContent.length);
                this.hojyoKisyuStartRowNo.setValue(hojyoKisyu.StartRowNo);

                // 列情報設定
                let hojyoKisyuGridColumns = new wijmo.CollectionView();
                for (let i = 0; i < hojyoKisyu.MigrateItemSettingDetailList.length; i++) {
                    hojyoKisyuGridColumns.sourceCollection.push("csvData" + (i + 1));
                }
                this.$scope.hojyoKisyuGridColumns = hojyoKisyuGridColumns;

                // 項目設定コンボボックス
                this.$timeout(() => {
                    if (this.itemSettingHojyoKisyuColumCmbList) {
                        this.itemSettingHojyoKisyuColumCmbList.forEach((item: wjInput.ComboBox) => {
                            item.dispose();
                        });
                    }
                    this.itemSettingHojyoKisyuColumCmbList = [];
                    hojyoKisyu.MigrateItemSettingDetailList.forEach(n => {
                        let cmb = this.createHojyoKisyuItemSettingWijmoCombBox(n);
                        this.itemSettingHojyoKisyuColumCmbList.push(cmb);
                    });
                    this.checkHojyoKisyuRequiredItem();
                    this.mediator_stepItemSettingMediatorsHojyoKisyuOK = true;
                });

                // グリッド
                if (this.hojyoKisyuFileContentGrid == null) {
                    this.hojyoKisyuFileContentGrid = new fileContentGrid(this.$scope, this.$timeout, "hojyoKisyuGridControl");

                    // グリッドのアイテムフォーマッターを登録
                    this.$scope.hojyoKisyuGridControlitemFormatter = (gridPanel: wjGrid.GridPanel, rowIndex: number, columnIndex: number, cell: HTMLElement) => {
                        let cellType = gridPanel.cellType;
                        if (cellType == wjGrid.CellType.Cell) {
                            let value = this.hojyoKisyuFileContentGrid.getValueByPosition(rowIndex, columnIndex, false);
                            if (this.isNumberCell(value)) {
                                cell.style.textAlign = "right";
                            } else {
                                cell.style.textAlign = "center";
                            }
                        }
                    }
                }

                this.$timeout(() => {
                    this.hojyoKisyuFileContentGrid.setViewData(this.convertGridViewData(hojyoKisyu.FileContent, hojyoKisyu.StartRowNo));
                    this.$timeout(() => {
                        this.hojyoKisyuFileContentGrid.refresh(true);
                    }, 300);
                });
            } else {
                this.mediator_stepItemSettingMediatorsHojyoKisyuOK = true;
            }

            // エラーログ
            this.setHojyoKisyuItemSettingErrLog(hojyoKisyu);
        } else {
            this.$scope.hojyoKisyuItemSetting = null;
            this.mediator_stepItemSettingMediatorsHojyoKisyuOK = true;
        }

        // 部門期首残高タブ
        if (this.m_ItemSettingGroup.MigrateItemSettingList.any((n: { FileType: number; }) => n.FileType == MigrationFileType.BmnZan)) {
            let bmnKisyu = this.m_ItemSettingGroup.MigrateItemSettingList.where(n => n.FileType == MigrationFileType.BmnZan)[0];
            this.$scope.bmnKisyuItemSetting = bmnKisyu;

            if (!this.isMigrationItemSettingGroupSaved) {
                // 開始行
                if (this.bmnKisyuStartRowNo == null) {
                    let inp = this.initImportRowNumericInput("bmnkisyu-import-row");
                    inp.lostFocus.addHandler(((e: { mInputControl: { value: number | null; }; }) => {
                        this.$timeout(() => {
                            this.bmnKisyuFileContentGrid.setViewData(this.convertGridViewData(this.$scope.bmnKisyuItemSetting.FileContent, inp.getValue()));
                        });

                        if (e.mInputControl.value == null) {
                            e.mInputControl.value = 1;
                        }
                        if (this.$scope.bmnKisyuItemSetting.StartRowNo == e.mInputControl.value) {
                            // 選択内容が変更していない場合は何もしない
                            return;
                        }

                        if (this.$scope.itemSettingSaveStatus == MigrationSettingSaveStatus.Saved) {
                            this.$scope.itemSettingSaveStatus = MigrationSettingSaveStatus.Changed;
                        }
                    }));
                    this.bmnKisyuStartRowNo = inp;
                }
                this.bmnKisyuStartRowNo.maxValue = Math.min(30, bmnKisyu.FileContent.length);
                this.bmnKisyuStartRowNo.setValue(bmnKisyu.StartRowNo);

                // 列情報設定
                let bmnKisyuGridColumns = new wijmo.CollectionView();
                for (let i = 0; i < bmnKisyu.MigrateItemSettingDetailList.length; i++) {
                    bmnKisyuGridColumns.sourceCollection.push("csvData" + (i + 1));
                }
                this.$scope.bmnKisyuGridColumns = bmnKisyuGridColumns;

                // 項目設定コンボボックス
                this.$timeout(() => {
                    if (this.itemSettingBmnKisyuColumCmbList) {
                        this.itemSettingBmnKisyuColumCmbList.forEach((item: wjInput.ComboBox) => {
                            item.dispose();
                        });
                    }
                    this.itemSettingBmnKisyuColumCmbList = [];
                    bmnKisyu.MigrateItemSettingDetailList.forEach(n => {
                        let cmb = this.createBmnKisyuItemSettingWijmoCombBox(n);
                        this.itemSettingBmnKisyuColumCmbList.push(cmb);
                    });
                    this.checkBmnKisyuRequiredItem();
                    this.mediator_stepItemSettingMediatorsBmnKisyuOK = true;
                });

                // グリッド
                if (this.bmnKisyuFileContentGrid == null) {
                    this.bmnKisyuFileContentGrid = new fileContentGrid(this.$scope, this.$timeout, "bmnKisyuGridControl");

                    // グリッドのアイテムフォーマッターを登録
                    this.$scope.bmnKisyuGridControlitemFormatter = (gridPanel: wjGrid.GridPanel, rowIndex: number, columnIndex: number, cell: HTMLElement) => {
                        let cellType = gridPanel.cellType;
                        if (cellType == wjGrid.CellType.Cell) {
                            let value = this.bmnKisyuFileContentGrid.getValueByPosition(rowIndex, columnIndex, false);
                            if (this.isNumberCell(value)) {
                                cell.style.textAlign = "right";
                            } else {
                                cell.style.textAlign = "center";
                            }
                        }
                    }
                }

                this.$timeout(() => {
                    this.bmnKisyuFileContentGrid.setViewData(this.convertGridViewData(bmnKisyu.FileContent, bmnKisyu.StartRowNo));
                    this.$timeout(() => {
                        this.bmnKisyuFileContentGrid.refresh(true);
                    }, 300);
                });
            } else {
                this.mediator_stepItemSettingMediatorsBmnKisyuOK = true;
            }

            // エラーログ
            this.setBmnKisyuItemSettingErrLog(bmnKisyu);
        } else {
            this.$scope.bmnKisyuItemSetting = null;
            this.mediator_stepItemSettingMediatorsBmnKisyuOK = true;
        }
        
        // 仕訳タブ
        if (this.m_ItemSettingGroup.MigrateItemSettingList.any((n: { FileType: number; }) => n.FileType == MigrationFileType.Swk)) {
            let swk = this.m_ItemSettingGroup.MigrateItemSettingList.where(n => n.FileType == MigrationFileType.Swk)[0];
            this.$scope.swkItemSetting = swk;

            if (!this.isMigrationItemSettingGroupSaved) {
                // 開始行
                if (this.swkStartRowNo == null) {
                    let inp = this.initImportRowNumericInput("swk-import-row");
                    inp.lostFocus.addHandler(((e: { mInputControl: { value: number | null; }; }) => {
                        this.$timeout(() => {
                            this.swkFileContentGrid.setViewData(this.convertGridViewData(this.$scope.swkItemSetting.FileContent, inp.getValue()));
                        });

                        if (e.mInputControl.value == null) {
                            e.mInputControl.value = 1;
                        }
                        if (this.$scope.swkItemSetting.StartRowNo == e.mInputControl.value) {
                            // 選択内容が変更していない場合は何もしない
                            return;
                        }

                        if (this.$scope.itemSettingSaveStatus == MigrationSettingSaveStatus.Saved) {
                            this.$scope.itemSettingSaveStatus = MigrationSettingSaveStatus.Changed;
                        }
                    }));
                    this.swkStartRowNo = inp;
                }
                this.swkStartRowNo.maxValue = Math.min(30, swk.FileContent.length);
                this.swkStartRowNo.setValue(swk.StartRowNo);

                // 金額設定
                this.m_TaxKbn = swk.TaxInc;
                this.$scope.taxKbn = this.m_TaxKbn;
                if (this.m_TaxKbn == TaxKbn.TaxExcluded) {
                    $("#radio-taxExcluded").prop("checked", true);
                } else if (this.m_TaxKbn == TaxKbn.TaxIncluded) {
                    $("#radio-taxIncluded").prop("checked", true);
                }

                // 内税消費税額自動計算区分
                this.m_UchizeiAutoCalcKbn = swk.UchizeiAutoCalcKbn;
                this.$scope.uchizeiAutoCalcKbn = this.m_UchizeiAutoCalcKbn;
                if (this.m_UchizeiAutoCalcKbn == UchizeiAutoCalcKbn.Auto) {
                    $("#radio-uchizeiAutoCalcKbnOn").prop("checked", true);
                } else {
                    $("#radio-uchizeiAutoCalcKbnOff").prop("checked", true);
                }

                // 列情報設定
                let swkGridColumns = new wijmo.CollectionView();
                for (let i = 0; i < swk.MigrateItemSettingDetailList.length; i++) {
                    swkGridColumns.sourceCollection.push("csvData" + (i + 1));
                }
                this.$scope.swkGridColumns = swkGridColumns;

                // 項目設定コンボボックス
                if (this.taxInfoDataCacheForItemSetting != undefined) {
                    this.$timeout(() => {
                        if (this.itemSettingSwkColumCmbList) {
                            this.itemSettingSwkColumCmbList.forEach((item: wjInput.ComboBox) => {
                                item.dispose();
                            });
                        }
                        this.itemSettingSwkColumCmbList = [];
                        swk.MigrateItemSettingDetailList.forEach(n => {
                            let cmb = this.createSwkItemSettingWijmoCombBox(n);
                            this.itemSettingSwkColumCmbList.push(cmb);
                        });
                        this.checkSwkRequiredItem();
                        this.mediator_stepItemSettingMediatorsSwkOK = true;
                    });
                }

                // グリッド
                if (this.swkFileContentGrid == null) {
                    this.swkFileContentGrid = new fileContentGrid(this.$scope, this.$timeout, "swkGridControl");

                    // グリッドのアイテムフォーマッターを登録
                    this.$scope.swkGridControlitemFormatter = (gridPanel: wjGrid.GridPanel, rowIndex: number, columnIndex: number, cell: HTMLElement) => {
                        let cellType = gridPanel.cellType;
                        if (cellType == wjGrid.CellType.Cell) {
                            let value = this.swkFileContentGrid.getValueByPosition(rowIndex, columnIndex, false);
                            if (this.isNumberCell(value)) {
                                cell.style.textAlign = "right";
                            } else {
                                cell.style.textAlign = "center";
                            }
                        }
                    }
                }

                this.$timeout(() => {
                    this.swkFileContentGrid.setViewData(this.convertGridViewData(swk.FileContent, swk.StartRowNo));
                    this.$timeout(() => {
                        this.swkFileContentGrid.refresh(true);
                    }, 300);
                });
            } else {
                this.mediator_stepItemSettingMediatorsSwkOK = true;
            }

            // エラーログ
            this.setSwkItemSettingErrLog(swk);
        } else {
            this.$scope.swkItemSetting = null;
            this.mediator_stepItemSettingMediatorsSwkOK = true;
        }

        if (!this.isMigrationItemSettingGroupSaved) {
            // 項目設定タブの制御
            let lst = [
                { key: "kisyu", value: this.$scope.kisyuItemSetting != null },
                { key: "hojyokisyu", value: this.$scope.hojyoKisyuItemSetting != null },
                { key: "bmnkisyu", value: this.$scope.bmnKisyuItemSetting != null },
                { key: "swk", value: this.$scope.swkItemSetting != null },
            ];
            lst.forEach(n => {
                $(`#li-${n.key}-tab`).removeClass("active");
                $(`#${n.key}-tab-pane`).removeClass("in active show");
            });
            let item = lst.singleOrNull((n: { value: boolean; }) => n.value);
            if (item != null) {
                $(`#${item.key}-tab`).tab("show");
                $(`#li-${item.key}-tab`).addClass("active");
                $(`#${item.key}-tab-pane`).addClass("in active");
            }
        }

        // 項目設定エラーログタブの制御
        this.initItemSettingErrLogTab();
    }

    private createTaxCodeTaxRateMap() {
        this.taxCodeTaxRateMap = {};

        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.DefaultAutoSet] = this.taxRatePatternUsable;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.None] = this.taxRatePatternUnusable;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.TaxationSalesIncluded] = this.taxRatePatternRate;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.TaxationSalesSubtractionIncluded] = this.taxRatePatternRate;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.TaxationSalesLoanlossRepaymentIncluded] = this.taxRatePatternRate;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.TaxationSalesLoanlossCollectionIncluded] = this.taxRatePatternRate;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.TaxationSalesExcluded] = this.taxRatePatternRate;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.TaxationSalesSubtractionExcluded] = this.taxRatePatternRate;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.TaxationSalesLoanlossRepaymentExcluded] = this.taxRatePatternRate;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.TaxationSalesLoanlossCollectionExcluded] = this.taxRatePatternRate;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.ExemptionSales] = this.taxRatePatternUnusable;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.ValuablePapersSalesExemption] = this.taxRatePatternUnusable;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.TaxationLessnessSales] = this.taxRatePatternUnusable;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.ExportSales] = this.taxRatePatternUnusable;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.ExemptionExportSales] = this.taxRatePatternUnusable;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.Unclear] = this.taxRatePatternUnusable;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.TaxationJournalizeIncluded] = this.taxRatePatternRate;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.TaxationJournalizeSubtractionIncluded] = this.taxRatePatternRate;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.CommonJournalizeIncluded] = this.taxRatePatternRate;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.ExemptionSalseJournalizeIncluded] = this.taxRatePatternRate;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.CommonJournalizeSubtractionIncluded] = this.taxRatePatternRateThreeUnusable;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.ExemptionSalseJournalizeSubtractionIncluded] = this.taxRatePatternRateThreeUnusable;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.TaxationJournalizeExcluded] = this.taxRatePatternRate;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.TaxationJournalizeSubtractionExcluded] = this.taxRatePatternRate;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.CommonJournalizeExcluded] = this.taxRatePatternRate;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.ExemptionSalseJournalizeExcluded] = this.taxRatePatternRate;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.CommonJournalizeSubtractionExcluded] = this.taxRatePatternRateThreeUnusable;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.ExemptionSalseJournalizeSubtractionExcluded] = this.taxRatePatternRateThreeUnusable;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.ExemptionJournalize] = this.taxRatePatternUnusable;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.TaxationLessnessStockingCleaningContract] = this.taxRatePatternUnusable;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.ImportJournalizeIncluded] = this.taxRatePatternRateThreeUnusable;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.ImportCommonJournalizeIncluded] = this.taxRatePatternRateThreeUnusable;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.ExemptionSalseImportIncluded] = this.taxRatePatternRateThreeUnusable;
        if (this.taxInfoDataCacheForItemSetting != undefined) {
            if (this.taxInfoDataCacheForItemSetting.ImportKbn == ImportKbn.ExcludingTaxNationalLocal) {
                this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.ImportJournalizeExcluded] = this.taxRatePatternStateRate;
                this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.ImportCommonJournalizeExcluded] = this.taxRatePatternStateRate;
                this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.JournalizeCotCode64] = this.taxRatePatternStateRate;
            } else {
                this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.ImportJournalizeExcluded] = this.taxRatePatternRate;
                this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.ImportCommonJournalizeExcluded] = this.taxRatePatternRate;
                this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.JournalizeCotCode64] = this.taxRatePatternRate;
            }
        }
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.ImportJournalizeConsumptionTax] = this.taxRatePatternLocalRate;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.ImportCommonJournalizeLocalConsumptionTax] = this.taxRatePatternLocalRate;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.ExemptionJournalizeImport] = this.taxRatePatternLocalRate;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.TaxationSalesSpecificJournalize] = this.taxRatePatternRateEightTenUsable;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.TaxationExemptionSalesSpecificJournalize] = this.taxRatePatternRateEightTenUsable;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.TaxationSalesSpecificJournalizeReturn] = this.taxRatePatternRateEightTenUsable;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.ExemptionSalseImportSpecificTaxationJournalize] = this.taxRatePatternRateEightTenUsable;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.TaxationExemptionSalesSpecificJournalizeReturn] = this.taxRatePatternRateEightTenUsable;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.ExemptionSalseImportSpecificTaxationJournalizeReturn] = this.taxRatePatternRateEightTenUsable;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.UnclearBusiness] = this.taxRatePatternUnusable;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.StocktakingAdjustTaxation] = this.taxRatePatternRate;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.StocktakingAdjustConsumptionTax] = this.taxRatePatternRate;
        this.taxCodeTaxRateMap[MigrationConvertItemTaxCodeKey.StocktakingAdjustExemption] = this.taxRatePatternRate;
    }

    private isNumberCell(value: string): boolean {
        // 金額に変換できる数値かどうか
        if (MjsUtilities.isNull(value)) {
            return false;
        }
        let replaced = value.replace(/\./g, '').replace(/-/, '').replace(/[0-9]/g, '').replace(/,/g, '').replace(/\s+/g, '');
        if (replaced.length > 0) {
            return false;
        } else {
            let replaced = value.replace(/-/, '').replace(/,/g, '').replace(/\s+/g, '');
            if (Number.isNaN(Number(replaced))) {
                return false;
            } else {
                return true;
            }
        }
    }

    private createKisyuItemSettingWijmoCombBox(detail: MigrationItemSettingDetailViewModel): wjInput.ComboBox {
        let id = `#kisyuCmbColum${detail.ColNo}`;
        let items = this.createCmbCloudItems(MigrationFileType.Zan);
        let formatItem = (sender: any, e: any) => {
            let selectedKeys = this.itemSettingKisyuColumCmbList.filter(n => n.selectedValue != "").select<number>((n: { selectedItem: { key: any; }; }) => n.selectedItem.key);
            if (!e.item.classList.contains("wj-state-selected")) {
                if (selectedKeys.any((n: any) => n == e.data.key)) {
                    e.item.style.display = 'flex';
                    e.item.innerHTML = `<span style="width: 110px;">${e.data.value}</span><span>※設定済</span>`;
                    e.item.classList.add("wj-state-disabled");
                }
            } else {
                e.item.classList.remove("wj-state-disabled");
            }

            if (e.data.value.slice(-1) == "*") {
                e.item.classList.add("migration-red");
            }
        }
        let cmb = this.initItemSettingWijmoCombBox(id, items, formatItem, detail.CloudItem);

        cmb.gotFocus.addHandler((s: { selectedIndex?: any; itemsSource?: any; dropDown?: any; selectedValue?: string; }, e: any) => {
            // 他Comboの選択によりItemが変わるためformatItemをやり直す
            let index = s.selectedIndex;
            s.itemsSource = this.createCmbCloudItems(MigrationFileType.Zan);
            s.selectedIndex = index;

            let dropDownWidth = "";
            if (this.itemSettingKisyuColumCmbList.any((n: { selectedValue: string; }) => s != n && n.selectedValue != "")) {
                dropDownWidth = "210";
            }
            $(s.dropDown).css("width", dropDownWidth);
        });

        cmb.lostFocus.addHandler((s: { selectedValue: number; dropDown: string; selectedIndex: number; }, e: any) => {
            if (detail.CloudItem == s.selectedValue) {
                // 選択内容が変更していない場合は何もしない
                return;
            }

            if ($(s.dropDown).find(".wj-state-selected").hasClass("wj-state-disabled")) {
                // 非活性項目を選択した場合は未選択へ（マウスホイールで設定可能）
                s.selectedIndex = -1;
            }

            detail.CloudItem = s.selectedValue;
            this.checkKisyuRequiredItem();
            if (this.$scope.itemSettingSaveStatus == MigrationSettingSaveStatus.Saved) {
                this.$scope.itemSettingSaveStatus = MigrationSettingSaveStatus.Changed;
            }
        });

        return cmb;
    }

    private createHojyoKisyuItemSettingWijmoCombBox(detail: MigrationItemSettingDetailViewModel): wjInput.ComboBox {
        let id = `#hojyoKisyuCmbColum${detail.ColNo}`;
        let items = this.createCmbCloudItems(MigrationFileType.HojyoZan);
        let formatItem = (sender: any, e: any) => {
            let selectedKeys = this.itemSettingHojyoKisyuColumCmbList.filter(n => n.selectedValue != "").select<number>((n: { selectedItem: { key: any; }; }) => n.selectedItem.key);
            if (!e.item.classList.contains("wj-state-selected")) {
                if (selectedKeys.any((n: any) => n == e.data.key)) {
                    e.item.style.display = 'flex';
                    e.item.innerHTML = `<span style="width: 110px;">${e.data.value}</span><span>※設定済</span>`;
                    e.item.classList.add("wj-state-disabled");
                }
            } else {
                e.item.classList.remove("wj-state-disabled");
            }

            if (e.data.value.slice(-1) == "*") {
                e.item.classList.add("migration-red");
            }
        }
        let cmb = this.initItemSettingWijmoCombBox(id, items, formatItem, detail.CloudItem);

        cmb.gotFocus.addHandler((s: { selectedIndex?: any; itemsSource?: any; dropDown?: any; selectedValue?: string; }, e: any) => {
            // 他Comboの選択によりItemが変わるためformatItemをやり直す
            let index = s.selectedIndex;
            s.itemsSource = this.createCmbCloudItems(MigrationFileType.HojyoZan);
            s.selectedIndex = index;

            let dropDownWidth = "";
            if (this.itemSettingHojyoKisyuColumCmbList.any((n: { selectedValue: string; }) => s != n && n.selectedValue != "")) {
                dropDownWidth = "210";
            }
            $(s.dropDown).css("width", dropDownWidth);
        });

        cmb.lostFocus.addHandler((s: { selectedValue: number; dropDown: string; selectedIndex: number; }, e: any) => {
            if (detail.CloudItem == s.selectedValue) {
                // 選択内容が変更していない場合は何もしない
                return;
            }

            if ($(s.dropDown).find(".wj-state-selected").hasClass("wj-state-disabled")) {
                // 非活性項目を選択した場合は未選択へ（マウスホイールで設定可能）
                s.selectedIndex = -1;
            }

            detail.CloudItem = s.selectedValue;
            this.checkHojyoKisyuRequiredItem();
            if (this.$scope.itemSettingSaveStatus == MigrationSettingSaveStatus.Saved) {
                this.$scope.itemSettingSaveStatus = MigrationSettingSaveStatus.Changed;
            }
        });

        return cmb;
    }

    private createBmnKisyuItemSettingWijmoCombBox(detail: MigrationItemSettingDetailViewModel): wjInput.ComboBox {
        let id = `#bmnKisyuCmbColum${detail.ColNo}`;
        let items = this.createCmbCloudItems(MigrationFileType.BmnZan);
        let formatItem = (sender: any, e: any) => {
            let selectedKeys = this.itemSettingBmnKisyuColumCmbList.filter(n => n.selectedValue != "").select<number>((n: { selectedItem: { key: any; }; }) => n.selectedItem.key);
            if (!e.item.classList.contains("wj-state-selected")) {
                if (selectedKeys.any((n: any) => n == e.data.key)) {
                    e.item.style.display = 'flex';
                    e.item.innerHTML = `<span style="width: 110px;">${e.data.value}</span><span>※設定済</span>`;
                    e.item.classList.add("wj-state-disabled");
                }
            } else {
                e.item.classList.remove("wj-state-disabled");
            }

            if (e.data.value.slice(-1) == "*") {
                e.item.classList.add("migration-red");
            }
        }
        let cmb = this.initItemSettingWijmoCombBox(id, items, formatItem, detail.CloudItem);

        cmb.gotFocus.addHandler((s: { selectedIndex?: any; itemsSource?: any; dropDown?: any; selectedValue?: string; }, e: any) => {
            // 他Comboの選択によりItemが変わるためformatItemをやり直す
            let index = s.selectedIndex;
            s.itemsSource = this.createCmbCloudItems(MigrationFileType.BmnZan);
            s.selectedIndex = index;

            let dropDownWidth = "";
            if (this.itemSettingBmnKisyuColumCmbList.any((n: { selectedValue: string; }) => s != n && n.selectedValue != "")) {
                dropDownWidth = "210";
            }
            $(s.dropDown).css("width", dropDownWidth);
        });

        cmb.lostFocus.addHandler((s: { selectedValue: number; dropDown: string; selectedIndex: number; }, e: any) => {
            if (detail.CloudItem == s.selectedValue) {
                // 選択内容が変更していない場合は何もしない
                return;
            }

            if ($(s.dropDown).find(".wj-state-selected").hasClass("wj-state-disabled")) {
                // 非活性項目を選択した場合は未選択へ（マウスホイールで設定可能）
                s.selectedIndex = -1;
            }

            detail.CloudItem = s.selectedValue;
            this.checkBmnKisyuRequiredItem();
            if (this.$scope.itemSettingSaveStatus == MigrationSettingSaveStatus.Saved) {
                this.$scope.itemSettingSaveStatus = MigrationSettingSaveStatus.Changed;
            }
        });

        return cmb;
    }
    
    private createSwkItemSettingWijmoCombBox(detail: MigrationItemSettingDetailViewModel): wjInput.ComboBox {
        let id = `#swkCmbColum${detail.ColNo}`;
        let items = this.createCmbCloudItems(MigrationFileType.Swk);
        let formatItem = (sender: any, e: any) => {
            let selectedKeys = this.itemSettingSwkColumCmbList.filter(n => n.selectedValue != "").select<number>((n: { selectedItem: { key: any; }; }) => n.selectedItem.key);
            if (!e.item.classList.contains("wj-state-selected")) {
                if (selectedKeys.any((n: any) => n == e.data.key)) {
                    e.item.style.display = 'flex';
                    e.item.innerHTML = `<span style="width: 110px;">${e.data.value}</span><span>※設定済</span>`;
                    e.item.classList.add("wj-state-disabled");
                }
            } else {
                e.item.classList.remove("wj-state-disabled");
            }

            if (e.data.value.slice(-1) == "*") {
                e.item.classList.add("migration-red");
            }
        }
        let cmb = this.initItemSettingWijmoCombBox(id, items, formatItem, detail.CloudItem);

        cmb.gotFocus.addHandler((s: { selectedIndex?: any; itemsSource?: any; dropDown?: any; selectedItem?: { key: string; }; }, e: any) => {
            // 他Comboの選択によりItemが変わるためformatItemをやり直す
            let index = s.selectedIndex;
            s.itemsSource = this.createCmbCloudItems(MigrationFileType.Swk);
            s.selectedIndex = index;

            let dropDownWidth = "";
            if (this.itemSettingSwkColumCmbList.any((n: { selectedItem: { key: string; }; }) => s != n && n.selectedItem.key != "")) {
                dropDownWidth = "210";
            }
            $(s.dropDown).css("width", dropDownWidth);
        });

        cmb.lostFocus.addHandler((s: { selectedValue: number; dropDown: string; selectedIndex: number; }, e: any) => {
            if (detail.CloudItem == s.selectedValue) {
                // 選択内容が変更していない場合は何もしない
                return;
            }

            if ($(s.dropDown).find(".wj-state-selected").hasClass("wj-state-disabled")) {
                // 非活性項目を選択した場合は未選択へ（マウスホイールで設定可能）
                s.selectedIndex = -1;
            }

            detail.CloudItem = s.selectedValue;
            this.checkSwkRequiredItem();
            if (this.$scope.itemSettingSaveStatus == MigrationSettingSaveStatus.Saved) {
                this.$scope.itemSettingSaveStatus = MigrationSettingSaveStatus.Changed;
            }
        });

        return cmb;
    }

    private initItemSettingErrLogTab() {
        let lst = [
            { key: "kisyu", value: this.$scope.kisyuItemSettingErrLogList != null },
            { key: "hojyokisyu", value: this.$scope.hojyoKisyuItemSettingErrLogList != null },
            { key: "bmnkisyu", value: this.$scope.bmnKisyuItemSettingErrLogList != null },
            { key: "swk", value: this.$scope.swkItemSettingErrLogList != null },
        ];
        lst.forEach(n => {
            $(`#li-${n.key}-err-tab`).removeClass("active");
            $(`#${n.key}-err-tab-pane`).removeClass("in active show");
        });
        let item = lst.singleOrNull((n: { value: boolean; }) => n.value);
        if (item != null) {
            $(`#${item.key}-err-tab`).tab("show");
            $(`#li-${item.key}-err-tab`).addClass("active");
            $(`#${item.key}-err-tab-pane`).addClass("in active");
        }
    }

    private initImportErrLogTab() {
        let lst = [
            { key: "hojyokisyu-import", value: this.$scope.hojyoKisyuImportErrLogList != null },
            { key: "bmnkisyu-import", value: this.$scope.bmnKisyuImportErrLogList != null },
        ];
        lst.forEach(n => {
            $(`#li-${n.key}-err-tab`).removeClass("active");
            $(`#${n.key}-err-tab-pane`).removeClass("in active show");
        });
        let item = lst.singleOrNull((n: { value: boolean; }) => n.value);
        if (item != null) {
            $(`#${item.key}-err-tab`).tab("show");
            $(`#li-${item.key}-err-tab`).addClass("active");
            $(`#${item.key}-err-tab-pane`).addClass("in active");
        }
    }

    private setKisyuItemSettingErrLog(kisyu: MigrationItemSettingViewModel): void {
        if (kisyu.ErrLogFileName != null && kisyu.ErrLogFileName != "") {
            // エラーログの表示は負荷がかかるため、1000件までとする
            if (kisyu.MigrateItemSettingErrDetailList.length > 1000) {
                this.$scope.isKisyuItemSettingErrLogCntOver = true;
                this.$scope.kisyuItemSettingErrLogList = kisyu.MigrateItemSettingErrDetailList.slice(0, 1000);
            } else {
                this.$scope.isKisyuItemSettingErrLogCntOver = false;
                this.$scope.kisyuItemSettingErrLogList = kisyu.MigrateItemSettingErrDetailList;
            }
        } else {
            this.$scope.isKisyuItemSettingErrLogCntOver = null;
            this.$scope.kisyuItemSettingErrLogList = null;
        }
    }

    private setHojyoKisyuItemSettingErrLog(hojyoKisyu: MigrationItemSettingViewModel): void {
        if (hojyoKisyu.ErrLogFileName != null && hojyoKisyu.ErrLogFileName != "") {
            // エラーログの表示は負荷がかかるため、1000件までとする
            if (hojyoKisyu.MigrateItemSettingErrDetailList.length > 1000) {
                this.$scope.isHojyoKisyuItemSettingErrLogCntOver = true;
                this.$scope.hojyoKisyuItemSettingErrLogList = hojyoKisyu.MigrateItemSettingErrDetailList.slice(0, 1000);
            } else {
                this.$scope.isHojyoKisyuItemSettingErrLogCntOver = false;
                this.$scope.hojyoKisyuItemSettingErrLogList = hojyoKisyu.MigrateItemSettingErrDetailList;
            }
        } else {
            this.$scope.isHojyoKisyuItemSettingErrLogCntOver = null;
            this.$scope.hojyoKisyuItemSettingErrLogList = null;
        }
    }

    private setBmnKisyuItemSettingErrLog(bmnKisyu: MigrationItemSettingViewModel): void {
        if (bmnKisyu.ErrLogFileName != null && bmnKisyu.ErrLogFileName != "") {
            // エラーログの表示は負荷がかかるため、1000件までとする
            if (bmnKisyu.MigrateItemSettingErrDetailList.length > 1000) {
                this.$scope.isBmnKisyuItemSettingErrLogCntOver = true;
                this.$scope.bmnKisyuItemSettingErrLogList = bmnKisyu.MigrateItemSettingErrDetailList.slice(0, 1000);
            } else {
                this.$scope.isBmnKisyuItemSettingErrLogCntOver = false;
                this.$scope.bmnKisyuItemSettingErrLogList = bmnKisyu.MigrateItemSettingErrDetailList;
            }
        } else {
            this.$scope.isBmnKisyuItemSettingErrLogCntOver = null;
            this.$scope.bmnKisyuItemSettingErrLogList = null;
        }
    }

    private setSwkItemSettingErrLog(swk: MigrationItemSettingViewModel): void {
        if (swk.ErrLogFileName != null && swk.ErrLogFileName != "") {
            // エラーログの表示は負荷がかかるため、1000件までとする
            if (swk.MigrateItemSettingErrDetailList.length > 1000) {
                this.$scope.isSwkItemSettingErrLogCntOver = true;
                this.$scope.swkItemSettingErrLogList = swk.MigrateItemSettingErrDetailList.slice(0, 1000);
            } else {
                this.$scope.isSwkItemSettingErrLogCntOver = false;
                this.$scope.swkItemSettingErrLogList = swk.MigrateItemSettingErrDetailList;
            }
        } else {
            this.$scope.isSwkItemSettingErrLogCntOver = null;
            this.$scope.swkItemSettingErrLogList = null;
        }
    }

    private setHojyoKisyuImportrLog(hojyoKisyu: MigrationHojyoGroupViewModel): void {
        if (hojyoKisyu.ImportErrLogFileName != null && hojyoKisyu.ImportErrLogFileName != "") {
            // エラーログの表示は負荷がかかるため、1000件までとする
            if (hojyoKisyu.OpeningBalanceImportErrDetailList.length > 1000) {
                this.$scope.isHojyoKisyuImportErrLogCntOver = true;
                this.$scope.hojyoKisyuImportErrLogList = hojyoKisyu.OpeningBalanceImportErrDetailList.slice(0, 1000);
            } else {
                this.$scope.isHojyoKisyuImportErrLogCntOver = false;
                this.$scope.hojyoKisyuImportErrLogList = hojyoKisyu.OpeningBalanceImportErrDetailList;
            }
        } else {
            this.$scope.isHojyoKisyuImportErrLogCntOver = null;
            this.$scope.hojyoKisyuImportErrLogList = null;
        }
    }

    private setBmnKisyuImportErrLog(bmnKisyu: MigrationHojyoGroupViewModel): void {
        if (bmnKisyu.ImportErrLogFileName != null && bmnKisyu.ImportErrLogFileName != "") {
            // エラーログの表示は負荷がかかるため、1000件までとする
            if (bmnKisyu.OpeningBalanceImportErrDetailList.length > 1000) {
                this.$scope.isBmnKisyuImportErrLogCntOver = true;
                this.$scope.bmnKisyuImportErrLogList = bmnKisyu.OpeningBalanceImportErrDetailList.slice(0, 1000);
            } else {
                this.$scope.isBmnKisyuImportErrLogCntOver = false;
                this.$scope.bmnKisyuImportErrLogList = bmnKisyu.OpeningBalanceImportErrDetailList;
            }
        } else {
            this.$scope.isBmnKisyuImportErrLogCntOver = null;
            this.$scope.bmnKisyuImportErrLogList = null;
        }
    }

    private initializeItemConvertSetting(): void {
        this.$scope.kessanList = null;
        this.$scope.taxKbnList = null;
        this.$scope.taxRateList = null;
        this.$scope.gyosyuList = null;
        this.$scope.ivJigyoKbnList = null;
        this.$scope.itemConvertSettingTaxKbnErrCnt = 0;
        this.$scope.itemConvertSettingTaxRateErrCnt = 0;
        if (this.m_ItemConvertSettingGroup != null) {
            this.$scope.itemConvertSettingSaveStatus = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingSaved;
        }
        if (this.input_cmbKessans) {
            Object.values(this.input_cmbKessans).forEach((cmb: wjInput.ComboBox) => {
                cmb.dispose();
            });
        }

        if (this.input_cmbTaxKbns) {
            Object.values(this.input_cmbTaxKbns).forEach((cmb: wjInput.AutoComplete) => {
                cmb.dispose();
            });
        }

        if (this.input_cmbTaxRates) {
            Object.values(this.input_cmbTaxRates).forEach((cmb: wjInput.ComboBox) => {
                cmb.dispose();
            });
        }

        if (this.input_cmbTypeCds) {
            Object.values(this.input_cmbTypeCds).forEach((cmb: wjInput.ComboBox) => {
                cmb.dispose();
            });
        }
        
        this.input_cmbKessans = {};
        this.input_cmbTaxKbns = {};
        this.input_cmbTaxRates = {};
        this.input_cmbTypeCds = {};

        const beforeValueMaxLength: number = 20;
        const beforeValueAfterText: string = "...";

        if (this.m_ItemConvertSettingGroup != null && this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.length > 0) {
            let active = null;

            // 決算仕訳区分
            if (this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.any((n: { ConvertCategory: number; }) => n.ConvertCategory == MigrationConvertCategory.Nmonth)) {
                active = "kessan";
                let kessanItemConvertSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.where(n => n.ConvertCategory == MigrationConvertCategory.Nmonth)[0];
                let kessanList = [];
                for (let detail of kessanItemConvertSetting.MigrateItemConvertSettingDetailList) {
                    let displayBeforeValue = detail.BeforeValue;
                    if (detail.BeforeValue.length > beforeValueMaxLength) {
                        displayBeforeValue = detail.BeforeValue.substr(0, beforeValueMaxLength) + beforeValueAfterText;
                    }

                    kessanList.push({
                        BeforeValue: detail.BeforeValue,
                        DisplayBeforeValue: displayBeforeValue,
                        AfterItem: detail.AfterItem,
                    });
                }
                let items = this.createConvertItems(kessanItemConvertSetting.ConvertItemList);
                this.$scope.kessanList = kessanList;
                this.$timeout(() => {
                    this.$scope.kessanList.forEach((n: MigrationItemConvertSettingDetailViewModel, index: number) => {
                        this.createKessanCmb(n, index, items);
                        this.input_cmbKessans[n.BeforeValue].onLostFocus();
                    });

                    this.mediator_stepItemSettingMediatorsKessanOK = true;
                });
            } else {
                this.mediator_stepItemSettingMediatorsKessanOK = true;
            }

            // 消費税区分
            this.$scope.IsItemTaxRate = false;
            this.$scope.IsItemTypeCd = false;
            if (this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.any((n: { ConvertCategory: number; }) => n.ConvertCategory == MigrationConvertCategory.TaxCode)) {
                if (active == null) {
                    active = "taxKbn";
                }

                let taxKbnList: Array<any> = []; // 消費税区分に消費税率、業種を含む

                // 消費税コード
                let taxKbnItemConvertSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.where(n => n.ConvertCategory == MigrationConvertCategory.TaxCode && n.ConvertItem == MigrationConvertItem.TaxCode)[0];
                for (let detail of taxKbnItemConvertSetting.MigrateItemConvertSettingDetailList) {
                    let displayBeforeValue = detail.BeforeValue;
                    if (detail.BeforeValue.length > beforeValueMaxLength) {
                        displayBeforeValue = detail.BeforeValue.substr(0, beforeValueMaxLength) + beforeValueAfterText;
                    }

                    taxKbnList.push({
                        BeforeValue: detail.BeforeValue,
                        DisplayBeforeValue: displayBeforeValue,
                        AfterItemTaxKbn: detail.AfterItem,
                        AfterItemTaxRate: null!,
                        AfterItemTypeCd: null!
                    });
                }
                let taxCodeItems = this.createConvertItems(taxKbnItemConvertSetting.ConvertItemList);

                // 消費税率
                let taxRateItemConvertSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.where(n => n.ConvertCategory == MigrationConvertCategory.TaxCode && n.ConvertItem == MigrationConvertItem.TaxRate)[0];
                let taxRateItems: any[] = [];
                if (!MjsUtilities.isNull(taxRateItemConvertSetting)) {
                    this.$scope.IsItemTaxRate = true;
                    for (let tax of taxKbnList) {
                        let after = taxRateItemConvertSetting.MigrateItemConvertSettingDetailList.singleOrNull((n: { BeforeValue: string; }) => n.BeforeValue == tax.BeforeValue);
                        if (!MjsUtilities.isNull(after)) {
                            tax.AfterItemTaxRate = after.AfterItem;
                        }
                    }
                    taxRateItems = this.createConvertItems(taxRateItemConvertSetting.ConvertItemList);
                }

                // 業種
                let typeCdItemConvertSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.where(n => n.ConvertCategory == MigrationConvertCategory.TaxCode && n.ConvertItem == MigrationConvertItem.TypeCd)[0];
                let typeCdItems: any[] = [];
                if (!MjsUtilities.isNull(typeCdItemConvertSetting)) {
                    this.$scope.IsItemTypeCd = true;
                    for (let tax of taxKbnList) {
                        let after = typeCdItemConvertSetting.MigrateItemConvertSettingDetailList.singleOrNull((n: { BeforeValue: string; }) => n.BeforeValue == tax.BeforeValue);
                        if (!MjsUtilities.isNull(after)) {
                            tax.AfterItemTypeCd = after.AfterItem;
                        }
                    }
                    typeCdItems = this.createConvertItems(typeCdItemConvertSetting.ConvertItemList);
                }

                this.$scope.taxKbnList = taxKbnList;
                this.$timeout(() => {
                    this.$scope.taxKbnList.forEach((n: { BeforeValue: string; AfterItemTaxKbn: any; AfterItemTaxRate: any; AfterItemTypeCd: any; }, index: number) => {
                        // 消費税コード
                        this.createTaxCodeCmb(n.BeforeValue, n.AfterItemTaxKbn, index, taxCodeItems);
                        this.createTaxCodeCmbBtn(n.BeforeValue, index, taxCodeItems);

                        // 消費税率
                        if (!MjsUtilities.isNull(taxRateItemConvertSetting)) {
                            this.createTaxRateCmb(n.BeforeValue, n.AfterItemTaxRate, index, taxRateItems);
                            this.input_cmbTaxRates[n.BeforeValue].onLostFocus();
                        }

                        // 業種
                        if (!MjsUtilities.isNull(typeCdItemConvertSetting)) {
                            this.createGyosyuCmb(n.BeforeValue, n.AfterItemTypeCd, index, typeCdItems);
                            this.input_cmbTypeCds[n.BeforeValue].onLostFocus();
                        }

                        this.input_cmbTaxKbns[n.BeforeValue].onLostFocus();
                    });

                    this.mediator_stepItemSettingMediatorsTaxKbnOK = true;
                    if (this.$scope.IsItemTaxRate) {
                        this.mediator_stepItemSettingMediatorsTaxRateOK = true;
                    }
                    if (this.$scope.IsItemTypeCd) {
                        this.mediator_stepItemSettingMediatorsGyosyuOK = true;
                    }
                });
            } else {
                this.mediator_stepItemSettingMediatorsTaxKbnOK = true;
            }

            // 消費税率
            if (this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.any((n: { ConvertCategory: number; }) => n.ConvertCategory == MigrationConvertCategory.TaxRate)) {
                if (active == null) {
                    active = "taxRate";
                }
                let taxRateItemConvertSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.where(n => n.ConvertCategory == MigrationConvertCategory.TaxRate)[0];
                let taxRateList = [];
                for (let detail of taxRateItemConvertSetting.MigrateItemConvertSettingDetailList) {
                    let displayBeforeValue = detail.BeforeValue;
                    if (detail.BeforeValue.length > beforeValueMaxLength) {
                        displayBeforeValue = detail.BeforeValue.substr(0, beforeValueMaxLength) + beforeValueAfterText;
                    }

                    taxRateList.push({
                        BeforeValue: detail.BeforeValue,
                        DisplayBeforeValue: displayBeforeValue,
                        AfterItem: detail.AfterItem,
                    });
                }
                let items = this.createConvertItems(taxRateItemConvertSetting.ConvertItemList);
                this.$scope.taxRateList = taxRateList;
                this.$timeout(() => {
                    this.$scope.taxRateList.forEach((n: { BeforeValue: string | number; AfterItem: any; }, index: number) => {
                        this.createTaxRateCmb(n.BeforeValue, n.AfterItem, index, items);
                        this.input_cmbTaxRates[n.BeforeValue].onLostFocus();
                    });

                    this.mediator_stepItemSettingMediatorsTaxRateOK = true;
                });
            } else {
                if (!this.$scope.IsItemTaxRate) {
                    this.mediator_stepItemSettingMediatorsTaxRateOK = true;
                }
            }

            // 業種
            if (this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.any((n: { ConvertCategory: number; }) => n.ConvertCategory == MigrationConvertCategory.TypeCd)) {
                if (active == null) {
                    active = "gyosyu";
                }
                let typeCdItemConvertSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.where(n => n.ConvertCategory == MigrationConvertCategory.TypeCd)[0];
                let gyosyuList = [];
                for (let detail of typeCdItemConvertSetting.MigrateItemConvertSettingDetailList) {
                    let displayBeforeValue = detail.BeforeValue;
                    if (detail.BeforeValue.length > beforeValueMaxLength) {
                        displayBeforeValue = detail.BeforeValue.substr(0, beforeValueMaxLength) + beforeValueAfterText;
                    }

                    gyosyuList.push({
                        BeforeValue: detail.BeforeValue,
                        DisplayBeforeValue: displayBeforeValue,
                        AfterItem: detail.AfterItem,
                    });
                }
                let items = this.createConvertItems(typeCdItemConvertSetting.ConvertItemList);
                this.$scope.gyosyuList = gyosyuList;
                this.$timeout(() => {
                    this.$scope.gyosyuList.forEach((n: { BeforeValue: string | number; AfterItem: any; }, index: number) => {
                        this.createGyosyuCmb(n.BeforeValue, n.AfterItem, index, items);
                        this.input_cmbTypeCds[n.BeforeValue].onLostFocus();
                    });

                    this.mediator_stepItemSettingMediatorsGyosyuOK = true;
                });
            } else {
                if (!this.$scope.IsItemTypeCd) {
                    this.mediator_stepItemSettingMediatorsGyosyuOK = true;
                }
            }

            // 事業者区分
            if (this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.any((n: { ConvertCategory: number; }) => n.ConvertCategory == MigrationConvertCategory.IvJigyoKbn)) {
                if (active == null) {
                    active = "ivJigyoKbn";
                }
                let ivJigyoKbnItemConvertSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.where(n => n.ConvertCategory == MigrationConvertCategory.IvJigyoKbn)[0];
                let ivJigyoKbnList = [];
                for (let detail of ivJigyoKbnItemConvertSetting.MigrateItemConvertSettingDetailList) {
                    let displayBeforeValue = detail.BeforeValue;
                    if (detail.BeforeValue.length > beforeValueMaxLength) {
                        displayBeforeValue = detail.BeforeValue.substr(0, beforeValueMaxLength) + beforeValueAfterText;
                    }

                    ivJigyoKbnList.push({
                        BeforeValue: detail.BeforeValue,
                        DisplayBeforeValue: displayBeforeValue,
                        AfterItem: detail.AfterItem,
                    });
                }
                let items = this.createConvertItems(ivJigyoKbnItemConvertSetting.ConvertItemList);
                this.$scope.ivJigyoKbnList = ivJigyoKbnList;
                this.$timeout(() => {
                    this.$scope.ivJigyoKbnList.forEach((n: { BeforeValue: string | number; AfterItem: any; }, index: number) => {
                        this.createIvJigyoKbnCmb(n.BeforeValue, n.AfterItem, index, items);
                        this.input_cmbIvJigyoKbns[n.BeforeValue].onLostFocus();
                    });

                    this.mediator_stepItemSettingMediatorsIvJigyoKbnOK = true;
                });
            } else {
                if (!this.$scope.IsItemIvJigyoKbn) {
                    this.mediator_stepItemSettingMediatorsIvJigyoKbnOK = true;
                }
            }
            // 変換項目設定のタブ制御
            $(`#${active}-tab`).tab("show");
            $("#li-kessan-tab").removeClass("active");
            $("#li-taxKbn-tab").removeClass("active");
            $("#li-taxRate-tab").removeClass("active");
            $("#li-gyosyu-tab").removeClass("active");
            $("#li-ivJigyoKbn-tab").removeClass("active");
            $(`#li-${active}-tab`).addClass("active");
            $("#kessan-tab-pane").removeClass("in active show");
            $("#taxKbn-tab-pane").removeClass("in active show");
            $("#taxRate-tab-pane").removeClass("in active show");
            $("#gyosyu-tab-pane").removeClass("in active show");
            $("#ivJigyoKbn-tab-pane").removeClass("in active show");
            $(`#${active}-tab-pane`).addClass("in active");
        } else {
            this.mediator_stepItemSettingMediatorsKessanOK = true;
            this.mediator_stepItemSettingMediatorsTaxKbnOK = true;
            this.mediator_stepItemSettingMediatorsTaxRateOK = true;
            this.mediator_stepItemSettingMediatorsGyosyuOK = true;
            this.mediator_stepItemSettingMediatorsIvJigyoKbnOK = true;
        }

        this.$scope.itemConvertSettingTaxRateErrCnt = this.getTaxRateErrCnt();
        this.$scope.itemConvertSettingTaxKbnErrCnt = this.getTaxKbnErrCnt();
        if (this.$scope.itemConvertSettingTaxKbnErrCnt != 0 || this.$scope.itemConvertSettingTaxRateErrCnt != 0) {
            this.itemConvertSettingSaveBtn.disable();
        } else {
            this.itemConvertSettingSaveBtn.enable();
        }
    }

    private createKessanCmb(n: MigrationItemConvertSettingDetailViewModel, index: number, items: any[]): void {
        let id = `#cmbSelectKessan${index}`;
        let cmb = this.initWijmoCombBox(id, items, null!);
        cmb.selectedValue = n.AfterItem;

        cmb.lostFocus.addHandler((s: { selectedValue: string; }, e: any) => {
            let kessanItemConvertSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.where(n => n.ConvertCategory == MigrationConvertCategory.Nmonth)[0];
            let detail = kessanItemConvertSetting.MigrateItemConvertSettingDetailList.singleOrNull((m: { BeforeValue: string; }) => m.BeforeValue == n.BeforeValue);
            if (detail.AfterItem == s.selectedValue) {
                // 選択内容が変更していない場合は何もしない
                return;
            }

            this.$timeout(() => {
                if (this.$scope.itemConvertSettingSaveStatus == MigrationSettingSaveStatus.Saved) {
                    this.$scope.itemConvertSettingSaveStatus = MigrationSettingSaveStatus.Changed;
                }
            });
        });
        this.input_cmbKessans[n.BeforeValue] = cmb;
    }

    private createTaxCodeCmb(before: any, after: any, index: number, items: any[]): void {
        let id = `#cmbSelectTaxKbn${index}`;
        let cmb = this.initConvertItemWijmoAutoComplete(id, items);
        if (MjsUtilities.isNull(after)) {
            cmb.selectedIndex = -1;
        } else {
            cmb.selectedValue = after;
        }

        // クリック時にドロップダウンを表示する
        $("#" + cmb.hostElement.id + ' input').on('click', () => {
            cmb.isDroppedDown = !cmb.isDroppedDown;
        });

        cmb.lostFocus.addHandler((sender: wjInput.AutoComplete, ea: wijmo.EventArgs) => {
            if (sender.selectedValue == null && sender._oldText != "") {
                // 直接入力した場合にselectedValueが設定されないため強制的に設定
                let key = sender.itemsSource.singleOrNull((n: { value: any; }) => n.value == sender._oldText);
                if (key != null) {
                    sender.selectedValue = sender.itemsSource.singleOrNull((n: { value: any; }) => n.value == sender._oldText).key;
                }
            }

            if (MjsUtilities.isNull(sender.selectedItem)) {
                // 消費税コード一覧に存在しない場合、空を設定する
                sender.selectedIndex = -1;
            }

            // 空白は背景色赤でエラー
            if (MjsUtilities.isNull(sender.text)) {
                $("#" + sender.hostElement.id).addClass('easy-input-required');
            } else {
                $("#" + sender.hostElement.id).removeClass('easy-input-required');
            }

            this.SetAutoSetColor(id, sender.text);

            let taxRateItemConvertSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.singleOrNull((n: { ConvertCategory: number; ConvertItem: number; }) => n.ConvertCategory == MigrationConvertCategory.TaxCode && n.ConvertItem == MigrationConvertItem.TaxRate);
            if (!MjsUtilities.isNull(taxRateItemConvertSetting)) {
                let id = `#cmbSelectTaxRate${index}`;

                // 消費税率の制御
                let taxRates = this.taxCodeTaxRateMap[sender.selectedValue];
                if (taxRates != null && taxRates != undefined) {
                    if (taxRates.length == 0) {
                        $(id).hide();
                    } else {
                        $(id).show();

                        let taxRateCmb = this.input_cmbTaxRates[before];
                        let taxRateCmbValue = taxRateCmb.selectedValue;
                        let items = [];
                        for (let item of taxRateItemConvertSetting.ConvertItemList) {
                            if (taxRates.any((n: number) => n == item.Key)) {
                                items.push(item);
                            }
                        }
                        taxRateCmb.itemsSource = this.createConvertItems(items);
                        if (taxRateCmb.itemsSource.any((n: { key: any; }) => n.key == taxRateCmbValue)) {
                            taxRateCmb.selectedValue = taxRateCmbValue;
                        } else {
                            taxRateCmb.selectedValue = TaxRate.None;
                        }

                        taxRateCmb.onLostFocus();
                    }
                }

                if (sender.selectedIndex == -1) {
                    // 空欄の場合は非表示
                    $(id).hide();
                }
            }

            let typeCdItemConvertSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.singleOrNull((n: { ConvertCategory: number; ConvertItem: number; }) => n.ConvertCategory == MigrationConvertCategory.TaxCode && n.ConvertItem == MigrationConvertItem.TypeCd);
            if (!MjsUtilities.isNull(typeCdItemConvertSetting)) {
                let id = `#cmbSelectGyosyu${index}`;

                if (sender.selectedValue != MigrationConvertItemTaxCodeKey.DefaultAutoSet && !this.sTaxCodeKeyListByUseGyosyu.any((n: any) => n == sender.selectedValue)) {
                    // 課税売上系以外の場合は業種非表示
                    $(id).hide();
                } else {
                    $(id).show();
                }

                if (sender.selectedIndex == -1) {
                    // 空欄の場合は非表示
                    $(id).hide();
                }
            }

            this.$timeout(() => {
                this.$scope.itemConvertSettingTaxRateErrCnt = this.getTaxRateErrCnt();
                this.$scope.itemConvertSettingTaxKbnErrCnt = this.getTaxKbnErrCnt();
                if (this.$scope.itemConvertSettingTaxKbnErrCnt != 0 || this.$scope.itemConvertSettingTaxRateErrCnt != 0) {
                    this.itemConvertSettingSaveBtn.disable();
                } else {
                    this.itemConvertSettingSaveBtn.enable();
                }

                let taxKbnItemConvertSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.where(n => n.ConvertCategory == MigrationConvertCategory.TaxCode && n.ConvertItem == MigrationConvertItem.TaxCode)[0];
                let detail = taxKbnItemConvertSetting.MigrateItemConvertSettingDetailList.singleOrNull((m: { BeforeValue: any; }) => m.BeforeValue == before);
                if (detail.AfterItem == sender.selectedValue) {
                    // 選択内容が変更していない場合は何もしない
                    return;
                }

                if (this.$scope.itemConvertSettingSaveStatus == MigrationSettingSaveStatus.Saved) {
                    this.$scope.itemConvertSettingSaveStatus = MigrationSettingSaveStatus.Changed;
                }
            });
        });

        this.input_cmbTaxKbns[before] = cmb;
    }

    private createTaxCodeCmbBtn(before: string, index: number, items: any[]): void {
        let btnId = `#cmbSelectBtnTaxKbn${index}`;
        let cmbBtn = $(btnId);
        cmbBtn.on('click', (e) => {
            if (this.taxKbnExplorer == undefined) {
                this.taxKbnExplorer = new ExplorerUIExpansion(this.$scope, this.$timeout, "taxKbnExproler");
            }

            this.taxKbnExplorer.closed.removeAllHandlers(); // 違うkeyの値でtaxKbnExplorer.closed処理が複数回呼ばれるためremoveAllHandlersする
            this.taxKbnExplorer.closed.addHandler((sender: ExplorerUIExpansion, ea: wijmo.EventArgs ) => {
                let e = ea as ExplorerClosedEventArgs;
                let isSelected = e.isSelected;
                if (isSelected) {
                    let cmb = this.input_cmbTaxKbns[before];
                    let old = cmb.selectedValue;
                    cmb.selectedValue = e.selectedItems[0].code;
                    if (old == cmb.selectedValue) {
                        // 選択内容が変更していない場合は何もしない
                        return;
                    }

                    cmb.onLostFocus();
                }
            });

            let taxKbnList = [];
            for (let taxCode of items) {
                taxKbnList.push({ code: taxCode.key, name: this.getTaxKbn(taxCode.key), association: taxCode.value });
            }
            this.taxKbnExplorer.show(cmbBtn, taxKbnList, [], true, null!, null!, true);
        });
    }

    private createTaxRateCmb(before: any, after: any, index: number, items: any[]): void {
        let id = `#cmbSelectTaxRate${index}`;
        let format = (sender: any, e: { data: { value: string; }; item: { innerHTML: string; }; }) => {
            if (e.data.value == '消費税率を選択してください') {
                e.item.innerHTML = `<span class="migration-blue">${e.data.value}</span>`;
            }
        }
        let cmb = this.initWijmoCombBox(id, items, format);
        cmb.selectedValue = after;

        cmb.lostFocus.addHandler((s: { text: string; hostElement: { id: string; }; selectedValue: string; }, e: any) => {
            // 未設定は背景色赤でエラー
            if (s.text == "消費税率を選択してください") {
                $("#" + s.hostElement.id).addClass('easy-input-required');
            } else {
                $("#" + s.hostElement.id).removeClass('easy-input-required');
            }

            this.SetAutoSetColor(id, s.text);

            this.$timeout(() => {
                this.$scope.itemConvertSettingTaxRateErrCnt = this.getTaxRateErrCnt();
                if (this.$scope.itemConvertSettingTaxKbnErrCnt != 0 || this.$scope.itemConvertSettingTaxRateErrCnt != 0) {
                    this.itemConvertSettingSaveBtn.disable();
                } else {
                    this.itemConvertSettingSaveBtn.enable();
                }

                let taxRateItemConvertSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.singleOrNull((n: { ConvertCategory: number; ConvertItem: number; }) => n.ConvertCategory == MigrationConvertCategory.TaxRate && n.ConvertItem == MigrationConvertItem.TaxRate);
                if (taxRateItemConvertSetting == null) {
                    taxRateItemConvertSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.singleOrNull((n: { ConvertCategory: number; ConvertItem: number; }) => n.ConvertCategory == MigrationConvertCategory.TaxCode && n.ConvertItem == MigrationConvertItem.TaxRate);
                }
                let detail = taxRateItemConvertSetting.MigrateItemConvertSettingDetailList.singleOrNull((m: { BeforeValue: any; }) => m.BeforeValue == before);
                if (detail.AfterItem == s.selectedValue) {
                    // 選択内容が変更していない場合は何もしない
                    return;
                }

                if (MjsUtilities.isNull(detail.AfterItem)) {
                    // コンボボックス非表示
                    return;
                }

                if (this.$scope.itemConvertSettingSaveStatus == MigrationSettingSaveStatus.Saved) {
                    this.$scope.itemConvertSettingSaveStatus = MigrationSettingSaveStatus.Changed;
                }
            });
        });

        this.input_cmbTaxRates[before] = cmb;
    }

    private createGyosyuCmb(before: any, after: any, index: number, items: any[]): void {
        let id = `#cmbSelectGyosyu${index}`;
        let cmb = this.initWijmoCombBox(id, items, null!);
        cmb.selectedValue = after;

        cmb.lostFocus.addHandler((s: { selectedValue: string; }, e: any) => {
            let typeCdItemConvertSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.singleOrNull((n: { ConvertCategory: number; ConvertItem: number; }) => n.ConvertCategory == MigrationConvertCategory.TypeCd && n.ConvertItem == MigrationConvertItem.TypeCd);
            if (typeCdItemConvertSetting == null) {
                typeCdItemConvertSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.singleOrNull((n: { ConvertCategory: number; ConvertItem: number; }) => n.ConvertCategory == MigrationConvertCategory.TaxCode && n.ConvertItem == MigrationConvertItem.TypeCd);
            }
            let detail = typeCdItemConvertSetting.MigrateItemConvertSettingDetailList.singleOrNull((m: { BeforeValue: any; }) => m.BeforeValue == before);
            if (detail.AfterItem == s.selectedValue) {
                // 選択内容が変更していない場合は何もしない
                return;
            }

            if (MjsUtilities.isNull(detail.AfterItem)) {
                // コンボボックス非表示
                return;
            }

            this.$timeout(() => {
                if (this.$scope.itemConvertSettingSaveStatus == MigrationSettingSaveStatus.Saved) {
                    this.$scope.itemConvertSettingSaveStatus = MigrationSettingSaveStatus.Changed;
                }
            });
        });

        this.input_cmbTypeCds[before] = cmb;
    }

    
    private createIvJigyoKbnCmb(before: any, after: any, index: number, items: any[]): void {
        let id = `#cmbSelectIvJigyoKbn${index}`;
        let cmb = this.initWijmoCombBox(id, items, null!);
        cmb.selectedValue = after;

        cmb.lostFocus.addHandler((s: { selectedValue: string; }, e: any) => {
            let ivJigyoKbnItemConvertSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.singleOrNull((n: { ConvertCategory: number; ConvertItem: number; }) => n.ConvertCategory == MigrationConvertCategory.IvJigyoKbn && n.ConvertItem == MigrationConvertItem.IvJigyoKbn);
            if (ivJigyoKbnItemConvertSetting == null) {
                ivJigyoKbnItemConvertSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.singleOrNull((n: { ConvertCategory: number; ConvertItem: number; }) => n.ConvertCategory == MigrationConvertCategory.IvJigyoKbn && n.ConvertItem == MigrationConvertItem.IvJigyoKbn);
            }
            let detail = ivJigyoKbnItemConvertSetting.MigrateItemConvertSettingDetailList.singleOrNull((m: { BeforeValue: any; }) => m.BeforeValue == before);
            if (detail.AfterItem == s.selectedValue) {
                // 選択内容が変更していない場合は何もしない
                return;
            }

            if (MjsUtilities.isNull(detail.AfterItem)) {
                // コンボボックス非表示
                return;
            }

            this.$timeout(() => {
                if (this.$scope.itemConvertSettingSaveStatus == MigrationSettingSaveStatus.Saved) {
                    this.$scope.itemConvertSettingSaveStatus = MigrationSettingSaveStatus.Changed;
                }
            });
        });

        this.input_cmbIvJigyoKbns[before] = cmb;
    }

    private createConvertItems(convertItemList: KeyValueViewModel[]): any[] {
        let items = [];
        for (let convertItem of convertItemList) {
            items.push({
                key: convertItem.Key,
                value: convertItem.Value
            });
        }

        return items;
    }

    private getTaxKbnErrCnt(): number {
        return Object.keys(this.input_cmbTaxKbns).where(n => MjsUtilities.isNull(this.input_cmbTaxKbns[n].selectedValue)).length;
    }

    private getTaxRateErrCnt(): number {
        let taxRateErrCnt = 0;
        if (!MjsUtilities.isNull(this.$scope.taxRateList)) {
            // 消費税率タブ
            taxRateErrCnt = Object.keys(this.input_cmbTaxRates).where(n => this.input_cmbTaxRates[n].selectedValue == TaxRate.None).length;
        } else {
            // 消費税区分タブ
            let unusableTaxRateTaxCodeKey = Object.keys(this.taxCodeTaxRateMap).where(n => this.taxCodeTaxRateMap[n].length == 0).select((n: any) => n);
            for (let taxRateCmbkey of Object.keys(this.input_cmbTaxRates)) {
                let taxKbnCmb = this.input_cmbTaxKbns[taxRateCmbkey];
                if (!MjsUtilities.isNull(taxKbnCmb)) {
                    if (!MjsUtilities.isNull(taxKbnCmb.selectedValue) && !unusableTaxRateTaxCodeKey.any((n: any) => taxKbnCmb.selectedValue == n) && this.input_cmbTaxRates[taxRateCmbkey].selectedValue == TaxRate.None) {
                        taxRateErrCnt++;
                    }
                }
            }
        }

        return taxRateErrCnt;
    }

    private SetAutoSetColor(id: string, text: string): void {
        // 文字色青
        if (text == '会社の設定に従う' || text == '消費税率を選択してください') {
            $(`${id} input`).addClass("migration-blue");
        } else {
            $(`${id} input`).removeClass("migration-blue");
        }
    }

    private getTaxKbn(code: string): string {
        let numCode = Number(code);
        if (numCode == MigrationConvertItemTaxCodeKey.DefaultAutoSet || numCode == MigrationConvertItemTaxCodeKey.None) {
            return "-";
        }
        else if (this.sTaxCodeKeyList.indexOf(numCode) != -1) {
            return "売上";
        } else {
            return "仕入";
        }
    }

    private convertGridViewData(content: string[][], startNo: number): MigrationFileContentViewModel[] {
        let models = [];
        for (let rowIdx = 0; rowIdx < 30; rowIdx++) {
            let vm = new MigrationFileContentViewModel();
            let row = content[rowIdx + startNo - 1];
            if (row == undefined) {
                continue;
            }
            for (let colIdx = 0; colIdx < row.length; colIdx++) {
                vm["csvData" + (colIdx + 1)] = row[colIdx];
            }
            models.push(vm);
        }

        return models;
    }

    private checkKisyuRequiredItem(): void {
        let requiredItem: string[] = [];
        let itemSettingErrCnt = 0;
        if (this.itemSettingKisyuColumCmbList.every(n => n.selectedValue != MigrationKisyuCloudItem.KmkName)) {
            requiredItem.push("勘定科目名称");
            itemSettingErrCnt++;
        }
        if (this.itemSettingKisyuColumCmbList.every(n => n.selectedValue != MigrationKisyuCloudItem.BeginningBalance)) {
            requiredItem.push("残高金額");
            itemSettingErrCnt++;
        }

        this.$timeout(() => {
            this.$scope.itemSettingKisyuErrCnt = itemSettingErrCnt;
            if (itemSettingErrCnt == 0) {
                this.$scope.kisyuRequiredItem = undefined;
            } else {
                this.$scope.kisyuRequiredItem = requiredItem.join("、");
            }

            if (this.$scope.itemSettingKisyuErrCnt != 0 ||
                this.$scope.itemSettingHojyoKisyuErrCnt != 0 ||
                this.$scope.itemSettingSwkErrCnt != 0) {
                this.itemSettingSaveBtn.disable();
            } else {
                this.itemSettingSaveBtn.enable();
            }
        });
    }

    private checkHojyoKisyuRequiredItem(): void {
        let requiredItem: string[] = [];
        let itemSettingErrCnt = 0;
        if (this.itemSettingHojyoKisyuColumCmbList.every(n => n.selectedValue != MigrationHojyoKisyuCloudItem.KmkName)) {
            requiredItem.push("勘定科目名称");
            itemSettingErrCnt++;
        }
        if (this.itemSettingHojyoKisyuColumCmbList.every(n => n.selectedValue != MigrationHojyoKisyuCloudItem.HojyoKbn)) {
            requiredItem.push("補助種類");
            itemSettingErrCnt++;
        }
        if (this.itemSettingHojyoKisyuColumCmbList.every(n => n.selectedValue != MigrationHojyoKisyuCloudItem.HojyoName)) {
            requiredItem.push("補助科目名称");
            itemSettingErrCnt++;
        }
        if (this.itemSettingHojyoKisyuColumCmbList.every(n => n.selectedValue != MigrationHojyoKisyuCloudItem.BeginningBalance)) {
            requiredItem.push("残高金額");
            itemSettingErrCnt++;
        }

        this.$timeout(() => {
            this.$scope.itemSettingHojyoKisyuErrCnt = itemSettingErrCnt;
            if (itemSettingErrCnt == 0) {
                this.$scope.hojyoKisyuRequiredItem = undefined;
            } else {
                this.$scope.hojyoKisyuRequiredItem = requiredItem.join("、");
            }

            if (this.$scope.itemSettingKisyuErrCnt != 0 ||
                this.$scope.itemSettingHojyoKisyuErrCnt != 0 ||
                this.$scope.itemSettingSwkErrCnt != 0) {
                this.itemSettingSaveBtn.disable();
            } else {
                this.itemSettingSaveBtn.enable();
            }
        });
    }

    private checkBmnKisyuRequiredItem(): void {
        let requiredItem: string[] = [];
        let itemSettingErrCnt = 0;
        if (this.itemSettingBmnKisyuColumCmbList.every(n => n.selectedValue != MigrationBmnKisyuCloudItem.KmkName)) {
            requiredItem.push("勘定科目名称");
            itemSettingErrCnt++;
        }
        if (this.itemSettingBmnKisyuColumCmbList.every(n => n.selectedValue != MigrationBmnKisyuCloudItem.BmnName)) {
            requiredItem.push("部門名称");
            itemSettingErrCnt++;
        }
        if (this.itemSettingBmnKisyuColumCmbList.every(n => n.selectedValue != MigrationBmnKisyuCloudItem.BeginningBalance)) {
            requiredItem.push("残高金額");
            itemSettingErrCnt++;
        }

        this.$timeout(() => {
            this.$scope.itemSettingBmnKisyuErrCnt = itemSettingErrCnt;
            if (itemSettingErrCnt == 0) {
                this.$scope.bmnKisyuRequiredItem = undefined;
            } else {
                this.$scope.bmnKisyuRequiredItem = requiredItem.join("、");
            }

            if (this.$scope.itemSettingKisyuErrCnt != 0 ||
                this.$scope.itemSettingBmnKisyuErrCnt != 0 ||
                this.$scope.itemSettingSwkErrCnt != 0) {
                this.itemSettingSaveBtn.disable();
            } else {
                this.itemSettingSaveBtn.enable();
            }
        });
    }

    private checkSwkRequiredItem(): void {
        let requiredItem: string[] = [];
        let itemSettingErrCnt = 0;
        if (this.itemSettingSwkColumCmbList.every(n => n.selectedValue != MigrationSwkCloudItem.DenDate)) {
            requiredItem.push("日付");
            itemSettingErrCnt++;
        }
        if (this.itemSettingSwkColumCmbList.every(n => n.selectedValue != MigrationSwkCloudItem.DKmkName)) {
            requiredItem.push("借方科目");
            itemSettingErrCnt++;
        }
        if (this.itemSettingSwkColumCmbList.every(n => n.selectedValue != MigrationSwkCloudItem.CKmkName)) {
            requiredItem.push("貸方科目");
            itemSettingErrCnt++;
        }
        if (this.itemSettingSwkColumCmbList.every(n => n.selectedValue != MigrationSwkCloudItem.InpSum)) {
            requiredItem.push("金額");
            itemSettingErrCnt++;
        }

        this.$timeout(() => {
            this.$scope.itemSettingSwkErrCnt = itemSettingErrCnt;
            if (itemSettingErrCnt == 0) {
                this.$scope.swkRequiredItem = undefined;
            } else {
                this.$scope.swkRequiredItem = requiredItem.join("、");
            }

            if (this.$scope.itemSettingKisyuErrCnt != 0 ||
                this.$scope.itemSettingHojyoKisyuErrCnt != 0 ||
                this.$scope.itemSettingSwkErrCnt != 0) {
                this.itemSettingSaveBtn.disable();
            } else {
                this.itemSettingSaveBtn.enable();
            }
        });
    }

    private initializeStepKmkSetting(): void {
        this.m_kmkSettingDatas = this.convertFromKmkSettingViewModel(this.m_KmkSetting);
        this.setKmkSettingTable(this.m_kmkSettingDatas);
        this.initInputControlsKmkSetting(this.m_kmkSettingDatas);

        this.visibleBtnSuccessKmkSettingSave(this.m_KmkSetting.MappingSaved);
        this.visibleBtnSuccessKmkKisyuImport(this.m_KmkSetting.KisyuImported);

        this.mediator_stepKmkSettingTimerid = setInterval(() => {
            if (this.mediator_stepKmkSettingMediatorsOK) {
                clearInterval(this.mediator_stepKmkSettingTimerid);

                // Mediatorセット
                this.setMediator(this.createMediatorItemStepKmkSetting());

                this.showStep(MigrationStep.KmkSetting);
                this.scrollOK = this.m_CurrentStep == MigrationStep.KmkSetting || (!this.m_StepCheckResult.IsHojyoSettingStepEnable && !this.m_StepCheckResult.IsSwkImportStepEnable && this.m_StepCheckResult.IsFinishStepEnable);

                this.mediator_stepHojyoSettingMediatorsOK = true;
                if (!this.m_StepCheckResult.IsHojyoSettingStepEnable) {
                    this.mediator_stepSwkImportMediatorsOK = true;
                }
            }
        }, 200);
    }

    private initializeStepHojyoSetting(): void {
        this.$scope.hojyoErrCnt = 0;
        this.$scope.generalhojyoErrCnt = 0;
        this.$scope.bmnErrCnt = 0;
        this.$scope.segmentErrCnt = 0;
        this.$scope.koujiErrCnt = 0;
        this.m_hojyokmkSettingDatas = this.convertFromHojyoSettingViewModel(this.m_HojyoSettingHojyoList);
        this.m_generalhojyoSettingDatas = this.convertFromHojyoSettingViewModel(this.m_HojyoSettingGeneralHojyoList);
        this.m_bmnSettingDatas = this.convertFromHojyoSettingViewModel(this.m_HojyoSettingBmnList);
        this.m_segmentSettingDatas = this.convertFromHojyoSettingViewModel(this.m_HojyoSettingSegmentList);
        this.m_koujiSettingDatas = this.convertFromHojyoSettingViewModel(this.m_HojyoSettingKoujiList);

        this.currentTab = MigrationHojyoTabKbn.Hojyo;
        if (this.m_hojyokmkSettingDatas.length != 0) {
            this.currentTab = MigrationHojyoTabKbn.Hojyo;
        } else if (this.m_generalhojyoSettingDatas.length != 0) {
            this.currentTab = MigrationHojyoTabKbn.GeneralHojyo;
        } else if (this.m_bmnSettingDatas.length != 0) {
            this.currentTab = MigrationHojyoTabKbn.Bmn;
        } else if (this.m_segmentSettingDatas.length != 0) {
            this.currentTab = MigrationHojyoTabKbn.Segment;
        } else {
            this.currentTab = MigrationHojyoTabKbn.Kouji;
        }

        this.visibleBtnSuccessHojyoKmkSettingSave(this.m_HojyoSetting.MappingSaved);
        this.visibleBtnSuccessGeneralHojyoSettingSave(this.m_HojyoSetting.MappingSaved);
        this.visibleBtnSuccessBmnSettingSave(this.m_HojyoSetting.MappingSaved);
        this.visibleBtnSuccessSegmentSettingSave(this.m_HojyoSetting.MappingSaved);
        this.visibleBtnSuccessKoujiSettingSave(this.m_HojyoSetting.MappingSaved);

        this.visibleBtnSuccessHojyoKmkKisyuImport(this.m_HojyoSetting.KisyuImported);

        this.$scope.hojyoList = this.m_hojyokmkSettingDatas;
        this.$timeout(() => {
            this.initSettingTab();
            this.initInputControlsHojyoKmkSetting(this.m_hojyokmkSettingDatas, MigrationHojyoTabKbn.Hojyo);
            this.mediator_stepHojyoSettingMediatorsHojyo = this.createMediatorItemStepHojyoSettingHojyo();
            // BootstrapのTooltipsを初期化する
            $('[data-toggle="tooltip"]').tooltip(ProcessMigration.TOOLTIP_OPTIONS).off("show.bs.tooltip").on("show.bs.tooltip", this.onShowToolTip.bind(this));
            this.mediator_stepHojyoSettingMediatorsHojyoOK = true;
        }, 100);

        this.$scope.generalHojyoList = this.m_generalhojyoSettingDatas;
        this.$timeout(() => {
            this.initSettingTab();
            this.initInputControlsHojyoSetting(this.m_generalhojyoSettingDatas, MigrationHojyoTabKbn.GeneralHojyo);
            this.mediator_stepHojyoSettingMediatorsGeneralHojyo = this.createMediatorItemStepHojyoSettingGeneralHojyo();
            // BootstrapのTooltipsを初期化する
            $('[data-toggle="tooltip"]').tooltip(ProcessMigration.TOOLTIP_OPTIONS).off("show.bs.tooltip").on("show.bs.tooltip", this.onShowToolTip.bind(this));
            this.mediator_stepHojyoSettingMediatorsGeneralHojyoOK = true;
        }, 100);

        this.$scope.bmnList = this.m_bmnSettingDatas;
        this.$timeout(() => {
            this.initSettingTab();
            this.initInputControlsHojyoSetting(this.m_bmnSettingDatas, MigrationHojyoTabKbn.Bmn);
            this.mediator_stepHojyoSettingMediatorsBmn = this.createMediatorItemStepHojyoSettingBmn();
            // BootstrapのTooltipsを初期化する
            $('[data-toggle="tooltip"]').tooltip(ProcessMigration.TOOLTIP_OPTIONS).off("show.bs.tooltip").on("show.bs.tooltip", this.onShowToolTip.bind(this));
            this.mediator_stepHojyoSettingMediatorsBmnOK = true;
        }, 100);

        this.$scope.segmentList = this.m_segmentSettingDatas;
        this.$timeout(() => {
            this.initSettingTab();
            this.initInputControlsHojyoSetting(this.m_segmentSettingDatas, MigrationHojyoTabKbn.Segment);
            this.mediator_stepHojyoSettingMediatorsSegment = this.createMediatorItemStepHojyoSettingSegment();
            // BootstrapのTooltipsを初期化する
            $('[data-toggle="tooltip"]').tooltip(ProcessMigration.TOOLTIP_OPTIONS).off("show.bs.tooltip").on("show.bs.tooltip", this.onShowToolTip.bind(this));
            this.mediator_stepHojyoSettingMediatorsSegmentOK = true;
        }, 100);

        this.$scope.koujiList = this.m_koujiSettingDatas;
        this.$timeout(() => {
            this.initSettingTab();
            this.initInputControlsHojyoSetting(this.m_koujiSettingDatas, MigrationHojyoTabKbn.Kouji);
            this.mediator_stepHojyoSettingMediatorsKouji = this.createMediatorItemStepHojyoSettingKouji();
            // BootstrapのTooltipsを初期化する
            $('[data-toggle="tooltip"]').tooltip(ProcessMigration.TOOLTIP_OPTIONS).off("show.bs.tooltip").on("show.bs.tooltip", this.onShowToolTip.bind(this));
            this.mediator_stepHojyoSettingMediatorsKoujiOK = true;
        }, 100);

        this.mediator_stepHojyoSettingTimerid = setInterval(() => {
            if (this.mediator_stepHojyoSettingMediatorsOK && this.mediator_stepHojyoSettingMediatorsHojyoOK && this.mediator_stepHojyoSettingMediatorsGeneralHojyoOK && this.mediator_stepHojyoSettingMediatorsBmnOK && this.mediator_stepHojyoSettingMediatorsSegmentOK && this.mediator_stepHojyoSettingMediatorsKoujiOK) {
                clearInterval(this.mediator_stepHojyoSettingTimerid);

                this.showStep(MigrationStep.HojyoSetting);
                this.scrollOK = this.m_CurrentStep == MigrationStep.HojyoSetting || (!this.m_StepCheckResult.IsSwkImportStepEnable && this.m_StepCheckResult.IsFinishStepEnable);

                // Mediatorセット
                this.setMediator(this.createMediatorItemStepHojyoSetting([this.mediator_stepHojyoSettingMediatorsHojyo, this.mediator_stepHojyoSettingMediatorsGeneralHojyo, this.mediator_stepHojyoSettingMediatorsBmn, this.mediator_stepHojyoSettingMediatorsSegment, this.mediator_stepHojyoSettingMediatorsKouji]));

                this.mediator_stepSwkImportMediatorsOK = true;
            }
        }, 200);

        this.initImportErrLogTab()        // 残高インポートエラーログタブの制御
    }

    private initializeStepSwkImport(): void {
        this.initSwkSettingTable(this.m_JournalSetting);
        this.setSwkDescription();

        if (this.m_JournalSetting.SwkImported) {
            // 仕訳インポート済み
            this.visibleBtnSuccessSwkImport(Status.Completion);
        } else {
            if (this.m_JournalSetting.ErrCancelFlg && this.m_JournalSetting.BatchStatus == Status.Completion) {
                this.visibleBtnSuccessSwkImport(Status.Canceled);
            } else {
                this.visibleBtnSuccessSwkImport(this.m_JournalSetting.BatchStatus);
            }
        }

        if (this.m_JournalSetting.ErrLog.length > 0) {
            this.initSwkErrLog(this.m_JournalSetting.ErrLog);
            $("#swkErrLogArea").show();
        }

        this.$timeout(() => {
            this.mediator_stepSwkImportMediators = this.createMediatorItemStepSwkImport();
            // BootstrapのTooltipsを初期化する
            $('[data-toggle="tooltip"]').tooltip(ProcessMigration.TOOLTIP_OPTIONS).off("show.bs.tooltip").on("show.bs.tooltip", this.onShowToolTip.bind(this));
        }, 100);

        this.mediator_stepSwkImportTimerid = setInterval(() => {
            if (this.mediator_stepSwkImportMediatorsOK) {
                clearInterval(this.mediator_stepSwkImportTimerid);

                this.showStep(MigrationStep.SwkImport);
                this.scrollOK = this.m_CurrentStep == MigrationStep.SwkImport || this.m_StepCheckResult.IsFinishStepEnable;

                // Mediatorセット
                this.setMediator(this.mediator_stepSwkImportMediators);
            }
        }, 200);
    }

    private initializeStepFinish(): void {
        this.showStep(MigrationStep.Finish);
        // this.scrollOK は stepKmkSetting or stepHojyoSetting or StepSwkImport で this.m_StepCheckResult.IsFinishStepEnable == true の場合にOKとなる
    }
    // #endregion initialize

    // #region mediator
    private setMediator(mediators: MediatorItem[]) {
        // 閉じるボタンを一旦 mediator から削除
        this.mediator.removeLastControl();

        // Mediatorセット
        mediators.forEach(n => this.mediator.addControl(n));

        // 閉じるボタンを末尾に追加
        this.mediator.addControl(this.createBtnMediatorItem(this.header.closeButton));
    }

    private createMediatorItemStepFileImport(): MediatorItem[] {
        let mediators: MediatorItem[] = [];
        let fileImportBtn = this.createBtnMediatorItem(this.fileImportBtn);

        mediators.push(fileImportBtn);

        // ステップの先頭要素を保持する
        this.topStepMediatorMap[MigrationStep.FileImport] = mediators[0];

        return mediators;
    }

    private createMediatorItemStepItemSetting(): MediatorItem[] {
        // 項目設定
        let tabkisyu = this.createTabAndInputMediatorItem($("#kisyu-tab"));
        let tabhojyokisyu = this.createTabAndInputMediatorItem($("#hojyokisyu-tab"));
        let tabbmnkisyu = this.createTabAndInputMediatorItem($("#bmnkisyu-tab"));
        let tabswk = this.createTabAndInputMediatorItem($("#swk-tab"));
        let kisyutabitem = this.createMediatorItemStepItemSettingKisyu();
        let hojyokisyutabitem = this.createMediatorItemStepItemSettingHojyoKisyu();
        let bmnkisyutabitem = this.createMediatorItemStepItemSettingBmnKisyu();
        let swktabitem = this.createMediatorItemStepItemSettingSwk();
        let itemSettingSaveBtn = this.createBtnMediatorItem(this.itemSettingSaveBtn);

        // 項目設定エラーログ
        let tabkisyuerr = this.createTabAndInputMediatorItem($("#kisyu-err-tab"));
        let tabhojyokisyuerr = this.createTabAndInputMediatorItem($("#hojyokisyu-err-tab"));
        let tabbmnkisyuerr = this.createTabAndInputMediatorItem($("#bmnkisyu-err-tab"));
        let tabswkerr = this.createTabAndInputMediatorItem($("#swk-err-tab"));
        let errLogDownLoadBtn = this.createBtnMediatorItem(this.itemSettingErrLogDownLoadBtn);

        // 変換設定
        let tabKessan = this.createTabAndInputMediatorItem($("#kessan-tab"));
        let tabTaxKbn = this.createTabAndInputMediatorItem($("#taxKbn-tab"));
        let tabTaxRate = this.createTabAndInputMediatorItem($("#taxRate-tab"));
        let tabGyosyu = this.createTabAndInputMediatorItem($("#gyosyu-tab"));
        let tabIvJigyoKbn = this.createTabAndInputMediatorItem($("#ivJigyoKbn-tab"));
        let kessantabitem = this.createMediatorItemStepItemSettingKessan();
        let taxKbntabitem = this.createMediatorItemStepItemSettingTaxRate();
        let taxRatetabitem = this.createMediatorItemStepItemSettingTaxKbn();
        let gyousyutabitem = this.createMediatorItemStepItemSettingGyosyu();
        let ivJigyoKbntabitem = this.createMediatorItemStepItemSettingIvJigyoKbn();
        let itemConvertSettingSaveBtn = this.createBtnMediatorItem(this.itemConvertSettingSaveBtn);

        let mediators: MediatorItem[] = [];
        mediators.push(tabkisyu);
        mediators.push(tabhojyokisyu);
        mediators.push(tabbmnkisyu);
        mediators.push(tabswk);
        kisyutabitem.forEach(n => mediators.push(n));
        hojyokisyutabitem.forEach(n => mediators.push(n));
        bmnkisyutabitem.forEach(n => mediators.push(n));
        swktabitem.forEach(n => mediators.push(n));
        mediators.push(itemSettingSaveBtn);
        mediators.push(tabkisyuerr);
        mediators.push(tabhojyokisyuerr);
        mediators.push(tabbmnkisyuerr);
        mediators.push(tabswkerr);
        mediators.push(errLogDownLoadBtn);

        mediators.push(tabKessan);
        mediators.push(tabTaxKbn);
        mediators.push(tabTaxRate);
        mediators.push(tabGyosyu);
        mediators.push(tabIvJigyoKbn);
        kessantabitem.forEach(n => mediators.push(n));
        taxKbntabitem.forEach(n => mediators.push(n));
        taxRatetabitem.forEach(n => mediators.push(n));
        gyousyutabitem.forEach(n => mediators.push(n));
        ivJigyoKbntabitem.forEach(n => mediators.push(n));
        mediators.push(itemConvertSettingSaveBtn);

        // ステップの先頭要素を保持する
        let topmediator;
        if (this.m_ItemSettingGroup.MigrateItemSettingList.any((n: { FileType: number; }) => n.FileType == MigrationFileType.Zan)) {
            topmediator = mediators[0];
        } else if (this.m_ItemSettingGroup.MigrateItemSettingList.any((n: { FileType: number; }) => n.FileType == MigrationFileType.HojyoZan)) {
            topmediator = mediators[1];
        } else if (this.m_ItemSettingGroup.MigrateItemSettingList.any((n: { FileType: number; }) => n.FileType == MigrationFileType.BmnZan)) {
            topmediator = mediators[2];
        } else {
            topmediator = mediators[3];
        }
        this.topStepMediatorMap[MigrationStep.ItemSetting] = topmediator;

        return mediators;
    }

    private createMediatorItemStepItemSettingKisyu(): MediatorItem[] {
        let mediators: MediatorItem[] = [];
        if (this.kisyuStartRowNo) {
            mediators.push(this.createNumericInputMediatorItem(this.kisyuStartRowNo));

            let combolist = this.itemSettingKisyuColumCmbList.select((cmb: wjInput.ComboBox) => this.createWijmoComboBoxMediatorItem(cmb));
            combolist.forEach(n => mediators.push(n));
        }

        return mediators;
    }

    private createMediatorItemStepItemSettingHojyoKisyu(): MediatorItem[] {
        let mediators: MediatorItem[] = [];
        if (this.hojyoKisyuStartRowNo) {
            mediators.push(this.createNumericInputMediatorItem(this.hojyoKisyuStartRowNo));

            let combolist = this.itemSettingHojyoKisyuColumCmbList.select((cmb: wjInput.ComboBox) => this.createWijmoComboBoxMediatorItem(cmb));
            combolist.forEach(n => mediators.push(n));
        }

        return mediators;
    }

    private createMediatorItemStepItemSettingBmnKisyu(): MediatorItem[] {
        let mediators: MediatorItem[] = [];
        if (this.bmnKisyuStartRowNo) {
            mediators.push(this.createNumericInputMediatorItem(this.bmnKisyuStartRowNo));

            let combolist = this.itemSettingBmnKisyuColumCmbList.select((cmb: wjInput.ComboBox) => this.createWijmoComboBoxMediatorItem(cmb));
            combolist.forEach(n => mediators.push(n));
        }

        return mediators;
    }

    private createMediatorItemStepItemSettingSwk(): MediatorItem[] {
        let mediators: MediatorItem[] = [];
        if (this.swkStartRowNo) {
            let mediatorStartRowNo = this.createNumericInputMediatorItem(this.swkStartRowNo);
            mediators.push(mediatorStartRowNo);

            this.mediator_radioTaxIncluded = this.createRadioMediatorItem($('#radio-taxIncluded'));
            this.mediator_radioTaxExcluded = this.createRadioMediatorItem($('#radio-taxExcluded'));
            this.mediator_uchizeiAutoCalcKbnOn = this.createRadioMediatorItem($('#radio-uchizeiAutoCalcKbnOn'));
            this.mediator_uchizeiAutoCalcKbnOff = this.createRadioMediatorItem($('#radio-uchizeiAutoCalcKbnOff'));

            mediators.push(this.mediator_radioTaxIncluded);
            mediators.push(this.mediator_radioTaxExcluded);
            mediators.push(this.mediator_uchizeiAutoCalcKbnOn);
            mediators.push(this.mediator_uchizeiAutoCalcKbnOff);

            let combolist = this.itemSettingSwkColumCmbList.select((cmb: wjInput.ComboBox) => this.createWijmoComboBoxMediatorItem(cmb));
            combolist.forEach(n => mediators.push(n));

            let mediatorStartRowNoDelegateKeyDownEvent = (mediator: Mediator, e: JQueryKeyEventObject) => {
                switch (e.keyCode) {
                    case wijmo.Key.Enter:
                        if (this.m_TaxKbn == TaxKbn.TaxIncluded) {
                            this.mediator_radioTaxIncluded.setFocus(false);
                        } else if (this.m_TaxKbn == TaxKbn.TaxExcluded) {
                            this.mediator_radioTaxExcluded.setFocus(false);
                        }
                        e.preventDefault();
                        break;
                    case wijmo.Key.Tab:
                        if (e.shiftKey) {
                            mediator.setFocusToPrevItem();
                        } else {
                            if (this.m_TaxKbn == TaxKbn.TaxIncluded) {
                                this.mediator_radioTaxIncluded.setFocus(false);
                            } else if (this.m_TaxKbn == TaxKbn.TaxExcluded) {
                                this.mediator_radioTaxExcluded.setFocus(false);
                            }
                        }
                        e.preventDefault();
                        break;
                }
            }

            let radioTaxKbnDelegateKeyDownEvent = (mediator: Mediator, e: JQueryKeyEventObject) => {
                switch (e.keyCode) {
                    case wijmo.Key.Tab:
                        if (e.shiftKey) {
                            this.swkStartRowNo.setFocus();
                        } else {
                            if (this.m_UchizeiAutoCalcKbn == UchizeiAutoCalcKbn.Auto) {
                                this.mediator_uchizeiAutoCalcKbnOn.setFocus(false);
                            } else if (this.m_UchizeiAutoCalcKbn == UchizeiAutoCalcKbn.Manual) {
                                this.mediator_uchizeiAutoCalcKbnOff.setFocus(false);
                            }
                        }
                        e.preventDefault();
                        break;
                    case wijmo.Key.Left:
                    case wijmo.Key.Right:
                        break;
                    case wijmo.Key.Up:
                    case wijmo.Key.Down:
                        e.preventDefault();
                        break;
                }
            };

            let radioUchizeiAutoCalcKbnDelegateKeyDownEvent = (mediator: Mediator, e: JQueryKeyEventObject) => {
                switch (e.keyCode) {
                    case wijmo.Key.Tab:
                        if (e.shiftKey) {
                            if (this.m_TaxKbn == TaxKbn.TaxIncluded) {
                                this.mediator_radioTaxIncluded.setFocus(false);
                            } else if (this.m_TaxKbn == TaxKbn.TaxExcluded) {
                                this.mediator_radioTaxExcluded.setFocus(false);
                            }
                        } else {
                            this.itemSettingSwkColumCmbList[0].focus();
                        }
                        e.preventDefault();
                        break;
                    case wijmo.Key.Left:
                    case wijmo.Key.Right:
                        break;
                    case wijmo.Key.Up:
                    case wijmo.Key.Down:
                        e.preventDefault();
                        break;
                }
            };

            let firstSwkColumCmbDelegateKeyDownEvent = (mediator: Mediator, e: JQueryKeyEventObject) => {
                switch (e.keyCode) {
                    case wijmo.Key.Enter:
                        mediator.setFocusToNextItem();
                        e.preventDefault();
                        break;
                    case wijmo.Key.Tab:
                        if (e.shiftKey) {
                            if (this.m_UchizeiAutoCalcKbn == UchizeiAutoCalcKbn.Auto) {
                                this.mediator_uchizeiAutoCalcKbnOn.setFocus(false);
                            } else if (this.m_UchizeiAutoCalcKbn == UchizeiAutoCalcKbn.Manual) {
                                this.mediator_uchizeiAutoCalcKbnOff.setFocus(false);
                            }
                        } else {
                            mediator.setFocusToNextItem();
                        }
                        e.preventDefault();
                        break;
                }
            };

            mediatorStartRowNo.delegateKeyDownEvent = mediatorStartRowNoDelegateKeyDownEvent;
            (<ProcessMigrationMediatorItem>this.mediator_radioTaxIncluded).delegateKeyDownEvent = radioTaxKbnDelegateKeyDownEvent;
            (<ProcessMigrationMediatorItem>this.mediator_radioTaxExcluded).delegateKeyDownEvent = radioTaxKbnDelegateKeyDownEvent;
            (<ProcessMigrationMediatorItem>this.mediator_uchizeiAutoCalcKbnOn).delegateKeyDownEvent = radioUchizeiAutoCalcKbnDelegateKeyDownEvent;
            (<ProcessMigrationMediatorItem>this.mediator_uchizeiAutoCalcKbnOff).delegateKeyDownEvent = radioUchizeiAutoCalcKbnDelegateKeyDownEvent;
            combolist[0].delegateKeyDownEvent = firstSwkColumCmbDelegateKeyDownEvent;
        }

        return mediators;
    }

    private createMediatorItemStepItemSettingKessan(): MediatorItem[] {
        let mediators: MediatorItem[] = [];
        if (this.m_ItemConvertSettingGroup != null) {
            let setting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.singleOrNull((n: { ConvertCategory: number; }) => n.ConvertCategory == MigrationConvertCategory.Nmonth);
            if (!MjsUtilities.isNull(setting)) {
                for (let data of setting.MigrateItemConvertSettingDetailList) {
                    mediators.push(this.createWijmoComboBoxMediatorItem(this.input_cmbKessans[data.BeforeValue]));
                }
            }
        }

        return mediators;
    }

    private createMediatorItemStepItemSettingTaxKbn(): MediatorItem[] {
        let mediators: MediatorItem[] = [];
        if (this.m_ItemConvertSettingGroup != null) {
            let taxCodeSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.singleOrNull((n: { ConvertCategory: number; ConvertItem: number; }) => n.ConvertCategory == MigrationConvertCategory.TaxCode && n.ConvertItem == MigrationConvertItem.TaxCode);
            let taxRateSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.singleOrNull((n: { ConvertCategory: number; ConvertItem: number; }) => n.ConvertCategory == MigrationConvertCategory.TaxCode && n.ConvertItem == MigrationConvertItem.TaxRate);
            let typeCdSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.singleOrNull((n: { ConvertCategory: number; ConvertItem: number; }) => n.ConvertCategory == MigrationConvertCategory.TaxCode && n.ConvertItem == MigrationConvertItem.TypeCd);
            if (!MjsUtilities.isNull(taxCodeSetting)) {
                for (let data of taxCodeSetting.MigrateItemConvertSettingDetailList) {
                    mediators.push(this.createTaxKbnWijmoAutoCompleteMediatorItem(this.input_cmbTaxKbns[data.BeforeValue]));

                    if (!MjsUtilities.isNull(taxRateSetting)) {
                        mediators.push(this.createWijmoComboBoxMediatorItem(this.input_cmbTaxRates[data.BeforeValue]));
                    }

                    if (!MjsUtilities.isNull(typeCdSetting)) {
                        mediators.push(this.createWijmoComboBoxMediatorItem(this.input_cmbTypeCds[data.BeforeValue]));
                    }
                }
            }
        }

        return mediators;
    }

    private createMediatorItemStepItemSettingTaxRate(): MediatorItem[] {
        let mediators: MediatorItem[] = [];
        if (this.m_ItemConvertSettingGroup != null) {
            let setting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.singleOrNull((n: { ConvertCategory: number; }) => n.ConvertCategory == MigrationConvertCategory.TaxRate);
            if (!MjsUtilities.isNull(setting)) {
                for (let data of setting.MigrateItemConvertSettingDetailList) {
                    mediators.push(this.createWijmoComboBoxMediatorItem(this.input_cmbTaxRates[data.BeforeValue]));
                }
            }
        }

        return mediators;
    }

    private createMediatorItemStepItemSettingGyosyu(): MediatorItem[] {
        let mediators: MediatorItem[] = [];
        if (this.m_ItemConvertSettingGroup != null) {
            let setting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.singleOrNull((n: { ConvertCategory: number; }) => n.ConvertCategory == MigrationConvertCategory.TypeCd);
            if (!MjsUtilities.isNull(setting)) {
                for (let data of setting.MigrateItemConvertSettingDetailList) {
                    mediators.push(this.createWijmoComboBoxMediatorItem(this.input_cmbTypeCds[data.BeforeValue]));
                }
            }
        }

        return mediators;
    }

    private createMediatorItemStepItemSettingIvJigyoKbn(): MediatorItem[] {
        let mediators: MediatorItem[] = [];
        if (this.m_ItemConvertSettingGroup != null) {
            let setting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.singleOrNull((n: { ConvertCategory: number; }) => n.ConvertCategory == MigrationConvertCategory.IvJigyoKbn);
            if (!MjsUtilities.isNull(setting)) {
                for (let data of setting.MigrateItemConvertSettingDetailList) {
                    mediators.push(this.createWijmoComboBoxMediatorItem(this.input_cmbIvJigyoKbns[data.BeforeValue]));
                }
            }
        }

        return mediators;
    }

    private createMediatorItemStepKmkSetting(): MediatorItem[] {
        let mediators: MediatorItem[] = [];
        let registKmkBtn = this.createBtnMediatorItem(this.registKmkBtn);
        let refreshBtn = this.createBtnMediatorItem(this.refreshBtn);
        let inputKmks = [];
        for (let grp of this.m_kmkSettingDatas) {
            for (let detail of grp.KmkSettingDetails) {
                inputKmks.push(this.createWijmoAutoCompleteMediatorItem(this.input_cmbDstkmks[`${detail.GroupNo}_${detail.DetailNo}`]));
                inputKmks.push(this.createNumericInputMediatorItem(this.input_inpKmkSums[`${detail.GroupNo}_${detail.DetailNo}`]));
            }
        }

        let kmkSettingSaveBtn = this.createBtnMediatorItem(this.kmkSettingSaveBtn);
        let kmkKisyuImportBtn = this.createBtnMediatorItem(this.kmkKisyuImportBtn);

        mediators.push(registKmkBtn);
        mediators.push(refreshBtn);
        inputKmks.forEach(n => mediators.push(n));
        mediators.push(kmkSettingSaveBtn);
        mediators.push(kmkKisyuImportBtn);

        // ステップの先頭要素を保持する
        this.topStepMediatorMap[MigrationStep.KmkSetting] = mediators[0];

        return mediators;
    }

    private createMediatorItemStepHojyoSetting(hojyoMediators: Array<MediatorItem[]>): MediatorItem[] {
        let mediators: MediatorItem[] = [];
        let tabhojyo = this.createTabAndInputMediatorItem($("#hojyo-tab"));
        let tabgeneralhojyo = this.createTabAndInputMediatorItem($("#generalhojyo-tab"));
        let tabbmn = this.createTabAndInputMediatorItem($("#bmn-tab"));
        let tabsegment = this.createTabAndInputMediatorItem($("#segment-tab"));
        let tabkouji = this.createTabAndInputMediatorItem($("#kouji-tab"));
        let hojyokmkSettingSaveBtn = this.createBtnMediatorItem(this.hojyokmkSettingSaveBtn);
        let hojyokmkKisyuImportBtn = this.createBtnMediatorItem(this.hojyokmkKisyuImportBtn);
        let hojyokmkKisyuCheckBtn = this.createBtnMediatorItem(this.hojyokmkKisyuCheckBtn);
        let bmnKisyuCheckBtn = this.createBtnMediatorItem(this.bmnKisyuCheckBtn);
        let kisyuImportErrLogDownLoadBtn = this.createBtnMediatorItem(this.kisyuImportErrLogDownLoadBtn);
        let tabhojyo_importerror = this.createTabAndInputMediatorItem($("#hojyokisyu-import-err-tab"));
        let tabbmn_importerror = this.createTabAndInputMediatorItem($("#bmnkisyu-import-err-tab"));

        mediators.push(tabhojyo);
        mediators.push(tabgeneralhojyo);
        mediators.push(tabbmn);
        mediators.push(tabsegment);
        mediators.push(tabkouji);
        hojyoMediators.forEach(n => n.forEach(m => mediators.push(m)));
        mediators.push(hojyokmkSettingSaveBtn);
        mediators.push(hojyokmkKisyuImportBtn);
        mediators.push(hojyokmkKisyuCheckBtn);
        mediators.push(bmnKisyuCheckBtn);
        mediators.push(tabhojyo_importerror);
        mediators.push(tabbmn_importerror);
        mediators.push(kisyuImportErrLogDownLoadBtn);

        // ステップの先頭要素を保持する
        let topmediator;
        if (this.m_hojyokmkSettingDatas.length > 0) {
            topmediator = mediators[0];
        } else if (this.m_generalhojyoSettingDatas.length > 0) {
            topmediator = mediators[1];
        } else if (this.m_bmnSettingDatas.length > 0) {
            topmediator = mediators[2];
        } else if (this.m_segmentSettingDatas.length > 0) {
            topmediator = mediators[3];
        } else {
            topmediator = mediators[4];
        }
        this.topStepMediatorMap[MigrationStep.HojyoSetting] = topmediator;

        return mediators;
    }

    private createMediatorItemStepHojyoSettingHojyo(): MediatorItem[] {
        let mediators: MediatorItem[] = [];
        let inputHojyos = [];
        for (let data of this.m_hojyokmkSettingDatas) {
            inputHojyos.push(this.createWijmoHowRegistComboBoxMediatorItem(this.input_cmbHowRegistHojyos[data.DetailNo]));
            inputHojyos.push(this.createWijmoComboBoxMediatorItem(this.input_cmbSelectHojyos[data.DetailNo]));
            inputHojyos.push(this.createNumericInputMediatorItem(this.input_inpHojyoSums[data.DetailNo]));
        }

        inputHojyos.forEach(n => mediators.push(n));

        return mediators;
    }

    private createMediatorItemStepHojyoSettingGeneralHojyo(): MediatorItem[] {
        let mediators: MediatorItem[] = [];
        let inputGeneralHojyo = [];
        for (let data of this.m_generalhojyoSettingDatas) {
            inputGeneralHojyo.push(this.createWijmoHowRegistComboBoxMediatorItem(this.input_cmbHowRegistGeneralHojyos[data.DetailNo]));
            inputGeneralHojyo.push(this.createWijmoComboBoxMediatorItem(this.input_cmbSelectGeneralHojyos[data.DetailNo]));
        }

        inputGeneralHojyo.forEach(n => mediators.push(n));

        return mediators;
    }

    private createMediatorItemStepHojyoSettingBmn(): MediatorItem[] {
        let mediators: MediatorItem[] = [];
        let inputBmns = [];
        for (let data of this.m_bmnSettingDatas) {
            inputBmns.push(this.createWijmoHowRegistComboBoxMediatorItem(this.input_cmbHowRegistBmns[data.DetailNo]));
            inputBmns.push(this.createWijmoComboBoxMediatorItem(this.input_cmbSelectBmns[data.DetailNo]));
        }

        inputBmns.forEach(n => mediators.push(n));

        return mediators;
    }

    private createMediatorItemStepHojyoSettingSegment(): MediatorItem[] {
        let mediators: MediatorItem[] = [];
        let inputSegments = [];
        for (let data of this.m_segmentSettingDatas) {
            inputSegments.push(this.createWijmoHowRegistComboBoxMediatorItem(this.input_cmbHowRegistSegments[data.DetailNo]));
            inputSegments.push(this.createWijmoComboBoxMediatorItem(this.input_cmbSelectSegments[data.DetailNo]));
        }

        inputSegments.forEach(n => mediators.push(n));

        return mediators;
    }

    private createMediatorItemStepHojyoSettingKouji(): MediatorItem[] {
        let mediators: MediatorItem[] = [];
        let inputKoujis = [];
        for (let data of this.m_koujiSettingDatas) {
            inputKoujis.push(this.createWijmoHowRegistComboBoxMediatorItem(this.input_cmbHowRegistKoujis[data.DetailNo]));
            inputKoujis.push(this.createWijmoComboBoxMediatorItem(this.input_cmbSelectKoujis[data.DetailNo]));
        }

        inputKoujis.forEach(n => mediators.push(n));

        return mediators;
    }

    private createMediatorItemStepSwkImport(): MediatorItem[] {
        let mediators: MediatorItem[] = [];
        let checkBoxs = [];
        for (let i = 0; i < this.$scope.swkSetting.SwkInfoList.length; i++) {
            if (i == 0 && this.m_JournalSetting.ExcludedJournalAmount != 0) {
                // 先頭が対象外の場合はスキップ
                continue;
            }
            checkBoxs.push(this.createTabAndInputMediatorItem($(`#swkinfo-checkbox${i}`)));
        }
        let checkBoxErrCancelFlg = this.createTabAndInputMediatorItem($("#checkbox-errCancelFlg"));
        let swkImportBtn = this.createBtnMediatorItem(this.swkImportBtn);
        let swkErrLogDownLoadBtn = this.createBtnMediatorItem(this.swkErrLogDownLoadBtn);

        checkBoxs.forEach(n => mediators.push(n));
        mediators.push(checkBoxErrCancelFlg);
        mediators.push(swkImportBtn);
        mediators.push(swkErrLogDownLoadBtn);

        // ステップの先頭要素を保持する
        let top: MediatorItem = null!;
        for (let checkBox of checkBoxs) {
            if (!checkBox.m_control.is(":disabled")) {
                top = checkBox;
                break;
            }
        }
        if (top == null) {
            top = checkBoxErrCancelFlg;
        }
        this.topStepMediatorMap[MigrationStep.SwkImport] = top;

        return mediators;
    }

    private createBtnMediatorItem(button: Button | LinkButton): ProcessMigrationMediatorItem {
        let mediatorItem = new ProcessMigrationMediatorItem($(button.getSelector()), false, true);

        mediatorItem.delegateBeforeFocus = (args: mediatorBeforeFocusArgs) => {
            args.skipToAfterNextItem = !button.isEnabled() || $(button.getSelector()).is(":hidden");
        };

        return mediatorItem;
    }

    private createRadioMediatorItem(button: JQuery): ProcessMigrationMediatorItem {
        let mediatorItem = new ProcessMigrationMediatorItem(button, false, true, true);

        mediatorItem.delegateBeforeFocus = (args: mediatorBeforeFocusArgs) => {
            args.skipToAfterNextItem = button.prop("disabled") || button.is(":hidden");
        };

        return mediatorItem;
    }

    private createWijmoAutoCompleteMediatorItem(control: wjInput.AutoComplete): ProcessMigrationMediatorItem {
        let mediatorItem = new ProcessMigrationMediatorItem($(control.inputElement), true, true);

        mediatorItem.delegateBeforeFocus = (args: mediatorBeforeFocusArgs) => {
            args.skipToAfterNextItem = control.isDisabled || $(control.inputElement).is(":hidden");
        };

        return mediatorItem;
    }

    private createTaxKbnWijmoAutoCompleteMediatorItem(control: wjInput.AutoComplete): ProcessMigrationTaxKbnAutoCompleteMediatorItem {
        let mediatorItem = new ProcessMigrationTaxKbnAutoCompleteMediatorItem($(control.inputElement), true, true, null!, control, this);

        mediatorItem.delegateBeforeFocus = (args: mediatorBeforeFocusArgs) => {
            args.skipToAfterNextItem = control.isDisabled || $(control.inputElement).is(":hidden");
        };

        return mediatorItem;
    }

    private createNumericInputMediatorItem(control: NumericInput): ProcessMigrationMediatorItem {
        let mediatorItem = new ProcessMigrationMediatorItem(control.getCtrlList()[0], true, true);

        mediatorItem.delegateBeforeFocus = (args: mediatorBeforeFocusArgs) => {
            args.skipToAfterNextItem = !control.enabled || control.getCtrlList()[0].is(":hidden");
        };

        return mediatorItem;
    }

    private createWijmoHowRegistComboBoxMediatorItem(control: wjInput.ComboBox): ProcessMigrationHowRegistCmbMediatorItem {
        let mediatorItem = new ProcessMigrationHowRegistCmbMediatorItem($(control.inputElement), true, true, null!, control, this);

        mediatorItem.delegateBeforeFocus = (args: mediatorBeforeFocusArgs) => {
            args.skipToAfterNextItem = control.isDisabled || $(control.inputElement).is(":hidden");
        };

        return mediatorItem;
    }

    private createWijmoComboBoxMediatorItem(control: wjInput.ComboBox): ProcessMigrationMediatorItem {
        let mediatorItem = new ProcessMigrationMediatorItem($(control.inputElement), true, true);

        mediatorItem.delegateBeforeFocus = (args: mediatorBeforeFocusArgs) => {
            args.skipToAfterNextItem = control.isDisabled || $(control.inputElement).is(":hidden");
        };

        return mediatorItem;
    }

    private createTabAndInputMediatorItem(control: JQuery): ProcessMigrationMediatorItem {
        let mediatorItem = new ProcessMigrationMediatorItem(control, false, true);

        mediatorItem.delegateBeforeFocus = (args: mediatorBeforeFocusArgs) => {
            args.skipToAfterNextItem = control.is(":disabled") || control.is(":hidden");
        };

        return mediatorItem;
    }
    // #endregion mediator

    // #region masterDataLoad
    protected masterDataLoadCompleteCallback(): void {
        let dtMainContainer = this.masterDataManager.getDataContainer<DTMAINCacheViewModel, DTMAINCacheContainer>(CacheDataType.DTMAIN);
        this.dtMainDataCache = dtMainContainer.models;

        let taxInfoContainer = this.masterDataManager.getDataContainer<TaxInfoCacheViewModel, TaxInfoCacheContainer>(CacheDataType.TaxInfo);
        this.taxInfoDataCache = taxInfoContainer.models;

        let kbnInfoContainer = this.masterDataManager.getDataContainer<KbnInfoCacheViewModel, KbnInfoCacheContainer>(CacheDataType.KbnInfo);
        this.kbnInfoDataCache = kbnInfoContainer.models;

        let masterInfoContainer = this.masterDataManager.getDataContainer<MasterInfoCacheViewModel, MasterInfoCacheContainer>(CacheDataType.MasterInfo);
        this.masterInfoDataCache = masterInfoContainer.models;

        let kmkMaKmkContainer = this.masterDataManager.getDataContainer<KmkMACacheViewModel, KmkMA_KmkCacheContainer>(CacheDataType.KmkMA_Kmk);
        this.kmkMaKmkDataCache = kmkMaKmkContainer.models;

        let kmkMaSubContainer = this.masterDataManager.getDataContainer<KmkMACacheViewModel, KmkMA_SubCacheContainer>(CacheDataType.KmkMA_Sub);
        this.kmkMaSubDataCache = kmkMaSubContainer.models;

        let kmkInfoContainer = this.masterDataManager.getDataContainer<KmkInfoCacheViewModel, KmkInfoCacheContainer>(CacheDataType.KmkInfo);
        this.kmkInfoDataCache = kmkInfoContainer.models;

        let bankContainer = this.masterDataManager.getDataContainer<HojyoMACacheViewModel, HojyoMA_BankCacheContainer>(CacheDataType.HojyoMA_Bank);
        this.bankDataCache = bankContainer.models;

        let clientContainer = this.masterDataManager.getDataContainer<HojyoMACacheViewModel, HojyoMA_ClientCacheContainer>(CacheDataType.HojyoMA_Client);
        this.clientDataCache = clientContainer.models;

        let staffDataContainer = this.masterDataManager.getDataContainer<HojyoMACacheViewModel, HojyoMA_StaffCacheContainer>(CacheDataType.HojyoMA_Staff);
        this.staffDataCache = staffDataContainer.models;

        let general1Container = this.masterDataManager.getDataContainer<HojyoMACacheViewModel, HojyoMA_GenHojo1CacheContainer>(CacheDataType.HojyoMA_GenHojo1);
        this.general1DataCache = general1Container.models;

        let general2Container = this.masterDataManager.getDataContainer<HojyoMACacheViewModel, HojyoMA_GenHojo2CacheContainer>(CacheDataType.HojyoMA_GenHojo2);
        this.general2DataCache = general2Container.models;

        let general3Container = this.masterDataManager.getDataContainer<HojyoMACacheViewModel, HojyoMA_GenHojo3CacheContainer>(CacheDataType.HojyoMA_GenHojo3);
        this.general3DataCache = general3Container.models;

        let general4Container = this.masterDataManager.getDataContainer<HojyoMACacheViewModel, HojyoMA_GenHojo4CacheContainer>(CacheDataType.HojyoMA_GenHojo4);
        this.general4DataCache = general4Container.models;

        let general5Container = this.masterDataManager.getDataContainer<HojyoMACacheViewModel, HojyoMA_GenHojo5CacheContainer>(CacheDataType.HojyoMA_GenHojo5);
        this.general5DataCache = general5Container.models;

        let bmnContainer = this.masterDataManager.getDataContainer<HojyoMACacheViewModel, HojyoMA_BmnCacheContainer>(CacheDataType.HojyoMA_Bmn);
        this.bmnDataCache = bmnContainer.models;

        let segmentContainer = this.masterDataManager.getDataContainer<HojyoMACacheViewModel, HojyoMA_SegCacheContainer>(CacheDataType.HojyoMA_Seg);
        this.segmentDataCache = segmentContainer.models;

        let koujiContainer = this.masterDataManager.getDataContainer<HojyoMACacheViewModel, HojyoMA_KojiCacheContainer>(CacheDataType.HojyoMA_Koji);
        this.koujiDataCache = koujiContainer.models;

        if (this.isHojyoKmkSettingSaveBtnClick) {
            this.setCmbSelectHojyosItemSource(MigrationHojyoTabKbn.Hojyo, this.m_hojyokmkSettingDatas, false);
            this.initHojyoKmkSettingTable(this.m_hojyokmkSettingDatas, false);
            this.setCmbSelectHojyosItemSource(MigrationHojyoTabKbn.GeneralHojyo, this.m_generalhojyoSettingDatas, false);
            this.initGeneralHojyoSettingTable(this.m_generalhojyoSettingDatas, false);
            this.setCmbSelectHojyosItemSource(MigrationHojyoTabKbn.Bmn, this.m_bmnSettingDatas, false);
            this.initBmnSettingTable(MigrationHojyoTabKbn.Bmn, this.m_bmnSettingDatas, false);
            this.setCmbSelectHojyosItemSource(MigrationHojyoTabKbn.Segment, this.m_segmentSettingDatas, false);
            this.initBmnSettingTable(MigrationHojyoTabKbn.Segment, this.m_segmentSettingDatas, false);
            this.setCmbSelectHojyosItemSource(MigrationHojyoTabKbn.Kouji, this.m_koujiSettingDatas, false);
            this.initBmnSettingTable(MigrationHojyoTabKbn.Kouji, this.m_koujiSettingDatas, false);
            this.m_hojyokmkSettingDatas.forEach(n => n.IsEdited = false);
            this.m_generalhojyoSettingDatas.forEach(n => n.IsEdited = false);
            this.m_bmnSettingDatas.forEach(n => n.IsEdited = false);
            this.m_segmentSettingDatas.forEach(n => n.IsEdited = false);
            this.m_koujiSettingDatas.forEach(n => n.IsEdited = false);
            this.isHojyoKmkSettingSaveBtnClick = false;
            return;
        }

        if (!this.isRefreshBtnClick && !this.isHojyoKmkSettingSaveBtnClick && !this.isSwkImportBtnClick) {
            // 初期表示のみ
            this.taxInfoDataCacheForItemSetting = this.taxInfoDataCache[0]; // 項目設定では最初にキャッシュした消費税情報しか使用しない
            this.kbnInfoDataCacheForItemSetting = this.kbnInfoDataCache.singleOrNull((n: { RecKbn: number; }) => n.RecKbn == PartitionRecordKbn.SwkInputCondition);
            this.createTaxCodeTaxRateMap();
            if (this.isBeforeCurrentStep(MigrationStep.ItemSetting, true) && this.m_StepCheckResult.IsItemSettingStepEnable && this.m_ItemSettingGroup.MigrateItemSettingList.any((n: { FileType: number; }) => n.FileType == MigrationFileType.Swk)) {
                let swk = this.m_ItemSettingGroup.MigrateItemSettingList.where(n => n.FileType == MigrationFileType.Swk)[0];

                // 項目設定コンボボックス
                this.$timeout(() => {
                    if (this.itemSettingSwkColumCmbList) {
                        this.itemSettingSwkColumCmbList.forEach((item: wjInput.ComboBox) => {
                            item.dispose();
                        });
                    }
                    this.itemSettingSwkColumCmbList = [];
                    swk.MigrateItemSettingDetailList.forEach(n => {
                        let cmb = this.createSwkItemSettingWijmoCombBox(n);
                        this.itemSettingSwkColumCmbList.push(cmb);
                    });
                    this.checkSwkRequiredItem();
                    this.mediator_stepItemSettingMediatorsSwkOK = true;
                });
            }
        }

        if (this.isBeforeCurrentStep(MigrationStep.ItemSetting, true) && this.m_StepCheckResult.IsItemSettingStepEnable) {
            if (this.$scope.kisyuItemSetting != null) {
                this.kisyuFileContentGrid.asyncRefresh(300, true);
            } else if (this.$scope.hojyoKisyuItemSetting != null) {
                this.hojyoKisyuFileContentGrid.asyncRefresh(300, true);
            } else if (this.$scope.bmnKisyuItemSetting != null) {
                this.bmnKisyuFileContentGrid.asyncRefresh(300, true);
            } else {
                this.swkFileContentGrid.asyncRefresh(300, true);
            }

            if (this.m_ItemConvertSettingGroup != null && this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.length > 0) {
                if (this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.any((n: { ConvertCategory: number; ConvertItem: number; }) => n.ConvertCategory == MigrationConvertCategory.TaxCode && n.ConvertItem == MigrationConvertItem.TaxRate)) {
                    this.$scope.taxKbnList.forEach((n: { BeforeValue: string | number; }, index: any) => {
                        this.input_cmbTaxKbns[n.BeforeValue].onLostFocus();
                    });
                }
            }
        }

        if (this.isBeforeCurrentStep(MigrationStep.KmkSetting, true) && this.m_StepCheckResult.IsKmkSettingStepEnable && this.m_kmkSettingDatas.length > 0) {
            this.setCmbDstKmkItemSource(this.m_kmkSettingDatas);
            this.initKmkSettingTable(this.m_kmkSettingDatas);
        }

        if (this.isBeforeCurrentStep(MigrationStep.HojyoSetting, true) && this.m_StepCheckResult.IsHojyoSettingStepEnable && this.m_hojyokmkSettingDatas.length > 0) {
            this.$timeout(() => { // 画面表示を待つ
                this.setCmbSelectHojyosItemSource(MigrationHojyoTabKbn.Hojyo, this.m_hojyokmkSettingDatas);
                this.initHojyoKmkSettingTable(this.m_hojyokmkSettingDatas);
            }, 100);
        }

        if (this.isBeforeCurrentStep(MigrationStep.HojyoSetting, true) && this.m_StepCheckResult.IsHojyoSettingStepEnable && this.m_generalhojyoSettingDatas.length > 0) {
            this.$timeout(() => { // 画面表示を待つ
                this.setCmbSelectHojyosItemSource(MigrationHojyoTabKbn.GeneralHojyo, this.m_generalhojyoSettingDatas);
                this.initGeneralHojyoSettingTable(this.m_generalhojyoSettingDatas);
            }, 100);
        }

        if (this.isBeforeCurrentStep(MigrationStep.HojyoSetting, true) && this.m_StepCheckResult.IsHojyoSettingStepEnable && this.m_bmnSettingDatas.length > 0) {
            this.$timeout(() => { // 画面表示を待つ
                this.setCmbSelectHojyosItemSource(MigrationHojyoTabKbn.Bmn, this.m_bmnSettingDatas);
                this.initBmnSettingTable(MigrationHojyoTabKbn.Bmn, this.m_bmnSettingDatas);
            }, 100);
        }

        if (this.isBeforeCurrentStep(MigrationStep.HojyoSetting, true) && this.m_StepCheckResult.IsHojyoSettingStepEnable && this.m_segmentSettingDatas.length > 0) {
            this.$timeout(() => { // 画面表示を待つ
                this.setCmbSelectHojyosItemSource(MigrationHojyoTabKbn.Segment, this.m_segmentSettingDatas);
                this.initBmnSettingTable(MigrationHojyoTabKbn.Segment, this.m_segmentSettingDatas);
            }, 100);
        }

        if (this.isBeforeCurrentStep(MigrationStep.HojyoSetting, true) && this.m_StepCheckResult.IsHojyoSettingStepEnable && this.m_koujiSettingDatas.length > 0) {
            this.$timeout(() => { // 画面表示を待つ
                this.setCmbSelectHojyosItemSource(MigrationHojyoTabKbn.Kouji, this.m_koujiSettingDatas);
                this.initBmnSettingTable(MigrationHojyoTabKbn.Kouji, this.m_koujiSettingDatas);
            }, 100);
        }

        if (this.isRefreshBtnClick) {
            setTimeout(() => {
                $(this.refreshBtn.getSelector()).focus();
            });
        } else if (this.isSwkImportBtnClick) {
            setTimeout(() => {
                $(this.swkImportBtn.getSelector()).focus();
            });
        } else {
            // 初期表示
            this.scrollTimerid = setInterval(() => {
                if (this.scrollOK) {
                    // プログレスバーが非表示の場合
                    if ($('#mjsCommonProgressConfirm').is(':hidden')) {
                        clearInterval(this.scrollTimerid);

                        // 現在のステップにスクロールする
                        this.scroll(`#step` + this.getStepName(this.m_CurrentStep));

                        // 先頭要素にフォーカス
                        setTimeout(() => {
                            let mediator = this.topStepMediatorMap[this.m_CurrentStep];
                            if (!MjsUtilities.isNull(mediator)) {
                                mediator.getFocusControl().focus();
                            }
                        });
                    }
                }
            }, 200);
        }

        this.isRefreshBtnClick = false;
        this.isSwkImportBtnClick = false;
    }
    // #endregion masterDataLoad

    // #region setEventHeader
    private setEventHeader(): void {
        this.header.closeButton.setEventHandler(new MjsDelegateContainer(this.onCloseButton, this));
        this.header.stepFileImportButton.setEventHandler(new MjsDelegateContainer(this.onStepFileImportButton, this));
        this.header.stepItemSettingButton.setEventHandler(new MjsDelegateContainer(this.onStepItemSettingButton, this));
        this.header.stepKmkSettingButton.setEventHandler(new MjsDelegateContainer(this.onStepKmkSettingButton, this));
        this.header.stepHojyoSettingButton.setEventHandler(new MjsDelegateContainer(this.onStepHojyoSettingButton, this));
        this.header.stepSwkImportButton.setEventHandler(new MjsDelegateContainer(this.onStepSwkImportButton, this));
        this.header.stepFinishButton.setEventHandler(new MjsDelegateContainer(this.onStepFinishButton, this));

        $(".migration-nav > .nav-tabs > li").on('dragend', function (e) {
            if (!$(e).hasClass("active")) {
                $(e).find('a').blur();
            }
        });

        $(".migration-nav > .nav-tabs > li").on('drop dragover', function (e) {
            e.stopPropagation();
            e.preventDefault();
        });
    }

    private onCloseButton(): void {
        if (this.isBeforeCurrentStep(MigrationStep.FileImport, false)) {
            let notItemStepSave = this.m_StepCheckResult.IsItemSettingStepEnable && (this.$scope.itemSettingSaveStatus != MigrationSettingSaveStatus.Saved || (this.$scope.itemConvertSettingSaveStatus != MigrationSettingSaveStatus.Saved && this.m_ItemConvertSettingGroup != null && this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.length > 0));
            let notKmkStepSave = false;
            let notHojyoStepSave = false;
            let notSwkStepSave = false;
            if (this.isBeforeCurrentStep(MigrationStep.ItemSetting, false)) {
                notKmkStepSave = this.m_StepCheckResult.IsKmkSettingStepEnable && !this.isKmkSettingSave;
                notHojyoStepSave = this.m_StepCheckResult.IsHojyoSettingStepEnable && ((!this.isHojyoKmkSettingSave && this.m_hojyokmkSettingDatas.length > 0) || (!this.isGeneralHojyoSettingSave && this.m_generalhojyoSettingDatas.length > 0) || (!this.isBmnSettingSave && this.m_bmnSettingDatas.length > 0) || (!this.isSegmentSettingSave && this.m_segmentSettingDatas.length > 0) || (!this.isKoujiSettingSave && this.m_koujiSettingDatas.length > 0));
                notSwkStepSave = this.m_StepCheckResult.IsSwkImportStepEnable && !this.isSwkInfoSave;
            }
            if (notItemStepSave || notKmkStepSave || notHojyoStepSave || notSwkStepSave) {
                // 閉じる確認ダイアログを表示
                if (!this.confirmDialog_Close) {
                    this.confirmDialog_Close = this.createConfirmDialog("#confirmDialog_Close", ProcessMigration.dlgConfirmMsg_Close, () => { this.onClose(); });
                }
                this.confirmDialog_Close.open(".on-no-confirmDialog_Close");
                return;
            }
        }

        this.onClose();
    }
    private onStepFileImportButton(): void {
        this.scroll("#stepFileImport");
    }
    private onStepItemSettingButton(): void {
        this.scroll("#stepItemSetting");
    }
    private onStepKmkSettingButton(): void {
        this.scroll("#stepKmkSetting");
    }
    private onStepHojyoSettingButton(): void {
        this.scroll("#stepHojyoSetting");
    }
    private onStepSwkImportButton(): void {
        this.scroll("#stepSwkImport");
    }
    private onStepFinishButton(): void {
        this.scroll("#stepFinish");
    }
    private scroll(id: string): void {
        setTimeout(()=> {
            const paddingTop = 5; // 上部余白
            const mainBodyWrapper = $("#migration-main-body");
            const scrollTarget = $(id);
            const position = scrollTarget.offset()!.top + mainBodyWrapper.scrollTop() - mainBodyWrapper.offset()!.top - paddingTop ;
            mainBodyWrapper.animate({ scrollTop: position }, "slow", "swing");
        }, 300);
    }
    // #endregion setEventHeader

    // #region setEventStepFileImport
    private setEventStepFileImport(): void {
        // 移行対象ファイル選択
        const fileselect = $('#files-select');
        this.$scope.onFileSelect = () => {
            if (this.isFileImport) {
                $.when.apply(this, [this.reset()]).done(() => {
                    this.m_CurrentStep = MigrationStep.FileImport;
                    this.showStep(MigrationStep.FileImport);
                    this.$timeout(() => {
                        fileselect.click();
                    });
                });
            } else {
                fileselect.click();
            }
        };
        fileselect.unbind('change');
        fileselect.change((e: any) => { this.fileSelectChangeEvent(e); return false; });

        // 移行対象ファイルドラッグ&ドロップ
        $('#file-upload').bind({
            drop: (e: JQueryEventObject) => {
                let theDragEvent: DragEvent = <DragEvent>e.originalEvent;
                this.dragEventObject = theDragEvent.dataTransfer!.files;
                if (this.dragEventObject.length == 0) {
                    // ファイルのドラッグ&ドロップ以外は何もしない
                    return false;
                }

                if (UserAgentUtil.isEdge()) {
                    if (this.dragEventObject[0].name.indexOf(".url") != -1) {
                        // 旧Edgeのタブのドラッグ&ドロップ対策
                        return false;
                    }
                }

                e.preventDefault();
                if (this.isFileImport) {
                    $.when.apply(this, [this.reset()]).done(() => {
                        this.$timeout(() => {
                            this.m_CurrentStep = MigrationStep.FileImport;
                            this.showStep(MigrationStep.FileImport);
                            this.fileDropEvent();
                        });
                    });
                } else {
                    this.fileDropEvent();
                }

                return false;
            },
            dragenter: () => { return false; }, //デフォルト動作抑制
            dragover: () => { return false; } //デフォルト動作抑制
        });

        // ゴミ箱アイコン
        this.$scope.trashClick = (rowIndex: number) => { this.deleteFiles(rowIndex); };

        // ファイルをインポートするボタン
        this.fileImportBtn.setEventHandler(new MjsDelegateContainer<Function, any>(() => {
            if (this.fileImport_clickEventInProg) {
                return;
            }
            this.fileImport_clickEventInProg = true;

            let success = (isSame: boolean, textStatus: string, jqXHR: JQueryXHR) => {
                if (!isSame) {
                    // 前回設定削除確認ダイアログを表示
                    if (!this.confirmDialog_DeletePreSetting) {
                        this.confirmDialog_DeletePreSetting = this.createConfirmDialog("#confirmDialog_DeletePreSetting", ProcessMigration.dlgConfirmMsg_DeletePreSetting, () => {
                            $.when.apply(this, [this.delete()]).done(() => {
                                this.m_CurrentStep = MigrationStep.FileImport;
                                this.showStep(MigrationStep.FileImport);
                                $("#file-import").show();
                                this.$timeout(() => {
                                    this.importFiles();
                                });
                            });
                        }, () => {
                            this.fileImport_clickEventInProg = false;
                        });
                    }
                    this.$timeout(() => {
                        this.confirmDialog_DeletePreSetting.open(".on-no-confirmDialog_DeletePreSetting");
                    });
                } else {
                    this.importFiles();
                }
            };
            let complete = (jqXHR: JQueryXHR, textStatus: string) => { };
            let error = (data: any, jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => { };
            let model: MigrationFileImportViewModel = {
                MigrationFileInfos: this.m_FileInfos
            };
            let settings: AjaxSettings = {
                url: "/api/v1/ProcessMigration/ImportCheck",
                type: "POST",
                data: model,
                showBusyIndicator: true,
                beforeSend: null!,
                success: success,
                complete: complete,
                error: error
            };

            // ファイルインポートチェック
            Ajax.perform(settings);
        }, this));
    }

    private fileSelectChangeEvent(e: any): boolean {
        let files: File[] = e.target.files;

        // valueクリア後のchangeイベントは処理しない
        if (files.length == 0) { return false; }

        this.fileUpload(files);

        // 同じファイルが選択された場合もchangeイベントで拾えるよう、valueをクリア
        e.target.value = "";
        return false;
    }

    private fileDropEvent() {
        let files: File[] = [];
        for (var i = 0; i < this.dragEventObject.length; i++) {
            files.push(this.dragEventObject[i]);
        }

        this.fileUpload(files);
        return false;
    }

    private fileUpload(files: File[]): void {
        const fileMaxCnt: number = 4;
        const fileMaxSize: number = 50 * 1048576; // 50MB
        const fileMaxTotalSize: number = 50 * 1048576; // 50MB 

        // ファイル件数チェック
        if (files.length > fileMaxCnt) {
            // ファイルアップロード件数警告ダイアログを表示
            if (!this.alertDialog_FileCntOver) {
                this.alertDialog_FileCntOver = this.createAlertDialog("#alertDialog_FileCntOver", "警告", ProcessMigration.dlgAlertMsg_FileCntOver);
            }
            this.alertDialog_FileCntOver.open(".on-ok-alertDialog_FileCntOver");
            return;
        }

        // ファイル合計サイズチェック
        let fileTotalSize = 0;
        for (let file of files) {
            fileTotalSize += file.size;
        }
        if (fileTotalSize > fileMaxTotalSize) {
            // 1回の通信のサーバーリクエスト上限は55MBまで。そのため、ファイル合計サイズが50MBを超える場合は通信を行わない
            // ファイルアップロードサイズ警告ダイアログを表示
            if (!this.alertDialog_FileSizeOver) {
                this.alertDialog_FileSizeOver = this.createAlertDialog("#alertDialog_FileSizeOver", "警告", ProcessMigration.dlgAlertMsg_FileSizeOver);
            }
            this.alertDialog_FileSizeOver.open(".on-ok-alertDialog_FileSizeOver");
            return;
        }

        // 送信データ用FormData生成
        let formData: FormData = new FormData();
        let errFiles: MigrationFileInfoViewModel[] = [];
        for (let file of files) {
            // ファイルタイプチェック
            var isfileTypeError = file.type == null || file.type.trim().length == 0 && file.name.split('.').pop()!.toLowerCase() != 'csv';
            if (UserAgentUtil.isMsie() || UserAgentUtil.isEdge()) {
                isfileTypeError = file.type == null || file.type.trim().length == 0 && file.name.split('.').pop()!.toLowerCase() != 'text' && file.name.split('.').pop()!.toLowerCase() != 'csv';
            }
            if (isfileTypeError) {
                let typeErrFile: MigrationFileInfoViewModel = {
                    GUID: null!,
                    FileName: file.name,
                    Status: MigrationFileStatus.UploadError,
                    FileType: MigrationFileType.TypeNotFound,
                    SystemKbn: this.m_SystemKbn,
                    ErrorMessage: "指定されたファイルはファイルがテキスト形式でないためインポートできません。もう一度、手順に従ってエクスポートしてください。",
                    VersionNo: null!
                };
                errFiles.push(typeErrFile);
                continue;
            }

            // ファイルサイズチェック
            if (file.size > fileMaxSize) {
                // ファイルサイズが50MBを超えるとサーバー通信に負荷がかかるので、通信を行わない
                // しかし、取込ファイルの設定テーブルには表示するため、MigrationFileInfoViewModel型で保持しておく
                let overCapacityFile: MigrationFileInfoViewModel = {
                    GUID: null!,
                    FileName: file.name,
                    Status: MigrationFileStatus.UploadError,
                    FileType: MigrationFileType.TypeNotFound,
                    SystemKbn: this.m_SystemKbn,
                    ErrorMessage: "指定されたファイルはファイルサイズが50MBを超えるためインポートできません。もう一度、手順に従ってエクスポートしてください。",
                    VersionNo: null!
                };
                errFiles.push(overCapacityFile);
                continue;
            }

            formData.append(file.name, file);
        }

        formData.append("SystemKbn", this.m_SystemKbn.toString());

        let success = (data: MigrationUploadViewModel, textStatus: string, jqXHR: JQueryXHR) => {
            this.m_FileInfos = data.MigrationFileInfos;
            let fileInfos = this.m_FileInfos.concat(errFiles);
            this.initImportTarget(fileInfos);
            if (fileInfos.any((file: { Status: number; }) =>
                file.Status == MigrationFileStatus.FileCombinationError ||
                file.Status == MigrationFileStatus.NoData ||
                file.Status == MigrationFileStatus.DuplicateFile ||
                file.Status == MigrationFileStatus.FileReadError ||
                file.Status == MigrationFileStatus.FileCheckError ||
                file.Status == MigrationFileStatus.UploadError ||
                file.Status == MigrationFileStatus.ImportError)) {
                this.fileImportBtn.disable();
            } else {
                this.fileImportBtn.enable();
            }
            this.showTargetLack(this.m_FileInfos.select((n: { FileType: any; }) => n.FileType));
            this.visibleBtnSuccessFileImport(false);
            $("#file-import").show();

            for (let i = 0; i < fileInfos.length; i++) {
                // ごみ箱アイコンを表示
                $(`#trash${i}`).css("visibility", "visible");
            }
        };
        let complete = (jqXHR: JQueryXHR, textStatus: string) => { };
        let error = (data: any, jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => { };
        let settings: AjaxSettings = {
            url: "/api/v1/ProcessMigration/UploadFile",
            type: "POST",
            data: formData,
            showBusyIndicator: true,
            beforeSend: null!,
            success: success,
            complete: complete,
            error: error
        };

        // ファイルアップロード
        Ajax.performForFileUpload(settings);
    }

    private deleteFiles(rowIndex: number): void {
        let success = (data: MigrationDeleteFileParamViewModel, textStatus: string, jqXHR: JQueryXHR) => {
            this.m_FileInfos = data.MigrationFileInfos;
            if (this.m_FileInfos.length > 0) {
                this.initImportTarget(this.m_FileInfos);
            } else {
                $("#file-import").hide();
            }

            this.showTargetLack(this.m_FileInfos.select((n: { FileType: any; }) => n.FileType));
            if (this.m_FileInfos.any((file: { Status: number; }) =>
                file.Status == MigrationFileStatus.FileCombinationError ||
                file.Status == MigrationFileStatus.NoData ||
                file.Status == MigrationFileStatus.DuplicateFile ||
                file.Status == MigrationFileStatus.FileReadError ||
                file.Status == MigrationFileStatus.FileCheckError ||
                file.Status == MigrationFileStatus.UploadError ||
                file.Status == MigrationFileStatus.ImportError)) {
                this.fileImportBtn.disable();
            } else {
                this.fileImportBtn.enable();
            }
        };
        let complete = (jqXHR: JQueryXHR, textStatus: string) => { };
        let error = (data: any, jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => { };
        let model: MigrationDeleteFileParamViewModel = {
            SystemKbn: this.m_SystemKbn,
            GUID: this.m_FileInfos[rowIndex].GUID,
            VersionNo: this.m_FileInfos[rowIndex].VersionNo,
            MigrationFileInfos: null!
        };
        let settings: AjaxSettings = {
            url: "/api/v1/ProcessMigration/DeleteFile",
            type: "DELETE",
            data: model,
            showBusyIndicator: true,
            beforeSend: null!,
            success: success,
            complete: complete,
            error: error
        };

        // ツールチップを一旦すべて破棄して再度生成
        $('[data-toggle="tooltip"]').tooltip('dispose').tooltip(ProcessMigration.TOOLTIP_OPTIONS).off("show.bs.tooltip").on("show.bs.tooltip", this.onShowToolTip.bind(this));

        // ファイル削除
        Ajax.perform(settings);
    }

    private importFiles(): void {
        let success = (data: MigrationFileImportViewModel, textStatus: string, jqXHR: JQueryXHR) => {
            this.m_FileInfos = data.MigrationFileInfos;
            this.initImportTarget(this.m_FileInfos);

            this.fileImportBtn.disable();
            if (!this.m_FileInfos.any((n: { Status: number; }) => n.Status != MigrationFileStatus.ImportFinish)) {
                // ゴミ箱アイコン非表示
                this.hiddenAllBtnTrash();
                this.visibleBtnSuccessFileImport(true);

                // ステップ更新
                $.when.apply(this, [this.getCurrentStep()]).done(() => {
                    // ヘッダステップナビ更新
                    this.header.fadeOutStepNav(false);
                    this.header.fadeInStepNav(this.m_StepCheckResult);
                });
            }
        };
        let complete = (jqXHR: JQueryXHR, textStatus: string) => {
            this.fileImport_clickEventInProg = false;
        };
        let error = (data: any, jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => { };
        let model: MigrationFileImportViewModel = {
            MigrationFileInfos: this.m_FileInfos
        };
        let settings: AjaxSettings = {
            url: "/api/v1/ProcessMigration/ImportFile",
            type: "POST",
            data: model,
            showBusyIndicator: true,
            beforeSend: null!,
            success: success,
            complete: complete,
            error: error
        };

        // ファイルインポート
        Ajax.perform(settings);
    }

    private initImportTarget(data: MigrationFileInfoViewModel[]): void {
        this.$timeout(() => {
            const fnameMaxLength: number = 20;
            const fnameAfterText: string = "... ";
            let fileList: any[] = [];
            for (let file of data) {
                let ext = "." + file.FileName.split('.').pop();
                let fname = file.FileName;
                if ((file.FileName.length - ext.length) > fnameMaxLength) {
                    fname = file.FileName.substr(0, fnameMaxLength) + fnameAfterText + ext;
                }

                fileList.push({
                    isErr: (file.Status == MigrationFileStatus.UploadError || file.Status == MigrationFileStatus.FileCheckError), // アップロードされていないファイルの場合
                    isDup: file.Status == MigrationFileStatus.DuplicateFile,
                    name: fname,
                    type: file.FileType,
                    errMsg: file.ErrorMessage == null ? "" : file.ErrorMessage
                });
            }

            this.$scope.fileList = fileList;

            this.$timeout(() => {
                for (let i = 0; i < fileList.length; i++) {
                    let file = fileList[i];

                    // 取込対象
                    let id = '#labelImportTarget' + i.toString();

                    
                    if (file.isErr) {
                        // エラーの場合は背景色灰色
                        $(id).css("background-color", "#f6f6f6");
                    } else if (file.isDup) {
                        // 重複の場合は背景色赤
                        $(id).css("background-color", "#fddde3");
                    } else {
                        // 正常なファイルの場合は透明
                        $(id).css("background-color", "transparent");
                    }

                    switch (file.type) {
                        case MigrationFileType.Zan: $(id).text("期首残高"); break;
                        case MigrationFileType.HojyoZan: $(id).text("補助期首残高"); break;
                        case MigrationFileType.Swk: $(id).text("仕訳"); break;
                        case MigrationFileType.BmnZan: $(id).text("部門期首残高"); break;
                        default: $(id).text(""); break;
                    }
                }

                // BootstrapのTooltipsを初期化する
                $('[data-toggle="tooltip"]').tooltip(ProcessMigration.TOOLTIP_OPTIONS).off("show.bs.tooltip").on("show.bs.tooltip", this.onShowToolTip.bind(this));
            }, 100);
        });
    }

    private hiddenAllBtnTrash(): void {
        this.$timeout(() => {
            for (let i = 0; i < this.m_FileInfos.length; i++) {
                $(`#trash${i}`).css("visibility", "hidden");
            }
        });
    }

    private showTargetLack(typeList: number[]): void {
        let msgList: string[] = this.getTargetLackListOfOther(typeList);
        msgList = msgList.where(n => n != undefined);
        this.$timeout(() => {
            if (msgList.length != 0) {
                let msg = msgList.join("、");
                this.$scope.targetlack = msg;
                $("#target-lack").removeClass("dp_none");
            } else {
                $("#target-lack").addClass("dp_none");
            }
        });
    }

    private getTargetLackListOfOther(typeList: number[]): string[] {
        let targetList = [
            "期首残高",
            "補助期首残高",
            "部門期首残高",
            "仕訳"
        ];

        if (typeList.indexOf(MigrationFileType.Zan) != -1) {
            delete targetList[0];
        }
        if (typeList.indexOf(MigrationFileType.HojyoZan) != -1) {
            delete targetList[1];
        }
        if (typeList.indexOf(MigrationFileType.BmnZan) != -1) {
            delete targetList[2];
        }
        if (typeList.indexOf(MigrationFileType.Swk) != -1) {
            delete targetList[3];
        }

        return targetList;
    }

    private reset(): JQueryPromise<{}> {
        let d = $.Deferred<{}>();
        let success = (data: string, textStatus: string, jqXHR: JQueryXHR) => {
            // Mediatorセット
            this.mediator.removeAllMediatoritems();
            this.setMediator(this.createMediatorItemStepFileImport());

            this.header.changeSystemStepNav(this.m_SystemKbn);
            this.hideStep(MigrationStep.FileImport);
            this.hideStep(MigrationStep.ItemSetting);
            this.hideStep(MigrationStep.KmkSetting);
            this.hideStep(MigrationStep.HojyoSetting);
            this.hideStep(MigrationStep.SwkImport);
            this.hideStep(MigrationStep.Finish);

            d.resolve();
        };
        let complete = (jqXHR: JQueryXHR, textStatus: string) => { };
        let error = (data: any, jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => { d.reject(); };
        let settings: AjaxSettings = {
            url: "/api/v1/ProcessMigration/Reset",
            type: "DELETE",
            data: { SystemKbn: this.m_SystemKbn },
            showBusyIndicator: true,
            beforeSend: null!,
            success: success,
            complete: complete,
            error: error
        };

        // リセット
        Ajax.perform(settings);

        return d.promise();
    }

    private delete(): JQueryPromise<{}> {
        let d = $.Deferred<{}>();
        let success = (data: string, textStatus: string, jqXHR: JQueryXHR) => {
            // Mediatorセット
            this.mediator.removeAllMediatoritems();
            this.setMediator(this.createMediatorItemStepFileImport());

            this.header.changeSystemStepNav(this.m_SystemKbn);
            this.hideStep(MigrationStep.FileImport);
            this.hideStep(MigrationStep.ItemSetting);
            this.hideStep(MigrationStep.KmkSetting);
            this.hideStep(MigrationStep.HojyoSetting);
            this.hideStep(MigrationStep.SwkImport);
            this.hideStep(MigrationStep.Finish);

            d.resolve();
        };
        let complete = (jqXHR: JQueryXHR, textStatus: string) => { };
        let error = (data: any, jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => { d.reject(); };
        let settings: AjaxSettings = {
            url: "/api/v1/ProcessMigration/Delete",
            type: "DELETE",
            data: {SystemKbn: this.m_SystemKbn},
            showBusyIndicator: true,
            beforeSend: null!,
            success: success,
            complete: complete,
            error: error
        };

        // 削除
        Ajax.perform(settings);

        return d.promise();
    }

    private getCurrentStep(): JQueryPromise<{}> {
        let d = $.Deferred<{}>();
        this.oldStep = this.m_CurrentStep;
        let success = (data: MigrationStepInfoViewModel, textStatus: string, jqXHR: JQueryXHR) => {
            this.m_CurrentStep = data.CurrentStep;
            this.m_StepCheckResult = data.StepCheckResult;

            // ステップ番号Map作成
            this.createDispStepNoMap(this.m_StepCheckResult);

            let stepList: number[] = this.getExecuteStepList();

            let jqXHRList: any[] = [];
            for (let step of stepList) {
                switch (step) {
                    case MigrationStep.ItemSetting: jqXHRList.push(this.storeItemSetting()); jqXHRList.push(this.storeItemConvertSetting()); break;
                    case MigrationStep.KmkSetting: jqXHRList.push(this.storeKmkSetting()); break;
                    case MigrationStep.HojyoSetting: jqXHRList.push(this.storeHojyoKmkSetting()); break;
                    case MigrationStep.SwkImport: jqXHRList.push(this.storeSwkSetting()); break;
                }
            }

            $.when.apply(this, jqXHRList).done(() => {
                for (let step of stepList) {
                    this.header.activeStepNav(step);
                    switch (step) {
                        case MigrationStep.FileImport:
                            this.showStep(MigrationStep.FileImport);
                            break;
                        case MigrationStep.ItemSetting:
                            this.mediator_stepItemSettingMediatorsKisyuOK = false;
                            this.mediator_stepItemSettingMediatorsHojyoKisyuOK = false;
                            this.mediator_stepItemSettingMediatorsSwkOK = false;
                            this.mediator_stepItemSettingMediatorsKessanOK = false;
                            this.mediator_stepItemSettingMediatorsTaxKbnOK = false;
                            this.mediator_stepItemSettingMediatorsTaxRateOK = false;
                            this.mediator_stepItemSettingMediatorsGyosyuOK = false;
                            this.$timeout(() => this.initializeStepItemSetting());
                            break;
                        case MigrationStep.KmkSetting:
                            this.input_cmbDstkmks = {};
                            this.input_inpKmkSums = {};
                            this.$timeout(() => {
                                this.initializeStepKmkSetting();
                                this.$timeout(() => {
                                    this.setCmbDstKmkItemSource(this.m_kmkSettingDatas);
                                    this.initKmkSettingTable(this.m_kmkSettingDatas);
                                });
                            });
                            break;
                        case MigrationStep.HojyoSetting:
                            if (this.input_cmbHowRegistHojyos) {
                                Object.values(this.input_cmbHowRegistHojyos).forEach((cmb: wjInput.ComboBox) => {
                                    cmb.dispose();
                                });
                            }
                            if (this.input_cmbSelectHojyos) {
                                Object.values(this.input_cmbSelectHojyos).forEach((cmb: wjInput.ComboBox) => {
                                    cmb.dispose();
                                });
                            }
                            if (this.input_inpHojyoSums) {
                                Object.values(this.input_inpHojyoSums).forEach((ctrl: CurrencyInput) => {
                                    ctrl.dispose();
                                });
                            }
                            if (this.input_cmbHowRegistGeneralHojyos) {
                                Object.values(this.input_cmbHowRegistGeneralHojyos).forEach((cmb: wjInput.ComboBox) => {
                                    cmb.dispose();
                                });
                            }
                            if (this.input_cmbSelectGeneralHojyos) {
                                Object.values(this.input_cmbSelectGeneralHojyos).forEach((cmb: wjInput.ComboBox) => {
                                    cmb.dispose();
                                });
                            }
                            if (this.input_cmbHowRegistBmns) {
                                Object.values(this.input_cmbHowRegistBmns).forEach((cmb: wjInput.ComboBox) => {
                                    cmb.dispose();
                                });
                            }
                            if (this.input_cmbSelectBmns) {
                                Object.values(this.input_cmbSelectBmns).forEach((cmb: wjInput.ComboBox) => {
                                    cmb.dispose();
                                });
                            }
                            if (this.input_cmbHowRegistSegments) {
                                Object.values(this.input_cmbHowRegistSegments).forEach((cmb: wjInput.ComboBox) => {
                                    cmb.dispose();
                                });
                            }
                            if (this.input_cmbSelectSegments) {
                                Object.values(this.input_cmbSelectSegments).forEach((cmb: wjInput.ComboBox) => {
                                    cmb.dispose();
                                });
                            }
                            if (this.input_cmbHowRegistKoujis) {
                                Object.values(this.input_cmbHowRegistKoujis).forEach((cmb: wjInput.ComboBox) => {
                                    cmb.dispose();
                                });
                            }
                            if (this.input_cmbSelectKoujis) {
                                Object.values(this.input_cmbSelectKoujis).forEach((cmb: wjInput.ComboBox) => {
                                    cmb.dispose();
                                });
                            }

                            this.input_cmbHowRegistHojyos = {};
                            this.input_cmbSelectHojyos = {};
                            this.input_inpHojyoSums = {};
                            this.input_cmbHowRegistGeneralHojyos = {};
                            this.input_cmbSelectGeneralHojyos = {};
                            this.input_cmbHowRegistBmns = {};
                            this.input_cmbSelectBmns = {};
                            this.input_cmbHowRegistSegments = {};
                            this.input_cmbSelectSegments = {};
                            this.input_cmbHowRegistKoujis = {};
                            this.input_cmbSelectKoujis = {};

                            this.mediator_stepHojyoSettingMediatorsHojyoOK = false;
                            this.mediator_stepHojyoSettingMediatorsGeneralHojyoOK = false;
                            this.mediator_stepHojyoSettingMediatorsBmnOK = false;
                            this.mediator_stepHojyoSettingMediatorsSegmentOK = false;
                            this.mediator_stepHojyoSettingMediatorsKoujiOK = false;
                            this.mediator_stepHojyoSettingMediatorsHojyo = [];
                            this.mediator_stepHojyoSettingMediatorsGeneralHojyo = [];
                            this.mediator_stepHojyoSettingMediatorsBmn = [];
                            this.mediator_stepHojyoSettingMediatorsSegment = [];
                            this.mediator_stepHojyoSettingMediatorsKouji = [];
                            this.$timeout(() => {
                                this.initializeStepHojyoSetting();
                                this.$timeout(() => { // 画面表示を待つ
                                    this.setCmbSelectHojyosItemSource(MigrationHojyoTabKbn.Hojyo, this.m_hojyokmkSettingDatas);
                                    this.initHojyoKmkSettingTable(this.m_hojyokmkSettingDatas);
                                    this.setCmbSelectHojyosItemSource(MigrationHojyoTabKbn.GeneralHojyo, this.m_generalhojyoSettingDatas);
                                    this.initGeneralHojyoSettingTable(this.m_generalhojyoSettingDatas);
                                    this.setCmbSelectHojyosItemSource(MigrationHojyoTabKbn.Bmn, this.m_bmnSettingDatas);
                                    this.initBmnSettingTable(MigrationHojyoTabKbn.Bmn, this.m_bmnSettingDatas);
                                    this.setCmbSelectHojyosItemSource(MigrationHojyoTabKbn.Segment, this.m_segmentSettingDatas);
                                    this.initBmnSettingTable(MigrationHojyoTabKbn.Segment, this.m_segmentSettingDatas);
                                    this.setCmbSelectHojyosItemSource(MigrationHojyoTabKbn.Kouji, this.m_koujiSettingDatas);
                                    this.initBmnSettingTable(MigrationHojyoTabKbn.Kouji, this.m_koujiSettingDatas);
                                }, 100);
                            });
                            break;
                        case MigrationStep.SwkImport:
                            this.$timeout(() => this.initializeStepSwkImport());
                            break;
                        case MigrationStep.Finish:
                            this.initializeStepFinish();
                            break;
                    }
                }

                this.scrollTimerid = setInterval(() => {
                    if (this.scrollOK) {
                        // プログレスバーが非表示の場合
                        if ($('#mjsCommonProgressConfirm').is(':hidden')) {
                            clearInterval(this.scrollTimerid);

                            if (this.oldStep != this.m_CurrentStep) {
                                // 現在のステップにスクロールする
                                this.scroll(`#step` + this.getStepName(this.m_CurrentStep));

                                // 先頭要素にフォーカス
                                setTimeout(() => {
                                    let mediator = this.topStepMediatorMap[this.m_CurrentStep];
                                    if (!MjsUtilities.isNull(mediator)) {
                                        mediator.getFocusControl().focus();
                                    }
                                });
                            }
                        }
                    }
                }, 200);
            });

            d.resolve();
        };
        let complete = (jqXHR: JQueryXHR, textStatus: string) => { };
        let error = (data: any, jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => { d.reject(); };
        let model: MigrationStepInfoViewModel = {
            SystemKbn: this.m_SystemKbn,
            CurrentStep: this.m_CurrentStep,
            StepCheckResult: this.m_StepCheckResult
        }
        let settings: AjaxSettings = {
            url: "/api/v1/ProcessMigration/GetCurrentStep",
            type: "POST",
            data: model,
            showBusyIndicator: false,
            beforeSend: null!,
            success: success,
            complete: complete,
            error: error
        };

        // ステップ更新
        Ajax.perform(settings);

        return d.promise();
    }
    // #endregion setEventStepFileImport

    // #region setEventStepItemSetting
    private setEventStepItemSetting(): void {
        // タブ切り替え（項目設定）
        this.$scope.itemSettingTabClick = (currentItemSettingTab: number) => {
            ["kisyu", "hojyokisyu", "bmnkisyu", "swk"].forEach((tabName: string, index: number) => {
                if (index == currentItemSettingTab) {
                    $(`#${tabName}-tab`).tab("show");
                    $(`#${tabName}-tab-pane`).addClass("in active");
                    $(`#li-${tabName}-tab`).addClass("active");
                    if (tabName == "kisyu") {
                        this.kisyuFileContentGrid.asyncRefresh(300, true);
                    } else if (tabName == "hojyokisyu"){
                        this.hojyoKisyuFileContentGrid.asyncRefresh(300, true);
                    } else if (tabName == "bmnkisyu"){
                        this.bmnKisyuFileContentGrid.asyncRefresh(300, true);
                    } else {
                        this.swkFileContentGrid.asyncRefresh(300, true);
                    }
                } else {
                    $(`#${tabName}-tab-pane`).removeClass("in active show");
                    $(`#li-${tabName}-tab`).removeClass("active");
                }
            });
        };

        // タブ切り替え（エラーログ）
        this.$scope.itemSettingErrLogTabClick = (currentItemSettingErrLogTab: number) => {
            ["kisyu", "hojyokisyu", "bmnkisyu", "swk"].forEach((tabName: string, index: number) => {
                if (index == currentItemSettingErrLogTab) {
                    $(`#${tabName}-err-tab`).tab("show");
                    $(`#${tabName}-err-tab-pane`).addClass("in active");
                    $(`#li-${tabName}-err-tab`).addClass("active");
                } else {
                    $(`#${tabName}-err-tab-pane`).removeClass("in active show");
                    $(`#li-${tabName}-err-tab`).removeClass("active");
                }
            });
        };

        // タブ切り替え（項目変換設定）
        this.$scope.itemConvertSettingTabClick = (currentItemSettingTab: number) => {
            ["kessan", "taxKbn", "taxRate", "gyosyu", "ivJigyoKbn"].forEach((tabName: string, index: number) => {
                if (index == currentItemSettingTab) {
                    $(`#${tabName}-tab`).tab("show");
                    $(`#${tabName}-tab-pane`).addClass("in active");
                    $(`#li-${tabName}-tab`).addClass("active");
                } else {
                    $(`#${tabName}-tab-pane`).removeClass("in active show");
                    $(`#li-${tabName}-tab`).removeClass("active");
                }
            });
        };

        // 金額の設定
        this.$scope.taxKbnBtnClick = (taxKbn: number) => {
            if (this.m_TaxKbn != taxKbn) {
                if (this.$scope.itemSettingSaveStatus == MigrationSettingSaveStatus.Saved) {
                    this.$timeout(() => {
                        this.$scope.itemSettingSaveStatus = MigrationSettingSaveStatus.Changed;
                    });
                }
            }

            this.m_TaxKbn = taxKbn;
        };

        // 内税消費税額自動計算区分の設定
        this.$scope.uchizeiAutoCalcKbnBtnClick = (uchizeiAutoCalcKbn: number) => {
            if (this.m_UchizeiAutoCalcKbn != uchizeiAutoCalcKbn) {
                if (this.$scope.itemSettingSaveStatus == MigrationSettingSaveStatus.Saved) {
                    this.$timeout(() => {
                        this.$scope.itemSettingSaveStatus = MigrationSettingSaveStatus.Changed;
                    });
                }
            }

            this.m_UchizeiAutoCalcKbn = uchizeiAutoCalcKbn;
        };

        // 対応設定を保存するボタン（項目設定）
        this.itemSettingSaveBtn.setEventHandler(new MjsDelegateContainer<Function, any>(() => {
            let success = (isChange: boolean, textStatus: string, jqXHR: JQueryXHR) => {
                if (isChange) {
                    // 項目設定削除確認ダイアログを表示
                    if (!this.confirmDialog_DeleteItemSetting) {
                        this.confirmDialog_DeleteItemSetting = this.createConfirmDialog("#confirmDialog_DeleteItemSetting", ProcessMigration.dlgConfirmMsg_DeleteItemSetting, () => { this.saveItemSetting(); });
                    }
                    this.$timeout(() => {
                        this.confirmDialog_DeleteItemSetting.open(".on-no-confirmDialog_DeleteItemSetting");
                    });
                } else {
                    this.saveItemSetting();
                }
            };
            let complete = (jqXHR: JQueryXHR, textStatus: string) => { };
            let error = (data: any, jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => { };
            this.updatemItemSettingGroup();
            let settings: AjaxSettings = {
                url: "/api/v1/ProcessMigration/SaveMigrationItemSettingCheck",
                type: "POST",
                data: this.m_ItemSettingGroup,
                showBusyIndicator: true,
                beforeSend: null!,
                success: success,
                complete: complete,
                error: error
            };

            // 項目設定保存チェック
            Ajax.perform(settings);
        }, this));

        // エラーログをダウンロードするボタン
        this.itemSettingErrLogDownLoadBtn.setEventHandler(new MjsDelegateContainer<Function, any>(() => { this.itemSettingErrLogDownLoad(); }, this));

        // 対応設定を保存するボタン（項目変換設定）
        this.itemConvertSettingSaveBtn.setEventHandler(new MjsDelegateContainer<Function, any>(() => {
            let unusableTaxRateTaxCodeKey = Object.keys(this.taxCodeTaxRateMap).where(n => this.taxCodeTaxRateMap[n].length == 0).select((n: any) => n);
            if (Object.keys(this.input_cmbTaxKbns).any((n: string | number) => MjsUtilities.isNull(this.input_cmbTaxKbns[n].selectedValue)) ||
                (!this.$scope.IsItemTaxRate && Object.keys(this.input_cmbTaxRates).any((n: string | number) => this.input_cmbTaxRates[n].selectedValue == TaxRate.None)) ||
                (this.$scope.IsItemTaxRate && Object.keys(this.input_cmbTaxRates).any((n: string | number) => this.input_cmbTaxRates[n].selectedValue == TaxRate.None && !MjsUtilities.isNull(this.input_cmbTaxKbns[n].selectedValue) && !unusableTaxRateTaxCodeKey.any((m: any) => this.input_cmbTaxKbns[n].selectedValue == m)))) {
                // エラー（消費税区分に空欄がある、または、消費税率が未設定（消費税区分タブの場合は税率設定可能な状態で未設定））の場合は処理終了
                return;
            }
            this.saveItemConvertSetting();
        }, this));
    }

    private saveItemSetting(): void {
        let taxKbnDlgOpen = false;
        if (this.$scope.swkItemSetting) {
            taxKbnDlgOpen = this.kbnInfoDataCacheForItemSetting.GnPuKbn8 == ExcludedInputKbn.Simultaneous &&
                this.m_TaxKbn == TaxKbn.TaxExcluded &&
                this.itemSettingSwkColumCmbList.every(n => n.selectedValue != MigrationSwkCloudItem.TaxSum);
        }
        if (taxKbnDlgOpen) {
            // 金額設定確認ダイアログを表示
            this.confirmDialog_ConfirmTaxKbn = this.createConfirmDialog("#confirmDialog_ConfirmTaxKbn", ProcessMigration.dlgConfirmMsg_ConfirmTaxKbn, () => { this.saveMigrationItemSettingGroup(); });
            this.$timeout(() => {
                this.confirmDialog_ConfirmTaxKbn.open(".on-no-confirmDialog_ConfirmTaxKbn");
            });
        } else {
            this.saveMigrationItemSettingGroup();
        }

        setTimeout(() => {
            this.mediator.setFocusToActiveItem();
        });
    }

    private saveMigrationItemSettingGroup(): void {
        let success = (data: MigrationItemSettingGroupViewModel, textStatus: string, jqXHR: JQueryXHR) => {
            this.m_ItemSettingGroup = data;
            this.m_ItemSettingGroup.MigrateItemSettingList.forEach(n => n.MigrateItemSettingSaved = MigrationSettingSaveStatus.Saved);
            this.m_ItemConvertSettingGroup = null!;
            this.isMigrationItemSettingGroupSaved = true;
            this.$timeout(() => {
                this.$scope.itemSettingSaveStatus = MigrationSettingSaveStatus.Saved;
            });
            this.isItemSettingSaveBtnClick = true;

            // Mediatorセット
            this.mediator.removeAllMediatoritems();
            this.setMediator(this.createMediatorItemStepFileImport());

            this.hideStep(MigrationStep.KmkSetting);
            this.hideStep(MigrationStep.HojyoSetting);
            this.hideStep(MigrationStep.SwkImport);
            this.hideStep(MigrationStep.Finish);

            // 本当はhideStep(MigrationStep.ItemSetting)、showStep(MigrationStep.ItemSetting)だがGridの再描画がおかしくなるためしない
            // angular用変数初期化
            this.$scope.itemSettingKisyuErrCnt = 0;
            this.$scope.itemSettingHojyoKisyuErrCnt = 0;
            this.$scope.itemSettingSwkErrCnt = 0;
            this.$scope.kisyuItemSettingErrLogList = null;
            this.$scope.hojyoKisyuItemSettingErrLogList = null;
            this.$scope.bmnKisyuItemSettingErrLogList = null;
            this.$scope.swkItemSettingErrLogList = null;
            this.$scope.isKisyuItemSettingErrLogCntOver = null;
            this.$scope.isHojyoKisyuItemSettingErrLogCntOver = null;
            this.$scope.isSwkItemSettingErrLogCntOver = null;
            this.$scope.hojyoKisyuImportErrLogList = null;
            this.$scope.bmnKisyuImportErrLogList = null;
            this.$scope.isHojyoKisyuImportErrLogCntOver = null;
            this.$scope.isBmnKisyuImportErrLogCntOver = null;
            this.$scope.itemSettingSaveStatus = null;
            this.$scope.itemConvertSettingTaxKbnErrCnt = 0;
            this.$scope.itemConvertSettingTaxRateErrCnt = 0;
            this.$scope.kessanList = null;
            this.$scope.taxKbnList = null;
            this.$scope.taxRateList = null;
            this.$scope.gyosyuList = null;
            this.$scope.ivJigyoKbnList = null;
            this.$scope.itemConvertSettingSaveStatus = null;

            this.$timeout(() => {
                $.when.apply(this, [this.getCurrentStep()]).done(() => {
                    // ヘッダステップナビ更新
                    this.header.fadeOutStepNav(true);
                    this.header.fadeInStepNav(this.m_StepCheckResult);
                });

                let name = this.getStepName(MigrationStep.ItemSetting);
                this.hideLeftBorder(name);
            });
        };
        let complete = (jqXHR: JQueryXHR, textStatus: string) => { };
        let error = (data: any, jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => { };
        this.updatemItemSettingGroup();
        let settings: AjaxSettings = {
            url: "/api/v1/ProcessMigration/SaveMigrationItemSettingGroup",
            type: "POST",
            data: this.m_ItemSettingGroup,
            showBusyIndicator: true,
            beforeSend: null!,
            success: success,
            complete: complete,
            error: error
        };

        // 項目設定保存
        Ajax.perform(settings);
    }

    private updatemItemSettingGroup(): void {
        this.m_ItemSettingGroup.SystemKbn = this.m_SystemKbn;

        // 期首残高
        if (this.m_ItemSettingGroup.MigrateItemSettingList.any((n: { FileType: number; }) => n.FileType == MigrationFileType.Zan)) {
            let kisyu = this.m_ItemSettingGroup.MigrateItemSettingList.where(n => n.FileType == MigrationFileType.Zan)[0];
            kisyu.SystemKbn = this.m_SystemKbn;
            kisyu.StartRowNo = this.kisyuStartRowNo.getValue();
            kisyu.TaxInc = null!;

            this.itemSettingKisyuColumCmbList.forEach((combo, i) => {
                let colum = kisyu.MigrateItemSettingDetailList.filter(n => n.ColNo == (i + 1))[0];
                colum.CloudItem = combo.selectedValue;
            });
        }

        // 補助期首残高
        if (this.m_ItemSettingGroup.MigrateItemSettingList.any((n: { FileType: number; }) => n.FileType == MigrationFileType.HojyoZan)) {
            let hojyoKisyu = this.m_ItemSettingGroup.MigrateItemSettingList.where(n => n.FileType == MigrationFileType.HojyoZan)[0];
            hojyoKisyu.SystemKbn = this.m_SystemKbn;
            hojyoKisyu.StartRowNo = this.hojyoKisyuStartRowNo.getValue();
            hojyoKisyu.TaxInc = null!;

            this.itemSettingHojyoKisyuColumCmbList.forEach((combo, i) => {
                let colum = hojyoKisyu.MigrateItemSettingDetailList.filter(n => n.ColNo == (i + 1))[0];
                colum.CloudItem = combo.selectedValue;
            });
        }

        // 部門期首残高
        if (this.m_ItemSettingGroup.MigrateItemSettingList.any((n: { FileType: number; }) => n.FileType == MigrationFileType.BmnZan)) {
            let bmnKisyu = this.m_ItemSettingGroup.MigrateItemSettingList.where(n => n.FileType == MigrationFileType.BmnZan)[0];
            bmnKisyu.SystemKbn = this.m_SystemKbn;
            bmnKisyu.StartRowNo = this.bmnKisyuStartRowNo.getValue();
            bmnKisyu.TaxInc = null!;

            this.itemSettingBmnKisyuColumCmbList.forEach((combo, i) => {
                let colum = bmnKisyu.MigrateItemSettingDetailList.filter(n => n.ColNo == (i + 1))[0];
                colum.CloudItem = combo.selectedValue;
            });
        }

        // 仕訳
        if (this.m_ItemSettingGroup.MigrateItemSettingList.any((n: { FileType: number; }) => n.FileType == MigrationFileType.Swk)) {
            let swk = this.m_ItemSettingGroup.MigrateItemSettingList.where(n => n.FileType == MigrationFileType.Swk)[0];
            swk.SystemKbn = this.m_SystemKbn;
            swk.StartRowNo = this.swkStartRowNo.getValue();
            swk.TaxInc = this.m_TaxKbn;
            swk.UchizeiAutoCalcKbn = this.m_UchizeiAutoCalcKbn;

            this.itemSettingSwkColumCmbList.forEach((combo, i) => {
                let colum = swk.MigrateItemSettingDetailList.filter(n => n.ColNo == (i + 1))[0];
                colum.CloudItem = combo.selectedValue;
            });
        }
    }

    private storeItemSetting(): JQueryPromise<{}> {
        let d = $.Deferred<{}>();
        if (this.isMigrationItemSettingGroupSaved) {
            d.resolve();
            return d.promise();
        }
        let success = (data: MigrationItemSettingGroupViewModel, textStatus: string, jqXHR: JQueryXHR) => {
            this.m_ItemSettingGroup = data;
            d.resolve();
        }
        let complete = (jqXHR: JQueryXHR, textStatus: string) => { };
        let error = (data: any, jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => { d.reject(); };
        let itemSettingList = this.m_FileInfos.select<MigrationItemSettingViewModel>((n: { GUID: any; SystemKbn: any; FileType: any; }) => {
            return { GUID: n.GUID, SystemKbn: n.SystemKbn, FileType: n.FileType };
        });
        let model: MigrationItemSettingGroupViewModel = {
            SystemKbn: this.m_SystemKbn,
            MigrateItemSettingList: itemSettingList,
        };
        let settings: AjaxSettings = {
            url: "/api/v1/ProcessMigration/StoreMigrationItemSettingGroup",
            type: "POST",
            data: model,
            showBusyIndicator: false,
            beforeSend: null!,
            success: success,
            complete: complete,
            error: error
        };

        // 項目設定データ取得
        Ajax.perform(settings);

        return d.promise();
    }

    private storeItemConvertSetting(): JQueryPromise<{}> {
        let d = $.Deferred<{}>();
        if (this.m_FileInfos.every(n => n.FileType != MigrationFileType.Swk)) {
            d.resolve();
            return d.promise();
        }

        let success = (data: MigrationItemConvertSettingGroupViewModel, textStatus: string, jqXHR: JQueryXHR) => {
            this.m_ItemConvertSettingGroup = data;

            if (this.m_ItemSettingGroup != null) {
                let errDetailList: MigrationErrViewModel[] = [];
                for (let setting of this.m_ItemSettingGroup.MigrateItemSettingList) {
                    errDetailList = errDetailList.concat(setting.MigrateItemSettingErrDetailList);
                }
                // 項目設定にエラーがある場合、項目変換設定は表示しない
                let isItemSettingErr = errDetailList.any((n: { Status: string; }) => n.Status == MigrationErrStatus.Error);
                if (isItemSettingErr) {
                    this.m_ItemConvertSettingGroup = null!;
                }
            }

            d.resolve();
        }
        let complete = (jqXHR: JQueryXHR, textStatus: string) => { };
        let error = (data: any, jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => { d.reject(); };

        let swk = this.m_FileInfos.where(n => n.FileType == MigrationFileType.Swk)[0];
        let model: MigrationItemConvertSettingGroupViewModel = {
            SystemKbn: this.m_SystemKbn,
            FileType: swk.FileType,
            MigrateItemConvertSettingList: [],
            MigrateItemConvertSettingSaved: MigrationSettingSaveStatus.UnSaved,
        };

        let settings: AjaxSettings = {
            url: "/api/v1/ProcessMigration/StoreMigrationItemConvertSettingGroup",
            type: "POST",
            data: model,
            showBusyIndicator: false,
            beforeSend: null!,
            success: success,
            complete: complete,
            error: error
        };

        // 項目変換設定データ取得
        Ajax.perform(settings);

        return d.promise();
    }

    private saveItemConvertSetting(): void {
        let success = (data: MigrationItemConvertSettingGroupViewModel, textStatus: string, jqXHR: JQueryXHR) => {
            this.m_ItemConvertSettingGroup = data;
            this.m_ItemConvertSettingGroup.MigrateItemConvertSettingSaved = MigrationSettingSaveStatus.Saved;
            this.$timeout(() => {
                this.$scope.itemConvertSettingSaveStatus = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingSaved;
            });
            if (this.m_CurrentStep == MigrationStep.ItemSetting) {
                // ステップ更新
                this.getCurrentStep();
            } else {
                if (this.isBeforeCurrentStep(MigrationStep.SwkImport, true) && this.m_StepCheckResult.IsSwkImportStepEnable) {
                    $.when.apply(this, [this.storeSwkSetting()]).done(() => {
                        this.$timeout(() => {
                            // 仕訳インポートテーブル初期化
                            this.initSwkSettingTable(this.m_JournalSetting);
                        });
                    });
                }
            }
            setTimeout(() => {
                this.mediator.setFocusToActiveItem();
            });
        };
        let complete = (jqXHR: JQueryXHR, textStatus: string) => { };
        let error = (data: any, jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => { };
        this.updatemItemConvertSettingGroup();
        let settings: AjaxSettings = {
            url: "/api/v1/ProcessMigration/SaveMigrationItemConvertSettingGroup",
            type: "POST",
            data: this.m_ItemConvertSettingGroup,
            showBusyIndicator: true,
            beforeSend: null!,
            success: success,
            complete: complete,
            error: error
        };

        // 項目変換設定保存
        Ajax.perform(settings);
    }

    private updatemItemConvertSettingGroup(): void {
        // 決算仕訳区分
        let kessanItemConvertSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.singleOrNull((n: { ConvertCategory: number; }) => n.ConvertCategory == MigrationConvertCategory.Nmonth);
        if (!MjsUtilities.isNull(kessanItemConvertSetting)) {
            this.updatemItemConvertSetting(kessanItemConvertSetting, this.input_cmbKessans);
        }

        // 消費税区分
        let taxCodeItemConvertSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.singleOrNull((n: { ConvertCategory: number; ConvertItem: number; }) => n.ConvertCategory == MigrationConvertCategory.TaxCode && n.ConvertItem == MigrationConvertItem.TaxCode);
        if (!MjsUtilities.isNull(taxCodeItemConvertSetting)) {
            this.updatemItemConvertSetting(taxCodeItemConvertSetting, this.input_cmbTaxKbns);
        }

        // 消費税率
        let taxRateItemConvertSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.singleOrNull((n: { ConvertCategory: number; ConvertItem: number; }) => n.ConvertCategory == MigrationConvertCategory.TaxRate && n.ConvertItem == MigrationConvertItem.TaxRate);
        if (!MjsUtilities.isNull(taxRateItemConvertSetting)) {
            this.updatemItemConvertSetting(taxRateItemConvertSetting, this.input_cmbTaxRates);
        } else {
            taxRateItemConvertSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.singleOrNull((n: { ConvertCategory: number; ConvertItem: number; }) => n.ConvertCategory == MigrationConvertCategory.TaxCode && n.ConvertItem == MigrationConvertItem.TaxRate);
            if (!MjsUtilities.isNull(taxRateItemConvertSetting)) {
                let exceptTaxCodeKey = Object.keys(this.taxCodeTaxRateMap).where(n => this.taxCodeTaxRateMap[n].length == 0).select((n: any) => n);
                let except = taxCodeItemConvertSetting.MigrateItemConvertSettingDetailList.where(n => exceptTaxCodeKey.any((m: string) => n.AfterItem == m)).select((n: { BeforeValue: any; }) => n.BeforeValue);
                this.updatemItemConvertSetting(taxRateItemConvertSetting, this.input_cmbTaxRates, except);
            }
        }

        // 業種
        let typeCdItemConvertSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.singleOrNull((n: { ConvertCategory: number; ConvertItem: number; }) => n.ConvertCategory == MigrationConvertCategory.TypeCd && n.ConvertItem == MigrationConvertItem.TypeCd);
        if (!MjsUtilities.isNull(typeCdItemConvertSetting)) {
            this.updatemItemConvertSetting(typeCdItemConvertSetting, this.input_cmbTypeCds);
        } else {
            typeCdItemConvertSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.singleOrNull((n: { ConvertCategory: number; ConvertItem: number; }) => n.ConvertCategory == MigrationConvertCategory.TaxCode && n.ConvertItem == MigrationConvertItem.TypeCd);
            if (!MjsUtilities.isNull(typeCdItemConvertSetting)) {
                let except = taxCodeItemConvertSetting.MigrateItemConvertSettingDetailList.where(n => n.AfterItem != MigrationConvertItemTaxCodeKey.DefaultAutoSet.toString() && !this.sTaxCodeKeyListByUseGyosyu.any((m: string) => m == n.AfterItem)).select((n: { BeforeValue: any; }) => n.BeforeValue);
                this.updatemItemConvertSetting(typeCdItemConvertSetting, this.input_cmbTypeCds, except);
            }
        }

        // 事業者区分
        let ivJigyoKbnItemConvertSetting = this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.singleOrNull((n: { ConvertCategory: number; ConvertItem: number; }) => n.ConvertCategory == MigrationConvertCategory.IvJigyoKbn && n.ConvertItem == MigrationConvertItem.IvJigyoKbn);
        if (!MjsUtilities.isNull(ivJigyoKbnItemConvertSetting)) {
            this.updatemItemConvertSetting(ivJigyoKbnItemConvertSetting, this.input_cmbIvJigyoKbns);
        }
    }

    private updatemItemConvertSetting(convertSetting: MigrationItemConvertSettingViewModel, inputCmb: any, except: string[] = null!): void {
        for (var data of convertSetting.MigrateItemConvertSettingDetailList) {
            let value = inputCmb[data.BeforeValue];
            if (except == null) {
                data.AfterItem = value.selectedValue;
            } else {
                if (except.any((n: string) => n == data.BeforeValue)) {
                    data.AfterItem = null!;
                } else {
                    data.AfterItem = value.selectedValue;
                }
            }
        }
    }
    // #endregion setEventStepItemSetting

    // #region setEventStepKmkSetting
    private setEventStepKmkSetting(): void {
        // 勘定科目登録を行うボタン
        this.registKmkBtn.setEventHandler(new MjsDelegateContainer<Function, any>(() => { this.registKmk(); }, this));

        // 表示を更新するボタン
        this.refreshBtn.setEventHandler(new MjsDelegateContainer<Function, any>(() => { this.refresh(); }, this));

        // 対応設定を保存するボタン
        this.kmkSettingSaveBtn.setEventHandler(new MjsDelegateContainer<Function, any>(() => {
            if (this.m_hojyokmkSettingDatas.length > 0) {
                let isConfirmDialog_ConfirmSettingOpen: boolean = false;
                for (let grp of this.m_kmkSettingDatas) {
                    for (let detail of grp.KmkSettingDetails) {
                        if (detail.IsEdited) {
                            let kmkhojyoList = this.m_hojyokmkSettingDatas.where(n => n.HowRegist == MasterKbn.EachKmkAuxiliary && n.SrcKmkCode == detail.SrcKmkCode);
                            for (let kmkhojyo of kmkhojyoList) {
                                if (detail.DstKmkCode != kmkhojyo.DstKmkCode) {
                                    // stepKmkSettingとstepHojyoSettingで対応先科目コードが異なる場合、stepHojyoSettingの科目別補助の設定が初期化（「新規登録」）される
                                    // 対応設定変更確認ダイアログを表示するかどうか判定する
                                    isConfirmDialog_ConfirmSettingOpen = true;
                                    break;
                                }
                            }
                        }
                    }
                }

                if (isConfirmDialog_ConfirmSettingOpen && (this.isHojyoKmkSettingSave || this.isGeneralHojyoSettingSave)) {
                    // 対応設定変更確認ダイアログを表示
                    if (!this.confirmDialog_ConfirmSetting) {
                        this.confirmDialog_ConfirmSetting = this.createConfirmDialog("#confirmDialog_ConfirmSetting", ProcessMigration.dlgConfirmMsg_ConfirmSetting, () => { this.visibleBtnSuccessHojyoKmkSettingSave(false); this.kmkSettingSave(); });
                    }
                    this.confirmDialog_ConfirmSetting.open(".on-no-confirmDialog_ConfirmSetting");
                } else {
                    this.kmkSettingSave();
                }
            } else {
                this.kmkSettingSave();
            }
        }, this));

        // 残高をインポートするボタン
        this.kmkKisyuImportBtn.setEventHandler(new MjsDelegateContainer<Function, any>(() => { this.kmkKisyuImport(); }, this));
    }

    private registKmk(): void {
        let windowOption = new WindowOption();
        WindowUtil.getOpen(
            "/RegisterAccount/DrillDown"
            , null!
            , windowOption
        );

        setTimeout(() => {
            this.mediator.setFocusToActiveItem();
        });
    }

    private refresh(): void {
        this.isRefreshBtnClick = true;
        this.showLoadingArea();
        this.masterDataManager.refresh();
    }

    private kmkSettingSave(): void {
        let success = (data: MigrationKmkSettingViewModel, textStatus: string, jqXHR: JQueryXHR) => {
            for (let updatedGrp of data.KmkGroupList) {
                if (updatedGrp.GroupName == null) {
                    updatedGrp.GroupName = "";
                }
                let grpViewModel = this.m_KmkSetting.KmkGroupList.singleOrNull((n: { GroupName: string; }) => n.GroupName == updatedGrp.GroupName);
                let grp = this.m_kmkSettingDatas.singleOrNull((n: { GroupName: string; }) => n.GroupName == updatedGrp.GroupName);
                for (let updatedDetail of updatedGrp.KmkList) {
                    let detailViewModel = grpViewModel.KmkList.singleOrNull((n: { SrcKmkCode: string; }) => n.SrcKmkCode == updatedDetail.SrcKmkCode);
                    let detail = grp.KmkSettingDetails.singleOrNull((n: { SrcKmkCode: string; }) => n.SrcKmkCode == updatedDetail.SrcKmkCode);

                    // 保持しているViewModelを更新する
                    MjsUtilities.deepCopy(detailViewModel, updatedDetail);
                    MjsUtilities.deepCopy(detail, this.convertFromKmkSettingDetailViewModel(updatedDetail, detail.GroupNo, detail.DetailNo));

                    detail.IsEdited = false;
                }
            }

            this.m_KmkSetting.MappingSaved = true;
            this.visibleBtnSuccessKmkSettingSave(true);

            if (this.m_CurrentStep == MigrationStep.KmkSetting) {
                if (!this.m_KmkSetting.KisyuEnable) {
                    // ステップ更新
                    this.getCurrentStep();
                }
            } else {
                if (this.isBeforeCurrentStep(MigrationStep.HojyoSetting, true) && this.m_StepCheckResult.IsHojyoSettingStepEnable) {
                    // 補助科目設定を更新する
                    this.updateHojyoSetting(data.UpdatedHojyoGroupList);
                }

                if (this.isBeforeCurrentStep(MigrationStep.SwkImport, true) && this.m_StepCheckResult.IsSwkImportStepEnable) {
                    $.when.apply(this, [this.storeSwkSetting()]).done(() => {
                        this.$timeout(() => {
                            // エラー情報を更新する
                            this.updateErrInfoInSwkSettingTable(this.m_JournalSetting);

                            // 画面の状態をデータモデルに反映する
                            let swkSettingData = <settingSwkData>this.$scope.swkSetting;
                            let rowIndex = this.m_JournalSetting.ExcludedJournalAmount != 0 ? 1 : 0;
                            for (let i = 0; i < this.m_JournalSetting.JournalMonthList.length; i++) {
                                this.m_JournalSetting.JournalMonthList[i].CheckStatus = swkSettingData.SwkInfoList[rowIndex].CheckStatus;
                                rowIndex++;
                            }
                            this.m_JournalSetting.ErrCancelFlg = swkSettingData.ErrCancelFlg;
                        });
                    });
                }
            }

            setTimeout(() => {
                this.mediator.setFocusToActiveItem();
            });
        };
        let complete = (jqXHR: JQueryXHR, textStatus: string) => { };
        let error = (data: any, jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => { };
        // 編集したデータのみ取得する
        let kmkGroupList = this.getEditedKmkGroupViewModel();
        let model: MigrationKmkSettingViewModel = {
            SystemKbn: this.m_SystemKbn,
            KmkGroupList: kmkGroupList,
            MappingSaved: this.m_KmkSetting.MappingSaved,
            KisyuImported: this.isKmkKisyuImported,
            KisyuEnable: this.m_KmkSetting.KisyuEnable,
            UpdatedHojyoGroupList: this.m_KmkSetting.UpdatedHojyoGroupList,
        };
        let settings: AjaxSettings = {
            url: "/api/v1/ProcessMigration/SaveMigrationKmkSetting",
            type: "PUT",
            data: model,
            showBusyIndicator: true,
            beforeSend: null!,
            success: success,
            complete: complete,
            error: error
        };

        // 勘定科目対応設定保存
        Ajax.perform(settings);
    }

    private updateHojyoSetting(UpdatedHojyoGroupList: MigrationHojyoGroupViewModel[]): void {
        let targetCheckMasterByAccountKmkCodeList: number[] = []; // 科目別マスタ採用チェック対象の科目コードリスト
        let hojyoList = [];
        let hojyoData = [];
        for (let updateHojyoGrp of UpdatedHojyoGroupList) {
            if (updateHojyoGrp.HojyoTabKbn == MigrationHojyoTabKbn.Hojyo) {
                hojyoList = this.m_HojyoSettingHojyoList;
                hojyoData = this.m_hojyokmkSettingDatas;
            } else if (updateHojyoGrp.HojyoTabKbn == MigrationHojyoTabKbn.GeneralHojyo) {
                hojyoList = this.m_HojyoSettingGeneralHojyoList;
                hojyoData = this.m_generalhojyoSettingDatas;
            } else {
                continue;
            }

            for (let updatedDetail of updateHojyoGrp.HojyoList) {
                let detailViewModel = hojyoList.singleOrNull((n: { SrcKmkCode: string; SrcHojyoCode: string; SrcHojyoKbn: number; }) => n.SrcKmkCode == updatedDetail.SrcKmkCode && n.SrcHojyoCode == updatedDetail.SrcHojyoCode && n.SrcHojyoKbn == updatedDetail.SrcHojyoKbn);
                let detail = hojyoData.singleOrNull((n: { SrcKmkCode: string; SrcHojyoCode: string; SrcHojyoKbn: number; }) => n.SrcKmkCode == updatedDetail.SrcKmkCode && n.SrcHojyoCode == updatedDetail.SrcHojyoCode && n.SrcHojyoKbn == updatedDetail.SrcHojyoKbn);

                // 科目別マスタ採用チェックには変更前後のデータを渡す 
                targetCheckMasterByAccountKmkCodeList.push(detail.DstKmkCode);
                targetCheckMasterByAccountKmkCodeList.push(updatedDetail.DstKmkCode);

                // 保持しているViewModelを更新する
                this.updateSettingDetailViewModel(detailViewModel, updatedDetail); // ※サーバーから返却される値は不定値を含むため、一部のみ更新する

                // 保持しているViewModelの対応先科目コードを更新する
                detail.DstKmkCode = updatedDetail.DstKmkCode;
                if (detail.HowRegist == MasterKbn.EachKmkAuxiliary) {
                    // 科目別補助の場合「新規登録」で初期化する
                    this.setCodeAndMappingKbnAndIsRequiredCaseOfNew(detail);
                }

                detail.IsEdited = true;
            }
        }

        // 重複を除く
        targetCheckMasterByAccountKmkCodeList = targetCheckMasterByAccountKmkCodeList.filter((elem, index, self) => self.indexOf(elem) === index);

        let success = (data: MigrationHojyoSettingViewModel, textStatus: string, jqXHR: JQueryXHR) => {
            for (let hojyoGrp of data.HojyoGroupList) {
                if (hojyoGrp.HojyoTabKbn == MigrationHojyoTabKbn.Hojyo) {
                    for (let masterByAccount of hojyoGrp.HojyoList) {
                        let detail = this.m_hojyokmkSettingDatas.singleOrNull((n: { SrcKmkCode: string; SrcHojyoCode: string; SrcHojyoKbn: number; }) => n.SrcKmkCode == masterByAccount.SrcKmkCode && n.SrcHojyoCode == masterByAccount.SrcHojyoCode && n.SrcHojyoKbn == masterByAccount.SrcHojyoKbn);
                        detail.AuxiliaryAccountCanAdoptedKbn = masterByAccount.AuxiliaryAccountCanAdoptedKbn;
                        detail.IsEdited = true;
                    }
                } else if (hojyoGrp.HojyoTabKbn == MigrationHojyoTabKbn.GeneralHojyo) {
                    for (let masterByAccount of hojyoGrp.HojyoList) {
                        let detail = this.m_generalhojyoSettingDatas.singleOrNull((n: { SrcKmkCode: string; SrcHojyoCode: string; SrcHojyoKbn: number; }) => n.SrcKmkCode == masterByAccount.SrcKmkCode && n.SrcHojyoCode == masterByAccount.SrcHojyoCode && n.SrcHojyoKbn == masterByAccount.SrcHojyoKbn);
                        detail.AuxiliaryAccountCanAdoptedKbn = masterByAccount.AuxiliaryAccountCanAdoptedKbn;
                        detail.IsEdited = true;
                    }
                }
            }

            this.$timeout(() => {
                // stepHojyoSettingの補助科目対応設定を更新する
                this.setCmbSelectHojyosItemSource(MigrationHojyoTabKbn.Hojyo, this.m_hojyokmkSettingDatas, false);
                this.initHojyoKmkSettingTable(this.m_hojyokmkSettingDatas, false);

                this.setCmbSelectHojyosItemSource(MigrationHojyoTabKbn.GeneralHojyo, this.m_generalhojyoSettingDatas, false);
                this.initGeneralHojyoSettingTable(this.m_generalhojyoSettingDatas, false);
            });
        };

        let complete = (jqXHR: JQueryXHR, textStatus: string) => { };
        let error = (data: any, jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => { };
        let targetHojyoList = this.m_hojyokmkSettingDatas.where(n => targetCheckMasterByAccountKmkCodeList.indexOf(n.DstKmkCode) != -1);
        let targetGeneralHojyoList = this.m_generalhojyoSettingDatas.where(n => targetCheckMasterByAccountKmkCodeList.indexOf(n.DstKmkCode) != -1);
        let modelHojyoList: MigrationSettingDetailViewModel[] = [];
        for (let detail of targetHojyoList) {
            modelHojyoList.push(this.convertFromHojyokmkSettingDatas(detail, this.m_HojyoSettingHojyoList));
        }
        let modelGeneralHojyoList: MigrationSettingDetailViewModel[] = [];
        for (let detail of targetGeneralHojyoList) {
            modelGeneralHojyoList.push(this.convertFromHojyokmkSettingDatas(detail, this.m_HojyoSettingGeneralHojyoList));
        }
        let model = new MigrationHojyoSettingViewModel();
        model.HojyoGroupList = [
            { HojyoTabKbn: MigrationHojyoTabKbn.Hojyo, HojyoGroupName: "補助科目", HojyoList: modelHojyoList, ImportErrLogFileName: "", OpeningBalanceImportErrDetailList: []},
            { HojyoTabKbn: MigrationHojyoTabKbn.GeneralHojyo, HojyoGroupName: "汎用補助", HojyoList: modelGeneralHojyoList, ImportErrLogFileName: "", OpeningBalanceImportErrDetailList: [] }
        ];
        let settings: AjaxSettings = {
            url: "/api/v1/ProcessMigration/CheckMasterByAccount",
            type: "POST",
            data: model,
            showBusyIndicator: false,
            beforeSend: null!,
            success: success,
            complete: complete,
            error: error
        };

        // 科目別マスタ採用チェック
        Ajax.perform(settings);
    }

    private updateSettingDetailViewModel(dst: MigrationSettingDetailViewModel, src: MigrationSettingDetailViewModel): void {
        // 更新対象はviewmodel定義書の備考欄で「対応設定保存時の戻り値として返却できない」の記載がないもの
        dst.SrcKmkCode = src.SrcKmkCode;
        dst.SrcHojyoKbn = src.SrcHojyoKbn;
        dst.SrcHojyoCode = src.SrcHojyoCode;
        dst.SrcHojyoName = src.SrcHojyoName;
        dst.DstHojyoKbn1 = src.DstHojyoKbn1;
        dst.DstHojyoKbn2 = src.DstHojyoKbn2;
        dst.DstKmkCode = src.DstKmkCode;
        dst.DstKmkHojyoCode = src.DstKmkHojyoCode;
        dst.DstHojyoCode = src.DstHojyoCode;
        dst.MappingSettingKbn = src.MappingSettingKbn;
        dst.OpeningBalance = src.OpeningBalance;
        dst.SaveOperationKbn = src.SaveOperationKbn;
        dst.MappingVersionNo = src.MappingVersionNo;
    }

    private kmkKisyuImport(): void {
        let success = (data: MigrationOpeningBalanceViewModel, textStatus: string, jqXHR: JQueryXHR) => {
            this.visibleBtnSuccessKmkKisyuImport(true);

            if (this.m_KmkSetting.KisyuEnable) {
                // ステップ更新
                this.getCurrentStep();
            }

            setTimeout(() => {
                this.mediator.setFocusToActiveItem();
            });
        };
        let complete = (jqXHR: JQueryXHR, textStatus: string) => { };
        let error = (data: any, jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => { };
        let model: MigrationOpeningBalanceViewModel = {
            CleaningTarget: CleaningTargetKbn.Account,
        };
        let settings: AjaxSettings = {
            url: "/api/v1/ProcessMigration/MigrateOpeningBalance",
            type: "POST",
            data: model,
            showBusyIndicator: true,
            beforeSend: null!,
            success: success,
            complete: complete,
            error: error
        };

        // 期首残高移行
        Ajax.perform(settings);
    }

    private storeKmkSetting(): JQueryPromise<{}> {
        let d = $.Deferred<{}>();
        let success = (data: MigrationKmkSettingViewModel, textStatus: string, jqXHR: JQueryXHR) => {
            this.m_KmkSetting = data;
            this.m_kmkSettingDatas = this.convertFromKmkSettingViewModel(this.m_KmkSetting);
            d.resolve();
        }
        let complete = (jqXHR: JQueryXHR, textStatus: string) => { };
        let error = (data: any, jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => { d.reject(); };
        let model: MigrationKmkSettingViewModel = {
            SystemKbn: this.m_SystemKbn,
            KmkGroupList: null!,
            MappingSaved: false,
            KisyuImported: false,
            KisyuEnable: null!,
            UpdatedHojyoGroupList: null!
        };
        let settings: AjaxSettings = {
            url: "/api/v1/ProcessMigration/StoreKmkSetting",
            type: "POST",
            data: model,
            showBusyIndicator: false,
            beforeSend: null!,
            success: success,
            complete: complete,
            error: error
        };

        // 科目登録用データ取得
        Ajax.perform(settings);

        return d.promise();
    }

    private initInputControlsKmkSetting(data: settingKmkGrpData[]): void {
        for (let grp of data) {
            for (let detail of grp.KmkSettingDetails) {
                // AI監査支援に登録する勘定科目
                this.initCmbDstKmk(detail.GroupNo, detail.DetailNo);

                // AI監査支援に登録する勘定科目検索エクスプローラボタン
                this.initCmbDstKmkBtn(detail.GroupNo, detail.DetailNo);

                // 期首残高
                this.initInpKmkSum(detail.GroupNo, detail.DetailNo);
            }
        }
    }

    private setCmbDstKmkItemSource(data: settingKmkGrpData[]): void {
        for (let grp of data) {
            for (let detail of grp.KmkSettingDetails) {
                let cmb = this.input_cmbDstkmks[`${detail.GroupNo}_${detail.DetailNo}`]
                let kmkMaItems = this.getKmkMAItems();
                cmb.itemsSource = this.convertToCmbKmkItems(kmkMaItems);
            }
        }
    }

    private initKmkSettingTable(data: settingKmkGrpData[]): void {
        // 表示制御
        for (let grp of data) {
            for (let detail of grp.KmkSettingDetails) {
                // AI監査支援に登録する勘定科目
                let cmb = this.input_cmbDstkmks[`${detail.GroupNo}_${detail.DetailNo}`];
                let itemsSource = cmb.itemsSource.singleOrNull((n: { key: number; }) => n.key == detail.DstKmkCode);
                let kmkMa = null;
                if (itemsSource != null) {
                    cmb.selectedValue = detail.DstKmkCode;
                    kmkMa = itemsSource.item;
                } else {
                    if (this.m_hojyokmkSettingDatas.length > 0) {
                        // 補助科目対応設定で科目別補助が選択されている場合
                        let clearKmkHojyoList = this.m_hojyokmkSettingDatas.where(n => n.HowRegist == HojyoKbn.EachKmkAuxiliary && n.DstKmkCode == detail.DstKmkCode);
                        for (let clearKmkHojyo of clearKmkHojyoList) {
                            if (clearKmkHojyo.DstKmkHojyoCode != null) {
                                // 科目別補助に値が設定されている場合、デフォルトに変更する
                                this.setCodeAndMappingKbnAndIsRequiredCaseOfDefault(clearKmkHojyo);
                                clearKmkHojyo.DstKmkCode = null!;

                                this.visibleBtnSuccessHojyoKmkSettingSave(false);
                            }
                        }
                    }

                    if (detail.DstKmkCode != null) {
                        // 勘定科目が更新され見つからなかった場合
                        this.visibleBtnSuccessKmkSettingSave(false);
                        detail.IsEdited = true;
                    }

                    cmb.selectedIndex = -1;
                    detail.DstKmkCode = null!;
                }

                // 製造科目は文字色青
                this.setManufacturingCostColor(detail, kmkMa);

                let inp = this.input_inpKmkSums[`${detail.GroupNo}_${detail.DetailNo}`];
                if (this.m_KmkSetting.KisyuEnable) {
                    inp.setValue(detail.OpeningBalance);

                    // 期首残区分がなしの場合、期首残高はツールチップを表示して非活性にする
                    this.showTooltipKmkKisyu(detail, kmkMa);
                } else {
                    inp.enabled = false;
                    inp.setText("");
                }

                // 重複の場合、ツールチップを表示する
                this.showTooltipKmkDup(null!, detail);

                for (let grp of this.m_kmkSettingDatas) {
                    // エラー件数（入力必須件数）を表示する
                    this.showKmkErrCnt(detail.GroupNo);

                    // 確認科目件数を表示する
                    this.showKmkInfoCnt(grp.GroupNo);
                }
            }
        };

        if (this.m_KmkSetting.KisyuEnable) {
            if (this.isKmkSettingSave) {
                // 残高インポートボタン活性
                this.kmkKisyuImportBtn.enable();
            } else {
                // 残高インポートボタン非活性
                this.kmkKisyuImportBtn.disable();
            }
        } else {
            // 残高インポートボタン非活性
            this.kmkKisyuImportBtn.disable();
        }
    }

    private setKmkSettingTable(data: settingKmkGrpData[]): void {
        const toggleRowID: string = "toggleRowTR_";
        const toggleMarkID: string = "toggleMarkTD_";
        const toggleMarkIconID: string = "toggleIconSpan_";
        const groupNameID: string = "groupNameTD_";
        const errCntID: string = "kmkErrCnt";
        const infoCntID: string = "kmkInfoCnt";
        const grpID: string = "kmkGrp";

        $("#kmkSettingTable").empty();
        let tablehtml = $("#kmkSettingTable");
        let html = '<table class="migration-table" ng-model="kmkErrCntList">';
        let headhtml = '<thead><tr class="migration-table-border-head"><th width="1%" style="min-width: 25px;"></th><th width="15%"></th><th width="15%">インポートされた勘定科目</th><th width="3%"></th><th width="20%">AI監査支援の勘定科目</th><th width="30%">期首残高</th></tr></thead>';
        html += headhtml;
        for (let i = 0; i < data.length; i++) {
            let grpdata = data[i];
            let gNo = grpdata.GroupNo;
            let grpId = grpID + gNo; // `kmkGrp${gNo}`;
            let errCntid = errCntID + gNo; // `kmkErrCnt${gNo}`;
            let infoCntid = infoCntID + gNo; // `kmkInfoCnt${gNo}`;
            let grphtml = '<tr data-toggle="collapse" data-target="' + '#' + grpId + '" class="accordion-toggle" aria-expanded="true" aria-controls="' + '#' + grpId + '" id="' + toggleRowID + grpId + '">';
            grphtml += '<td class="toggleMark" id="' + toggleMarkID + grpId + '"><span class="down-right" id="' + toggleMarkIconID + grpId + '"></span></td>';
            grphtml += '<td colspan="5" id="' + groupNameID + grpId + '">' + grpdata.GroupName + ' <span id="' + errCntid + '"></span><span id="' + infoCntid + '"></span></td>';
            grphtml += '</tr>';
            html += grphtml;
            html += '<tbody id="' + grpId + '" class="collapse in accordian-body" aria-expanded="true" style="z-index: -1;">';
            for (let j = 0; j < grpdata.KmkSettingDetails.length; j++) {
                let detaildata = grpdata.KmkSettingDetails[j];
                let dNo = detaildata.DetailNo;
                let cmbDstKmkid = `cmbDstKmk${gNo}_${dNo}`;
                let cmbDstKmkBtnid = `cmbDstKmkBtn${gNo}_${dNo}`;
                let inpKmkSumid = `inpKmkSum${gNo}_${dNo}`;
                let tooltipDupid = `tooltipKmkDup${gNo}_${dNo}`;
                let tooltipKisyuid = `tooltipKmkKisyu${gNo}_${dNo}`;
                let detailhtml = '<tr style="height: 50px;">';
                detailhtml += '<td colspan="2"></td>';
                detailhtml += '<td><div style="white-space: nowrap;">' + detaildata.SrcKmkName + '</div></td>';
                detailhtml += '<td><span class="fa fa-arrow-right"></span></td>';
                detailhtml += '<td style="min-width: 240px;"><div class="dp_flex"><div class="flex-autocomplete zenkaku">';
                detailhtml += '<span id="' + cmbDstKmkid + '" class="easy-input easy-input-autocomplete"> </span>';
                detailhtml += '<button id="' + cmbDstKmkBtnid + '" class="btn-easy-input-explorer-combo" tabindex="-1"><span class="custom-icon-StackedBooks"></span></button>';
                detailhtml += '</div>';
                detailhtml += '<span id="' + tooltipDupid + '" class="dp_none pt5 red-icon"><span class="fa fa-exclamation-circle ml5" data-toggle="tooltip" data-html="true" data-placement="top" title="同一名称の科目が設定されています。設定を見直してください。"></span></span>';
                detailhtml += '</div>';
                detailhtml += '</td>';
                detailhtml += '<td><span id="' + inpKmkSumid + '" class="easy-input hankaku number-input"></span>';
                detailhtml += '<span id="' + tooltipKisyuid + '" class="dp_none"><span class="fa fa-info-circle migration-blue ml10" data-toggle="tooltip" data-html="true" data-placement="top" title="勘定科目の期首残区分がなしの状態となっているため、残高インポートできません。インポートしたい場合は勘定科目登録で該当の勘定科目の期首残区分をありに更新してください。"></span></span>';
                detailhtml += '</td>';
                detailhtml += '</tr>';
                html += detailhtml;
            }

            html += '</tbody>';
        }

        html += '</table>';
        tablehtml.append(html);

        // BootstrapのTooltipsを初期化する
        $('[data-toggle="tooltip"]').tooltip(ProcessMigration.TOOLTIP_OPTIONS).off("show.bs.tooltip").on("show.bs.tooltip", this.onShowToolTip.bind(this));

        // テーブル開閉時、展開アイコンを切り替える
        $("tr.accordion-toggle").click((e) => {
            if (this.clickEventInProg) {
                // 連打クリックされると展開アイコンの切り替えが誤作動するため対策する
                return false;
            }
            this.clickEventInProg = true;

            let clickedID = e.target.id;
            let clickedParentID = e.target.parentElement!.id;
            let iconSPAN_ID = null;
            if (clickedID.indexOf(toggleRowID) >= 0) {
                // <tr>がクリックされた
                iconSPAN_ID = toggleMarkIconID + clickedID.substr(toggleRowID.length);
            }
            else if (clickedID.indexOf(toggleMarkID) >= 0) {
                // <td toggleMark>がクリックされた
                iconSPAN_ID = toggleMarkIconID + clickedID.substr(toggleMarkID.length);
            }
            else if (clickedID.indexOf(toggleMarkIconID) >= 0) {
                // <span>がクリックされた
                iconSPAN_ID = clickedID;
            }
            else if (clickedID.indexOf(groupNameID) >= 0) {
                // <td colspan=5 groupName>がクリックされた
                iconSPAN_ID = toggleMarkIconID + clickedID.substr(groupNameID.length);
            }
            else if (clickedID.indexOf(errCntID) >= 0) {
                // <span errCnt>がクリックされた
                iconSPAN_ID = toggleMarkIconID + grpID + clickedID.substr(errCntID.length);
            }
            else if (clickedID.indexOf(infoCntID) >= 0) {
                // <span infoCnt>がクリックされた
                iconSPAN_ID = toggleMarkIconID + grpID + clickedID.substr(infoCntID.length);
            }
            else if (clickedParentID.indexOf(errCntID) >= 0) {
                // <span errCnt>がクリックされた
                iconSPAN_ID = toggleMarkIconID + grpID + clickedParentID.substr(errCntID.length);
            }
            else if (clickedParentID.indexOf(infoCntID) >= 0) {
                // <span infoCnt>がクリックされた
                iconSPAN_ID = toggleMarkIconID + grpID + clickedParentID.substr(infoCntID.length);
            }
            if (iconSPAN_ID != null) {
                // 展開アイコンを切り替える
                $("#" + iconSPAN_ID).toggleClass("down-right");
                $("#" + iconSPAN_ID).toggleClass("right");
            }
            $('tbody.accordian-body').toggleClass("in");
            $('tbody.accordian-body').toggleClass("show");

            return undefined!;
        });
        $('tbody.accordian-body').on('shown.bs.collapse', { param: this }, function (e) {
            // 連打クリックされると展開アイコンの切り替えが誤作動するため対策する
            e.data.param.clickEventInProg = false;
        });
        $('tbody.accordian-body').on('hidden.bs.collapse', { param: this }, function (e) {
            // 連打クリックされると展開アイコンの切り替えが誤作動するため対策する
            e.data.param.clickEventInProg = false;
        });
    }

    private initCmbDstKmk(gNo: number, dNo: number): wjInput.AutoComplete {
        const id = `#cmbDstKmk${gNo}_${dNo}`;

        let cmb = this.initWijmoAutoComplete(id);

        // クリック時にドロップダウンを表示する
        $("#" + cmb.hostElement.id + ' input').on('click', () => {
            cmb.isDroppedDown = !cmb.isDroppedDown;
        });

        // ロストフォーカス
        cmb.lostFocus.addHandler((sender: wjInput.AutoComplete, ea: wijmo.EventArgs) => {
            let id = sender.hostElement.id;
            let i = id.substring(9, id.indexOf("_"));
            let j = id.substring(10 + i.length);

            let kmk = this.m_kmkSettingDatas[Number(i)].KmkSettingDetails[Number(j)];
            let oldCode = kmk.DstKmkCode;

            if (oldCode == sender.selectedValue) {
                if (oldCode == null) {
                    if (sender._oldText == "") {
                        // 選択内容が変更していない場合（空）は何もしない
                        return;
                    }
                } else {
                    // 選択内容が変更していない場合は何もしない
                    return;
                }
            }

            kmk.IsEdited = true; // 編集済み

            if (sender.selectedValue == null && sender._oldText != "") {
                // 直接入力した場合にselectedValueが設定されないため強制的に設定
                let key = sender.itemsSource.singleOrNull((n: { value: string; }) => n.value == sender._oldText);
                if (key != null) {
                    sender.selectedValue = sender.itemsSource.singleOrNull((n: { value: string; }) => n.value == sender._oldText).key;
                }
            }

            let kmkMa = sender.selectedItem != null ? sender.selectedItem.item : null!;
            if (kmkMa == null) {
                // 勘定科目一覧に存在しない場合、空を設定する
                sender.selectedIndex = -1;
                kmk.DstKmkCode = null!;
            } else {
                kmk.DstKmkCode = sender.selectedValue;

                // 製造科目は文字色青
                this.setManufacturingCostColor(kmk, kmkMa);
            }

            if (this.m_KmkSetting.KisyuEnable) {
                // 期首残区分がなしの場合、期首残高はツールチップを表示して非活性にする
                this.showTooltipKmkKisyu(kmk, kmkMa);
            }

            // 重複の場合、ツールチップを表示する
            this.showTooltipKmkDup(oldCode, kmk);

            for (let grp of this.m_kmkSettingDatas) {
                // エラー件数（入力必須件数）を表示する
                this.showKmkErrCnt(grp.GroupNo);

                // 確認科目件数を表示する
                this.showKmkInfoCnt(grp.GroupNo);
            }

            // 編集中（未保存）
            this.visibleBtnSuccessKmkSettingSave(false);
        }, this);

        this.input_cmbDstkmks[`${gNo}_${dNo}`] = cmb;
        return cmb;
    }

    private setManufacturingCostColor(kmk: settingKmkDetailData, kmkMa: KmkMACacheViewModel): void {
        if (kmk.DstKmkCode == null || kmkMa == null) {
            $(`#cmbDstKmk${kmk.GroupNo}_${kmk.DetailNo} input`).removeClass("migration-blue");
            return;
        }

        if (kmkMa.SumAnaCode == SumAnaCode.ManufacturingCost) {
            $(`#cmbDstKmk${kmk.GroupNo}_${kmk.DetailNo} input`).addClass("migration-blue");
        } else {
            $(`#cmbDstKmk${kmk.GroupNo}_${kmk.DetailNo} input`).removeClass("migration-blue");
        }
    }

    private showTooltipKmkKisyu(kmk: settingKmkDetailData, kmkMa: KmkMACacheViewModel): void {
        if (kmk.DstKmkCode == null || kmkMa == null) {
            $(`#tooltipKmkKisyu${kmk.GroupNo}_${kmk.DetailNo}`).addClass('dp_none');
            return;
        }

        let inp = this.input_inpKmkSums[`${kmk.GroupNo}_${kmk.DetailNo}`];
        if (kmkMa.KisyuKbn != UseKbn.Setting) {
            inp.enabled = false;
            if (kmkMa.BPKbn == BPKbn.BS && kmkMa.SumAnaCode != SumAnaCode.ManufacturingCost) {
                $(`#tooltipKmkKisyu${kmk.GroupNo}_${kmk.DetailNo}`).removeClass('dp_none');
            }
            else {
                $(`#tooltipKmkKisyu${kmk.GroupNo}_${kmk.DetailNo}`).addClass('dp_none');
            }
        } else {
            inp.enabled = true;
            $(`#tooltipKmkKisyu${kmk.GroupNo}_${kmk.DetailNo}`).addClass('dp_none');
        }
    }

    private showTooltipKmkDup(oldCode: number, kmk: settingKmkDetailData): void {
        let olddupkmk = this.getDupKmkList(oldCode);
        if (olddupkmk.length == 1) {
            $(`#tooltipKmkDup${olddupkmk[0].GroupNo}_${olddupkmk[0].DetailNo}`).addClass('dp_none');
        }

        let dupkmk = this.getDupKmkList(kmk.DstKmkCode);
        if (dupkmk.length > 1) {
            for (let dup of dupkmk) {
                $(`#tooltipKmkDup${dup.GroupNo}_${dup.DetailNo}`).removeClass('dp_none');
            }
        } else {
            $(`#tooltipKmkDup${kmk.GroupNo}_${kmk.DetailNo}`).addClass('dp_none');
        }
    }

    private getDupKmkList(kmkCode: number): settingKmkDetailData[] {
        if (kmkCode == null) {
            return [];
        }

        let dupkmk: settingKmkDetailData[] = [];
        for (let grp of this.m_kmkSettingDatas) {
            for (let detail of grp.KmkSettingDetails) {
                if (kmkCode == detail.DstKmkCode) {
                    dupkmk.push(detail);
                }
            }
        }

        return dupkmk;
    }

    private showKmkErrCnt(gNo: number): void {
        let errCnt = this.getErrCntKmk(gNo);
        $(`#kmkErrCnt${gNo}`).empty();
        if (errCnt > 0) {
            $(`#kmkErrCnt${gNo}`).append(`（<span class="red-icon">エラー：${errCnt}件</span>）`);
        }

        if (errCnt > 0) { // 本来なら全グループのエラー件数で判定するが、AI監査は必ず1グループのため全件数で判定していない
            $("#kmk-required").removeClass("dp_none");
            this.kmkSettingSaveBtn.disable();
        } else {
            $("#kmk-required").addClass("dp_none");
            this.kmkSettingSaveBtn.enable();
        }
    }

    private getErrCntKmk(gNo: number): number {
        let grp = this.m_kmkSettingDatas[gNo];
        let cntErr: number = 0;

        for (let detail of grp.KmkSettingDetails) {
            if (!$(`#tooltipKmkDup${grp.GroupNo}_${detail.DetailNo}`).hasClass('dp_none') || detail.DstKmkCode == null) {
                cntErr++;
                $(`#cmbDstKmk${grp.GroupNo}_${detail.DetailNo}`).addClass('easy-input-required');
            } else {
                $(`#cmbDstKmk${grp.GroupNo}_${detail.DetailNo}`).removeClass('easy-input-required');
            }
        }

        return cntErr;
    }

    private showKmkInfoCnt(gNo: number): void {
        let infoCnt = this.getInfoCntKmk(gNo);
        $(`#kmkInfoCnt${gNo}`).empty();
        if (infoCnt > 0) {
            $(`#kmkInfoCnt${gNo}`).append(`（<span class="migration-blue">確認：${infoCnt}件</span>）`);
        }
    }

    private getInfoCntKmk(gNo: number): number {
        let grp = this.m_kmkSettingDatas[gNo];
        let cntInfo: number = 0;

        for (let detail of grp.KmkSettingDetails) {
            if (!$(`#tooltipKmkKisyu${grp.GroupNo}_${detail.DetailNo}`).hasClass('dp_none')) {
                cntInfo++;
            }
        }

        return cntInfo;
    }

    private initCmbDstKmkBtn(gNo: number, dNo: number): void {
        let id = `cmbDstKmkBtn${gNo}_${dNo}`;

        let cmbDstKmkBtn = $("#" + id);
        cmbDstKmkBtn.on('click', (e) => {
            if (this.kmkSettingExplorer == undefined) {
                this.kmkSettingExplorer = new ExplorerUI(this.$scope, this.$timeout);
            }

            let key = e.target.id;
            if (MjsUtilities.isNull(key)) {
                key = e.target.parentElement!.id;
            }
            key = key.substring(12);
            this.kmkSettingExplorer.closed.removeAllHandlers(); // 違うkeyの値でkmkSettingExplorer.closed処理が複数回呼ばれるためremoveAllHandlersする
            this.kmkSettingExplorer.closed.addHandler((sender: ExplorerUI, ea: wijmo.EventArgs) => {
                let e = ea as ExplorerClosedEventArgs;
                let isSelected = e.isSelected;
                if (isSelected) {
                    this.input_cmbDstkmks[key].selectedValue = e.selectedItems[0].code;

                    let gNo = key.substring(0, key.indexOf("_"));
                    let dNo = key.substring(key.indexOf("_") + 1);
                    let kmk = this.m_kmkSettingDatas[gNo].KmkSettingDetails[dNo];
                    let oldCode = kmk.DstKmkCode;
                    kmk.DstKmkCode = e.selectedItems[0].code;
                    let kmkMa = this.input_cmbDstkmks[key].selectedItem.item;

                    if (oldCode == kmk.DstKmkCode) {
                        // 選択内容が変更していない場合は何もしない
                        return;
                    }

                    kmk.IsEdited = true; // 編集済み

                    // 製造科目は文字色青
                    this.setManufacturingCostColor(kmk, kmkMa);

                    if (this.m_KmkSetting.KisyuEnable) {
                        // 期首残区分がなしの場合、期首残高はツールチップを表示して非活性にする
                        this.showTooltipKmkKisyu(kmk, kmkMa);
                    }

                    // 重複の場合、ツールチップを表示する
                    this.showTooltipKmkDup(oldCode, kmk);

                    for (let grp of this.m_kmkSettingDatas) {
                        // エラー件数（入力必須件数）を表示する
                        this.showKmkErrCnt(kmk.GroupNo);

                        // 確認科目件数を表示する
                        this.showKmkInfoCnt(grp.GroupNo);
                    }

                    // 編集中（未保存）
                    this.visibleBtnSuccessKmkSettingSave(false);
                }
            });

            let kmkList = this.convertToExplorerKmkItems(this.getKmkMAItems());
            this.kmkSettingExplorer.show(cmbDstKmkBtn, kmkList, [], true, null!, null!, true);
        });
    }

    private initInpKmkSum(gNo: number, dNo: number): CurrencyInput {
        const id = `inpKmkSum${gNo}_${dNo}`;

        let inp = this.initCurrencyInput(id);

        this.input_inpKmkSums[`${gNo}_${dNo}`] = inp;
        return inp;
    }

    private getKmkMAItems(): KmkMACacheViewModel[] {
        let kmkMaItems = this.kmkMaKmkDataCache.where(n => n.MasterKbn == MasterKbn.CountingKmk && n.SumKbn == MasterSumKbn.Real && n.RecordKbn == RecordKbn.Regular);

        // 除外科目を除く（ただし、複合科目の場合は非会計科目であっても除外しない。その他会計システムの場合は期日入力必須を除外しない。）
        let complexAccounts = this.kmkInfoDataCache.singleOrNull((n: { SpeCode: number; }) => n.SpeCode == ExeKmkCode.ComplexAccounts).KmkCode;
        let fundCompoundSubjectKmk = this.kmkInfoDataCache.singleOrNull((n: { SpeCode: number; }) => n.SpeCode == ExeKmkCode.FundCompoundSubjectKmk).KmkCode;
        kmkMaItems = kmkMaItems.where(n => !(n.InputKbn == InputKbn.ImpossibleToInput || (this.m_SystemKbn != MigrationSystemKbn.Other && n.DInputAlw == 1) || n.TkInputKbn != TkInputKbn.Any || (n.AnalyzeCode == AnalyzeCode.NonAccountingKmk && n.GCode != complexAccounts) || (n.GCode == fundCompoundSubjectKmk) || this.isDateErr(n.StartDate, n.EndDate)));

        // 製造科目を末尾に移動
        let manufacturingCost = kmkMaItems.where(n => n.SumAnaCode == SumAnaCode.ManufacturingCost);
        kmkMaItems = kmkMaItems.where(n => n.SumAnaCode != SumAnaCode.ManufacturingCost);
        kmkMaItems = kmkMaItems.concat(manufacturingCost);

        return kmkMaItems;
    }
    // #endregion setEventStepKmkSetting

    // #region setEventStepHojyoSetting
    private setEventStepHojyoSetting(): void {
        // タブ切り替え
        this.$scope.tabClick = (hojyoTabKbn: number) => {
            this.currentTab = hojyoTabKbn;

            ["hojyo", "generalhojyo", "bmn", "segment", "kouji"].forEach((tabName: string, index: number) => {
                if (index == hojyoTabKbn) {
                    $(`#${tabName}-tab`).tab("show");
                    $(`#${tabName}-tab-pane`).addClass("in active");
                    $(`#li-${tabName}-tab`).addClass("active");
                } else {
                    $(`#${tabName}-tab-pane`).removeClass("in active show");
                    $(`#li-${tabName}-tab`).removeClass("active");
                }
            });

            // ボタンの表示・非表示制御
            if (hojyoTabKbn == MigrationHojyoTabKbn.Hojyo) {
                $("#hojyokmkkisyu-check-btn").removeAttr('hidden'); // 補助期首残高を確認するボタン 表示
                $("#bmnkisyu-check-btn").attr('hidden', 'hidden');  // 部門期首残高を確認するボタン 非表示
            } else if (hojyoTabKbn == MigrationHojyoTabKbn.Bmn) {
                $("#hojyokmkkisyu-check-btn").attr('hidden', 'hidden'); // 補助期首残高を確認するボタン 非表示
                $("#bmnkisyu-check-btn").removeAttr('hidden');  // 部門期首残高を確認するボタン 表示
            } else {
                $("#hojyokmkkisyu-check-btn").attr('hidden', 'hidden'); // 補助期首残高を確認するボタン 非表示
                $("#bmnkisyu-check-btn").attr('hidden', 'hidden');  // 部門期首残高を確認するボタン 非表示
            }
        };

        // 対応設定を保存するボタン
        this.hojyokmkSettingSaveBtn.setEventHandler(new MjsDelegateContainer<Function, any>(async () => {
            // 対応設定保存ajax通信エラーが発生したかどうかの初期化
            this.hojyokmkSettingSaveIsErr = false;

            // 編集したデータor新規登録データのみ取得する
            let editedHojyoList = this.getEditedOrNewRegisterHojyoListSettingDetailViewModel(MigrationHojyoTabKbn.Hojyo);
            let editedGeneralHojyoList = this.getEditedOrNewRegisterHojyoListSettingDetailViewModel(MigrationHojyoTabKbn.GeneralHojyo);
            let editedBmnList = this.getEditedOrNewRegisterBmnListSettingDetailViewModel(MigrationHojyoTabKbn.Bmn);
            let editedSegmentList = this.getEditedOrNewRegisterBmnListSettingDetailViewModel(MigrationHojyoTabKbn.Segment);
            let editedKoujiList = this.getEditedOrNewRegisterBmnListSettingDetailViewModel(MigrationHojyoTabKbn.Kouji);

            let editedHojyoListLength = editedHojyoList.length;
            let editedGeneralHojyoListLength = editedGeneralHojyoList.length;
            let editedBmnListLength = editedBmnList.length;
            let editedSegmentListLength = editedSegmentList.length;
            let editedKoujiListLength = editedKoujiList.length;

            if (editedHojyoListLength == 0 && editedGeneralHojyoListLength == 0 && editedBmnListLength == 0 && editedSegmentListLength == 0 && editedKoujiListLength == 0) {
                // 未編集の場合、MappingSavedだけ更新する
                let model: MigrationHojyoSettingViewModel = {
                    SystemKbn: this.m_SystemKbn,
                    HojyoGroupList: [],
                    MappingSaved: true,
                    KisyuImported: this.isHojyoKisyuImported,
                    KisyuEnable: this.m_HojyoSetting.KisyuEnable,
                    AdoptClientKmkCodeList: [],
                };

                this.hojyokmkSettingSave(model, true);
                return;
            }

            // 新規登録データは分割1回目と2回目で同一データが存在する場合に重複登録されてしまう。
            // そのため、重複登録しないように新規登録データをキャッシュし、すでに登録済みの場合はキャッシュデータで内容を更新して通信する。
            this.newRegisterHojyoList = [];
            this.newRegisterGeneralHojyoList = [];
            this.newRegisterBmnList = [];
            this.newRegisterSegmentList = [];
            this.newRegisterKoujiList = [];

            for (let hojyo of editedHojyoList) {
                if (hojyo.MappingSettingKbn == MigrationMappingSettingKbn.NewRegister || hojyo.MappingSettingKbn == MigrationMappingSettingKbn.NewSundry) {
                    this.newRegisterHojyoList.push({
                        SrcKmkCode: hojyo.SrcKmkCode,
                        SrcHojyoCode: hojyo.SrcHojyoCode,
                        DstKmkCode: hojyo.DstKmkCode,
                        DstHojyoKbn1: hojyo.DstHojyoKbn1,
                        DstHojyoKbn2: hojyo.DstHojyoKbn2,
                        SrcHojyoName: hojyo.SrcHojyoName,
                        SrcHojyoRenChar: hojyo.SrcHojyoRenChar,
                        DstKmkHojyoCode: null!,
                        DstHojyoCode: null!
                    });
                }
            }
            for (let generalhojyo of editedGeneralHojyoList) {
                if (generalhojyo.MappingSettingKbn == MigrationMappingSettingKbn.NewRegister || generalhojyo.MappingSettingKbn == MigrationMappingSettingKbn.NewSundry) {
                    this.newRegisterGeneralHojyoList.push({
                        SrcKmkCode: generalhojyo.SrcKmkCode,
                        SrcHojyoCode: generalhojyo.SrcHojyoCode,
                        DstKmkCode: generalhojyo.DstKmkCode,
                        DstHojyoKbn1: generalhojyo.DstHojyoKbn1,
                        DstHojyoKbn2: generalhojyo.DstHojyoKbn2,
                        SrcHojyoName: generalhojyo.SrcHojyoName,
                        SrcHojyoRenChar: generalhojyo.SrcHojyoRenChar,
                        DstKmkHojyoCode: null!,
                        DstHojyoCode: null!
                    });
                }
            }
            for (let bmn of editedBmnList) {
                if (bmn.MappingSettingKbn == MigrationMappingSettingKbn.NewRegister || bmn.MappingSettingKbn == MigrationMappingSettingKbn.NewSundry) {
                    this.newRegisterBmnList.push({
                        SrcKmkCode: bmn.SrcKmkCode,
                        SrcHojyoCode: bmn.SrcHojyoCode,
                        DstKmkCode: bmn.DstKmkCode,
                        DstHojyoKbn1: bmn.DstHojyoKbn1,
                        DstHojyoKbn2: bmn.DstHojyoKbn2,
                        SrcHojyoName: bmn.SrcHojyoName,
                        SrcHojyoRenChar: bmn.SrcHojyoRenChar,
                        DstKmkHojyoCode: null!,
                        DstHojyoCode: null!
                    });
                }
            }
            for (let segment of editedSegmentList) {
                if (segment.MappingSettingKbn == MigrationMappingSettingKbn.NewRegister || segment.MappingSettingKbn == MigrationMappingSettingKbn.NewSundry) {
                    this.newRegisterSegmentList.push({
                        SrcKmkCode: segment.SrcKmkCode,
                        SrcHojyoCode: segment.SrcHojyoCode,
                        DstKmkCode: segment.DstKmkCode,
                        DstHojyoKbn1: segment.DstHojyoKbn1,
                        DstHojyoKbn2: segment.DstHojyoKbn2,
                        SrcHojyoName: segment.SrcHojyoName,
                        SrcHojyoRenChar: segment.SrcHojyoRenChar,
                        DstKmkHojyoCode: null!,
                        DstHojyoCode: null!
                    });
                }
            }
            for (let kouji of editedKoujiList) {
                if (kouji.MappingSettingKbn == MigrationMappingSettingKbn.NewRegister || kouji.MappingSettingKbn == MigrationMappingSettingKbn.NewSundry) {
                    this.newRegisterKoujiList.push({
                        SrcKmkCode: kouji.SrcKmkCode,
                        SrcHojyoCode: kouji.SrcHojyoCode,
                        DstKmkCode: kouji.DstKmkCode,
                        DstHojyoKbn1: kouji.DstHojyoKbn1,
                        DstHojyoKbn2: kouji.DstHojyoKbn2,
                        SrcHojyoName: kouji.SrcHojyoName,
                        SrcHojyoRenChar: kouji.SrcHojyoRenChar,
                        DstKmkHojyoCode: null!,
                        DstHojyoCode: null!
                    });
                }
            }

            // 分割1回目と2回目で得意先と仕入先がまたぐ場合、取引先として採用されない。
            // (仕様：科目別マスタ採用は取引先、得意先、仕入先を同時に採用することができない。そのため、一つの科目に得意先、仕入先で新規登録する場合、取引先として採用する。)
            // そのため、取引先として採用する必要がある科目をリストにしてサーバーに渡す。リスト対象は以下。
            //  - 得意先、仕入先
            //  - 取引先、得意先
            //  - 取引先、仕入先
            let kmkCodeGrp: { [key: number]: MigrationSettingDetailViewModel[]; } = {};
            for (let data of editedHojyoList) {
                let details = kmkCodeGrp[data.DstKmkCode];
                if (MjsUtilities.isNull(details)) {
                    details = [];
                    kmkCodeGrp[data.DstKmkCode] = details;
                }

                details.push(data);
            }

            let adoptClientKmkCodeList: Array<any> = [];
            for (let kmkCode of Object.keys(kmkCodeGrp)) {
                let details = kmkCodeGrp[kmkCode];
                if ((details.any((n: { DstHojyoKbn1: number; DstHojyoKbn2: number; }) => n.DstHojyoKbn1 == HojyoKbn.Client && n.DstHojyoKbn2 == HojyoKbn.Customers) && details.any((n: { DstHojyoKbn1: number; DstHojyoKbn2: number; }) => n.DstHojyoKbn1 == HojyoKbn.Client && n.DstHojyoKbn2 == HojyoKbn.Supplier)) ||
                    (details.any((n: { DstHojyoKbn1: number; DstHojyoKbn2: null; }) => n.DstHojyoKbn1 == HojyoKbn.Client && n.DstHojyoKbn2 == null) && details.any((n: { DstHojyoKbn1: number; DstHojyoKbn2: number; }) => n.DstHojyoKbn1 == HojyoKbn.Client && n.DstHojyoKbn2 == HojyoKbn.Customers)) ||
                    (details.any((n: { DstHojyoKbn1: number; DstHojyoKbn2: null; }) => n.DstHojyoKbn1 == HojyoKbn.Client && n.DstHojyoKbn2 == null) && details.any((n: { DstHojyoKbn1: number; DstHojyoKbn2: number; }) => n.DstHojyoKbn1 == HojyoKbn.Client && n.DstHojyoKbn2 == HojyoKbn.Supplier))) {
                    adoptClientKmkCodeList.push(kmkCode);
                }
            }

            // Azureのタイムアウトが4分のため、4分を超えると通信が切断されてしまう。
            // 補助科目の設定保存は4分を超える可能性があるので、件数を分割して通信を行う。
            const splitCnt: number = 100; // 分割件数
            let hojyoRoopCnt = Math.ceil(editedHojyoListLength / splitCnt);
            let generalhojyoRoopCnt = Math.ceil(editedGeneralHojyoListLength / splitCnt);
            let bmnRoopCnt = Math.ceil(editedBmnListLength / splitCnt);
            let segmentRoopCnt = Math.ceil(editedSegmentListLength / splitCnt);
            let koujiRoopCnt = Math.ceil(editedKoujiListLength / splitCnt);
            let ajaxCommunicationCnt = hojyoRoopCnt + generalhojyoRoopCnt + bmnRoopCnt + segmentRoopCnt + koujiRoopCnt;

            // 補助分割
            let hojyoList = [];
            for (let i = 0; i < hojyoRoopCnt; i++) {
                let j = i * splitCnt;
                hojyoList.push(editedHojyoList.slice(j, j + splitCnt));
            }

            // 汎用補助分割
            let generalhojyoList = [];
            for (let i = 0; i < generalhojyoRoopCnt; i++) {
                let j = i * splitCnt;
                generalhojyoList.push(editedGeneralHojyoList.slice(j, j + splitCnt));
            }

            // 部門分割
            let bmnList = [];
            for (let i = 0; i < bmnRoopCnt; i++) {
                let j = i * splitCnt;
                bmnList.push(editedBmnList.slice(j, j + splitCnt));
            }

            // セグメント分割
            let segmentList = [];
            for (let i = 0; i < segmentRoopCnt; i++) {
                let j = i * splitCnt;
                segmentList.push(editedSegmentList.slice(j, j + splitCnt));
            }

            // 工事分割
            let koujiList = [];
            for (let i = 0; i < koujiRoopCnt; i++) {
                let j = i * splitCnt;
                koujiList.push(editedKoujiList.slice(j, j + splitCnt));
            }

            let modelList = hojyoList.concat(generalhojyoList).concat(bmnList).concat(segmentList).concat(koujiList);
            for (let i = 1; i <= ajaxCommunicationCnt; i++) {
                let modelHojyoList: MigrationSettingDetailViewModel[] = [];
                let modelGeneralHojyoList: MigrationSettingDetailViewModel[] = [];
                let modelBmnList: MigrationSettingDetailViewModel[] = [];
                let modelSegmentList: MigrationSettingDetailViewModel[] = [];
                let modelKoujiList: MigrationSettingDetailViewModel[] = [];

                if (hojyoRoopCnt >= i) {
                    modelHojyoList = modelList[i - 1];
                    for (let data of modelHojyoList) {
                        if (data.MappingSettingKbn == MigrationMappingSettingKbn.NewRegister) {
                            let ret = this.searchHojyo(data, MigrationHojyoTabKbn.Hojyo, true);
                            if (ret != null) {
                                // 重複登録考慮（すでに登録済みの場合は新規登録しない）
                                if (data.DstHojyoKbn1 == HojyoKbn.EachKmkAuxiliary) {
                                    if (ret.DstKmkHojyoCode != null) {
                                        data.MappingSettingKbn = MigrationMappingSettingKbn.Mapped;
                                        data.DstKmkHojyoCode = ret.DstKmkHojyoCode;
                                    }
                                } else {
                                    if (ret.DstHojyoCode != null) {
                                        data.MappingSettingKbn = MigrationMappingSettingKbn.Mapped;
                                        data.DstHojyoCode = ret.DstHojyoCode;
                                    }
                                }
                            }
                        }
                    }
                } else if (hojyoRoopCnt < i && i <= hojyoRoopCnt + generalhojyoRoopCnt) {
                    modelGeneralHojyoList = modelList[i - 1];
                    for (let data of modelGeneralHojyoList) {
                        if (data.MappingSettingKbn == MigrationMappingSettingKbn.NewRegister) {
                            let ret = this.searchHojyo(data, MigrationHojyoTabKbn.GeneralHojyo, true);
                            if (ret != null && ret.DstHojyoCode != null) {
                                // 重複登録考慮（すでに登録済みの場合は新規登録しない）
                                data.MappingSettingKbn = MigrationMappingSettingKbn.Mapped;
                                data.DstHojyoCode = ret.DstHojyoCode;
                            }
                        }
                    }
                } else if (hojyoRoopCnt + generalhojyoRoopCnt < i && i <= hojyoRoopCnt + generalhojyoRoopCnt + bmnRoopCnt) {
                    modelBmnList = modelList[i - 1];
                    for (let data of modelBmnList) {
                        if (data.MappingSettingKbn == MigrationMappingSettingKbn.NewRegister) {
                            let ret = this.searchHojyo(data, MigrationHojyoTabKbn.Bmn, true);
                            if (ret != null && ret.DstHojyoCode != null) {
                                // 重複登録考慮（すでに登録済みの場合は新規登録しない）
                                data.MappingSettingKbn = MigrationMappingSettingKbn.Mapped;
                                data.DstHojyoCode = ret.DstHojyoCode;
                            }
                        }
                    }
                } else if (hojyoRoopCnt + generalhojyoRoopCnt + bmnRoopCnt < i && i <= hojyoRoopCnt + generalhojyoRoopCnt + bmnRoopCnt + segmentRoopCnt) {
                    modelSegmentList = modelList[i - 1];
                    for (let data of modelSegmentList) {
                        if (data.MappingSettingKbn == MigrationMappingSettingKbn.NewRegister) {
                            let ret = this.searchHojyo(data, MigrationHojyoTabKbn.Segment, true);
                            if (ret != null && ret.DstHojyoCode != null) {
                                // 重複登録考慮（すでに登録済みの場合は新規登録しない）
                                data.MappingSettingKbn = MigrationMappingSettingKbn.Mapped;
                                data.DstHojyoCode = ret.DstHojyoCode;
                            }
                        }
                    }
                } else if (hojyoRoopCnt + generalhojyoRoopCnt + bmnRoopCnt + segmentRoopCnt < i && i <= hojyoRoopCnt + generalhojyoRoopCnt + bmnRoopCnt + segmentRoopCnt + koujiRoopCnt) {
                    modelKoujiList = modelList[i - 1];
                    for (let data of modelKoujiList) {
                        if (data.MappingSettingKbn == MigrationMappingSettingKbn.NewRegister) {
                            let ret = this.searchHojyo(data, MigrationHojyoTabKbn.Kouji, true);
                            if (ret != null && ret.DstHojyoCode != null) {
                                // 重複登録考慮（すでに登録済みの場合は新規登録しない）
                                data.MappingSettingKbn = MigrationMappingSettingKbn.Mapped;
                                data.DstHojyoCode = ret.DstHojyoCode;
                            }
                        }
                    }
                }

                let model: MigrationHojyoSettingViewModel = {
                    SystemKbn: this.m_SystemKbn,
                    HojyoGroupList: [
                        { HojyoTabKbn: MigrationHojyoTabKbn.Hojyo, HojyoGroupName: "補助科目", HojyoList: modelHojyoList, ImportErrLogFileName: "", OpeningBalanceImportErrDetailList: [] },
                        { HojyoTabKbn: MigrationHojyoTabKbn.GeneralHojyo, HojyoGroupName: "汎用補助", HojyoList: modelGeneralHojyoList, ImportErrLogFileName: "", OpeningBalanceImportErrDetailList: [] },
                        { HojyoTabKbn: MigrationHojyoTabKbn.Bmn, HojyoGroupName: "部門", HojyoList: modelBmnList, ImportErrLogFileName: "", OpeningBalanceImportErrDetailList: [] },
                        { HojyoTabKbn: MigrationHojyoTabKbn.Segment, HojyoGroupName: "セグメント", HojyoList: modelSegmentList, ImportErrLogFileName: "", OpeningBalanceImportErrDetailList: [] },
                        { HojyoTabKbn: MigrationHojyoTabKbn.Kouji, HojyoGroupName: "工事", HojyoList: modelKoujiList, ImportErrLogFileName: "", OpeningBalanceImportErrDetailList: [] },
                    ],
                    MappingSaved: i == ajaxCommunicationCnt,
                    KisyuImported: this.isHojyoKisyuImported,
                    KisyuEnable: this.m_HojyoSetting.KisyuEnable,
                    AdoptClientKmkCodeList: adoptClientKmkCodeList,
                };

                if (this.hojyokmkSettingSaveIsErr) {
                    // 途中でエラーが発生した場合は処理終了
                    break;
                }

                await this.hojyokmkSettingSave(model, i == ajaxCommunicationCnt);
            }
        }, this));

        // タブ切り替え（残高インポートエラーログ）
        this.$scope.importErrLogTabClick = (currentImportErrLogTab: number) => {
            ["hojyokisyu-import", "bmnkisyu-import"].forEach((tabName: string, index: number) => {
                if (index == currentImportErrLogTab) {
                    $(`#${tabName}-err-tab`).tab("show");
                    $(`#${tabName}-err-tab-pane`).addClass("in active");
                    $(`#li-${tabName}-err-tab`).addClass("active");
                } else {
                    $(`#${tabName}-err-tab-pane`).removeClass("in active show");
                    $(`#li-${tabName}-err-tab`).removeClass("active");
                }
            });
        };

        // Excel出力ダイアログクローズ(補助期首残高を確認する)
        this.$scope.OnCloseExcelPopupHojyoZandakaCheck = () => {
            this.$timeout(() => {
                this.$scope.excelPopupActivatedHojyoZandakaCheck = false;
            });
        }

        // Excel出力ダイアログクローズ(部門期首残高を確認する)
        this.$scope.OnCloseExcelPopupBmnZandakaCheck = () => {
            this.$timeout(() => {
                this.$scope.excelPopupActivatedBmnZandakaCheck = false;
            });
        }

        // 残高をインポートするボタン
        this.hojyokmkKisyuImportBtn.setEventHandler(new MjsDelegateContainer<Function, any>(() => { this.hojyokmkKisyuImport(); }, this));

        // 補助期首残高を確認するボタン
        this.hojyokmkKisyuCheckBtn.setEventHandler(new MjsDelegateContainer<Function, any>(() => { this.hojyokmkKisyuCheck(); }, this));

        // 部門期首残高を確認するボタン
        this.bmnKisyuCheckBtn.setEventHandler(new MjsDelegateContainer<Function, any>(() => { this.bmnKisyuCheck(); }, this));

        // 残高インポートエラーログをダウンロードするボタン
        this.kisyuImportErrLogDownLoadBtn.setEventHandler(new MjsDelegateContainer<Function, any>(() => { this.kisyuImportErrLogDownLoad(); }, this));
    }

    private searchHojyo(data: MigrationSettingDetailViewModel, hojyoTabKbn: number, flg: boolean): newRegisterData {
        // 補助(部門)が新規登録の重複登録考慮用キャッシュにあるかどうか判定する
        // flg…true:使うとき（クラウドの設定が同一だったら同じと判定する）、false:更新するとき（新規のデータを一意に特定するため条件を強める）
        if (hojyoTabKbn == MigrationHojyoTabKbn.Hojyo) {
            if (data.DstHojyoKbn1 == HojyoKbn.EachKmkAuxiliary) {
                if (flg) {
                    return this.newRegisterHojyoList.singleOrNull((n: { DstKmkCode: number; DstHojyoKbn1: number; SrcHojyoName: string; SrcHojyoRenChar: string; }) =>
                        n.DstKmkCode == data.DstKmkCode &&
                        n.DstHojyoKbn1 == data.DstHojyoKbn1 &&
                        n.SrcHojyoName == data.SrcHojyoName &&
                        n.SrcHojyoRenChar == data.SrcHojyoRenChar
                    );
                } else {
                    return this.newRegisterHojyoList.singleOrNull((n: { SrcKmkCode: string; SrcHojyoCode: string; SrcHojyoKbn: number; DstKmkCode: number; DstHojyoKbn1: number; SrcHojyoName: string; SrcHojyoRenChar: string; }) =>
                        n.SrcKmkCode == data.SrcKmkCode &&
                        n.SrcHojyoCode == data.SrcHojyoCode &&
                        n.SrcHojyoKbn == data.SrcHojyoKbn &&
                        n.DstKmkCode == data.DstKmkCode &&
                        n.DstHojyoKbn1 == data.DstHojyoKbn1 &&
                        n.SrcHojyoName == data.SrcHojyoName &&
                        n.SrcHojyoRenChar == data.SrcHojyoRenChar
                    );
                }
            } else {
                if (flg) {
                    return this.newRegisterHojyoList.singleOrNull((n: { DstHojyoKbn1: number; DstHojyoKbn2: number; SrcHojyoName: string; SrcHojyoRenChar: string; }) =>
                        n.DstHojyoKbn1 == data.DstHojyoKbn1 &&
                        n.DstHojyoKbn2 == data.DstHojyoKbn2 &&
                        n.SrcHojyoName == data.SrcHojyoName &&
                        n.SrcHojyoRenChar == data.SrcHojyoRenChar
                    );
                } else {
                    return this.newRegisterHojyoList.singleOrNull((n: { SrcKmkCode: string; SrcHojyoCode: string; SrcHojyoKbn: number; DstHojyoKbn1: number; DstHojyoKbn2: number; SrcHojyoName: string; SrcHojyoRenChar: string; }) =>
                        n.SrcKmkCode == data.SrcKmkCode &&
                        n.SrcHojyoCode == data.SrcHojyoCode &&
                        n.SrcHojyoKbn == data.SrcHojyoKbn &&
                        n.DstHojyoKbn1 == data.DstHojyoKbn1 &&
                        n.DstHojyoKbn2 == data.DstHojyoKbn2 &&
                        n.SrcHojyoName == data.SrcHojyoName &&
                        n.SrcHojyoRenChar == data.SrcHojyoRenChar
                    );
                }
            }
        } else {
            let lst;
            if (hojyoTabKbn == MigrationHojyoTabKbn.GeneralHojyo) {
                lst = this.newRegisterGeneralHojyoList;
            } else if (hojyoTabKbn == MigrationHojyoTabKbn.Bmn) {
                lst = this.newRegisterBmnList;
            } else if (hojyoTabKbn == MigrationHojyoTabKbn.Segment) {
                lst = this.newRegisterSegmentList;
            } else {
                lst = this.newRegisterKoujiList;
            }

            if (flg) {
                return lst.singleOrNull((n: { DstHojyoKbn1: number; DstHojyoKbn2: number; SrcHojyoName: string; SrcHojyoRenChar: string; }) =>
                    n.DstHojyoKbn1 == data.DstHojyoKbn1 &&
                    n.DstHojyoKbn2 == data.DstHojyoKbn2 &&
                    n.SrcHojyoName == data.SrcHojyoName &&
                    n.SrcHojyoRenChar == data.SrcHojyoRenChar
                );
            } else {
                return lst.singleOrNull((n: { SrcKmkCode: string; SrcHojyoCode: string; SrcHojyoKbn: number; DstHojyoKbn1: number; DstHojyoKbn2: number; SrcHojyoName: string; SrcHojyoRenChar: string; }) =>
                    n.SrcKmkCode == data.SrcKmkCode &&
                    n.SrcHojyoCode == data.SrcHojyoCode &&
                    n.SrcHojyoKbn == data.SrcHojyoKbn &&
                    n.DstHojyoKbn1 == data.DstHojyoKbn1 &&
                    n.DstHojyoKbn2 == data.DstHojyoKbn2 &&
                    n.SrcHojyoName == data.SrcHojyoName &&
                    n.SrcHojyoRenChar == data.SrcHojyoRenChar
                );
            }
        }
    }

    private async hojyokmkSettingSave(model: MigrationHojyoSettingViewModel, isAjaxCommunicationEnd: boolean): Promise<void> {
        let isSuccess: boolean = false;
        let success = (data: MigrationHojyoSettingViewModel, textStatus: string, jqXHR: JQueryXHR) => {
            for (let updated of data.HojyoGroupList) {
                for (let updatedDetail of updated.HojyoList) {
                    if (updated.HojyoTabKbn == MigrationHojyoTabKbn.Hojyo) {
                        let detailViewModel = this.m_HojyoSettingHojyoList.singleOrNull((n: { SrcKmkCode: string; SrcHojyoCode: string; SrcHojyoKbn: number; }) => n.SrcKmkCode == updatedDetail.SrcKmkCode && n.SrcHojyoCode == updatedDetail.SrcHojyoCode && n.SrcHojyoKbn == updatedDetail.SrcHojyoKbn);
                        let detail = this.m_hojyokmkSettingDatas.singleOrNull((n: { SrcKmkCode: string; SrcHojyoCode: string; SrcHojyoKbn: number; }) => n.SrcKmkCode == updatedDetail.SrcKmkCode && n.SrcHojyoCode == updatedDetail.SrcHojyoCode && n.SrcHojyoKbn == updatedDetail.SrcHojyoKbn);

                        // 保持しているViewModelを更新する
                        MjsUtilities.deepCopy(detailViewModel, updatedDetail);
                        MjsUtilities.deepCopy(detail, this.convertFromHojyoSettingDetailViewModel(updatedDetail, detail.DetailNo));

                        detail.IsEdited = true; // convertでフラグが落ちてしまうのでここで設定

                        let ret = this.searchHojyo(detailViewModel, MigrationHojyoTabKbn.Hojyo, false);
                        if (ret != null) {
                            // 新規登録の重複登録考慮用キャッシュを更新する
                            if (detailViewModel.DstHojyoKbn1 == HojyoKbn.EachKmkAuxiliary) {
                                ret.DstKmkHojyoCode = detailViewModel.DstKmkHojyoCode;
                            } else {
                                ret.DstHojyoCode = detailViewModel.DstHojyoCode;
                            }
                        }
                    } else {
                        let detailViewModel;
                        let detail;
                        let hojyoTabKbn;
                        if (updated.HojyoTabKbn == MigrationHojyoTabKbn.GeneralHojyo) {
                            detailViewModel = this.m_HojyoSettingGeneralHojyoList.singleOrNull((n: { SrcKmkCode: string; SrcHojyoCode: string; SrcHojyoKbn: number; }) => n.SrcKmkCode == updatedDetail.SrcKmkCode && n.SrcHojyoCode == updatedDetail.SrcHojyoCode && n.SrcHojyoKbn == updatedDetail.SrcHojyoKbn);
                            detail = this.m_generalhojyoSettingDatas.singleOrNull((n: { SrcKmkCode: string; SrcHojyoCode: string; SrcHojyoKbn: number; }) => n.SrcKmkCode == updatedDetail.SrcKmkCode && n.SrcHojyoCode == updatedDetail.SrcHojyoCode && n.SrcHojyoKbn == updatedDetail.SrcHojyoKbn);
                            hojyoTabKbn = MigrationHojyoTabKbn.Bmn;
                        } else if (updated.HojyoTabKbn == MigrationHojyoTabKbn.Bmn) {
                            detailViewModel = this.m_HojyoSettingBmnList.singleOrNull((n: { SrcKmkCode: string; SrcHojyoCode: string; SrcHojyoKbn: number; }) => n.SrcKmkCode == updatedDetail.SrcKmkCode && n.SrcHojyoCode == updatedDetail.SrcHojyoCode && n.SrcHojyoKbn == updatedDetail.SrcHojyoKbn);
                            detail = this.m_bmnSettingDatas.singleOrNull((n: { SrcKmkCode: string; SrcHojyoCode: string; SrcHojyoKbn: number; }) => n.SrcKmkCode == updatedDetail.SrcKmkCode && n.SrcHojyoCode == updatedDetail.SrcHojyoCode && n.SrcHojyoKbn == updatedDetail.SrcHojyoKbn);
                            hojyoTabKbn = MigrationHojyoTabKbn.Bmn;
                        } else if (updated.HojyoTabKbn == MigrationHojyoTabKbn.Segment) {
                            detailViewModel = this.m_HojyoSettingSegmentList.singleOrNull((n: { SrcKmkCode: string; SrcHojyoCode: string; SrcHojyoKbn: number; }) => n.SrcKmkCode == updatedDetail.SrcKmkCode && n.SrcHojyoCode == updatedDetail.SrcHojyoCode && n.SrcHojyoKbn == updatedDetail.SrcHojyoKbn);
                            detail = this.m_segmentSettingDatas.singleOrNull((n: { SrcKmkCode: string; SrcHojyoCode: string; SrcHojyoKbn: number; }) => n.SrcKmkCode == updatedDetail.SrcKmkCode && n.SrcHojyoCode == updatedDetail.SrcHojyoCode && n.SrcHojyoKbn == updatedDetail.SrcHojyoKbn);
                            hojyoTabKbn = MigrationHojyoTabKbn.Segment;
                        } else {
                            detailViewModel = this.m_HojyoSettingKoujiList.singleOrNull((n: { SrcKmkCode: string; SrcHojyoCode: string; SrcHojyoKbn: number; }) => n.SrcKmkCode == updatedDetail.SrcKmkCode && n.SrcHojyoCode == updatedDetail.SrcHojyoCode && n.SrcHojyoKbn == updatedDetail.SrcHojyoKbn);
                            detail = this.m_koujiSettingDatas.singleOrNull((n: { SrcKmkCode: string; SrcHojyoCode: string; SrcHojyoKbn: number; }) => n.SrcKmkCode == updatedDetail.SrcKmkCode && n.SrcHojyoCode == updatedDetail.SrcHojyoCode && n.SrcHojyoKbn == updatedDetail.SrcHojyoKbn);
                            hojyoTabKbn = MigrationHojyoTabKbn.Kouji;
                        }

                        // 保持しているViewModelを更新する
                        MjsUtilities.deepCopy(detailViewModel, updatedDetail);
                        MjsUtilities.deepCopy(detail, this.convertFromHojyoSettingDetailViewModel(updatedDetail, detail.DetailNo));

                        detail.IsEdited = true; // convertでフラグが落ちてしまうのでここで設定

                        let ret = this.searchHojyo(detailViewModel, hojyoTabKbn, false);
                        if (ret != null) {
                            // 新規登録の重複登録考慮用キャッシュを更新する
                            ret.DstHojyoCode = detailViewModel.DstHojyoCode;
                        }
                    }
                }
            }

            isSuccess = true;
        };
        let complete = (jqXHR: JQueryXHR, textStatus: string) => {
            if (isSuccess && isAjaxCommunicationEnd) {
                // データ再表示
                this.isHojyoKmkSettingSaveBtnClick = true;
                this.showLoadingArea();
                this.masterDataManager.refresh();

                this.m_HojyoSetting.MappingSaved = true;
                this.visibleBtnSuccessHojyoKmkSettingSave(true);
                this.visibleBtnSuccessGeneralHojyoSettingSave(true);
                this.visibleBtnSuccessBmnSettingSave(true);
                this.visibleBtnSuccessSegmentSettingSave(true);
                this.visibleBtnSuccessKoujiSettingSave(true);

                if (this.m_CurrentStep == MigrationStep.HojyoSetting) {
                    if (!this.m_HojyoSetting.KisyuEnable) {
                        // ステップ更新
                        this.getCurrentStep();
                    }
                } else {
                    if (this.isBeforeCurrentStep(MigrationStep.SwkImport, true) && this.m_StepCheckResult.IsSwkImportStepEnable) {
                        $.when.apply(this, [this.storeSwkSetting()]).done(() => {
                            this.$timeout(() => {
                                // エラー情報を更新する
                                this.updateErrInfoInSwkSettingTable(this.m_JournalSetting);

                                // 画面の状態をデータモデルに反映する
                                let swkSettingData = <settingSwkData>this.$scope.swkSetting;
                                let rowIndex = this.m_JournalSetting.ExcludedJournalAmount != 0 ? 1 : 0;
                                for (let i = 0; i < this.m_JournalSetting.JournalMonthList.length; i++) {
                                    this.m_JournalSetting.JournalMonthList[i].CheckStatus = swkSettingData.SwkInfoList[rowIndex].CheckStatus;
                                    rowIndex++;
                                }
                                this.m_JournalSetting.ErrCancelFlg = swkSettingData.ErrCancelFlg;
                            });
                        });
                    }
                }

                setTimeout(() => {
                    this.mediator.setFocusToActiveItem();
                });
            }
        };
        let error = (data: any, jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
            this.hojyokmkSettingSaveIsErr = true;
        };
        let settings: AjaxSettings = {
            url: "/api/v1/ProcessMigration/SaveMigrationHojyoSetting",
            type: "PUT",
            data: model,
            showBusyIndicator: true,
            beforeSend: null!,
            success: success,
            complete: complete,
            error: error
        };

        // 補助科目対応設定保存
        await Ajax.perform(settings, false);
    }

    // 残高インポートエラーログの取得
    private storeImportErrLog(): void {
        let success = (data: MigrationHojyoSettingViewModel, textStatus: string, jqXHR: JQueryXHR) => {
            this.m_HojyoSetting = data;
            this.m_HojyoSettingHojyoList = [];
            this.m_HojyoSettingGeneralHojyoList = [];
            this.m_HojyoSettingBmnList = [];
            this.m_HojyoSettingSegmentList = [];
            this.m_HojyoSettingKoujiList = [];

            if (this.m_HojyoSetting != null) {
                for (let setting of this.m_HojyoSetting.HojyoGroupList) {
                    switch (setting.HojyoTabKbn) {
                        case MigrationHojyoTabKbn.Hojyo: this.m_HojyoSettingHojyoList = setting.HojyoList; this.setHojyoKisyuImportrLog(setting); break;
                        case MigrationHojyoTabKbn.GeneralHojyo: this.m_HojyoSettingGeneralHojyoList = setting.HojyoList; break;
                        case MigrationHojyoTabKbn.Bmn: this.m_HojyoSettingBmnList = setting.HojyoList; this.setBmnKisyuImportErrLog(setting); break;
                        case MigrationHojyoTabKbn.Segment: this.m_HojyoSettingSegmentList = setting.HojyoList; break;
                        case MigrationHojyoTabKbn.Kouji: this.m_HojyoSettingKoujiList = setting.HojyoList; break;
                    }
                }
            }

            this.m_hojyokmkSettingDatas = this.convertFromHojyoSettingViewModel(this.m_HojyoSettingHojyoList);
            this.m_generalhojyoSettingDatas = this.convertFromHojyoSettingViewModel(this.m_HojyoSettingGeneralHojyoList);
            this.m_bmnSettingDatas = this.convertFromHojyoSettingViewModel(this.m_HojyoSettingBmnList);
            this.m_segmentSettingDatas = this.convertFromHojyoSettingViewModel(this.m_HojyoSettingSegmentList);
            this.m_koujiSettingDatas = this.convertFromHojyoSettingViewModel(this.m_HojyoSettingKoujiList);

            this.initImportErrLogTab();
        }
        let complete = (jqXHR: JQueryXHR, textStatus: string) => { };
        let error = (data: any, jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {  };
        let model: MigrationHojyoSettingViewModel = {
            SystemKbn: this.m_SystemKbn,
            HojyoGroupList: null!,
            MappingSaved: false,
            KisyuImported: false,
            KisyuEnable: null!,
            AdoptClientKmkCodeList: null!,
        };
        let settings: AjaxSettings = {
            url: "/api/v1/ProcessMigration/StoreHojyoSetting",
            type: "POST",
            data: model,
            showBusyIndicator: false,
            beforeSend: null!,
            success: success,
            complete: complete,
            error: error
        };

        // 補助登録用データ取得
        Ajax.perform(settings);
    }

    private hojyokmkKisyuImport(): void {
        let success = (data: MigrationOpeningBalanceViewModel, textStatus: string, jqXHR: JQueryXHR) => {
            this.visibleBtnSuccessHojyoKmkKisyuImport(true);
            this.storeImportErrLog();
            if (this.m_HojyoSetting.KisyuEnable) {
                // ステップ更新
                this.getCurrentStep();
            }

            setTimeout(() => {
                this.mediator.setFocusToActiveItem();
            });
        };
        let complete = (jqXHR: JQueryXHR, textStatus: string) => { };
        let error = (data: any, jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => { };
        let model: MigrationOpeningBalanceViewModel = {
            CleaningTarget: CleaningTargetKbn.Hojyo,
        };
        let settings: AjaxSettings = {
            url: "/api/v1/ProcessMigration/MigrateOpeningBalance",
            type: "POST",
            data: model,
            showBusyIndicator: true,
            beforeSend: null!,
            success: success,
            complete: complete,
            error: error
        };

        // 期首残高移行
        Ajax.perform(settings);
    }

    // 「補助期首残高を確認する」ボタン押下後に実行する処理
    private hojyokmkKisyuCheck(): void {
        this.$timeout(() => {
            this.$scope.excelPopupActivatedHojyoZandakaCheck = true;
        });
    }

    // 「部門期首残高を確認する」ボタン押下後に実行する処理
    private bmnKisyuCheck(): void {
        this.$timeout(() => {
            this.$scope.excelPopupActivatedBmnZandakaCheck = true;
        });
    }

    private storeHojyoKmkSetting(): JQueryPromise<{}> {
        let d = $.Deferred<{}>();
        let success = (data: MigrationHojyoSettingViewModel, textStatus: string, jqXHR: JQueryXHR) => {
            this.m_HojyoSetting = data;
            this.m_HojyoSettingHojyoList = [];
            this.m_HojyoSettingGeneralHojyoList = [];
            this.m_HojyoSettingBmnList = [];
            this.m_HojyoSettingSegmentList = [];
            this.m_HojyoSettingKoujiList = [];

            if (this.m_HojyoSetting != null) {
                for (let setting of this.m_HojyoSetting.HojyoGroupList) {
                    switch (setting.HojyoTabKbn) {
                        case MigrationHojyoTabKbn.Hojyo: this.m_HojyoSettingHojyoList = setting.HojyoList; this.setHojyoKisyuImportrLog(setting); break;
                        case MigrationHojyoTabKbn.GeneralHojyo: this.m_HojyoSettingGeneralHojyoList = setting.HojyoList; break;
                        case MigrationHojyoTabKbn.Bmn: this.m_HojyoSettingBmnList = setting.HojyoList; this.setBmnKisyuImportErrLog(setting); break;
                        case MigrationHojyoTabKbn.Segment: this.m_HojyoSettingSegmentList = setting.HojyoList; break;
                        case MigrationHojyoTabKbn.Kouji: this.m_HojyoSettingKoujiList = setting.HojyoList; break;
                    }
                }
            }

            this.m_hojyokmkSettingDatas = this.convertFromHojyoSettingViewModel(this.m_HojyoSettingHojyoList);
            this.m_generalhojyoSettingDatas = this.convertFromHojyoSettingViewModel(this.m_HojyoSettingGeneralHojyoList);
            this.m_bmnSettingDatas = this.convertFromHojyoSettingViewModel(this.m_HojyoSettingBmnList);
            this.m_segmentSettingDatas = this.convertFromHojyoSettingViewModel(this.m_HojyoSettingSegmentList);
            this.m_koujiSettingDatas = this.convertFromHojyoSettingViewModel(this.m_HojyoSettingKoujiList);

            d.resolve();
        }
        let complete = (jqXHR: JQueryXHR, textStatus: string) => { };
        let error = (data: any, jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => { d.reject(); };
        let model: MigrationHojyoSettingViewModel = {
            SystemKbn: this.m_SystemKbn,
            HojyoGroupList: null!,
            MappingSaved: false,
            KisyuImported: false,
            KisyuEnable: null!,
            AdoptClientKmkCodeList: null!,
        };
        let settings: AjaxSettings = {
            url: "/api/v1/ProcessMigration/StoreHojyoSetting",
            type: "POST",
            data: model,
            showBusyIndicator: false,
            beforeSend: null!,
            success: success,
            complete: complete,
            error: error
        };

        // 補助登録用データ取得
        Ajax.perform(settings);

        return d.promise();
    }

    private initInputControlsHojyoKmkSetting(datas: settingHojyoDetailData[], hojyoTabKbn: number): void {
        for (let data of datas) {
            // AI監査支援での登録方法
            this.initCmbHowRegist(data.DetailNo, data.SrcHojyoKbn, hojyoTabKbn);

            // AI監査支援の補助選択
            this.initCmbSelectHojyo(data.DetailNo, hojyoTabKbn);

            // 期首残高
            this.initInpHojyoSum(data.DetailNo);
        }
    }

    private initInputControlsHojyoSetting(datas: settingHojyoDetailData[], hojyoTabKbn: number): void {
        for (let data of datas) {
            // AI監査支援での登録方法
            this.initCmbHowRegist(data.DetailNo, data.SrcHojyoKbn, hojyoTabKbn);

            // AI監査支援の補助選択
            this.initCmbSelectHojyo(data.DetailNo, hojyoTabKbn);
        }
    }

    private setCmbSelectHojyosItemSource(hojyoTabKbn: number, datas: settingHojyoDetailData[], isAllUpdate: boolean = true): void {
        let inputs;
        if (hojyoTabKbn == MigrationHojyoTabKbn.Hojyo) {
            inputs = this.input_cmbSelectHojyos;
        } else if (hojyoTabKbn == MigrationHojyoTabKbn.GeneralHojyo) {
            inputs = this.input_cmbSelectGeneralHojyos;
        } else if (hojyoTabKbn == MigrationHojyoTabKbn.Bmn) {
            inputs = this.input_cmbSelectBmns;
        } else if (hojyoTabKbn == MigrationHojyoTabKbn.Segment) {
            inputs = this.input_cmbSelectSegments;
        } else {
            inputs = this.input_cmbSelectKoujis;
        }

        for (let data of datas) {

            if (!isAllUpdate && !data.IsEdited) {
                continue;
            }

            let cmb = inputs[data.DetailNo];
            cmb.itemsSource = this.createCmbSelectHojyoItems(data);
        }
    }

    private initHojyoKmkSettingTable(datas: settingHojyoDetailData[], isAllUpdate: boolean = true): void {
        // 表示制御
        for (let data of datas) {

            if (!isAllUpdate && !data.IsEdited) {
                continue;
            }

            // AI監査支援での登録方法
            let idHow = `cmbHowRegist_hojyo${data.DetailNo}`;
            let cmbHow = this.input_cmbHowRegistHojyos[data.DetailNo];

            // AI監査支援の補助選択
            let idSelect = `cmbSelectHojyo_hojyo${data.DetailNo}`;
            let cmbSelect = this.input_cmbSelectHojyos[data.DetailNo];
            let itemsSource = cmbSelect.itemsSource;

            if (data.MappingSettingKbn == MigrationMappingSettingKbn.NotRegister) {
                $(`#${idHow} input`).addClass("migration-red");
                this.setCodeAndMappingKbnAndIsRequiredCaseOfNotRegist(data);
                data.HowRegist = -1;
                cmbHow.selectedValue = data.HowRegist;
            } else {
                $(`#${idHow} input`).removeClass("migration-red");
                cmbHow.selectedValue = data.HowRegist;

                if (data.HowRegist == HojyoKbn.EachKmkAuxiliary) {
                    let kmkhojyo = itemsSource.singleOrNull((n: { key: number; }) => n.key == data.DstKmkHojyoCode);
                    if (kmkhojyo == null && data.MappingSettingKbn == MigrationMappingSettingKbn.Mapped) {
                        // 補助科目が更新され見つからなかった場合
                        if (data.DstKmkHojyoCode != null) {
                            this.visibleBtnSuccessHojyoKmkSettingSave(false);
                            data.IsEdited = true;
                        }
                        this.setCodeAndMappingKbnAndIsRequiredCaseOfDefault(data);
                    }

                    let kmk = this.kmkMaKmkDataCache.singleOrNull((n: { GCode: number; }) => n.GCode == data.DstKmkCode);
                    if (kmk == null) {
                        // 勘定科目が更新され見つからなかった場合
                        if (data.DstKmkCode != null) {
                            this.visibleBtnSuccessHojyoKmkSettingSave(false);
                            data.IsEdited = true;
                        }
                        this.setCodeAndMappingKbnAndIsRequiredCaseOfDefault(data);
                    }

                    if ((data.DstKmkCode == null || data.AuxiliaryAccountCanAdoptedKbn != AuxiliaryAccountCanAdoptedKbn.Normal)) {
                        // 勘定科目が対応付けされていない or 科目別マスタが採用できなかった場合
                        if (data.DstKmkHojyoCode != null || data.MappingSettingKbn == MigrationMappingSettingKbn.NewRegister || data.MappingSettingKbn == MigrationMappingSettingKbn.NewSundry) {
                            this.visibleBtnSuccessHojyoKmkSettingSave(false);
                            data.IsEdited = true;
                        }
                        this.setCodeAndMappingKbnAndIsRequiredCaseOfDefault(data);
                    }
                } else {
                    let hojyo = itemsSource.singleOrNull((n: { key: string; }) => n.key == this.getTemporaryCode(data.DstHojyoCode, data.HowRegist));
                    if (hojyo == null && data.MappingSettingKbn == MigrationMappingSettingKbn.Mapped) {
                        // 補助科目が更新され見つからなかった場合
                        if (data.DstHojyoCode != null) {
                            this.visibleBtnSuccessHojyoKmkSettingSave(false);
                            data.IsEdited = true;
                        }
                        this.setCodeAndMappingKbnAndIsRequiredCaseOfDefault(data);
                    }

                    if ((data.DstKmkCode == null || data.AuxiliaryAccountCanAdoptedKbn != AuxiliaryAccountCanAdoptedKbn.Normal) && (data.MappingSettingKbn == MigrationMappingSettingKbn.NewRegister || data.MappingSettingKbn == MigrationMappingSettingKbn.NewSundry)) {
                        // 勘定科目が対応付けされていない or 科目別マスタが採用できなかった場合で「新規登録」または「諸口」の場合
                        this.visibleBtnSuccessHojyoKmkSettingSave(false);
                        data.IsEdited = true;
                        this.setCodeAndMappingKbnAndIsRequiredCaseOfDefault(data);
                    }
                }

                if (data.MappingSettingKbn == MigrationMappingSettingKbn.NewRegister) {
                    $(`#${idSelect} input`).addClass("migration-blue");
                    cmbSelect.selectedValue = "新規登録";
                } else if (data.MappingSettingKbn == MigrationMappingSettingKbn.NewSundry) {
                    cmbSelect.selectedValue = "諸口";
                } else {
                    $(`#${idSelect} input`).removeClass("migration-blue");

                    if (data.MappingSettingKbn == MigrationMappingSettingKbn.Unselected) {
                        cmbSelect.selectedValue = "デフォルト";
                    } else if (data.MappingSettingKbn == MigrationMappingSettingKbn.Mapped) {
                        if (data.HowRegist == HojyoKbn.EachKmkAuxiliary) {
                            cmbSelect.selectedValue = data.DstKmkHojyoCode;
                        } else {
                            cmbSelect.selectedValue = this.getTemporaryCode(data.DstHojyoCode, data.HowRegist);
                        }
                    }
                }
            }

            // AI監査支援の補助選択を表示/非表示する
            this.showCmbSelectHojyo(data, MigrationHojyoTabKbn.Hojyo);

            // AI監査支援の補助選択を活性/非活性する
            this.setEnableCmbSelectHojyoAndShowTooltip(data, MigrationHojyoTabKbn.Hojyo);

            // 期首残高を活性/非活性する
            this.setEnableInpHojyoSumAndShowTooltip(data);

            // 重複の場合、ツールチップを表示する
            this.showTooltipHojyoDup(MigrationHojyoTabKbn.Hojyo, null!, data);
            this.showTooltipHojyoSameRegist(MigrationHojyoTabKbn.Hojyo, null!, data);
        };

        if (this.m_HojyoSetting.KisyuEnable) {
            if (this.isHojyoKmkSettingSave) {
                // 残高インポートボタン活性
                this.hojyokmkKisyuImportBtn.enable();
                if (this.isHojyoKisyuImported) {
                    // 補助期首残高を確認するボタン活性
                    this.hojyokmkKisyuCheckBtn.enable();
                    // 部門期首残高を確認するボタン活性
                    this.bmnKisyuCheckBtn.enable();
                } else {
                    // 補助期首残高を確認するボタン非活性
                    this.hojyokmkKisyuCheckBtn.disable();
                    // 部門期首残高を確認するボタン非活性
                    this.bmnKisyuCheckBtn.disable();
                }
            } else {
                // 残高インポートボタン非活性
                this.hojyokmkKisyuImportBtn.disable();
                // 補助期首残高を確認するボタン非活性
                this.hojyokmkKisyuCheckBtn.disable();
                // 部門期首残高を確認するボタン非活性
                this.bmnKisyuCheckBtn.disable();
            }
        } else {
            // 残高インポートボタン非活性
            this.hojyokmkKisyuImportBtn.disable();
            // 補助期首残高を確認するボタン非活性
            this.hojyokmkKisyuCheckBtn.disable();
            // 部門期首残高を確認するボタン非活性
            this.bmnKisyuCheckBtn.disable();
        }

        // エラー件数（入力必須件数）を表示する
        this.showHojyoBmnErrCnt(MigrationHojyoTabKbn.Hojyo);
    }

    private initGeneralHojyoSettingTable(datas: settingHojyoDetailData[], isAllUpdate: boolean = true): void {
        // 表示制御
        for (let data of datas) {

            if (!isAllUpdate && !data.IsEdited) {
                continue;
            }

            // AI監査支援での登録方法
            let idHow = `cmbHowRegist_generalhojyo${data.DetailNo}`;
            let cmbHow = this.input_cmbHowRegistGeneralHojyos[data.DetailNo];

            // AI監査支援の補助選択
            let idSelect = `cmbSelectHojyo_generalhojyo${data.DetailNo}`;
            let cmbSelect = this.input_cmbSelectGeneralHojyos[data.DetailNo];
            let itemsSource = cmbSelect.itemsSource;

            if (data.MappingSettingKbn == MigrationMappingSettingKbn.NotRegister) {
                $(`#${idHow} input`).addClass("migration-red");
                this.setCodeAndMappingKbnAndIsRequiredCaseOfNotRegist(data);
                data.HowRegist = -1;
                cmbHow.selectedValue = data.HowRegist;
            } else {
                $(`#${idHow} input`).removeClass("migration-red");
                cmbHow.selectedValue = data.HowRegist;

                let hojyo = itemsSource.singleOrNull((n: { key: string; }) => n.key == this.getTemporaryCode(data.DstHojyoCode, data.HowRegist));
                if (hojyo == null && data.MappingSettingKbn == MigrationMappingSettingKbn.Mapped) {
                    // 補助科目が更新され見つからなかった場合
                    if (data.DstHojyoCode != null) {
                        this.visibleBtnSuccessGeneralHojyoSettingSave(false);
                        data.IsEdited = true;
                    }
                    this.setCodeAndMappingKbnAndIsRequiredCaseOfDefault(data);
                }

                if ((data.DstKmkCode == null || data.AuxiliaryAccountCanAdoptedKbn != AuxiliaryAccountCanAdoptedKbn.Normal) && (data.MappingSettingKbn == MigrationMappingSettingKbn.NewRegister || data.MappingSettingKbn == MigrationMappingSettingKbn.NewSundry)) {
                    // 勘定科目が対応付けされていない or 科目別マスタが採用できなかった場合で「新規登録」の場合
                    this.visibleBtnSuccessGeneralHojyoSettingSave(false);
                    data.IsEdited = true;
                    this.setCodeAndMappingKbnAndIsRequiredCaseOfDefault(data);
                }

                if (data.MappingSettingKbn == MigrationMappingSettingKbn.NewRegister) {
                    $(`#${idSelect} input`).addClass("migration-blue");
                    cmbSelect.selectedValue = "新規登録";
                } else if (data.MappingSettingKbn == MigrationMappingSettingKbn.NewSundry) {
                    cmbSelect.selectedValue = "諸口";
                } else {
                    $(`#${idSelect} input`).removeClass("migration-blue");

                    if (data.MappingSettingKbn == MigrationMappingSettingKbn.Unselected) {
                        cmbSelect.selectedValue = "デフォルト";
                    } else if (data.MappingSettingKbn == MigrationMappingSettingKbn.Mapped) {
                        cmbSelect.selectedValue = this.getTemporaryCode(data.DstHojyoCode, data.HowRegist);
                    }
                }
            }

            // AI監査支援の補助選択を表示/非表示する
            this.showCmbSelectHojyo(data, MigrationHojyoTabKbn.GeneralHojyo);

            // AI監査支援の補助選択を活性/非活性する
            this.setEnableCmbSelectHojyoAndShowTooltip(data, MigrationHojyoTabKbn.GeneralHojyo);

            // 重複の場合、ツールチップを表示する
            this.showTooltipHojyoDup(MigrationHojyoTabKbn.GeneralHojyo, null!, data);
            this.showTooltipHojyoSameRegist(MigrationHojyoTabKbn.GeneralHojyo, null!, data);
        };

        // エラー件数（入力必須件数）を表示する
        this.showHojyoBmnErrCnt(MigrationHojyoTabKbn.GeneralHojyo);
    }

    private initBmnSettingTable(hojyoTabKbn: number, datas: settingHojyoDetailData[], isAllUpdate: boolean = true): void {
        // 表示制御
        for (let data of datas) {

            if (!isAllUpdate && !data.IsEdited) {
                continue;
            }

            // AI監査支援での登録方法
            let idHow;
            let cmbHow;

            // AI監査支援の補助選択
            let idSelect;
            let cmbSelect;

            if (hojyoTabKbn == MigrationHojyoTabKbn.Bmn) {
                idHow = `cmbHowRegist_bmn${data.DetailNo}`;
                cmbHow = this.input_cmbHowRegistBmns[data.DetailNo];
                idSelect = `cmbSelectHojyo_bmn${data.DetailNo}`;
                cmbSelect = this.input_cmbSelectBmns[data.DetailNo];
            } else if (hojyoTabKbn == MigrationHojyoTabKbn.Segment) {
                idHow = `cmbHowRegist_segment${data.DetailNo}`;
                cmbHow = this.input_cmbHowRegistSegments[data.DetailNo];
                idSelect = `cmbSelectHojyo_segment${data.DetailNo}`;
                cmbSelect = this.input_cmbSelectSegments[data.DetailNo];
            } else {
                idHow = `cmbHowRegist_kouji${data.DetailNo}`;
                cmbHow = this.input_cmbHowRegistKoujis[data.DetailNo];
                idSelect = `cmbSelectHojyo_kouji${data.DetailNo}`;
                cmbSelect = this.input_cmbSelectKoujis[data.DetailNo];
            }

            let itemsSource = cmbSelect.itemsSource;

            if (data.MappingSettingKbn == MigrationMappingSettingKbn.NotRegister) {
                $(`#${idHow} input`).addClass("migration-red");
                this.setCodeAndMappingKbnAndIsRequiredCaseOfNotRegist(data);
                data.HowRegist = -1;
                cmbHow.selectedValue = data.HowRegist;
            } else {
                $(`#${idHow} input`).removeClass("migration-red");
                cmbHow.selectedValue = data.HowRegist;

                let hojyo = itemsSource.singleOrNull((n: { key: string; }) => n.key == this.getTemporaryCode(data.DstHojyoCode, data.HowRegist));
                if (hojyo == null && data.MappingSettingKbn == MigrationMappingSettingKbn.Mapped) {
                    // 補助科目が更新され見つからなかった場合
                    if (data.DstHojyoCode != null) {
                        if (hojyoTabKbn == MigrationHojyoTabKbn.Bmn) {
                            this.visibleBtnSuccessBmnSettingSave(false);
                        } else if (hojyoTabKbn == MigrationHojyoTabKbn.Segment) {
                            this.visibleBtnSuccessSegmentSettingSave(false);
                        } else if (hojyoTabKbn == MigrationHojyoTabKbn.Kouji) {
                            this.visibleBtnSuccessKoujiSettingSave(false);
                        }
                        data.IsEdited = true;
                    }

                    this.setCodeAndMappingKbnAndIsRequiredCaseOfDefault(data);
                }

                if (data.MappingSettingKbn == MigrationMappingSettingKbn.NewRegister) {
                    $(`#${idSelect} input`).addClass("migration-blue");
                    cmbSelect.selectedValue = "新規登録";
                } else if (data.MappingSettingKbn == MigrationMappingSettingKbn.NewSundry) {
                    cmbSelect.selectedValue = "諸口";
                } else {
                    $(`#${idSelect} input`).removeClass("migration-blue");

                    if (data.MappingSettingKbn == MigrationMappingSettingKbn.Unselected) {
                        cmbSelect.selectedValue = "デフォルト";
                    } else if (data.MappingSettingKbn == MigrationMappingSettingKbn.Mapped) {
                        cmbSelect.selectedValue = this.getTemporaryCode(data.DstHojyoCode, data.HowRegist);
                    }
                }
            }

            // AI監査支援の補助選択を表示/非表示する
            this.showCmbSelectHojyo(data, hojyoTabKbn);
        };

        if (this.m_HojyoSetting.KisyuEnable) {
            if (this.isBmnSettingSave) {
                // 残高インポートボタン活性
                this.hojyokmkKisyuImportBtn.enable();
                if (this.isHojyoKisyuImported) {
                    // 補助期首残高を確認するボタン活性
                    this.hojyokmkKisyuCheckBtn.enable();
                    // 部門期首残高を確認するボタン活性
                    this.bmnKisyuCheckBtn.enable();
                } else {
                    // 補助期首残高を確認するボタン非活性
                    this.hojyokmkKisyuCheckBtn.disable();
                    // 部門期首残高を確認するボタン非活性
                    this.bmnKisyuCheckBtn.disable();
                }
            } else {
                // 残高インポートボタン非活性
                this.hojyokmkKisyuImportBtn.disable();
                // 補助期首残高を確認するボタン非活性
                this.hojyokmkKisyuCheckBtn.disable();
                // 部門期首残高を確認するボタン非活性
                this.bmnKisyuCheckBtn.disable();
            }
        } else {
            // 残高インポートボタン非活性
            this.hojyokmkKisyuImportBtn.disable();
            // 補助期首残高を確認するボタン非活性
            this.hojyokmkKisyuCheckBtn.disable();
            // 部門期首残高を確認するボタン非活性
            this.bmnKisyuCheckBtn.disable();
        }
        // エラー件数（入力必須件数）を表示する
        this.showHojyoBmnErrCnt(hojyoTabKbn);
    }

    private initSettingTab(): void {
        let hojyoTab = $("#li-hojyo-tab");
        let hojyoPane = $("#hojyo-tab-pane");
        let generalhojyoTab = $("#li-generalhojyo-tab");
        let generalhojyoPane = $("#generalhojyo-tab-pane");
        let bmnTab = $("#li-bmn-tab");
        let bmnPane = $("#bmn-tab-pane");
        let segmentTab = $("#li-segment-tab");
        let segmentPane = $("#segment-tab-pane");
        let koujiTab = $("#li-kouji-tab");
        let koujiPane = $("#kouji-tab-pane");

        // タブの表示
        if (this.m_hojyokmkSettingDatas.length > 0) { hojyoTab.show(); } else { hojyoTab.hide(); }
        if (this.m_generalhojyoSettingDatas.length > 0) { generalhojyoTab.show(); } else { generalhojyoTab.hide(); }
        if (this.m_bmnSettingDatas.length > 0) { bmnTab.show(); } else { bmnTab.hide(); }
        if (this.m_segmentSettingDatas.length > 0) { segmentTab.show(); } else { segmentTab.hide(); }
        if (this.m_koujiSettingDatas.length > 0) { koujiTab.show(); } else { koujiTab.hide(); }

        // タブのアクティブ化、テーブルの表示
        hojyoTab.removeClass("active"); hojyoPane.removeClass("in active show");
        generalhojyoTab.removeClass("active"); generalhojyoPane.removeClass("in active show");
        bmnTab.removeClass("active"); bmnPane.removeClass("in active show");
        segmentTab.removeClass("active"); segmentPane.removeClass("in active show");
        koujiTab.removeClass("active"); koujiPane.removeClass("in active show");

        switch (this.currentTab) {
            case MigrationHojyoTabKbn.Hojyo:
                hojyoTab.addClass("active"); hojyoPane.addClass("in active"); hojyoTab.tab("show");
                break;
            case MigrationHojyoTabKbn.GeneralHojyo:
                generalhojyoTab.addClass("active"); generalhojyoPane.addClass("in active"); generalhojyoTab.tab("show");
                break;
            case MigrationHojyoTabKbn.Bmn:
                bmnTab.addClass("active"); bmnPane.addClass("in active"); bmnTab.tab("show");
                break;
            case MigrationHojyoTabKbn.Segment:
                segmentTab.addClass("active"); segmentPane.addClass("in active"); segmentTab.tab("show");
                break;
            case MigrationHojyoTabKbn.Kouji:
                koujiTab.addClass("active"); koujiPane.addClass("in active"); koujiTab.tab("show");
                break;
        }

        // ボタンの表示・非表示制御
        if (this.currentTab == MigrationHojyoTabKbn.Hojyo) {
            $("#hojyokmkkisyu-check-btn").removeAttr('hidden');     // 補助期首残高を確認するボタン 表示
            $("#bmnkisyu-check-btn").attr('hidden', 'hidden');      // 部門期首残高を確認するボタン 非表示            
        } else if (this.currentTab == MigrationHojyoTabKbn.Bmn) {
            $("#hojyokmkkisyu-check-btn").attr('hidden', 'hidden'); // 補助期首残高を確認するボタン 非表示
            $("#bmnkisyu-check-btn").removeAttr('hidden');          // 部門期首残高を確認するボタン 表示
        } else {
            $("#hojyokmkkisyu-check-btn").attr('hidden', 'hidden'); // 補助期首残高を確認するボタン 非表示
            $("#bmnkisyu-check-btn").attr('hidden', 'hidden');      // 部門期首残高を確認するボタン 非表示
        }
    }

    private initCmbHowRegist(dNo: number, srcHojyoKbn: number, hojyoTabKbn: number): wjInput.ComboBox {
        let id: string = undefined!;
        if (hojyoTabKbn == MigrationHojyoTabKbn.Hojyo) {
            id = `#cmbHowRegist_hojyo${dNo}`;
        } else if (hojyoTabKbn == MigrationHojyoTabKbn.GeneralHojyo) {
            id = `#cmbHowRegist_generalhojyo${dNo}`;
        } else if (hojyoTabKbn == MigrationHojyoTabKbn.Bmn) {
            id = `#cmbHowRegist_bmn${dNo}`;
        } else if (hojyoTabKbn == MigrationHojyoTabKbn.Segment) {
            id = `#cmbHowRegist_segment${dNo}`;
        } else {
            id = `#cmbHowRegist_kouji${dNo}`;
        }

        let formatItem = (sender: any, e: { data: { value: string; }; item: { innerHTML: string; }; }) => {
            if (e.data.value == '登録しない') {
                e.item.innerHTML = `<span class="migration-red">${e.data.value}</span>`;
            } else {
                e.item.innerHTML = `<span class="migration-blue">${e.data.value}</span>として登録`;
            }
        }
        let cmb = this.initWijmoCombBox(id, this.createCmbHowRegistItems(srcHojyoKbn), formatItem);

        cmb.lostFocus.addHandler( (s: { _e: { id: any; }; selectedValue: number; selectedItem: { value: string; }; }) => {
            let id = s._e.id;
            let data: settingHojyoDetailData;
            let dNo: number;
            let cmbSelect: wjInput.ComboBox;

            if (this.currentTab == MigrationHojyoTabKbn.Hojyo) {
                dNo = Number(id.substring(18));
                data = this.m_hojyokmkSettingDatas[dNo];
                cmbSelect = this.input_cmbSelectHojyos[dNo];
            } else if (this.currentTab == MigrationHojyoTabKbn.GeneralHojyo){
                dNo = Number(id.substring(25));
                data = this.m_generalhojyoSettingDatas[dNo];
                cmbSelect = this.input_cmbSelectGeneralHojyos[dNo];
            } else if (this.currentTab == MigrationHojyoTabKbn.Bmn) {
                dNo = Number(id.substring(16));
                data = this.m_bmnSettingDatas[dNo];
                cmbSelect = this.input_cmbSelectBmns[dNo];
            } else if (this.currentTab == MigrationHojyoTabKbn.Segment) {
                dNo = Number(id.substring(20));
                data = this.m_segmentSettingDatas[dNo];
                cmbSelect = this.input_cmbSelectSegments[dNo];
            } else {
                dNo = Number(id.substring(18));
                data = this.m_koujiSettingDatas[dNo];
                cmbSelect = this.input_cmbSelectKoujis[dNo];
            }

            let olddata = MjsUtilities.deepObjectCopy(data);

            if (olddata.HowRegist == s.selectedValue) {
                // 選択内容が変更していない場合は何もしない
                return;
            }

            data.IsEdited = true; // 編集済み

            if (s.selectedItem.value == "登録しない") {
                $(`#${id} input`).addClass("migration-red");

                // データセット
                this.setCodeAndMappingKbnAndIsRequiredCaseOfNotRegist(data);
                data.HowRegist = -1;
            } else {
                $(`#${id} input`).removeClass("migration-red");
                data.HowRegist = s.selectedValue;

                cmbSelect.itemsSource = this.createCmbSelectHojyoItems(data);

                // AI監査支援の補助選択をデフォルトにする
                cmbSelect.selectedValue = "デフォルト";
                $(`#${cmbSelect.hostElement.id} input`).removeClass("migration-blue");

                // データセット
                this.setCodeAndMappingKbnAndIsRequiredCaseOfDefault(data);
            }

            if (hojyoTabKbn == MigrationHojyoTabKbn.Hojyo || hojyoTabKbn == MigrationHojyoTabKbn.GeneralHojyo) {
                let success = (data: MigrationHojyoSettingViewModel, textStatus: string, jqXHR: JQueryXHR) => {
                    for (let hojyoGrp of data.HojyoGroupList) {
                        if (hojyoGrp.HojyoTabKbn == MigrationHojyoTabKbn.Hojyo) {
                            this.$timeout(() => {
                                for (let masterByAccount of hojyoGrp.HojyoList) {
                                    let hojyodetail = this.m_hojyokmkSettingDatas.singleOrNull((n: { SrcKmkCode: string; SrcHojyoCode: string; SrcHojyoKbn: number; }) => n.SrcKmkCode == masterByAccount.SrcKmkCode && n.SrcHojyoCode == masterByAccount.SrcHojyoCode && n.SrcHojyoKbn == masterByAccount.SrcHojyoKbn);
                                    if (hojyodetail != null) {
                                        hojyodetail.AuxiliaryAccountCanAdoptedKbn = masterByAccount.AuxiliaryAccountCanAdoptedKbn;

                                        // AI監査支援の補助選択を表示/非表示する
                                        this.showCmbSelectHojyo(hojyodetail, MigrationHojyoTabKbn.Hojyo);

                                        // 期首残高を活性/非活性する
                                        this.setEnableInpHojyoSumAndShowTooltip(hojyodetail);

                                        // AI監査支援の補助選択を活性/非活性する
                                        this.setEnableCmbSelectHojyoAndShowTooltip(hojyodetail, MigrationHojyoTabKbn.Hojyo);

                                        // 重複の場合、ツールチップを表示する
                                        this.showTooltipHojyoDup(MigrationHojyoTabKbn.Hojyo, olddata, hojyodetail);
                                        this.showTooltipHojyoSameRegist(MigrationHojyoTabKbn.Hojyo, olddata, hojyodetail);
                                    }
                                }

                                // エラー件数（入力必須件数）を表示する
                                this.showHojyoBmnErrCnt(MigrationHojyoTabKbn.Hojyo);
                            });
                        } else if (hojyoGrp.HojyoTabKbn == MigrationHojyoTabKbn.GeneralHojyo) {
                            this.$timeout(() => {
                                for (let masterByAccount of hojyoGrp.HojyoList) {
                                    let hojyodetail = this.m_generalhojyoSettingDatas.singleOrNull((n: { SrcKmkCode: string; SrcHojyoCode: string; SrcHojyoKbn: number; }) => n.SrcKmkCode == masterByAccount.SrcKmkCode && n.SrcHojyoCode == masterByAccount.SrcHojyoCode && n.SrcHojyoKbn == masterByAccount.SrcHojyoKbn);
                                    if (hojyodetail != null) {
                                        hojyodetail.AuxiliaryAccountCanAdoptedKbn = masterByAccount.AuxiliaryAccountCanAdoptedKbn;

                                        // AI監査支援の補助選択を表示/非表示する
                                        this.showCmbSelectHojyo(hojyodetail, MigrationHojyoTabKbn.GeneralHojyo);

                                        // AI監査支援の補助選択を活性/非活性する
                                        this.setEnableCmbSelectHojyoAndShowTooltip(hojyodetail, MigrationHojyoTabKbn.GeneralHojyo);

                                        // 重複の場合、ツールチップを表示する
                                        this.showTooltipHojyoDup(MigrationHojyoTabKbn.GeneralHojyo, olddata, hojyodetail);
                                        this.showTooltipHojyoSameRegist(MigrationHojyoTabKbn.GeneralHojyo, olddata, hojyodetail);
                                    }
                                }

                                // エラー件数（入力必須件数）を表示する
                                this.showHojyoBmnErrCnt(MigrationHojyoTabKbn.GeneralHojyo);
                            });
                        }
                    }
                };

                let complete = (jqXHR: JQueryXHR, textStatus: string) => { };
                let error = (data: any, jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => { };
                let targetHojyoList = this.m_hojyokmkSettingDatas.where(n => n.DstKmkCode == data.DstKmkCode);
                let targetGeneralHojyoList = this.m_generalhojyoSettingDatas.where(n => n.DstKmkCode == data.DstKmkCode);
                let modelHojyoList: MigrationSettingDetailViewModel[] = [];
                for (let detail of targetHojyoList) {
                    modelHojyoList.push(this.convertFromHojyokmkSettingDatas(detail, this.m_HojyoSettingHojyoList));
                }
                let modelGeneralHojyoList: MigrationSettingDetailViewModel[] = [];
                for (let detail of targetGeneralHojyoList) {
                    modelGeneralHojyoList.push(this.convertFromHojyokmkSettingDatas(detail, this.m_HojyoSettingGeneralHojyoList));
                }
                let model = new MigrationHojyoSettingViewModel();
                model.HojyoGroupList = [
                    { HojyoTabKbn: MigrationHojyoTabKbn.Hojyo, HojyoGroupName: "補助科目", HojyoList: modelHojyoList, ImportErrLogFileName: "", OpeningBalanceImportErrDetailList: [] },
                    { HojyoTabKbn: MigrationHojyoTabKbn.GeneralHojyo, HojyoGroupName: "汎用補助", HojyoList: modelGeneralHojyoList, ImportErrLogFileName: "", OpeningBalanceImportErrDetailList: [] }
                ];
                let settings: AjaxSettings = {
                    url: "/api/v1/ProcessMigration/CheckMasterByAccount",
                    type: "POST",
                    data: model,
                    showBusyIndicator: false,
                    beforeSend: null!,
                    success: success,
                    complete: complete,
                    error: error
                };

                // 科目別マスタ採用チェック
                Ajax.perform(settings);
            } else {
                // AI監査支援の補助選択を表示/非表示する
                this.showCmbSelectHojyo(data, this.currentTab);

                // 重複の場合、ツールチップを表示する
                this.showTooltipHojyoDup(this.currentTab, olddata, data);
                this.showTooltipHojyoSameRegist(this.currentTab, olddata, data);

                // エラー件数（入力必須件数）を表示する
                this.showHojyoBmnErrCnt(this.currentTab);
            }

            // 編集中（未保存）
            if (this.currentTab == MigrationHojyoTabKbn.Hojyo) {
                this.visibleBtnSuccessHojyoKmkSettingSave(false);
            } else if (this.currentTab == MigrationHojyoTabKbn.GeneralHojyo) {
                this.visibleBtnSuccessGeneralHojyoSettingSave(false);
            } else if (this.currentTab == MigrationHojyoTabKbn.Bmn) {
                this.visibleBtnSuccessBmnSettingSave(false);
            } else if (this.currentTab == MigrationHojyoTabKbn.Segment) {
                this.visibleBtnSuccessSegmentSettingSave(false);
            } else if (this.currentTab == MigrationHojyoTabKbn.Kouji) {
                this.visibleBtnSuccessKoujiSettingSave(false);
            }
        });

        if (hojyoTabKbn == MigrationHojyoTabKbn.Hojyo) {
            this.input_cmbHowRegistHojyos[dNo] = cmb;
        } else if (hojyoTabKbn == MigrationHojyoTabKbn.GeneralHojyo) {
            this.input_cmbHowRegistGeneralHojyos[dNo] = cmb;
        } else if (hojyoTabKbn == MigrationHojyoTabKbn.Bmn) {
            this.input_cmbHowRegistBmns[dNo] = cmb;
        } else if (hojyoTabKbn == MigrationHojyoTabKbn.Segment) {
            this.input_cmbHowRegistSegments[dNo] = cmb;
        } else if (hojyoTabKbn == MigrationHojyoTabKbn.Kouji) {
            this.input_cmbHowRegistKoujis[dNo] = cmb;
        }

        return cmb;
    }

    private setEnableCmbSelectHojyoAndShowTooltip(data: settingHojyoDetailData, hojyoTabKbn: number): void {
        let cmbSelect: wjInput.ComboBox = this.input_cmbSelectHojyos[data.DetailNo];
        let tooltipNotKmkId: string;
        let tooltipMasterByAccountId: string = undefined!;
        if (hojyoTabKbn == MigrationHojyoTabKbn.Hojyo) {
            cmbSelect = this.input_cmbSelectHojyos[data.DetailNo];
            tooltipNotKmkId = `#tooltipHojyoNotKmk${data.DetailNo}`;
            tooltipMasterByAccountId = `#tooltipMasterByAccount${data.DetailNo}`
        } else if (hojyoTabKbn == MigrationHojyoTabKbn.GeneralHojyo) {
            cmbSelect = this.input_cmbSelectGeneralHojyos[data.DetailNo];
            tooltipNotKmkId = `#tooltipGeneralHojyoNotKmk${data.DetailNo}`;
            tooltipMasterByAccountId = `#tooltipGeneralHojyoMasterByAccount${data.DetailNo}`
        }

        let isDisabled = cmbSelect.isDisabled;

        // 科目の対応付けがされていない補助の場合
        let isNotKmk = data.HowRegist != HojyoKbn.Bmn && data.HowRegist != HojyoKbn.Segment1 && data.HowRegist != HojyoKbn.Koji && data.MappingSettingKbn != MigrationMappingSettingKbn.NotRegister && data.DstKmkCode == null;

        // 科目に補助が採用できない場合(登録しない場合は除く)
        let isNotMasterByAccount = data.MappingSettingKbn != MigrationMappingSettingKbn.NotRegister && data.AuxiliaryAccountCanAdoptedKbn != AuxiliaryAccountCanAdoptedKbn.Normal;

        if (isNotKmk || isNotMasterByAccount) {
            isDisabled = true;
        } else {
            isDisabled = false;
        }

        if (isNotKmk) {
            $(tooltipNotKmkId!).removeClass('dp_none');
        } else {
            $(tooltipNotKmkId!).addClass('dp_none');
        }

        if (isNotMasterByAccount) {
            let title: string;
            switch (data.AuxiliaryAccountCanAdoptedKbn) {
                case AuxiliaryAccountCanAdoptedKbn.ExceedNumberAdopted:
                    title = "勘定科目単位の採用範囲を超えています。科目採用区分を変更する事はできません。";
                    break;
                case AuxiliaryAccountCanAdoptedKbn.NoCombinationOfSubKmkAndKoji:
                    title = "科目別補助と工事の併用はできません。";
                    break;
                case AuxiliaryAccountCanAdoptedKbn.NoCombinationOfCommonHojyoAndKoji:
                    title = "補助と工事の併用はできません。";
                    break;
                case AuxiliaryAccountCanAdoptedKbn.SpecialAccountCanNotadoptedForSubKmk:
                    title = "特殊科目に科目別補助は採用できません。科目採用区分を変更する事はできません。";
                    break;
                case AuxiliaryAccountCanAdoptedKbn.SameCommonAdoptionForClient:
                    title = "同時に取引先、得意先、仕入先を採用することはできません。";
                    break;
                case AuxiliaryAccountCanAdoptedKbn.NoCombinationOfBankCodeKmkAndBank:
                    title = "勘定科目に銀行コードが設定されているため、銀行を採用することはできません。";
                    break;
            }
            $(tooltipMasterByAccountId + ` > span`).attr('title', title!).tooltip('dispose').tooltip(ProcessMigration.TOOLTIP_OPTIONS).off("show.bs.tooltip").on("show.bs.tooltip", this.onShowToolTip.bind(this));
            $(tooltipMasterByAccountId!).removeClass('dp_none');
        } else {
            $(tooltipMasterByAccountId!).addClass('dp_none');
        }

        if (isDisabled && cmbSelect.containsFocus()) {
            this.mediator.setFocusToNextItem();
        }

        cmbSelect.isDisabled = isDisabled;
    }

    private setEnableInpHojyoSumAndShowTooltip(data: settingHojyoDetailData): void {
        let inpSum = this.input_inpHojyoSums[data.DetailNo];
        if (this.m_HojyoSetting.KisyuEnable) {
            // 期首残高インポート済み
            let enabled = inpSum.enabled;

            // AI監査支援での登録方法「登録しない」の場合
            let isNotRegister = data.MappingSettingKbn == MigrationMappingSettingKbn.NotRegister;

            // 期首残区分がなしの場合(登録しない場合は除く)
            let kmkMa = this.kmkMaKmkDataCache.singleOrNull((n: { GCode: number; }) => n.GCode == data.DstKmkCode);
            let isNotKisyuKbn = !isNotRegister && kmkMa != null && kmkMa.KisyuKbn != UseKbn.Setting;

            // 科目の対応付けがされていない補助の場合(登録しない場合は除く)
            let isNotKmk = !isNotRegister && data.HowRegist != HojyoKbn.Bmn && data.HowRegist != HojyoKbn.Segment1 && data.HowRegist != HojyoKbn.Koji && data.DstKmkCode == null;

            // 科目に補助が採用できない場合(登録しない場合は除く)
            let isNotMasterByAccount = !isNotRegister && data.AuxiliaryAccountCanAdoptedKbn != AuxiliaryAccountCanAdoptedKbn.Normal;

            if (isNotRegister || isNotKisyuKbn || isNotKmk || isNotMasterByAccount) {
                enabled = false;
            } else {
                enabled = true;
            }

            if (isNotRegister) {
                inpSum.setText("");
            } else {
                inpSum.setValue(data.OpeningBalance);
            }

            if (isNotKisyuKbn && kmkMa.BPKbn == BPKbn.BS && kmkMa.SumAnaCode != SumAnaCode.ManufacturingCost) {
                $(`#tooltipHojyoKisyu${data.DetailNo}`).removeClass('dp_none');
            } else {
                $(`#tooltipHojyoKisyu${data.DetailNo}`).addClass('dp_none');
            }

            if (!enabled && inpSum.inputControl.containsFocus()) {
                this.mediator.setFocusToNextItem();
            }

            inpSum.enabled = enabled;
        } else {
            inpSum.setText("");
            inpSum.enabled = false;
        }
    }

    private setCodeAndMappingKbnAndIsRequiredCaseOfNotRegist(data: settingHojyoDetailData): void {
        data.MappingSettingKbn = MigrationMappingSettingKbn.NotRegister;
        data.DstKmkHojyoCode = null!;
        data.DstHojyoCode = null!;
    }

    private setCodeAndMappingKbnAndIsRequiredCaseOfNewSundry(data: settingHojyoDetailData): void {
        data.MappingSettingKbn = MigrationMappingSettingKbn.NewSundry;

        if (data.HowRegist == HojyoKbn.EachKmkAuxiliary) {
            data.DstKmkHojyoCode = null!;
        } else {
            data.DstHojyoCode = null!;
        }
    }

    private setCodeAndMappingKbnAndIsRequiredCaseOfDefault(data: settingHojyoDetailData): void {
        data.MappingSettingKbn = MigrationMappingSettingKbn.Unselected;

        if (data.HowRegist == HojyoKbn.EachKmkAuxiliary) {
            data.DstKmkHojyoCode = null!;
        } else {
            data.DstHojyoCode = null!;
        }
    }

    private setCodeAndMappingKbnAndIsRequiredCaseOfNew(data: settingHojyoDetailData): void {
        data.MappingSettingKbn = MigrationMappingSettingKbn.NewRegister;

        // サーバーで採番するのでクライアントはnull
        if (data.HowRegist == HojyoKbn.EachKmkAuxiliary) {
            data.DstKmkHojyoCode = null!;
        } else {
            data.DstHojyoCode = null!;
        }
    }

    private setCodeAndMappingKbnAndIsRequiredCaseOfMapping(data: settingHojyoDetailData, code: any): void {
        data.MappingSettingKbn = MigrationMappingSettingKbn.Mapped;

        if (data.HowRegist == HojyoKbn.EachKmkAuxiliary) {
            data.DstKmkHojyoCode = code;
        } else {
            data.DstHojyoCode = this.getOriginalCode(code, data.HowRegist);
        }
    }

    private showCmbSelectHojyo(data: settingHojyoDetailData, hojyoTabKbn: number): void {
        let id: string;
        if (hojyoTabKbn == MigrationHojyoTabKbn.Hojyo) {
            id = `#cmbSelectHojyo_hojyo${data.DetailNo}`;
        } else if (hojyoTabKbn == MigrationHojyoTabKbn.GeneralHojyo) {
            id = `#cmbSelectHojyo_generalhojyo${data.DetailNo}`;
        } else if (hojyoTabKbn == MigrationHojyoTabKbn.Bmn) {
            id = `#cmbSelectHojyo_bmn${data.DetailNo}`;
        } else if (hojyoTabKbn == MigrationHojyoTabKbn.Segment) {
            id = `#cmbSelectHojyo_segment${data.DetailNo}`;
        } else {
            id = `#cmbSelectHojyo_kouji${data.DetailNo}`;
        }

        if (data.MappingSettingKbn != MigrationMappingSettingKbn.NotRegister) {
            $(id).show();
        } else {
            $(id).hide();
        }
    }

    private showTooltipHojyoSameRegist(hojyoTabKbn: number, oldhojyo: settingHojyoDetailData, hojyo: settingHojyoDetailData): void {
        if (oldhojyo != null) {
            let oldduphojyo = this.getDupHojyoRegistList(hojyoTabKbn, oldhojyo.DstKmkCode, oldhojyo.SrcHojyoName, oldhojyo.SrcHojyoRenChar, oldhojyo.HowRegist, oldhojyo.MappingSettingKbn);
            if (oldduphojyo.length == 1) {
                if (hojyoTabKbn == MigrationHojyoTabKbn.Hojyo) {
                    $(`#tooltipHojyoSameRegist${oldduphojyo[0].DetailNo}`).addClass('dp_none');
                } else if (hojyoTabKbn == MigrationHojyoTabKbn.GeneralHojyo) {
                    $(`#tooltipGeneralHojyoSameRegist${oldduphojyo[0].DetailNo}`).addClass('dp_none');
                }
            }
        }

        let duphojyo = this.getDupHojyoRegistList(hojyoTabKbn, hojyo.DstKmkCode, hojyo.SrcHojyoName, hojyo.SrcHojyoRenChar, hojyo.HowRegist, hojyo.MappingSettingKbn);
        if (duphojyo.length > 1) {
            for (let dup of duphojyo) {
                if (hojyoTabKbn == MigrationHojyoTabKbn.Hojyo) {
                    $(`#tooltipHojyoSameRegist${dup.DetailNo}`).removeClass('dp_none');
                } else if (hojyoTabKbn == MigrationHojyoTabKbn.GeneralHojyo) {
                    $(`#tooltipGeneralHojyoSameRegist${dup.DetailNo}`).removeClass('dp_none');
                }
            }
        } else {
            if (hojyoTabKbn == MigrationHojyoTabKbn.Hojyo) {
                $(`#tooltipHojyoSameRegist${hojyo.DetailNo}`).addClass('dp_none');
            } else if (hojyoTabKbn == MigrationHojyoTabKbn.GeneralHojyo) {
                $(`#tooltipGeneralHojyoSameRegist${hojyo.DetailNo}`).addClass('dp_none');
            }
        }
    }

    private getDupHojyoRegistList(hojyoTabKbn: number, kmkCode: number, hojyoName: string, hojyoRenChar: string, howRegist: number, mappingKbn: number): settingHojyoDetailData[] {
        if (hojyoName == null) {
            return [];
        }

        if (mappingKbn != MigrationMappingSettingKbn.NewRegister) {
            return [];
        }

        let settingDatas: settingHojyoDetailData[] = [];
        if (hojyoTabKbn == MigrationHojyoTabKbn.Hojyo) {
            settingDatas = this.m_hojyokmkSettingDatas;
        } else if (hojyoTabKbn == MigrationHojyoTabKbn.GeneralHojyo) {
            settingDatas = this.m_generalhojyoSettingDatas;
        }

        let dupList: settingHojyoDetailData[] = [];
        for (let detail of settingDatas) {
            if (detail.SrcHojyoName == hojyoName && detail.SrcHojyoRenChar == hojyoRenChar && detail.AuxiliaryAccountCanAdoptedKbn == AuxiliaryAccountCanAdoptedKbn.Normal && detail.HowRegist == howRegist && detail.MappingSettingKbn == MigrationMappingSettingKbn.NewRegister) {
                if (detail.HowRegist == MasterKbn.EachKmkAuxiliary) {
                    if (detail.DstKmkCode == kmkCode) {
                        dupList.push(detail);
                    }
                } else {
                    dupList.push(detail);
                }
            }
        }

        return dupList;
    }

    private showTooltipHojyoDup(hojyoTabKbn: number, oldhojyo: settingHojyoDetailData, hojyo: settingHojyoDetailData): void {
        if (oldhojyo != null) {
            let oldduphojyo = this.getDupHojyoList(hojyoTabKbn, oldhojyo.DstKmkCode, oldhojyo.HowRegist, oldhojyo.DstKmkHojyoCode, oldhojyo.DstHojyoCode);
            if (oldduphojyo.length == 1) {
                if (hojyoTabKbn == MigrationHojyoTabKbn.Hojyo) {
                    $(`#tooltipHojyoDup${oldduphojyo[0].DetailNo}`).addClass('dp_none');
                } else if (hojyoTabKbn == MigrationHojyoTabKbn.GeneralHojyo) {
                    $(`#tooltipGeneralHojyoDup${oldduphojyo[0].DetailNo}`).addClass('dp_none');
                }
            }
        }

        let duphojyo = this.getDupHojyoList(hojyoTabKbn, hojyo.DstKmkCode, hojyo.HowRegist, hojyo.DstKmkHojyoCode, hojyo.DstHojyoCode);
        if (duphojyo.length > 1) {
            for (let dup of duphojyo) {
                if (hojyoTabKbn == MigrationHojyoTabKbn.Hojyo) {
                    $(`#tooltipHojyoDup${dup.DetailNo}`).removeClass('dp_none');
                } else if (hojyoTabKbn == MigrationHojyoTabKbn.GeneralHojyo) {
                    $(`#tooltipGeneralHojyoDup${dup.DetailNo}`).removeClass('dp_none');
                }
            }
        } else {
            if (hojyoTabKbn == MigrationHojyoTabKbn.Hojyo) {
                $(`#tooltipHojyoDup${hojyo.DetailNo}`).addClass('dp_none');
            } else if (hojyoTabKbn == MigrationHojyoTabKbn.GeneralHojyo) {
                $(`#tooltipGeneralHojyoDup${hojyo.DetailNo}`).addClass('dp_none');
            }
        }
    }

    private getDupHojyoList(hojyoTabKbn: number, kmkCode: number, howRegist: number, hojyoKmkCode: number, hojyoCode: string): settingHojyoDetailData[] {
        if (kmkCode == null ) {
            return [];
        }

        if (howRegist == MasterKbn.EachKmkAuxiliary) {
            if (hojyoKmkCode == null) {
                return [];
            }
        } else {
            if (hojyoCode == null) {
                return [];
            }
        }

        let settingDatas: settingHojyoDetailData[] = [];
        if (hojyoTabKbn == MigrationHojyoTabKbn.Hojyo) {
            settingDatas = this.m_hojyokmkSettingDatas;
        } else if (hojyoTabKbn == MigrationHojyoTabKbn.GeneralHojyo){
            settingDatas = this.m_generalhojyoSettingDatas;
        }

        let dupList: settingHojyoDetailData[] = [];
        for (let detail of settingDatas) {
            if (detail.DstKmkCode == kmkCode && detail.AuxiliaryAccountCanAdoptedKbn == AuxiliaryAccountCanAdoptedKbn.Normal && detail.HowRegist == howRegist) {
                if (howRegist == MasterKbn.EachKmkAuxiliary) {
                    if (detail.DstKmkHojyoCode == hojyoKmkCode) {
                        dupList.push(detail);
                    }
                } else {
                    if (detail.DstHojyoCode == hojyoCode) {
                        dupList.push(detail);
                    }
                }
            }
        }

        return dupList;
    }

    private showHojyoBmnErrCnt(hojyoTabKbn: number): void {
        this.$timeout(() => {
            let cntErr: number = 0;
            let selectcmbid = "";
            let settingDatas: settingHojyoDetailData[] = [];
            if (hojyoTabKbn == MigrationHojyoTabKbn.Hojyo) {
                settingDatas = this.m_hojyokmkSettingDatas;
                selectcmbid = "#cmbSelectHojyo_hojyo";
            } else if (hojyoTabKbn == MigrationHojyoTabKbn.GeneralHojyo) {
                settingDatas = this.m_generalhojyoSettingDatas;
                selectcmbid = "#cmbSelectHojyo_generalhojyo";
            } else if (hojyoTabKbn == MigrationHojyoTabKbn.Bmn) {
                settingDatas = this.m_bmnSettingDatas;
                selectcmbid = "#cmbSelectHojyo_bmn";
            } else if (hojyoTabKbn == MigrationHojyoTabKbn.Segment) {
                settingDatas = this.m_segmentSettingDatas;
                selectcmbid = "#cmbSelectHojyo_segment";
            } else if (hojyoTabKbn == MigrationHojyoTabKbn.Kouji) {
                settingDatas = this.m_koujiSettingDatas;
                selectcmbid = "#cmbSelectHojyo_kouji";
            }

            for (let detail of settingDatas) {
                if (hojyoTabKbn == MigrationHojyoTabKbn.Hojyo) {
                    if (detail.MappingSettingKbn != MigrationMappingSettingKbn.NotRegister) {
                        if (!$(`#tooltipMasterByAccount${detail.DetailNo}`).hasClass('dp_none') ||
                            !$(`#tooltipHojyoDup${detail.DetailNo}`).hasClass('dp_none') ||
                            (detail.MappingSettingKbn == MigrationMappingSettingKbn.Unselected && !this.input_cmbSelectHojyos[detail.DetailNo].isDisabled)) {
                            cntErr++;
                            $(`${selectcmbid}${detail.DetailNo} .wj-input`).addClass('easy-input-required');
                        } else {
                            $(`${selectcmbid}${detail.DetailNo} .wj-input`).removeClass('easy-input-required');
                        }
                    } else {
                        $(`${selectcmbid}${detail.DetailNo} .wj-input`).removeClass('easy-input-required');
                    }
                } else if (hojyoTabKbn == MigrationHojyoTabKbn.GeneralHojyo) {
                    if (detail.MappingSettingKbn != MigrationMappingSettingKbn.NotRegister) {
                        if (!$(`#tooltipGeneralHojyoMasterByAccount${detail.DetailNo}`).hasClass('dp_none') ||
                            !$(`#tooltipGeneralHojyoDup${detail.DetailNo}`).hasClass('dp_none') ||
                            (detail.MappingSettingKbn == MigrationMappingSettingKbn.Unselected && !this.input_cmbSelectGeneralHojyos[detail.DetailNo].isDisabled)) {
                            cntErr++;
                            $(`${selectcmbid}${detail.DetailNo} .wj-input`).addClass('easy-input-required');
                        } else {
                            $(`${selectcmbid}${detail.DetailNo} .wj-input`).removeClass('easy-input-required');
                        } 
                    } else {
                        $(`${selectcmbid}${detail.DetailNo} .wj-input`).removeClass('easy-input-required');
                    }
                } else {
                    if (detail.MappingSettingKbn == MigrationMappingSettingKbn.Unselected) {
                        cntErr++;
                        $(`${selectcmbid}${detail.DetailNo} .wj-input`).addClass('easy-input-required');
                    } else {
                        $(`${selectcmbid}${detail.DetailNo} .wj-input`).removeClass('easy-input-required');
                    }
                }
            }

            if (hojyoTabKbn == MigrationHojyoTabKbn.Hojyo) {
                this.$scope.hojyoErrCnt = cntErr;
            } else if (hojyoTabKbn == MigrationHojyoTabKbn.GeneralHojyo) {
                this.$scope.generalhojyoErrCnt = cntErr;
            } else if (hojyoTabKbn == MigrationHojyoTabKbn.Bmn) {
                this.$scope.bmnErrCnt = cntErr;
            } else if (hojyoTabKbn == MigrationHojyoTabKbn.Segment) {
                this.$scope.segmentErrCnt = cntErr;
            } else if (hojyoTabKbn == MigrationHojyoTabKbn.Kouji) {
                this.$scope.koujiErrCnt = cntErr;
            }

            if (this.$scope.hojyoErrCnt > 0 || this.$scope.generalhojyoErrCnt > 0 || this.$scope.bmnErrCnt > 0 || this.$scope.segmentErrCnt > 0 || this.$scope.koujiErrCnt > 0) {
                this.hojyokmkSettingSaveBtn.disable();
                let msgList = [];
                if (this.$scope.hojyoErrCnt > 0) { msgList.push("補助科目"); }
                if (this.$scope.generalhojyoErrCnt > 0) { msgList.push("汎用補助"); }
                if (this.$scope.bmnErrCnt > 0) { msgList.push("部門"); }
                if (this.$scope.segmentErrCnt > 0) { msgList.push("セグメント"); }
                if (this.$scope.koujiErrCnt > 0) { msgList.push("工事"); }
                let msg = msgList.join("、");
                this.$scope.settingErr = msg;
                $("#setting-errmsg").removeClass("dp_none");
            } else {
                this.hojyokmkSettingSaveBtn.enable();
                $("#setting-errmsg").addClass("dp_none");
            }
        });
    }

    private initCmbSelectHojyo(dNo: number, hojyoTabKbn: number): wjInput.ComboBox {
        let id: string = undefined!;
        if (hojyoTabKbn == MigrationHojyoTabKbn.Hojyo) {
            id = `#cmbSelectHojyo_hojyo${dNo}`;
        } else if (hojyoTabKbn == MigrationHojyoTabKbn.GeneralHojyo) {
            id = `#cmbSelectHojyo_generalhojyo${dNo}`;
        } else if (hojyoTabKbn == MigrationHojyoTabKbn.Bmn) {
            id = `#cmbSelectHojyo_bmn${dNo}`;
        } else if (hojyoTabKbn == MigrationHojyoTabKbn.Segment) {
            id = `#cmbSelectHojyo_segment${dNo}`;
        } else {
            id = `#cmbSelectHojyo_kouji${dNo}`;
        }

        let formatItem = (sender: any, e: { data: { value: string; }; item: { innerHTML: string; }; }) => {
            if (e.data.value == "新規登録") {
                e.item.innerHTML = `<span class="migration-blue">${e.data.value}</span>`;
            }
        }

        let cmb = this.initWijmoCombBox(id, [], formatItem);

        cmb.lostFocus.addHandler( (s: { _e: { id: any; }; selectedValue: string | number; }) => {
            const id = s._e.id;
            let data: settingHojyoDetailData;

            if (this.currentTab == MigrationHojyoTabKbn.Hojyo) {
                let i = Number(id.substring(20));
                data = this.m_hojyokmkSettingDatas[i];
            } else if (this.currentTab == MigrationHojyoTabKbn.GeneralHojyo) {
                let i = Number(id.substring(27));
                data = this.m_generalhojyoSettingDatas[i];
            } else if (this.currentTab == MigrationHojyoTabKbn.Bmn) {
                let i = Number(id.substring(18));
                data = this.m_bmnSettingDatas[i];
            } else if (this.currentTab == MigrationHojyoTabKbn.Segment) {
                let i = Number(id.substring(22));
                data = this.m_segmentSettingDatas[i];
            } else {
                let i = Number(id.substring(20));
                data = this.m_koujiSettingDatas[i];
            }

            let olddata = MjsUtilities.deepObjectCopy(data);

            if ((olddata.MappingSettingKbn == MigrationMappingSettingKbn.NewRegister && s.selectedValue == "新規登録") ||
                (olddata.MappingSettingKbn == MigrationMappingSettingKbn.NewSundry && s.selectedValue == "諸口") ||
                (olddata.MappingSettingKbn == MigrationMappingSettingKbn.Unselected && s.selectedValue == "デフォルト") ||
                (data.HowRegist != HojyoKbn.EachKmkAuxiliary && this.getTemporaryCode(data.DstHojyoCode, data.HowRegist) == s.selectedValue) ||
                (data.HowRegist == HojyoKbn.EachKmkAuxiliary && data.DstKmkHojyoCode == s.selectedValue)) {
                // 選択内容が変更していない場合は何もしない
                return;
            }

            if (data.HowRegist == -1) {
                // 登録しない場合は何もしない （コンボボックスを非表示するより早くにフォーカスを受けることがあるため対策）
                return;
            }

            data.IsEdited = true; // 編集済み

            if (s.selectedValue == "新規登録") {
                $(`#${id} input`).addClass("migration-blue");

                // データセット
                this.setCodeAndMappingKbnAndIsRequiredCaseOfNew(data);
            } else {
                $(`#${id} input`).removeClass("migration-blue");

                if (s.selectedValue == "デフォルト") {
                    // データセット
                    this.setCodeAndMappingKbnAndIsRequiredCaseOfDefault(data);
                } else if(s.selectedValue == "諸口") {
                    // データセット
                    this.setCodeAndMappingKbnAndIsRequiredCaseOfNewSundry(data);
                } else {
                    // データセット
                    this.setCodeAndMappingKbnAndIsRequiredCaseOfMapping(data, s.selectedValue);
                }
            }

            // 重複の場合、ツールチップを表示する
            this.showTooltipHojyoDup(this.currentTab, olddata, data);
            this.showTooltipHojyoSameRegist(this.currentTab, olddata, data);

            // エラー件数（入力必須件数）を表示する
            this.showHojyoBmnErrCnt(this.currentTab);

            // 編集中（未保存）
            if (this.currentTab == MigrationHojyoTabKbn.Hojyo) {
                this.visibleBtnSuccessHojyoKmkSettingSave(false);
            } else if (this.currentTab == MigrationHojyoTabKbn.GeneralHojyo) {
                this.visibleBtnSuccessGeneralHojyoSettingSave(false);
            } else if (this.currentTab == MigrationHojyoTabKbn.Bmn) {
                this.visibleBtnSuccessBmnSettingSave(false);
            } else if (this.currentTab == MigrationHojyoTabKbn.Segment) {
                this.visibleBtnSuccessSegmentSettingSave(false);
            } else if (this.currentTab == MigrationHojyoTabKbn.Kouji) {
                this.visibleBtnSuccessKoujiSettingSave(false);
            }
        });

        if (hojyoTabKbn == MigrationHojyoTabKbn.Hojyo) {
            this.input_cmbSelectHojyos[dNo] = cmb;
        } else if(hojyoTabKbn == MigrationHojyoTabKbn.GeneralHojyo) {
            this.input_cmbSelectGeneralHojyos[dNo] = cmb;
        } else if (hojyoTabKbn == MigrationHojyoTabKbn.Bmn) {
            this.input_cmbSelectBmns[dNo] = cmb;
        } else if (hojyoTabKbn == MigrationHojyoTabKbn.Segment) {
            this.input_cmbSelectSegments[dNo] = cmb;
        } else if (hojyoTabKbn == MigrationHojyoTabKbn.Kouji) {
            this.input_cmbSelectKoujis[dNo] = cmb;
        }

        return cmb;
    }

    private initInpHojyoSum(dNo: number): void {
        const id = `inpHojyoSum${dNo}`;

        let inp = this.initCurrencyInput(id);

        this.input_inpHojyoSums[dNo] = inp;
    }

    private createCmbCloudItems(fileType: number): any {
        let items: any = [];

        if (fileType == MigrationFileType.Zan) {
            items = [
                { "key": MigrationKisyuCloudItem.None, "value": "" },
                { "key": MigrationKisyuCloudItem.KmkName, "value": "勘定科目名称*" },
                { "key": MigrationKisyuCloudItem.BeginningBalance, "value": "残高金額*" },
            ];
        } else if (fileType == MigrationFileType.HojyoZan) {
            items = [
                { "key": MigrationHojyoKisyuCloudItem.None, "value": "" },
                { "key": MigrationHojyoKisyuCloudItem.KmkName, "value": "勘定科目名称*" },
                { "key": MigrationHojyoKisyuCloudItem.HojyoKbn, "value": "補助種類*" },
                { "key": MigrationHojyoKisyuCloudItem.HojyoName, "value": "補助科目名称*" },
                { "key": MigrationHojyoKisyuCloudItem.BeginningBalance, "value": "残高金額*" },
            ];
        } else if (fileType == MigrationFileType.BmnZan) {
            items = [
                { "key": MigrationBmnKisyuCloudItem.None, "value": "" },
                { "key": MigrationBmnKisyuCloudItem.KmkName, "value": "勘定科目名称*" },
                { "key": MigrationBmnKisyuCloudItem.BmnName, "value": "部門名称*" },
                { "key": MigrationBmnKisyuCloudItem.BeginningBalance, "value": "残高金額*" },
            ];
        } else if (fileType == MigrationFileType.Swk) {
            items = [
                { "key": MigrationSwkCloudItem.None, "value": "" },
                { "key": MigrationSwkCloudItem.DenDate, "value": "日付*" },
                { "key": MigrationSwkCloudItem.Nmonth, "value": "決算仕訳区分" },
                { "key": MigrationSwkCloudItem.DenNo, "value": "伝票NO" },
                { "key": MigrationSwkCloudItem.DKmkName, "value": "借方科目*" },
                { "key": MigrationSwkCloudItem.DHojyoName, "value": "借方科目別補助" },
                { "key": MigrationSwkCloudItem.DBmnName, "value": "借方部門" },
                { "key": MigrationSwkCloudItem.DSegmentName, "value": "借方セグメント" },
                { "key": MigrationSwkCloudItem.DBankName, "value": "借方銀行" },
                { "key": MigrationSwkCloudItem.DClientName, "value": "借方取引先" },
                { "key": MigrationSwkCloudItem.DStaffName, "value": "借方社員" },
                { "key": MigrationSwkCloudItem.DGeneralHojyo1Name, "value": "借方汎用補助１" },
                { "key": MigrationSwkCloudItem.DGeneralHojyo2Name, "value": "借方汎用補助２" },
                { "key": MigrationSwkCloudItem.DGeneralHojyo3Name, "value": "借方汎用補助３" },
                { "key": MigrationSwkCloudItem.DGeneralHojyo4Name, "value": "借方汎用補助４" },
                { "key": MigrationSwkCloudItem.DGeneralHojyo5Name, "value": "借方汎用補助５" },
                { "key": MigrationSwkCloudItem.DKoujiName, "value": "借方工事" },
                { "key": MigrationSwkCloudItem.CKmkName, "value": "貸方科目*" },
                { "key": MigrationSwkCloudItem.CHojyoName, "value": "貸方科目別補助" },
                { "key": MigrationSwkCloudItem.CBmnName, "value": "貸方部門" },
                { "key": MigrationSwkCloudItem.CSegmentName, "value": "貸方セグメント" },
                { "key": MigrationSwkCloudItem.CBankName, "value": "貸方銀行" },
                { "key": MigrationSwkCloudItem.CClientName, "value": "貸方取引先" },
                { "key": MigrationSwkCloudItem.CStaffName, "value": "貸方社員" },
                { "key": MigrationSwkCloudItem.CGeneralHojyo1Name, "value": "貸方汎用補助１" },
                { "key": MigrationSwkCloudItem.CGeneralHojyo2Name, "value": "貸方汎用補助２" },
                { "key": MigrationSwkCloudItem.CGeneralHojyo3Name, "value": "貸方汎用補助３" },
                { "key": MigrationSwkCloudItem.CGeneralHojyo4Name, "value": "貸方汎用補助４" },
                { "key": MigrationSwkCloudItem.CGeneralHojyo5Name, "value": "貸方汎用補助５" },
                { "key": MigrationSwkCloudItem.CKoujiName, "value": "貸方工事" },
                { "key": MigrationSwkCloudItem.InpSum, "value": "金額*" },
                { "key": MigrationSwkCloudItem.TaxSum, "value": "消費税額" },
                { "key": MigrationSwkCloudItem.TaxCode, "value": "消費税区分" },
                { "key": MigrationSwkCloudItem.TaxRate, "value": "消費税率" },
                { "key": MigrationSwkCloudItem.TypeCd, "value": "業種" },
                { "key": MigrationSwkCloudItem.ShireStart, "value": "仕入日（自）" },
                { "key": MigrationSwkCloudItem.ShireEnd, "value": "仕入日（至）" },
                { "key": MigrationSwkCloudItem.ShireSaki, "value": "仕入先" },
                { "key": MigrationSwkCloudItem.Tekiyo, "value": "摘要" },
                { "key": MigrationSwkCloudItem.IvJigyoKbn, "value": "事業者区分" },
            ];

            // 免税の場合は、消費税項目を削除
            if (this.taxInfoDataCacheForItemSetting.AccProKbn == TaxInfoAccProKbn.TaxFreeTrader) {
                items = items.filter((n: { key: number; }) =>
                    n.key != MigrationSwkCloudItem.TaxSum &&
                    n.key != MigrationSwkCloudItem.TaxCode &&
                    n.key != MigrationSwkCloudItem.TaxRate &&
                    n.key != MigrationSwkCloudItem.TypeCd &&
                    n.key != MigrationSwkCloudItem.ShireStart &&
                    n.key != MigrationSwkCloudItem.ShireEnd &&
                    n.key != MigrationSwkCloudItem.ShireSaki
                );
            }

            // 工事未採用の場合は、貸借工事を削除
            let koujiMasterInfo = this.masterInfoDataCache.singleOrNull((n: { MasterKbn: number; }) => n.MasterKbn == MasterKbn.Koji);
            if (koujiMasterInfo != null && koujiMasterInfo.UseKbn == UseKbn.None) {
                items = items.filter((n: { key: number; }) => n.key != MigrationSwkCloudItem.DKoujiName && n.key != MigrationSwkCloudItem.CKoujiName);
            }
        }

        return items;
    }

    private createCmbHowRegistItems(srcHojyoKbn: number): any {
        let items: any = [];

        switch (srcHojyoKbn) {
            case HojyoKbn.EachKmkAuxiliary: items.push({ "key": srcHojyoKbn, "value": "科目別補助" }); break;
            case HojyoKbn.Bank: items.push({ "key": srcHojyoKbn, "value": "銀行" }); break;
            case HojyoKbn.Client: items.push({ "key": srcHojyoKbn, "value": "取引先" }); break;
            case HojyoKbn.Staff: items.push({ "key": srcHojyoKbn, "value": "社員" }); break;
            case HojyoKbn.GeneralPurposeAuxiliary1: items.push({ "key": srcHojyoKbn, "value": "汎用補助１" }); break;
            case HojyoKbn.GeneralPurposeAuxiliary2: items.push({ "key": srcHojyoKbn, "value": "汎用補助２" }); break;
            case HojyoKbn.GeneralPurposeAuxiliary3: items.push({ "key": srcHojyoKbn, "value": "汎用補助３" }); break;
            case HojyoKbn.GeneralPurposeAuxiliary4: items.push({ "key": srcHojyoKbn, "value": "汎用補助４" }); break;
            case HojyoKbn.GeneralPurposeAuxiliary5: items.push({ "key": srcHojyoKbn, "value": "汎用補助５" }); break;
            case HojyoKbn.Bmn: items.push({ "key": srcHojyoKbn, "value": "部門" }); break;
            case HojyoKbn.Segment1: items.push({ "key": srcHojyoKbn, "value": "セグメント" }); break;
            case HojyoKbn.Koji: items.push({ "key": srcHojyoKbn, "value": "工事" }); break;
        }

        items.push({ "key": -1, "value": "登録しない" });

        return items;
    }

    private createCmbSelectHojyoItems(data: settingHojyoDetailData): any {
        let items: any = [];
        let hojyoMa = this.getHojyoMAItems(data.HowRegist);

        // コードと被らないようにkeyは平仮名で設定
        switch (data.HowRegist) {
            case HojyoKbn.EachKmkAuxiliary: items.push({ "key": "デフォルト", "value": "科目別補助を選択" }); break;
            case HojyoKbn.Bank: items.push({ "key": "デフォルト", "value": "銀行を選択" }); break;
            case HojyoKbn.Client:
            case HojyoKbn.Customers: // 得意先・仕入先は取引先にする
            case HojyoKbn.Supplier: items.push({ "key": "デフォルト", "value": "取引先を選択" }); break;
            case HojyoKbn.Staff: items.push({ "key": "デフォルト", "value": "社員を選択" }); break;
            case HojyoKbn.GeneralPurposeAuxiliary1: items.push({ "key": "デフォルト", "value": "汎用補助１を選択" }); break;
            case HojyoKbn.GeneralPurposeAuxiliary2: items.push({ "key": "デフォルト", "value": "汎用補助２を選択" }); break;
            case HojyoKbn.GeneralPurposeAuxiliary3: items.push({ "key": "デフォルト", "value": "汎用補助３を選択" }); break;
            case HojyoKbn.GeneralPurposeAuxiliary4: items.push({ "key": "デフォルト", "value": "汎用補助４を選択" }); break;
            case HojyoKbn.GeneralPurposeAuxiliary5: items.push({ "key": "デフォルト", "value": "汎用補助５を選択" }); break;
            case HojyoKbn.Bmn: items.push({ "key": "デフォルト", "value": "部門を選択" }); break;
            case HojyoKbn.Segment1: items.push({ "key": "デフォルト", "value": "セグメントを選択" }); break;
            case HojyoKbn.Koji: items.push({ "key": "デフォルト", "value": "工事を選択" }); break;
            default: break;
        }

        items.push({ "key": "新規登録", "value": "新規登録" });

        let kmkhojyoSundry = null;
        let hojyoSundry = null;
        for (let hojyobase of hojyoMa) {
            if (data.HowRegist == HojyoKbn.EachKmkAuxiliary) {
                let kmkhojyo = <KmkMACacheViewModel>hojyobase;
                if (kmkhojyo.GCode == data.DstKmkCode) {
                    if (this.isDateErr(kmkhojyo.StartDate, kmkhojyo.EndDate)) {
                        // 適用期間が会計期間外の場合は除外
                        continue;
                    }

                    if (kmkhojyo.NCode == SundryInfo.Sundry) {
                        kmkhojyoSundry = kmkhojyo;
                        continue;
                    }

                    items.push({ "key": kmkhojyo.SubCode, "value": kmkhojyo.SimpleName });
                }
            } else {
                let hojyo = <HojyoMACacheViewModel>hojyobase;
                if (this.isDateErr(hojyo.StartDate, hojyo.EndDate)) {
                    // 適用期間が会計期間外の場合は除外
                    continue;
                }

                if (data.HowRegist == HojyoKbn.Bmn) {
                    if (this.isExcludeBmnClassification(data.BmnBPKbn, hojyo.HojyoKbn2)) {
                        // 部門の場合、PL/BS科目に設定できる部門分類でない場合は除外
                        continue;
                    }
                }

                if (hojyo.NCode == SundryInfo.Sundry) {
                    hojyoSundry = hojyo;
                    continue;
                }

                items.push({ "key": this.getTemporaryCode(hojyo.GCode, data.HowRegist), "value": hojyo.SimpleName });
            }
        }

        // 諸口は最下部に追加
        if (data.HowRegist == HojyoKbn.EachKmkAuxiliary) {
            if (kmkhojyoSundry != null) {
                items.push({ "key": kmkhojyoSundry.SubCode, "value": kmkhojyoSundry.SimpleName });
            } else if (data.MappingSettingKbn == MigrationMappingSettingKbn.NewRegister && data.SrcHojyoName.indexOf('諸口') != -1) {
                data.MappingSettingKbn = MigrationMappingSettingKbn.NewSundry;
                items.push({ "key": "諸口", "value": "諸口" });
            }
        } else {
            if (hojyoSundry != null) {
                items.push({ "key": this.getTemporaryCode(hojyoSundry.GCode, data.HowRegist), "value": hojyoSundry.SimpleName });
            } else if (data.MappingSettingKbn == MigrationMappingSettingKbn.NewRegister && data.SrcHojyoName.indexOf('諸口') != -1) {
                data.MappingSettingKbn = MigrationMappingSettingKbn.NewSundry;
                items.push({ "key": "諸口", "value": "諸口" });
            }
        }

        return items;
    }

    private getHojyoMAItems(hojyokbn: number): DataModelBase[] {
        let hojyoMaItems: DataModelBase[] = [];
        switch (hojyokbn) {
            case HojyoKbn.EachKmkAuxiliary:
                hojyoMaItems = this.kmkMaSubDataCache.where(n => n.MasterKbn == MasterKbn.EachKmkAuxiliary && n.SumKbn == MasterSumKbn.Real && n.RecordKbn == RecordKbn.Regular);
                break;
            case HojyoKbn.Bank:
                hojyoMaItems = this.bankDataCache.where(n => n.MasterKbn == MasterKbn.Bank && n.SumKbn == MasterSumKbn.Real && n.RecordKbn == RecordKbn.Regular);
                break;
            case HojyoKbn.Client:
                hojyoMaItems = this.clientDataCache.where(n => (n.MasterKbn == MasterKbn.Client || n.MasterKbn == MasterKbn.Customers || n.MasterKbn == MasterKbn.Supplier) && n.SumKbn == MasterSumKbn.Real && n.RecordKbn == RecordKbn.Regular);
                break;
            case HojyoKbn.Staff:
                hojyoMaItems = this.staffDataCache.where(n => n.MasterKbn == MasterKbn.Staff && n.SumKbn == MasterSumKbn.Real && n.RecordKbn == RecordKbn.Regular);
                break;
            case HojyoKbn.GeneralPurposeAuxiliary1:
                hojyoMaItems = this.general1DataCache.where(n => n.MasterKbn == MasterKbn.GeneralPurposeAuxiliary1 && n.SumKbn == MasterSumKbn.Real && n.RecordKbn == RecordKbn.Regular);
                break;
            case HojyoKbn.GeneralPurposeAuxiliary2:
                hojyoMaItems = this.general2DataCache.where(n => n.MasterKbn == MasterKbn.GeneralPurposeAuxiliary2 && n.SumKbn == MasterSumKbn.Real && n.RecordKbn == RecordKbn.Regular);
                break;
            case HojyoKbn.GeneralPurposeAuxiliary3:
                hojyoMaItems = this.general3DataCache.where(n => n.MasterKbn == MasterKbn.GeneralPurposeAuxiliary3 && n.SumKbn == MasterSumKbn.Real && n.RecordKbn == RecordKbn.Regular);
                break;
            case HojyoKbn.GeneralPurposeAuxiliary4:
                hojyoMaItems = this.general4DataCache.where(n => n.MasterKbn == MasterKbn.GeneralPurposeAuxiliary4 && n.SumKbn == MasterSumKbn.Real && n.RecordKbn == RecordKbn.Regular);
                break;
            case HojyoKbn.GeneralPurposeAuxiliary5:
                hojyoMaItems = this.general5DataCache.where(n => n.MasterKbn == MasterKbn.GeneralPurposeAuxiliary5 && n.SumKbn == MasterSumKbn.Real && n.RecordKbn == RecordKbn.Regular);
                break;
            case HojyoKbn.Bmn:
                hojyoMaItems = this.bmnDataCache.where(n => n.MasterKbn == MasterKbn.Bmn && n.SumKbn == MasterSumKbn.Real && n.RecordKbn == RecordKbn.Regular);
                break;
            case HojyoKbn.Segment1:
                hojyoMaItems = this.segmentDataCache.where(n => n.MasterKbn == MasterKbn.Segment1 && n.SumKbn == MasterSumKbn.Real && n.RecordKbn == RecordKbn.Regular);
                break;
            case HojyoKbn.Koji:
                hojyoMaItems = this.koujiDataCache.where(n => n.MasterKbn == MasterKbn.Koji && n.SumKbn == MasterSumKbn.Real && n.RecordKbn == RecordKbn.Regular);
                break;
            default:
                break;
        }

        return hojyoMaItems;
    }

    private isDateErr(startDate: Date, endDate: Date): boolean {
        let kStDate = this.dtMainDataCache[0].KStDate;
        let kEdDate = this.dtMainDataCache[0].KEdDate;

        if ((!(MjsUtilities.isNull(endDate)) && endDate < kStDate) || (!(MjsUtilities.isNull(startDate)) && kEdDate < startDate)) {
            return true;
        }

        return false;
    }

    private isExcludeBmnClassification(bmnBPKbn: number, hojyoKbn2: number): boolean {
        switch (bmnBPKbn) {
            case BmnClassification.CommonBmnBSPL: return hojyoKbn2 != BmnClassification.CommonBmnBSPL;
            case BmnClassification.BS: return hojyoKbn2 != BmnClassification.BS && hojyoKbn2 != BmnClassification.CommonBmnBSPL;
            case BmnClassification.PL: return hojyoKbn2 != BmnClassification.PL && hojyoKbn2 != BmnClassification.CommonBmnBSPL;
            default: return false;
        }
    }
    // #endregion setEventStepHojyoSetting

    // #region setEventStepSwkImport
    private setEventStepSwkImport(): void {
        // インポートチェックボックス
        this.$scope.checkStatusClick = (index: number) => {
            let swkSetting = <settingSwkData>this.$scope.swkSetting;
            swkSetting.SwkInfoList[index].CheckStatus = $(`#swkinfo-checkbox${index}`).prop("checked");
            if (swkSetting.SwkInfoList.any((n: { CheckStatus: any; }) => n.CheckStatus)) {
                this.swkImportBtn.enable();
            } else {
                this.swkImportBtn.disable();
            }

            let i = swkSetting.SwkInfoList.any((n: { YearMonth: string; }) => n.YearMonth == "対象外") ? index - 1 : index;
            this.m_JournalSetting.JournalMonthList[i].CheckStatus = swkSetting.SwkInfoList[index].CheckStatus;

            // 編集中
            this.visibleBtnSuccessSwkImport(Status.Waiting);
        };

        // エラー時キャンセルフラグ
        this.$scope.errCancelFlgClick = () => {
            this.$scope.swkSetting.ErrCancelFlg = this.m_JournalSetting.ErrCancelFlg =  $("#checkbox-errCancelFlg").prop("checked");

            // 編集中
            this.visibleBtnSuccessSwkImport(Status.Waiting);
        };

        // 仕訳をインポートするボタン
        this.swkImportBtn.setEventHandler(new MjsDelegateContainer<Function, any>(() => {
            let isShowDlg = (this.m_ItemSettingGroup != null && this.$scope.itemSettingSaveStatus != MigrationSettingSaveStatus.Saved) ||
                (this.m_ItemConvertSettingGroup != null && this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.length > 0 && this.$scope.itemConvertSettingSaveStatus != MigrationSettingSaveStatus.Saved) ||
                (this.m_kmkSettingDatas.length > 0 && !this.isKmkSettingSave) ||
                (this.m_hojyokmkSettingDatas.length > 0 && !this.isHojyoKmkSettingSave) ||
                (this.m_generalhojyoSettingDatas.length > 0 && !this.isGeneralHojyoSettingSave) ||
                (this.m_bmnSettingDatas.length > 0 && !this.isBmnSettingSave) ||
                (this.m_segmentSettingDatas.length > 0 && !this.isSegmentSettingSave) ||
                (this.m_koujiSettingDatas.length > 0 && !this.isKoujiSettingSave);

            if (isShowDlg) {
                let target = [];
                if (this.m_ItemSettingGroup != null && this.$scope.itemSettingSaveStatus != MigrationSettingSaveStatus.Saved) {
                    target.push("項目");
                }
                if (this.m_ItemConvertSettingGroup != null && this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.length > 0 && this.$scope.itemConvertSettingSaveStatus != MigrationSettingSaveStatus.Saved) {
                    target.push("項目の変換");
                }
                if (this.m_kmkSettingDatas.length > 0 && !this.isKmkSettingSave) {
                    target.push("科目");
                }
                if (this.m_hojyokmkSettingDatas.length > 0 && !this.isHojyoKmkSettingSave) {
                    target.push("補助");
                }
                if (this.m_generalhojyoSettingDatas.length > 0 && !this.isGeneralHojyoSettingSave) {
                    target.push("汎用補助");
                }
                if (this.m_bmnSettingDatas.length > 0 && !this.isBmnSettingSave) {
                    target.push("部門");
                }
                if (this.m_segmentSettingDatas.length > 0 && !this.isSegmentSettingSave) {
                    target.push("セグメント");
                }
                if (this.m_koujiSettingDatas.length > 0 && !this.isKoujiSettingSave) {
                    target.push("工事");
                }

                let msg = target.join("、") + "の" + ProcessMigration.dlgConfirmMsg_ConfirmSwkImport;

                // 仕訳インポート確認ダイアログを表示
                if (!this.confirmDialog_ConfirmSwkImport) {
                    this.confirmDialog_ConfirmSwkImport = this.createConfirmDialog("#confirmDialog_ConfirmSwkImport", msg, () => { this.swkImport(); });
                }
                this.confirmDialog_ConfirmSwkImport.setMessage(msg);
                this.confirmDialog_ConfirmSwkImport.open(".on-no-confirmDialog_ConfirmSwkImport");
            } else {
                this.swkImport();
            }
        }, this));

        // エラーログをダウンロードするボタン
        this.swkErrLogDownLoadBtn.setEventHandler(new MjsDelegateContainer<Function, any>(() => { this.swkErrLogDownLoad(); }, this));
    }

    private swkImport(): void {
        let success = (id: number, textStatus: string, jqXHR: JQueryXHR) => {
            this.m_BatchRequestId = id;

            // 進捗確認ダイアログを表示
            this.progressBarDialog = new ProgressConfirmDialog("仕訳インポート中", "仕訳インポート準備中...", 0, true);
            $(ProgressConfirmDialog.CANCEL_BUTTON_ID).click(() => { this.cancelProgress(); });
            this.progressBarDialog.open();
            this.batchCanceling = false;
            this.timerid = setInterval(() => { this.observeProgress(this.m_BatchRequestId); }, 1000);
        };
        let complete = (jqXHR: JQueryXHR, textStatus: string) => { };
        let error = (data: any, jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => { };
        let model: MigrationJournalSettingViewModel = {
            SystemKbn: this.m_SystemKbn,
            MiddleKbn: this.m_JournalSetting.MiddleKbn,
            ExcludedJournalAmount: this.m_JournalSetting.ExcludedJournalAmount,
            ExcludedErrorMessage: this.m_JournalSetting.ExcludedErrorMessage,
            TotalJournalAmount: this.m_JournalSetting.TotalJournalAmount,
            TotalErrorAmount: this.m_JournalSetting.TotalErrorAmount,
            JournalMonthList: this.m_JournalSetting.JournalMonthList,
            ErrCancelFlg: this.m_JournalSetting.ErrCancelFlg,
            BatchRequestId: this.m_JournalSetting.BatchRequestId,
            BatchStatus: this.m_JournalSetting.BatchStatus,
            SwkImported: this.m_JournalSetting.SwkImported,
            ErrLog: this.m_JournalSetting.ErrLog
        };
        let settings: AjaxSettings = {
            url: "/api/v1/ProcessMigration/MigrateSwk",
            type: "POST",
            data: model,
            showBusyIndicator: true,
            beforeSend: null!,
            success: success,
            complete: complete,
            error: error
        };

        // 仕訳インポート
        Ajax.perform(settings);
    }

    private observeProgress(id: number): void {
        let success = (p: any, textStatus: string, jqXHR: JQueryXHR) => {
            if (this.timerid == null) {
                return;
            }

            if (p.status == Status.Waiting) {
                return;
            }

            if (!MjsUtilities.isNull(p.message) && !this.batchCanceling) {
                this.progressBarDialog.setMessage(p.message);
                this.progressBarDialog.setPercentage(p.progress);

                if (p.progress == 100) {
                    this.progressBarDialog.disableCancelButton();
                }
            }

            if (p.status == Status.Runtime) {
                return;
            }

            clearInterval(this.timerid);
            this.timerid = null;

            switch (p.status) {
                case Status.Canceled:
                    this.visibleBtnSuccessSwkImport(Status.Canceled);
                    this.progressBarDialog.hide();
                    $("#swkErrLogArea").hide();
                    break;
                case Status.Completion:
                    // エラー結果取得
                    $.when.apply(this, [this.storeSwkSetting()]).done(() => {
                        let errlog = this.m_JournalSetting.ErrLog;
                        if (errlog.length > 0) {
                            this.initSwkErrLog(errlog);
                            $("#swkErrLogArea").show();
                        }
                        else {
                            $("#swkErrLogArea").hide();
                        }

                        this.$timeout(() => {
                            if (this.m_JournalSetting.SwkImported) {
                                this.visibleBtnSuccessSwkImport(this.m_JournalSetting.BatchStatus);
                                // ※本来はステップ更新処理を行うべきだが、必ずstepFinishが表示される仕様のため呼び出しを行わない
                                this.m_CurrentStep = MigrationStep.Finish;
                                this.m_StepCheckResult.IsFinishStepEnable = true;
                                this.createDispStepNoMap(this.m_StepCheckResult);
                                this.showStep(MigrationStep.Finish);
                            } else if (this.m_JournalSetting.ErrCancelFlg) {
                                this.visibleBtnSuccessSwkImport(Status.Canceled);
                            }
                        });

                        this.progressBarDialog.hide();
                    });
                    break;
                case Status.ErrorExit:
                    this.visibleBtnSuccessSwkImport(Status.ErrorExit);
                    this.progressBarDialog.hide();
                    $("#swkErrLogArea").hide();

                    // 仕訳インポートエラーダイアログを表示
                    if (!this.alertDialog_SwkImportErr) {
                        this.alertDialog_SwkImportErr = this.createAlertDialog("#alertDialog_SwkImportErr", "エラー", ProcessMigration.dlgAlertMsg_SwkImportErr);
                    }
                    this.$timeout(() => {
                        this.alertDialog_SwkImportErr.open(".on-ok-alertDialog_SwkImportErr");
                    });
                    break;
                default:
                    break;
            }

            this.isSwkImportBtnClick = true;
            this.showLoadingArea();
            this.masterDataManager.refresh();
        };
        let complete = (jqXHR: JQueryXHR, textStatus: string) => { };
        let error = (data: any, jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => { };
        let settings: AjaxSettings = {
            url: "/api/v1/ProcessMigration/GetProgress",
            type: "POST",
            data: { requestId: id },
            showBusyIndicator: false,
            beforeSend: null!,
            success: success,
            complete: complete,
            error: error
        }

        Ajax.perform(settings);
    }

    private cancelProgress(): void {
        if (!this.m_BatchRequestId) {
            return;
        }

        let success = (data: any) => {
            this.progressBarDialog.setMessage("仕訳インポートキャンセル中...");
            this.progressBarDialog.disableCancelButton();
            this.batchCanceling = true;

            // エラー結果取得
            $.when.apply(this, [this.storeSwkSetting()]).done(() => {
                let errlog = this.m_JournalSetting.ErrLog;
                if (errlog.length > 0) {
                    this.initSwkErrLog(errlog);
                    $("#swkErrLogArea").show();
                }
            });

            setTimeout(() => {
                $(this.swkImportBtn.getSelector()).focus();
            });
        };
        let complete = (jqXHR: JQueryXHR, textStatus: string) => { };
        let error = (data: any, jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => { };
        let settings: AjaxSettings = {
            url: "/api/v1/ProcessMigration/SetCancelRequest",
            type: "POST",
            data: { requestId: this.m_BatchRequestId },
            beforeSend: null!,
            showBusyIndicator: true,
            success: success,
            complete: complete,
            error: error
        }

        Ajax.perform(settings);
    }

    private getFileName(contentDisposition: string){
        let fileName = contentDisposition.substring(contentDisposition.indexOf("''") + 2,
                                                    contentDisposition.length
                                                    );
        //デコードするとスペースが"+"になるのでスペースへ置換します
        fileName = decodeURI(fileName).replace(/\+/g, " ");
    
        return fileName;
    }

    // MS 制のブラウザではないブラウザでダウンロード処理を実行
    private downloadDataForNonMS(data: Blob, filename: string) {
        const downloadUrl = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
   }

    private itemSettingErrLogDownLoad(): void {
        // 項目設定エラーログが複数ある場合は、複数呼び出す
        this.m_ItemSettingGroup.MigrateItemSettingList.forEach((n, index) => {
            if (n.ErrLogFileName != null && n.ErrLogFileName != "") {
                let param: any = {
                    errLogFileName: n.ErrLogFileName
                }

                // IE・Edge用
                let storecompleteCB = (data: Blob, textStatus: string) => {
                    if (this.$window.navigator.msSaveBlob) {
                        this.$window.navigator.msSaveBlob(data, n.ErrLogFileName);
                    } else {
                        this.downloadDataForNonMS(data, n.ErrLogFileName);
                    }
                }

                let dataRequests: AjaxSettings = {
                    url: "/api/v1/ProcessMigration/DownLoadByItemSettingAjax",
                    type: "POST",
                    data: param,
                    showBusyIndicator: true,
                    beforeSend: (xhr) => {
                        // xhr.overrideMimeType('text/html;charset=Shift_JIS');//Shift-JISの文字化け回避
                    },
                    success: (data, textStatus: string) => { storecompleteCB(data, textStatus) },
                    complete: null!,
                    error: null!
                }
                Ajax.perform(dataRequests, true, "blob");
            }
        })

        setTimeout(() => {
            this.mediator.setFocusToActiveItem();
        });
    }

    private kisyuImportErrLogDownLoad(): void {
        // 残高インポートエラーログが複数ある場合は、複数呼び出す
        this.m_HojyoSetting.HojyoGroupList.forEach((n, index) => {
            if (n.ImportErrLogFileName != null && n.ImportErrLogFileName != "") {
                let param: any = {
                    errLogFileName: n.ImportErrLogFileName
                }

                // IE・Edge用
                let storecompleteCB = (data: Blob, textStatus: string) => {
                    if (this.$window.navigator.msSaveBlob) {
                        this.$window.navigator.msSaveBlob(data, n.ImportErrLogFileName);
                    } else {
                        this.downloadDataForNonMS(data, n.ImportErrLogFileName);
                    }
                }

                let dataRequests: AjaxSettings = {
                    url: "/api/v1/ProcessMigration/DownLoadByOpeningBalanceImportErrAjax",
                    type: "POST",
                    data: param,
                    showBusyIndicator: true,
                    beforeSend: (xhr) => {
                        // xhr.overrideMimeType('text/html;charset=Shift_JIS');//Shift-JISの文字化け回避
                    },
                    success: (data, textStatus: string) => { storecompleteCB(data, textStatus) },
                    complete: null!,
                    error: null!
                }
                Ajax.perform(dataRequests, true, "blob");
            }
        })

        setTimeout(() => {
            this.mediator.setFocusToActiveItem();
        });
    }
    private swkErrLogDownLoad(): void {
        let param: any = {
            requestId: this.m_BatchRequestId
        }

        let settings: AjaxSettings = {
            url: "/api/v1/ProcessMigration/DownLoadBySwkImport",
            type: "POST",
            data: param,
            showBusyIndicator: false,
            beforeSend: null!,
            success: (blob: Blob, status: string, response: JQueryXHR) => {
                const contentDisposition = response.getResponseHeader("content-disposition")!;
                const filename = this.getFileName(contentDisposition);

                if (this.$window.navigator.msSaveBlob) {
                    this.$window.navigator.msSaveBlob(blob, filename);
                } else {
                    this.downloadDataForNonMS(blob, filename);
                }
            },
        };
        
        // 仕訳登録用データ取得
        Ajax.perform(settings, true, "blob");

        setTimeout(() => {
            this.mediator.setFocusToActiveItem();
        });
    }

    private storeSwkSetting(): JQueryPromise<{}> {
        let d = $.Deferred<{}>();
        let success = (data: MigrationJournalSettingViewModel, textStatus: string, jqXHR: JQueryXHR) => {
            this.m_JournalSetting = data;
            d.resolve();
        };
        let complete = (jqXHR: JQueryXHR, textStatus: string) => { };
        let error = (data: any, jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => { d.reject(); };
        let model: MigrationJournalSettingViewModel = {
            SystemKbn: this.m_SystemKbn,
            MiddleKbn: null!,
            ExcludedJournalAmount: null!,
            ExcludedErrorMessage: null!,
            TotalJournalAmount: null!,
            TotalErrorAmount: null!,
            JournalMonthList: null!,
            ErrCancelFlg: null!,
            BatchRequestId: null!,
            BatchStatus: null!,
            SwkImported: false,
            ErrLog: null!
        };
        let settings: AjaxSettings = {
            url: "/api/v1/ProcessMigration/StoreJournalSetting",
            type: "POST",
            data: model,
            showBusyIndicator: false,
            beforeSend: null!,
            success: success,
            complete: complete,
            error: error
        };

        // 仕訳登録用データ取得
        Ajax.perform(settings);

        return d.promise();
    }

    private initSwkSettingTable(data: MigrationJournalSettingViewModel): void {
        let infoList: swkInfoData[] = [];

        if (data.ExcludedJournalAmount != 0) {
            // 対象外の仕訳を先頭に追加する
            infoList.push({
                CheckStatus: false,
                YearMonth: "対象外",
                JournalAmount: 0,
                ErrorAmount: data.ExcludedJournalAmount,
                ErrorMessage: data.ExcludedErrorMessage,
                isMultiErr: false,
            });
        }

        for (let info of data.JournalMonthList) {
            let msg = "";
            if (info.ErrorMessages.length == 1) {
                msg = info.ErrorMessages[0];
            } else if (info.ErrorMessages.length > 1) {
                msg = info.ErrorMessages.join("<br/>");
            }

            infoList.push({
                CheckStatus: info.CheckStatus,
                YearMonth: info.YearMonth,
                JournalAmount: info.JournalAmount,
                ErrorAmount: info.ErrorAmount,
                ErrorMessage: msg,
                isMultiErr: info.ErrorMessages.length > 1,
            });
        }

        let swkSetting: settingSwkData = {
            SwkInfoList: infoList,
            TotalJournalAmount: data.TotalJournalAmount,
            TotalErrorAmount: data.TotalErrorAmount,
            ErrCancelFlg: data.ErrCancelFlg,
        };

        this.$scope.swkSetting = swkSetting;

        this.$timeout(() => {
            let rowIndex = data.ExcludedJournalAmount != 0 ? 1 : 0;
            for (let journalMonth of data.JournalMonthList) {
                if (journalMonth.SwkConfirmedFlag || (journalMonth.JournalAmount == 0 && journalMonth.ErrorAmount == 0)) {
                    // 月次確定済み or 仕訳・エラー件数が0件の場合はチェックボックス非活性、チェックをoffにする
                    this.$scope.swkSetting.SwkInfoList[rowIndex].CheckStatus = journalMonth.CheckStatus;
                    $(`#swkinfo-checkbox${rowIndex}`).prop('disabled', true);
                } else if (!journalMonth.SwkConfirmedFlag) {
                    $(`#swkinfo-checkbox${rowIndex}`).prop('disabled', false);
                }
                $(`#swkinfo-checkbox${rowIndex}`).prop("checked", swkSetting.SwkInfoList[rowIndex].CheckStatus);

                rowIndex++;
            }
            $("#checkbox-errCancelFlg").prop("checked", swkSetting.ErrCancelFlg);

            if (this.$scope.swkSetting.SwkInfoList.any((n: { CheckStatus: any; }) => n.CheckStatus)) {
                this.swkImportBtn.enable();
            } else {
                this.swkImportBtn.disable();
            }

            // 複数エラーの場合、ツールチップにメッセージを設定する
            this.setTooltipMultiMsg(this.$scope.swkSetting.SwkInfoList);
        }, 100);
    }

    private updateErrInfoInSwkSettingTable(data: MigrationJournalSettingViewModel): void {
        let isEdited: boolean = false;
        let rowIndex = data.ExcludedJournalAmount != 0 ? 1 : 0;
        for (let info of data.JournalMonthList) {
            let msg = "";
            if (info.ErrorMessages.length == 1) {
                msg = info.ErrorMessages[0];
            } else if (info.ErrorMessages.length > 1) {
                msg = info.ErrorMessages.join("<br/>");
            }

            this.$scope.swkSetting.SwkInfoList[rowIndex].JournalAmount = info.JournalAmount;
            this.$scope.swkSetting.SwkInfoList[rowIndex].ErrorAmount = info.ErrorAmount;
            this.$scope.swkSetting.SwkInfoList[rowIndex].ErrorMessage = msg;
            this.$scope.swkSetting.SwkInfoList[rowIndex].isMultiErr = info.ErrorMessages.length > 1;

            isEdited = isEdited || this.$scope.swkSetting.SwkInfoList[rowIndex].CheckStatus != info.CheckStatus;

            if (info.SwkConfirmedFlag || (info.JournalAmount == 0 && info.ErrorAmount == 0)) {
                // 月次確定済み or 仕訳・エラー件数が0件の場合はチェックボックス非活性、チェックをoffにする
                this.$scope.swkSetting.SwkInfoList[rowIndex].CheckStatus = info.CheckStatus;
                $(`#swkinfo-checkbox${rowIndex}`).prop("checked", info.CheckStatus);
                $(`#swkinfo-checkbox${rowIndex}`).prop('disabled', true);
            } else if (!info.SwkConfirmedFlag) {
                $(`#swkinfo-checkbox${rowIndex}`).prop('disabled', false);
            }

            rowIndex++;
        }

        this.$scope.swkSetting.TotalJournalAmount = data.TotalJournalAmount;
        this.$scope.swkSetting.TotalErrorAmount = data.TotalErrorAmount;

        if (this.$scope.swkSetting.SwkInfoList.any((n: { CheckStatus: any; }) => n.CheckStatus)) {
            this.swkImportBtn.enable();
        } else {
            this.swkImportBtn.disable();
        }

        // BootstrapのTooltipsを初期化する
        $('[data-toggle="tooltip"]').tooltip(ProcessMigration.TOOLTIP_OPTIONS).off("show.bs.tooltip").on("show.bs.tooltip", this.onShowToolTip.bind(this));

        // 複数エラーの場合、ツールチップにメッセージを設定する
        this.setTooltipMultiMsg(this.$scope.swkSetting.SwkInfoList);

        if (isEdited) {
            this.visibleBtnSuccessSwkImport(Status.Waiting);
        }
    }

    private setTooltipMultiMsg(swkInfoList: swkInfoData[]): void {
        for (let i = 0; i < swkInfoList.length; i++) {
            let info = swkInfoList[i];
            if (info.isMultiErr) {
                $(`#tooltip-MultiErr${i}`).attr('title', info.ErrorMessage).tooltip('dispose').tooltip(ProcessMigration.TOOLTIP_OPTIONS).off("show.bs.tooltip").on("show.bs.tooltip", this.onShowToolTip.bind(this));
            }
        }
    }

    private initSwkErrLog(errLogList: MigrationErrViewModel[]): void {
        this.$timeout(() => {
            let viewErrLogList: MigrationErrViewModel[] = [];

            // エラーログの表示は負荷がかかるため、1000件までとする
            if (errLogList.length > 1000) {
                this.$scope.isSwkErrLogListCntOver = true;
                for (let i = 0; i < 1000; i++) {
                    viewErrLogList.push(errLogList[i]);
                }
            } else {
                this.$scope.isSwkErrLogListCntOver = false;
                viewErrLogList = errLogList;
            }

            this.$scope.swkErrLogList = viewErrLogList;
        });
    }
    // #endregion setEventStepSwkImport

    // #region convert && DataGet(Data → ViewModel)
    private getTemporaryCode(code: string, masterKbn: number): string {
        if (masterKbn == HojyoKbn.Customers || masterKbn == HojyoKbn.Supplier) {
            masterKbn = HojyoKbn.Client;
        }

        let masterInfo = this.masterInfoDataCache.singleOrNull((n: { MasterKbn: number; }) => n.MasterKbn == masterKbn);

        if (masterInfo == null) {
            return code;
        }
        else {
            return BizUtil.toTemporaryCode(code, masterInfo.CodeAttr, masterInfo.CodeDigit);
        }
    }

    private getOriginalCode(code: string, masterKbn: number): string {
        if (masterKbn == HojyoKbn.Customers || masterKbn == HojyoKbn.Supplier) {
            masterKbn = HojyoKbn.Client;
        }

        let masterInfo = this.masterInfoDataCache.singleOrNull((n: { MasterKbn: number; }) => n.MasterKbn == masterKbn);

        if (masterInfo == null) {
            return code;
        }
        else {
            return BizUtil.toPermanentCode(code, masterInfo.CodeAttr);
        }
    }

    private convertToCmbKmkItems(kmkMaItems: Array<KmkMACacheViewModel>): any {
        let kmkList = [];
        for (let kmkma of kmkMaItems) {
            kmkList.push({ "key": kmkma.GCode, "value": kmkma.SimpleName, "item": kmkma });
        }

        return kmkList;
    }

    private convertToExplorerKmkItems(kmkMaItems: Array<KmkMACacheViewModel>): any {
        let kmkList = [];
        for (let kmkma of kmkMaItems) {
            kmkList.push({ code: kmkma.GCode, name: kmkma.SimpleName, association: kmkma.rensou });
        }

        return kmkList;
    }

    private convertFromKmkSettingViewModel(data: MigrationKmkSettingViewModel): settingKmkGrpData[] {
        let grplist: settingKmkGrpData[] = [];
        for (let i = 0; i < data.KmkGroupList.length; i++) {
            let grp = data.KmkGroupList[i];

            let kmklist: settingKmkDetailData[] = [];
            for (let j = 0; j < grp.KmkList.length; j++) {
                let detail = grp.KmkList[j];
                kmklist.push(this.convertFromKmkSettingDetailViewModel(detail, i, j));
            }

            let grpkmk: settingKmkGrpData = {
                GroupNo: i,
                GroupName: grp.GroupName,
                KmkSettingDetails: kmklist
            }

            grplist.push(grpkmk);
        }

        return grplist;
    }

    private convertFromKmkSettingDetailViewModel(detail: MigrationSettingDetailViewModel, gNo: number, dNo: number): settingKmkDetailData {
        const kmknameMaxLength: number = 20;
        const kmknameAfterText: string = "...";

        let openingBalance = detail.OpeningBalance == null ? 0 : detail.OpeningBalance;

        let srcKmkName = detail.SrcKmkName != null ? detail.SrcKmkName : "";
        if (srcKmkName.length > kmknameMaxLength) {
            srcKmkName = detail.SrcKmkName.substr(0, kmknameMaxLength) + kmknameAfterText;
        }

        let kmk: settingKmkDetailData = {
            GroupNo: gNo,
            DetailNo: dNo,
            SrcKmkCode: detail.SrcKmkCode,
            SrcKmkName: srcKmkName,
            DstKmkCode: detail.DstKmkCode,
            OpeningBalance: openingBalance,
            IsEdited: false
        }

        return kmk;
    }

    private getEditedKmkGroupViewModel(): MigrationKmkGroupViewModel[] {
        let editedKmkGroupViewModelList: MigrationKmkGroupViewModel[] = [];
        for (let grp of this.m_kmkSettingDatas) {
            let grpViewModel = this.m_KmkSetting.KmkGroupList.singleOrNull((n: { GroupName: string; }) => n.GroupName == grp.GroupName);

            let editedDetaillViewModelList: MigrationSettingDetailViewModel[] = [];
            for (let detail of grp.KmkSettingDetails) {

                if (detail.IsEdited) {
                    let detailViewModel = grpViewModel.KmkList.singleOrNull((n: { SrcKmkCode: string; }) => n.SrcKmkCode == detail.SrcKmkCode);

                    let editedDetaillViewModel = new MigrationSettingDetailViewModel();
                    MjsUtilities.deepCopy(editedDetaillViewModel, detailViewModel);
                    editedDetaillViewModel.DstKmkCode = detail.DstKmkCode;
                    editedDetaillViewModel.MappingSettingKbn = detail.DstKmkCode != null ? MigrationMappingSettingKbn.Mapped : MigrationMappingSettingKbn.Unselected;
                    editedDetaillViewModel.OpeningBalance = detail.OpeningBalance;
                    editedDetaillViewModelList.push(editedDetaillViewModel);
                }
            }

            if (editedDetaillViewModelList.length != 0) {
                let editedKmkGroupViewModel: MigrationKmkGroupViewModel = {
                    GroupName: grpViewModel.GroupName,
                    KmkList: editedDetaillViewModelList
                };
                editedKmkGroupViewModelList.push(editedKmkGroupViewModel);
            }
        }

        return editedKmkGroupViewModelList;
    }

    private convertFromHojyoSettingViewModel(data: MigrationSettingDetailViewModel[]): settingHojyoDetailData[] {
        let hojyolist: settingHojyoDetailData[] = [];
        for (let i = 0; i < data.length; i++) {
            let detail = data[i];
            hojyolist.push(this.convertFromHojyoSettingDetailViewModel(detail, i));
        }

        return hojyolist;
    }

    private convertFromHojyoSettingDetailViewModel(detail: MigrationSettingDetailViewModel, dNo: number): settingHojyoDetailData {
        const kmkAndHojyonameMaxLength: number = 20;
        const kmkAndHojyonameAfterText: string = "...";

        let openingBalance = detail.OpeningBalance == null ? 0 : detail.OpeningBalance;

        let howRegist = detail.DstHojyoKbn1;
        if (detail.DstHojyoKbn1 == HojyoKbn.Client) {
            if (detail.DstHojyoKbn2 == HojyoKbn.Customers || detail.DstHojyoKbn2 == HojyoKbn.Supplier) {
                howRegist = detail.DstHojyoKbn2;
            }
        }
        if (detail.MappingSettingKbn == MigrationMappingSettingKbn.NotRegister) {
            howRegist = -1;
        }

        let srcKmkName = detail.SrcKmkName != null ? detail.SrcKmkName : "";
        if (srcKmkName.length > kmkAndHojyonameMaxLength) {
            srcKmkName = detail.SrcKmkName.substr(0, kmkAndHojyonameMaxLength) + kmkAndHojyonameAfterText;
        }

        let srcHojyoNameDisp = detail.SrcHojyoName != null ? detail.SrcHojyoName : "";
        if (srcHojyoNameDisp.length > kmkAndHojyonameMaxLength) {
            srcHojyoNameDisp = detail.SrcHojyoName.substr(0, kmkAndHojyonameMaxLength) + kmkAndHojyonameAfterText;
        }

        let hojyo: settingHojyoDetailData = {
            DetailNo: dNo,
            SrcHojyoKbn: detail.SrcHojyoKbn,
            SrcKmkCode: detail.SrcKmkCode,
            SrcKmkName: srcKmkName,
            SrcHojyoCode: detail.SrcHojyoCode,
            SrcHojyoName: detail.SrcHojyoName,
            SrcHojyoNameDisp: srcHojyoNameDisp,
            SrcHojyoRenChar: detail.SrcHojyoRenChar,
            BmnBPKbn: detail.BmnBPKbn,
            DstKmkCode: detail.DstKmkCode,
            DstKmkHojyoCode: detail.DstKmkHojyoCode,
            DstHojyoCode: detail.DstHojyoCode,
            HowRegist: howRegist,
            MappingSettingKbn: detail.MappingSettingKbn,
            OpeningBalance: openingBalance,
            IsEdited: false,
            AuxiliaryAccountCanAdoptedKbn: detail.AuxiliaryAccountCanAdoptedKbn
        }

        return hojyo;
    }

    private getEditedOrNewRegisterHojyoListSettingDetailViewModel(hojyoTabKbn: number): MigrationSettingDetailViewModel[] {
        let editedHojyoListSettingDetailViewModelList: MigrationSettingDetailViewModel[] = [];
        let settingDatas: settingHojyoDetailData[] = [];
        let settingViewModel: MigrationSettingDetailViewModel[] = [];
        if (hojyoTabKbn == MigrationHojyoTabKbn.Hojyo) {
            settingDatas = this.m_hojyokmkSettingDatas;
            settingViewModel = this.m_HojyoSettingHojyoList;
        } else if (hojyoTabKbn == MigrationHojyoTabKbn.GeneralHojyo) {
            settingDatas = this.m_generalhojyoSettingDatas;
            settingViewModel = this.m_HojyoSettingGeneralHojyoList;
        }

        for (let detail of settingDatas) {
            if (detail.IsEdited || detail.MappingSettingKbn == MigrationMappingSettingKbn.NewRegister || detail.MappingSettingKbn == MigrationMappingSettingKbn.NewSundry) {
                editedHojyoListSettingDetailViewModelList.push(this.convertFromHojyokmkSettingDatas(detail, settingViewModel));
            } else {
                // 科目別マスタが採用されていない場合は、更新する必要があるので追加する（銀行、取引先、社員、汎用補助１～５）
                let data = this.convertFromHojyokmkSettingDatas(detail, settingViewModel);
                let kmkMa = this.kmkMaKmkDataCache.singleOrNull((n: { MasterKbn: number; SumKbn: number; RecordKbn: number; GCode: number; }) => n.MasterKbn == MasterKbn.CountingKmk && n.SumKbn == MasterSumKbn.Real && n.RecordKbn == RecordKbn.Regular && n.GCode == data.DstKmkCode);
                if (kmkMa != null) {
                    if (data.AuxiliaryAccountCanAdoptedKbn == AuxiliaryAccountCanAdoptedKbn.Normal) {
                        if (data.DstHojyoKbn1 == HojyoKbn.Bank) {
                            if (kmkMa.HojyoUse1 != HojyoKbn.Bank && kmkMa.HojyoUse2 != HojyoKbn.Bank) {
                                editedHojyoListSettingDetailViewModelList.push(data);
                            }
                        } else if (data.DstHojyoKbn1 == HojyoKbn.Client) {
                            if (kmkMa.HojyoUse1 != HojyoKbn.Client && kmkMa.HojyoUse2 != HojyoKbn.Client) {
                                if (data.DstHojyoKbn2 == HojyoKbn.Customers) {
                                    if (kmkMa.HojyoUse1 != HojyoKbn.Customers && kmkMa.HojyoUse2 != HojyoKbn.Customers) {
                                        editedHojyoListSettingDetailViewModelList.push(data);
                                    }
                                } else if (data.DstHojyoKbn2 == HojyoKbn.Supplier) {
                                    if (kmkMa.HojyoUse1 != HojyoKbn.Supplier && kmkMa.HojyoUse2 != HojyoKbn.Supplier) {
                                        editedHojyoListSettingDetailViewModelList.push(data);
                                    }
                                } else {
                                    editedHojyoListSettingDetailViewModelList.push(data);
                                }
                            }
                        } else if (data.DstHojyoKbn1 == HojyoKbn.Staff) {
                            if (kmkMa.HojyoUse1 != HojyoKbn.Staff && kmkMa.HojyoUse2 != HojyoKbn.Staff) {
                                editedHojyoListSettingDetailViewModelList.push(data);
                            }
                        } else if (data.DstHojyoKbn1 == HojyoKbn.GeneralPurposeAuxiliary1) {
                            if (kmkMa.HojyoUse1 != HojyoKbn.GeneralPurposeAuxiliary1 && kmkMa.HojyoUse2 != HojyoKbn.GeneralPurposeAuxiliary1) {
                                editedHojyoListSettingDetailViewModelList.push(data);
                            }
                        } else if (data.DstHojyoKbn1 == HojyoKbn.GeneralPurposeAuxiliary2) {
                            if (kmkMa.HojyoUse1 != HojyoKbn.GeneralPurposeAuxiliary2 && kmkMa.HojyoUse2 != HojyoKbn.GeneralPurposeAuxiliary2) {
                                editedHojyoListSettingDetailViewModelList.push(data);
                            }
                        } else if (data.DstHojyoKbn1 == HojyoKbn.GeneralPurposeAuxiliary3) {
                            if (kmkMa.HojyoUse1 != HojyoKbn.GeneralPurposeAuxiliary3 && kmkMa.HojyoUse2 != HojyoKbn.GeneralPurposeAuxiliary3) {
                                editedHojyoListSettingDetailViewModelList.push(data);
                            }
                        } else if (data.DstHojyoKbn1 == HojyoKbn.GeneralPurposeAuxiliary4) {
                            if (kmkMa.HojyoUse1 != HojyoKbn.GeneralPurposeAuxiliary4 && kmkMa.HojyoUse2 != HojyoKbn.GeneralPurposeAuxiliary4) {
                                editedHojyoListSettingDetailViewModelList.push(data);
                            }
                        } else if (data.DstHojyoKbn1 == HojyoKbn.GeneralPurposeAuxiliary5) {
                            if (kmkMa.HojyoUse1 != HojyoKbn.GeneralPurposeAuxiliary5 && kmkMa.HojyoUse2 != HojyoKbn.GeneralPurposeAuxiliary5) {
                                editedHojyoListSettingDetailViewModelList.push(data);
                            }
                        }
                    }
                }
            }
        }

        return editedHojyoListSettingDetailViewModelList;
    }

    private convertFromHojyokmkSettingDatas(detail: settingHojyoDetailData, hojyoSettingList: MigrationSettingDetailViewModel[]): MigrationSettingDetailViewModel {
        let detailViewModel = hojyoSettingList.singleOrNull((n: { SrcKmkCode: string; SrcHojyoCode: string; SrcHojyoKbn: number; }) => n.SrcKmkCode == detail.SrcKmkCode && n.SrcHojyoCode == detail.SrcHojyoCode && n.SrcHojyoKbn == detail.SrcHojyoKbn);

        let convertDetaillViewModel = new MigrationSettingDetailViewModel();
        MjsUtilities.deepCopy(convertDetaillViewModel, detailViewModel);

        let hojyokbn1 = detail.HowRegist == -1 ? detailViewModel.DstHojyoKbn1 : detail.HowRegist;
        let hojyokbn2 = detail.HowRegist == -1 ? detailViewModel.DstHojyoKbn2 : null!;
        if (detail.HowRegist == HojyoKbn.Customers) {
            hojyokbn1 = HojyoKbn.Client;
            hojyokbn2 = HojyoKbn.Customers;
        } else if (detail.HowRegist == HojyoKbn.Supplier) {
            hojyokbn1 = HojyoKbn.Client;
            hojyokbn2 = HojyoKbn.Supplier;
        }

        convertDetaillViewModel.DstHojyoKbn1 = hojyokbn1;
        convertDetaillViewModel.DstHojyoKbn2 = hojyokbn2;
        convertDetaillViewModel.DstKmkCode = detail.DstKmkCode;
        convertDetaillViewModel.DstKmkHojyoCode = detail.DstKmkHojyoCode;
        convertDetaillViewModel.DstHojyoCode = detail.DstHojyoCode;
        convertDetaillViewModel.MappingSettingKbn = detail.MappingSettingKbn;
        convertDetaillViewModel.OpeningBalance = detail.OpeningBalance;
        convertDetaillViewModel.AuxiliaryAccountCanAdoptedKbn = detail.AuxiliaryAccountCanAdoptedKbn;

        return convertDetaillViewModel;
    }

    private getEditedOrNewRegisterBmnListSettingDetailViewModel(hojyoTabKbn: number): MigrationSettingDetailViewModel[] {
        let settingDatas: settingHojyoDetailData[] = [];
        let settingList: MigrationSettingDetailViewModel[] = [];
        if (hojyoTabKbn == MigrationHojyoTabKbn.Bmn) {
            settingDatas = this.m_bmnSettingDatas;
            settingList = this.m_HojyoSettingBmnList;
        } else if (hojyoTabKbn == MigrationHojyoTabKbn.Segment) {
            settingDatas = this.m_segmentSettingDatas;
            settingList = this.m_HojyoSettingSegmentList;
        } else if (hojyoTabKbn == MigrationHojyoTabKbn.Kouji){
            settingDatas = this.m_koujiSettingDatas;
            settingList = this.m_HojyoSettingKoujiList;
        }

        let editedBmnListSettingDetailViewModelList: MigrationSettingDetailViewModel[] = [];
        for (let detail of settingDatas) {
            if (detail.IsEdited || detail.MappingSettingKbn == MigrationMappingSettingKbn.NewRegister || detail.MappingSettingKbn == MigrationMappingSettingKbn.NewSundry) {
                let detailViewModel = settingList.singleOrNull((n: { SrcKmkCode: string; SrcHojyoCode: string; SrcHojyoKbn: number; }) => n.SrcKmkCode == detail.SrcKmkCode && n.SrcHojyoCode == detail.SrcHojyoCode && n.SrcHojyoKbn == detail.SrcHojyoKbn);
                let editedDetaillViewModel = new MigrationSettingDetailViewModel();
                MjsUtilities.deepCopy(editedDetaillViewModel, detailViewModel);
                editedDetaillViewModel.DstHojyoKbn1 = detail.HowRegist == -1 ? detailViewModel.DstHojyoKbn1 : detail.HowRegist;
                editedDetaillViewModel.DstHojyoCode = detail.DstHojyoCode;
                editedDetaillViewModel.MappingSettingKbn = detail.MappingSettingKbn;
                editedDetaillViewModel.OpeningBalance = detail.OpeningBalance;
                editedBmnListSettingDetailViewModelList.push(editedDetaillViewModel);
            }
        }
        return editedBmnListSettingDetailViewModelList;
    }
    // #endregion convert && DataGet(Data → ViewModel)

    // #region initializeinput
    private initWijmoAutoComplete(id: string): wjInput.AutoComplete {
        let cmb = new wjInput.AutoComplete(id, {
            itemsSource: [],
            cssMatch: 'easy-input-auto-complete-highlight',
            placeholder: '文字を入力して検索',
            displayMemberPath: 'value',
            selectedValuePath: 'key',
            minLength: 1,
            maxItems: 300,
            showDropDownButton: false,
            maxDropDownHeight: 185,
            delay: 100,
            formatItem: (sender: any, e: { data: { item: { SumAnaCode: number; }; value: any; }; item: { innerHTML: string; }; }) => {
                if (e.data.item.SumAnaCode == SumAnaCode.ManufacturingCost) {
                    e.item.innerHTML = `<span class="migration-blue">${e.data.value}</span>`;
                }
            },
            dropDownCssClass: 'migration'
        });

        $(cmb.hostElement).attr("style", "width: 170px;");

        cmb.textChanged.addHandler(function (s, e) {
            const maxLength: number = 14;
            let value = cmb.text;

            // SJIS以外の文字を削除
            value = MjsUtilities.removeNotSjisChars(value);

            if (value && value.match(ProcessMigration.inputFilterRegexpXSS)) {
                // 入力禁止文字を削除
                value = StringInput.removeInvalidChars(value, ProcessMigration.inputFilterRegexpXSSDell);
            }

            // 最大文字数より文字数が多い場合、入力内容を変更前の状態に戻す
            if (MjsUtilities.getSjisByteCount(value) > maxLength) {
                // 入力位置
                let caretPos = cmb.inputElement.selectionStart;
                // 変更前文字列の後半部分
                let lastStr = value.substr(caretPos!);
                // 変更前文字列の前半部分
                let maxLenForFirstStr = maxLength - MjsUtilities.getSjisByteCount(lastStr);
                let firstStr = MjsUtilities.cutString(value, maxLenForFirstStr);
                // 変更前文字列を復元
                value = firstStr + lastStr;
            }

            cmb.text = value;
        });

        // ドロップの禁止
        $(cmb.hostElement).on('drop dragover', function (e) {
            e.stopPropagation();
            e.preventDefault();
        });

        return cmb;
    }

    private initConvertItemWijmoAutoComplete(id: string, item: any): wjInput.AutoComplete {
        let cmb = new wjInput.AutoComplete(id, {
            itemsSource: item,
            cssMatch: 'easy-input-auto-complete-highlight',
            placeholder: '消費税区分を選択してください',
            displayMemberPath: 'value',
            selectedValuePath: 'key',
            minLength: 1,
            maxItems: 300,
            showDropDownButton: false,
            maxDropDownHeight: 185,
            delay: 100,
            formatItem: (sender: any, e: { data: { value: string; }; item: { innerHTML: string; }; }) => {
                if (e.data.value == '会社の設定に従う') {
                    e.item.innerHTML = `<span class="migration-blue">${e.data.value}</span>`;
                }
            },
            dropDownCssClass: 'migration'
        });

        $(cmb.hostElement).attr("style", "width: 280px;");
        $(cmb.dropDown).css("width", "280");

        cmb.textChanged.addHandler(function (s, e) {
            const maxLength: number = 36;
            let value = cmb.text;

            // SJIS以外の文字を削除
            value = MjsUtilities.removeNotSjisChars(value);

            if (value && value.match(ProcessMigration.inputFilterRegexpXSS)) {
                // 入力禁止文字を削除
                value = StringInput.removeInvalidChars(value, ProcessMigration.inputFilterRegexpXSSDell);
            }

            // 最大文字数より文字数が多い場合、入力内容を変更前の状態に戻す
            if (MjsUtilities.getSjisByteCount(value) > maxLength) {
                // 入力位置
                let caretPos = cmb.inputElement.selectionStart;
                // 変更前文字列の後半部分
                let lastStr = value.substr(caretPos!);
                // 変更前文字列の前半部分
                let maxLenForFirstStr = maxLength - MjsUtilities.getSjisByteCount(lastStr);
                let firstStr = MjsUtilities.cutString(value, maxLenForFirstStr);
                // 変更前文字列を復元
                value = firstStr + lastStr;
            }

            cmb.text = value;
        });

        // ドロップの禁止
        $(cmb.hostElement).on('drop dragover', function (e) {
            e.stopPropagation();
            e.preventDefault();
        });

        return cmb;
    }

    private initCurrencyInput(id: string): CurrencyInput {
        let inp = new CurrencyInput(id, this.$scope, this.$compile, null!, -9999999999999, 99999999999999, 170);
        inp.initialize();

        // 編集不可
        inp.inputControl.isReadOnly = true;

        // ドロップの禁止
        $(inp.inputControl.hostElement).on('drop dragover dragstart', function (e) {
            e.stopPropagation();
            e.preventDefault();
        });

        return inp;
    }

    private initImportRowNumericInput(id: string): NumericInput {
        let inp = new NumericInput(id, this.$scope, this.$compile, null!, null!, 1, 30, false, 80);
        inp.initialize();

        // ドロップの禁止
        $(inp.inputControl.hostElement).on('drop dragover', function (e) {
            e.stopPropagation();
            e.preventDefault();
        });

        return inp;
    }

    private initWijmoCombBox(id: string, item: any, formatItem: (sender: any, e: any) => void): wjInput.ComboBox {
        let cmb = new wjInput.ComboBox(id, {
            itemsSource: item,
            isRequired: true,
            isEditable: false,
            selectedValuePath: 'key',
            displayMemberPath: 'value',
            formatItem: formatItem,
            maxDropDownHeight: 210,
            dropDownCssClass: 'migration'
        });

        $(cmb.hostElement).attr("style", "width: 210px;");

        return cmb;
    }

    private initItemSettingWijmoCombBox(id: string, item: any, formatItem: (sender: any, e: any) => void, cloudItem: number): wjInput.ComboBox {
        let cmb = new wjInput.ComboBox(id, {
            itemsSource: item,
            isRequired: true,
            isEditable: false,
            selectedValuePath: 'key',
            displayMemberPath: 'value',
            formatItem: formatItem,
            maxDropDownHeight: 210,
            dropDownCssClass: 'migration'
        });

        cmb.selectedValue = cloudItem;
        if (cmb.selectedItem.value.slice(-1) == "*") {
            $(`${id} input`).addClass("migration-red");
        }

        cmb.lostFocus.addHandler((s, e) => {
            const id = s._e.id;
            if (s.selectedItem.value.slice(-1) == "*") {
                $(`#${id} input`).addClass("migration-red");
            } else {
                $(`#${id} input`).removeClass("migration-red");
            }
        });

        // widthはGridのセル幅と同じにすること
        $(cmb.hostElement).attr("style", "width: 154px; position: relative;left: 2px; right: 2px; font-size: 14px;");

        return cmb;
    }
    // #endregion initializeinput

    // #region other
    private showStep(migrationstep: number): void {
        let dispNo = this.dispStepNoMap[migrationstep];
        this.$timeout(() => {
            switch (migrationstep) {
                case MigrationStep.FileImport: this.$scope.stepFileImportDispNo = dispNo; break;
                case MigrationStep.ItemSetting: this.$scope.stepItemSettingDispNo = dispNo; break;
                case MigrationStep.KmkSetting: this.$scope.stepKmkSettingDispNo = dispNo; break;
                case MigrationStep.HojyoSetting: this.$scope.stepHojyoSettingDispNo = dispNo; break;
                case MigrationStep.SwkImport: this.$scope.stepSwkImportDispNo = dispNo; break;
                case MigrationStep.Finish: this.$scope.stepFinishDispNo = dispNo; break;
                default: break;
            }
        });

        const id = '#step' + this.getStepName(migrationstep);
        $(id).show();

        let stepList = [MigrationStep.FileImport, MigrationStep.ItemSetting, MigrationStep.KmkSetting, MigrationStep.HojyoSetting, MigrationStep.SwkImport, MigrationStep.Finish];
        stepList.forEach((step) => {
            if (this.isBeforeCurrentStep(step, false)) {
                $("#step" + this.getStepName(step) + "-content").addClass("step-content-border-left-deepblue");
            }
        });

        this.header.activeStepNav(migrationstep);
    }

    private hideStep(migrationstep: number): void {
        let name = this.getStepName(migrationstep);
        const id = '#step' + name;
        $(id).hide();
        this.hideLeftBorder(name);
        if (migrationstep == MigrationStep.FileImport) {
            $("#file-import").hide();
            this.visibleBtnSuccessFileImport(false);
        } else if (migrationstep == MigrationStep.ItemSetting) {
            this.isMigrationItemSettingGroupSaved = false;
            // angular用変数初期化
            this.$scope.itemSettingKisyuErrCnt = 0;
            this.$scope.itemSettingHojyoKisyuErrCnt = 0;
            this.$scope.itemSettingSwkErrCnt = 0;
            this.$scope.kisyuGridColumns = new wijmo.CollectionView();
            this.$scope.hojyoKisyuGridColumns = new wijmo.CollectionView();
            this.$scope.swkGridColumns = new wijmo.CollectionView();
            this.$scope.kisyuItemSettingErrLogList = null;
            this.$scope.hojyoKisyuItemSettingErrLogList = null;
            this.$scope.bmnKisyuItemSettingErrLogList = null;
            this.$scope.swkItemSettingErrLogList = null;
            this.$scope.isKisyuItemSettingErrLogCntOver = null;
            this.$scope.isHojyoKisyuItemSettingErrLogCntOver = null;
            this.$scope.isSwkItemSettingErrLogCntOver = null;
            this.$scope.hojyoKisyuImportErrLogList = null;
            this.$scope.bmnKisyuImportErrLogList = null;
            this.$scope.isHojyoKisyuImportErrLogCntOver = null;
            this.$scope.isBmnKisyuImportErrLogCntOver = null;
            this.$scope.itemSettingSaveStatus = null;
            this.$scope.itemConvertSettingTaxKbnErrCnt = 0;
            this.$scope.itemConvertSettingTaxRateErrCnt = 0;
            this.$scope.kessanList = null;
            this.$scope.taxKbnList = null;
            this.$scope.taxRateList = null;
            this.$scope.gyosyuList = null;
            this.$scope.ivJigyoKbnList = null;
            this.$scope.itemConvertSettingSaveStatus = null;
        } else if (migrationstep == MigrationStep.KmkSetting) {
            this.m_kmkSettingDatas = [];
            this.visibleBtnSuccessKmkSettingSave(false);
        } else if (migrationstep == MigrationStep.HojyoSetting) {
            this.m_hojyokmkSettingDatas = [];
            this.m_generalhojyoSettingDatas = [];
            this.m_bmnSettingDatas = [];
            this.m_segmentSettingDatas = [];
            this.m_koujiSettingDatas = [];
            this.visibleBtnSuccessHojyoKmkSettingSave(false);
            this.visibleBtnSuccessGeneralHojyoSettingSave(false);
            this.visibleBtnSuccessBmnSettingSave(false);
            this.visibleBtnSuccessSegmentSettingSave(false);
            this.visibleBtnSuccessKoujiSettingSave(false);
        } else if (migrationstep == MigrationStep.SwkImport) {
            $("#swkErrLogArea").hide();
            this.visibleBtnSuccessSwkImport(Status.Waiting);
        }

        this.header.notActiveStepNav(migrationstep);
    }

    private hideLeftBorder(stepName: string): void {
        $("#step" + stepName + "-content").removeClass("step-content-border-left-deepblue");
    }

    private getStepName(migrationstep: number): string {
        switch (migrationstep) {
            case MigrationStep.FileImport: return "FileImport";
            case MigrationStep.ItemSetting: return "ItemSetting";
            case MigrationStep.KmkSetting: return "KmkSetting";
            case MigrationStep.HojyoSetting: return "HojyoSetting";
            case MigrationStep.SwkImport: return "SwkImport";
            case MigrationStep.Finish: return "Finish";
            default: return "FileImport";
        }
    }

    private setSwkDescription(): void {
        let descStepNum: string[] = [];
        let descstepName: string[] = [];
        if (this.m_ItemConvertSettingGroup != null && this.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.length > 0) {
            descStepNum.push("②");
            descstepName.push("項目変換");
        }
        if (this.dispStepNoMap[MigrationStep.KmkSetting] != null) {
            switch (this.dispStepNoMap[MigrationStep.KmkSetting]) {
                case 3:
                    descStepNum.push("③");
                    break;
                case 4:
                    descStepNum.push("④");
                    break;
            }
            descstepName.push("科目");
        }
        if (this.dispStepNoMap[MigrationStep.HojyoSetting] != null) {
            switch (this.dispStepNoMap[MigrationStep.HojyoSetting]) {
                case 4:
                    descStepNum.push("④");
                    break;
                case 5:
                    descStepNum.push("⑤");
                    break;
            }
            descstepName.push("補助科目");
        }
        this.$scope.stepSwkImportDescription = "・" + descStepNum.join("、") + "で保存された" + descstepName.join("、") + "の設定に従って仕訳がインポートされます。"
    }
    // #endregion other

    // #region visibleBtnSuccess
    private visibleBtnSuccessFileImport(isVisible: boolean): void {
        if (isVisible) {
            $("#file-import-success").css("visibility", "visible");
            this.isFileImport = true;
        } else {
            $("#file-import-success").css("visibility", "hidden");
            this.isFileImport = false;
        }
    }

    private visibleBtnSuccessKmkSettingSave(isVisible: boolean): void {
        if (isVisible) {
            $("#kmksetting-save-success").css("visibility", "visible");
            this.isKmkSettingSave = true;
            this.$timeout(() => { this.$scope.isKmkSettingChanged = false; });
            if (this.m_KmkSetting.KisyuEnable) {
                this.kmkKisyuImportBtn.enable();
            }
        } else {
            $("#kmksetting-save-success").css("visibility", "hidden");
            this.isKmkSettingSave = false;
            this.$timeout(() => {
                if (this.m_KmkSetting.MappingSaved) {
                    this.$scope.isKmkSettingChanged = true;
                } else {
                    this.$scope.isKmkSettingChanged = false;
                }
            });

            if (this.kmkKisyuImportBtn.isEnabled()) {
                this.kmkKisyuImportBtn.disable();
                this.visibleBtnSuccessKmkKisyuImport(false); // 期首残高も必ず未保存
            }
        }
    }

    private visibleBtnSuccessKmkKisyuImport(isVisible: boolean): void {
        if (isVisible) {
            $("#kmkkisyu-import-success").css("visibility", "visible");
            this.isKmkKisyuImported = true;
        } else {
            $("#kmkkisyu-import-success").css("visibility", "hidden");
            this.isKmkKisyuImported = false;
        }
    }

    private visibleBtnSuccessHojyoKmkSettingSave(isVisible: boolean): void {
        if (isVisible) {
            $("#hojyokmksetting-save-success").css("visibility", "visible");
            this.isHojyoKmkSettingSave = true;
            this.$timeout(() => { this.$scope.isHojyoSettingChanged = false; });
            if (this.m_HojyoSetting.KisyuEnable) {
                this.hojyokmkKisyuImportBtn.enable();
            }
        } else {
            $("#hojyokmksetting-save-success").css("visibility", "hidden");
            this.isHojyoKmkSettingSave = false;
            this.$timeout(() => {
                if (this.m_HojyoSetting.MappingSaved) {
                    this.$scope.isHojyoSettingChanged = true;
                } else {
                    this.$scope.isHojyoSettingChanged = false;
                }
            });
            if (this.hojyokmkKisyuImportBtn.isEnabled()) {
                this.hojyokmkKisyuImportBtn.disable();
                this.visibleBtnSuccessHojyoKmkKisyuImport(false); // 期首残高も必ず未保存
            }
        }

        this.setHojyoSettingSaveInfoMsg();
    }

    private visibleBtnSuccessGeneralHojyoSettingSave(isVisible: boolean): void {
        if (isVisible) {
            $("#hojyokmksetting-save-success").css("visibility", "visible");
            this.isGeneralHojyoSettingSave = true;
            this.$timeout(() => { this.$scope.isGeneralHojyoSettingChanged = false; });
        } else {
            $("#hojyokmksetting-save-success").css("visibility", "hidden");
            this.isGeneralHojyoSettingSave = false;
            this.$timeout(() => {
                if (this.m_HojyoSetting.MappingSaved) {
                    this.$scope.isGeneralHojyoSettingChanged = true;
                } else {
                    this.$scope.isGeneralHojyoSettingChanged = false;
                }
            });
            if (this.hojyokmkKisyuImportBtn.isEnabled()) {
                this.hojyokmkKisyuImportBtn.disable();
                this.visibleBtnSuccessHojyoKmkKisyuImport(false); // 期首残高も必ず未保存
            }
        }

        this.setHojyoSettingSaveInfoMsg();
    }

    private visibleBtnSuccessBmnSettingSave(isVisible: boolean): void {
        if (isVisible) {
            $("#hojyokmksetting-save-success").css("visibility", "visible");
            this.isBmnSettingSave = true;
            this.$timeout(() => { this.$scope.isBmnSettingChanged = false; });
        } else {
            $("#hojyokmksetting-save-success").css("visibility", "hidden");
            this.isBmnSettingSave = false;
            this.$timeout(() => {
                if (this.m_HojyoSetting.MappingSaved) {
                    this.$scope.isBmnSettingChanged = true;
                } else {
                    this.$scope.isBmnSettingChanged = false;
                }
            });
            if (this.hojyokmkKisyuImportBtn.isEnabled()) {
                this.hojyokmkKisyuImportBtn.disable();
                this.visibleBtnSuccessHojyoKmkKisyuImport(false); // 期首残高も必ず未保存
            }
        }

        this.setHojyoSettingSaveInfoMsg();
    }

    private visibleBtnSuccessSegmentSettingSave(isVisible: boolean): void {
        if (isVisible) {
            $("#hojyokmksetting-save-success").css("visibility", "visible");
            this.isSegmentSettingSave = true;
            this.$timeout(() => { this.$scope.isSegmentSettingChanged = false; });
        } else {
            $("#hojyokmksetting-save-success").css("visibility", "hidden");
            this.isSegmentSettingSave = false;
            this.$timeout(() => {
                if (this.m_HojyoSetting.MappingSaved) {
                    this.$scope.isSegmentSettingChanged = true;
                } else {
                    this.$scope.isSegmentSettingChanged = false;
                }
            });

            if (this.hojyokmkKisyuImportBtn.isEnabled()) {
                this.hojyokmkKisyuImportBtn.disable();
                this.visibleBtnSuccessHojyoKmkKisyuImport(false); // 期首残高も必ず未保存
            }
        }

        this.setHojyoSettingSaveInfoMsg();
    }

    private visibleBtnSuccessKoujiSettingSave(isVisible: boolean): void {
        if (isVisible) {
            $("#hojyokmksetting-save-success").css("visibility", "visible");
            this.isKoujiSettingSave = true;
            this.$timeout(() => { this.$scope.isKoujiSettingChanged = false; });
        } else {
            $("#hojyokmksetting-save-success").css("visibility", "hidden");
            this.isKoujiSettingSave = false;
            this.$timeout(() => {
                if (this.m_HojyoSetting.MappingSaved) {
                    this.$scope.isKoujiSettingChanged = true;
                } else {
                    this.$scope.isKoujiSettingChanged = false;
                }
            });

            if (this.hojyokmkKisyuImportBtn.isEnabled()) {
                this.hojyokmkKisyuImportBtn.disable();
                this.visibleBtnSuccessHojyoKmkKisyuImport(false); // 期首残高も必ず未保存
            }
        }

        this.setHojyoSettingSaveInfoMsg();
    }

    private setHojyoSettingSaveInfoMsg() {
        this.$timeout(() => {
            let msgList = [];
            if (this.$scope.isHojyoSettingChanged || this.$scope.isGeneralHojyoSettingChanged || this.$scope.isBmnSettingChanged || this.$scope.isSegmentSettingChanged || this.$scope.isKoujiSettingChanged) {
                if (this.$scope.isHojyoSettingChanged) { msgList.push("補助科目"); }
                if (this.$scope.isGeneralHojyoSettingChanged) { msgList.push("汎用補助"); }
                if (this.$scope.isBmnSettingChanged) { msgList.push("部門"); }
                if (this.$scope.isSegmentSettingChanged) { msgList.push("セグメント"); }
                if (this.$scope.isKoujiSettingChanged) { msgList.push("工事"); }
                let msg = msgList.join("、");
                this.$scope.saveInfo = msg;
                $("#save-infomsg").removeClass("dp_none");
            } else {
                $("#save-infomsg").addClass("dp_none");
            }
        });
    }

    private visibleBtnSuccessHojyoKmkKisyuImport(isVisible: boolean): void {
        if (isVisible) {
            $("#hojyokmkkisyu-import-success").css("visibility", "visible");
            this.isHojyoKisyuImported = true;
            // 補助期首残高を確認するボタン活性
            this.hojyokmkKisyuCheckBtn.enable();
            // 部門期首残高を確認するボタン活性
            this.bmnKisyuCheckBtn.enable();
        } else {
            $("#hojyokmkkisyu-import-success").css("visibility", "hidden");
            this.isHojyoKisyuImported = false;
            // 補助期首残高を確認するボタン非活性
            this.hojyokmkKisyuCheckBtn.disable();
            // 部門期首残高を確認するボタン非活性
            this.bmnKisyuCheckBtn.disable();
        }
    }

    private visibleBtnSuccessSwkImport(statusCode: number): void {
        switch (statusCode) {
            case Status.Waiting:
            case Status.Runtime:
                this.isSwkInfoSave = false;
                $("#swk-import-success").hide();
                $("#swk-import-cancel").hide();
                $("#swk-import-err").hide();
                break;
            case Status.Completion:
                this.isSwkInfoSave = true;
                $("#swk-import-success").show();
                $("#swk-import-cancel").hide();
                $("#swk-import-err").hide();
                break;
            case Status.Canceled:
                this.isSwkInfoSave = true;
                $("#swk-import-success").hide();
                $("#swk-import-cancel").show();
                $("#swk-import-err").hide();
                break;
            case Status.ErrorExit:
                this.isSwkInfoSave = true;
                $("#swk-import-success").hide();
                $("#swk-import-cancel").hide();
                $("#swk-import-err").show();
                break;
            default:
                break;
        }
    }
    // #endregion visibleBtnSuccess

    // #region dialog
    private createAlertDialog(selector: string, title: string, message: string): AlertDialog {
        let okButton: SimpleButton = new Button(".on-ok" + "-" + selector.substring(1, selector.length));
        let alertDialog = new AlertDialog(selector, title, message, okButton);

        okButton.execute = () => {
            alertDialog.close();
        };

        return alertDialog;
    }

    private createConfirmDialog(selector: string, message: string, yesCallback: () => void, noCallback: () => void = null!): ConfirmDialog {
        const title: string = messageUtil.getMsg(Messages.Common.Title.CONFIRM);
        const yesSelector: string = ".on-yes" + "-" + selector.substring(1, selector.length);
        const noSelector: string = ".on-no" + "-" + selector.substring(1, selector.length);
        const closeSelector: string = ".close a";
        let yesButton: SimpleButton = new Button(yesSelector);
        let noButton: SimpleButton = new Button(noSelector);
        let confirmDialog = new ConfirmDialog(selector, title, message, yesButton, noButton);

        yesButton.execute = () => {
            confirmDialog.close();
            yesCallback();
        };

        noButton.execute = () => {
            confirmDialog.close();
            if (noCallback != null) {
                noCallback();
            }
        };

        $(confirmDialog.selector).keydown((e) => {
            let isYesFocus = $($(confirmDialog.selector).find(yesSelector)).is(':focus');
            let isNoFocus = $($(confirmDialog.selector).find(noSelector)).is(':focus');
            let isCloseFocus = $($(confirmDialog.selector).find(closeSelector)).is(':focus');
            if (confirmDialog.isOpening() && !isYesFocus && !isNoFocus && !isCloseFocus) {
                if (e.keyCode == wijmo.Key.Tab) {
                    e.preventDefault();
                    confirmDialog.onFocus(noSelector);
                }
            }
        });

        return confirmDialog;
    }
    // #endregion dialog

    private onShowToolTip(e: JQueryEventObject) {
        setTimeout(()=> {
            const offset = $(e.target).offset();
            const targetWidth = $(e.target).width();
            const targetHeight = $(e.target).height();
            const tooltipRef = $(".tooltip");
            tooltipRef.removeClass("bs-tooltip-left bs-tooltip-top bs-tooltip-bottom");
            tooltipRef.addClass("bs-tooltip-right");
            tooltipRef.css("transform", "");
            tooltipRef.css("margin-left", offset!.left + targetWidth + 3);
            tooltipRef.css("max-width", "100%");
            $(".tooltip-inner").css("max-width", "100%");
            setTimeout(() => {
                const tooltipHeight = tooltipRef.height();
                tooltipRef.css("top",  offset!.top + targetHeight / 2 - tooltipHeight / 2);
                $(".tooltip .arrow").css("top",  tooltipHeight / 2 - 7);
                $(".tooltip .arrow").css("left",  0);
            });
        });
    }
}

class ProcessMigrationMediatorItem extends MediatorGeneralItem {
    public m_control: JQuery;

    constructor(control: JQuery, canMoveEnter: boolean, canMoveTab: boolean, canMoveArrow: boolean = null!) {
        super(control);
        this.delegateKeyDownEvent = (mediator: Mediator, e: JQueryKeyEventObject) => {
            if (canMoveEnter) { this.mediatorEnterKeyDownEvent(mediator, e); }
            if (canMoveTab) { this.mediatorTabKeyDownEvent(mediator, e); }
            if (canMoveArrow) { this.mediatorArrowKeyDownEvent(mediator, e); }
        };
        this.m_control = control;
    }

    private mediatorEnterKeyDownEvent(mediator: Mediator, e: JQueryKeyEventObject): void {
        if (e.keyCode === wijmo.Key.Enter) {
            mediator.setFocusToNextItem();
            e.preventDefault();
        }
    }

    private mediatorTabKeyDownEvent(mediator: Mediator, e: JQueryKeyEventObject): void {
        if (e.keyCode === wijmo.Key.Tab) {
            if (e.shiftKey) {
                mediator.setFocusToPrevItem();
            } else {
                mediator.setFocusToNextItem();
            }
            e.preventDefault();
        }
    }

    private mediatorArrowKeyDownEvent(mediator: Mediator, e: JQueryKeyEventObject): void {
        switch (e.keyCode) {
            case wijmo.Key.Left:
            case wijmo.Key.Right:
                break;
            case wijmo.Key.Up:
            case wijmo.Key.Down:
                e.preventDefault();
                break;
        }
    }
}

class ProcessMigrationTaxKbnAutoCompleteMediatorItem extends MediatorGeneralItem {
    constructor(control: JQuery, canMoveEnter: boolean, canMoveTab: boolean, canMoveArrow: boolean = null!, cmb: any, mainClass: any) {
        super(control);
        this.delegateKeyDownEvent = (mediator: Mediator, e: JQueryKeyEventObject) => {
            if (canMoveEnter) { this.mediatorEnterKeyDownEvent(mediator, e); }
            if (canMoveTab) { this.mediatorTabKeyDownEvent(mediator, e); }
            if (canMoveArrow) { this.mediatorArrowKeyDownEvent(mediator, e); }
        };

        this.delegateBeforeBlur = (args: mediatorBeforeBlurArgs) => {
            let id: string = cmb._e.id;
            let no: number = Number(id.substring(15));

            // 消費税率の制御
            if (mainClass.m_ItemConvertSettingGroup != null && mainClass.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.length > 0) {
                if (!mainClass.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.any((n: { ConvertCategory: number; }) => n.ConvertCategory == MigrationConvertCategory.TaxRate)) {
                    let taxRateid = `#cmbSelectTaxRate${no}`;
                    let taxRates = mainClass.taxCodeTaxRateMap[cmb.selectedValue];
                    if (taxRates != null && taxRates != undefined) {
                        if (taxRates.length == 0) {
                            $(taxRateid).hide();
                        } else {
                            $(taxRateid).show();
                        }
                    }

                    if (cmb.selectedIndex == -1) {
                        // 空欄の場合は非表示
                        $(taxRateid).hide();
                    }
                }
            }

            // 業種の制御
            if (mainClass.m_ItemConvertSettingGroup != null && mainClass.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.length > 0) {
                if (!mainClass.m_ItemConvertSettingGroup.MigrateItemConvertSettingList.any((n: { ConvertCategory: number; }) => n.ConvertCategory == MigrationConvertCategory.TypeCd)) {
                    let typeCdid = `#cmbSelectGyosyu${no}`;
                    if (cmb.selectedValue != MigrationConvertItemTaxCodeKey.DefaultAutoSet && !mainClass.sTaxCodeKeyListByUseGyosyu.any((n: any) => n == cmb.selectedValue)) {
                        // 課税売上系以外の場合は業種非表示
                        $(typeCdid).hide();
                    } else {
                        $(typeCdid).show();
                    }

                    if (cmb.selectedIndex == -1) {
                        // 空欄の場合は非表示
                        $(typeCdid).hide();
                    }
                }
            }
        };
    }

    private mediatorEnterKeyDownEvent(mediator: Mediator, e: JQueryKeyEventObject): void {
        if (e.keyCode === wijmo.Key.Enter) {
            mediator.setFocusToNextItem();
            e.preventDefault();
        }
    }

    private mediatorTabKeyDownEvent(mediator: Mediator, e: JQueryKeyEventObject): void {
        if (e.keyCode === wijmo.Key.Tab) {
            if (e.shiftKey) {
                mediator.setFocusToPrevItem();
            } else {
                mediator.setFocusToNextItem();
            }
            e.preventDefault();
        }
    }

    private mediatorArrowKeyDownEvent(mediator: Mediator, e: JQueryKeyEventObject): void {
        switch (e.keyCode) {
            case wijmo.Key.Left:
            case wijmo.Key.Right:
                break;
            case wijmo.Key.Up:
            case wijmo.Key.Down:
                e.preventDefault();
                break;
        }
    }
}

class ProcessMigrationHowRegistCmbMediatorItem extends MediatorGeneralItem {
    constructor(control: JQuery, canMoveEnter: boolean, canMoveTab: boolean, canMoveArrow: boolean = null!, cmb: any, mainClass: any) {
        super(control);
        this.delegateKeyDownEvent = (mediator: Mediator, e: JQueryKeyEventObject) => {
            if (canMoveEnter) { this.mediatorEnterKeyDownEvent(mediator, e); }
            if (canMoveTab) { this.mediatorTabKeyDownEvent(mediator, e); }
            if (canMoveArrow) { this.mediatorArrowKeyDownEvent(mediator, e); }
        };

        this.delegateBeforeBlur = (args: mediatorBeforeBlurArgs) => {
            let id: string = cmb._e.id;
            let data: settingHojyoDetailData;
            let cmbSelectId: string;
            let isHojyo: boolean = id.indexOf("hojyo") > -1;
            let isGeneralHojyo: boolean = id.indexOf("generalhojyo") > -1;
            let isBmn: boolean = id.indexOf("bmn") > -1;
            let isSegment: boolean = id.indexOf("segment") > -1;
            let dNo: number = undefined!;
            let howRegistCmb: wjInput.ComboBox = cmb;
            let hojyoBmnCmb: wjInput.ComboBox;
            let inpSum: CurrencyInput = undefined!;

            if (isHojyo) {
                dNo = Number(id.substring(18));
            } else if (isGeneralHojyo) {
                dNo = Number(id.substring(25));
            } else if (isBmn) {
                dNo = Number(id.substring(16));
            } else if (isSegment) {
                dNo = Number(id.substring(20));
            } else {
                dNo = Number(id.substring(18));
            }

            if (isHojyo) {
                data = mainClass.m_hojyokmkSettingDatas[dNo];
                cmbSelectId = `#cmbSelectHojyo_hojyo${dNo}`;
                hojyoBmnCmb = mainClass.input_cmbSelectHojyos[dNo];
                inpSum = mainClass.input_inpHojyoSums[dNo];
            } else if (isGeneralHojyo) {
                data = mainClass.m_generalhojyoSettingDatas[dNo];
                cmbSelectId = `#cmbSelectHojyo_generalhojyo${dNo}`;
                hojyoBmnCmb = mainClass.input_cmbSelectGeneralHojyos[dNo];
            }  else if (isBmn) {
                data = mainClass.m_bmnSettingDatas[dNo];
                cmbSelectId = `#cmbSelectHojyo_bmn${dNo}`;
                hojyoBmnCmb = mainClass.input_cmbSelectBmns[dNo];
            } else if (isSegment) {
                data = mainClass.m_segmentSettingDatas[dNo];
                cmbSelectId = `#cmbSelectHojyo_segment${dNo}`;
                hojyoBmnCmb = mainClass.input_cmbSelectSegments[dNo];
            } else {
                data = mainClass.m_koujiSettingDatas[dNo];
                cmbSelectId = `#cmbSelectHojyo_kouji${dNo}`;
                hojyoBmnCmb = mainClass.input_cmbSelectKoujis[dNo];
            }

            if (howRegistCmb.selectedItem.value == "登録しない") {
                $(cmbSelectId).hide();
                if (isHojyo) {
                    inpSum.setText("");
                    inpSum.enabled = false;
                }
            }
            else {
                $(cmbSelectId).show();

                let olddata = MjsUtilities.deepObjectCopy(data);
                if (olddata.HowRegist != howRegistCmb.selectedValue) {
                    hojyoBmnCmb.isDisabled = false;
                }
            }
        };
    }

    private mediatorEnterKeyDownEvent(mediator: Mediator, e: JQueryKeyEventObject): void {
        if (e.keyCode === wijmo.Key.Enter) {
            mediator.setFocusToNextItem();
            e.preventDefault();
        }
    }

    private mediatorTabKeyDownEvent(mediator: Mediator, e: JQueryKeyEventObject): void {
        if (e.keyCode === wijmo.Key.Tab) {
            if (e.shiftKey) {
                mediator.setFocusToPrevItem();
            } else {
                mediator.setFocusToNextItem();
            }
            e.preventDefault();
        }
    }

    private mediatorArrowKeyDownEvent(mediator: Mediator, e: JQueryKeyEventObject): void {
        switch (e.keyCode) {
            case wijmo.Key.Left:
            case wijmo.Key.Right:
                break;
            case wijmo.Key.Up:
            case wijmo.Key.Down:
                e.preventDefault();
                break;
        }
    }
}

export class fileContentGrid extends AbstractGrid {
    /**
    * コンストラクタ
    * @param $scope
    * @param $timeout
    * @param name
    */
    constructor($scope: any, $timeout: any, name: string) {
        var gridOption = new GridOption();
        gridOption.allowResizing = wjGrid.AllowResizing.None;
        super($scope, $timeout, name, gridOption);
    }

    protected initialize(): void { }
    protected cellChangingEvent(e: wjGrid.CellRangeEventArgs): void { }
    protected cellChangeEvent(row: number, col: number): void { }
    protected cellEditEndingHandler(value: any, rowIndex: number, columnIndex: number): void
    protected cellEditEndingHandler(value: any, rowIndex: number, columnIndex: number, e: wijmo.EventArgs): void
    protected cellEditEndingHandler(value: any, rowIndex: number, columnIndex: number, e?: any): void { }
    protected cellEditEndedHandler(value: any, rowIndex: number, columnIndex: number): void { }
    protected cellItemEventHandler(row: number, col: number, cell: HTMLElement) { }
    protected headerItemFormatter(rowIndex: number, columnIndex: number, cell: HTMLElement): void { }
    protected cellItemFormatter(rowIndex: number, columnIndex: number, cell: HTMLElement): void { }
    protected cellResizeEvent(e: wjGrid.CellRangeEventArgs): void { }
    protected scrollEvent(e: wijmo.EventArgs): void { }
    protected headerDoubleClickEvent(col: number, row: number): void { }
    protected cellDoubleClickEvent(col: number, row: number): void { }
    protected headerContextMenuEvent(col: number, row: number): void { }
    protected cellContextMenuEvent(col: number, row: number): void { }
    protected gotFocusEvent(e: wijmo.EventArgs): void { }
    protected lostFocusEvent(e: wijmo.EventArgs): void { }
    protected backspaceKeyupEvent(row: number, col: number): void { }
    protected spaceKeyupEvent(row: number, col: number): void { }

    protected getFuncNameInitialized() { return this.getControlName() + "Init"; }
    protected getFuncNameCellEditEnding() { return this.getControlName() + "cellEditEnding"; }
    protected getFuncNameCellEditEnded() { return this.getControlName() + "cellEditEnded"; }
    protected getFuncNameResizedColum() { return this.getControlName() + "resizedColumn"; }
    protected getFuncNameScrollPositionChange() { return this.getControlName() + "ScrollPositionChanged"; }
    protected getFuncNameDraggedColumn() { return this.getControlName() + "DraggedColumn"; }
    protected getFuncNameItemFormatter() { return this.getControlName() + "itemFormatter"; }
    protected getFuncNameSelectionChanging() { return this.getControlName() + "SelectionChanging"; }
    protected getFuncNameSelectionChanged() { return this.getControlName() + "selectionChanged"; }
    protected getNameItemSource() { return this.getControlName() + "itemsSource"; }
    protected getFuncNameGotFocus() { return this.getControlName() + "GotFocus"; }
    protected getFuncNameLostFocus() { return this.getControlName() + "LostFocus"; }
}

class MigrationFileContentViewModel {
    public selected!: number;

    public csvData1!: string;
    public csvData2!: string;
    public csvData3!: string;
    public csvData4!: string;
    public csvData5!: string;
    public csvData6!: string;
    public csvData7!: string;
    public csvData8!: string;
    public csvData9!: string;
    public csvData10!: string;
    public csvData11!: string;
    public csvData12!: string;
    public csvData13!: string;
    public csvData14!: string;
    public csvData15!: string;
    public csvData16!: string;
    public csvData17!: string;
    public csvData18!: string;
    public csvData19!: string;
    public csvData20!: string;
    public csvData21!: string;
    public csvData22!: string;
    public csvData23!: string;
    public csvData24!: string;
    public csvData25!: string;
    public csvData26!: string;
    public csvData27!: string;
    public csvData28!: string;
    public csvData29!: string;
    public csvData30!: string;
    public csvData31!: string;
    public csvData32!: string;
    public csvData33!: string;
    public csvData34!: string;
    public csvData35!: string;
    public csvData36!: string;
    public csvData37!: string;
    public csvData38!: string;
    public csvData39!: string;
    public csvData40!: string;
    public csvData41!: string;
    public csvData42!: string;
    public csvData43!: string;
    public csvData44!: string;
    public csvData45!: string;
    public csvData46!: string;
    public csvData47!: string;
    public csvData48!: string;
    public csvData49!: string;
    public csvData50!: string;
    public csvData51!: string;
    public csvData52!: string;
    public csvData53!: string;
    public csvData54!: string;
    public csvData55!: string;
    public csvData56!: string;
    public csvData57!: string;
    public csvData58!: string;
    public csvData59!: string;
    public csvData60!: string;
    public csvData61!: string;
    public csvData62!: string;
    public csvData63!: string;
    public csvData64!: string;
    public csvData65!: string;
    public csvData66!: string;
    public csvData67!: string;
    public csvData68!: string;
    public csvData69!: string;
    public csvData70!: string;
    public csvData71!: string;
    public csvData72!: string;
    public csvData73!: string;
    public csvData74!: string;
    public csvData75!: string;
    public csvData76!: string;
    public csvData77!: string;
    public csvData78!: string;
    public csvData79!: string;
    public csvData80!: string;
    public csvData81!: string;
    public csvData82!: string;
    public csvData83!: string;
    public csvData84!: string;
    public csvData85!: string;
    public csvData86!: string;
    public csvData87!: string;
    public csvData88!: string;
    public csvData89!: string;
    public csvData90!: string;
    public csvData91!: string;
    public csvData92!: string;
    public csvData93!: string;
    public csvData94!: string;
    public csvData95!: string;
    public csvData96!: string;
    public csvData97!: string;
    public csvData98!: string;
    public csvData99!: string;
    public csvData100!: string;
}
