import Axios from 'axios';
import React from 'react';
import MainHome from './containers/pages/A000/AIKCMN001000001/MainHome';
import Login from './containers/pages/A001/AIKCMN002000004/Login';
import AccountRegister from './containers/pages/A130/AIKADT008000001/AccountRegister';
import { MainHomeViewModel } from './models/mainHomeViewModel';
import { AlertKbnEnum, setAlertMessage } from './stores/AlertMessage';

const App: React.FC = () => {
  let [selectClientYear, setSelectClientYear] = React.useState<number|undefined>();
  if (window.location.pathname == '/Login')
    {
        return <Login />
    }
    else if(window.location.pathname == '/RegisterAccount/DrillDown') { // 勘定科目登録のURLが指定された場合
      const initialUrl = '/api/v1/MainHome/Initial';

      /** 選択している年度を取得 */
      Axios.get<MainHomeViewModel>(initialUrl)
        .then(response => {
          setSelectClientYear(response.data.SelectClientYear);
        })
        .catch(error => {
            setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.message });
        });

      /* 勘定科目登録で×を押されたら勘定科目登録のウィンドウを閉じる */
      const onClose = () => {
        window.close();
      }
      
      /* 勘定科目登録の画面を呼び出す */
      return selectClientYear ? <AccountRegister onClose={onClose} fiscalYear={selectClientYear}/> : <></>;
    } 
  
  else if (
    ((window.location.pathname !== '/') && (window.location.pathname !== '/Riyokiyaku/riyokiyaku.pdf')) ||
    (window.location.search !== '' && !window.location.search.startsWith('?token='))
  ) {
    // 不正なURLの場合はエラー画面へ
    window.location.href = window.location.origin + '/StatusError?statusCode=404';
    return null;
  } else if (window.location.search.startsWith('?token=')) {
    // 初期起動時はリダイレクトする為、ホームページは表示させない
    return null;
  }
  return <MainHome />;
};

export default App;
