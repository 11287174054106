import React from 'react';
import moment from 'moment';

import * as wjGrid from 'wijmo/wijmo.grid';
import { FlexGrid } from 'wijmo/wijmo.react.grid';

import * as labels from '../../../../constants/labels';
import { ConfStatusEnum } from '../../../../constants/enums';
import { AuditResultListViewModel } from '../../../../models/auditResultListViewModel';
import { AuditResultCountVO } from '../../../../models/auditResultCountVO';
import { AuditResultBmnCountVO } from '../../../../models/auditResultBmnCountVO';

import externallinkSvg from '../../../../images/icon/16_externallink.svg';

const htmlLinkIcon = () =>
  `<span class="bg-svg-icon d-inline-block _icon" style="background-image: url(${externallinkSvg})">　</span>`;

type AuditResultListGridProp = {
  sources: AuditResultListViewModel;
  gridClassName?: string;
  linkTabIndex?: number;
  onRemarkLinkClick: (nmonth: number) => void;
  onNegativeLinkClick: (nmonth: number) => void;
  onJournalLinkClick: (nmonth: number) => void;
  onBuplicateLinkClick: (nmonth: number) => void;
  onExciseLinkClick: (nmonth: number) => void;
  onCheckListLinkClick: (nmonth: number) => void;
  onNoticeLinkClick: (nmonth: number) => void;
  auditTarget?: number;
};

type AuditResultListGridState = {};

declare global {
  interface Window {}
}

const remarkLink = (nmonth: number, label: string, tabIndex?: number) =>
  `<button type='button' class='btn btn-link p-0 border-0' onclick=window.handleRemarkLinkClick("${nmonth}") ${
    typeof tabIndex === 'undefined' ? '' : 'tabIndex=' + tabIndex
  }>${htmlLinkIcon()} ${label}</button>`;
const negativeLink = (nmonth: number, label: string, tabIndex?: number) =>
  `<button type='button' class='btn btn-link p-0 border-0' onclick=window.handleNegativeLinkClick("${nmonth}") ${
    typeof tabIndex === 'undefined' ? '' : 'tabIndex=' + tabIndex
  }>${htmlLinkIcon()} ${label}</button>`;
const journalLink = (nmonth: number, label: string, tabIndex?: number) =>
  `<button type='button' class='btn btn-link p-0 border-0' onclick=window.handleJournalLinkClick("${nmonth}") ${
    typeof tabIndex === 'undefined' ? '' : 'tabIndex=' + tabIndex
  }>${htmlLinkIcon()} ${label}</button>`;
const duplicateLink = (nmonth: number, label: string, tabIndex?: number) =>
  `<button type='button' class='btn btn-link p-0 border-0' onclick=window.handleBuplicateLinkClick("${nmonth}") ${
    typeof tabIndex === 'undefined' ? '' : 'tabIndex=' + tabIndex
  }>${htmlLinkIcon()} ${label}</button>`;
const exciseLink = (nmonth: number, label: string, tabIndex?: number) =>
  `<button type='button' class='btn btn-link p-0 border-0' onclick=window.handleExciseLinkClick("${nmonth}") ${
    typeof tabIndex === 'undefined' ? '' : 'tabIndex=' + tabIndex
  }>${htmlLinkIcon()} ${label}</button>`;
const checkListLink = (nmonth: number, label: string, tabIndex?: number) =>
  `<button type='button' class='btn btn-link p-0 border-0' onclick=window.handleCheckListLinkClick("${nmonth}") ${
    typeof tabIndex === 'undefined' ? '' : 'tabIndex=' + tabIndex
  }>${htmlLinkIcon()} ${label}</button>`;
const noticeLink = (nmonth: number, label: string, tabIndex?: number) =>
  `<button type='button' class='btn btn-link p-0 border-0' onclick=window.handleNoticeLinkClick("${nmonth}") ${
    typeof tabIndex === 'undefined' ? '' : 'tabIndex=' + tabIndex
  }>${htmlLinkIcon()} ${label}</button>`;

declare global {
  interface Window {
    handleRemarkLinkClick: (nmonth: number) => void;
    handleNegativeLinkClick: (nmonth: number) => void;
    handleJournalLinkClick: (nmonth: number) => void;
    handleBuplicateLinkClick: (nmonth: number) => void;
    handleExciseLinkClick: (nmonth: number) => void;
    handleCheckListLinkClick: (nmonth: number) => void;
    handleNoticeLinkClick: (nmonth: number) => void;
  }
}

/** 行ヘッダーの列数 */
const headerRowCnt = 4;

/** bindingの固定文言 */
const nMonthBinding = 'nMonth';

let rowKeys : string[];

/** 行のビューモデル */
const kmkRowKeys = [
  'RemarkIncDec.LastExecDate',
  'RemarkIncDec.AuditableCnt',
  'RemarkIncDec.CompleteCnt',
  'RemarkIncDec.AdmittedCnt',
  'RemarkIncDec.PendingCnt',
  'RemarkIncDec.SumCnt',
  'NegativeBalance.LastExecDate',
  'NegativeBalance.AuditableCnt',
  'NegativeBalance.CompleteCnt',
  'NegativeBalance.AdmittedCnt',
  'NegativeBalance.PendingCnt',
  'NegativeBalance.SumCnt',
  'JournalLine.LastExecDate',
  'JournalLine.AuditableCnt',
  'JournalLine.CompleteCnt',
  'JournalLine.AdmittedCnt',
  'JournalLine.PendingCnt',
  'JournalLine.SumCnt',
  'JournalDouble.LastExecDate',
  'JournalDouble.AuditableCnt',
  'JournalDouble.CompleteCnt',
  'JournalDouble.AdmittedCnt',
  'JournalDouble.PendingCnt',
  'JournalDouble.SumCnt',
  'TaxKubun.LastExecDate',
  'TaxKubun.AuditableCnt',
  'TaxKubun.CompleteCnt',
  'TaxKubun.AdmittedCnt',
  'TaxKubun.PendingCnt',
  'TaxKubun.SumCnt'
  /*
  'Checklist.LastExecDate',
  'Checklist.CheckCnt',
  'Checklist.UnCheckCnt',
  'Checklist.SumCnt',
  'Notice.NoticeCnt'
  */
];

const bmnRowKeys = [
    'RemarkIncDec.LastExecDate',
    'RemarkIncDec.AuditableCnt',
    'RemarkIncDec.CompleteCnt',
    'RemarkIncDec.AdmittedCnt',
    'RemarkIncDec.PendingCnt',
    'RemarkIncDec.SumCnt',
    'NegativeBalance.LastExecDate',
    'NegativeBalance.AuditableCnt',
    'NegativeBalance.CompleteCnt',
    'NegativeBalance.AdmittedCnt',
    'NegativeBalance.PendingCnt',
    'NegativeBalance.SumCnt'
];

class AuditResultListGrid extends React.Component<AuditResultListGridProp, AuditResultListGridState> {
  grid?: wjGrid.FlexGrid;

  constructor(props: AuditResultListGridProp) {
    super(props);
    const {
      onRemarkLinkClick,
      onNegativeLinkClick,
      onJournalLinkClick,
      onBuplicateLinkClick,
      onExciseLinkClick,
      onCheckListLinkClick,
      onNoticeLinkClick
    } = props;

    this.initializedGrid = this.initializedGrid.bind(this);
    this.handleItemFormatter = this.handleItemFormatter.bind(this);
    window.handleRemarkLinkClick = onRemarkLinkClick;
    window.handleNegativeLinkClick = onNegativeLinkClick;
    window.handleJournalLinkClick = onJournalLinkClick;
    window.handleBuplicateLinkClick = onBuplicateLinkClick;
    window.handleExciseLinkClick = onExciseLinkClick;
    window.handleCheckListLinkClick = onCheckListLinkClick;
    window.handleNoticeLinkClick = onNoticeLinkClick;
  }

  // 列ヘッダーと一覧データの宣言
  public getDataFromVm(sources: AuditResultListViewModel) {
    const { Counts, Months } = sources;

    const itemsSource: object[] = [];
    const columns: object[] = [
      {
        header: labels.AIKADT002000006_GRID_HEADER_NO,
        binding: 'No',
        width: 50,
        align: 'center'
      },
      {
        header: labels.AIKADT002000006_GRID_HEADER_AUDITTYPE,
        binding: 'Audit',
        width: 150
      },
      {
        header: labels.AIKADT002000006_GRID_HEADER_EXECUTINGTIMESTATSDETAIL,
        binding: 'Executig',
        width: 90,
        align: 'center'
      },
      {
        header: labels.AIKADT002000006_GRID_HEADER_EXECUTINGTIMESTATSDETAIL,
        binding: 'Timestatsdetail',
        width: 90,
        align: 'center'
      }
    ];

    // 一覧データの初期化
      if (this.props.auditTarget == 1) {
        rowKeys = kmkRowKeys;
      } else {
        rowKeys = bmnRowKeys;
      }

      rowKeys.forEach(key => {
        if (this.props.auditTarget == 1) {
          itemsSource.push(createRowHeader(key));
        } else {
          itemsSource.push(createBmnRowHeader(key));
        }
    });

    if (Months != undefined && Counts != undefined) {
      // 表示データが存在する場合、ヘッダー情報と一覧情報を生成

      // 一覧データの作成
      Months.forEach(month => {
        let auditResultCount: AuditResultCountVO | undefined = undefined;
        let auditResultBmnCount: AuditResultBmnCountVO | undefined = undefined;

        if (this.props.auditTarget == 1) {
          //科目表示
          // 月の監査結果情報を取得
          Counts.forEach(data => {
            if (month.NMonth === data.NMonth) auditResultCount = data;
          });

          // 一覧データに表示情報を設定
          rowKeys.forEach((rowKey, index) => {
            let item: string | Date = '';
            if (auditResultCount) {
              const rowKeyList = rowKey.split('.');
              if (auditResultCount[rowKeyList[0]] != undefined) {
                if (rowKeyList.length > 1) {
                  if (auditResultCount[rowKeyList[0]][rowKeyList[1]] != undefined) {
                    if (rowKeyList[1] === 'LastExecDate') {
                      item = moment(auditResultCount[rowKeyList[0]][rowKeyList[1]]).toDate();
                    } else {
                      item = auditResultCount[rowKeyList[0]][rowKeyList[1]];
                    }
                  }
                } else {
                  item = auditResultCount[rowKeyList[0]];
                }
              }
            }
            itemsSource[index][nMonthBinding + month.NMonth] = item;
          });
        } else {
          //部門表示
          // 月の監査結果情報を取得
          Counts.forEach(data => {
            if (month.NMonth === data.NMonth) auditResultBmnCount = data;
          });

          // 一覧データに表示情報を設定
          rowKeys.forEach((rowKey, index) => {
            let item: string | Date = '';
            if (auditResultBmnCount) {
              const rowKeyList = rowKey.split('.');
              if (auditResultBmnCount[rowKeyList[0]] != undefined) {
                if (rowKeyList.length > 1) {
                  if (auditResultBmnCount[rowKeyList[0]][rowKeyList[1]] != undefined) {
                    if (rowKeyList[1] === 'LastExecDate') {
                      item = moment(auditResultBmnCount[rowKeyList[0]][rowKeyList[1]]).toDate();
                    } else {
                      item = auditResultBmnCount[rowKeyList[0]][rowKeyList[1]];
                    }
                  }
                } else {
                  item = auditResultBmnCount[rowKeyList[0]];
                }
              }
            }
            itemsSource[index][nMonthBinding + month.NMonth] = item;
          });
        }
      });

      // 列ヘッダーの作成
      Months.forEach(month => {
        columns.push({
          header: month.MonthName,
          binding: nMonthBinding + month.NMonth,
          width: 110,
          align: 'right',
          format: 'yy/M/d H:mm'
        });
      });
    }
    return { columns, itemsSource };
  }

  public initializedGrid = (flexGrid: wjGrid.FlexGrid) => {
    this.grid = flexGrid;
    flexGrid.mergeManager = new RestrictedMergeManager(flexGrid);
  };

  public handleItemFormatter = (panel: wjGrid.GridPanel, row: number, col: number, cell: HTMLElement) => {
    if (panel.cellType === wjGrid.CellType.ColumnHeader) {
      // 列ヘッダー
      cell.classList.add('d-flex');
      cell.classList.add('justify-content-center');
      cell.classList.add('align-items-center');
      cell.classList.add('font-weight-normal');
      cell.innerHTML = panel.grid.columnHeaders.columns[col].header.split('\n').join('<br />');
      panel.grid.columnHeaders.rows.defaultSize = 45;
    } else if (panel.cellType === wjGrid.CellType.Cell) {
      if (col < headerRowCnt) {
        // 行ヘッダー
        cell.classList.add('wj-header');
        cell.classList.add('font-weight-normal');

        cell.innerHTML = panel
          .getCellData(row, col, false)
          .split('\n')
          .join('<br />');

        if (col === 3) {
          if (rowKeys[row].match('CompleteCnt') || rowKeys[row] === 'Checklist.CheckCnt') {
            cell.classList.add(['text', String(ConfStatusEnum[ConfStatusEnum.Complete])].join('-'));
          } else if (rowKeys[row].match('AdmittedCnt') || rowKeys[row] === 'Checklist.UnCheckCnt') {
            cell.classList.add(['text', String(ConfStatusEnum[ConfStatusEnum.Admitted])].join('-'));
          } else if (rowKeys[row].match('PendingCnt')) {
            cell.classList.add(['text', String(ConfStatusEnum[ConfStatusEnum.Pending])].join('-'));
          }
        }
      } else {
        // データ
        const column: wjGrid.Column = panel.columns[col];
        const val = panel.getCellData(row, col, false);
        if (rowKeys[row].match('LastExecDate')) {
          // 最終実行日
          cell.classList.add('d-flex');
          cell.classList.add('justify-content-center');
          cell.classList.add('align-items-center');
          if (val === '') {
            cell.classList.add('_grayout');
            cell.innerHTML = '未実行';
          }
        } else if (rowKeys[row].match('Cnt')) {
          // 件数項目
          if (val === '') {
            cell.classList.add('_grayout');
          } else {
            const lbl = [val, labels.COMMON_FUNCTION_GRID_TOTALCOUNT].join(' ');
            const nmonth: number = Number(column.binding.slice(nMonthBinding.length));

            cell.innerHTML = lbl;
            if (rowKeys[row].match('CompleteCnt') || rowKeys[row] === 'Checklist.CheckCnt') {
              cell.classList.add(['text', String(ConfStatusEnum[ConfStatusEnum.Complete])].join('-'));
            } else if (rowKeys[row].match('AdmittedCnt') || rowKeys[row] === 'Checklist.UnCheckCnt') {
              cell.classList.add(['text', String(ConfStatusEnum[ConfStatusEnum.Admitted])].join('-'));
            } else if (rowKeys[row].match('PendingCnt')) {
              cell.classList.add(['text', String(ConfStatusEnum[ConfStatusEnum.Pending])].join('-'));
            } else if (rowKeys[row] === 'RemarkIncDec.SumCnt') {
              cell.innerHTML = remarkLink(nmonth, lbl, this.props.linkTabIndex);
            } else if (rowKeys[row] === 'NegativeBalance.SumCnt') {
              cell.innerHTML = negativeLink(nmonth, lbl, this.props.linkTabIndex);
            } else if (rowKeys[row] === 'JournalLine.SumCnt') {
              cell.innerHTML = journalLink(nmonth, lbl, this.props.linkTabIndex);
            } else if (rowKeys[row] === 'JournalDouble.SumCnt') {
              cell.innerHTML = duplicateLink(nmonth, lbl, this.props.linkTabIndex);
            } else if (rowKeys[row] === 'TaxKubun.SumCnt') {
              cell.innerHTML = exciseLink(nmonth, lbl, this.props.linkTabIndex);
            } else if (rowKeys[row] === 'Checklist.SumCnt') {
              cell.innerHTML = checkListLink(nmonth, lbl, this.props.linkTabIndex);
            } else if (rowKeys[row] === 'Notice.NoticeCnt') {
              cell.innerHTML = noticeLink(nmonth, lbl, this.props.linkTabIndex);
            }
          }
        }
      }
    }
  };

  public render() {
    const { columns, itemsSource } = this.getDataFromVm(this.props.sources);
    return columns.length < 1 || itemsSource.length < 1 ? null : (
      <span className='audit-result-list-grid'>
        <FlexGrid
          className={this.props.gridClassName}
          autoGenerateColumns={false}
          columns={columns}
          allowResizing={wjGrid.AllowResizing.None}
          allowDragging={wjGrid.AllowDragging.None}
          allowSorting={false}
          isReadOnly={true}
          selectionMode={wjGrid.SelectionMode.None}
          headersVisibility={wjGrid.HeadersVisibility.Column}
          alternatingRowStep={0}
          frozenColumns={headerRowCnt}
          itemsSource={itemsSource}
          initialized={this.initializedGrid}
          itemFormatter={this.handleItemFormatter}
        />
      </span>
    );
  }
}

/** 結合を設定 */
class RestrictedMergeManager extends wjGrid.MergeManager {
  constructor(flexGrid: wjGrid.FlexGrid) {
    super(flexGrid);
  }
  getMergedRange = (p: wjGrid.GridPanel, r: number, c: number, clip = true) => {
    let rng = new wjGrid.CellRange(r, c);
    if (c < headerRowCnt) {
      // 行ヘッダーのみ結合
      let val = p.getCellData(r, c, false);

      // 縦の結合
      // 上下に同じ値が有れば結合していく
      while (rng.row > 0 && p.getCellData(rng.row - 1, c, false) === val) {
        rng.row--;
      }
      while (rng.row2 < p.rows.length - 1 && p.getCellData(rng.row2 + 1, c, false) === val) {
        rng.row2++;
      }

      // 横の結合
      // 左右に同じ値が有れば結合していく
      while (rng.col > 0 && p.getCellData(r, rng.col - 1, false) === val) {
        rng.col--;
      }
      while (rng.col2 < p.columns.length - 1 && p.getCellData(r, rng.col2 + 1, false) === val) {
        rng.col2++;
      }
    }
    return rng;
  };
}

/** 行ヘッダーの文言取得 */
const createRowHeader = (key: string) => {
  switch (key) {
    case 'RemarkIncDec.LastExecDate':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO1,
        Audit: labels.AIKADT002000006_GRID_HEADER_MONTHLYBALANCEAUDITREMARKABLEICCREASEANDDECREASE,
        Executig: labels.AIKADT002000006_GRID_HEADER_LASTEXECUTIONTIME,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_LASTEXECUTIONTIME
      };
    case 'RemarkIncDec.AuditableCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO1,
        Audit: labels.AIKADT002000006_GRID_HEADER_MONTHLYBALANCEAUDITREMARKABLEICCREASEANDDECREASE,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_UNCONFIRMED
      };
    case 'RemarkIncDec.CompleteCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO1,
        Audit: labels.AIKADT002000006_GRID_HEADER_MONTHLYBALANCEAUDITREMARKABLEICCREASEANDDECREASE,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_CONFIRMATIONOK
      };
    case 'RemarkIncDec.AdmittedCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO1,
        Audit: labels.AIKADT002000006_GRID_HEADER_MONTHLYBALANCEAUDITREMARKABLEICCREASEANDDECREASE,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_CONFIRMATIONNG
      };
    case 'RemarkIncDec.PendingCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO1,
        Audit: labels.AIKADT002000006_GRID_HEADER_MONTHLYBALANCEAUDITREMARKABLEICCREASEANDDECREASE,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_PENDING
      };
    case 'RemarkIncDec.SumCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO1,
        Audit: labels.AIKADT002000006_GRID_HEADER_MONTHLYBALANCEAUDITREMARKABLEICCREASEANDDECREASE,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_TOTAL
      };
    case 'NegativeBalance.LastExecDate':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO2,
        Audit: labels.AIKADT002000006_GRID_HEADER_MONTHLYBALANCEAUDITMINUSBALANCE,
        Executig: labels.AIKADT002000006_GRID_HEADER_LASTEXECUTIONTIME,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_LASTEXECUTIONTIME
      };
    case 'NegativeBalance.AuditableCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO2,
        Audit: labels.AIKADT002000006_GRID_HEADER_MONTHLYBALANCEAUDITMINUSBALANCE,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_UNCONFIRMED
      };
    case 'NegativeBalance.CompleteCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO2,
        Audit: labels.AIKADT002000006_GRID_HEADER_MONTHLYBALANCEAUDITMINUSBALANCE,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_CONFIRMATIONOK
      };
    case 'NegativeBalance.AdmittedCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO2,
        Audit: labels.AIKADT002000006_GRID_HEADER_MONTHLYBALANCEAUDITMINUSBALANCE,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_CONFIRMATIONNG
      };
    case 'NegativeBalance.PendingCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO2,
        Audit: labels.AIKADT002000006_GRID_HEADER_MONTHLYBALANCEAUDITMINUSBALANCE,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_PENDING
      };
    case 'NegativeBalance.SumCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO2,
        Audit: labels.AIKADT002000006_GRID_HEADER_MONTHLYBALANCEAUDITMINUSBALANCE,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_TOTAL
      };
    case 'JournalLine.LastExecDate':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO3,
        Audit: labels.AIKADT002000006_GRID_HEADER_JOURNALIZATIONITEMAUDIT,
        Executig: labels.AIKADT002000006_GRID_HEADER_LASTEXECUTIONTIME,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_LASTEXECUTIONTIME
      };
    case 'JournalLine.AuditableCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO3,
        Audit: labels.AIKADT002000006_GRID_HEADER_JOURNALIZATIONITEMAUDIT,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_UNCONFIRMED
      };
    case 'JournalLine.CompleteCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO3,
        Audit: labels.AIKADT002000006_GRID_HEADER_JOURNALIZATIONITEMAUDIT,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_CONFIRMATIONOK
      };
    case 'JournalLine.AdmittedCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO3,
        Audit: labels.AIKADT002000006_GRID_HEADER_JOURNALIZATIONITEMAUDIT,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_CONFIRMATIONNG
      };
    case 'JournalLine.PendingCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO3,
        Audit: labels.AIKADT002000006_GRID_HEADER_JOURNALIZATIONITEMAUDIT,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_PENDING
      };
    case 'JournalLine.SumCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO3,
        Audit: labels.AIKADT002000006_GRID_HEADER_JOURNALIZATIONITEMAUDIT,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_TOTAL
      };
    case 'JournalDouble.LastExecDate':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO4,
        Audit: labels.AIKADT002000006_GRID_HEADER_JOURNALIZATIONDUPLICATEAUDIT,
        Executig: labels.AIKADT002000006_GRID_HEADER_LASTEXECUTIONTIME,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_LASTEXECUTIONTIME
      };
    case 'JournalDouble.AuditableCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO4,
        Audit: labels.AIKADT002000006_GRID_HEADER_JOURNALIZATIONDUPLICATEAUDIT,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_UNCONFIRMED
      };
    case 'JournalDouble.CompleteCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO4,
        Audit: labels.AIKADT002000006_GRID_HEADER_JOURNALIZATIONDUPLICATEAUDIT,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_CONFIRMATIONOK
      };
    case 'JournalDouble.AdmittedCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO4,
        Audit: labels.AIKADT002000006_GRID_HEADER_JOURNALIZATIONDUPLICATEAUDIT,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_CONFIRMATIONNG
      };
    case 'JournalDouble.PendingCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO4,
        Audit: labels.AIKADT002000006_GRID_HEADER_JOURNALIZATIONDUPLICATEAUDIT,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_PENDING
      };
    case 'JournalDouble.SumCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO4,
        Audit: labels.AIKADT002000006_GRID_HEADER_JOURNALIZATIONDUPLICATEAUDIT,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_TOTAL
      };
    case 'TaxKubun.LastExecDate':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO5,
        Audit: labels.AIKADT002000006_GRID_HEADER_TAXKUBUNAUDIT,
        Executig: labels.AIKADT002000006_GRID_HEADER_LASTEXECUTIONTIME,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_LASTEXECUTIONTIME
      };
    case 'TaxKubun.AuditableCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO5,
        Audit: labels.AIKADT002000006_GRID_HEADER_TAXKUBUNAUDIT,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_UNCONFIRMED
      };
    case 'TaxKubun.CompleteCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO5,
        Audit: labels.AIKADT002000006_GRID_HEADER_TAXKUBUNAUDIT,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_CONFIRMATIONOK
      };
    case 'TaxKubun.AdmittedCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO5,
        Audit: labels.AIKADT002000006_GRID_HEADER_TAXKUBUNAUDIT,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_CONFIRMATIONNG
      };
    case 'TaxKubun.PendingCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO5,
        Audit: labels.AIKADT002000006_GRID_HEADER_TAXKUBUNAUDIT,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_PENDING
      };
    case 'TaxKubun.SumCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO5,
        Audit: labels.AIKADT002000006_GRID_HEADER_TAXKUBUNAUDIT,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_TOTAL
      };
    case 'Checklist.LastExecDate':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO6,
        Audit: labels.AIKADT002000006_GRID_HEADER_CHECKLISTNOTICE,
        Executig: labels.AIKADT002000006_GRID_HEADER_LASTEXECUTIONTIME,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_LASTEXECUTIONTIME
      };
    case 'Checklist.CheckCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO6,
        Audit: labels.AIKADT002000006_GRID_HEADER_CHECKLISTNOTICE,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_CHECKED
      };
    case 'Checklist.UnCheckCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO6,
        Audit: labels.AIKADT002000006_GRID_HEADER_CHECKLISTNOTICE,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_UNCHECKED
      };
    case 'Checklist.SumCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO6,
        Audit: labels.AIKADT002000006_GRID_HEADER_CHECKLISTNOTICE,
        Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_TOTAL
      };
    case 'Notice.NoticeCnt':
      return {
        No: labels.AIKADT002000006_GRID_HEADER_NO6,
        Audit: labels.AIKADT002000006_GRID_HEADER_CHECKLISTNOTICE,
        Executig: labels.AIKADT002000006_GRID_HEADER_NOTICE,
        Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_NOTICE
      };
  }
  return {};
};

/** 行ヘッダーの文言取得 */
const createBmnRowHeader = (key: string) => {
    switch (key) {
        case 'RemarkIncDec.LastExecDate':
            return {
                No: labels.AIKADT002000006_GRID_HEADER_NO1,
                Audit: labels.AIKADT002000006_GRID_HEADER_MONTHLYBALANCEAUDITREMARKABLEICCREASEANDDECREASE,
                Executig: labels.AIKADT002000006_GRID_HEADER_LASTEXECUTIONTIME,
                Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_LASTEXECUTIONTIME
            };
        case 'RemarkIncDec.AuditableCnt':
            return {
                No: labels.AIKADT002000006_GRID_HEADER_NO1,
                Audit: labels.AIKADT002000006_GRID_HEADER_MONTHLYBALANCEAUDITREMARKABLEICCREASEANDDECREASE,
                Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
                Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_UNCONFIRMED
            };
        case 'RemarkIncDec.CompleteCnt':
            return {
                No: labels.AIKADT002000006_GRID_HEADER_NO1,
                Audit: labels.AIKADT002000006_GRID_HEADER_MONTHLYBALANCEAUDITREMARKABLEICCREASEANDDECREASE,
                Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
                Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_CONFIRMATIONOK
            };
        case 'RemarkIncDec.AdmittedCnt':
            return {
                No: labels.AIKADT002000006_GRID_HEADER_NO1,
                Audit: labels.AIKADT002000006_GRID_HEADER_MONTHLYBALANCEAUDITREMARKABLEICCREASEANDDECREASE,
                Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
                Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_CONFIRMATIONNG
            };
        case 'RemarkIncDec.PendingCnt':
            return {
                No: labels.AIKADT002000006_GRID_HEADER_NO1,
                Audit: labels.AIKADT002000006_GRID_HEADER_MONTHLYBALANCEAUDITREMARKABLEICCREASEANDDECREASE,
                Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
                Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_PENDING
            };
        case 'RemarkIncDec.SumCnt':
            return {
                No: labels.AIKADT002000006_GRID_HEADER_NO1,
                Audit: labels.AIKADT002000006_GRID_HEADER_MONTHLYBALANCEAUDITREMARKABLEICCREASEANDDECREASE,
                Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
                Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_TOTAL
            };
        case 'NegativeBalance.LastExecDate':
            return {
                No: labels.AIKADT002000006_GRID_HEADER_NO2,
                Audit: labels.AIKADT002000006_GRID_HEADER_MONTHLYBALANCEAUDITMINUSBALANCE,
                Executig: labels.AIKADT002000006_GRID_HEADER_LASTEXECUTIONTIME,
                Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_LASTEXECUTIONTIME
            };
        case 'NegativeBalance.AuditableCnt':
            return {
                No: labels.AIKADT002000006_GRID_HEADER_NO2,
                Audit: labels.AIKADT002000006_GRID_HEADER_MONTHLYBALANCEAUDITMINUSBALANCE,
                Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
                Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_UNCONFIRMED
            };
        case 'NegativeBalance.CompleteCnt':
            return {
                No: labels.AIKADT002000006_GRID_HEADER_NO2,
                Audit: labels.AIKADT002000006_GRID_HEADER_MONTHLYBALANCEAUDITMINUSBALANCE,
                Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
                Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_CONFIRMATIONOK
            };
        case 'NegativeBalance.AdmittedCnt':
            return {
                No: labels.AIKADT002000006_GRID_HEADER_NO2,
                Audit: labels.AIKADT002000006_GRID_HEADER_MONTHLYBALANCEAUDITMINUSBALANCE,
                Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
                Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_CONFIRMATIONNG
            };
        case 'NegativeBalance.PendingCnt':
            return {
                No: labels.AIKADT002000006_GRID_HEADER_NO2,
                Audit: labels.AIKADT002000006_GRID_HEADER_MONTHLYBALANCEAUDITMINUSBALANCE,
                Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
                Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_PENDING
            };
        case 'NegativeBalance.SumCnt':
            return {
                No: labels.AIKADT002000006_GRID_HEADER_NO2,
                Audit: labels.AIKADT002000006_GRID_HEADER_MONTHLYBALANCEAUDITMINUSBALANCE,
                Executig: labels.AIKADT002000006_GRID_HEADER_STATUS,
                Timestatsdetail: labels.AIKADT002000006_GRID_HEADER_TOTAL
            };
    }
    return {};
};

export default AuditResultListGrid;
