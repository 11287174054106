import React from 'react';
import Axios from 'axios';
import * as wjGrid from 'wijmo/wijmo.grid';
import { FlexGrid } from 'wijmo/wijmo.react.grid';
import * as Consts from '../../../../constants/constant';
import * as Enums from '../../../../constants/enums';
import * as Maps from '../../../../constants/dataMaps';
import * as Labels from '../../../../constants/labels';
import * as Messages from '../../../../constants/message';
import { getValueFromDataMap as GetValueFromDataMap } from '../../../../utils/dataMapsUtils';
import { bindValueToMessage as BindValueToMessage } from '../../../../utils/messageUtils';
import { setAlertMessage, AlertKbnEnum } from '../../../../stores/AlertMessage';
import { setIsLoading, getLodingStatus } from '../../../../stores/NowLoading';
import JournalLine from '../AIKADT002000004/JournalLine';

// **************************************************
// コンポーネントのインポート
// **************************************************
import {
  UpperActionLayout,
  UpperActionLayoutHeader,
  UpperActionLayoutAction,
  UpperActionLayoutContents
} from '../../../../components/templates/UpperActionLayout';
import PageHeader from '../../../../components/molecules/CMN/PageHeader/PageHeader';
import MonthSelect, { MODE } from '../../../organisms/A000/MonthSelect';
import ExcelAuditTargetOutputsetting from '../../../organisms/A000/ExcelAuditTargetOutputsetting';
import AuditExecutionButton from '../../../../components/molecules/CMN/AuditExecutionButton/AuditExecutionButton';
import AuditResultSummaryGrid, {
  setFilterDefinition,
  setSortCriteria
} from '../../../../components/molecules/CMN/Grid/AuditResultSummaryGrid';
import AuditExecPopup from '../../../../components/molecules/A040/AuditExecPopup';
import AuditExecGridPopup from '../../../../components/molecules/A040/AuditExecGridPopup';
import AuditExecCheckPopup from '../../../../components/molecules/A040/AuditExecCheckPopup';
import AuditBatchExecPopup from '../../../../components/molecules/A040/AuditBatchExecPopup';
import ProgressBarPopup from '../../../../components/molecules/A040/ProgressBarPopup';
import BmnAuditInfoPopup from '../../../../components/molecules/A040/BmnAuditInfoPopup';
import Result from '../AIKADT002000002/Result';
import ImportApi from '../../A080/AIKADT005000001/ImportApi';
import AuditExecComboPopup from '../../../../components/molecules/A040/AuditExecComboPopup';
import IconButton from '../../../../components/molecules/CMN/IconButton/IconButton';
import ExcelButton from '../../../../components/atoms/Icon/FileExcel';
import TableButton from '../../../../components/atoms/Icon/Table';
import Button from 'reactstrap/lib/Button';

// **************************************************
// Modelのインポート
// **************************************************
import { ExecutionResultViewModel } from '../../../../models/executionResultViewModel';
import { AuditRemarkIncDecViewModel } from '../../../../models/auditRemarkIncDecViewModel';
import { AuditNegativeBalanceViewModel } from '../../../../models/auditNegativeBalanceViewModel';
import { AuditJournalLineViewModel } from '../../../../models/auditJournalLineViewModel';
import { JournalLineRuleVO } from '../../../../models/journalLineRuleVO';
import { JournalLineRuleAuditVO } from '../../../../models/journalLineRuleAuditVO';
import { AuditJournalDoubleViewModel } from '../../../../models/auditJournalDoubleViewModel';
import { AuditTaxKubunViewModel } from '../../../../models/auditTaxKubunViewModel';
import { MonthlyBalanceRuleSettingViewModel } from '../../../../models/monthlyBalanceRuleSettingViewModel';
import { RulePatternViewModel } from '../../../../models/rulePatternViewModel';
import { AuditJournalDoubleCheckPatternViewModel } from '../../../../models/auditJournalDoubleCheckPatternViewModel';
import { DblJokenVO } from '../../../../models/dblJokenVO';
import { BmnSepAuditInfoViewModel } from '../../../../models/bmnSepAuditInfoViewModel';
import AuditTargetManager, {AuditTargetManagerVo} from '../../../organisms/A000/AuditTargetManager';
import { BmnInformationViewModel } from '../../../../models/bmnInformationViewModel';
import { MasterKbn } from '../../../../VKZ/core/constants/constant';

// **************************************************
// URL
// **************************************************
// 共通
export const monthUrl = '/api/v1/ExecutionResult/Month';
// 部門別監査状況
export const bmnAuditInfoUrl = 'api/v1/ExecutionResult/BmnAuditInitial';
// // 著増減
export const ridAuditUrl = '/api/v1/AuditRemarkIncDec/Audit';
export const ridPrgUrl = '/api/v1/AuditRemarkIncDec/Progress';
// マイナス残高
export const nbAuditUrl = '/api/v1/AuditNegativeBalance/Audit';
export const nbPrgUrl = '/api/v1/AuditNegativeBalance/Progress';
// 仕訳明細
export const jlListUrl = '/api/v1/AuditJournalLine/RuleList';
export const jlAuditUrl = '/api/v1/AuditJournalLine/Audit';
export const jlPrgUrl = '/api/v1/AuditJournalLine/Progress';
// 仕訳重複
export const jdCheckPatternUrl = '/api/v1/AuditJournalDouble/GetCheckPattern';
export const jdAuditUrl = '/api/v1/AuditJournalDouble/Audit';
export const jdPrgUrl = '/api/v1/AuditJournalDouble/Progress';
// 消費税区分
export const tkAuditUrl = '/api/v1/AuditTaxKubun/Audit';
export const tkPrgUrl = '/api/v1/AuditTaxKubun/Progress';

// データ連携確認
export const resultStatusUrl = '/api/v1/MainHome/ResultStatus';

// 月次残高ルール設定一覧情報取得
export const monthlyBalanceSettingeUrl = '/api/v1/MonthlyBalanceRuleSetting/Initial';

// 月次残高ルールパターン取得
export const rulePatternUrl = '/api/v1/RulePattern/Create';

// 処理判定
const JudgeURL = 'api/v1/CommonStartJudgement/Judge';
// システム履歴記入
const WriteSysHistoryURL = 'api/v1/CommonSystemHistory/Write';
// ﾏｽﾀ基本情報取得URL
export const masterInfoURL = 'api/v1/AuditTargetManager/GetMasterInfo';

type ExecutionResultProps = {
  onClose: () => void;
  fiscalYear: number;
};
const initializationViewModel: ExecutionResultViewModel = {
  BalanceList: []
};
const initializationRulePatternViewModel: RulePatternViewModel = {
  RulePatternItems: []
};
const defaultAuditTarget: AuditTargetManagerVo = {
  target: 1
}

const ExecutionResult: React.FC<ExecutionResultProps> = props => {
  // **************************************************
  // 初期表示ステート
  // **************************************************
  const [initState, setInitState] = React.useState(true);
  // 通信中フラグ
  const [isScreenLock, setIsScreenLock] = React.useState(false);
  // **************************************************
  // 部門別監査状況設定
  // **************************************************
  // 部門別監査状況ViewModel
  const [bmnViewModel, setBmnViewModel] = React.useState<BmnSepAuditInfoViewModel>(
    new BmnSepAuditInfoViewModel()
  );
  const [bmnAuditInfoActivated, setBmnAuditInfoActivated] = React.useState(false);
  const [bmnAuditInfoHidden, setbmnAuditInfoHidden] = React.useState(true);
  // コード属性
  const [codeAttr, setCodeAttr] = React.useState(0);
  // 部門別監査状況ボタンクリック
  async function handleBmnAuditInfoClick() {
    setIsScreenLock(true);
    setIsLoading(true);
    try {
      await getBmnAuditData(bmnAuditInfoUrl);
      await getBmnCodeAttr();
      setBmnAuditInfoActivated(true);
      setIsScreenLock(false);
      setIsLoading(false);
    } catch (error) {
      if (typeof error.response.data !== 'undefined') {
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
      }
      setIsScreenLock(false);
      setIsLoading(false);
    }
  }
  // 部門別監査状況ViewModelを設定する
  async function getBmnAuditData(url: string) {
    await Axios.get<BmnSepAuditInfoViewModel>(url).then(response => {
      setBmnViewModel(response.data);
    })
  }
  // 部門別監査状況ポップアップを閉じる
  const handleOnBmnAuditInfoClose = () => {
    setBmnAuditInfoActivated(false);
  }

  // **************************************************
  // 月選択の設定
  // **************************************************
  const [monthSelectRefreshCnt, setMonthSelectRefreshCnt] = React.useState(0);
  const [selectedMonth, setSelectedMonth] = React.useState([0]);
  async function handleOnMonthChange(nMonth: number[]) {
    // 画面の月選択情報を更新
    setSelectedMonth(nMonth);
    // Excel出力向けの月情報を更新
    setExcelServiceParams(
      JSON.stringify({
          stNMonth: nMonth[0],
          edNMonth: nMonth[nMonth.length - 1]
      })
    );
    try {
      await monthOnGet(monthUrl, nMonth[0], nMonth[nMonth.length -1]);
    } catch (error) {
      if (typeof error.response.data !== 'undefined') {
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
      }
    }
  }

  // データ取り込み中確認
  const [isImportApiOpen, setIsImportApiOpen] = React.useState(false);
  const handleOnImportApiClose = () => {
    setIsImportApiOpen(false);
  };
  /** データ取得確認処理 */
  const resultStatusOnGet = (url: string, nextFunction: Function) => {
    Axios.get<Enums.BatStatusEnum>(url)
      .then(response => {
        const status = response.data;
        if (status == Enums.BatStatusEnum.Processing) {
          setIsImportApiOpen(true);
        } else {
          nextFunction();
        }
      })
      .catch(error => {
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.message });
      });
  };

  // **************************************************
  // Excel出力の設定
  // **************************************************
  const [excelFilters, setExcelFilters] = React.useState('');
  const [excelButtonDisabled, setExcelButtonDisabled] = React.useState(true);
  const [excelPopActivated, setExcelPopupActivated] = React.useState(false);
  const [excelServiceParams, setExcelServiceParams] = React.useState('');
  // 監査対象：「科目」時の出力用テンプレートID
  const [excelTemplateId, setExcelTemplateId] = React.useState(Consts.AIKADT002000001E01);
  // 監査対象：「部門」時の出力用テンプレートID
  const [excelBmnTemplateId, setExcelBmnTemplateId] = React.useState(Consts.AIKADT002000001E11);
  // Excel出力をクリックする
  const handleOnExcelClick = () => {
    // 科目用、部門用のテンプレートIDを設定する
    setExcelTemplateId(Consts.AIKADT002000001E01);
    setExcelBmnTemplateId(Consts.AIKADT002000001E11);
    resultStatusOnGet(resultStatusUrl, openExcelPop);
  };
  // 監査結果一覧をクリックする
  const handleExcelDetailClick = () => {
    // 科目用、部門用のテンプレートIDを設定する
    setExcelTemplateId(Consts.AIKADT002000001E02);
    setExcelBmnTemplateId(Consts.AIKADT002000001E12);
    resultStatusOnGet(resultStatusUrl, openExcelPop);
  };
  const openExcelPop = () => {
    setExcelPopupActivated(true);
  };
  const handleOnExcelClose = () => {
    setExcelPopupActivated(false);
  };

  // **************************************************
  // 閉じるボタン(ヘッダー)の設定
  // **************************************************
  const handleOnCloseClick = () => {
    props.onClose();
  };

  // **************************************************
  // 監査対象の設定
  // **************************************************
  const [auditTargetChangeFlg, setauditTargetChangeFlg] = React.useState(false);
  const [auditTargetVO, setAuditTargetVO] = React.useState<AuditTargetManagerVo>(defaultAuditTarget);
  // 監査対象の初期表示、実行後の再表示のみ使用する
  const [defaultAuditTargetVO, setDefaultAuditTargetVO] = React.useState<AuditTargetManagerVo>(defaultAuditTarget);
  const handleOnBmnList = (e:any) => 
  {
    setbmnAuditInfoHidden(false);
  }
  // 監査対象変更ハンドラー
  const handleOnTransAuditTarget = (e: AuditTargetManagerVo) =>
  {
    // 部門に変更する場合
    if (e.target == MasterKbn.Bmn)
    {
      setAuditTargetVO(e);
      // 監査対象変更Flg
      setauditTargetChangeFlg(true);
      if (e.items !== undefined)
      {
        // 部門残高取得
        monthOnGet(monthUrl, selectedMonth[0], selectedMonth[selectedMonth.length -1 ], e);
      } else {
        // Excelボタンの無効化
        setExcelButtonDisabled(true);
        // 監査実行ボタン無効化
        setRidBtnDisabled(true);
        setNBBtnDisabled(true);
        setJLBtnDisabled(true);
        setJDBtnDisabled(true);
        setTKBtnDisabled(true);
        setABBtnDisabled(true);
        // 残高一覧をクリア
        setViewModel(initializationViewModel);
      }
    }
    // 科目に変更する場合
    else if (e.target == 1) {
      setAuditTargetVO(defaultAuditTarget);
      if (auditTargetChangeFlg){
        monthOnGet(monthUrl, selectedMonth[0], selectedMonth[selectedMonth.length -1 ], defaultAuditTarget);
      }
    }
  };

  // **************************************************
  // 監査実行ボタンの設定
  // **************************************************
  // 月次残高ルールパターンのビューモデル
  const [rulePatternVM, setRulePatternViewModel] = React.useState(initializationRulePatternViewModel);
  // 著増減
  const [ridBtnDisabled, setRidBtnDisabled] = React.useState(true);
  const [ridIsAuditExecution, setRidIsAuditExecution] = React.useState(false);
  const [ridRemain, setRidRemain] = React.useState(0);
  const handleOnRidClick = () => {
    resultStatusOnGet(resultStatusUrl, openRidPop);
  };
  async function openRidPop() {
    try {
      // 月次残高ルールパターン取得
      await rulePatternOnGet(rulePatternUrl, auditTargetVO.target!);
      setRidErrMsg(auditIsUnconfirmed() ? Messages.AuditExecution_Warning_Unconfirm : '');
    } catch (error) {
      setRulePatternViewModel(initializationRulePatternViewModel);
      if (typeof error.response.data !== 'undefined') {
        setRidErrMsg(error.response.data.message);
      }
    }
    setRidPopIsOpen(true);
    setRidPrgRate(0);
  }
  // マイナス残高
  const [nbBtnDisabled, setNBBtnDisabled] = React.useState(true);
  const [nbIsAuditExecution, setNBIsAuditExecution] = React.useState(false);
  const [nbRemain, setNBRemain] = React.useState(0);
  const handleOnNBClick = () => {
    resultStatusOnGet(resultStatusUrl, openNBPop);
  };
  async function openNBPop() {
    try {
      // 月次残高ルールパターン取得
      await rulePatternOnGet(rulePatternUrl, auditTargetVO.target!);
      setNBErrMsg(auditIsUnconfirmed() ? Messages.AuditExecution_Warning_Unconfirm : '');
    } catch (error) {
      setRulePatternViewModel(initializationRulePatternViewModel);
      if (typeof error.response.data !== 'undefined') {
        setNBErrMsg(error.response.data.message);
      }
    }
    setNBPopIsOpen(true);
    setNBPrgRate(0);
  }
  // 仕訳明細
  const [jlBtnDisabled, setJLBtnDisabled] = React.useState(true);
  const [jlIsAuditExecution, setJLIsAuditExecution] = React.useState(false);
  const [jlRemain, setJLRemain] = React.useState(0);
  const handleOnJLClick = () => {
    resultStatusOnGet(resultStatusUrl, openJLPop);
  };
  async function openJLPop() {
    try {
      // 仕訳明細ルール一覧取得
      await jlRuleListOnGet(jlListUrl);
      setJLErrMsg(auditIsUnconfirmed() ? Messages.AuditExecution_Warning_Unconfirm : '');
      setJLPopDisabled(false);
    } catch (error) {
      setJLRules([]);
      if (typeof error.response.data !== 'undefined') {
        setJLErrMsg(error.response.data.message);
      }
      setJLPopDisabled(true);
    }
    setJLPopIsOpen(true);
    setJLPrgRate(0);
  }
  // 仕訳重複
  const [jdBtnDisabled, setJDBtnDisabled] = React.useState(true);
  const [jdIsAuditExecution, setJDIsAuditExecution] = React.useState(false);
  const [jdRemain, setJDRemain] = React.useState(0);
  const handleOnJDClick = () => {
    resultStatusOnGet(resultStatusUrl, openJDPop);
  };
    async function openJDPop() {
      try {
        await jdCheckPatternOnGet(jdCheckPatternUrl);
        setJDErrMsg(auditIsUnconfirmed() ? (Messages.AuditExecution_Warning_Unconfirm + '\n' +Messages.AuditExecution_Warning_Default) : Messages.AuditExecution_Warning_Default);
        setJDPopDisabled(false)
      } catch (error) {
        setCheckPattern([]);
      }
    setJDPopIsOpen(true);
    setJDPrgRate(0);
  };
  // 消費税区分
  const [tkBtnDisabled, setTKBtnDisabled] = React.useState(true);
  const [tkIsAuditExecution, setTKIsAuditExecution] = React.useState(false);
  const [tkRemain, setTKRemain] = React.useState(0);
  const handleOnTKClick = () => {
    resultStatusOnGet(resultStatusUrl, openTKPop);
  };
  const openTKPop = () => {
    setTKErrMsg(auditIsUnconfirmed() ? Messages.AuditExecution_Warning_Unconfirm : '');
    setTKPopIsOpen(true);
    setTKPrgRate(0);
  };
  // 一括監査実行
  const [abBtnDisabled, setABBtnDisabled] = React.useState(true);
  const handleOnABClick = () => {
    setauditTargetChangeFlg(true);
    resultStatusOnGet(resultStatusUrl, openABPop);
  };
  async function openABPop() {
    try {
      /** 補足：ver1.09部門対応にあたって、ルールパターンの取得を一括監査ダイアログに移動した */
      // 仕訳明細ルール一覧取得
      await jlRuleListOnGet(jlListUrl);
      // 仕訳重複パターン取得
      await jdCheckPatternOnGet(jdCheckPatternUrl);
      setABErrMsg(Messages.AuditBatchExecution_Warning_Default)
    } catch(error) {
      // 月次残高ルールパターン
      setRulePatternViewModel(initializationRulePatternViewModel);
      // 仕訳明細ルール一覧
      setJLRules([]);
      // 仕訳重複パターン
      setCheckPattern([]);
    }
    setABPopIsOpen(true);
  }

  // **************************************************
  // 監査実行ポップアップの設定
  // **************************************************
  // 著増減
  const [ridPopIsOpen, setRidPopIsOpen] = React.useState(false);
  const [ridIsExecute, setRidIsExecute] = React.useState(false);
  const [ridPrgRate, setRidPrgRate] = React.useState(0);
  async function handleOnRidPopClick(rulePattern: Enums.RulePatternEnum | null) {
    // ルールがない場合、実行させない
    if (rulePattern === null){
      // チェック対象が存在しない場合はエラーとする
      setRidErrMsg(BindValueToMessage(Messages.Common_Error_Unsetting, ['著増減監査ルール']));
      return;
    }
    setRidErrMsg('');
    setRidPrgRate(0);
    setRidIsExecute(true);

    // 著増減チェック対象の存在チェック
    let MonthlyBalanceRuleSettingVM;
    try {
      // 月次残高ルール設定情報を取得
      MonthlyBalanceRuleSettingVM = await monthlyBalanceRuleSettingOnGet(monthlyBalanceSettingeUrl, rulePattern);
      // 監査対象より監査ルールをセットする
      if (auditTargetVO.target == MasterKbn.Bmn) {
        MonthlyBalanceRuleSettingVM.RuleItems = MonthlyBalanceRuleSettingVM.RuleItemBmns;
      } else if (auditTargetVO.target == MasterKbn.CountingKmk) {
        MonthlyBalanceRuleSettingVM.RuleItems = MonthlyBalanceRuleSettingVM.RuleItemKmks;
      }
    } catch (error) {
      if (typeof error.response === 'undefined') {
        // ネットワークエラーの場合
        setRidPrgRate(0);
        setRidIsExecute(false);
        return;
      }
      if (typeof error.response.data !== 'undefined') {
        setRidErrMsg(error.response.data.message);
      }
      setRidIsExecute(false);
      return;
    }
    let executeCntCheck = true;
    if (
      typeof MonthlyBalanceRuleSettingVM.RuleItems !== 'undefined' &&
      MonthlyBalanceRuleSettingVM.RuleItems.length > 0
    ) {
      // チェック対象が存在するか確認
      for (const vo of MonthlyBalanceRuleSettingVM.RuleItems) {
        if (vo.RidKmkFlg === true) {
          executeCntCheck = false;
          break;
        }
        if (vo.RidHojyoFlg === true) {
          executeCntCheck = false;
          break;
        }
      }
    }
    if (executeCntCheck) {
      // チェック対象が存在しない場合はエラーとする
      setRidIsExecute(false);
      setRidErrMsg(BindValueToMessage(Messages.Common_Error_Unsetting, ['著増減監査ルール']));
      return;
    }

    let guid;
    try {
        guid = await ridAuditOnPost(ridAuditUrl, selectedMonth[0], selectedMonth[selectedMonth.length -1], rulePattern);
        await Axios.post(WriteSysHistoryURL, {syoriId: 'AIKADT002000001', operation: '著増減監査実行'});
    } catch (error) {
      if (typeof error.response === 'undefined') {
        // ネットワークエラーの場合
        setRidPrgRate(0);
        setRidIsExecute(false);
        return;
      }
      if (typeof error.response.data !== 'undefined') {
        setRidErrMsg(error.response.data.message);
      }
      setRidIsExecute(false);
      return;
    }

    let errMsg = '';
    let rate = 0;
    while (rate < 100) {
      await sleep(Consts.PROGRESSINTERVAL);
      try {
        rate = await ridProgressOnPost(ridPrgUrl, guid!);
        setRidPrgRate(rate);
      } catch (error) {
        if (typeof error.response === 'undefined') {
          // ネットワークエラーの場合
          setRidPrgRate(0);
          setRidIsExecute(false);
          return;
        }
        if (typeof error.response.data !== 'undefined') {
          errMsg = error.response.data.message;
        }
        break;
      }
    }
    if (errMsg === '') {
      setTimeout(() => {
        setRidIsExecute(false);
        setRidPopIsOpen(false);
        reloadPageDisplayInfo(selectedMonth);
      }, Consts.AUDITCOMPLETEWAIT);
    } else if (errMsg) {
      setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: errMsg });
      setRidIsExecute(false);
      setRidPopIsOpen(false);
    }
  }
  const handleOnRidPopClose = () => {
    setRidPopIsOpen(false);
  };
  // マイナス残高
  const [nbPopIsOpen, setNBPopIsOpen] = React.useState(false);
  const [nbIsExecute, setNBIsExecute] = React.useState(false);
  const [nbPrgRate, setNBPrgRate] = React.useState(0);
  async function handleOnNBPopClick(rulePattern: Enums.RulePatternEnum | null) {
    // ルールがない場合、実行させない
    if (rulePattern === null){
      // チェック対象が存在しない場合はエラーとする
      setNBErrMsg(BindValueToMessage(Messages.Common_Error_Unsetting, ['マイナス監査ルール']));
      return;
    }
    setNBErrMsg('');
    setNBPrgRate(0);
    setNBIsExecute(true);

    // マイナス残高チェック対象の存在チェック
    let MonthlyBalanceRuleSettingVM;
    try {
      // 月次残高ルール設定情報を取得
      MonthlyBalanceRuleSettingVM = await monthlyBalanceRuleSettingOnGet(monthlyBalanceSettingeUrl, rulePattern);
      // 監査対象より監査ルールをセットする
      if (auditTargetVO.target == MasterKbn.Bmn) {
        MonthlyBalanceRuleSettingVM.RuleItems = MonthlyBalanceRuleSettingVM.RuleItemBmns;
      } else if (auditTargetVO.target == MasterKbn.CountingKmk) {
        MonthlyBalanceRuleSettingVM.RuleItems = MonthlyBalanceRuleSettingVM.RuleItemKmks;
      }
    } catch (error) {
      if (typeof error.response === 'undefined') {
        // ネットワークエラーの場合
        setNBPrgRate(0);
        setNBIsExecute(false);
        return;
      }
      if (typeof error.response.data !== 'undefined') {
        setNBErrMsg(error.response.data.message);
      }
      setNBIsExecute(false);
      return;
    }
    let executeCntCheck = true;
    if (
      typeof MonthlyBalanceRuleSettingVM.RuleItems !== 'undefined' &&
      MonthlyBalanceRuleSettingVM.RuleItems.length > 0
    ) {
      // チェック対象が存在するか確認
      for (const vo of MonthlyBalanceRuleSettingVM.RuleItems) {
        if (vo.NBKmkFlg === true) {
          executeCntCheck = false;
          break;
        }
        if (vo.NBHojyoFlg === true) {
          executeCntCheck = false;
          break;
        }
      }
    }

    if (executeCntCheck) {
      // チェック対象が存在しない場合はエラーとする
      setNBIsExecute(false);
      setNBErrMsg(BindValueToMessage(Messages.Common_Error_Unsetting, ['マイナス残高監査ルール']));
      return;
    }

    let guid;
    try {
        guid = await nbAuditOnPost(nbAuditUrl, selectedMonth[0], selectedMonth[selectedMonth.length -1], rulePattern);
        await Axios.post(WriteSysHistoryURL, {syoriId: 'AIKADT002000001', operation: 'マイナス残高監査実行'});
    } catch (error) {
      if (typeof error.response === 'undefined') {
        // ネットワークエラーの場合
        setNBPrgRate(0);
        setNBIsExecute(false);
        return;
      }
      if (typeof error.response.data !== 'undefined') {
        setNBErrMsg(error.response.data.message);
      }
      setNBIsExecute(false);
      return;
    }

    let errMsg = '';
    let rate = 0;
    while (rate < 100) {
      await sleep(Consts.PROGRESSINTERVAL);
      try {
        rate = await nbProgressOnPost(nbPrgUrl, guid!);
        setNBPrgRate(rate);
      } catch (error) {
        if (typeof error.response === 'undefined') {
          // ネットワークエラーの場合
          setNBPrgRate(0);
          setNBIsExecute(false);
          return;
        }
        if (typeof error.response.data !== 'undefined') {
          errMsg = error.response.data.message;
        }
        break;
      }
    }
    if (errMsg === '') {
      setTimeout(() => {
        setNBIsExecute(false);
        setNBPopIsOpen(false);
        reloadPageDisplayInfo(selectedMonth);
      }, Consts.AUDITCOMPLETEWAIT);
    } else if (errMsg) {
      setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: errMsg });
      setNBIsExecute(false);
      setNBPopIsOpen(false);
    }
  }
  const handleOnNBPopClose = () => {
    setNBPopIsOpen(false);
  };
  // 仕訳明細
  const [jlPopIsOpen, setJLPopIsOpen] = React.useState(false);
  const [jlPopDisabled, setJLPopDisabled] = React.useState(false);
  const [jlIsExecute, setJLIsExecute] = React.useState(false);
  const [jlPrgRate, setJLPrgRate] = React.useState(0);
  const [jlRules, setJLRules] = React.useState<Array<JournalLineRuleVO>>([]);
  async function handleOnJlPopClick(rules: Array<JournalLineRuleAuditVO>) {
    setJLErrMsg('');
    // ルールの必須チェック
    if (rules.length == 0) {
      setJLErrMsg(BindValueToMessage(Messages.Common_Error_Unchecked, ['仕訳明細監査ルール']));
      return;
    }
    setJLPrgRate(0);
    setJLIsExecute(true);
    let guid;
    try {
      guid = await jlAuditOnPost(jlAuditUrl, selectedMonth[0], selectedMonth[selectedMonth.length -1], rules);
      await Axios.post(WriteSysHistoryURL, {syoriId: 'AIKADT002000001', operation: '仕訳明細監査実行'});
    } catch (error) {
      if (typeof error.response === 'undefined') {
        // ネットワークエラーの場合
        setJLPrgRate(0);
        setJLIsExecute(false);
        return;
      }
      if (typeof error.response.data !== 'undefined') {
        setJLErrMsg(error.response.data.message);
      }
      setJLIsExecute(false);
      return;
    }
    let errMsg = '';
    let rate = 0;
    while (rate < 100) {
      await sleep(Consts.PROGRESSINTERVAL);
      try {
        rate = await jlProgressOnPost(jlPrgUrl, guid!);
        setJLPrgRate(rate);
      } catch (error) {
        if (typeof error.response === 'undefined') {
          // ネットワークエラーの場合
          setJLPrgRate(0);
          setJLIsExecute(false);
          return;
        }
        if (typeof error.response.data !== 'undefined') {
          errMsg = error.response.data.message;
        }
        break;
      }
    }
    if (errMsg === '') {
      setTimeout(() => {
        setJLIsExecute(false);
        setJLPopIsOpen(false);
        reloadPageDisplayInfo(selectedMonth);
      }, Consts.AUDITCOMPLETEWAIT);
    } else if (errMsg) {
      setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: errMsg });
      setJLIsExecute(false);
      setJLPopIsOpen(false);
    }
  }
  const handleOnJLPopClose = () => {
    setJLPopIsOpen(false);
  };
  // 仕訳重複
  const [jdPopIsOpen, setJDPopIsOpen] = React.useState(false);
  const [jdPopDisabled, setJDPopDisabled] = React.useState(false);
  const [jdIsExecute, setJDIsExecute] = React.useState(false);
  const [jdPrgRate, setJDPrgRate] = React.useState(0);
  const [jdCheckPattern, setCheckPattern] = React.useState<Array<DblJokenVO>>([]);
  const [jdPopSave, setJDPopSave] = React.useState(false);
  async function handleOnJDPopClick(jokens: Array<Enums.DblJokenEnum>) {
    setJDErrMsg('');
    // 条件の必須チェック
    if (jokens.length == 0) {
      setJDErrMsg(BindValueToMessage(Messages.Common_Error_Unchecked, ['仕訳重複監査条件']));
      return;
    }
    setJDPopSave(true);
    setJDPrgRate(0);
    setJDIsExecute(true);
    let guid;
      try {
        guid = await jdAuditOnPost(jdAuditUrl, selectedMonth[0], selectedMonth[selectedMonth.length -1], jokens);
        await Axios.post(WriteSysHistoryURL, {syoriId: 'AIKADT002000001', operation: '仕訳重複監査実行'});
    } catch (error) {
      if (typeof error.response === 'undefined') {
        // ネットワークエラーの場合
        setJDPrgRate(0);
        setJDIsExecute(false);
        return;
      }
      if (typeof error.response.data !== 'undefined') {
        setJDErrMsg(error.response.data.message);
      }
      setJDIsExecute(false);
      return;
    }
    let errMsg = '';
    let rate = 0;
    while (rate < 100) {
      await sleep(Consts.PROGRESSINTERVAL);
      try {
        rate = await jdProgressOnPost(jdPrgUrl, guid!);
        setJDPrgRate(rate);
      } catch (error) {
        if (typeof error.response === 'undefined') {
          // ネットワークエラーの場合
          setJDPrgRate(0);
          setJDIsExecute(false);
          return;
        }
        if (typeof error.response.data !== 'undefined') {
          errMsg = error.response.data.message;
        }
        break;
      }
    }
    if (errMsg === '') {
      setTimeout(() => {
        setJDIsExecute(false);
        setJDPopIsOpen(false);
        reloadPageDisplayInfo(selectedMonth);
      }, Consts.AUDITCOMPLETEWAIT);
    } else if (errMsg) {
      setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: errMsg });
      setJDIsExecute(false);
      setJDPopIsOpen(false);
    }
  }
  const handleOnJDPopClose = () => {
    setJDPopSave(false);
    setJDPopIsOpen(false);
  };
  // 消費税区分
  const [tkPopIsOpen, setTKPopIsOpen] = React.useState(false);
  const [tkIsExecute, setTKIsExecute] = React.useState(false);
  const [tkPrgRate, setTKPrgRate] = React.useState(0);
  async function handleOnTKPopClick() {
    setTKErrMsg('');
    setTKPrgRate(0);
    setTKIsExecute(true);
    let guid;
    try {
      guid = await tkAuditOnPost(tkAuditUrl, selectedMonth[0], selectedMonth[selectedMonth.length - 1]);
      await Axios.post(WriteSysHistoryURL, {syoriId: 'AIKADT002000001', operation: '消費税区分監査実行'});
    } catch (error) {
      if (typeof error.response === 'undefined') {
        // ネットワークエラーの場合
        setTKPrgRate(0);
        setTKIsExecute(false);
        return;
      }
      if (typeof error.response.data !== 'undefined') {
        setTKErrMsg(error.response.data.message);
      }
      setTKIsExecute(false);
      return;
    }
    let errMsg = '';
    let rate = 0;
    while (rate < 100) {
      await sleep(Consts.PROGRESSINTERVAL);
      try {
        rate = await tkProgressOnPost(tkPrgUrl, guid!);
        setTKPrgRate(rate);
      } catch (error) {
        if (typeof error.response === 'undefined') {
          // ネットワークエラーの場合
          setTKPrgRate(0);
          setTKIsExecute(false);
          return;
        }
        if (typeof error.response.data !== 'undefined') {
          errMsg = error.response.data.message;
        }
        break;
      }
    }
    if (errMsg === '') {
      setTimeout(() => {
        setTKIsExecute(false);
        setTKPopIsOpen(false);
        reloadPageDisplayInfo(selectedMonth);
      }, Consts.AUDITCOMPLETEWAIT);
    } else if (errMsg) {
      setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: errMsg });
      setTKIsExecute(false);
      setTKPopIsOpen(false);
    }
  }
  const handleOnTKPopClose = () => {
    setTKPopIsOpen(false);
  };

  // 一括監査実行
  const [ABPopIsOpen, setABPopIsOpen] = React.useState(false);
  const [abIsExecute, setABIsExecute] = React.useState(false);
  const [ProgressIsOpen, setProgressIsOpen] = React.useState(false);
  const [abPrgRate, setABPrgRate] = React.useState(0);
  const handleOnABPopClose = () => {
    setABPopIsOpen(false);
  }
  async function handleOnABPopClick(BatchTargets: Array<Enums.AuditTypeEnum>, Bridrules: Enums.RulePatternEnum | null, Bnbrules: Enums.RulePatternEnum | null, BjlRules: Array<JournalLineRuleAuditVO>, Bjdjokens: Array<Enums.DblJokenEnum>, auditTarget: AuditTargetManagerVo){
    // 対象部門数のチェック
    if (auditTarget.target == MasterKbn.Bmn) {
      if (auditTarget.items === undefined) {
        setABErrMsg(BindValueToMessage(Messages.AuditBatchExecution_Error_Hojyo_Invalid, ['部門']));
        return;
      } else if (auditTarget.items.length < 1) {
        setABErrMsg(BindValueToMessage(Messages.AuditBatchExecution_Error_Hojyo_Invalid, ['部門']));
        return;
      }
      if (auditTarget.items!.length > 100) {
        setABErrMsg(BindValueToMessage(Messages.Common_Error_TooManySelect, ['部門', '100']));
        return;
      }
    }
    
    // 監査対象の必須チェック
    if (BatchTargets.length == 0)
    {
      setABErrMsg(BindValueToMessage(Messages.Common_Error_Unchecked, ['監査対象']));
      return;
    }

    setABPrgRate(0);
    setProgressIsOpen(true);

    // 著増減チェック対象の存在チェック
    if (BatchTargets.indexOf(Enums.AuditTypeEnum.RemarkIncDecAudit) >= 0) {
      if (Bridrules == null) {
        // チェック対象が存在しない場合はエラーとする
        setABErrMsg(BindValueToMessage(Messages.Common_Error_Unsetting, ['著増減監査ルール']));
        setProgressIsOpen(false);
        return;
      }
      let RidMonthlyBalanceRuleSettingVM;
      try {
        // 月次残高ルール設定情報を取得
        RidMonthlyBalanceRuleSettingVM = await monthlyBalanceRuleSettingOnGet(monthlyBalanceSettingeUrl, Bridrules);
        // 監査対象より監査ルールをセットする
        if (auditTarget.target == MasterKbn.Bmn) {
          RidMonthlyBalanceRuleSettingVM.RuleItems = RidMonthlyBalanceRuleSettingVM.RuleItemBmns;
        } else if (auditTarget.target == MasterKbn.CountingKmk) {
          RidMonthlyBalanceRuleSettingVM.RuleItems = RidMonthlyBalanceRuleSettingVM.RuleItemKmks;
        }
      } catch (error) {
        if (typeof error.response === 'undefined') {
          setProgressIsOpen(false);
          return;
        }
        if (typeof error.response.data !== 'undefined') {
          setABErrMsg(error.response.data.message);
        }
        setProgressIsOpen(false);
        return;
      }
      let RidexecuteCntCheck = true;
      if (
        typeof RidMonthlyBalanceRuleSettingVM.RuleItems !== 'undefined' &&
        RidMonthlyBalanceRuleSettingVM.RuleItems.length > 0
      ) {
        // チェック対象が存在するか確認
        for (const vo of RidMonthlyBalanceRuleSettingVM.RuleItems) {
          if (vo.RidKmkFlg === true) {
            RidexecuteCntCheck = false;
            break;
          }
          if (vo.RidHojyoFlg === true) {
            RidexecuteCntCheck = false;
            break;
          }
        }
      }
      if (RidexecuteCntCheck) {
        // チェック対象が存在しない場合はエラーとする
        setABErrMsg(BindValueToMessage(Messages.Common_Error_Unsetting, ['著増減監査ルール']));
        setProgressIsOpen(false);
        return;
      }
    }

    // マイナス残高チェック対象の存在チェック
    if (BatchTargets.indexOf(Enums.AuditTypeEnum.NegativeBalanceAudit) >= 0) {
      if (Bnbrules == null) {
        // チェック対象が存在しない場合はエラーとする
        setABErrMsg(BindValueToMessage(Messages.Common_Error_Unsetting, ['マイナス残高監査ルール']));
        setProgressIsOpen(false);
        return;
      }
      let NbMonthlyBalanceRuleSettingVM;
      try {
        // 月次残高ルール設定情報を取得
        NbMonthlyBalanceRuleSettingVM = await monthlyBalanceRuleSettingOnGet(monthlyBalanceSettingeUrl, Bnbrules);
        // 監査対象より監査ルールをセットする
        if (auditTarget.target == MasterKbn.Bmn) {
          NbMonthlyBalanceRuleSettingVM.RuleItems = NbMonthlyBalanceRuleSettingVM.RuleItemBmns;
        } else if (auditTarget.target == MasterKbn.CountingKmk) {
          NbMonthlyBalanceRuleSettingVM.RuleItems = NbMonthlyBalanceRuleSettingVM.RuleItemKmks;
        }
      } catch (error) {
        if (typeof error.response === 'undefined') {
          // ネットワークエラーの場合
          setProgressIsOpen(false);
          return;
        }
        if (typeof error.response.data !== 'undefined') {
          setABErrMsg(error.response.data.message);
        }
        setProgressIsOpen(false);
        return;
      }
      let NbexecuteCntCheck = true;
      if (
        typeof NbMonthlyBalanceRuleSettingVM.RuleItems !== 'undefined' &&
        NbMonthlyBalanceRuleSettingVM.RuleItems.length > 0
      ) {
        // チェック対象が存在するか確認
        for (const vo of NbMonthlyBalanceRuleSettingVM.RuleItems) {
          if (vo.NBKmkFlg === true) {
            NbexecuteCntCheck = false;
            break;
          }
          if (vo.NBHojyoFlg === true) {
            NbexecuteCntCheck = false;
            break;
          }
        }
      }
      if (NbexecuteCntCheck) {
        // チェック対象が存在しない場合はエラーとする
        setABErrMsg(BindValueToMessage(Messages.Common_Error_Unsetting, ['マイナス残高監査ルール']));
        setProgressIsOpen(false);
        return;
      }
    }

    setABIsExecute(false);
    try {
      var Ridguid: string | undefined;
      var Nbguid: string | undefined;
      var Jlguid: string | undefined;
      var Jdguid: string | undefined;
      var Tkguid: string | undefined;
      

      // 著増減監査とマイナス残高監査の複数監査実行フラグ
      var ridandNbMutipleFld = true;
      if (
        (BatchTargets.indexOf(Enums.AuditTypeEnum.RemarkIncDecAudit) >= 0
        || BatchTargets.indexOf(Enums.AuditTypeEnum.NegativeBalanceAudit) >= 0)
        && 
        (BatchTargets.indexOf(Enums.AuditTypeEnum.TaxKubunAudit) < 0
        && BatchTargets.indexOf(Enums.AuditTypeEnum.JournalDoubleAudit) < 0
        && BatchTargets.indexOf(Enums.AuditTypeEnum.JournalLineAudit) < 0)
      ) {
        // 仕訳明細、仕訳重複、消費税区分にチェックがない場合は件数制御を無効化する
        ridandNbMutipleFld = false;
      }
      if (BatchTargets.indexOf(Enums.AuditTypeEnum.RemarkIncDecAudit) >= 0) {
        Ridguid = await ridAuditOnPost(ridAuditUrl, selectedMonth[0], selectedMonth[selectedMonth.length - 1], Bridrules!, ridandNbMutipleFld, BatchTargets.length, auditTarget);
        await Axios.post(WriteSysHistoryURL, {syoriId: 'AIKADT002000001', operation: '著増減監査実行'});
      }
      if (BatchTargets.indexOf(Enums.AuditTypeEnum.NegativeBalanceAudit) >= 0) {
        Nbguid = await nbAuditOnPost(nbAuditUrl, selectedMonth[0], selectedMonth[selectedMonth.length - 1], Bnbrules!, ridandNbMutipleFld, BatchTargets.length, auditTarget);
        await Axios.post(WriteSysHistoryURL, {syoriId: 'AIKADT002000001', operation: 'マイナス残高監査実行'});
      }
      if (BatchTargets.indexOf(Enums.AuditTypeEnum.JournalLineAudit) >= 0) {
        Jlguid = await jlAuditOnPost(jlAuditUrl, selectedMonth[0], selectedMonth[selectedMonth.length - 1], BjlRules, BatchTargets.length);
        await Axios.post(WriteSysHistoryURL, {syoriId: 'AIKADT002000001', operation: '仕訳明細監査実行'});
      }
      if (BatchTargets.indexOf(Enums.AuditTypeEnum.JournalDoubleAudit) >= 0) {
        Jdguid = await jdAuditOnPost(jdAuditUrl, selectedMonth[0], selectedMonth[selectedMonth.length - 1], Bjdjokens, BatchTargets.length);
        await Axios.post(WriteSysHistoryURL, {syoriId: 'AIKADT002000001', operation: '仕訳重複監査実行'});
      }
      if (BatchTargets.indexOf(Enums.AuditTypeEnum.TaxKubunAudit) >= 0) {
        Tkguid = await tkAuditOnPost(tkAuditUrl, selectedMonth[0], selectedMonth[selectedMonth.length - 1], BatchTargets.length);
        await Axios.post(WriteSysHistoryURL, {syoriId: 'AIKADT002000001', operation: '消費税区分監査実行'});
      }
    } catch (error) {
      if (typeof error.response === 'undefined') {
        // ネットワークエラーの場合
        setABPrgRate(0);
        setABIsExecute(false);
        setProgressIsOpen(false);
        return;
      }
      if (typeof error.response.data !== 'undefined') {
        setABErrMsg(error.response.data.message);
      }
      setABIsExecute(false);
      setProgressIsOpen(false);
      return;
    }
    let errMsg = '';
    // PrgRate設定
    let batchRate:number = 0;
    while (batchRate < 100) {
      await sleep(Consts.PROGRESSINTERVAL)
      try {
        let rate = 0;
        if (BatchTargets.indexOf(Enums.AuditTypeEnum.RemarkIncDecAudit) >= 0) {
          rate += await ridProgressOnPost(ridPrgUrl, Ridguid!);
        }
        if (BatchTargets.indexOf(Enums.AuditTypeEnum.NegativeBalanceAudit) >= 0) {
          rate += await nbProgressOnPost(nbPrgUrl, Nbguid!);
        }
        if (BatchTargets.indexOf(Enums.AuditTypeEnum.JournalLineAudit) >= 0) {
          rate += await jlProgressOnPost(jlPrgUrl, Jlguid!);
        }
        if (BatchTargets.indexOf(Enums.AuditTypeEnum.JournalDoubleAudit) >= 0) {
          rate += await jdProgressOnPost(jdPrgUrl, Jdguid!);
        }
        if (BatchTargets.indexOf(Enums.AuditTypeEnum.TaxKubunAudit) >= 0) {
          rate += await tkProgressOnPost(tkPrgUrl, Tkguid!);
        }
        batchRate = Math.round(rate/BatchTargets.length);
        setABPrgRate(batchRate);
      } catch (error) {
        if (typeof error.response === 'undefined') {
          // ネットワークエラーの場合
          setABPrgRate(0);
          setABIsExecute(false);
          setProgressIsOpen(false);
          return;
        }
        if (typeof error.response.data !== 'undefined') {
          errMsg = error.response.data.message;
        }
        break;
      }
    }
    if (errMsg === ''){
      setTimeout(() => {
        setABIsExecute(false);
        setABPopIsOpen(false);
        setProgressIsOpen(false);
        // 補助コードリストの先頭１行
        var item = new Array<BmnInformationViewModel>();
        item.push(auditTarget.items![0]);
        // 実行の補助区分、補助コードを画面に渡す
        setDefaultAuditTargetVO({ ...{ target: auditTarget.target, items: item } });
        // 実行の補助区分、補助コードのデータを再度取得する
        handleOnTransAuditTarget({target: auditTarget.target, items: item});
        setMonthSelectRefreshCnt(monthSelectRefreshCnt + 1); // 月度情報の再取得
      }, Consts.AUDITCOMPLETEWAIT);
    } else if (errMsg){
      setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: errMsg });
      setABIsExecute(false);
      setABPopIsOpen(false);
      setProgressIsOpen(false);
    }
  }

  // **************************************************
  // 監査結果管理ポップアップの設定
  // **************************************************
  const [resultActivated, setResultActivated] = React.useState(false);
  const [journalLineActivated, setJournalLineActivated] = React.useState(false);
  const [auditType, setAuditType] = React.useState(Enums.AuditTypeEnum.RemarkIncDecAudit);
  const [seqNo, setseqNo] = React.useState([0]);
  const [kmkCode, setkmkCode] = React.useState(0);
  const [activateScreenType, setActivateScreenType] = React.useState(Enums.ActivateScreenEnum.Result);

  const handleOnResultPopClose = (isRefresh: boolean) => {
    setResultActivated(false);
    if (isRefresh) {
      refleshAtClose();
    }
  };
  const handleOnJournalLineClose = (isRefresh: boolean) => {
    setJournalLineActivated(false);
    if (isRefresh) {
      refleshAtClose();
    }
  };

  /**
   * 子画面クローズ時のリロード処理
   */
  async function refleshAtClose() {
    setFilterDefinition({ filterDefinition: excelFilters, code: kmkCode });
    const flexGrid = refGrid.current!.control as wjGrid.FlexGrid;
    const sortDesc = flexGrid.collectionView.sortDescriptions[0];
    if (typeof sortDesc !== 'undefined') {
      setSortCriteria({ property: sortDesc.property, ascending: sortDesc.ascending });
    } else {
      setSortCriteria({ property: undefined, ascending: undefined });
    }
    reloadPageDisplayInfo(selectedMonth);
  }

  /**
   * ページの表示情報を再取得します。
   */
  async function reloadPageDisplayInfo(selectMonth: number[]) {
    try {
      setMonthSelectRefreshCnt(monthSelectRefreshCnt + 1); // 月度情報の再取得
      await monthOnGet(monthUrl, selectMonth[0], selectMonth[selectMonth.length -1 ]); // 一覧情報の再取得
    } catch (error) {
      if (typeof error.response.data !== 'undefined') {
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
      }
    }
  }

  // **************************************************
  // グリッドの設定
  // **************************************************
  const refGrid = React.createRef<FlexGrid>();
  const handleOnRemarkBadgeClick = (code: number, seq: number[]) => {
    setAuditType(Enums.AuditTypeEnum.RemarkIncDecAudit);
    setkmkCode(code);
    setseqNo(seq);
    // 表示画面判定
    if (selectScreenType === Enums.ActivateScreenEnum.Result) {
      setResultActivated(true);
    } else {
      setJournalLineActivated(true);
    }
  };
  const handleOnNegativeBadgeClick = (code: number, seq: number[]) => {
    setAuditType(Enums.AuditTypeEnum.NegativeBalanceAudit);
    setkmkCode(code);
    setseqNo(seq);
    // 表示画面判定
    if (selectScreenType === Enums.ActivateScreenEnum.Result) {
      setResultActivated(true);
    } else {
      setJournalLineActivated(true);
    }
  };
  const handleOnJournalBadgeClick = (code: number, seq: number[]) => {
    setAuditType(Enums.AuditTypeEnum.JournalLineAudit);
    setkmkCode(code);
    setseqNo(seq);
    // 表示画面判定
    if (selectScreenType === Enums.ActivateScreenEnum.Result) {
      setResultActivated(true);
    } else {
      setJournalLineActivated(true);
    }
  };
  const handleOnDuplicateBadgeClick = (code: number, seq: number[]) => {
    setAuditType(Enums.AuditTypeEnum.JournalDoubleAudit);
    setkmkCode(code);
    setseqNo(seq);
    // 表示画面判定
    if (selectScreenType === Enums.ActivateScreenEnum.Result) {
      setResultActivated(true);
    } else {
      setJournalLineActivated(true);
    }
  };
  const handleOnExciseBadgeClick = (code: number, seq: number[]) => {
    setAuditType(Enums.AuditTypeEnum.TaxKubunAudit);
    setkmkCode(code);
    setseqNo(seq);
    // 表示画面判定
    if (selectScreenType === Enums.ActivateScreenEnum.Result) {
      setResultActivated(true);
    } else {
      setJournalLineActivated(true);
    }
  };
  const handleOnFilterChanged = (filters: string) => {
    setExcelFilters(filters);
  };
  const handleOnSelectActivateScreenType = (screenType: number) => {
    if (screenType == Enums.ActivateScreenEnum.JounalLine) {
      setActivateScreenType(selectScreenType = Enums.ActivateScreenEnum.JounalLine);
    } else {
      setActivateScreenType(selectScreenType = Enums.ActivateScreenEnum.Result);
    }
  }

  // **************************************************
  // 通信処理
  // **************************************************
  const [ridErrMsg, setRidErrMsg] = React.useState('');
  const [nbErrMsg, setNBErrMsg] = React.useState('');
  const [jlErrMsg, setJLErrMsg] = React.useState('');
  const [jdErrMsg, setJDErrMsg] = React.useState('');
  const [tkErrMsg, setTKErrMsg] = React.useState('');
  const [abErrMsg, setABErrMsg] = React.useState('');

  // AIKADT002000001_Get1：月データ作成;
  const [viewModel, setViewModel] = React.useState<ExecutionResultViewModel>(initializationViewModel);
  async function monthOnGet(url: string, stMonth: number, edMonth: number, auditTarget: AuditTargetManagerVo = auditTargetVO) {
    if (auditTarget.target == MasterKbn.Bmn && auditTarget.items === undefined) {
      return;
    }
    setIsScreenLock(true);
    setIsLoading(true);
    let auditTargetKbn = auditTarget.target!;
    let hojyoCode = auditTarget.target == MasterKbn.Bmn ? auditTarget.items![0].BmnCode! : ' ';
    // 「/」「\」を退避するために、全角に変換する
      let hojyoCodeForUrl = hojyoCode.replaceAll('/', '／').replaceAll('\\', '￥');
    const encodedUrl = [url, encodeURI(stMonth.toString()), encodeURI(edMonth.toString()), encodeURI(auditTargetKbn.toString()), encodeURIComponent(hojyoCodeForUrl)].join('/');
    try {
      const response = await Axios.get<ExecutionResultViewModel>(encodedUrl);
      const vm = response.data;
      setIsScreenLock(false);
      setIsLoading(false);
      // 初期表示以外はラジオボタンの状態をユーザー操作で維持する
      if (!initState && selectScreenType >= 0) {
        vm.ActivateScreenType = selectScreenType;
      }
      setViewModel(vm);
      // 仕訳表示ラジオボタンを設定
      setActivateScreenType(selectScreenType = vm.ActivateScreenType || Enums.ActivateScreenEnum.Result);
      // Excelボタンの有効化
      setExcelButtonDisabled(false);
      // 監査実行ボタン有効化
      setRidBtnDisabled(false);
      setNBBtnDisabled(false);
      setJLBtnDisabled(false);
      setJDBtnDisabled(false);
      setTKBtnDisabled(false);
      setABBtnDisabled(false);
      // 未確認件数設定
      setRidRemain(vm.RidAuditableCnt ? vm.RidAuditableCnt : 0);
      setNBRemain(vm.NBAuditableCnt ? vm.NBAuditableCnt : 0);
      setJLRemain(vm.JLAuditableCnt ? vm.JLAuditableCnt : 0);
      setJDRemain(vm.JDAuditableCnt ? vm.JDAuditableCnt : 0);
      setTKRemain(vm.TKAuditableCnt ? vm.TKAuditableCnt : 0);
      // チェック記号表示制御
      setRidIsAuditExecution(vm.RidCompleteFlg ? vm.RidCompleteFlg : false);
      setNBIsAuditExecution(vm.NBCompleteFlg ? vm.NBCompleteFlg : false);
      setJLIsAuditExecution(vm.JLCompleteFlg ? vm.JLCompleteFlg : false);
      setJDIsAuditExecution(vm.JDCompleteFlg ? vm.JDCompleteFlg : false);
      setTKIsAuditExecution(vm.TKCompleteFlg ? vm.TKCompleteFlg : false);
      // 初期表示フラグを設定する
      setInitState(false);
    } catch (error) {
      setIsScreenLock(false);
      setIsLoading(false);
      // 初期表示以外はラジオボタンの状態をユーザー操作で維持する
      if (!initState && selectScreenType >= 0) {
        initializationViewModel.ActivateScreenType = selectScreenType;
      }
      setViewModel(initializationViewModel);
      // 仕訳表示ラジオボタンを設定
      setActivateScreenType(selectScreenType = initializationViewModel.ActivateScreenType || Enums.ActivateScreenEnum.Result);
      // Excelボタンの無効化
      setExcelButtonDisabled(true);
      // 監査実行ボタン無効化
      setRidBtnDisabled(true);
      setNBBtnDisabled(true);
      setJLBtnDisabled(true);
      setJDBtnDisabled(true);
      setTKBtnDisabled(true);
      setABBtnDisabled(true);
      // 未確認件数設定
      setRidRemain(0);
      setNBRemain(0);
      setJLRemain(0);
      setJDRemain(0);
      setTKRemain(0);
      // チェック記号表示制御
      setRidIsAuditExecution(false);
      setNBIsAuditExecution(false);
      setJLIsAuditExecution(false);
      setJDIsAuditExecution(false);
      setTKIsAuditExecution(false);
      // 初期表示フラグを設定する
      setInitState(false);

      throw error;
    }
  }

  // ﾏｽﾀ基本情報取得 
  async function getBmnCodeAttr() {
    const response =  await Axios.get(masterInfoURL);   
    setCodeAttr(response.data['CodeAttr']); 
  }

  // AIKCMN001000006_Get1：ルールパターンデータ作成
  async function rulePatternOnGet(url: string, auditTargetKbn: number) {
    const encodeUrl = [url, encodeURI(auditTargetKbn.toString())].join('/');
    await Axios.get<RulePatternViewModel>(encodeUrl).then(response => {
      setRulePatternViewModel(response.data);
    });
  }

  // AIKADT002000001_Post11：著増減監査実行
  async function ridAuditOnPost(url: string, stMonth: number, edMonth: number, rulePattern: Enums.RulePatternEnum, multipleFlg = false, auditTypeCount = 1, auditTarget: AuditTargetManagerVo = auditTargetVO) {
    let auditTargetKbn = auditTarget.target!;
    let hojyoCodeList = auditTarget.items!;
    const encodedUrl = [url, encodeURI(stMonth.toString()), encodeURI(edMonth.toString()), rulePattern, encodeURI(multipleFlg.toString()), encodeURI(auditTargetKbn.toString())].join('/');
    const response = await Axios.post<AuditRemarkIncDecViewModel>(encodedUrl, {
      AuditTypeCount: auditTypeCount,
      hojyoCodeList: hojyoCodeList
    });
    return response.data.Guid;
  }

  // AIKADT002000001_Post12：著増減進捗率取得
  async function ridProgressOnPost(url: string, guid: string) {
    const encodedUrl = [url, encodeURI(guid)].join('/');
    const response = await Axios.post<AuditRemarkIncDecViewModel>(encodedUrl);
    return response.data.Progress!;
  }

  // AIKADT002000001_Post21：マイナス残高監査実行
  async function nbAuditOnPost(url: string, stMonth: number, edMonth: number, rulePattern: Enums.RulePatternEnum, mutilpleFlg = false, auditTypeCount = 1, auditTarget: AuditTargetManagerVo = auditTargetVO) {
    let auditTargetKbn = auditTarget.target!;
    let hojyoCodeList = auditTarget.items!;
    const encodedUrl = [url, encodeURI(stMonth.toString()), encodeURI(edMonth.toString()), rulePattern, encodeURI(mutilpleFlg.toString()), encodeURI(auditTargetKbn.toString())].join('/');
    const response = await Axios.post<AuditNegativeBalanceViewModel>(encodedUrl, {
      AuditTypeCount: auditTypeCount,
      hojyoCodeList: hojyoCodeList
    });
    return response.data.Guid;
  }

  // AIKADT002000001_Post22：マイナス残高進捗率取得
  async function nbProgressOnPost(url: string, guid: string) {
    const encodedUrl = [url, encodeURI(guid)].join('/');
    const response = await Axios.post<AuditNegativeBalanceViewModel>(encodedUrl);
    return response.data.Progress!;
  }

  // AIKADT002000001_Get31：ルール一覧表示データ作成
  async function jlRuleListOnGet(url: string) {
    await Axios.post<AuditJournalLineViewModel>(url, {
      selectMonth: selectedMonth
    }).then(response => {
      setJLRules(response.data.RuleItems!);
    });
  }

  // AIKADT002000001_Post31：仕訳明細監査実行
  async function jlAuditOnPost(url: string, stNMonth: number, edNMonth: number, rules: Array<JournalLineRuleAuditVO>, auditTypeCount = 1) {
    const encodedUrl = [url, encodeURI(stNMonth.toString()), encodeURI(edNMonth.toString())].join('/');
    const response = await Axios.post<AuditJournalLineViewModel>(encodedUrl, {
      journalLineRuleAudit: rules,
      AuditTypeCount: auditTypeCount
    });
    return response.data.Guid;
  }

  // AIKADT002000001_Post32：仕訳明細進捗率取得
  async function jlProgressOnPost(url: string, guid: string) {
    const encodedUrl = [url, encodeURI(guid)].join('/');
    const response = await Axios.post<AuditJournalLineViewModel>(encodedUrl);
    return response.data.Progress!;
  }

  // AIKADT002000001_Get41：仕訳重複監査実行
  async function jdCheckPatternOnGet(url: string) {
    await Axios.get<AuditJournalDoubleCheckPatternViewModel>(url).then(response => {
      setCheckPattern(response.data.JokenItems!);
    });
  }

  // AIKADT002000001_Post41：仕訳重複監査実行
  async function jdAuditOnPost(url: string, stMonth: number, edMonth: number, jokens: Array<Enums.DblJokenEnum>, auditTypeCount = 1) {
    const encodedUrl = [url, encodeURI(stMonth.toString()), encodeURI(edMonth.toString())].join('/');
    const response = await Axios.post<AuditJournalDoubleViewModel>(encodedUrl, {
      dblJoken: jokens,
      AuditTypeCount: auditTypeCount
    });
    return response.data.Guid;
  }

  // AIKADT002000001_Post42：仕訳重複進捗率取得
  async function jdProgressOnPost(url: string, guid: string) {
    const encodedUrl = [url, encodeURI(guid)].join('/');
    const response = await Axios.post<AuditJournalDoubleViewModel>(encodedUrl);
    return response.data.Progress!;
  }

  // AIKADT002000001_Post51：消費税区分監査実行
  async function tkAuditOnPost(url: string, stMonth: number, edMonth: number, auditTypeCount = 1) {
    const encodedUrl = [url, encodeURI(stMonth.toString()), encodeURI(edMonth.toString())].join('/');
    const response = await Axios.post<AuditTaxKubunViewModel>(encodedUrl, {
      AuditTypeCount: auditTypeCount
    });
    return response.data.Guid;
  }

  // AIKADT002000001_Post52：消費税区分進捗率取得
  async function tkProgressOnPost(url: string, guid: string) {
    const encodedUrl = [url, encodeURI(guid)].join('/');
    const response = await Axios.post<AuditTaxKubunViewModel>(encodedUrl);
    return response.data.Progress!;
  }

  // 著増減／マイナス残高実行チェック
  async function monthlyBalanceRuleSettingOnGet(url: string, rulePattern: Enums.RulePatternEnum) {
    const encodedUrl = [url, rulePattern].join('/');
    const response = await Axios.get<MonthlyBalanceRuleSettingViewModel>(encodedUrl);
    return response.data;
  }

  // **************************************************
  // 内部共通関数
  // **************************************************
  //監査未確認があるか
  const auditIsUnconfirmed = (): boolean => {
    return [ridRemain, nbRemain, jlRemain, jdRemain, tkRemain].some(remain => remain > 0);
  };

  //スリープ
  const sleep = (ms: number) =>
    new Promise<void>(resolve => {
      setTimeout(() => resolve(), ms);
    });

  //権限チェックFlg 0:起動可能 その他:起動不可
  const [authCheckFlg, setAuthCheckFlg] = React.useState(-1);
  React.useEffect(() => {
    // 処理判定
    async function JudgeOnPost(url: string, syoriId: string) {
      const status = await Axios.post<number>(url, {syoriId: syoriId});
      setAuthCheckFlg(status.data);
      if (status.data == 0) {
        //システム履歴記入
        await Axios.post(WriteSysHistoryURL, {syoriId: syoriId, operation: '処理開始'});
      } else {
        if(status.data == -101) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: Messages.Common_Error_StartCheck_NotUse});
        } else if (status.data == -102) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: Messages.Common_Error_StartCheck_ContractOver});
        } else if (status.data == -103) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: Messages.Common_Error_StartCheck_Notauthorized});
        } else if (status.data == -104) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: Messages.Common_Error_StartCheck_NotDataSelect});
        } else if (status.data == -105) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: Messages.Common_Error_StartCheck_ContractEqual});
        }
        props.onClose();
      }
    }
    JudgeOnPost(JudgeURL, 'AIKADT002000001');
  }, []);
  // AuditTargetManager REF
  const auditTargetREF = React.useRef<any>();
  React.useEffect(() => {
    if (auditTargetREF.current != undefined) {
      auditTargetREF.current.initial(true);
    }
  }, [props.fiscalYear]);
  // 通信表示監視処理
  React.useEffect(() => {
    if (isScreenLock) {
      setIsLoading(true);
    }
  }, [getLodingStatus()]);
  return (authCheckFlg != 0)? (<div></div>) : (
    <UpperActionLayout className='vw-100 h-100 ExecutionResult'>
      <UpperActionLayoutHeader>
        <PageHeader
          pageTitle={Labels.AIKADT002000001_FUNCTION_NAME}
          excelButtonTabIndex={12}
          excelButtonDisabled={excelButtonDisabled}
          onExcelClick={handleOnExcelClick}
          closeButtonTabIndex={13}
          onCloseClick={handleOnCloseClick}
        >
          <div className='d-flex w-100'>
            <div className='flex-grow-1'>
              {/* 月選択 */}
              <MonthSelect
                displayMode={MODE.resultDisplay}
                onMonthChange={handleOnMonthChange}
                tabIndex={1}
                refreshCnt={monthSelectRefreshCnt}
                dataRelationCheck={true}
                fiscalYear={props.fiscalYear}
              />
            </div>
            <div className='mx-1' hidden={bmnAuditInfoHidden}>
              <IconButton
                key='BmnAuditInfo'
                data-testid='bmnAuditInfo-icon'
                onClick={handleBmnAuditInfoClick}
                icon={<TableButton />}
                className={'-icon'}
                tabIndex={11}
              >
                {'部門別監査状況'}
              </IconButton>
            </div>
            <div className='mx-1'>
              <IconButton
                key='Excel'
                data-testid='excel-icon'
                onClick={handleExcelDetailClick}
                disabled={excelButtonDisabled}
                icon={<ExcelButton />}
                className={'-icon'}
                tabIndex={11}
              >
                {Labels.AIKADT002000001_BUTTON_FUNCTION_RESULT_EXPORT}
              </IconButton>
            </div>
          </div>
        </PageHeader>
      </UpperActionLayoutHeader>
      <UpperActionLayoutAction className='px-3 pt-3 text-nowrap'>
        <AuditTargetManager
          name={'ExecutionResult-AuditTarget'}
          transmitSelectItems={handleOnTransAuditTarget}
          transmitBmnList={handleOnBmnList}
          defaultSelectItem={defaultAuditTargetVO}
          applyCombo={true}
          tabIndex={2}
          forwardRef={auditTargetREF}
        />
        {/* 著増減監査実行ボタン */}
        <AuditExecutionButton
          color={Enums.AuditTypeEnum[Enums.AuditTypeEnum.RemarkIncDecAudit]}
          disabled={ridBtnDisabled}
          onClick={handleOnRidClick}
          isAuditExecution={ridIsAuditExecution}
          remain={ridRemain}
          tabIndex={3}
        >
          {Labels.AIKADT002000001_BUTTON_FUNCTION_REMARKINCDECEXECUTION}
        </AuditExecutionButton>
        {/* マイナス残高監査実行ボタン */}
        <AuditExecutionButton
          color={Enums.AuditTypeEnum[Enums.AuditTypeEnum.NegativeBalanceAudit]}
          disabled={nbBtnDisabled}
          onClick={handleOnNBClick}
          isAuditExecution={nbIsAuditExecution}
          remain={nbRemain}
          tabIndex={4}
        >
          {Labels.AIKADT002000001_BUTTON_FUNCTION_NEGATIVEBALANCEEXECUTION}
        </AuditExecutionButton>
        {/* 仕訳明細監査実行ボタン */}
        <AuditExecutionButton
          color={Enums.AuditTypeEnum[Enums.AuditTypeEnum.JournalLineAudit]}
          disabled={jlBtnDisabled || auditTargetVO.target == MasterKbn.Bmn}
          onClick={handleOnJLClick}
          isAuditExecution={jlIsAuditExecution}
          remain={jlRemain}
          tabIndex={5}
        >
          {Labels.AIKADT002000001_BUTTON_FUNCTION_JOURNALLINEEXECUTION}
        </AuditExecutionButton>
        {/* 仕訳重複監査実行ボタン */}
        <AuditExecutionButton
          color={Enums.AuditTypeEnum[Enums.AuditTypeEnum.JournalDoubleAudit]}
          disabled={jdBtnDisabled || auditTargetVO.target == MasterKbn.Bmn}
          onClick={handleOnJDClick}
          isAuditExecution={jdIsAuditExecution}
          remain={jdRemain}
          tabIndex={6}
        >
          {Labels.AIKADT002000001_BUTTON_FUNCTION_JOURNALDOUBLEEXECUTION}
        </AuditExecutionButton>
        {/* 消費税区分監査実行ボタン */}
        <AuditExecutionButton
          color={Enums.AuditTypeEnum[Enums.AuditTypeEnum.TaxKubunAudit]}
          disabled={tkBtnDisabled || auditTargetVO.target == MasterKbn.Bmn}
          onClick={handleOnTKClick}
          isAuditExecution={tkIsAuditExecution}
          remain={tkRemain}
          tabIndex={7}
        >
          {Labels.AIKADT002000001_BUTTON_FUNCTION_TAXKUBUNEXECUTION}
        </AuditExecutionButton>
        {/* 一括監査実行ボタン */}
        <Button
          color='secondary'
          disabled={abBtnDisabled}
          onClick={handleOnABClick}
          size='lg'
          tabIndex={8}
        >
          {Labels.AIKADT002000001_BUTTON_FUNCTION_BATCHEXECUTION}
        </Button>
      </UpperActionLayoutAction>
      <UpperActionLayoutContents className='vw-100 p-3'>
        {/* 残高結果一覧グリッド */}
        <AuditResultSummaryGrid
          gridClassName='ExecutionResult-grid'
          viewModel={viewModel}
          bpKbnTabIndex={9}
          tabIndex={10}
          gridRef={refGrid}
          onRemarkBadgeClick={handleOnRemarkBadgeClick}
          onNegativeBadgeClick={handleOnNegativeBadgeClick}
          onJournalBadgeClick={handleOnJournalBadgeClick}
          onDuplicateBadgeClick={handleOnDuplicateBadgeClick}
          onExciseBadgeClick={handleOnExciseBadgeClick}
          onFilterChanged={handleOnFilterChanged}
          onSelectScreenType={handleOnSelectActivateScreenType}
          selectScreenType={activateScreenType}
        />
      </UpperActionLayoutContents>
      {/* Excel出力設定 */}
      <ExcelAuditTargetOutputsetting
        activated={excelPopActivated}
        templateId={excelTemplateId}
        bmnTemplateId={excelBmnTemplateId}
        filters={excelFilters}
        serviceParams={excelServiceParams}
        onClose={handleOnExcelClose}
        initAuditTarget={auditTargetVO}
        stNMonth={selectedMonth[0]}
        edNMonth={selectedMonth[selectedMonth.length - 1]}
      />
      {/* 著増減監査実行ポップアップ */}
      <AuditExecComboPopup
        isOpen={ridPopIsOpen}
        header={Labels.AIKADT002000001_BUTTON_FUNCTION_REMARKINCDECEXECUTION}
        headerColor={Enums.AuditTypeEnum[Enums.AuditTypeEnum.RemarkIncDecAudit]}
        onCloseClick={handleOnRidPopClose}
        description={Labels.AIKADT002000001_INPUT_HEADER_REMARKINCDECCONTENT}
        errorMessage={ridErrMsg}
        isExecute={ridIsExecute}
        prgMessage={
          GetValueFromDataMap(Maps.AuditTypeEnum, Enums.AuditTypeEnum.RemarkIncDecAudit) +
          Labels.AIKADT002000001_INPUT_HEADER_EXECUTING
        }
        prgRate={ridPrgRate}
        rulePatternVM={rulePatternVM}
        onExcuteClick={handleOnRidPopClick}
      />
      {/* マイナス残高監査実行ポップアップ */}
      <AuditExecComboPopup
        isOpen={nbPopIsOpen}
        header={Labels.AIKADT002000001_BUTTON_FUNCTION_NEGATIVEBALANCEEXECUTION}
        headerColor={Enums.AuditTypeEnum[Enums.AuditTypeEnum.NegativeBalanceAudit]}
        onCloseClick={handleOnNBPopClose}
        description={Labels.AIKADT002000001_INPUT_HEADER_NEGATIVEBALANCECONTENT}
        isExecute={nbIsExecute}
        errorMessage={nbErrMsg}
        prgMessage={
          GetValueFromDataMap(Maps.AuditTypeEnum, Enums.AuditTypeEnum.NegativeBalanceAudit) +
          Labels.AIKADT002000001_INPUT_HEADER_EXECUTING
        }
        prgRate={nbPrgRate}
        rulePatternVM={rulePatternVM}
        onExcuteClick={handleOnNBPopClick}
      />
      {/* 仕訳明細監査実行ポップアップ */}
      <AuditExecGridPopup
        id='ExecutionResult-JournalLine-popup'
        isOpen={jlPopIsOpen}
        header={Labels.AIKADT002000001_BUTTON_FUNCTION_JOURNALLINEEXECUTION}
        headerColor={Enums.AuditTypeEnum[Enums.AuditTypeEnum.JournalLineAudit]}
        onCloseClick={handleOnJLPopClose}
        description={Labels.AIKADT002000001_INPUT_HEADER_JOURNALLINECONTENT}
        disabled={jlPopDisabled}
        errorMessage={jlErrMsg}
        isExecute={jlIsExecute}
        prgMessage={
          GetValueFromDataMap(Maps.AuditTypeEnum, Enums.AuditTypeEnum.JournalLineAudit) +
          Labels.AIKADT002000001_INPUT_HEADER_EXECUTING
        }
        prgRate={jlPrgRate}
        conditionHeading={Labels.AIKADT002000001_INPUT_HEADER_JOURNALLINECONDITION}
        journalLineRules={jlRules}
        onJLClick={handleOnJlPopClick}
      />
      {/* 仕訳重複監査実行ポップアップ */}
      <AuditExecCheckPopup
        isOpen={jdPopIsOpen}
        header={Labels.AIKADT002000001_BUTTON_FUNCTION_JOURNALDOUBLEEXECUTION}
        headerColor={Enums.AuditTypeEnum[Enums.AuditTypeEnum.JournalDoubleAudit]}
        onCloseClick={handleOnJDPopClose}
        description={Labels.AIKADT002000001_INPUT_HEADER_JOURNALDOUBLECONTENT}
        isExecute={jdIsExecute}
        errorMessage={jdErrMsg}
        disabled={jdPopDisabled}
        checkpattern={jdCheckPattern}
        prgMessage={
          GetValueFromDataMap(Maps.AuditTypeEnum, Enums.AuditTypeEnum.JournalDoubleAudit) +
          Labels.AIKADT002000001_INPUT_HEADER_EXECUTING
        }
        prgRate={jdPrgRate}
        conditionHeading={Labels.AIKADT002000001_INPUT_HEADER_JOURNALDOUBLECONDITION}
        onJDClick={handleOnJDPopClick}
        jdpopSave={jdPopSave}
      />
      {/* 消費税区分監査実行ポップアップ */}
      <AuditExecPopup
        isOpen={tkPopIsOpen}
        header={Labels.AIKADT002000001_BUTTON_FUNCTION_TAXKUBUNEXECUTION}
        headerColor={Enums.AuditTypeEnum[Enums.AuditTypeEnum.TaxKubunAudit]}
        onCloseClick={handleOnTKPopClose}
        description={Labels.AIKADT002000001_INPUT_HEADER_TAXKUBUNCONTENT}
        errorMessage={tkErrMsg}
        isExecute={tkIsExecute}
        prgMessage={
          GetValueFromDataMap(Maps.AuditTypeEnum, Enums.AuditTypeEnum.TaxKubunAudit) +
          Labels.AIKADT002000001_INPUT_HEADER_EXECUTING
        }
        prgRate={tkPrgRate}
        onClick={handleOnTKPopClick}
      />
      {/* 一括監査実行ポップアップ */}
      <AuditBatchExecPopup
        isOpen={ABPopIsOpen}
        header={Labels.AIKADT002000001_BUTTON_FUNCTION_BATCHEXECUTION}
        description={Labels.AIKADT002000001_INPUT_HEADER_BATCHEXECUTION}
        conditionHeading={Labels.AIKADT002000001_INPUT_HEADER_BATCHEXECUTIONCONDITION}
        errorMessage={abErrMsg}
        onCloseClick={handleOnABPopClose}
        onABClick={handleOnABPopClick}
        isExecute={abIsExecute}
        journalLineRules={jlRules}
        checkpattern={jdCheckPattern}
        stNMonth={selectedMonth[0]}
        edNMonth={selectedMonth[selectedMonth.length - 1]}
        defaultAuditTarget={auditTargetVO}
      />
      <ProgressBarPopup
        isOpen={ProgressIsOpen}
        prgMessage={
          '一括監査' +
          Labels.AIKADT002000001_INPUT_HEADER_EXECUTING
        }
        prgRate={abPrgRate}
      />
      {/* 監査結果管理ポップアップ */}
      <Result
        activated={resultActivated}
        onClose={handleOnResultPopClose}
        AuditType={auditType}
        stNMonth={selectedMonth[0]}
        edNMonth={selectedMonth[selectedMonth.length - 1]}
        SeqNo={seqNo}
        KmkCode={kmkCode}
        ActivateScreenType={activateScreenType}
      />
      {/* 仕訳明細ポップアップ */}
      <JournalLine
        activated={journalLineActivated}
        onClose={handleOnJournalLineClose}
        auditType={auditType}
        nMonth={selectedMonth[0]}
        stNMonth={selectedMonth[0]}
        edNMonth={selectedMonth[selectedMonth.length - 1]}
        seqNo={seqNo}
        rowNo={0}
        activateScreenType={activateScreenType!}
        kmkCode={kmkCode}
      />
      {/* 部門別監査状況ポップアップ */}
      <BmnAuditInfoPopup
        activated={bmnAuditInfoActivated}
        onClose={handleOnBmnAuditInfoClose}
        viewModel={bmnViewModel}
        stNMonth={selectedMonth[0]}
        codeAttr={codeAttr}
      />
      <ImportApi activated={isImportApiOpen} onClose={handleOnImportApiClose} />
    </UpperActionLayout>
  );
};
export default ExecutionResult;
export var selectScreenType = Enums.ActivateScreenEnum.Result;