import { Page } from "./interface";
import { MasterDataManager } from "../dataAccess/masterDataManager";
import { HeaderBase } from "../support/pageHeaders/headerBase";
import { UserInfoCacheContainer } from "../models/cache/userInfoCacheContainer";
import { CacheDataType, SystemDiv } from "../core/constants/enums";
import { PageInitializeParameter } from "../pageController";
import { Logger } from "../common/usefuls/logger";
import { BusyIndicator } from "../core/busyIndicator";
import * as wijmo from 'wijmo/wijmo';
import { journalControlUtil } from "../common/Biz/swklControlUtil";
import { MjsEventContainer } from "../common/usefuls/event";
import { ErrorInfoEventArgs } from "../common/event/eventData";
import { UserInfoCacheViewModel } from "../models/cache/userInfoCacheViewModel";
import { ProcessMigrationHeader } from "../support/pageHeaders/processMigrationHeader";
import { Tuple } from "../common/usefuls/utilities";
import { SummaryStyleClass } from "../core/constants/constant";
import { UnloadCheck } from "./common/unloadCheck";
import { Button } from "../support/component/button/simpleButton";
import { AlertDialog } from "../support/component/overlay/abstractDialog";
import { messageUtil } from "../common/messageUtil";
import { Messages } from "../common/message";
import { SystemHeader } from "../support/pageHeaders/systemHeader";
import $ from 'jquery';
import { Mjs } from "../mjs";
import "../common/usefuls/utilities";
import { MjsEventArgs } from "../common/event/eventData";


export abstract class AbstractPage implements Page {

    private mPageId!: string;
    private mMasterDataManager!: MasterDataManager;
    private mHeaderManager!: HeaderBase;
    // private mFunctionPanelManager: undefined;
    private mUserInfoCacheContainer!: UserInfoCacheContainer;
    protected drillDownFlg!: boolean;

    /**
     * ページ準備中にローディングイメージを表示させるかどうかを取得する。既定で表示させる。必要に応じて具象クラスがOverrideすること。
     */
    protected get showLoadingImage(): boolean { return true; }

    /**
     * マスター情報をメモリに展開しない場合はtrue。必要に応じて具象クラスがOverrideすること。
     */
    protected get noLoadMasterData(): boolean { return false; }

    /**
     * ページがFunctionパネルを所持するかどうかを取得する。既定で所持する。必要に応じて具象クラスがOverrideすること。
     */
    protected get hasFunctionPanel(): boolean { return true; }

    /**
     * ページがシステム共通ヘッダーを所持するかどうかを取得する。既定で所持する。必要に応じて具象クラスがOverrideすること。
     */
    protected get hasSystemHeader(): boolean { return true; }

    /**
     * マスターデータにアクセスするためのMasterDataManagerを取得する。masterDataLoadCompleteCallbackの発生以降からアクセス可。
     */
    protected get masterDataManager(): MasterDataManager { return this.mMasterDataManager; }

    /**
     * ページが必要とするマスターデータの種別を取得する。必要に応じて具象クラスがOverrideすること。
     * ※'UserInfo'は親クラスで自動的に取得するため、ページ側での定義は不要
     */
    protected get targetCacheTypes(): CacheDataType[] { return null!; }

    /**
     * ヘッダー管理クラスを取得する。postInitializeメソッドのタイミング以降よりアクセス可能。（プロパティだとGenericsが使用できないのでメソッドで提供）
     */
    protected getHeader<THeader extends HeaderBase>(): THeader { return <THeader>this.mHeaderManager; }

    /**
     * Functionパネル管理クラスを取得する。postInitializeメソッドのタイミング以降よりアクセス可能。
     */
    // protected getFunctionPanelManager(): undefined { return this.mFunctionPanelManager; }

    /**
     * 月選択コントロールの決算ラベル有無
     */
    protected mMonthSelectorHasClosingOption: boolean = true;
    protected get monthSelectorHasClosingOption(): boolean { return this.mMonthSelectorHasClosingOption; }

    /**
     * 月選択コントロールの複数選択有無
     */
    protected get monthSelectorMultiSelect(): boolean { return true; }

    /**
     * UserInfoCacheContainerを取得する。masterDataLoadCompleteCallbackの発生以降からアクセス可。
     * ※masterDataManager経由で取得できるコンテナと同一内容。どのページでも必ず管理する情報のため独立したプロパティで公開している
     */
    protected get userInfoCacheContainer(): UserInfoCacheContainer { return this.mUserInfoCacheContainer; }

    /**
     * 現在管理年度を取得する
     */
    protected get currentClientYear(): number { return Mjs.clientYear; }

    /**
     * マスターデータの非同期ロードが完了しているかどうか。
     * true：マスターデータの非同期ロードが完了している
     * false：マスターデータの非同期ロードが未完了
     */
    protected mMasterDataLoadCompleted: boolean = false;
    /**
     * マスターデータの非同期ロードが完了しているかどうかを取得する。
     * true：マスターデータの非同期ロードが完了している
     * false：マスターデータの非同期ロードが未完了
     */
    protected get masterDataLoadCompleted(): boolean { return this.mMasterDataLoadCompleted; }

    constructor() {
    }

    /**
     * ページの初期化処理を行う
     * @param parameter
     */
    public initialize(parameter: PageInitializeParameter): void {

        // PageID取得
        if (parameter.$scope == null || parameter.$scope.viewModel == null || parameter.$scope.viewModel.PageID == null) {
            throw Error("PageID is required.");
        }
        this.mPageId = parameter.$scope.viewModel.PageID;
        this.drillDownFlg = (window as any).drillDownFlg;

        Logger.debugTimeStamp("PageID " + this.mPageId);

        // 内部事前処理
        Logger.debugTimerStart("innerInitialize");
        this.innerInitialize();
        Logger.debugTimerStop("innerInitialize");

        Logger.debugTimerStart("preInitialize");
        this.preInitialize(parameter);
        Logger.debugTimerStop("preInitialize");

        Logger.debugTimerStart("innerPageInitialize");
        this.innerPageInitialize(parameter);
        Logger.debugTimerStop("innerPageInitialize");

        Logger.debugTimerStart("pageInitialize");
        this.pageInitialize(parameter);
        Logger.debugTimerStop("pageInitialize");

        Logger.debugTimerStart("postInitialize");
        this.postInitialize();
        Logger.debugTimerStop("postInitialize");

        // 内部事後処理
        Logger.debugTimerStart("innerFinalize");
        this.innerFinalize();
        Logger.debugTimerStop("innerFinalize");

        // キーイベントのバインド処理
        Logger.debugTimerStart("keyEventBind");
        this.keyEventBind();
        Logger.debugTimerStop("keyEventBind");

        // イベントのバインド処理
        this.eventBind();
    }

    /**
     * ページ初期化前の処理を行う。必要に応じて具象クラスがOverrideすること。
     */
    protected preInitialize(parameter: PageInitializeParameter) {

        // 仕訳コントロール情報を取得(共通として扱う。使用しない箇所はViewModelに含まれない)
        let journalControl: any = parameter.$scope.viewModel.ControlInfo;
        if (journalControl) {
            // journalControlUtilのイニシャライズを行う。これでどこでもjournalControlUtilが使用可能となる。
            journalControlUtil.initialize(journalControl);
        }

    }

    /**
     * ページ初期化処理を行う。
     */
    protected pageInitialize(parameter: PageInitializeParameter): void { }

    /**
     * ページ初期化後の処理を行う。必要に応じて具象クラスがOverrideすること。
     */
    protected postInitialize(): void { /* noop */ }

    /**
     * マスターデータロード完了コールバック。必要に応じて具象クラスがOverrideすること。
     */
    protected masterDataLoadCompleteCallback(): void { /* noop */ }
    /**
     * マスターデータロード後、LoadingPanelが非表示となった後に最後のイニシャライズを行う際にコールされる関数。
     * 必要に応じて継承したクラスでOverrideすること。
     */
    protected afterMasterDataLoadProcess(): void { /* noop */ }

    /**
     * 処理中インジケータを表示します。
     */
    protected showLoadingArea(): void {
        BusyIndicator.open();
    }

    /**
     * 処理中インジケータを除去します。
     */
    protected hideLoadingArea(): void {
        //if (selectedSystemDiv == SystemDiv.Payroll.toString()) {
        //    return;
        //}
        BusyIndicator.close();
    }


    /**
     * キーボードイベントバインド
     */
    protected keyEventBind(): void {
        $("body").keyup((e: KeyboardEvent) => {
            if (BusyIndicator.checkOpening()) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                return;
            }
            switch (e.keyCode) {
                case wijmo.Key.Escape:
                    this.inPageEscapeKeyupEvent(e);
                    break;

                case wijmo.Key.End:
                    this.inPageEndKeyupEvent(e);
                    break;

            }
        });

        $("body").on("keydown", (e) => {
            if (BusyIndicator.checkOpening()) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                return;
            }
            switch (e.keyCode) {
                // ブラウザ更新処理抑制
                case wijmo.Key.F5:
                    e.preventDefault();
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    break;
            }
        });
    }

    /**
     * エスケープキーイベント（keyup）
     * @param e
     */
    protected inPageEscapeKeyupEvent(e: KeyboardEvent): void {
        Logger.debug("inPageEscapeKeyupEvent");
    }

    /**
     * エンドキーイベント（keyup）
     * @param e
     */
    protected inPageEndKeyupEvent(e: KeyboardEvent): void {
        Logger.debug("inPageEndKeyupEvent");
    }

    /**
     * イベントのバインド
     */
    protected eventBind(): void {
        // bootstrap モーダルダイアログ
        //  クローズイベントに処理を追加
        $('div.modal').on("hide.bs.modal", (e: JQueryEventObject) => {
            // ダイアログ画面上のwijmoフィルタ画面をクローズ
            $(".wj-columnfiltereditor").remove();
        });
    }

    /**
     * マスターデータを非同期でロードする。ロード終了のコールバックはmasterDataLoadCompleteCallbackで受け取る。
     */
    private loadMasterDataAsync(cacheTypes: CacheDataType[]) {
        Logger.debugTimeStamp("AbstractPage.loadMasterDataAsyncマスターデータを非同期でロードする★開始★");

        this.mMasterDataManager = new MasterDataManager(cacheTypes);
        this.masterDataManager.addMasterLoadCompleteEventHandler(new MjsEventContainer<ErrorInfoEventArgs>((s, e) => {

            Logger.debugTimerStop("loadMasterDataAsync");
            Logger.debugTimeStamp("AbstractPage.loadMasterDataAsyncマスターデータを非同期でロードする★終了★");

            if (e.hasError) {
                if (e.errorCode == MasterDataManager.ERROR_IDX_DB_OPEN) {
                    // IndexdDB操作エラーなので、サーバーからの強制取得として再試行
                    Logger.debug("indexed db operate error occurred. retry at force load from server. ");
                    this.mMasterDataManager.changeYearAsync(Mjs.clientYear, true);
                } else {
                    var msg = "通信エラーが発生しました。ページの再読み込みを行ってください。";
                    msg += "\n\nerror code:" + e.errorCode + "\n" + e.errorMessage;
                    Logger.debug(msg);
                    alert(msg);
                }
            } else {
                this.doMasterDataLoadCompleteProcess(cacheTypes);
                if (this.showLoadingImage) {
                    this.hideLoadingArea();
                }
                this.afterMasterDataLoadProcess();
                this.mMasterDataLoadCompleted = true;
            }
        }, this) as MjsEventContainer<MjsEventArgs>);

        try {
            Logger.debugTimerStart("loadMasterDataAsync");

            this.mMasterDataManager.changeYearAsync(Mjs.clientYear);
        } catch (e) {
            // IndexedDBの操作に失敗した可能性あり。サーバーからの強制取得として再試行。
            Logger.debug("error occurred at MasterDataManager. retry at force load from server. ");
            this.mMasterDataManager.changeYearAsync(Mjs.clientYear, true);
        }
    }

    private doMasterDataLoadCompleteProcess(cacheTypes: CacheDataType[]) {
        Logger.debugTimeStamp("AbstractPage.doMasterDataLoadCompleteProcess");
        // UserInfoが含まれる場合（給与で動作させない考慮）
        if (cacheTypes.any((e: CacheDataType) => e == CacheDataType.UserInfo)) {
            this.mUserInfoCacheContainer = this.masterDataManager.getDataContainer<UserInfoCacheViewModel, UserInfoCacheContainer>(CacheDataType.UserInfo);
            this.outputUserStyle();
            this.masterDataLoadCompleteCallback();
        }
    }

    // 内部で必要な初期化処理
    private innerInitialize() {
        if (this.showLoadingImage) {
            this.showLoadingArea();
        }
    }

    private innerPageInitialize(parameter: PageInitializeParameter) {
        if (this.hasSystemHeader) {
            // システム共通ヘッダークラスの初期化
            var systemHeader = new SystemHeader(parameter);
            systemHeader.initialize();
        }

        // ヘッダー管理クラスの初期化
        parameter.$scope.monthSelectorHasClosingOption = this.monthSelectorHasClosingOption;
        parameter.$scope.monthSelectorMultiSelect = this.monthSelectorMultiSelect;
        this.mHeaderManager = this.createHeaderManager(parameter);
        if (this.mHeaderManager != null) {
            this.mHeaderManager.initialize();
        }

        // Functionパネルとマネージャーの初期化
        if (this.hasFunctionPanel) {
            //var functionPanel = new mjs.acelink.vkz.controls.userControls.FunctionPanel();
            //functionPanel.initialize();
            //var groupContainer: mjs.acelink.vkz.common.data.FunctionGroupContainer = mjs.acelink.vkz.common.data.FunctionDataFactory.create(this.mPageId);
            //this.mFunctionPanelManager = new mjs.acelink.vkz.support.FunctonPanelManager(functionPanel, groupContainer);
        }
    }

    private innerFinalize() {
        if (!this.noLoadMasterData) {
            // 給与を対象外とする処理をコメントアウト
            // 給与はキャッシュを使用しないが、画面にインジケータを出すため処理を流す
            // これをしないと、ヘッダーの年度表示が非常に遅く見えてしまう
            // TODO：ヘッダーの年度表示をサーバー側のレンダリングで行う。ここは臨時対応になるため削除する。
            //if (selectedSystemDiv == SystemDiv.Payroll.toString()) {
            //    if (this.showLoadingImage) {
            //        this.hideLoadingArea();
            //    }
            //} else {

            // マスターデータの非同期ロード
            var cacheTypes: CacheDataType[] = this.targetCacheTypes == null ? [] : this.targetCacheTypes;
            if (Mjs.selectedSystemDiv == SystemDiv.Vkz.toString()) {
                if (!cacheTypes.any((e: CacheDataType) => e == CacheDataType.UserInfo)) {
                    cacheTypes.push(CacheDataType.UserInfo);
                }
            }
            this.loadMasterDataAsync(cacheTypes);
        } else {
            if (this.showLoadingImage) {
                this.hideLoadingArea();
            }
            this.mMasterDataLoadCompleted = true;
        }
    }

    // ヘッダー管理クラスのファクトリーメソッド
    private createHeaderManager(parameter: PageInitializeParameter): HeaderBase {

        var header: HeaderBase = undefined!;

        // ページに埋め込まれたヘッダーIDでインスタンス化対象のヘッダーを決定
        if (document.getElementById("processMigrationHeader") != null) {
            header = new ProcessMigrationHeader(parameter);
        }

        return header;
    }

    private outputUserStyle(): void {

        var tekiyozanModel = this.mUserInfoCacheContainer.models.singleOrNull((e: UserInfoCacheViewModel) => e.ItemNo == 11);
        var koteiTekiyoModel = this.mUserInfoCacheContainer.models.singleOrNull((e: UserInfoCacheViewModel) => e.ItemNo == 12);
        var tokusyuTekiyoModel = this.mUserInfoCacheContainer.models.singleOrNull((e: UserInfoCacheViewModel) => e.ItemNo == 13);
        var tekiyozanColor: string = tekiyozanModel == null ? "blue" : this.toCssColor(tekiyozanModel.UserKbn);
        var koteiTekiyoColor: string = koteiTekiyoModel == null ? "black" : this.toCssColor(koteiTekiyoModel.UserKbn);
        var tokusyuTekiyoColor: string = tokusyuTekiyoModel == null ? "black" : this.toCssColor(tokusyuTekiyoModel.UserKbn);

        var classes: Array<Tuple<string, string>> = [];

        classes.push(new Tuple<string, string>("." + SummaryStyleClass.SummaryBalance, "color:" + tekiyozanColor + ";"));
        classes.push(new Tuple<string, string>("." + SummaryStyleClass.Classification2, "color:" + koteiTekiyoColor + ";"));
        classes.push(new Tuple<string, string>("." + SummaryStyleClass.SpeSummary, "color:" + tokusyuTekiyoColor + ";"));

        var style = "<style type='text/css'>";
        for (let i = 0; i < classes.length; i++) {
            style += classes[i].Item1 + " {" + classes[i].Item2 + "} ";
        }
        style += "</style>";
        $("head", document).append($(style));
    }

    private toCssColor(rgb: number): string {
        var r: number = (rgb >> 16) & 0xFF;
        var g: number = (rgb >> 8) & 0xFF;
        var b: number = rgb & 0xFF;
        return '#' + (((256 + r << 8) + g << 8) + b).toString(16).substr(1, 6);
    }

    protected goHome(): void {
        UnloadCheck.submitFromMenu("/Home");
    }

    /**
     * ファイル出力可能かどうかを判定する。
     * ・可能であれば true を返す。
     * ・不可であればアラートを表示して false を返す。
     */
    protected canOutputFile(): boolean {
        if (Mjs.isPersonalPc) {
            return true;
        } else {
            let button = new Button(".on-ok");
            let dialog = new AlertDialog("#mjsCommonAlert", messageUtil.getMsg(Messages.Common.Title.CONFIRM), messageUtil.getMsg(Messages.Common.PRINTCONTROL), button);
            button.execute = () => {
                dialog.close();
            };
            dialog.open(".on-ok");
            return false;
        }
    }
}
