import { MonthRangeData } from "../data/monthRangeData";
import { RensouData } from "../usefuls/utilities";

/**
 * イベント引数の基本クラス
 */
export class MjsEventArgs {
}

/**
 * テキスト変更中(数値/文字列)に発生する引数クラス
 */
export class MjsInputtingStringEventArgs extends MjsEventArgs {

    // 旧入力値を保持
    private _oldInput: string;

    // 新入力値を保持
    private _newInput: string;

    // 最大文字数を保持
    private _maxLength: number;

    // アクセサ：旧入力値
    public get oldInput(): string { return this._oldInput; }

    // アクセサ：旧入力値
    public get newInput(): string { return this._newInput; }

    // アクセサ：コントロールのインスタンス
    public get maxLength(): number { return this._maxLength; }

    /**
     * コンストラクタ。旧文字列と、新しく入力された文字列を入力する
     * 対象は、数値入力と、文字列入力に対応
     * @param oldString
     * @param newString
     */
    constructor() {

        super();

        // 値をイニシャライズする
        this._maxLength = 0;
        this._oldInput = "";
        this._newInput = "";

    }

    public setNewParams(maxLength: number, oldInput: string, newInput: string) {

        // 値を更新する
        this._maxLength = maxLength;
        this._oldInput = oldInput;
        this._newInput = newInput;

    }

}

/**
 * キャンセル可能なイベントの引数クラス
 */
export class MjsCancelEventArgs extends MjsEventArgs {
    private _cancel: boolean = false;

    get cancel(): boolean {
        return this._cancel;
    }
    set cancel(cancel: boolean) {
        this._cancel = cancel;
    }
}

/**
 * 項目選択系イベントの引数クラス
 */
export class ItemSelectingEventArgs<T> extends MjsCancelEventArgs {

    private selectingItem: T;

    constructor(item: T) {
        super();
        this.selectingItem = item;
    }

    get item(): T {
        return this.selectingItem;
    }
}

/**
 * 項目選択系イベントの引数クラス
 */
export class ItemSelectedEventArgs<T> extends MjsEventArgs {

    private selectedItem: T;

    constructor(item: T) {
        super();
        this.selectedItem = item;
    }

    get item(): T {
        return this.selectedItem;
    }
}

/**
 * 選択項目リストの項目構築イベント引数クラス
 * ・additionalClassにて追加の適用スタイルを指定できる
 */
export class SelectListItemBuildEventArgs extends MjsEventArgs {
    private mRensouData: RensouData;
    private mAdditionalClass!: string;

    constructor(dataSource: RensouData) {
        super();
        this.mRensouData = dataSource;
    }

    /**
     * 項目構築元の情報となるRensouDataの参照を取得する
     */
    get rensouData(): RensouData {
        return this.mRensouData;
    }

    /**
     * 項目に追加適用するクラス（スタイル）名を取得する
     */
    get additionalClass(): string {
        return this.mAdditionalClass;
    }

    /**
     * 項目に追加適用するクラス（スタイル）名を設定する
     */
    set additionalClass(additionalClass: string) {
        this.mAdditionalClass = additionalClass;
    }
}

export class SelectMonthChangingEventArgs extends MjsCancelEventArgs {
    private mMonthRangeData: MonthRangeData;

    constructor(monthRangeData: MonthRangeData) {
        super();
        this.mMonthRangeData = monthRangeData;
    }

    /*
     * 選択情報を取得する
     */
    get monthRangeData(): MonthRangeData {
        return this.mMonthRangeData;
    }
}
/**
 * 月選択変更イベントの引数クラス
 */
export class SelectMonthChangedEventArgs extends MjsEventArgs {
    private mMonthRangeData: MonthRangeData;

    constructor(monthRangeData: MonthRangeData) {
        super();
        this.mMonthRangeData = monthRangeData;
    }

    /*
     * 選択情報を取得する
     */
    get monthRangeData(): MonthRangeData {
        return this.mMonthRangeData;
    }
}

/**
 * 値変更中通知系イベントの引数クラス
 */
export class ValueChangingEventArgs<T> extends MjsCancelEventArgs {
    private mOldValue: T;
    private mNewValue: T;

    constructor(oldValue: T, newValue: T) {
        super();
        this.mOldValue = oldValue;
        this.mNewValue = newValue;
    }

    get oldValue(): T {
        return this.mOldValue;
    }

    get newValue(): T {
        return this.mNewValue;
    }
}

/**
 * 値変更通知系イベントの引数クラス
 */
export class ValueChangedEventArgs<T> extends MjsEventArgs {
    private _value: T;

    constructor(value: T) {
        super();
        this._value = value;
    }

    get value(): T {
        return this._value;
    }
}

/**
 * エラー情報を保持するイベントの引数クラス
 */
export class ErrorInfoEventArgs extends MjsEventArgs {
    private mHasError: boolean;
    private mErrorCode: number;
    private mErrorMessage: string;

    constructor(hasError: boolean, errorCode: number, errorMessage: string) {
        super();
        this.mHasError = hasError;
        this.mErrorCode = errorCode;
        this.mErrorMessage = errorMessage;
    }

    /**
     * エラーの有無。trueならエラーあり。
     */
    get hasError(): boolean {
        return this.mHasError;
    }

    /**
     * エラーコード
     */
    get errorCode(): number {
        return this.mErrorCode;
    }

    /**
     * エラーメッセージ
     */
    get errorMessage(): string {
        return this.mErrorMessage;
    }
}

/**
 * ファンクション実行指示イベントの引数クラス
 */
export class FunctionCommandedEventArgs extends MjsEventArgs {
    private mFunctionIndex: number;
    constructor(functionIndex: number) {
        super();
        this.mFunctionIndex = functionIndex;
    }

    /**
     * 指示されたファンクションのインデックス値（0から始まる値）
     */
    get functionIndex(): number {
        return this.mFunctionIndex;
    }
}
