import { MjsDelegateContainer } from "../../../common/usefuls/delegate";
import { Logger } from "../../../common/usefuls/logger";
import { AbstractButton } from "./abstractButton";
import $ from 'jquery';

//ボタンクラス属性（主にWebフォント用,<a>タグ用）
export class LinkButton extends AbstractButton {
    private _css: string;
    private _handler!: MjsDelegateContainer<Function, any>;

    /**
     * コンストラクタ
     * @param selector：LinkButtonに関連付ける<a>タグボタンを特定するためのセレクタ
     * @param label：ボタンに表示するラベルを明示的に変更する際に指定する
     * @param css：ボタンスタイルを指定する際にcssクラス名を指定する
     */
    constructor(selector: string, label?: string, css?: string) {
        super(selector, label!);
        this._css = css!;
    }
    /**
     * ボタン押下時に実行されるイベントを発火。
     * 外部からこのAPIをコールすることで、強制的に実行することもできる。
     */
    public execute(): void {
        // 発火はEnable状態のときのみ
        if (!this.isEnabled()) {
            return;
        }
        Logger.debug("LinkButton : Firing PushButton Event");
        if (this._handler != null) {
            this._handler.invoke(null!);
        } else {
            Logger.debug("event handler is null");
        }
    }
    /**
     * 設定しているCssを取得する
     */
    public getCss(): string { return this._css; }

    /**
     * ボタンを有効化する
     */
    public enable(): void {
        $(this.getSelector()).removeClass(AbstractButton.disabledClass);
        //$(this.getSelector()).removeAttr("disabled");
        $(this.getSelector()).unbind("focusin");
    }

    /*
     * ボタンを 無効化する
     */
    public disable(): void {
        $(this.getSelector()).addClass(AbstractButton.disabledClass);

        //$(this.getSelector()).attr("disabled", "disabled");
        // フォーカス前のコントロールにフォーカスを戻す
        $(this.getSelector()).unbind("focusin");
        $(this.getSelector()).focusin((e) => { if (!$(e.relatedTarget).is(':focus')) $(e.relatedTarget).focus(); });
    }
    /**
     * ボタンの有効/無効をフラグで返す。
     * true:有効
     * false:無効
     */
    public isEnabled(): boolean {
        return !$(this.getSelector()).hasClass(AbstractButton.disabledClass);
    }
    /**
     * ボタン押下時のイベントを外部から設定する際にコールする。
     * @param handler：MjsDelegateContainerを使用したイベントハンドラ
     */
    public setEventHandler(handler: MjsDelegateContainer<Function, any>): void {
        this._handler = handler;
    }
}
