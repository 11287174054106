export class Mjs {
    public static clientCode: number;
    public static drillDownFlg: boolean;
    public static clientYear: number;
    public static isPersonalPc: boolean;
    public static isDemo: boolean;
    public static hsTokenValue: string;
    public static isOfficeAdmin: boolean;
    public static selectedSystemDiv: string;
    public static isDispCreateCompanyData: boolean;
    public static userDiv: string;
    public static selectedMenuCategoryId: string;
    public static lastSelectedMenuCategoryId: string;
    public static clientFiscalYear: string;
    public static existVkzData: boolean;
    public static existPayrollData: boolean;
}
