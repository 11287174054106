/**
 * 中間決算区分
 */
export enum MiddleKbn {

    /** なし */
    None = 0,

    /** 半期決算 */
    HalfPeriod = 1,

    /** 四半期決算 */
    QuarterPeriod = 2,
}

/**
 * 公益法人展開ステータス
 */
export enum KoekiSetStatus {

    /** 展開する */
    Deployment = 0,

    /** 展開しない（組み合わせが存在しない） */
    NotExists = -1,

    /** 展開しない（入力区分が入力不可） */
    InputDisabled = -2,

    /** 展開しない（一般法人） */
    GeneralCorporation = -3,

    /** 展開しない（自動展開なし） */
    NotAutomaticDeployment = -4,

    /** 展開しない（詳細情報が取得できない） */
    NotBeRetrieved = -5,

    /** 展開しない（該当する第２科目が存在しない） */
    NotExistsSecondKmk = -6,

    /** 展開しない（その他） */
    Etc = -9,
}

/**
 * クライアントキャッシュデータ種別
 */
export enum CacheDataType {

    /** マスタ基本情報 */
    MasterInfo = 0,

    /** 区分情報（採用区分） */
    KbnInfo = 1,

    /** 勘定科目基本ﾏｽﾀ（マスタ区分：勘定科目） */
    KmkMA_Kmk = 2,

    /** 勘定科目基本ﾏｽﾀ（マスタ区分：科目別補助） */
    KmkMA_Sub = 3,

    /** 補助基本ﾏｽﾀ（マスタ区分：銀行） */
    HojyoMA_Bank = 4,

    /** 補助基本ﾏｽﾀ（マスタ区分：取引先） */
    HojyoMA_Client = 5,

    /** 補助基本ﾏｽﾀ（マスタ区分：社員） */
    HojyoMA_Staff = 6,

    /** 補助基本ﾏｽﾀ（マスタ区分：汎用補助1） */
    HojyoMA_GenHojo1 = 7,

    /** 補助基本ﾏｽﾀ（マスタ区分：汎用補助2） */
    HojyoMA_GenHojo2 = 8,

    /** 補助基本ﾏｽﾀ（マスタ区分：汎用補助3） */
    HojyoMA_GenHojo3 = 9,

    /** 補助基本ﾏｽﾀ（マスタ区分：汎用補助4） */
    HojyoMA_GenHojo4 = 10,

    /** 補助基本ﾏｽﾀ（マスタ区分：汎用補助5） */
    HojyoMA_GenHojo5 = 11,

    /** 補助基本ﾏｽﾀ（マスタ区分：部門） */
    HojyoMA_Bmn = 12,

    /** 補助基本ﾏｽﾀ（マスタ区分：セグメント） */
    HojyoMA_Seg = 13,

    /** 補助基本ﾏｽﾀ（マスタ区分：工事） */
    HojyoMA_Koji = 14,

    /** 固定摘要ﾏｽﾀ */
    TekiMA = 15,

    /** 定型仕訳マスタ */
    SwkFxHead = 16,

    /** ウィンドウ表示順序 */
    WinDspInfo = 17,

    /** 管理科目基本ﾏｽﾀ */
    SKmkMA = 18,

    /** ユーザ基本情報 */
    UserInfo = 19,

    /** ｼｽﾃﾑ基本情報 */
    MasInfo = 20,

    /** 摘要ツリー */
    TekiTree = 21,

    /** セグメント2 */
    HojyoMA_Seg2 = 22,

    /** セグメント3 */
    HojyoMA_Seg3 = 23,

    /** セグメント4 */
    HojyoMA_Seg4 = 24,

    /** セグメント5 */
    HojyoMA_Seg5 = 25,

    /** 消費税基本情報 */
    TaxInfo = 26,

    /** 取引先詳細情報・期日情報 */
    CSInfo = 27,

    /** 部門別共通補助別科目情報・部門別科目別消費税コード */
    HojyoKmkInfo = 28,

    /** 特定科目情報・調整勘定情報 */
    KmkInfo = 29,

    /** 会社基本情報 */
    DTMAIN = 30,

    /** 勘定科目詳細情報 */
    KmkMB = 31,
}

/**
 * エラータイプ
 */
export enum ErrorType {

    /** エラー処理が必要になる */
    Error = 0,

    /** 再ログインが必要になる */
    ReLogin = 1,

    /** 画面の再表示が必要になる */
    ReLoad = 2,

    /** 一定時間の待機が必要になる */
    Wait = 3,

    /** システム管理者への問い合わせが必要になる */
    Inquiry = 9,
}

/**
 * 貸借部門組合
 */
export enum CombiDebitCreditBmn {

    /** なし */
    None = 0,

    /** 共通仕入 */
    CommonJournalize = 1,

    /** 非課税対応 */
    TaxExemption = 2,

    /** 組合エラー */
    Error = 9,
}

/**
 * 科目性格コード
 */
export enum CharCodeKmk {

    /** 非消費税科目 */
    TaxExemption = 0,

    /** 売上 */
    Sale = 1,

    /** 仕入 */
    Journalize = 2,

    /** 仮受消費税 */
    ProvisionalReceptionTax = 3,

    /** 仮払消費税 */
    ProvisionalPaymentTax = 4,

    /** 売上調整 */
    AdjustmentCounting = 5,

    /** 仕入調整 */
    AdjustmentJournalize = 6,

    /** 貸倒償却 (1～５) */
    ChargeOff = 7,
}

/**
 * サポート種類
 */
export enum SupportType {

    /** ベーシックサポート(旧) */
    Basic = 0,

    /** スタンダードサポート(旧) */
    Standard = 1,

    /** プレミアムサポート(旧) */
    Premium = 2,

    /** 電話サポート */
    CallSupport = 3,
}

/**
 * システム区分
 */
export enum SystemDiv {

    /** 共通 */
    Common = 0,

    /** 記帳くんCloud */
    Vkz = 1,

    /** 給与 */
    Payroll = 2,
}

/**
 * 商品ID
 */
export enum ProductType {

    /** 記帳くん Cloud 基本 */
    VkzBase = 1,

    /** 記帳くん Cloud アカウント追加 */
    AddAccount = 2,

    /** 記帳くん Cloud 給与基本 */
    VkzPayroll = 6,

    /** 結合テスト用プロダクト(Test Mappinfあり) */
    TestProduct = 7,

    /** スクレイピング(Test用) */
    Scraping = 10,

    /** その他 */
    Other = 99,

    /** 記帳くん Cloud スタンダードサポート */
    StdSupport = 101,

    /** 記帳くん Cloud プレミアムサポート */
    PrmSupport = 102,
}

/**
 * システムコード
 */
export enum SystemCodes {

    /** 会計 */
    Vkz = 101,

    /** 会計ﾚｼｰﾄ取込 */
    VkzReceiptCapture = 102,

    /** 会計工事 */
    VkzConstruction = 103,

    /** 給与 */
    Payroll = 201,

    /** マイナンバー */
    Mynumber = 202,

    /** かんたんクラウド申告 */
    ReportSystem = 104,
}

/**
 * ユーザ区分
 */
export enum UserDiv {

    /** システム連動用 */
    Linkage = 0,

    /** 事務所ユーザ */
    Office = 1,

    /** 顧問先ユーザ */
    Client = 2,

    /** 直販ユーザ */
    Portable = 3,
}

/**
 * 大分類コード
 */
export enum Classification {

    /** 売上と仕入返品 */
    SalesAndPurchaseReturns = 1,

    /** 銀行取引（収入） */
    IncomeBankTransaction = 2,

    /** 仕入・売上返品 */
    PurchaseAndSaleReturns = 3,

    /** 費用の支払 */
    PaymentCost = 4,

    /** 保険料の支払 */
    PaymentOfInsurancePremium = 5,

    /** 税金の支払 */
    TaxPayment = 6,

    /** 物品の購入 */
    PurchaseOfGoods = 7,

    /** 銀行取引（支出） */
    SpendingBankTransaction = 8,

    /** 固定資産の購入 */
    PurchaseOfFixedAssets = 9,

    /** 決済 */
    Settlement = 10,

    /** 銀行取引（振替） */
    TransferBankTransaction = 11,
}

/**
 * 連想入力初期モード
 */
export enum RensoInputMode {

    /** ローマ字 */
    Romaji = 0,

    /** 英数 */
    Eisu = 1,

    /** カナ */
    Kana = 2,
}

/**
 * 外部システム区分
 */
export enum ExternalSystemDiv {

    /** かんたん申告 */
    Report = 1,

    /** 弁護士ポータル */
    Itotal = 2,
}

/**
 * 月次確定区分
 */
export enum FixedMonthKbn {

    /** 全ての選択月が月次未確定 */
    None = 0,

    /** 選択月の中に月次確定済の月と月次未確定の月が混在 */
    Mixed = 1,

    /** 全ての選択月が月次確定済 */
    All = 2,
}

/**
 * 業種
 */
export enum IndustryType {

    /** 農林 */
    Type01 = 1,

    /** 水産 */
    Type02 = 2,

    /** 鉱業 */
    Type03 = 3,

    /** 建設・土木 */
    Type04 = 4,

    /** 化学・薬品 */
    Type05 = 5,

    /** 食品 */
    Type06 = 6,

    /** 石油・プラスチック・ゴム */
    Type07 = 7,

    /** 繊維・紙・木材・家具 */
    Type08 = 8,

    /** 鉄・非鉄金属・窯業 */
    Type09 = 9,

    /** 輸送用機械・関連部品 */
    Type10 = 10,

    /** 一般機械 */
    Type11 = 11,

    /** 電機・精密機械 （コンピュータ製造を除く） */
    Type12 = 12,

    /** コンピュータ製造 */
    Type13 = 13,

    /** その他の製造業 */
    Type14 = 14,

    /** 電気・ガス・水道 */
    Type15 = 15,

    /** 通信 */
    Type16 = 16,

    /** 放送・新聞・出版・印刷・映画 */
    Type17 = 17,

    /** 情報サービス */
    Type18 = 18,

    /** 運輸・郵便・倉庫 */
    Type19 = 19,

    /** 商社 */
    Type20 = 20,

    /** 卸業 */
    Type21 = 21,

    /** 小売り （百貨店を含む） */
    Type22 = 22,

    /** 銀行・保険・証券・信販 */
    Type23 = 23,

    /** 不動産 */
    Type24 = 24,

    /** 専門サービスコンサル、法律事務所など */
    Type25 = 25,

    /** 個人事業者 */
    Type26 = 26,

    /** 各種団体財団、社団、協会、NPOなど */
    Type27 = 27,

    /** その他サービス行 （情報サービスを除く） */
    Type28 = 28,

    /** 公共自治体 （県市町村） */
    Type29 = 29,

    /** 教育・研究機関 */
    Type30 = 30,

    /** その他の産業・業種 */
    Type31 = 31,
}

