import { MigrationHojyoGroupViewModel } from "./migrationHojyoGroupViewModel";

/**
 * データ移行 ViewModel クラス
 */
export class MigrationHojyoSettingViewModel {
    /** 移行元システム区分 */
    public SystemKbn!: number;

    /** 補助対応設定グループリスト */
    public HojyoGroupList!: Array<MigrationHojyoGroupViewModel>;

    /** 対応設定保存済フラグ */
    public MappingSaved!: boolean;

    /** 期首残高インポート済フラグ */
    public KisyuImported!: boolean;

    /** 期首残高有効フラグ */
    public KisyuEnable!: boolean;

    /** 取引先採用科目コードリスト */
    public AdoptClientKmkCodeList!: Array<number>;

}

