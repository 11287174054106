import React from 'react';
import store from 'store2';

import * as wj from 'wijmo/wijmo';
import * as wjGrid from 'wijmo/wijmo.grid';
import { FlexGrid } from 'wijmo/wijmo.react.grid';
import * as wjFilter from 'wijmo/wijmo.grid.filter';
import { FlexGridFilter } from 'wijmo/wijmo.react.grid.filter';
import * as wjInput from 'wijmo/wijmo.input';
import { SortDescription } from 'wijmo/wijmo';

import { CustomGridEditor } from './CustomGridEditor';
import { headerMerge } from './CommonGrid';
import * as wijmoGridUtils from '../../../../utils/wijmoGridUtils';
import * as labels from '../../../../constants/labels';
import { CmpTMonthEnum, CmpValueEnum } from '../../../../constants/enums';
import { CALCULATEMAX } from '../../../../constants/constant';
import { MasterKbn } from '../../../../VKZ/core/constants/constant';

const selectAllClassName = (binding: string) => 'select-all-' + binding;

// フィルタ情報のテンポラリー
const filterDefinitionStoreKey = 'filter-SimpleGri';

// ソート条件のテンポラリー
const sortCriteriaStoreKey = 'sortCriteria-SimpleGrid';
// ソート条件のテンポラリー
export type sortCriteriaStore = {
  property?: string;
  ascending?: boolean;
};

export type sortLabelMap = {
  key: any;
  value: string;
};
export type sortLabel = {
  binding: string;
  map: sortLabelMap[];
};
export const enumSortLabel = [
  { key: null, value: labels.COMMON_GRID_FILTER_NULL },
  { key: undefined, value: labels.COMMON_GRID_FILTER_NULL }
] as sortLabelMap[];

export type MonthlyBalanceSelectGridProp = {
  columns: any[];
  sources: any[];
  headerMerges?: headerMerge[];
  selectionMode?: wjGrid.SelectionMode;
  frozenColumns?: number;
  sortLabelMapList?: sortLabel[];
  selectAllBinding?: string[];
  imeEnabledBinding?: string[];
  tabIndex?: number;
  innerRef?: React.RefObject<any>;
  gridRef?: React.RefObject<any>;
  gridClassName?: string;
  onFilterChanged?: (filterJson: string) => void;
  noFlexClass?: boolean;
  auditTarget: number;
};

type MonthlyBalanceSelectGridState = {};

/**
 * セル内ボタンを持たないグリッド
 */
class MonthlyBalanceSelectGrid extends React.Component<MonthlyBalanceSelectGridProp, MonthlyBalanceSelectGridState> {
  grid?: wjGrid.FlexGrid;
  filter?: wjFilter.FlexGridFilter;
  SkiValueCustomGridEditor?: CustomGridEditor;
  SkiValueSumCustomGridEditor?: CustomGridEditor;
  isChangeFilterAndSort = false;
  workGrid?: wjGrid.FlexGrid;
  workFilter?: wjFilter.FlexGridFilter;

  constructor(props: MonthlyBalanceSelectGridProp) {
    super(props);

    this.initializedFilter = this.initializedFilter.bind(this);
    this.handleFilterApplied = this.handleFilterApplied.bind(this);
    this.initializedGrid = this.initializedGrid.bind(this);
    this.handleItemFormatter = this.handleItemFormatter.bind(this);
    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
    this.handleCellEditEnding = this.handleCellEditEnding.bind(this);
    this.handleCellEditEnded = this.handleCellEditEnded.bind(this);
    this.handleGridRefresh = this.handleGridRefresh.bind(this);
    this.setFilterAndSortFromStore = this.setFilterAndSortFromStore.bind(this);
  }

  private bkup_kmkcode = 0;
  private chgColorFlg = false;
  private hojyoGCodeNumFlg = 0;
  
  // フィルターの初期化
  public initializedFilter = (flexFilter: wjFilter.FlexGridFilter) => {
    this.filter = flexFilter;
  };

  // フィルターが変更された時の処理
  public handleFilterApplied = (f: wjFilter.FlexGridFilter) => {
    if (typeof this.props.onFilterChanged === 'function') {
      this.props.onFilterChanged(f.filterDefinition);
    }
  };

  // Gridの初期化
  public initializedGrid = (flexGrid: wjGrid.FlexGrid) => {
    this.grid = flexGrid;
    this.bkup_kmkcode = 0;
    this.chgColorFlg = false;
    this.hojyoGCodeNumFlg = 0;
      
    const { headerMerges, selectAllBinding } = this.props;

    if (headerMerges && headerMerges.length > 0) {
      // 2段ヘッダーの表示
      const extraRow = new wjGrid.Row();
      extraRow.allowMerging = true;

      const panel = flexGrid.columnHeaders;
      panel.rows.splice(0, 0, extraRow);

      const columns = flexGrid.columns;
      columns.forEach(col => {
        let headerLbl = col.header;

        headerMerges.forEach(item => {
          const binding = item.binding.filter(binding => binding === col.binding);
          if (binding.length > 0) {
            headerLbl = item.header;
          }
        });
        panel.setCellData(0, col.index, headerLbl);
      });
    }

    if (selectAllBinding && selectAllBinding.length > 0) {
      // 一括選択
      selectAllBinding.forEach(binding => {
        const column = flexGrid.columns.filter(col => col.binding === binding);
        if (column.length > 0) {
          flexGrid.hostElement.addEventListener('click', e => {
            if (
              typeof e.target !== 'undefined' &&
              wj.hasClass(e.target as Element, selectAllClassName(binding)) &&
              e.target instanceof HTMLInputElement
            ) {
              flexGrid.deferUpdate(() => {
                flexGrid.collectionView.items.forEach(item => {
                  if (column[0].inputType === 'SubDsp' && item.SubDspFlg === false) {
                    item[binding] = false;
                  } else {
                    item[binding] = (e.target as HTMLInputElement).checked;
                  }
                });
              });
            }
          });
        }
      });
    }

    this.SkiValueCustomGridEditor = new CustomGridEditor(flexGrid, 'SkiValue', wjInput.InputNumber, {
      format: 'f2',
      isRequired: false,
      min: 0,
      step: 1
    });

    this.SkiValueSumCustomGridEditor = new CustomGridEditor(flexGrid, 'SkiValueSum', wjInput.InputNumber, {
      format: 'd',
      isRequired: false,
      min: 0,
      step: 1
    });
  };

  // ItemFormatter
  public handleItemFormatter = (panel: wjGrid.GridPanel, row: number, col: number, cell: HTMLElement) => {
    if (panel.cellType === wjGrid.CellType.ColumnHeader) {
      // ヘッダ
      if (!this.props.noFlexClass) { cell.classList.add('d-flex');}
      cell.classList.add('justify-content-center');
      cell.classList.add('align-items-center');
      cell.classList.add('font-weight-normal');

      // 一括選択チェックボックスの表示
      const binding = panel.columns[col].binding;
      if (typeof this.props.selectAllBinding !== 'undefined') {
        if (this.props.selectAllBinding.indexOf(binding) > -1) {
          if ((typeof this.props.headerMerges !== 'undefined' && this.props.headerMerges.length == 0) || row === 1) {
            const label = panel.columns[col].header;
            const cb = '<input class="mx-1 ' + selectAllClassName(binding) + '" tabindex="-1" type="checkbox">';
            const currentSort = wijmoGridUtils.getGridHeaderSortIcon(panel.columns[col]);
            cell.innerHTML = cb + label + currentSort;
            this.updateSelectAllBox(panel.grid, panel.columns[col]);
          }
        }
      }
    } else if (panel.cellType === wjGrid.CellType.Cell) {
      const item = panel.rows[row].dataItem;
      const column: wjGrid.Column = panel.columns[col];

      if (column.inputType === 'SubDsp') {
        if (item.SubDspFlg === false) {
          cell.classList.add('_grayout');
          cell.innerHTML = '';
        }
      } else if (column.inputType === 'percent') {
        cell.classList.add('_percent');
      }

      //科目コードが前行と同じ場合、科目コード・科目名を非表示(文字色白)とする対応
      // 表示1行目は部門1行目ではなくても表示する対応
      if (row == panel._vru.row && col == 0) {  //表示1行目・科目コードのカラムの場合、強制的にoldクリア
        this.bkup_kmkcode = 0;
      }

      if (column.binding === 'KmkGCode') {
        if (item.KmkGCode === this.bkup_kmkcode && row != 0) {
          cell.classList.add('_duplicate-kmk');
          this.chgColorFlg = true;
        } else {
          cell.classList.add('_notduplicate-kmk');
          this.chgColorFlg = false;
        }
        this.bkup_kmkcode = item.KmkGCode;
      }
      if (column.binding === 'KmkName') {
        if (this.chgColorFlg) {
          cell.classList.add('_duplicate-kmk');
        } else {
          cell.classList.add('_notduplicate-kmk');
        }
      }
      if (column.binding === 'HojyoGCodeNum') {  //部門コード数値
        if (item.HojyoGCodeNum !== 99999999999) {  //HojyoGCodeNumには数値の場合数値が入り、フリーの場合9999999999が入る
          this.hojyoGCodeNumFlg = 1;
        }
        if (item.HojyoGCodeNum === 99999999999) {
          this.hojyoGCodeNumFlg = 2;
        }
      }

      //監査対象選択時のグリッド入れ替え
      //グリッドカラムリセット
      const colBmnCode: wjGrid.Column = panel.columns[2];  //補助コードグリッドカラム
      const colBmnCodeNum: wjGrid.Column = panel.columns[3];  //補助コード数値グリッドカラム
      const colBmnName: wjGrid.Column = panel.columns[4];   //補助名グリッドカラム
      const colRidKmkFlg: wjGrid.Column = panel.columns[5];   //科目(著増減)フラググリッドカラム
      const colRidHojyoFlg: wjGrid.Column = panel.columns[6]; //科目別補助(著増減)フラググリッドカラム
      const colNBKmkFlg: wjGrid.Column = panel.columns[12];    //科目(マイナス残高)フラググリッドカラム
      const colNBHojyoFlg: wjGrid.Column = panel.columns[13];  //科目別補助(マイナス残高)フラググリッドカラム

      if (this.props.auditTarget === MasterKbn.Bmn) {
          //部門対応版
        colBmnCode.visible = true;  //部門コード表示
        colBmnCodeNum.visible = true;  //部門コード非表示
        colBmnName.visible = true;  //部門名表示
        colRidKmkFlg.header = labels.AIKADT001000003_GRID_HEADER_ACCOUNTRECRUIT;  //採用(著増減)見出し名
        colRidHojyoFlg.visible = false;  //補助(著増減)非表示
        colNBKmkFlg.header = labels.AIKADT001000003_GRID_HEADER_ACCOUNTRECRUIT;  //採用(著増減)見出し名
        colNBHojyoFlg.visible = false;  //補助(マイナス残高)非表示

        //補助コードが属性数値の場合
        if (this.hojyoGCodeNumFlg == 1) {
          colBmnCode.width = 0.1;   //部門コード非表示
          colBmnCodeNum.width = 130; //部門コード数値表示
        } else if (this.hojyoGCodeNumFlg == 2) {  //フリーの場合
          colBmnCode.width = 130;     //部門コード表示
          colBmnCodeNum.width = 0.1; //部門コード数値非表示
        }
      } else {
        //科目版(既存)
        colBmnCode.width = 0.1;     //部門コード非表示
        colBmnCodeNum.width = 0.1;  //部門コード数値非表示
        colBmnName.visible = false;  //部門名非表示
        colRidKmkFlg.header = labels.AIKADT001000003_GRID_HEADER_ACCOUNT;  //勘定科目(著増減)見出し名
        colRidHojyoFlg.visible = true;  //補助(著増減)表示
        colNBKmkFlg.header = labels.AIKADT001000003_GRID_HEADER_ACCOUNT;  //勘定科目(マイナス残高)見出し名
        colNBHojyoFlg.visible = true;   //補助(マイナス残高)表示
      }
    }
  };

  public pasting(grid: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs) {
    // ペーストを無効
    e.cancel = true;
  }

  private updateSelectAllBox(flexGrid: wjGrid.FlexGrid, column: wjGrid.Column) {
    const { binding, inputType } = column;
    let cb = flexGrid.hostElement.querySelector('.' + selectAllClassName(binding));
    if (cb instanceof HTMLInputElement) {
      let baseItems = flexGrid.collectionView.items;
      if (inputType === 'SubDsp') {
        baseItems = baseItems.filter(item => item.SubDspFlg === true);
      }
      if (baseItems.length > 0) {
        const checkedItems = baseItems.filter(item => item[binding] === true);
        cb.checked = checkedItems.length === baseItems.length;
        cb.indeterminate = !cb.checked && checkedItems.length !== 0;
      } else {
        cb.disabled = true;
      }
    }
  }

  public handleCellEditEnding(grid: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs) {
    const binding = grid.columns[e.col].binding;
    if (grid.columns[e.col].inputType === 'SubDsp' && grid.collectionView.items[e.row].SubDspFlg === false) {
      e.cancel = true;
      return;
    }

    if (binding === 'SkiValue') {
      if (this.SkiValueCustomGridEditor && this.SkiValueCustomGridEditor.control.value != null) {
        const skiValue = Number(this.SkiValueCustomGridEditor.control.value);
        if (skiValue < 0 || skiValue > CALCULATEMAX) {
          e.cancel = true;
          return;
        }
      }
    } else if (binding === 'SkiValueSum') {
      // カスタムエディタを使用しているため入力値をカスタムエディタから取得
      if (this.SkiValueSumCustomGridEditor && this.SkiValueSumCustomGridEditor.control.value != null) {
        const skiValueSum = Number(this.SkiValueSumCustomGridEditor.control.value);
        if (skiValueSum < 0 || skiValueSum > 9999999999999) {
          e.cancel = true;
          return;
        }
      }
    }
  }

  public handleCellEditEnded(grid: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs) {
    const item = grid.collectionView.items[e.row];
    if (grid.columns[e.col].binding === 'CmpTMonthKbn') {
      if (
        item.CmpTMonthKbn == CmpTMonthEnum.BeginningBalance &&
        !(item.CmpValueKbn == null || item.CmpValueKbn == CmpValueEnum.Balance)
      ) {
        grid.beginUpdate();
        item.CmpValueKbn = undefined;
        grid.endUpdate();
      }
      return;
    }
  }

  public handleSelectionChanged(grid: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs) {
    if (
      this.props.imeEnabledBinding != null &&
      this.props.imeEnabledBinding.indexOf(grid.columns[e.col].binding) > -1
    ) {
      grid.imeEnabled = true;
    } else {
      grid.imeEnabled = false;
    }
  }

  public handleGridRefresh() {
    if (this.grid != null && this.filter != null) {
      // 符号フィルタのソートを「(なし)」が先頭に来るように並び替え
      const filterDefinition = JSON.parse(this.filter.filterDefinition);
      filterDefinition.filters = filterDefinition.filters.filter((item: any) => item.binding !== 'SkiSign');

      // 符号フィルタに表示する文言を取得
      if (this.workGrid == null || this.workFilter == null) {
        // 表示されないgridを作成し、フィルタの文言を取得する
        this.workGrid = new wjGrid.FlexGrid('#no-dsp-grid', {
          autoGenerateColumns: false,
          columns: this.props.columns,
          itemsSource: this.props.sources
        });
        this.workFilter = new wjFilter.FlexGridFilter(this.workGrid);
      }
      this.workGrid.itemsSource = this.props.sources;
      filterDefinition.filters.forEach((item: any) => {
        if (
          item.binding === 'RidKmkFlg' ||
          item.binding === 'RidHojyoFlg' ||
          item.binding === 'NBKmkFlg' ||
          item.binding === 'NBHojyoFlg'
        ) {
          if (item.showValues[labels.AIKADT001000003_GRID_SORT_TRUE] === true) {
            item.showValues = { true: true }
          } else if (item.showValues[labels.AIKADT001000003_GRID_SORT_FALSE] === true) {
            item.showValues = { false: true }
          }
        } else if (
          item.binding === 'CmpTMonthKbn' ||
          item.binding === 'CmpValueKbn'
        ) {
          if (item.showValues[labels.COMMON_GRID_FILTER_NULL] === true) {
            item.showValues['undefined'] = true;
            item.showValues['null'] = true;
          }
        }
      });
      this.workFilter.filterDefinition = JSON.stringify(filterDefinition);
      this.workFilter.apply();
      if (this.grid.getColumn('SkiSign') != null) {
        const skiSignFilter = this.filter.getColumnFilter('SkiSign');
        const skiSignMap = skiSignFilter.column.dataMap.collectionView.items
          .filter(
            item =>
              this.workGrid!.collectionView.items.filter(
                data => data.SkiSign == item.key || (data.SkiSign == null && item.key == -1)
              ).length > 0
          )
          .map(item => (item.key === -1 ? labels.COMMON_GRID_FILTER_NULL : item.value));
        skiSignFilter.valueFilter.sortValues = false;
        skiSignFilter.valueFilter.uniqueValues = skiSignMap;
      }
    }
  }

  private filterSetting() {
    if (this.filter != null) {
      if (this.props.sortLabelMapList != null) {
        this.props.sortLabelMapList.forEach(item => {
          this.filter!.getColumnFilter(item.binding).dataMap = new wjGrid.DataMap(item.map, 'key', 'value');
        });
        this.filter.apply();
      }
    }
  }

  // storeよりフィルターとソートを設定する
  private setFilterAndSortFromStore() {
    if (this.filter != null && this.grid != null) {
      if (this.isChangeFilterAndSort === true) {
        this.isChangeFilterAndSort = false;
        const filterDefinition = getFilterDefinition();
        if (filterDefinition) {
          removeFilterDefinition();

          // フィルターを設定
          this.filter.filterDefinition = filterDefinition;
          this.filter.apply();
        }

        const sortCriteriaStore = getSortCriteria();
        if (sortCriteriaStore) {
          removeSortCriteria();
          if (sortCriteriaStore.property != undefined && sortCriteriaStore.ascending != undefined) {
            var view = this.grid.collectionView;
            // ソートを設定
            view.deferUpdate(() => {
              view.sortDescriptions.clear();
              view.sortDescriptions.push(
                new SortDescription(sortCriteriaStore.property!, sortCriteriaStore.ascending!)
              );
            });
          }
        }

        // 選択セルを設定
        setTimeout(() => {
          this.grid!.selection = new wjGrid.CellRange(0, 0);
        });
      }
    }
  }

  public componentDidMount() {
    this.filterSetting();
  }

  public shouldComponentUpdate(nextProps: MonthlyBalanceSelectGridProp, nextState: MonthlyBalanceSelectGridProp) {
    if (this.props.sources !== nextProps.sources) {
      // sourcesが変更された場合にフィルター、ソート情報を設定する
      this.isChangeFilterAndSort = true;
    }
    return true;
  }

  public componentDidUpdate() {
    this.filterSetting();

    // フィルタとソートを設定
    this.setFilterAndSortFromStore();
  }

  public render() {
    const { columns, sources, gridClassName, tabIndex, innerRef, gridRef } = this.props;
    const selectionMode =
      typeof this.props.selectionMode === 'undefined' ? wjGrid.SelectionMode.Row : this.props.selectionMode;

    wijmoGridUtils.convertItemsSourceToDateType(columns, sources);
    const frozenColumns = this.props.frozenColumns == null ? 0 : this.props.frozenColumns;
    return (
      <span ref={innerRef}>
        <FlexGrid
          className={gridClassName}
          autoGenerateColumns={false}
          columns={columns}
          allowDragging={wjGrid.AllowDragging.None}
          allowMerging={wjGrid.AllowMerging.ColumnHeaders}
          allowResizing={wjGrid.AllowResizing.None}
          selectionMode={selectionMode}
          headersVisibility={wjGrid.HeadersVisibility.Column}
          alternatingRowStep={0}
          frozenColumns={frozenColumns}
          itemsSource={sources}
          initialized={this.initializedGrid}
          itemFormatter={this.handleItemFormatter}
          cellEditEnding={this.handleCellEditEnding}
          cellEditEnded={this.handleCellEditEnded}
          selectionChanged={this.handleSelectionChanged}
          refreshed={this.handleGridRefresh}
          pasting={this.pasting}
          tabIndex={tabIndex}
          keyActionEnter={wjGrid.KeyAction.Cycle}
          ref={gridRef}
        >
          <FlexGridFilter initialized={this.initializedFilter} filterApplied={this.handleFilterApplied} />
        </FlexGrid>
        <div id='no-dsp-grid' className='d-none' />
      </span>
    );
  }
}

/**
 * フィルタ情報をストアに設定します。
 * @param filterDefinition フィルタ情報
 */
export const setFilterDefinition = (filterDefinition: string) => {
  store.session.set(filterDefinitionStoreKey, filterDefinition);
};

/**
 * ストアからフィルタ情報を取得します。
 */
export const getFilterDefinition = () => {
  return store.session.has(filterDefinitionStoreKey)
    ? (store.session.get(filterDefinitionStoreKey) as string)
    : undefined;
};

/**
 * ストアからフィルタ情報を削除します。
 */
export const removeFilterDefinition = () => {
  if (store.session.has(filterDefinitionStoreKey)) {
    store.session.remove(filterDefinitionStoreKey);
  }
};

/**
 * ソート条件をストアに設定します。
 * @param sortCriteria ソート条件
 */
export const setSortCriteria = (sortCriteria: sortCriteriaStore) => {
  store.session.set(sortCriteriaStoreKey, sortCriteria);
};

/**
 * ストアからソート条件を取得します。
 */
export const getSortCriteria = () => {
  return store.session.has(sortCriteriaStoreKey)
    ? (store.session.get(sortCriteriaStoreKey) as sortCriteriaStore)
    : undefined;
};

/**
 * ストアからソート条件を削除します。
 */
export const removeSortCriteria = () => {
  if (store.session.has(sortCriteriaStoreKey)) {
    store.session.remove(sortCriteriaStoreKey);
  }
};

export default MonthlyBalanceSelectGrid;
