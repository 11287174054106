import React from 'react';

import classnames from 'classnames';
import { CardHeader, CardBody, Card, FormGroup, Label, Input } from 'reactstrap';

import { ConfStatusEnum } from '../../../constants/enums';
import * as labels from '../../../constants/labels';
import { AuditResultVO } from '../../../models/auditResultVO';
import AngleCircleFrameLine from '../../atoms/AngleCircleFrameLine/AngleCircleFrameLine';
import InputTextArea from '../../atoms/InputTextArea/InputTextArea';
import CommentSlash from '../../atoms/Icon/CommentSlash';
import CommentDots from '../../atoms/Icon/CommentDots';
import TableIcon from '../../atoms/Icon/Table';
import UserGraduate from '../../atoms/Icon/UserGraduate';
import IconButton from '../CMN/IconButton/IconButton';

type ResultItemProps = React.HTMLAttributes<HTMLDivElement> & {
  no: number;
  resultDetail?: AuditResultVO;
  confirmDisabled?: boolean;
  firstFocusRef?: React.RefObject<any>;
  lastFocusRef?: React.RefObject<any>;
  y?: number;
  isOpen?: boolean;
  commentHeight?: number;
  onModificationProposalClick?: (SeqNo: number, RowNo: number) => void;
  onJournalClick?: (SeqNo: number, RowNo: number, NMonth: number) => void;
  onConfirmChange?: (NMonth: number, SeqNo: number, RowNo: number, auditMessageConfirm: ConfStatusEnum) => void;
  onCommentBlur?: (NMonth: number, SeqNo: number, RowNo: number, auditMessageComment: string) => void;
  onTextAreaOpenChange?: (isOpen: boolean) => void;
  onCommentInput?: (auditMessageComment: string) => void;
  onCommentResize?: (height: number) => void;
  onBodyResize?: (height: number) => void;
  onCommentForcus?: () => void;
};

const confirmRadios = [
  { label: labels.AIKADT002000002_BUTTON_FUNCTION_CONFIRMATIONOK, value: ConfStatusEnum.Complete },
  { label: labels.AIKADT002000002_BUTTON_FUNCTION_CONFIRMATIONNG, value: ConfStatusEnum.Admitted },
  { label: labels.AIKADT002000002_BUTTON_FUNCTION_PENDING, value: ConfStatusEnum.Pending }
];
const ResultItem: React.FC<ResultItemProps> = props => {
  const {
    no,
    confirmDisabled,
    firstFocusRef,
    lastFocusRef,
    tabIndex,
    y,
    isOpen,
    commentHeight,
    onModificationProposalClick,
    onJournalClick,
    onConfirmChange,
    onCommentBlur,
    onTextAreaOpenChange,
    onCommentInput,
    onCommentResize,
    onBodyResize,
    onCommentForcus,
  } = props;
  const [isTextAreaOpen, setTextAreaOpen] = React.useState(isOpen == undefined ? false : isOpen);
  const [resultDetail, setResultDetail] = React.useState(props.resultDetail);
  const [commentIconFlg, setCommentIconFlg] = React.useState(
    resultDetail!.Comment == undefined || resultDetail!.Comment === ''
  );

  resultDetail!.ConfStatus = resultDetail!.ConfStatus != undefined ? resultDetail!.ConfStatus : ConfStatusEnum.Auditable;
  const cardHeaderBgColer = ['bg', String(ConfStatusEnum[resultDetail!.ConfStatus])].join('-');

  let dsplayJournalLineRef = firstFocusRef;

  const iconList = [];
  if (typeof onModificationProposalClick === 'function') {
    const handleOnModificationProposalClick = () => {
      if (resultDetail!.SeqNo != undefined && resultDetail!.RowNo != undefined) {
            onModificationProposalClick(resultDetail!.SeqNo, resultDetail!.RowNo);
      }
    };
    iconList.push(
      <IconButton
        key='UserGraduate'
        id={['ModificationProposal', resultDetail!.RowNo].join('-')}
        onClick={handleOnModificationProposalClick}
        icon={<UserGraduate className='icon-lg' />}
        className={'ml-1 text-white'}
        tabIndex={tabIndex}
        innerRef={firstFocusRef}
      >
        <span className='small'>修正提案</span>
      </IconButton>
    );
    dsplayJournalLineRef = undefined;
  }
  const handleOnJournalClick = () => {
    if (resultDetail!.SeqNo != undefined && resultDetail!.RowNo != undefined && resultDetail!.NMonth != undefined) {
       onJournalClick!(resultDetail!.SeqNo, resultDetail!.RowNo, resultDetail!.NMonth);
    }
  };
  iconList.push(
    <IconButton
      key='TableIcon'
      id={['Journal', resultDetail!.RowNo].join('-')}
      data-testid={['Journal', resultDetail!.RowNo].join('-')}
      onClick={handleOnJournalClick}
      icon={<TableIcon className='icon-lg' />}
      className={'ml-1 text-white'}
      tabIndex={tabIndex}
      innerRef={dsplayJournalLineRef}
    >
      <span className='small'>仕訳表示</span>
    </IconButton>
  );

  const handleOnConfirmChange = (confStatus: ConfStatusEnum) => {
    resultDetail!.ConfStatus = confStatus;
    setResultDetail({ ...resultDetail });
      if (resultDetail!.SeqNo != undefined && resultDetail!.RowNo != undefined && resultDetail!.NMonth != undefined) {
       onConfirmChange!(resultDetail!.NMonth, resultDetail!.SeqNo, resultDetail!.RowNo, confStatus);
    }
  };

  const confirmRadiosList: React.ReactElement[] = [];
  confirmRadios.forEach(confirm => {
    confirmRadiosList.push(
      <FormGroup check className='mr-3' key={['confirm', confirm.value].join('_')}>
        <Label check className='text-nowrap'>
          <Input
            type='radio'
            disabled={confirmDisabled}
            name={['confirm', resultDetail!.NMonth, resultDetail!.RowNo].join('_')}
            checked={confirmRadioChecked(confirm.value, resultDetail!.ConfStatus!)}
            onChange={() => handleOnConfirmChange(confirm.value)}
            tabIndex={tabIndex}
          />
          <span className={['text', String(ConfStatusEnum[confirm.value])].join('-')}>{confirm.label}</span>
        </Label>
      </FormGroup>
    );
  });

  const getEachFromTextAreaOpen = (isTextareaOpen: boolean) => {
    let preComentLadel = '';
    let textAreaOpenToggleRef;
    let auditMessageCommentRef;
    if (isTextareaOpen) {
      preComentLadel = labels.AIKADT002000002_BUTTON_FUNCTION_COMMENTDISPLAY;
      textAreaOpenToggleRef = undefined;
      auditMessageCommentRef = lastFocusRef;
    } else {
      preComentLadel = labels.AIKADT002000002_BUTTON_FUNCTION_COMMENTINVISIBLE;
      textAreaOpenToggleRef = lastFocusRef;
      auditMessageCommentRef = undefined;
    }
    return {
      preComentLadel,
      textAreaOpenToggleRef,
      auditMessageCommentRef
    };
  };

  const handleOnCommentCrilck = () => {
    if (onTextAreaOpenChange != undefined) {
      onTextAreaOpenChange(!isTextAreaOpen);
    }
    return setTextAreaOpen(!isTextAreaOpen);
  }

  const { preComentLadel, textAreaOpenToggleRef, auditMessageCommentRef } = getEachFromTextAreaOpen(isTextAreaOpen);

  const comentIcon = commentIconFlg ? (
    <span className='nocomment'>
      <CommentSlash />
    </span>
  ) : (
    <CommentDots />
  );

  const handleOnCommentInputCapture = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCommentIconFlg(e.target.value === '');
  };

  const handleOnCommentBlur = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (resultDetail!.Comment_bef !== e.target.value) {
      setResultDetail({ ...resultDetail!, Comment: e.target.value });
      if (resultDetail!.SeqNo != undefined && resultDetail!.RowNo != undefined && resultDetail!.NMonth != undefined) {
        onCommentBlur!(resultDetail!.NMonth, resultDetail!.SeqNo, resultDetail!.RowNo, e.target.value);
      }
    }
  };

  const handleOnInput = ((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onCommentInput) {
      onCommentInput(e.target.value);
    }
  });

  const handleOnForcus = ((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onCommentForcus) {
        onCommentForcus();
    }
　});

  const handleOnCommentResize = ((el: HTMLDivElement) => {
    if (onCommentResize) {
      onCommentResize(el.clientHeight);
    }
  });
 
  const headerHeight = 82; // メッセージ部、コメントテキストボックスを除いた部分の合計高さ

  const handleOnBodyResize = ((el: HTMLDivElement | null) => {
    if (el && onBodyResize) {
      onBodyResize(el.clientHeight + headerHeight + (isOpen && commentHeight ? commentHeight : 0));
    }
  });

  const messageDivRef = React.useRef<HTMLDivElement>(null);

  // 描画完了、開閉状態更新、コメント高さ変更時に上位コンポーネントに高さ変更を通知
  React.useEffect(() => handleOnBodyResize(messageDivRef.current), [ isOpen, resultDetail!.AuditMess, commentHeight ]);


  // divタグに不要なpropsを除去
  const divProps = { ...props };  
  delete divProps['resultDetail'];
  delete divProps['confirmDisabled'];
  delete divProps['onModificationProposalClick'];
  delete divProps['onJournalClick'];
  delete divProps['onConfirmChange'];
  delete divProps['onCommentBlur'];
  delete divProps['onTextAreaOpenChange'];
  delete divProps['onCommentInput'];
  delete divProps['onCommentResize'];
  delete divProps['onBodyResize'];
  delete divProps['onCommentForcus'];
  delete divProps['tabIndex'];
  delete divProps['firstFocusRef'];
  delete divProps['lastFocusRef'];
  delete divProps['y'];
  delete divProps['isOpen'];
  delete divProps['commentHeight'];

  return (
    <div {...divProps} className={classnames(props.className, 'audit-retult-item')} style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        transform: `translateY(${y}px)`
      }}>
      <Card className='retult-item-card'>
        <CardHeader className={classnames(cardHeaderBgColer, 'd-flex justify-content-between text-white px-3 py-1 ')}>
          <div className='retult-item-message-no d-flex justify-content-center align-items-center'>({no})</div>
          <div className='retult-item-icon'>{iconList}</div>
        </CardHeader>
        <CardBody className='px-3 py-2'>
          <div className='d-flex justify-content-between'>
            <div className='retult-item-message white-space-pre-wrap' ref={messageDivRef}>{resultDetail!.AuditMess}</div>
            <div className='retult-item-confirm'>
              <AngleCircleFrameLine className='border-dashed pl-3 pt-1 pb-1 d-flex'>
                {confirmRadiosList}
              </AngleCircleFrameLine>
            </div>
          </div>
          <div className='message-comment'>
            <div className='d-flex'>
              <div className='message-comment-label' style={{ width: '100px' }}>
                <a
                  className='text-decoration-none cursor-pointer text-black'
                  onClick={handleOnCommentCrilck}
                  tabIndex={tabIndex}
                  id={['textAreaOpenToggle', resultDetail!.RowNo].join('-')}
                  ref={textAreaOpenToggleRef}
                  href='javascript:void(0);'
                >
                  {preComentLadel}
                </a>
                <span className='ml-1'>{comentIcon}</span>
              </div>
              <div className='message-comment-hr w-100'>
                <hr className='mt-2 mb-1 bg-secondary' />
              </div>
            </div>
            <div hidden={!isTextAreaOpen}>
              <InputTextArea
                name={'AuditMessageComment'}
                enable={{
                  top: false,
                  right: false,
                  bottom: true,
                  left: false,
                  topRight: false,
                  bottomRight: false,
                  bottomLeft: false,
                  topLeft: false,
                }}
                id={['AuditMessageComment', resultDetail!.RowNo].join('-')}
                data-testid={['AuditMessageComment', resultDetail!.RowNo].join('-')}
                className='message-comment-textarea'
                placeholder='コメントを入力してください'
                rows={3}
                maxLength={200}
                onFocus={handleOnForcus}
                onInputCapture={handleOnCommentInputCapture}
                onBlur={handleOnCommentBlur}
                onInput={handleOnInput}
                defaultValue={resultDetail!.Comment}
                tabIndex={tabIndex}
                innerRef={auditMessageCommentRef}
                defaultSize={{height: commentHeight}}
                onResize={handleOnCommentResize}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
const confirmRadioChecked = (value: ConfStatusEnum, target: ConfStatusEnum) => value === target;
export default ResultItem;
