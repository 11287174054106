import { UserControl } from "../interface";
import { InputBase } from "./inputControls/inputBase";
import * as wijmo from 'wijmo/wijmo';
import * as wjInput from 'wijmo/wijmo.input';
import $ from 'jquery';


export enum TextDisplayPosition {
    none = 1,
    right,
    left,
    center
}

export enum CtrlType {
    typeInputString,
    typeInputNumber,
    typeComboBox
}


export class userControls {

    /**
     * 区分によってい指定されたJQueryオブジェクトにスタイルを当てる
     * @param $ctrl
     * @param dispPosition
     */
    public static setTextDisplaytextPosition($ctrl: JQuery, dispPosition: TextDisplayPosition) {

        $ctrl.removeAttr(InputBase.inputType);

        switch (dispPosition) {
            case TextDisplayPosition.none:
                $ctrl.removeClass("al_right");
                $ctrl.removeClass("al_left");
                $ctrl.removeClass("al_center");
                break;
            case TextDisplayPosition.right:
                $ctrl.addClass("al_right");
                $ctrl.removeClass("al_left");
                $ctrl.removeClass("al_center");

                // 右寄せは数値とみなす(暫定)
                $ctrl.attr(
                    InputBase.inputType,
                    InputBase.typeNumInput
                );

                break;
            case TextDisplayPosition.left:
                $ctrl.addClass("al_left");
                $ctrl.removeClass("al_right");
                $ctrl.removeClass("al_center");

                // 左寄せは文字列とみなす(暫定)
                $ctrl.attr(
                    InputBase.inputType,
                    InputBase.typeStrInput
                );

                break;
            case TextDisplayPosition.center:
                $ctrl.addClass("al_center");
                $ctrl.removeClass("al_right");
                $ctrl.removeClass("al_left");
                break;
        }
    }
}


export class AbstractUserControl implements UserControl {
    protected baseTagID: string;
    protected mEnabled: boolean;
    protected mVisible: boolean;
    /**
     * コンストラクタ
     * @param id
     */
    constructor(id: string) {
        this.baseTagID = id;
        // .mjs-control
        var $baseTag: JQuery = $("#" + this.baseTagID);
        $baseTag.addClass("mjs-control");
        this.mEnabled = true;
        this.mVisible = true;
    }
    /**
     * コントロールのイニシャライズを行う
     */
    public initialize($scope: any, $compile: any): UserControl { return this; }
    /**
     *  コントロールのVisible/Hiddenの制御を行う。
     * @param flg
     */
    public visible(flg: boolean): void {
        // var $baseTag: JQuery = $("#" + this.baseTagID);
        this.mVisible = flg;
    }

    /**
     *  コントロールのVisible状態を取得する。
     * @param flg
     */
    public getVisible(): boolean {
        return this.mVisible;
    }

    /**
     * コントロールのEnable/Disable制御を行う。
     * @param flg
     */
    public enable(flg: boolean): void {
        var $baseTag: JQuery = $("#" + this.baseTagID);
        if (flg) {
            $baseTag.removeAttr("disabled");
        } else {
            $baseTag.attr("disabled", "disabled");
        }
        this.mEnabled = flg;
    }

    /**
     * コントロールのEnabled状態を取得する。
     */
    public getEnabled(): boolean {
        return this.mEnabled;
    }

    /**
     * 入力に使用するコントロールをJQueryのArray型で返す。
     */
    public getCtrlList(): Array<JQuery> { return null!; }
}



/**
 *  [ラベル][WijmoControl] のセットで作成される共通のコントロールを生成するクラス。
 */
export class CreateSimpleControlSet {
    private labelName: string;
    private ctrlType: CtrlType;

    private $labelCtrl!: JQuery;
    private $inputCtrl!: wijmo.Control;
    private w: number;

    // private width: number;
    public static get height(): number { return 26; }
    public static get margin(): number { return 5; }

    /**
     * 作成したLabelControlを取得する
     * 作成前に使用すると[null]が帰るので注意
     */
    public get $labelControl(): JQuery {
        return this.$labelCtrl;
    };
    /**
     * 作成したWjmo.inputを取得する
     * 作成前に使用すると[null]が帰るので注意
     */
    public get $inputControl(): wijmo.Control {
        return this.$inputCtrl;
    };

    /**
     * コンストラクタ
     * @param labelName : ラベル名を指定する
     * @param ctrlType : コントロールのタイプを指定する
     */
    constructor(labelName: string, ctrlType: CtrlType, width: number) {
        this.labelName = labelName;
        this.ctrlType = ctrlType;
        this.w = width;
    }

    /**
     * [ラベル][WijmoControl]のコントロールを配列で返す
     * @param $baseTag
     */
    public createControl($baseTag: JQuery): void {
        if (!$baseTag) { return; }

        var procDic: any = {
            0: this.creteInputStringControl,
            1: this.createInputNumberControl,
            2: this.creteComboControl
        };
        var $label = $("<span>" + this.labelName + "</span>");
        var $input = $("<div>").css("width", this.w).css("height", CreateSimpleControlSet.height).css("margin-left", CreateSimpleControlSet.margin);

        this.$labelCtrl = $label;
        this.$inputCtrl = procDic[this.ctrlType]($input);

        $baseTag.append($label);
        $baseTag.append($input);
    }

    /**
     * 【数値用】wijmo.InputNumberコントロールを作成する
     */
    private createInputNumberControl($input: JQuery): wjInput.InputNumber {

        let ctrl: wjInput.InputNumber = new wjInput.InputNumber(
            $input, {
                format: "D",
                min: 0,
                isRequired: false,
                value: null,
            });

        // キャレット移動をする/しないの設定
        $(ctrl.inputElement).attr(
            InputBase.inputType,
            InputBase.typeNumInput
        );

        return ctrl;
    }
    /**
     * 【文字列用】wijmo.InputComboコントロールを作成する
     *  ※ itemsourceを指定しなければ文字列コントロールとして使用可能。
     */
    private creteInputStringControl($input: JQuery): wjInput.ComboBox {

        let ctrl: wjInput.ComboBox = new wjInput.ComboBox($input, {
            isDroppedDown: false,
            isEditable: true
        });

        // キャレット移動をする/しないの設定
        $(ctrl.inputElement).attr(
            InputBase.inputType,
            InputBase.typeStrInput
        );

        return ctrl;
    }

    /**
     * 【文字列用】wijmo.InputComboコントロールを作成する
     *  作成後にitemsource、または選択用のItemを指定する必要あり
     */
    private creteComboControl($input: JQuery): wjInput.ComboBox {

        let ctrl: wjInput.ComboBox = new wjInput.ComboBox($input, {});

        // キャレット移動をする/しないの設定
        $(ctrl.inputElement).attr(
            InputBase.inputType,
            InputBase.typeStrInput
        );

        return ctrl;
    }
}
