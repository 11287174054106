import {DataTypeEnum} from "../constants/enums";
import {TargetJournalEnum} from "../constants/enums";
import {TargetTMonthEnum} from "../constants/enums";
import {RuleJokenVO} from "./ruleJokenVO";
import {TMonthVO} from "./tMonthVO";
/**
 * 仕訳明細ルール設定ViewModel ViewModel クラス
 */

export class JournalLineRuleSettingViewModel {
    /** 内部コード（ルール） */
    public NCode?:number;

    /** 種別区分 */
    public DataTypeKbn?:DataTypeEnum;

    /** 種別区分名称 */
    public DataTypeName?:string;

    /** ルール名称 */
    public RuleName?:string;

    /** 対象仕訳区分 */
    public TargetKbn?:TargetJournalEnum;

    /** 実行対象月区分 */
    public TargetTMonthKbn?:TargetTMonthEnum;

    /** 内部月(対象月) */
    public NMonth?:Array<number>;

    /** 監査メッセージ */
    public AuditMess?:string;

    /** コピー元内部コード（ルール） */
    public OrgNCode?:number;

    /** コピー元種別区分 */
    public OrgTypeKbn?:DataTypeEnum;

    /** 有効フラグ */
    public YukoFlg?:boolean;

    /** 編集可能フラグ  */
    public EditableFlg?:boolean;

    /** グループNo */
    public GroupNos?:Array<Array<string>>;

    /** ルール条件VO */
    public RuleJokens?:Array<RuleJokenVO>;

    /** 月選択VO */
    public MonthSelects?:Array<TMonthVO>;

}
