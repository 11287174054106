import { UserInfoYearKbn } from "../../core/constants/constant";
import { MiddleKbn } from "../../core/constants/enums";
import { FinInfoViewModel } from "../../models/common/finInfoViewModel";
import { MonthInfoViewModel } from "../../models/common/monthInfoViewModel";
import { DateFormatType, InterimClosingType } from "../defines";
import { MonthManager } from "../usefuls/monthManager";
import $ from 'jquery';

export class finInfoUtil {
    private static finInfo: FinInfoViewModel;
    public static initialize(finInfoData: FinInfoViewModel) {
        finInfoUtil.finInfo = finInfoData;
    }

    /**
     * 元となるFinInfoデータを取得する
     */
    public static getFinInfo(): FinInfoViewModel {
        return finInfoUtil.finInfo;
    }

    /** 会計年度 */
    public static getClientYear(): number {
        return finInfoUtil.finInfo.ClientYear;
    }

    /** 決算開始年月日 */
    public static getKStDate(): Date {
        return finInfoUtil.finInfo.KStDate;
    }

    /** 決算終了年月日 */
    public static getKEdDate(): Date {
        return finInfoUtil.finInfo.KEdDate;
    }

    /** 仕訳入力開始月 */
    public static getStrMonth(): number {
        return finInfoUtil.finInfo.StrMonth;
    }

    /** 月中開始日 */
    public static getMStDay(): number {
        return finInfoUtil.finInfo.MStDay;
    }

    /** 和暦／西暦区分 */
    public static getYearKbn(): number {
        return finInfoUtil.finInfo.YearKbn;
    }

    /** 中間決算区分 */
    public static getMiddleKbn(): MiddleKbn {
        return finInfoUtil.finInfo.MiddleKbn;
    }

    /** 中間決算月の集計方法 */
    public static getMCalcKbn(): number {
        return finInfoUtil.finInfo.MCalcKbn;
    }

    /** 月次確定内部月 */
    public static getFixMonths(): Array<number> {
        return finInfoUtil.finInfo.FixMonths;
    }

    /** 事務所ユーザフラグを取得 */
    public static getIsOfficeAdmin(): boolean {
        return finInfoUtil.finInfo.IsOfficeAdmin;
    }

    /** 月度管理情報のリストを取得 */
    public static getMonthInfoList(): Array<MonthInfoViewModel> {
        return finInfoUtil.finInfo.MonthInfoList;
    }


    /**
     * 指定した月が月次確定月かどうかを判定する
     * @param nMonthList
     */
    public static isFixedMonth(nMonthList: number): boolean {
        let fixMonths: number[] = finInfoUtil.getFixMonths();
        let ret: boolean = undefined!;
        $.each(fixMonths, (index: number, value: number) => {
            if (nMonthList === value) {
                ret = true;
                return false;
            }
            return true;
        });

        return ret;
    }

    /**
     * ユーザコントロールに使用する日付フォーマット区分(YMD形式)を返す。
     */
    public static convertSeirekiWarekiYMDKbn(): DateFormatType {
        //return DateFormatType.WarekiYMD;
        if (finInfoUtil.finInfo.YearKbn == UserInfoYearKbn.JPCalendar) {
            return DateFormatType.WarekiYMD;
        } if (finInfoUtil.finInfo.YearKbn == UserInfoYearKbn.ADCalendar) {
            return DateFormatType.SeirekiYMD;
        }
        return DateFormatType.SeirekiYMD;
    }
    /**
     * ユーザコントロールに使用する日付フォーマット区分(YM形式)を返す。
     */
    public static convertSeirekiWarekiYMKbn(): DateFormatType {
        if (finInfoUtil.finInfo.YearKbn == UserInfoYearKbn.JPCalendar) {
            return DateFormatType.WarekiYM;
        } if (finInfoUtil.finInfo.YearKbn == UserInfoYearKbn.ADCalendar) {
            return DateFormatType.SeirekiYM;
        }
        return DateFormatType.SeirekiYM;
    }

    /**
     * ユーザコントロールに使用する決算タイプ区分を返す。
     */
    public static convertInterimClosingType(): InterimClosingType {

        switch (finInfoUtil.finInfo.MiddleKbn) {
            case MiddleKbn.None:
                return InterimClosingType.Year;
            case MiddleKbn.HalfPeriod:
                return InterimClosingType.Interim;
            case MiddleKbn.QuarterPeriod:
                return InterimClosingType.Quarter;

        }
        return InterimClosingType.None;
    }

    /**
     * FinInfoに設定されている内容から、MonthManagerのインスタンスを作成して返す。
     * 一回作成したらそれを保持し、使いまわす
     */
    private static monthManager: MonthManager;
    public static createMonthManagerInstance(): MonthManager {
        if (finInfoUtil.monthManager) {
            return finInfoUtil.monthManager;
        }

        finInfoUtil.monthManager = new MonthManager(finInfoUtil.getKEdDate(), finInfoUtil.getMStDay(), finInfoUtil.convertInterimClosingType(), finInfoUtil.getMonthInfoList());
        return finInfoUtil.monthManager;
    }
}