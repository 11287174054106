import React from 'react';
import { Button } from 'reactstrap';
import axios from 'axios';

import Popup from '../../../../components/molecules/CMN/Popup/Popup';
import ResultItem from '../../../../components/molecules/A040/ResultItem';
import JournalLine from '../AIKADT002000004/JournalLine';
import * as labels from '../../../../constants/labels';
import { AuditTypeEnum, ConfStatusEnum, ActivateScreenEnum } from '../../../../constants/enums';
import { AuditResultViewModel } from '../../../../models/auditResultViewModel';
import { setAlertMessage, AlertKbnEnum } from '../../../../stores/AlertMessage';
import { AuditResultVO } from '../../../../models/auditResultVO';
import VirtualList from '../../../../components/atoms/VirtualList/VirtualList';
import { setIsLoading } from '../../../../stores/NowLoading';

//基本URL***************************************************************
//監査結果管理[著増減]
export const BaseRemarkIncDecUrl = '/api/v1/ResultRemarkIncDec';
//監査結果管理[マイナス残高]
export const BaseNegativeBalanceUrl = '/api/v1/ResultNegativeBalance';
//監査結果管理[仕訳明細]
export const BaseJournalLineUrl = '/api/v1/ResultJournalLine';
//監査結果管理[仕訳重複]
export const BaseJournalDoubleUrl = '/api/v1/ResultJournalDouble';
//監査結果管理[消費税区分]
export const BaseTaxKubunUrl = '/api/v1/ResultTaxKubun';
//******************************************************************************

//初期表示データ用***************************************************************
export const InitialRemarkIncDecUrl = [BaseRemarkIncDecUrl, 'Initial'].join('/');
export const InitialNegativeBalanceUrl = [BaseNegativeBalanceUrl, 'Initial'].join('/');
export const InitialJournalLineUrl = [BaseJournalLineUrl, 'Initial'].join('/');
export const InitialJournalDoubleUrl = [BaseJournalDoubleUrl, 'Initial'].join('/');
export const InitialTaxKubunUrl = [BaseTaxKubunUrl, 'Initial'].join('/');
//******************************************************************************

//確認ステータス更新用***************************************************************
export const ConfirmationRemarkIncDecUrl = [BaseRemarkIncDecUrl, 'Confirmation'].join('/');
export const ConfirmationNegativeBalanceUrl = [BaseNegativeBalanceUrl, 'Confirmation'].join('/');
export const ConfirmationJournalLineUrl = [BaseJournalLineUrl, 'Confirmation'].join('/');
export const ConfirmationJournalDoubleUrl = [BaseJournalDoubleUrl, 'Confirmation'].join('/');
export const ConfirmationTaxKubunUrl = [BaseTaxKubunUrl, 'Confirmation'].join('/');
//*****************************************************************************

//コメント更新用***************************************************************
export const CommentRemarkIncDecUrl = [BaseRemarkIncDecUrl, 'Comment'].join('/');
export const CommentNegativeBalanceUrl = [BaseNegativeBalanceUrl, 'Comment'].join('/');
export const CommentJournalLineUrl = [BaseJournalLineUrl, 'Comment'].join('/');
export const CommentJournalDoubleUrl = [BaseJournalDoubleUrl, 'Comment'].join('/');
export const CommentTaxKubunUrl = [BaseTaxKubunUrl, 'Comment'].join('/');
//*****************************************************************************

type ConfirmDisabledListItem = {
  RowNo: number;
  disabled: boolean;
};

type ResultProps = {
  activated: boolean;
  onClose: (isRefresh: boolean) => void;
  // 監査種類
  AuditType: AuditTypeEnum;
  // 開始月
  stNMonth: number;
  // 終了月
  edNMonth: number;
  // 処理連番
  SeqNo: number[];
  // 科目コード
  KmkCode: number;
  // 遷移元画面
  ActivateScreenType?: ActivateScreenEnum
};
const initializationViewModel: AuditResultViewModel = {
  TMonth: '',
  KmkName: '',
  Results: [],
  Count: 0,
};

// tabIndexの初期値
const baseTabIndex = 200;

// 1項目の高さ(コメント部非表示時)
const itemDefaultHeight = 123;
// コメント部のデフォルト高さ
const commentDefaultHeight = 69;
// 1項目の間隔
const itemGapHeight = 4;

const Result: React.FC<ResultProps> = props => {
  const { activated, onClose, AuditType, stNMonth, edNMonth, SeqNo, KmkCode } = props;

  const [viewModel, setViewModel] = React.useState<AuditResultViewModel>(initializationViewModel);
  const [confirmDisabledList, setConfirmDisabledList] = React.useState<ConfirmDisabledListItem[]>([]);
  const [parentRefresh, setParentRefresh] = React.useState(false);

  const resultFirstRef = React.useRef<any>();
  const resultLastRef = React.useRef<any>();
  const popupRef = React.useRef<any>();
  const closeIconRef = React.useRef<any>();
  const closeRef = React.useRef<any>();

  //監査種類名取得
  const GetAuditName = (auditType: AuditTypeEnum) => {
    switch (auditType) {
      case AuditTypeEnum.RemarkIncDecAudit:
        return labels.AIKADT002000002_FUNCTION_HEADER_REMARKINCDECAUDIT;

      case AuditTypeEnum.NegativeBalanceAudit:
        return labels.AIKADT002000002_FUNCTION_HEADER_NEGATIVEBALANCEAUDIT;

      case AuditTypeEnum.JournalLineAudit:
        return labels.AIKADT002000002_FUNCTION_HEADER_JOURNALLINEAUDIT;

      case AuditTypeEnum.JournalDoubleAudit:
        return labels.AIKADT002000002_FUNCTION_HEADER_JOURNALDOUBLEAUDIT;

      case AuditTypeEnum.TaxKubunAudit:
        return labels.AIKADT002000002_FUNCTION_HEADER_TAXKUBUNAUDIT;

      default:
        return '';
    }
  };

  //確認ステータスの活性非活性の変更
  const chengeConfirmDisabled = (rowNo: number, disabled: boolean) => {
    const confirmDisabled = confirmDisabledList.filter(item => item.RowNo === rowNo);
    if (confirmDisabled.length > 0) {
      confirmDisabled[0].disabled = disabled;
    }
    setConfirmDisabledList(confirmDisabledList.slice());
  };

  //ステータス更新URL決定
  const GetConfUrl = (auditType: AuditTypeEnum) => {
    switch (auditType) {
      case AuditTypeEnum.RemarkIncDecAudit:
        return ConfirmationRemarkIncDecUrl;

      case AuditTypeEnum.NegativeBalanceAudit:
        return ConfirmationNegativeBalanceUrl;

      case AuditTypeEnum.JournalLineAudit:
        return ConfirmationJournalLineUrl;

      case AuditTypeEnum.JournalDoubleAudit:
        return ConfirmationJournalDoubleUrl;

      case AuditTypeEnum.TaxKubunAudit:
        return ConfirmationTaxKubunUrl;

      default:
        return '';
    }
  };

  //ステータス更新WebApi
  const updateStatus = (
    url: string,
    nMonth: number,
    seqNo: number,
    rowNo: number,
    auditMessageConfirm: ConfStatusEnum
  ) => {
    const encodedUrl = [url, encodeURI(nMonth.toString())].join('/');
    chengeConfirmDisabled(rowNo, true);
    axios
      .post(encodedUrl, {
        seqNo: seqNo,
        rowNo: rowNo,
        confirmationStatus: auditMessageConfirm
      })
      .then(response => {
        setParentRefresh(true);
      })
      .catch(error => {
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
      })
      .finally(() => {
        chengeConfirmDisabled(rowNo, false);

        // ラジオボタンのフォーカスを戻す
        const confirmRadioList = document.getElementsByName(['confirm', rowNo].join('_'));
        if (confirmRadioList) {
          for (let i = 0; i < confirmRadioList.length; i++) {
            const confirmRadio = confirmRadioList[i] as HTMLInputElement;
            if (confirmRadio.checked === true) {
              confirmRadio.focus();
              break;
            }
          }
        }
      });
  };

  //コメント更新URL決定
  const GetCommentUrl = (auditType: AuditTypeEnum) => {
    switch (auditType) {
      case AuditTypeEnum.RemarkIncDecAudit:
        return CommentRemarkIncDecUrl;

      case AuditTypeEnum.NegativeBalanceAudit:
        return CommentNegativeBalanceUrl;

      case AuditTypeEnum.JournalLineAudit:
        return CommentJournalLineUrl;

      case AuditTypeEnum.JournalDoubleAudit:
        return CommentJournalDoubleUrl;

      case AuditTypeEnum.TaxKubunAudit:
        return CommentTaxKubunUrl;

      default:
        return '';
    }
  };

  //コメント更新WebApi
  const updateComment = (url: string, nMonth: number, seqNo: number, rowNo: number, auditMessageComment: string) => {
    const encodedUrl = [url, encodeURI(nMonth.toString())].join('/');
    axios
      .post(encodedUrl, {
        seqNo: seqNo,
        rowNo: rowNo,
        comment: auditMessageComment
      })
      .catch(error => {
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
      });
  };

  //取得用URL決定
  const GetIntialUrl = (auditType: AuditTypeEnum) => {
    switch (auditType) {
      case AuditTypeEnum.RemarkIncDecAudit:
        return InitialRemarkIncDecUrl;

      case AuditTypeEnum.NegativeBalanceAudit:
        return InitialNegativeBalanceUrl;

      case AuditTypeEnum.JournalLineAudit:
        return InitialJournalLineUrl;

      case AuditTypeEnum.JournalDoubleAudit:
        return InitialJournalDoubleUrl;

      case AuditTypeEnum.TaxKubunAudit:
        return InitialTaxKubunUrl;

      default:
        return '';
    }
  };

  // 開閉状態
  const [resultItemState, setResultItemState] = React.useState<boolean[]>([]);
  // コメント部高さ
  const [resultItemCommentHeight, setResultItemCommentHeight] = React.useState<number[]>([]);
  // 全体高さ
  const [resultItemHeight, setResultItemHeight] = React.useState<number[]>([]);

  //結果取得WebApi
  const InitialOnGet = (url: string, stNMonth: number, edNMonth: number, seqNo: number[], kmkCode: number) => {
    setIsLoading(true);
      const encodedUrl = [url, encodeURI(stNMonth.toString()), encodeURI(edNMonth.toString())].join('/');
      // 画面遷移元
      var activateScreenType = props.ActivateScreenType;
      if (!activateScreenType) {
          activateScreenType = ActivateScreenEnum.Result;
      }
      axios
        .post(encodedUrl, { seqNo: seqNo, kmkCode: kmkCode, activateScreenType: activateScreenType })
        .then(response => {
          setIsLoading(false);
          setResultItemState(Array(response.data.Results.length).fill(false));
          setResultItemHeight(Array(response.data.Results.length).fill(itemDefaultHeight));
          setResultItemCommentHeight(Array(response.data.Results.length).fill(commentDefaultHeight));
          setViewModel(response.data);
        })
        .catch(error => {
          setIsLoading(false);
          if (typeof error.response.data !== 'undefined') {
            setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
          }
          handleOnClose();
      });
  };

  const handleOnClose = () => {
    setViewModel(new AuditResultViewModel());
    setResultItemState([]);
    setResultItemCommentHeight([]);
    setResultItemHeight([]);
    onClose(parentRefresh);
    setParentRefresh(false);
  };

  //仕訳表示クリック時処理
  const [journalLineActivated, setJournalLineActivated] = React.useState(false);
  const [JournalLineSeqNo, setJournalLineSeqNo] = React.useState([0]);
  const [JournalLineRowNo, setJournalLineRowNo] = React.useState(0);
  const [JournalLineNmonth, setJournalLineNmonth] = React.useState(0);
  const handleOnJournalClick = (seqNo: number, rowNo: number, NMonth: number) => {
    //仕訳明細への画面遷移を記述する
    setJournalLineSeqNo([seqNo]);
    setJournalLineRowNo(rowNo);
    setJournalLineNmonth(NMonth);
    setJournalLineActivated(true);
  };
  const handleOnJournalLineClose = (isRefresh: boolean) => {
    setJournalLineActivated(false);
  };

  //確認ラジオボタン変更時処理
  const handleOnConfirmChange = (nMonth: number, seqNo: number, rowNo: number, auditMessageConfirm: ConfStatusEnum) => {
    updateStatus(GetConfUrl(AuditType), nMonth, seqNo, rowNo, auditMessageConfirm);
  };

  //コメント変更時処理
  const handleOnCommentBlur = (nMonth:number,   seqNo: number, rowNo: number, auditMessageComment: string) => {
    updateComment(GetCommentUrl(AuditType), nMonth, seqNo, rowNo, auditMessageComment);
  };

  //初期表示データ取得
  React.useEffect(() => {
    if (activated) {
      setViewModel(initializationViewModel);
      InitialOnGet(GetIntialUrl(AuditType), stNMonth, edNMonth, SeqNo, KmkCode);
    }
  }, [AuditType, stNMonth, edNMonth, SeqNo, KmkCode, activated]);

  //ヘッダーテキスト
  const headerText: React.ReactElement = (
    <div>
      <span>
        <b>
          {GetAuditName(AuditType)}
          {labels.AIKADT002000002_FUNCTION_NAME}
        </b>
      </span>
      <span className='ml-4 small'>
        {labels.AIKADT002000002_FUNCTION_HEADER_TARGETMONTH}
        {viewModel.TMonth}
      </span>
      <span className='ml-4 small'>
        {labels.AIKADT002000002_FUNCTION_HEADER_SUBJECTSNAME}
        {viewModel.KmkName}
      </span>
      <span className='ml-4 small'>
        {viewModel.Count}
        {labels.AIKADT002000002_FUNCTION_HEADER_AUDITMESSAGECOUNT}
      </span>
    </div>
  );

  const getRowHeight = (i: number) => {
    return resultItemHeight[i] + itemGapHeight;
  }

  const resultItemListFunc = (data:AuditResultVO, i: number, start: number, heightChangeCallback: () => void) => {
    const getEachFromResultsIndex = (i: number) => {
      let firstFocusRef;
      let lastFocusRef;
      if (i === 0) {
        firstFocusRef = resultFirstRef;
      }
      if (i === viewModel.Results!.length - 1) {
        lastFocusRef = resultLastRef;
      }
      return { firstFocusRef, lastFocusRef };
    };
    const { firstFocusRef, lastFocusRef } = getEachFromResultsIndex(i);
    const confirmDisabled = confirmDisabledList.filter(item => item.RowNo === data.RowNo);
    const disabled = confirmDisabled.length > 0 ? confirmDisabled[0].disabled : false;
    
    return (
      <ResultItem
        key={['result-item', data.NMonth, data.RowNo].join('-')}
        no={i + 1}
        confirmDisabled={disabled}
        className='mb-1'
        tabIndex={baseTabIndex + 1}
        firstFocusRef={firstFocusRef}
        lastFocusRef={lastFocusRef}
        resultDetail={data}
        isOpen={resultItemState[i]}
        commentHeight={resultItemCommentHeight[i]}
        y={start}
        //仕訳表示クリック
        onJournalClick={handleOnJournalClick}
        //確認ラジオボタン変更
        onConfirmChange={handleOnConfirmChange}
        //コメント変更(フォーカスアウト)
        onCommentBlur={(NMonth, SeqNo, RowNo, auditMessageComment) => {
          if (viewModel.Results) {
            viewModel.Results[i].Comment = auditMessageComment;
          }
          return handleOnCommentBlur(NMonth, SeqNo, RowNo, auditMessageComment);
        }}
        //コメントフォーカスイン
        onCommentForcus={() => {
          if (viewModel.Results) {
            viewModel.Results[i].Comment_bef = viewModel.Results[i].Comment;
          }
        }}
        //コメント変更(入力)
        onCommentInput={(auditMessageComment) => {
          if (viewModel.Results) {
            viewModel.Results[i].Comment = auditMessageComment;
          }
        }}
        //コメント開閉状態変化
        onTextAreaOpenChange={(isOpen: boolean) => {
          resultItemState[i] = isOpen;
          setResultItemState(resultItemState.slice());
        }}
        //コメント領域サイズ変更
        onCommentResize={(height: number) => {
          resultItemCommentHeight[i] = height;
          setResultItemCommentHeight(resultItemCommentHeight.slice());
        }}
        onBodyResize={(height: number) => {
          resultItemHeight[i] = height;
          setResultItemHeight(resultItemHeight.slice());
          heightChangeCallback();
        }}
      />
    );
  };


  /** キーボード操作（キーダウン） */
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 9) {
      if (event.shiftKey === false) {
        // タブキー押下時
        const target: HTMLElement = event.target as HTMLElement;
        if (
          target.tabIndex === closeIconRef.current.tabIndex ||
          target.tabIndex < resultFirstRef.current.tabIndex ||
          target.tabIndex > closeIconRef.current.tabIndex
        ) {
          resultFirstRef.current.focus();
          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        } else if (target.id === resultLastRef.current.id) {
          closeRef.current.focus();
          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
      } else {
        // シフト＋タブキー押下時

        const target: HTMLElement = event.target as HTMLElement;
        if (target.tabIndex === closeRef.current.tabIndex) {
          resultLastRef.current.focus();
          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        } else if (
          target.id === resultFirstRef.current.id ||
          target.tabIndex < resultFirstRef.current.tabIndex ||
          target.tabIndex > closeIconRef.current.tabIndex
        ) {
          closeIconRef.current.focus();
          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }
  };

  React.useEffect(() => {
    if (activated && viewModel.Results != undefined && viewModel.Results.length > 0) {
      // 確認ステータスの非活性リストを生成
      setConfirmDisabledList(viewModel.Results.map(item => ({ RowNo: item.RowNo!, disabled: false })));

      // 画面表示完了後に実行
      setTimeout(() => {
        if (popupRef.current) {
          // ポップアップにキーダウンイベントのフック
          popupRef.current.addEventListener('keydown', handleKeyDown);
        }
      });
    }
  }, [viewModel.Results]);

  return viewModel.Results == undefined || viewModel.Results.length < 1 ? null : (
    <Popup
      id='Result'
      isOpen={activated}
      onCloseClick={handleOnClose}
      header={headerText}
      headerColor={String(AuditTypeEnum[AuditType])}
      size='lg'
      innerRef={popupRef}
      closeIconTabIndex={baseTabIndex + 3}
      closeIconRef={closeIconRef}
      footer={
        <Button
          className='A3-btn'
          color='secondary'
          tabIndex={baseTabIndex + 2}
          onClick={handleOnClose}
          innerRef={closeRef}
        >
          {labels.COMMON_BUTTON_FUNCTION_CLOSE}
        </Button>
      }
      scrollDisabled={true}
    >
      <VirtualList 
        item={viewModel.Results}
        rowHeightCallback={getRowHeight}
        itemFunc={resultItemListFunc}
        viewPortMaxHeight='calc(100vh - 1.75rem * 2 - 120px)'
      />
      <JournalLine
        activated={journalLineActivated}
        onClose={handleOnJournalLineClose}
        auditType={AuditType}
        nMonth={JournalLineNmonth}
        stNMonth={stNMonth}
        edNMonth={edNMonth}
        seqNo={JournalLineSeqNo}
        rowNo={JournalLineRowNo}
        activateScreenType={props.ActivateScreenType!}
        kmkCode={props.KmkCode}
      />
    </Popup>
  );
};
export default Result;
