import { MjsUtilities } from "./utilities";

/**
 * HTML<->文字列、文字列<->HTMLの変換処理を行なうユーティリティクラス
 */
export class HtmlUtil {
    public static toBR(value: string): string {
        if (MjsUtilities.isNull(value)) {
            return null!;
        } else {
            return value.replace(/\r?\n/g, "<br />");
        }
    }
}
