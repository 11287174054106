import {AuditResultNegativeBalanceVO} from "./auditResultNegativeBalanceVO";
/**
 * 監査結果(マイナス残高)ViewModel ViewModel クラス
 */

export class AuditResultNegativeBalanceViewModel {
    /** 監査結果(マイナス残高)VO */
    public Results?:Array<AuditResultNegativeBalanceVO>;

    /** 対象月 */
    public TMonth?:string;

    /** 最終実行日時 */
    public LastExecDate?:Date;

}
