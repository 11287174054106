import React from 'react';
import { Button } from 'reactstrap';
import Popup from '../../../../components/molecules/CMN/Popup/Popup';
import * as labels from '../../../../constants/labels';
import logoImg from '../../../../images/logo_aikansa_R.png';

type VerInformationProps = {
  isOpen: boolean;
  onCloseClick: () => void;
};

// tabIndexの初期値
const baseTabIndex = 200;

const VerInformation: React.FC<VerInformationProps> = props => {
  const { isOpen, onCloseClick } = props;

  const firstFocusElement = React.useRef<any>();
  const lastFocusElement = React.useRef<any>();
  const popupRef = React.useRef<any>();

  /** キーボード操作（キーダウン） */
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 9) {
      if (event.shiftKey === false) {
        // タブキー押下時

        const target: HTMLElement = event.target as HTMLElement;
        if (
          target.tabIndex === lastFocusElement.current.tabIndex ||
          target.tabIndex < firstFocusElement.current.tabIndex ||
          target.tabIndex > lastFocusElement.current.tabIndex
        ) {
          // 最後のフォーカスエレメントの場合

          // 最初のエレメントにフォーカス
          firstFocusElement.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();

          // フォーカスが当たっていない場合
        }
      } else {
        // シフト＋タブキー押下時

        const target: HTMLElement = event.target as HTMLElement;
        if (
          target.tabIndex === firstFocusElement.current.tabIndex ||
          target.tabIndex < firstFocusElement.current.tabIndex ||
          target.tabIndex > lastFocusElement.current.tabIndex
        ) {
          // 最後のフォーカスエレメントの場合

          // 最後のエレメントにフォーカス
          lastFocusElement.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();

          // フォーカスが当たっていない場合
        }
      }
    }
  };

  /** 画面が表示された実行する処理 */
  React.useEffect(() => {
    if (isOpen) {
      // 画面表示完了後に実行
      setTimeout(() => {
        if (popupRef.current) {
          // ポップアップにキーダウンイベントのフック
          popupRef.current.addEventListener('keydown', handleKeyDown);
        }
      });
    }
  }, [isOpen]);

  return (
    <Popup
      isOpen={isOpen}
      header={labels.AIKCMN001000001_FUNCTION_NAME_VERSIONINFORMATION}
      footer={
        <div className='w-100 text-center'>
          <Button
            color='primary'
            className='mx-auto px-5 py-1'
            onClick={onCloseClick}
            tabIndex={baseTabIndex + 2}
            innerRef={lastFocusElement}
          >
            {labels.AIKCMN001000001_BUTTON_FUNCTION_OK}
          </Button>
        </div>
      }
      closeIconTabIndex={baseTabIndex + 1}
      closeIconRef={firstFocusElement}
      onCloseClick={onCloseClick}
      innerRef={popupRef}
    >
      <div className='d-flex justify-content-center align-items-end animated fadeInUp'>
        <div className='system-name'>
          <img src={logoImg} className='-logo m-0 p-0' />
        </div>
        <div className='system-version ml-4'>{labels.AIKCMN001000001_INPUT_HEADER_VERSION}2.00.0004</div>
      </div>
    </Popup>
  );
};

export default VerInformation;
