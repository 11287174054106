import { MigrationStep, MigrationSystemKbn } from "../../core/constants/constant";
import { MigrationStepCheckResultViewModel } from "../../models/process/migrationStepCheckResultViewModel";
import { PageInitializeParameter } from "../../pageController";
import { LinkButton } from "../component/button/linkButton";
import { AbstractHeader } from "./abstractHeader";
import $ from 'jquery';

export class ProcessMigrationHeader extends AbstractHeader {
    /** ヘッダー要素ID */
    protected headerId: string = "processMigrationHeader";

    protected $timeout: any;

    private mStepFileImportButton: LinkButton = new LinkButton("#head-stepFileImport-button");
    private mStepItemSettingButton: LinkButton = new LinkButton("#head-stepItemSetting-button");
    private mStepKmkSettingButton: LinkButton = new LinkButton("#head-stepKmkSetting-button");
    private mStepHojyoSettingButton: LinkButton = new LinkButton("#head-stepHojyoSetting-button");
    private mStepSwkImportButton: LinkButton = new LinkButton("#head-stepSwkImport-button");
    private mStepFinishButton: LinkButton = new LinkButton("#head-stepFinish-button");
    //private mCloseButton: LinkButton = new LinkButton("#head-close-button");

    private btnMap: { [key: number]: LinkButton; } = {}; // key:stepName

    constructor(parameter: PageInitializeParameter) {
        super(parameter);
        this.$timeout = parameter.$timeout;
    }

    public initialize() {
        super.initialize();

        this.btnMap[MigrationStep.FileImport] = this.mStepFileImportButton;
        this.btnMap[MigrationStep.ItemSetting] = this.mStepItemSettingButton;
        this.btnMap[MigrationStep.KmkSetting] = this.mStepKmkSettingButton;
        this.btnMap[MigrationStep.HojyoSetting] = this.mStepHojyoSettingButton;
        this.btnMap[MigrationStep.SwkImport] = this.mStepSwkImportButton;
        this.btnMap[MigrationStep.Finish] = this.mStepFinishButton;

        this.mStepFileImportButton.disable();
        this.mStepItemSettingButton.disable();
        this.mStepKmkSettingButton.disable();
        this.mStepHojyoSettingButton.disable();
        this.mStepSwkImportButton.disable();
        this.mStepFinishButton.disable();
    }

    public get stepFileImportButton(): LinkButton { return this.mStepFileImportButton; }
    public get stepItemSettingButton(): LinkButton { return this.mStepItemSettingButton; }
    public get stepKmkSettingButton(): LinkButton { return this.mStepKmkSettingButton; }
    public get stepHojyoSettingButton(): LinkButton { return this.mStepHojyoSettingButton; }
    public get stepSwkImportButton(): LinkButton { return this.mStepSwkImportButton; }
    public get stepFinishButton(): LinkButton { return this.mStepFinishButton; }
    //public get closeButton(): LinkButton { return this.mCloseButton; }        

    public initStepNav(stepCheckResult: MigrationStepCheckResultViewModel): void {
        let target = [];

        if (stepCheckResult.IsFileImportStepEnable) {
            target.push("#stepFileImportButton");
        }
        if (stepCheckResult.IsItemSettingStepEnable) {
            target.push("#stepItemSettingButton");
        }
        if (stepCheckResult.IsKmkSettingStepEnable) {
            target.push("#stepKmkSettingButton");
        }
        if (stepCheckResult.IsHojyoSettingStepEnable) {
            target.push("#stepHojyoSettingButton");
        }
        if (stepCheckResult.IsSwkImportStepEnable) {
            target.push("#stepSwkImportButton");
        }
        if (stepCheckResult.IsFinishStepEnable) {
            target.push("#stepFinishButton");
        }

        $.each(target, function (i, value) {
            $(value).show();
        });
    }

    public changeSystemStepNav(systemKbn: number): void {
        if (systemKbn == MigrationSystemKbn.Other) {
            $('#stepFileImportButton, #stepItemSettingButton, #stepKmkSettingButton, #stepHojyoSettingButton, #stepSwkImportButton, #stepFinishButton').show();
        } else {
            $('#stepFileImportButton, #stepKmkSettingButton, #stepHojyoSettingButton, #stepSwkImportButton, #stepFinishButton').show();
        }
    }

    public fadeInStepNav(stepCheckResult: MigrationStepCheckResultViewModel) {
        let target: any[] = [];

        if (stepCheckResult.IsItemSettingStepEnable) {
            target.push("#stepItemSettingButton");
        }
        if (stepCheckResult.IsKmkSettingStepEnable) {
            target.push("#stepKmkSettingButton");
        }
        if (stepCheckResult.IsHojyoSettingStepEnable) {
            target.push("#stepHojyoSettingButton");
        }
        if (stepCheckResult.IsSwkImportStepEnable) {
            target.push("#stepSwkImportButton");
        }
        target.push("#stepFinishButton");

        this.$timeout(() => {
            $.each(target, function (i, value) {
                $(value).delay(50 * i).fadeIn(200);
            });
        }, 50);
    }

    public fadeOutStepNav(isSaveItemSetting: boolean) {
        if (isSaveItemSetting) {
            // 項目設定保存からの呼び出しの場合
            $('#stepKmkSettingButton, #stepHojyoSettingButton, #stepSwkImportButton, #stepFinishButton').fadeOut(50);
        } else {
            $('#stepItemSettingButton, #stepKmkSettingButton, #stepHojyoSettingButton, #stepSwkImportButton, #stepFinishButton').fadeOut(50);
        }
    }

    public activeStepNav(migrationstep: number) {
        const id = this.getStepButtonId(migrationstep);
        $(id + " span").addClass("stepnav-active");
        this.btnMap[migrationstep].enable();
    }

    public notActiveStepNav(migrationstep: number) {
        const id = this.getStepButtonId(migrationstep);
        $(id + " span").removeClass("stepnav-active");
        this.btnMap[migrationstep].disable();
    }

    private getStepButtonId(migrationstep: number): string {
        switch (migrationstep) {
            case MigrationStep.FileImport: return "#stepFileImportButton";
            case MigrationStep.ItemSetting: return "#stepItemSettingButton";
            case MigrationStep.KmkSetting: return "#stepKmkSettingButton";
            case MigrationStep.HojyoSetting: return "#stepHojyoSettingButton";
            case MigrationStep.SwkImport: return "#stepSwkImportButton";
            case MigrationStep.Finish: return "#stepFinishButton";
            default: return "#stepFileImportButton";
        }
    }
}
