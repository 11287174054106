/**
 * ユーザー情報確認・変更ViewModel ViewModel クラス
 */

export class UserInfoEditViewModel {
    /** bizskyID値 */
    public bizskyID!:string;

    /** 姓 */
    public LastName!:string;

    /** 名 */
    public FirstName!:string;

    /** 姓カナ */
    public LastNameKana!:string;

    /** 名カナ */
    public FirstNameKana!:string;

    /** メールアドレス */
    public Email!: string;

    /** 確認用メールアドレス */
    public Email2: string | undefined;

}
