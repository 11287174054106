import React from 'react';
import { useVirtual } from "react-virtual";
import { AuditResultVO } from '../../../models/auditResultVO';

type VirtualListProps = {
    item: AuditResultVO[],
    rowHeightCallback: (i: number) => number,
    itemFunc: (item: any, i: number, start: number, heightChangeCallback: () => void ) => {},
    viewPortMaxHeight: string,
}

const VirtualList: React.FC<VirtualListProps> = props => {

  const resultRootRef = React.useRef(null);

  const [rowHeight, setRowHeight] = React.useState(Array(props.item.length).fill(true).map((item, i) => props.rowHeightCallback(i)));

  const rowVirtualizer = useVirtual({
    size: props.item.length,
    estimateSize: React.useCallback(i => rowHeight[i], [rowHeight]),
    parentRef: resultRootRef,
    overscan: 0
  });

  return (
    <div
      ref={resultRootRef}
      style={{
      maxHeight: props.viewPortMaxHeight,
      overflowY: 'auto'
      }}
    >
      <div
        style={{
        width: "100%",
        height: `${rowVirtualizer.totalSize + 2}px`,
        position: "relative"
        }}
      >
        {rowVirtualizer.virtualItems.map((virtualRow) => {
          return props.itemFunc(props.item[virtualRow.index], virtualRow.index, virtualRow.start, () => { 
            setRowHeight(Array(props.item.length).fill(true).map((item, i) => props.rowHeightCallback(i)));
          });
        })}
      </div>
    </div>
  );
}
export default VirtualList;
