import React from 'react';
import Axios from 'axios';
import * as wjInput from 'wijmo/wijmo.input';
import * as Enums from '../../../constants/enums';
import * as Maps from '../../../constants/dataMaps';

import AuditExecPopup, { AEPopupProps } from './AuditExecPopup';
import AuditExecGridPopup from './AuditExecGridPopup';
import AuditExecCheckPopup from './AuditExecCheckPopup';
import { ExecutionResultViewModel } from '../../../models/executionResultViewModel';
import { RulePatternViewModel } from '../../../models/rulePatternViewModel';
import { JournalLineRuleVO } from '../../../models/journalLineRuleVO';
import { DblJokenVO } from '../../../models/dblJokenVO';
import Input from 'reactstrap/lib/Input';
import FormGroup from 'reactstrap/lib/FormGroup';
import * as Labels from '../../../constants/labels';
import * as Messages from '../../../constants/message';
import { ComboBox } from 'wijmo/wijmo.react.input';
import { Button, Label } from 'reactstrap';
import { UpperActionLayout, UpperActionLayoutAction } from '../../templates/UpperActionLayout';
import { JournalLineRuleAuditVO } from '../../../models/journalLineRuleAuditVO';
import ConfirmationPopup from '../CMN/ConfirmationPopup/ConfirmationPopup';
import { bindValueToMessage as BindValueToMessage } from '../../../utils/messageUtils';
import AuditTargetManager, { AuditTargetManagerVo } from '../../../containers/organisms/A000/AuditTargetManager';
import { MasterKbn } from '../../../VKZ/core/constants/constant';
import { setIsLoading } from '../../../stores/NowLoading';

// 月次残高ルールパターン取得
export const rulePatternUrl = '/api/v1/RulePattern/Create';
// 監査未完了データ取得
export const remainUrl = '/api/v1/ExecutionResult/Remain';

export type AebPopupProps = AEPopupProps & {
  conditionHeading?: string;
  rulePatternVM?: RulePatternViewModel;
  journalLineRules?: Array<JournalLineRuleVO>;
  checkpattern?: Array<DblJokenVO>; 
  stNMonth?: number;
  edNMonth?: number;
  defaultAuditTarget?: AuditTargetManagerVo;
  onABClick?: (BatchTargets: Array<Enums.AuditTypeEnum>, Bridrules: Enums.RulePatternEnum | null, Bnbrules: Enums.RulePatternEnum | null, BjlRules: Array<JournalLineRuleAuditVO>, Bjdjokens: Array<Enums.DblJokenEnum>, auditTarget: AuditTargetManagerVo) => void;
}
// 一括監査実行対象
type BatchAuditType = {
  key: Enums.AuditTypeEnum;
  value: string;
  checked: boolean;
}

// tabIndexの初期値
const baseTabIndex = 400;

const initializationRulePatternViewModel: RulePatternViewModel = {
  RulePatternItems: []
};

const AuditBatchExecPopup: React.FC<AebPopupProps> = props => {
  // 月次残高ルールパターンのビューモデル
  const [rulePatternVM, setRulePatternViewModel] = React.useState(initializationRulePatternViewModel);
  // 科目用チェックリスト
  const [BatchAuditList, setBatchAudit] = React.useState<BatchAuditType[]>(
    Maps.AuditTypeEnum.map(batchaudit => ({
      key:batchaudit.key,
      value:batchaudit.value,
      checked:true
    }))
  );
  // 部門用チェックリスト
  const [bmnBatchAuditList, setBmnBatchAudit] = React.useState<BatchAuditType[]>(
    Maps.AuditTypeEnum.map(batchaudit => ({
      key:batchaudit.key,
      value:batchaudit.value,
      checked:batchaudit.key > 2? false : true
    }))
  );

  // **************************************************
  // 一括監査ポップアップ画面設定
  // **************************************************
  // チェックボックス変更時
  const handleOnCheckboxChange = (key: Enums.AuditTypeEnum) => {
    if (auditTarget.target === MasterKbn.CountingKmk) {
      // 科目
      if(key == 4 && BjderrMsg != '')
        {
          return;
        }
      const index = BatchAuditList.findIndex(batchaudit => batchaudit.key == key);
      BatchAuditList[index].checked = !BatchAuditList[index].checked;
      setBatchAudit([...BatchAuditList]);
    }
    else if (auditTarget.target === MasterKbn.Bmn) {
      // 部門
      const index = bmnBatchAuditList.findIndex(bmnbatchaudit => bmnbatchaudit.key == key);
      bmnBatchAuditList[index].checked = !bmnBatchAuditList[index].checked;
      setBmnBatchAudit([...bmnBatchAuditList]);
    }
  };

  // 監査実行ボタン押下
  async function handleOnClick(){
    await remainOnPost(remainUrl);
    setConfirmPopupActivated(true);
  };

  
  // **************************************************
  // 監査対象設定
  // **************************************************
  const [auditTarget, setAuditTarget] = React.useState<AuditTargetManagerVo>(props.defaultAuditTarget!);
  const [target, setTarget] = React.useState(props.defaultAuditTarget!.target);
  const [targetChangeDisable, setTargetChangeDisable] = React.useState(true);
  const [focusSetCnt, setFocusSetCnt] = React.useState(0);
  const [focusFlg, setFocusFlg] = React.useState(false);
  const handleOnBmnList = (e:any) => 
  {
    setTargetChangeDisable(false);
  }
  // 監査対象変更ハンドラー
  const handleOnTransAuditTarget = (e:AuditTargetManagerVo) =>
  {
    setTarget(e.target);
    // 部門に変更する場合
    if (e.target == MasterKbn.Bmn)
    {
      setAuditTarget(e);
    }
    // 科目に変更する場合
    if (e.target == MasterKbn.CountingKmk)
    {
      setAuditTarget({...auditTarget, target: e.target, items: [{BmnCode: ' '}]});
    }
    setFocusSetCnt(focusSetCnt + 1);
  }

  // **************************************************
  // 著増減設定
  // **************************************************
  const [Bridrules, setBridRule] = React.useState<wjInput.ComboBox>();
  // patternコンボボックスのItialized
  const BridruleInitialized = (comboBox: wjInput.ComboBox) => {
    setBridRule(comboBox);
  };
  // **************************************************
  // マイナス残高設定
  // **************************************************
  const [Bnbrules, setBnbRule] = React.useState<wjInput.ComboBox>();
  // patternコンボボックスのItialized
  const BnbruleInitialized = (comboBox: wjInput.ComboBox) => {
    setBnbRule(comboBox);
  };
  // **************************************************
  // 仕訳明細設定
  // **************************************************
  const [jlPopIsOpen, setJLPopIsOpen] = React.useState(false);
  const [jlPopDisabled, setJLPopDisabled] = React.useState(false);
  const [BjlRules, setBJLRules] = React.useState<Array<JournalLineRuleAuditVO>>([]);
  // 仕訳明細ポップアップ内チェック必須Msg
  const [jlErrMsg, setjlErrMsg] = React.useState('');
  // 監査実行ボタンの非表示FLG
  const [jlExecButton, setjlExecButton] = React.useState(false);
  // 仕訳明細条件設定押下
  const handleOnJLClick = () => {
    setjlExecButton(true);
    setJLPopIsOpen(true);
  }
  // 仕訳明細条件確認
  const handleOnJlPopClick = (rules: Array<JournalLineRuleAuditVO>) => {
    setjlErrMsg('');
    //条件の必須チェック
    if(rules.length == 0) {
      setjlErrMsg(BindValueToMessage(Messages.Common_Error_Unchecked, ['仕訳明細監査条件']));
      return;
    }
    setBJLRules([...rules]);
    setJLPopDisabled(false);
    setJLPopIsOpen(false);
  }
  // 仕訳明細条件閉じる
  const handleOnJLPopClose = () => {
    setJLPopIsOpen(false);
  };

  // **************************************************
  // 仕訳重複設定
  // **************************************************
  // 仕訳重複条件設定押下
  const [jdPopSave, setJDPopSave] = React.useState(false);
  const [jdPopIsOpen, setJDPopIsOpen] = React.useState(false);
  const [jdPopDisabled, setJDPopDisabled] = React.useState(false);
  const [BjdJokens, setBJdJokens] = React.useState<Array<Enums.DblJokenEnum>>([]);
  // DB上仕訳重複条件有無チェックMsg
  const [BjderrMsg, setBjderrMsg] = React.useState('');
  // 仕訳重複ポップアップ内チェック必須Msg
  const [jdErrMsg, setjdErrMsg] = React.useState('');
  // 監査実行ボタンの非表示FLG
  const [jdExecButton, setjdExecButton] = React.useState(false);
  // 仕訳重複条件押下
  const handleOnJDClick = () => {
    setjdExecButton(true);
    setJDPopDisabled(false);
    setJDPopIsOpen(true);
  }
  // 仕訳重複条件確認
  const handleOnJDPopClick = (jokens: Array<Enums.DblJokenEnum>) => {
    setjdErrMsg('');
    // 条件の必須チェック
    if (jokens.length == 0) {
      setjdErrMsg(BindValueToMessage(Messages.Common_Error_Unchecked, ['仕訳重複監査条件']));
      return;
    }
    setBjderrMsg('');
    setBJdJokens([...jokens]);
    setJDPopSave(true);
    setJDPopIsOpen(false);
  }
  // 仕訳重複条件閉じる
  const handleOnJDPopClose = () => {
    setJDPopSave(false);
    setJDPopIsOpen(false);
  };

  // **************************************************
  // 実行確認ポップアップ
  // **************************************************
  const [completeRemainMsg, setCompleteRemainMsg] = React.useState('');
  const [confirmPopupActivated, setConfirmPopupActivated] = React.useState(false);
  //確認ポップアップで閉じるボタン押下時
  const handleConfirmOnClose = () => {
    setConfirmPopupActivated(false);
  };
  //確認ポップアップで「はい」押下時
  const handleConfirmOnYesClick = () => {
    const BatchTargets: Array<Enums.AuditTypeEnum> = [];
    if (auditTarget.target === MasterKbn.CountingKmk) {
      // 科目のチェック項目をセットする
      BatchAuditList.forEach(batchaudit => {
        if(batchaudit.checked){
          BatchTargets.push(batchaudit.key);
        }
      });
    }
    else if (auditTarget.target === MasterKbn.Bmn) {
      // 部門のチェック項目をセットする
      bmnBatchAuditList.forEach(bmnbatchaudit => {
        if (bmnbatchaudit.checked) {
          BatchTargets.push(bmnbatchaudit.key)
        }
      });
    }
    // 引数: 監査実行対象, 著増減設定ルール, マイナス残高ルール, 仕訳明細ルール, 仕訳重複条件
    props.onABClick!(BatchTargets, Bridrules!.selectedItem == null? null :  Bridrules!.selectedItem.RulePattern, Bnbrules!.selectedItem == null? null : Bnbrules!.selectedItem.RulePattern, BjlRules, BjdJokens, auditTarget);
    setFocusSetCnt(0);
    setConfirmPopupActivated(false);
  };
  //確認ポップアップで「いいえ」押下時
  const handleConfirmOnNoClick = () => {
    setConfirmPopupActivated(false);
  };

  // クラス名生成
  const labelClassName = (value: Enums.AuditTypeEnum) => {
    let classNameString = "";
    switch (value) {
      case Enums.AuditTypeEnum.RemarkIncDecAudit:
        // 著増減監査
        classNameString = "label-RemarkIncDecAudit";
        break;
      case Enums.AuditTypeEnum.NegativeBalanceAudit:
        // マイナス残高監査
        classNameString = "label-NegativeBalanceAudit";
        break;
      case Enums.AuditTypeEnum.JournalLineAudit:
        // 仕訳明細監査
        classNameString = "label-JournalLineAudit";
        break;
      case Enums.AuditTypeEnum.JournalDoubleAudit:
        // 仕訳重複監査
        classNameString = "label-JournalDoubleAudit";
        break;
      case Enums.AuditTypeEnum.TaxKubunAudit:
        // 消費税区分監査
        classNameString = "label-TaxKubunAudit";
        break;
      default:
        break;
    }
    return classNameString;
  }

  // **************************************************
  // 通信処理
  // **************************************************
  // AIKCMN001000006_Get1：ルールパターンデータ作成
  async function rulePatternOnGet(url: string, auditTargetKbn: number) {
    setIsLoading(true);
    const encodeUrl = [url, encodeURI(auditTargetKbn.toString())].join('/');
    await Axios.get<RulePatternViewModel>(encodeUrl).then(response => {
      setRulePatternViewModel(response.data);
    });
    setIsLoading(false);
  }
  // 監査完了状況取得
  async function remainOnPost(url: string) {
    setIsLoading(true);
    const encodedUrl = [url, encodeURI(props.stNMonth!.toString()), encodeURI(props.edNMonth!.toString()), encodeURI(auditTarget.target!.toString())].join('/');
    const response = await Axios.post<ExecutionResultViewModel>(encodedUrl, {
      hojyoCodeList: auditTarget.items!
    });
    // 監査完了状況により、監査未完了MSGを表示制御
    // someの使い方: return trueの際にループを終了させる
    //               return falseの際にループを継続させる
    // 注意点:  値のリターンが必須であること
    if (auditTarget.target === MasterKbn.CountingKmk) {
      BatchAuditList.some(function(value){
        if (value.checked) {
          if ((value.key === Enums.AuditTypeEnum.RemarkIncDecAudit && !response.data.RidCompleteFlg) ||
              (value.key === Enums.AuditTypeEnum.NegativeBalanceAudit && !response.data.NBCompleteFlg) || 
              (value.key === Enums.AuditTypeEnum.JournalLineAudit && !response.data.JLCompleteFlg) ||
              (value.key === Enums.AuditTypeEnum.JournalDoubleAudit && !response.data.JDCompleteFlg) ||
              (value.key === Enums.AuditTypeEnum.TaxKubunAudit && !response.data.TKCompleteFlg)) {
            setCompleteRemainMsg(Messages.AuditExecution_Warning_Unconfirm);
            return true;
          } else {
            setCompleteRemainMsg('');
            return false;
          }
        } else {
          setCompleteRemainMsg('');
          return false;
        }
      });
    }
    else if (auditTarget.target === MasterKbn.Bmn) {
      bmnBatchAuditList.some(function(value){
        if (value.checked) {
          if ((value.key === Enums.AuditTypeEnum.RemarkIncDecAudit && !response.data.RidCompleteFlg) ||
              (value.key === Enums.AuditTypeEnum.NegativeBalanceAudit && !response.data.NBCompleteFlg)) {
            setCompleteRemainMsg(Messages.AuditExecution_Warning_Unconfirm);
            return true;
          } else {
            setCompleteRemainMsg('');
            return false;
          }
        } else {
          setCompleteRemainMsg('');
          return false;
        }
      });
    }
    setIsLoading(false);
  }

  // BatchAuditListについて 
  // batchaudit.keyの値はEnums.AuditTypeEnumになっています。詳細は以下
  // 著増減監査: 1,
  // マイナス残高監査: 2,
  // 仕訳明細監査: 3,
  // 仕訳重複監査: 4,
  // 消費税区分監査: 5,
  // チェックボックス情報取得
  const kmkCheckBoxes: React.ReactElement[] = [];
  const bmnCheckBoxes: React.ReactElement[] = [];
  BatchAuditList.forEach(batchaudit => {
    kmkCheckBoxes.push(
      <FormGroup check className='w-20' key={[batchaudit.key, 'checkbox'].join('-')}>
        <Label check className={labelClassName(batchaudit.key)}>
          <Input
            className='audit-checkbox'
            type='checkbox'
            name='batchaudit'
            value={batchaudit.key}
            checked={batchaudit.checked}
            onChange={() => {
              handleOnCheckboxChange(batchaudit.key);
            }}
            tabIndex={baseTabIndex + batchaudit.key}
          />
          <span className='audit-name'>{batchaudit.value + '実行'}</span>
        </Label>
      </FormGroup>
    );
  })
  bmnBatchAuditList.forEach(batchaudit => {
    bmnCheckBoxes.push(
      <FormGroup check className='w-20' key={[batchaudit.key, 'checkbox'].join('-')}>
        <Label check className={labelClassName(batchaudit.key)}>
          <Input
            className='audit-checkbox'
            type='checkbox'
            name='batchaudit'
            value={batchaudit.key}
            checked={batchaudit.key > 2? false : batchaudit.checked}
            onChange={() => {
              handleOnCheckboxChange(batchaudit.key);
            }}
            tabIndex={baseTabIndex + batchaudit.key}
            disabled={batchaudit.key > 2}
          />
          <span className='audit-name'>{batchaudit.value + '実行'}</span>
        </Label>
      </FormGroup>
    );
  })

  // 初期起動時
  React.useEffect(() => {
    // 監査対象が科目の場合
    if (props.defaultAuditTarget!.target == MasterKbn.CountingKmk)
    {
      // 仕訳明細を初期設定
      if(props.journalLineRules != [])
      {
        setBJLRules([...props.journalLineRules!]);
      }
      // 仕訳重複を初期設定
      setJDPopSave(false);

      if(props.checkpattern !== undefined && props.checkpattern && props.checkpattern != [])
      {
        setBjderrMsg('');
        const targets: Array<Enums.DblJokenEnum> = [];
        props.checkpattern.forEach(check => {
          targets.push(check.DblJoken!);
        });
        BatchAuditList.forEach(batchaudit => {
          if (batchaudit.key == 4 && targets.length > 0) {
            batchaudit.checked = true;
          }
        });
        setBJdJokens(targets);
      } else {
        setBjderrMsg(Messages.BatchAuditExec_Error_JDNotSet);
        BatchAuditList.forEach(batchaudit => {
          if(batchaudit.key == 4){
            batchaudit.checked = false;
          }
        })
      }
      setBatchAudit([...BatchAuditList]);
    }
    //監査対象が部門の場合
    else if (props.defaultAuditTarget!.target == MasterKbn.Bmn)
    {
      setBjderrMsg('');
      bmnBatchAuditList.forEach(batchaudit => {
        if(batchaudit.key > 2) {
          batchaudit.checked = false;
        }
      })
      setBmnBatchAudit([...bmnBatchAuditList]);
    }
    // 初期表示するたびに、フォーカスをセットするようフラグをリセットする
    setFocusFlg(false);
  }, [props.isOpen]);

  // 監査対象変更時
  React.useEffect(() => {
    rulePatternOnGet(rulePatternUrl, target!);
  }, [target]);

  const firstFocusElement = React.useRef<any>();
  const lastFocusElement = React.useRef<any>();

  React.useEffect(() => {
    if (!focusFlg) {
      if (!targetChangeDisable) {
        const auditTargetCmbElement = document.getElementById('auditTarget');
        if (auditTargetCmbElement) {
          const textElement = auditTargetCmbElement.getElementsByTagName('input');
          const buttonElement = auditTargetCmbElement.getElementsByTagName('button');
          const elements = Array.from(textElement);
          const buttonEle = Array.from(buttonElement);
          // 監査対象有効の場合、firstfocusを監査対象に設定する
          firstFocusElement.current = elements[0];
          elements[0].focus();
          var auditTargetBaseTab = 300;
          var i = 0;
          // 部門範囲のTab順を設定するために、ハードコーディングする
          // 以下順になります
          // 部門開始コード⇒部門開始選択ボタン⇒部門名開始⇒部門終了コード⇒部門終了選択ボタン⇒部門名終了
          while (i < elements.length)  {
            if (elements[i].type === 'text') {
              elements[i].tabIndex = auditTargetBaseTab + i*2;
              i++;
            }
          }
          buttonEle[4].tabIndex = auditTargetBaseTab + 5;
          buttonEle[5].tabIndex = auditTargetBaseTab + 9;
        }
      } else {
        //監査対象無効の場合、firstfocusをチェックボックスに設定
        const checkBoxElement = document.getElementById('audit-item');
        if (checkBoxElement) {
          const textElement = checkBoxElement.getElementsByTagName('input');
          const elements = Array.from(textElement);
          for (let item of elements) {
            if (item.type === 'checkbox') {
              firstFocusElement.current = item;
              break;
            }
          }
        }
      }
      setFocusFlg(true);
    }
  }, [focusSetCnt]);

  React.useEffect(() => {
    // WijmoのコンボボックスにTabIndexを設定してもTab遷移がうまくいかない為
    // コンボボックス内のテキストボックスにTabIndexを設定する
    const rulePatternCmbElement = document.getElementById('auditBatch-Rid');
    if (rulePatternCmbElement) {
      const textElement = rulePatternCmbElement.getElementsByTagName('input');
      const elements = Array.from(textElement);
      for (let item of elements) {
        if (item.type === 'text') {
          item.tabIndex = baseTabIndex + 6;
          break;
        }
      }
    }
  }, [Bridrules]);

  React.useEffect(() => {
    // WijmoのコンボボックスにTabIndexを設定してもTab遷移がうまくいかない為
    // コンボボックス内のテキストボックスにTabIndexを設定する
    const rulePatternCmbElement = document.getElementById('auditBatch-Nb');
    if (rulePatternCmbElement) {
      const textElement = rulePatternCmbElement.getElementsByTagName('input');
      const elements = Array.from(textElement);
      for (let item of elements) {
        if (item.type === 'text') {
          item.tabIndex = baseTabIndex + 7;
          break;
        }
      }
    }
  }, [Bnbrules]);

  const conditionHeading = props.conditionHeading ? <label>{props.conditionHeading}</label> : '';
  const popupProps = { ...props };
  delete popupProps['conditionHeading'];
  delete popupProps['journalLineRules'];
  delete popupProps['checkpattern'];
  delete popupProps['stNMonth'];
  delete popupProps['edNMonth'];
  delete popupProps['defaultAuditTarget'];
  delete popupProps['onABClick'];

  return (
    <UpperActionLayout className='AuditBatchExec'>
      <UpperActionLayoutAction>
        <AuditExecPopup
        {...popupProps}
          onClick={handleOnClick}
          className='audit-batch-exec'
          execButtonTabIndex={baseTabIndex + 10}
          cancelButtonTabIndex={baseTabIndex + 11}
          closeIconTabIndex={baseTabIndex + 12}
          closeIconRef={lastFocusElement}
          firstFocusElement={firstFocusElement}
          lastFocusElement={lastFocusElement}
        >
          <div>{conditionHeading}</div>
          <div className='mb-2' id='auditTarget'>
            <AuditTargetManager
              name='AuditBatchExecPopup-AuditTarget'
              transmitSelectItems={handleOnTransAuditTarget}
              transmitBmnList={handleOnBmnList}
              defaultSelectItem={props.defaultAuditTarget}
              applyRange={true}
            />
          </div>
          <div className='audit-item' id='audit-item'>{auditTarget.target == MasterKbn.CountingKmk? kmkCheckBoxes : bmnCheckBoxes}</div>
          <div>{props.children}</div>
          <div className='audit-positon-item'>
            <ul>
              <li className="remark-incdec">
                {/* 著増減監査コンボボックス */}
                <ComboBox
                  id='auditBatch-Rid'
                  className='w-100'
                  itemsSource={rulePatternVM!.RulePatternItems}
                  displayMemberPath={'RulePatternName'}
                  selectedValuePath={'RulePattern'}
                  selectedValue={rulePatternVM!.DefaultRulePattern}
                  initialized={BridruleInitialized}
                />
              </li>
              <li className="negative-balance">
                {/* マイナス残高コンボボックス */}
                <ComboBox
                  id='auditBatch-Nb'
                  className='w-100'
                  itemsSource={rulePatternVM!.RulePatternItems}
                  displayMemberPath={'RulePatternName'}
                  selectedValuePath={'RulePattern'}
                  selectedValue={rulePatternVM!.DefaultRulePattern}
                  initialized={BnbruleInitialized}
              />
              </li>
              <li className="journal-line">
                {/* 仕訳明細監査実行ボタン */}
                <Button
                  className="setting-cond-button"
                  color='primary'
                  onClick={handleOnJLClick}  
                  tabIndex={baseTabIndex + 8}
                  disabled={auditTarget.target == MasterKbn.Bmn}
                >
                  {'条件設定'}
                </Button>
              </li>
              <li>
                {/* 仕訳重複監査実行ボタン */}
                <Button
                  className="setting-cond-button"
                  color='primary'
                  onClick={handleOnJDClick}
                  tabIndex={baseTabIndex + 9}
                  disabled={auditTarget.target == MasterKbn.Bmn}
                >
                  {'条件設定'}
                </Button>
                <label className='text-danger jdErrMsg'>{BjderrMsg}</label>
              </li>
            </ul>
          </div>
        </AuditExecPopup>
      </UpperActionLayoutAction>
      {/* 仕訳明細監査条件ポップアップ */}
      <AuditExecGridPopup
        {...popupProps}
        id='ExecutionResult-JournalLine-popup'
        isOpen={jlPopIsOpen}
        header={Labels.AIKADT002000001_BUTTON_FUNCTION_JOURNALLINEEXECUTION}
        headerColor={Enums.AuditTypeEnum[Enums.AuditTypeEnum.JournalLineAudit]}
        onCloseClick={handleOnJLPopClose}
        description={Labels.AIKADT002000001_INPUT_HEADER_JOURNALLINECONTENT}
        disabled={jlPopDisabled}
        isExecute={false}
        isBatchExec={jlExecButton}
        errorMessage={jlErrMsg}
        journalLineRules={props.journalLineRules}
        conditionHeading={Labels.AIKADT002000001_INPUT_HEADER_JOURNALLINECONDITION}
        onJLClick={handleOnJlPopClick}
      />
      {/* 仕訳重複監査条件ポップアップ */}
      <AuditExecCheckPopup
        {...popupProps}
        isOpen={jdPopIsOpen}
        header={Labels.AIKADT002000001_BUTTON_FUNCTION_JOURNALDOUBLEEXECUTION}
        headerColor={Enums.AuditTypeEnum[Enums.AuditTypeEnum.JournalDoubleAudit]}
        onCloseClick={handleOnJDPopClose}
        description={Labels.AIKADT002000001_INPUT_HEADER_JOURNALDOUBLECONTENT}
        disabled={jdPopDisabled}
        isExecute={false}
        isBatchExec={jdExecButton}
        errorMessage={jdErrMsg}
        checkpattern={props.checkpattern}
        conditionHeading={Labels.AIKADT002000001_INPUT_HEADER_JOURNALDOUBLECONDITION}
        onJDClick={handleOnJDPopClick}
        jdpopSave={jdPopSave}
      />
      <ConfirmationPopup
        {...popupProps}
        isOpen={confirmPopupActivated}
        onClose={handleConfirmOnClose}
        onYesClick={handleConfirmOnYesClick}
        onNoClick={handleConfirmOnNoClick}
        errorMessage={completeRemainMsg}
      >
        <label className='white-space-pre-wrap'>{Labels.AIKADT002000001_INPUT_HEADER_BATCHEXECUTIONCOMFIRM}</label>
      </ConfirmationPopup>
    </UpperActionLayout>
  )
}

export default AuditBatchExecPopup;