import React from 'react';
import * as wjGrid from 'wijmo/wijmo.grid';
import { FlexGrid } from 'wijmo/wijmo.react.grid';
import * as wjFilter from 'wijmo/wijmo.grid.filter';
import { convertItemsSourceToDateType } from '../../../../utils/wijmoGridUtils';
import { UserAffiliationVO } from '../../../../models/userAffiliationVO';
import { UserVO } from '../../../../models/userVO';
import { UserAvailableCompanyVO } from '../../../../models/userAvailableCompanyVO';
import personSvg from '../../../../images/icon/26-1_person.svg';

type TantoByCompanyGridDragVO = UserAvailableCompanyVO & UserAffiliationVO & UserVO & any;

// ソート条件のテンポラリー
export type sortCriteriaStore = {
  property?: string;
  ascending?: boolean;
};

export type sortLabelMap = {
  key: any;
  value: string;
};
export type sortLabel = {
  binding: string;
  map: sortLabelMap[];
};

type TantoByCompanyGridDragProp = {
  columns: TantoByCompanyGridDragVO[];
  sources: TantoByCompanyGridDragVO[];
  headersVisi: number;
  childItemsPath?: string;
  sortLabelMapList?: sortLabel[];
  gridClassName?: string;
  gridRef?: React.RefObject<any>;
  selectMode?: number;
  saveSeletedMode?: (mode: number) => void;
  onDeleteClick?: (item: TantoByCompanyGridDragVO) => void;
  onDragStart?: (item: TantoByCompanyGridDragVO) => void;
  onDropEnd?: (clientCode: number) => void;
  onFilterChanged?: (filterJson: string) => void;
}

type TantoByCompanyGridDragState = {};

const htmlPersonSvg = () =>
  `<span class='mr-2 bg-svg-icon' title="担当者" style="background-image: url(${personSvg})">　</span>`;

class TantoByCompanyGridDrag extends React.Component<TantoByCompanyGridDragProp, TantoByCompanyGridDragState> {
  grid?: wjGrid.FlexGrid;
  filter?: wjFilter.FlexGridFilter;
  isChangeFilterAndSort = false;

  constructor(props: TantoByCompanyGridDragProp) {
    super(props);

    this.initializedGrid = this.initializedGrid.bind(this);
    this.handleItemFormatter = this.handleItemFormatter.bind(this);
  }

  // Gridの初期化
  public initializedGrid(
    flexGrid: wjGrid.FlexGrid,
    selectR: any[],
    lastClickRowInndx: number,
    lastFirstSelectedRowIndex: number,
    lastEndSelectedRowIndex: number,
    lastSelectedRowHistory: any[]
  ) {
    this.grid = flexGrid;
    selectR = [];
    // 最後にクリック行インデックスを保存
    lastClickRowInndx = -1;
    // 選択先頭行インデックス
    lastFirstSelectedRowIndex = -1;
    // 選択末尾行インデックス
    lastEndSelectedRowIndex = -1;
    // 選択状態の履歴を保持
    lastSelectedRowHistory = [];

    flexGrid.hostElement.addEventListener('keydown', e => {
      if (e.ctrlKey == true || e.shiftKey == true) {
        flexGrid.selectionMode = wjGrid.SelectionMode.ListBox;
        //e.stopPropagation();
      }
    },true);
/*
    flexGrid.hostElement.addEventListener('focusout', e => {
      this.props.saveSeletedMode!(flexGrid.selectionMode);
    }, true);

    flexGrid.hostElement.addEventListener('focusin', e => {
      flexGrid.selectionMode = this.props.selectMode!;
    },true)
*/
    // click と ctrl、shift
    // flexGrid.hostElement.addEventListener('click', e => {
    //   if (e.ctrlKey == true) {
    //     flexGrid.selectionMode = wjGrid.SelectionMode.ListBox;
    //     // 選択配列を初期化
    //     /*
    //     selectR = [];
    //     var ht = flexGrid.hitTest(e);
    //     for(var i = 0; i < flexGrid.itemsSource.length; i++) {
    //       // 選択されたら
    //       if(flexGrid.rows[i].isSelected) {
    //         selectR.push({...flexGrid.rows[i].dataItem});
    //       }
    //     }
    //     */
    //     e.stopPropagation();
    //   } else if (e.shiftKey == true) {
    //     flexGrid.selectionMode = wjGrid.SelectionMode.RowRange;
    //   } else{
    //     flexGrid.selectionMode = wjGrid.SelectionMode.Row;
    //     e.stopPropagation();
    //   }
    // }, true);

// disable built-in row drag/drop
// handle mousedown
    flexGrid.hostElement.addEventListener('mousedown', e => {
      if (flexGrid.hitTest(e).cellType == wjGrid.CellType.Cell && e.ctrlKey == false && e.shiftKey == false) {
        if (lastClickRowInndx == flexGrid.hitTest(e)!.row
          && typeof flexGrid.selectedRows !== 'undefined'
          && flexGrid.selectedRows.length == 1
        ) {
          // 前回と同じ行を選択され且つ、単一選択の場合は何もしない
          e.stopPropagation();
        } else if (typeof flexGrid.selectedRows !== 'undefined' && flexGrid.selectedRows.length > 1) {
          if (flexGrid.rows[flexGrid.hitTest(e)!.row].isSelected) {
            e.stopPropagation();
          }
        }
      }
      // 最後のクリックされた行インデックスを保持
      lastClickRowInndx = flexGrid.hitTest(e)!.row;
      // 選択した範囲のインデックス情報を保持
      if (typeof flexGrid.selectedRows !== 'undefined') {
        lastFirstSelectedRowIndex = flexGrid.selectedRows[0]!._idx;
        lastEndSelectedRowIndex = flexGrid.selectedRows[flexGrid.selectedRows.length - 1]!._idx;
        lastSelectedRowHistory = [];
        for (var rowIndex = 0; rowIndex < flexGrid.selectedRows.length; rowIndex++) {
          lastSelectedRowHistory.push({ ...flexGrid.selectedRows[rowIndex].dataItem });
        }
      }
    }, true);

// handle mouseup
    flexGrid.hostElement.addEventListener('mouseup', e => {
      if (typeof flexGrid.selectedRows !== 'undefined') {
        // グリッドのインスタンスが正しく設定されている場合のみ処理する
        var nowFirstSelectIdx = flexGrid.selectedRows[0]!._idx;
        var nowLastSelectIdx = flexGrid.selectedRows[flexGrid.selectedRows.length - 1]!._idx;
        var mouseOutRowIndex = flexGrid.hitTest(e)!.row;
        var mouseOutRowSelected = flexGrid.rows[mouseOutRowIndex]!.isSelected
        if (lastFirstSelectedRowIndex == nowFirstSelectIdx
          && lastEndSelectedRowIndex == nowLastSelectIdx
          && lastSelectedRowHistory.length == flexGrid.selectedRows.length
          && mouseOutRowSelected
          && flexGrid.selectedRows.length > 1
        ) {
          // 完全に同じインデックス且つ
          // 選択済みを再び押下した且つ
          // 複数選択の場合はその押下した箇所以外を選択解除する
          for (var i = 0; i < flexGrid.rows.length; i++) {
            if (i != flexGrid.hitTest(e)!.row) {
              flexGrid.rows[i].isSelected = false;
              // グリッドのselectionChangeが走らないのでCSSも併せて上書きする
              flexGrid.rows[i].cssClass = 'non-select-bk-color-white';
            }
          }
        }
      }
    }, true);

// handle drag start
    flexGrid.hostElement.addEventListener('dragstart', e => {
      // グリッドのレコード選択状態からドラッグデータを設定する
      // 選択情報格納変数初期化
      selectR = [];
      // ★旧選択変数格納処理削除しても問題ない実装者が鈴木ではない為一応残しておく↓
        /*
      if (e.ctrlKey == true) {
        // ctrlキーの場合は選択が解除されてしまうのでキープするように処理する
        var ht = flexGrid.hitTest(e);
        if (!flexGrid.rows[ht.row]!.isSelected) {
          flexGrid.rows[ht.row].isSelected = true;
        }
        // レイアウト調整
        for (var rowIndex = 0; rowIndex < flexGrid.rows.length; rowIndex++) {
          if (!flexGrid.rows[rowIndex].isSelected) {
            // 未選択状態の場合はCSSを上書き
            flexGrid.rows[rowIndex].cssClass = 'non-select-bk-color-white';
          } else {
            // 選択状態の場合はCSSをデフォルトの状態にする
            flexGrid.rows[rowIndex].cssClass = undefined;
          }
        }
        // 選択状態をキープした後にドラッグデータを作成
        for(var i = 0; i < flexGrid.itemsSource.length; i++) {
          // 選択されたらドラッグデータに追加
          if(flexGrid.rows[i].isSelected) {
            selectR.push({...flexGrid.rows[i].dataItem});
          }
        }
      }
      if (e.shiftKey == true) {
        for(var i = 0; i < flexGrid.itemsSource.length; i++) {
          // 選択されたらドラッグデータに追加
          if(flexGrid.rows[i].isSelected) {
            selectR.push({...flexGrid.rows[i].dataItem});
          }
        }
      }
//----->test
      e.stopPropagation();
      //1行のみ選択の際
      if (selectR.length < 1) {
        var ht = flexGrid.hitTest(e);
        flexGrid.select(new wjGrid.CellRange(ht.row, ht.row, flexGrid.columns.length - 1));
        selectR = flexGrid.selectedItems;
        // var rowElement = document.createElement('div');
        // rowElement.innerHTML = htmlPersonSvg(); // iconのElement
        // e.dataTransfer!.setDragImage(rowElement!, 100, 10);
      }
      var rowElement = document.createElement('div');
      rowElement.innerHTML = htmlPersonSvg(); // iconのElement
      // ドラッグの残像消しはIEだと機能しないのでコメントアウト
      // e.dataTransfer!.setDragImage(rowElement!, 100, 10);
*/
      // ★旧選択変数格納処理 ここまで↑
      // ドラッグ開始以降はイベント伝番を禁止にする
      e.stopPropagation();
      // グリッドから選択されたレコードのみをドラッグ情報としてまとめる
      if (typeof flexGrid.selectedRows !== 'undefined' && flexGrid.selectedRows.length > 0) {
        for (var rowIndex = 0; rowIndex < flexGrid.selectedRows.length; rowIndex++) {
          selectR.push({ ...flexGrid.selectedRows[rowIndex].dataItem });
        }
      }

      if (typeof this.props.onDragStart === 'function') {
        this.props.onDragStart!(selectR);
      }
    }, true);
  };

  // ItemFormatter
  public handleItemFormatter = (panel: wjGrid.GridPanel, row: number, col: number, cell: HTMLElement) => {
    // 縦罫線を消す
    cell.style.borderRight = 'none';
    // セルを選択したら
    if (panel.cellType == wjGrid.CellType.Cell) {
      cell.draggable = true;
      cell.classList.add('cursor-move');
    }

    if (panel.cellType === wjGrid.CellType.Cell) {

      const column: wjGrid.Column = panel.columns[col];
      const r: wjGrid.Row = panel.rows[row];
      const item: TantoByCompanyGridDragVO = r.dataItem;
      // マークをつける
      switch (column.binding) {
        case 'LoginID':
          if (item.LoginID != undefined) {
            cell.innerHTML = htmlPersonSvg() + cell.innerHTML;
          }
          break;
      }
    }
  }

  // 選択変更イベントハンドラ
  public selectionChanged = (flexGrid: wjGrid.FlexGrid, event: wjGrid.CellRangeEventArgs) => {
    // イベント内容確認用
    // リリースビルドの時は出力されないようにコントロール
    console.debug(event);
    // 選択状態以外のCSSを上書き(wijmoのバージョンアップによっては調整の必要あり)
    if (typeof flexGrid.selectedRows !== 'undefined' && flexGrid.selectedRows.length > 0) {
      if (flexGrid.selectedRows.length == 1) {
        // 単数選択
        for (var rowIndex = 0; rowIndex < flexGrid.rows.length; rowIndex++) {
          if (rowIndex != flexGrid.selectedRows[0]!.index) {
            // 未選択状態の場合はCSSを上書き
            flexGrid.rows[rowIndex].cssClass = 'non-select-bk-color-white';
          } else {
            // 選択状態の場合はCSSをデフォルトの状態にする
            flexGrid.rows[rowIndex].cssClass = undefined;
          }
        }
      } else {
        // 複数選択
        for (var rowIndex = 0; rowIndex < flexGrid.rows.length; rowIndex++) {
          if (!flexGrid.rows[rowIndex].isSelected) {
            // 未選択状態の場合はCSSを上書き
            flexGrid.rows[rowIndex].cssClass = 'non-select-bk-color-white';
          } else {
            // 選択状態の場合はCSSをデフォルトの状態にする
            flexGrid.rows[rowIndex].cssClass = undefined;
          }
        }
      }
    } else {
      // 未選択の場合は一律CSSを上書き
      for (var rowIndex = 0; rowIndex < flexGrid.rows.length; rowIndex++) {
        // 未選択状態の場合はCSSを上書き
        flexGrid.rows[rowIndex].cssClass = 'non-select-bk-color-white';
      }
    }
  }

  public render() {
    const { columns, sources, gridClassName, gridRef  } = this.props;
    convertItemsSourceToDateType(columns,sources);
    return (
      <FlexGrid
        className={gridClassName}
        autoGenerateColumns={false}
        columns={columns}
        itemsSource={sources}
        childItemsPath={this.props.childItemsPath}
        headersVisibility={this.props.headersVisi}
        isReadOnly={true}
        selectionMode={wjGrid.SelectionMode.ListBox}
        allowResizing={wjGrid.AllowResizing.None}
        alternatingRowStep={0}
        initialized={this.initializedGrid}
        itemFormatter={this.handleItemFormatter}
        ref={gridRef}
        selectionChanged={this.selectionChanged}
      >
      </FlexGrid>
    )
  }
}

export default TantoByCompanyGridDrag;