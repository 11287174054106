import { IDialog } from "../overlay/abstractDialog";
import $ from 'jquery';
import { Component } from "../component";

export abstract class AbstractButton extends Component {
    private _dialog!: IDialog;

    constructor(selector: string, label: string) {
        super(selector, label);
        this.clickEventBind();
        this.bindLabel();
    }

    private clickEventBind(): void {
        var self: AbstractButton = this;
        if (this.getSelector() != null) {
            // 以下の指定だとインスタンスごとにイベントがバインドされワンクリックで複数イベントが発火することがある
            //var element = this.findElement();
            // クリックイベントのアンバインド
            //$(element).unbind("click");
            //$(element).on("click", () => { self.execute() });
            $(this.getSelector()).unbind("click");
            $(this.getSelector()).on("click", () => { 
                self.execute(); 
            });
        }
    }

    protected bindLabel(): void {
        if (this.getLabel() != null) {
            $(this.getSelector()).html(this.getLabel());
        }
    }

    public setParentPanel(dialog: IDialog) {
        this._dialog = dialog;
    }

    public getParentPanel(): IDialog {
        return this._dialog;
    }
    /**
     * ボタンを有効化する
     */
    public abstract enable(): void;
    /*
     * ボタンを 無効化する
     */
    public abstract disable(): void;
    /**
     * ボタンの有効/無効をフラグで返す。
     * true:有効
     * false:無効
     */
    public abstract isEnabled(): boolean;

    public static disabledClass = "custom-button-disabled";
}
