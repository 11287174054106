import React from 'react';
import ReactN from 'reactn';
import axios from 'axios';
import Notice from '../../../../components/molecules/A060/Notice';
import {
  LowerActionDoubleContentsLayout,
  LowerActionDoubleContentsLayoutHeader,
  LowerActionDoubleContentsLayoutContentsTop,
  LowerActionDoubleContentsLayoutContentsBottom,
  LowerActionDoubleContentsLayoutAction
} from '../../../../components/templates/LowerActionDoubleContentsLayout';

import PageHeader from '../../../../components/molecules/CMN/PageHeader/PageHeader';
import * as labels from '../../../../constants/labels';
import ExcelOutputsetting from '../../../organisms/A000/ExcelOutputsetting';
import MonthSelect, { MODE } from '../../../organisms/A000/MonthSelect';
import CommonGrid from '../../../../components/molecules/CMN/Grid/CommonGrid';
import { ChecklistViewModel } from '../../../../models/checklistViewModel';
import { ChecklistVO } from '../../../../models/checklistVO';
import { ChecklistNoticeVO } from '../../../../models/checklistNoticeVO';
import ConfirmationPopup from '../../../../components/molecules/CMN/ConfirmationPopup/ConfirmationPopup';
import ExecutingCancel from '../../../../components/molecules/CMN/ExecutingCancel/ExecutingCancel';

// WebServiceWpiUri定義
export const getApiBaseUrl: string = '/api/v1/Checklist/Initial';
export const postApiBaseUrl: string = '/api/v1/Checklist/Output';

type ChecklistProps = {
  onClose: () => void;
};
const Checklist: React.FC<ChecklistProps> = props => {
  // 指定対象月の格納用状態変数宣言
  const [selectedMonth, updateSelectedMonth] = React.useState(0);
  //取得実行トリガー用状態変数
  const [excuteGetActivateCount, updateExcuteGetActivateCount] = React.useState(0);
  // 登録実行トリガー用状態変数
  const [excuteSaveActivateCount, updateExcuteSaveActivateCount] = React.useState(0);
  // 保存確認ポップアップ表示用状態変数
  const [saveConfirmPopupActivated, updateSaveConfirmPopupActivated] = React.useState(false);
  // クリア確認ポップアップ表示用状態変数
  const [clearConfirmPopupActivated, updateClearConfirmPopupActivated] = React.useState(false);
  // Excel出力ポップアップ表示用状態変数
  const [excelPopupActivated, setExcelPopupActivated] = React.useState(false);
  // フィルターExcel出力設定
  const [filters, setfilters] = React.useState('');
  // globalのエラーメッセージステータスを宣言
  const [, setGlobalErrorMessage] = ReactN.useGlobal('');
  // ビジネスロジックのエラーメッセージステータスを宣言
  const [errorMessage, setErrorMessage] = React.useState('');

  const [viewModel, updateViewModel] = React.useState<ChecklistViewModel>({ Checklists: [], Notices: [] });
  let gridSource: ChecklistVO[] = viewModel.Checklists == undefined ? [] : viewModel.Checklists;
  let noticeSource: ChecklistNoticeVO[] = viewModel.Notices == undefined ? [] : viewModel.Notices;

  // 指定月のデータ取得Api呼び出し
  const selectedMonthOnGet = (url: string, month: number) => {
    // WebAPI一覧のURLを作成
    var encodedUrl = [url, encodeURI(month.toString())].join('/');
    console.log(`encodedUrl: ${encodedUrl}`);

    // GET呼び出し
    axios
      .get<ChecklistViewModel>(encodedUrl)
      .then(response => {
        updateViewModel(response.data);
      })
      .catch(error => {
        console.log(`GET通信エラー発生GET[ ${error} ]`);
        setGlobalErrorMessage(error.message);
      });
  };

  const saveChecklistOnPost = (url: string, month: number) => {
    // WebAPI一覧のURLを作成
    var encodedUrl = [url, encodeURI(month.toString())].join('/');
    console.log(`encodedUrl: ${encodedUrl}`);

    // POST呼び出し
    axios
      .post(encodedUrl, {
        data: [viewModel.Checklists, viewModel.Notices]
      })
      .then(response => {
        updateSaveConfirmPopupActivated(false);
        console.log(`posted!`);
        console.log(`responseBody[ ${response} ]`);
      })
      .catch(error => {
        console.log(`POST通信エラー: error[ ${error}]`);
        setGlobalErrorMessage(error.message);
      });
  };

  // 通信処理(GET)呼び出し
  React.useEffect(() => {
    if (selectedMonth === 0) {
      console.log(`初回レンダリング時はGET通信無し`);
      //月指定無し(初回レンダリング)の場合は空VMをセットする
      updateViewModel({ Checklists: [], Notices: [] });
      return;
    }
    // GET呼び出し
    selectedMonthOnGet(getApiBaseUrl, Number(selectedMonth));

    // クリーンナップ(componentWillUnmountに相当)でViewModelを初期化
    return () => updateViewModel({ Checklists: [], Notices: [] });
    // 月指定変更をトリガーに呼び出し
    // クリアボタンイベントはトリガー用カウンタ変更で呼び出し
  }, [selectedMonth, excuteGetActivateCount]);

  // 通信処理(POST)呼び出し
  React.useEffect(() => {
    // デフォ値(=初回レンダリング時)は何もしない
    if (excuteSaveActivateCount == 0) {
      console.log(`初回レンダリング時はPOST通信無し`);
      return;
    }
    saveChecklistOnPost(postApiBaseUrl, Number(selectedMonth));

    // トリガー用カウンタ変更で呼び出し
  }, [excuteSaveActivateCount]);

  // Excel出力ポップアップ表示
  const handleOnExcelClick = () => {
    setExcelPopupActivated(true);
  };
  // Excel出力ポップアップ非表示
  const handleExcelOnClose = () => {
    setExcelPopupActivated(false);
  };

  //フィルターExcel出力設定
  const handleOnFilterChanged = (filterJson: string) => {
    setfilters(filterJson);
  };

  // 閉じる
  const handleOnCloseClick = () => {
    // 画面を閉じてメニューに戻る
    props.onClose();
  };

  // 月指定クリック:指定月のチェックリストを取得して表示する
  const handleOnMonthChange = (nMonth: number[]) => {
    console.log(`月指定ボタンクリック[ ${nMonth[0]} ]`);
    // 指定月の情報GET実行
    updateSelectedMonth(nMonth[0]);
  };

  //特記事項編集後のコールバック
  const handleOnChangeItem = (data: Notice[]) => {
    console.log(`特記事項追加/削除/編集`);
  };

  // クリアボタン
  const handleOnClearClick = () => {
    console.log(`クリアボタンクリック`);
    // クリア確認ポップアップ
    updateClearConfirmPopupActivated(true);
    console.log(`編集内容クリア`);
  };

  // クリア確認ポップアップYES時処理
  const excuteClear = () => {
    console.log(`クリア処理実行`);
    updateExcuteGetActivateCount(excuteGetActivateCount => excuteGetActivateCount + 1);
    updateClearConfirmPopupActivated(false);
  };

  const COMMENT_BODY_MAX_LENGTH = 200;
  const NOTICE_BODY_MAX_LENGTH = 500;
  // 検証
  const validChecklistStatus = () => {
    if (viewModel.Checklists !== undefined) {
      viewModel.Checklists.forEach(Checklist => {
        if (Checklist.Comment !== undefined) {
          if (Checklist.Comment.length > COMMENT_BODY_MAX_LENGTH) {
            console.log(`コメント入力超過: ${Checklist.Comment.length}`);
            setErrorMessage(`コメント桁超過エラー`);
            return;
          }
        }
      });
    }

    if (viewModel.Notices !== undefined) {
      viewModel.Notices.forEach(notice => {
        if (notice.Notice !== undefined) {
          if (notice.Notice.length > NOTICE_BODY_MAX_LENGTH) {
            console.log(`特記事項桁超過: ${notice.Notice.length}`);
            setErrorMessage(`特記事項桁超過エラー`);
            return;
          }
        }
      });
    }
    return errorMessage.length == 0;
  };

  // 保存ボタン
  const handleOnSaveClick = () => {
    console.log(`保存ボタンクリック`);

    if (!validChecklistStatus()) {
      console.log(`入力検証不正: ${errorMessage}`);
      return;
    }
    //確認ポップアップ表示
    updateSaveConfirmPopupActivated(true);
  };

  // 保存確認ポップアップYES時処理
  const excuteSave = () => {
    console.log(`保存処理実行`);
    let checks = viewModel.Checklists == undefined ? 0 : viewModel.Checklists.length;
    let notices = viewModel.Notices == undefined ? 0 : viewModel.Notices.length;

    let Results: string = `チェックリスト項目数[ ${checks} ]`;
    let NoticeResults: string = `特記事項項目数[ ${notices} ]`;
    console.log([Results, NoticeResults].join(`\n`));

    console.log(`excuteSaveActivateCountをインクリメント:登録処理を発火`);
    updateExcuteSaveActivateCount(excuteSaveActivateCount => excuteSaveActivateCount + 1);
  };

  // チェックリストGrid列定義
  const columns = [
    {
      header: labels.AIKADT002000005_GRID_HEADER_NO,
      binding: 'No',
      isReadOnly: true,
      width: 60,
      align: 'center'
    },
    {
      header: labels.AIKADT002000005_GRID_HEADER_CONFIRMATION,
      binding: 'ConfFlg',
      isReadOnly: false,
      width: 60,
      align: 'center'
    },
    {
      header: labels.AIKADT002000005_GRID_HEADER_CHECKITEMS,
      binding: 'CklContent',
      isReadOnly: true,
      width: '*',
      minWidth: 200
    },
    {
      header: labels.AIKADT002000005_GRID_HEADER_COMMENT,
      binding: 'Comment',
      isReadOnly: false,
      width: '*',
      minWidth: 200
    }
  ];

  return (
    <div>
      <LowerActionDoubleContentsLayout className='vw-100'>
        <LowerActionDoubleContentsLayoutHeader>
          <PageHeader
            pageTitle={labels.AIKADT002000005_FUNCTION_NAME}
            onExcelClick={handleOnExcelClick}
            onCloseClick={handleOnCloseClick}
          >
            {/*TODO fiscalYearはinitialOnGetの再発火用 */}
            <MonthSelect displayMode={MODE.normal} onMonthChange={handleOnMonthChange} fiscalYear={0} />
            {/*TemplateIDの定数対応*/}
            <ExcelOutputsetting
              activated={excelPopupActivated}
              templateId='AIKADT002000005_E01'
              filters={filters}
              onClose={handleExcelOnClose}
            />
          </PageHeader>
        </LowerActionDoubleContentsLayoutHeader>
        <LowerActionDoubleContentsLayoutAction className='p-3 vw-100'>
          <div className={'d-flex justify-content-between'}>
            <div className={'ml-auto text-red'}>{errorMessage}</div>
            <div className={'ml-4'}>
              <ExecutingCancel
                executeLabel={labels.COMMON_BUTTON_FUNCTION_SAVE}
                onExecuteClick={handleOnSaveClick}
                cancelLabel={labels.COMMON_BUTTON_FUNCTION_CANCEL}
                onCancelClick={handleOnClearClick}
              />
            </div>
          </div>
        </LowerActionDoubleContentsLayoutAction>
        <LowerActionDoubleContentsLayoutContentsTop className='p-3 vw-100'>
          <CommonGrid
            gridClassName='ChecklistSettingList-grid'
            columns={columns}
            sources={gridSource}
            onFilterChanged={handleOnFilterChanged}
          />
          <ConfirmationPopup
            isOpen={saveConfirmPopupActivated}
            onClose={() => updateSaveConfirmPopupActivated(false)}
            onYesClick={excuteSave}
            onNoClick={() => updateSaveConfirmPopupActivated(false)}
          />
          <ConfirmationPopup
            isOpen={clearConfirmPopupActivated}
            onClose={() => updateClearConfirmPopupActivated(false)}
            onYesClick={excuteClear}
            onNoClick={() => updateClearConfirmPopupActivated(false)}
          />
        </LowerActionDoubleContentsLayoutContentsTop>
        <LowerActionDoubleContentsLayoutContentsBottom className='px-3'>
          <Notice className='ChecklistSettingList-notice' notices={noticeSource} onChangeItem={handleOnChangeItem} />
        </LowerActionDoubleContentsLayoutContentsBottom>
      </LowerActionDoubleContentsLayout>
    </div>
  );
};

export default Checklist;
