import {KmkClassEnum} from "../constants/enums";
import {DCKbnEnum} from "../constants/enums";
import {BPKbnEnum} from "../constants/enums";
import {KisyuKbnEnum} from "../constants/enums";
import {TaxKbnEnum} from "../constants/enums";
import {TaxRateEnum} from "../constants/enums";
import {TypeCodeEnum} from "../constants/enums";
/**
 * 勘定科目登録VO ViewModel クラス
 */

export class AccuntRegisterListVO {
    /** 顧問先管理コード */
    public ClientCode?:number;

    /** 年度 */
    public ClientYear?:number;

    /** 顧問先管理ユーザー */
    public ClientUser?:string;

    /** 科目分類 */
    public KmkClass?:KmkClassEnum;

    /** モードフラグ */
    public ModeFlg?:number;

    /** コピー元外部コード */
    public CopyGCode?:number;

    /** マスタ区分 */
    public MasterKbn?:number;

    /** 実在／合計区分 */
    public SumKbn?:number;

    /** レコード区分 */
    public RecordKbn?:number;

    /** 外部コード */
    public GCode?:number;

    /** 科目別補助コード */
    public SubCode?:string;

    /** 連想シンボル */
    public RenChar?:string;

    /** 正式名称 */
    public LongName?:string;

    /** 簡略名称 */
    public SimpleName?:string;

    /** 内部コード（ユニークKey） */
    public NCode?:number;

    /** 更新日時 */
    public UpdateTime?:Date;

    /** 適用開始年月日 */
    public StartDate?:Date;

    /** 適用終了年月日 */
    public EndDate?:Date;

    /** 分析コード */
    public AnalyzeCode?:number;

    /** 要約分析コード */
    public SumAnaCode?:number;

    /** 性格コード */
    public CharCode?:number;

    /** ECharCode1 */
    public ECharCode1?:number;

    /** ECharCode2 */
    public ECharCode2?:number;

    /** ECharCode3 */
    public ECharCode3?:number;

    /** 正残区分 */
    public DCKbn?:DCKbnEnum;

    /** ＢＳ／ＰＬ区分 */
    public BPKbn?:BPKbnEnum;

    /** 期首残区分 */
    public KisyuKbn?:KisyuKbnEnum;

    /** 表示／印刷不可区分 */
    public NonDspKbn?:number;

    /** 比率科目区分 */
    public PerKmkKbn?:number;

    /** 販売採用区分 */
    public KmkKbn1?:number;

    /** 販売入金科目性格 */
    public KmkKbn2?:number;

    /** KmkKbn3 */
    public KmkKbn3?:number;

    /** KmkKbn4 */
    public KmkKbn4?:number;

    /** KmkKbn5 */
    public KmkKbn5?:number;

    /** KmkKbn6 */
    public KmkKbn6?:number;

    /** かんたん！用表示／印刷不可区分 */
    public KmkKbn7?:number;

    /** KmkKbn8 */
    public KmkKbn8?:number;

    /** UseKbn */
    public UseKbn?:number;

    /** 固定体系区分 */
    public KoteiKbn?:number;

    /** 借方資金繰コード */
    public DebitCash?:number;

    /** 貸方資金繰コード */
    public CreditCash?:number;

    /** 消費税科目区分 */
    public TaxKbn?:TaxKbnEnum;

    /** 売上消費税コード */
    public STaxCode?:number;

    /** 売上消費税率 */
    public STaxRate?:TaxRateEnum;

    /** 仕入消費税コード */
    public BTaxCode?:number;

    /** 仕入消費税率 */
    public BTaxRate?:TaxRateEnum;

    /** 消費税コード */
    public TaxCode?:number;

    /** 消費税率 */
    public TaxRate?:TaxRateEnum;

    /** 業種コード */
    public TypeCode?:TypeCodeEnum;

    /** TaxKbn2 */
    public TaxKbn2?:number;

    /** TaxKbn3 */
    public TaxKbn3?:number;

    /** TaxKbn4 */
    public TaxKbn4?:number;

    /** TaxKbn5 */
    public TaxKbn5?:number;

    /** TaxKbn6 */
    public TaxKbn6?:number;

    /** 科目別補助採用区分 */
    public SubKmkUse?:number;

    /** 第１補助採用区分 */
    public HojyoUse1?:number;

    /** 第２補助採用区分 */
    public HojyoUse2?:number;

    /** 部門採用区分 */
    public BmnUse?:number;

    /** セグメント１採用区分 */
    public SegUse1?:number;

    /** セグメント２採用区分 */
    public SegUse2?:number;

    /** セグメント３採用区分 */
    public SegUse3?:number;

    /** セグメント４採用区分 */
    public SegUse4?:number;

    /** 工事採用区分 */
    public KoujiUse?:number;

    /** KUseKbn1 */
    public KUseKbn1?:number;

    /** KUseKbn2 */
    public KUseKbn2?:number;

    /** KUseKbn3 */
    public KUseKbn3?:number;

    /** セグメント５採用区分 */
    public KUseKbn4?:number;

    /** セグメント１別第1補助集計区分 */
    public KUseKbn5?:number;

    /** セグメント１別第2補助集計区分 */
    public KUseKbn6?:number;

    /** 部門別第1補助集計区分 */
    public BHSumKbn1?:number;

    /** 部門別第2補助集計区分 */
    public BHSumKbn2?:number;

    /** 仕訳入力区分 */
    public InputKbn?:number;

    /** 期日入力区分 */
    public DInputKbn?:number;

    /** 期日必須入力区分 */
    public DInputAlw?:number;

    /** 手形番号入力区分 */
    public TInputKbn?:number;

    /** 手形番号必須入力区分 */
    public TInputAlw?:number;

    /** 手形管理ＮＯ入力区分 */
    public TMInputKbn?:number;

    /** 手形管理ＮＯ必須入力区分 */
    public TMInputAlw?:number;

    /** 番号１入力区分 */
    public NoInputKbn1?:number;

    /** 番号１必須入力区分 */
    public NoInputAlw1?:number;

    /** 番号２入力区分 */
    public NoInputKbn2?:number;

    /** 番号２必須入力区分 */
    public NoInputAlw2?:number;

    /** EInputKbn1 */
    public EInputKbn1?:number;

    /** EInputAlw1 */
    public EInputAlw1?:number;

    /** EInputKbn2 */
    public EInputKbn2?:number;

    /** EInputAlw2 */
    public EInputAlw2?:number;

    /** 固定摘要必須入力 */
    public TkInputKbn?:number;

    /** 摘要分類コード */
    public TkClassCode?:number;

    /** 小数点桁数 */
    public DPointNum?:number;

    /** 資金繰コード優先区分 */
    public CashCodeKbn?:number;

    /** 番号1初期表示区分 */
    public EInpKbn1?:number;

    /** 番号2初期表示区分 */
    public EInpKbn2?:number;

    /** 同一番号集計区分 */
    public EInpKbn3?:number;

    /** EInpKbn4 */
    public EInpKbn4?:number;

    /** EInpKbn5 */
    public EInpKbn5?:number;

    /** 科目外部コード */
    public DupCodeN4?:number;

    /** 外部コード（4桁コード） */
    public DupCode4?:number;

    /** 外部コード（3桁コード） */
    public DupCode3?:number;

    /** 銀行コード */
    public BankCode?:string;

    /** 特定部署コード */
    public SectionCode?:string;

    /** 内部コード２ */
    public NCode2?:number;

    /** 番号１名称No. */
    public NoName1?:number;

    /** 番号２名称No. */
    public NoName2?:number;

    /** 番号３～10入力区分 */
    public NoInputKbn3?:number;

    /** 番号３～10必須入力区分 */
    public NoInputAlw3?:number;

    /** 削除NGフラグ */
    public DeleteNgFlag?:boolean;

}
