import React from 'react';
import classnames from 'classnames';
import { ButtonProps, Button } from 'reactstrap';
import CheckCircle from '../../../atoms/Icon/CheckCircle';

export type auditExecutionButtonProps = ButtonProps & {
  isAuditExecution?: boolean;
  remain?: number;
};

const defaultButtonClass = 'audit-execution-btn px-0 py-0 mr-2';
const AuditExecutionButton: React.FC<auditExecutionButtonProps> = props => {
  const { isAuditExecution, remain, className, children } = props;
  const checkIcon = isAuditExecution ? <CheckCircle /> : '';
  // Buttonタグに不要なpropsを除去
  const buttonProps = { ...props };
  delete buttonProps['isAuditExecution'];
  delete buttonProps['remain'];
  return (
    <Button {...buttonProps} className={classnames(className, defaultButtonClass)}>
      {children}
      <span className='btn-mark text-white'>
        ({remain}) {checkIcon}
      </span>
    </Button>
  );
};
export default AuditExecutionButton;
