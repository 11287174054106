import React from 'react';
import * as Labels from '../../../constants/labels';

import Popup, { popupProps } from '../CMN/Popup/Popup';
import { Progress } from 'reactstrap';

export type PBPopupProps = popupProps & {
  prgMessage?: string;
  prgRate?: number;
};

const ProgressBarPopup: React.FC<PBPopupProps> = props => {
  const ProgressBar = (
    <>
      <div className='flex-grow-1'>
        <label className='small'>{props.prgMessage ? props.prgMessage : ''}</label>
        <div className='d-flex'>
          <Progress
            className='w-75'
            value={props.prgRate ? props.prgRate : 0}
            color='success'
            striped={true}
            animated={true}
          />
          <label className='w-25'>{props.prgRate ? props.prgRate + '%' : ''}</label>
        </div>
      </div>
    </> );

  const popupProps = { ...props }
  delete popupProps['prgMessage'];
  delete popupProps['prgRate'];

  return (
  <Popup
    {...popupProps}
    header={Labels.AIKADT002000001_BUTTON_FUNCTION_BATCHEXECUTION}
  >
    <div>{ProgressBar}</div>
  </Popup>
  );
};
export default ProgressBarPopup;