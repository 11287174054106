import React from 'react';
import store from 'store2';

import * as wjGrid from 'wijmo/wijmo.grid';
import { FlexGrid } from 'wijmo/wijmo.react.grid';
import * as wjFilter from 'wijmo/wijmo.grid.filter';
import { FlexGridFilter } from 'wijmo/wijmo.react.grid.filter';
import * as wjInput from 'wijmo/wijmo.input';
import { ComboBox } from 'wijmo/wijmo.react.input';

import { convertItemsSourceToDateType } from '../../../../utils/wijmoGridUtils';
import * as dataMaps from '../../../../constants/dataMaps';
import * as enums from '../../../../constants/enums';
import * as labels from '../../../../constants/labels';
import { ExecutionResultViewModel } from '../../../../models/executionResultViewModel';
import { BalanceListVO } from '../../../../models/balanceListVO';
import { SortDescription } from 'wijmo/wijmo';
import { Label, Input, FormGroup } from 'reactstrap';

type AuditResultSummaryGridProp = {
  viewModel: ExecutionResultViewModel;
  gridClassName?: string;
  tabIndex?: number;
  bpKbnTabIndex?: number;
  onRemarkBadgeClick: (code: number, seqNo: number[]) => void;
  onNegativeBadgeClick: (code: number, seqNo: number[]) => void;
  onJournalBadgeClick: (code: number, seqNo: number[]) => void;
  onDuplicateBadgeClick: (code: number, seqNo: number[]) => void;
  onSelectScreenType: (screenType: number) => void;
  selectScreenType: number;
  onExciseBadgeClick: (code: number, SeqNo: number[]) => void;
  onFilterChanged?: (filterJson: string) => void;
  gridRef?: React.RefObject<any>;
};
type AuditResultSummaryGridState = {
  itemsSource: BalanceListVO[];
  bpKbn: enums.BPEnum;
};

const remarkBadge = (journalCode: number, seqNo: number[], count: number, tabIndex?: number) =>
  `<a class='badge badge-pill badge-RemarkIncDecAudit text-white cursor-pointer' onclick='window.handleRemarkBadgeClick(${journalCode}, [${seqNo}])' ${
    typeof tabIndex === 'undefined' ? '' : 'tabIndex=' + tabIndex + ' href="javascript:void(0);"'
  }>${count}</a>`;
const negativeBadge = (journalCode: number, seqNo: number[], count: number, tabIndex?: number) =>
  `<a class='badge badge-pill badge-NegativeBalanceAudit text-white cursor-pointer' onclick='window.handleNegativeBadgeClick(${journalCode}, [${seqNo}])' ${
    typeof tabIndex === 'undefined' ? '' : 'tabIndex=' + tabIndex + ' href="javascript:void(0);"'
  }>${count}</a>`;
const journalBadge = (journalCode: number, seqNo: number[], count: number, tabIndex?: number) =>
   `<a class='badge badge-pill badge-JournalLineAudit text-white cursor-pointer' onclick='window.handleJournalBadgeClick(${journalCode}, [${seqNo}])' ${
    typeof tabIndex === 'undefined' ? '' : 'tabIndex=' + tabIndex + ' href="javascript:void(0);"'
  }>${count}</a>`;
const duplicateBadge = (journalCode: number, seqNo: number[], count: number, tabIndex?: number) =>
  `<a class='badge badge-pill badge-JournalDoubleAudit text-white cursor-pointer' onclick='window.handleDuplicateBadgeClick(${journalCode}, [${seqNo}])' ${
    typeof tabIndex === 'undefined' ? '' : 'tabIndex=' + tabIndex + ' href="javascript:void(0);"'
  }>${count}</a>`;
const exciseBadge = (journalCode: number, seqNo: number[], count: number, tabIndex?: number) =>
  `<a class='badge badge-pill badge-TaxKubunAudit text-white cursor-pointer' onclick='window.handleExciseBadgeClick(${journalCode}, [${seqNo}])' ${
    typeof tabIndex === 'undefined' ? '' : 'tabIndex=' + tabIndex + ' href="javascript:void(0);"'
  }>${count}</a>`;
declare global {
  interface Window {
    handleRemarkBadgeClick: (code: number, seqNo: number[]) => void;
    handleNegativeBadgeClick: (code: number, seqNo: number[]) => void;
    handleJournalBadgeClick: (code: number, seqNo: number[]) => void;
    handleDuplicateBadgeClick: (code: number, seqNo: number[]) => void;
    handleExciseBadgeClick: (code: number, seqNo: number[]) => void;
    handleSelectActivateScreenType: (screenType: number) => void;
  }
}

const badgeMap = [
  { key: true, value: labels.AIKADT002000001_GRID_SORT_EXISTS },
  { key: false, value: labels.AIKADT002000001_GRID_SORT_NONE }
];

// フィルタ情報のテンポラリー
const filterDefinitionStoreKey = 'filterAuditResultSummaryGrid';

// フィルタ情報のテンポラリー
type filterDefinitionStore = {
  filterDefinition: string;
  code: number;
};

// ソート条件のテンポラリー
const sortCriteriaStoreKey = 'sortCriteriaAuditResultSummaryGrid';
// ソート条件のテンポラリー
export type sortCriteriaStore = {
  property?: string;
  ascending?: boolean;
};

// 初期表示時のＢＳ／ＰＬ
const defaultbpKbn = enums.BPEnum.All;

// ＢＳ／ＰＬコンボボックスのID
const bpCmbId = 'audit-result-summar-grid';

class AuditResultSummaryGrid extends React.Component<AuditResultSummaryGridProp, AuditResultSummaryGridState> {
  grid?: wjGrid.FlexGrid;
  filter?: wjFilter.FlexGridFilter;
  isChangeFilterAndSort = false;

  constructor(props: AuditResultSummaryGridProp) {
    super(props);
    this.state = {
      itemsSource: this.getItemSource(this.props.viewModel, defaultbpKbn),
      bpKbn: defaultbpKbn
    };
    const {
      onRemarkBadgeClick,
      onNegativeBadgeClick,
      onJournalBadgeClick,
      onDuplicateBadgeClick,
      onExciseBadgeClick,
      onSelectScreenType
    } = props;
    this.initializedFilter = this.initializedFilter.bind(this);
    this.handleFilterApplied = this.handleFilterApplied.bind(this);
    this.initializedGrid = this.initializedGrid.bind(this);
    this.handleItemFormatter = this.handleItemFormatter.bind(this);
    this.handleGridRefresh = this.handleGridRefresh.bind(this);
    this.setFilterAndSortFromStore = this.setFilterAndSortFromStore.bind(this);
    this.handleSelectedIndexChangedBPKbn = this.handleSelectedIndexChangedBPKbn.bind(this);
    window.handleRemarkBadgeClick = onRemarkBadgeClick;
    window.handleNegativeBadgeClick = onNegativeBadgeClick;
    window.handleJournalBadgeClick = onJournalBadgeClick;
    window.handleDuplicateBadgeClick = onDuplicateBadgeClick;
    window.handleExciseBadgeClick = onExciseBadgeClick;
    window.handleSelectActivateScreenType = onSelectScreenType;
  }

  // 表示するItemSourceを返す
  public getItemSource(viewModel: ExecutionResultViewModel, bpKbn: enums.BPEnum) {
    convertItemsSourceToDateType(columns, viewModel.BalanceList!);
    return bpKbn == enums.BPEnum.All
      ? viewModel.BalanceList!
      : viewModel.BalanceList!.filter(item => item.BPKbn === bpKbn);
  }

  // フィルターの初期化
  public initializedFilter(flexFilter: wjFilter.FlexGridFilter) {
    this.filter = flexFilter;
  }

  // フィルターが変更された時の処理
  public handleFilterApplied(f: wjFilter.FlexGridFilter) {
    if (typeof this.props.onFilterChanged === 'function') {
      this.props.onFilterChanged(f.filterDefinition);
    }
  }

  // Gridの初期化
  public initializedGrid(flexGrid: wjGrid.FlexGrid) {
    this.grid = flexGrid;
  }

  // ItemFormatter
  public handleItemFormatter(panel: wjGrid.GridPanel, row: number, col: number, cell: HTMLElement) {
    // ヘッダ
    if (panel.cellType === wjGrid.CellType.ColumnHeader) {
      cell.classList.add('d-flex');
      cell.classList.add('justify-content-center');
      cell.classList.add('align-items-center');
      cell.classList.add('font-weight-normal');
    } else if (panel.cellType === wjGrid.CellType.Cell) {
      if (this.props.viewModel.BalanceList!.length > 0) {
        const item: BalanceListVO = panel.rows[row].dataItem;
        const column: wjGrid.Column = panel.columns[col];

        const kmkCode = item.KmkCode!;
        const remarkCount = item.RidNgCnt;
        const negativeCount = item.NBNgCnt;
        const journalCount = item.JLNgCnt;
        const duplicateCount = item.JDNgCnt;
        const exciseCount = item.TKNgCnt;


        const remarkSeqNo = this.props.viewModel.RidSeqNo ? this.props.viewModel.RidSeqNo : [0];
        const negativeSeqNo = this.props.viewModel.NBSeqNo ? this.props.viewModel.NBSeqNo : [0];
        const journalSeqNo = this.props.viewModel.JLSeqNo ? this.props.viewModel.JLSeqNo : [0];
        const duplicateSeqNo = this.props.viewModel.JDSeqNo ? this.props.viewModel.JDSeqNo : [0];
        const exciseSeqNo = this.props.viewModel.TKSeqNo ? this.props.viewModel.TKSeqNo : [0];

        if (column.inputType === 'price') {
          const val = panel.grid.getCellData(row, col, false);
          if (val === 0) {
            cell.innerHTML = '';
          } else if (val < 0) {
            cell.classList.add('text-red');
          }
        }

        if (column.binding === 'ConfirmFlg') {
          let html = '';

          if (typeof remarkCount === 'number') {
            html += remarkBadge(kmkCode, remarkSeqNo, remarkCount, this.props.tabIndex);
          }
          if (typeof negativeCount === 'number') {
            html += negativeBadge(kmkCode, negativeSeqNo, negativeCount, this.props.tabIndex);
          }
          if (typeof journalCount === 'number') {
            html += journalBadge(kmkCode, journalSeqNo, journalCount, this.props.tabIndex);
          }
            if (typeof duplicateCount === 'number') {
            html += duplicateBadge(kmkCode, duplicateSeqNo, duplicateCount, this.props.tabIndex);
          }
          if (typeof exciseCount === 'number') {
            html += exciseBadge(kmkCode, exciseSeqNo, exciseCount, this.props.tabIndex);
          }

          cell.innerHTML = html;
        }

        if (item.ConfirmFlg) {
          cell.classList.add('_exists');
        }
      }
    }
  }

  // Gridが更新された時の処理
  public handleGridRefresh() {
    if (this.filter != null) {
      let kmkNameFilter = this.filter.getColumnFilter('KmkName');
      let kmkNameMapList = this.state.itemsSource!.map(item => item.KmkName);
      kmkNameFilter.valueFilter.sortValues = false;
      kmkNameFilter.valueFilter.uniqueValues = kmkNameMapList;
    }
  }

  // storeにフィルタ情報が存在する場合にそのフィルタ情報を設定する
  private setFilterAndSortFromStore() {
    if (this.filter != null && this.grid != null) {
      if (this.isChangeFilterAndSort === true) {
        this.isChangeFilterAndSort = false;
        const filterDefinitionStore = getFilterDefinition();
        if (filterDefinitionStore) {
          removeFilterDefinition();

          const { filterDefinition } = filterDefinitionStore;

          // フィルターを設定
          this.filter.filterDefinition = filterDefinition;
          this.filter.apply();
        }

        const sortCriteriaStore = getSortCriteria();
        if (sortCriteriaStore) {
          removeSortCriteria();
          if (sortCriteriaStore.property != undefined && sortCriteriaStore.ascending != undefined) {
            var view = this.grid.collectionView;
            // ソートを設定
            view.deferUpdate(() => {
              view.sortDescriptions.clear();
              view.sortDescriptions.push(
                new SortDescription(sortCriteriaStore.property!, sortCriteriaStore.ascending!)
              );
            });
          }
        }
      }
    }
  }

  // ＢＳ／ＰＬコンボボックスが変更された時の処理
  public handleSelectedIndexChangedBPKbn(cmb: wjInput.ComboBox) {
    const bpKbn = cmb.selectedItem.key;
    this.setState({ itemsSource: this.getItemSource(this.props.viewModel, bpKbn), bpKbn: bpKbn });
  }

  public componentDidMount() {
    if (this.props.bpKbnTabIndex) {
      // WijmoのコンボボックスにTabIndexを設定してもTab遷移がうまくいかない為
      // コンボボックス内のテキストボックスにTabIndexを設定する
      const bpCmbElement = document.getElementById(bpCmbId);
      if (bpCmbElement) {
        const textElement = bpCmbElement.getElementsByTagName('input');
        const elements = Array.from(textElement);
        for (let item of elements) {
          if (item.type === 'text') {
            item.tabIndex = this.props.bpKbnTabIndex;
            break;
          }
        }
      }
    }
  }

  public shouldComponentUpdate(nextProps: AuditResultSummaryGridProp, nextState: AuditResultSummaryGridState) {
    if (this.props.viewModel !== nextProps.viewModel) {
      // viewModelが変更された場合はステータスを更新する
      this.isChangeFilterAndSort = true;
      this.setState({
        itemsSource: this.getItemSource(nextProps.viewModel, this.state.bpKbn)
      });
      return false;
    }
    return true;
  }

  public componentDidUpdate() {
    // フィルタとソートを設定
    this.setFilterAndSortFromStore();
  }

  public render() {
    const screenTypeChecked = (value: number) => value === Number(this.props.selectScreenType || enums.ActivateScreenEnum.Result);
    // 表示方法切り替えラジオボタン要素
    const screenTypeRadio: React.ReactElement[] = [];
    dataMaps.ActivateScreenEnum.forEach(screenType => {
      screenTypeRadio.push(
        <FormGroup check inline className='journal-view-radio' key={['screenType', screenType.key].join('_')}>
          <Label check>
            <Input
              type='radio'
              name='screenType'
              checked={screenTypeChecked(screenType.key)}
              onClick={() => window.handleSelectActivateScreenType(screenType.key)}
            />
            <span>{screenType.value}</span>
          </Label>
        </FormGroup>
      );
    });
    return (
      <span className='audit-result-summar-grid'>
        <div className="d-inline">
          <ComboBox
            id={bpCmbId}
            itemsSource={dataMaps.BPEnum}
            displayMemberPath={'value'}
            selectedValuePath={'key'}
            selectedValue={this.state.bpKbn}
            className='bpkbun mb-3'
            selectedIndexChanged={this.handleSelectedIndexChangedBPKbn}
          />
        </div>
        <div className="d-inline journal-view-label">仕訳表示</div>
        <div className="journal-view-group" tabIndex={(this.props.tabIndex || 0) - 1}>
          <div className="journal-view-fix">
            {screenTypeRadio}
          </div>
        </div>
        <FlexGrid
          className={this.props.gridClassName}
          autoGenerateColumns={false}
          columns={columns}
          headersVisibility={wjGrid.HeadersVisibility.Column}
          isReadOnly={true}
          selectionMode={wjGrid.SelectionMode.None}
          allowResizing={wjGrid.AllowResizing.None}
          allowDragging={wjGrid.AllowDragging.None}
          alternatingRowStep={0}
          frozenColumns={2}
          itemFormatter={this.handleItemFormatter}
          itemsSource={this.state.itemsSource}
          refreshed={this.handleGridRefresh}
          initialized={this.initializedGrid}
          ref={this.props.gridRef}
        >
          <FlexGridFilter initialized={this.initializedFilter} filterApplied={this.handleFilterApplied} />
        </FlexGrid>
      </span>
    );
  }
}

/**
 * フィルタ情報をストアに設定します。
 * @param filterDefinition フィルタ情報
 */
export const setFilterDefinition = (filterDefinition: filterDefinitionStore) => {
  store.session.set(filterDefinitionStoreKey, filterDefinition);
};

/**
 * ストアからフィルタ情報を取得します。
 */
export const getFilterDefinition = () => {
  return store.session.has(filterDefinitionStoreKey)
    ? (store.session.get(filterDefinitionStoreKey) as filterDefinitionStore)
    : undefined;
};

/**
 * ストアからフィルタ情報を削除します。
 */
export const removeFilterDefinition = () => {
  if (store.session.has(filterDefinitionStoreKey)) {
    store.session.remove(filterDefinitionStoreKey);
  }
};

/**
 * ソート条件をストアに設定します。
 * @param sortCriteria ソート条件
 */
export const setSortCriteria = (sortCriteria: sortCriteriaStore) => {
  store.session.set(sortCriteriaStoreKey, sortCriteria);
};

/**
 * ストアからソート条件を取得します。
 */
export const getSortCriteria = () => {
  return store.session.has(sortCriteriaStoreKey)
    ? (store.session.get(sortCriteriaStoreKey) as sortCriteriaStore)
    : undefined;
};

/**
 * ストアからソート条件を削除します。
 */
export const removeSortCriteria = () => {
  if (store.session.has(sortCriteriaStoreKey)) {
    store.session.remove(sortCriteriaStoreKey);
  }
};

export default AuditResultSummaryGrid;

// ヘッダー情報
const columns = [
  {
    header: labels.AIKADT002000001_GRID_HEADER_CODE,
    binding: 'KmkGCode',
    width: 80,
    align: 'center',
    format: 'd'
  },
  {
    header: labels.AIKADT002000001_GRID_HEADER_SUBJECTS,
    binding: 'KmkName',
    width: '*',
    minWidth: 120
  },
  {
    header: labels.AIKADT002000001_GRID_HEADER_EXECUTIONRESULT,
    binding: 'ConfirmFlg',
    width: 180,
    dataMap: new wjGrid.DataMap(badgeMap, 'key', 'value'),
    align: 'right'
  },
  {
    header: labels.AIKADT002000001_GRID_HEADER_PREVIOUSMONTHBALANCE,
    binding: 'LastBalance',
    width: 150,
    align: 'right',
    inputType: 'price'
  },
  {
    header: labels.AIKADT002000001_GRID_HEADER_DEBTORTOTAL,
    binding: 'ThisDebit',
    width: 150,
    align: 'right',
    inputType: 'price'
  },
  {
    header: labels.AIKADT002000001_GRID_HEADER_CREDITTOTAL,
    binding: 'ThisCredit',
    width: 150,
    align: 'right',
    inputType: 'price'
  },
  {
    header: labels.AIKADT002000001_GRID_HEADER_CURRENTMONTHBALANCE,
    binding: 'ThisBalance',
    width: 150,
    align: 'right',
    inputType: 'price'
  }
];
