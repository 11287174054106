import { Util } from "../util/Util";

/**
 * Setインタフェース
 */
export interface ISet<K, V> {
    key: K;
    value: V;
}

/**
 * HashMapクラス
 *
 * [概要]
 * key, value方式で値を保持する
 *
 * [仕様]
 * ・キーでオブジェクトを保持する
 * ・同一キーで複数の値を保持できない（後書き優先）
 * ・ジェネリックで保持する型が決まるので、型に親子関係がない場合は別インスタンスが必要
 *
 * [使用例]
 *
        var map = new HashMap<string, Cell>();  // インスタンス化
        var c1 = new Cell("KASHIKATA");         // マップするオブジェクトの生成
        var c2 = new Cell("KARIKATA", 5);
        var c3 = new Cell("TEKIYOU", 5);
        map.add("111", c1);                     // マップに追加
        map.add("222", c2);
        map.add("222", c3);
        map.get("111").getCellId();             // マップからキーを指定して取得するabstract 
        map.get("222").getCellId();
        map.size();                             // マップされた数を取得する
        map.remove("111");                      // キーを指定して要素を削除する
        map.toString();                         // マップの内容を出力する（主にデバッグ用）
        map.containsKey("111");                 // キーの存在確認
    * 
    */
export class HashMap<K, V> {

    /** オブジェクト保持用 */
    private tables: { [key: string]: ISet<string, V> };

    constructor() {
        this.tables = {};
    }

    /**
     * キーを指定してオブジェクトを取得する
     * @param key
     */
    public get(key: string): V {
        const pair: ISet<string, V> = this.tables['$' + key];
        if (Util.isUndefined(pair)) {
            return null!;
        }
        return pair.value;
    }

    /**
     * キーを指定してオブジェクトを保持する
     * 同一キーの場合、上書きされるので注意してください
     * @param key
     * @param value
     */
    public add(key: string, value: V): V {
        let ret: V;
        const k = '$' + key;
        const old: ISet<string, V> = this.tables[k];
        if (Util.isUndefined(old)) {
            ret = undefined!;
        } else {
            ret = old.value;
        }

        this.tables[k] = {
            key: key,
            value: value
        };

        return ret;

    } 

    /**
     * キーを指定して該当オブジェクトを削除する
     * @param key
     */
    public remove(key: string) {
        const pair = this.tables["$" + key];
        if (!Util.isUndefined(pair)) {
            delete this.tables["$" + key];
        }
    }

    /**
     * 指定したキーでマップされているか確認する
     * @param key
     * @return true: 存在する、false: 存在しない
     */
    public containsKey(key: string): boolean {
        return this.get(key) != null;
    }

    /**
     * マップされているキーの数を返す
     */
    public size(): number {
        return Object.keys(this.tables).length;
    }

    /**
     * マップ内容を精査する
     * @param callback
     */
    protected forEach(callback: (key: string, value: V) => any): void {
        for (const name in this.tables) {
            const pair: ISet<string, V> = this.tables[name];
            const ret = callback(pair.key, pair.value);
            if (ret === false) {
                return;
            }
        }
    } 

    /**
     * マップ内容を文字列で取得する
     */
    public toString(): string {
        let toret = '{';
        this.forEach((k, v) => {
            toret += `\n\t${k} : ${v}`;
        });
        return toret + '\n}'; 

    }
}
