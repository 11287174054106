import {AuditResultJournalDoubleVO} from "./auditResultJournalDoubleVO";
/**
 * 監査結果(仕訳重複)ViewModel ViewModel クラス
 */

export class AuditResultJournalDoubleViewModel {
    /** 監査結果(仕訳重複)リストVO */
    public Results?:Array<AuditResultJournalDoubleVO>;

    /** 対象月 */
    public TMonth?:string;

    /** 最終実行日時 */
    public LastExecDate?:Date;

}
