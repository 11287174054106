
/*
 *共通
 */
 /** 共通/エラー/使用不可プログラム */	
export const Common_Error_UnavailableProgram = "ページが表示できません\n指定された処理の使用権限がないため、ページが表示できません。\n使用権限につきましては、会計事務所へお問い合わせください。";

/** 共通/エラー/排他/更新済み */	
export const Common_Error_Exclusion_Updated = "該当データはすでに更新済みのため処理できません。";

/** 共通/エラー/排他/更新中 */	
export const Common_Error_Exclusion_Updating = "他で更新中のため処理できません。時間を置いてもう一度お試しください。";

/** 共通/エラー/データなし */	
export const Common_Error_NoData = "データがありません。";

/** 共通/エラー/禁止文字 */	
export const Common_Error_ProhibitedCharacter = "{0}に入力禁止文字が含まれています。";

/** 共通/確認/削除 */	
export const Common_Confirmation_Delete = "{0}を削除してもよろしいですか？";

/** 共通/確認/取消 */	
export const Common_Confirmation_Cancel = "編集中のデータを取消します。よろしいですか？";

/** 共通/確認/変更/Excel実行 */	
export const Common_Confirmation_Edited_Excel = "保存されていない項目が存在します。\n保存前の内容で出力されますがよろしいですか？";

/** 共通/確認/変更/Import実行 */	
export const Common_Confirmation_Edited_Import = "保存されていない項目が存在します。\n未保存の内容は破棄されますがよろしいですか？";


/*
 *仕訳重複チェック
 */
 /** 仕訳重複チェック/監査エラー/監査メッセージ */	
export const DuplicationCheck_AuditError_Message = "重複条件に該当する重複仕訳が存在します。";


/*
 *共通
 */
 /** 共通/エラー/不正な対象月 */	
export const Common_Error_InvalidTargetMonth = "対象月は不正です。";

/** 共通/エラー/不正なデータ */	
export const Common_Error_InvalidData = "{0}に不正なデータが含まれています。";


/*
 *月次残高チェック
 */
 /** 月次残高チェック/監査エラー/残高/監査メッセージ */	
export const MonthlyBalanceCheck_AuditError_Balance_Message = "監査対象科目に残高が設定されていません。";


/*
 *共通
 */
 /** 共通/更新不可/ */	
export const Common_Error_NotUpdatable = "対象のデータを更新することはできません。";

/** 共通/削除不可/ */	
export const Common_Error_NotDeletable = "対象のデータを削除することはできません。";

/** 共通/存在チェックエラー/ */	
export const Common_Error_ExistenceCheckError = "更新するデータが存在しません。";

/** 共通/重複チェックエラー/ */	
export const Common_Error_DuplicateCheckError = "登録するデータが重複しています。";

/** 共通/エラー/未チェック */	
export const Common_Error_Unchecked = "{0}を1つ以上選択してください。";

/** 共通/エラー/未設定 */	
export const Common_Error_Unsetting = "{0}を登録してください。";

/** 共通/エラー/仕訳件数超過 */	
export const Common_Error_TooManyShiwake = "選択された月の合計仕訳件数が制限件数({0}件)を超えています。選択月を減らして実施してください。";

/** 共通/エラー/不正な設定 */	
export const Common_Error_InvalidSetting = "{0}の設定が不正です。";

/** 共通/エラー/内部サーバーエラー */	
export const Common_Error_InternalServerError = "内部サーバーエラーが発生しました。";


/*
 *仕訳明細監査
 */
 /** 仕訳明細監査/エラー/ルール未指定 */	
export const JournalLineAudit_Error_RuleUnselected = "仕訳明細監査ルールが選択されていません。";


/*
 *共通
 */
 /** 共通/エラー/不正なGuid */	
export const Common_Error_InvalidGuid = "GUIDが不正です。";


/*
 *仕訳明細監査
 */
 /** 仕訳明細監査/エラー/仕訳明細ルール削除済み */	
export const JournalLineAudit_Error_RuleDeleted = "仕訳明細監査ルールが削除されています。";

/** 仕訳明細監査/エラー/仕訳データなし */	
export const JournalLineAudit_Error_NoJournalLineData = "対象の仕訳データがありません。";


/*
 *共通
 */
 /** 共通/エラー/文字数超過 */	
export const Common_Error_LengthExcess = "{0}は{1}文字以下で入力してください。";


/*
 *Excel出力設定
 */
 /** Excel出力設定/エラー/帳票出力権限 */	
export const ExcelOutputPreference_Error_ReportOutputPermission = "帳票を出力する権限がありません。";


/*
 *共通
 */
 /** 共通/エラー/選択数制限 */	
export const Common_Error_TooManySelect = "一度に選択する{0}数が多すぎます。{1}件以内となるように指定後再度実行してください。";


/*
 *消費税区分チェック
 */
 /** 消費税区分チェック/監査エラー/借方/監査メッセージ */	
export const TaxKbnCheck_AuditError_Debtor_Message = "科目に借方税区分が異なる仕訳が存在します";

/** 消費税区分チェック/監査エラー/貸方/監査メッセージ */	
export const TaxKbnCheck_AuditError_Credit_Message = "科目に貸方税区分が異なる仕訳が存在します";


/*
 *月次残高チェック
 */
 /** 月次残高チェック/監査エラー/月度/監査メッセージ */	
export const MonthlyBalanceCheck_AuditError_Month_Message = "比較対象月のデータが未設定です";


/*
 *共通
 */
 /** 共通/エラー/重複データ */	
export const Common_Error_DuplicateData = "{0}に重複データが存在します";

/** 共通/エラー/月次情報 */	
export const Common_Error_MonthlyInformation = "月次情報が存在しません";


/*
 *HTTP
 */
 /** HTTP/エラー/不正なリクエスト(400)/タイトル */	
export const Http_Error_BadRequest_Title = "不正なリクエスト";

/** HTTP/エラー/不正なリクエスト(400)/メッセージ */	
export const Http_Error_BadRequest_Message = "不正なリクエストが検出されました。";

/** HTTP/エラー/認証に失敗(401)/タイトル */	
export const Http_Error_Unauthorixed_Title = "認証に失敗";

/** HTTP/エラー/認証に失敗(401)/メッセージ */	
export const Http_Error_Unauthorixed_Message = "ユーザー認証に失敗しました。";

/** HTTP/エラー/アクセス拒否(403)/タイトル */	
export const Http_Error_Forbidden_Title = "アクセス拒否";

/** HTTP/エラー/アクセス拒否(403)/メッセージ */	
export const Http_Error_Forbidden_Message = "アクセスが拒否されました。";

/** HTTP/エラー/存在不明(404)/タイトル */	
export const Http_Error_NotFound_Title = "存在不明";

/** HTTP/エラー/存在不明(404)/メッセージ */	
export const Http_Error_NotFound_Message = "ページが見つかりませんでした。";

/** HTTP/エラー/タイムアウト(408)/タイトル */	
export const Http_Error_RequestTimeout_Title = "タイムアウト";

/** HTTP/エラー/タイムアウト(408)/メッセージ */	
export const Http_Error_RequestTimeout_Message = "通信がタイムアウトしました。";

/** HTTP/エラー/衝突(409)/タイトル */	
export const Http_Error_Conflict_Title = "衝突";

/** HTTP/エラー/衝突(409)/メッセージ */	
export const Http_Error_Conflict_Message = "リクエストは現在の状態のリソースと矛盾するため完了できません。";

/** HTTP/エラー/URIが大きい(414)/タイトル */	
export const Http_Error_RequestURITooLarge_Title = "URIが大きすぎ";

/** HTTP/エラー/URIが大きい(414)/メッセージ */	
export const Http_Error_RequestURITooLarge_Message = "リクエストURIが長すぎるため拒否しました。";

/** HTTP/エラー/サーバー内部エラー(500)/タイトル */	
export const Http_Error_InternalServerError_Title = "サーバー内部エラー";

/** HTTP/エラー/サーバー内部エラー(500)/メッセージ */	
export const Http_Error_InternalServerError_Message = "リクエストの実行に失敗しました。";

/** HTTP/エラー/サービス利用不可(503)/タイトル */	
export const Http_Error_ServiceUnavailable_Title = "サービス利用不可";

/** HTTP/エラー/サービス利用不可(503)/メッセージ */	
export const Http_Error_ServiceUnavailable_Message = "サーバーの一時的な過負荷あるいはメインテナンスのため、現在、リクエストを扱うことができません。";

/** HTTP/エラー/その他のエラー/タイトル */	
export const Http_Error_OtherError_Title = "サーバーエラー";

/** HTTP/エラー/その他のエラー/メッセージ */	
export const Http_Error_OtherError_Message = "サーバーエラーが発生しました。";


/*
 *月次残高チェック
 */
 /** 月次残高チェック/エラー/著増減チェック対象 */	
export const MonthlyBalanceCheck_Error_TargetRemarkIncDec = "{0}は著増減チェック対象の為、比較対象月と比較値と符号は必須入力となります。";

/** 月次残高チェック/エラー/著増減チェック対象/しきい値 */	
export const MonthlyBalanceCheck_Error_TargetRemarkIncDec_Ski = "{0}は著増減チェック対象の為、しきい値又はしきい値（金額）を入力してください。";

/** 月次残高チェック/エラー/著増減チェック対象/当月 */	
export const MonthlyBalanceCheck_Error_TargetRemarkIncDec_CurrentMonth = "{0}は比較対象月に当月が指定されている場合、しきい値の指定は出来ません。";

/** 月次残高チェック/エラー/著増減チェック対象外 */	
export const MonthlyBalanceCheck_Error_ExcludedRemarkIncDec = "著増減チェック対象外の為、比較対象月と比較値と符号としきい値としきい値金額の指定は出来ません。";

/** 月次残高チェック/確認/変更/パターン変更 */	
export const MonthlyBalanceCheck_Confirmation_Edited_RulePatternkbnChanged = "保存されていない項目が存在します。\n未保存の内容は破棄されますがよろしいですか？";

/** 月次残高チェック/確認/削除/削除 */	
export const MonthlyBalanceCheck_Confirmation_Delete_RulePatternDelete = "パターン{0}に登録した内容を削除します。よろしいですか?";


/*
 *監査実行
 */
 /** 監査実行/警告/未確認 */	
export const AuditExecution_Warning_Unconfirm = "※対象月の監査確認が完了しておりません。\n   監査実行すると、現在の状況が更新されますがよろしいですか？";


/*
 *監査実行（仕訳重複）
 */
 /** 監査実行（仕訳重複）/警告/初期表示 */	
export const AuditExecution_Warning_Default = "※重複監査条件指定項目は監査実行時に自動保存され、\n   次回起動時の初期表示となります。";


/*
 *Excel出力設定
 */
 /** Excel出力設定/エラー/パスワード不一致 */	
export const ExcelOutputPreference_Error_PasswordMismatch = "パスワードとパスワード(確認用)が一致しません。";

/** Excel出力設定/エラー/未入力 */	
export const ExcelOutputPreference_Error_NotInput = "{0}を選択した場合、{1}を入力してください。";

/** Excel出力設定/エラー/パスワード未設定 */	
export const ExcelOutputPreference_Error_PasswordUnset = "パスワード設定が有効の場合、パスワードを設定してください。";


/*
 *著増減監査
 */
 /** 著増減監査/勘定残高監査エラー/監査メッセージ */	
export const RemarkIncDecAudit_AuditError_Message = "「{0}」の勘定残高が{1}比{2}%となり、しきい値である{3}{4}%を超えています。";

/** 著増減監査/補助残高監査エラー/監査メッセージ */	
export const RemarkIncDecAudit_SubAuditError_Message = "「{0}」の補助残高が{1}比{2}%となり、しきい値である{3}{4}%を超えています。";

/** 著増減監査/部門残高監査エラー/監査メッセージ */	
export const RemarkIncDecAudit_BmnAuditError_Message = "「{0}」の「{1}」の勘定残高が{2}比{3}%となり、しきい値である{4}{5}%を超えています。";


/*
 *マイナス残高監査
 */
 /** マイナス残高監査/勘定残高監査エラー/監査メッセージ */	
export const NegativeBalanceAudit_AuditError_Message = "「{0}」の勘定残高がマイナスとなっています。({1})";

/** マイナス残高監査/補助残高監査エラー/監査メッセージ */	
export const NegativeBalanceAudit_SubAuditError_Message = "「{0}」の補助残高がマイナスとなっています。({1})";

/** マイナス残高監査/部門残高監査エラー/監査メッセージ */	
export const NegativeBalanceAudit_BmnAuditError_Message = "「{0}」の「{1}」の勘定残高がマイナスとなっています。({2})";


/*
 *データ取込
 */
 /** データ取込/情報表示/取込中メッセージ */	
export const ImportApi_Information_Message = "会計データ取込中です。\n完了までお待ちください。";

/** データ取込/情報表示/取込中 */	
export const ImportApi_Information_Importing = "データ取込中...";


/*
 *共通
 */
 /** 共通/成功/登録 */	
export const Common_Success_Registration = "{0}を登録しました。";


/*
 *仕訳明細ルール設定
 */
 /** 仕訳明細ルール設定/条件/メッセージ */	
export const JournalLineRuleSetting_Conditions_Message = "[条件{0}]：{1}";


/*
 *NX-PRO情報
 */
 /** NX-PRO情報/エラー/無効なVersion/メッセージ */	
export const NXPro_Error_InvalidVersion_Message = "バージョン情報の取得に失敗しました。";


/*
 *会計事務所
 */
 /** 会計事務所/実行エラー/取込/メッセージ */	
export const ImportOffice_ExcecutionError_Import_Message = "存在しない会計開始年月日が指定されました。会社コード={0} 会計開始年月日={1}";


/*
 *仕訳明細ルール設定
 */
 /** 仕訳明細ルール設定/項目条件/メッセージ */	
export const JournalLineRuleSetting_ConditionItems_Message = "{0}が{1}{2}";

/** 仕訳明細ルール設定/条件No/メッセージ */	
export const JournalLineRuleSetting_ConditionNo_Message = "[条件{0}]";


/*
 *Excel出力設定
 */
 /** Excel出力設定/エラー/不正なパラメーター */	
export const ExcelOutputPreference_Error_InvalidParameter = "パラメーターの設定が不正です。";


/*
 *月次残高チェック
 */
 /** 月次残高チェック/エラー/比較対象月/対象月範囲外 */	
export const MonthlyBalanceCheck_Error_ComparisonTargetMonth_OutOfRange = "比較対象月が設定範囲外となっています。";

/** 月次残高チェック/エラー/増減率しきい値/しきい値範囲外 */	
export const MonthlyBalanceCheck_Error_Threshold_OutOfRange = "しきい値：増減率しきい値が設定範囲外となっています。（範囲：0.00～999.99）";

/** 月次残高チェック/エラー/増減率しきい値/しきい値範囲外 */	
export const MonthlyBalanceCheck_Error_SkiValueSum_OutOfRange = "しきい値（金額）：増減率しきい値（金額）が設定範囲外となっています。（範囲：13桁以内）";


/*
 *共通
 */
 /** 共通/エラー/必須入力項目/入力無し */	
export const Common_Error_RequiredInput_Empty = "{0}は必須入力です。";


/*
 *仕訳明細ルール設定
 */
 /** 仕訳明細ルール設定/エラー/条件指定/0件 */	
export const JournalLineRuleSetting_Error_CheckRule_Zero = "条件が不正です。";

/** 仕訳明細ルール設定/エラー/条件指定/不正行 */	
export const JournalLineRuleSetting_Error_CheckRule_InvalidRow = "条件が不正です。";

/** 仕訳明細ルール設定/エラー/条件指定/未入力 */	
export const JournalLineRuleSetting_Error_CheckRule_Empty = "未入力です。";

/** 仕訳明細ルール設定/エラー/条件指定/桁数エラー */	
export const JournalLineRuleSetting_Error_CheckRule_DigitOver = "桁数が不正です。";


/*
 *監査ルールエクスポート
 */
 /** 監査ルールエクスポート/情報表示/メッセージ */	
export const RuleExport_Information_Message = "仕訳明細ルールと月次残高ルールをエクスポートします。";


/*
 *監査ルールインポート
 */
 /** 監査ルールインポート/エラー/拡張子エラー */	
export const RuleImport_Error_Extension = "拡張子が「xlsx」となっているExcelファイルを選択してください。";

/** 監査ルールインポート/エラー/排他 */	
export const AuditRuleImport_Error_Exclusion = "他で処理中（インポート/監査処理/データ連携）の為、実行できません。時間を置いてもう一度お試しください。";

/** 監査ルールインポート/エラー/存在エラー */	
export const AuditRuleImport_Error_Existence = "{0}：{1}行目の{2}が存在しません。";

/** 監査ルールインポート/エラー/シート/存在エラー */	
export const AuditRuleImport_Error_sheet_Existence = "{0}：対象のシートが存在しません。";

/** 監査ルールインポート/エラー/ヘッダー */	
export const AuditRuleImport_Error_Header = "{0}：ヘッダー情報が正しくありません。";

/** 監査ルールインポート/エラー/未設定エラー */	
export const AuditRuleImport_Error_NotSet = "{0}：{1}行目の{2}が未設定です。";

/** 監査ルールインポート/エラー/数値エラー */	
export const AuditRuleImport_Error_Number = "{0}：{1}行目の{2}は数値で入力してください。";

/** 監査ルールインポート/エラー/小数エラー */	
export const AuditRuleImport_Error_Decimal = "{0}：{1}行目の{2}は0～999.99で入力してください。";

/** 監査ルールインポート/エラー/フラグエラー */	
export const AuditRuleImport_Error_Bool = "{0}：{1}行目の{2}はTRUEまたはFALSEで入力してください。";

/** 監査ルールインポート/エラー/日付エラー */	
export const AuditRuleImport_Error_Date = "{0}：{1}行目の{2}は日付を入力してください。";

/** 監査ルールインポート/エラー/文字列エラー */	
export const AuditRuleImport_Error_String = "{0}：{1}行目の{2}は{3}文字以内で入力してください。";

/** 監査ルールインポート/エラー/部門使用エラー */	
export const AuditRuleImport_Error_BmnUse = "{0}：{1}行目の部門コードは当該科目コードでは使用不可です。";

/** 監査ルールインポート/エラー/重複エラー */	
export const AuditRuleImport_Error_Duplicate = "{0}：{1}行目の{2}と同じ{2}が複数行設定されています。";

/** 監査ルールインポート/エラー/必須入力エラー */	
export const AuditRuleImport_Error_Surely = "{0}：{1}行目の{2}は必須入力となります。";

/** 監査ルールインポート/エラー/入力エラー */	
export const AuditRuleImport_Error_NoInput = "{0}：{1}行目の{2}を入力してください。";

/** 監査ルールインポート/エラー/条件付き指定不可エラー */	
export const AuditRuleImport_Error_CondSpecify = "{0}：{1}行目の{2}場合、{3}指定は出来ません。";

/** 監査ルールインポート/エラー/指定不可エラー */	
export const AuditRuleImport_Error_Specify = "{0}：{1}行目の{2}指定は出来ません。";

/** 監査ルールインポート/エラー/全入力エラー */	
export const AuditRuleImport_Error_AllInput = "{0}：{1}行目の{2}は全て入力してください。";

/** 監査ルールインポート/エラー/入力不可エラー */	
export const AuditRuleImport_Error_NotInput = "{0}：{1}行目の{2}は入力不可です。";

/** 監査ルールインポート/エラー/設定エラー */	
export const AuditRuleImport_Error_Configuration = "{0}：{1}行目のパターン区分:{2}は{3}シートにも設定されています。";

/** 監査ルールインポート/エラー/条件付き入力エラー */	
export const AuditRuleImport_Error_CondInput = "{0}：{1}行目の{2}は{3}で入力してください。";

/** 監査ルールインポート/エラー/同一エラー */	
export const AuditRuleImport_Error_Same = "{0}：{1}行目の{2}と同一のものが存在します。";

/** 監査ルールインポート/エラー/入力禁止エラー */	
export const AuditRuleImport_Error_InputProhibited = "{0}：{1}行目の{2}に入力禁止文字が含まれています。";

/** 監査ルールインポート/エラー/改行コードエラー */	
export const AuditRuleImport_Error_ReturnCode = "{0}：{1}行目の{2}に改行コードが含まれています。";

/** 監査ルールインポート/エラー/DB登録済エラー */	
export const AuditRuleImport_Error_DbRegist = "{0}：{1}行目の{2}は、監査対象:「{3}」として登録済です。{2}を変更するか、{2}を削除してからインポートしてください。";

/** 監査ルールインポート/エラー/パターン区分設定エラー */	
export const AuditRuleImport_Error_ConfigMiss = "{0}：{1}行目 監査対象が「{2}」ですが、該当のパターン区分は{3}シートに設定されています。";

/** 監査ルールインポート/エラー/標準設定フラグエラー */	
export const AuditRuleImport_Error_NormalConfigFlg = "{0}：標準設定フラグは一つだけTRUEを入力して下さい。";

/** 監査ルールインポート/エラー/正常終了 */	
export const AuditRuleImport_Error_SuccessComp = "{0}：{1}件の{2}が正常に終了しました。";

/** 監査ルールインポート/エラー/バージョンエラー */	
export const AuditRuleImport_Error_Version = "監査ルールファイルのバージョンが古いため、エクスポートを行った上でエクスポートファイルを使用してインポートしてください";

/** 監査ルールインポート/エラー/登録データ無エラー */	
export const AuditRuleImport_Error_NotRegist = "{0}：登録データがありません。";


/*
 *共通
 */
 /** 共通/エラー/必須項目/無し */	
export const Common_Error_Required_Empty = "{0}は必須です。";


/*
 *一括監査出力
 */
 /** 一括監査出力/エラー/仕訳重複条件選択無し */	
export const BatchAuditExec_Error_JDNotSet = "※重複監査条件を設定してください。";

/** 一括監査出力/警告/デフォルト */	
export const AuditBatchExecution_Warning_Default = "※監査実行対象のチェックは監査実行時に自動保存され、\n   次回起動時の初期表示となります。";

/** 一括監査出力/エラー/補助/不正な設定 */	
export const AuditBatchExecution_Error_Hojyo_Invalid = "{0}の設定が不正です。";


/*
 *監査報告出力 
 */
 /** 監査報告出力 /情報表示/監査結果/指摘事項なし */	
export const OutPutAuiditReport_Information_Result_Complete = "監査の結果、指摘事項はありませんでした。";

/** 監査報告出力 /情報表示/監査結果/監査実行なし */	
export const OutPutAuiditReport_Information_Result_NoAudit = "監査が実行されていません。";

/** 監査報告出力 /情報表示/監査結果/連携データなし */	
export const OutPutAuiditReport_Information_Result_NoData = "実行月に連携データがありませんでした。";


/*
 *Excel月選択出力設定 
 */
 /** Excel月選択出力設定 /エラー/月度/不正な設定 */	
export const ExcelOutputSettingByMonth_Error_Month_InvalidSetting = "範囲指定が不正です。";


/*
 *システム履歴
 */
 /** システム履歴/警告/件数エラー */	
export const SystemHistory_Warning_CountError = "※検索結果が上限{0}件を超えた為、検索条件を見直して再度検索してください。";

/** システム履歴/エラー/Excel出力件数エラー */	
export const SystemHistory_Error_PrintCountError = "データ件数が{0}件を超えているため出力できません。検索条件でデータ件数を減らし再度実行してください。";


/*
 *担当者アカウント登録・変更機能
 */
 /** 担当者アカウント登録・変更機能/確認/追加時確認 */	
export const TantoManagement_Confirmation_Add = "追加を行う場合は追加を押してください。\nキャンセルを押した場合は元の画面に戻ります。";

/** 担当者アカウント登録・変更機能/確認/削除時確認 */	
export const TantoManagement_Confirmation_Delete = "削除を行う場合は削除を押してください。\nキャンセルを押した場合は元の画面に戻ります。";

/** 担当者アカウント登録・変更機能/成功/追加完了 */	
export const TantoManagement_Success_Add = "担当者を追加しました。";

/** 担当者アカウント登録・変更機能/成功/更新完了 */	
export const TantoManagement_Success_Update = "担当者情報を保存しました。\n担当者の変更内容の反映は、該当担当者が再ログイン後に適用されます。";

/** 担当者アカウント登録・変更機能/成功/削除完了 */	
export const TantoManagement_Success_Delete = "担当者を削除しました。";

/** 担当者アカウント登録・変更機能/エラー/必須項目/無し */	
export const TantoManagement_Error_Required_Empty = "{0}を入力してください。";

/** 担当者アカウント登録・変更機能/エラー/ポリシー */	
export const TantoManagement_Error_Format_PolicyErr = "ログインIDは半角英数字記号で入力してください。";

/** 担当者アカウント登録・変更機能/エラー/重複チェック */	
export const TantoManagement_Error_Conflict_Check = "既に使用されているログインIDです。";


/*
 *会社別担当者設定
 */
 /** 会社別担当者設定/確認/変更確認 */	
export const TantoByCompany_Confirmation_ChangeCancel = "編集中のデータを取消します。よろしいですか？";

/** 会社別担当者設定/情報表示/担当者検索結果0件メッセージ */	
export const TantoByCompany_Information_OfficeStaffNotFound = "担当者が見つかりません。検索情報を変更して再検索をお願いします。";

/** 会社別担当者設定/情報表示/会社絞込み結果0件メッセージ */	
export const TantoByCompany_Information_CompanyNotFound = "会社が見つかりません。絞り込み条件の変更をお願いします。";

/** 会社別担当者設定/成功/更新完了 */	
export const TantoByCompany_Success_Save = "設定を保存しました。";


/*
 *ログイン
 */
 /** ログイン/エラー/ログインエラー */	
export const KanrisyaLogin_Error_LoginError = "ログインIDまたはパスワードが違います。";


/*
 *ユーザー情報の確認・登録機能
 */
 /** ユーザー情報の確認・登録機能/エラー/形式/メールアドレス */	
export const UserInfo_Error_Format_MailAddress = "有効なメールアドレスではありません。";

/** ユーザー情報の確認・登録機能/エラー/確認/メールアドレス */	
export const UserInfo_Error_Confirm_MailAddress = "メールアドレス(確認用)が一致しません。";

/** ユーザー情報の確認・登録機能/成功/更新完了 */	
export const UserInfo_Success_Save = "保存が完了しました。";


/*
 *勘定科目登録
 */
 /** 勘定科目登録/エラー/削除時確認 */	
export const RegisterAccount_Error_DeleteAlready = "{0}は削除されているため、{1}できません。";

/** 勘定科目登録/エラー/削除時確認 */	
export const RegisterAccount_Error_Delete_JournalExist = "{0}は仕訳が登録されているため、削除できません。\n対象科目が無い状態の仕訳CSVを、仕訳・実績データ取込メニューにてデータインポートすることで洗替えされ削除可能になります。\n";

/** 勘定科目登録/エラー/削除時確認 */	
export const RegisterAccount_Error_Delete_BalanceExist = "{0}は残高・実績が登録されているため、削除できません。\n対象科目が無い状態の残高・実績CSVを、仕訳・実績データ取込メニューにてデータインポートすることで洗替えされ削除可能になります。\n";

/** 勘定科目登録/エラー/削除時確認 */	
export const RegisterAccount_Error_Delete_Fixing = "保存されていない項目が存在します。\n保存もしくは破棄してから、削除を実行してください。";

/** 勘定科目登録/エラー/削除完了 */	
export const RegisterAccount_Error_DeleteComplete = "{0}を削除しました。";

/** 勘定科目登録/エラー/追加時確認 */	
export const RegisterAccount_Error_Insert_Duplicate = "{0}は既に登録されているため、登録できません。変更してください。";

/** 勘定科目登録/エラー/追加時確認 */	
export const RegisterAccount_Error_Insert_UsageProhibited = "{0}はシステム使用のコードのため、使用できません。変更してください。";

/** 勘定科目登録/エラー/追加時確認 */	
export const RegisterAccount_Error_Insert_AlreadyDeleted = "{0}は削除されているため、{1}できません。";

/** 勘定科目登録/エラー/追加時確認 */	
export const RegisterAccount_Error_Insert_FrozenTaxKbn = "{0} 消費税科目として設定されているため、科目区分の変更はできません。";

/** 勘定科目登録/エラー/追加時確認 */	
export const RegisterAccount_Error_Insert_InvalidTaxCodeImportKbn0 = "{0} 輸入取引区分が「0:なし」のため、消費税コード「60～74」を設定することはできません。";

/** 勘定科目登録/エラー/追加時確認 */	
export const RegisterAccount_Error_Insert_InvalidTaxCodeProvisionalPayment = "{0} 選択した消費税コードは仮払消費税科目の時のみ設定可能です。";

/** 勘定科目登録/エラー/追加時確認 */	
export const RegisterAccount_Error_Insert_InvalidTaxRate = "{0} 選択した消費税コードでは設定できない消費税率です。";

/** 勘定科目登録/エラー/削除時確認 */	
export const RegisterAccount_Error_Delete_DeleteNg = "{0}はシステム予約の科目のため、削除できません。";

/** 勘定科目登録/エラー/削除時確認 */	
export const RegisterAccount_Error_Insert_FrozenTaxKbnSysReserve = "{0}はシステム予約の科目のため、科目区分の変更はできません。";


/*
 *仕訳・実績データ取込
 */
 /** 仕訳・実績データ取込/エラー/ファイル数上限 */	
export const Migration_Error_DlgAlertMsg_FileCntOver = "指定されたファイルの数が6件を超えるためインポートできません。\nもう一度、手順に従ってエクスポートしてください。";

/** 仕訳・実績データ取込/エラー/ファイルサイズ上限 */	
export const Migration_Error_DlgAlertMsg_FileSizeOver = "指定されたファイルの合計サイズが20MBを超えるためアップロードできません。\n一度に取り込むファイル数を減らして、もう一度アップロードしてください。";

/** 仕訳・実績データ取込/エラー/仕訳インポートエラー */	
export const Migration_Error_DlgAlertMsg_SwkImportEr = "仕訳インポート中に想定外のエラーが発生しました。\nしばらく時間をおいてからお試しください。";

/** 仕訳・実績データ取込/エラー/閉じる確認 */	
export const Migration_Error_DlgConfirmMsg_Close = "画面の内容が変更されていますが保存されていません。\n画面を移動すると入力内容が破棄されます。\nよろしいですか？";

/** 仕訳・実績データ取込/エラー/システム切替 */	
export const Migration_Error_DlgConfirmMsg_ChangeSystem = "移行元のシステムを切り替えると、保存している情報が全て失われます。\nよろしいですか？\n※この処理には数分かかる場合があります。";

/** 仕訳・実績データ取込/エラー/項目設定 */	
export const Migration_Error_DlgConfirmMsg_DeleteItemSetting = "項目の設定を変更すると、既に保存されている設定が全て失われます。\nよろしいですか？\n※この処理には数分かかる場合があります。";

/** 仕訳・実績データ取込/エラー/仕訳インポート */	
export const Migration_Error_DlgConfirmMsg_ConfirmTaxKbn = "金額の設定で税抜きでインポートが設定されていますが、項目に税額が設定されていません。\nこのまま仕訳をインポートすると金額が正しくインポートされない可能性があります。\nよろしいですか？";

/** 仕訳・実績データ取込/エラー/ファイル取込 */	
export const Migration_Error_DlgConfirmMsg_DeleteSetting = "ファイルの追加を行うと、既に取り込んであるファイルと保存されている設定が全て失われます。\nよろしいですか？\n※この処理には数分かかる場合があります。";

/** 仕訳・実績データ取込/エラー/科目設定保存 */	
export const Migration_Error_DlgConfirmMsg_ConfirmSetting = "補助科目の設定を既に保存しています。\n変更する科目に科目別補助がある場合、その科目別補助の設定が初期化されますが、\nよろしいですか？";

/** 仕訳・実績データ取込/エラー/仕訳インポート */	
export const Migration_Error_DlgConfirmMsg_ConfirmSwkImport = "設定が変更されています。\n最後に保存された設定で仕訳のインポートを行います。\nよろしいですか？";

/** 仕訳・実績データ取込/エラー/採用範囲 */	
export const Migration_Error_ExceedNumberAdopted = "勘定科目単位の採用範囲を超えています。これ以上補助が登録できないため、\n現在登録しようとしている補助の登録方法設定を「登録しない」としてください。";

/** 仕訳・実績データ取込/エラー/工事併用 */	
export const Migration_Error_NoCombinationOfSubKmkAndKoji = "科目別補助と工事の併用はできません。現在登録しようとしている科目別補助の登録方法設定を「登録しない」としてください。";

/** 仕訳・実績データ取込/エラー/工事併用 */	
export const Migration_Error_NoCombinationOfCommonHojyoAndKoji = "補助と工事の併用はできません。現在登録しようとしている補助の登録方法設定を「登録しない」としてください。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_SpecialAccountCanNotadoptedForSubKmk = "特殊科目に科目別補助は採用できません。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_SameCommonAdoptionForClient = "同時に取引先、得意先、仕入先を採用することはできません。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_NoCombinationOfBankCodeKmkAndBank = "勘定科目に銀行コードが設定されているため、銀行を採用することはできません。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Warning_dupHojyoAndDupKmkHojyoTitle = "同一名称の補助を同一種類の補助に新規登録する場合、補助は一つしか追加されません。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Warning_sumKisyuTitle = "残高登録する場合は、同一名称の補助の残高が合算されてインポートされます。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_dupHojyoAndDupKmkClientTitle = "同一名称の取引先を同一種類の取引先に新規登録する場合、取引先は一つしか追加されません。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_NotExistMultiAccount = "複合科目が使用不可。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_NotAdopted = "科目コード{0}の科目別補助が採用されていません。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_AutoNumbering = "科目コード{0}の科目別補助コードに空きが存在しません。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_NoOpeningBalanceKmk = "勘定科目の期首残区分がなしの状態となっているため、残高インポートできません。インポートしたい場合は勘定科目登録で該当の勘定科目の期首残区分をありに更新してください。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_KmkNotExist = "勘定科目（{0}）が存在しない、又は削除されたため、残高インポートができません。勘定科目登録で該当の勘定科目が登録されているか確認してください。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_HojyoNotExist = "補助科目（{0}：{1}）が存在しない、又は削除されたため、残高インポートができません。{2}登録で該当の補助が登録されているか確認してください。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_InvalidPeriod = "会計期間外の伝票日付の仕訳は取り込まれません。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_InvalidKmkSetting = "仕訳で使用されている科目が対応付けされていません。もう一度対応付けの設定を行ってください。不明科目が設定されている場合は、その科目に置き換えてインポートされます。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_InvalidHojyoSetting = "仕訳で使用されている{0}が対応付けされていません。もう一度対応付けの設定を行ってください。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_InvalidClosingDate = "決算期間が一致していないため取り込まれません。決算期間が正しいか確認してください。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_ValidateError = "指定されたファイルは{0}インポートできません。もう一度、手順に従ってエクスポートしてください。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_ValidateErrorForOther = "指定されたファイルはファイル種類が一致しないためインポートできません。ファイル名を確認してください。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_FileCombinationError = "{0}をインポートするには、{1}データのファイルが必要です。手順に従って{1}データをエクスポートしてファイルを追加してください。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_DuplicatedFileType = "取込対象が重複しているため、インポートできません。取込対象が重複しないように選択してください。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_ImportFailed = "取り込まれたファイルはエラーのためインポートされませんでした。ファイル内容を見直してください。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_RecordOver = "指定されたファイルの{0}件数が{1}件を超えているためインポートできません。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_ColumnOver = "指定されたファイルの列数が{0}件を超えているためインポートできません。";

/** 仕訳・実績データ取込/進捗/ */	
export const Migration_Progress_Delete = "[{0}月]既存の仕訳を削除しています。";

/** 仕訳・実績データ取込/進捗/ */	
export const Migration_Progress_Create = "[{0}月]仕訳を作成しています。";

/** 仕訳・実績データ取込/進捗/ */	
export const Migration_Progress_Correct = "[{0}月]仕訳を補完しています。";

/** 仕訳・実績データ取込/進捗/ */	
export const Migration_Progress_Check = "[{0}月]仕訳をチェックしています。";

/** 仕訳・実績データ取込/進捗/ */	
export const Migration_Progress_Register = "[{0}月]仕訳を登録しています。";

/** 仕訳・実績データ取込/進捗/ */	
export const Migration_Progress_Completion = "仕訳インポート完了処理中...";

/** 仕訳・実績データ取込/警告/ */	
export const Migration_Warning_SubstituteKmkCode = "{0}勘定科目を不明科目に置き換えました。設定によっては税額がクリアされます。";

/** 仕訳・実績データ取込/警告/ */	
export const Migration_Warning_MappingGetError = "{0}{1}の対応付けの設定が未設定のため、仕訳が作成できませんでした。";

/** 仕訳・実績データ取込/警告/ */	
export const Migration_Warning_MappingGetWarning = "{0}勘定科目の対応付けの設定が未設定のため、不明科目に置き換えました。設定によっては税額がクリアされます。";

/** 仕訳・実績データ取込/警告/ */	
export const Migration_Warning_KmkNotExist = "{0}勘定科目コード（{1}）が存在しません。";

/** 仕訳・実績データ取込/警告/ */	
export const Migration_Warning_ErrorInSameSlip = "この行を含む伝票の更新が行われませんでした。エラー内容については{0}行目を確認してください。";

/** 仕訳・実績データ取込/情報表示/ */	
export const Migration_Information_SwkCheck = "仕訳を確認してください。";

/** 仕訳・実績データ取込/情報表示/ */	
export const Migration_Information_SwkFileCheck = "取込ファイルの仕訳の内容を確認してください。";

/** 仕訳・実績データ取込/情報表示/ */	
export const Migration_Information_SwkErrRecCheck = "インポートがキャンセルされたため、仕訳の更新が行われませんでした。エラー内容についてはエラー行を確認してください。";

/** 仕訳・実績データ取込/情報表示/ */	
export const Migration_Information_Mapping = "対応付けの設定を見直してください。";

/** 仕訳・実績データ取込/情報表示/ */	
export const Migration_Information_KmkMapping = "勘定科目の対応付けの設定を見直してください。";

/** 仕訳・実績データ取込/情報表示/ */	
export const Migration_Information_Error = "{0}が補完できませんでした。";

/** 仕訳・実績データ取込/情報表示/ */	
export const Migration_Information_AutoSet = "{0}が自動セットされました。";

/** 仕訳・実績データ取込/情報表示/ */	
export const Migration_Information_Clear = "{0}がクリアされました。";

/** 仕訳・実績データ取込/情報表示/ */	
export const Migration_Information_SundrySet = "{0}に諸口が自動セットされました。";

/** 仕訳・実績データ取込/情報表示/ */	
export const Migration_Information_ForceSet = "{0}が条件により強制セットされました。";

/** 仕訳・実績データ取込/情報表示/ */	
export const Migration_Information_ForceClear = "{0}が条件により強制クリアされました。";

/** 仕訳・実績データ取込/情報表示/ */	
export const Migration_Information_UnavailableTaxRate = "使用不可税率が使用されているため、仕訳が作成できませんでした。";

/** 仕訳・実績データ取込/情報表示/ */	
export const Migration_Information_OldTaxRate = "旧税率（5.0, 8.0%）が使用されています。仕訳を確認してください。";

/** 仕訳・実績データ取込/警告/ */	
export const Migration_Warning_KmkName = "勘定科目名称が空欄のため、インポートをスキップしました。問題ないか確認してください。";

/** 仕訳・実績データ取込/警告/ */	
export const Migration_Warning_ItemLength = "{0}に{1}文字を超えて設定されているため、インポートをスキップしました。問題ないか確認してください。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_InputSum = "{0}に設定している列の中に、金額以外のものがあります。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_DigitOver = "{0}に設定している列の中に、桁数が超過しているものがあります。桁数は{1}桁以内である必要があります。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_Duplication = "内容が重複する列を{0}に設定することはできません。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_DateEmpty = "日付に設定している列の中に、空欄のものがあります。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_DateFormat = "{0}に設定している列の中に、日付形式に変換できないものがあります。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_Required = "必須項目が設定されていません。項目設定を見直してください。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_Combination = "貸借の組み合わせが正しくありません。項目設定を見直してください。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_ItemDuplication = "項目が重複しています。項目設定を見直してください。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_Solution = "項目設定を見直すか、{0}ファイルを修正してください。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_TaxCode = "消費税コードが設定されていません。消費税コードを見直してください。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_TaxRate = "消費税コードに使用できない消費税率が設定されています。消費税率を見直してください。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_TaxRateNone = "消費税率が設定されていません。消費税率を見直してください。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_TypeCode = "売上以外の消費税区分に業種が設定されています。業種を見直してください。";

/** 仕訳・実績データ取込/エラー/ */	
export const Migration_Error_SixthIndustryAtDate = "平成27年3月31日以前に第６種事業が設定されています。業種を見直してください。";

/** 仕訳・実績データ取込/警告/ */	
export const Migration_Wrning_DenNo = "伝票NOに数値以外が設定されている、又は{0}桁を超過しているため、クリアされました。";

/** 仕訳・実績データ取込/警告/ */	
export const Migration_Warning_NoTaxSum = "消費税額が設定されていません。";


/*
 *共通
 */
 /** 共通/エラー/パスワードポリシー/パスワードエラー */	
export const Common_Error_PasswordPolicy_PasswordErr = "パスワードが正しくありません。";

/** 共通/エラー/パスワードポリシー/パスワードポリシーエラー */	
export const Common_Error_PasswordPolicy_PasswordPolicyErr = "パスワードは8文字以上20文字以内かつ、アルファベットの大文字・小文字を含めてください。";

/** 共通/エラー/パスワードポリシー/パスワード一致エラー */	
export const Common_Error_PasswordPolicy_PasswordMatchErr = "パスワードと確認のパスワードが一致しません。";

/** 共通/成功/パスワード変更/更新完了 */	
export const Common_Success_PasswordChante_Save = "パスワードを変更しました。";

/** 共通/エラー/パスワード変更/パスワードエラー */	
export const Common_Error_PasswordChante_PasswordErr = "パスワードが正しくありません。";

/** 共通/エラー/起動確認/処理起動エラー */	
export const Common_Error_StartCheck_NotUse = "使用できない処理です。";

/** 共通/エラー/起動確認/契約数オーバー */	
export const Common_Error_StartCheck_ContractOver = "作成可能会社データ数を超えて会社データが作成されているため処理できません。";

/** 共通/エラー/起動確認/権限エラー */	
export const Common_Error_StartCheck_Notauthorized = "ご使用のログインIDでは処理できません。";

/** 共通/エラー/起動確認/データ未選択エラー */	
export const Common_Error_StartCheck_NotDataSelect = "会社データを選択してください。";

/** 共通/エラー/起動確認/契約数上限エラー */	
export const Common_Error_StartCheck_ContractEqual = "作成可能な会社データ数に達しているため処理できません。";

/** 共通/エラー/会社リスト取得エラー */	
export const Common_Error_ErrorData = "会社データリストの取得に失敗しました。";

/** 共通/警告/会社リストなし */	
export const Common_Wrning_NoData = "使用できる会社データがありません";

/** 共通/エラー/会社データ切替エラー */	
export const Common_Error_InvalidConpanyCode = "会社コードが不正です。";


/*
 *会社データ作成
 */
 /** 会社データ作成/成功/作成完了 */	
export const CreateCompanyData_Success_Insert_Success = "会社データ作成が完了しました。";

/** 会社データ作成/エラー/作成時確認 */	
export const CreateCompanyData_Error_Insert_AlreadyExist = "入力した会社コードは既に同年度を作成済みです。";

/** 会社データ作成/エラー/作成時確認 */	
export const CreateCompanyData_Error_Insert_MaxContracts = "作成済の会社数が、契約の上限に達しています。";

/** 会社データ作成/エラー/会社コード入力時確認 */	
export const CreateCompanyData_Error_Insert_ExistbyOther = "入力した会社コードは別担当者が担当している会社コードです。";

/** 会社データ作成/エラー/月次開始日/範囲外 */	
export const CreateCompanyData_Error_MStDay_OutOfRange = "月次開始日は1～{0}の範囲で入力してください";

/** 会社データ作成/確認/作成 */	
export const CreateCompanyData_Confirmation_Insert = "データを作成します。\nよろしいですか？";


/*
 *ユーザー情報の確認・登録機能
 */
 /** ユーザー情報の確認・登録機能/エラー/必須項目/名前なし */	
export const UserInfo_Error_Required_Name = "名前は必須項目です。";

/** ユーザー情報の確認・登録機能/エラー/必須項目/名前カナなし */	
export const UserInfo_Error_Required_NameKana = "名前（カナ）は必須項目です。";

/** ユーザー情報の確認・登録機能/エラー/必須項目/メールアドレスなし */	
export const UserInfo_Error_Required_MailAddress = "メールアドレスは必須項目です。";

/** ユーザー情報の確認・登録機能/エラー/更新エラー */	
export const UserInfo_Error_Update = "エラーが発生し、更新されませんでした。";


/*
 *共通
 */
 /** 共通/エラー/パスワードポリシー/パスワードポリシーエラー */	
export const Common_Error_PasswordPolicy_PasswordPolicyErr_1 = "パスワードは8文字以上20文字以内かつ、";

/** 共通/エラー/パスワードポリシー/パスワードポリシーエラー */	
export const Common_Error_PasswordPolicy_PasswordPolicyErr_2 = "アルファベットの大文字・小文字を含めてください。";


/*
 *担当者ログイン
 */
 /** 担当者ログイン/エラー/ログインエラー */	
export const TantoLogin_Error_LoginError = "ログインに失敗しました。ログインIDまたはパスワードを確認してください。";

/** 担当者ログイン/エラー/必須項目/ログインIDなし */	
export const TantoLogin_Error_Required_LoginID = "ログインIDを入力してください。";

/** 担当者ログイン/エラー/必須項目/パスワードなし */	
export const TantoLogin_Error_Required_Password = "パスワードを入力してください。";

/** 担当者ログイン/エラー/URLパラメータ/テナントID */	
export const TantoLogin_Error_Param_Id = "URLパラメータ「id」の値が不正です。";


/*
 *ホーム
 */
 /** ホーム/エラー/起動確認/契約数オーバーのため会社データ削除画面起動 */	
export const Home_Error_StartCheck_ContractOverAndDelete = "契約会社数を超える会社データが作成されているため会社データを削除してください。";

/** ホーム/エラー/起動確認/契約数オーバーのためログアウト */	
export const Home_Error_StartCheck_ContractOverAndLogout = "契約会社数を超える会社データが作成されているためログインできません。";

/** ホーム/エラー/起動確認/会社数0のためログアウト */	
export const Home_Error_StartCheck_CompanyNotFoundAndLogout = "会社データが作成されていないためログインできません。";


/*
 *仕訳・実績データ取込
 */
 /** 仕訳・実績データ取込/エラー/インポート設定なし */	
export const Migration_Error_NoItemSettting = "インポート設定が見つかりません。「データ取込」画面よりインポートを実行してください。";

/** 仕訳・実績データ取込/エラー/フォーマット差異あり */	
export const Migration_Error_FormatNotMatch = "前回インポートしたファイルとフォーマットが異なります。仕訳ファイルを修正するか、「データ取込」画面よりインポートを実行してください。";

/** 仕訳・実績データ取込/エラー/項目変換設定未登録 */	
export const Migration_Error_ConvertSettingUnregistered = "{0}が項目変換設定に未登録です。仕訳ファイルを修正するか、「データ取込」画面よりインポートを実行してください。";


/*
 *共通
 */
 /** 共通/成功/削除 */	
export const Common_Success_Delete = "{0}を削除しました。";

/** 共通/警告/データ削除/DB削除失敗 */	
export const Common_Warning_Cleanup_DBRecordDeleteFail = "Failed to delete client data. ClientCode:{0} Table:{1}.{2}　Message:{3}";

/** 共通/警告/データ削除/Blob削除除失敗 */	
export const Common_Warning_Cleanup_BlobDeleteFail = "Failed to delete data in blob container. Container name:{0} Blob name:{1}";

/** 共通/警告/データ削除/Blobコンテナ削除失敗 */	
export const Common_Warning_Cleanup_BlobContainerDeleteFail = "Failed to delete blob container. Container name:{0}";



