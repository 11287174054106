import "../usefuls/utilities";
import { KbnInfoCacheViewModel } from "../../models/cache/kbnInfoCacheViewModel";

export class kbnInfoUtil {

    /**
     * Initializeで渡されるKbnInfo情報を直接保持する
     */
    private static kbnInfo: Array<KbnInfoCacheViewModel>;


    /**
     * データを取得した際にイニシャライズする
     * @param dTMAINData
     */
    public static initialize(
        kbnInfoData: Array<KbnInfoCacheViewModel>
    ) {
        kbnInfoUtil.kbnInfo = kbnInfoData;
    }

    /**
     * 保持しているKbnInfoを取得する
     */
    public static getDTMAIN(): Array<KbnInfoCacheViewModel> {
        return kbnInfoUtil.kbnInfo;
    }

    /**
     * dTMAINを取得する
     */
    public static getKbnInfoByPk(partitionRecordKbn: number): KbnInfoCacheViewModel {

        let kbnInfoCacheViewModel: KbnInfoCacheViewModel = kbnInfoUtil.kbnInfo.singleOrNull((m: KbnInfoCacheViewModel) => {
            return m.RecKbn == partitionRecordKbn;
        });

        return kbnInfoCacheViewModel;
    }
    
    /**
     * 平成31年9月の10％入力区分を取得する
     */
    public static getTenPercentInputSegmentInSep(): number {
        return kbnInfoUtil.getKbnInfoByPk(23).GnPuKbn7;
    } 

    /**
     * 消費税率入力方式を取得する
     */
    public static getTaxRateInputMethod(): number {
        return kbnInfoUtil.getKbnInfoByPk(23).GnPuKbn4;
    }
}