import React from 'react';
import classnames from 'classnames';
import { default as Icon } from '../../../images/icon/11-1_file.svg';
import SVG from 'react-inlinesvg';

type IconProps = {
  className?: string;
};
const FileAlt: React.FC<IconProps> = props => <SVG src={Icon} className={classnames(props.className, 'icon')} />;
export default FileAlt;
