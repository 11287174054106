import React from 'react';
const _ProgressConfirmPartial: React.FC<{}> = props => {
    return (
      <>

{/*  処理中ダイアログ(画面中央に処理の進捗状況を意味するプログレスバーを表示する画面)
    本ダイアログを表示するには mjs.acelink.vkz.dialogs.ProgressConfirmDialogクラスを使用すること */}


<div className="modal fade" id="mjsCommonProgressConfirm" tabIndex={-1} data-keyboard="false" data-backdrop="static">
    <div className="modal-dialog" role="dialog">
        <div className="modal-content">
            <div className="bg-tertiary text-white d-flex align-items-center py-1 modal-header">
                <div className="dp_flex wp100">
                    <div className="wp50">
                        <h5 className="modal-title" id="mjsCommonProgressConfirmTitle">タイトル未設定</h5>
                    </div>
                </div>
            </div>
            <div className="modal-body">
                <div className="dp_flex column align-items-center">
                    <div className="modal-confirm-message mb10" id="mjsCommonProgressConfirmMessage" style={{alignSelf:"flex-start"}}>メッセージ未設定</div>
                    <div className="progress" style={{width:"600px"}}>
                        <div className="progress-bar progress-bar-striped active" id="mjsCommonProgressConfirmPercentage" style={{width:"0%"}}></div>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" id="mjsCommonProgressCancel" className="on-cancel btn btn-primary" data-dissmiss="modal">キャンセルボタン名未設定</button>
            </div>
        </div>{/*- /.modal-content */}
    </div>{/* /.modal-dialog --> */}
</div>{/* /.modal --> */}
      </>
    )
};
export default _ProgressConfirmPartial;