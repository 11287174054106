import { NumericInput } from './numericInput'; 

/**
 * 金額入力コントロール
 */
export class CurrencyInput extends NumericInput {

    /**
     * コンストラクタ
     * @param baseTagId: コントロール配置対象要素のID
     * @param $scope
     * @param $compile
     * @param caption: 見出しラベルの表示文字列。Nullが指定された場合は見出し領域非表示。
     * @param min: 許容最小値
     * @param max: 許容最大値
     * @param inputWidth: 入力領域の横幅
     */
    constructor(baseTagId: string, $scope: any, $compile: any, caption: string, min?: number, max?: number, inputWidth?: number);
    constructor(baseTagId: JQuery, $scope: any, $compile: any, caption: string, min?: number, max?: number, inputWidth?: number);
    constructor(baseTagId: any, $scope: any, $compile: any, caption: string, min: number = null!, max: number = null!, inputWidth: number = null!) {
        super(baseTagId, $scope, $compile, caption, null!, min, max, false, inputWidth);
    }

    protected get format(): string { return "n0"; }
}
