/**
 * 文字列stringを1文字単位に分割し、string[]に変換する
 * (JavaScriptの"分割したい文字列".split("")は一部のUnicode文字を正しく分割できない)
 * @param str 変換元の文字列string
 */
export const split = (str: string): string[] => {
  if ((str == null) || (str == "") || (str === undefined)) {
    return [];
  }
  return str.split(/(?=(?:[\0-\uD7FF\uE000-\uFFFF]|[\uD800-\uDBFF][\uDC00-\uDFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF]))/);
  // 下記の1パターンは全ブラウザで動くが一部のUnicode文字を2文字に分割してしまう。
  // "分割したい文字列".split("")

  // 下記の4パターンはIE11(Windows)で動作しない。
  // for (let c of "分割したい文字列") console.log(c)
  // [..."分割したい文字列"]
  // Array.from("分割したい文字列")
  // "分割したい文字列".split(/(?=[\s\S])/u)

  // 参考文献
  // https://stackoverflow.com/questions/4547609/how-do-you-get-a-string-to-a-character-array-in-javascript#answer-34717402
}

/**
 * 指定した文字列から無効な文字を削除して返す
 * @param str (無効な文字を削除したい)元の文字列
 * @param theRegExp 削除対象を示す正規表現(RegExp)
 */
export const removeInvalidChars = (str: string, theRegExp: RegExp): string => {
  let tmp: string[] = split(str);
  for (let i: number = 0; i < tmp.length; i++) {
    if (tmp[i].match(theRegExp) == null) {
      tmp[i] = "";
    }
  }
  return tmp.join("").toString();
}

/**
 * フリガナ・連想シンボルとして入力可能な文字のみ残し、入力不可能な文字を削除して返す
 * @param str チェック・変換対象の文字列
 */
export const validateKanaRenChar = (str: string): string => {
  return removeInvalidChars(str, /^[ -~｡-ﾟ]*$/);
}