import React from 'react';
import Axios from 'axios';

import * as labels from '../../../../constants/labels';
import * as constans from '../../../../constants/constant';
import * as message from '../../../../constants/message';
import { AuditResultListViewModel } from '../../../../models/auditResultListViewModel';
import PageHeader from '../../../../components/molecules/CMN/PageHeader/PageHeader';
import {
  DefaultLayout,
  DefaultLayoutHeader,
  DefaultLayoutContents
} from '../../../../components/templates/DefaultLayout';
import AuditResultListGrid from '../../../../components/molecules/CMN/Grid/AuditResultListGrid';
import ExcelAuditTargetOutputsetting from '../../../organisms/A000/ExcelAuditTargetOutputsetting';
import AuditResultRemarkIncDec from '../AIKADT002000007/AuditResultRemarkIncDec';
import AuditResultNegativeBalance from '../AIKADT002000008/AuditResultNegativeBalance';
import AuditResultJournalLine from '../AIKADT002000009/AuditResultJournalLine';
import AuditResultJournalDouble from '../AIKADT002000010/AuditResultJournalDouble';
import AuditResultTaxKubun from '../AIKADT002000011/AuditResultTaxKubun';
import AuditResultCheckList from '../AIKADT002000012/AuditResultCheckList';
import AuditTargetManager, { AuditTargetManagerVo } from '../../../organisms/A000/AuditTargetManager';
import { setAlertMessage, AlertKbnEnum } from '../../../../stores/AlertMessage';
import IconButton from '../../../../components/molecules/CMN/IconButton/IconButton';
import ExcelButton from '../../../../components/atoms/Icon/FileExcel';
import ExcelOutputSettingByMonth from '../../../organisms/A000/ExcelOutputSettingByMonth';
import { setIsLoading } from '../../../../stores/NowLoading';
import { MasterKbn } from '../../../../VKZ/core/constants/constant';
import { AuditResultCountVO } from '../../../../models/auditResultCountVO';

// 基本URL
const BaseUrl = '/api/v1/AuditResultList';
// 加工後URL ※ 補助コードについては「/」「\」を退避するために、全角に変換
export const initialUrl = (auditTarget: number, hojyoCode: string) => [BaseUrl, 'Initial', auditTarget, encodeURIComponent(hojyoCode.replaceAll('/', '／').replaceAll('\\', '￥'))].join('/');
// 処理判定
const JudgeURL = 'api/v1/CommonStartJudgement/Judge';
// システム履歴記入
const WriteSysHistoryURL = 'api/v1/CommonSystemHistory/Write';

const defaultAuditTarget: AuditTargetManagerVo = {
    target: MasterKbn.CountingKmk
}

type AuditResultList = {
  onClose: () => void;
  fiscalYear: number;
};
const AuditResultList: React.FC<AuditResultList> = props => {
  const [excelPopupActivated, setExcelPopupActivated] = React.useState(false);
  const [excelheaderLeft, setExcelheaderLeft] = React.useState('');
  const [AuditResultRemarkIncDecActivated, setAuditResultRemarkIncDecActivated] = React.useState(false);
  const [AuditResultNegativeBalanceActivated, setAuditResultNegativeBalanceActivated] = React.useState(false);
  const [AuditResultJournalLineActivated, setAuditResultJournalLineActivated] = React.useState(false);
  const [AuditResultJournalDoubleActivated, setAuditResultJournalDoubleActivated] = React.useState(false);
  const [AuditResultTaxKubunActivated, setAuditResultTaxKubunActivated] = React.useState(false);
  const [AuditResultCheckListActivated, setAuditResultCheckListActivated] = React.useState(false);
  const [nmonth, setNmonth] = React.useState(0);
  const [auditTarget, setAuditTarget] = React.useState<AuditTargetManagerVo>();
  // 監査報告出力の出力設定ポップアップ開閉状態コントロール
  const [reportExcelPopupActivated, setReportExcelPopupActivated] = React.useState(false);
  const [focusFlg, setFocusFlg] = React.useState(false);
  const [viewModel, updateViewModel] = React.useState<AuditResultListViewModel>({
    Months: [],
    Counts: []
  });

  const handleOnClose = () => {
    props.onClose();
  };
  const handleOnExcelClick = () => {
    // 監査報告出力
    setReportExcelPopupActivated(false);
    setExcelPopupActivated(true);
  };
    // Excel出力系ポップアップ閉じる処理（本画面共通）
  const handleExcelOnClose = () => {
      setExcelPopupActivated(false);
      setReportExcelPopupActivated(false);
  };

  // 監査明細出力ボタン押下処理
  const handleExcelReportClick = () => {
    // 通常メニューのExcel出力ポップアップは表示OFFにする
    setExcelPopupActivated(false);
    setReportExcelPopupActivated(true);
  };

  // 著増減監査件数押下
  const handleRemarkLinkClick = (nMonth: number) => {
    setNmonth(nMonth);
    setAuditResultRemarkIncDecActivated(true);
  };
  // マイナス残高監査件数押下
  const handleNegativeLinkClick = (nMonth: number) => {
    setNmonth(nMonth);
    setAuditResultNegativeBalanceActivated(true);
  };
  // 仕訳明細監査件数押下
  const handleJournalLinkClick = (nMonth: number) => {
    setNmonth(nMonth);
    setAuditResultJournalLineActivated(true);
  };
  // 仕訳重複監査件数押下
  const handleBuplicateLinkClick = (nMonth: number) => {
    setNmonth(nMonth);
    setAuditResultJournalDoubleActivated(true);
  };
  // 消費税区分監査件数押下
  const handleExciseLinkClick = (nMonth: number) => {
    setNmonth(nMonth);
    setAuditResultTaxKubunActivated(true);
  };
  // ステータス件数押下
  const handleCheckListLinkClick = (nMonth: number) => {
    setNmonth(nMonth);
    setAuditResultCheckListActivated(true);
  };
  // 特記事項件数押下
  const handleNoticeLinkClick = (nMonth: number) => {
    setNmonth(nMonth);
    setAuditResultCheckListActivated(true);
  };

  //通信処理(初期表示データ作成)
  const initialOnGet = (url: string) => {
    setIsLoading(true);
    Axios.get<AuditResultListViewModel>(url)
      .then(response => {
        updateViewModel(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        if (typeof error.response.data !== 'undefined') {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
        }
        setIsLoading(false);
      });
  };

  // 通信処理の実行
  React.useEffect(() => {
    
  }, [initialUrl, props.fiscalYear]);

  const firstFocusElement = React.useRef<any>();

  React.useEffect(() => {
    switch (auditTarget?.target) {
      case MasterKbn.Bmn:
        if (auditTarget?.items != null) {  //部門が設定されていれば通信
          JudgeOnPost(JudgeURL, 'AIKADT002000006');
        } else {
          //グリッド表示リセット
          let emptyViewModel = new AuditResultListViewModel();
          let countVo = new Array<AuditResultCountVO>();
          emptyViewModel.Months = viewModel.Months;
          emptyViewModel.Counts = countVo;
          updateViewModel(emptyViewModel);
        }
        break;
      case MasterKbn.CountingKmk:
      default:
        //科目は即通信
        JudgeOnPost(JudgeURL, 'AIKADT002000006');
        break;
    }
  }, [auditTarget]);
  
  // 処理判定
  async function JudgeOnPost(url: string, syoriId: string) {
    setIsLoading(true);

    var kansa = auditTarget?.target ?? 0;
    var bmn = auditTarget?.items == null ? "0" : auditTarget?.items[0].BmnCode ?? "0";

    const status = await Axios.post<number>(url, { syoriId: syoriId });

    if (status.data == 0) {
      // 初期起動
      initialOnGet(initialUrl(kansa, kansa == MasterKbn.Bmn ? bmn : "0"));
      setExcelheaderLeft(props.fiscalYear.toString().substr(0, 4));
      await Axios.post(WriteSysHistoryURL, { syoriId: syoriId, operation: '処理開始' });
    } else {
      if (status.data == -101) {
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotUse });
      } else if (status.data == -102) {
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractOver });
      } else if (status.data == -103) {
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_Notauthorized });
      } else if (status.data == -104) {
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotDataSelect });
      } else if (status.data == -105) {
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractEqual });
      }
      props.onClose();
    }
    setIsLoading(false);
  }

  React.useEffect(() => {
    const auditTargetCmbElement = document.getElementById('auditTarget');
    if (auditTargetCmbElement) {
      const textElement = auditTargetCmbElement.getElementsByTagName('input');
      const elements = Array.from(textElement);
      if (!focusFlg) {
        if (auditTarget) {
          // 監査対象有効の場合、firstfocusを監査対象に設定する
          firstFocusElement.current = elements[0];
          firstFocusElement.current.focus();
          setFocusFlg(true);
        }
      }
    }
  }, [viewModel]);

  const handleOnAuditResultRemarkIncDec = () => {
    setAuditResultRemarkIncDecActivated(false);
  };
  const handleOnAuditResultNegativeBalance = () => {
    setAuditResultNegativeBalanceActivated(false);
  };
  const handleOnAuditResultJournalLine = () => {
    setAuditResultJournalLineActivated(false);
  };
  const handleOnAuditResultJournalDouble = () => {
    setAuditResultJournalDoubleActivated(false);
  };
  const handleOnAuditResultTaxKubun = () => {
    setAuditResultTaxKubunActivated(false);
  };
  const handleOnAuditResultCheckList = () => {
    setAuditResultCheckListActivated(false);
  };

  return viewModel.Months == undefined || viewModel.Months.length < 1 ? null : (
    <DefaultLayout className='vw-100 h-100'>
      <DefaultLayoutHeader>
        <PageHeader
          pageTitle={labels.AIKADT002000006_FUNCTION_NAME}
          excelButtonTabIndex={3}
          onExcelClick={handleOnExcelClick}
          excelButtonDisabled={auditTarget?.target == null}
          closeButtonTabIndex={4}
          onCloseClick={handleOnClose}>
          <div className='d-flex w-100'>
            <div className='extra-header-area'>
              <IconButton
                key='Excel'
                data-testid='excel-icon'
                onClick={handleExcelReportClick}
                icon={<ExcelButton />}
                className={'-icon output-report-excel-button'}
                tabIndex={9}
                disabled={auditTarget?.target != MasterKbn.CountingKmk}
              >
                {labels.AIKADT002000006_GRID_HEADER_OUTPUT_RESULT_REOPRT}
              </IconButton>
            </div>
          </div>
        </PageHeader>
      </DefaultLayoutHeader>
      <DefaultLayoutContents className='vw-100 p-3'>
        <div className='mb-3 d-flex flex-nowrap' id='auditTarget'>
          <AuditTargetManager
            name={'AuditResultList-AuditTarget'}
            defaultSelectItem={defaultAuditTarget}
            transmitSelectItems={e => { setAuditTarget(e) }}
            transmitBmnList={e => { }}
            applyCombo={true}
            applyRange={false}
            disabled={auditTarget?.target == null}
            tabIndex={1}
          />
        </div>
       <div className='auditResultList-grid' id='auditResultList-grid'>
        <AuditResultListGrid
          gridClassName='AuditResultList-grid mw-100'
          sources={viewModel}
          linkTabIndex={2}
          onRemarkLinkClick={handleRemarkLinkClick}
          onNegativeLinkClick={handleNegativeLinkClick}
          onJournalLinkClick={handleJournalLinkClick}
          onBuplicateLinkClick={handleBuplicateLinkClick}
          onExciseLinkClick={handleExciseLinkClick}
          onCheckListLinkClick={handleCheckListLinkClick}
          onNoticeLinkClick={handleNoticeLinkClick}
          auditTarget={auditTarget?.target ?? MasterKbn.CountingKmk}
        />
      </div>
      </DefaultLayoutContents>
      {/* Excel出力ポップアップ */}
      <ExcelAuditTargetOutputsetting
        activated={excelPopupActivated}
        templateId={constans.AIKADT002000006E01}
        bmnTemplateId={constans.AIKADT002000006E11}
        headerLeft={excelheaderLeft}
        onClose={handleExcelOnClose}
        stNMonth={1}
        edNMonth={1}
        initAuditTarget={auditTarget}
      />
      {/* 監査報告出力ポップアップ */}
      <ExcelOutputSettingByMonth
        activated={reportExcelPopupActivated}
        templateId={constans.AIKADT002000006E02}
        headerLeft={excelheaderLeft}
        onClose={handleExcelOnClose}
        popupTitle={labels.AIKADT002000006_GRID_HEADER_OUTPUT_RESULT_REOPRT}
        fiscalYear={props.fiscalYear}
        auditTarget={auditTarget?.target ?? MasterKbn.CountingKmk}
        shareIsLoading={true}
      />
      <AuditResultRemarkIncDec
        activated={AuditResultRemarkIncDecActivated}
        onClose={handleOnAuditResultRemarkIncDec}
        month={nmonth}
        auditTarget={auditTarget?.target ?? MasterKbn.CountingKmk}
        hojyoCode={auditTarget?.target == MasterKbn.Bmn ? (auditTarget.items == null ? "0" : auditTarget?.items[0].BmnCode ?? "0") : "0"}
      />
      <AuditResultNegativeBalance
        activated={AuditResultNegativeBalanceActivated}
        onClose={handleOnAuditResultNegativeBalance}
        month={nmonth}
        auditTarget={auditTarget?.target ?? MasterKbn.CountingKmk}
        hojyoCode={auditTarget?.target == MasterKbn.Bmn ? (auditTarget.items == null ? "0" : auditTarget?.items[0].BmnCode ?? "0") : "0"}
      />
      <AuditResultJournalLine
        activated={AuditResultJournalLineActivated}
        onClose={handleOnAuditResultJournalLine}
        month={nmonth}
      />
      <AuditResultJournalDouble
        activated={AuditResultJournalDoubleActivated}
        onClose={handleOnAuditResultJournalDouble}
        month={nmonth}
      />
      <AuditResultTaxKubun
        activated={AuditResultTaxKubunActivated}
        onClose={handleOnAuditResultTaxKubun}
        month={nmonth}
      />
      <AuditResultCheckList
        activated={AuditResultCheckListActivated}
        onClose={handleOnAuditResultCheckList}
        month={nmonth}
      />
    </DefaultLayout>
  );
};
export default AuditResultList;
