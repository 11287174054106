import {AuditResultRemarkIncDecVO} from "./auditResultRemarkIncDecVO";
/**
 * 監査結果(著増減)ViewModel ViewModel クラス
 */

export class AuditResultRemarkIncDecViewModel {
    /** 監査結果(著増減)VO */
    public Results?:Array<AuditResultRemarkIncDecVO>;

    /** 対象月 */
    public TMonth?:string;

    /** 最終実行日時 */
    public LastExecDate?: Date;

    /** 監査対象対象月 */
    public AuditTarget?: number;

}
