import {ConfStatusEnum} from "../constants/enums";
import {JournalLineVO} from "./journalLineVO";
/**
 * 仕訳明細一覧ViewModel ViewModel クラス
 */

export class JournalLineListViewModel {
    /** 内部月(対象月) */
    public NMonth?:string;

    /** 明細No */
    public RowNo?:number;

    /** 確認ステータス */
    public ConfStatus?:ConfStatusEnum;

    /** 監査メッセージ */
    public AuditMess?:string;

    /** 比較対象月 */
    public CmpTMonth?:string;

    /** 比較対象月金額 */
    public CmpSum?:number;

    /** 金額 */
    public InpSum?:number;

    /** 検索No(監査対象) */
    public SrchNo?:number;

    /** コメント */
    public Comment?:string;

    /** 仕訳明細VO */
    public JournalLines?:Array<JournalLineVO>;

}
