import { setIsLoading } from '../../stores/NowLoading';
import { getGlobal } from 'reactn';
export class BusyIndicator {

    // private static busyIndicator: JQuery = null!;

    /**
     * ビジーインジケーター用オブジェクトを取得します。
     */
    /*
    public static getBusyIndicator(): JQuery {
        if (BusyIndicator.busyIndicator == null) {
            BusyIndicator.busyIndicator = $("<div class=\"mjsLoadingPanel\"></div>");
        }
        
        return BusyIndicator.busyIndicator;
    }
    */

    /**
     * ビジーインジケーターを表示します。
     */
    public static open(): void {
        // BusyIndicator.getBusyIndicator().modal(option).addClass("in");
        setIsLoading(true);
    }

    /**
     * ビジーインジケーターを閉じます。
     */
    public static close(): void {
        // BusyIndicator.getBusyIndicator().modal("hide").addClass("out");
        setIsLoading(false);
    }

    /**
     * ビジーインジケーターが開いているかチェック
     * True:開いている,False：開いていない
     */
    public static checkOpening(): boolean {
        return getGlobal["isLoading"];
    }
}
