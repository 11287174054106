/* ■■■■ import ■■■■ */
import Axios from 'axios';
import React from 'react';
import { Button, FormGroup, Input, Label } from 'reactstrap';

import * as wjInput from 'wijmo/wijmo.input';
import { InputDate, InputNumber, ComboBox } from 'wijmo/wijmo.react.input';

import * as Labels from '../../../../constants/labels';
import * as enums from '../../../../constants/enums';
import * as dataMaps from '../../../../constants/dataMaps';

import * as message from '../../../../constants/message';
import { bindValueToMessage } from '../../../../utils/messageUtils';
import { setAlertMessage, AlertKbnEnum } from '../../../../stores/AlertMessage';
import ConfirmationPopup from '../../../../components/molecules/CMN/ConfirmationPopup/ConfirmationPopup';
import Popup from '../../../../components/molecules/CMN/Popup/Popup';

import {
  LowerActionLayout,
  LowerActionLayoutHeader,
  LowerActionLayoutContents,
  LowerActionLayoutAction
} from '../../../../components/templates/LowerActionLayout';

import PageHeader from '../../../../components/molecules/CMN/PageHeader/PageHeader';

import ExecutingCancel from '../../../../components/molecules/CMN/ExecutingCancel/ExecutingCancel';

import { CreateCompanyDataViewModel } from '../../../../models/createCompanyDataViewModel';
import { format } from '../../../../utils/dateUtils';
import { validateKanaRenChar } from '../../../../utils/stringUtils';
import { setIsLoading } from '../../../../stores/NowLoading';

/* ■■■■ dataMaps とは別に本画面専用に独自定義 ■■■■ */

/** 会計処理区分(dataMaps.AccProKbnEnumから0免税業者を削除 1&2の文言を本画面向けに変更) */
const TaxInfoAccProKbn = [

  // { key: 0, value: '免税業者' },
  { key: 1, value: '税込' },
  { key: 2, value: '税抜' },
];

/** 簡易課税区分(dataMaps.KaniKbnEnumに2免税を追加) */
const KaniKbn = [
  { key: 0, value: '原則課税' },
  { key: 1, value: '簡易課税' },
  { key: 2, value: '免税' },
];

/* ■■■■ enums とは別に本画面専用に独自定義 ■■■■ */

/** 会計処理区分(dataMaps.AccProKbnEnumから0免税業者を削除) */
enum TaxInfoAccProKbnEnum {
  /** 免税業者 */
  // TaxFreeTrader = 0,

  /** 税込処理 */
  TaxIncluded = 1,

  /** 税抜処理 */
  TaxTreatment = 2
}

/** 簡易課税区分(dataMaps.KaniKbnEnumに2免税を追加) */
enum KaniKbnEnum {
  /** 原則課税 */
  PrincipleTaxation = 0,

  /** 簡易課税 */
  SimplifiedTax = 1,

  /** 免税 */
  TaxFreeTrader = 2
}

/* ■■■■ dataMapsを元にしたコンボボックスの選択肢 ■■■■ */

/** B20 科目体系コンボボックスの選択肢 */
const corpKbnItemsSource = dataMaps.CorpKbnEnum.map(item => ({ ...item }));

/** B33 輸入取引区分コンボボックスの選択肢 */
const importKbnItemsSource = dataMaps.ImportKbnEnum.map(item => ({ ...item }));

/* ■■■■ mini class ■■■■ */

/** B2 会社コード */
class ComCodeUtil {
  /** B2 会社コードの最小値 */
  private static readonly MinValue: number = 1;

  /** B2 会社コードの最大値 */
  private static readonly MaxValue: number = 99999999;

  /** B2 会社コードの最大桁数 */
  private static readonly MaxLength: number = 8;

  public static isValidString(theValue: string | undefined): boolean {
    if (theValue == undefined) {
      return false; // 未入力はNG
    }
    if (theValue.length == 0) {
      return false; // 未入力はNG
    }
    if (ComCodeUtil.MaxLength < theValue.length) {
      return false; // 最大文字数オーバーはNG
    }
    let temp: number = ComCodeUtil.fromStringToNumber(theValue);
    return ComCodeUtil.isValidNumber(temp);
  }

  public static isValidNumber(theValue: number): boolean {
    if (isNaN(theValue)) {
      return false;
    }
    if ((theValue < ComCodeUtil.MinValue) || (ComCodeUtil.MaxValue < theValue)) {
      return false;
    }
    return true;
  }

  public static fromStringToNumber(theValue: string): number {
    return parseInt(theValue, 10);
  }

  public static getValidString(theValue: string | undefined): string {
    if (ComCodeUtil.isValidString(theValue)) {
      if (theValue != undefined) {
        let temp: number = ComCodeUtil.fromStringToNumber(theValue);
        return temp.toString();
      }
    }
    return '';
  }
}

/** B4 会社名 */
class LComNameUtil {
  /** B4 会社名の最大文字数 */
  private static readonly MaxLength: number = 60;

  public static isValid(theValue: string | undefined): boolean {
    if (theValue == undefined) {
      return false; // 未入力はNG
    }
    if (theValue.length == 0) {
      return false; // 未入力はNG
    }
    if (LComNameUtil.MaxLength < theValue.length) {
      return false; // 最大文字数オーバーはNG
    }
    return true;
  }
}

///** B6 フリガナ */
//class KanaComNameUtil {
//  /** B6 フリガナの最大文字数 */
//  private static readonly MaxLength: number = 60;
//
//  public static isValid(theValue: string | undefined): boolean {
//    if (theValue == undefined) {
//      return true; // 未入力はOK
//    }
//    if (theValue.length == 0) {
//      return true; // 未入力はOK
//    }
//    if (KanaComNameUtil.MaxLength < theValue.length) {
//      return false; // 最大文字数オーバーはNG
//    }
//    return true;
//  }
//}

/** B8 簡略会社名 */
class SComNameUtil {
  /** B8 簡略会社名の最大文字数 */
  private static readonly MaxLength: number = 30;

  public static isValid(theValue: string | undefined): boolean {
    if (theValue == undefined) {
      return false; // 未入力はNG
    }
    if (theValue.length == 0) {
      return false; // 未入力はNG
    }
    if (SComNameUtil.MaxLength < theValue.length) {
      return false; // 最大文字数オーバーはNG
    }
    return true;
  }
}

///** B11 連想 */
//class RenCharUtil {
//  /** B11 連想の最大文字数 */
//  private static readonly MaxLength: number = 12;
//
//  public static isValid(theValue: string | undefined): boolean {
//    if (theValue == undefined) {
//      return true; // 未入力はOK
//    }
//    if (theValue.length == 0) {
//      return true; // 未入力はOK
//    }
//    if (RenCharUtil.MaxLength < theValue.length) {
//      return false; // 最大文字数オーバーはNG
//    }
//    return true;
//  }
//}

///** B17 月次開始日 */
//class MStDayUtil {
//  /** B17 月次開始日の最大桁数 */
//  private static readonly MaxLength: number = 2;
//
//  public static isValid(theValue: string | undefined): boolean {
//    if (theValue == undefined) {
//      return false; // 未入力はNG
//    }
//    if (theValue.length == 0) {
//      return false; // 未入力はNG
//    }
//    if (MStDayUtil.MaxLength < theValue.length) {
//      return false; // 最大桁数オーバーはNG
//    }
//    return true;
//  }
//}

/* ■■■■ 定数定義 ■■■■ */

/** サーバへアクセスする際のURL(ベース部分) */
const BaseURL = 'api/v1/CreateCompanyData';

/** 会社コード決定時にサーバへアクセスする際のURL */
const CreateDataURL = [BaseURL, 'CreateData'].join('/');

/** 作成ボタン押下時にサーバへアクセスする際のURL */
const AddDataURL = [BaseURL, 'AddData'].join('/');

/** 共通処理 */
// 処理起動判定
const JudgeURL = 'api/v1/CommonStartJudgement/Judge';
// システム履歴記入
const WriteSysHistoryURL = 'api/v1/CommonSystemHistory/Write';

/** 会計期間(年月日～年月日)用のフォーマット */
const kaikeiKikanFormat = 'yyyyMMdd';

/* ■■■■ Reactのproperties ■■■■ */

type CreateCompanyDataProps = {
  /** 自画面を閉じる処理 */
  onClose: () => void;

  /** 表示年度 */
    fiscalYear: number;

  /** 親画面の年度ドロップダウンを更新する処理 */
    onYearAdd: () => void;

    initializeState: boolean;
};

/**
 * 会社データ作成画面 機能ID AIKCMN003000001
 * @param props CreateCompanyDataProps {自画面を閉じる処理, 表示年度}
 */
const CreateCompanyData: React.FC<CreateCompanyDataProps> = props => {
  const { onClose, onYearAdd } = props;

  /** A2 閉じるボタン押下時の処理 */
  const handleOnCloseClick = () => {
    onClose();
  };

  /* ■■■■ ReactのState ■■■■ */

  // 1 編集済フラグ true 編集中, false 編集してない
  const [edited, setEdited] = React.useState<boolean>(false);

  // 2 会社コード【画面上に入力欄あり】
  const [comCode, setComCode] = React.useState<number>(0);

  // 3 会社名【画面上に入力欄あり】
  const [lComName, setLComName] = React.useState<string>('');

  // 4 フリガナ【画面上に入力欄あり】
  const [kanaComName, setKanaComName] = React.useState<string>('');

  // 5 簡略会社名【画面上に入力欄あり】
  const [sComName, setSComName] = React.useState<string>('');

  // 6 連想【画面上に入力欄あり】
  const [renChar, setRenChar] = React.useState<string>('');

  // 7 会計期間（自）【画面上に入力欄あり】
  const [kStDate, setKStDate] = React.useState<Date>(new Date());

  // 8 会計期間（至）【画面上に入力欄あり】
  const [kEdDate, setKEdDate] = React.useState<Date>(new Date());

  // 9 月次開始日【画面上に入力欄あり】
  const [mStDayString, setMStDayString] = React.useState<string>('');

  // 10 科目体系区分【画面上に入力欄あり】
  const [corpKbn, setCorpKbn] = React.useState<number>(enums.CorpKbnEnum.Corporation);

  // 11 中間決算区分【画面上に入力欄あり】
  const [middleKbn, setMiddleKbn] = React.useState<number>(enums.MidtermClosingEnum.None);

  // 12 簡易課税区分(kanikbn)【画面上に入力欄あり】
  const [kanikbn, setKanikbn] = React.useState<number>(KaniKbnEnum.PrincipleTaxation);

  // 13 会計処理区分【画面上に入力欄あり】
  const [accProKbn, setAccProKbn] = React.useState<number>(enums.AccProKbnEnum.TaxTreatment);

  // 14 輸入取引区分【画面上に入力欄あり】
  const [importKbn, setImportKbn] = React.useState<number>(enums.ImportKbnEnum.Balance);

  // 15 過年度コピー区分【画面上に入力欄あり】
  const [yearCopyKbn, setYearCopyKbn] = React.useState<number>(0);

  // 16 中間決算区分の集計方法
  const [mCalcKbn, setMCalcKbn] = React.useState<number>(enums.MidtermClosingCalcKbnEnum.Without);

  // 17 部門採用
  const [departmentUseKbn, setDepartmentUseKbn] = React.useState<number>(-1);

  // 18 簡易課税基準業種
  const [basedType, setBasedType] = React.useState<number>(-1);

  // 19 コピー元会計年度
  const [copyClientYear, setCopyClientYear] = React.useState<number>(-1);

  // 20 会社コード
  const [clientCode, setClientCode] = React.useState<number>(0);

  // 21 作成します確認メッセージを表示するフラグ
  const [addCompanyConfirmActivated, setAddCompanyConfirmActivated] = React.useState<boolean>(false);

  // 22 取り消します確認メッセージを表示するフラグ
  const [cancelConfirmActivated, setCancelConfirmActivated] = React.useState<boolean>(false);

  // 23 汎用メッセージ
  const [allPurposePopupMessage, setAllPurposePopupMessage] = React.useState<string>('');

  // 24 汎用メッセージを表示するフラグ
  const [allPurposePopupActivated, setAllPurposePopupActivated] = React.useState<boolean>(false);

  // 25 「過年度データの」科目体系区分
  const [corpKbnPast, setCorpKbnPast] = React.useState<number>(-1);

  // 26 月次開始日にフォーカスを移動させるフラグ
  const [focusMStDay, setFocusMStDay] = React.useState<boolean>(false);

  // 27 会社コードにフォーカスを移動させるフラグ
  const [focusComCode, setFocusComCode] = React.useState<boolean>(false);

  // 28 会社コード入力欄(会社コード入力欄にフォーカスをセットするため)
  const [comCodeInputNumber, setComCodeInputNumber] = React.useState<any>();

  // 29 B20 科目体系ComboBox
  const [corpKbnCmb, setCorpKbnCmb] = React.useState<wjInput.ComboBox>();

  // 30 B33 輸入取引区分ComboBox
  const [importKbnCmb, setImportKbnCmb] = React.useState<wjInput.ComboBox>();

  // 31 フォーカス順序の最初の入力欄
  const firstFocusElement = React.useRef<any>();

  // 32 フォーカス順序の最後の入力欄
  const lastFocusElement = React.useRef<any>();

  // 33 本画面(会社データ作成)全体の<div>
  const thisPage = React.useRef<any>();

  /** 汎用メッセージのOKボタンへの参照 */
  const refer2AllPurposePopupMessageButton = React.useRef<any>();

  /** 月次開始日入力欄への参照 */
  const refer2MStDay = React.useRef<any>();

  // 初期表示処理
  React.useEffect(() => {
    // 処理判定
    async function JudgeOnPost(url: string, syoriId: string) {
      const status = await Axios.post<number>(url, {syoriId: syoriId});
      if (status.data == 0) {
        // 初期起動
        initialize();
        await Axios.post(WriteSysHistoryURL, {syoriId: syoriId, operation: '処理開始'});
      } else {
        if(status.data == -101) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotUse});
        } else if (status.data == -102) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractOver});
        } else if (status.data == -103) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_Notauthorized});
        } else if (status.data == -104) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotDataSelect});
        } else if (status.data == -105) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractEqual});
        }
        props.onClose();
      }
    }
    JudgeOnPost(JudgeURL, 'AIKCMN003000001');
    
  }, [props.fiscalYear]);

  /** 初期表示時のState初期化処理 */
  function initialize(): void {
    setEdited(false);
    setComCode(0);
    setLComName('');
    setKanaComName('');
    setSComName('');
    setRenChar('');
    setKStDate(new Date());
    setCorpKbn(enums.CorpKbnEnum.Corporation);
    setMiddleKbn(enums.MidtermClosingEnum.None);
    setKanikbn(KaniKbnEnum.PrincipleTaxation);
    setAccProKbn(enums.AccProKbnEnum.TaxTreatment);
    setImportKbn(enums.ImportKbnEnum.Balance);
    setYearCopyKbn(0);
    setMCalcKbn(enums.MidtermClosingCalcKbnEnum.Without);
    setDepartmentUseKbn(-1);
    setBasedType(-1);
    setCopyClientYear(-1);
    setClientCode(0);
    setAddCompanyConfirmActivated(false);
    setCancelConfirmActivated(false);
    setAllPurposePopupMessage('');
    setAllPurposePopupActivated(false);
    setCorpKbnPast(-1);
    setFocusMStDay(false);
    setFocusComCode(false);

    // フォーカス順序を制御するための初期処理
    if ((thisPage != undefined) && (thisPage.current != undefined)) {
      thisPage.current.removeEventListener('keydown', handleKeyDown);
      thisPage.current.addEventListener('keydown', handleKeyDown);
    }
    if ((firstFocusElement != undefined) && (firstFocusElement.current != undefined)) {
      firstFocusElement.current.focus();
    }
  }

  /** キーボード操作（キーダウン） */
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 9) {
      const target: HTMLElement = event.target as HTMLElement;
      if (event.shiftKey === false) {
        // タブキー押下時
        if ((target.tabIndex === lastFocusElement.current.tabIndex) ||
          (target.tabIndex < firstFocusElement.current.tabIndex) ||
          (target.tabIndex > lastFocusElement.current.tabIndex)) {
          // 最後のフォーカスエレメントの場合

          // 最初のエレメントにフォーカス
          firstFocusElement.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
      }
      else {
        // シフト＋タブキー押下時
        if ((target.tabIndex === firstFocusElement.current.tabIndex) ||
          (target.tabIndex < firstFocusElement.current.tabIndex) ||
          (target.tabIndex > lastFocusElement.current.tabIndex)) {
          // 最後のフォーカスエレメントの場合

          // 最後のエレメントにフォーカス
          lastFocusElement.current.focus();

          // 後のイベントをキャンセル
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }
  };

  /** 免税であればtrueを返す。免税以外はfalseを返す。 */
  function isTaxFree(): boolean {
    if (kanikbn == KaniKbnEnum.TaxFreeTrader) {
      return true;
    }
    return false;
  }

  /** DBに過年度データが無い新規の会社の場合trueを返す。DBに過年度データがある既存の会社の場合falseを返す。 */
  function isNewCompany(): boolean {
    if (clientCode == 0) {
      return true;
    }
    return false;
  }

  /** B25 作成ボタン押下時の処理 */
  const handleOnExecuteClick = () => {
    let errorMessage = checkCompany();
    if (errorMessage == '') {
      setAddCompanyConfirmActivated(true);
    }
    else {
      setAllPurposePopupMessage(errorMessage);
      setAllPurposePopupActivated(true);
    }
  }

  /** 登録可能な会社データか否かチェックする。登録可能な場合''を返す。登録不可能な場合、チェックNGだったエラーメッセージを返す。 */
  function checkCompany(): string {
    let requiredButEmpty = false;
    let invalidItem = '';
    if (!ComCodeUtil.isValidNumber(comCode)) {
      invalidItem = Labels.AIKCMN003000001_INPUT_ComCode;
      if (comCode == 0) {
        requiredButEmpty = true;
      }
    }
    else if (!LComNameUtil.isValid(lComName)) {
      invalidItem = Labels.AIKCMN003000001_INPUT_LComName;
      if (lComName.length == 0) {
        requiredButEmpty = true;
      }
    }
    //else if (!KanaComNameUtil.isValid(kanaComName)) {
    //  invalidItem = Labels.AIKCMN003000001_INPUT_KanaComName;
    //}
    else if (!SComNameUtil.isValid(sComName)) {
      invalidItem = Labels.AIKCMN003000001_INPUT_SComName;
      if (sComName.length == 0) {
        requiredButEmpty = true;
      }
    }
    //else if (!RenCharUtil.isValid(renChar)) {
    //  invalidItem = Labels.AIKCMN003000001_INPUT_RenChar;
    //}
    //else if (!MStDayUtil.isValid(mStDayString)) {
    //  invalidItem = Labels.AIKCMN003000001_INPUT_MStDay;
    //}
    if (invalidItem == '') {
      return '';
    }
    else {
      return bindValueToMessage((requiredButEmpty ? message.Common_Error_RequiredInput_Empty : message.Common_Error_InvalidData), [invalidItem]);
    }
  }

  /**
   * B25 作成ボタン押下時にサーバへアクセスする
   */
  const AddOnPost = () => {
    setIsLoading(true);
    let url = AddDataURL;
    let viewModel = new CreateCompanyDataViewModel();
    viewModel.ClientCode = clientCode;
    viewModel.ComCode = comCode;
    viewModel.LComName = lComName;
    viewModel.KanaComName = kanaComName;
    viewModel.SComName = sComName;
    viewModel.RenChar = renChar;
    viewModel.KStDate = format(kStDate, kaikeiKikanFormat);
    viewModel.KEdDate = format(kEdDate, kaikeiKikanFormat);
    viewModel.MStDay = parseInt(mStDayString);
    viewModel.CorpKbn = corpKbn;
    viewModel.AccProKbn = accProKbn;
    viewModel.MiddleKbn = middleKbn;
    viewModel.MCalcKbn = mCalcKbn;
    viewModel.DepartmentUseKbn = departmentUseKbn;
    viewModel.BasedType = basedType;
    viewModel.Kanikbn = kanikbn;
    viewModel.ImportKbn = importKbn;
    viewModel.YearCopyKbn = yearCopyKbn;
    viewModel.CopyClientYear = copyClientYear;
    Axios.post<number>(url, viewModel)
      .then(response => {
        if (response.data == 0) {
          setAllPurposePopupMessage(message.CreateCompanyData_Success_Insert_Success);
        }
        else if (response.data == 1) {
          setAllPurposePopupMessage(message.CreateCompanyData_Error_Insert_AlreadyExist);
        }
        else if (response.data == 2) {
          setAllPurposePopupMessage(message.Common_Error_StartCheck_ContractEqual);
        }
        else {
          // 想定外の戻り値
          setAllPurposePopupMessage(message.Common_Error_InternalServerError);
        }
        onYearAdd();
      })
      .catch(error => {
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_InternalServerError });
      });
  }

  // 初期表示処理
  React.useEffect(() => {
    // 処理判定
    if (props.initializeState == false) {
      //親画面の初期化が終了していた場合
      //保存ボタン押下時の処理待ち状態から復帰する
      if (allPurposePopupMessage != "") { //画面初期表示時に一瞬ポップアップが表示されないようにする
        setAllPurposePopupActivated(true);
      }
      setIsLoading(false);
    }
  }, [props.initializeState]);


  /** B26 取消ボタン押下時の処理 */
  const handleOnCancelClick = () => {
    if (edited == true) {
      // 編集中の場合
      setCancelConfirmActivated(true);
      return;
    }

    // 編集してない場合
    initialize();
  }

  /** 会社名からコピーボタン押下時の処理 */
  const copyClick = () => {
    // 会社名の値を略式会社名にコピーする
    let newName: string = '';
    if (lComName != undefined) {
      newName = lComName;
      if (15 < newName.length) {
        newName = newName.slice(0, 15);
      }
    }
    setSComName(newName);
  }
  
  /**
   * 会社コード決定時にサーバへアクセスする
   */
  const CreateOnGet = (comCode: number) => {
    setIsLoading(true);
    let url = CreateDataURL;
    Axios.post<CreateCompanyDataViewModel>(url, { ComCode: comCode })
      .then(response => {
        if ((response.data) &&
          (response.data.ClientCode != undefined) &&
          (0 < response.data.ClientCode) &&
          (response.data.ComCode != undefined) &&
          (0 < response.data.ComCode)) {
          // 該当する会社データが存在した、かつ、会社データ作成可能の場合
          if (response.data.ClientCode != undefined) {
            setClientCode(response.data.ClientCode);
          }
          if (response.data.ComCode != undefined) {
            setComCode(response.data.ComCode);
          }
          if (response.data.LComName != undefined) {
            setLComName(response.data.LComName);
          }
          if (response.data.KanaComName != undefined) {
            setKanaComName(response.data.KanaComName);
          }
          if (response.data.SComName != undefined) {
            setSComName(response.data.SComName);
          }
          if (response.data.RenChar != undefined) {
            setRenChar(response.data.RenChar);
          }
          if (response.data.KStDate != undefined) {
            setKStDate(new Date(response.data.KStDate));
          }
          if (response.data.KEdDate != undefined) {
            setKEdDate(new Date(response.data.KEdDate));
          }
          if (response.data.MStDay != undefined) {
            setMStDayString(response.data.MStDay.toString());
          }
          if (response.data.CorpKbn != undefined) {
            setCorpKbn(response.data.CorpKbn); // 画面に表示する方
            setCorpKbnPast(response.data.CorpKbn); // 画面に表示しない方
          }
          if (response.data.AccProKbn != undefined) {
            setAccProKbn(response.data.AccProKbn);
          }
          if (response.data.MiddleKbn != undefined) {
            setMiddleKbn(response.data.MiddleKbn);
          }
          if (response.data.MCalcKbn != undefined) {
            setMCalcKbn(response.data.MCalcKbn);
          }
          if (response.data.DepartmentUseKbn != undefined) {
            setDepartmentUseKbn(response.data.DepartmentUseKbn);
          }
          if (response.data.BasedType != undefined) {
            setBasedType(response.data.BasedType);
          }
          if (response.data.Kanikbn != undefined) {
            setKanikbn(response.data.Kanikbn);
          }
          if (response.data.ImportKbn != undefined) {
            setImportKbn(response.data.ImportKbn);
          }
          if (response.data.CopyClientYear != undefined) {
            setCopyClientYear(response.data.CopyClientYear);
          }
          if (response.data.YearCopyKbn != undefined) {
            setYearCopyKbn(response.data.YearCopyKbn);
          }
        }
        else if ((response.data) &&
          (response.data.ClientCode == -1)) {
          // 該当する会社データが存在した、かつ、会社データ作成不可能の場合
          setAllPurposePopupMessage(message.CreateCompanyData_Error_Insert_ExistbyOther);
          setAllPurposePopupActivated(true);
          // 会社コードにフォーカスをセットする
          if ((comCodeInputNumber != undefined) && (comCodeInputNumber.inputElement != undefined)) {
            comCodeInputNumber.inputElement.focus();
          }
          // エラーメッセージを閉じた際も、会社コードにフォーカスをセットする
          setFocusComCode(true);
        }
        else {
          // 該当する会社データが存在しなかった場合
          setMStDayString(kStDate.getDate().toString());
          setClientCode(0);
          setYearCopyKbn(0);
        }
      })
      .catch(error => {
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_InternalServerError });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  /**
   * 各種入力欄の値を変更した際の処理
   * @param event 値変更時のイベント
   */
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //console.debug('viewModel.ComCode:' + comCodeString);
    //console.debug('viewModel.LComName:' + lComName);
    //console.debug('viewModel.KanaComName:' + kanaComName);
    //console.debug('viewModel.SComName:' + sComName);
    //console.debug('viewModel.RenChar:' + renChar);
    //console.debug('viewModel.MStDay:' + mStDayString);
    switch (event.target.name) {
      case 'LComName':
        setLComName(event.target.value);
        setEdited(true);
        break;
      case 'KanaComName':
        setKanaComName(event.target.value);
        setEdited(true);
        break;
      case 'SComName':
        setSComName(event.target.value);
        setEdited(true);
        break;
      case 'RenChar':
        setRenChar(event.target.value);
        setEdited(true);
        break;
      case 'MStDay':
        setMStDayString(event.target.value);
        setEdited(true);
        break;
    }
  }

  const middleKbnList: React.ReactElement[] = [];
  dataMaps.MidtermClosingEnum.forEach(data => {
    const checked = (middleKbn === data.key);
    middleKbnList.push(
      <FormGroup check className='mr-5 my-label float-left' key={[data.key, 'middleKbn'].join('-')}>
        <Label check className='margin-right-0-5'>
          <Input
            type='radio'
            name='MiddleKbn'
            checked={checked}
            tabIndex={11}
            onChange={() => {
              handleOnMiddleKbnChange(data.key);
            }}
          />
          {data.value}
        </Label>
      </FormGroup>
    );
  });
  const handleOnMiddleKbnChange = (middleKbn: enums.MidtermClosingEnum) => {
    setMiddleKbn(middleKbn);
  };

  const kaniKbnList: React.ReactElement[] = [];
  KaniKbn.forEach(data => {
    const checked = (kanikbn === data.key);
    kaniKbnList.push(
      <FormGroup check className='mr-5 my-label float-left' key={[data.key, 'kaniKbn'].join('-')}>
        <Label check className='margin-right-0-5'>
          <Input
            type='radio'
            name='KaniKbn'
            checked={checked}
            tabIndex={12}
            onChange={() => {
              handleOnKaniKbnChange(data.key);
            }}
          />
          {data.value}
        </Label>
      </FormGroup>
    );
  });
  const handleOnKaniKbnChange = (kaniKbn: KaniKbnEnum) => {
    setKanikbn(kaniKbn);
  };

  /** B22 B23 会計処理区分ラジオボタン(群) */
  const accProKbnList: React.ReactElement[] = [];
  TaxInfoAccProKbn.forEach(data => {
    const checked = (accProKbn === data.key);
    accProKbnList.push(
      <FormGroup check className='mr-5 my-label float-left' key={[data.key, 'accProKbn'].join('-')}>
        <Label check className='margin-right-0-5'>
          <Input
            type='radio'
            name='AccProKbn'
            checked={checked}
            tabIndex={13}
            onChange={() => {
              handleOnAccProKbnChange(data.key);
            }}
            disabled={isTaxFree()}
          />
          {data.value}
        </Label>
      </FormGroup>
    );
  });

  /**
   * B22 B23 会計処理区分を変更した際の処理
   * @param middleKbn 中間決算区分(変更後の新しい値)
   */
  const handleOnAccProKbnChange = (accProKbn: TaxInfoAccProKbnEnum) => {
    setAccProKbn(accProKbn);
  };

  /**
   * B20 科目体系ComboBoxが初期化された際の処理
   * @param comboBox 初期化されたB20 科目体系ComboBox
   */
  const corpKbnInitialized = (initializedComboBox: wjInput.ComboBox) => {
    if (corpKbnCmb == undefined) {
      setCorpKbnCmb(initializedComboBox);
      // tabindexを設定する
      initializedComboBox.inputElement.tabIndex = 10;
    }
  };
  
  /**
   * B20 科目体系コンボボックスを変更した際の処理
   */
  const handleOnCorpKbnChange = () => {
    if ((corpKbnCmb != undefined) && (corpKbnCmb.selectedItem != undefined)) {
      let corpKbn: enums.CorpKbnEnum = parseInt(corpKbnCmb.selectedItem.key);
      setCorpKbn(corpKbn);
    }
  };

  /**
   * B33 輸入取引区分ComboBoxが初期化された際の処理
   * @param comboBox 初期化されたB33 輸入取引区分ComboBox
   */
  const importKbnInitialized = (initializedComboBox: wjInput.ComboBox) => {
    if (importKbnCmb == undefined) {
      setImportKbnCmb(initializedComboBox);
      // tabindexを設定する
      initializedComboBox.inputElement.tabIndex = 14;
    }
  };

  /**
   * B33 輸入取引区分コンボボックスを変更した際の処理
   */
  const handleOnImportKbnChange = () => {
    if ((importKbnCmb != undefined) && (importKbnCmb.selectedItem != undefined)) {
      let importKbn: enums.ImportKbnEnum = parseInt(importKbnCmb.selectedItem.key);
      setImportKbn(importKbn);
    }
  };

  /**
   * 会計期間(自)を変更した際の処理
   * @param event 会計期間(自)を変更した際のイベント
   */
  const handleOnChangeKStDate = (event: any) => {
    if (event.value) {
      let stDate: Date = event.value;
      let edDate: Date = calcKEdDate(stDate);
      setKStDate(stDate);
      setKEdDate(edDate);

      let stDay = stDate.getDate();
      setMStDayString(stDay.toString());
    }
  };

  /**
   * 会計期間(自)Blur(Focus out)イベント処理
   * @param event Blur(Focus out)イベント
   */
  const handleOnBlurKStDate = (event: any) => {
    if (event.value == null) {
      setKStDate(new Date());
    }
  };

  /**
   * 会計期間(自)に対応する会計期間(至)を算出する
   * @param stDate 会計期間(自)
   */
  function calcKEdDate(stDate: Date): Date {
    if (stDate) {
      let edDate: Date = new Date(stDate.getFullYear() + 1, stDate.getMonth(), stDate.getDate() - 1);
      return edDate;
    }
    return new Date();
  }

  function isLeapYear(year: number): boolean {
    let result = (new Date(year, 1, 29).getMonth() == 1);
    return result;
  }

  /**
   * 会社コード入力欄が初期化された際の処理
   * @param initializedInputNumber 初期化された会社コード入力欄
   */
  const initializedComCode = (initializedInputNumber: any) => {
    setComCodeInputNumber(initializedInputNumber);
    // tabindexを設定する
    initializedInputNumber.inputElement.tabIndex = 1;
    // firstFocusElementを設定する
    firstFocusElement.current = initializedInputNumber.inputElement;
  }

  /**
   * 会計期間(自)入力欄が初期化された際の処理
   * @param initializedInputDate 初期化された会計期間(自)入力欄
   */
  const initializedKStDate = (initializedInputDate: any) => {
    // tabindexを設定する
    initializedInputDate.inputElement.tabIndex = 7;
  }

  /**
   * 会社コードを変更した際の処理
     * @param control 変更時のコントロール
     * @param eventArgs 変更イベントの引数
   */
  function handleOnChangeComCode(control: any, eventArgs: any): void {
    setComCode(control.value);
  }

  /**
   * 会社コードBlur(Focus out)イベント処理
   * @param control Blur時のコントロール
   * @param eventArgs Blurイベントの引数
   */
  const handleOnBlurComCode = (control: any, eventArgs: any) => {
    if ((allPurposePopupActivated == false) && (focusComCode == false)) {
      if (ComCodeUtil.isValidNumber(control.value)) {
        setComCode(control.value);
        CreateOnGet(control.value);
      }
    }
  };

  /** 月次開始日Blur(Focus out)イベント処理 */
  const handleOnBlurMStDay = () => {
    let targetString = '';
    let targetNumber = 0;
    if ((mStDayString != undefined) || (mStDayString != null)) {
      if (0 < mStDayString.trim().length) {
        targetNumber = parseInt(mStDayString.trim(), 10);
        if (!isNaN(targetNumber)) {
          targetString = targetNumber.toString();
        }
      }
    }
    if (targetString == '') {
      targetString = kStDate.getDate().toString();
      targetNumber = kStDate.getDate();
    }
    setMStDayString(targetString);

    let yyyy: number = kStDate.getFullYear();
    let mm: number = kStDate.getMonth();
    let min: number = 1;
    let max: number = 2;
    switch (mm) {
      case 0: // 1月
      case 2: // 3月
      case 4: // 5月
      case 6: // 7月
      case 7: // 8月
      case 9: // 10月
      case 11: // 12月
        max = 31;
        break;
      case 3: // 4月
      case 5: // 6月
      case 8: // 9月
      case 10: // 11月
        max = 30;
        break;
      case 1: // 2月
        if (isLeapYear(yyyy)) {
          max = 29;
        }
        else {
          max = 28;
        }
        break;
    }

    if ((targetNumber < min) || (max < targetNumber)) {
      // エラーメッセージを閉じた際、月次開始日にフォーカスをセットする
      setFocusMStDay(true);

      // エラーメッセージを表示する
      setAllPurposePopupMessage(bindValueToMessage(message.CreateCompanyData_Error_MStDay_OutOfRange, [max.toString()]));
      setAllPurposePopupActivated(true);
    }
  };

  const handleAddCompanyConfirmClose = () => {
    setAddCompanyConfirmActivated(false);
  };
  const handleAddCompanyConfirmYes = () => {
    setAddCompanyConfirmActivated(false);
    AddOnPost();
  };
  const handleAddCompanyConfirmNo = () => {
    setAddCompanyConfirmActivated(false);
  };

  const handleCancelConfirmClose = () => {
    setCancelConfirmActivated(false);
  };
  const handleCancelConfirmYes = () => {
    setCancelConfirmActivated(false);
    initialize();
  };
  const handleCancelConfirmNo = () => {
    setCancelConfirmActivated(false);
  };

  /** 汎用メッセージを表示した際の処理 */
  const handleAllPurposePopupOpened = () => {
    // OKボタンへフォーカスをセットする
    if (refer2AllPurposePopupMessageButton.current != undefined) {
      refer2AllPurposePopupMessageButton.current.focus();
    }
  }

  /** 汎用メッセージを閉じた際の処理 */
  const handleAllPurposePopupClose = () => {
    setAllPurposePopupActivated(false);

    // 会社コードにフォーカスをセットする
    if (focusComCode == true) {
      setTimeout(() => {
        setFocusComCode(false);
        if ((comCodeInputNumber != undefined) && (comCodeInputNumber.inputElement != undefined)) {
          comCodeInputNumber.inputElement.focus();
        }
      }, 100);
    }
    // 月次開始日にフォーカスをセットする
    if (focusMStDay == true) {
      setFocusMStDay(false);
      if (refer2MStDay.current != undefined) {
        refer2MStDay.current.focus();
      }
    }
  };

  /** 汎用メッセージを閉じる際の処理 */
  const handleAllPurposePopupCloseClick = () => {
    setAllPurposePopupActivated(false);
  };

  /**
   * 会社名・簡略会社名Blur時(Focus out)の処理
   * @param event Blur時のイベント
   */
  const handleOnBlurComName = (event: React.FocusEvent<HTMLInputElement>) => {
    let valueAfter = event.target.value.trim();
    switch (event.target.name) {
      case 'LComName': // 会社名
        setLComName(valueAfter);
        break;

      case 'SComName': // 簡略会社名
        setSComName(valueAfter);
        break;
    }
  }

  /**
   * フリガナBlur時(Focus out)の処理
   * @param event Blur時のイベント
   */
  const handleOnBlurKanaComName = (event: React.FocusEvent<HTMLInputElement>) => {
    let valueBefore = event.target.value;
    let valueAfter = validateKanaRenChar(valueBefore);
    //console.debug('handleOnBlurKanaComName() before=' + valueBefore + ' after=' + valueAfter);
    setKanaComName(valueAfter);
  };

  /**
   * 連想入力Blur時(Focus out)の処理
   * @param event Blur時のイベント
   */
  const handleOnBlurRenChar = (event: React.FocusEvent<HTMLInputElement>) => {
    let valueBefore = event.target.value;
    let valueAfter = validateKanaRenChar(valueBefore);
    //console.debug('handleOnBlurRenChar() before=' + valueBefore + ' after=' + valueAfter);
    setRenChar(valueAfter);
  };

  const handleOnChangeYearCopyKbn = (event: any) => {
    setYearCopyKbn(event.target.checked ? 1 : 0);

    // チェックOFF→ONにした際、サーバから取得した過年度データの科目体系に戻す
    if ((event.target.checked) && (corpKbnPast != -1)) {
      setCorpKbn(corpKbnPast);
    }
  }

  return (
    <div className='create-company-data' ref={thisPage}>
      <LowerActionLayout className='vw-100 h-100'>
        <LowerActionLayoutHeader>
          <PageHeader
            pageTitle={Labels.AIKCMN003000001_FUNCTION_NAME /* A1 機能名ラベル */}
            onCloseClick={handleOnCloseClick /* A2 閉じるボタン */}
          >
          </PageHeader>
        </LowerActionLayoutHeader>
        <LowerActionLayoutContents className='vw-100 px-3 company-info-grid'>
          <div className='d-flex mb-3 grid-parent'>
            <div className='flex-auto'>
              <div>
                <div className='d-flex'>
                  <div className='table-th'><Label for='ComCode' className='label-control my-label'>{Labels.AIKCMN003000001_INPUT_ComCode /* B1 会社コード ラベル */}</Label></div>
                  <div className='table-td'>
                    <InputNumber
                      id={'ComCode'}
                      name={'ComCode'}
                      min={1}
                      max={99999999}
                      format={'D'}
                      className={'comCode'}
                      //tabIndex={1} initializedComCode()で設定する
                      value={comCode /* B2 会社コード入力テキストボックス */}
                      valueChanged={handleOnChangeComCode}
                      lostFocus={handleOnBlurComCode}
                      initialized={initializedComCode}
                    />
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='table-th'><Label for='LComName' className='label-control my-label'>{Labels.AIKCMN003000001_INPUT_LComName /* B3 会社名 ラベル */}</Label></div>
                  <div className='table-td'>
                    <Input
                      id='LComName'
                      name='LComName'
                      className='lComName'
                      tabIndex={2}
                      maxLength={60}
                      value={lComName /* B4 会社名入力テキストボックス */}
                      onChange={handleOnChange}
                      onBlur={handleOnBlurComName}
                    />
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='table-th'><Label for='KanaComName' className='label-control my-label'>{Labels.AIKCMN003000001_INPUT_KanaComName /* B5 フリガナ ラベル */}</Label></div>
                  <div className='table-td'>
                    <Input
                      id='KanaComName'
                      name='KanaComName'
                      className='kanaComName'
                      tabIndex={3}
                      maxLength={60}
                      value={kanaComName /* B6 フリガナ入力テキストボックス */}
                      onChange={handleOnChange}
                      onBlur={handleOnBlurKanaComName}
                    />
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='table-th'><Label for='SComName' className='label-control my-label'>{Labels.AIKCMN003000001_INPUT_SComName /* B7 簡略会社名 ラベル */}</Label></div>
                  <div className='table-td'>
                    <div className='float-left'>
                      <Input
                        id='SComName'
                        name='SComName'
                        className='sComName mr-3'
                        tabIndex={4}
                        maxLength={30}
                        value={sComName /* B8 簡略会社名入力テキストボックス */}
                        onChange={handleOnChange}
                        onBlur={handleOnBlurComName}
                      />
                    </div>
                    <div className='float-left'>
                      <Button
                        onClick={copyClick}
                        tabIndex={5}
                      >
                        {Labels.AIKADT007000001_BUTTON_COPY /* B9 会社名からコピーボタン */}
                      </Button>
                    </div>
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='table-th'><Label for='RenChar' className='label-control my-label'>{Labels.AIKCMN003000001_INPUT_RenChar /* B10 連想 ラベル */}</Label></div>
                  <div className='table-td'>
                    <Input
                      id='RenChar'
                      name='RenChar'
                      className='renChar'
                      tabIndex={6}
                      maxLength={12}
                      value={renChar /* B11 連想入力テキストボックス */}
                      onChange={handleOnChange}
                      onBlur={handleOnBlurRenChar}
                    />
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='table-th'><Label for='KStDate' className='label-control my-label'>{Labels.AIKCMN003000001_INPUT_KStDate /* B12 会計期間 ラベル */}</Label></div>
                  <div className='table-td'>
                    <div className='float-left'>
                      <InputDate
                        id='KStDate'
                        name='KStDate'
                        className='kStDate'
                        //tabIndex={7} initializedKStDate()で設定する
                        format={'yyyy/MM/dd'}
                        value={kStDate /* B13 会計期間自入力テキストボックス */}
                        defaultValue={null}
                        isRequired={false}
                        isDisabled={false}
                        valueChanged={handleOnChangeKStDate}
                        lostFocus={handleOnBlurKStDate}
                        initialized={initializedKStDate}
                      />
                    </div>
                    <div className='float-left'>
                      <Label className='label-control my-label padding-like-a-form-control'>{Labels.AIKCMN003000001_INPUT_Hani /* B14 ～ ラベル */}</Label>
                    </div>
                    <div className='float-left'>
                      <InputDate
                        id='KEdDate'
                        name='KEdDate'
                        className='kEdDate'
                        //tabIndex={8}
                        format={'yyyy/MM/dd'}
                        value={kEdDate /* B15 会計期間至入力テキストボックス */}
                        defaultValue={null}
                        isRequired={false}
                        isDisabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='table-th'><Label for='MStDay' className='label-control my-label'>{Labels.AIKCMN003000001_INPUT_MStDay /* B16 月次開始日 ラベル */}</Label></div>
                  <div className='table-td'>
                    <div className='float-left'>
                      <Input
                        id='MStDay'
                        name='MStDay'
                        className='mStDay'
                        tabIndex={9}
                        value={mStDayString /* B17 月次開始日入力テキストボックス */}
                        onChange={handleOnChange}
                        onBlur={handleOnBlurMStDay}
                        innerRef={refer2MStDay}
                      />
                    </div>
                    <div className='float-left'>
                      <Label className='label-control my-label padding-like-a-form-control'>{Labels.AIKCMN003000001_INPUT_Day /* B18 日 ラベル */}</Label>
                    </div>
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='table-th'><Label for='CorpKbn' className='label-control my-label'>{Labels.AIKCMN003000001_INPUT_CorpKbn /* B19 科目体系 ラベル */}</Label></div>
                  <div className='table-td'>
                    <div className='float-left'>
                      <ComboBox
                        id={'CorpKbn'}
                        name={'CorpKbn'}
                        className='corpKbn'
                        //tabIndex={10} corpKbnInitialized()で設定する
                        itemsSource={corpKbnItemsSource}
                        displayMemberPath={'value'}
                        selectedValuePath={'key'}
                        selectedValue={corpKbn /* B20 科目体系コンボボックス */}
                        initialized={corpKbnInitialized}
                        onSelectedIndexChanged={handleOnCorpKbnChange}
                        isDisabled={yearCopyKbn == 1 ? true : false}
                      />
                    </div>
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='table-th'><Label for='MiddleKbn' className='label-control my-label'>{Labels.AIKCMN003000001_INPUT_MiddleKbn /* B21 中間決算区分 ラベル */}</Label></div>
                  <div className='table-td'>
                    <div className='float-left'>
                      {middleKbnList /* B22 なし - B23 半期決算 - B24 四半期決算 */}
                    </div>
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='table-th'><Label for='KaniKbn' className='label-control my-label'>{Labels.AIKCMN003000001_INPUT_KaniKbn /* B25 消費税区分 ラベル */}</Label></div>
                  <div className='table-td'>
                    <div className='float-left'>
                      {kaniKbnList /* B26 原則課税 - B27 簡易課税 - B28 免税 */}
                    </div>
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='table-th'><Label for='AccProKbn' className='label-control my-label'>{Labels.AIKCMN003000001_INPUT_AccProKbn /* B29 会計処理区分 ラベル */}</Label></div>
                  <div className='table-td'>
                    <div className='float-left'>
                      {accProKbnList /* B30 税抜 - B31 税込 */}
                    </div>
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='table-th'><Label for='ImportKbn' className='label-control my-label'>{Labels.AIKCMN003000001_INPUT_ImportKbn /* B32 輸入取引区分 ラベル */}</Label></div>
                  <div className='table-td'>
                    <div className='float-left'>
                      <ComboBox
                        id={'ImportKbn'}
                        name={'ImportKbn'}
                        className='importKbn'
                        //tabIndex={14} importKbnInitialized()で設定する
                        itemsSource={importKbnItemsSource}
                        displayMemberPath={'value'}
                        selectedValuePath={'key'}
                        selectedValue={importKbn /* B33 輸入取引区分コンボボックス */}
                        initialized={importKbnInitialized}
                        onSelectedIndexChanged={handleOnImportKbnChange}
                        isDisabled={isTaxFree()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex mb-3 pl-4'>
            <Input
              id='YearCopyKbn'
              name='YearCopyKbn'
              type='checkbox'
              checked={yearCopyKbn == 1 ? true : false}
              onChange={handleOnChangeYearCopyKbn}
              disabled={isNewCompany()}
              tabIndex={15}
            />
            <Label for='YearCopyKbn' className='label-control my-label'>{Labels.AIKCMN003000001_INPUT_YearCopyKbn /* B34 過年度データからマスタをコピーして作成する ラベル */}</Label>
          </div>

        </LowerActionLayoutContents>

        <LowerActionLayoutAction className='px-3 pb-3 company-data-executing-cancel'>
          <div className={'d-flex justify-content-between'}>
            <div className='justify-content-start'>
            </div>
            <div className={'ml-4'}>
              <ExecutingCancel
                executeLabel={Labels.COMMON_BUTTON_FUNCTION_SAVE /* B35 保存ボタン */}
                executeTabIndex={16}
                onExecuteClick={handleOnExecuteClick}
                executeDisabled={ComCodeUtil.isValidNumber(comCode) ? false : true}

                cancelLabel={Labels.COMMON_BUTTON_FUNCTION_CANCEL /* B36 取消ボタン */}
                cancelTabIndex={17}
                cancelRef={lastFocusElement}
                onCancelClick={handleOnCancelClick}
                cancelDisabled={ComCodeUtil.isValidNumber(comCode) ? false : true}
              />
            </div>
          </div>
        </LowerActionLayoutAction>

        <ConfirmationPopup
          isOpen={addCompanyConfirmActivated}
          onClose={handleAddCompanyConfirmClose}
          onYesClick={handleAddCompanyConfirmYes}
          onNoClick={handleAddCompanyConfirmNo}
        >
          {message.CreateCompanyData_Confirmation_Insert}
        </ConfirmationPopup>

        <ConfirmationPopup
          isOpen={cancelConfirmActivated}
          onClose={handleCancelConfirmClose}
          onYesClick={handleCancelConfirmYes}
          onNoClick={handleCancelConfirmNo}
        >
          {message.Common_Confirmation_Cancel}
        </ConfirmationPopup>

        <Popup
          header={Labels.AIKCMN003000001_FUNCTION_NAME}
          isOpen={allPurposePopupActivated}
          onOpened={handleAllPurposePopupOpened}
          onClosed={handleAllPurposePopupClose}
          onCloseClick={handleAllPurposePopupCloseClick}
          footer={
            <div className='w-100 text-center'>
              <Button
                color='primary'
                className='mx-auto px-5 py-1'
                onClick={handleAllPurposePopupCloseClick}
                innerRef={refer2AllPurposePopupMessageButton}
                tabIndex={202}
              >
                {Labels.AIKCMN001000001_BUTTON_FUNCTION_OK}
              </Button>
            </div>
          }
          closeIconTabIndex={201}
        >
          {allPurposePopupMessage}
        </Popup>

      </LowerActionLayout>
    </div>
  );
}
export default CreateCompanyData;