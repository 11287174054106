import React, { useImperativeHandle } from 'react';
import Axios from 'axios';
import * as wjInput from 'wijmo/wijmo.input';
import { AutoComplete, ComboBox } from 'wijmo/wijmo.react.input';
import { BmnInformationViewModel } from '../../../models/bmnInformationViewModel';
import ListSelectButton from './ListSelectButton';
import RangeSelectItems from './RangeSelectItems';
import * as wijmo from 'wijmo/wijmo';
import { CodeAttr, MasterKbn } from "../../../VKZ/core/constants/constant";
import * as constants from '../../../constants/constant';
import * as labels from '../../../constants/labels';
import { setIsLoading, getLodingStatus } from '../../../stores/NowLoading';
import { compareObject } from "../../../utils/objectUtils";

// 科目情報取得URL
export const accountInformationURL = 'api/v1/AuditTargetManager/GetAccounts';
// 部門情報取得URL
export const bmnInformationURL = 'api/v1/AuditTargetManager/GetBmn';
// ﾏｽﾀ基本情報取得URL
export const masterInfoURL = 'api/v1/AuditTargetManager/GetMasterInfo';

// 監査対象選択アイテムValueObject
export class AuditTargetManagerVo {
  // 監査対象
  public target?: number;
  // 部門リスト
  public items?: Array<BmnInformationViewModel>;
  // Exception
  public Exception?: number;
}

/** 画面プロパティ */
export type AuditTargetManagerProps = {
  // REF
  forwardRef?: React.RefObject<any>;
  // コントロール識別名
  name: string;
  // 選択アイテム通知関数
  transmitSelectItems: (items: AuditTargetManagerVo) => void;
  // 部門リスト通知関数
  transmitBmnList?: (list: Array<BmnInformationViewModel>) => void;
  // 部門選択コンボボックス使用フラグ
  applyCombo?: boolean;
  // 部門範囲選択使用フラグ
  applyRange?: boolean;
  // 初期選択アイテム
  defaultSelectItem?: AuditTargetManagerVo;
  // disabled
  disabled?: boolean;
  // tabIndex
  tabIndex?: number;
};

// 監査対象のID
const auditTargetCmbId = 'AuditTargetManager-auditTargetCmb';
// 部門コンボ単独のID
const bmnCmbId = 'AuditTargetManager-bmnCmb';

/**
 * 監査対象制御コンポーネント
 * @param props
 */
const AuditTargetManager: React.FC<AuditTargetManagerProps> = props => {
  const { forwardRef, name, transmitSelectItems, applyCombo, applyRange, transmitBmnList, disabled, defaultSelectItem } = props;
  // 監査対象リスト
  const [auditList, setAuditList] = React.useState([{}]);
  // 部門リスト
  const [bmnList, setBmnList] = React.useState([{}]);
  // 部門コンボボックス表示フラグ
  const [comboDisplay, setComboDisplay] = React.useState(false);
  // 部門範囲選択表示フラグ
  const [rangeDisplay, setRangeDisplay] = React.useState(false);
  // 部門領域表示フラグ
  const [visible, setVisible] = React.useState(false);
  // 監査対象選択アイテム
  const [auditTarget, setAuditTarget] = React.useState({});
  // 監査対象アイテム選択インデックス
  const [auditIndex, setAuditIndex] = React.useState(0);
  // 部門選択アイテム
  const [bmnSeletctedItem, setBmnSeletctedItem] = React.useState({});
  // 部門コンボボックス選択インデックス
  const [bmnIndex, setBmnIndex] = React.useState(0);
  // 監査対象コンボボックスdisabled
  const [withoutBmn, setWithoutBmn] = React.useState(false);
  // 部門コンボボックスREF
  const bmnComboRef = React.useRef<wjInput.ComboBox>();
  // 部門コンボボックスREF
  const bmnRangeRef = React.useRef<any>();
  // 部門コンボボックステンプレート
  const rightTemplate = `<table cellpadding="3"><tr><td align="right" width="90">{BmnGCode}</td><td>{SimpleName}</td></tr></table>`;
  const leftTemplate = `<table cellpadding="3"><tr><td align="left" width="90">{BmnGCode}</td><td>{SimpleName}</td></tr></table>`;
  var bmnCmbTemplate = leftTemplate;
  // 初期設定完了フラグ
  const [initialValue, setInitialValue] = React.useState(false);
  // 選択範囲初期値
  const [rangeItems, setRangeItems] = React.useState([{}]);
  // コード属性
  const [codeAttr, setCodeAttr] = React.useState(0);
  // 通信中フラグ
  const [isScreenLock, setIsScreenLock] = React.useState(false);

  /** 表示得データ取得処理 */
  async function initial(forced: boolean = false) {
    // ローディング中表示ON
    setIsScreenLock(true);
    setIsLoading(true);
    if (forced) {
      window.accountCount = undefined;
      window.masInfo = undefined;
      window.bmnInfo = undefined
    }
    // 科目情報取得
    var accountCount = window.accountCount || await getAccounts();
    // ﾏｽﾀ基本情報取得
    var masInfo = window.masInfo || await getBmnDisplayName();
    // 部門情報
    var bmnInfo;
    // コード属性設定
    setCodeAttr(masInfo.data['CodeAttr']);
    if (masInfo.data['CodeAttr'] == CodeAttr.Number || masInfo.data['CodeAttr'] == CodeAttr.NumberZeroFill) {
      bmnCmbTemplate = rightTemplate;
    }
    // 部門採用している科目が存在してかつﾏｽﾀ基本情報の使用区分が0でない場合
    if (accountCount.data > 0 && masInfo.data['UseKbn'] != 0) {
      // 部門情報取得
      bmnInfo = window.bmnInfo || await getBmn();
      // 部門情報を更新
      setBmnList(bmnInfo.data);
    } else {
      // 部門採用なしの場合、監査対象コンボボックスを使用不可に設定
      setWithoutBmn(true);
    }
    // 監査対象コンボボックス設定
    setAuditList([{
      dispName: labels.AIKCMN0010000013_LABEL_SUBJECTS,
      target: MasterKbn.CountingKmk
    }, {
        dispName: masInfo.data['JHojyoName'],
        target: MasterKbn.Bmn
    }]);
    window.accountCount = accountCount;
    window.masInfo = masInfo;
    window.bmnInfo = bmnInfo
    // ローディング中表示OFF
    setIsScreenLock(false);
    setIsLoading(false);
  }
  /** 部門採用している科目件数取得 */
  async function getAccounts() {
    // 部門採用科目件数GET
    return await Axios.get(accountInformationURL);
  }
  /** 部門情報取得 */
  async function getBmn() {
    // 部門情報GET
    return await Axios.get(bmnInformationURL);
  }
  /** ﾏｽﾀ基本情報取得 */
  async function getBmnDisplayName() {
    return await Axios.get(masterInfoURL);    
  }
  /** 呼び出し可能functionを定義 */
  useImperativeHandle(forwardRef, () => ({
    initial
  }));
  /**
   * 監査対象コンボボックスリスト変更イベント
   * @param e
   */
  var handleAuditTargetItemsSourceChanged = (e: wjInput.ComboBox) => {
    // 監査対象コンボボックスの内容が変更された場合初期設定をやり直す
    setInitialValue(false);
    // 初期選択が設定されている場合は初期値を選択する
    if (defaultSelectItem && defaultSelectItem.target) {
      // 比較key列抽出
      var targetItems = (e.itemsSource as Array<any>).map((data) => {
        return data['target'];
      });
      // 初期選択
      e.selectedIndex = searchIndex(targetItems, defaultSelectItem.target);
    }
  }
  /**
   * 監査対象コンボボックス変更イベントhandler
   * @param e
   */
  const handleSelectedIndexChanged = (e: wjInput.ComboBox) => {
    // 監査対象選択indexを設定
    setAuditIndex(e.selectedIndex);
    // 選択アイテムを設定
    setAuditTarget(e.selectedItem);
    // 部門選択表示切替
    setVisible(e.selectedItem.target == MasterKbn.Bmn);
  }
  /**
   * 部門コンボボックスリスト変更handler
   * @param e
   */
  const handleBmnComboItemsSourceChanged = (e: wjInput.ComboBox) => {
    // 初期選択が設定されている場合は初期値を選択する
    if (defaultSelectItem && defaultSelectItem.target) {
      if (defaultSelectItem.items) {
        // 初期選択
        e.selectedIndex = searchIndex(e.itemsSource as Array<any>, defaultSelectItem.items[0]);
        setRangeItems(defaultSelectItem.items);
      } else {
        e.selectedIndex = -1;
      }
    }
  }
  /**
   * リスト検索
   * @param items 検索対象リスト
   * @param target 検索値
   */
  const searchIndex = (items: Array<any>, target: any) => {
    // フラグ
    var flg = false;
    // インデックス
    var index = -1;
    // リスト内を検索
    items.some(r => {
      // インデックスを増加
      index++;
      // 一致する値があればループを抜ける
      return flg = compareObject(r, target);
    });
    // 一致する値がない場合は-1を返却
    return !flg ? -1 : index;
  }
  /**
   * 部門コンボボックスフォーカスアウトhandler
   * @param control
   * @param eventArgs
   */
  const handleBmnComboLostFocus = (control: any, eventArgs: any) => {
    var flg = false;
    var target = null;
    var targetIndex = -1;
    control.collectionView.filter = null;
    (control.itemsSource as Array<any>).some(item => {
      // 表示名一覧と入力内容を比較
      // 一覧と入力内容が一致する場合はループ終了
      target = item;
      targetIndex++;
      return flg = item['SimpleName'] == control.text;
    });
    if (!flg) {
      // 一覧と入力内容が一致しない場合は、入力値を空にする
      control.text = '';
    } else if (target && control.selectedItem == null) {
      // 部門コンボindexの設定
      setBmnIndex(targetIndex);
      // 選択値を通知する
      setBmnSeletctedItem(target);
    }
  }
  /**
   * 部門コンボボックス選択変更handler
   * @param e
   */
  const handleSelectBmnChanged = (e: wjInput.ComboBox) => {
    if (bmnIndex !== e.selectedIndex) {
      setBmnIndex(e.selectedIndex);
    }
    // 選択値を通知する
    setBmnSeletctedItem({ ...e.selectedItem });
  }
  /**
   * 部門切替ボタン選択変更通知
   * @param v
   */
  const noticeListSelectButton = (v: any) => {
    var cnt = -1;
    bmnList.some(bmn => {
      cnt++;
      return compareObject(bmn, v);
    });
    setBmnIndex(cnt);
  }
  /**
   * 選択アイテム通知
   * @param e
   */
  const rangeSelect = (e?: any) => {
    var selectItems = e as Array<any>;
    // 通知アイテムを作成
    var item: AuditTargetManagerVo = {
      target: MasterKbn.Bmn,
      items: selectItems
    }
    if (e != undefined && selectItems.length == 0) {
      item.Exception = constants.AUDITTARGETEXCEPTION_RANGEERR;
      // エラー通知
      transmitSelectItems(item);
    } else if (applyRange) {
      // 表示されていて範囲が正常の場合通知する
      setRangeItems(e);
    }
  }
  /**
   * 選択値の変更
   */
  const formatItem = () => {
    // 初期選択が設定されている場合
    if (defaultSelectItem && defaultSelectItem.target
      && auditList.length > 0 && Object.keys(auditList[0]).length > 0) {
      var selectItem: AuditTargetManagerVo = {};
      // 監査対象から選択するインデックスを取得する
      var targetItems = auditList.map((data) => {
        return data['target'];
      });
      // コンボボックスの選択を変更する
      var index = searchIndex(targetItems, defaultSelectItem.target)
      selectItem.target = auditList[index]['target'];
      setAuditIndex(index);
      // 部門コンボボックスの設定
      if (defaultSelectItem.items && (applyRange || applyCombo)) {
        // 初期選択
        setBmnIndex(searchIndex(bmnList, defaultSelectItem.items[0]));
        setRangeItems(defaultSelectItem.items);
        selectItem.items = applyRange ? defaultSelectItem.items : [defaultSelectItem.items[0]];
      } else {
        // 設定されていない場合、空を選択
        setBmnIndex(-1);
      }
      setInitialValue(false);
      // 設定内容を通知する
      transmitSelectItems(selectItem);
    }

    // WijmoのコンボボックスにTabIndexを設定してもTab遷移がうまくいかない為
    // コンボボックス内のテキストボックスにTabIndexを設定する
    if (props.tabIndex) {
      //監査対象コンボ
      const auditTargetCmbElement = document.getElementById(auditTargetCmbId);
      if (auditTargetCmbElement) {
        auditTargetCmbElement.tabIndex = -1;
        const textElement = auditTargetCmbElement.getElementsByTagName('input');
        const elements = Array.from(textElement);
        for (let item of elements) {
          if (item.type === 'text') {
            item.tabIndex = props.tabIndex;
            break;
          }
        }
      }
      //部門コンボ
      const bmnCmbElement = document.getElementById(bmnCmbId);
      if (bmnCmbElement) {
        bmnCmbElement.tabIndex = -1;
        const textElement = bmnCmbElement.getElementsByTagName('input');
        const elements = Array.from(textElement);
        for (let item of elements) {
          if (item.type === 'text') {
            item.tabIndex = props.tabIndex;
            break;
          }
        }
      }
    }
  }
  /** 初期表示処理 */
  React.useEffect(() => {
    // コンボボックス使用フラグ設定
    setComboDisplay(applyCombo != undefined ? applyCombo : false);
    // 範囲選択使用フラグ設定
    setRangeDisplay(applyRange != undefined ? applyRange : false);
    // 部門選択の表示を設定する
    setVisible(auditTarget['target'] == MasterKbn.Bmn);
  }, [applyCombo, applyRange]);
  /** 部門表示フラグ変更時処理 */
  React.useEffect(() => {
    // 初期設定が完了していない場合は、コンボボックスをクリアしない
    if (defaultSelectItem && !initialValue) {
      // 表示された際に初期設定が完了する
      setInitialValue(!initialValue);
    } else {
      // 部門コンボボックスの内容クリア
      setBmnSeletctedItem({});
      bmnComboRef.current!['control'].text = "";
      // 部門範囲の内容クリア
      setRangeItems([{}]);
    }
    if (visible) {
      // 表示されたコントロールにフォーカスを移動する
      if (comboDisplay) {
        // 部門コンボボックスにフォーカス移動
        bmnComboRef.current!['control'].focus();
      } else if (rangeDisplay) {
        // 部門範囲選択にフォーカス移動
        bmnRangeRef.current.setFocus();
      }
    }
  }, [visible]);
  /** 部門選択アイテム変更時処理 */
  React.useEffect(() => {
    var item: AuditTargetManagerVo = {
      target: MasterKbn.Bmn,
      items: [bmnSeletctedItem]
    };
    if (!bmnSeletctedItem.hasOwnProperty('BmnGCode')
      || !bmnSeletctedItem.hasOwnProperty('SimpleName')
    ) {
      item.items = undefined;
    }
    // 部門コンボボックスが表示されている場合
    if (auditTarget.hasOwnProperty('target') && auditTarget['target'] != 1 && applyCombo) {
      // 通知
      transmitSelectItems(item);
    }
  }, [bmnSeletctedItem]);
  /** 初期処理起動フラグ変更時処理 */
  React.useEffect(() => {
    // 初期処理起動
    initial();
  }, []);
  /** 初期選択、監査対象リスト変更処理 */
  React.useEffect(() => {
    formatItem();
  }, [defaultSelectItem, auditList])
  // 部門リスト変更時処理
  React.useEffect(() => {
    if (bmnList[0] != null && bmnList[0].hasOwnProperty('BmnGCode') && transmitBmnList) {
      // 部門リスト通知
      transmitBmnList(bmnList);
    }
  }, [bmnList]);
  // 監査対象変更時処理
  React.useEffect(() => {
    if (auditTarget.hasOwnProperty('target') && defaultSelectItem) {
      // 選択アイテムを通知する
      var items: AuditTargetManagerVo = {
        target: auditTarget['target']
      }
      transmitSelectItems(items);
    }
  }, [auditTarget]);
  // 部門範囲選択変更時処理
  React.useEffect(() => {
    // 通知アイテムを作成
    var item: AuditTargetManagerVo = {
      target: MasterKbn.Bmn,
      items: rangeItems
    }
    if (rangeItems != undefined && rangeItems.length == 0) {
      item.Exception = 1;
    }
    // 表示されている場合
    if (auditTarget.hasOwnProperty('target') && auditTarget['target'] != 1 && applyRange) {
      // 通知
      transmitSelectItems(item);
    }
  }, [rangeItems]);
  // 通信表示監視処理
  React.useEffect(() => {
    if (isScreenLock) {
      setIsLoading(true);
    }
  }, [getLodingStatus()]);
  return (
    <div className='audit-target' >
      {/*監査対象コンボボックス*/}
      <div className='d-inline'>
        <span className='audit-target-space'>{labels.AIKCMN0010000013_LABEL_Target}</span>
        <ComboBox
          id={auditTargetCmbId}
          className='audit-target-space'
          itemsSource={auditList}
          headerPath={'dispName'}
          displayMemberPath={'dispName'}
          selectedIndex={auditIndex}
          selectedIndexChanged={handleSelectedIndexChanged}
          isDisabled={disabled || withoutBmn}
          itemsSourceChanged={handleAuditTargetItemsSourceChanged}
        />
      </div>
      {/*部門選択コンボボックス*/}
      <div
        className={comboDisplay && visible ? 'd-inline show' : 'non-display'}
      >
        <AutoComplete
          id={bmnCmbId}
          className='audit-target-space'
          itemsSource={bmnList}
          headerPath={"SimpleName"}
          displayMemberPath={"SimpleName"}
          isEditable={true}
          isRequired={false}
          ref={bmnComboRef}
          lostFocus={handleBmnComboLostFocus}
          selectedIndex={bmnIndex}
          selectedIndexChanged={handleSelectBmnChanged}
          formatItem={function (s: any, e: any) {
            var html = wijmo.format(bmnCmbTemplate, e.data, function (data :any, name: any, fmt: any, val: any) {
              return wijmo.isString(data[name]) ? wijmo.escapeHtml(data[name]) : val;
            });
            e.item.innerHTML = html;
          }}
          isDisabled={disabled}
          itemsSourceChanged={handleBmnComboItemsSourceChanged}
          maxItems={bmnList.length}
        />
        {/*部門切替ボタン*/}
        <div
          className='d-inline audit-target-space'
        >
          <ListSelectButton
            value={bmnSeletctedItem}
            target={bmnList}
            onClick={noticeListSelectButton}
            disabled={disabled}
            tabIndex={(props.tabIndex ?? 0)}
          />
        </div>
      </div>
      {/*部門範囲一覧*/}
      <div
        className={rangeDisplay && visible ? 'audit-targt-interval show' : 'non-display'}
      >
        <RangeSelectItems
          name={name}
          items={bmnList}
          onSelect={rangeSelect}
          bindingCode={'BmnGCode'}
          bindingName={'SimpleName'}
          disabled={disabled}
          selectItems={rangeItems}
          forwardRef={bmnRangeRef}
          codeAttr={codeAttr}
          tabIndex={(props.tabIndex ?? 0)}
        />
      </div>
    </div>
  );
};
export default AuditTargetManager;
