import React from 'react';
import reactn from 'reactn';
import axios from 'axios';
import FormGroup from 'reactstrap/lib/FormGroup';
import AngleCircleFrameLinee from '../../../../components/atoms/AngleCircleFrameLine/AngleCircleFrameLine';
import Popup from '../../../../components/molecules/CMN/Popup/Popup';
import IconButton from '../../../../components/molecules/CMN/IconButton/IconButton';
import FileExcel from '../../../../components/atoms/Icon/FileExcel';
import Close from '../../../../components/atoms/Icon/Close';
import CommonGrid from '../../../../components/molecules/CMN/Grid/CommonGrid';
import ExcelOutputsetting from '../../../organisms/A000/ExcelOutputsetting';
import * as labels from '../../../../constants/labels';
import { AuditResultChecklistViewModel } from '../../../../models/auditResultChecklistViewModel';
import { Label } from 'reactstrap';

export const initialUrl = '/api/v1/AuditResultCheckList/Initial';

type AuditResultCheckListProps = {
  activated: boolean;
  month: number;
  onClose: () => void;
};

const AuditResultCheckList: React.FC<AuditResultCheckListProps> = props => {
  const { activated, month, onClose } = props;

  const [viewModel, updateViewModel] = React.useState<AuditResultChecklistViewModel>({
    TMonth: '',
    Results: [],
    NoticeResults: []
  });
  const [, setErrorMessage] = reactn.useGlobal('errorMessage');

  const [excelPopupActivated, setExcelPopupActivated] = React.useState(false);

  const handleOnExcelCloseClick = () => {
    setExcelPopupActivated(false);
  };
  const handleOnExcelClick = () => {
    setExcelPopupActivated(true);
  };

  //Excel出力時に使用
  const templateId = 'AIKADT002000012_E01'; //監査結果[チェックリスト・特記事項]

  //初期処理
  const initialOnGet = (url: string, month: number) => {
    const encodedUrl = [url, encodeURI(month.toString())].join('/');
    axios
      .get(encodedUrl)
      .then(response => {
        updateViewModel(response.data);
      })
      .catch(error => {
        setErrorMessage(error.message);
      });
    return viewModel;
  };
  React.useEffect(() => {
    if (activated) {
      initialOnGet(initialUrl, month);
    }
  }, [initialUrl, month, activated]);

  const noticeSource = viewModel.NoticeResults;
  const noticeList: React.ReactElement[] = [];

  if (typeof noticeSource !== 'undefined') {
    noticeSource.forEach(data => {
      noticeList.push(
        <FormGroup key={['notice', data.No].join('-')} className='mr-1'>
          <Label className='font-weight-bold'>
            {labels.AIKADT002000012_GRID_HEADER_NOTICE +
            labels.AIKADT002000012_INPUT_HEADER_OPENINGPARENTHESES +
            data.No + //ViewModel定義書の連番より設定
              labels.AIKADT002000012_INPUT_HEADER_CLOSINGPARENTHESIS}
          </Label>
          <AngleCircleFrameLinee className='px-1 white-space-pre-wrap -notice'>{data.Notice}</AngleCircleFrameLinee>
        </FormGroup>
      );
    });
  }

  //返却値　画面表示
  return typeof viewModel.Results === 'undefined' || viewModel.Results.length < 1 ? (
    <div className='d-none' />
  ) : (
    <div>
      <Popup
        id='audit-result-check-list'
        notMaxHeight={true}
        size='xl'
        isOpen={activated}
        header={
          <div>
            {labels.AIKADT002000012_FUNCTION_NAME} &ensp;
            <span>
              {labels.AIKADT002000012_FUNCTION_HEADER_TARGETMONTH}
              {viewModel.TMonth}月
            </span>
          </div>
        }
        headerIcon={
          <div>
            <IconButton icon={<FileExcel className='icon-lg' />} onClick={handleOnExcelClick} />
            <IconButton icon={<Close className='icon-lg' />} className='ml-2' onClick={onClose} />
          </div>
        }
      >
        <div className='-wjgrid'>
          <CommonGrid columns={glidColoms} sources={viewModel.Results} />
        </div>
        <div className='-notices overflow-auto mt-3'>{noticeList}</div>
      </Popup>

      <ExcelOutputsetting activated={excelPopupActivated} onClose={handleOnExcelCloseClick} templateId={templateId} />
    </div>
  );
};
export default AuditResultCheckList;

const glidColoms = [
  {
    header: labels.AIKADT002000012_GRID_HEADER_SEQUENCE,
    binding: 'No',
    width: 60,
    isReadOnly: true,
    align: 'center'
  },
  {
    header: labels.AIKADT002000012_GRID_HEADER_CONFIRMATION,
    binding: 'ConfFlg',
    width: 60,
    isReadOnly: true,
    align: 'center'
  },
  {
    header: labels.AIKADT002000012_GRID_HEADER_CHECKITEMS,
    binding: 'CklContent',
    width: '*',
    minWidth: 300,
    isReadOnly: true
  },
  {
    header: labels.AIKADT002000012_GRID_HEADER_COMMENT,
    binding: 'Comment',
    width: '*',
    minWidth: 300,
    isReadOnly: true
  }
];
