export class Messages {
    /**
     * 商品名
     */
    public static readonly Productname = {
        /** 商品名 */
        PRODUCTNAME: ["かんたんクラウド"],
    };

    /**
     * 共通
     */
    public static readonly Common = {
        /** なし */
        NOTHING: ["なし"],
        /** あり */
        SETTING: ["あり"],
        /** 全て */
        ALL: ["全て"],
        /** 予算 */
        BUDGET: ["予算"],
        /** 出力パターン */
        OUTPUTPTN: ["出力パターン"],
        /** 月次 */
        MONTHLY: ["月次"],
        /** 月次（A4縦） */
        MONTHLYA4VERTICAL: ["月次（A4縦）"],
        /** 年次 */
        ANNUAL: ["年次"],
        /** 年次（決算あり） */
        ANNUALFOURTHQUARTER: ["年次（決算あり）"],
        /** 出力形式 */
        OUTPUTLAYOUT: ["出力形式"],
        /** 出力順序 */
        OUTPUTORDER: ["出力順序"],
        /** 範囲 */
        RANGE: ["範囲"],
        /** 印刷制御 */
        PRINTCONTROL: ["セキュアモードのため使用できません。\nフルアクセスモードでログインしなおしてください。"],

        /**
         * 選択システム区分
         */
        Selectedsystemdiv: {
            /** 会計 */
            VKZ: ["会計"],
            /** 給与 */
            PAYROLL: ["給与"],
        },

        /**
         * 検証
         */
        Validate: {
            /** データなし */
            NODATA: ["該当データがありません。"],
        },

        /**
         * エラー
         */
        Error: {
            /** 範囲 */
            RANGE: ["範囲指定が不正です"],
            /** 選択期間 */
            SELECTPERIOD: ["選択期間が不正です"],
            /** 日付 */
            DATE: ["日付が不正です"],
            /** 重複 */
            DUPLICATE: ["同一の{0};がすでに存在しています。"],
            /** 存在しない */
            NOTFOUND: ["{0};が存在しません。"],

            /**
             * パスワード
             */
            Password: {
                /** 入力なし */
                NOINPUT: ["パスワードを入力してください。"],
                /** 一致しない */
                NOTMATCH: ["パスワードと確認のパスワードが一致しません。"],
                /** 規約違反 */
                VIOLATIONOFREGULATIONS: ["パスワードは8文字以上20文字以内かつ、アルファベットの大文字・小文字を含めてください。"],
                /** 禁則文字の使用 */
                USEPROHIBITEDCHARACTERS: ["パスワードに使用できない文字が使われています。"],
            },

            /**
             * パスワード（確認）
             */
            Passwordconfirm: {
                /** 入力なし */
                NOINPUT: ["パスワード（確認用）を入力してください。"],
            },

            /**
             * 排他
             */
            Exclusion: {
                /** 更新済み */
                UPDATED: ["該当データはすでに更新済みのため処理できません。"],
                /** ロック */
                LOCKED: ["データロック中です。\n以下の処理が実行中のため、データがロックされています。\n・会計事務所からデータ移行が行われている\n・マスター再計算が行われている\n・決算更新が行われている\n・バックアップまたはリストアが行われている\n・会社データが削除された可能性があります"],
            },

            /**
             * データ無し
             */
            Nodata: {
                /** 更新済み */
                UPDATED_WINDOWS: ["データが更新されている可能性があります。表示モードより表示更新を行ってから操作してください。"],
            },

            /**
             * サーバー
             */
            Server: {
                /** 接続 */
                CONNECTION: ["サーバーとの通信でエラーが発生しました。"],
            },

            /**
             * マスター登録権利
             */
            Masterregistrationright: {
                /** 決算確定済み */
                FINISHEDCLOSING: ["決算確定済みの為参照のみです。"],
            },

            /**
             * バランスチェック
             */
            Balancecheck: {
                /** 棚卸科目 */
                STOCKTAKINGKMK: ["貸借のバランスが一致していません。\n棚卸仕訳を月次洗替方式で入力し、前月の｢期末棚卸高｣と当月の｢期首棚卸高｣の金額が一致していません。\n\n出力してよろしいですか？"],
                /** 複合 */
                COMPLEXKMK: ["貸借のバランスが一致していません。\n仕訳入力で｢複合｣｢資金複合｣を使用しそれぞれの勘定の残高が0円になっていません。\n\n出力してよろしいですか？"],
                /** 期首残高 */
                BEGINNINGTERM: ["貸借のバランスが一致していません。\n期首残高登録または経過月発生高登録で借方合計・貸方合計が一致していません。\n\n出力してよろしいですか？"],
                /** 繰越利益剰余金 */
                TRANSFERPROFIT: ["貸借のバランスが一致していません。\n貸借対照表の(BS科目名称)と損益計算書の(PL科目名称)が一致していません。\n\n出力してよろしいですか？"],
                /** 合計科目 */
                TOTALKMK: ["貸借のバランスが一致していません。\n貸借対照表の資産の部と負債・純資産の部が一致していません。\n\n出力してよろしいですか？"],
            },
        },

        /**
         * キャプション
         */
        Caption: {
            /** 年 */
            YEAR: ["年"],
            /** 月 */
            MONTH: ["月"],
            /** 日 */
            DAY: ["日"],
            /** 前期 */
            LASTYEAR: ["前期"],
            /** 前々期 */
            TWOYEARSBEFORE: ["前々期"],
            /** 期首 */
            BEGINNINGTERM: ["期首"],
            /** 国税(略称) */
            NATIONALTAXABBREVIATED: ["国"],
            /** 地方税(略称) */
            LOCALTAXABBREVIATED: ["地"],
            /** （） */
            EMPARENTHESES: ["（{0};）"],
            /** 翌 */
            NEXT: ["翌"],
            /** コード */
            CODE: ["{0};コード"],
            /** リスト */
            LIST: ["{0};リスト"],
            /** 別に改頁する */
            SEPARATEPAGEBREAKS: ["{0};別に改頁する"],

            /**
             * 特殊月
             */
            Spmonth: {
                /** 外部月20 */
                OUTER20: ["第１四半期締後"],
                /** 外部月21 */
                OUTER21: ["第１四半期決算月"],
                /** 外部月22 */
                OUTER22: ["第１四半期決算月２"],
                /** 外部月23 */
                OUTER23: ["第１四半期決算月３"],
                /** 外部月30 */
                OUTER30: ["第３四半期締後"],
                /** 外部月31 */
                OUTER31: ["第３四半期決算月"],
                /** 外部月32 */
                OUTER32: ["第３四半期決算月２"],
                /** 外部月33 */
                OUTER33: ["第３四半期決算月３"],
                /** 外部月80 */
                OUTER80: ["上期締後"],
                /** 外部月81 */
                OUTER81: ["中間決算月"],
                /** 外部月82 */
                OUTER82: ["中間決算月２"],
                /** 外部月83 */
                OUTER83: ["中間決算月３"],
                /** 外部月90 */
                OUTER90: ["下期締後"],
                /** 外部月91 */
                OUTER91: ["決算月"],
                /** 外部月92 */
                OUTER92: ["決算月２"],
                /** 外部月93 */
                OUTER93: ["決算月３"],
            },

            /**
             * 月選択コントロール
             */
            Monthselector: {
                /** 見出し */
                HEAD: ["月度"],
                /** 締後 */
                AFTERCLOSE: ["締後"],
                /** 第一 */
                QUARTER1: ["第一"],
                /** 中間 */
                QUARTER2: ["中間"],
                /** 第三 */
                QUARTER3: ["第三"],
                /** 決算 */
                CLOSING: ["決算"],
            },

            /**
             * 記号
             */
            Symbol: {
                /** ○ */
                CIRCLE: ["○"],
                /** × */
                CROSS: ["×"],
            },
        },

        /**
         * タイトル
         */
        Title: {
            /** 確認 */
            CONFIRM: ["確認"],
            /** 情報 */
            INFORMATION: ["情報"],
            /** 取消 */
            CANCEL: ["取消"],
            /** 終了 */
            CLOSE: ["終了"],
            /** 取消確認 */
            CONFIRMCANCEL: ["取消確認"],

            /**
             * 警告
             */
            Warning: {
                /** マスター更新警告表示 */
                MASTERUPDATE: ["マスター更新警告表示"],
            },
        },

        /**
         * 確認
         */
        Confirm: {
            /** 更新 */
            UPDATE: ["更新しました。"],
            /** 削除 */
            DELETE: ["削除します。よろしいですか？"],
            /** 取消 */
            CANCEL: ["入力を取り消しますか？"],
        },

        /**
         * 補足
         */
        Supplement: {
            /** 注意 */
            CAUTION: ["【注意】"],
        },

        /**
         * マスター区分
         */
        Masterkbn: {
            /** 科目 */
            KMK: ["科目"],
            /** 勘定科目 */
            COUNTINGKMK: ["勘定科目"],
            /** 科目別補助 */
            EACHKMKAUXILIARY: ["科目別補助"],
            /** 銀行 */
            BANK: ["銀行"],
            /** 取引先 */
            CLIENT: ["取引先"],
            /** 社員 */
            STAFF: ["社員"],
            /** 汎用補助 */
            GENERALACCOUNTDETAIL: ["汎用補助"],
            /** 部門 */
            BMN: ["部門"],
            /** セグメント */
            SEGMENT: ["セグメント"],
            /** セグメント1 */
            SEGMENT1: ["ｾｸﾞﾒﾝﾄ1"],
            /** セグメント2 */
            SEGMENT2: ["ｾｸﾞﾒﾝﾄ2"],
            /** セグメント3 */
            SEGMENT3: ["ｾｸﾞﾒﾝﾄ3"],
            /** セグメント4 */
            SEGMENT4: ["ｾｸﾞﾒﾝﾄ4"],
            /** セグメント5 */
            SEGMENT5: ["ｾｸﾞﾒﾝﾄ5"],
            /** 得意先 */
            CUSTOMERS: ["得意先"],
            /** 仕入先 */
            SUPPLIER: ["仕入先"],
            /** 得意先/仕入先 */
            CUSTOMERSSUPPLIER: ["得意先/仕入先"],
            /** 工事 */
            CONSTRUCTION: ["工事"],
            /** 固定摘要 */
            FIXINGSUMMARY: ["摘要"],
        },

        /**
         * 属性
         */
        Attribute: {
            /** 数字 */
            NUMBER: ["数字"],
            /** フリー（左寄せ） */
            FREELEFTJUSTIFICATION: ["フリー（左寄せ）"],
        },

        /**
         * 付箋区分
         */
        Fusenkbn: {
            /** はがす */
            NONE: ["はがす"],
            /** 赤 */
            RED: ["赤"],
            /** 青 */
            BLUE: ["青"],
            /** 緑 */
            GREEN: ["緑"],
            /** 橙 */
            ORANGE: ["橙"],
            /** 連絡 */
            TAGCONTACTS: ["連絡"],
        },

        /**
         * 項目名
         */
        Columnname: {
            /** 項目 */
            ITEM: ["項目"],
            /** 金額 */
            AMOUNT: ["金額"],
            /** 累計期間 */
            CUMULATIVEPERIOD: ["{0};ヶ月累計"],
        },

        /**
         * 補助名称(実在)
         */
        Jhojyoname: {
            /** 補助 */
            HOJYO: ["補助"],
            /** 資金繰 */
            FINANCING: ["資金繰"],
        },

        /**
         * 発生区分
         */
        Occurrence: {
            /** 発生のみ出力 */
            OCCURRENCEONLY: ["発生のみ出力"],
            /** 貸借発生のみ出力 */
            LOANSEPARATELYOCCURRENCE: ["貸借発生のみ出力"],
            /** 0円の科目も出力 */
            ZEROKMK: ["0円の科目も出力"],
        },

        /**
         * 比率名
         */
        Rationame: {
            /** 部門 */
            BMN: ["部門比"],
            /** 補助 */
            HOJYO: ["補助比"],
        },

        /**
         * 印刷
         */
        Print: {
            /** 失敗 */
            FAILED: ["処理実行中にエラーが発生しました。\n印刷設定を確認し問題がない場合はしばらく時間をおいてから再度お試しください。"],
        },

        /**
         * 消費税コード
         */
        Taxcode: {
            /** 例外税率文字 */
            EXCEPTIONTAXRATECHARACTER: ["*"],
        },

        /**
         * コード名称
         */
        Codename: {
            /** 特定科目 */
            SPECIFICKMK: ["特定科目"],
        },

        /**
         * 定数
         */
        Const: {
            /** 仮受消費税 */
            PROVISIONALRECEPTIONTAX: ["仮受消費税"],
            /** 仮払消費税 */
            PROVISIONALPAYMENTTAX: ["仮払消費税"],
        },

        /**
         * 帳票NO
         */
        Repno: {
            /** 仮受仮払消費税元帳 */
            PROVISIONALRECEPTIONPROVISIONALPAYMENTTAXLEDGER: ["仮受仮払消費税元帳"],
        },

        /**
         * 消費税率
         */
        Taxrate: {
            /** 総額 */
            TOTALAMOUNT: ["総額"],
        },

        /**
         * 軽減税率
         */
        Reducedrate: {
            /** 正式名称 */
            LONGNAME: ["軽減"],
            /** 簡略名称 */
            SIMPLENAME: ["軽"],
        },

        /**
         * 単位
         */
        Tanikbn: {
            /** 円 */
            YEN: ["円"],
            /** 千円 */
            THOUSANDYEN: ["千円"],
            /** 万円 */
            MANYEN: ["万円"],
            /** 十万円 */
            ONEHUNDREDTHOUSANDYEN: ["十万円"],
            /** 百万円 */
            ONEMILLIONYEN: ["百万円"],
            /** パーセント */
            PERCENT: ["%"],
        },

        /**
         * 装飾
         */
        Deco: {
            /** なし */
            OUTPUTDECOKBN1: ["{0};"],
            /** 【】 */
            OUTPUTDECOKBN2: ["【{0};】"],
            /** （） */
            OUTPUTDECOKBN3: ["（{0};）"],
            /** 〔〕 */
            OUTPUTDECOKBN4: ["〔{0};〕"],
            /** ＜＞ */
            OUTPUTDECOKBN5: ["＜{0};＞"],
            /** (()) */
            OUTPUTDECOKBN6: ["(({0};))"],
            /** ｛｝ */
            OUTPUTDECOKBN7: ["｛{0};｝"],
        },

        /**
         * 端数処理区分
         */
        Roundkbn: {
            /** 四捨五入 */
            ROUNDING: ["四捨五入"],
            /** 切り上げ */
            ROUNDUP: ["切り上げ"],
            /** 切り捨て */
            ROUNDDOWN: ["切り捨て"],
        },

        /**
         * 出力パターン
         */
        Outputptn: {
            /** 登録不可 */
            NOTREGIST: ["最大件数まで登録済みのため、保存できません。"],
            /** 出力パターン指定なし */
            NONE: ["出力パターン指定なし"],

            /**
             * 確認
             */
            Confirm: {
                /** 初期値設定 */
                STANDARDPATTERN: ["初期値に設定しますか？"],
                /** タイトル */
                TITLE: ["出力パターン登録"],
            },

            /**
             * 工事出力方法
             */
            Kojrsvkbn: {
                /** 未成 */
                INCOMPLETE: ["未成"],
                /** 完成 */
                COMPLETED: ["完成"],
                /** 追加支出 */
                ADDITIONALEXPENDITURE: ["追加支出"],
                /** 前期完成 */
                COMPLETEDPREVIOUSTERM: ["前期完成"],
            },
        },

        /**
         * 出力順序
         */
        Outputorder: {
            /** コード順 */
            CODEORDER: ["コード順"],
            /** 科目指定 */
            KMKSPECIFICATION: ["科目指定"],
            /** 連想シンボル順 */
            RENCHARORDER: ["連想シンボル順"],
            /** 金額昇順 */
            SUMASCORDER: ["金額昇順"],
            /** 金額降順 */
            SUMDESCORDER: ["金額降順"],
            /** 金額指定 */
            SUMSETTINGORDER: ["金額指定"],
        },

        /**
         * 出力区分
         */
        Outputkbn: {
            /** なし */
            NONE: ["出力なし"],
            /** 出力する */
            OUTPUT: ["出力する"],
        },

        /**
         * 諸口
         */
        Sundry: {

            /**
             * デフォルト名称
             */
            Defaultname: {
                /** 簡略名称 */
                SIMPLENAME: ["諸口"],
                /** 正式名称 */
                LONGNAME: ["諸口"],
            },
        },

        /**
         * 行展開
         */
        Lineexpansion: {
            /** 展開可能 */
            CANOPEN: ["＋"],
            /** 展開済 */
            EXPANDED: ["－"],
        },

        /**
         * 区分選択
         */
        Typeselect: {
            /** 指定なし */
            NONE: ["指定なし"],
        },

        /**
         * RSS
         */
        Rss: {

            /**
             * 取得
             */
            Get: {
                /** データなし */
                NONDATA: ["現在お知らせはありません。"],
                /** 例外発生 */
                ERROR: ["かんたんクラウドのお知らせの取得ができませんでした。"],
            },
        },

        /**
         * サポート種類
         */
        Supporttype: {
            /** 標準サポート */
            BASIC: ["標準サポート（メール）"],
            /** スタンダードサポート */
            STANDARD: ["スタンダードサポート（メール・チャット・遠隔操作）"],
            /** プレミアムサポート */
            PREMIUM: ["プレミアムサポート（メール・チャット・電話・遠隔操作）"],
        },

        /**
         * メールサポート
         */
        Mailsupport: {

            /**
             * 送信完了
             */
            Send: {
                /** タイトル */
                TITLE: ["メールサポート 受付完了"],
                /** 完了 */
                COMPLETED: ["お問い合わせを受け付けました。\n\n平日9時から17時までの受付は、当日回答いたします。\nそれ以降は翌営業日に順次回答いたします。"],
            },

            /**
             * クリア確認
             */
            Clearinput: {
                /** タイトル */
                TITLE: ["メールサポート 取消確認"],
                /** 確認 */
                CONFIRM: ["入力中の値をクリアします。よろしいですか？"],
            },

            /**
             * プレースホルダ
             */
            Placeholder: {
                /** 質問 */
                QUESTION: ["ご質問を入力してください。"],
                /** メールアドレス確認 */
                CONFRIMEMAIL: ["確認のため再入力してください。"],
            },

            /**
             * 入力チェック
             */
            Validation: {
                /** 質問未入力 */
                EMPTYQUESTION: ["お問い合わせ内容を入力してください。"],
                /** メールアドレス不一致 */
                DIFFERENTEMAIL: ["メールアドレスが一致しません。"],
                /** メールアドレス不正 */
                INVALIDEMAIL: ["メールアドレスの形式で入力してください。"],
                /** 必須項目未入力 */
                EMPTY: ["必須入力です。"],
            },

            /**
             * 入力モード
             */
            Inputmode: {
                /** OKボタン表記 */
                OKBUTTONCAPTION: ["確認"],
                /** キャンセルボタン表記 */
                CANSELBUTTONCAPTION: ["キャンセル"],
                /** ダイアログタイトル */
                DIALOGTITLE: ["メールサポート"],
            },

            /**
             * 確認モード
             */
            Confirmmode: {
                /** OKボタン表記 */
                OKBUTTONCAPTION: ["送信"],
                /** キャンセルボタン表記 */
                CANSELBUTTONCAPTION: ["戻る"],
                /** ダイアログタイトル */
                DIALOGTITLE: ["メールサポート 確認"],
            },
        },

        /**
         * FAQ検索
         */
        Faqsearch: {
            /** 未入力 */
            EMPTY: ["検索コードを入力してください。"],
        },

        /**
         * 印刷設定
         */
        Printsetting: {
            /** 印刷通知 */
            PRINTBOXGUIDE: ["印刷完了後、印刷BOXに通知されますのでご確認ください。"],
            /** 印刷中 */
            PRINTING: ["印刷中"],
            /** 印刷中… */
            NOWPRINTING: ["印刷中…"],
        },

        /**
         * バッチ処理
         */
        Batch: {
            /** 失敗 */
            FAILED: ["処理実行中にエラーが発生しました。しばらく時間をおいてから再度お試しください。"],
        },

        /**
         * 予算
         */
        Budget: {

            /**
             * 名称
             */
            Name: {
                /** 予算1 */
                BUDGET1: ["予算"],
                /** 予算2 */
                BUDGET2: ["予算２"],
                /** 予算3 */
                BUDGET3: ["予算３"],
                /** 予算4 */
                BUDGET4: ["予算４"],
            },
        },

        /**
         * 月別管理
         */
        Monthlymanagement: {
            /** 年間 */
            BYYEAR: ["年間"],
            /** 月別 */
            BYMONTH: ["月別"],
        },

        /**
         * 利用者情報登録
         */
        Clientinforegister: {
            /** タイトル */
            TITLE: ["利用者情報登録"],
            /** 更新 */
            UPDATE: ["利用者情報を更新しました。"],
        },
    };

    /**
     * 入力
     */
    public static readonly Entry = {

        /**
         * 共通
         */
        Common: {

            /**
             * 検証
             */
            Validate: {
                /** 摘要文字数 */
                TEKIYOLENGTH: ["摘要は全角64文字以内で入力してください。"],
            },

            /**
             * 確認
             */
            Confirm: {
                /** 終了 */
                CLOSE: ["{0};を終了します。よろしいですか？"],
                /** 取消 */
                CANCEL: ["{0};途中の仕訳が存在します。\n月変更すると{0};内容を取り消しますが、よろしいですか？"],
            },
        },

        /**
         * 仕入税額控除
         */
        Purchasetaxdeduction: {

            /**
             * 検証
             */
            Validate: {
                /** 摘要文字数 */
                TEKIYOLENGTH: ["仕入税額控除の記載事項「仕入日」「仕入先」を入力する場合は摘要を全角22文字以内で入力してください。"],
            },
        },

        /**
         * 仕訳帳
         */
        Journal: {

            /**
             * 確認
             */
            Confirm: {
                /** 削除 */
                DELETE: ["検索NO {0};の仕訳データを削除します。よろしいですか？"],
            },

            /**
             * 検証
             */
            Validate: {
                /** 修正 */
                NONMODIFIABLE: ["{0};は修正できません。（{1};のため）"],
                /** 誤り */
                MISTAKE: ["{0};が間違っています。"],
                /** 追加不可 */
                NOTADD: ["{0};月度は追加できません。（{1};のため）"],
                /** 汎用チェック */
                ANYCHECK: ["正しい{0};を入力してください。"],
                /** 汎用チェック（必須） */
                REQUIREDANYCHECK: ["正しい{0};を必ず入力してください。"],
                /** 正しくない */
                INCORRECT: ["コードを正しく入力してください。"],
                /** 存在しないコード */
                NOTEXISTCODE: ["該当コードがありません。"],
                /** 範囲チェック */
                SIZECHECK: ["正しい終了金額を入力してください。"],
                /** 固定摘要選択個数チェック */
                TEKIYOOVERSIZE: ["固定摘要は{0};個までです。"],
                /** 第６種事業日付チェックエラー */
                SIXTHINDUSTRYDISABLEATDATE: ["第６種事業は平成27年3月31日以前の日付では入力できません。"],
                /** 科目の組み合わせエラー */
                IMPOSSIBLETAXCOMBINATION: ["消費税の入力できない仕訳取引パターンです。"],
                /** 消費税率不一致 */
                MISMATCHTAXRATE: ["消費税率の組み合わせが異なっています。"],
                /**  Xssチェックエラー  */
                XSS: ["{0};に使用できない文字が使われています。"],
                /** 認められない展開方式 */
                IMPOSSIBLEEXPANDCOMBINATION: ["この仕訳は展開方式が認められていません。"],
                /** 適用期間外 */
                OUTOFAPPLICATIONPERIOD: ["当該情報の適用期間が範囲外です。"],
            },

            /**
             * 情報
             */
            Information: {
                /** 正式名称コピー */
                COPYOFFICIALNAME: ["F8 正式名称コピー"],
                /** 勘定科目修正 */
                ACCOUNTMODIFIABLE: ["勘定科目を修正してください。"],
                /** 入力してください */
                PLEASEENTER: ["{0};を入力してください。"],
                /** 入力範囲内で指定してください */
                PLEASEENTERINPUTRANGE: ["入力範囲内で{0};を指定してください。"],
            },
        },

        /**
         * 伝票
         */
        Voucher: {

            /**
             * 確認
             */
            Confirm: {
                /** 日付 */
                DATE: ["標準でない税率、使用できない税率の明細があります。\n伝票日付を変更しますか？\n\n※伝票日付を変更後は、明細行の税率を必ず見直してください。"],
            },

            /**
             * エラー
             */
            Error: {
                /** 消費税率区分 */
                TAXRATE: ["{0};行目に使用できない消費税率が入力されています。"],
            },
        },

        /**
         * CSV取込
         */
        Csvimport: {

            /**
             * 検証
             */
            Validate: {
                /** 空白のみ */
                SPACEONLY: ["空白のみの登録はできません。"],
                /** 選択なし */
                NOTSELECT: ["{0};を選択してください。"],
            },

            /**
             * 情報
             */
            Information: {
                /** 項目最大表示 */
                MAXDISPITEM: ["{0};項目まで表示されます"],
            },

            /**
             * 確認
             */
            Confirm: {
                /** 仕訳作成 */
                CREATEFORJOURNAL: ["明細の取込が完了しました。\n引き続き仕訳作成画面へ移動して仕訳入力を行いますか？"],
            },

            /**
             * ファイル
             */
            File: {
                /** サイズ超過 */
                OVERSIZED: ["ファイルサイズが大きすぎます。"],
            },
        },

        /**
         * レシート取込
         */
        Receiptimport: {

            /**
             * 検証
             */
            Validate: {
                /** 不正な日付 */
                INVALIDDATE: ["不正な日付です。"],
                /** 不正な拡張子 */
                INVALIDEXTENSION: ["拡張子がjpg、jpeg以外のファイルは、アップロードできません。"],
                /** ファイルサイズ超過 */
                FILESIZEOVER: ["ファイルサイズが上限を超えています。最大{0};MB"],
                /** ファイル数超過 */
                FILECOUNTOVER: ["ファイル数が上限を超えています。最大{0};ファイル"],
                /** アップロード中 */
                UPLOADING: ["他のファイルをアップロードしているため、アップロードできません。"],
            },

            /**
             * 情報
             */
            Information: {
                /** 仕訳作成不可 */
                CANNOTCREATEFORJOURNAL: ["解析中のレシートがあるため仕訳作成は行えません。"],
            },

            /**
             * 確認
             */
            Confirm: {
                /** 仕訳作成 */
                CREATEFORJOURNAL: ["解析できなかったレシートが残っています。仕訳作成に移動してもよろしいですか？"],
                /** 終了 */
                EXIT: ["解析できなかったレシートが残っています。終了してもよろしいですか？\n（解析できなかった項目は仕訳作成で空欄になります。）"],
            },

            /**
             * ガイド
             */
            Guide: {
                /** 解析結果編集 */
                ANALYSISRESULTEDITING: ["解析した{0};を修正したい場合は、直接編集してください。"],
                /** 読み取り形式ラジオグループ */
                READINGDIRECTIONRADIO: ["読み取り方式を選択してください。\n単方向読み取り・・・縦方向に読み取り、画像解析を行います。解析速度は速いです。\n四方向読み取り・・・読み取り方向を自動的に判断し、画像解析を行います。解析速度は遅いです。"],
                /** マルチピクチャ―チェックボックス */
                MULTIPICTURECHECKBOX: ["マルチピクチャーを取り込む場合はチェックを付けてください。\n※マルチピクチャー・・・画像内に複数の証憑（レシート、領収書）がある画像ファイル"],
                /** 更新ボタン */
                UPDATEBUTTON: ["解析結果の「日付」「仕入先」「金額」と手入力した「還元＋ポイント利用額」「決済手段」を、現在の入力内容で更新します。"],
                /** 削除ボタン */
                DELETEBUTTON: ["取り込んだ画像データを削除します。削除後、同じ画像データを取り込み直す場合は、再度、解析処理が実行されます。"],
                /** 仕訳作成へボタン */
                CREATEFORJOURNALBUTTON: ["取り込んだ画像の内容を基に仕訳を作成します。\nメニューの「仕訳作成」からも同様の処理が可能です。"],
                /** 解析済 */
                ANALYSED: ["画像を解析しました。解析結果を修正したい場合は、表示されている「日付」「仕入先」「金額」を直接修正してください。"],
                /** 解析中 */
                ANALYSING: ["画像を解析しています。解析が完了すると、自動的に内容が更新されます。\nまた、明細取込処理を終了した場合も、画像の解析は続行されます。"],
                /** 分割中 */
                SPLITTING: ["画像を分割しています。分割が完了すると、分割後の画像の解析処理が開始されます。"],
                /** 解析完了 */
                ANALYSISCOMPLETED: ["全てのレシートの解析が終了しました。"],
            },

            /**
             * 解析結果
             */
            Analysisresult: {
                /** 失敗 */
                FAILED: ["{0};の解析ができませんでした。"],
                /** 金額不一致 */
                SUMMISMATCH: ["金額が正しく読み取れていない可能性があります。ご確認ください。"],
            },

            /**
             * 分割結果
             */
            Splittingresult: {
                /** 失敗 */
                FAILED: ["画像の分割ができませんでした。"],
            },
        },

        /**
         * 仕訳作成
         */
        Entrycreateforjournal: {

            /**
             * 確認
             */
            Confirm: {
                /** 一括削除開始 */
                ALLDELETE: ["一括削除を開始します。よろしいですか？"],
                /** 次の取込対象遷移 */
                DISPNEXTTARGE: ["選択された取込対象の入力が全て完了しました。\n次の取込対象を表示しますか？"],
                /** 全データ取込終了 */
                ENDOFTARGET: ["全ての取込対象の入力が完了しました。"],
                /** 全データ削除終了 */
                ALLDETAILDELETE: ["全ての明細が削除されたため、\n取込対象選択に移動します。"],
            },

            /**
             * 検証
             */
            Validate: {
                /** 削除対象未選択 */
                NOTSELECTEDTARGET: ["削除対象が選択されていません。"],
                /** 会計期間外データ */
                ACCOUNTINGPERIODOUTSIDEDATA: ["会計期間外のデータです。"],
            },

            /**
             * 不正文字削除警告
             */
            Warninvalidchardeleted: {
                /** 不正文字 */
                INVALIDCHAR: ["「{0};」"],
                /** カンマ */
                COMMA: ["、"],
                /** 削除 */
                DELETED: ["{0};はシステムで使用できない文字のため削除しました。"],
            },
        },

        /**
         * かんたん入力
         */
        Easyinput: {

            /**
             * 検証
             */
            Validate: {
                /** 取引先摘要文字数 */
                SUPPLIERSTEKIYOLENGTH: ["取引先と摘要合わせて、全角63文字以内で入力してください。"],
            },

            /**
             * 摘要
             */
            Tekiyo: {
                /** 区切り文字 */
                DELIMITERSTRING: [" "],
            },
        },

        /**
         * 仕訳辞書
         */
        Swkdictionary: {

            /**
             * 検証
             */
            Validate: {
                /** 重複 */
                DUPLICATED: ["既に同じキーワード・入払区分の仕訳辞書が登録済みです。"],
            },
        },
    };

    /**
     * 検証
     */
    public static readonly Validate = {

        /**
         * 共通
         */
        Common: {

            /**
             * 見出し
             */
            Caption: {
                /** 科目順序 */
                ACCOUNTORDER: ["科目順序"],
                /** 部門区分 */
                DEPARTMENTTYPE: ["部門区分"],
            },
        },

        /**
         * 種類
         */
        Type: {
            /** 元帳 */
            LEDGER: ["元帳"],
            /** 摘要残高 */
            TEKIYOBALANCE: ["摘要残高"],
            /** 科目別補助 */
            EACHKMKAUXILIARY: ["科目別補助"],
            /** 得意先 */
            CUSTOMERS: ["得意先"],
            /** 仕入先 */
            SUPPLIER: ["仕入先"],
            /** 科目 */
            KMK: ["科目"],
            /** 別科目 */
            ANOTHERKMK: ["別科目"],
            /** 合計 */
            TOTAL: ["合計"],
            /** 単独科目計 */
            INDIVIDUALKMKTOTAL: ["単独科目計"],
        },

        /**
         * 比較損益
         */
        Comparative: {

            /**
             * 比較区分
             */
            Comparison: {
                /** 前期比較 */
                FIRSTTERMCOMPARISON: ["前期比較"],
                /** 前々期比較 */
                AGOPERIODCOMPARISON: ["前々期比較"],
                /** 同月実績 */
                MONTHLYRESULTS: ["{0};同月実績"],
                /** 累計実績 */
                CUMULATIVERESULTS: ["{0};累計実績"],
                /** 月次予算 */
                MONTHLY: ["月次{0};"],
                /** 累計予算 */
                CUMULATIVE: ["累計{0};"],
            },
        },
    };

    /**
     * 検証
     */
    public static readonly Validation = {

        /**
         * 科目別補助元帳
         */
        Outputsubsidiaryledger: {

            /**
             * エラー
             */
            Error: {
                /** 存在なし */
                NOTEXISTS: ["科目別補助が登録されていません。"],
            },
        },
    };

    /**
     * 伝票
     */
    public static readonly Voucher = {

        /**
         * 内税外税
         */
        Inctaxexctax: {
            /** 外税 */
            EXCLUDED: ["外"],
            /** 内税 */
            INCLUDED: ["内"],
        },
    };

    /**
     * 帳簿
     */
    public static readonly Output = {

        /**
         * 日記帳出力条件
         */
        Journalcondition: {

            /**
             * 仕入日入力方法
             */
            Sdateinpkbn: {
                /** 指定なし */
                NONE: ["指定なし"],
                /** 年月日 */
                YMD: ["年月日"],
                /** 年月 */
                YM: ["年月"],
                /** 全て */
                ALL: ["全て"],
            },
        },

        /**
         * 仕訳日記帳
         */
        Outputjournal: {

            /**
             * 業種簡略名称
             */
            Typesimplename: {
                /** 第1種事業 */
                FIRSTPROJECT: ["1種"],
                /** 第2種事業 */
                SECONDPROJECT: ["2種"],
                /** 第3種事業 */
                THIRDPROJECT: ["3種"],
                /** 第4種事業 */
                FOURTHPROJECT: ["4種"],
                /** 第5種事業 */
                FIFTHPROJECT: ["5種"],
                /** 第6種事業 */
                SIXTHPROJECT: ["6種"],
            },
        },

        /**
         * 仕訳履歴
         */
        Journalhistory: {

            /**
             * 明細ヘッダー
             */
            Detailheader: {
                /** 削除日 */
                DELETEDATE: ["削除日"],
                /** 修正日 */
                UPDATEDATE: ["修正日"],
            },

            /**
             * 帳票区分
             */
            Repokbn: {
                /** 削除履歴 */
                DELETEHISTORY: ["削除履歴"],
                /** 修正履歴 */
                UPDATEHISTORY: ["修正履歴"],
                /** 削除・修正履歴 */
                DELETEUPDATEHISTORY: ["削除・修正履歴"],
            },
        },
    };

    /**
     * 管理表
     */
    public static readonly Report = {

        /**
         * エラー
         */
        Error: {

            /**
             * 通知
             */
            Notification: {
                /** 科目出力順序未登録 */
                UNREGISTEDKMKORDER: ["{0};が登録されていません。\n科目出力順序登録を確認して下さい。"],
            },
        },

        /**
         * 消費税元帳
         */
        Consumptiontaxledger: {

            /**
             * レイアウト
             */
            Layout: {
                /** A4縦白紙 */
                A4: ["A4縦白紙"],
                /** B5縦白紙 */
                B5: ["B5縦白紙"],
                /** A4縦白紙(補助名欄付) */
                A4HOJYONAME: ["A4縦白紙（補助名欄付）"],
                /** A4縦白紙(補助コード欄付) */
                A4HOJYOCODE: ["A4縦白紙（補助コード欄付）"],
            },
        },

        /**
         * 消費税元帳
         */
        Taxledger: {

            /**
             * 簡易課税基準業種
             */
            Basedtype: {
                /** 卸売業 */
                WHOLESALEBUSINESS: ["第１種(卸売業)"],
                /** 小売業 */
                RETAILTRADE: ["第２種(小売業)"],
                /** 製造業 */
                MANUFACTURINGINDUSTRY: ["第３種(製造業)"],
                /** その他 */
                OTHERS: ["第４種(その他)"],
                /** サービス業 */
                SERVICEINDUSTRY: ["第５種(サービス業)"],
                /** 不動産業 */
                ESTATE: ["第６種(不動産業)"],
            },

            /**
             * 売上消費税コード1
             */
            Staxcode1: {
                /** 課税売上 */
                TAXATIONSALES: ["課税売上"],
                /** 課税売上(内税) */
                TAXATIONSALESINCLUDED: ["課税売上(内税)"],
                /** 課税売上(外税) */
                TAXATIONSALESEXCLUDED: ["課税売上(外税)"],
                /** 非課税売上 */
                EXEMPTIONSALES: ["非課税売上"],
                /** 不課税売上 */
                TAXATIONLESSNESSSALES: ["不課税売上"],
                /** 輸出売上 */
                EXPORTSALES: ["輸出売上"],
            },

            /**
             * 売上消費税コード2
             */
            Staxcode2: {
                /** 課税売上 */
                TAXATIONSALES: ["課税売上"],
                /** 課税売上(内税) 総額 */
                TAXATIONSALESINCLUDEDTOTAL: ["課税売上(内税) 総額"],
                /** 課税売上(内税) */
                TAXATIONSALESINCLUDED: ["課税売上(内税)"],
                /** 課税控除(内税) */
                TAXATIONSALESSUBTRACTIONINCLUDED: ["課税売上控除(内税)"],
                /** 課税貸倒償却(内税) */
                TAXATIONSALESLOANLOSSREPAYMENTINCLUDED: ["課税貸倒償却(内税)"],
                /** 課税貸倒回収(内税) */
                TAXATIONSALESLOANLOSSCOLLECTIONINCLUDED: ["課税貸倒回収(内税)"],
                /** 課税売上(外税) 総額 */
                TAXATIONSALESEXCLUDEDTOTAL: ["課税売上(外税) 総額"],
                /** 課税売上(外税) */
                TAXATIONSALESEXCLUDED: ["課税売上(外税)"],
                /** 課税売上控除(外税) */
                TAXATIONSALESSUBTRACTIONEXCLUDED: ["課税売上控除(外税)"],
                /** 課税貸倒償却(外税) */
                TAXATIONSALESLOANLOSSREPAYMENTEXCLUDED: ["課税貸倒償却(外税)"],
                /** 課税貸倒回収(外税) */
                TAXATIONSALESLOANLOSSCOLLECTIONEXCLUDED: ["課税貸倒回収(外税)"],
                /** 非課税売上 総額 */
                EXEMPTIONSALESTOTAL: ["非課税売上 総額"],
                /** 非課税売上 */
                EXEMPTIONSALES: ["非課税売上"],
                /** 有価証券売上等(非課税) */
                VALUABLEPAPERSSALESEXEMPTION: ["有価証券売上等(非課税)"],
                /** 不課税売上 */
                TAXATIONLESSNESSSALES: ["不課税売上"],
                /** 輸出売上 総額 */
                EXPORTSALESTOTAL: ["輸出売上 総額"],
                /** 輸出売上 */
                EXPORTSALES: ["輸出売上"],
                /** 非課税輸出売上 */
                EXEMPTIONEXPORTSALES: ["非課税輸出売上"],
            },

            /**
             * 仕入消費税コード1
             */
            Btaxcode1: {
                /** 課税仕入 */
                TAXATIONJOURNALIZE: ["課税仕入"],
                /** 課税仕入(内税) */
                TAXATIONJOURNALIZEINCLUDED: ["課税仕入(内税)"],
                /** 課税仕入(外税) */
                TAXATIONJOURNALIZEEXCLUDED: ["課税仕入(外税)"],
                /** 非課税売上対応仕入 */
                EXEMPTIONJOURNALIZE: ["非課税仕入"],
                /** 不課税仕入 */
                TAXATIONLESSNESSSTOCKINGCLEANINGCONTRACT: ["不課税仕入"],
                /** 輸入仕入(外税) */
                IMPORTJOURNALIZEEXCLUDED: ["輸入仕入(外税)"],
                /** 輸入仕入(内税) */
                IMPORTJOURNALIZEINCLUDED: ["輸入仕入(内税)"],
                /** 特定課税仕入 */
                TAXATIONSALESSPECIFICJOURNALIZE: ["特定課税仕入"],
            },

            /**
             * 仕入消費税コード2
             */
            Btaxcode2: {
                /** 課税仕入 */
                TAXATIONJOURNALIZE: ["課税仕入"],
                /** 課税仕入(内税) 総額 */
                TAXATIONJOURNALIZEINCLUDEDTOTAL: ["課税仕入(内税) 総額"],
                /** 課税売上対応仕入(内税) */
                TAXATIONJOURNALIZEINCLUDED: ["課税仕入(内税)"],
                /** 共通売上対応仕入(内税) */
                COMMONJOURNALIZEINCLUDED: ["共通仕入(内税)"],
                /** 課税売上対応仕入控除(内税) */
                TAXATIONJOURNALIZESUBTRACTIONINCLUDED: ["課税仕入控除(内税)"],
                /** 非課税売上対応仕入(内税) */
                EXEMPTIONSALSEJOURNALIZEINCLUDED: ["非課税売上対応仕入(内税)"],
                /** 共通売上対応仕入控除(内税) */
                COMMONJOURNALIZESUBTRACTIONINCLUDED: ["共通仕入控除(内税)"],
                /** 非課税売上対応仕入控除(内税) */
                EXEMPTIONSALSEJOURNALIZESUBTRACTIONINCLUDED: ["非課税売上対応仕入控除(内税)"],
                /** 課税仕入(外税) 総額 */
                TAXATIONJOURNALIZEEXCLUDEDTOTAL: ["課税仕入(外税) 総額"],
                /** 課税売上対応仕入(外税) */
                TAXATIONJOURNALIZEEXCLUDED: ["課税仕入(外税)"],
                /** 共通売上対応仕入(外税) */
                COMMONJOURNALIZEEXCLUDED: ["共通仕入(外税)"],
                /** 課税売上対応仕入控除(外税) */
                TAXATIONJOURNALIZESUBTRACTIONEXCLUDED: ["課税仕入控除(外税)"],
                /** 非課税売上対応仕入(外税) */
                EXEMPTIONSALSEJOURNALIZEEXCLUDED: ["非課税売上対応仕入(外税)"],
                /** 共通売上対応仕入控除(外税) */
                COMMONJOURNALIZESUBTRACTIONEXCLUDED: ["共通仕入控除(外税)"],
                /** 非課税売上対応仕入控除(外税) */
                EXEMPTIONSALSEJOURNALIZESUBTRACTIONEXCLUDED: ["非課税売上対応仕入控除(外税)"],
                /** 非課税仕入 */
                EXEMPTIONJOURNALIZE: ["非課税仕入"],
                /** 不課税仕入 */
                TAXATIONLESSNESSSTOCKINGCLEANINGCONTRACT: ["不課税仕入"],
                /** 輸入仕入(外税) 総額 */
                IMPORTJOURNALIZEEXCLUDEDTOTAL: ["輸入仕入(外税) 総額"],
                /** 課税売上対応輸入仕入(外税) */
                IMPORTJOURNALIZEEXCLUDED: ["輸入仕入(外税)"],
                /** 共通売上対応輸入仕入(外税) */
                IMPORTCOMMONJOURNALIZEEXCLUDED: ["輸入共通仕入(外税)"],
                /** 非課税売上対応輸入仕入(外税) */
                JOURNALIZECOTCODE64: ["非課税売上対応輸入仕入(外税)"],
                /** 課税売上対応輸入仕入(地方消費税) */
                IMPORTJOURNALIZECONSUMPTIONTAX: ["輸入仕入(地方消費税)"],
                /** 共通売上対応輸入仕入(地方消費税) */
                IMPORTCOMMONJOURNALIZELOCALCONSUMPTIONTAX: ["輸入共通仕入(地方消費税)"],
                /** 非課税売上対応輸入仕入(地方消費税) */
                EXEMPTIONJOURNALIZEIMPORT: ["非課税売上対応輸入仕入(地方消費税)"],
                /** 輸入仕入(内税) 総額 */
                IMPORTJOURNALIZEINCLUDEDTOTAL: ["輸入仕入(内税) 総額"],
                /** 課税売上対応輸入仕入(内税) */
                IMPORTJOURNALIZEINCLUDED: ["輸入仕入(内税)"],
                /** 共通売上対応輸入仕入(内税) */
                IMPORTCOMMONJOURNALIZEINCLUDED: ["輸入共通仕入(内税)"],
                /** 非課税売上対応輸入仕入(内税) */
                EXEMPTIONSALSEIMPORTINCLUDED: ["非課税売上対応輸入仕入(内税)"],
                /** 特定課税仕入 総額 */
                TAXATIONSALESSPECIFICJOURNALIZETOTAL: ["特定課税仕入 総額"],
                /** 課税売上対応特定課税仕入 */
                TAXATIONSALESSPECIFICJOURNALIZE: ["課税売上対応特定課税仕入"],
                /** 共通売上対応特定課税仕入 */
                TAXATIONEXEMPTIONSALESSPECIFICJOURNALIZE: ["共通売上対応特定課税仕入"],
                /** 課税売上対応特定課税仕入控除 */
                TAXATIONSALESSPECIFICJOURNALIZERETURN: ["課税売上対応特定課税仕入控除"],
                /** 非課税売上対応特定課税仕入 */
                EXEMPTIONSALSEIMPORTSPECIFICTAXATIONJOURNALIZE: ["非課税売上対応特定課税仕入"],
                /** 共通売上対応特定課税仕入控除 */
                TAXATIONEXEMPTIONSALESSPECIFICJOURNALIZERETURN: ["共通売上対応特定課税仕入控除"],
                /** 非課税売上対応特定課税仕入控除 */
                EXEMPTIONSALSEIMPORTSPECIFICTAXATIONJOURNALIZERETURN: ["非課税売上対応特定課税仕入控除"],
            },

            /**
             * 消費税コード
             */
            Taxcode: {
                /** 総額 */
                TOTALAMOUNT: ["総額"],
            },
        },

        /**
         * 消費税関係資料
         */
        Consumptiontaxrelateddocument: {

            /**
             * 情報
             */
            Information: {
                /** 仕訳帳 */
                JOURNALBOOK: ["仕訳帳"],
                /** 不課税売上内訳 */
                BREAKDOWNOFNONTAXEDSALES: ["不課税売上内訳"],
                /** 戻る */
                RETURN: ["戻る"],
            },

            /**
             * キャプション
             */
            Caption: {
                /** 消費税精算表 */
                CONSUMPTIONTAXWORKSHEET: ["消費税精算表"],
                /** 売上・仕入一覧表 */
                SALESANDJOURNALIZELIST: ["売上・仕入一覧表"],
                /** 基礎資料月別明細 */
                BASICDOCUMENTMONTHLYSTATEMENT: ["基礎資料月別明細"],
                /** 仮受仮払消費税等 */
                SUSPENSECONSUMPTIONTAXRECEIVEDANDPAID: ["仮受仮払消費税等"],
                /** 月別差引税額 */
                MONTHLYDEDUCTIBLETAXAMOUNT: ["月別差引税額"],
                /** 業種別売上高 */
                INDUSTRYCLASSIFIEDNETSALES: ["業種別売上高"],
                /** 業種別精算表 */
                INDUSTRYCLASSIFIEDWORKSHEET: ["業種別精算表"],
                /** 部門別消費税精算表 */
                SECTORALLYCONSUMPTIONTAXWORKSHEET: ["部門別消費税精算表"],
                /** 部門別売上・仕入一覧表 */
                SECTORALLYSALESANDJOURNALIZELIST: ["部門別売上・仕入一覧表"],
                /** 税率別消費税精算表 */
                TAXRATECONSUMPTIONTAXWORKSHEET: ["税率別消費税精算表"],
                /** 課税売上高 */
                TAXABLENETSALES: ["課税売上高"],
                /** 売上控除 */
                SALESSUBTRACTION: ["売上控除"],
                /** 貸倒償却 */
                CHARGEOFF: ["貸倒償却"],
                /** 貸倒回収 */
                SALESLOANLOSSCOLLECTION: ["貸倒回収"],
                /** 売上その他 */
                SALESOTHER: ["売上その他"],
                /** 課税仕入 */
                TAXATIONJOURNALIZE: ["課税仕入"],
                /** 共通仕入 */
                COMMONJOURNALIZE: ["共通仕入"],
                /** 非課税売上対応仕入 */
                EXEMPTIONSALSEJOURNALIZESUBTRACTION: ["非課税売上対応仕入"],
                /** 輸入仕入 */
                IMPORTJOURNALIZE: ["輸入仕入"],
                /** 棚卸調整/特定課税仕入 */
                STOCKTAKINGADJUSTANDSPECIFICJOURNALIZE: ["棚卸調整/特定課税仕入"],
            },

            /**
             * 税率別消費税精算表表示内容キャプション
             */
            Taxrateconsumptiontaxworksheetkbncaption: {
                /** 売上 */
                SALES: ["売上"],
                /** 仮受消費税等 */
                SUSPENSECONSUMPTIONTAXRECEIVED: ["仮受消費税等"],
                /** 仕入 */
                JOURNALIZE: ["仕入"],
                /** 仮払消費税等 */
                SUSPENSECONSUMPTIONTAXPAID: ["仮払消費税等"],
            },
        },

        /**
         * 業種別元帳
         */
        Consumptiontaxledgerbytype: {

            /**
             * レイアウト
             */
            Layout: {
                /** A4縦白紙 */
                A4: ["A4縦白紙"],
                /** B5縦白紙 */
                B5: ["B5縦白紙"],
                /** A4縦白紙(相手科目名欄付) */
                A4KMKNAME: ["A4縦白紙（相手科目名欄付）"],
                /** A4縦白紙(相手科目コード欄付) */
                A4KMKCODE: ["A4縦白紙（相手科目コード欄付）"],
            },
        },

        /**
         * 資金集計表
         */
        Fundingsummary: {

            /**
             * キャプション
             */
            Caption: {
                /** 平均残高項目名 */
                SUBTITLE: ["＊　平均残高　＊"],
            },
        },

        /**
         * 資金繰実績・予定表
         */
        Financingperformanceschedule: {

            /**
             * キャプション
             */
            Caption: {
                /** 累計期間 */
                CUMULATIVEPERIOD: ["{0};ヶ月間累計"],
            },

            /**
             * レイアウト
             */
            Layout: {
                /** 実績・予定表 */
                FINANCINGPERFORMANCESCHEDULE: ["実績・予定表"],
                /** 月次推移表 */
                FINANCINGMONTHLYTRANSITIONCHART: ["月次推移表"],
                /** 月次推移表(実績・予定) */
                FINANCINGMONTHLYTRANSITIONCHARTPERFORMANCESCHEDULE: ["月次推移表(実績・予定)"],
            },

            /**
             * 情報
             */
            Information: {
                /** 仕訳帳 */
                JOURNALBOOK: ["仕訳帳"],
            },
        },

        /**
         * 財務報告書
         */
        Financialstatement: {

            /**
             * 出力形式
             */
            Layout: {
                /** 科目コードあり */
                KMKCODESETTING: ["科目コードあり"],
                /** 科目コードなし */
                KMKCODENONE: ["科目コードなし"],
                /** Ａ４縦科目コードあり */
                A4KMKCODESETTING: ["Ａ４縦科目コードあり"],
                /** Ａ４縦科目コードなし */
                A4KMKCODENONE: ["Ａ４縦科目コードなし"],
                /** 財務報告書 */
                FINANCIALREPORTS: ["財務報告書"],
                /** 財務報告書（A4縦） */
                FINANCIALREPORTSA4VERTICAL: ["財務報告書（A4縦）"],
            },
        },

        /**
         * 推移財務報告書
         */
        Monthlyfinancialstatement: {

            /**
             * 出力形式
             */
            Layout: {
                /** 推移 */
                CHANGE: ["推移"],
                /** 推移（決算あり） */
                CHANGEFOURTHQUARTER: ["推移（決算あり）"],
                /** 総合推移 */
                GENERALCHANGE: ["総合推移"],
                /** 総合推移（決算あり） */
                GENERALCHANGEFOURTHQUARTER: ["総合推移（決算あり）"],
                /** 総合推移（前年度末比較） */
                GENERALCHANGELASTYEAR: ["総合推移（前年度末比較）"],
                /** 総合推移（収支計算書） */
                GENERALCHANGEEARNINGS: ["総合推移（収支計算書）"],
            },

            /**
             * 集計方法
             */
            Aggregationmethod: {
                /** 月次推移 */
                MONTHLY: ["月次推移"],
                /** 累計推移 */
                ANNUAL: ["累計推移"],
            },

            /**
             * 貸借対照表出力
             */
            Balancesheetoutput: {
                /** 発生を出力 */
                INP: ["発生を出力"],
                /** 残高を出力 */
                BALANCE: ["残高を出力"],
            },

            /**
             * 残期間出力区分
             */
            Outputptnzankbn: {
                /** 前期 */
                LASTYEAR: ["前期"],
                /** 前々期 */
                TWOYEARSBEFORE: ["前々期"],
                /** 比較対象出力 */
                COMPARISON: ["比較対象出力"],
            },
        },

        /**
         * 比較財務報告書
         */
        Comparativefinancialstatement: {

            /**
             * タイトル
             */
            Title: {
                /** 実績 */
                RESULTS: ["実績"],
                /** 予算 */
                BUDGET: ["予算"],
            },
        },

        /**
         * 四半期比較財務報告書
         */
        Quartercomparativefinancialstatement: {

            /**
             * 出力形式
             */
            Layout: {
                /** 四半期比較 */
                QUARTERCOMPARATIVE: ["四半期比較"],
                /** 半期比較 */
                HALFCOMPARATIVE: ["半期比較"],
            },
        },

        /**
         * 部門別比較財務報告書
         */
        Departmentcomparativefinancialstatement: {

            /**
             * エラー
             */
            Error: {
                /** 存在なし */
                NOTEXISTS: ["部門が採用されていないので、この処理は行えません。"],
            },

            /**
             * 出力種類
             */
            Outputtype: {
                /** 部門別比較財務報告書 */
                DEPARTMENTCOMPARATIVEFINANCIALSTATEMENT: ["部門別比較財務報告書"],
            },
        },

        /**
         * 比較財務報告書出力条件
         */
        Comparativefinancialstatementcondition: {

            /**
             * レイアウト
             */
            Layout: {
                /** 比較 */
                COMPARISON: ["比較"],
                /** 総合比較 */
                OVERALLCOMPARISON: ["総合比較"],
                /** 総合比較（月次／累計） */
                OVERALLCOMPARISONMONTHLYCUMULATIVE: ["総合比較（月次／累計）"],
                /** 前期／予算比較 */
                EARLYBUDGETCOMPARISON: ["前期／予算比較"],
                /** 比較損益 */
                COMPARISONLOSS: ["比較損益"],
                /** 前年度末比較 */
                PREVIOUSYEARENDCOMPARISON: ["前年度末比較"],
                /** 収支計算書 */
                INCOMESTATEMENT: ["収支計算書"],
            },

            /**
             * 集計区分
             */
            Calckbn: {
                /** 累計 */
                CUMULATIVE: ["累計"],
                /** 月次 */
                MONTHLY: ["月次"],
            },
        },

        /**
         * 月次
         */
        Monthly: {
            /** 月 */
            MONTH: ["{0};月"],
            /** 残期間月 */
            ZANMONTH: ["{0};月*"],
        },

        /**
         * 資金繰
         */
        Financing: {
            /** 期首から指定月 */
            BEGINNINGTODESIGNATEDMONTH: ["期首から{0};月まで"],
        },

        /**
         * 月末税抜処理
         */
        Taxexcludingmonthend: {

            /**
             * キャプション
             */
            Caption: {
                /** 管理表 */
                MANAGELIST: ["管理表"],
                /** 日記帳 */
                DAILYREPORT: ["日記帳"],
                /** 振替伝票 */
                TRANSFERSLIP: ["振替伝票"],
            },

            /**
             * エラー
             */
            Error: {
                /** 会計処理区分 */
                ACCPROKBN: ["月末税抜が採用されていないので、この処理は実行できません。"],
            },
        },

        /**
         * 科目別部門
         */
        Accountdepartment: {

            /**
             * レイアウト
             */
            Layout: {
                /** 比較損益 */
                COMPARATIVEINCOMESTATEMENT: ["比較損益"],
            },
        },

        /**
         * 部門別集計表
         */
        Departmentsummary: {

            /**
             * 出力形式
             */
            Layout: {
                /** 部門別財務報告書 */
                DEPARTMENTFINANCIALSTATEMENT: ["部門別財務報告書"],
                /** 部門別集計 */
                DEPARTMENTSUMMARY: ["部門別集計"],
            },
        },

        /**
         * 月別比較
         */
        Monthlycomparative: {

            /**
             * キャプション
             */
            Caption: {
                /** 前期実績 */
                LASTYEARRESULTS: ["前期実績"],
                /** 当月予算 */
                BUDGET: ["当月予算"],
                /** 前期累計 */
                CUMULATIVELASTYEARRESULTS: ["前期累計"],
                /** 当期累計予算 */
                CUMULATIVEBUDGET: ["当期累計予算"],
            },

            /**
             * 出力種類
             */
            Outputtype: {
                /** 科目 */
                KMK: ["科目"],
                /** 科目別補助 */
                EACHKMKAUXILIARY: ["科目・科目別補助"],
                /** 部門・科目 */
                BMNKMK: ["{0};・科目"],
                /** 部門・科目別補助 */
                BMNEACHKMKAUXILIARY: ["{0};・科目・科目別補助"],
                /** 科目・補助 */
                KMKAUXILIARY: ["科目・{0};"],
            },
        },
    };

    /**
     * 登録
     */
    public static readonly Register = {

        /**
         * 帳票タイトル
         */
        Titlename: {
            /** 登録リスト（連結用文字列） */
            REGISTRATIONLIST: ["{0};登録リスト"],
            /** 当期 */
            CURRENT: ["当期"],
        },

        /**
         * エラー
         */
        Error: {
            /** 行 */
            ROW: ["{0};行目の登録が不正です。"],
            /** 禁則文字 */
            PROHIBITEDCHARACTERS: ["{0};に設定不可文字が設定されています。"],
        },

        /**
         * 査印欄パターン
         */
        Stamppattern: {

            /**
             * エラー
             */
            Error: {
                /** 削除 */
                DELETE: ["パターンＮＯ {0}; は使用中のため、削除することができません。"],
                /** 査印欄の個数 */
                STAMPCOUNT: ["文字方向を「縦書き」とした場合、査印欄の個数を１０個以内に設定して下さい。"],
            },

            /**
             * 確認
             */
            Confirm: {
                /** 査印欄の個数 */
                STAMPCOUNT: ["査印欄の個数を減らすと ＮＯ{0}; 以降の査印名称がクリアされます。よろしいですか？"],
            },
        },

        /**
         * 査印欄パターン名称
         */
        Stampptnname: {
            /** 印刷なし */
            NONE: ["印刷なし"],
        },

        /**
         * 財務報告書フォーム形式
         */
        Financereportform: {
            /** 法人 */
            CORPORATION: ["法人"],
            /** 医療法人 */
            MEDICAL: ["医療法人"],
            /** 建設工事業 */
            KOJI: ["建設工事業"],
            /** 個人拡大 */
            PERSONALEXPANSION: ["個人拡大"],
            /** 個人基本 */
            PERSONALBASIS: ["個人基本"],
            /** 法人基本 */
            CORPORATIONBASIC: ["法人基本"],
            /** 旧医療法人 */
            OLDMEDICAL: ["旧医療法人"],
            /** 新医療法人 */
            NEWMEDICAL: ["新医療法人"],
        },

        /**
         * 自動セット区分
         */
        Automaticsetkbn: {
            /** 基準割合を超える科目 */
            BEYONDCRITERIAKMK: ["基準割合を超える科目"],
            /** 基準割合を超える先頭科目 */
            BEYONDCRITERIATOPKMK: ["基準割合を超える先頭科目"],
            /** 最大金額の科目 */
            MAXKMK: ["最大金額の科目"],
        },

        /**
         * 配賦情報
         */
        Allocation: {

            /**
             * キャプション
             */
            Caption: {
                /** 通常配賦 */
                ALLOCATIONNORMAL: ["通常配賦"],
                /** 範囲配賦 */
                ALLOCATIONRANGE: ["範囲配賦"],
                /** 通常科目 */
                ALLOCATIONKMKNORMALVALUE: ["通常科目"],
                /** 配賦基準値 */
                ALLOCATIONKMKBASEDVALUE: ["配賦基準値"],
                /** 配賦基準科目の選択 */
                ALLOCATIONKMKCHOICE: ["{0};　選択中"],
                /** 全部門対象 */
                ALLOCATIONALLBMN: ["全{0};対象"],
            },

            /**
             * 警告
             */
            Warning: {
                /** 配賦情報の変更 */
                ALLOCATIONUPDATE: ["配賦情報の変更が行われています。\n月次確定済の月にも変更内容を反映する場合は、マスター更新処理の\n詳細画面で「月次確定済みの月も更新をする」にチェックを付けて処理\nを行うようにして下さい。"],
            },

            /**
             * 妥当性確認
             */
            Validate: {
                /** 配賦割合チェック */
                ALLOCATIONRATIO: ["配賦割合が100%になるように調整してください。"],
            },

            /**
             * 配賦採用区分
             */
            Mstrkbn1: {
                /** なし */
                NONE: ["{0};の配賦区分が採用されていないので、この処理は行えません。"],
            },
        },

        /**
         * 補助出力順序
         */
        Auxiliaryoutputorder: {

            /**
             * コンテキストメニュー
             */
            Contextmenu: {
                /** 挿入 */
                INSERT: ["挿入"],
                /** 削除 */
                DELETE: ["削除"],
                /** 強制空白 */
                FORCEBLANK: ["強制空白"],
            },
        },

        /**
         * セグメント自動セット情報
         */
        Segmentautoset: {
            /** 自動セット区分 */
            AUTOMATICSETKBN: ["自動セット区分"],

            /**
             * 検証
             */
            Validate: {
                /** 未採用 */
                NOTADOPTED: ["未採用になっているため変更できません。"],
                /** 共通セグメント採用 */
                ADOPTEDCOMMONSEGMENT: ["共通セグメントを採用しているため変更できません。"],
            },
        },

        /**
         * 勘定科目
         */
        Account: {

            /**
             * 確認
             */
            Confirm: {
                /** 銀行採用確認 */
                BANKUSE: ["この科目は、銀行補助が採用されています。\n銀行補助採用をやめ、銀行コードのセットを行いますか？"],
            },

            /**
             * コンテキストメニュー
             */
            Contextmenu: {
                /** 加算 */
                ADDITION: ["加算"],
                /** 減算 */
                SUBTRACTION: ["減算"],
                /** 他の加算先へのコピー */
                COPY: ["他の加算先へのコピー"],
                /** 他の加算先へ移動 */
                MOVE: ["他の加算先へ移動"],
                /** 他の加算先からの貼り付け */
                PASTE: ["他の加算先からの貼り付け"],
                /** 加算しない */
                NOTADD: ["加算しない"],
                /** 削除 */
                DELETE: ["削除"],
            },

            /**
             * 銀行コード
             */
            Bankcode: {
                /** 科目別補助 */
                SUB: ["科目別補助で銀行コードを採用しています。"],
            },

            /**
             * 貸借区分
             */
            Dckbn: {
                /** 借方 */
                DEBIT: ["借方"],
                /** 貸方 */
                CREDIT: ["貸方"],
            },

            /**
             * 部門セグメント採用区分
             */
            Bmnseguse: {
                /** 共通 */
                COMMON: ["共通"],
            },

            /**
             * BS/PL区分（画面用）
             */
            Bpkbndisplay: {
                /** Ｂ／Ｓ */
                BS: ["Ｂ／Ｓ"],
                /** Ｐ／Ｌ */
                PL: ["Ｐ／Ｌ"],
            },

            /**
             * 表示／印刷不可区分
             */
            Nondspkbn: {
                /** 可 */
                PERMIT: ["可"],
                /** 不可 */
                IMPROPER: ["不可"],
            },

            /**
             * 比率科目区分
             */
            Perkmkkbn: {
                /** 通常科目 */
                NORMAL: ["通常科目"],
                /** 比率科目 */
                RATIO: ["比率科目"],
            },

            /**
             * 元帳摘要欄起票者印字区分
             */
            Mtekiprn: {
                /** 印字なし */
                NONE: ["印字なし"],
                /** 印字あり */
                SETTING: ["印字あり"],
            },

            /**
             * 仕訳入力区分
             */
            Inputkbn: {
                /** 貸借入力 */
                DEBITCREDIT: ["貸借入力"],
                /** 正残側のみ */
                POSITIVEBALANCE: ["正残のみ"],
                /** 負残側のみ */
                NEGATIVEBALANCE: ["負残のみ"],
                /** 入力不可 */
                IMPOSSIBLETOINPUT: ["入力不可"],
            },

            /**
             * 仕訳入力区分（画面用）
             */
            Inputkbndisplay: {
                /** 正残側のみ */
                POSITIVEBALANCE: ["正残側のみ"],
                /** 負残側のみ */
                NEGATIVEBALANCE: ["負残側のみ"],
            },

            /**
             * 期日/手形入力区分
             */
            Dtinputkbn: {
                /** 入力なし */
                NONE: ["入力なし"],
                /** 貸借入力（任意） */
                DEBITCREDITANY: ["貸借入力（任意）"],
                /** 貸借入力（必須） */
                DEBITCREDITMUST: ["貸借入力（必須）"],
                /** 正残側（任意） */
                POSITIVEBALANCEANY: ["正残側（任意）"],
                /** 正残側（必須） */
                POSITIVEBALANCEMUST: ["正残側（必須）"],
                /** 負残側（任意） */
                NEGATIVEBALANCEANY: ["負残側（任意）"],
                /** 負残側（必須） */
                NEGATIVEBALANCEMUST: ["負残側（必須）"],
            },

            /**
             * 固定摘要必須入力区分
             */
            Tkinputkbn: {
                /** 任意 */
                ANY: ["任意"],
            },

            /**
             * 固定摘要必須入力区分(画面用)
             */
            Tkinputkbndisplay: {
                /** 固定摘要必須 */
                MUSTFIXINGSUMMARY: ["固定摘要必須"],
                /** 摘要残必須 */
                MUSTREMAININGSUMMARY: ["摘要残必須"],
            },

            /**
             * 資金繰コード優先区分
             */
            Cashcodekbn: {
                /** 科目優先 */
                KMKPRIORITY: ["科目優先"],
                /** 補助優先 */
                AUXILIARYPRIORITY: ["補助優先"],
            },

            /**
             * 履歴管理区分（画面用）
             */
            Hiskbndisplay: {
                /** 修正履歴管理区分に従う */
                FOLLOWHISTORYMANAGEMENT: ["修正履歴管理区分に従う"],
                /** 無条件に履歴を取る */
                TAKEHISTORYUNCONDITIONAL: ["無条件に履歴を取る"],
            },

            /**
             * 集計単位区分
             */
            Totalkbn: {
                /** 指定した単位で集計 */
                DESIGNATIONUNIT: ["指定単位"],
                /** 無視する */
                IGNORING: ["集計対象外"],
            },

            /**
             * 帳票区分
             */
            Repokbn: {
                /** 実在科目登録リスト(簡略) */
                REALKMKREGISTERLISTSIMPLE: ["実在科目登録リスト(簡略)"],
                /** 実在科目登録リスト(詳細) */
                REALKMKREGISTERLISTDETAIL: ["実在科目登録リスト(詳細)"],
                /** 合計科目登録リスト */
                TOTALKMKREGISTERLIST: ["合計科目登録リスト"],
                /** 科目体系登録リスト */
                KMKTREEREGISTERLIST: ["科目体系登録リスト"],
                /** 実在科目加算リスト */
                REALKMKADDITIONLIST: ["実在科目加算リスト"],
                /** 合計科目加算リスト */
                TOTALKMKADDITIONLIST: ["合計科目加算リスト"],
                /** 実在科目コード一覧 */
                REALKMKCODELIST: ["実在科目コード一覧"],
                /** 合計科目コード一覧 */
                TOTALKMKCODELIST: ["合計科目コード一覧"],
            },
        },

        /**
         * 予算登録
         */
        Budget: {

            /**
             * 出力金額
             */
            Outputamount: {
                /** 当期予算 */
                CURRENTBUDGET: ["当期予算"],
                /** 当年実績 */
                THISYEARRESULTS: ["当年実績"],
                /** 前年実績 */
                LASTYEARRESULTS: ["前年実績"],
                /** 前々年実績 */
                TWOYEARSBEFORERESULTS: ["前々年実績"],
            },

            /**
             * 対象マスター
             */
            Targetmaster: {
                /** 勘定科目予算 */
                BUDGETCOUNTINGKMK: ["勘定科目予算"],
                /** 科目別補助予算 */
                BUDGETEACHKMKAUXILIARY: ["科目別補助予算"],
                /** 部門予算 */
                BUDGETBMN: ["部門予算"],
                /** 部門別科目別補助予算 */
                BUDGETEACHKMKAUXILIARYBYBMN: ["部門別科目別補助予算"],
                /** セグメント予算 */
                BUDGETSEGMENT: ["セグメント予算"],
                /** セグメント別科目別補助予算 */
                BUDGETEACHKMKAUXILIARYBYSEGMENT: ["セグメント別科目別補助予算"],
            },

            /**
             * キャプション
             */
            Caption: {
                /** ファイル名 */
                FILENAME: ["以下の内容でインポートを開始します。よろしいですか？"],
                /** CSV形式 */
                CSVTYPE: ["ファイル名："],
                /** 予算年度 */
                BUDGETYEAR: ["CSV形式："],
                /** 対象月 */
                TARGETMONTH: ["予算年度："],
            },

            /**
             * 確認
             */
            Confirm: {
                /** CSVインポート開始 */
                CSVIMPORTSTART: ["対　象　月："],
                /** エラーログ出力 */
                ERRORLOGOUTPUT: ["インポートできませんでした。"],
                /** ログ出力 */
                LOGOUTPUT: ["エラーがあります。ログファイルを参照しますか？"],
            },

            /**
             * 情報
             */
            Information: {
                /** インポートエラー */
                IMPORTERROR: ["ログファイルを参照しますか？"],
                /** 登録未実施 */
                NOTREGIST: ["連結を行いませんでした。"],
                /** Excel取込正常終了 */
                EXCELIMPORT: ["正常にExcel取込が完了しました。"],
            },
        },

        /**
         * 仕訳入力情報
         */
        Swkinputinfo: {

            /**
             * キャプション
             */
            Caption: {
                /** 通年 */
                YEARROUND: ["通年"],
            },

            /**
             * 固定摘要ウィンドウ表示方法
             */
            Fixedmemorandumwindowdisplay: {
                /** 全件表示 */
                ALLINDICATION: ["全件表示"],
                /** 科目連想表示 */
                KMKRENSODISPLAY: ["科目連想表示"],
                /** 摘要残優先表示 */
                REMARKSBALANCEPRIORITY: ["摘要残優先表示"],
                /** 非表示 */
                HIDDEN: ["非表示"],
            },

            /**
             * 資金繰コード入力区分
             */
            Financingcodeinputkbn: {
                /** 資金繰コードなしの時 */
                NONECASHCODE: ["資金繰コードなしの時"],
                /** 複合仕訳の時 */
                WHENCOMPOSITESWK: ["複合仕訳の時"],
                /** 資金科目／非資金科目の組合せの時 */
                CASHANDNONCASH: ["資金科目／非資金科目の組合せの時"],
                /** 入力なし */
                NONE: ["入力なし"],
            },

            /**
             * 棚卸仕訳入力方法
             */
            Stocktakingswkinput: {
                /** 全社共通に従う */
                COMMON: ["全社共通に従う{0};"],
                /** 従来（期首／期末）方式 */
                ENDOFPERIOD: ["「期首／期末」方式"],
                /** 期末／期末方式 */
                ENDOFEND: ["「期末／期末」方式"],
            },

            /**
             * 伝票NO入力区分
             */
            Slipnoinputkbn: {
                /** 任意入力 */
                ANY: ["任意入力"],
                /** システム自動付番 */
                SYSTEMTURNAUTOMATION: ["システム自動付番（{0};）"],
                /** 自動付番 */
                TURNAUTOMATION: ["自動付番"],
                /** 自動付番（前回＋１） */
                TURNAUTOMATIONPLUS1: ["自動付番（前回＋１）"],
            },

            /**
             * 伝票NO管理区分
             */
            Slipnomanagementkbn: {
                /** 会社単位 */
                CORPUNIT: ["会社別"],
                /** 担当者単位 */
                UNITPERSON: ["担当者別"],
            },

            /**
             * 伝票NOシステム自動付番方法
             */
            Slipnosystemautonumbering: {
                /** 月次型 */
                MONTHLY: ["月次型"],
                /** 通年型 */
                YEARROUNDTYPE: ["通年型"],
            },

            /**
             * 仕入税額控除の記載事項の入力方法
             */
            Purchasetaxcreditinputmethod: {
                /** 摘要に入力 */
                INPUTSUMMARY: ["摘要に入力"],
                /** 仕入先、仕入日を摘要の他に入力 */
                INPUTOTHERSUMMARY: ["仕入先、仕入日を摘要の他に入力"],
            },

            /**
             * 通知
             */
            Notification: {
                /** 参照 */
                REFERENCE: ["同期方法が差分のため、以下の項目は参照のみです。\n・「外税同時入力」\n・「伝票NO入力」\n・「証憑NO入力」\n・「証憑NO属性」\n・「消費税率入力区分」\n・「仕入税額控除の記載事項の入力方法」"],
                /** 参照のみ */
                REFERENCEONLY: ["同期方法が差分のため、以下の項目は参照のみです。"],
                /** 外税同時入力 */
                TAXINPUTKBN: ["・「外税同時入力」"],
                /** 伝票NO入力 */
                DENNOINPUTKBN: ["・「伝票NO入力」"],
                /** 証憑NO入力 */
                LEAFNOINPUTKBN: ["・「証憑NO入力」"],
                /** 証憑NO属性 */
                LEAFNOATTRIBUTE: ["・「証憑NO属性」"],
                /** 消費税率入力区分 */
                TAXRATEINPUTKBN: ["・「消費税率入力区分」"],
                /** 消費税率入力方法 */
                TAXRATEINPUTMETHOD: ["・「消費税率入力方法」"],
                /** 仕入税額控除の記載事項の入力方法 */
                PURCHASETAXCREDITINPUTMETHOD: ["・「仕入税額控除の記載事項の入力方法」"],
                /** 変動名称 */
                VARIABLENAME: ["・「{0};」"],
                /** 開始伝票NOの変更確認 */
                CONFIRMCHANGESTARTDENNO: ["伝票データが入力済の場合は、開始伝票NOの変更により伝票NOの重複または歯抜けが発生するケースがあります。\n開始伝票NOの変更を行いますか？"],
                /** 終了伝票NOの見直確認 */
                CONFIRMREVIEWENDDENNO: ["開始伝票NOの変更を行う場合は、最終伝票NOの見直しを同時に行うようにしてください。\n開始伝票NOの変更を有効とし、最終伝票NOの見直しを行いますか？"],
                /** 対象月仕訳データ入力済み */
                ALWAYSSWKDATAINPUT: ["以下の月に仕訳データが入力済みのため、変更できません。"],
            },

            /**
             * 明細
             */
            Detail: {
                /** 2019年9月の10%の入力 */
                TENPERCENTINPUTINSEPTEMBEROF2019: ["{0};年9月の10%の入力"],
            },
        },

        /**
         * 科目別業種
         */
        Accountindustry: {

            /**
             * 妥当性確認
             */
            Validate: {
                /** 業種コード変更確認 */
                INDUSTRYCODE: ["仕訳データが入力済みです。変更してもよろしいですか？入力済み仕訳の業種コードを変更する場合には、マスター再計算の画面で業種コード再セットにチェックをつけて行ってください。"],
            },
        },

        /**
         * 科目別消費税
         */
        Accounttax: {

            /**
             * 妥当性確認
             */
            Validate: {
                /** 消費税コードの仮払消費税科目による制限 */
                TAXCODERESTRICTIONBYPROVISIONALPAYMENTTAX: ["指定コードは仮払消費税科目の時のみ設定可能です。"],
                /** 消費税率の設定可否 */
                TAXRATE: ["選択した消費税コードでは設定できない消費税率です。"],
                /** 科目区分の売上・仕入変更確認 */
                TAXKBNCHANGESALESANDPURCHASE: ["売上・仕入区分の変更がありました。消費税コード、端数処理、業種コードにて問題が発生する可能性があります。マスター再計算を実行するようにしてください。"],
                /** 科目区分の対象外変更確認 */
                TAXKBNCHANGENOTAPPLICABLE: ["仕訳データが入力済みです。変更してもよろしいですか？変更後は、マスター再計算を行ってください。"],
            },

            /**
             * 共通仕入部門区分名称
             */
            Cmnjournalizebmnkbnname: {
                /** 課税・非課税売上共通課税仕入部門 */
                TAXCMNJOURNALIZE: ["課税・非課税売上共通課税仕入部門"],
                /** 非課税売上対応課税仕入部門 */
                TAXEXEMPTIONJOURNALIZE: ["非課税売上対応課税仕入部門"],
            },
        },

        /**
         * 科目出力順序
         */
        Kmkorder: {

            /**
             * 分類
             */
            Classification: {
                /** 営業 */
                SALES: ["営業"],
                /** 医業 */
                MEDICAL: ["医業"],
                /** 資産の部 */
                ASSETSSECTION: ["資産の部"],
                /** 負債の部 */
                LIABILITIES: ["負債の部"],
                /** 純資産の部 */
                STOCKHOLDERS: ["純資産の部"],
                /** 負債及び純資産の部 */
                LIABILITIESSTOCKHOLDERS: ["負債及び純資産の部"],
                /** 損益の部 */
                INCOME: ["{0};損益の部"],
                /** 外損益の部 */
                OUTINCOME: ["{0};外損益の部"],
                /** 経常損益の部 */
                ORDINARYINCOME: ["経常損益の部"],
                /** 特別損益の部 */
                EXTRAORDINARYINCOME: ["特別損益の部"],
                /** 資本の部 */
                DEPARTMENTOFCAPITAL: ["資本の部"],
                /** 決算報告書 */
                FINANCIALSTATEMENTS: ["決算報告書"],
            },

            /**
             * 帳票種別
             */
            Repoclass: {
                /** 試算表 */
                TB: ["試算表"],
                /** 試算表(部門別) */
                TBBMN: ["試算表(部門別)"],
            },

            /**
             * 装飾区分
             */
            Decokbn: {
                /** 標準 */
                OUTPUTDECOKBN0: ["標準"],
                /** 【 】 */
                OUTPUTDECOKBN1: ["【 】"],
                /** （ ） */
                OUTPUTDECOKBN2: ["（ ）"],
                /** 〔 〕 */
                OUTPUTDECOKBN3: ["〔 〕"],
                /** ＜ ＞ */
                OUTPUTDECOKBN4: ["＜ ＞"],
                /** (( )) */
                OUTPUTDECOKBN5: ["(( ))"],
                /** ｛ ｝ */
                OUTPUTDECOKBN6: ["｛ ｝"],
                /** なし */
                OUTPUTDECOKBN99: ["なし"],
            },

            /**
             * インデント
             */
            Charkbn1: {
                /** そのまま */
                OUTPUTCHARKBN0: ["そのまま"],
                /** 最左端 */
                OUTPUTCHARKBN1: ["最左端"],
                /** １段右へ */
                OUTPUTCHARKBN2: ["１段右へ"],
                /** ２段右へ */
                OUTPUTCHARKBN3: ["２段右へ"],
                /** ３段右へ */
                OUTPUTCHARKBN4: ["３段右へ"],
            },

            /**
             * 金額出力区分
             */
            Numkbn1: {
                /** デフォルト */
                OUTPUTNUMKBN0: ["デフォルト"],
                /** 前行の右側に出力 */
                OUTPUTNUMKBN1: ["前行の右側に出力"],
                /** 左側に出力 */
                OUTPUTNUMKBN2: ["左側に出力"],
            },

            /**
             * マスター区分
             */
            Masterkbn: {
                /** 段組変更 */
                STAGESETCHANGES: ["段組変更"],
                /** 強制空白 */
                FORCEBLANK: ["強制空白"],
                /** 強制改頁 */
                FORCEPAGEBREAK: ["強制改頁"],
            },

            /**
             * 工事特殊科目
             */
            Kojiexekmk: {
                /** 契約金額 */
                CONTRACTSUM: ["契約金額"],
                /** 回収高 */
                COLLECTBALANCE: ["回収高"],
                /** 回収率 */
                COLLECTRATE: ["回収率"],
                /** 工事収支 */
                KOJIBALANCE: ["工事収支"],
                /** バランス検定 */
                BALANCEVERIFICATIONSIMPLE: ["バランス検定"],
                /** バランス検定（収支率） */
                BALANCEVERIFICATION: ["バランス検定（収支率）"],
                /** 希望利益 */
                REQUESTPROFIT: ["希望利益"],
                /** 消化率 */
                DIGESTIBILITY: ["消化率"],
                /** 予算評価益 */
                BUDGETAPPRAISALPROFIT: ["予算評価益"],
                /** 希望利益率 */
                REQUESTPROFITRATE: ["希望利益率"],
                /** 予算原価率 */
                BUDGETCOSTRATE: ["予算原価率"],
                /** 回収残 */
                COLLECTREMAINING: ["回収残"],
                /** 実現利益 */
                REALIZEDPROFIT: ["実現利益"],
                /** 利益達成率 */
                PROFITACHIEVEMENTRATE: ["利益達成率"],
                /** 実現利益率 */
                REALIZEDPROFITRATE: ["実現利益率"],
                /** 原価率 */
                COSTRATE: ["原価率"],
            },

            /**
             * 資金繰特殊科目
             */
            Financingexekmk: {
                /** 固定性預金 */
                FIXINGDEPOSIT: ["固定性預金"],
                /** 受取手形 */
                BILLRECEIPT: ["受取手形"],
                /** 売掛金 */
                CREDIT: ["売掛金"],
                /** 支払手形 */
                BILLPAYMENT: ["支払手形"],
                /** 買掛金 */
                ACCOUNTSPAYABLE: ["買掛金"],
                /** 短期借入金 */
                SHORTLOAN: ["短期借入金"],
                /** 長期借入金 */
                LONGLOAN: ["長期借入金"],
            },

            /**
             * 決算書印刷項目
             */
            Financialstatementsprintitem: {
                /** 積立金取崩１ */
                REFUNDRESERVE1: ["積立金取崩１"],
                /** 積立金取崩２ */
                REFUNDRESERVE2: ["積立金取崩２"],
                /** 積立金取崩３ */
                REFUNDRESERVE3: ["積立金取崩３"],
                /** 積立金取崩４ */
                REFUNDRESERVE4: ["積立金取崩４"],
                /** 積立金取崩５ */
                REFUNDRESERVE5: ["積立金取崩５"],
                /** 積立金取崩６ */
                REFUNDRESERVE6: ["積立金取崩６"],
                /** 積立金取崩７ */
                REFUNDRESERVE7: ["積立金取崩７"],
                /** 積立金取崩８ */
                REFUNDRESERVE8: ["積立金取崩８"],
                /** 積立金取崩９ */
                REFUNDRESERVE9: ["積立金取崩９"],
                /** 積立金取崩１０ */
                REFUNDRESERVE10: ["積立金取崩１０"],
                /** 積立金取崩額合計 */
                REFUNDRESERVETOTAL: ["積立金取崩額合計"],
                /** 利益金処分額１ */
                DISPOSITIONPROFITS1: ["利益金処分額１"],
                /** 利益金処分額２ */
                DISPOSITIONPROFITS2: ["利益金処分額２"],
                /** 利益金処分額３ */
                DISPOSITIONPROFITS3: ["利益金処分額３"],
                /** 利益金処分額４ */
                DISPOSITIONPROFITS4: ["利益金処分額４"],
                /** 利益金処分額５ */
                DISPOSITIONPROFITS5: ["利益金処分額５"],
                /** 利益金処分額６ */
                DISPOSITIONPROFITS6: ["利益金処分額６"],
                /** 利益金処分額７ */
                DISPOSITIONPROFITS7: ["利益金処分額７"],
                /** 利益金処分額８ */
                DISPOSITIONPROFITS8: ["利益金処分額８"],
                /** 利益金処分額９ */
                DISPOSITIONPROFITS9: ["利益金処分額９"],
                /** 利益金処分額１０ */
                DISPOSITIONPROFITS10: ["利益金処分額１０"],
                /** 利益金処分額１１ */
                DISPOSITIONPROFITS11: ["利益金処分額１１"],
                /** 利益金処分額１２ */
                DISPOSITIONPROFITS12: ["利益金処分額１２"],
                /** 利益金処分額１３ */
                DISPOSITIONPROFITS13: ["利益金処分額１３"],
                /** 利益金処分額１４ */
                DISPOSITIONPROFITS14: ["利益金処分額１４"],
                /** 利益金処分額１５ */
                DISPOSITIONPROFITS15: ["利益金処分額１５"],
                /** 当期利益処分額合計 */
                CURRENTTERMDISPOSITIONPROFITSTOTAL: ["当期利益処分額合計"],
                /** 当期未処分利益 */
                CURRENTTERMUNDISPUTEDPROFITS: ["当期未処分利益"],
                /** 次期繰越利益 */
                NEXTTERMRETAINEDEARNINGS: ["次期繰越利益"],
                /** 報告年月日 */
                REPORTDATE: ["報告年月日"],
                /** 監査年月日 */
                AUDITDATE: ["監査年月日"],
                /** Ｂ／Ｓ脚注１ */
                BSFOOTNOTE1: ["Ｂ／Ｓ脚注１"],
                /** Ｂ／Ｓ脚注２ */
                BSFOOTNOTE2: ["Ｂ／Ｓ脚注２"],
                /** Ｂ／Ｓ脚注３ */
                BSFOOTNOTE3: ["Ｂ／Ｓ脚注３"],
                /** Ｂ／Ｓ脚注４ */
                BSFOOTNOTE4: ["Ｂ／Ｓ脚注４"],
                /** Ｂ／Ｓ脚注５ */
                BSFOOTNOTE5: ["Ｂ／Ｓ脚注５"],
                /** Ｂ／Ｓ脚注６ */
                BSFOOTNOTE6: ["Ｂ／Ｓ脚注６"],
                /** Ｂ／Ｓ脚注７ */
                BSFOOTNOTE7: ["Ｂ／Ｓ脚注７"],
                /** Ｂ／Ｓ脚注８ */
                BSFOOTNOTE8: ["Ｂ／Ｓ脚注８"],
                /** Ｂ／Ｓ脚注９ */
                BSFOOTNOTE9: ["Ｂ／Ｓ脚注９"],
                /** Ｂ／Ｓ脚注１０ */
                BSFOOTNOTE10: ["Ｂ／Ｓ脚注１０"],
                /** Ｂ／Ｓ脚注１１ */
                BSFOOTNOTE11: ["Ｂ／Ｓ脚注１１"],
                /** Ｂ／Ｓ脚注１２ */
                BSFOOTNOTE12: ["Ｂ／Ｓ脚注１２"],
                /** Ｂ／Ｓ脚注１３ */
                BSFOOTNOTE13: ["Ｂ／Ｓ脚注１３"],
                /** Ｂ／Ｓ脚注１４ */
                BSFOOTNOTE14: ["Ｂ／Ｓ脚注１４"],
                /** Ｂ／Ｓ脚注１５ */
                BSFOOTNOTE15: ["Ｂ／Ｓ脚注１５"],
                /** Ｂ／Ｓ脚注１６ */
                BSFOOTNOTE16: ["Ｂ／Ｓ脚注１６"],
                /** Ｂ／Ｓ脚注１７ */
                BSFOOTNOTE17: ["Ｂ／Ｓ脚注１７"],
                /** Ｂ／Ｓ脚注１８ */
                BSFOOTNOTE18: ["Ｂ／Ｓ脚注１８"],
                /** Ｂ／Ｓ脚注１９ */
                BSFOOTNOTE19: ["Ｂ／Ｓ脚注１９"],
                /** Ｂ／Ｓ脚注２０ */
                BSFOOTNOTE20: ["Ｂ／Ｓ脚注２０"],
                /** Ｐ／Ｌ脚注１ */
                PLFOOTNOTE1: ["Ｐ／Ｌ脚注１"],
                /** Ｐ／Ｌ脚注２ */
                PLFOOTNOTE2: ["Ｐ／Ｌ脚注２"],
                /** Ｐ／Ｌ脚注３ */
                PLFOOTNOTE3: ["Ｐ／Ｌ脚注３"],
                /** Ｐ／Ｌ脚注４ */
                PLFOOTNOTE4: ["Ｐ／Ｌ脚注４"],
                /** Ｐ／Ｌ脚注５ */
                PLFOOTNOTE5: ["Ｐ／Ｌ脚注５"],
                /** Ｐ／Ｌ脚注６ */
                PLFOOTNOTE6: ["Ｐ／Ｌ脚注６"],
                /** Ｐ／Ｌ脚注７ */
                PLFOOTNOTE7: ["Ｐ／Ｌ脚注７"],
                /** Ｐ／Ｌ脚注８ */
                PLFOOTNOTE8: ["Ｐ／Ｌ脚注８"],
                /** Ｐ／Ｌ脚注９ */
                PLFOOTNOTE9: ["Ｐ／Ｌ脚注９"],
                /** Ｐ／Ｌ脚注１０ */
                PLFOOTNOTE10: ["Ｐ／Ｌ脚注１０"],
                /** Ｐ／Ｌ脚注１１ */
                PLFOOTNOTE11: ["Ｐ／Ｌ脚注１１"],
                /** Ｐ／Ｌ脚注１２ */
                PLFOOTNOTE12: ["Ｐ／Ｌ脚注１２"],
                /** Ｐ／Ｌ脚注１３ */
                PLFOOTNOTE13: ["Ｐ／Ｌ脚注１３"],
                /** Ｐ／Ｌ脚注１４ */
                PLFOOTNOTE14: ["Ｐ／Ｌ脚注１４"],
                /** Ｐ／Ｌ脚注１５ */
                PLFOOTNOTE15: ["Ｐ／Ｌ脚注１５"],
                /** Ｐ／Ｌ脚注１６ */
                PLFOOTNOTE16: ["Ｐ／Ｌ脚注１６"],
                /** Ｐ／Ｌ脚注１７ */
                PLFOOTNOTE17: ["Ｐ／Ｌ脚注１７"],
                /** Ｐ／Ｌ脚注１８ */
                PLFOOTNOTE18: ["Ｐ／Ｌ脚注１８"],
                /** Ｐ／Ｌ脚注１９ */
                PLFOOTNOTE19: ["Ｐ／Ｌ脚注１９"],
                /** Ｐ／Ｌ脚注２０ */
                PLFOOTNOTE20: ["Ｐ／Ｌ脚注２０"],
                /** 〔会計方針等〕 */
                ACCOUNTINGPOLICIES: ["〔会計方針等〕"],
                /** 棚卸評価方法 */
                INVENTORYEVALUATIONPOLICY: ["棚卸評価方法"],
                /** 固定資産償却方法 */
                FIXEDASSETDEPRECIATIONMETHOD: ["固定資産償却方法"],
                /** 〔貸借対照表関係〕 */
                BALANCESHEETRELATION: ["〔貸借対照表関係〕"],
                /** 受取手形割引高 */
                BILLSRECIVABLEDISCOUNT: ["受取手形割引高"],
                /** 不渡手形 */
                DISHONOREDBILL: ["不渡手形"],
                /** 裏書譲渡手形 */
                NOTEENDORSED: ["裏書譲渡手形"],
                /** 減価償却累計額 */
                ACCUMULATEDDEPRECIATION: ["減価償却累計額"],
                /** １株当たりの当期純利益 */
                EARNINGSPERSHARE: ["１株当たりの当期純利益"],
                /** 〔損益計算書関係〕 */
                PROFITANDLOSSSTATEMENTRELATION: ["〔損益計算書関係〕"],
                /** 資本剰余金処分額１ */
                AMOUNTCAPITALSURPLUSDISPOSITION1: ["資本剰余金処分額１"],
                /** 資本剰余金処分額２ */
                AMOUNTCAPITALSURPLUSDISPOSITION2: ["資本剰余金処分額２"],
                /** 資本剰余金処分額３ */
                AMOUNTCAPITALSURPLUSDISPOSITION3: ["資本剰余金処分額３"],
                /** 資本剰余金処分額４ */
                AMOUNTCAPITALSURPLUSDISPOSITION4: ["資本剰余金処分額４"],
                /** 資本剰余金処分額５ */
                AMOUNTCAPITALSURPLUSDISPOSITION5: ["資本剰余金処分額５"],
                /** 資本剰余金処分額６ */
                AMOUNTCAPITALSURPLUSDISPOSITION6: ["資本剰余金処分額６"],
                /** 資本剰余金処分額７ */
                AMOUNTCAPITALSURPLUSDISPOSITION7: ["資本剰余金処分額７"],
                /** 資本剰余金処分額８ */
                AMOUNTCAPITALSURPLUSDISPOSITION8: ["資本剰余金処分額８"],
                /** 資本剰余金処分額９ */
                AMOUNTCAPITALSURPLUSDISPOSITION9: ["資本剰余金処分額９"],
                /** 資本剰余金処分額１０ */
                AMOUNTCAPITALSURPLUSDISPOSITION10: ["資本剰余金処分額１０"],
                /** 資本剰余金処分額合計 */
                AMOUNTCAPITALSURPLUSDISPOSITIONTOTAL: ["資本剰余金処分額合計"],
                /** その他資本剰余金 */
                OTHERCAPITALSURPLUS: ["その他資本剰余金"],
                /** その他資本剰余金次期繰越額 */
                NEXTTERMOTHERCAPITALSURPLUS: ["その他資本剰余金次期繰越額"],
            },

            /**
             * コンテキストメニュー
             */
            Contextmenu: {
                /** 挿入 */
                INSERT: ["挿入"],
                /** 削除 */
                DELETE: ["削除"],
                /** 強制空白 */
                FORCEBLANK: ["強制空白"],
                /** 強制改頁 */
                FORCEPAGEBREAK: ["強制改頁"],
                /** 段組変更 */
                STEPEDIT: ["段組変更"],
                /** 勘定科目 */
                COUNTINGKMK: ["勘定科目"],
                /** タイトル科目 */
                TITLEKMK: ["タイトル科目"],
                /** 科目挿入 */
                KMKINSERT: ["科目挿入"],
                /** 範囲削除 */
                RANGEDELETE: ["範囲削除"],
                /** 資金繰科目 */
                FINANCINGKMK: ["資金繰科目"],
                /** 資金繰特殊科目 */
                FINANCINGEXEKMK: ["資金繰特殊科目"],
            },

            /**
             * パターンコピー
             */
            Patterncopy: {
                /** 登録確認 */
                REGISTRATIONCONFIRMATION: ["パターン「{0};」をパターン「{1};」にコピーします。\nよろしいですか？"],
                /** 更新確認 */
                UPDATECONFIRMATION: ["パターン「{0};」の内容に上書きしますがよろしいですか？"],
                /** 新規パターン */
                NEWPATTERN: ["（新規パターン）"],
                /** 同一パターン */
                SAMEPATTERN: ["コピー元とコピー先に同一パターンは設定できません。"],
            },
        },

        /**
         * ウィンドウ表示順序
         */
        Windowdisplayorder: {

            /**
             * 確認
             */
            Confirm: {
                /** 上書確認 */
                OVERWRITECONFIRMATION: ["ウィンドウ表示順序に全科目を登録します。登録済みの内容に上書きされますがよろしいですか？"],
                /** 登録確認 */
                REGISTRATIONCONFIRMATION: ["ウィンドウ表示順序に全科目を登録しますがよろしいですか？"],
            },
        },

        /**
         * 資金繰
         */
        Financing: {

            /**
             * 確認
             */
            Confirm: {
                /** 誤り */
                MISTAKE: ["月が間違っています。"],
            },
        },

        /**
         * 資金繰予算
         */
        Financingbudget: {
            /** 資金繰予算 */
            FINANCINGBUDGET: ["資金繰予算"],

            /**
             * 入力型
             */
            Inputtype: {
                /** 月次入力型 */
                MONTHLY: ["【月次型入力】"],
                /** 年次入力型 */
                ANNUAL: ["【年次型入力】"],
            },
        },

        /**
         * 業種
         */
        Industry: {

            /**
             * 名称
             */
            Name: {
                /** 基準業種 */
                STANDARD: ["基準業種"],
                /** 第1種事業 */
                FIRSTPROJECT: ["第１種（卸売業）"],
                /** 第2種事業 */
                SECONDPROJECT: ["第２種（小売業）"],
                /** 第3種事業 */
                THIRDPROJECT: ["第３種（製造業）"],
                /** 第4種事業 */
                FOURTHPROJECT: ["第４種（その他）"],
                /** 第5種事業 */
                FIFTHPROJECT: ["第５種（サービス業）"],
                /** 第6種事業 */
                SIXTHPROJECT: ["第６種（不動産業）"],
            },
        },

        /**
         * 共通
         */
        Common: {
            /** ツリー体系名 */
            TREEROOTNAME: ["{0};体系"],
            /** 科目別消費税 */
            ACCOUNTTAX: ["科目別消費税"],
            /** 科目別業種 */
            ACCOUNTINDUSTRY: ["科目別業種"],
            /** タイトル科目 */
            TITLEKMK: ["タイトル科目"],
            /** 実働勘定科目 */
            ACTUALCOUNTINGKMK: ["実働勘定科目"],
            /** 実在勘定科目 */
            REALCOUNTINGKMK: ["実在勘定科目"],
            /** 損益科目 */
            PLKMK: ["損益科目"],
            /** 非会計科目 */
            NONACCOUNTING: ["非会計科目"],
            /** 資金繰加算体系 */
            FINANCINGADDITIONSYSTEM: ["資金繰加算体系"],
            /** 資金繰特殊科目 */
            FINANCINGEXEKMK: ["資金繰特殊科目"],

            /**
             * 確認
             */
            Confirm: {
                /** 更新 */
                REGIST: ["編集中のデータを保存します。\nよろしいですか？"],
                /** 入力取消 */
                CANCELENTRY: ["編集中のデータを取消します。\nよろしいですか？"],
                /** 削除 */
                REMOVE: ["\"{0};\"のレコードを削除します。よろしいですか？"],
            },

            /**
             * 採用
             */
            Adoption: {
                /** なし */
                NONE: ["{0};が採用されていないので、この処理は行えません。"],
            },

            /**
             * 諸口
             */
            Sundry: {
                /** なし */
                NONE: ["{0};マスターが未登録のため、この処理は行えません。"],
            },

            /**
             * 種類
             */
            Type: {
                /** 科目別補助 */
                EACHKMKAUXILIARY: ["科目別補助"],
                /** セグメント２ */
                SEGMENT2: ["セグメント２"],
                /** セグメント３ */
                SEGMENT3: ["セグメント３"],
                /** セグメント４ */
                SEGMENT4: ["セグメント４"],
                /** セグメント５ */
                SEGMENT5: ["セグメント５"],
            },
        },

        /**
         * 基本情報
         */
        Firminfomation: {

            /**
             * エラー
             */
            Error: {
                /** 中間決算区分変更 */
                EDITMIDDLEKBN: ["仕訳データが登録済みです。区分を変更する事はできません。"],
                /** 科目別補助予算区分変更 */
                EDITEACHKMKAUXILIARYBUDGETKBN: ["勘定科目の予算採用を超える区分を設定することはできません。"],
                /** 電子帳簿採用 */
                EDITSWKHISTORYUSEEBOOKOBJECT: ["電子帳簿採用のため、変更はできません。"],
                /** 仕入の税額計算区分が積上方式２ */
                BCALCKBNISPILINGSYSTEM2: ["消費税申告書－会社基本情報にある「仕入の税額計算区分」で\n『積上方式２（内税分を含む）』が設定されている為、\n『切り上げ』を設定することはできません。"],
                /** 部門別消費税集計区分変更 */
                EDITDEPARTMENTALTAXAGGREGATEKBM: ["部門が未採用なので集計することはできません。"],
                /** 同一部門コード */
                BMNCODEDUPLICATED: ["既に同一コードが登録済みです。"],
                /** 同一部門コードが共通仕入部門に存在している */
                BMNCODEDUPLICATEDOTHERCOMMONPURCHASEBMN: ["既に同一コードが{0};に登録済みです。"],
            },

            /**
             * 確認
             */
            Confirm: {
                /** 仕訳データ入力済み */
                ENTEREDSWK: ["仕訳データが入力済みです。変更してもよろしいですか？\n変更後は、マスター再計算処理を行ってください。"],
                /** 消費税申告書データ削除 */
                DELETETAXDATA: ["免税データからの変更を行う場合、消費税申告書データを削除する必要があります。\n削除してもよろしいでしょうか？"],
                /** 輸入取引区分を消費税一括処理に変更 */
                EDITIMPORTKBNTAXBULK: ["消費税一括処理を選択してよろしいですか？\n輸入許可書に記載されている消費税／地方消費税を入力するには\n「税抜処理（国税／地方税分割）」を選択してください。"],
            },

            /**
             * 案内
             */
            Info: {
                /** 輸入取引区分なしに変更 */
                EDITIMPORTKBNNONE: ["「なし」の場合は、輸入関係の消費税コードを使用することはできません。\n科目別消費税コードの設定内容を再確認してください。"],
            },

            /**
             * 役職名
             */
            Positionname: {
                /** 代表取締役社長 */
                PRESIDENT: ["代表取締役社長"],
                /** 代表取締役会長 */
                CHAIRMAN: ["代表取締役会長"],
                /** 代表取締役副社長 */
                VICEPRESIDENT: ["代表取締役副社長"],
                /** 専務取締役 */
                SENIORMANAGINGDIRECTOR: ["専務取締役"],
                /** 常務取締役 */
                MANAGINGDIRECTOR: ["常務取締役"],
                /** 取締役 */
                DIRECTOR: ["取締役"],
                /** 監査役 */
                AUDITOR: ["監査役"],
            },

            /**
             * 補足
             */
            Note: {
                /** 簡略名称 */
                SIMPLENAME: ["正式名称をコピーする場合は「F8」を押下してください。"],
                /** 決算期（期数） */
                NUMBEROFPERIODS: ["決算報告書の表紙に印刷されます。"],
                /** 設立年月日 */
                ESTABLISHEDDATE: ["会計期間内で入力した場合は新規設立会社となり、財務報告書の「自至」の「自」となります。"],
            },

            /**
             * 月次予算採用区分
             */
            Mbudgetkbn: {
                /** なし */
                NONE: ["なし"],
                /** 予算１ */
                BUDGETKBN1: ["予算１"],
                /** 予算１～２ */
                BUDGETKBN2: ["予算１～２"],
                /** 予算１～３ */
                BUDGETKBN3: ["予算１～３"],
                /** 予算１～４ */
                BUDGETKBN4: ["予算１～４"],
            },

            /**
             * 簡易課税基準業種
             */
            Basedtype: {
                /** 卸売業 */
                WHOLESALEBUSINESS: ["第１種(卸売業)"],
                /** 小売業 */
                RETAILTRADE: ["第２種(小売業)"],
                /** 製造業 */
                MANUFACTURINGINDUSTRY: ["第３種(製造業)"],
                /** その他 */
                OTHERS: ["第４種(その他)"],
                /** サービス業 */
                SERVICEINDUSTRY: ["第５種(サービス業)"],
                /** 不動産業 */
                ESTATE: ["第６種(不動産業)"],
            },

            /**
             * 輸入取引区分
             */
            Importkbn: {
                /** なし */
                NONE: ["なし"],
                /** 税抜処理（消費税一括処理） */
                EXCLUDEDCONSUMPTION: ["税抜処理(消費税一括処理)"],
                /** 税抜処理（国税/地方税分割） */
                EXCLUDINGTAXNATIONALLOCAL: ["税抜処理(国税／地方税分割)"],
                /** 税込処理（消費税一括処理） */
                TAXINCLUDEDCONSUMPTION: ["税込処理(消費税一括処理)"],
            },

            /**
             * 通知
             */
            Notification: {
                /** 参照 */
                REFERENCE: ["以下の項目以外は参照のみです。\n・基本情報\n・「自動変換区分（仕訳入力・インポート）」"],
                /** 更新 */
                UPDATE: ["更新しました。"],
            },
        },

        /**
         * 各種マスター採用情報
         */
        Variousmasteradoptinginformation: {

            /**
             * マスター存在エラー
             */
            Masterexistserror: {
                /** 採用区分 */
                USEKBN: ["マスターが登録済みです。\n変更はできません。"],
                /** 桁数 */
                CODEDIGIT: ["データが登録済みです。桁数を変更する事はできません。"],
                /** 属性 */
                CODEATTR: ["データが登録済みです。属性を変更する事はできません。"],
                /** 配賦採用区分 */
                ADOPTION: ["データが登録済みです。配賦採用を変更する事はできません。"],
            },

            /**
             * 入力値エラー
             */
            Inputerror: {
                /** 桁数 */
                CODEDIGIT: ["桁数は3～10の範囲で設定してください"],
            },

            /**
             * コード体系エラー
             */
            Codesystemerror: {
                /** 工事PL */
                KOJIPL: ["工事Ｐ／Ｌ型のコード体系ではありません。"],
                /** 工事BS */
                KOJIBS: ["工事Ｂ／Ｓ型のコード体系ではありません。"],
            },

            /**
             * 科目別採用
             */
            Masteruse: {
                /** 採用あり */
                MASTERUSEERROR: ["既に採用済の科目があるため、変更することはできません。"],
                /** 入金管理区分の採用不可 */
                DEPOSITCONTROLCLASSIFICATIONNOTPOSSIBLE: ["未成工事受入金・完成工事未収入金に部門、補助が採用されている場合は工事別の入金管理は行えません。"],
            },

            /**
             * 工事基本情報
             */
            Kojibasicinfo: {
                /** 管理種別（P / L型） */
                MANAGEMENTTYPEPL: ["P / L型"],
                /** 管理種別（P / L型(未成・完成区別なし)） */
                MANAGEMENTTYPEPLNOCLASSIFICATION: ["P / L型(未成・完成区別なし)"],
            },
        },

        /**
         * 科目別採用
         */
        Adoptauxiliaryaccount: {

            /**
             * マスター区分
             */
            Masterkbn: {
                /** 全科目 */
                ALLCOUNTINGKMK: ["全科目"],
            },

            /**
             * 採用区分
             */
            Adoptionkbn: {
                /** 採用なし */
                NOTADOPTED: ["採用なし"],
                /** 採用あり */
                ADOPTED: ["採用あり"],
            },

            /**
             * 部門採用区分
             */
            Bmnadoptionkbn: {
                /** 共通部門 */
                COMMONBMN: ["共通部門"],
            },

            /**
             * セグメント採用区分
             */
            Segmentadoptionkbn: {
                /** 共通セグメント */
                COMMONSEGMENT: ["共通セグメント"],
            },

            /**
             * 確認
             */
            Confirm: {
                /** 銀行コード削除 */
                DELETEBANKCODE: ["この科目は、銀行コードが設定されています。\n銀行コードをクリアし、銀行補助の採用をしますか？"],
            },

            /**
             * エラー
             */
            Error: {
                /** 仕訳データ入力済み */
                ENTEREDSWKDATA: ["仕訳データが入力済みです。科目採用区分を変更する事はできません。"],
                /** 第2補助採用済み */
                ADOPTEDHOJYO2: ["第2補助で補助が採用されています。科目採用区分を変更する事はできません。"],
                /** マスター登録済み */
                MASTERREGISTERED: ["マスターが登録済です。科目採用区分を変更する事はできません。"],
                /** 同一の共通補助採用 */
                SAMECOMMONADOPTION: ["第1補助と第2補助で同一の補助が採用されています。科目採用区分を変更する事はできません。"],
                /** 第1補助未採用 */
                NOTADOPTEDHOJYO1: ["第1補助で補助が採用されていません。科目採用区分を変更する事はできません。"],
                /** 採用数を超えている */
                EXCEEDNUMBERADOPTED: ["勘定科目単位の採用範囲を超えています。科目採用区分を変更する事はできません。"],
                /** 連動ユーザー仕訳データ入力済み */
                LINKAGEUSERENTEREDSWKDATA: ["仕訳データが入力済みです。科目採用区分を変更する事はできません。\n補助の採用を行ってください。"],
                /** 共通補助と工事の併用不可 */
                NOCOMBINATIONOFCOMMONHOJYOANDKOUJI: ["補助と工事の併用はできません。"],
                /** 科目別補助と工事の併用不可 */
                NOCOMBINATIONOFSUBKMKANDKOUJI: ["科目別補助と工事の併用はできません。"],
                /** 部門と工事の併用不可 */
                NOCOMBINATIONOFBMNANDKOUJI: ["部門と工事の併用はできません。"],
                /** セグメントと工事の併用不可 */
                NOCOMBINATIONOFSEGMENTANDKOUJI: ["セグメントと工事の併用はできません。"],
                /** セグメント2と工事の併用不可 */
                NOCOMBINATIONOFSEGMENT2ANDKOUJI: ["セグメント2と工事の併用はできません。"],
                /** セグメント3と工事の併用不可 */
                NOCOMBINATIONOFSEGMENT3ANDKOUJI: ["セグメント3と工事の併用はできません。"],
                /** セグメント4と工事の併用不可 */
                NOCOMBINATIONOFSEGMENT4ANDKOUJI: ["セグメント4と工事の併用はできません。"],
                /** セグメント5と工事の併用不可 */
                NOCOMBINATIONOFSEGMENT5ANDKOUJI: ["セグメント5と工事の併用はできません。"],
                /** 事務所連動済み */
                OFFICELINKED: ["NX-Pro会計大将と会計事務所連動を行っているため、採用を変更できません。"],
                /** 部門管理又はセグメント管理未採用 */
                NOTADOPTEDBMNORSEGMENT: ["部門管理又はセグメント管理が採用されていません。科目採用区分を変更する事はできません。"],
                /** 特殊科目に部門又はセグメント採用不可 */
                SPECIALACCOUNTCANNOTADOPTEDFORBMNORSEGMENT: ["特殊科目に部門又はセグメントは採用できません。科目採用区分を変更する事はできません。"],
                /** P/L科目採用範囲外の科目に部門又はセグメント採用不可 */
                PLACCOUNTOUTOFADOPTIONRANGETCANNOTADOPTEDFORBMNORSEGMENT: ["P/L科目採用範囲外の科目に部門又はセグメントが採用されています。科目採用区分を変更する事はできません。"],
                /** B/S科目に部門又はセグメント採用不可 */
                BSACCOUNTCANNOTADOPTEDFORBMNORSEGMENT: ["管理区分が「P/Lのみ採用」のため、B/S科目には部門又はセグメントを採用できません。科目採用区分を変更する事はできません。"],
                /** B/S科目採用範囲外の科目に部門又はセグメント採用不可 */
                BSACCOUNTOUTOFADOPTIONRANGETCANNOTADOPTEDFORBMNORSEGMENT: ["B/S科目採用範囲外の科目に部門又はセグメントが採用されています。科目採用区分を変更する事はできません。"],
                /** 工事未採用 */
                NOTADOPTEDKOUJI: ["仕訳データが入力済みです。科目採用区分を変更する事はできません。\n補助の採用を行ってください。"],
                /** 工事の採用変更不可 */
                CANNOTCHANGEADOPTIONOFKOUJI: ["未成工事受入金科目又は完成工事未収入金科目に設定している科目の工事が「採用なし」になっています。科目採用区分を変更する事はできません。"],
                /** 特殊科目に工事採用不可 */
                SPECIALACCOUNTCANNOTADOPTEDFORKOUJI: ["特殊科目に工事が採用されています。科目採用区分を変更する事はできません。"],
                /** 工事採用範囲外の科目に工事採用不可 */
                KOUJIOUTOFADOPTIONRANGETCANNOTADOPTEDFORKOUJI: ["工事採用範囲外の科目に工事が採用されています。科目採用区分を変更する事はできません。"],
                /** 共通補助未採用 */
                NOTADOPTEDCOMMONHOJYO: ["共通補助管理が採用されていない補助が科目に採用されています。科目採用区分を変更する事はできません。"],
            },
        },

        /**
         * 科目別補助登録
         */
        Accountdetail: {

            /**
             * 採用
             */
            Adoption: {
                /** なし */
                NONE: ["{0};を採用している科目が設定されていないため登録できません。"],
            },

            /**
             * 銀行コード
             */
            Bankcode: {
                /** 実在 */
                REAL: ["実在で銀行コードを採用しています。"],
            },
        },

        /**
         * 補助登録
         */
        Hojyo: {
            /** 補足 */
            NOTE: ["マスターが新規登録されました。\n出力順序などの設定の見直しが必要と思われます。"],

            /**
             * 初期表示
             */
            Initial: {
                /** 存在なし */
                NOTEXISTS: ["諸口マスターが設定されていないため登録できません。"],
            },

            /**
             * 情報
             */
            Information: {
                /** 合計科目 */
                ISTOTALKMK: ["合計{0};として同一コードが登録済です。"],
            },

            /**
             * コンテキストメニュー
             */
            Contextmenu: {
                /** 他の加算先へコピー */
                COPY: ["他の加算先へコピー"],
                /** 他の加算先へ移動 */
                MOVE: ["他の加算先へ移動"],
                /** 他の加算先から貼り付け */
                PASTE: ["他の加算先から貼り付け"],
                /** 加算しない */
                NOTADD: ["加算しない"],
                /** 削除 */
                DELETE: ["削除"],
                /** 全て展開 */
                OPEN: ["全て展開"],
                /** 全て閉じる */
                CLOSE: ["全て閉じる"],
            },

            /**
             * 資金繰コード
             */
            Cashcode: {
                /** 科目に従う */
                DEFAULTOPTION: ["科目に従う"],
            },
        },

        /**
         * 取引先登録
         */
        Client: {

            /**
             * 確認
             */
            Confirm: {
                /** 得意先 */
                CUSTOMERSUSE: ["得意先区分を未採用にします。\n既に仕訳が存在している場合は、諸口マスターに置き換わる場合がありますので注意してください。\n未採用にしますか？"],
                /** 仕入先 */
                SUPPLIERUSE: ["仕入先区分を未採用にします。\n既に仕訳が存在している場合は、諸口マスターに置き換わる場合がありますので注意してください。\n未採用にしますか？"],
            },
        },

        /**
         * 部門登録
         */
        Department: {

            /**
             * 部門性格
             */
            Departmentproperty: {
                /** 通常部門 */
                NORMAL: ["通常{0};"],
                /** 営業部門 */
                BUSINESS: ["営業{0};"],
                /** 製造部門 */
                MANUFACTURING: ["製造{0};"],
                /** 管理部門 */
                MANAGING: ["管理{0};"],
                /** 営業+管理部門 */
                BUSINESSANDMANAGEMENT: ["営業+管理{0};"],
                /** 製造+管理部門 */
                MANUFACTURINGMANAGING: ["製造+管理{0};"],
            },

            /**
             * 部門分類
             */
            Departmentclassification: {
                /** P/L部門 */
                PL: ["P/L{0};"],
                /** 共通部門（B/S+P/L） */
                COMMONBMNBSPL: ["共通{0};（B/S+P/L）"],
                /** B/S部門 */
                BS: ["B/S{0};"],
            },

            /**
             * 共通仕入部門区分
             */
            Departmentcmnjournalize: {
                /** 課税・非課税売上共通課税仕入部門 */
                DEPARTMENTTAXCMNJOURNALIZE: ["課税・非課税売上共通"],
                /** 非課税売上対応課税仕入部門 */
                DEPARTMENTTAXEXEMPTIONJOURNALIZE: ["非課税売上対応"],
            },

            /**
             * キャプション
             */
            Caption: {
                /** 部門分類 */
                DEPARTMENTCLASSIFICATION: ["{0};分類"],
                /** 部門性格 */
                DEPARTMENTPROPERTY: ["{0};性格コード"],
                /** 共通仕入部門 */
                DEPARTMENTTAXCMNJOURNALIZE: ["共通仕入{0};"],
                /** B/S計上部門 */
                BSBUMON: ["B/S計上{0};"],
            },
        },

        /**
         * 工事登録
         */
        Construction: {

            /**
             * 確認
             */
            Confirm: {
                /** 税率変更確認 */
                TAXRATEANDAMOUNT: ["契約情報の標準税率が変更になりますが、よろしいですか？\n変更した場合は消費税額の見直しをしてください。"],
                /** 税率変更確認 */
                CHANGETAXRATE: ["契約情報の標準税率が、{0};から{1};に変更になりますが、よろしいですか？"],
            },

            /**
             * 検証
             */
            Validate: {
                /** 特殊工事設定解除不可 */
                SPECIALCONSTRUCTION: ["特殊工事の設定解除は、他の工事を特殊工事に設定することにより自動的に行われます。特殊工事は、必ず１件の工事に対して設定することが必要です。"],
                /** 月次確定済み */
                FINISHDATE: ["月次確定済みの月のため、その日は入力できません。"],
                /** 無効な日付 */
                INVALIDDATE: ["決算開始日より前の日付を入力することはできません。"],
                /** 同一契約年月日 */
                DUPLICATECONTRACTDATE: ["同一年月日は入力できません。"],
                /** 矛盾税率 */
                TAXRATEINCONSISTENCY: ["「契約年月日」、「完成予定日」、「税率」の適用期間の組み合わせが一致していない契約情報があります。\n登録を見直してください。"],
            },
        },

        /**
         * 工事出力順序登録
         */
        Constructionoutputorder: {

            /**
             * 検証
             */
            Validate: {
                /** 重複分類コード */
                DUPLICATECLASSIFICATION: ["{0};分類は重複して登録することはできません。"],
                /** 不適切な順序 */
                INVALIDORDER: ["{0};の分類を使用する場合は、出力順序の先頭に登録してください。\n両方の分類を使用する場合は、{1};の順番で設定してください。"],
            },
        },

        /**
         * 工事完成情報
         */
        Completedconstructioninformation: {
            /** タイトル */
            TITLE: ["工事完成情報"],

            /**
             * 初期表示
             */
            Initial: {
                /** 存在なし */
                NOTEXISTS: ["諸口マスターが設定されていないため、この処理は行えません。"],
            },

            /**
             * エラー
             */
            Error: {
                /** 結果 */
                RESULT: ["更新処理でエラーが発生しました。"],
                /** 内容 */
                CONTENT: ["更新に失敗した工事が存在します。更新結果からエラー内容をご確認ください。"],
            },

            /**
             * 入力区分コンボボックス
             */
            Buncode: {
                /** 入力可 */
                INPUTPOSSIBLE: ["入力可"],
                /** 原価科目は不可 */
                COSTIMPOSSIBLE: ["原価科目は不可"],
                /** 入力不可 */
                INPUTIMPOSSIBLE: ["入力不可"],
            },

            /**
             * 翌期移送区分コンボボックス
             */
            Movekbn: {
                /** する */
                TODO: ["する"],
                /** しない */
                DONOT: ["しない"],
                /** 仕訳を含めて移送する */
                INCLUDE: ["仕訳を含めて移送する"],
            },

            /**
             * ラベル
             */
            Label: {
                /** 全ての工事 */
                ALLCONSTRUCTION: ["全ての工事"],
                /** 未成工事 */
                UNCOMPLETEDCONSTRUCTION: ["未成工事"],
            },
        },

        /**
         * 固定摘要登録
         */
        Fixedmemorandum: {

            /**
             * 摘要残高
             */
            Tekibalance: {
                /** 管理あり */
                MANAGED: ["管理あり"],
                /** 管理なし */
                NOTMANAGED: ["管理なし"],
            },

            /**
             * 検証
             */
            Validate: {
                /** 固定摘要重複 */
                DUPLICATIONFIXEDMEMORANDUM: ["No.{0};の固定摘要と重複しています。"],
                /** 摘要分類重複 */
                DUPLICATIONFIXINGSUMMARYCLASS: ["No.{0};の摘要分類と重複しています。"],
            },
        },

        /**
         * 残高登録
         */
        Balanceregister: {

            /**
             * 入力区分
             */
            Inputkbn: {
                /** 期首残高 */
                BEGINNINGACCOUNTBALANCE: ["期首残高"],
                /** 経過月発生高 */
                ELAPSEDMONTHACCRUALS: ["経過月発生高"],
                /** 期中残高 */
                DURINGACCOUNTBALANCE: ["期中残高"],
            },
        },

        /**
         * 科目残高登録
         */
        Openingaccountbalance: {
            /** 警告 */
            WARNING: ["バランスエラーですが、更新してもよろしいですか？"],

            /**
             * 
             */
            Haifumode: {
                /** 解除 */
                RELEASEMESSAGE: ["配賦金額のみの入力を解除しますか？"],
                /** 入力 */
                SETTINGMESSAGE: ["配賦金額のみで入力しますか？"],
            },
        },

        /**
         * 科目残高
         */
        Accountbalance: {
            /** 配賦金額 */
            HAIFUAMOUNT: ["配賦金額"],

            /**
             * 確認
             */
            Confirm: {
                /** 合計セット */
                SETSUMMARY: ["{0};別の合計金額を全社にセットします。よろしいですか？"],
                /** 自動仕訳作成 */
                AUTOJOURNALIZING: ["自動仕訳を作成します。よろしいですか？\n\n【警告】\nこの処理を行うと、指定されている月度の入力済仕訳データはすべて削除され登録されている期中残高にしたがって自動仕訳が起票されます。\n指定月度の入力済仕訳データをご確認の上、処理を行うようにして下さい。\nなお、自動仕訳は指定月度の当月残高と前月残高の差額として起票され、その仕訳の消費税コードは40番になります。\n部門以外の補助コードは「諸口」として起票されますのでご留意下さい。"],
            },

            /**
             * 完了
             */
            Complete: {
                /** 自動仕訳作成 */
                AUTOJOURNALIZING: ["自動仕訳作成が完了しました。"],
            },

            /**
             * 警告
             */
            Warning: {
                /** バランスエラー */
                BALANCEERROR: ["バランスエラーがあります。"],
            },

            /**
             * 表示形式
             */
            Displayformat: {
                /** BS科目表示 */
                BS: ["ＢＳ科目表示"],
                /** PL科目表示 */
                PL: ["ＰＬ科目表示"],
            },

            /**
             * 合計セット種類
             */
            Sumsetkind: {
                /** 部門合計 */
                BMN: ["部門合計"],
                /** セグメント合計 */
                SEGMENT: ["セグメント合計"],
                /** 補助合計 */
                AUXILIARY: ["補助合計"],
                /** 科目別補助合計 */
                EACHKMKAUXILIARY: ["科目別補助合計"],
            },
        },

        /**
         * 摘要残高登録
         */
        Openingaccountmemorandum: {

            /**
             * コンテキストメニュー
             */
            Contextmenu: {
                /** 追加 */
                ADD: ["追加"],
                /** 削除 */
                DELETE: ["削除"],
            },
        },

        /**
         * 担当者登録
         */
        Staff: {

            /**
             * キャプション
             */
            Caption: {
                /** 有効 */
                LOGINENABLE: ["有効"],
                /** 無効 */
                LOGINDISABLE: ["無効"],
                /** 管理者権限 */
                ADMINPERMIT: ["管理者"],
                /** 一般権限 */
                GENERALPERMIT: ["一般"],
                /** メール確認用 */
                MAILPLACEHOLDER: ["メール確認用"],
                /** 名前 */
                FIRSTNAME: ["名"],
                /** 姓 */
                LASTNAME: ["姓"],
                /** 名前カナ */
                FIRSTKANANAME: ["ﾒｲ"],
                /** 姓カナ */
                LASTKANANAME: ["ｾｲ"],
                /** 再入力 */
                REENTER: ["確認のため再入力してください。"],
            },

            /**
             * 確認
             */
            Confirm: {
                /** 存在しない */
                NOTEXISTSSTAFF: ["担当者が見つかりません。"],
                /** 削除確認 */
                REMOVESTAFF: ["現在の担当者を削除してもよろしいですか？"],
            },
        },

        /**
         * 金融機関登録
         */
        Financialinstitution: {

            /**
             * 初期表示
             */
            Initial: {
                /** データなし */
                NONDATA: ["導入処理から連携アカウントの登録を行ってください。"],
            },

            /**
             * 削除
             */
            Remove: {
                /** 確認 */
                CONFIRM: ["の情報を削除します。よろしいですか？"],
                /** 削除成功 */
                SUCCESSFULLYDELETED: ["削除しました。"],
            },

            /**
             * エラー
             */
            Error: {
                /** 接続タイムアウト */
                CONNECTIONTIMEOUT: ["接続がタイムアウトしました。処理を再起動してください。"],
                /** 削除済み */
                DELETED: ["削除済みの金融機関情報です。"],
                /** 予期しないエラー */
                UNEXPECTEDERROR: ["予期しないエラーが発生しました。"],
                /** アカウント不正 */
                ACCOUNTUNAUTHORIZED: ["連携アカウント情報が正しくありません。"],
                /** ログイン失敗 */
                LOGINFAILURE: ["ログインに失敗しました。パスワードを確認してください。"],
                /** パスワード未入力 */
                NOPASSWORDINPUT: ["パスワードを入力してください。"],
                /** 整合性エラー */
                CONSISTENCYERROR: ["科目、マスターの情報が変更されました。\n科目情報の再登録を行ってください。"],
            },

            /**
             * ダイアログ
             */
            Dialog: {
                /** タイトル */
                TITLE: ["パスワード入力"],
            },

            /**
             * 表示更新
             */
            Displayupdate: {
                /** ツールチップ */
                TOOLTIP: ["金融機関リストを更新します"],
            },
        },

        /**
         * 取引明細-導入処理
         */
        Transactiondetail: {

            /**
             * パスワード
             */
            Password: {
                /** 再発行 */
                REISSUE: ["パスワードを再発行しますか？"],
                /** 有効期限 */
                EXPIRATIONDATE: ["登録しているメールアドレスに仮パスワードを送信しました。\n仮パスワードは１時間有効です。"],
                /** 不一致 */
                DIFFERENCE: ["パスワード・パスワード（確認用）が異なります。\nそれぞれ同じ文字列を入力してください。"],
                /** 設定 */
                SETTING: ["パスワードは8文字以上20文字以内かつ、\nアルファベットの大文字・小文字、数字を含めてください。"],
                /** 規約違反 */
                VIOLATIONREGULATIONS: ["パスワードに使用できない文字が含まれています。"],
                /** 不正 */
                UNAUTHORIZED: ["パスワードが違います。新規にアカウントを作成する場合は\n別の連携IDまたはメールアドレスを入力してください。"],
                /** 表示 */
                DISPLAY: ["8～20文字の半角記号英数字（大文字・小文字両方含む）を入力してください。使用できる記号は次の通りです。\n$ & @ ! - _ % ^ * ' : ( ) + ? ; \" = \n連携アカウント作成後に変更することができます。"],
            },

            /**
             * 連携アカウント
             */
            Account: {
                /** 存在しない */
                NOTEXIST: ["連携アカウントが存在しません。"],
                /** ID不正 */
                IDINJUSTICE: ["ユーザIDとメールアドレスの情報が一致しません。ご確認のうえ、もう一度お取引ください。"],
                /** 削除前確認 */
                CONFIRMBEFOREDELETE: ["連携アカウントを削除します。\nよろしいですか？"],
                /** 削除 */
                DELETE: ["連携アカウントを削除しました。"],
                /** 削除後確認 */
                CONFIRMAFTERDELETION: ["連携アカウントを削除後、同じ連携IDを使用している全てのデータで取引明細連携が\n使用できなくなりますがよろしいですか？\nはい・・・同じ連携IDを使用している全てのデータで取引明細連携が使用できなくなります。\nいいえ・・・選択中のデータで取引明細連携が使用できなくなります。"],
                /** 表示 */
                DISPLAY: ["1～20文字の半角英数字を入力してください。\n連携アカウントの作成後に変更することはできません。"],
            },

            /**
             * メール
             */
            Mail: {
                /** 送信 */
                SEND: ["登録したメールアドレスに確認メールを送信しました。\n24時間以内に、メールに添付されたURLから登録を行ってください。\n\n※1時間経過してもメールが届かない場合はサポートセンターにお問い合わせください。\n"],
                /** 設定 */
                SETTING: ["入力した連携ID・メールアドレスで作成された連携アカウントが存在します。\nこの端末で使用するアカウントに設定してよろしいでしょうか？"],
                /** 重複 */
                OVERLAP: ["既に使用されています。\n別のメールアドレスを入力してください。"],
                /** 表示 */
                DISPLAY: ["受信可能なメールアドレスを入力してください。連携アカウントの作成時にメールが送信されます。                                       \n連携アカウント作成後に変更することができます。"],
            },
        },

        /**
         * 社員登録
         */
        Employeeregister: {

            /**
             * エラー
             */
            Error: {
                /** 重複 */
                DUPLICATION: ["ただ今混み合っております。\nもう一度、更新を実行してください。"],
            },

            /**
             * 確認
             */
            Confirm: {
                /** データ上限超え */
                DATACOUNTOVER: ["推奨{0};を超えています。"],
                /** 配偶者情報クリア */
                CLEARHAIGUINFO: ["入力されている配偶者情報をクリアします。よろしいですか。"],
                /** 扶養者情報クリア */
                CLEARSUPPORTERINFORMATION: ["選択した扶養者情報をクリアします。よろしいですか。"],
                /** 行削除 */
                REMOVELINE: ["{0};の行を削除します。よろしいですか。"],
            },

            /**
             * 検証
             */
            Validate: {
                /** 個人番号不正 */
                MYNUMBERUNAUTHORIZED: ["{0};個人番号が正しくありません。"],
                /** 個人番号存在 */
                EXISTSMYNUMBER: ["{0};個人番号は登録済みです。"],
            },
        },

        /**
         * 源泉徴収票
         */
        Withholdingslip: {

            /**
             * 摘要入力データ
             */
            Summaryinputdata: {
                /** 削除 */
                DELETE: ["手入力した内容はクリアされます。"],
            },
        },

        /**
         * 明細書設定
         */
        Registerpaymentslip: {

            /**
             * 検証
             */
            Validate: {
                /** 妥当性 */
                VALIDITY: ["{0};を入力してください。"],
                /** 存在しないコード */
                NOTEXISTCODE: ["該当コードがありません。"],
            },

            /**
             * 確認
             */
            Confirm: {
                /** 給与・賞与項目の単価存在 */
                EXISTSUNITPRICE: ["社員登録の給与・賞与項目に金額が登録済みです。変更してもよろしいですか？\n\n明細書の合計項目の金額が正しく集計されなくなるため、\n社員登録の給与・賞与項目の金額を0円にしてから変更してください。"],
                /** システム項目の確認 */
                SYSTEMITEM: ["システムで使用している項目です。チェックを外しますか？\n※チェックを外した場合、会計事務所に正しく連動されない可能性があります。"],
            },
        },

        /**
         * 会社基本情報登録
         */
        Registerpaymentfirminformation: {

            /**
             * 情報
             */
            Information: {
                /** 社員登録の更新確認 */
                UPDATECONFIRMMESSAGE: ["計算済みの社員を未計算に戻していますが、未計算に戻すかどうかを確認するメッセージを表示する場合は「メッセージを表示して確認する」を選択してください。"],
            },

            /**
             * 確認
             */
            Confirm: {
                /** 保険料率の変更 */
                INSURANCERATECHANGE: ["保険料率が変更されました。\n社員情報の保険料を再計算します。よろしいですか？\n（給与、賞与明細書が作成済みの場合は、再作成を行ってください。）"],
                /** 法人番号クリア */
                CORPORATENUMBERCLEAR: ["入力されている法人番号がクリアされます。よろしいですか？"],
                /** コード桁数変更 */
                CODEDIGITCHANGE: ["社員コード桁数の値が変更されました。\n社員情報に登録されている社員コードの桁数を指定の桁数に更新します。\nよろしいですか？"],
            },

            /**
             * 検証
             */
            Validate: {
                /** 個人番号入力済み */
                MYNUMBERINPUTERROR: ["個人番号が入力されています。\n個人番号を削除してから区分を変更してください。"],
                /** 会社情報取得失敗 */
                GETTINGQYOMA: ["会社基本情報を取得できませんでした。"],
                /** 法人番号不正 */
                CORPORATENUMBERUNAUTHORIZED: ["法人番号が正しくありません。"],
                /** 存在しない郵便番号 */
                NOTFOUNDPOSTALCODE: ["入力された郵便番号は現在使用されていません。\n再度ご確認のうえ入力してください。"],
                /** 計算基準額情報取得失敗 */
                GETTINGCALCBASE: ["計算基準額情報を取得できませんでした。"],
            },
        },

        /**
         * 定型仕訳
         */
        Fixedvoucher: {

            /**
             * 要求
             */
            Request: {
                /** 定型NO入力 */
                ENTRYNO: ["定型NOを入力してください。"],
            },

            /**
             * 確認
             */
            Confirm: {
                /** 一括削除 */
                DELETEALL: ["定型仕訳一括削除を開始します。よろしいですか？"],
                /** 選択仕訳の削除 */
                DELETESELECTEDVOUCHER: ["{0};番の定型仕訳を削除します。\nよろしいですか？"],
            },

            /**
             * メッセージ
             */
            Message: {
                /** 一括削除完了 */
                ALLDELETEDONE: ["一括削除が完了しました。"],
            },

            /**
             * エラー
             */
            Error: {
                /** 無効な選択範囲 */
                INVALIDRANGE: ["範囲が間違っています。"],
                /** 仕訳入力区分 */
                SWKINPKBN: ["他で登録された定型仕訳は修正・削除できません。"],
            },
        },

        /**
         * 個人番号登録
         */
        Mynumberregister: {

            /**
             * 確認
             */
            Confirm: {
                /** 削除 */
                REMOVE: ["{0};の個人番号を削除します。よろしいですか？"],
                /** 個人番号の使用 */
                MYNUMBERUSED: ["この番号は『{0};』の個人番号として登録済みです。\n同じ番号を使用してもよろしいですか？"],
            },

            /**
             * 検証
             */
            Validate: {
                /** 個人番号の使用済み */
                MYNUMBERUSEDERROR: ["この番号は『{0};』の個人番号として登録済みのため、使用できません。\nもし間違っていた場合は、一度「番号削除」してから登録してください。"],
                /** 個人番号の重複 */
                MYNUMBERDUPLICATIONCODE: ["同一社員内で同じ個人番号が入力されています。"],
                /** 必須入力 */
                REQUIRED: ["{0};が入力されていません。"],
                /** 個人番号の使用済み（他なし） */
                MYNUMBERNOUSEDERROR: ["この番号は登録済みのため、使用できません。\nもし間違っていた場合は、一度「番号削除」してから登録してください。"],
            },
        },
    };

    /**
     * 決算書
     */
    public static readonly Statementofaccounts = {

        /**
         * 共通
         */
        Common: {

            /**
             * エラー
             */
            Error: {
                /** データ取得 */
                SELECTDATA: ["{0};の取得に失敗しました。"],
                /** データ更新 */
                UPDATEDATA: ["{0};の更新に失敗しました。"],
                /** データ削除 */
                DELETEDATA: ["{0};の削除に失敗しました。"],
            },
        },

        /**
         * 基本情報画面
         */
        Basicinfo: {

            /**
             * 法人番号
             */
            Corporatenumber: {
                /** 桁数確認 */
                LENGTHCONFIRMATION: ["法人番号は{0};桁の半角数字で入力してください。"],
            },

            /**
             * 変動事由
             */
            Hendojiyu: {
                /** 確認 */
                CONFIRMATION: ["株主資本等変動計算書の変動額合計と当期首・当期末残高が一致しません。\n一致しない金額は「その他変動事由」として出力されます。\n\n出力してよろしいですか？"],
            },

            /**
             * 科目出力順序
             */
            Kmkorder: {
                /** 確認 */
                CONFIRMATION: ["決算書出力順序を登録してください。"],
                /** 未設定 */
                NOTSET: ["科目出力順序情報が存在しません。"],
            },
        },

        /**
         * 注記表画面
         */
        Notepattern: {

            /**
             * 内容1
             */
            Contents1: {
                /** 文字数オーバー */
                OVER: ["内容１のデータが容量を超えています。"],
            },

            /**
             * 内容2
             */
            Contents2: {
                /** 入力済 */
                ALREADYINPUT: ["内容２にデータが入力されています。"],
            },

            /**
             * 内容を戻してよいか
             */
            Roleback: {
                /** 確認 */
                CONFIRMATION: ["内容を標準に戻します。よろしいですか？"],
            },
        },
    };

    /**
     * 工事
     */
    public static readonly Construction = {

        /**
         * チェックボックス
         */
        Checkbox: {

            /**
             * キャプション
             */
            Caption: {
                /** ０円工事出力 */
                ZEROCONSTRUCTIONOUTPUT: ["０円の{0};を出力する"],
            },
        },

        /**
         * 工事管理表
         */
        Constructionadministration: {

            /**
             * 出力形式
             */
            Layout: {
                /** 詳細 */
                DETAIL: ["詳細"],
                /** 簡易 */
                SIMPLE: ["簡易"],
                /** 工事別損益一覧表 */
                PROFITANDLOSSBYCONSTRUCTION: ["工事別損益一覧表"],
                /** 工事別予算一覧表 */
                BUDGETLISTBYCONSTRUCTION: ["工事別予算一覧表"],
                /** 工事別収支一覧表 */
                INCOMEANDEXPENDITUREBYCONSTRUCTION: ["工事別収支一覧表"],
            },

            /**
             * 名寄せ区分
             */
            Rootkbn: {
                /** 名寄せしない */
                NONE: ["名寄せしない"],
                /** 名寄せする */
                SETTING: ["名寄せする"],
            },

            /**
             * 比較対象
             */
            Cmpkbn: {
                /** 契約金額 */
                CONTRACTAMOUNT: ["契約金額"],
                /** 売上金額 */
                SALESAMOUNT: ["売上金額"],
            },
        },

        /**
         * 工事台帳
         */
        Constructionregister: {

            /**
             * 出力形式
             */
            Layout: {
                /** 工事台帳 */
                CONSTRUCTIONREGISTER: ["工事台帳"],
            },

            /**
             * 回収区分
             */
            Collectkbn: {
                /** 計 */
                TOTAL: ["計"],
                /** 繰越 */
                CARRYOVER: ["繰越"],
            },

            /**
             * 未成／完成区分
             */
            Incompletecompletedkbn: {
                /** 未成 */
                INCOMPLETE: ["【未成】"],
                /** 未成・完成 */
                INCOMPLETECOMPLETED: ["【未成・完成】"],
                /** 完成 */
                COMPLETED: ["【完成】"],
            },

            /**
             * 未成・完成出力区分
             */
            Incompletecompletedoutputkbn: {
                /** 未成分 */
                INCOMPLETE: ["未成分"],
                /** 完成分 */
                COMPLETED: ["完成分"],
                /** 出力月完成分 */
                OUTPUTMONTHCOMPLETED: ["月完成分"],
            },

            /**
             * 出力種類
             */
            Ledgerno: {
                /** 実在工事 */
                REALITYCONSTRUCTION: ["実在工事"],
                /** 統合工事 */
                INTEGRATIONCONSTRUCTION: ["統合工事"],
            },
        },

        /**
         * 工事別集計表
         */
        Constructionsummary: {

            /**
             * 出力形式
             */
            Layout: {
                /** 実績のみ */
                RESULTSONLY: ["実績のみ"],
            },
        },

        /**
         * 科目別工事集計表
         */
        Accountconstructionsummary: {

            /**
             * 出力形式
             */
            Layout: {
                /** 集計表 */
                SPREADSHEET: ["集計表"],
            },
        },
    };

    /**
     * データ関係
     */
    public static readonly Process = {

        /**
         * マスター再計算
         */
        Recalculation: {

            /**
             * 確認
             */
            Confirm: {
                /** 資金繰自動セット */
                AUTOMATICSET: ["入力済みの資金繰コードを既定値で置換します。\nよろしいですか？"],
                /** 内税分消費税額を再セット */
                TAXAMOUNTSET: ["入力済仕訳の消費税額を再計算した金額で置換します。\nよろしいですか？"],
                /** 消費税コード再セット */
                TAXCODESET: ["仕訳の消費税コード・消費税率が、会社基本情報で登録した科目別消費税（科目別補助別消費税）の消費税コード・消費税率に再セットされます。\n実行すると元に戻りませんが、よろしいですか？\n※消費税率10%や軽減税率8%をセットする場合は、マスター再計算の処理期間を税率適用前後で期間を区分してください。"],
                /** 業種コード再セット */
                INDUSTRYCODESET: ["仕訳の業種コードを、会社基本情報の業種コードで再セットします。\n実行すると元に戻りませんが、よろしいですか？"],
                /** セグメント再セット */
                SEGMENTCODESET: ["入力済のセグメントコードを自動セット情報で置換します。\nよろしいですか？"],
                /** 再計算開始前 */
                BEFORERECALCULATION: ["処理を開始してもよろしいですか？"],
            },

            /**
             * 情報
             */
            Information: {
                /** 再計算開始後 */
                RECALCULATIONSTARTED: ["マスター再計算を開始しました。処理完了またはダイアログを閉じた時にウインドウが閉じられますので、再度ログインをお願いします。\n次回マスター再計算処理の起動時に、結果を確認する事が出来ます。\n※マスター再計算処理中にログインすると、エラー画面に移動します。"],
                /** 印刷データ無し */
                NOPRINTDATA: ["該当データがありません。"],
            },
        },

        /**
         * 給与仕訳連動
         */
        Payrolljournalcreating: {

            /**
             * 確認
             */
            Confirm: {
                /** 処理月以外 */
                DENDATEOTHERMONTH: ["伝票日付が給与処理月の範囲内({0};～{1};)ではありません。\n処理を開始してもよろしいですか？"],
                /** 処理年以外 */
                DENDATEOTHERYEAR: ["伝票日付が給与処理年の範囲内ではありません。処理を開始してもよろしいですか？"],
            },
        },

        /**
         * 決算更新
         */
        Finalclosing: {

            /**
             * 処理システム
             */
            Caption: {
                /** 決算更新 */
                CLOSINGUPDATE: ["決算更新"],
                /** 残高再移送 */
                BALANCERETRANSFER: ["残高再移送"],
            },

            /**
             * 情報
             */
            Information: {
                /** 月次確定情報 */
                MONTHLYCLOSINGINFO: ["月次確定が行われていません。"],
                /** 次年度データ存在 */
                NEXTYEAREXISTS: ["次年度データが存在するため、処理できません。"],
                /** 次年度データ非存在 */
                NEXTYEARNOTEXISTS: ["次年度データが存在しないため、処理できません。"],
                /** 決算更新開始通知 */
                NOTIFYFINALCLOSING: ["決算更新を開始しました。処理完了またはダイアログを閉じた時にウインドウが閉じられますので、再度ログインをお願いします。\n次回ログイン時、決算更新で作成した年度が表示されます。\n※決算更新処理中にログインすると、エラー画面に移動します。"],
                /** 期首残高移送始通知 */
                NOTIFYOPENINGBALANCETRANSFER: ["期首残高移送を開始しました。処理完了またはダイアログを閉じた時にウインドウが閉じられますので、再度ログインをお願いします。\n次回ログイン時、期首残高をご確認ください。\n※期首残高移送中にログインすると、エラー画面に移動します。"],
            },

            /**
             * 確認
             */
            Confirm: {
                /** 決算更新開始確認 */
                CHECKSTART: ["{0};を開始します。よろしいですか？"],
            },
        },

        /**
         * バックアップ
         */
        Backup: {

            /**
             * 情報
             */
            Information: {
                /** バックアップ開始後 */
                BACKUPSTARTED: ["会社データのバックアップを開始しました。\nデータをロックするため、ウインドウが閉じられますので、再度ログインをお願いします。\n※バックアップが完了するまで、該当データで各種処理の実行ができません。\n※バックアップの結果は、次回バックアップ処理起動時に「最終バックアップ日時」、「最終バックアップ状況」で確認してください。\n※バックアップデータは、『かんたんクラウド』がバージョンアップされると自動的に削除されます。"],
            },
        },

        /**
         * リストア
         */
        Restore: {

            /**
             * 情報
             */
            Information: {
                /** リストア開始後 */
                RESTORESTARTED: ["会社データのリストアを開始しました。\nデータをロックするため、ウインドウが閉じられますので、再度ログインをお願いします。\n※リストアが完了するまで、該当データで各種処理の実行ができません。\n※リストアの結果は、次回リストア処理起動時に「最終リストア日時」、「最終リストア状況」で確認してください。"],
            },
        },

        /**
         * 月次確定
         */
        Monthlyclosing: {

            /**
             * 確認
             */
            Confirm: {
                /** 処理継続 */
                CONTINUE: ["月次確定してよろしいですか？"],
            },

            /**
             * 警告
             */
            Warning: {
                /** 棚卸科目 */
                STOCKTAKINGKMK: ["棚卸科目の月別の金額が一致していません。このまま確定してよろしいですか？\n期末棚卸と次月期首棚卸の金額を確認してください。"],
                /** 複合 */
                COMPLEXKMK: ["複合科目、又は資金複合科目の貸借発生金額が一致していません。このまま確定してよろしいですか？　\n仕訳帳で複合科目、又は資金複合科目の貸借発生金額を確認してください。"],
                /** 期首残高 */
                BEGINNINGTERM: ["科目期首残高のバランスが一致していません。このまま確定してよろしいですか？"],
                /** その他 */
                OTHER: ["科目バランスが一致していません。このまま確定してよろしいですか？"],
            },

            /**
             * 情報
             */
            Information: {
                /** 理由 */
                REASON: ["確定解除事由を入力してください。"],
                /** 更新 */
                UPDATE: ["月次確定情報を更新しました。"],
            },

            /**
             * 同期警告
             */
            Syncwarning: {
                /** 顧問先警告 */
                CLIENTWARNING: ["会計事務所でデータ同期済みのため確定解除できません。確定解除は、会計事務所で処理することができますので、会計事務所ご担当者にご相談ください。"],
                /** 事務所警告 */
                OFFICEWARNING: ["かんたんクラウドのデータ同期済ですので、解除すると、次回のデータ同期では、解除した月の全仕訳が会計大将へ同期されます。解除してよろしいですか？"],
            },

            /**
             * 確定警告
             */
            Confirmwarning: {
                /** 顧問先警告 */
                CLIENTWARNING: ["会計事務所で月次確定済みのため確定解除できません。確定解除は、会計事務所で処理することができますので、会計事務所ご担当者にご相談ください。"],
                /** 事務所警告 */
                OFFICEWARNING: ["会計大将で月次確定済みのため確定解除できません。確定解除は、会計大将の月次確定処理で解除後に、かんたんクラウドのデータ同期処理を実行してください。"],
            },
        },

        /**
         * 会社データ作成
         */
        Datacreator: {

            /**
             * 科目体系区分
             */
            Corpkbn: {
                /** 法人（一般） */
                CORPGENERAL: ["法人（一般）"],
                /** 法人（工事B/S） */
                CORPCONSTRUCTIONBS: ["法人（工事B/S型）"],
                /** 法人（工事P/L） */
                CORPCONSTRUCTIONPL: ["法人（工事P/L型）"],
                /** 法人（医療） */
                CORPMEDICAL: ["法人（医療）"],
                /** NPO法人 */
                CORPNPO: ["NPO法人"],
                /** 個人（一般） */
                PERSONALGENERAL: ["個人（一般）"],
                /** 個人（医療） */
                PERSONALMEDICAL: ["個人（医療）"],
                /** 個人（不動産） */
                PERSONALESTATE: ["個人（不動産）"],
            },

            /**
             * 確認
             */
            Confirm: {
                /** 作成確認 */
                CREATE: ["データを作成します。\nよろしいですか?"],
                /** 作成確認（会社名変更） */
                CREATERENAME: ["{0};　は、かんたんクラウド契約会社名と異なります。\nデータを作成してもよろしいですか？\n\n『かんたんクラウド契約会社名　{1};』"],
                /** 作成後変更不可 */
                CANNOTCHANGE: ["・会計期間、月次開始日、科目体系は、会社データ作成後に変更することができません。"],
                /** 過年度から作成 */
                PASTYEAR: ["・過年度データが無い場合、前期比較帳票の出力ができません。前期比較帳票を出力したい場合は、過年度のデータから作成してください。"],
            },

            /**
             * 情報
             */
            Information: {
                /** 作成完了 */
                CREATECOMPLETE: ["データを作成しました。"],
            },
        },

        /**
         * データ移行
         */
        Migration: {

            /**
             * 期首残高更新
             */
            Openingbalanceupdate: {
                /** 期首残なし科目 */
                NOOPENINGBALANCEKMK: ["勘定科目の期首残区分がなしの状態となっているため、残高インポートできません。\nインポートしたい場合は勘定科目登録で該当の勘定科目の期首残区分をありに更新してください。"],
            },

            /**
             * 仕訳設定
             */
            Ledgersetting: {
                /** 会計期間外 */
                INVALIDPERIOD: ["会計期間外の伝票日付の仕訳は取り込まれません。"],
                /** 科目対応付け不足 */
                INVALIDKMKSETTING: ["仕訳で使用されている科目が対応付けされていません。もう一度対応付けの設定を行ってください。\n不明科目が設定されている場合は、その科目に置き換えてインポートされます。"],
                /** 決算日不正 */
                INVALIDCLOSINGDATE: ["決算期間が一致していないため取り込まれません。\n決算期間が正しいか確認してください。"],
                /** 月次確定済み */
                MONTHLYCONFIRMED: ["月次確定済みの月のため、仕訳のインポートは行えません。仕訳のインポートを行いたい場合は、月次確定処理で該当月の確定を解除してください。"],
            },

            /**
             * ファイルアップロード
             */
            Fileupload: {
                /** ファイル検証エラー */
                VALIDATEERROR: ["指定されたファイルは{0};インポートできません。もう一度、手順に従ってエクスポートしてください。"],
                /** ファイル組合せエラー */
                FILECOMBINATIONERROR: ["{0};をインポートするには、{1};データのファイルが必要です。手順に従って{1};データをエクスポートしてファイルを追加してください。"],
            },

            /**
             * ファイルインポート
             */
            Fileimport: {
                /** ファイル種類重複 */
                DUPLICATEDFILETYPE: ["取込対象が重複しているため、インポートできません。取込対象が重複しないように選択してください。"],
                /** ファイルインポート失敗 */
                IMPORTFAILED: ["取り込まれたファイルはエラーのためインポートされませんでした。ファイル内容を見直してください。"],
            },
        },

        /**
         * 状況表示
         */
        Progresssituation: {

            /**
             * 表示種類
             */
            Viewtype: {
                /** 全体表示 */
                ALL: ["全体表示"],
                /** 印刷のみ */
                PRINTONLY: ["印刷のみ"],
                /** 排他のみ */
                EXCLUSIONONLY: ["排他のみ"],
            },

            /**
             * 種類
             */
            Type: {
                /** 印刷 */
                PRINT: ["印刷"],
                /** 排他 */
                EXCLUSION: ["排他"],
            },

            /**
             * ステータス
             */
            Status: {
                /** 作成済 */
                COMPLETED: ["作成済"],
                /** 確認済 */
                CONFIRMATION: ["確認済"],
                /** エラー */
                ERROREXIT: ["エラー"],
                /** キャンセル */
                CANCELED: ["キャンセル"],
                /** 実行中 */
                RUNTIME: ["実行中"],
                /** 待機中 */
                WAITING: ["待機中"],
            },

            /**
             * 操作
             */
            Operation: {
                /** ファイル表示 */
                FILEVIEW: ["ファイル表示"],
                /** ファイル再表示 */
                FILEREVIEW: ["ファイル再表示"],
                /** エラー表示 */
                ERROR: ["エラー表示"],
            },

            /**
             * 処理システム
             */
            Processingsystem: {
                /** 月次確定処理 */
                MONTHLYCONFIRMED: ["月次確定処理"],
                /** 決算更新 */
                CLOSINGUPDATE: ["決算更新"],
                /** 残高再移送 */
                BALANCERETRANSFER: ["残高再移送"],
                /** マスター更新 */
                MASTERUPDATE: ["マスター更新"],
                /** マスター再計算 */
                MASTERRETALCULATION: ["マスター再計算"],
                /** バックアップ */
                BACKUP: ["バックアップ"],
                /** リストア */
                RESTORE: ["リストア"],
                /** データインストール */
                DATAINSTAL: ["データインストール"],
                /** データ同期処理（NX-Pro→かんたんクラウド） */
                FORCLOUDDATA: ["データ同期処理（NX-Pro→かんたんクラウド）"],
                /** データ同期処理（かんたんクラウド→NX-Pro） */
                FORNXDATA: ["データ同期処理（かんたんクラウド→NX-Pro）"],
                /** 会社データ（会計）の削除 */
                COMPANYDATADELETE: ["会社データ（会計）削除"],
                /** 会社データ（給与）の削除 */
                PAYROLLCOMPANYDATADELETE: ["会社データ（給与）削除"],
                /** その他 */
                OTHER: ["その他"],
            },

            /**
             * ファイルタイプ
             */
            Filetype: {
                /** PDF */
                PDF: ["PDF"],
                /** Excel */
                EXCEL: ["Excel"],
            },
        },

        /**
         * NX-Pro給与データ取込
         */
        Processnxprodataimport: {

            /**
             * 情報
             */
            Information: {
                /** 社員20人超 */
                RECOMMENDEMPLOYEE: ["CSVデータの社員数が推奨の20人を超えています。"],
                /** データ取込完了 */
                IMPORTDONE: ["インポートが完了しました。"],
            },

            /**
             * 確認
             */
            Confirm: {
                /** CSV読み込み完了 */
                READCSVDONE: ["給与データの読み込みが完了しました。"],
                /** マイナンバーCSV読み込み完了 */
                READMYNUMBERCSVDONE: ["マイナンバー情報の読み込みが完了しました。"],
                /** インポート実行確認 */
                EXECUTEIMPORT: ["インポートを開始します。よろしいですか？"],
                /** 取込確認 */
                IMPORTCSV: ["CSVデータにエラーがあります。エラーのある社員情報は取り込まれません。\nエラーを無視して、取込可能な社員情報のみインポートしますか？"],
            },

            /**
             * エラー
             */
            Error: {
                /** 行が不正 */
                ERRORROW: ["{0};行目のCSVデータが不正のためインポートできません。"],
                /** 社員50人超 */
                ERRORMAXEMPLOYEE: ["CSVデータの社員数が{0};人を超えているためインポートできません。"],
                /** 社員数取込制限 */
                ERROREMPLOYEECOUNTOVER: ["登録後の在籍社員数が制限（{0};名）を超えるためインポートできません。"],
                /** 給与実績・賞与実績が登録済み */
                ERRORCSVEXISTDATA: ["給与または賞与の実績データが存在するため、インポートできません。"],
                /** マイナンバー情報が登録済み */
                ERRORCSVEXISTMYNUMBER: ["マイナンバー情報が登録済みのため、インポートできません。"],
                /** ファイル読込失敗 */
                NOTTEXTFILE: ["ファイルがテキスト形式でないため、読み込みができませんでした。"],
            },
        },

        /**
         * NX-Pro年調データ取込
         */
        Nxproadjustmentimport: {

            /**
             * 確認
             */
            Confirm: {
                /** 未確定取込確認 */
                EXECUTEIMPORTNONCLOSING: ["給与12月まで確定されていません。\nこのまま年調連携を行うと正しく年末調整が行われません。\nインポートを開始しますか？"],
            },
        },

        /**
         * NX-Pro年調データ出力
         */
        Nxproadjustmentexport: {

            /**
             * 確認
             */
            Confirm: {
                /** マイナンバー情報のCSV出力 */
                MYNUMBERCSVOUTPUT: ["マイナンバー情報のCSVファイルを出力します。"],
                /** 出力確認 */
                EXECUTEOUTPUT: ["給与12月まで確定されていません。\nこのまま年調連携を行うと正しく年末調整が行われません。\nエクスポートを開始しますか？"],
                /** 氏名桁数チェック */
                NAMELENGTHERROR: ["配偶者または扶養者の氏名に全角8文字を超える文字が入力されているため、\nCSV出力を実行できません。ご確認ください。"],
                /** 親キー未設定エラー */
                PARENTKEYERROR: ["配偶者または扶養者に個人番号が登録されている社員に個人番号が登録されていないため、\nCSV出力を実行できません。ご確認ください。"],
            },

            /**
             * 警告
             */
            Warning: {
                /** 出力警告（ベーシック） */
                BASICOUTPUTWARNING: ["NX-Pro年末調整の年調項目は未入力の状態で、NX-Pro年末調整に連携します。"],
                /** 出力警告（プラス） */
                PLUSOUTPUTWARNING: ["『年調データ入力』の項目が未入力の状態で［エクスポート］し、NX-Pro年末調整で連携を行うと\nNX-Pro年末調整で入力した年調項目は未入力の状態に戻ります。"],
            },
        },

        /**
         * 給与賞与確定
         */
        Processpaymentconfirm: {

            /**
             * 検証
             */
            Validate: {
                /** 単独年調未作成 */
                ADJUSTMENTENTRYERROR: ["年末調整が行われていません。年調データ入力で作成を行ってください。"],
            },

            /**
             * 確認
             */
            Confirm: {
                /** 仕訳連動確定確認 */
                EXISTSSWK: ["連動された仕訳情報が補完されていますが、 確定更新を行いますか？\n補完の内容は明細書の仕訳作成ダイアログを確認してください。"],
            },

            /**
             * エラー
             */
            Error: {
                /** 仕訳連動確定不可 */
                ERRORSWK: ["確定更新できません。\n明細書の仕訳作成ダイアログを確認してください。"],
                /** 仕訳未連動 */
                NOEXISTSSWK: ["仕訳連動が行われていません。明細書入力で仕訳作成を行ってください。"],
            },
        },

        /**
         * 他社給与データ取込
         */
        Otherpayrolldataimport: {

            /**
             * 確認
             */
            Confirm: {
                /** 明細項目未設定あり */
                ITEMNOTSET: ["未割り当ての明細項目が存在します。処理を開始してよろしいですか？"],
            },
        },

        /**
         * 他社給与データ変換
         */
        Otherpayrolldataconversion: {

            /**
             * 確認
             */
            Confirm: {
                /** 汎用項目未設定あり */
                GENERALITEMNOTSET: ["未割り当ての項目が存在します。処理を開始してよろしいですか？"],
            },

            /**
             * エラー
             */
            Error: {
                /** CSVファイル読込失敗 */
                NOTCSVFILE: ["ファイルがCSV形式でないため、読み込みができませんでした。"],
            },
        },

        /**
         * 年次更新
         */
        Paymentyearupdate: {

            /**
             * エラー
             */
            Error: {
                /** 次年度データあり */
                NEXTYEAREXISTS: ["翌年度データが作成済みのため、この処理は行えません。"],
            },

            /**
             * 確認
             */
            Confirm: {
                /** 年次更新完了 */
                UPDATE: ["年次更新が完了しました。ウインドウが閉じられますので、再度ログインをお願いします。\n次回ログイン時、年次更新で作成した年度が表示されます。\n社員登録の扶養親族等で「扶養区分」と「扶養親族の数」を確認してから\n給与明細書、賞与明細書を作成してください。"],
                /** データ削除 */
                OLDYEARDELETE: ["年次更新を行うと{0};年分のデータが削除されます。"],
                /** データ保存延長 */
                SAVEPERIOD: ["※データ保存延長サービスに加入すると最大10年分のデータが保存可能です。"],
            },

            /**
             * 検証
             */
            Validate: {
                /** 未確定 */
                NONCLOSING: ["全ての給与・賞与が確定されていないため、年次更新できません。"],
                /** 単独年調未確定 */
                ADJUSTMENTNONCLOSING: ["単独年調が未確定のため、年次更新できません。"],
                /** 連動項目未選択 */
                ITEMUNSELECTED: ["連動項目を選択してください。"],
            },
        },
    };

    /**
     * 管理サイト
     */
    public static readonly Officeadmin = {

        /**
         * 状況表示
         */
        Progresssituation: {

            /**
             * ステータス
             */
            Status: {
                /** 完了 */
                COMPLETED: ["完了"],
                /** 中止 */
                CANCELED: ["中止"],
            },
        },
    };

    /**
     * 給与共通
     */
    public static readonly Qyocommon = {

        /**
         * 給与共通
         */
        Qyocommon: {

            /**
             * 検証
             */
            Validate: {
                /** 未登録 */
                NOTEXISTS: ["{0};が登録されていません。"],
                /** 未選択 */
                NOTSELECT: ["{0};が選択されていません。"],
                /** 取得できない */
                NOTFOUND: ["{0};取得できませんでした。"],
            },

            /**
             * 情報
             */
            Information: {
                /** 終了 */
                END: ["{0};が正常に終了しました。"],
            },

            /**
             * 確認
             */
            Confirm: {
                /** ファイル重複 */
                DUPLICATEFILE: ["同一ファイル名が存在しますがよろしいですか？"],
                /** データ上限超え */
                DATACOUNTOVER: ["検索結果が上限{0};を超えた為、検索条件を見直して再度検索してください。"],
            },

            /**
             * 警告
             */
            Warning: {
                /** 社員50人超 */
                EMPLOYEECOUNTOVER: ["かんたんクラウド給与で処理できる在籍社員数の制限（{0};名）を超えています。\n社員情報の見直しを行ってください。"],
                /** 社員追加オプション */
                EMPLOYEEADDITIONALOPTIONS: ["2021年4月以降は社員追加オプションのお申込みが必要となります。"],
                /** 社員数制限 */
                EMPLOYEECOUNTOVERIMPOSSIBLE: ["{0};名を超えている場合は、全社員の{1};が行えません。"],
            },
        },

        /**
         * 社会保険通知書送信
         */
        Insurancechangenoticesend: {

            /**
             * 確認
             */
            Confirm: {
                /** CSV出力確認 */
                EXPORTCSVEXECUTE: ["Edge Tracker給与明細参照用に、給与{0};月の社会保険通知書データを抽出します。\n　・画面に表示されている社員のうち、「社員登録」の「EdgeTracker／社会保険通知書」で\n　　「対象」を選択している社員の社会保険通知書データが出力されます。\n　・Edge Tracker給与明細参照で、社会保険通知書データの取り込みと公開（配信）が必要です。"],
            },
        },

        /**
         * 年調明細書送信
         */
        Adjustnoticenotice: {

            /**
             * 確認
             */
            Confirm: {
                /** CSV出力確認 */
                EXPORTCSVEXECUTE: ["Edge Tracker給与明細参照用に、年調明細書データを抽出します。\n　・画面に表示されている社員のうち、「社員登録」の「EdgeTracker／年調明細書」で\n　　「対象」を選択している社員の年調明細書データが出力されます。\n　・Edge Tracker給与明細参照で、年調明細書データの取り込みと公開（配信）が必要です。"],
            },
        },

        /**
         * 源泉徴収票送信
         */
        Withholdingnotice: {

            /**
             * 確認
             */
            Confirm: {
                /** CSV出力確認 */
                EXPORTCSVEXECUTE: ["Edge Tracker給与明細参照用に、源泉徴収票データを抽出します。\n　・画面に表示されている社員のうち、「社員登録」の「EdgeTracker／源泉徴収票」で\n　　「対象」を選択している社員の源泉徴収票データが出力されます。\n　・Edge Tracker給与明細参照で、源泉徴収票データの取り込みと公開（配信）が必要です。"],
            },

            /**
             * エラー
             */
            Error: {
                /** 桁数オーバー */
                ERRORCSVDIGITOVER: ["{0};は最大桁数を超えています。\n{1};以内で登録してください。"],
            },
        },

        /**
         * 給与明細書送信
         */
        Payrollnotice: {

            /**
             * 確認
             */
            Confirm: {
                /** CSV出力確認 */
                EXPORTCSVEXECUTE: ["Edge Tracker給与明細参照用に、給与{0};月の給与明細書データを抽出します。\n　・画面に表示されている社員のうち、「社員登録」の「EdgeTracker／給与明細書」で\n　　「対象」を選択している社員の給与明細書データが出力されます。\n　・Edge Tracker給与明細参照で、給与明細書データの取り込みと公開（配信）が必要です。"],
            },
        },

        /**
         * 賞与明細書送信
         */
        Bonusnotice: {

            /**
             * 確認
             */
            Confirm: {
                /** CSV出力確認 */
                EXPORTCSVEXECUTE: ["Edge Tracker給与明細参照用に、賞与{0};回の賞与明細書データを抽出します。\n　・画面に表示されている社員のうち、「社員登録」の「EdgeTracker／賞与明細書」で\n　　「対象」を選択している社員の賞与明細書データが出力されます。\n　・Edge Tracker給与明細参照で、賞与明細書データの取り込みと公開（配信）が必要です。"],
            },
        },

        /**
         * 仕訳作成
         */
        Payentryqyoswkmake: {

            /**
             * エラー
             */
            Error: {
                /** 仕訳連動失敗 */
                SWKMAKEFAILED: ["仕訳連動を実行できませんでした。({0};）"],
            },

            /**
             * 検証
             */
            Validate: {
                /** 月次確定済 */
                MONTHLYCLOSING: ["該当月の仕訳が月次確定済みのため、仕訳を連動できません。"],
                /** 仕訳連動未完了 */
                SWKMAKE: ["前回の仕訳連動処理が完了していません。"],
                /** 伝票日付会計月度不正 */
                DENDATEMONTHERROR: ["伝票日付が会計月度内({0};～{1};)ではありません。"],
                /** 伝票日付会計期間不正 */
                DENDATEACCOUNTINGTERMERROR: ["伝票日付が会計期間範囲内ではありません。"],
                /** 伝票日付不正 */
                DENDATEERROR: ["伝票日付が仕訳入力開始日以降ではありません。"],
                /** 仕訳情報未設定 */
                NOTEXISTSKMK: ["仕訳情報が設定されていません。\n『明細書設定』の仕訳情報で科目を設定してください。"],
            },

            /**
             * 確認
             */
            Confirm: {
                /** 仕訳作成済 */
                EXISTSSWK: ["仕訳が作成済みですが、処理を開始してもよろしいですか？"],
                /** 仕訳作成開始通知 */
                SWKMAKENOTIFY: ["仕訳作成処理を開始しました。\n処理状況はダイアログの再度起動時、または『給与賞与確定』の確定時に確認可能です。\n※完了していない場合はしばらく時間をおいてから再度ご確認ください"],
            },
        },

        /**
         * マイナンバー共通
         */
        Mynumbercommon: {

            /**
             * 検証
             */
            Validate: {
                /** 氏名・生年月日が不正 */
                NAMEANDBIRTHDAYERROR: ["氏名と生年月日が異なっています。よろしいですか？"],
                /** 氏名が不正 */
                NAMEERROR: ["氏名が異なっています。よろしいですか？"],
                /** 生年月日が不正 */
                BIRTHDAYERROR: ["生年月日が異なっています。よろしいですか？"],
                /** 生年月日の取込 */
                BIRTHDAYUPDATE: ["生年月日欄に生年月日 {0}; を取り込みますか？"],
                /** マイナンバー情報なし・再設定 */
                MYNUMBERNODATARESET: ["連携済みの個人情報が存在しません。"],
                /** 個人番号が重複 */
                MYNUMBERDUPLICATION: ["異なる個人に同じ「個人番号」が連携されています。"],
                /** 個人番号が不一致 */
                MYNUMBERMISMATCH: ["同じ個人に異なる「個人番号」が連携されています。"],
                /** 抽出時の例外 */
                UNEXPECTEDERROR: ["抽出処理で想定外のエラーが発生しました。"],
            },
        },

        /**
         * 個人番号API
         */
        Mynumberapi: {

            /**
             * 検証
             */
            Validate: {
                /** 接続情報が不正 */
                CONNECTINFOERROR: ["パラメータの接続情報が不正です。"],
                /** 事業者情報が不正 */
                CLIENTINFOERROR: ["パラメータの事業者情報が不正です。"],
                /** 検索キー情報が不正 */
                SEARCHLISTERROR: ["パラメータの検索キー情報が不正です。"],
                /** 操作履歴情報が不正 */
                HISTORYLISTERROR: ["パラメータの操作履歴情報が不正です。"],
                /** 検索キーが未設定 */
                SEARCHLISTCHECK: ["検索キーが未設定です。"],
                /** マイナンバー情報なし */
                MYNUMBERNODATA: ["存在しないマイナンバー情報がありました。"],
                /** 個人情報なし */
                PERSONALINFONODATA: ["個人情報が存在しません。"],
            },

            /**
             * 例外
             */
            Exception: {
                /** マイナンバー取得 */
                NUMBERGET: ["マイナンバー取得で例外エラーが発生しました。"],
                /** マイナンバー存在確認 */
                NUMBERCONFIRMATION: ["マイナンバー存在確認で例外エラーが発生しました。"],
                /** 全件取得 */
                NUMBERALLGET: ["全件取得で例外エラーが発生しました。"],
                /** 履歴書込み */
                ADDHISTORY: ["履歴書込みで例外エラーが発生しました。"],
                /** その他 */
                OTHER: ["例外エラーが発生しました。"],
            },
        },

        /**
         * 勤怠データ取込
         */
        Workfrontdataimport: {

            /**
             * 確認
             */
            Confirm: {
                /** データ取込失敗 */
                IMPORTFAILED: ["{0};の取込を実行できませんでした。({1};）"],
                /** データ取込完了 */
                IMPORTDONE: ["{0};の取込が完了しました。"],
            },

            /**
             * 情報
             */
            Information: {
                /** CSV読み込み完了 */
                READCSVDONE: ["{0};の読み込みが完了しました。"],
            },
        },

        /**
         * 年調データ取込
         */
        Adjustmentfrontdataimport: {

            /**
             * 確認
             */
            Confirm: {
                /** 社員情報変更 */
                CHANGEEMPLOYEE: ["社員情報の異なるデータが存在します。このまま更新してもよろしいですか？"],
            },
        },

        /**
         * 入力検証
         */
        Adjcheck: {

            /**
             * 検証
             */
            Validate: {
                /** 入力検証エラーあり */
                CHECKERROR: ["入力データにエラーがあります。\n≪入力検証≫で確認しますか？\n\nはい・・・・≪入力検証≫を表示します。\nいいえ・・・エラーを無視して、処理を進めます。"],
                /** 入力検証警告あり */
                CHECKWARNING: ["入力データに要確認点があります。\n≪入力検証≫で確認しますか？\n\nはい・・・・≪入力検証≫を表示します。\nいいえ・・・無視して、処理を進めます。"],
                /** 個人番号連携重複 */
                MYNUMBERDUPLICATION: ["異なる個人に同じ「個人番号」が登録されています。\n正しい「個人番号」を登録してください。"],
                /** 個人番号登録誤り */
                MYNUMBERUNAUTHORIZED: ["同じ個人に異なる「個人番号」が登録されています。\n正しい「個人番号」を登録してください。"],
            },
        },

        /**
         * 明細通知
         */
        Payslipnotice: {

            /**
             * 確認
             */
            Confirm: {
                /** 明細通知失敗 */
                NOTICEFAILED: ["通知を実行できませんでした。({0};)"],
                /** 明細通知完了 */
                NOTICEDONE: ["通知が完了しました。"],
            },
        },

        /**
         * 明細書設定
         */
        Registerpaymentslip: {

            /**
             * エラー
             */
            Error: {
                /** 計算項目未設定 */
                CALCITEMNOTSET: ["単価計算式が設定されていません。設定内容を確認してください。"],
            },
        },
    };

    /**
     * 給与処理
     */
    public static readonly Payment = {

        /**
         * 明細書入力
         */
        Paymentslipentry: {

            /**
             * 検証
             */
            Validate: {
                /** 社員未登録 */
                NOTEXISTS: ["社員が登録されていません。"],
                /** 社員未選択 */
                NOTSELECT: ["社員が選択されていません。"],
                /** 金額誤差 */
                AMOUNTERROR: ["{0};を確認してください。（明細書画面「{1};」、計算結果「{2};」）"],
            },

            /**
             * エラー
             */
            Error: {
                /** 明細書作成なし */
                MAKENODATA: ["支給対象の社員がいないため、明細書は作成されませんでした。"],
                /** 差異あり */
                WITHDIFFERENCE: ["明細書データに不整合がありました。\nログをダウンロードして確認してください。"],
                /** 金額差異 */
                DIFFERENTINCOMETAX: ["{0};が一致していません。（計算値「{1};」、明細書「{2};」）"],
            },

            /**
             * 確認
             */
            Confirm: {
                /** 差異なし */
                NODIFFERENCE: ["明細書データに不整合はありませんでした。"],
                /** 検証 */
                VERIFICATION: ["{0};を開始します。よろしいですか？"],
                /** 徴収の確認（算定基礎届） */
                ICBCOLLECT: ["標準報酬月額の改定対象者が存在します。\n『算定基礎届』より、「改定」を行ってください。"],
                /** 徴収の確認（月額変更届） */
                IMCCOLLECT: ["標準報酬月額の改定対象者が存在します。\n『月額変更届』より、「改定」を行ってください。"],
                /** 標準報酬月額・等級変更 */
                UPDATEKENPO: ["新しい標準報酬月額・等級が選択されていません。このまま明細書を作成してよろしいですか？\n新しい標準報酬月額・等級で明細書を作成する場合は、「会社基本情報登録」から「標準報酬月額・等級」を変更してください。"],
                /** 改定対象者の確認（算定基礎届） */
                CALCULATIONBASISTARGET: ["・標準月額（算定基礎届）の改定対象者が存在します。\n　改定処理を行う場合は、先に『算定基礎届』を行ってください。"],
                /** 改定対象者の確認（月額変更届） */
                MONTHLYCHANGETARGET: ["・標準月額（月額変更届）の改定対象者が存在します。\n　改定処理を行う場合は、先に『月額変更届』を行ってください。"],
                /** 保険料率の確認 */
                INSURANCERATE: ["・保険料率が未登録のため保険料が計算されません。\n　明細書を作成する場合は、先に『会社基本情報登録』から「保険料被保険者負担率」を登録してください。"],
                /** 標準月額変更 */
                STANDARDMONTHLYFEE: ["・新しい保険料額表が選択されていません。\n　新しい保険料額表で明細書を作成する場合は、先に『会社基本情報登録』から「標準報酬月額・等級」を変更してください。"],
                /** 適用時期前の標準月額変更 */
                STANDARDMONTHLYFEEINCORRECT: ["・最新の保険料額表が選択されているため正しくありません。\n　適用時期になってから最新の保険料額表を選択してください。\n　正しい保険料額表で明細書を作成する場合は、先に『会社基本情報登録』から「標準報酬月額・等級」を変更してください。"],
                /** 都道府県保険料率の設定 */
                PREFECTUREINSRATE: ["・健康保険・介護保険の保険料率を更新する必要があります。\n　新しい保険料率で明細書を作成する場合は、先に『会社基本情報登録』から［保険料被保険者負担率］-［都道府県］を最新年度に変更してください。"],
                /** 適用時期前の都道府県保険料率の設定 */
                PREFECTUREINSRATEINCORRECT: ["・最新の健康保険・介護保険の保険料率が選択されているため正しくありません。\n　適用時期になってから最新の保険料率を選択してください。\n　正しい保険料率で明細書を作成する場合は、先に『会社基本情報登録』から［保険料被保険者負担率］-［都道府県］を変更してください。"],
                /** 作成実行 */
                EXECUTECREATE: ["次の問題がありました。\n\n{0};\nこのまま明細書を作成してもよろしいですか？"],
                /** 通勤費の確認 */
                COMMUTINGEXPENSES: ["通勤費には現物通勤費を含んだ金額を入力してください。"],
                /** 月額表の確認 */
                MONTHLYTABLE: ["・［月額表］の選択が正しくありません。\n　支給日と月額表の対応年が不一致のため、正しい所得税が計算されません。\n　明細書作成ダイアログの［計算情報］-［月額表］を変更してください。\n　　R2年1月以降分……R2年1月以降はこちらを使用します。\n　　H30年1月以降分……H30年1月以降、R1年12月までの場合に使用します。"],
                /** 算出率表の確認 */
                CALCULATIONRATETABLE: ["・［源泉所得税額の算出率表］の選択が正しくありません。\n　支給日と源泉所得税額の算出率表の対応年が不一致のため、正しい所得税が計算されません。\n　明細書作成ダイアログの［所得税計算情報］-［源泉所得税額の算出率表］を変更してください。\n　　R2年1月以降分……R2年1月以降はこちらを使用します。\n　　H30年1月以降分……H30年1月以降、R1年12月までの場合に使用します。"],
            },
        },

        /**
         * 集計表
         */
        Paymetsliptotalization: {

            /**
             * 検証
             */
            Validate: {
                /** データなし */
                NODATA: ["該当データがありません。"],
                /** 社員存在 */
                EXISTSEMPCALCNONE: ["未計算社員が存在します。明細書入力で再作成を行ってください。"],
            },
        },

        /**
         * 金種表
         */
        Paymentdenomination: {

            /**
             * 検証
             */
            Validate: {
                /** 社員未登録 */
                NOTEXISTS: ["社員が未登録のため実行できません。"],
                /** データなし */
                NODATAEMPPAYMENT: ["現金支給の対象者に該当がありません。"],
            },

            /**
             * 情報
             */
            Information: {
                /** 明細書作成 */
                PAYENTRYQYOMAKE: ["明細書作成が行われていません。"],
            },
        },

        /**
         * 銀行振込処理
         */
        Paymenttransfer: {

            /**
             * 検証
             */
            Validate: {
                /** 依頼先銀行口座未登録 */
                NOTEXISTSREQBANK: ["依頼先銀行口座が未登録のため実行できません。"],
                /** 年調設定エラー */
                ADJUSTMENTSETTINGERROR: ["年調方法が単独年調に設定されていないため、実行できません。"],
                /** xml出力文字不正 */
                XMLOUTPUT: ["{0};に{1};は使用できません。"],
                /** xmlファイル拡張子 */
                XMLEXTENSION: ["出力出来ない拡張子です。\n拡張子を[xml]にしてください。"],
            },

            /**
             * 情報
             */
            Information: {
                /** アップロード中 */
                UPLOADPROCESSING: ["ファイルアップロード中です．．．"],
            },

            /**
             * 確認
             */
            Confirm: {
                /** メールアドレス違い */
                DIFFERENTMAILADDRESS: ["メールアドレスまたはパスワードが違います。"],
            },

            /**
             * エラー
             */
            Error: {
                /** 認証拒否 */
                AUTHENTICATIONREJECTION: ["要求に対して認証が拒否されました。"],
                /** 予期せぬエラー */
                UNEXPECTED: ["予期せぬエラーが発生しました。"],
                /** 接続エラー */
                INTERNETCONNECTION: ["楽たすサーバーへ接続できません。\nインターネット接続を確認してください。"],
            },
        },
    };

    /**
     * 社員処理
     */
    public static readonly Employee = {

        /**
         * 有休付与
         */
        Employeepaidholidaysgrant: {

            /**
             * 確認
             */
            Confirm: {
                /** 有休更新 */
                UPDATEEMPGRANT: ["有休を更新します。よろしいですか？"],
            },
        },

        /**
         * 社員登録
         */
        Employeeregister: {

            /**
             * 確認
             */
            Confirm: {
                /** 明細書更新 */
                UPDATEPAYENTRYQYO: ["処理中の明細書に反映しますか？"],
                /** 当年給与データ存在 */
                EXISTSQYODATA: ["当年の給与データが存在します。処理を開始してよろしいですか？"],
                /** 社員未計算 */
                UPDATEEMPCALCNONE: ["未計算に戻しますか？"],
            },

            /**
             * 情報
             */
            Information: {
                /** 社員未計算 */
                UPDATEEMPCALCNONE: ["社員を未計算にしました。明細書入力で再作成を行ってください。"],
                /** 社員未計算（年調入力） */
                UPDATEEMPCALCNONEADJUSTMENTENTRY: ["社員を未計算にしました。年調データ入力で再作成を行ってください。"],
            },

            /**
             * 検証
             */
            Validate: {
                /** 市区町村コード存在 */
                EXISTSCITYNO: ["すでに登録されている市区町村コードです。"],
            },

            /**
             * エラー
             */
            Error: {
                /** 振込先重複 */
                DUPLICATETRAN: ["銀行振込の{0};の振込先が重複しています。"],
            },
        },
    };

    /**
     * 社会保険
     */
    public static readonly Insurance = {

        /**
         * 算定基礎届
         */
        Insurancecalculatebase: {

            /**
             * 確認
             */
            Confirm: {
                /** 健保種別「なし」 */
                INSURANCENONE: ["健保種別が「なし」に設定されています。\n会社基本情報登録で健保種別の再設定を行ってください。"],
                /** 改定完了 */
                INSURANCEPOST: ["社会保険料の改定を完了しました。"],
                /** 改定の確認 */
                UPDATEINSURANCE: ["標準報酬月額の改定を行います。よろしいですか？"],
                /** 標準報酬月額・等級変更 */
                UPDATEKENPO: ["新しい標準報酬月額・等級が選択されていません。\n『会社基本情報登録』から標準報酬月額・等級の設定を行ってください。"],
                /** 対象変更の確認 */
                CHANGENECESSITY: ["対象を変更します。よろしいですか？"],
                /** 再計算の確認 */
                RECALC: ["手入力した内容がクリアされますが、よろしいですか？"],
            },

            /**
             * 警告
             */
            Warning: {
                /** 改定の警告 */
                UPDATEINSURANCE: ["社会保険料の徴収月の前月が確定されていません。\n確定後に「改定」を行ってください。\nこのまま改定を行いますか？"],
            },
        },

        /**
         * 月額変更届
         */
        Insurancemonthlychange: {

            /**
             * エラー
             */
            Error: {
                /** データ取得 */
                SELECTDATA: ["データ取得に失敗しました。"],
            },
        },

        /**
         * 労働保険年度更新用資料
         */
        Insurancelaborupdate: {

            /**
             * 確認
             */
            Confirm: {
                /** 再集計 */
                RECOUNT: ["集計結果が編集されています。再集計しますか？\nはい・・・編集した内容をクリアし、再集計を行います。\nいいえ・・・編集した内容を表示します。"],
                /** 高齢者の合計 */
                AGEDTOTAL: ["被保険者と役員被保険者の合計より小さい数値を入力してください。"],
            },
        },
    };

    /**
     * 年末調整
     */
    public static readonly Adjustment = {

        /**
         * 年調データ入力
         */
        Adjustmententry: {

            /**
             * 検証
             */
            Validate: {
                /** 社員存在 */
                EXISTSEMPCALCNONE: ["未計算社員が存在します。年調データ入力を行ってください。"],
                /** 単独年調作成 */
                ADJUSTMENTMAKE: ["年末調整（単独年調）を開始するには作成を行ってください。"],
            },

            /**
             * 確認
             */
            Confirm: {
                /** 計算実行確認 */
                EXECUTECALC: ["年末調整の計算を行います。よろしいですか？"],
                /** 未計算 */
                UPDATEEMPCALCNONE: ["すでに年末調整の計算が行われているため、未計算に戻します。よろしいですか？"],
                /** 確定（給与） */
                PAYCLOSING: ["給与12月がすでに確定されているため、年調方法を変更できません。変更したい場合は、確定解除を行ってください。"],
                /** 確定（賞与） */
                BONUSCLOSING: ["賞与年調回がすでに確定されているため、年調方法を変更できません。変更したい場合は、確定解除を行ってください。"],
            },

            /**
             * 情報
             */
            Information: {
                /** 計算実行完了 */
                CALCDONE: ["年末調整の計算を行いました。"],
            },
        },

        /**
         * 申告書データ取込
         */
        Declarationformimport: {

            /**
             * 確認
             */
            Confirm: {
                /** インポート実行 */
                IMPORTEXECUTE: ["選択したデータは既に取り込み済みです。\nインポートを実行しますか？"],
                /** 確認リスト出力メッセージ */
                CONFIRMLISTMESSAGE: ["「国税庁年調ソフト」から出力された電子データの個人情報に「かんたんクラウド給与」と一致していない\n社員、配偶者、扶養親族が存在します。\n確認リストファイルをダウンロードして確認してください。\n内容に問題が無いことが確認できた場合はＯＫボタンを押下してインポートを開始してください。"],
            },

            /**
             * エラー
             */
            Error: {
                /** 社員選択 */
                CHOICETHEEMPLOYEE: ["年調データ入力画面で社員を選択してから実行してください。"],
                /** ファイル名エラー */
                FILENAMEERROR: ["ファイル名にエラーが有るため取込めません。"],
                /** 複数ファイルエラー */
                MULTIPLEFILEERROR: ["複数ファイルは指定できません。"],
                /** ファイル再入力 */
                RETRYFILESELECT: ["申告書電子データには国税庁年調ソフトから出力したパスワード付きのZipファイル\nを指定してください。　　　例）ファイル名＝XXXX+[pass].zip"],
                /** パスワードエラー */
                PASSWORDERROR: ["パスワードエラーのためインポートできません。"],
                /** 確認リスト出力エラーメッセージ */
                CONFIRMLISTERRORMESSAGE: ["「国税庁年調ソフト」から出力された電子データを取り込むにあたり、問題のあるデータが存在します。\nこのままでは取り込みの処理を実行できません。\n確認リストファイルをダウンロードして確認してください。"],
                /** エラーログ出力メッセージ */
                ERRORLOGMESSAGE: ["申告書データにエラーがあります。\nエラーログをダウンロードして確認してください。"],
            },
        },
    };

    /**
     * 連携システム
     */
    public static readonly Connect = {

        /**
         * マスター送信
         */
        Mastersend: {

            /**
             * 確認
             */
            Confirm: {
                /** CSV出力開始 */
                EXPORTCSVEXECUTE: ["{0};を抽出します。\nよろしいですか？"],
                /** マスター送信開始 */
                MASTERSENDEXECUTE: ["マスター送信を開始します。よろしいですか？"],
            },

            /**
             * 情報
             */
            Information: {
                /** マスター送信完了 */
                MASTERSENDDONE: ["マスター送信が完了しました。"],
            },

            /**
             * エラー
             */
            Error: {
                /** bizsky接続情報未設定 */
                BIZSKYNOTSET: ["bizsky接続情報が設定されていません。\n『連携情報設定』で接続情報を設定してください。"],
                /** マスター送信失敗 */
                MASTERSENDFAILED: ["マスター送信を実行できませんでした。({0};）"],
            },
        },
    };

    /**
     * かんたん申告起動
     */
    public static readonly Reportsystemlauncher = {

        /**
         * 確認
         */
        Confirm: {
            /** 起動確認 */
            LAUNCH: ["かんたんクラウド確定申告を起動します。"],
        },
    };

    /**
     * かんたん申告導入設定
     */
    public static readonly Reportsystemkmkmapping = {

        /**
         * 確認
         */
        Confirm: {
            /** 科目パターン変更 */
            KMKPATTERN: ["科目パターンが変更されました。\n現在の科目体系は破棄して、作成し直します。\nよろしいですか？"],
            /** 未割り当て科目あり */
            NOLINK: ["未割り当ての科目が存在します。\n更新してよろしいですか？"],
        },
    };
};
