/**
 * マスターデータ ViewModel クラス
 */
import { DataModelBase } from "../../core/models/dataModelBase";
export class KmkMBCacheViewModel extends DataModelBase {
    /** 顧問先管理コード */
    public ClientCode!: number;

    /** 年度 */
    public ClientYear!: number;

    /** 顧問先管理ユーザー */
    public ClientUser!: string;

    /** マスタ区分 */
    public MasterKbn!: number;

    /** 実在／合計区分 */
    public SumKbn!: number;

    /** 外部コード */
    public GCode!: number;

    /** 科目別補助コード */
    public SubCode!: number;

    /** 更新日時 */
    public UpdateTime!: Date;

    /** 装飾区分 */
    public DecoKbn!: number;

    /** マイナス印刷区分 */
    public MinusKbn!: number;

    /** 差額印刷区分 */
    public SagakuKbn!: number;

    /** 利益印刷区分 */
    public ProfitKbn!: number;

    /** 合計表示区分 */
    public TotalKbn!: number;

    /** 累計集計区分 */
    public ruikeiKbn!: number;

    /** 平均値採用区分 */
    public AveKbn!: number;

    /** 合計第２レベル区分 */
    public LevelKbn!: number;

    /** 特殊合計科目コード */
    public SpKmkCode!: number;

    /** 収入／支出除去区分 */
    public PLCutKbn!: number;

    /** 仕訳データ逆転区分 */
    public ReverseKbn!: number;

    /** 元帳摘要欄起票者印字区分 */
    public MTekiPrn!: number;

    /** 集計単位区分 */
    public OutKbn1!: number;

    /**  */
    public OutKbn2!: number;

    /**  */
    public OutKbn3!: number;

    /**  */
    public OutKbn4!: number;

    /**  */
    public OutKbn5!: number;

    /** 科目別補助電子帳簿採用区分 */
    public OutKbn6!: number;

    /** 単位コード */
    public TaniCode!: number;

    /** マイナス変換科目名 */
    public MinusName!: string;

    /** 変動費比率 */
    public FixChgRate!: number;

    /** 個別原価率 */
    public CostRate!: number;

    /**  */
    public ElRate1!: number;

    /**  */
    public ElRate2!: number;

    /** 履歴管理区分 */
    public HisKbn!: number;

    /** 第1補助電子帳簿採用区分 */
    public EBook1Kbn!: number;

    /** 第2補助電子帳簿採用区分 */
    public EBook2kbn!: number;

    /** 性格コード２ */
    public CharCode2!: number;

    /** 借方第２科目コード */
    public D2KmkCd!: number;

    /** 借方第３科目コード */
    public D3KmkCd!: number;

    /** 貸方第２科目コード */
    public C2KmkCd!: number;

    /** 貸方第３科目コード */
    public C3KmkCd!: number;

    /** 売上／仕入逆転区分 */
    public SBExchg!: number;

    /**  */
    public PubKbn1!: number;

    /**  */
    public PubKbn2!: number;

    /** 通貨コード */
    public CurrCode!: number;

    /** 取引レート区分 */
    public ExRateKbn!: number;

    /** 決算レート区分 */
    public FinRateKbn!: number;

    /** 決算レート換算処理区分 */
    public FinRateExg!: number;

    /** 為替差損益振替処理区分 */
    public ExgTrnKbn!: number;

    /** 為替差益科目コード */
    public ExgPrKCd!: number;

    /** 為替差益科目別補助コード */
    public ExgPrSCd!: number;

    /** 為替差損科目コード */
    public ExgLoKCd!: number;

    /** 為替差損科目別補助コード */
    public ExgLoSCd!: number;

}
