import { CodeAttr, DenKbn, EvidenceNoType, ExcludedInputKbn, HojyoKbn, MasterKbn, MasterSumKbn, PartitionRecordKbn, RecordKbn } from "../../core/constants/constant";
import { HojyoMACacheViewModel } from "../../models/cache/hojyoMACacheViewModel";
import { KbnInfoCacheViewModel } from "../../models/cache/kbnInfoCacheViewModel";
import { MasterInfoCacheViewModel } from "../../models/cache/masterInfoCacheViewModel";
import { MjsUtilities } from "../usefuls/utilities";
import { journalControlUtil } from "./swklControlUtil";
import $ from 'jquery';
import "../usefuls/utilities";

export class CodeRegexp {

    /**
     * フリー入力用に絞る正規表現
     * @param useWindow
     */
    public static getFreeRegexp(allowedSymbolString: string = ""): RegExp {
        let freeCode: string = "";

        if (!journalControlUtil.isInitialized() || allowedSymbolString != "") {
            // 引数で指定されていた場合(またはキャッシュのイニシャライズが終了していないとき)
            freeCode = allowedSymbolString;
        } else {
            // 引数に何も指定されていない場合は、会社設定から取得
            freeCode = journalControlUtil.getDTName7();
        }

        if (freeCode) {
            // 文字が存在すれば正規表現用にエスケープする
            freeCode = CodeRegexp.escapeRegExp(freeCode);
        }

        // エスケープされた正規表現
        return RegExp("^[ｦ-ﾟ0-9a-zA-Z " + freeCode + "]*$");
    }

    /**
     * フリー入力(証憑NO)用に絞る正規表現
     */
    public static getFreeRegexpShohyo(): RegExp {
        return RegExp("[ -~｡-ﾟ]+");
    }

    /**
     * 数値入力に絞る正規表現(0入力あり整数のみに修正)
     * @param useWindow
     */
    public static getNumberRegexp(isUseWindow: boolean = false) {
        if (isUseWindow) {
            return RegExp("^[0-9a-zA-Zｦ-ﾟ]+$");
        } else {
            return RegExp("^[0-9]*$");
        }
    }

    /**
     * 数値入力に絞る正規表現(数値のみに修正)
     * ※ただたんに数値の入力だけに絞りたい場合はこちらを使用
     * @param useWindow
     */
    public static getZerofilRegexp(isUseWindow: boolean = false) {
        if (isUseWindow) {
            return RegExp("^[0-9a-zA-Zｦ-ﾟ]+$");
        } else {
            return RegExp("^[0-9]*$");
        }

    }

    private static escapeRegExp(string: string) {
        //return string.replace(/([\\])/g, '').replace(/([.*+?^\-=!:${}()|[\]\/\\])/g, "\\$1");
        return string.replace(/([.*+?^\-=!:${}()|[\]\/\\])/g, "\\$1");
    }
}

/**
 * hojoMaレコードから各種判断を’する処理
 */
export class hojoMAUtil {
    /**
     * 補助科目の保持
     */
    private static hojyoMA: any;

    /**
     *  hojyoMaUtilを使用するためのイニシャライズ
     * @param hojyoMa
     */
    public static initialize(
        bank: Array<HojyoMACacheViewModel>,
        bmn: Array<HojyoMACacheViewModel>,
        genHojo1: Array<HojyoMACacheViewModel>,
        genHojo2: Array<HojyoMACacheViewModel>,
        genHojo3: Array<HojyoMACacheViewModel>,
        genHojo4: Array<HojyoMACacheViewModel>,
        genHojo5: Array<HojyoMACacheViewModel>,
        koji: Array<HojyoMACacheViewModel>,
        seg: Array<HojyoMACacheViewModel>,
        staff: Array<HojyoMACacheViewModel>,
        client: Array<HojyoMACacheViewModel>
    ) {
        hojoMAUtil.hojyoMA = {};

        hojoMAUtil.hojyoMA[MasterKbn.Bank] = bank;
        hojoMAUtil.hojyoMA[MasterKbn.Bmn] = bmn;
        hojoMAUtil.hojyoMA[MasterKbn.GeneralPurposeAuxiliary1] = genHojo1;
        hojoMAUtil.hojyoMA[MasterKbn.GeneralPurposeAuxiliary2] = genHojo2;
        hojoMAUtil.hojyoMA[MasterKbn.GeneralPurposeAuxiliary3] = genHojo3;
        hojoMAUtil.hojyoMA[MasterKbn.GeneralPurposeAuxiliary4] = genHojo4;
        hojoMAUtil.hojyoMA[MasterKbn.GeneralPurposeAuxiliary5] = genHojo5;
        hojoMAUtil.hojyoMA[MasterKbn.Koji] = koji;
        hojoMAUtil.hojyoMA[MasterKbn.Segment1] = seg;
        hojoMAUtil.hojyoMA[MasterKbn.Staff] = staff;
        hojoMAUtil.hojyoMA[MasterKbn.Client] = client;
        hojoMAUtil.hojyoMA[MasterKbn.Customers] = client;
        hojoMAUtil.hojyoMA[MasterKbn.Supplier] = client;
    }


    /**
     * 取引先HojyoMaデータからそのまま取引先データを返す。
     * @param hojyoMa
     */
    public static getClient(hojyoMa: Array<HojyoMACacheViewModel>): Array<HojyoMACacheViewModel> {
        // 取引先の実在科目のみを返す。
        return hojyoMa.where(r => r.SumKbn == MasterSumKbn.Real);
    }

    /**
     * 取引先HojyoMaデータから得意先データを取得する
     * @param hojyoMa
     */
    public static getCustomersInClient(hojyoMa: Array<HojyoMACacheViewModel>): Array<HojyoMACacheViewModel> {
        //＜実在取引先 （ 得意先 ） の判別方法＞
        //・補助基本マスタ（HojyoMA）のマスタ区分が取引先（MasterKbn = 22）
        //・補助基本マスタ（HojyoMA）の実在／合計区分が実在（SumKbn = 0）
        //・補助基本マスタ（HojyoMA）のレコード区分が正規レコード（RecordKbn = 0）
        //・補助基本マスタ（HojyoMA）の得意先区分が採用あり（HojyoKbn1 = 1）
        return hojyoMa.filter((value: HojyoMACacheViewModel, index: number, array: Array<HojyoMACacheViewModel>) => {
            if ((value.MasterKbn == MasterKbn.Client) &&
                (value.SumKbn == MasterSumKbn.Real) &&
                (value.RecordKbn == RecordKbn.Regular) &&
                (value.HojyoKbn1 == HojyoKbn.Kmk)) {
                return true;
            }
            return false;
        });
    }
    /**
     * 取引先HojyoMaデータから仕入れ先データを取得する
     * @param hojyoMa
     */
    public static getSupplierInClient(hojyoMa: Array<HojyoMACacheViewModel>): Array<HojyoMACacheViewModel> {
        //＜実在取引先 （ 仕入先 ） の判別方法＞
        //・補助基本マスタ（HojyoMA）のマスタ区分が取引先（MasterKbn = 22）
        //・補助基本マスタ（HojyoMA）の実在／合計区分が実在（SumKbn = 0）
        //・補助基本マスタ（HojyoMA）のレコード区分が正規レコード（RecordKbn = 0）
        //・補助基本マスタ（HojyoMA）の仕入先区分が採用あり（HojyoKbn2 = 1）
        return hojyoMa.filter((value: HojyoMACacheViewModel, index: number, array: Array<HojyoMACacheViewModel>) => {
            if ((value.MasterKbn == MasterKbn.Client) &&
                (value.SumKbn == MasterSumKbn.Real) &&
                (value.RecordKbn == RecordKbn.Regular) &&
                (value.HojyoKbn2 == HojyoKbn.Kmk)) {
                return true;
            }
            return false;
        });
    }

    /**
     * 補助科目の実在科目のみを取り出す
     * @param hojyoMa
     */
    public static getRealHojyoMa(hojyoMa: Array<HojyoMACacheViewModel>): Array<HojyoMACacheViewModel> {
        // 引数で指定された項目リストから実在科目のみを取り出す
        return hojyoMa.filter((value: HojyoMACacheViewModel, index: number, array: Array<HojyoMACacheViewModel>) => {
            if (value.SumKbn == MasterSumKbn.Real) {
                return true;
            }
            return false;
        });
    }
    /**
     * 指定されたマスタ区分とコードの補助マスタを取得する
     * @param gCode
     */
    public static getHojyoKmk(gCode: string, masterKbn: number) {
        let hojyoMa: HojyoMACacheViewModel = undefined!;
        let hojoList: Array<HojyoMACacheViewModel> = hojoMAUtil.hojyoMA[masterKbn];
        let hojyoKbn = masterKbn == MasterKbn.Customers || masterKbn == MasterKbn.Supplier ? MasterKbn.Client : masterKbn;

        $.each(hojoList, (index: number, value: HojyoMACacheViewModel) => {
            if ((value.GCode == gCode) &&
                (value.MasterKbn == hojyoKbn)) {
                hojyoMa = value;
                return false;
            }
            return true;
        });
        return hojyoMa;
    }

    /**
     * 指定された科目コードのHojyoMA Entityを取得
     * @param masterKbn MasterKbn
     * @param sumKbn SumKbn
     * @param recordKbn RecordKbn
     * @param gCode GCode
     */
    public static getHojyoMaByPk(masterKbn: number, sumKbn: number, recordKbn: number, gCode: string): HojyoMACacheViewModel {

        let hojyoMaCacheViewModel: HojyoMACacheViewModel = null!;
        let hojoList: Array<HojyoMACacheViewModel> = hojoMAUtil.hojyoMA[masterKbn];

        hojyoMaCacheViewModel = hojoList.singleOrNull((m: HojyoMACacheViewModel) => {
            return m.SumKbn == sumKbn && m.RecordKbn == recordKbn && m.GCode == gCode;
        });

        return hojyoMaCacheViewModel;
    }
}

/**
 * MasterInfo、KbnInfoで各項目の判定を行うUtilクラス。
 */
export class subItemUtil {
    // MasterInfo系科目
    public static kmkMa: kmkMaInfo;
    public static kmkMaSub: kmkMaSubInfo;
    public static bmn: bmnInfo;
    public static bank: bankInfo;
    public static client: clientInfo;
    public static staff: staffInfo;
    public static koji: kojiInfo;
    public static seg1: segInfo1;
    public static seg2: segInfo2;
    public static seg3: segInfo3;
    public static seg4: segInfo4;
    public static seg5: segInfo5;
    public static genHojo1: GenHojoInfo1;
    public static genHojo2: GenHojoInfo2;
    public static genHojo3: GenHojoInfo3;
    public static genHojo4: GenHojoInfo4;
    public static genHojo5: GenHojoInfo5;
    public static cash: CashInfo;
    // KbnInfo系科目
    public static shohyoNo: ShohyoNoInfo;
    public static denNo: DenNoInfo;
    public static limitDate: LimitDateInfo;
    public static fixedTekiyo: FixedTekiyoInfo;
    public static fixedVoucher: FixedVoucher;
    public static fixedJournal: FixedJournal;
    public static fixedCashJournal: FixedCashJournal;
    public static taxInputInfo: TaxInputInfo;
    public static complexInfo: ComplexInfo;
    public static tekiyoAutoInputInfo: TekiyoAutoInputInfo;
    public static charitableInfo: CharitableInfo;

    public static initialize(
        masterInfoList: Array<MasterInfoCacheViewModel>,
        kbnInfoList: Array<KbnInfoCacheViewModel>) {
        if (masterInfoList) {
            subItemUtil.kmkMa = new kmkMaInfo(masterInfoList);
            subItemUtil.kmkMaSub = new kmkMaSubInfo(masterInfoList);
            subItemUtil.bmn = new bmnInfo(masterInfoList);
            subItemUtil.bank = new bankInfo(masterInfoList);
            subItemUtil.client = new clientInfo(masterInfoList);
            subItemUtil.staff = new staffInfo(masterInfoList);
            subItemUtil.koji = new kojiInfo(masterInfoList);
            subItemUtil.seg1 = new segInfo1(masterInfoList);
            subItemUtil.seg2 = new segInfo2(masterInfoList);
            subItemUtil.genHojo1 = new GenHojoInfo1(masterInfoList);
            subItemUtil.genHojo2 = new GenHojoInfo2(masterInfoList);
            subItemUtil.genHojo3 = new GenHojoInfo3(masterInfoList);
            subItemUtil.genHojo4 = new GenHojoInfo4(masterInfoList);
            subItemUtil.genHojo5 = new GenHojoInfo5(masterInfoList);
            subItemUtil.cash = new CashInfo(masterInfoList);
            subItemUtil.seg3 = new segInfo3(masterInfoList);
            subItemUtil.seg4 = new segInfo4(masterInfoList);
            subItemUtil.seg5 = new segInfo5(masterInfoList);
        }
        if (kbnInfoList) {
            let swkControlKbnInfo: KbnInfoCacheViewModel = subItemUtil.getSwkInputKbnInfo(kbnInfoList);
            let partitionKbnInfo: KbnInfoCacheViewModel = subItemUtil.getPartitionKbnInfo(kbnInfoList);
            let charitableKbnInfo: KbnInfoCacheViewModel = subItemUtil.getCharitableKbnInfo(kbnInfoList);

            // 使用する区分情報を渡してインスタンスを作成する
            subItemUtil.shohyoNo = new ShohyoNoInfo(swkControlKbnInfo);
            subItemUtil.denNo = new DenNoInfo(swkControlKbnInfo);
            subItemUtil.limitDate = new LimitDateInfo(partitionKbnInfo);
            subItemUtil.fixedTekiyo = new FixedTekiyoInfo(swkControlKbnInfo);
            subItemUtil.fixedVoucher = new FixedVoucher(swkControlKbnInfo);
            subItemUtil.fixedJournal = new FixedJournal(swkControlKbnInfo);
            subItemUtil.fixedCashJournal = new FixedCashJournal(swkControlKbnInfo);
            subItemUtil.taxInputInfo = new TaxInputInfo(swkControlKbnInfo);
            subItemUtil.complexInfo = new ComplexInfo(swkControlKbnInfo);
            subItemUtil.tekiyoAutoInputInfo = new TekiyoAutoInputInfo(swkControlKbnInfo);
            subItemUtil.charitableInfo = new CharitableInfo(charitableKbnInfo);
        }
    }

    /**
     * 仕訳入力条件KbnInfoレコードを取得する
     * @param kbnInfoList
     */
    private static getSwkInputKbnInfo(kbnInfoList: Array<KbnInfoCacheViewModel>): KbnInfoCacheViewModel {
        return subItemUtil.getKbnInfo(
            kbnInfoList,
            PartitionRecordKbn.SwkInputCondition
        );
    }

    /**
     * 採用区分KbnInfoレコードを取得する
     * @param kbnInfoList
     */
    private static getPartitionKbnInfo(kbnInfoList: Array<KbnInfoCacheViewModel>): KbnInfoCacheViewModel {
        return subItemUtil.getKbnInfo(
            kbnInfoList,
            PartitionRecordKbn.Partition
        );
    }

    /**
     * 公益法人関係KbnInfoレコードを取得する
     * @param kbnInfoList
     */
    private static getCharitableKbnInfo(kbnInfoList: Array<KbnInfoCacheViewModel>): KbnInfoCacheViewModel {
        return subItemUtil.getKbnInfo(
            kbnInfoList,
            PartitionRecordKbn.Charitable
        );
    }

    /**
     * 区分infoから目的の区分を持つレコードを取得する
     * @param kbnInfoList
     */
    private static getKbnInfo(
        kbnInfoList: Array<KbnInfoCacheViewModel>,
        recKbn: number
    ): KbnInfoCacheViewModel {

        let kbnInfo: KbnInfoCacheViewModel = undefined!;
        $.each(kbnInfoList, (index: number, value: KbnInfoCacheViewModel) => {
            if (value.RecKbn == recKbn) {
                kbnInfo = value;
                return false;
            }
            return true;
        });
        return kbnInfo;

    }

    public static getHojyoItemUtil(masterKbn: number): hojoItemUtil {
        switch (masterKbn) {
            case MasterKbn.CountingKmk:
                return subItemUtil.kmkMa;
            case MasterKbn.EachKmkAuxiliary:
                return subItemUtil.kmkMaSub;
            //21:銀行 
            case MasterKbn.Bank:
                return subItemUtil.bank;
            //22:取引先 下記構造なので、設定項目は統一化して扱う。
            // 取引先┬得意先
            //      └仕入れ先
            case MasterKbn.Client:
            case MasterKbn.Customers:
            case MasterKbn.Supplier:
                return subItemUtil.client;
            //25:社員 
            case MasterKbn.Staff:
                return subItemUtil.staff;
            //31:汎用補助1 
            case MasterKbn.GeneralPurposeAuxiliary1:
                return subItemUtil.genHojo1;
            //32:汎用補助2 
            case MasterKbn.GeneralPurposeAuxiliary2:
                return subItemUtil.genHojo2;
            //33:汎用補助3 
            case MasterKbn.GeneralPurposeAuxiliary3:
                return subItemUtil.genHojo3;
            //34:汎用補助4 
            case MasterKbn.GeneralPurposeAuxiliary4:
                return subItemUtil.genHojo4;
            //35:汎用補助5 
            case MasterKbn.GeneralPurposeAuxiliary5:
                return subItemUtil.genHojo5;
            //41:部門 
            case MasterKbn.Bmn:
                return subItemUtil.bmn;
            //42:ｾｸﾞﾒﾝﾄ 
            case MasterKbn.Segment1:
                return subItemUtil.seg1;
            //43:ｾｸﾞﾒﾝﾄ2 
            case MasterKbn.Segment2:
                return subItemUtil.seg2;
            //44:ｾｸﾞﾒﾝﾄ3 
            case MasterKbn.Segment3:
                return subItemUtil.seg3;
            //45:ｾｸﾞﾒﾝﾄ4 
            case MasterKbn.Segment4:
                return subItemUtil.seg4;
            //46:ｾｸﾞﾒﾝﾄ5 
            case MasterKbn.Segment5:
                return subItemUtil.seg5;
            //51:工事 
            case MasterKbn.Koji:
                return subItemUtil.koji;
            case MasterKbn.Financing:
                return subItemUtil.cash;
        }
        return null!;
    }

    /**
     * マスター区分に応じた借方補助科目のJSONを取得する
     * @param masterKbn
     */
    /*
    public static getDHojyoJson(
        masterDatas: EntryMasterDatas
        , kmkItemInfoVM: KmkItemInfoViewModel
        , KmkGCode: string
    ): any {
        let json = subItemUtil.getHojyoJson(masterDatas, kmkItemInfoVM, "D", KmkGCode);
        return json;
    }
    */

    /**
     * マスター区分に応じた貸方補助科目のフィールド名を取得する
     * @param masterKbn
     */
    /*
    public static getCHojyoJson(
        masterDatas: EntryMasterDatas
        , kmkItemInfoVM: KmkItemInfoViewModel
        , KmkGCode: string
    ): any {
        let json = subItemUtil.getHojyoJson(masterDatas, kmkItemInfoVM, "C", KmkGCode);
        return json;
    }
    */

    /**
     * マスター区分に応じた補助科目のJSONを取得する
     * @param masterKbn
     */
    /*
    public static getHojyoJson(
        masterDatas: EntryMasterDatas
        , kmkItemInfoVM: KmkItemInfoViewModel
        , prefix: string = ""
        , KmkGCode: string
    ): any {
        switch (kmkItemInfoVM.MasterKbn) {
            case MasterKbn.CountingKmk:
                return genCodeJson("KmkCode", prefix, kmkItemInfoVM);
            case MasterKbn.EachKmkAuxiliary:
                return genCodeJson("SubCode", prefix, kmkItemInfoVM);
            case MasterKbn.Bank:                    //21:銀行
            case MasterKbn.Client:                  //22:取引先
            case MasterKbn.Customers:               //23:得意先
            case MasterKbn.Supplier:                //24:仕入れ先
            case MasterKbn.Staff:                   //25:社員
            case MasterKbn.GeneralPurposeAuxiliary1://31:汎用補助1
            case MasterKbn.GeneralPurposeAuxiliary2://32:汎用補助2
            case MasterKbn.GeneralPurposeAuxiliary3://33:汎用補助3
            case MasterKbn.GeneralPurposeAuxiliary4://34:汎用補助4
            case MasterKbn.GeneralPurposeAuxiliary5://35:汎用補助5
                let partData: Array<mjs.acelink.vkz.KmkMACacheViewModel>;
                partData = masterDatas.kmkMA_Kmk.filter(m =>
                    m.GCode == Number(KmkGCode) && m.MasterKbn == 1 && m.SumKbn == 0 && m.RecordKbn == 0 && m.SumKbn == 0
                );
                if (partData.length > 0 && partData[0].HojyoUse1 != 0) {
                    return genSaiyoukbnAndCodeJson(
                        "Hoj"
                        , prefix
                        , kmkItemInfoVM
                        , 1
                    );
                }
                if (partData.length > 0 && partData[0].HojyoUse2 != 0) {
                    return genSaiyoukbnAndCodeJson(
                        "Hoj"
                        , prefix
                        , kmkItemInfoVM
                        , 2
                    );
                }
                break;
            case MasterKbn.Bmn://41:部門
                return genCodeJson("BmnCode", prefix, kmkItemInfoVM);
            //42:ｾｸﾞﾒﾝﾄ 
            case MasterKbn.Segment1:
                return genCodeJson("SegCode1", prefix, kmkItemInfoVM);
            case MasterKbn.Segment2:
                return "";
            //51:工事
            case MasterKbn.Koji:
                return genCodeJson("KojCode", prefix, kmkItemInfoVM);
            case MasterKbn.Financing:
                return genCodeJson("CashCode", prefix, kmkItemInfoVM);
        }
        return null;
    }
    */

    /**
     * マスタ基本情報から採用区分を取得する
     * @param masterKbnList
     */
    public static getUseKbnList(masterKbnList: Array<number>): Array<MasterInfoCacheViewModel> {
        let result: Array<MasterInfoCacheViewModel> = new Array<MasterInfoCacheViewModel>();

        masterKbnList.forEach((val, idx, arr) => {
            let hojoItem = subItemUtil.getHojyoItemUtil(val);
            let masterInfo = hojoItem.getMasterInfo();
            result.push(masterInfo);
        });

        return result;
    }
}

/*
function genCodeJson(fname: string, prefix: string, kmkItemInfoVM: KmkItemInfoViewModel): any {
    let key = prefix + fname;
    let json = {};
    json[key] = kmkItemInfoVM.Gcode;
    return json;
}

function genSaiyoukbnAndCodeJson(fname: string, prefix: string, kmkItemInfoVM: KmkItemInfoViewModel, index: number): any {
    let codeKey = prefix + fname + "Code" + index;
    let kbnKey = prefix + fname + "Kbn" + index;
    let json = {};
    json[codeKey] = kmkItemInfoVM.Gcode;
    json[kbnKey] = kmkItemInfoVM.MasterKbn;
    return json;
}
*/

export interface hojoItemUtil {
    /** マスタinfoを返す */
    getMasterInfo(): MasterInfoCacheViewModel;

    /** 使用可能か否かを返す(true:使用可能、false:使用不可能) */
    canUse(): boolean;

    /** マスタinfoのCodeAttr.Free を返す */
    isFreeMode(): boolean;

    /** マスタinfoのCodeAttr.NumberZeroFill を返す */
    isZeroFillMode(): boolean;

    /** マスタinfoのCodeAttr.Number を返す */
    isNumberMode(): boolean;

    /**
     * 選択Window等に表示する為のコード文字列を作成する
     * @param orgCodeString
     */
    createCodeString(orgCodeString: string): string;

    /** コード文字列の長さを取得する */
    getCodeLength(): number;

    /** マスタinfoに設定されている、補助名を取得する */
    getDisplayName(): string;
}

/**
 * マスタ区分を使用して判定する項目のベースクラス
 */
abstract class hojoMasterInfo implements hojoItemUtil {
    protected masterInfo!: MasterInfoCacheViewModel;
    /**
     * コンストラクタ
     * @param masterInfoList
     * @remark: 情報がない場合は、MasterInfo情報を持たないインスタンスが作成される。
     * そのため、MasterInfoがない場合は、デフォルト形式(記帳君では確認できず、なので特殊仕様になります)
     * で値を返すオブジェクトとなる。
     */
    constructor(masterInfoList: Array<MasterInfoCacheViewModel>) {
        $.each(masterInfoList, (index: number, value: MasterInfoCacheViewModel) => {
            if (value.MasterKbn == this.getMasterKbn()) {
                this.masterInfo = value;
                return false;
            }
            return true;
        });
    }
    protected abstract getMasterKbn(): number;
    public getMasterInfo(): MasterInfoCacheViewModel {
        return this.masterInfo ?
            this.masterInfo :
            null!;
    }
    public canUse(): boolean {
        return this.masterInfo ?
            (this.masterInfo.UseKbn != 0) :
            false;
    }
    public isFreeMode(): boolean {
        return this.masterInfo ?
            (this.masterInfo.CodeAttr == CodeAttr.Free) :
            false;
    }
    public isZeroFillMode(): boolean {
        return this.masterInfo ?
            (this.masterInfo.CodeAttr == CodeAttr.NumberZeroFill) :
            false;
    }
    public isNumberMode(): boolean {
        return this.masterInfo ?
            (this.masterInfo.CodeAttr == CodeAttr.Number) :
            false;
    }
    /**
     * 選択Window等に表示するようのコード文字列を作成する
     * @param orgCodeString
     */
    public createCodeString(orgCodeString: string): string {
        if (this.masterInfo) {
            if (this.isNumberMode()) {
                if (orgCodeString) {
                    return Number(orgCodeString).toString();
                } else {
                    return "";
                }
            } else if (this.isZeroFillMode()) {
                return MjsUtilities.createZeroFillString(orgCodeString, this.masterInfo.CodeDigit);
            }
            return orgCodeString;
        }
        return orgCodeString;
    }
    /**
     * コードの文字列長さを取得する
     */
    public getCodeLength(): number {
        return this.masterInfo.CodeDigit;
    }
    /**
     * マスタinfoに設定されている、補助名を取得する
     */
    public getDisplayName() {
        if (this.masterInfo && this.masterInfo.JHojyoName) {
            return this.masterInfo.JHojyoName;
        }
        return ""
    }
}

/**
 * 本科目に関する使用可否等の情報を取得するクラス
 */
class kmkMaInfo extends hojoMasterInfo {
    protected getMasterKbn(): number {
        return MasterKbn.CountingKmk;
    }
    public getDisplayName() {
        return "科目";
    }
}

/**
 * 銀行に関する使用可否等の情報を取得するクラス
 */
class kmkMaSubInfo extends hojoMasterInfo {
    protected getMasterKbn(): number {
        return MasterKbn.EachKmkAuxiliary;
    }
    public getDisplayName() {
        return "科目別補助";
    }
}

/**
 * 銀行に関する使用可否等の情報を取得するクラス
 */
class bankInfo extends hojoMasterInfo {
    protected getMasterKbn(): number {
        return MasterKbn.Bank;
    }
}

/**
* 取引先に関する使用可否等の情報を取得するクラス
*/
class clientInfo extends hojoMasterInfo {
    protected getMasterKbn(): number {
        return MasterKbn.Client;
    }
}

/**
 * 社員に関する使用可否等の情報を取得するクラス
 */
class staffInfo extends hojoMasterInfo {
    protected getMasterKbn(): number {
        return MasterKbn.Staff;
    }
}

/**
 * 工事に関する使用可否等の情報を取得するクラス
 */
class kojiInfo extends hojoMasterInfo {
    protected getMasterKbn(): number {
        return MasterKbn.Koji;
    }
}

/**
 * 部門に関する使用可否等の情報を取得するクラス
 */
class bmnInfo extends hojoMasterInfo {
    protected getMasterKbn(): number {
        return MasterKbn.Bmn;
    }
}

/**
 * セグメント1に関する使用可否等の情報を取得するクラス
 */
class segInfo1 extends hojoMasterInfo {
    protected getMasterKbn(): number {
        return MasterKbn.Segment1;
    }
}

/**
 * セグメント2に関する使用可否等の情報を取得するクラス
 */
class segInfo2 extends hojoMasterInfo {
    protected getMasterKbn(): number {
        return MasterKbn.Segment2;
    }
}

/**
 * セグメント2に関する使用可否等の情報を取得するクラス
 */
class segInfo3 extends hojoMasterInfo {
    protected getMasterKbn(): number {
        return MasterKbn.Segment3;
    }
}

/**
 * セグメント2に関する使用可否等の情報を取得するクラス
 */
class segInfo4 extends hojoMasterInfo {
    protected getMasterKbn(): number {
        return MasterKbn.Segment4;
    }
}

/**
 * セグメント2に関する使用可否等の情報を取得するクラス
 */
class segInfo5 extends hojoMasterInfo {
    protected getMasterKbn(): number {
        return MasterKbn.Segment5;
    }
}

/**
 * 汎用補助1に関する使用可否等の情報を取得するクラス
 */
class GenHojoInfo1 extends hojoMasterInfo {
    protected getMasterKbn(): number {
        return MasterKbn.GeneralPurposeAuxiliary1;
    }
}

/**
 * 汎用補助2に関する使用可否等の情報を取得するクラス
 */
class GenHojoInfo2 extends hojoMasterInfo {
    protected getMasterKbn(): number {
        return MasterKbn.GeneralPurposeAuxiliary2;
    }
}

/**
 * 汎用補助3に関する使用可否等の情報を取得するクラス
 */
class GenHojoInfo3 extends hojoMasterInfo {
    protected getMasterKbn(): number {
        return MasterKbn.GeneralPurposeAuxiliary3;
    }
}

/**
 * 汎用補助4に関する使用可否等の情報を取得するクラス
 */
class GenHojoInfo4 extends hojoMasterInfo {
    protected getMasterKbn(): number {
        return MasterKbn.GeneralPurposeAuxiliary4;
    }
}

/**
 * 汎用補助5に関する使用可否等の情報を取得するクラス
 */
class GenHojoInfo5 extends hojoMasterInfo {
    protected getMasterKbn(): number {
        return MasterKbn.GeneralPurposeAuxiliary5;
    }
}

/**
 * 汎用補助5に関する使用可否等の情報を取得するクラス
 */
class CashInfo extends hojoMasterInfo {
    protected getMasterKbn(): number {
        return MasterKbn.Financing;
    }
}

/**
 * 証憑NOに関する使用可否等の情報を取得するクラス
 */
class ShohyoNoInfo implements hojoItemUtil {
    private shohyoNoKbnInfo: KbnInfoCacheViewModel;
    constructor(swkControlKbnInfo: KbnInfoCacheViewModel) {
        this.shohyoNoKbnInfo = swkControlKbnInfo;
    }
    /**
     * Kbninfoでの判断となるため、MasterInfoはダミーとする。
     */
    public getMasterInfo(): MasterInfoCacheViewModel {
        return null!;
    }

    /**
     *  KbnInfoでの判断となるため、KbnInfoを返す口を作成
     */
    public getKbnInfo(): KbnInfoCacheViewModel {
        return this.shohyoNoKbnInfo;
    }
    /**
     * 証憑NOが使用可能かどうかを判定する。
     * @param shohyoNoKbnInfo
     * 仕訳入力条件区分情報マスタの証憑NO入力区分が入力なし（KbnInfo(RecKbn=2).GnPuKbn13=9）なら入力を認めない。
     */
    public canUse(): boolean {
        return !(this.shohyoNoKbnInfo && this.shohyoNoKbnInfo.GnPuKbn13 == 9);// 定数なし
    }
    public isFreeMode(): boolean {
        return (this.shohyoNoKbnInfo && this.shohyoNoKbnInfo.GnPuKbn14 == EvidenceNoType.FreeLeftJustification);
    }
    public isZeroFillMode(): boolean {
        return false;
    }
    public isNumberMode(): boolean {
        return (this.shohyoNoKbnInfo && this.shohyoNoKbnInfo.GnPuKbn14 == EvidenceNoType.Number);
    }
    public getCodeLength(): number {
        return 8;// 証憑NOは数値/フリーどちらも8桁固定
    }

    /**
     * 選択Window等に表示するようのコード文字列を作成する
     * @param orgCodeString
     */
    public createCodeString(orgCodeString: string): string {
        if (this.shohyoNoKbnInfo) {
            if (this.isNumberMode()) {
                if (orgCodeString) {
                    return Number(orgCodeString).toString();
                } else {
                    return "";
                }
            } else if (this.isZeroFillMode()) {
                return MjsUtilities.createZeroFillString(orgCodeString, this.getCodeLength());
            }
            return orgCodeString;
        }
        return orgCodeString;
    }

    /**
     * 証憑NOの場合は固定値となる。
     */
    public getDisplayName(): string {
        return "証憑NO";
    }

    /**
     * 数値になっているCode値を前ゼロありの値に変換する
     * 値なしの時はNullとなる。
     * @param val
     */
    public createDBCodeValue(val: string) {
        // 数値型、前0ありがたは、前0ありの文字列としてDBに登録
        if (!this.isFreeMode()) {
            let length: number = this.getCodeLength() * -1;
            if (val) {
                // 最大8桁の0埋め数値を返す。
                return ("00000000" + val).slice(length);
            } else {
                return "";
            }
        }

        // Freeの時はそのまま
        return val;
    }
}

/**
 * 伝票NOに関する使用可否等の情報を取得するクラス
 */
class DenNoInfo implements hojoItemUtil {
    private denNoKbnInfo: KbnInfoCacheViewModel;
    constructor(swkControlKbnInfo: KbnInfoCacheViewModel) {
        this.denNoKbnInfo = swkControlKbnInfo;
    }

    /**
     * Kbninfoでの判断となるため、MasterInfoはダミーとする。
     */
    public getMasterInfo(): MasterInfoCacheViewModel {
        return null!;
    }

    /**
     *  KbnInfoでの判断となるため、KbnInfoを返す口を作成
     */
    public getKbnInfo(): KbnInfoCacheViewModel {
        return this.denNoKbnInfo;
    }

    public isBalanceCheckEntryJournal(): boolean {
        return this.denNoKbnInfo && !(this.denNoKbnInfo.GnPuKbn9 === 1);
    }

    public canUse(): boolean {
        return this.denNoKbnInfo && !(this.denNoKbnInfo.GnPuKbn11 === 9);
    }

    /**
     * 伝票NO入力区分(伝票型入力)=自動付番(前回+1)であればtrueを返す【振替伝票・入金伝票・出金伝票】
     */
    public isAutoCreateDenNoVoucher(): boolean {
        return this.denNoKbnInfo && this.denNoKbnInfo.GnPuKbn12 == 2; // 2：自動付番（前回 + 1） todo 定数定義待ち
    }
    /**
     * 伝票NO入力区分(伝票型入力)=システム自動付番であればtrueを返す【振替伝票・入金伝票・出金伝票】
     */
    public isAutoCreateDenNoVoucherAtServer(): boolean {
        return this.denNoKbnInfo && this.denNoKbnInfo.GnPuKbn12 == 1; // 1：システム自動付番 todo 定数定義待ち
    }
    /**
     * 伝票NOシステム自動付番方法=通年型であればtrueを返す
     */
    public isAutoCreateDenNoVoucherAnnual(): boolean {
        return this.denNoKbnInfo && this.denNoKbnInfo.GnPuKbn18 == 2; // 2：通年型 todo 定数定義待ち
    }
    /**
     * 伝票NOシステム自動付番方法=月次型であればtrueを返す
     */
    public isAutoCreateDenNoVoucherMonthly(): boolean {
        return this.denNoKbnInfo && this.denNoKbnInfo.GnPuKbn18 == 1; // 1：月次型 todo 定数定義待ち
    }
    /**
     * 伝票NO自動付番(仕訳帳、出納帳、元帳)
     */
    public isAutoCreateDenNo(): boolean {
        return this.denNoKbnInfo && this.denNoKbnInfo.GnPuKbn11 == 1;// 定数なし
    }
    public isFreeMode(): boolean {
        return false;
    }
    public isZeroFillMode(): boolean {
        return false;
    }
    public isNumberMode(): boolean {
        return true;
    }
    public getCodeLength(): number {
        return 8;// 伝票NOは数値/フリーどちらも8桁固定
    }

    /**
     * 選択Window等に表示するようのコード文字列を作成する
     * @param orgCodeString
     */
    public createCodeString(orgCodeString: string): string {
        if (this.denNoKbnInfo) {
            if (this.isNumberMode()) {
                if (orgCodeString) {
                    return Number(orgCodeString).toString();
                } else {
                    return "";
                }
            } else if (this.isZeroFillMode()) {
                return MjsUtilities.createZeroFillString(orgCodeString, this.getCodeLength());
            }
            return orgCodeString;
        }
        return orgCodeString;
    }

    /**
     * 証憑NOの場合は固定値となる。
     */
    public getDisplayName(): string {
        return "伝票NO";
    }

    /**
     * 数値になっているCode値を前ゼロありの値に変換する
     * 値なしの時はNullとなる。
     * @param val
     */
    public createDBCodeValue(val: number) {
        let length: number = this.getCodeLength() * -1;
        if (val) {
            // 最大8桁の0埋め数値を返す。
            return ("00000000" + val).slice(length);
        } else {
            return "";
        }
    }
}

/**
 * 期日に関する使用可否等の情報を取得するクラス
 */
class LimitDateInfo implements hojoItemUtil {
    private limitDateInfo: KbnInfoCacheViewModel;
    constructor(partitionKbnInfo: KbnInfoCacheViewModel) {
        this.limitDateInfo = partitionKbnInfo;
    }

    /**
     * Kbninfoでの判断となるため、MasterInfoはダミーとする。
     */
    public getMasterInfo(): MasterInfoCacheViewModel {
        return null!;
    }

    /**
     *  KbnInfoでの判断となるため、KbnInfoを返す口を作成
     */
    public getKbnInfo(): KbnInfoCacheViewModel {
        return this.limitDateInfo;
    }

    public canUse(): boolean {
        return this.limitDateInfo && (this.limitDateInfo.GnPuKbn1 != 0);
    }

    public isFreeMode(): boolean {
        return false;
    }
    public isZeroFillMode(): boolean {
        return false;
    }
    public isNumberMode(): boolean {
        return true;
    }
    public getCodeLength(): number {
        return 8;// 期日は数値/フリーどちらも8桁固定
    }

    /**
     * 選択Window等に表示するようのコード文字列を作成する
     * @param orgCodeString
     */
    public createCodeString(orgCodeString: string): string {
        if (this.limitDateInfo) {
            if (this.isNumberMode()) {
                if (orgCodeString) {
                    return Number(orgCodeString).toString();
                } else {
                    return "";
                }
            } else if (this.isZeroFillMode()) {
                return MjsUtilities.createZeroFillString(orgCodeString, this.getCodeLength());
            }
            return orgCodeString;
        }
        return orgCodeString;
    }

    /**
     * 期日の場合は固定値となる。
     */
    public getDisplayName(): string {
        return "期日";
    }
}

class FixedTekiyoInfo implements hojoItemUtil {
    private tekiyoInfo: KbnInfoCacheViewModel;
    constructor(swkControlKbnInfo: KbnInfoCacheViewModel) {
        this.tekiyoInfo = swkControlKbnInfo;
    }

    /**
     * Kbninfoでの判断となるため、MasterInfoはダミーとする。
     */
    public getMasterInfo(): MasterInfoCacheViewModel {
        return null!;
    }

    /**
     *  KbnInfoでの判断となるため、KbnInfoを返す口を作成
     */
    public getKbnInfo(): KbnInfoCacheViewModel {
        return this.tekiyoInfo;
    }

    public canUse(): boolean {
        // 摘要は常に使用可能
        return true;
    }

    /**
     * 摘要先行入力モードかどうかを返す。
     * true: 摘要先行入力モード false： 通常モード
     */
    public isTekiyoPrecedingModeForEntryJournal(): boolean {
        // 摘要先行入力時（KbnInfo(MasterKbn=2).GnPuKbn6=1、KbnInfo(MasterKbn=2).GnPuKbn7=1）
        //TODO:２次機能とする(摘要先行モードは１次ではやらないため、)
        //if (this.tekiyoInfo.GnPuKbn6 === UseKbn.Setting) {
        //    return true;
        //}
        //return false;

        return false;
    }
    /**
     * 摘要先行入力モードかどうかを返す。
     * true: 摘要先行入力モード false： 通常モード
     */
    public isTekiyoPrecedingModeForEntryCashJournal(): boolean {
        // 摘要先行入力時（KbnInfo(MasterKbn=2).GnPuKbn6=1、KbnInfo(MasterKbn=2).GnPuKbn7=1）
        //TODO:２次機能とする(摘要先行モードは１次ではやらないため、)
        //if (this.tekiyoInfo.GnPuKbn7 === UseKbn.Setting) {
        //    return true;
        //}
        //return false;

        return false;
    }

    /**
     * 元帳の適用先行入力モードかどうかを返す。
     * true: 摘要先行入力モード false： 通常モード
     * ※ 元帳は仕訳帳と同じ判断を行うため、仕訳帳のAPIを使用した結果を返す。
     * 今後、どうなるか？があるので、一応結果を返す口は仕訳帳/元帳で分けておく。
     */
    public isTekiyoPrecedingModeForValidateLedger(): boolean {
        //TODO:２次機能とする(摘要先行モードは１次ではやらないため、)
        //return this.isTekiyoPrecedingModeForEntryJournal();

        return false;
    }

    public isFreeMode(): boolean {
        return false;
    }
    public isZeroFillMode(): boolean {
        return false;
    }
    public isNumberMode(): boolean {
        // 摘要コードは数値のみ
        return true;
    }
    public getCodeLength(): number {
        return 6;// 摘要コードのMaxは固定6桁
    }
    /**
     * 選択Window等に表示するようのコード文字列を作成する
     * @param orgCodeString
     */
    public createCodeString(orgCodeString: string): string {
        if (this.tekiyoInfo) {
            if (this.isNumberMode()) {
                if (orgCodeString) {
                    return Number(orgCodeString).toString();
                } else {
                    return "";
                }
            } else if (this.isZeroFillMode()) {
                return MjsUtilities.createZeroFillString(orgCodeString, this.getCodeLength());
            }
            return orgCodeString;
        }
        return orgCodeString;
    }

    /**
     * 期日の場合は固定値となる。
     */
    public getDisplayName(): string {
        return "摘要";
    }
}

/** 定型仕訳採用区分（伝票型入力） */
class FixedVoucher implements hojoItemUtil {
    private kbnInfo: KbnInfoCacheViewModel;
    constructor(kbnInfoCacheViewModel: KbnInfoCacheViewModel) {
        this.kbnInfo = kbnInfoCacheViewModel;
    }

    /**
     * Kbninfoでの判断となるため、MasterInfoはダミーとする。
     */
    public getMasterInfo(): MasterInfoCacheViewModel {
        return null!;
    }

    /**
     *  KbnInfoでの判断となるため、KbnInfoを返す口を作成
     */
    public getKbnInfo(): KbnInfoCacheViewModel {
        return this.kbnInfo;
    }

    public canUse(): boolean {
        return (this.kbnInfo) && (this.kbnInfo.GnPuKbn29 != 0);
    }

    public isFreeMode(): boolean {
        return false;
    }

    public isZeroFillMode(): boolean {
        return false;
    }

    public isNumberMode(): boolean {
        // 定型NOは数値のみ
        return true;
    }

    public getCodeLength(): number {
        return 4;// 定型NOのMaxは固定4桁
    }

    public createCodeString(orgCodeString: string): string {
        if (this.kbnInfo) {
            if (this.isNumberMode()) {
                if (orgCodeString) {
                    return Number(orgCodeString).toString();
                } else {
                    return "";
                }
            } else if (this.isZeroFillMode()) {
                return MjsUtilities.createZeroFillString(orgCodeString, this.getCodeLength());
            }
            return orgCodeString;
        }
        return orgCodeString;
    }

    public getDisplayName(): string {
        return "定型仕訳";
    }
}

/** 定型仕訳採用区分（仕訳帳） */
class FixedJournal implements hojoItemUtil {
    private kbnInfo: KbnInfoCacheViewModel;
    constructor(kbnInfoCacheViewModel: KbnInfoCacheViewModel) {
        this.kbnInfo = kbnInfoCacheViewModel;
    }

    /**
     * Kbninfoでの判断となるため、MasterInfoはダミーとする。
     */
    public getMasterInfo(): MasterInfoCacheViewModel {
        return null!;
    }

    /**
     *  KbnInfoでの判断となるため、KbnInfoを返す口を作成
     */
    public getKbnInfo(): KbnInfoCacheViewModel {
        return this.kbnInfo;
    }

    public canUse(): boolean {
        return (this.kbnInfo) && (this.kbnInfo.GnPuKbn20 != 0);
    }

    public isFreeMode(): boolean {
        return false;
    }

    public isZeroFillMode(): boolean {
        return false;
    }

    public isNumberMode(): boolean {
        // 定型NOは数値のみ
        return true;
    }

    public getCodeLength(): number {
        return 4;// 定型NOのMaxは固定4桁
    }

    public createCodeString(orgCodeString: string): string {
        if (this.kbnInfo) {
            if (this.isNumberMode()) {
                if (orgCodeString) {
                    return Number(orgCodeString).toString();
                } else {
                    return "";
                }
            } else if (this.isZeroFillMode()) {
                return MjsUtilities.createZeroFillString(orgCodeString, this.getCodeLength());
            }
            return orgCodeString;
        }
        return orgCodeString;
    }

    public getDisplayName(): string {
        return "定型仕訳";
    }
}

/** 定型仕訳採用区分（仕訳帳） */
class FixedCashJournal implements hojoItemUtil {
    private kbnInfo: KbnInfoCacheViewModel;
    constructor(kbnInfoCacheViewModel: KbnInfoCacheViewModel) {
        this.kbnInfo = kbnInfoCacheViewModel;
    }

    /**
     * Kbninfoでの判断となるため、MasterInfoはダミーとする。
     */
    public getMasterInfo(): MasterInfoCacheViewModel {
        return null!;
    }

    /**
     *  KbnInfoでの判断となるため、KbnInfoを返す口を作成
     */
    public getKbnInfo(): KbnInfoCacheViewModel {
        return this.kbnInfo;
    }

    public canUse(): boolean {
        return (this.kbnInfo) && (this.kbnInfo.GnPuKbn21 != 0);
    }

    public isFreeMode(): boolean {
        return false;
    }

    public isZeroFillMode(): boolean {
        return false;
    }

    public isNumberMode(): boolean {
        // 定型NOは数値のみ
        return true;
    }

    public getCodeLength(): number {
        return 4;// 定型NOのMaxは固定4桁
    }

    public createCodeString(orgCodeString: string): string {
        if (this.kbnInfo) {
            if (this.isNumberMode()) {
                if (orgCodeString) {
                    return Number(orgCodeString).toString();
                } else {
                    return "";
                }
            } else if (this.isZeroFillMode()) {
                return MjsUtilities.createZeroFillString(orgCodeString, this.getCodeLength());
            }
            return orgCodeString;
        }
        return orgCodeString;
    }

    public getDisplayName(): string {
        return "定型仕訳";
    }
}

/**
 * KbnInfoから取得可能な消費税情報を取得する
 */
class TaxInputInfo {
    private taxInputInfo: KbnInfoCacheViewModel;
    constructor(swkControlKbnInfo: KbnInfoCacheViewModel) {
        this.taxInputInfo = swkControlKbnInfo;
    }

    /**
     *  KbnInfoでの判断となるため、KbnInfoを返す口を作成
     */
    public getKbnInfo(): KbnInfoCacheViewModel {
        return this.taxInputInfo;
    }

    /**
     * 外税同時入力の可否を返す。
     * true: 同時入力時
     * false: 別々入力時
     */
    public isExcludedInput(): boolean {
        return this.taxInputInfo.GnPuKbn8 == ExcludedInputKbn.Simultaneous;
    }
}

/**
 * 摘要自動入力を行うかの判別
 */
class TekiyoAutoInputInfo {
    private taxInputInfo: KbnInfoCacheViewModel;

    constructor(partitionKbnInfo: KbnInfoCacheViewModel) {
        this.taxInputInfo = partitionKbnInfo;
    }

    public isHojyoAutoInput(): boolean {
        // 定数追加してくださいｗ
        return this.taxInputInfo.GnPuKbn26 == 1;
    }

    public isKojiAutoInput(): boolean {
        // 定数追加してください
        return this.taxInputInfo.GnPuKbn27 == 1;
    }

    public isBummonAutoInput(): boolean {
        // 定数追加してくださいｗ
        return this.taxInputInfo.GnPuKbn28 == 1;
    }
}

/**
 * KbnInfoから取得可能な資金複合/複合の情報を取得する
 */
class ComplexInfo {
    private complexInfo: KbnInfoCacheViewModel;
    constructor(swkControlKbnInfo: KbnInfoCacheViewModel) {
        this.complexInfo = swkControlKbnInfo;
    }

    /**
     *  KbnInfoでの判断となるため、KbnInfoを返す口を作成
     */
    public getKbnInfo(): KbnInfoCacheViewModel {
        return this.complexInfo;
    }

    /**
     * 外税同時入力の可否を返す。
     * true: 同時入力時
     * false: 別々入力時
     */
    public isSetFundComplex(): boolean {
        return this.complexInfo.GnPuKbn36 == DenKbn.FundComplex;
    }
}

/**
 * KbnInfoから取得可能な公益法人関係の情報を取得する
 */
class CharitableInfo {
    private charitableInfo: KbnInfoCacheViewModel;
    constructor(partitionKbnInfo: KbnInfoCacheViewModel) {
        this.charitableInfo = partitionKbnInfo;
    }

    /**
     *  KbnInfoでの判断となるため、KbnInfoを返す口を作成
     */
    public getKbnInfo(): KbnInfoCacheViewModel {
        return this.charitableInfo;;
    }
}