import {CompanyDataListVO} from "./companyDataListVO";
/**
 * 会社データ一覧ViewModel ViewModel クラス
 */

export class CompanyDataListViewModel {
    /** 会社データ一覧VO */
    public CompanyDataList?:Array<CompanyDataListVO>;

    /** エラーメッセージ */
    public ErrorMessage?:string;

}
