import { MjsUtilities } from "./utilities";
import "./utilities";

/**
 * ログ出力クラス
 */
export class Logger {
    /** ログ出力レベル:Debug */
    public static DEBUG: number = 10

    /** ログ出力レベル:Info */
    public static INFO: number = 20

    /** ログ出力レベル:Warn */
    public static WARN: number = 30

    /** ログ出力レベル:Error */
    public static ERROR: number = 40

    /** 現在のログ出力レベル(Debug < Info < Warn < Error) */
    public static currentLogLevel: number = Logger.ERROR;

    /** debugTimerStart() debugTimerStop() debugTimeStamp()でのログ出力に使用する接頭辞 */
    protected static prefix: string = "";

    /**
     * ログ出力レベルを変更する。
     * @param level 新たに設定するログ出力レベル(Debug < Info < Warn < Error)
     */
    public static levelChange(level: number): Logger {
        this.currentLogLevel = level.valueOf();
        return this;
    }

    /**
     * Errorログを出力する。
     * @param message ログ出力するメッセージ
     * @param params ログ出力するメッセージ
     */
    public static error(message?: any, ...params: any[]): void {
        if (this.isErrorLevel()) {
            console.error(message, params.toString());
        }
    }

    /**
     * Warnログを出力する。
     * @param message ログ出力するメッセージ
     * @param params ログ出力するメッセージ
     */
    public static warn(message?: any, ...params: any[]): void {
        if (this.isWarnLevel()) {
            console.warn(message, params.toString());
        }
    }

    /**
     * Infoログを出力する。
     * @param message ログ出力するメッセージ
     * @param params ログ出力するメッセージ
     */
    public static info(message?: any, ...params: any[]): void {
        if (this.isInfoLevel()) {
            console.info(message, params.toString());
        }
    }

    /**
     * Debugログを出力する。
     * @param message ログ出力するメッセージ
     * @param params ログ出力するメッセージ
     */
    public static debug(message?: any, ...params: any[]): void {
        if (this.isDebugLevel()) {
            console.log(message, params.toString());
        }
    }

    /**
     * Debugログを出力可能な場合Trueを返す。
     */
    public static isDebugLevel(): boolean {
        return Logger.DEBUG >= this.currentLogLevel;
    }

    /**
     * Infoログを出力可能な場合Trueを返す。
     */
    public static isInfoLevel(): boolean {
        return Logger.INFO >= this.currentLogLevel;
    }

    /**
     * Warnログを出力可能な場合Trueを返す。
     */
    public static isWarnLevel(): boolean {
        return Logger.WARN >= this.currentLogLevel;
    }

    /**
     * Errorログを出力可能な場合Trueを返す。
     */
    public static isErrorLevel(): boolean {
        return Logger.ERROR >= this.currentLogLevel;
    }

    /**
     * debugTimerStart() debugTimerStop() debugTimeStamp()でのログ出力に使用する接頭辞を取得する。
     */
    public static getPrefix(): string {
        return Logger.prefix;
    }

    /**
     * debugTimerStart() debugTimerStop() debugTimeStamp()でのログ出力に使用する接頭辞を設定する。
     * @param newPrefix 新たに設定する接頭辞
     */
    public static setPrefix(newPrefix: string): void {
        if (MjsUtilities.isNull(newPrefix)) {
            newPrefix = "";
        }
        Logger.prefix = newPrefix;
    }

    /**
     * 処理時間計測用のタイマーを開始する。(タイマー開始時刻をDebugログ出力する。)
     * @param timerName 開始するタイマー名
     */
    public static debugTimerStart(timerName?: string): void {
        if (Logger.isDebugLevel()) {
            console.log(Logger.prefix + "Timer Start " + timerName + "\t" + Logger.getCurrentTimeStamp());
            console.time(Logger.prefix + timerName);
        }
    }

    /**
     * 処理時間計測用のタイマーを停止し、処理時間をDebugログ出力する。(タイマー停止時刻もDebugログ出力する。)
     * @param timerName 停止するタイマー名
     */
    public static debugTimerStop(timerName?: string): void {
        if (Logger.isDebugLevel()) {
            console.timeEnd(Logger.prefix + timerName);
            console.log(Logger.prefix + "Timer Stop " + timerName + "\t" + Logger.getCurrentTimeStamp());
        }
    }

    /**
     * 現在日付時刻付きでDebugログ出力する。
     * @param message ログ出力するメッセージ
     */
    public static debugTimeStamp(message: string): void {
        if (Logger.isDebugLevel()) {
            console.log(Logger.prefix + message + "\t" + Logger.getCurrentTimeStamp());
        }
    }

    /**
     * 現在日付時刻を返す。(yyyy/mm/dd hh:mm:ss.zzzフォーマット)
     */
    protected static getCurrentTimeStamp(): string {
        let nw = new Date();
        let timeStamp: string = "";
        timeStamp = nw.getFullYear().padLeft(4, '0') + "/" + (nw.getMonth() + 1).padLeft(2, '0') + "/" + nw.getDate().padLeft(2, '0') + " ";
        timeStamp = timeStamp + nw.getHours().padLeft(2, '0') + ":" + nw.getMinutes().padLeft(2, '0') + ":" + nw.getSeconds().padLeft(2, '0') + "." + nw.getMilliseconds().padLeft(3, '0');
        return timeStamp;
    }
}
