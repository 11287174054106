import React from 'react';
import classnames from 'classnames';

const UpperActionLayout: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
  <div {...props} className={classnames(props.className, 'upper-action-layout')}>
    {props.children}
  </div>
);

const UpperActionLayoutHeader: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
  <div {...props} className={classnames(props.className, '-header')}>
    {props.children}
  </div>
);

const UpperActionLayoutAction: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
  <div {...props} className={classnames(props.className, '-action')}>
    {props.children}
  </div>
);

const UpperActionLayoutContents: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
  <div {...props} className={classnames(props.className, '-content')}>
    {props.children}
  </div>
);

export { UpperActionLayout, UpperActionLayoutHeader, UpperActionLayoutContents, UpperActionLayoutAction };
