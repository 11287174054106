import React from 'react';

import classnames from 'classnames';
import Button from 'reactstrap/lib/Button';
import IconButton from '../CMN/IconButton/IconButton';
import Trash from '../../atoms/Icon/Trash';
import InputTextArea from '../../atoms/InputTextArea/InputTextArea';
import * as labels from '../../../constants/labels';
import { ChecklistNoticeVO } from '../../../models/checklistNoticeVO';

type Notice = ChecklistNoticeVO & {
  isdelete?: boolean;
};

export type noticeProps = React.HTMLAttributes<HTMLDivElement> & {
  notices?: Notice[];
  onChangeItem?: (notices: Notice[]) => void;
};

const Notice: React.FC<noticeProps> = props => {
  const { onChangeItem, className } = props;
  const [notices, setNotices] = React.useState(props.notices);

  React.useEffect(() => {
    setNotices(props.notices);
  }, [props.notices]);

  const handleAddClick = () => {
    notices!.push({
      No: notices!.length + 1,
      NoticeNo: notices!.length + 1,
      Notice: ''
    });
    setNotices(notices!.slice());
    onChangeItem!(notices!);
  };

  const handleNoticeBlur = (e: React.ChangeEvent<HTMLTextAreaElement>, data: Notice) => {
    const index = notices!.findIndex(item => item === data);
    notices![index].Notice = e.target.value;
    setNotices(notices!.slice());
    onChangeItem!(notices!);
  };

  const handleTrashClick = (data: Notice) => {
    const index = notices!.findIndex(item => item === data);
    notices![index].isdelete = true;
    setNotices(notices!.slice());
    onChangeItem!(notices!);
  };

  const noticeList: React.ReactElement[] = [];
  if (notices) {
    notices.forEach(data => {
      const isdelete = data.isdelete === true ? 'd-none' : '';
      noticeList.push(
        <div key={data.No} className={classnames(isdelete, 'mt-2')}>
          <div className='notices-lable text-lbl'>
            <b>
              {labels.AIKADT002000005_INPUT_HEADER_NOTICE}（{data.No}）
            </b>
          </div>
          <div className='d-flex justify-content-between'>
            <InputTextArea
              name='notices'
              enable={{
                top: false,
                right: false,
                bottom: true,
                left: false,
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false
              }}
              defaultSize={{ width: '100%' }}
              defaultValue={data.Notice}
              className='notices-rextarea'
              onBlur={e => handleNoticeBlur(e, data)}
            />
            <IconButton icon={<Trash />} onClick={() => handleTrashClick(data)} className='ml-2' />
          </div>
        </div>
      );
    });
  }

  // divタグに不要なpropsを除去
  const divProps = { ...props };
  delete divProps['notices'];
  delete divProps['onChangeItem'];
  return (
    <div {...divProps} className={classnames(className, 'notices-data')}>
      <div>
        <Button className='A3-btn' color='primary' onClick={handleAddClick}>
          {labels.AIKADT002000005_BUTTON_FUNCTION_NOTICEADDITION}
        </Button>
      </div>
      <div className='notices-list overflow-auto'>{noticeList}</div>
    </div>
  );
};
export default Notice;
