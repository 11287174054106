import { KeyValueData, RensouData } from "../../common/usefuls/utilities";
import { AnalyzeCode, MasterKbn, MasterSumKbn, NonDspKbn } from "../../core/constants/constant";
import { DataModelBase } from "../../core/models/dataModelBase";

/**
 * マスターデータ ViewModel クラス
 */
export class KmkMACacheViewModel extends DataModelBase implements KeyValueData, RensouData {
    // interface:KeyValueDataの実装
    public get key(): string {
        return this.code;
    }
    public get value(): string { return this.SimpleName; }

    // interface:RensouDataの実装
    public get code(): string {
        return this.MasterKbn === MasterKbn.CountingKmk ?
            (this.GCode != undefined && this.GCode != null) ? this.GCode.toString() : null! :
            (this.SubCode != undefined && this.SubCode != null) ? this.SubCode.toString() : null!;
    }
    public get masterKbn(): number { return this.MasterKbn; }
    public get displayValue(): string { return this.SimpleName; }
    public get subNameValue(): string { return this.LongName; }
    public get rensou(): string { return this.RenChar; }
    public get simpleFlag(): boolean { return true; }
    public get detailFlag(): boolean { return true; }
    private _displayFlag!: boolean;
    public set displayFlag(val: boolean) { this._displayFlag = val; }
    public get displayFlag(): boolean { return this._displayFlag; }

    /*------------------------------*
                独自の関数群
        *------------------------------*/

    /**
     * 実在科目の判定 [true: 実在科目, false: それ以外]
     */
    public get isRealKmk(): boolean {
        return this.SumKbn == MasterSumKbn.Real;
    }

    /**
     * 合計科目の判定 [true: 合計科目, false: それ以外]
     */
    public get isTotalKmk(): boolean {
        return this.SumKbn == MasterSumKbn.Total;
    }

    /**
     * 表示／印刷不可区分の判定 [true: 不可, false: 可]
     */
    public get isImproperKmk(): boolean {
        return this.NonDspKbn == NonDspKbn.Improper;
    }

    /**
     * 非会計科目かどうかの判定 [true:非合計科目 false: それ以外]
     */
    public get isNonAccountingCourses(): boolean {
        return this.AnalyzeCode == AnalyzeCode.NonAccountingKmk;
    }

    /*------------------------------*
                項目群
        *------------------------------*/
    /** マスタ区分 */
    public MasterKbn!: number;

    /** 実在／合計区分 */
    public SumKbn!: number;

    /** レコード区分 */
    public RecordKbn!: number;

    /** 外部コード */
    public GCode!: number;

    /** 科目別補助コード */
    public SubCode!: number;

    /** 連想シンボル */
    public RenChar!: string;

    /** 正式名称 */
    public LongName!: string;

    /** 簡略名称 */
    public SimpleName!: string;

    /** 内部コード（ユニークKey） */
    public NCode!: number;

    /** 更新日時 */
    public UpdateTime!: Date;

    /** 適用開始年月日 */
    public StartDate!: Date;

    /** 適用終了年月日 */
    public EndDate!: Date;

    /** 分析コード */
    public AnalyzeCode!: number;

    /** 要約分析コード */
    public SumAnaCode!: number;

    /** 性格コード */
    public CharCode!: number;

    /**  */
    public ECharCode1!: number;

    /**  */
    public ECharCode2!: number;

    /**  */
    public ECharCode3!: number;

    /** 正残区分 */
    public DCKbn!: number;

    /** ＢＳ／ＰＬ区分 */
    public BPKbn!: number;

    /** 期首残区分 */
    public KisyuKbn!: number;

    /** 表示／印刷不可区分 */
    public NonDspKbn!: number;

    /** 比率科目区分 */
    public PerKmkKbn!: number;

    /** 販売採用区分 */
    public KmkKbn1!: number;

    /** 販売入金科目性格 */
    public KmkKbn2!: number;

    /**  */
    public KmkKbn3!: number;

    /**  */
    public KmkKbn4!: number;

    /**  */
    public KmkKbn5!: number;

    /**  */
    public KmkKbn6!: number;

    /** かんたん！用表示／印刷不可区分 */
    public KmkKbn7!: number;

    /** 元帳摘要欄起票者印字区分 */
    public KmkKbn8!: number;

    /** 使用／未使用区分 */
    public UseKbn!: number;

    /** 固定体系区分 */
    public KoteiKbn!: number;

    /** 借方資金繰コード */
    public DebitCash!: number;

    /** 貸方資金繰コード */
    public CreditCash!: number;

    /** 消費税科目区分 */
    public TaxKbn!: number;

    /** 売上消費税コード */
    public STaxCode!: number;

    /** 売上消費税率 */
    public STaxRate!: number;

    /** 仕入消費税コード */
    public BTaxCode!: number;

    /** 仕入消費税率 */
    public BTaxRate!: number;

    /** 業種コード */
    public TypeCode!: number;

    /**  */
    public TaxKbn2!: number;

    /**  */
    public TaxKbn3!: number;

    /**  */
    public TaxKbn4!: number;

    /**  */
    public TaxKbn5!: number;

    /**  */
    public TaxKbn6!: number;

    /** 科目別補助採用区分 */
    public SubKmkUse!: number;

    /** 第１補助採用区分 */
    public HojyoUse1!: number;

    /** 第２補助採用区分 */
    public HojyoUse2!: number;

    /** 部門採用区分 */
    public BmnUse!: number;

    /** セグメント１採用区分 */
    public SegUse1!: number;

    /** セグメント２採用区分 */
    public SegUse2!: number;

    /** セグメント３採用区分 */
    public SegUse3!: number;

    /** セグメント４採用区分 */
    public SegUse4!: number;

    /** 工事採用区分 */
    public KoujiUse!: number;

    /**  */
    public KUseKbn1!: number;

    /**  */
    public KUseKbn2!: number;

    /**  */
    public KUseKbn3!: number;

    /** セグメント５採用区分 */
    public KUseKbn4!: number;

    /** セグメント１別第1補助集計区分 */
    public KUseKbn5!: number;

    /** セグメント１別第2補助集計区分 */
    public KUseKbn6!: number;

    /** 部門別第1補助集計区分 */
    public BHSumKbn1!: number;

    /** 部門別第2補助集計区分 */
    public BHSumKbn2!: number;

    /** 仕訳入力区分 */
    public InputKbn!: number;

    /** 期日入力区分 */
    public DInputKbn!: number;

    /** 期日必須入力区分 */
    public DInputAlw!: number;

    /** 手形番号入力区分 */
    public TInputKbn!: number;

    /** 手形番号必須入力区分 */
    public TInputAlw!: number;

    /** 手形管理ＮＯ入力区分 */
    public TMInputKbn!: number;

    /** 手形管理ＮＯ必須入力区分 */
    public TMInputAlw!: number;

    /** 番号１入力区分 */
    public NoInputKbn1!: number;

    /** 番号１必須入力区分 */
    public NoInputAlw1!: number;

    /** 番号２入力区分 */
    public NoInputKbn2!: number;

    /** 番号２必須入力区分 */
    public NoInputAlw2!: number;

    /**  */
    public EInputKbn1!: number;

    /**  */
    public EInputAlw1!: number;

    /**  */
    public EInputKbn2!: number;

    /**  */
    public EInputAlw2!: number;

    /** 固定摘要必須入力 */
    public TkInputKbn!: number;

    /** 摘要分類コード */
    public TkClassCode!: number;

    /** 小数点桁数 */
    public DPointNum!: number;

    /** 資金繰コード優先区分 */
    public CashCodeKbn!: number;

    /** 番号1初期表示区分 */
    public EInpKbn1!: number;

    /** 番号2初期表示区分 */
    public EInpKbn2!: number;

    /** 同一番号集計区分 */
    public EInpKbn3!: number;

    /**  */
    public EInpKbn4!: number;

    /**  */
    public EInpKbn5!: number;

    /** 外部コード（新4桁コード） */
    public DupCodeN4!: number;

    /** 外部コード（4桁コード） */
    public DupCode4!: number;

    /** 外部コード（3桁コード） */
    public DupCode3!: number;

    /** 銀行コード */
    public BankCode!: string;

    /** 特定部署コード */
    public SectionCode!: string;

    /** 内部コード２ */
    public NCode2!: BigJs.Big;

    /** 番号１名称No. */
    public NoName1!: number;

    /** 番号２名称No. */
    public NoName2!: number;

    /** 番号３～10入力区分 */
    public NoInputKbn3!: number;

    /** 番号３～10必須入力区分 */
    public NoInputAlw3!: number;

}
