import React from 'react';

import * as wj from 'wijmo/wijmo';
import * as wjGrid from 'wijmo/wijmo.grid';
import { FlexGrid } from 'wijmo/wijmo.react.grid';
import * as wjFilter from 'wijmo/wijmo.grid.filter';

import { CustomGridEditor } from './CustomGridEditor';
import { headerMerge } from './CommonGrid';
import * as wijmoGridUtils from '../../../../utils/wijmoGridUtils';

const selectAllClassName = (binding: string) => 'select-all-' + binding;

export type SimpleGrid2Prop = {
  columns: any[];
  sources: any[];
  headerMerges?: headerMerge[];
  selectionMode?: wjGrid.SelectionMode;
  frozenColumns?: number;
  selectAllBinding?: string[];
  imeEnabledBinding?: string[];
  tabIndex?: number;
  innerRef?: React.RefObject<any>;
  gridRef?: React.RefObject<any>;
  gridClassName?: string;
  onSelectionChanged?: (grid: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs) => void;
  onBeginningEdit?: (grid: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs) => void;
  onCellEditEnding?: (grid: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs) => void;
  onCellEditEnded?: (grid: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs) => void;
};

type SimpleGrid2State = {};

/**
 * セル内ボタンを持たないグリッド(SimpleGridを基にして、ソート・フィルター機能を削除)
 */
class SimpleGrid2 extends React.Component<SimpleGrid2Prop, SimpleGrid2State> {
  grid?: wjGrid.FlexGrid;
  filter?: wjFilter.FlexGridFilter;
  SkiValueCustomGridEditor?: CustomGridEditor;
  SkiValueSumCustomGridEditor?: CustomGridEditor;
  workGrid?: wjGrid.FlexGrid;
  workFilter?: wjFilter.FlexGridFilter;

  constructor(props: SimpleGrid2Prop) {
    super(props);

    this.initializedGrid = this.initializedGrid.bind(this);
    this.handleItemFormatter = this.handleItemFormatter.bind(this);
    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
    this.handleBeginningEdit = this.handleBeginningEdit.bind(this);
    this.handleCellEditEnding = this.handleCellEditEnding.bind(this);
    this.handleCellEditEnded = this.handleCellEditEnded.bind(this);
    this.handleGridRefresh = this.handleGridRefresh.bind(this);
  }

  // Gridの初期化
  public initializedGrid = (flexGrid: wjGrid.FlexGrid) => {
    this.grid = flexGrid;

    if (this.props.tabIndex != undefined) {
      flexGrid.hostElement.tabIndex = this.props.tabIndex;
    }

    const { headerMerges, selectAllBinding } = this.props;

    if (headerMerges && headerMerges.length > 0) {
      // 2段ヘッダーの表示
      const extraRow = new wjGrid.Row();
      extraRow.allowMerging = true;

      const panel = flexGrid.columnHeaders;
      panel.rows.splice(0, 0, extraRow);

      const columns = flexGrid.columns;
      columns.forEach(col => {
        let headerLbl = col.header;

        headerMerges.forEach(item => {
          const binding = item.binding.filter(binding => binding === col.binding);
          if (binding.length > 0) {
            headerLbl = item.header;
          }
        });
        panel.setCellData(0, col.index, headerLbl);
      });
    }

    if (selectAllBinding && selectAllBinding.length > 0) {
      // 一括選択
      selectAllBinding.forEach(binding => {
        const column = flexGrid.columns.filter(col => col.binding === binding);
        if (column.length > 0) {
          flexGrid.hostElement.addEventListener('click', e => {
            if (
              typeof e.target !== 'undefined' &&
              wj.hasClass(e.target as Element, selectAllClassName(binding)) &&
              e.target instanceof HTMLInputElement
            ) {
              flexGrid.deferUpdate(() => {
                flexGrid.collectionView.items.forEach(item => {
                  if (column[0].inputType === 'SubDsp' && item.SubDspFlg === false) {
                    item[binding] = false;
                  } else {
                    item[binding] = (e.target as HTMLInputElement).checked;
                  }
                });
              });
            }
          });
        }
      });
    }
  };

  // ItemFormatter
  public handleItemFormatter = (panel: wjGrid.GridPanel, row: number, col: number, cell: HTMLElement) => {
    if (panel.cellType === wjGrid.CellType.ColumnHeader) {
      // ヘッダ
      cell.classList.add('d-flex');
      cell.classList.add('justify-content-center');
      cell.classList.add('align-items-center');
      cell.classList.add('font-weight-normal');

      // 一括選択チェックボックスの表示
      const binding = panel.columns[col].binding;
      if (typeof this.props.selectAllBinding !== 'undefined') {
        if (this.props.selectAllBinding.indexOf(binding) > -1) {
          if ((typeof this.props.headerMerges !== 'undefined' && this.props.headerMerges.length == 0) || row === 1) {
            const label = panel.columns[col].header;
            const cb = '<input class="mx-1 ' + selectAllClassName(binding) + '" tabindex="-1" type="checkbox">';
            const currentSort = wijmoGridUtils.getGridHeaderSortIcon(panel.columns[col]);
            cell.innerHTML = cb + label + currentSort;
            this.updateSelectAllBox(panel.grid, panel.columns[col]);
          }
        }
      }
    } else if (panel.cellType === wjGrid.CellType.Cell) {
      const item = panel.rows[row].dataItem;
      const column: wjGrid.Column = panel.columns[col];

      if (column.inputType === 'SubDsp') {
        if (item.SubDspFlg === false) {
          cell.classList.add('_grayout');
          cell.innerHTML = '';
        }
      } else if (column.inputType === 'percent') {
        cell.classList.add('_percent');
      }

      if (column.binding === 'GCode') {
        if (item.GCode == 999999999999) {
          panel.rows[row].height = 0;
        }
      }
    }
  };

  public pasting(grid: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs) {
    // ペーストを無効
    e.cancel = true;
  }

  private updateSelectAllBox(flexGrid: wjGrid.FlexGrid, column: wjGrid.Column) {
    const { binding, inputType } = column;
    let cb = flexGrid.hostElement.querySelector('.' + selectAllClassName(binding));
    if (cb instanceof HTMLInputElement) {
      let baseItems = flexGrid.collectionView.items;
      if (inputType === 'SubDsp') {
        baseItems = baseItems.filter(item => item.SubDspFlg === true);
      }
      if (baseItems.length > 0) {
        const checkedItems = baseItems.filter(item => item[binding] === true);
        cb.checked = checkedItems.length === baseItems.length;
        cb.indeterminate = !cb.checked && checkedItems.length !== 0;
      } else {
        cb.disabled = true;
      }
    }
  }

  public handleBeginningEdit(grid: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs) {
    if ((this.props.onBeginningEdit) && (typeof this.props.onBeginningEdit === 'function')) {
      // 呼び出し元画面からonBeginningEdit()が指定されている場合
      this.props.onBeginningEdit(grid, e);
    }
    else {
      // 呼び出し元画面からonBeginningEdit()が指定されていない場合
    }
  }

  public handleCellEditEnding(grid: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs) {
    if ((this.props.onCellEditEnding) && (typeof this.props.onCellEditEnding === 'function')) {
      // 呼び出し元画面からonCellEditEnding()が指定されている場合
      this.props.onCellEditEnding(grid, e);
    }
    else {
      // 呼び出し元画面からonCellEditEnding()が指定されていない場合
      if (grid.columns[e.col].inputType === 'SubDsp' && grid.collectionView.items[e.row].SubDspFlg === false) {
        e.cancel = true;
        return;
      }
    }
  }

  public handleCellEditEnded(grid: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs) {
    if ((this.props.onCellEditEnded) && (typeof this.props.onCellEditEnded === 'function')) {
      // 呼び出し元画面からonCellEditEnded()が指定されている場合
      this.props.onCellEditEnded(grid, e);
    }
    else {
      // 呼び出し元画面からonCellEditEnded()が指定されていない場合
    }
  }

  public handleSelectionChanged(grid: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs) {
    if ((this.props.onSelectionChanged) && (typeof this.props.onSelectionChanged === 'function')) {
      // 呼び出し元画面からonSelectionChanged()が指定されている場合
      this.props.onSelectionChanged(grid, e);
    }
    else {
      // 呼び出し元画面からonSelectionChanged()が指定されていない場合
      if (
        this.props.imeEnabledBinding != null &&
        this.props.imeEnabledBinding.indexOf(grid.columns[e.col].binding) > -1
      ) {
        grid.imeEnabled = true;
      } else {
        grid.imeEnabled = false;
      }
    }
  }

  public handleGridRefresh() {
  }

  public componentDidMount() {
  }

  public shouldComponentUpdate(nextProps: SimpleGrid2Prop, nextState: SimpleGrid2Prop) {
    if (this.props.sources !== nextProps.sources) {
    }
    return true;
  }

  public componentDidUpdate() {
  }

  public render() {
    const { columns, sources, gridClassName, innerRef, gridRef } = this.props;
    const selectionMode =
      typeof this.props.selectionMode === 'undefined' ? wjGrid.SelectionMode.Row : this.props.selectionMode;

    wijmoGridUtils.convertItemsSourceToDateType(columns, sources);
    const frozenColumns = this.props.frozenColumns == null ? 0 : this.props.frozenColumns;
    return (
      <span ref={innerRef}>
        <FlexGrid
          className={gridClassName}
          autoGenerateColumns={false}
          columns={columns}
          allowDragging={wjGrid.AllowDragging.None}
          allowMerging={wjGrid.AllowMerging.ColumnHeaders}
          allowResizing={wjGrid.AllowResizing.None}
          selectionMode={selectionMode}
          headersVisibility={wjGrid.HeadersVisibility.Column}
          alternatingRowStep={0}
          frozenColumns={frozenColumns}
          itemsSource={sources}
          initialized={this.initializedGrid}
          itemFormatter={this.handleItemFormatter}
          beginningEdit={this.handleBeginningEdit}
          cellEditEnding={this.handleCellEditEnding}
          cellEditEnded={this.handleCellEditEnded}
          selectionChanged={this.handleSelectionChanged}
          refreshed={this.handleGridRefresh}
          pasting={this.pasting}
          keyActionEnter={wjGrid.KeyAction.Cycle}
          keyActionTab={wjGrid.KeyAction.CycleOut}
          ref={gridRef}
          showSort={false}
          imeEnabled={true}
        >
        </FlexGrid>
        <div id='no-dsp-grid' className='d-none' />
      </span>
    );
  }
}

export default SimpleGrid2;
