import React from 'react';
import Axios from 'axios';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { Collapse, Button } from 'reactstrap';
import { setAlertMessage, AlertMessage, AlertKbnEnum } from '../../../../stores/AlertMessage';
import { setLogoutCallBack, callLogoutCallBack } from '../../../../stores/LogoutCallbck';
import helpJson from '../../../../helpSetting.json';
import configureAxios from '../../../../config/axios.config';

import {
    CorePageWrapper,
    CorePageHeader,
    CorePageMain,
    CorePageSideMenu,
    CorePageOtherMenu
} from '../../../../components/templates/CorePageLayout';
import Alert from '../../../../components/atoms/Alert/A3Alert';
import AppHeader from '../../../../components/molecules/A000/AppHeader';
import SideMenu from '../../../../components/molecules/A000/SideMenu';
import OtherMenu from '../../../../components/molecules/A000/OtherMenu';
import MenuPopup from '../../../../components/molecules/A000/MenuPopup';
import AuditMonthlyMenu from '../../../../components/molecules/A000/AuditMonthlyMenu';
import AuditMasterMenu from '../../../../components/molecules/A000/AuditMasterMenu';
import AuditAdminMenu from '../../../../components/molecules/A000/AuditAdminMenu'
import RegisterMenu from '../../../../components/molecules/A000/RegisterMenu';
import SettingMenu from '../../../../components/molecules/A000/SettingMenu';
import VerInformation from './VerInformation';
import PasswordChange from './PasswordChange';

import AppInformation from '../../../organisms/A000/AppInformation';
import JournalLineRuleSettingList from '../../A020/AIKADT001000001/JournalLineRuleSettingList';
import MonthlyBalanceSetting from '../../A020/AIKADT001000003/MonthlyBalanceSetting';
import ChecklistSettingList from '../../A030/AIKADT001000004/ChecklistSettingList';
import ExecutionResult from '../../A040/AIKADT002000001/ExecutionResult';
import Checklist from '../../A060/AIKADT002000005/Checklist';
import AuditResultList from '../../A070/AIKADT002000006/AuditResultList';
import AIJournalCheck from '../../A100/AIKADT002000013/AIJournalCheck';
import Migration from '../../A130/AIKADT006000001/Migration';
import VerHistory from './VerHistory';
import ImportApi from '../../A080/AIKADT005000001/ImportApi';
import TantoManagement from '../../A004/AIKCMN005000001/TantoManagement';
import TantoByCompany from '../../A005/AIKCMN006000001/TantoByCompany';
import SystemHistory from '../../A006/AIKCMN007000001/SystemHistory';
import UserInfo from '../../A008/AIKCMN009000001/UserInfo';
import ContractInfo from '../../A200/AIKCMN008000001/ContractInfo';
import CompanyDataSelect from '../../A000/AIKCMN001000007/CompanyDataSelect';
import FirmInfo from '../../A120/AIKADT007000001/FirmInfo';
import AccountRegister from '../../A130/AIKADT008000001/AccountRegister';
import CreateCompanyData from '../../A002/AIKCMN003000001/CreateCompanyData';
import DeleteCompanyData from '../../A003/AIKCMN004000001/DeleteCompanyData';

import * as labels from '../../../../constants/labels';
import { BatStatusEnum, AuthorityEnum, CooperationEnum } from '../../../../constants/enums';
import { ALERTTIMEOUT } from '../../../../constants/constant';
import { MainHomeViewModel } from '../../../../models/mainHomeViewModel';
import { ClientYearVO } from '../../../../models/clientYearVO';
import Loading from '../../../../components/molecules/CMN/Loading/Loading';
import Popup from '../../../../components/molecules/CMN/Popup/Popup';
import * as Labels from '../../../../constants/labels';
import * as message from '../../../../constants/message';

// API呼び出し用のURL
const baseUrl = '/api/v1/MainHome';
const bmnInfoUrl = '/api/v1/AuditTargetManager'
// GET
export const initialUrl = [baseUrl, 'Initial'].join('/');
export const clientUrl = [baseUrl, 'Client'].join('/');
export const resultStatusUrl = [baseUrl, 'ResultStatus'].join('/');
// 科目情報取得URL
export const accountInformationURL = [bmnInfoUrl, 'GetAccounts'].join('/');
// 部門情報取得URL
export const bmnInformationURL = [bmnInfoUrl, 'GetBmn'].join('/');
// ﾏｽﾀ基本情報取得URL
export const masterInfoURL = [bmnInfoUrl, 'GetMasterInfo'].join('/');
// POST
export const switchFiscalYearUrl = (fiscalYear: number) =>
    [baseUrl, 'SwitchFiscalYear', fiscalYear.toString()].join('/');
// DELETE
export const aiLogoutUrl = [baseUrl, 'Logout'].join('/');

// 画面遷移用のURL
const homeURL = '/';
const verHistoryURL = '/AIKCMN001000001/VerHistory';
const journalLineRuleSettingListURL = '/AIKADT001000001/JournalLineRuleSettingList';
const monthlyBalanceSettingURL = '/AIKADT001000003/MonthlyBalanceSetting';
const checklistSettingListURL = '/AIKADT001000004/ChecklistSettingList';
const executionResultURL = '/AIKADT002000001/ExecutionResult';
const checklistURL = '/AIKADT002000005/Checklist';
const auditResultListURL = '/AIKADT002000006/AuditResultList';
const aiJournalCheckURL = '/AIKADT002000013/AIJournalCheck';


const systemHistoryURL = '/AIKCMN007000001/SystemHistory';
const tantoManagementURL = '/AIKCMN005000001/TantoManagement';
const tantoByCompanyURL = '/AIKCMN006000001/TantoByCompany';
const userInfoURL = '/AIKCMN009000001/UserInfo';
const contractInfoURL = '/AIKCMN008000001/ContractInfo';

const migrationURL = '/AIKADT006000001/Migration';
const firmInfoURL = '/AIKADT007000001/FirmInfo';
const accountRegisterURL = '/AIKADT008000001/AccountRegister';
const createCompanyDataURL = '/AIKCMN003000001/CreateCompanyData';
const deleteCompanyDataURL = '/AIKCMN004000001/DeleteCompanyData';

const loginURL = '/AIKCMN002000004/Login';

const companyDataSelectURL = '/AIKCMN001000007/CompanyDataSelect';

// MJSメンテナンスページのURL
const MJSMaintenacePageUrl = 'https://info.support.mjs.co.jp/';



// URLと画面IDのマッピング
export const idURLMap: { [index: string]: string } = {
    [homeURL]: 'AIKCMN001000001', // ホーム
    [verHistoryURL]: 'AIKCMN001000001VerHistory', // バージョン履歴
    [journalLineRuleSettingListURL]: 'AIKADT001000001', // 仕訳明細ルール設定一覧
    [monthlyBalanceSettingURL]: 'AIKADT001000003', // 月次残高ルール設定
    [checklistSettingListURL]: 'AIKADT001000004', // チェックリスト設定一覧
    [executionResultURL]: 'AIKADT002000001', // 監査実行・結果表示
    [checklistURL]: 'AIKADT002000005', // チェックリスト
    [auditResultListURL]: 'AIKADT002000006', // 監査結果一覧
    [aiJournalCheckURL]: 'AIKADT002000013',// AI仕訳チェック
    [migrationURL]: 'AIKADT006000001',// 仕訳・実績データ取込み
    [firmInfoURL]: 'AIKADT007000001',// 会社基本情報
    [accountRegisterURL]: 'AIKADT008000001',// 勘定科目登録
    [createCompanyDataURL]: 'AIKCMN003000001',// 会社データ作成
    [deleteCompanyDataURL]: 'AIKCMN004000001',// 会社データ削除
    [tantoManagementURL]: 'AIKCMN005000001',// 担当者アカウント登録・変更
    [tantoByCompanyURL]: 'AIKCMN006000001',// 会社別担当者設定
    [systemHistoryURL]: 'AIKCMN007000001',// システム履歴
    [contractInfoURL]: 'AIKCMN008000001',// 契約情報
    [userInfoURL]: 'AIKCMN009000001',// ユーザー情報の確認・変更
    [loginURL]: 'AIKCMN002000004',// 担当者ログイン
    [companyDataSelectURL]: 'AIKCMN001000007',// 会社データ選択画面
};

const AppHome: React.FC = () => {
    /** アラート */
    const globalAlertMessage = useGlobal<AlertMessage>('alertMessage')[0];
    const [alertState, setAlertState] = React.useState(globalAlertMessage);
    const [visibleCommon, setVisibleCommon] = React.useState(false);
    const [initializeState, setInitializeState] = React.useState(false);
    const alertColor = AlertKbnEnum[alertState.alertKbn].toString();
    React.useEffect(() => {
        if (globalAlertMessage.message == null || globalAlertMessage.message === '') {
            // メッセージがない場合はアラートを表示させない
            globalAlertMessage.alerted = false;
        }
        setAlertState(globalAlertMessage);
    }, [globalAlertMessage]);

  React.useEffect(() => {
    if (globalAlertMessage.alerted === true) {
      setTimeout(() => {
        if (globalAlertMessage.alerted === true) {
          handleAlertClose();
        }
      }, globalAlertMessage.timeout === undefined ? ALERTTIMEOUT : globalAlertMessage.timeout);
    }
  }, [alertState]);

    /** ロード中 */
    const globalIsLoading = useGlobal<boolean>('isLoading')[0];
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        if (redirect === homeURL) {
            // ホーム画面では表示させない
            setIsLoading(false);
        } else {
            setIsLoading(globalIsLoading);
        }
    }, [globalIsLoading]);

    const [isSideMenuOpen, setIsSideMenuOpen] = React.useState(true);
    const [isFiscalYearOpen, setIsFiscalYearOpen] = React.useState(false);
    const [isOtherMenuOpen, setIsOtherMenuOpen] = React.useState(false);
    const [isAuditMonthlyMenuModalOpen, setIsAuditMonthlyMenuModalOpen] = React.useState(false);
    const [isAuditMasterMenuModalOpen, setIsAuditMasterMenuModalOpen] = React.useState(false);
    const [isAuditAdminMenuModalOpen, setIsAuditAdminMenuModalOpen] = React.useState(false);
    const [isRegisterMenuModalOpen, setIsRegisterMenuModalOpen] = React.useState(false);
    const [isSettingMenuModalOpen, setIsSettingMenuModalOpen] = React.useState(false);
    const [isVerInformationOpen, setIsVerInformationOpen] = React.useState(false);
    const [isImportApiOpen, setIsImportApiOpen] = React.useState(false);
    const [isPasswordChangeOpen, setIsPasswordChangeOpen] = React.useState(false);

    const [redirect, setRedirect] = React.useState('/');



    
    // 契約上作成可能な会社数上限を超過したため、会社データ削除ページに遷移する旨のダイアログの表示制御
    const [deletePopupActivated, setDeletePopupActivated] = React.useState<boolean>(false);

    // 契約上作成可能な会社数上限を超過したため、ログアウトする旨のダイアログの表示制御
    const [logoutPopupActivated, setLogoutPopupActivated] = React.useState<boolean>(false);

    // 会社データが0件のためログアウトする旨のダイアログの表示制御
    const [companyNotFoundPopupActivated, setCompanyNotFoundPopupActivated] = React.useState<boolean>(false);


    /** 画面遷移 */
    const toNextPage = (url: string) => {
        setRedirect(url);
        handleAllMenuClose();
    };

    const [viewModel, setViewModel] = React.useState<MainHomeViewModel>({
        ClientYearVO: undefined,
        ClientName: undefined,
        SelectClientYear: undefined,
        LoginID: "",
        Authority: AuthorityEnum.Ippan,
        Cooperation: CooperationEnum.Other,
        IsCompanyCntOver: false,
        EntrancePageURL: "",
        TantoLoginURL : ""
    });

    /** 年度変更時のイベントを実装 */
    const handleOnFiscalYearChange = (clientYear: ClientYearVO) => {
        handleAllMenuClose();
        if (clientYear.ClientYear != undefined && clientYear.ClientYear !== viewModel.SelectClientYear) {
            switchFiscalYearOnPost(switchFiscalYearUrl(clientYear.ClientYear), clientYear.ClientYear);
        }
    };

    /** モーダルメニューを閉じる */
    const handleModalClose = () => {
        setIsAuditMonthlyMenuModalOpen(false);
        setIsAuditMasterMenuModalOpen(false);
        setIsAuditAdminMenuModalOpen(false);
        setIsRegisterMenuModalOpen(false);
        setIsSettingMenuModalOpen(false);
    };

    /** 全てのメニューを閉じる */
    const handleAllMenuClose = () => {
        setIsSideMenuOpen(false);
        setIsFiscalYearOpen(false);
        setIsOtherMenuOpen(false);
        handleModalClose();
    };

    /** ホーム画面へ戻る */
    const backHome = () => {
        toNextPage(homeURL);
        setIsSideMenuOpen(true);
    };

    /** アラートを閉じる */
    const handleAlertClose = () => {
        setAlertMessage({ ...alertState, alerted: false });
    };

    /** 初期表示時の処理 */
    const initialOnGet = (url: string) => {
        setInitializeState(true);
        setIsLoading(true);
        Axios.get<MainHomeViewModel>(url)
            .then(response => {
                setViewModel(response.data);
                setLogoutCallBack(() => {
                    if (response.data.Cooperation == CooperationEnum.Other) {
                        /** 他社連携の場合 */
                        if (response.data.Authority == AuthorityEnum.Bizsky) {
                            /** bizskyユーザの場合 */
                            window.location.assign('/api/v1/Login/BizskyLogout');
                        } else {
                            /** 担当者ユーザの場合 */
                            if (response.data.TantoLoginURL != null) {
                                window.location.href = response.data.TantoLoginURL;
                            }
                        }
                    } else if (response.data.Cooperation == CooperationEnum.MJS){
                        /** オンプレの場合 */
                        toOutReactPage('logout.html');
                    }
                }) ;
                setVisibleCommon(true);
                //会社確定必要なユーザーの場合はまず会社を確定させ、それから会社数が多すぎるかのチェックをする
                //会社情報存在チェック　
                clientOnGet(clientUrl, response.data)//第二引数はviewModelでないのは、setViewModel()がこの関数を抜けないと効かないため
                    .finally(() => {
                        setIsLoading(false);
                    })
                })
            .catch(error => {
                setVisibleCommon(true);
                setIsLoading(false);
                setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.message });
            })
            .finally(() => {
                setInitializeState(false);
            });
    };

    const onYearAdd = () => {
        initialOnGet(initialUrl);//この関数はブロッキングしないので、別途呼び出し完了のイベントをsetInitializeState()で子画面に伝えている
    };


    const handleLogoutPopupClose = () => {
        setLogoutPopupActivated(false);
        logoutOnDelete(aiLogoutUrl);
    };

    const handleLogoutPopupCloseClick = () => {
        setLogoutPopupActivated(false);
    };

    const handleDeletePopupClose = () => {
        setDeletePopupActivated(false);
        toNextPage(deleteCompanyDataURL);
    };

    const handleDeletePopupCloseClick = () => {
        setDeletePopupActivated(false);
    };


    const handleCompanyNotFoundPopupClose = () => {
        setCompanyNotFoundPopupActivated(false);
        logoutOnDelete(aiLogoutUrl);
    };

    const handleCompanyNotFoundPopupCloseClick = () => {
        setCompanyNotFoundPopupActivated(false);
    };

    /** 会社情報存在チェック処理 clientcode,yearをキーにdtmainがあるか確認している */
    const clientOnGet = (url: string, vm: MainHomeViewModel) => {
        return Axios.get<boolean>(url)
            .then(response => {
                const status = response.data;

                if (vm.IsCompanyCntOver) {
                    // 他社連携かつ、
                    //「作成済会社データ数＞契約会社データ数」の場合
                    if (vm.Authority == AuthorityEnum.Ippan) {
                        //担当者(一般)はエラーを表示してログアウトするしかない
                        setLogoutPopupActivated(true);
                    } else {
                        //上記以外は会社データ削除画面に遷移
                        setDeletePopupActivated(true);
                    }
                } else {
                    //　「作成済会社データ数≦契約会社データ数」またはオンプレ場合
                    if (status == true) {
                        //会社が決まっている場合
                        //データ取込結果ステータス取得し、取込中であればエラーメッセージ表示(第二引数なければどこにも遷移はしない)
                        resultStatusOnGet(resultStatusUrl);
                        // 部門情報取得
                        getBmnInformation();
                    } else {
                        //会社が決まっていない場合
                        if (vm.Cooperation == CooperationEnum.Other) {
                            //他社連携の場合
                            if (vm.Authority != AuthorityEnum.Bizsky) {
                                //担当者ユーザーの場合

                                if (vm.SelectableCompanyCount == 0) {
                                    //選択可能な会社がない場合
                                    if (vm.Authority == AuthorityEnum.Ippan) {
                                        //担当者(一般)の場合
                                        //ログアウト
                                        setCompanyNotFoundPopupActivated(true);
                                    }
                                    //担当者(管理者)は会社未確定の状態でホーム画面を表示
                                } else {
                                    //会社選択画面に遷移(機能設計書の他社システム連携の1-1-1のパターン)
                                    toNextPage(companyDataSelectURL);
                                    //会社選択画面で会社選択のタイミングでresultStatusOnGet()をやることは不要
                                    //  (会社選択画面はImportResultテーブルのチェックが不要な他社連携でしか表示されないため)
                                }
                                // ※vm.SelectableCompanyCount == 1のときはここには来ない
                            }
                            //bizskyユーザーの場合は会社は選択できない
                            //会社未確定の状態でホーム画面を表示
                        } else {
                            //オンプレの場合
                            //ログアウト(機能設計書の連携区分:共通の1-1-3のパターン)
                            logoutOnDelete(aiLogoutUrl);
                        }
                    }
                }
            })
            .catch(error => {
                setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.message });
            });
    };

    /** データ取得確認処理 */
    const resultStatusOnGet = (url: string, nextPageUrl?: string) => {
        if (viewModel.Cooperation == CooperationEnum.Other) {
            // 他社連携の場合は会社を確定できない(bizskyユーザの場合)か、確定できてもImportResult.status = 1(処理中)となるケースがないためデータ取得確認不要
            if (typeof nextPageUrl === 'string') {
                toNextPage(nextPageUrl);
            }
        }
        else {
            // データ取得済みか確認
            //   ImportResult.status = 1(処理中)のときメニューから起動できない(その画面に移らずに、「データ取込中...」と画面に表示)
            //     他社連携の場合のImportResultは、ImportResult.status = 1(処理中)になるケースがない
            //       (会社作成は作成完了後、status=2のレコードを作成するが、作成失敗した場合はレコードを作成しない)
            Axios.get<BatStatusEnum>(url)
                .then(response => {
                    const status = response.data;
                    if (status == BatStatusEnum.Processing) {
                        setIsImportApiOpen(true);
                    } else {
                        if (typeof nextPageUrl === 'string') {
                            toNextPage(nextPageUrl);
                        }
                    }
                })
                .catch(error => {
                    setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.message });
                });
        }
    };

    /** 年度変更処理 */
    const switchFiscalYearOnPost = (url: string, fiscalYear: number) => {
        setIsLoading(true);
        Axios.post(url)
            .then(response => {
                Axios.get<MainHomeViewModel>(initialUrl) /* 年度更新で会社名などが変更になる可能性があるため、基本情報を再取得 */
                    .then(response => {
                        setViewModel({ ...viewModel, SelectClientYear: fiscalYear, ClientName: response.data.ClientName});
                        setIsLoading(false);
                    })
                    .catch(error => {
                        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.message });
                        setIsLoading(false);
                    })
            })
            .catch(error => {
                setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.message });
                setIsLoading(false);
            })
    };

    /** ログアウト処理 */
    const logoutOnDelete = (aiLogoutUrl: string) => {
        configureAxios.clearTokenHeader();
        /** AI監査からログアウト */
        Axios.delete(aiLogoutUrl)
            .then(response => {
                callLogoutCallBack();
                setLogoutCallBack(() => {});
            })
            .catch(error => {
                setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.message });
            });
    };

    /** 部門情報取得 */
    async function getBmnInformation() {
        // 科目情報取得
        window.accountCount = await Axios.get(accountInformationURL);
        // ﾏｽﾀ基本情報取得
        window.masInfo = await Axios.get(masterInfoURL);
        if (window.accountCount.data > 0 && window.masInfo.data['UseKbn'] != 0) {
            // 部門情報取得
            window.bmnInfo = await Axios.get(bmnInformationURL);
        }
    }

    /** 初期表示時のみ実行 */
    React.useEffect(() => {
        initialOnGet(initialUrl);
    }, [initialUrl]);

    /** ホーム画面が表示された場合に実行 */
    React.useEffect(() => {
        if (viewModel.ClientYearVO != undefined) {
            if (redirect === homeURL) {
                initialOnGet(initialUrl);
                resultStatusOnGet(resultStatusUrl);
            }
        }
    }, [redirect]);

    // ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
    // ヘッダー
    /** サイドメニューのトグル押下 */
    const handleMenuOnToggle = () => {
        handleAllMenuClose();
        setIsSideMenuOpen(!isSideMenuOpen);
    };
    /** 年度の変更 */
    const handleFiscalYearOnClick = () => {
        handleAllMenuClose();
        setIsFiscalYearOpen(!isFiscalYearOpen);
    };
    /** ヘルプボタン押下 */
    const handleHelpOnClick = () => {
        // メニューを閉じる
        handleAllMenuClose();
        // ヘルプへ遷移
        const helpMap: string = helpJson.idMap[idURLMap[redirect]];
        /** HelpページのURL */ // URLが変わる場合、VerHistory.tsxのopenHelp()も同様に修正する必要あり
        let helpUrl = getSystemPath() + helpJson.URL["PageUrl"];
        if (0 < helpMap.length) {
            helpUrl = helpUrl + helpJson.URL["PageUrlParam"] + helpMap + helpJson.URL["PageUrlExt"];
        }
        openNewWindow(helpUrl, 'mjs-ai-help');
    };
    /** その他メニューのトグル押下 */
    const handleOtherMenuOnClick = () => {
        handleAllMenuClose();
        setIsOtherMenuOpen(!isOtherMenuOpen);
    };

    // ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
    // サイドメニュー
    /** ホーム押下 */
    const handleHomeMenuOnClick = () => {
        resultStatusOnGet(resultStatusUrl, homeURL);
    };
    /** 月次処理メニュー押下 */
    const handleMonthlyMenuOnClick = () => {
        handleModalClose();
        setIsAuditMonthlyMenuModalOpen(!isAuditMonthlyMenuModalOpen);
    };
    /** 導入・登録処理メニュー押下 */
    const handleRegisterMenuOnClick = () => {
        handleModalClose();
        setIsRegisterMenuModalOpen(!isRegisterMenuModalOpen)
    }
    /** マスターメニュー押下 */
    const handleMasterMenuOnClick = () => {
        handleModalClose();
        setIsAuditMasterMenuModalOpen(!isAuditMasterMenuModalOpen);
    };
    /** 環境設定メニュー押下 */
    const handleSettingMenuOnClick = () => {
        handleModalClose();
        setIsSettingMenuModalOpen(!isSettingMenuModalOpen)
    };
    /** 管理者メニュー押下 */
    const handleAdminManagementOnClick = () => {
        handleModalClose();
        setIsAuditAdminMenuModalOpen(!isAuditAdminMenuModalOpen);
    }
    // ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝

    // ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
    // その他メニュー
    /** ログアウト押下 */
    const handleLogoutOnClick = () => {
        logoutOnDelete(aiLogoutUrl);
    };
    /** バージョン履歴押下 */
    const handleVerHistoryOnClick = () => {
        toNextPage(verHistoryURL);
        setIsOtherMenuOpen(false);
    };
    /** バージョン情報押下 */
    const handleVerInformationOnClick = () => {
        setIsVerInformationOpen(true);
        setIsOtherMenuOpen(false);
    };
    /** パスワード変更 */
    const handlePasswordChangeOnClick = () => {
        setIsPasswordChangeOpen(true);
        setIsOtherMenuOpen(false);
    };
    /** 会社データ選択 */
    const handleCompanyDataSelectOnClick = () => {
        resultStatusOnGet(resultStatusUrl, companyDataSelectURL);
        setIsOtherMenuOpen(false);
    }
    // ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝

    // ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
    // ポップアップメニュー
    /** 月次処理メニューのモーダルメニューを閉じる */
    const handleAuditMonthlyMenuModalClose = () => {
        handleAllMenuClose();
    };
    /** 導入・登録処理メニューのモーダルメニューを閉じる */
    const handleRegisterMenuModalClose = () => {
        handleAllMenuClose();
    };
    /** マスターメニューのモーダルメニューを閉じる */
    const handleAuditMasterMenuModalClose = () => {
        handleAllMenuClose();
    };
    /** 環境設定メニューのモーダルメニューを閉じる */
    const handleSettingMenuModalClose = () => {
        handleAllMenuClose();
    };
    /** 管理者メニューのモーダルメニューを閉じる */
    const handleAuditAdminMenuModalClose = () => {
        handleAllMenuClose();
    };
    // ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝


    // ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
    // 導入・登録処理メニュー
    /** 会社基本情報押下 */
    const handleOnFirmInfoClick = () => {
        resultStatusOnGet(resultStatusUrl, firmInfoURL);
    };
    /** 勘定科目登録押下 */
    const handleOnAccountRegisterClick = () => {
        resultStatusOnGet(resultStatusUrl, accountRegisterURL);
    };
    // ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
    // ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
    // マスターメニュー
    /** 仕訳明細ルール設定押下 */
    const handleOnJournalLineRuleSettingListClick = () => {
        resultStatusOnGet(resultStatusUrl, journalLineRuleSettingListURL);
    };
    /** 月次残高ルール設定押下 */
    const handleOnMonthlyBalanceSettingClick = () => {
        resultStatusOnGet(resultStatusUrl, monthlyBalanceSettingURL);
    };
    /** チェックリスト押下 */
    const handleOnChecklistSettingListClick = () => {
        resultStatusOnGet(resultStatusUrl, checklistSettingListURL);
    };
    // ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
    // ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
    // 環境設定メニュー
    /** 会社データ作成押下 */
    const handleOnCreateCompanyDataClick = () => {
        resultStatusOnGet(resultStatusUrl, createCompanyDataURL);
    };
    /** 会社データ削除押下 */
    const handleOnDeleteCompanyDataClick = () => {
        resultStatusOnGet(resultStatusUrl, deleteCompanyDataURL);
    };
    /** 担当者アカウント登録・変更押下 */
    const handleOnTantoManagementClick = () => {
        resultStatusOnGet(resultStatusUrl, tantoManagementURL);
    };
    /** 会社別担当者設定押下 */
    const handleOnTantoByCompanyClick = () => {
        resultStatusOnGet(resultStatusUrl, tantoByCompanyURL);
    };
    /** システム履歴押下 */
    const handleOnSystemHistoryClick = () => {
        resultStatusOnGet(resultStatusUrl, systemHistoryURL);
    };
    // ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
    // ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
    // 管理者メニュー
    const handleOnUserInfoClick = () => {
        resultStatusOnGet(resultStatusUrl, userInfoURL);
    }
    const handleOnContractInfoClick = () => {
        resultStatusOnGet(resultStatusUrl, contractInfoURL);
    }
    // ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝

    // ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
    // バージョン情報
    /** 閉じるボタン押下 */
    const handleVerInformationClose = () => {
        setIsVerInformationOpen(false);
    };
    // ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
    // ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
    // 月次監査メニュー
    /** 監査実行・結果表示押下 */
    const handleOnExecutionResultClick = () => {
        resultStatusOnGet(resultStatusUrl, executionResultURL);
    };
    const handleOnAIJournalCheckClick = () => {
        resultStatusOnGet(resultStatusUrl, aiJournalCheckURL);
    };
    /** チェックリスト押下 */
    const handleOnChecklistClick = () => {
        resultStatusOnGet(resultStatusUrl, checklistURL);
    };
    /** 監査結果一覧押下 */
    const handleOnAuditResultListClick = () => {
        resultStatusOnGet(resultStatusUrl, auditResultListURL);
    };
    /** データ取込み押下 */
    const handleOnMigrationClick = () => {
        resultStatusOnGet(resultStatusUrl, migrationURL);
    };
    // ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝

    // ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
    // パスワード変更
    /** 閉じるボタン押下 */
    const handlePasswordChangeClose = () => {
        setIsPasswordChangeOpen(false);
    };
    // ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝

    // ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝

    // データ取込
    /** 閉じるボタン押下 */
    const handleOnImportApiClose = () => {
        setIsImportApiOpen(false);
    };
    // ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝

    // メインコンテンツクリックでメニュを閉じる
    const handleMainOnClick = (event: any) => {
        handleAllMenuClose();
    };

    /** メンテナンス情報サイトを別タブで開く */
    const openMJSMaintenance = () => {
        openNewWindow(MJSMaintenacePageUrl, undefined);
    };

    /** 別ウィンドウで開く共通処理 */
    const openNewWindow = (url: string, windowName?: string) => {
        // 脆弱性対応向けオプション設定
        const windowFeatures = "noopener,toolbar=yes,location=yes,status=yes,menubar=yes,scrollbars=yes,resizable=yes";
        const winopen = window.open(url, windowName, windowFeatures);
        if (winopen) {
            // IE,Edge(MSIE版)向け脆弱性対策
            winopen.opener = null;
        }
    }

    //fiscalYearを受け取る画面向けにundefinedが入らないようにしてビルドエラーが出ないようにする
    let selectClientYear;
    if (viewModel.SelectClientYear == undefined) {
        selectClientYear = 0;//bizskyユーザ、管理者の場合にfiscalYearを使う画面を誤って表示しなければこの値が使われることはない
    } else {
        selectClientYear = viewModel.SelectClientYear;
    }

    const body = visibleCommon ?
     (
        <HashRouter>
            <CorePageWrapper className='app-home'>
                <Loading activated={isLoading} />
                <Switch>
                    <Route path={companyDataSelectURL}>
                    </Route>
                    <Route>
                        <CorePageHeader>
                            <AppHeader
                                selectClientYear={viewModel.SelectClientYear}
                                clientYears={viewModel.ClientYearVO}
                                isFiscalYearOpen={isFiscalYearOpen}
                                fiscalYearOnClick={handleFiscalYearOnClick}
                                fiscalYearOnChange={handleOnFiscalYearChange}
                                companyName={viewModel.ClientName}
                                menuOnToggle={handleMenuOnToggle}
                                isOpenMenu={isSideMenuOpen}
                                helpOnClick={handleHelpOnClick}
                                otherMenuOnToggle={handleOtherMenuOnClick}
                            />
                        </CorePageHeader>
                        <CorePageSideMenu>
                            <SideMenu
                                isOpen={isSideMenuOpen}
                                className='position-fixed overflow-hidden'
                                homeMenuOnClick={handleHomeMenuOnClick}
                                masterMenuOnClick={handleMasterMenuOnClick}
                                monthlyMenuOnClick={handleMonthlyMenuOnClick}
                                adminManagementOnClick={handleAdminManagementOnClick}
                                registerMenuOnClick={handleRegisterMenuOnClick}
                                settingMenuOnClick={handleSettingMenuOnClick}
                                cooperation={viewModel.Cooperation}
                            />
                        </CorePageSideMenu>
                    </Route>
                </Switch>
                <CorePageMain>
                    <Alert
                        className='white-space-pre-wrap'
                        color={alertColor}
                        isOpen={alertState.alerted}
                        toggle={handleAlertClose}
                    >
                        {alertState.message}
                    </Alert>
                    <Redirect to={redirect} />
                    <div className='w-100 h-100' onClick={handleMainOnClick}>
                        <Switch>
                            <Route path={homeURL} exact={true}>
                                <AppInformation isSideMenuOpen={isSideMenuOpen} openMaintenaceClick={openMJSMaintenance} />
                            </Route>
                            <Route path={verHistoryURL}>
                                <VerHistory onClose={backHome} />
                            </Route>
                            <Route path={journalLineRuleSettingListURL}>
                                <JournalLineRuleSettingList onClose={backHome} fiscalYear={selectClientYear} />
                            </Route>
                            <Route path={monthlyBalanceSettingURL}>
                                <MonthlyBalanceSetting onClose={backHome} fiscalYear={selectClientYear} />
                            </Route>
                            <Route path={checklistSettingListURL}>
                                <ChecklistSettingList onClose={backHome} />
                            </Route>
                            <Route path={executionResultURL}>
                                <ExecutionResult onClose={backHome} fiscalYear={selectClientYear} />
                            </Route>
                            <Route path={checklistURL}>
                                <Checklist onClose={backHome} />
                            </Route>
                            <Route path={auditResultListURL}>
                                <AuditResultList onClose={backHome} fiscalYear={selectClientYear} />
                            </Route>
                            <Route path={aiJournalCheckURL}>
                                <AIJournalCheck onClose={backHome} fiscalYear={selectClientYear} />
                            </Route>
                            <Route path={migrationURL}>
                                <Migration onClose={backHome} fiscalYear={selectClientYear} />
                            </Route>
                            <Route path={tantoManagementURL}>
                                <TantoManagement onClose={backHome} />
                            </Route>
                            <Route path={tantoByCompanyURL}>
                                <TantoByCompany onClose={backHome} />
                            </Route>
                            <Route path={systemHistoryURL}>
                                <SystemHistory onClose={backHome} />
                            </Route>
                            <Route path={userInfoURL}>
                                <UserInfo onClose={backHome} />
                            </Route>
                            <Route path={contractInfoURL}>
                                <ContractInfo onClose={backHome} />
                            </Route>
                            <Route path={companyDataSelectURL}>
                                <CompanyDataSelect onClose={backHome}/>
                            </Route>
                            <Route path={firmInfoURL}>
                                <FirmInfo onClose={backHome} fiscalYear={selectClientYear} />
                            </Route>
                            <Route path={accountRegisterURL}>
                                <AccountRegister onClose={backHome} fiscalYear={selectClientYear} />
                            </Route>
                            <Route path={createCompanyDataURL}>
                                <CreateCompanyData onClose={backHome} fiscalYear={selectClientYear} onYearAdd={onYearAdd} initializeState={initializeState} />
                            </Route>
                            <Route path={deleteCompanyDataURL}>
                                <DeleteCompanyData onClose={backHome} fiscalYear={selectClientYear} />
                            </Route>
                            <Route
                                component={() => {
                                    toOutReactPage('StatusError?statusCode=404');
                                    return null;
                                }}
                            />
                        </Switch>
                    </div>
                </CorePageMain>
                <CorePageOtherMenu>
                    <Collapse
                        isOpen={isOtherMenuOpen}
                        navbar={false}
                        className='position-fixed overflow-hidden app-other-menu right-0'
                    >
                        <OtherMenu
                            logoutOnClick={handleLogoutOnClick}
                            verHistoryOnClick={handleVerHistoryOnClick}
                            verInformationOnClick={handleVerInformationOnClick}
                            passwordChangeOnClick={handlePasswordChangeOnClick}
                            companyDataSelectOnClick={handleCompanyDataSelectOnClick}
                            cooperation={viewModel.Cooperation}
                            authority={viewModel.Authority}
                            isCompanyCntOver={viewModel.IsCompanyCntOver}
                        />
                    </Collapse>
                </CorePageOtherMenu>
            </CorePageWrapper>
            <MenuPopup
                isOpen={isAuditMonthlyMenuModalOpen}
                onClose={handleAuditMonthlyMenuModalClose}
                title={labels.AIKCMN001000001_FUNCTION_NAME_MONTHLYAUDIT}
            >
                <AuditMonthlyMenu
                    onAuditResultListClick={handleOnAuditResultListClick}
                    onAIJournalCheckClick={handleOnAIJournalCheckClick}
                    onChecklistClick={handleOnChecklistClick}
                    onExecutionResultClick={handleOnExecutionResultClick}
                    onMigrationClick={handleOnMigrationClick}
                    cooperation={viewModel.Cooperation}
                />
            </MenuPopup>
            <MenuPopup
                isOpen={isRegisterMenuModalOpen}
                onClose={handleRegisterMenuModalClose}
                title={'導入・登録処理'}
            >
                <RegisterMenu
                    onFirmInfoClick={handleOnFirmInfoClick}
                    onAccountRegister={handleOnAccountRegisterClick}
                />
            </MenuPopup>
            <MenuPopup
                isOpen={isAuditMasterMenuModalOpen}
                onClose={handleAuditMasterMenuModalClose}
                title={labels.AIKCMN001000001_FUNCTION_NAME_VARIOUSMASTERREGISTRATION}
            >
                <AuditMasterMenu
                    onJournalLineRuleSettingListClick={handleOnJournalLineRuleSettingListClick}
                    onMonthlyBalanceSettingClick={handleOnMonthlyBalanceSettingClick}
                    onChecklistSettingListClick={handleOnChecklistSettingListClick}
                />
            </MenuPopup>
            <MenuPopup
                isOpen={isSettingMenuModalOpen}
                onClose={handleSettingMenuModalClose}
                title={'環境設定'}
            >
                <SettingMenu
                    onCreateCompanyDataClick={handleOnCreateCompanyDataClick}
                    onDeleteCompanyDataClick={handleOnDeleteCompanyDataClick}
                    onSystemHistoryClick={handleOnSystemHistoryClick}
                    onTantoManagementClick={handleOnTantoManagementClick}
                    onTantoByCompanyClick={handleOnTantoByCompanyClick}
                />
            </MenuPopup>
            <MenuPopup
                isOpen={isAuditAdminMenuModalOpen}
                onClose={handleAuditAdminMenuModalClose}
                title={'管理者用'}
            >
                <AuditAdminMenu
                    onUserInfoClick={handleOnUserInfoClick}
                    onContractInfoClick={handleOnContractInfoClick}
                />
            </MenuPopup>
            <VerInformation isOpen={isVerInformationOpen} onCloseClick={handleVerInformationClose} />
            <PasswordChange isOpen={isPasswordChangeOpen} onCloseClick={handlePasswordChangeClose} />

            <ImportApi activated={isImportApiOpen} onClose={handleOnImportApiClose} />

            <Popup
                header={Labels.AIKCMN002000004_FUNCTION_CONTRACTOVER}
                isOpen={logoutPopupActivated}
                onClosed={handleLogoutPopupClose}
                onCloseClick={handleLogoutPopupCloseClick}
                footer={
                    <div className='w-100 text-center'>
                        <Button
                            color='primary'
                            className='mx-auto px-5 py-1'
                            onClick={handleLogoutPopupCloseClick}
                        >
                            {Labels.AIKCMN001000001_BUTTON_FUNCTION_OK}
                        </Button>
                    </div>
                }
            >
                {message.Home_Error_StartCheck_ContractOverAndLogout}
            </Popup>
            <Popup
                header={Labels.AIKCMN002000004_FUNCTION_CONTRACTOVER}
                isOpen={deletePopupActivated}
                onClosed={handleDeletePopupClose}
                onCloseClick={handleDeletePopupCloseClick}
                footer={
                    <div className='w-100 text-center'>
                        <Button
                            color='primary'
                            className='mx-auto px-5 py-1'
                            onClick={handleDeletePopupCloseClick}
                        >
                            {Labels.AIKCMN001000001_BUTTON_FUNCTION_OK}
                        </Button>
                    </div>
                }
            >
                {message.Home_Error_StartCheck_ContractOverAndDelete}
            </Popup>
            <Popup
                header={Labels.AIKCMN002000004_FUNCTION_NAME}
                isOpen={companyNotFoundPopupActivated}
                onClosed={handleCompanyNotFoundPopupClose}
                onCloseClick={handleCompanyNotFoundPopupCloseClick}
                footer={
                    <div className='w-100 text-center'>
                        <Button
                            color='primary'
                            className='mx-auto px-5 py-1'
                            onClick={handleCompanyNotFoundPopupCloseClick}
                        >
                            {Labels.AIKCMN001000001_BUTTON_FUNCTION_OK}
                        </Button>
                    </div>
                }
            >
                {message.Home_Error_StartCheck_CompanyNotFoundAndLogout}
            </Popup>
        </HashRouter>
    ) : (null);
    return body;

};

/** React外のページへ遷移 */
const toOutReactPage = (url: string) => {
    window.location.href = [getSystemPath(), url].join('/');
};

const getSystemPath = () => {
    return window.location.origin;
};

export default AppHome;

declare global {
    interface Window {
        masInfo: any;
        accountCount: any;
        bmnInfo: any;
    }
}
