/**
 * 会社データ作成ViewModel ViewModel クラス
 */

export class CreateCompanyDataViewModel {
    /** 会社コード */
    public ClientCode?:number;

    /** 顧客先コード */
    public ComCode?:number;

    /** 会社名 */
    public LComName?:string;

    /** フリガナ */
    public KanaComName?:string;

    /** 簡略会社名 */
    public SComName?:string;

    /** 連想 */
    public RenChar?:string;

    /** 会計期間（自） */
    public KStDate?:string;

    /** 会計期間（至） */
    public KEdDate?:string;

    /** 月次開始日 */
    public MStDay?:number;

    /** 科目体系区分 */
    public CorpKbn?:number;

    /** 会計処理区分 */
    public AccProKbn?:number;

    /** 中間決算区分 */
    public MiddleKbn?:number;

    /** 中間決算区分の集計方法 */
    public MCalcKbn?:number;

    /** 部門採用 */
    public DepartmentUseKbn?:number;

    /** 簡易課税基準業種 */
    public BasedType?:number;

    /** 簡易課税区分(kanikbn) */
    public Kanikbn?:number;

    /** 輸入取引区分 */
    public ImportKbn?:number;

    /** 過年度コピー区分 */
    public YearCopyKbn?:number;

    /** コピー元会計年度 */
    public CopyClientYear?:number;

}
