import React from 'react';

import { Card, CardImg, CardText } from 'reactstrap';
import { Nav, NavItem, NavLink } from 'reactstrap';

import ResultMenuIcon from '../../../images/vkz/ico_menu_kamokubetsu.png';
import AIJournalMenuIcon from '../../../images/vkz/ico_menu_shiwake.png';
import CheckMenuIcon from '../../../images/vkz/ico_menu_kyuuyo_syouyo_kakutei.png';
import AiCaptureIcon from '../../../images/vkz/ico_menu_torikomi.png';
// import NotesMenuIcon from '../../../images/vkz/ico_menu_shiwake.png';

import * as labels from '../../../constants/labels';
import { CooperationEnum } from '../../../constants/enums';

type AuditMonthlyMenuProps = {
  onExecutionResultClick: () => void;
  onAIJournalCheckClick: () => void;
  onChecklistClick: () => void;
  onAuditResultListClick: () => void;
  onMigrationClick: () => void;
  cooperation: CooperationEnum | undefined ;
};

const headerClass = 'h6 text-lbl';
const navLinkClass = 'cursor-pointer p-0 m-2';
const cardClass = 'border-0 justify-content-center align-items-center _item';
const lblClass = 'small text-black d-block text-center';
const cardTextClass = 'm-2';

const AuditMonthlyMenu = (props: AuditMonthlyMenuProps) => {
  const executingresultdisplayLbl: React.ReactElement[] = [];
  labels.AIKCMN001000001_BUTTON_FUNCTION_AUDITEXECUTINGRESULTDISPLAY.split('\n').forEach((lbl, i) => {
    executingresultdisplayLbl.push(
      <span className={lblClass} key={'executingresultdisplayLbl' + i}>
        {lbl}
      </span>
    );
  });

  const checklistLbl: React.ReactElement[] = [];
  labels.AIKCMN001000001_BUTTON_FUNCTION_CHECKLIST.split('\n').forEach((lbl, i) => {
    checklistLbl.push(
      <span className={lblClass} key={'checklistLbl' + i}>
        {lbl}
      </span>
    );
  });

  const aiSupportLbl: React.ReactElement[] = [];
  'AI仕訳チェック'.split('\n').forEach((lbl, i) => {
    aiSupportLbl.push(
      <span className={lblClass} key={'AIJournalCheckLbl' + i}>
        {lbl}
      </span>
    );
  });

  const aiCaptureLbl: React.ReactElement[] = [];
  'データ取込'.split('\n').forEach((lbl, i) => {
    aiCaptureLbl.push(
      <span className={lblClass} key={'aiCaptureLbl' + i}>
        {lbl}
      </span>
    );
  });

  const auditresultlistLbl: React.ReactElement[] = [];
  labels.AIKCMN001000001_BUTTON_FUNCTION_AUDITRESULTLIST.split('\n').forEach((lbl, i) => {
    auditresultlistLbl.push(
      <span className={lblClass} key={'auditresultlistLbl' + i}>
        {lbl}
      </span>
    );
  });

const migrationLbl: React.ReactElement[] = [];
    labels.AIKCMN001000001_BUTTON_HEADER_DATAIMPORT.split('\n').forEach((lbl, i) => {
        migrationLbl.push(
            <span className={lblClass} key={'migrationLbl' + i}>
            {lbl}
        </span>
    );
    });


    const dataImportSection = props.cooperation == CooperationEnum.Other ? (
        <section>
            <h2 className={headerClass}>{labels.AIKCMN001000001_BUTTON_HEADER_DATAIMPORT}</h2>
            <Nav>
                <NavItem>
                    <NavLink
                        className={navLinkClass}
                        onClick={props.onMigrationClick}
                        tabIndex={116}
                        href='javascript:void(0);'
                    >
                        <Card className={cardClass}>
                            <CardImg src={AiCaptureIcon} />
                            <CardText>{aiCaptureLbl}</CardText>
                        </Card>
                    </NavLink>
                </NavItem>
            </Nav>
        </section>
    ):
        (null);

  return (
    <div className='app-menu'>
      <section>
        <h2 className={headerClass}>{labels.AIKCMN001000001_BUTTON_HEADER_AUDITEXECUTION}</h2>
        <Nav>
          <NavItem>
            <NavLink
              className={navLinkClass}
              onClick={props.onExecutionResultClick}
              tabIndex={112}
              href='javascript:void(0);'
            >
              <Card className={cardClass}>
                <CardImg src={CheckMenuIcon} />
                <CardText className={cardTextClass}>{executingresultdisplayLbl}</CardText>
              </Card>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={navLinkClass}
              onClick={props.onAIJournalCheckClick}
              tabIndex={113}
              href='javascript:void(0);'
            >
              <Card className={cardClass}>
                <CardImg src={AIJournalMenuIcon} />
                <CardText className={cardTextClass}>{aiSupportLbl}</CardText>
              </Card>
            </NavLink>
          </NavItem>
        </Nav>
      </section>
      {/*
      <section>
        <h2 className={headerClass}>{labels.AIKCMN001000001_BUTTON_HEADER_CHECKLIST}</h2>
        <Nav>
          <NavItem>
            <NavLink className={navLinkClass} onClick={props.onChecklistClick} tabIndex={114} href='javascript:void(0);' >
              <Card className={cardClass}>
                <CardImg src={NotesMenuIcon} />
                <CardText>{checklistLbl}</CardText>
              </Card>
            </NavLink>
          </NavItem>
        </Nav>
      </section>
      */}
      <section>
        <h2 className={headerClass}>{labels.AIKCMN001000001_BUTTON_HEADER_AUDITRESULTLIST}</h2>
        <Nav>
          <NavItem>
            <NavLink
              className={navLinkClass}
              onClick={props.onAuditResultListClick}
              tabIndex={115}
              href='javascript:void(0);'
            >
              <Card className={cardClass}>
                <CardImg src={ResultMenuIcon} />
                <CardText>{auditresultlistLbl}</CardText>
              </Card>
            </NavLink>
          </NavItem>
        </Nav>
       </section>
       {dataImportSection}
    </div>
  );
};
export default AuditMonthlyMenu;
