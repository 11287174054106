import React from 'react';
import Axios from 'axios';
import { ColumnsInfoViewModel } from '../../../models/columnsInfoViewModel';

// グリッドヘッダーVO
export class GridHeaderVo {
  // ヘッダー表示名
  public header?: string;
  // ヘッダーセル
  public cells?: Array<GridCellsVo>;
  // セル結合
  public colspan?: number;
}
// グリッドセルVO
export class GridCellsVo {
  // バインディング
  public binding?: string;
  // 幅
  public width?: number | string;
  // 配置位置
  public align?: string;
  // フォーマット
  public format?: string;
  // 最小幅
  public minWidth?: number;
}

// ヘッダー情報取得URL
export const getColInfo = 'api/v1/GridHeaderRenewal/Get';
// ヘッダー情報更新URL
export const updateColInfo = 'api/v1/GridHeaderRenewal/Create';
/** プロパティ */
export type GridHeaderRenewalManagerProps = {
  // 機能ID
  featureId: string;
  // 表示列情報通知
  sendColumn: (col: any) => void;
  // 起動フラグ
  activated: boolean;
  // 基本列属性
  defaultColumns: Array<GridHeaderVo>;
  // 共有機能IDリスト
  share?: Array<string>;
};
/**
 * グリッドヘッダー報更新コンポーネント
 * @param props
 */
const GridHeaderRenewalManager: React.FC<GridHeaderRenewalManagerProps> = props => {
  const { featureId, sendColumn, activated, defaultColumns, share } = props;
  const [isInit, setIsInit] = React.useState(true);
  /** 初期処理 */
  async function initialize() {

    const url = [getColInfo, encodeURI(featureId)].join('/');

    // 列情報取得
    const response = await Axios.get(url);
    // 取得情報があれば取得情報から表示列の情報を作成する
    var data = response.data as Array<any>;
    if (data.length > 0) {
      // パターン番号設定
      searchPattern(data);
      // 行ヘッダー情報作成
      createColumns(response.data);
    }
  }
  // 列属性パターン番号
  const [pattern, setPattern] = React.useState(0);
  /**
   * 除外行名取得
   * @param num パターン番号
   */
  const eliminateStr = (num: number): Array<string> => {
    var arrStr: Array<string> = [];
    // パターンから除外文字を設定する
    switch (num) {
      case 1:// 部門項目：表示、補助項目：非表示、期日項目：非表示
        arrStr = ["補助", "期日"];
        break;
      case 2:// 部門項目：非表示、補助項目：表示、期日項目：非表示
        arrStr = ["部門", "期日"];
        break;
      case 3:// 部門項目：非表示、補助項目：非表示、期日項目：非表示
        arrStr = ["部門", "補助", "期日"];
        break;
      default:// すべて表示
    }
    return arrStr;
  }
  /**
   * パターン番号検索
   * @param data 列情報
   */
  const searchPattern = (data: Array<any>) => {
    var hiddenStr: Array<any> = [];
    // 列情報から非表示列を取得する
    data.forEach((d) => {
      if (d['IsVisible'] === 0 && d['ColName'] != null) {
        var str = d['ColName'] as string;
        if (str.indexOf("部門") >= 0) {
          // 部門行
          hiddenStr.push("部門");
        } else if (str.indexOf("補助") >= 0) {
          // 補助行
          hiddenStr.push("補助");
        } else if (str.indexOf("期日") >= 0) {
          // 期日行
          hiddenStr.push("期日");
        }
      }
    });
    if (hiddenStr.length == 0) {
      // 全行表示
      setPattern(0);
    } else if (hiddenStr.includes("部門")
      && hiddenStr.includes("補助")
      && hiddenStr.includes("期日")
    ) {
      // 部門・補助・期日行非表示
      setPattern(3);
    } else if (hiddenStr.includes("部門")) {
      // 部門・期日行非表示
      setPattern(2);
    } else {
      // 補助・期日行非表示
      setPattern(1);
    }
  }
  /** 更新処理 */
  const refreshColumn = () => {
    // 除外行
    var eliminate = eliminateStr(pattern + 1);
    // 列情報
    var colInfoList: Array<ColumnsInfoViewModel> = [];
    // 基本列属性から表示列の情報を作成する
    defaultColumns.forEach(col => {
      // 行表示名に除外文字が含まれているか確認する
      let flg = eliminate.some(str => {
        return col.header!.indexOf(str) >= 0;
      });
      // 除外文字が含まれている情報は表示フラグをOFFにする
      colInfoList.push({
        ClientCode: 0,
        ColName: col.header,
        FeatureKbn: '',
        IsVisible: flg ? 0 : 1
      });
    });
    // 列情報から表示列の情報を作成する
    var updateData = createColumns(colInfoList);
    // DB更新
    creatColInfo(updateData);
    // 列属性パターンを設定
    setPattern(pattern == 3 ? 0 : pattern + 1);
  }
  /**
   * 列情報(DB)更新処理
   * @param data 列情報
   */
  async function update(data: Array<ColumnsInfoViewModel>) {
    // 更新データ
    var updateData = { 'update': data, 'groupId': share };
    // 更新post
    Axios.post(updateColInfo, updateData);
  }
  /**
   * 更新データ作成
   * @param data 行ヘッダー情報
   */
  const creatColInfo = (data: Array<GridHeaderVo>) => {
    var colInfoList: Array<ColumnsInfoViewModel> = [];
    // 一致フラグ
    var flg = false;
    // 基本列属性から列情報ViewModelを作成する
    defaultColumns.forEach(v => {
      data.some(d => {
        return flg = d.header == v.header;
      });
      // 行ヘッダー情報にない行は表示フラグを0にする
      var columnsInfoViewModel: ColumnsInfoViewModel = {
        FeatureKbn: featureId,
        ColName: v.header,
        IsVisible: flg ? 1 : 0
      }
      colInfoList.push(columnsInfoViewModel);
    });
    // 更新する
    update(colInfoList);
  }
 /**
  * 行ヘッダー情報作成
  * @param data 列情報
  */
  const createColumns = (data: Array<ColumnsInfoViewModel>): any => {
    
    var displayColumns: Array<GridHeaderVo> = [];
    // 基本列属性からヘッダー情報を作成する
    defaultColumns.forEach((item) => {
      // 表示フラグがONの行だけヘッダー情報に設定する
      data.forEach(v => {
        if (v.ColName == item.header && v.IsVisible == 1) {
          displayColumns.push(item);
        }
      });
    });
    // 行ヘッダー情報通知
    sendColumn(displayColumns);
    // 行ヘッダー情報返却
    return displayColumns;
  }
  /** ヘッダー更新処理 */
  React.useEffect(() => {
    // 初期表示時は、ヘッダーを更新しない
    if (isInit) {
      setIsInit(false);
      return;
    }
    // ヘッダー更新
    refreshColumn();

  }, [activated]);
  // 初期処理
  React.useEffect(() => {
    initialize();
  }, []);
    return (
      <div></div>
    );
};
export default GridHeaderRenewalManager;
