import { BizUtil } from "./Biz/bizUtil";
import { Messages } from "./message";
import { MjsUtilities } from "./usefuls/utilities";
import "./usefuls/utilities";

enum InputErrorMessage {
    none = 0,
    inCorrect,                  // コードを正しく入力してください。
    notExistCode,               // 該当コードがありません。
    pleaseEnter,                // {0}を入力して下さい
    mistake,                    // {0}が間違っています。
    sizeCheck,                  // 正しい終了金額を入力してください。
    anyCheck,                   // 正しい{0}を入力してください。
    requiredAnyCheck,           // 正しい{0}を必ず入力してください。
    tekiyoOverSelect,           // 固定摘要は{0}個までです。
    fixMonthSelect,             // "{0}月度には追加できません。（{1}のため）"
    sixthIndustryDisableAtDate, // 第６種事業は平成27年3月31日以前の日付では入力できません。
    impossibleTaxCombination,   // 消費税の入力できない仕訳取引パターンです。
    ngWordString,               // {0}に使用できない文字が使われています。
    misMatchTaxRate,            // 消費税率の組み合わせが異なっています。
    tekiyoOverLengthPurchaseTaxCreditInTekiyo,      // 仕入税額控除の記載事項「仕入日」「仕入先」を入力する場合は摘要を全角22文字以内で入力してください。
    tekiyoOverLengthPurchaseTaxCreditNotInTekiyo,   // 摘要は全角64文字以内で入力してください。
    impossibleExpandCombination,// この仕訳は展開方式が認められていません。
    pleaseEnterInputRange,      // 入力範囲内で{0}を指定してください。
    misTogaiPeriodInfo          // 当該情報の適用期間が範囲外です。
}

export class messageUtil {
    /**
     * 入力系で表示するメッセージ
     */
    static readonly InputErrorMessage = InputErrorMessage;
    readonly InputErrorMessage = messageUtil.InputErrorMessage;

    public static createErrorMessage(messageKbn: InputErrorMessage, name: string): string {
        switch (messageKbn) {
            // コードを正しく入力してください。
            case InputErrorMessage.inCorrect:
                return messageUtil.getMsg(Messages.Entry.Journal.Validate.INCORRECT);
            // 該当コードがありません。
            case InputErrorMessage.notExistCode:
                return messageUtil.getMsg(Messages.Entry.Journal.Validate.NOTEXISTCODE);
            // ***を入力して下さい。
            case InputErrorMessage.pleaseEnter:
                return messageUtil.getMsg(Messages.Entry.Journal.Information.PLEASEENTER).format(name);
            // ***が間違っています。
            case InputErrorMessage.mistake:
                return messageUtil.getMsg(Messages.Entry.Journal.Validate.MISTAKE).format(name);
            // 正しい終了金額を入力してください。
            case InputErrorMessage.sizeCheck:
                return messageUtil.getMsg(Messages.Entry.Journal.Validate.SIZECHECK);
            // 正しい{0}を入力してください。
            case InputErrorMessage.anyCheck:
                return messageUtil.getMsg(Messages.Entry.Journal.Validate.ANYCHECK).format(name);
            // 正しい{0}を必ず入力してください。
            case InputErrorMessage.requiredAnyCheck:
                return messageUtil.getMsg(Messages.Entry.Journal.Validate.REQUIREDANYCHECK).format(name);
            // 固定摘要は{0}個までです。
            case InputErrorMessage.tekiyoOverSelect:
                return messageUtil.getMsg(Messages.Entry.Journal.Validate.TEKIYOOVERSIZE).format(name);
            // "{0}月度には追加できません。（{1}のため）"
            case InputErrorMessage.fixMonthSelect:
                return messageUtil.getMsg(Messages.Entry.Journal.Validate.NOTADD).format(name, "月次確定");
            case InputErrorMessage.sixthIndustryDisableAtDate:
                return messageUtil.getMsg(Messages.Entry.Journal.Validate.SIXTHINDUSTRYDISABLEATDATE);
            // 消費税の入力できない仕訳取引パターンです。
            case InputErrorMessage.impossibleTaxCombination:
                return messageUtil.getMsg(Messages.Entry.Journal.Validate.IMPOSSIBLETAXCOMBINATION);
            // {0}に使用できない文字が使われています。
            case InputErrorMessage.ngWordString:
                return messageUtil.getMsg(Messages.Entry.Journal.Validate.XSS).format(name);
            // 消費税率の組み合わせが異なっています。
            case InputErrorMessage.misMatchTaxRate:
                return messageUtil.getMsg(Messages.Entry.Journal.Validate.MISMATCHTAXRATE);
            // 仕入税額控除の記載事項「仕入日」「仕入先」を入力する場合は摘要を全角22文字以内で入力してください。
            case InputErrorMessage.tekiyoOverLengthPurchaseTaxCreditInTekiyo:
                return messageUtil.getMsg(Messages.Entry.Purchasetaxdeduction.Validate.TEKIYOLENGTH);
            // 摘要は全角64文字以内で入力してください。
            case InputErrorMessage.tekiyoOverLengthPurchaseTaxCreditNotInTekiyo:
                return messageUtil.getMsg(Messages.Entry.Common.Validate.TEKIYOLENGTH);
            // この仕訳は展開方式が認められていません。
            case InputErrorMessage.impossibleExpandCombination:
                return messageUtil.getMsg(Messages.Entry.Journal.Validate.IMPOSSIBLEEXPANDCOMBINATION);
            // 入力範囲内で{0}を指定してください。
            case InputErrorMessage.pleaseEnterInputRange:
                return messageUtil.getMsg(Messages.Entry.Journal.Information.PLEASEENTERINPUTRANGE).format(name);
            // 当該情報の適用期間が範囲外です。
            case InputErrorMessage.misTogaiPeriodInfo:
                return messageUtil.getMsg(Messages.Entry.Journal.Validate.OUTOFAPPLICATIONPERIOD);
        }
        return ""
    }

    /**
     * 複数のメッセージ一覧で定義されたメッセージから適切なメッセージを取得する。エラーの場合、nullを返す。
     * @param msgList 複数のメッセージ一覧で定義されたメッセージ(群)
     */
    public static getMsg(msgList: string[]): string {

        /** メッセージ一覧の配列番号:通常 */
        const MSG_INDEX_DEFAULT: number = 0;

        /** メッセージ一覧の配列番号:直販 */
        const MSG_INDEX_PORTABLE: number = 1;

        if (MjsUtilities.isNull(msgList)) {
            return null!;
        }
        if (MjsUtilities.isNull(msgList.length)) {
            return null!;
        }
        if (msgList.length <= 0) {
            return null!;
        }
        let index: number = MSG_INDEX_DEFAULT;
        if (BizUtil.IsPortableUser()) {
            index = MSG_INDEX_PORTABLE;
        }
        return msgList[index];
    }
}
