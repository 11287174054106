import * as wjInput from 'wijmo/wijmo.input';
import * as wijmo from 'wijmo/wijmo';
import { MjsUtilities } from '../../../common/usefuls/utilities';
import { InputBase } from './inputBase';
import $ from 'jquery';

/**
 * 数値入力コントロール
 */
export class NumericInput extends InputBase {

    private mDecimalLength: number;
    private mMin: number;
    private mMax: number;
    private mMaxLength!: number;
    private mMinLength!: number;
    private mShowSpinner: boolean;
    private mCanInputNumberOnly!: boolean;
    private mFilterRegExpForKeyInput!: RegExp;
    private mInputWidth: number;
    protected mInputControl!: wjInput.InputNumber;

    // 必須入力設定（true=必須入力／false=任意入力(入力値のクリア可)）
    public get isRequired(): boolean { return this.mInputControl.isRequired; }
    public set isRequired(value: boolean) { this.mInputControl.isRequired = value; }

    // 小数桁数
    public get decimalLength(): number { return this.mDecimalLength; }
    public set decimalLength(value: number) {
        this.mDecimalLength = value;
        // フォーマットの設定
        this.setFormat = this.format;
    }

    public get getFormat(): string { return this.mInputControl.format; }
    public set setFormat(value: string) { this.mInputControl.format = value; }

    // 最大値を途中で変更する
    public get maxValue(): number { return this.mMax; }
    public set maxValue(value: number) {
        // MaxLengthを設定する
        this.setMaxLength(value);

        this.mMax = value;
    }

    // 最小値を途中で変更する
    public get minValue(): number { return this.mMin; }
    public set minValue(value: number) {
        // MinLengthを設定する
        this.setMinLength(value);

        this.mMin = value;
    }

    /** 半角数値のみ入力可能フラグ  */
    public get canInputNumberOnly(): boolean { return this.mCanInputNumberOnly; }
    public set canInputNumberOnly(value: boolean) { this.mCanInputNumberOnly = value; }

    /** キー入力可能文字制限の正規表現  */
    public get filterRegExpForKeyInput(): RegExp { return this.mFilterRegExpForKeyInput; }
    public set filterRegExpForKeyInput(value: RegExp) { this.mFilterRegExpForKeyInput = value; }

    /**
     * コンストラクタ
     * @param baseTagId: コントロール配置対象要素のID
     * @param $scope
     * @param $compile
     * @param caption: 見出しラベルの表示文字列。Nullが指定された場合は見出し領域非表示。
     * @param decimalLength: 小数点以下桁数。Null指定の場合は整数入力モードで動作。
     * @param min: 許容最小値
     * @param max: 許容最大値
     * @param showSpinner: スピンボタンの表示有無
     * @param inputWidth: 入力領域の横幅
     */
    constructor(baseTagId: string, $scope: any, $compile: any, caption: string, decimalLength?: number, min?: number, max?: number, showSpinner?: boolean, inputWidth?: number);
    constructor(baseTagId: JQuery, $scope: any, $compile: any, caption: string, decimalLength?: number, min?: number, max?: number, showSpinner?: boolean, inputWidth?: number);
    constructor(baseTagId: any, $scope: any, $compile: any, caption: string, decimalLength: number = null!, min: number = null!, max: number = null!, showSpinner: boolean = false, inputWidth: number = null!) {
        super(baseTagId, $scope, $compile, caption);
        this.mDecimalLength = decimalLength;

        this.mMin = min;
        // 最小値に使用できる文字数を取得
        this.setMinLength(min);

        this.mMax = max;
        // 最大値に使用できる文字数を取得
        this.setMaxLength(max);

        this.mShowSpinner = showSpinner;
        this.mInputWidth = inputWidth;
    }

    /**
     * 最大値に対して最大何文字使用できるかを設定する
     * @param value
     */
    private setMaxLength(value: number) {

        if (value) {
            this.mMaxLength = value.toString().length;
        }

    }

    /**
     * 最小値に対して最大何文字使用できるかを設定する
     * @param value
     */
    private setMinLength(value: number) {

        if (value) {
            if (value < 0) {
                this.mMinLength = value.toString().length;
            }
        }

    }

    public get inputControl(): wjInput.InputNumber { return this.mInputControl; }
    protected get format(): string { return this.mDecimalLength != null || this.mDecimalLength == 0 ? "n" + this.mDecimalLength : "d"; }

    protected postInitialize(): void {
        // spinnerの表示制御
        if (this.mShowSpinner) {
            this.mInputControl.step = 1;
            this.mInputControl.showSpinner = true;
            $(this.mInputControl._btnUp).on("click", e => this.spinnerClick(e));
            $(this.mInputControl._btnDn).on("click", e => this.spinnerClick(e));
        } else {
            this.mInputControl.showSpinner = false;
        }

        this.mInputControl.valueChanged.addHandler(this.valueChangeHandler, this);
        this.mInputControl.textChanged.addHandler(this.textChangeHandler, this);
        $(this.mInputControl.inputElement).keypress(e => {
            let inChar: string = e.key || "";

            // 数値のみ入力を許可する場合
            if (this.mCanInputNumberOnly) {
                // 数字以外だった場合は入力キャンセル
                if (!inChar.match(/\d/)) {
                    e.preventDefault();
                    return false;
                }
            }

            // キー入力可能文字制限の正規表現が設定されていた場合
            if (this.filterRegExpForKeyInput != null) {
                // 入力可能文字以外の場合は入力キャンセル
                if (!inChar.match(this.filterRegExpForKeyInput)) {
                    e.preventDefault();
                    return false;
                }
            }
            return undefined!;
        });
    }

    /**
     * 値(Text値ではなくValue値)が変更された場合の処理
     * → 文字入力制限を超えた入力を抑えるため。
     * → Min/Max値を超えた入力が行われた場合、かつ文字数がMaxで指定された数値の桁数で計算。
     * 課題：いまのところMax値で文字列長さを取得するため、Max値よりもMin値の方が文字数が長い場合
     * 想定通りに動かないため、どこかのタイミングでmMaxLengthの値の設定の仕方を考慮する必要がある。
     * @param e
     */
    private valueChangeHandler(a: wjInput.InputNumber, e: wijmo.EventArgs) {
        //現時点で入力されている文字列
        let newValueString: string = this.mInputControl.text.replace(/,/g, "");
        //直前に入力されていた文字列
        let oldValueString: string = a._oldText ? a._oldText.replace(/,/g, "") : "";
        //入力された文字列を浮動小数点数または整数に変換する
        let newValue: number = this.mDecimalLength != null ? parseFloat(newValueString) : parseInt(newValueString);

        // 最大値よりも大きければ、最大値の数値とする
        if (this.mMax != null && newValue > this.mMax) {

            // Max値を上回ったケース
            let settingText: string;
            if (newValue.toString().length === this.mMaxLength) {

                settingText = oldValueString ? oldValueString : MjsUtilities.cutString(newValueString, this.mMaxLength - 1);

            } else if (this.mDecimalLength > 0) {
                // 小数点付最大値対応（整数のみ）

                // 整数部1桁目を削る（ex:1234 -> 123）
                //  newValueString.substr(0, newValueString.indexOf('.') - 1) : 整数部1桁目より左（上の桁）の文字列
                //  newValueString.substr(newValueString.indexOf('.'))        : 小数部分（小数点含む）
                settingText = oldValueString ? oldValueString : newValueString.substr(0, newValueString.indexOf('.') - 1)
                    + newValueString.substr(newValueString.indexOf('.'));

            } else {

                // 最大値そのものが入力された場合に最大値を超えない様に文字列を削って設定する
                settingText = oldValueString ? oldValueString : MjsUtilities.cutString(newValueString, this.mMaxLength);

            }

            this.mInputControl.text = settingText;

        } else if (this.mMin != null && newValue < this.mMin) {

            // Min値を下回ったケース
            let settingText: string;
            if (newValue.toString().length === this.mMinLength) {

                // Min値の文字列を作成して設定する
                settingText = oldValueString ? oldValueString : MjsUtilities.cutString(newValueString, this.mMinLength - 1);
                this.mInputControl.text = settingText;

            } else if (newValue.toString().length > this.mMinLength) {

                // Min値の文字列を作成して設定する
                settingText = MjsUtilities.cutString(newValueString, this.mMinLength);
                this.mInputControl.text = oldValueString ? oldValueString : settingText;

            }

            //最小値が0以上の時に-を入力した場合は-を取り除く
            if (this.mMin >= 0) {
                if (newValueString.indexOf('-') === 0) this.mInputControl.text = newValueString.slice(1);
            }

        }
    }


    /**
     * テキスト変更時のイベントハンドラ
     * @param e
     */
    private textChangeHandler(e: wijmo.EventArgs) {
        // テキストチェンジなので、テキストからとる必要がある。
        let numberString: string = this.mInputControl.text.replace(/,/g, "");
        let newValue: number = this.mDecimalLength != null ? parseFloat(numberString) : parseInt(numberString);

        //if (isNaN(newValue)) {
        //    return null;
        //} else {
        //    // 最大値よりも大きければ、最大値の数値とする
        //    if (this.mMax != null && newValue > this.mMax) {
        //        newValue = this.mMax;
        //        this.mInputControl.text = newValue.toString();
        //        return;
        //    }

        //    // 最大値よりも大きければ、最大値の数値とする
        //    if (this.mMin != null && newValue < this.mMin) {
        //        newValue = this.mMin;
        //        this.mInputControl.text = newValue.toString();
        //        return;
        //    }
        //}

        this.setValueToViewModel(this.getCtrlList()[0], newValue);
    }

    /**
     * 値を設定する
     * @param value
     */
    public setValue(value: number) {
        this.mInputControl.value = value;
    }

    /**
     * 値を取得する
     */
    public getValue(): number {
        return this.mInputControl.value;
    }
    /**
     * 入力テキストを設定する
     */
    public setText(value: string) {
        this.mInputControl.text = value;
    }
    /**
     * 入力テキストを取得する
     */
    public getText(): string {
        return this.mInputControl.text;
    }

    // override
    public setFocus(): void {
        this.mInputControl.inputElement.focus();
    }

    protected createInputControl($owner: JQuery): any {
        // マウススクロールイベントを止める処理
        // ■コントロール作成前に実施している理由
        // 　Wijmo側不具合の回避策。詳細はBUG50775参照
        var mousewheelevent = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';
        $owner.on(mousewheelevent, function (e: any) {
            e.preventDefault();
        });

        //this.mInputControl = new wjInput.InputNumber($owner, { format: this.format, isRequired: false, min: this.mMin, max: this.mMax, value: null });
        this.mInputControl = new wjInput.InputNumber($owner, { format: this.format, isRequired: false, min: null, max: null, value: null });

        // キャレット移動をする/しないの設定
        $(this.mInputControl.inputElement).attr(
            InputBase.inputType,
            InputBase.typeNumInput
        );

        //var maxLength = this.mMax == null ? 9 : this.mMax.toString().length;
        if (this.mInputWidth != null) {
            $(this.mInputControl.inputElement).attr("style", "width:" + this.mInputWidth + "px;");
        }

        //this.setAngularDirective($(this.mInputControl.inputElement), "angular");
        this.createAngularDirectiveInfo($(this.mInputControl.inputElement), "angular");
        this.setAngularWatchEventHandler();

        this.mInputControl.lostFocus.addHandler(this.input_lostFocus, this);
        $(this.mInputControl.inputElement).keydown((e: JQueryKeyEventObject) => this.keyDown.raise(this, e));

        return this.mInputControl;
    }

    /**
     * AngularのCompile実行前に行いたい処理がある場合にここに記述
     */
    protected setAngularWatchEventHandler() {
        let ngModel: string = this.getNgModelValue(this.getCtrlList()[0]);
        if (ngModel) {
            this.$scope.$watch(ngModel, (newValue: { toString: () => string; } | null, oldValue: any) => {
                let val: number = (newValue == null) ? NaN : Number(newValue.toString().replace(/,/g, ""));
                let actControlVal: number = this.mInputControl.value;

                this.mInputControl.deferUpdate(() => {
                    if (isNaN(val)) {
                        this.mInputControl.value = null!;
                    } else {
                        this.mInputControl.value = val;
                    }
                });
                if (actControlVal == this.mInputControl.value) {
                    this.mInputControl.onTextChanged(wijmo.EventArgs.empty);
                }
            });
        }
    }

    private spinnerClick(e: JQueryEventObject) {
        if (this.mInputControl.text == "") {
            this.mInputControl.value = 0;
        }
    }

    private input_lostFocus(e: any) {

        //現時点で入力されている文字列
        let newValueString: string = this.mInputControl.text.replace(/,/g, "");

        //入力された文字列を浮動小数点数または整数に変換する
        let newValue: number = this.mDecimalLength != null ? parseFloat(newValueString) : parseInt(newValueString);

        if ((this.mMin != null) && (newValue < this.mMin) && (0 < this.mMin)) {
            this.setText("");
            this.setFocus();
            return;
        }

        this.lostFocus.raise(this, wijmo.EventArgs.empty);
    }

    public getCtrlList(): Array<JQuery> {
        return [$(this.mInputControl.inputElement)];
    }

    /**
     * コントロールのEnable/Disable制御を行う。
     * @param flg
     */
    public set enabled(flg: boolean) {
        super.setBaseTagEnable(flg);
        this.mInputControl.isDisabled = !flg;
    }

    /**
     * コントロールのEnable/Disable制御を取得する。
     *
     */
    public get enabled(): boolean {
        return !this.mInputControl.isDisabled;
    }

    /**
     * 入力内容に対する検証を行う
     * @param showError: エラー情報を表示するならtrue
     */
    public validate(showError: boolean = false): boolean {
        return true;
    }
}