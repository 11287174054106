import React from 'react';

import { Card, CardImg, CardText } from 'reactstrap';
import { Nav, NavItem, NavLink } from 'reactstrap';

import ContractInfoIcon from '../../../images/vkz/ico_menu_contract.png';
import UserInfoIcon from '../../../images/vkz/ico_menu_admin_update.png';

type IAuditAdminMenuProps = {
    onUserInfoClick: () => void;
    onContractInfoClick: () => void;
};

const headerClass = 'h6 text-lbl';
const navLinkClass = 'cursor-pointer p-0 mx-3 my-2';
const lblClass = 'small text-black d-block text-center';
const cardClass = 'border-0 justify-content-center align-items-center _item';
const cardTextClass = 'm-2';

const AuditAdminMenu = (props: IAuditAdminMenuProps) => {
  const contractInfoLbl: React.ReactElement[] = [];
  '契約情報'.split('\n').forEach((lbl, i) => {
    contractInfoLbl.push(
      <span className={lblClass} key={'contractInfoLbl' + i}>
        {lbl}
      </span>
    );
  });
  const userInfoLbl: React.ReactElement[] = [];
  'ユーザー情報の確認・変更'.split('\n').forEach((lbl, i) => {
    userInfoLbl.push(
      <span className={lblClass} key={'userInfoLbl' + i}>
        {lbl}
      </span>
    );
  });
  return (
    <div className='app-menu'>
      <section>
        <h2 className={headerClass}>{'管理者用'}</h2>
        <Nav>
          <NavItem>
            <NavLink
              className={navLinkClass}
              onClick={props.onContractInfoClick}
              tabIndex={120}
              href='javascript:void(0);'
            >
              <Card className={cardClass}>
                <CardImg src={ContractInfoIcon} />
                <CardText className={cardTextClass}>{contractInfoLbl}</CardText>
              </Card>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={navLinkClass}
              onClick={props.onUserInfoClick}
              tabIndex={121}
              href='javascript:void(0);'
            >
              <Card className={cardClass}>
                <CardImg src={UserInfoIcon} />
                <CardText className={cardTextClass}>{userInfoLbl}</CardText>
              </Card>
            </NavLink>
          </NavItem>
        </Nav>
      </section>
    </div>
  );
};
export default AuditAdminMenu;
