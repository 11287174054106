import {MonthlyBalanceRuleItemVO} from "./monthlyBalanceRuleItemVO";
import {RulePatternEnum} from "../constants/enums";
import {MonthlyBalanceRulePatternItemVO} from "./monthlyBalanceRulePatternItemVO";
import {MasterBaseInformationVO} from "./masterBaseInformationVO";
import {HojyoInformationVO} from "./hojyoInformationVO";
import {AccountInformationVO} from "./accountInformationVO";
/**
 * 月次残高ルール設定ViewModel ViewModel クラス
 */

export class MonthlyBalanceRuleSettingViewModel {
    /** 月次残高ルールVO */
    public RuleItems?:Array<MonthlyBalanceRuleItemVO>;

    /** ルールパターン名称 */
    public RulePatternName?:string;

    /** ルールパターン標準設定フラグ */
    public RulePatternDefaultSettingFlg?:boolean;

    /** 標準設定パターン */
    public DefaultRulePattern?:RulePatternEnum;

    /** 標準設定パターン部門用 */
    public DefaultRulePatternBmn?:RulePatternEnum;

    /** 監査対象 */
    public AuditTarget?:number;

    /** 月次残高ルール科目用VO */
    public RuleItemKmks?:Array<MonthlyBalanceRuleItemVO>;

    /** 月次残高ルール部門用VO */
    public RuleItemBmns?:Array<MonthlyBalanceRuleItemVO>;

    /** 月次残高ルールパターンVO */
    public RulePatternItems?:Array<MonthlyBalanceRulePatternItemVO>;

    /** 更新行数リミット */
    public UpdateLimit?:number;

    /** マスタ基本情報VO */
    public MasterInfoItems?:MasterBaseInformationVO;

    /** 補助情報VO */
    public HojyoInfoItems?:Array<HojyoInformationVO>;

    /** 科目情報VO */
    public AccountInfoItems?:Array<AccountInformationVO>;

}
