import {AuditResultTaxKubunVO} from "./auditResultTaxKubunVO";
/**
 * 監査結果(消費税区分)ViewModel ViewModel クラス
 */

export class AuditResultTaxKubunViewModel {
    /** 監査結果(消費税区分)VO */
    public Results?:Array<AuditResultTaxKubunVO>;

    /** 対象月 */
    public TMonth?:string;

    /** 最終実行日時 */
    public LastExecDate?:Date;

}
