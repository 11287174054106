import { MjsUtilities } from "../../../common/usefuls/utilities";
import $ from 'jquery';

/**
 * Windowオープンを行なうモジュール
 *
 * [使用方法]
    1. 対象のページ（cshtml）で本モジュールを読み込んでください
        例）
        <script type="text/javascript" src="@Url.Content("~/Scripts/mjs/support/component/drillDown/windowUtil.js")"></script>

    2. 以下のようにするとWindowが開きます
        POST編）
        mjs.acelink.vkz.support.component.drillDown.WindowUtil.postOpen(
            "https://yahoo.co.jp"
            , {"a": "111", "b": "222"}
            , new mjs.acelink.vkz.support.component.drillDown.WindowOption()
            );

        GET編）
        mjs.acelink.vkz.support.component.drillDown.WindowUtil.getOpen(
            "https://yahoo.co.jp"
            , {"a": "111", "b": "222"}
            , new mjs.acelink.vkz.support.component.drillDown.WindowOption()
            );

    3. GET, POST使用時のパラメーター
        params: ObjectはViewModel系を引数にすることが可能です。


    注意）Post時のパラメーターをサーバー側で正常に取得できるかの検証はできておりません。

 */

export class WindowUtil {

    public static tokenValue: string;
    public static hsTokenValue: string;
    public static selectedSystemDiv: string;
    public static lastSelectedMenuCategoryId: string;

    private static _url: string;
    private static _target: string = "mjs_drill_down_window";
    private static _method: string;
    private static _params: Object;
    private static _option: WindowOption;
    private static _window: Window;
    private static _withoutToken: boolean = false;
    private static _withoutSystemType: boolean = false;
    private static _withoutMenuId: boolean = false;

    /**
     * GETでWindowオープンをする（別Window）
     * @param url
     * @param params
     * @param option
     * @param target
     */
    public static getOpen(url: string, params: Object, option: WindowOption, target?: string) {
        if (typeof target !== "undefined") {
            WindowUtil._target = target;
        }
        else {
            WindowUtil._target = WindowUtil.getUniqueTargetName();
        }

        WindowUtil._url = url;
        WindowUtil._method = "GET";
        WindowUtil._params = params;
        WindowUtil._option = option;
        WindowUtil.appendQueryString();
        WindowUtil.open();
    }


    /**
     * POSTでWindowオープンをする（別Window）
     * @param url
     * @param params
     * @param option
     * @param target
     */
    public static postOpen(url: string, params: Object, option: WindowOption, target?: string, withoutToken?: boolean, withoutSystemType?: boolean, withoutMenuId?: boolean) {
        if (typeof target !== "undefined") {
            WindowUtil._target = target;
        }
        else {
            WindowUtil._target = WindowUtil.getUniqueTargetName();
        }

        if (withoutToken) {
            WindowUtil._withoutToken = true;
        }
        else {
            WindowUtil._withoutToken = false;
        }

        if (withoutSystemType) {
            WindowUtil._withoutSystemType = true;
        } else {
            WindowUtil._withoutSystemType = false;
        }

        if (withoutMenuId) {
            WindowUtil._withoutMenuId = true;
        } else {
            WindowUtil._withoutMenuId = false;
        }

        WindowUtil._url = url;
        WindowUtil._method = "POST";
        WindowUtil._params = params;
        WindowUtil._option = option;
        WindowUtil.submit();
    }

    /**
     * GET用のQueryパラメーターを生成する
     */
    private static appendQueryString(): void {
        if (WindowUtil._params != null) {
            WindowUtil._params = MjsUtilities.stringify(WindowUtil._params);
            let query = "";
            for (let key in WindowUtil._params) {
                query += "&" + key + "=" + WindowUtil._params[key];
            }

            query += "&__RequestVerificationToken=" + WindowUtil.tokenValue;
            query += "&__VSVerificationToken=" + WindowUtil.hsTokenValue;

            // 先頭の「&」をカットする
            if (query != null) {
                WindowUtil._url += "?" + query.slice(1);
            }
        }
    }

    /**
     * Formタグを生成しパラメーターをHiddenで生成する
     */
    private static genFormParameter(): void {
        let form = $("<form id='mjs-drill-down-form'></form>");
        form.attr("action", WindowUtil._url);
        form.attr("target", WindowUtil._target);
        form.attr("method", WindowUtil._method);

        let template = $("<input type='hidden' />");
        WindowUtil._params = MjsUtilities.stringify(WindowUtil._params);
        for (let key in WindowUtil._params) {
            if (typeof (WindowUtil._params[key]) == "object") {
                if (WindowUtil._params[key].length == undefined) {
                    let input = template.clone(false);
                    for (let p in WindowUtil._params[key]) {
                        input = template = $("<input type='hidden' />");
                        input.attr("name", key + "['" + p + "']");
                        input.attr("value", WindowUtil._params[key][p]);
                        form.append(input);
                    };
                } else {
                    let input = template.clone(false);
                    $.each(WindowUtil._params[key], (i, v) => {
                        input = template = $("<input type='hidden' />");
                        input.attr("name", key + "[]");
                        input.attr("value", v);
                        form.append(input);
                    });
                }
            } else {
                let input = template.clone(false);
                input.attr("name", key);
                input.attr("value", WindowUtil._params[key]);
                form.append(input);
            }
        }
        if (!WindowUtil._withoutToken) {
            let input = template.clone(false);
            input.attr("name", "__RequestVerificationToken");
            input.attr("value", WindowUtil.tokenValue);
            form.append(input);
        }
        if (!WindowUtil._withoutToken) {
            let input = template.clone(false);
            input.attr("name", "__VSVerificationToken");
            input.attr("value", WindowUtil.hsTokenValue);
            form.append(input);
        }

        if (!WindowUtil._withoutSystemType) {
            let input = template.clone(false);
            input.attr("name", "selectedSystemDiv");
            input.attr("value", WindowUtil.selectedSystemDiv);
            form.append(input);
        }

        if (!WindowUtil._withoutMenuId) {
            let input = template.clone(false);
            input.attr("name", "lastSelectedMenuCategoryId");
            input.attr("value", WindowUtil.lastSelectedMenuCategoryId);
            form.append(input);
        }

        $("body").append(form);
        $("#mjs-drill-down-form").submit();
        setTimeout(() => {
            $("#mjs-drill-down-form").remove();
        }, 300);
    }

    /**
     * 生成したFormをサブミットしWindowをオープンする（POST用）
     */
    private static submit() {
        if (!WindowUtil._option.isTabOpen) {
            try {
                WindowUtil._window = window.open(
                    ""
                    , WindowUtil._target
                    , WindowUtil._option.getOptionsString()
                )!;
            } catch (e) {
            }
        }
        WindowUtil.genFormParameter();
    }

    /**
     * Windowをオープンする（GET用）
     */
    public static open() {
        WindowUtil._window = window.open(
            ""
            , WindowUtil._target
            , WindowUtil._option.getOptionsString()
        )!;
        WindowUtil._window.location.href = WindowUtil._url;
    }

    public static close(target?: string) {
        if (target) {
            window.open('about:blank', target)!.close();
        } else {
            window.open('about:blank', WindowUtil._target)!.close();
        }
    }

    /**
     * 現在開いているwindowオブジェクトを返却する
     */
    public static getOpenWindow(): Window {
        return WindowUtil._window;
    }

    /**
     * ユニークなWindow名称(target)を取得する
     */
    private static getUniqueTargetName(): string {
        let now = new Date();
        let newTarget = now.getFullYear() + "-" + now.getMonth() + "-" + now.getDay() + "_"
            + now.getHours() + "-" + now.getMinutes() + "-" + now.getSeconds() + "." + now.getMilliseconds();
        return newTarget;
    }
}

/**
 * Windowオープン時のオプション
 */
export class WindowOption {
    public width: number = 1280;
    public height: number = 768;
    public isScrollbars: boolean = true;
    public isResizable: boolean = true;
    public isMenubar: boolean = false;
    public isToolbar: boolean = false;
    public isLocation: boolean = true;
    public isDirectories: boolean = false;
    public isStatus: boolean = false;
    public isTabOpen: boolean = false;

    /**
     * Windowオープン時のオプション一式をデフォルト値で作成する。
     * widthのデフォルト値は、呼び出し元ウィンドウのwidthとする。
     */
    constructor() {
        this.width = window.innerWidth; // 本ウィンドウ(=Windowオープンの呼び出し元)の横幅
    }

    public getOptionsString(): string {
        let options = "";
        options = "width=" + this.width
            + ", height=" + this.height
            + ", scrollbars=" + (this.isScrollbars == true ? "yes" : "no")
            + ", resizable=" + (this.isResizable == true ? "yes" : "no")
            + ", status=" + (this.isStatus == true ? "yes" : "no")
            + ", menubar=" + (this.isMenubar == true ? "yes" : "no")
            + ", toolbar=" + (this.isToolbar == true ? "yes" : "no")
            + ", location=" + (this.isLocation == true ? "yes" : "no")
            + ", directories=" + (this.isDirectories == true ? "yes" : "no");
        return options;
    }
}
