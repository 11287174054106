import React, { useState } from 'react';
import Axios from 'axios';
import { Button, Input, Label } from 'reactstrap';
import Popup from '../../../../components/molecules/CMN/Popup/Popup';
import { setAlertMessage, AlertKbnEnum } from '../../../../stores/AlertMessage';
import { bindValueToMessage } from '../../../../utils/messageUtils';
import { UserPasswordViewModel } from '../../../../models/UserPasswordViewModel';
import * as labels from '../../../../constants/labels';
import * as message from '../../../../constants/message';
import { setIsLoading } from '../../../../stores/NowLoading';

const BaseURL = 'api/v1/PasswordChange/Update';

type PasswordChangePops = {
    //画面を閉じる処理
    isOpen: boolean;
    onCloseClick: () => void;
};

const PasswordChange: React.FC<PasswordChangePops> = props => {
    const { isOpen, onCloseClick } = props;

    //テキストボックス内変更時の処理
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        switch (event.target.name) {
            case 'OldPassword':
                updateViewModel({ ...viewModel, OldPassword: event.target.value });
                break;
            case 'NewPassword':
                updateViewModel({ ...viewModel, NewPassword: event.target.value });
                break;
            case 'NewPasswordKakunin':
                updateViewModel({ ...viewModel, NewPasswordKakunin: event.target.value });
                break;
        }
    }

    const [viewModel, updateViewModel] = React.useState<UserPasswordViewModel>(
        new UserPasswordViewModel()
    );

    //エラーメッセージ用
    const [errorPasswordMessage, setErrorPassworfMessage] = React.useState<string>(' ');
    const [errorNewPasswordMessage, setErrorNewPasswordMessage] = React.useState<string>(' ');
    const [errorNewPasswordMessage2, setErrorNewPasswordMessage2] = React.useState<string>(' ');
    const [errorPasswordKakuninMessage, setErrorPasswordKakuninMessage] = React.useState<string>(' ');

    //フォーカス移動用
    const passwordFocus = React.useRef<any>();
    const newPasswordFocus = React.useRef<any>();

    const [isRevealPassword, setIsRevealPassword] = useState(false);
    const PasswordMask = () => {
        setIsRevealPassword((prevState) => !prevState);
    }

    /** 更新ボタン押下時の処理 */
    const handleOnUpdateClick = () => {
        setIsLoading(true);
        setErrorPassworfMessage('');
        setErrorNewPasswordMessage('');
        setErrorNewPasswordMessage2('');
        setErrorPasswordKakuninMessage('');
        //パスワードが空白ではないか？
        if (viewModel.OldPassword == null || viewModel.OldPassword == ""
            || viewModel.NewPassword == null || viewModel.NewPassword == "") {
            setErrorPassworfMessage(message.Common_Error_PasswordPolicy_PasswordErr)
            passwordFocus.current.focus();
            return;
        }
        //新パスワードの文字数/文字列があっているか？
        var reg = /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!-/:-@¥[-`{-~]{8,20}$/;
        if (!reg.test(viewModel.NewPassword)) {
            setErrorNewPasswordMessage(message.Common_Error_PasswordPolicy_PasswordPolicyErr_1);
            setErrorNewPasswordMessage2(message.Common_Error_PasswordPolicy_PasswordPolicyErr_2);
            newPasswordFocus.current.focus();
            return;
        }
        //新パスワードが確認用と一致するか？
        if (viewModel.NewPassword != viewModel.NewPasswordKakunin && viewModel != null) {
            setErrorPasswordKakuninMessage(message.Common_Error_PasswordPolicy_PasswordMatchErr);
            newPasswordFocus.current.focus();
            return;
        }

        else {
          Axios.post(BaseURL, viewModel)
            .then(response => {
              setAlertMessage({
                alerted: true,
                alertKbn: AlertKbnEnum.success,
                message: bindValueToMessage(message.Common_Success_PasswordChante_Save)
              });
              setErrorPassworfMessage('');
              setErrorNewPasswordMessage('');
              setErrorNewPasswordMessage2('');
              setErrorPasswordKakuninMessage('');
              props.onCloseClick();
            })
            .catch(error => {
              setErrorPassworfMessage(error.response.data.message);
            })
            .finally(() => {
              setIsLoading(false);
            })
        }
    };

    React.useEffect (() => {
      setIsRevealPassword(false);
      updateViewModel(new UserPasswordViewModel());
      setErrorPassworfMessage('');
      setErrorNewPasswordMessage('');
      setErrorNewPasswordMessage2('');
      setErrorPasswordKakuninMessage('');
    }, [isOpen]);

    return (
        <Popup
            isOpen={isOpen}
            header={labels.AIKCMN001000001_FUNCTION_NAME}
            footer={
                <div>
                    <div className='footer-botton'>
                        <Button
                            color='primary'
                            className='save-button'
                            id='updateButton'
                            onClick={handleOnUpdateClick}
                        >
                            {labels.AIKCMN001000001_BUTTON_HOZON}
                        </Button>

                        <Button
                            color='primary'
                            className='cancel-button'
                            onClick={onCloseClick}
                        >
                            {labels.AIKCMN001000001_BUTTON_CLOSE}
                        </Button>
                    </div>
                </div>
            }
            onCloseClick={onCloseClick}
        >
            <div>
                <div className='password-change'>
                    <table className=''>
                        <tbody>
                            <tr>
                                <th className='table-th'>
                                    <Label className='label-top'>
                                        {labels.AIKCMN001000001_LABEL_NOWPASSWORD}
                                    </Label></th>
                                <td className='table-td'>
                                    <Input
                                        type={isRevealPassword ? 'text' : 'password'}
                                        id='password'
                                        name='OldPassword'
                                        value={viewModel.OldPassword}
                                        onChange={handleOnChange}
                                        className='label-top'
                                        innerRef={passwordFocus}
                                    /></td>
                            </tr>
                            <tr>
                                <th className='label-err'></th>
                                <td>
                                    <Label className='label-err '>
                                        {errorPasswordMessage}
                                    </Label></td>
                            </tr>
                            <tr>
                                <th className='table-th'>
                                    <Label>
                                        {labels.AIKCMN001000001_LABEL_NEWPASSWORD}
                                    </Label></th>
                                <td className='table-td'>
                                    <Input
                                        type={isRevealPassword ? 'text' : 'password'}
                                        id='newPassword'
                                        name='NewPassword'
                                        value={viewModel.NewPassword}
                                        onChange={handleOnChange}
                                        innerRef={newPasswordFocus}
                                    />
                                </td>
                            </tr>
                            <th className='label-err'></th>
                            <td>
                                <Label className='label-err'>
                                    {errorNewPasswordMessage}
                                </Label></td>
                            <tr>
                                <th className='label-err'></th>
                                <td>
                                    <Label className='label-err'>

                                        {errorNewPasswordMessage2}
                                    </Label></td>
                            </tr>
                            <tr>
                                <th className='table-th'>

                                    <Label >
                                        {labels.AIKCMN001000001_LABEL_PASSWORDKAKUNINERR}
                                    </Label></th>
                                <td className='table-td'>
                                    <Input
                                        type={isRevealPassword ? 'text' : 'password'}
                                        id='passwordKakunin'
                                        name='NewPasswordKakunin'
                                        onChange={handleOnChange}
                                        value={viewModel.NewPasswordKakunin}
                                    /></td>
                            </tr>
                            <th className='label-err'></th>
                            <td>
                                <Label className='label-err '>
                                    {errorPasswordKakuninMessage}
                                </Label>
                            </td>
                            <tr>
                                <th className='table-th'></th>
                                <td className='table-td'>
                                    <Input
                                        type='checkbox'
                                        id='passwordCheckbox'
                                        onClick={PasswordMask}
                                        checked={isRevealPassword}
                                        className='textbox'
                                    />
                                    <Label className='label-textbox'>
                                        {labels.AIKCMN001000001_LABEL_PASSWORDISP}
                                    </Label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </Popup >

    );
};

export default PasswordChange;
