import React from 'react';
import Axios from 'axios';

import * as labels from '../../../constants/labels';
import { JournalLineRuleJItemViewModel } from '../../../models/journalLineRuleJItemViewModel';
import { RuleJItemVO } from '../../../models/ruleJItemVO';
import { setAlertMessage, AlertKbnEnum } from '../../../stores/AlertMessage';

import Button from 'reactstrap/lib/Button';
import Popup from '../../../components/molecules/CMN/Popup/Popup';
import SimpleGrid from '../../../components/molecules/CMN/Grid/SimpleGrid';
import * as wijmo from 'wijmo/wijmo';
import * as wjGrid from 'wijmo/wijmo.grid';
import { FlexGrid } from 'wijmo/wijmo.react.grid';

export const initialUrl = '/api/v1/JournalLineRuleSetting/JokenItem';

type JournalLineJItemProps = {
  activated: boolean;
  initialSelectedJItemNo?: number;
  onClose: () => void;
  onSelectClick: (selectedItem: RuleJItemVO) => void;
};

// tabIndexの初期値
const baseTabIndex = 300;

const JournalLineJItem: React.FC<JournalLineJItemProps> = props => {
  const { activated, initialSelectedJItemNo, onClose, onSelectClick } = props;

  const [viewModel, updateViewModel] = React.useState<JournalLineRuleJItemViewModel>({ RuleJItems: [] });
  const [backdropClassNameIE, setBackdropClassNameIE] = React.useState('');

  const ref = React.useRef<FlexGrid>();
  const focusElement = React.useRef<any>();
  const popupRef = React.useRef<any>();

  /** キーボード操作（キーダウン） */
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 9) {
      focusElement.current.focus();

      // 後のイベントをキャンセル
      event.preventDefault();
      event.stopPropagation();
    }
  };

  React.useEffect(() => {
    if (activated && viewModel.RuleJItems != undefined && viewModel.RuleJItems.length > 0) {
      // セルの初期選択
      setTimeout(() => {
        if (ref.current) {
          const flexGrid = ref.current.control as wjGrid.FlexGrid;
          if (initialSelectedJItemNo != undefined) {
            flexGridSelect(initialSelectedJItemNo, flexGrid);
          }
        }
      });

      // 画面表示完了後に実行
      setTimeout(() => {
        if (ref.current) {
          const flexGrid = ref.current.control as wjGrid.FlexGrid;
          flexGrid.focus();
        }
        if (popupRef.current) {
          // ポップアップにキーダウンイベントのフック
          popupRef.current.addEventListener('keydown', handleKeyDown);
        }
      }, 500);

      // IEの為の実装
      setTimeout(() => {
        if (backdropClassNameIE !== '') {
          const popupElements = document.getElementsByClassName('popup-wrap-JournalLineJItem');
          if (popupElements.length > 0) {
            const popupElement = (popupElements[0] as HTMLDivElement).parentNode as HTMLDivElement;
            popupElement.style.position = '';
            setTimeout(() => {
              popupElement.style.position = 'relative';
              setBackdropClassNameIE('');
            }, 500);
          }
        }
      });
    }
  }, [viewModel.RuleJItems]);

  /** 選択ボタン押下時の処理 */
  const handleOnSelectClick = () => {
    onSelectClick(ref.current!.control.selectedItems[0]);
    updateViewModel({ RuleJItems: [] });
  };

  /** 閉じるボタン押下時の処理 */
  const handleOnCloseClick = () => {
    onClose();
    updateViewModel({ RuleJItems: [] });
  };

  /** 選択セルを変更 */
  const flexGridSelect = (jItemNo: number, flexGrid: wjGrid.FlexGrid) => {
    const items = flexGrid.collectionView.items;
    const selectionRow = items.indexOf(items.filter(item => item.JItemNo === jItemNo)[0]);
    if (selectionRow > -1) {
      flexGrid.selection = new wjGrid.CellRange(selectionRow);
      // スクロールの移動
      setTimeout(() => {
        const rc = flexGrid.cells.getCellBoundingRect(selectionRow, 0, true);
        flexGrid.scrollPosition = new wijmo.Point(0, -rc.top);
      }, 100);
    }
  };

  // 通信処理
  const initialOnGet = (url: string) => {
    Axios.get<JournalLineRuleJItemViewModel>(url)
      .then(response => {
        updateViewModel(response.data);
      })
      .catch(error => {
        if (typeof error.response.data !== 'undefined') {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
        }
      });
  };

  React.useEffect(() => {
    if (activated) {
      initialOnGet(initialUrl);
    }
  }, [initialUrl, activated]);

  React.useEffect(() => {
    // IEの為の実装
    setBackdropClassNameIE('popup-backdrop-JournalLineJItem-IE');
  }, [initialUrl]);

  return viewModel.RuleJItems == undefined || viewModel.RuleJItems.length < 1 ? null : (
    <Popup
      id='JournalLineJItem'
      wrapClassName='popup-wrap-JournalLineJItem'
      backdropClassName={backdropClassNameIE}
      isOpen={activated}
      onCloseClick={handleOnCloseClick}
      closeIconTabIndex={-1}
      header={labels.AIKADT001000002_FUNCTION_NAME_CONDITIONITEMSELECTION}
      innerRef={popupRef}
      footer={
        <Button color='primary' onClick={handleOnSelectClick} tabIndex={baseTabIndex + 1} innerRef={focusElement}>
          {labels.AIKADT001000002_BUTTON_FUNCTION_SELECT}
        </Button>
      }
      size='sm'
    >
      <SimpleGrid
        gridClassName='JournalLineJItem-grid'
        columns={[
          {
            header: labels.AIKADT001000002_GRID_HEADER_ITEM,
            binding: 'JItemName',
            width: '*',
            isReadOnly: true,
            inputType: 'button'
          }
        ]}
        sources={viewModel.RuleJItems}
        gridRef={ref}
      />
    </Popup>
  );
};

export default JournalLineJItem;
