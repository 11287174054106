import { MjsDelegateContainer } from "../../../common/usefuls/delegate";
import { MjsUtilities } from "../../../common/usefuls/utilities";
import { ArrayList } from "../../functionPanel/collection/ArrayList";
import { Mediator, mediatorBeforeBlurArgs, MediatorButtonTabItem, MediatorGeneralItem } from "../../mediator";
import { SimpleButton, Button } from "../button/simpleButton";
import $ from 'jquery';
import 'jquery-ui/ui/widgets/draggable.js';
import 'jquery-ui/ui/widgets/resizable.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';

export interface IDialog {
    open(): void;
    close(): void;
}

/**
 * BootStrapを使用したダイアログを表示する抽象クラス
 */
// TODO: Componetを継承するかどうか要検討（現時点では不要かも）
export abstract class AbstractDialog implements IDialog {

    /** タイトル用のセレクタ */
    private static _SELECTOR_OF_TITLE: string = ".modal-title";
    /** ダイアログセレクタ */
    private _selector: string;
    /** ダイアログタイトル */
    private _title: string;
    /** ×ボタン用のセレクタ */
    protected static _SELECTOR_OF_CLOSE: string = ".close a";

    constructor(selector: string, title: string) {
        this._selector = selector;
        this._title = title;
        $(this.getTitle()).html(this.title);

        // ダイアログがドラッグ操作で画面ウィンドウ外に出ないよう設定
        $(".modal-content").draggable({
            handle: ".modal-header",
            containment: 'document',
            scroll: true
        });
    }

    private _closedEvent!: () => void;

    public set closedEvent(closedEvent: () => void) {
        this._closedEvent = closedEvent;
        if (this._closedEvent) {
            $(this.selector).on("hidden.bs.modal", this._closedEvent);
        }
    }
    public get closedEvent(): () => void {
        return this._closedEvent;
    }

    public get selector(): string { return this._selector; }
    public get title(): string { return this._title; }

    /**
     * 指定されたセレクタにフォーカスをあてる 
     * @param target セレクタ
     * @param interval フォーカスを充てるまでの間隔（デフォルト：500ミリ）
     */
    public onFocus(target: string, interval: number = 500) {
        setTimeout(() => {
            $(this.selector).find(target).focus();
        }, interval);
    }

    protected getTitle(): string {
        return this.selector + " " + AbstractDialog._SELECTOR_OF_TITLE;
    }

    public setTitle(title: string): void {
        this._title = title;
        $(this.getTitle()).html(this._title);
    }

    public abstract open(focusTarget?: string): void;
    public abstract close(): void;

    /**
     * 本ダイアログが表示中か否かを返す
     * @return true:表示中、false:非表示中
     */
    public isOpening(): boolean {
        let temp = $(this.selector).css("display");
        if ((temp == "none") || (MjsUtilities.isNull(temp))) {
            return false;
        }
        return true;
    }
}

/**
 * デフォルトダイアログ
 */
export class DefaultDialog extends AbstractDialog {

    constructor(selector: string, title: string) {
        super(selector, title);
    }

    /**
     * ダイアログをオープンする
     */
    public open(focusTarget?: string, closed?: () => void): void {
        $(this.selector).modal({backdrop: 'static'}).addClass("in");
        if (closed != null) {
            $(this.selector).unbind("hidden.bs.modal");
            $(this.selector).on("hidden.bs.modal", () => closed());
        }
        if (focusTarget != null) {
            this.onFocus(focusTarget);
        }
    }

    /**
     * ダイアログを閉じる
     */
    public close(): void {
        // モーダル非表示
        $(this.selector).modal("hide").removeClass("in");
        // イベントのアンバインド
        $(this.selector).off('hidden.bs.modal');
    }

    /**
     * (必要に応じて)Dialogを中心に配置する。
     * 初回、Initiali等で使用すれば、Window移動後は影響しないので、
     * 仕様タイミングとしては、初回表示前に実行しておくのがベスト。
     * @param dialogWidth：Dialogのサイズをpxで指定する。
     */
    public setDisplayCenter(dialogWidth: number, dialogHeight: number): void {
        let $dialogBody: JQuery = $(this.selector);

        var w = $(window).width();
        var h = $(window).height();

        var cw = dialogWidth;
        var ch = dialogHeight;

        //取得した値をcssに追加する
        let left: number = (w - cw) / 2;
        let top: number = (h - ch) / 2;

        $dialogBody.css("left", left + "px");
        $dialogBody.css("top", top + "px");
        $dialogBody.css("right", "auto");
        $dialogBody.css("bottom", "auto");
    }

    protected convertLinefeed(message: string): string {
        if (message) {
            return message.replace(/\r?\n/g, '<br />');
        } else {
            return "";
        }
    }

}


/**
 * 確認ダイアログ用のクラス
 * [概要]
 * 確認ダイアログを表示する処理は一定の規約を守っていただくことで
 * ダイアログの表示、「はい」ボタン押下イベント、「いいえ」ボタン押下イベントの発火まで行うことができます
 *
 * [使用方法]
 * 　1. _ConfirmPartial.htmlをインクルードしてください
 *  （例）entryJounal/index.cshtmlなどに以下のような記述を追加する
 *   @{ 
 *     Html.RenderPartial("~/Views/Shared/Modal/_ConfirmPartial.cshtml");
 *   }
 *
 *   2. 「はい」ボタン押下時に独自処理を実行するためのボタンクラスを作成し、インスタンスを作成する
 *  （例）
 *      export class YesButton extends SimpleButton {
 *          constructor() {
 *              super("#on-yes");   // 「はい」ボタンのセレクタを指定 → のちに確認ダイアログ用の「はい」ボタンの抽象クラスを作成する可能性があり、その時は不要になるかも
 *          }
 *          public execute(): void {
 *              Logger.debug("yes");
 *          }
 *      }
 *
 *      var yesButton = new YesButton();
 *
 *   3. ConfirmDialogのインスタンスを作成してください。このときに上記で作成したボタンクラスを引数に設定してください
 *  （例）TS内に以下のように記述する
 *      var confirm: ConfirmDialog = new ConfirmDialog(
 *          "#mjsCommonConfirm" // ダイアログのセレクタ
 *          , "仕訳データ削除"  // ダイアログのタイトル
 *          , "の仕訳データを削除します。よろしいですか" // ダイアログに表示するメッセージ
 *          , yesButton // 手順２で作成したボタンクラス
 *      );
 *
 *   4. ダイアログをオープンする
 *  （例）
 *      confirm.open();
 * 
 * [その他]
 *  「いいえ」ボタン押下時に独自の処理を行いたい場合
 *
 *  1. 手順２と同じ要領でボタンクラスを作成してください
 *  2. ダイアログ作成時に「いいえ」ボタンを指定してください
 *      var confirm: ConfirmDialog = new ConfirmDialog(
 *          "#mjsCommonConfirm" // ダイアログのセレクタ
 *          , "仕訳データ削除"  // ダイアログのタイトル
 *          , "の仕訳データを削除します。よろしいですか" // ダイアログに表示するメッセージ
 *          , yesButton // 手順２で作成したボタンクラス
 *          , noButton // 手順２で作成したボタンクラス
 *      );
 *  あとは同じです
 *
 */
export class ConfirmDialog extends DefaultDialog {

    /** メッセージ用のセレクタ */
    private static _SELECTOR_OF_MESSAGE: string = ".modal-confirm-message";
    /** 「はい」ボタン押下時のボタンクラス */
    protected yesButton: SimpleButton;
    /** 「いいえ」ボタン押下時のボタンクラス */
    protected noButton!: SimpleButton;
    /** ダイアログ内に表示するメッセージ */
    private message: string;
    //private title: string;


    /**
     * ダイアログ内のFocus移動を管理するMediator
     */
    protected mediator!: Mediator;

    /**
     * コンストラクタ
     * @param selector ダイアログのセレクタ
     * @param title ダイアログのタイトル
     * @param message ダイアログ内に表示するメッセージ
     * @param yesButton 「はい」押下時のボタンクラスeditmenu
     * @param noButton  「いいえ」押下時のボタンクラス（省略可能）
     */
    constructor(selector: string, title: string, message: string, yesButton: SimpleButton, noButton?: SimpleButton) {
        super(selector, title);
        this.yesButton = yesButton;
        this.message = message;
        if (noButton != null) {
            this.noButton = noButton;
        }

        this.init();
    }

    /**
     * ダイアログの初期化を行う
     */
    protected init(): void {
        //$(this.getTitle()).html(this.title);
        $(this.getMessageSelector()).html(this.convertLinefeed(this.message));
        var self = this;

        // 閉じるイベント
        $(this.selector).on('hidden.bs.modal', function () {
            self.close();
        });

        //// 2017.3.6 add by m.nakamura
        //// 確認ダイアログ表示時に横スクロールがあると右、左でスクロールする制御
        //// のため追加
        //$(this.selector).on("keydown", (e) => {
        //    switch (e.keyCode) {
        //        case wijmo.Key.Right:
        //        case wijmo.Key.Left:
        //            e.preventDefault();
        //            e.stopImmediatePropagation();
        //            break;
        //    }
        //});

        // Mediatorの登録
        this.setMediator();
        this.onFocus(this.yesButton.getSelector());

        //$(this.selector + " .modal-content").prop('resize', 'none');
        $(this.selector + " .modal-content").resizable({ disabled: true });
    }

    /**
     * Noボタン押下時の処理
     * 独自の「いいえ」ボタン押下時の処理が設定されている場合は、
     * 独自の処理を行い、それ以外はダイアログを閉じるイベントと同じ処理を行います
     */
    public onNo(): void {
        if (this.noButton != null) {
            this.noButton.execute();
        } else {
            this.close();
        }
    }

    public setMessage(message: string): void {
        this.message = message;
        $(this.getMessageSelector()).html(this.convertLinefeed(this.message));
    }

    private getMessageSelector(): string {
        return this.selector + " " + ConfirmDialog._SELECTOR_OF_MESSAGE;
    }
    /**
     * Focus順の設定を行う。
     */
    protected setMediator() {
        this.mediator = new Mediator();
        let $okButton: JQuery = $(this.yesButton.getSelector());
        // OKボタンの登録
        this.mediator.addControl(this.createOkButtonMediatorItem($okButton));
        if (this.noButton != undefined) {
            var $noButton: JQuery = $(this.noButton.getSelector());
        } else {
            var $noButton: JQuery = $(".on-no");
        }
        // Noボタンの登録
        this.mediator.addControl(this.createNoButtonMediatorItem($noButton));

        // 閉じるボタンの登録
        this.mediator.addControl(this.createCloseButtonMediatorItem($(this.selector + " " + ConfirmDialog._SELECTOR_OF_CLOSE)));
    }
    /**
     * 各画面コントロールのMediatorItemを作成する
     * @param control
     */
    protected createOkButtonMediatorItem(control: JQuery): MediatorGeneralItem {
        let item = new MediatorButtonTabItem(control);
        item.delegateBeforeBlur = (args: mediatorBeforeBlurArgs): void => {
            if (!args.IsForward) {
                this.mediator.setFocusToLastItem();
            }
        }
        return item;
    }
    protected createNoButtonMediatorItem(control: JQuery): MediatorGeneralItem {
        let item = new MediatorButtonTabItem(control);
        item.delegateBeforeBlur = (args: mediatorBeforeBlurArgs): void => {
            if (args.IsForward) {
                this.mediator.setFocusToFirstItem();
            }
        };
        return item;
    }

    protected createCloseButtonMediatorItem(control: JQuery): MediatorGeneralItem {
        let item = new MediatorButtonTabItem(control);
        item.delegateBeforeBlur = (args: mediatorBeforeBlurArgs): void => {
            if (args.IsForward) {
                this.mediator.setFocusToFirstItem();
            }
        };
        return item;
    }
}

export class ExConfirmDialog extends ConfirmDialog {
    /** 「キャンセル」ボタン押下時のボタンクラス */
    private cancelButton: SimpleButton;

    constructor(
        selector: string
        , title: string
        , message: string
        , yesButton: SimpleButton
        , noButton: SimpleButton
        , cancelButton: SimpleButton) {
        super(
            selector
            , title
            , message
            , yesButton
            , noButton
        );

        this.cancelButton = cancelButton;
    }

    //オーバーライド
    protected setMediator() {
        this.mediator = new Mediator();
        let $okButton: JQuery = $(this.yesButton.getSelector());
        let $noButton: JQuery;
        let $cancelButton: JQuery;

        // OKボタンの登録
        this.mediator.addControl(this.createOkButtonMediatorItem($okButton));
        if (this.noButton != undefined) {
            $noButton = $(this.noButton.getSelector());
        } else {
            $noButton = $(".on-no");
        }

        if (this.cancelButton) {
            $cancelButton = $(this.cancelButton.getSelector());
        } else {
            $cancelButton = $(".on-cancel");
        }
        //Noボタンの登録
        this.mediator.addControl(this.createNoButtonMediatorItem($noButton));
        // Cancelボタンの登録
        this.mediator.addControl(this.createCancelButtonMediatorItem($cancelButton));
        // 閉じるボタンの登録
        this.mediator.addControl(this.createCloseButtonMediatorItem($(this.selector + " " + ConfirmDialog._SELECTOR_OF_CLOSE)));
    }

    //オーバーライド
    protected createOkButtonMediatorItem(control: JQuery): MediatorGeneralItem {
        let item = new MediatorButtonTabItem(control);
        item.delegateBeforeBlur = (args: mediatorBeforeBlurArgs): void => {
            if (!args.IsForward) {
                this.mediator.setFocusToLastItem();
            }
        }
        return item;
    }
    protected createNoButtonMediatorItem(control: JQuery): MediatorGeneralItem {
        let item = new MediatorButtonTabItem(control);
        //item.delegateBeforeBlur = (args: mediatorBeforeBlurArgs): void => {
        //};
        return item;
    }

    /**
     * キャンセルボタンのメディエータアイテム
     * @param control
     */
    private createCancelButtonMediatorItem(control: JQuery): MediatorGeneralItem {
        let item = new MediatorButtonTabItem(control);
        item.delegateBeforeBlur = (args: mediatorBeforeBlurArgs): void => {
            if (args.IsForward) {
                this.mediator.setFocusToFirstItem();
            }
        };
        return item;
    }
}

/**
 * 確認必須ダイアログ
 * 「内容確認済みチェックボックス」付きのダイアログ
 * [使用方法]
 * 　1. _RequiredConfirmPartial.cshtmlをインクルードしてください
 *  （例）entryJounal/index.cshtmlなどに以下のような記述を追加する
 *   @{
 *     Html.RenderPartial("~/Views/Shared/Modal/_RequiredConfirmPartial.cshtml");
 *   }
 *   ※手順2. 以降は、確認ダイアログ用のクラス（ConfirmDialogクラス）と同じ
 */
export class requiredConfirmDialog extends ConfirmDialog {

    private cancelButton: SimpleButton; //「キャンセル」ボタン
    private useCancelButton!: boolean;
    private useConfirmCheckbox!: boolean;

    protected mediatorCheckConfirmedItem!: MediatorGeneralItem;
    protected mediatorYesButtonItem!: MediatorGeneralItem;
    protected mediatorNoButtonItem!: MediatorGeneralItem;
    protected mediatorCancelButtonItem!: MediatorGeneralItem;

    /** 「キャンセル」ボタン使用フラグ */
    public get UseCancelButton(): boolean { return this.useCancelButton; }
    public set UseCancelButton(value: boolean) { this.useCancelButton = !!(value); }

    /** 「内容確認済みチェックボックス」使用フラグ */
    public get UseConfirmCheckbox(): boolean { return this.useConfirmCheckbox; }
    public set UseConfirmCheckbox(value: boolean) { this.useConfirmCheckbox = !!(value); }

    /** 「内容確認済みチェックボックス」 取得 */
    public getCheckConfirmed(): JQuery {
        return $("#rqrConf-checkConfirmed");
    }

    /** 「内容確認済みチェックボックス」ラベル htmlValue設定 */
    public setCheckConfirmedMessage(htmlString: string) {
        $("#rqrConf-labelForCheckConfirmed").html(this.convertLinefeed(htmlString));
    }

    /**
     * 確認必須ダイアログ
     * ※コンストラクタ引数のyesButtonには、Buttonクラス(extends SimpleButton)を使用してください（disableメソッドが実装されている必要があるため）
     */
    constructor(selector: string, title: string, message: string, yesButton: SimpleButton, noButton: SimpleButton, cancelButton: SimpleButton) {
        super(selector, title, message, yesButton, noButton);

        this.cancelButton = cancelButton;
        this.UseCancelButton = false;
        this.UseConfirmCheckbox = true;
    }

    /**
     * ## オーバーライド
     * ダイアログの初期化を行う
     */
    protected init(): void {
        super.init();

        // 「内容確認済みチェックボックス」
        $("#rqrConf-checkConfirmed").change((e) => {
            !!($(e.target).prop("checked")) ? this.yesButton.enable() : this.yesButton.disable();
        });
    }

    /**
     * ## オーバーライド
     * ダイアログをオープンする
     * @param focusTarget
     * @param closed
     */
    public open(focusTarget?: string, closed?: () => void): void {
        if (this.cancelButton && this.UseCancelButton) {
            // 「キャンセル」ボタンを使用する場合
            $(this.cancelButton.getSelector()).show();
        } else {
            $(".on-cancel").attr("disabled", "disabled").hide();
        }

        if (this.UseConfirmCheckbox) {
            // 「内容確認済みチェックボックス」を使用する場合
            $("tr.tr-confirmed").show();
            // 初期表示時はチェックボックスoff
            $("#rqrConf-checkConfirmed").prop("checked", false).change();
        } else {
            $("tr.tr-confirmed").hide();
        }

        super.open(focusTarget, closed);
    }

    // ## オーバーライド
    protected setMediator() {
        this.mediator = new Mediator();

        // 「内容確認済みチェックボックス」の登録
        let $checkConfirmed = $("#rqrConf-checkConfirmed");
        this.mediatorCheckConfirmedItem = this.createCheckConfirmedMediatorItem($checkConfirmed);
        this.mediator.addControl(this.mediatorCheckConfirmedItem);

        // OKボタンの登録
        let $okButton = $(this.yesButton.getSelector());
        this.mediatorYesButtonItem = this.createOkButtonMediatorItem($okButton);
        this.mediator.addControl(this.mediatorYesButtonItem);

        // Noボタンの登録
        let $noButton = (this.noButton) ? $(this.noButton.getSelector()) : $(".on-no");
        this.mediatorNoButtonItem = this.createNoButtonMediatorItem($noButton);
        this.mediator.addControl(this.mediatorNoButtonItem);

        // Cancelボタンの登録
        if (this.UseCancelButton) {
            // Cancelボタンを使用する場合
            let $cancelButton = (this.cancelButton) ? $(this.cancelButton.getSelector()) : $(".on-cancel");
            this.mediatorCancelButtonItem = this.createCancelButtonMediatorItem($cancelButton);
            this.mediator.addControl(this.mediatorCancelButtonItem);
        }
        // 閉じるボタンの登録
        this.mediator.addControl(this.createCloseButtonMediatorItem($(this.selector + " " + ConfirmDialog._SELECTOR_OF_CLOSE)));
    }

    protected createCheckConfirmedMediatorItem(control: JQuery): MediatorGeneralItem {
        let item = new MediatorButtonTabItem(control);
        item.delegateBeforeBlur = (args: mediatorBeforeBlurArgs): void => {
            if (args.IsForward) {
                if (!(this.yesButton.isEnabled())) {
                    this.mediator.setActiveItem(this.mediatorNoButtonItem);
                    this.mediator.setFocusToActiveItem();
                }
            } else {
                this.mediator.setFocusToLastItem();
            }
        }
        return item;
    }
    // ## オーバーライド
    protected createOkButtonMediatorItem(control: JQuery): MediatorGeneralItem {
        let item = new MediatorButtonTabItem(control);
        //item.delegateBeforeBlur = (args: mediatorBeforeBlurArgs): void => {
        //}
        return item;
    }
    // ## オーバーライド
    protected createNoButtonMediatorItem(control: JQuery): MediatorGeneralItem {
        let item = new MediatorButtonTabItem(control);
        item.delegateBeforeBlur = (args: mediatorBeforeBlurArgs): void => {
            if (args.IsForward) {
                if (!this.cancelButton) {
                    this.mediator.setFocusToFirstItem();
                }
            } else {
                if (!(this.yesButton.isEnabled())) {
                    this.mediator.setActiveItem(this.mediatorCheckConfirmedItem);
                    this.mediator.setFocusToActiveItem();
                }
            }
        };
        return item;
    }

    /**
     * キャンセルボタンのメディエータアイテム
     * @param control
     */
    private createCancelButtonMediatorItem(control: JQuery): MediatorGeneralItem {
        let item = new MediatorButtonTabItem(control);
        item.delegateBeforeBlur = (args: mediatorBeforeBlurArgs): void => {
            if (args.IsForward) {
                this.mediator.setFocusToFirstItem();
            }
        };
        return item;
    }
}


/**
 * アラートダイアログを表示するクラス

  [概要]
  SimpleAlertDialogとの違いは、
  本クラスはOK押下時のイベントを取得し、独自処理を行いたい場合のクラスになります。
  OKイベントを取得する必要がない場合は、SimpleAlertDialogをご利用ください。

  [使用方法]
  1. ダイアログを表示するためのHTMLを作成してください。
   →デフォルトで\Shared\Modal\_AlertPartial.cshtmlを用意しておりますので、メッセージ表示＆OKボタンのみでよければ
    こちらをご利用ください。

  2. 手順[1.]で作成したHTMLをインクルードしてください
    @{
        Html.RenderPartial("~/Views/Shared/Modal/_AlertPartial.cshtml");
    }
 */
export class AlertDialog extends DefaultDialog {

    /** メッセージ用のセレクタ */
    private static _SELECTOR_OF_MESSAGE: string = ".modal-alert-message";
    /** 「OK」ボタン押下時のボタンクラス */
    private okButton!: SimpleButton;
    /** ダイアログ内に表示するメッセージ */
    private message: string;
    /**
     * ダイアログ内のFocus移動を管理するMediator
     */
    protected mediator!: Mediator;

    /**
     * コンストラクタ
     * @param selector ダイアログのセレクタ
     * @param title ダイアログのタイトル
     * @param message ダイアログ内に表示するメッセージ
     * @param okButton 「はい」押下時のボタンクラス
     */
    constructor(selector: string, title: string, message: string, okButton: SimpleButton) {
        super(selector, title);
        if (okButton != null) {
            this.okButton = okButton;
            this.okButton.setParentPanel(this);
        }
        this.message = message;
        this.init();
    }

    /**
     * ダイアログの初期化を行う
     */
    protected init(): void {
        $(this.getMessageSelector()).html(this.convertLinefeed(this.message));
        var self = this;

        // 閉じるイベント
        $(this.selector).on('hidden.bs.modal', function () {
            self.close();
        });

        //$(this.selector + " .modal-content").prop('resize', 'none');
        $(this.selector + " .modal-content").resizable({ disabled: true });

        this.setMediator();
    }

    public setMessage(message: string): void {
        this.message = message;
        $(this.getMessageSelector()).html(this.convertLinefeed(this.message));
    }

    private getMessageSelector(): string {
        return this.selector + " " + AlertDialog._SELECTOR_OF_MESSAGE;
    }


    protected setMediator() {
        this.mediator = new Mediator();

        // OKボタンの登録
        let $okButton = $(this.okButton.getSelector());
        this.mediator.addControl(this.createOkButtonMediatorItem($okButton));

        // 閉じるボタンの登録
        this.mediator.addControl(this.createCloseButtonMediatorItem($(this.selector + " " + ConfirmDialog._SELECTOR_OF_CLOSE)));
    }

    /**
     * 各画面コントロールのMediatorItemを作成する
     * @param control
     */
    protected createOkButtonMediatorItem(control: JQuery): MediatorGeneralItem {
        let item = new MediatorButtonTabItem(control);
        item.delegateBeforeBlur = (args: mediatorBeforeBlurArgs): void => {
            if (!args.IsForward) {
                this.mediator.setFocusToLastItem();
            }
        }
        return item;
    }
    protected createCloseButtonMediatorItem(control: JQuery): MediatorGeneralItem {
        let item = new MediatorButtonTabItem(control);
        item.delegateBeforeBlur = (args: mediatorBeforeBlurArgs): void => {
            if (args.IsForward) {
                this.mediator.setFocusToFirstItem();
            }
        };
        return item;
    }
}

export class SimpleAlertDialog extends AlertDialog {

    private defaultOkButton: Button = new Button(".on-ok");

    /**
     * コンストラクタ
     * @param selector ダイアログのセレクタ
     * @param title ダイアログのタイトル
     * @param message ダイアログ内に表示するメッセージ
     * @param okButton 「はい」押下時のボタンクラスeditmenu
     */
    constructor(title: string, message: string) {
        super("#mjsCommonAlert", title, message, null!);
        this.defaultOkButton.setEventHandler(new MjsDelegateContainer(this.close, this));
        this.init();
    }
}


/**
 * モーダルダイアログの基本クラス
 * [概要]
 * 画面レイアウトはHTMLで定義し、そのHTMLのセレクタを指定することで
 * 簡単にモーダルダイアログを表示することができます。
 * 画面内のボタン押下イベントは、addButtonメソッドでいくつでも追加することができます。
 *
 * [使用方法]
 * ①以下のようにしてインスタンス化してください
   var dialog: ModalDialog = new ModalDailog("セレクタ", "タイトル");
   ・第一引数：表示するダイアログHTMLのセレクタ
  ・第二引数：ダイアログのタイトル（.modal-titleに設定されます）

  ②ダイアログクラスにボタンクラスを追加してイベントのバインドを行ってください
  dialog.addButton(new SampleButton1("selector"));
  dialog.addButton(new SampleButton2("selector"));

   SimpleButtonクラスのexecuteに必要な処理を記述してください
 */
export class ModalDialog extends DefaultDialog {

    /** ボタン配列 */
    private buttons: ArrayList<SimpleButton> = new ArrayList<SimpleButton>();

    constructor(selector: string, title: string) {
        super(selector, title);
    }

    /**
     * ボタンを追加する
     * @param button
     */
    public addButton(button: SimpleButton) {
        button.setParentPanel(this);
        this.buttons.add(button);
    }
}
