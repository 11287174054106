
/*
 * 共通
 */
/** 共通/ボタン/機能/新規 */	
export const COMMON_BUTTON_FUNCTION_NEW = '新規';
/** 共通/ボタン/機能/Excel出力 */	
export const COMMON_BUTTON_FUNCTION_EXCEL = 'Excel出力';
/** 共通/ボタン/機能/閉じる */	
export const COMMON_BUTTON_FUNCTION_CLOSE = '閉じる';
/** 共通/ボタン/機能/インポート */	
export const COMMON_BUTTON_FUNCTION_IMPORT = 'インポート';
/** 共通/ボタン/機能/エクスポート */	
export const COMMON_BUTTON_FUNCTION_EXPORT = 'エクスポート';
/** 共通/ボタン/機能/保存 */	
export const COMMON_BUTTON_FUNCTION_SAVE = '保存';
/** 共通/ボタン/機能/取消 */	
export const COMMON_BUTTON_FUNCTION_CANCEL = '取消';
/** 共通/ボタン/機能/全選択 */	
export const COMMON_BUTTON_FUNCTION_SELECTALL = '全選択';
/** 共通/ボタン/機能/全解除 */	
export const COMMON_BUTTON_FUNCTION_UNSELECTALL = '全解除';
/** 共通/ボタン/機能/挿入 */	
export const COMMON_BUTTON_FUNCTION_INSERT = '挿入';
/** 共通/ボタン/機能/削除 */	
export const COMMON_BUTTON_FUNCTION_DELETE = '削除';
/** 共通/入力/ヘッダ/最新 */	
export const COMMON_INPUT_HEADER_LASTEXECUTION = '最新';
/** 共通/入力/ヘッダ/すべて */	
export const COMMON_INPUT_HEADER_ALLHISTORY = 'すべて';
/** 共通/機能/表/総件数 */	
export const COMMON_FUNCTION_GRID_TOTALCOUNT = '件';
/** 共通/表/フィルタ/空 */	
export const COMMON_GRID_FILTER_NULL = '(なし)';

/*
 * 仕訳明細ルール設定一覧
 */
/** 仕訳明細ルール設定一覧/機能/名称 */	
export const AIKADT001000001_FUNCTION_NAME = '仕訳明細ルール設定一覧';
/** 仕訳明細ルール設定一覧/表/ヘッダ/No. */	
export const AIKADT001000001_GRID_HEADER_NO = 'No.';
/** 仕訳明細ルール設定一覧/表/ヘッダ/種別 */	
export const AIKADT001000001_GRID_HEADER_TYPE = '種別';
/** 仕訳明細ルール設定一覧/表/ヘッダ/対象 */	
export const AIKADT001000001_GRID_HEADER_TARGET = '対象';
/** 仕訳明細ルール設定一覧/表/ヘッダ/名称 */	
export const AIKADT001000001_GRID_HEADER_NAME = '名称';
/** 仕訳明細ルール設定一覧/表/ヘッダ/対象月 */	
export const AIKADT001000001_GRID_HEADER_TARGETMONTH = '実行対象月';
/** 仕訳明細ルール設定一覧/表/ヘッダ/指定条件 */	
export const AIKADT001000001_GRID_HEADER_DESIGNATEDCRITERIA = '指定条件';
/** 仕訳明細ルール設定一覧/表/ヘッダ/更新日時 */	
export const AIKADT001000001_GRID_HEADER_UPDATETIME = '更新日時';
/** 仕訳明細ルール設定一覧/表/ヘッダ/有効 */	
export const AIKADT001000001_GRID_HEADER_VALID = '有効';
/** 仕訳明細ルール設定一覧/表/ヘッダ/コマンド */	
export const AIKADT001000001_GRID_HEADER_COMMAND = '';
/** 仕訳明細ルール設定一覧/表/ヘッダ/コピー */	
export const AIKADT001000001_GRID_HEADER_COPY = 'コピー';
/** 仕訳明細ルール設定一覧/表/ツールチップ/コピー */	
export const AIKADT001000001_GRID_TOOLTIP_COPY = 'コピー';
/** 仕訳明細ルール設定一覧/表/ツールチップ/削除 */	
export const AIKADT001000001_GRID_TOOLTIP_DELETE = '削除';
/** 仕訳明細ルール設定一覧/表/ソート/true */	
export const AIKADT001000001_GRID_SORT_TRUE = 'あり';
/** 仕訳明細ルール設定一覧/表/ソート/false */	
export const AIKADT001000001_GRID_SORT_FALSE = 'なし';

/*
 * 仕訳明細ルール設定
 */
/** 仕訳明細ルール設定/機能/名称 */	
export const AIKADT001000002_FUNCTION_NAME = '仕訳明細ルール設定';
/** 仕訳明細ルール設定/入力/ヘッダ/種別 */	
export const AIKADT001000002_INPUT_HEADER_TYPE = '種別';
/** 仕訳明細ルール設定/入力/ヘッダ/名称 */	
export const AIKADT001000002_INPUT_HEADER_NAME = '名称';
/** 仕訳明細ルール設定/入力/プレースホルダ/名称 */	
export const AIKADT001000002_INPUT_PLACEHOLDER_NAME = '仕訳明細ルール名を入力してください';
/** 仕訳明細ルール設定/入力/ヘッダ/対象仕訳データ */	
export const AIKADT001000002_INPUT_HEADER_TARGET = '対象仕訳データ';
/** 仕訳明細ルール設定/入力/プレースホルダ/対象仕訳データ */	
export const AIKADT001000002_INPUT_PLACEHOLDER_TARGET = '対象仕訳データを選択してください';
/** 仕訳明細ルール設定/入力/ヘッダ/実行対象月選択 */	
export const AIKADT001000002_INPUT_HEADER_TARGETSELECT = '実行対象月選択';
/** 仕訳明細ルール設定/入力/ヘッダ/監査メッセージ */	
export const AIKADT001000002_INPUT_HEADER_AUDITMESSAGE = '監査メッセージ';
/** 仕訳明細ルール設定/入力/プレースホルダ/監査メッセージ */	
export const AIKADT001000002_INPUT_PLACEHOLDER_AUDITMESSAGE = '監査NG時に表示するメッセージを入力してください';
/** 仕訳明細ルール設定/入力/ヘッダ/有効 */	
export const AIKADT001000002_INPUT_HEADER_VALID = '有効';
/** 仕訳明細ルール設定/表/ヘッダ/追加 */	
export const AIKADT001000002_GRID_HEADER_ADD = '追';
/** 仕訳明細ルール設定/表/ヘッダ/削除 */	
export const AIKADT001000002_GRID_HEADER_DELETE = '削';
/** 仕訳明細ルール設定/表/ヘッダ/グループ化 */	
export const AIKADT001000002_GRID_HEADER_GROUP = 'グ';
/** 仕訳明細ルール設定/表/ヘッダ/条件指定 */	
export const AIKADT001000002_GRID_HEADER_CONDITIONSPECIFICATION = '条件指定';
/** 仕訳明細ルール設定/表/ヘッダ/AND/OR */	
export const AIKADT001000002_GRID_HEADER_ANDOR = 'AND/OR';
/** 仕訳明細ルール設定/表/ヘッダ/項目 */	
export const AIKADT001000002_GRID_HEADER_ITEM = '項目';
/** 仕訳明細ルール設定/表/ヘッダ/条件 */	
export const AIKADT001000002_GRID_HEADER_CONDITION = '条件';
/** 仕訳明細ルール設定/表/ヘッダ/値 */	
export const AIKADT001000002_GRID_HEADER_VALUE = '値';
/** 仕訳明細ルール設定/ボタン/機能/条件追加 */	
export const AIKADT001000002_BUTTON_FUNCTION_CONDITIONADDITION = '条件追加';
/** 仕訳明細ルール設定/ボタン/機能/グループ化 */	
export const AIKADT001000002_BUTTON_FUNCTION_GROUP = 'グループ化';
/** 仕訳明細ルール設定/機能/名称/条件項目選択 */	
export const AIKADT001000002_FUNCTION_NAME_CONDITIONITEMSELECTION = '条件項目選択';
/** 仕訳明細ルール設定/ボタン/機能/選択 */	
export const AIKADT001000002_BUTTON_FUNCTION_SELECT = '選択';

/*
 * 監査結果[仕訳重複]
 */
/** 監査結果[仕訳重複]/機能/名称 */	
export const AIKADT002000010_FUNCTION_NAME = '監査結果[仕訳重複]';
/** 監査結果[仕訳重複]/機能/ヘッダ/対象月 */	
export const AIKADT002000010_FUNCTION_HEADER_TARGETMONTH = '対象月：';
/** 監査結果[仕訳重複]/ボタン/機能/Excel出力ボタン */	
export const AIKADT002000010_BUTTON_FUNCTION_EXCEL = 'Excel出力';
/** 監査結果[仕訳重複]/ボタン/機能/閉じるボタン */	
export const AIKADT002000010_BUTTON_FUNCTION_CLOSE = '閉じる';
/** 監査結果[仕訳重複]/表/ヘッダ/連番ヘッダ */	
export const AIKADT002000010_GRID_HEADER_SEQUENCE = 'No.';
/** 監査結果[仕訳重複]/表/ヘッダ/仕訳重複(検索No.)ヘッダ */	
export const AIKADT002000010_GRID_HEADER_JOURNALIZATIONDUPLICATESEARCHNO = '仕訳重複(検索No.)';
/** 監査結果[仕訳重複]/表/ヘッダ/ステータスヘッダ */	
export const AIKADT002000010_GRID_HEADER_STATUS = 'ステータス';
/** 監査結果[仕訳重複]/表/ヘッダ/実行日時ヘッダ */	
export const AIKADT002000010_GRID_HEADER_EXECUTINGTIME = '実行日時';
/** 監査結果[仕訳重複]/表/ヘッダ/科目コードヘッダ */	
export const AIKADT002000010_GRID_HEADER_SUBJECTSCODE = '科目コード';
/** 監査結果[仕訳重複]/表/ヘッダ/科目名ヘッダ */	
export const AIKADT002000010_GRID_HEADER_SUBJECTSNAME = '科目名';
/** 監査結果[仕訳重複]/表/ヘッダ/検索No.ヘッダ */	
export const AIKADT002000010_GRID_HEADER_SEARCHNO = '検索No.';

/*
 * 監査結果[著増減]
 */
/** 監査結果[著増減]/機能/名称 */	
export const AIKADT002000007_FUNCTION_NAME = '監査結果[著増減]';
/** 監査結果[著増減]/機能/ヘッダ/対象月 */	
export const AIKADT002000007_FUNCTION_HEADER_TARGETMONTH = '対象月：';
/** 監査結果[著増減]/表/ヘッダ/連番 */	
export const AIKADT002000007_GRID_HEADER_SEQUENCE = 'No.';
/** 監査結果[著増減]/表/ヘッダ/パターン */	
export const AIKADT002000007_GRID_HEADER_PATTERN = 'パターン';
/** 監査結果[著増減]/表/ヘッダ/科目コード */	
export const AIKADT002000007_GRID_HEADER_SUBJECTSCODE = '科目コード';
/** 監査結果[著増減]/表/ヘッダ/科目名 */	
export const AIKADT002000007_GRID_HEADER_SUBJECTSNAME = '科目名';
/** 監査結果[著増減]/表/ヘッダ/補助コード */	
export const AIKADT002000007_GRID_HEADER_AUXILIARYCODE = '補助コード';
/** 監査結果[著増減]/表/ヘッダ/補助名 */	
export const AIKADT002000007_GRID_HEADER_AUXILIARYNAME = '補助名';
/** 監査結果[著増減]/表/ヘッダ/部門コード */	
export const AIKADT002000007_GRID_HEADER_BMNCODE = '部門コード';
/** 監査結果[著増減]/表/ヘッダ/部門名称 */	
export const AIKADT002000007_GRID_HEADER_BMNSUBJECTS = '部門名';
/** 監査結果[著増減]/表/ヘッダ/金額[円] */	
export const AIKADT002000007_GRID_HEADER_PRICE = '金額[円]';
/** 監査結果[著増減]/表/ヘッダ/比較対象月 */	
export const AIKADT002000007_GRID_HEADER_COMPARISONMONTH = '比較対象月';
/** 監査結果[著増減]/表/ヘッダ/比較値 */	
export const AIKADT002000007_GRID_HEADER_COMPARISONVALUE = '比較値';
/** 監査結果[著増減]/表/ヘッダ/しきい値 */	
export const AIKADT002000007_GRID_HEADER_INCREASEANDDECREASERATETHRESHOLD = 'しきい値';
/** 監査結果[著増減]/表/ヘッダ/増減率 */	
export const AIKADT002000007_GRID_HEADER_INCREASEANDDECREASERATE = '増減率';
/** 監査結果[著増減]/表/ヘッダ/しきい値（金額） */	
export const AIKADT002000007_GRID_HEADER_THRESHOLDVALUEMONEY = 'しきい値（金額）';
/** 監査結果[著増減]/表/ヘッダ/差額 */	
export const AIKADT002000007_GRID_HEADER_DIFFERENCE = '差額';
/** 監査結果[著増減]/表/ヘッダ/ステータス */	
export const AIKADT002000007_GRID_HEADER_STATUS = 'ステータス';
/** 監査結果[著増減]/表/ヘッダ/実行日時 */	
export const AIKADT002000007_GRID_HEADER_EXECUTINGTIME = '実行日時';
/** 監査結果[著増減]/表/ヘッダ/部門コード */	
export const AIKADT002000007_GRID_HEADER_DEPARTMENTCODE = '部門コード';
/** 監査結果[著増減]/表/ヘッダ/部門名 */	
export const AIKADT002000007_GRID_HEADER_DEPARTMENTNAME = '部門名';

/*
 * チェックリスト設定一覧
 */
/** チェックリスト設定一覧/機能/名称 */	
export const AIKADT001000004_FUNCTION_NAME = 'チェックリスト設定一覧';
/** チェックリスト設定一覧/表/ヘッダ/No. */	
export const AIKADT001000004_GRID_HEADER_NO = 'No.';
/** チェックリスト設定一覧/表/ヘッダ/種別 */	
export const AIKADT001000004_GRID_HEADER_TYPE = '種別';
/** チェックリスト設定一覧/表/ヘッダ/名称 */	
export const AIKADT001000004_GRID_HEADER_NAME = '名称';
/** チェックリスト設定一覧/表/ヘッダ/対象月 */	
export const AIKADT001000004_GRID_HEADER_TARGETMONTH = '実行対象月';
/** チェックリスト設定一覧/表/ヘッダ/内容 */	
export const AIKADT001000004_GRID_HEADER_CONTENT = '内容';
/** チェックリスト設定一覧/表/ヘッダ/有効 */	
export const AIKADT001000004_GRID_HEADER_VALID = '有効';
/** チェックリスト設定一覧/表/ヘッダ/コマンド */	
export const AIKADT001000004_GRID_HEADER_COMMAND = '';
/** チェックリスト設定一覧/表/ツールチップ/コピー */	
export const AIKADT001000004_GRID_TOOLTIP_COPY = 'コピー';
/** チェックリスト設定一覧/表/ツールチップ/削除 */	
export const AIKADT001000004_GRID_TOOLTIP_DELETE = '削除';

/*
 * チェックリスト設定
 */
/** チェックリスト設定/機能/名称 */	
export const AIKADT001000005_FUNCTION_NAME = 'チェックリスト設定';
/** チェックリスト設定/入力/ヘッダ/種別 */	
export const AIKADT001000005_INPUT_HEADER_TYPE = '種別';
/** チェックリスト設定/入力/ヘッダ/名称 */	
export const AIKADT001000005_INPUT_HEADER_NAME = '名称';
/** チェックリスト設定/入力/プレースホルダ/名称 */	
export const AIKADT001000005_INPUT_PLACEHOLDER_NAME = 'チェックリスト項目名を入力してください';
/** チェックリスト設定/入力/ヘッダ/実行対象月選択 */	
export const AIKADT001000005_INPUT_HEADER_TARGETSELECT = '実行対象月選択';
/** チェックリスト設定/入力/ヘッダ/内容 */	
export const AIKADT001000005_INPUT_HEADER_CONTENT = '内容';
/** チェックリスト設定/入力/プレースホルダ/内容 */	
export const AIKADT001000005_INPUT_PLACEHOLDER_CONTENT = 'チェックリストの内容を入力してください';
/** チェックリスト設定/入力/ヘッダ/有効 */	
export const AIKADT001000005_INPUT_HEADER_VALID = '有効';

/*
 * チェックリスト
 */
/** チェックリスト/機能/名称 */	
export const AIKADT002000005_FUNCTION_NAME = 'チェックリスト';
/** チェックリスト/表/ヘッダ/No. */	
export const AIKADT002000005_GRID_HEADER_NO = 'No.';
/** チェックリスト/表/ヘッダ/確認 */	
export const AIKADT002000005_GRID_HEADER_CONFIRMATION = '確認';
/** チェックリスト/表/ヘッダ/チェック項目 */	
export const AIKADT002000005_GRID_HEADER_CHECKITEMS = 'チェック項目';
/** チェックリスト/表/ヘッダ/コメント */	
export const AIKADT002000005_GRID_HEADER_COMMENT = 'コメント';
/** チェックリスト/ボタン/機能/特記事項追加 */	
export const AIKADT002000005_BUTTON_FUNCTION_NOTICEADDITION = '特記事項追加';
/** チェックリスト/入力/ヘッダ/特記事項 */	
export const AIKADT002000005_INPUT_HEADER_NOTICE = '特記事項';
/** チェックリスト/表/ツールチップ/削除 */	
export const AIKADT002000005_GRID_TOOLTIP_DELETE = '削除';
/** チェックリスト/ボタン/機能/クリア */	
export const AIKADT002000005_BUTTON_FUNCTION_CLEAR = 'クリア';

/*
 * 仕訳明細
 */
/** 仕訳明細/機能/名称 */	
export const AIKADT002000004_FUNCTION_NAME = '仕訳明細';
/** 仕訳明細/機能/ヘッダ/対象月 */	
export const AIKADT002000004_FUNCTION_HEADER_TARGETMONTH = '対象月：';
/** 仕訳明細/機能/ヘッダ/確認ステータス */	
export const AIKADT002000004_FUNCTION_HEADER_CONFIRMATIONSTATUS = 'ステータス：';
/** 仕訳明細/機能/ヘッダ/監査種類 */	
export const AIKADT002000004_FUNCTION_HEADER_AUDITTYPE = '監査種類';
/** 仕訳明細/機能/ヘッダ/比較対象月 */	
export const AIKADT002000004_FUNCTION_HEADER_COMPARISONMONTH = '残高';
/** 仕訳明細/機能/ヘッダ/当月残高 */	
export const AIKADT002000004_FUNCTION_HEADER_CURRENTMONTHBALANCE = '当月残高';
/** 仕訳明細/機能/ヘッダ/監査メッセージ */	
export const AIKADT002000004_FUNCTION_HEADER_AUDITMESSAGE = '監査メッセージ';
/** 仕訳明細/機能/ヘッダ/著増減監査 */	
export const AIKADT002000004_FUNCTION_HEADER_REMARKINCDECAUDIT = '著増減監査';
/** 仕訳明細/機能/ヘッダ/マイナス残高監査 */	
export const AIKADT002000004_FUNCTION_HEADER_NEGATIVEBALANCEAUDIT = 'マイナス残高監査';
/** 仕訳明細/機能/ヘッダ/仕訳明細監査 */	
export const AIKADT002000004_FUNCTION_HEADER_JOURNALLINEAUDIT = '仕訳明細監査';
/** 仕訳明細/機能/ヘッダ/仕訳重複監査 */	
export const AIKADT002000004_FUNCTION_HEADER_JOURNALDOUBLEAUDIT = '仕訳重複監査';
/** 仕訳明細/機能/ヘッダ/消費税区分監査 */	
export const AIKADT002000004_FUNCTION_HEADER_TAXKUBUNAUDIT = '消費税区分監査';
/** 仕訳明細/機能/ヘッダ/未確認 */	
export const AIKADT002000004_FUNCTION_HEADER_UNCONFIRMED = '未確認';
/** 仕訳明細/機能/ヘッダ/確認NG */	
export const AIKADT002000004_FUNCTION_HEADER_CONFIRMATIONNG = '確認NG';
/** 仕訳明細/機能/ヘッダ/確認OK */	
export const AIKADT002000004_FUNCTION_HEADER_CONFIRMATIONOK = '確認OK';
/** 仕訳明細/機能/ヘッダ/保留 */	
export const AIKADT002000004_FUNCTION_HEADER_PENDING = '保留';
/** 仕訳明細/表/ヘッダ/検索No. */	
export const AIKADT002000004_GRID_HEADER_SEARCHNO = '検索No.';
/** 仕訳明細/表/ヘッダ/伝票No. */	
export const AIKADT002000004_GRID_HEADER_SLIPNO = '伝票No.';
/** 仕訳明細/表/ヘッダ/月日 */	
export const AIKADT002000004_GRID_HEADER_MONTHANDDAY = '月日';
/** 仕訳明細/表/ヘッダ/借方科目 */	
export const AIKADT002000004_GRID_HEADER_DEBITSUBJECTS = '借方科目';
/** 仕訳明細/表/ヘッダ/借方部門 */	
export const AIKADT002000004_GRID_HEADER_DEBITSECTION = '借方部門';
/** 仕訳明細/表/ヘッダ/借方補助 */	
export const AIKADT002000004_GRID_HEADER_DEBITAUXILIARY = '借方補助';
/** 仕訳明細/表/ヘッダ/借方金額 */	
export const AIKADT002000004_GRID_HEADER_DEBITAMOUNT = '借方金額';
/** 仕訳明細/表/ヘッダ/貸方科目 */	
export const AIKADT002000004_GRID_HEADER_CREDITSUBJECTS = '貸方科目';
/** 仕訳明細/表/ヘッダ/貸方部門 */	
export const AIKADT002000004_GRID_HEADER_CREDITSECTION = '貸方部門';
/** 仕訳明細/表/ヘッダ/貸方補助 */	
export const AIKADT002000004_GRID_HEADER_CREDITAUXILIARY = '貸方補助';
/** 仕訳明細/表/ヘッダ/貸方金額 */	
export const AIKADT002000004_GRID_HEADER_CREDITAMOUNT = '貸方金額';
/** 仕訳明細/表/ヘッダ/消費税/資金科目 */	
export const AIKADT002000004_GRID_HEADER_TAXFUNDSUBJECTS = '消費税/資金科目';
/** 仕訳明細/表/ヘッダ/期日 */	
export const AIKADT002000004_GRID_HEADER_DEADLINE = '期日';
/** 仕訳明細/表/ヘッダ/摘要 */	
export const AIKADT002000004_GRID_HEADER_REMARKS = '摘要';
/** 仕訳明細/ボタン/機能/表示切替 */	
export const AIKADT002000004_BUTTON_FUNCTION_DISPLAYSWITCH = '表示切替';

/*
 * 監査結果管理
 */
/** 監査結果管理/機能/名称 */	
export const AIKADT002000002_FUNCTION_NAME = '実行結果';
/** 監査結果管理/機能/ヘッダ/対象月 */	
export const AIKADT002000002_FUNCTION_HEADER_TARGETMONTH = '対象月：';
/** 監査結果管理/機能/ヘッダ/科目名称 */	
export const AIKADT002000002_FUNCTION_HEADER_SUBJECTSNAME = '科目：';
/** 監査結果管理/機能/ヘッダ/監査メッセージ件数 */	
export const AIKADT002000002_FUNCTION_HEADER_AUDITMESSAGECOUNT = '件';
/** 監査結果管理/ボタン/機能/確認OK */	
export const AIKADT002000002_BUTTON_FUNCTION_CONFIRMATIONOK = '確認OK';
/** 監査結果管理/ボタン/機能/確認NG */	
export const AIKADT002000002_BUTTON_FUNCTION_CONFIRMATIONNG = '確認NG';
/** 監査結果管理/ボタン/機能/保留 */	
export const AIKADT002000002_BUTTON_FUNCTION_PENDING = '保留';
/** 監査結果管理/ボタン/機能/コメント表示 */	
export const AIKADT002000002_BUTTON_FUNCTION_COMMENTDISPLAY = '▲ コメント';
/** 監査結果管理/ボタン/機能/コメント非表示 */	
export const AIKADT002000002_BUTTON_FUNCTION_COMMENTINVISIBLE = '▼ コメント';
/** 監査結果管理/表/ヘッダ/仕訳重複(検索No.) */	
export const GRID_HEADER_JOURNALIZATIONDUPLICATESEARCHNO = '仕訳重複(検索No.)';

/*
 * 仕訳明細
 */
/** 仕訳明細/機能/ヘッダ/著増減監査 */	
export const AIKADT002000002_FUNCTION_HEADER_REMARKINCDECAUDIT = '著増減監査';
/** 仕訳明細/機能/ヘッダ/マイナス残高監査 */	
export const AIKADT002000002_FUNCTION_HEADER_NEGATIVEBALANCEAUDIT = 'マイナス残高監査';
/** 仕訳明細/機能/ヘッダ/仕訳明細監査 */	
export const AIKADT002000002_FUNCTION_HEADER_JOURNALLINEAUDIT = '仕訳明細監査';
/** 仕訳明細/機能/ヘッダ/仕訳重複監査 */	
export const AIKADT002000002_FUNCTION_HEADER_JOURNALDOUBLEAUDIT = '仕訳重複監査';
/** 仕訳明細/機能/ヘッダ/消費税区分監査 */	
export const AIKADT002000002_FUNCTION_HEADER_TAXKUBUNAUDIT = '消費税区分監査';

/*
 * 監査結果一覧
 */
/** 監査結果一覧/機能/名称 */	
export const AIKADT002000006_FUNCTION_NAME = '監査結果一覧';
/** 監査結果一覧/入力/ヘッダ/監査対象 */	
export const AIKADT002000006_INPUT_HEADER_AUDITTARGET = '監査対象';
/** 監査結果一覧/表/ヘッダ/No */	
export const AIKADT002000006_GRID_HEADER_NO = 'No.';
/** 監査結果一覧/表/ヘッダ/監査種類 */	
export const AIKADT002000006_GRID_HEADER_AUDITTYPE = '監査種類';
/** 監査結果一覧/表/ヘッダ/実行日時ステータス内訳 */	
export const AIKADT002000006_GRID_HEADER_EXECUTINGTIMESTATSDETAIL = '実行日時\nステータス内訳';
/** 監査結果一覧/表/ヘッダ/No1 */	
export const AIKADT002000006_GRID_HEADER_NO1 = '1';
/** 監査結果一覧/表/ヘッダ/月次残高監査著増減 */	
export const AIKADT002000006_GRID_HEADER_MONTHLYBALANCEAUDITREMARKABLEICCREASEANDDECREASE = '月次残高監査\n著増減';
/** 監査結果一覧/表/ヘッダ/No2 */	
export const AIKADT002000006_GRID_HEADER_NO2 = '2';
/** 監査結果一覧/表/ヘッダ/月次残高監査マイナス残高 */	
export const AIKADT002000006_GRID_HEADER_MONTHLYBALANCEAUDITMINUSBALANCE = '月次残高監査\nマイナス残高';
/** 監査結果一覧/表/ヘッダ/No3 */	
export const AIKADT002000006_GRID_HEADER_NO3 = '3';
/** 監査結果一覧/表/ヘッダ/仕訳明細監査 */	
export const AIKADT002000006_GRID_HEADER_JOURNALIZATIONITEMAUDIT = '仕訳明細監査';
/** 監査結果一覧/表/ヘッダ/No4 */	
export const AIKADT002000006_GRID_HEADER_NO4 = '4';
/** 監査結果一覧/表/ヘッダ/仕訳重複監査 */	
export const AIKADT002000006_GRID_HEADER_JOURNALIZATIONDUPLICATEAUDIT = '仕訳重複監査';
/** 監査結果一覧/表/ヘッダ/No5 */	
export const AIKADT002000006_GRID_HEADER_NO5 = '5';
/** 監査結果一覧/表/ヘッダ/消費税区分監査 */	
export const AIKADT002000006_GRID_HEADER_TAXKUBUNAUDIT = '消費税区分監査';
/** 監査結果一覧/表/ヘッダ/No6 */	
export const AIKADT002000006_GRID_HEADER_NO6 = '6';
/** 監査結果一覧/表/ヘッダ/チェックリスト特記事項 */	
export const AIKADT002000006_GRID_HEADER_CHECKLISTNOTICE = 'チェックリスト\n特記事項';
/** 監査結果一覧/表/ヘッダ/最終実行日時 */	
export const AIKADT002000006_GRID_HEADER_LASTEXECUTIONTIME = '最終実行日時';
/** 監査結果一覧/表/ヘッダ/ステータス */	
export const AIKADT002000006_GRID_HEADER_STATUS = 'ステータス';
/** 監査結果一覧/表/ヘッダ/未確認 */	
export const AIKADT002000006_GRID_HEADER_UNCONFIRMED = '未確認';
/** 監査結果一覧/表/ヘッダ/確認OK */	
export const AIKADT002000006_GRID_HEADER_CONFIRMATIONOK = '確認OK';
/** 監査結果一覧/表/ヘッダ/確認NG */	
export const AIKADT002000006_GRID_HEADER_CONFIRMATIONNG = '確認NG';
/** 監査結果一覧/表/ヘッダ/保留 */	
export const AIKADT002000006_GRID_HEADER_PENDING = '保留';
/** 監査結果一覧/表/ヘッダ/計 */	
export const AIKADT002000006_GRID_HEADER_TOTAL = '計';
/** 監査結果一覧/表/ヘッダ/チェック済み */	
export const AIKADT002000006_GRID_HEADER_CHECKED = 'チェック済み';
/** 監査結果一覧/表/ヘッダ/未チェック */	
export const AIKADT002000006_GRID_HEADER_UNCHECKED = '未チェック';
/** 監査結果一覧/表/ヘッダ/特記事項 */	
export const AIKADT002000006_GRID_HEADER_NOTICE = '特記事項';
/** 監査結果一覧/表/ヘッダ/監査報告出力 */	
export const AIKADT002000006_GRID_HEADER_OUTPUT_RESULT_REOPRT = '監査報告出力';

/*
 * 監査結果[消費税区分]
 */
/** 監査結果[消費税区分]/機能/名称 */	
export const AIKADT002000011_FUNCTION_NAME = '監査結果[消費税区分]';
/** 監査結果[消費税区分]/機能/ヘッダ/対象月 */	
export const AIKADT002000011_FUNCTION_HEADER_TARGETMONTH = '対象月：';
/** 監査結果[消費税区分]/ボタン/機能/Excel出力ボタン */	
export const AIKADT002000011_BUTTON_FUNCTION_EXCEL = 'Excel出力';
/** 監査結果[消費税区分]/ボタン/機能/閉じるボタン */	
export const AIKADT002000011_BUTTON_FUNCTION_CLOSE = '閉じる';
/** 監査結果[消費税区分]/表/ヘッダ/連番ヘッダ */	
export const AIKADT002000011_GRID_HEADER_SEQUENCE = 'No.';
/** 監査結果[消費税区分]/表/ヘッダ/検索No.ヘッダ */	
export const AIKADT002000011_GRID_HEADER_SEARCHNO = '検索No.';
/** 監査結果[消費税区分]/表/ヘッダ/仕訳日付 */	
export const AIKADT002000011_GRID_HEADER_JOURNALIZATIONDATE = '仕訳日付';
/** 監査結果[消費税区分]/表/ヘッダ/借方貸方 */	
export const AIKADT002000011_GRID_HEADER_BALANCE = '借方\n貸方';
/** 監査結果[消費税区分]/表/ヘッダ/科目コードヘッダ */	
export const AIKADT002000011_GRID_HEADER_SUBJECTSCODE = '科目コード';
/** 監査結果[消費税区分]/表/ヘッダ/科目名ヘッダ */	
export const AIKADT002000011_GRID_HEADER_SUBJECTSNAME = '科目名';
/** 監査結果[消費税区分]/表/ヘッダ/標準税区分 */	
export const AIKADT002000011_GRID_HEADER_STANDARDTAXKUBUN = '標準税区分';
/** 監査結果[消費税区分]/表/ヘッダ/税区分 */	
export const AIKADT002000011_GRID_HEADER_SPECIFIEDTAXKUBUN = '指定された\n税区分';
/** 監査結果[消費税区分]/表/ヘッダ/ステータスヘッダ */	
export const AIKADT002000011_GRID_HEADER_STATUS = 'ステータス';
/** 監査結果[消費税区分]/表/ヘッダ/実行日時ヘッダ */	
export const AIKADT002000011_GRID_HEADER_EXECUTINGTIME = '実行日時';

/*
 * 監査結果[チェックリスト・特記事項]
 */
/** 監査結果[チェックリスト・特記事項]/機能/名称 */	
export const AIKADT002000012_FUNCTION_NAME = '監査結果[チェックリスト・特記事項]';
/** 監査結果[チェックリスト・特記事項]/入力/ヘッダ/開き括弧 */	
export const AIKADT002000012_INPUT_HEADER_OPENINGPARENTHESES = '(';
/** 監査結果[チェックリスト・特記事項]/入力/ヘッダ/閉じ括弧 */	
export const AIKADT002000012_INPUT_HEADER_CLOSINGPARENTHESIS = ')';
/** 監査結果[チェックリスト・特記事項]/機能/ヘッダ/対象月 */	
export const AIKADT002000012_FUNCTION_HEADER_TARGETMONTH = '対象月：';
/** 監査結果[チェックリスト・特記事項]/表/ヘッダ/連番ヘッダ */	
export const AIKADT002000012_GRID_HEADER_SEQUENCE = 'No.';
/** 監査結果[チェックリスト・特記事項]/表/ヘッダ/確認ヘッダ */	
export const AIKADT002000012_GRID_HEADER_CONFIRMATION = '確認';
/** 監査結果[チェックリスト・特記事項]/表/ヘッダ/チェック項目ヘッダ */	
export const AIKADT002000012_GRID_HEADER_CHECKITEMS = 'チェック項目';
/** 監査結果[チェックリスト・特記事項]/表/ヘッダ/コメントヘッダ */	
export const AIKADT002000012_GRID_HEADER_COMMENT = 'コメント';
/** 監査結果[チェックリスト・特記事項]/表/ヘッダ/特記事項タイトル */	
export const AIKADT002000012_GRID_HEADER_NOTICE = '特記事項';

/*
 * 監査結果[マイナス残高]
 */
/** 監査結果[マイナス残高]/機能/名称 */	
export const AIKADT002000008_FUNCTION_NAME = '監査結果[マイナス残高]';
/** 監査結果[マイナス残高]/機能/ヘッダ/対象月 */	
export const AIKADT002000008_FUNCTION_HEADER_TARGETMONTH = '対象月：';
/** 監査結果[マイナス残高]/表/ヘッダ/連番 */	
export const AIKADT002000008_GRID_HEADER_SEQUENCE = 'No.';
/** 監査結果[マイナス残高]/表/ヘッダ/パターン */	
export const AIKADT002000008_GRID_HEADER_PATTERN = 'パターン';
/** 監査結果[マイナス残高]/表/ヘッダ/科目コード */	
export const AIKADT002000008_GRID_HEADER_SUBJECTSCODE = '科目コード';
/** 監査結果[マイナス残高]/表/ヘッダ/科目名 */	
export const AIKADT002000008_GRID_HEADER_SUBJECTSNAME = '科目名';
/** 監査結果[マイナス残高]/表/ヘッダ/補助コード */	
export const AIKADT002000008_GRID_HEADER_AUXILIARYCODE = '補助コード';
/** 監査結果[マイナス残高]/表/ヘッダ/補助名 */	
export const AIKADT002000008_GRID_HEADER_AUXILIARYNAME = '補助名';
/** 監査結果[マイナス残高]/表/ヘッダ/部門コード */	
export const AIKADT002000008_GRID_HEADER_BMNCODE = '部門コード';
/** 監査結果[マイナス残高]/表/ヘッダ/部門名称 */	
export const AIKADT002000008_GRID_HEADER_BMNSUBJECTS = '部門名';
/** 監査結果[マイナス残高]/表/ヘッダ/金額[円] */	
export const AIKADT002000008_GRID_HEADER_PRICE = '金額[円]';
/** 監査結果[マイナス残高]/表/ヘッダ/ステータス */	
export const AIKADT002000008_GRID_HEADER_STATUS = 'ステータス';
/** 監査結果[マイナス残高]/表/ヘッダ/実行日時 */	
export const AIKADT002000008_GRID_HEADER_EXECUTINGTIME = '実行日時';
/** 監査結果[マイナス残高]/表/ヘッダ/部門コード */	
export const AIKADT002000008_GRID_HEADER_DEPARTMENTCODE = '部門コード';
/** 監査結果[マイナス残高]/表/ヘッダ/部門名 */	
export const AIKADT002000008_GRID_HEADER_DEPARTMENTNAME = '部門名';

/*
 * 監査結果[仕訳明細]
 */
/** 監査結果[仕訳明細]/機能/名称 */	
export const AIKADT002000009_FUNCTION_NAME = '監査結果[仕訳明細]';
/** 監査結果[仕訳明細]/機能/ヘッダ/対象月 */	
export const AIKADT002000009_FUNCTION_HEADER_TARGETMONTH = '対象月：';
/** 監査結果[仕訳明細]/タブ/ヘッダ/仕訳明細監査結果 */	
export const AIKADT002000009_TAB_HEADER_JOURNALIZATIONITEMAUDITRESULT = '仕訳明細監査結果';
/** 監査結果[仕訳明細]/タブ/ヘッダ/仕訳明細監査結果集計表 */	
export const AIKADT002000009_TAB_HEADER_JOURNALIZATIONITEMAUDITRESULTAGGREGATETABLE = '仕訳明細監査結果集計表';
/** 監査結果[仕訳明細]/表/ヘッダ/連番 */	
export const AIKADT002000009_GRID_HEADER_SEQUENCE = 'No.';
/** 監査結果[仕訳明細]/表/ヘッダ/仕訳明細ルール */	
export const AIKADT002000009_GRID_HEADER_JOURNALIZATIONITEMRULE = '仕訳明細ルール';
/** 監査結果[仕訳明細]/表/ヘッダ/ステータス */	
export const AIKADT002000009_GRID_HEADER_STATUS = 'ステータス';
/** 監査結果[仕訳明細]/表/ヘッダ/実行日時 */	
export const AIKADT002000009_GRID_HEADER_EXECUTINGTIME = '実行日時';
/** 監査結果[仕訳明細]/機能/表/最終実行日時 */	
export const AIKADT002000009_FUNCTION_GRID_LASTEXECUTIONTIME = '最終実行日時:';
/** 監査結果[仕訳明細]/表/ヘッダ/未確認 */	
export const AIKADT002000009_GRID_HEADER_UNCONFIRMED = '未確認';
/** 監査結果[仕訳明細]/表/ヘッダ/確認OK */	
export const AIKADT002000009_GRID_HEADER_CONFIRMATIONOK = '確認OK';
/** 監査結果[仕訳明細]/表/ヘッダ/確認NG */	
export const AIKADT002000009_GRID_HEADER_CONFIRMATIONNG = '確認NG';
/** 監査結果[仕訳明細]/表/ヘッダ/保留 */	
export const AIKADT002000009_GRID_HEADER_PENDING = '保留';
/** 監査結果[仕訳明細]/表/ヘッダ/計 */	
export const AIKADT002000009_GRID_HEADER_TOTAL = '計';

/*
 * 月次残高ルール設定
 */
/** 月次残高ルール設定/機能/名称 */	
export const AIKADT001000003_FUNCTION_NAME = '月次残高ルール設定';
/** 月次残高ルール設定/入力/ヘッダ/監査対象 */	
export const AIKADT001000003_INPUT_HEADER_AUDITTARGET = '監査対象';
/** 月次残高ルール設定/表/ヘッダ/コード */	
export const AIKADT001000003_GRID_HEADER_CODE = 'コード';
/** 月次残高ルール設定/表/ヘッダ/科目 */	
export const AIKADT001000003_GRID_HEADER_SUBJECTS = '科目';
/** 月次残高ルール設定/表/ヘッダ/部門コード */	
export const AIKADT001000003_GRID_HEADER_BMNCODE = 'コード';
/** 月次残高ルール設定/表/ヘッダ/部門名称 */	
export const AIKADT001000003_GRID_HEADER_BMNSUBJECTS = '部門';
/** 月次残高ルール設定/表/ヘッダ/著増減 */	
export const AIKADT001000003_GRID_HEADER_REMARKABLEINCREASEANDDECREASE = '著増減';
/** 月次残高ルール設定/表/ヘッダ/マイナス残高 */	
export const AIKADT001000003_GRID_HEADER_MINUSBALANCE = 'マイナス残高';
/** 月次残高ルール設定/表/ヘッダ/勘定 */	
export const AIKADT001000003_GRID_HEADER_ACCOUNT = '勘定';
/** 月次残高ルール設定/表/ヘッダ/採用 */	
export const AIKADT001000003_GRID_HEADER_ACCOUNTRECRUIT = '採用';
/** 月次残高ルール設定/表/ヘッダ/補助 */	
export const AIKADT001000003_GRID_HEADER_ASSIST = '補助';
/** 月次残高ルール設定/表/ヘッダ/比較対象月 */	
export const AIKADT001000003_GRID_HEADER_COMPARISONMONTH = '比較対象月';
/** 月次残高ルール設定/表/ヘッダ/符号 */	
export const AIKADT001000003_GRID_HEADER_SIGN = '符号';
/** 月次残高ルール設定/表/ヘッダ/しきい値 */	
export const AIKADT001000003_GRID_HEADER_THRESHOLDVALUE = 'しきい値';
/** 月次残高ルール設定/表/ソート/true */	
export const AIKADT001000003_GRID_SORT_TRUE = 'あり';
/** 月次残高ルール設定/表/ソート/false */	
export const AIKADT001000003_GRID_SORT_FALSE = 'なし';
/** 月次残高ルール設定/表/ヘッダ/比較値 */	
export const AIKADT001000003_GRID_HEADER_COMPARISONVALUE = '比較値';
/** 月次残高ルール設定/表/ヘッダ/しきい値（金額） */	
export const AIKADT001000003_GRID_HEADER_THRESHOLDVALUEMONEY = 'しきい値（金額）';
/** 月次残高ルール設定/入力/名称/ */	
export const AIKADT001000003_INPUT_NAME_DEFAULT = '（標準）';
/** 月次残高ルール設定/入力/ヘッダ/ */	
export const AIKADT001000003_INPUT_HEADER_DEFAULTSETTING = '標準に設定する';

/*
 * 監査実行・結果表示
 */
/** 監査実行・結果表示/機能/名称 */	
export const AIKADT002000001_FUNCTION_NAME = '監査実行・結果表示';
/** 監査実行・結果表示/ボタン/機能/監査結果出力 */	
export const AIKADT002000001_BUTTON_FUNCTION_RESULT_EXPORT = '監査結果出力';
/** 監査実行・結果表示/ボタン/機能/著増減(監査)実行 */	
export const AIKADT002000001_BUTTON_FUNCTION_REMARKINCDECEXECUTION = '著増減監査実行';
/** 監査実行・結果表示/ボタン/機能/マイナス残高(監査)実行 */	
export const AIKADT002000001_BUTTON_FUNCTION_NEGATIVEBALANCEEXECUTION = 'マイナス残高監査実行';
/** 監査実行・結果表示/ボタン/機能/仕訳明細(監査)実行 */	
export const AIKADT002000001_BUTTON_FUNCTION_JOURNALLINEEXECUTION = '仕訳明細監査実行';
/** 監査実行・結果表示/ボタン/機能/仕訳重複(監査)実行 */	
export const AIKADT002000001_BUTTON_FUNCTION_JOURNALDOUBLEEXECUTION = '仕訳重複監査実行';
/** 監査実行・結果表示/ボタン/機能/消費税区分(監査)実行 */	
export const AIKADT002000001_BUTTON_FUNCTION_TAXKUBUNEXECUTION = '消費税区分監査実行';
/** 監査実行・結果表示/ボタン/機能/一括監査実行 */	
export const AIKADT002000001_BUTTON_FUNCTION_BATCHEXECUTION = '一括監査実行';
/** 監査実行・結果表示/ボタン/機能/除外項目設定 */	
export const AIKADT002000001_BUTTON_FUNCTION_EXCLUSIONITEMSETTING = '除外項目設定';
/** 監査実行・結果表示/表/ヘッダ/コード */	
export const AIKADT002000001_GRID_HEADER_CODE = 'コード';
/** 監査実行・結果表示/表/ヘッダ/科目 */	
export const AIKADT002000001_GRID_HEADER_SUBJECTS = '科目';
/** 監査実行・結果表示/表/ヘッダ/実行結果 */	
export const AIKADT002000001_GRID_HEADER_EXECUTIONRESULT = '実行結果';
/** 監査実行・結果表示/表/ヘッダ/前月残高 */	
export const AIKADT002000001_GRID_HEADER_PREVIOUSMONTHBALANCE = '前月残高';
/** 監査実行・結果表示/表/ヘッダ/借方　計 */	
export const AIKADT002000001_GRID_HEADER_DEBTORTOTAL = '借方　計';
/** 監査実行・結果表示/表/ヘッダ/貸方　計 */	
export const AIKADT002000001_GRID_HEADER_CREDITTOTAL = '貸方　計';
/** 監査実行・結果表示/表/ヘッダ/当月残高 */	
export const AIKADT002000001_GRID_HEADER_CURRENTMONTHBALANCE = '当月残高';
/** 監査実行・結果表示/表/ソート/あり */	
export const AIKADT002000001_GRID_SORT_EXISTS = 'あり';
/** 監査実行・結果表示/表/ソート/なし */	
export const AIKADT002000001_GRID_SORT_NONE = 'なし';
/** 監査実行・結果表示/入力/ヘッダ/著増減(監査)内容 */	
export const AIKADT002000001_INPUT_HEADER_REMARKINCDECCONTENT = '月次残高監査ルールに従って著増減監査を行います。';
/** 監査実行・結果表示/入力/ヘッダ/マイナス残高(監査)内容 */	
export const AIKADT002000001_INPUT_HEADER_NEGATIVEBALANCECONTENT = '月次残高監査ルールに従ってマイナス残高監査を行います。';
/** 監査実行・結果表示/入力/ヘッダ/仕訳明細(監査)内容 */	
export const AIKADT002000001_INPUT_HEADER_JOURNALLINECONTENT = '下記で指定した仕訳明細監査ルールに従って監査を行います。';
/** 監査実行・結果表示/入力/ヘッダ/仕訳重複(監査)内容 */	
export const AIKADT002000001_INPUT_HEADER_JOURNALDOUBLECONTENT = '下記指定条件にて仕訳の重複監査を行います。';
/** 監査実行・結果表示/入力/ヘッダ/消費税区分(監査)内容 */	
export const AIKADT002000001_INPUT_HEADER_TAXKUBUNCONTENT = '科目に設定されている税区分と異なる税区分が指定されている仕訳の監査を行います。';
/** 監査実行・結果表示/入力/ヘッダ/一括監査実行内容 */	
export const AIKADT002000001_INPUT_HEADER_BATCHEXECUTION = '下記で指定した監査を行います。';
/** 監査実行・結果表示/入力/ヘッダ/一括実行確認内容ラベル */	
export const AIKADT002000001_INPUT_HEADER_BATCHEXECUTIONCOMFIRM = '一括監査を実行します。\n処理に時間がかかります。\n処理を実行してよろしいでしょうか？';
/** 監査実行・結果表示/ボタン/機能/監査実行 */	
export const AIKADT002000001_BUTTON_FUNCTION_AUDITEXECUTION = '監査実行';
/** 監査実行・結果表示/入力/ヘッダ/仕訳明細(監査)条件 */	
export const AIKADT002000001_INPUT_HEADER_JOURNALLINECONDITION = '＜監査実行対象＞';
/** 監査実行・結果表示/入力/ヘッダ/一括監査実行条件 */	
export const AIKADT002000001_INPUT_HEADER_BATCHEXECUTIONCONDITION = '＜監査実行対象＞';
/** 監査実行・結果表示/表/ヘッダ/連番ヘッダ */	
export const AIKADT002000001_GRID_HEADER_SEQUENCE = 'No.';
/** 監査実行・結果表示/表/ヘッダ/種別 */	
export const AIKADT002000001_GRID_HEADER_TYPE = '種別';
/** 監査実行・結果表示/表/ヘッダ/対象 */	
export const AIKADT002000001_GRID_HEADER_TARGET = '対象';
/** 監査実行・結果表示/表/ヘッダ/名称 */	
export const AIKADT002000001_GRID_HEADER_NAME = '名称';
/** 監査実行・結果表示/表/ヘッダ/連想 */	
export const AIKADT002000001_GRID_HEADER_RENSO = '連想';
/** 監査実行・結果表示/表/ソート/true */	
export const AIKADT002000001_GRID_SORT_TRUE = 'あり';
/** 監査実行・結果表示/表/ソート/false */	
export const AIKADT002000001_GRID_SORT_FALSE = 'なし';
/** 監査実行・結果表示/入力/ヘッダ/仕訳重複(監査)条件 */	
export const AIKADT002000001_INPUT_HEADER_JOURNALDOUBLECONDITION = '＜重複監査条件指定＞';
/** 監査実行・結果表示/入力/ヘッダ/実行中 */	
export const AIKADT002000001_INPUT_HEADER_EXECUTING = '実行中．．．';
/** 監査実行・結果表示/入力/ヘッダ/複数月監査注意喚起記載 */	
export const AIKADT002000001_INPUT_HEADER_MULTIPLEMONTH = '※各決算月を集計最終月にして複数月集計した場合、「決算１」が監査対象となっています。\n　決算２～３も含めて監査を行う場合は、「決算」ボタンより月を選択してください。';

/*
 * Excel出力設定
 */
/** Excel出力設定/機能/名称 */	
export const AIKCMN001000002_FUNCTION_NAME = 'Excel出力設定';
/** Excel出力設定/入力/ヘッダ/パスワードを設定 */	
export const AIKCMN001000002_INPUT_HEADER_SETTINGPASSWORD = 'パスワードを設定';
/** Excel出力設定/入力/ヘッダ/パスワード */	
export const AIKCMN001000002_INPUT_HEADER_PASSWORD = 'パスワード';
/** Excel出力設定/入力/ヘッダ/パスワード(確認用) */	
export const AIKCMN001000002_INPUT_HEADER_PASSWORDCONFIRMATION = 'パスワード(確認用)';
/** Excel出力設定/入力/ヘッダ/パスワードを表示しながら入力する */	
export const AIKCMN001000002_INPUT_HEADER_DISPLAYPASSWORD = 'パスワードを表示しながら入力する';
/** Excel出力設定/入力/ヘッダ/会社情報 */	
export const AIKCMN001000002_INPUT_HEADER_COMPANYINFORMATION = '会社情報';
/** Excel出力設定/入力/ヘッダ/会社コード */	
export const AIKCMN001000002_INPUT_HEADER_COMPANYCODE = '会社コード';
/** Excel出力設定/入力/ヘッダ/会社名 */	
export const AIKCMN001000002_INPUT_HEADER_COMPANYNAME = '会社名';
/** Excel出力設定/ボタン/機能/出力 */	
export const AIKCMN001000002_BUTTON_FUNCTION_OUTPUT = '出力';
/** Excel出力設定/機能/名称/出力中 */	
export const AIKCMN001000002_FUNCTION_NAME_INOUTPUT = '出力中';

/*
 * Excel監査対象出力設定
 */
/** Excel監査対象出力設定/機能/名称 */	
export const AIKCMN001000013_FUNCTION_NAME = 'Excel監査対象出力設定';
/** Excel監査対象出力設定/入力/ヘッダ/会社情報 */	
export const AIKCMN001000013_INPUT_HEADER_COMPANYINFORMATION = '会社情報';
/** Excel監査対象出力設定/入力/ヘッダ/会社コード */	
export const AIKCMN001000013_INPUT_HEADER_COMPANYCODE = '会社コード';
/** Excel監査対象出力設定/入力/ヘッダ/会社名 */	
export const AIKCMN001000013_INPUT_HEADER_COMPANYNAME = '会社名';
/** Excel監査対象出力設定/入力/ヘッダ/監査対象 */	
export const AIKCMN001000013_INPUT_HEADER_AUDITTARGET = '監査対象';
/** Excel監査対象出力設定/入力/ヘッダ/範囲 */	
export const AIKCMN001000013_INPUT_HEADER_RANGE = '範囲';
/** Excel監査対象出力設定/入力/ヘッダ/チルダ */	
export const AIKCMN001000013_INPUT_HEADER_TILDE = '～';
/** Excel監査対象出力設定/ボタン/機能/出力 */	
export const AIKCMN001000013_BUTTON_FUNCTION_OUTPUT = '出力';
/** Excel監査対象出力設定/機能/名称/出力中 */	
export const AIKCMN001000013_FUNCTION_NAME_INOUTPUT = '出力中';

/*
 * 共通機能
 */
/** 共通機能//名称 */	
export const AIKCMN001000001_NAME = '';
/** 共通機能/ボタン/機能/ヘルプ */	
export const AIKCMN001000001_BUTTON_FUNCTION_HELP = 'ヘルプ';
/** 共通機能/ボタン/機能/パスワード変更 */	
export const AIKCMN001000001_BUTTON_FUNCTION_PASSCHANGE = 'パスワード変更';
/** 共通機能/ボタン/機能/バージョン情報 */	
export const AIKCMN001000001_BUTTON_FUNCTION_VERSIONINFORMATION = 'バージョン情報';
/** 共通機能/ボタン/機能/バージョン履歴 */	
export const AIKCMN001000001_BUTTON_FUNCTION_VERSIONHISTORY = 'バージョン履歴';
/** 共通機能/ボタン/機能/ログアウト */	
export const AIKCMN001000001_BUTTON_FUNCTION_LOGOUT = 'ログアウト';
/** 共通機能/ボタン/機能/会社を切り替える */	
export const AIKCMN001000001_BUTTON_FUNCTION_DATASELECT = '会社を切り替える';
/** 共通機能/ボタン/機能/ホーム */	
export const AIKCMN001000001_BUTTON_FUNCTION_HOME = 'ホーム';
/** 共通機能/ボタン/機能/月次監査 */	
export const AIKCMN001000001_BUTTON_FUNCTION_MONTHLYAUDIT = '月次監査';
/** 共通機能/ボタン/機能/導入・登録処理 */	
export const AIKCMN001000001_BUTTON_FUNCTION_DONYU = '導入・登録処理';
/** 共通機能/ボタン/機能/各種マスタ登録 */	
export const AIKCMN001000001_BUTTON_FUNCTION_VARIOUSMASTERREGISTRATION = '各種マスタ登録';
/** 共通機能/ボタン/機能/管理者 */	
export const AIKCMN001000001_BUTTON_FUNCTION_KANRISYA = '管理者';
/** 共通機能/機能/名称/月次監査 */	
export const AIKCMN001000001_FUNCTION_NAME_MONTHLYAUDIT = '月次監査';
/** 共通機能/機能/名称/導入・登録処理 */	
export const AIKCMN001000001_FUNCTION_NAME_DONYU = '導入・登録処理';
/** 共通機能/機能/名称/各種マスタ登録 */	
export const AIKCMN001000001_FUNCTION_NAME_VARIOUSMASTERREGISTRATION = '各種マスタ登録';
/** 共通機能/機能/名称/管理者 */	
export const AIKCMN001000001_FUNCTION_NAME_KANRISYA = '管理者';
/** 共通機能/ボタン/ヘッダ/監査実行 */	
export const AIKCMN001000001_BUTTON_HEADER_AUDITEXECUTION = '監査実行';
/** 共通機能/ボタン/機能/監査実行・結果表示 */	
export const AIKCMN001000001_BUTTON_FUNCTION_AUDITEXECUTINGRESULTDISPLAY = '監査実行・\n結果表示';
/** 共通機能/ボタン/ヘッダ/チェックリスト */	
export const AIKCMN001000001_BUTTON_HEADER_CHECKLIST = 'チェックリスト';
/** 共通機能/ボタン/機能/チェックリスト */	
export const AIKCMN001000001_BUTTON_FUNCTION_CHECKLIST = 'チェックリスト';
/** 共通機能/ボタン/ヘッダ/監査結果一覧 */	
export const AIKCMN001000001_BUTTON_HEADER_AUDITRESULTLIST = '監査結果一覧';
/** 共通機能/ボタン/機能/監査結果一覧 */	
export const AIKCMN001000001_BUTTON_FUNCTION_AUDITRESULTLIST = '監査結果一覧';
/** 共通機能/ボタン/ヘッダ/データ取込 */	
export const AIKCMN001000001_BUTTON_HEADER_DATAIMPORT = 'データ取込';
/** 共通機能/ボタン/ヘッダ/データ取込 */	
export const AIKCMN001000001_BUTTON_HEADER_SWKIMPORT = 'データ取込';
/** 共通機能/ボタン/機能/仕訳・実績データ取込み */	
export const AIKCMN001000001_BUTTON_FUNCTION_SWKIMPORT = '仕訳・実績データ取込み';
/** 共通機能/ボタン/ヘッダ/導入・登録処理 */	
export const AIKCMN001000001_BUTTON_HEADER_DONYU = '導入・登録処理';
/** 共通機能/ボタン/機能/会社基本情報 */	
export const AIKCMN001000001_BUTTON_FUNCTION_KIHONJYOHO = '会社基本情報';
/** 共通機能/ボタン/機能/勘定科目登録 */	
export const AIKCMN001000001_BUTTON_FUNCTION_KANJYOKAMOKU = '勘定科目登録';
/** 共通機能/ボタン/機能/導入ウィザード */	
export const AIKCMN001000001_BUTTON_FUNCTION_WIZARD = '導入ウィザード';
/** 共通機能/ボタン/ヘッダ/監査ルール設定 */	
export const AIKCMN001000001_BUTTON_HEADER_AUDITRULEPREFERENCE = '監査ルール設定';
/** 共通機能/ボタン/機能/仕訳明細監査ルール設定一覧 */	
export const AIKCMN001000001_BUTTON_FUNCTION_JOURNALIZATIONITEMAUDITRULESETTINGLIST = '仕訳明細\nルール設定一覧';
/** 共通機能/ボタン/機能/月次残高監査ルール設定 */	
export const AIKCMN001000001_BUTTON_FUNCTION_MONTHLYBALANCEAUDITRULEPREFERENCE = '月次残高ルール設定';
/** 共通機能/ボタン/ヘッダ/チェックリスト設定 */	
export const AIKCMN001000001_BUTTON_HEADER_CHECKLISTPREFERENCE = 'チェックリスト設定';
/** 共通機能/ボタン/機能/チェックリスト設定一覧 */	
export const AIKCMN001000001_BUTTON_FUNCTION_CHECKLISTSETTINGLIST = 'チェックリスト設定一覧';
/** 共通機能/ボタン/ヘッダ/データ関係処理 */	
export const AIKCMN001000001_BUTTON_HEADER_DATAKANKEI = 'データ関係処理';
/** 共通機能/ボタン/機能/会社データ作成 */	
export const AIKCMN001000001_BUTTON_FUNCTION_DATASAKUSEI = '会社データ作成';
/** 共通機能/ボタン/機能/会社データ削除 */	
export const AIKCMN001000001_BUTTON_FUNCTION_DATASAKUJYO = '会社データ削除';
/** 共通機能/ボタン/ヘッダ/担当者設定 */	
export const AIKCMN001000001_BUTTON_HEADER_TANTO = '担当者設定';
/** 共通機能/ボタン/機能/担当者アカウント登録・変更 */	
export const AIKCMN001000001_BUTTON_FUNCTION_TANTOACCOUNT = '担当者アカウント登録・変更';
/** 共通機能/ボタン/機能/会社別担当者設定 */	
export const AIKCMN001000001_BUTTON_FUNCTION_KAISYATANTO = '会社別担当者設定';
/** 共通機能/ボタン/ヘッダ/その他情報 */	
export const AIKCMN001000001_BUTTON_HEADER_SONOTA = 'その他情報';
/** 共通機能/ボタン/機能/システム履歴 */	
export const AIKCMN001000001_BUTTON_FUNCTION_SYSTEMRIREKI = 'システム履歴';
/** 共通機能/ボタン/機能/契約情報 */	
export const AIKCMN001000001_BUTTON_FUNCTION_KEIYAKU = '契約情報';
/** 共通機能/ボタン/機能/ユーザー情報の確認・変更 */	
export const AIKCMN001000001_BUTTON_FUNCTION_USERINFO = 'ユーザー情報の確認・変更';
/** 共通機能/機能/名称/お知らせ */	
export const AIKCMN001000001_FUNCTION_NAME_NEWS = 'お知らせ';
/** 共通機能/機能/名称/バージョン情報 */	
export const AIKCMN001000001_FUNCTION_NAME_VERSIONINFORMATION = 'バージョン情報';
/** 共通機能/入力/ヘッダ/バージョン： */	
export const AIKCMN001000001_INPUT_HEADER_VERSION = 'バージョン：';
/** 共通機能/ボタン/機能/OK */	
export const AIKCMN001000001_BUTTON_FUNCTION_OK = 'OK';
/** 共通機能/機能/名称/バージョン履歴 */	
export const AIKCMN001000001_FUNCTION_NAME_VERSIONHISTORY = 'バージョン履歴';
/** 共通機能/入力/ヘッダ/バージョン履歴 */	
export const AIKCMN001000001_INPUT_HEADER_VERSIONHISTORY = 'バージョン履歴';
/** 共通機能/メンテナンス/メンテナンス */	
export const AIKCMN001000001_MAINTENANCE_OBSTACLE_NEWS = '障害・メンテナンス情報';

/*
 * データ取込
 */
/** データ取込/機能/名称 */	
export const AIKADT005000001_FUNCTION_NAME = 'データ取込';

/*
 * AI仕訳チェック
 */
/** AI仕訳チェック/機能/名称 */	
export const AIKADT002000013_FUNCTION_NAME = 'AI仕訳チェック';
/** AI仕訳チェック/機能/ヘッダ/提案件数 */	
export const AIKADT002000013_FUNCTION_HEADER_SUGGESTEDCOUNT = '提案件数';
/** AI仕訳チェック/機能/ヘッダ/仕訳件数 */	
export const AIKADT002000013_FUNCTION_HEADER_JOURNALLINECOUNT = '仕訳件数';
/** AI仕訳チェック/機能/ヘッダ/修正提案 */	
export const AIKADT002000013_FUNCTION_HEADER_MODIFICATIONSUGGESTED = '修正提案';
/** AI仕訳チェック/機能/ヘッダ/借方 */	
export const AIKADT002000013_FUNCTION_HEADER_DEBTOR = '＜借方＞';
/** AI仕訳チェック/機能/ヘッダ/貸方 */	
export const AIKADT002000013_FUNCTION_HEADER_CREDIT = '＜貸方＞';
/** AI仕訳チェック/表/ヘッダ/検索No. */	
export const AIKADT002000013_GRID_HEADER_SEARCHNO = '検索No.';
/** AI仕訳チェック/表/ヘッダ/伝票No. */	
export const AIKADT002000013_GRID_HEADER_SLIPNO = '伝票No.';
/** AI仕訳チェック/表/ヘッダ/月日 */	
export const AIKADT002000013_GRID_HEADER_MONTHANDDAY = '月日';
/** AI仕訳チェック/表/ヘッダ/借方科目 */	
export const AIKADT002000013_GRID_HEADER_DEBITSUBJECTS = '借方科目';
/** AI仕訳チェック/表/ヘッダ/借方部門 */	
export const AIKADT002000013_GRID_HEADER_DEBITSECTION = '借方部門';
/** AI仕訳チェック/表/ヘッダ/借方補助 */	
export const AIKADT002000013_GRID_HEADER_DEBITAUXILIARY = '借方補助';
/** AI仕訳チェック/表/ヘッダ/借方金額 */	
export const AIKADT002000013_GRID_HEADER_DEBITAMOUNT = '借方金額';
/** AI仕訳チェック/表/ヘッダ/貸方科目 */	
export const AIKADT002000013_GRID_HEADER_CREDITSUBJECTS = '貸方科目';
/** AI仕訳チェック/表/ヘッダ/貸方部門 */	
export const AIKADT002000013_GRID_HEADER_CREDITSECTION = '貸方部門';
/** AI仕訳チェック/表/ヘッダ/貸方補助 */	
export const AIKADT002000013_GRID_HEADER_CREDITAUXILIARY = '貸方補助';
/** AI仕訳チェック/表/ヘッダ/貸方金額 */	
export const AIKADT002000013_GRID_HEADER_CREDITAMOUNT = '貸方金額';
/** AI仕訳チェック/表/ヘッダ/消費税/資金科目 */	
export const AIKADT002000013_GRID_HEADER_TAXFUNDSUBJECTS = '消費税/資金科目';
/** AI仕訳チェック/表/ヘッダ/期日 */	
export const AIKADT002000013_GRID_HEADER_DEADLINE = '期日';
/** AI仕訳チェック/表/ヘッダ/摘要 */	
export const AIKADT002000013_GRID_HEADER_REMARKS = '摘要';
/** AI仕訳チェック/ボタン/機能/表示切替 */	
export const AIKADT002000013_BUTTON_FUNCTION_DISPLAYSWITCH = '表示切替';

/*
 * インポート・エクスポート
 */
/** インポート・エクスポート/機能/名称 */	
export const AIKCMN001000005_FUNCTION_NAME = 'インポート・エクスポート';
/** インポート・エクスポート/エクスポート/機能/名称 */	
export const AIKCMN001000005_EXPORT_HEADER_NAME = '監査ルールエクスポート';
/** インポート・エクスポート/ボタン/機能/出力 */	
export const AIKCMN001000005_BUTTON_FUNCTION_OUTPUT = '出力';
/** インポート・エクスポート/機能/名称/出力中 */	
export const AIKCMN001000005_FUNCTION_NAME_INOUTPUT = '出力中';
/** インポート・エクスポート/インポート/機能/名称 */	
export const AIKCMN001000005_IMPORT_HEADER_NAME = '監査ルールインポート';
/** インポート・エクスポート/入力/ヘッダ/取込ファイル */	
export const AIKCMN001000005_INPUT_HEADER_IMPORTFILE = '取込ファイル';
/** インポート・エクスポート/入力/ヘッダ/取込ルール */	
export const AIKCMN001000005_INPUT_HEADER_IMPORTRULE = '取込ルール';
/** インポート・エクスポート/入力/ヘッダ/インポート方法 */	
export const AIKCMN001000005_INPUT_HEADER_IMPORTMETHOD = 'インポート方法';
/** インポート・エクスポート/入力/ヘッダ/結果メッセージ */	
export const AIKCMN001000005_INPUT_HEADER_IMPORTRESULTMESS = '結果メッセージ';
/** インポート・エクスポート/ボタン/機能/取込 */	
export const AIKCMN001000005_BUTTON_FUNCTION_INPUT = '取込';
/** インポート・エクスポート/機能/名称/取込中 */	
export const AIKCMN001000005_FUNCTION_NAME_ININPUT = '取込中';
/** インポート・エクスポート/ボタン/機能/閉じる */	
export const AIKCMN001000005_BUTTON_FUNCTION_CLOSE = '閉じる';
/** インポート・エクスポート/ラベル/警告 */	
export const AIKCMN001000005_LABEL_ALERT = 'インポートを行うとインポート前の設定に戻すことが出来なくなります。\n現在の設定をエクスポートしておくことを推奨します。';

/*
 * システム履歴
 */
/** システム履歴/機能/名称 */	
export const AIKCMN007000001_FUNCTION_NAME = 'システム履歴';
/** システム履歴/ボタン/機能/Excel出力ボタン */	
export const AIKCMN007000001_BUTTON_FUNCTION_EXCEL = 'Excel出力';
/** システム履歴/ボタン/機能/閉じるボタン */	
export const AIKCMN007000001_BUTTON_FUNCTION_CLOSE = '閉じる';
/** システム履歴/入力/ヘッダ/開始日時 */	
export const AIKCMN007000001_INPUT_HEADER_DATETIME = '開始日時';
/** システム履歴/入力/ヘッダ/チルダ */	
export const AIKCMN007000001_INPUT_HEADER_TILDE = '～';
/** システム履歴/入力/ヘッダ/処理名 */	
export const AIKCMN007000001_INPUT_HEADER_FUNCTION = '処理名';
/** システム履歴/入力/ヘッダ/担当者名 */	
export const AIKCMN007000001_INPUT_HEADER_OFFICESTAFF = '担当者名';
/** システム履歴/入力/ヘッダ/会社名 */	
export const AIKCMN007000001_INPUT_HEADER_COMPANYNAME = '会社名';
/** システム履歴/入力/ヘッダ/操作 */	
export const AIKCMN007000001_INPUT_HEADER_OPERATION = '操作';
/** システム履歴/ボタン/機能/検索 */	
export const AIKCMN007000001_BUTTON_FUNCTION_SEARCH = '検索';
/** システム履歴/表/ヘッダ/開始日時 */	
export const AIKCMN007000001_GRID_HEADER_DATETIME = '開始日時';
/** システム履歴/表/ヘッダ/データ年度 */	
export const AIKCMN007000001_GRID_HEADER_FISCALYEAR = 'データ年度';
/** システム履歴/表/ヘッダ/会社名 */	
export const AIKCMN007000001_GRID_HEADER_COMPANYNAME = '会社名';
/** システム履歴/表/ヘッダ/処理名 */	
export const AIKCMN007000001_GRID_HEADER_FUNCTIONNAME = '処理名';
/** システム履歴/表/ヘッダ/操作 */	
export const AIKCMN007000001_GRID_HEADER_OPERATION = '操作';
/** システム履歴/表/ヘッダ/権限 */	
export const AIKCMN007000001_GRID_HEADER_OFFICESTAFFKBN = '権限';
/** システム履歴/表/ヘッダ/担当者名 */	
export const AIKCMN007000001_GRID_HEADER_OFFICESTAFF = '担当者名';
/** システム履歴/機能/フッタ/警告ラベル */	
export const AIKCMN007000001_FUNCTION_FOOTER_TOOMANYROWS = '※検索結果が上限100件を超えた為、検索条件を見直して再度検索してください。\n';

/*
 * 担当者アカウント登録・変更
 */
/** 担当者アカウント登録・変更/機能/名称 */	
export const AIKCMN005000001_FUNCTION_NAME = '担当者アカウント登録・変更';
/** 担当者アカウント登録・変更/入力/ヘッダ/担当者ラベル */	
export const AIKCMN005000001_INPUT_HEADER_OFFICESTAFF = '担当者';
/** 担当者アカウント登録・変更/ボタン/機能/新規担当者 */	
export const AIKCMN005000001_BUTTON_FUNCTION_CREATE = '新規担当者';
/** 担当者アカウント登録・変更/入力/ヘッダ/ログインIDラベル */	
export const AIKCMN005000001_INPUT_HEADER_LOGINID = 'ログインID';
/** 担当者アカウント登録・変更/入力/ヘッダ/パスワード変更ラベル */	
export const AIKCMN005000001_INPUT_HEADER_PWDCHANGE = 'パスワードを変更する';
/** 担当者アカウント登録・変更/入力/ヘッダ/パスワードラベル */	
export const AIKCMN005000001_INPUT_HEADER_PWD = 'パスワード';
/** 担当者アカウント登録・変更/入力/ヘッダ/パスワード（確認用）ラベル */	
export const AIKCMN005000001_INPUT_HEADER_PWDCONFIRM = 'パスワード(確認用)';
/** 担当者アカウント登録・変更/入力/ヘッダ/パスワード入力切替ラベル */	
export const AIKCMN005000001_INPUT_HEADER_PWDVISIBLE = 'パスワードを表示しながら入力する';
/** 担当者アカウント登録・変更/入力/ヘッダ/担当者名ラベル */	
export const AIKCMN005000001_INPUT_HEADER_OFFICESTAFFNAME = '担当者名';
/** 担当者アカウント登録・変更/入力/ヘッダ/担当者カナ名ラベル */	
export const AIKCMN005000001_INPUT_HEADER_OFFICESTAFFKANA = '担当者カナ名';
/** 担当者アカウント登録・変更/入力/ヘッダ/メールアドレスラベル */	
export const AIKCMN005000001_INPUT_HEADER_MAIL = 'メールアドレス';
/** 担当者アカウント登録・変更/入力/ヘッダ/メールアドレス（確認用）ラベル */	
export const AIKCMN005000001_INPUT_HEADER_MAILCONFIRM = 'メールアドレス(確認用)';
/** 担当者アカウント登録・変更/入力/ヘッダ/権限ラベル */	
export const AIKCMN005000001_INPUT_HEADER_AUTHORITY = '権限';
/** 担当者アカウント登録・変更/入力/ヘッダ/ログイン有効無効ラベル */	
export const AIKCMN005000001_INPUT_HEADER_LOGINENABLED = 'ログイン';
/** 担当者アカウント登録・変更/ボタン/機能/保存ボタン */	
export const AIKCMN005000001_BUTTON_FUNCTION_SAVE = '保存';
/** 担当者アカウント登録・変更/ボタン/機能/削除ボタン */	
export const AIKCMN005000001_BUTTON_FUNCTION_CANCEL = '削除';

/*
 * 会社別担当者設定
 */
/** 会社別担当者設定/機能/名称 */	
export const AIKCMN006000001_FUNCTION_NAME = '会社別担当者設定';
/** 会社別担当者設定/ボタン/機能/Excel出力ボタン */	
export const AIKCMN006000001_BUTTON_FUNCTION_EXCEL = 'Excel出力';
/** 会社別担当者設定/ボタン/機能/閉じるボタン */	
export const AIKCMN006000001_BUTTON_FUNCTION_CLOSE = '閉じる';
/** 会社別担当者設定/入力/ヘッダ/設定方法説明ラベル */	
export const AIKCMN006000001_INPUT_HEADER_GUIDANCE = '会社ごとに利用可能な担当者を設定します。\n・会社に担当者を設定する場合\n　担当者をマウスで選択し、会社へドラッグ＆ドロップします。\n・設定済みの担当者を解除する場合\n　設定済みの担当者の「解除」をクリックします。';
/** 会社別担当者設定/ボタン/機能/担当者検索ボタン */	
export const AIKCMN006000001_BUTTON_FUNCTION_SEARCH = '検索';
/** 会社別担当者設定/入力/ヘッダ/担当者一覧タイトルラベル */	
export const AIKCMN006000001_INPUT_HEADER_OFFICESTAFFLIST = '担当者一覧';
/** 会社別担当者設定/表/ヘッダ/ログインIDタイトルラベル */	
export const AIKCMN006000001_GRID_HEADER_LOGINID = 'ログインID';
/** 会社別担当者設定/表/ヘッダ/担当者名タイトルラベル */	
export const AIKCMN006000001_GRID_HEADER_OFFICESTAFFNAME = '担当者名';
/** 会社別担当者設定/表/ヘッダ/担当者名カナタイトルラベル */	
export const AIKCMN006000001_GRID_HEADER_OFFICESTAFFKANA = '担当者名カナ';
/** 会社別担当者設定/入力/ヘッダ/会社一覧タイトルラベル */	
export const AIKCMN006000001_INPUT_HEADER_COMPANYLIST = '会社一覧';
/** 会社別担当者設定/表/ヘッダ/会社コードタイトルラベル */	
export const AIKCMN006000001_GRID_HEADER_COMPANYCODE = '会社コード';
/** 会社別担当者設定/表/ヘッダ/会社名タイトルラベル */	
export const AIKCMN006000001_GRID_HEADER_COMPANYNAME = '会社名';
/** 会社別担当者設定/ボタン/機能/解除ボタン列 */	
export const AIKCMN006000001_BUTTON_FUNCTION_RELEASE = '解除';
/** 会社別担当者設定/入力/フッタ/操作方法説明ラベル */	
export const AIKCMN006000001_INPUT_FOOTER_GRIDGUIDANCE = '※「Ctrl」キーを押しながらクリックで複数選択が可能です。\n※「Shift」キーを押しながらクリックで範囲選択が可能です。';
/** 会社別担当者設定/入力/フッタ/更新説明ラベル */	
export const AIKCMN006000001_INPUT_FOOTER_SAVEGUIDANCE = '※設定を反映するには保存ボタンをクリックしてください。';
/** 会社別担当者設定/ボタン/機能/保存ボタン */	
export const AIKCMN006000001_BUTTON_FUNCTION_SAVE = '保存';
/** 会社別担当者設定/ボタン/機能/取消ボタン */	
export const AIKCMN006000001_BUTTON_FUNCTION_CANCEL = '取消';
/** 会社別担当者設定/表/担当者検索結果0件メッセージ */	
export const AIKCMN006000001_GRID_OFFICESTAFFNOTFOUND = '担当者が見つかりません。検索情報を変更して再検索をお願いします。';
/** 会社別担当者設定/表/会社絞込み結果0件メッセージ */	
export const AIKCMN006000001_GRID_COMPANYNOTFOUND = '会社が見つかりません。絞り込み条件の変更をお願いします。';
/** 会社別担当者設定/ボタン/機能/保存ボタン(更新確認ダイアログ) */	
export const AIKCMN006000001_BUTTON_FUNCTION_UPDATECONFIRM = '保存';
/** 会社別担当者設定/ボタン/機能/キャンセルボタン(更新確認ダイアログ) */	
export const AIKCMN006000001_BUTTON_FUNCTION_UPDATECANCEL = 'キャンセル';
/** 会社別担当者設定/ボタン/機能/閉じるボタン(更新完了ダイアログ) */	
export const AIKCMN006000001_BUTTON_FUNCTION_COMPLETECONFIRM = '閉じる';
/** 会社別担当者設定/ボタン/機能/はいボタン(変更確認ダイアログ) */	
export const AIKCMN006000001_BUTTON_FUNCTION_CANCELCONFIRM = 'はい';
/** 会社別担当者設定/ボタン/機能/いいえボタン(変更確認ダイアログ) */	
export const AIKCMN006000001_BUTTON_FUNCTION_DONOTCANCEL = 'いいえ';

/*
 * 契約情報
 */
/** 契約情報/機能/名称 */	
export const AIKCMN008000001_FUNCTION_NAME = '契約情報';
/** 契約情報/ボタン/ヘッダ/閉じる */	
export const AIKCMN008000001_BUTTON_HEADER_CLOSE = '閉じる';
/** 契約情報/ラベル/契約NO */	
export const AIKCMN008000001_LABEL_KEIYAKUNO = '契約NO';
/** 契約情報/ラベル/契約日 */	
export const AIKCMN008000001_LABEL_KEIYAKUBI = '契約日';
/** 契約情報/ラベル/利用開始日 */	
export const AIKCMN008000001_LABEL_RIYOUKAISIBI = '利用開始日';
/** 契約情報/ラベル/採用情報 */	
export const AIKCMN008000001_LABEL_SAIYOJYOHO = '採用情報';
/** 契約情報/ラベル/AI監査業務専用URL */	
export const AIKCMN008000001_LABEL_AIURL = 'AI監査業務専用URL';

/*
 * ログイン
 */
/** ログイン/機能/名称 */	
export const AIKCMN002000004_FUNCTION_NAME = 'ログイン';
/** ログイン/ラベル/ログイン種別説明 */	
export const AIKCMN002000004_LABEL_LOGININFO1 = 'bizskyアカウントでログイン';
/** ログイン/ラベル/ログインID */	
export const AIKCMN002000004_LABEL_LOGINID = 'ログインID';
/** ログイン/ラベル/パスワード */	
export const AIKCMN002000004_LABEL_LOGINPASS = 'パスワード';
/** ログイン/機能/パスワード忘れ用リンク */	
export const AIKCMN002000004_FUNCTION_FORGOTPASSLINK = 'パスワードをお忘れの方はこちらへ';
/** ログイン/ボタン/ログイン */	
export const AIKCMN002000004_BUTTON_LOGIN = 'ログイン';
/** ログイン/機能/管理者ログインに遷移 */	
export const AIKCMN002000004_FUNCTION_KANRISYALINK = 'bizskyIDを持つ管理者はこちら';
/** ログイン/機能/障害・メンテナンス情報 */	
export const AIKCMN002000004_FUNCTION_MAINTENANCE = '障害・メンテナンス情報';
/** ログイン/機能/運営会社 */	
export const AIKCMN002000004_FUNCTION_OPERATINGCOMPANY = '運営会社';
/** ログイン/機能/利用規約 */	
export const AIKCMN002000004_FUNCTION_TERMSOFSERVICE = '利用規約';
/** ログイン/機能/情報セキュリティ基本方針・個人情報保護方針 */	
export const AIKCMN002000004_FUNCTION_INFORMATIONSECURITY = '情報セキュリティ基本方針・個人情報保護方針';
/** ログイン/機能/会社数上限超過 */	
export const AIKCMN002000004_FUNCTION_CONTRACTOVER = '会社数上限超過';

/*
 * 会社データ選択
 */
/** 会社データ選択/機能/名称 */	
export const AIKCMN00200005_FUNCTION_NAME = '会社データ選択';
/** 会社データ選択/ボタン/閉じる */	
export const AIKCMN00200005_BUTTON_CLOSE = '閉じる';
/** 会社データ選択/ラベル/選択説明 */	
export const AIKCMN00200005_LABEL_SELECTINFO = 'ご利用する会社を選択してください。';
/** 会社データ選択/ボタン/選択する */	
export const AIKCMN00200005_BUTTON_DATASELECT = '選択する';

/*
 * エントランスページ
 */
/** エントランスページ/機能/名称 */	
export const AIKCMN00200001_FUNCTION_NAME = 'エントランスページ';
/** エントランスページ/ボタン/障害・メンテナンス情報 */	
export const AIKCMN00200001_BUTTON_MAINTENANCE = '障害・メンテナンス情報';
/** エントランスページ/ボタン/お知らせ */	
export const AIKCMN00200001_BUTTON_INFORMATION = 'お知らせ';
/** エントランスページ/ボタン/ログイン */	
export const AIKCMN00200001_BUTTON_LOGIN = 'ログイン';
/** エントランスページ/機能/プロダクトキー通知書が届いたらこちらへ */	
export const AIKCMN00200001_FUNCTION_PRODUCTKEY = 'プロダクトキー通知書が届いたらこちらへ';
/** エントランスページ/ボタン/利用を開始する */	
export const AIKCMN00200001_BUTTON_RIYOSTART = '利用を開始する';
/** エントランスページ/ラベル/ご利用までの手順 */	
export const AIKCMN00200001_LABEL_TEJYUN = 'ご利用までの手順';
/** エントランスページ/ラベル/STEP */	
export const AIKCMN00200001_LABEL_STEP01LABEL = 'STEP';
/** エントランスページ/ラベル/01 */	
export const AIKCMN00200001_LABEL_STEP01NO = '01';
/** エントランスページ/ラベル/bizsky IDの登録 */	
export const AIKCMN00200001_LABEL_STEP01TITLE = 'bizsky IDの登録';
/** エントランスページ/ラベル/AI監査のサービスを利用するアカウントを登録します。 */	
export const AIKCMN00200001_LABEL_STEP01EXPLANATION = 'AI監査のサービスを利用するアカウントを登録します。';
/** エントランスページ/ラベル/STEP */	
export const AIKCMN00200001_LABEL_STEP02LABEL = 'STEP';
/** エントランスページ/ラベル/02 */	
export const AIKCMN00200001_LABEL_STEP02NO = '02';
/** エントランスページ/ラベル/ライセンスの登録 */	
export const AIKCMN00200001_LABEL_STEP02TITLE = 'ライセンスの登録';
/** エントランスページ/ラベル/お手元のプロダクトキー通知書に記載されている25桁の英数字を入力します。 */	
export const AIKCMN00200001_LABEL_STEP02EXPLANATION = 'お手元のプロダクトキー通知書に記載されている25桁の英数字を入力します。';
/** エントランスページ/ラベル/STEP */	
export const AIKCMN00200001_LABEL_STEP03ABEL = 'STEP';
/** エントランスページ/ラベル/03 */	
export const AIKCMN00200001_LABEL_STEP03NO = '03';
/** エントランスページ/ラベル/マスタの登録 */	
export const AIKCMN00200001_LABEL_STEP03TITLE = 'マスタの登録';
/** エントランスページ/ラベル/AI監査で使用するマスタ情報をご利用中のシステムから出力したマスタ情報から取り込みます。 */	
export const AIKCMN00200001_LABEL_STEP03EXPLANATION = 'AI監査で使用するマスタ情報をご利用中のシステムから出力したマスタ情報から取り込みます。';
/** エントランスページ/機能/bizskyIDとは? */	
export const AIKCMN00200001_FUNCTION_BIZSKYIDHELP = 'bizskyIDとは?';
/** エントランスページ/ラベル/©2020 MIROKU JYOHO SERVICE Co.Ltd */	
export const AIKCMN00200001_LABEL_COPYRIGHT = '©2020 MIROKU JYOHO SERVICE Co.Ltd';

/*
 * ユーザー情報の確認・変更
 */
/** ユーザー情報の確認・変更/機能/名称 */	
export const AIKCMN00900001_FUNCTION_NAME = 'ユーザー情報の確認・変更';
/** ユーザー情報の確認・変更/ラベル/bizskyID */	
export const AIKCMN00900001_LABEL_BIZSKYID = 'bizskyID';
/** ユーザー情報の確認・変更/ラベル/名前 */	
export const AIKCMN00900001_LABEL_NAME = '名前';
/** ユーザー情報の確認・変更/ラベル/姓 */	
export const AIKCMN00900001_LABEL_SEIINP = '姓';
/** ユーザー情報の確認・変更/ラベル/名 */	
export const AIKCMN00900001_LABEL_MEIINP = '名';
/** ユーザー情報の確認・変更/ラベル/名前（カナ） */	
export const AIKCMN00900001_LABEL_NAMEKANA = '名前（カナ）';
/** ユーザー情報の確認・変更/ラベル/セイ */	
export const AIKCMN00900001_LABEL_SEIKANAINP = 'セイ';
/** ユーザー情報の確認・変更/ラベル/メイ */	
export const AIKCMN00900001_LABEL_MEIKANAINP = 'メイ';
/** ユーザー情報の確認・変更/ラベル/メールアドレス */	
export const AIKCMN00900001_LABEL_MAIL = 'メールアドレス';
/** ユーザー情報の確認・変更/ラベル/メールアドレス */	
export const AIKCMN00900001_LABEL_MAILINP = 'メールアドレス';
/** ユーザー情報の確認・変更/ラベル/メールアドレス（確認用） */	
export const AIKCMN00900001_LABEL_MAILKAKUNIN = 'メールアドレス（確認用）';
/** ユーザー情報の確認・変更/ラベル/メールアドレス（確認用） */	
export const AIKCMN00900001_LABEL_MAILKAKUNININP = 'メールアドレス（確認用）';
/** ユーザー情報の確認・変更/ボタン/保存 */	
export const AIKCMN00900001_BUTTON_SAVE = '保存';

/*
 * 会社基本情報
 */
/** 会社基本情報/機能/名称 */	
export const AIKADT007000001_FUNCTION_NAME = '会社基本情報';
/** 会社基本情報/入力/会社コード */	
export const AIKADT007000001_INPUT_ComCode = '会社コード';
/** 会社基本情報/入力/会社名 */	
export const AIKADT007000001_INPUT_LComName = '会社名';
/** 会社基本情報/入力/フリガナ */	
export const AIKADT007000001_INPUT_KanaComName = 'フリガナ';
/** 会社基本情報/入力/簡略会社名 */	
export const AIKADT007000001_INPUT_SComName = '簡略会社名';
/** 会社基本情報/ボタン/会社名からコピー */	
export const AIKADT007000001_BUTTON_COPY = '会社名からコピー';
/** 会社基本情報/入力/会計期間 */	
export const AIKADT007000001_INPUT_KStDate = '会計期間';
/** 会社基本情報/入力/範囲 */	
export const AIKADT007000001_INPUT_Hani = '～';
/** 会社基本情報/入力/月次開始日 */	
export const AIKADT007000001_INPUT_MStDay = '月次開始日';
/** 会社基本情報/入力/日 */	
export const AIKADT007000001_INPUT_Day = '日';
/** 会社基本情報/入力/中間決算区分 */	
export const AIKADT007000001_INPUT_MiddleKbn = '中間決算区分';
/** 会社基本情報/入力/中間決算区分の集計方法 */	
export const AIKADT007000001_INPUT_MCalcKbn = '中間決算区分の集計方法';
/** 会社基本情報/入力/科目体系 */	
export const AIKADT007000001_INPUT_CorpKbn = '科目体系';

/*
 * 勘定科目登録
 */
/** 勘定科目登録/機能/名称 */	
export const AIKADT008000001_FUNCTION_NAME = '勘定科目登録';
/** 勘定科目登録/入力/科目分類 */	
export const AIKADT008000001_INPUT_KmkClass = '科目分類';
/** 勘定科目登録/表/ヘッダ/科目コード */	
export const AIKADT008000001_GRID_HEADER_Gcode = 'コード';
/** 勘定科目登録/表/ヘッダ/正式名称 */	
export const AIKADT008000001_GRID_HEADER_LongName = '正式名称';
/** 勘定科目登録/表/ヘッダ/簡略名称 */	
export const AIKADT008000001_GRID_HEADER_SimpleName = '簡略名称';
/** 勘定科目登録/表/ヘッダ/連想 */	
export const AIKADT008000001_GRID_HEADER_RenChar = '連想';
/** 勘定科目登録/表/ヘッダ/正残 */	
export const AIKADT008000001_GRID_HEADER_DCKbn = '正残';
/** 勘定科目登録/表/ヘッダ/ＢＳ／ＰＬ */	
export const AIKADT008000001_GRID_HEADER_BPKbn = 'ＢＳ／ＰＬ';
/** 勘定科目登録/表/ヘッダ/期首残 */	
export const AIKADT008000001_GRID_HEADER_KisyuKbn = '期首残';
/** 勘定科目登録/表/ヘッダ/科目区分 */	
export const AIKADT008000001_GRID_HEADER_TaxKbn = '科目区分';
/** 勘定科目登録/表/ヘッダ/消費税コード */	
export const AIKADT008000001_GRID_HEADER_TaxCode = '消費税コード';
/** 勘定科目登録/表/ヘッダ/消費税率 */	
export const AIKADT008000001_GRID_HEADER_TaxRate = '消費税率';
/** 勘定科目登録/表/ヘッダ/業種コード */	
export const AIKADT008000001_GRID_HEADER_TypeCode = '業種コード';

/*
 * 仕訳・実績データ取込
 */
/** 仕訳・実績データ取込/入力/機能正式名 */	
export const AIKADT006000001_INPUT_NAME = '仕訳・実績データ取込み';
/** 仕訳・実績データ取込/入力/ファイル取込ステップタイトル */	
export const AIKADT006000001_INPUT_ImportFileiTitle = '';
/** 仕訳・実績データ取込/入力/取込ファイルの説明 */	
export const AIKADT006000001_INPUT_ImportFileDesc = '';
/** 仕訳・実績データ取込/入力/取込ファイルのエクスポート説明 */	
export const AIKADT006000001_INPUT_ImportFileExDesc = '';
/** 仕訳・実績データ取込/入力/取込ファイルの設定 */	
export const AIKADT006000001_INPUT_ImportFileConf = '';
/** 仕訳・実績データ取込/入力/取込ファイルの設定説明 */	
export const AIKADT006000001_INPUT_ImportFileConfDesc = '';
/** 仕訳・実績データ取込/入力/取込ファイルの設定テーブル */	
export const AIKADT006000001_INPUT_ImportFileConfTable = '';
/** 仕訳・実績データ取込/入力/取込対象 */	
export const AIKADT006000001_INPUT_ImportTarget = '';
/** 仕訳・実績データ取込/ボタン/ファイルインポート */	
export const AIKADT006000001_BUTTON_Import = '';
/** 仕訳・実績データ取込/入力/項目設定ステップタイトル */	
export const AIKADT006000001_INPUT_ItemConfTitle = '';
/** 仕訳・実績データ取込/入力/項目設定設定説明 */	
export const AIKADT006000001_INPUT_ItemConfDesc = '';
/** 仕訳・実績データ取込/入力/項目設定 */	
export const AIKADT006000001_INPUT_ItemConf = '';
/** 仕訳・実績データ取込/入力/インポート開始行 */	
export const AIKADT006000001_INPUT_ItemImpStartRow = '';
/** 仕訳・実績データ取込/入力/期首残高行目 */	
export const AIKADT006000001_INPUT_ItemKBlStartRow = '';
/** 仕訳・実績データ取込/入力/補助期首残高行目 */	
export const AIKADT006000001_INPUT_ItemHBlStartRow = '';
/** 仕訳・実績データ取込/入力/仕訳行目 */	
export const AIKADT006000001_INPUT_ItemSwkStartRow = '';
/** 仕訳・実績データ取込/入力/科目実績行目 */	
export const AIKADT006000001_INPUT_ItemKSmStartRow = '';
/** 仕訳・実績データ取込/入力/補助実績行目 */	
export const AIKADT006000001_INPUT_ItemHSmStartRow = '';
/** 仕訳・実績データ取込/入力/項目設定エラー */	
export const AIKADT006000001_INPUT_ItemConfErr = '';
/** 仕訳・実績データ取込/入力/項目設定エラーログ */	
export const AIKADT006000001_INPUT_ItemConfErrLog = '';
/** 仕訳・実績データ取込/ボタン/設定保存 */	
export const AIKADT006000001_BUTTON_ItemContSave = '';
/** 仕訳・実績データ取込/入力/項目変換 */	
export const AIKADT006000001_INPUT_ItemChg = '';
/** 仕訳・実績データ取込/入力/インポート項目 */	
export const AIKADT006000001_INPUT_ItemImpBef = '';
/** 仕訳・実績データ取込/入力/変換後項目 */	
export const AIKADT006000001_INPUT_ItemImpAft = '';
/** 仕訳・実績データ取込/入力/矢印 */	
export const AIKADT006000001_INPUT_ItemArrow = '';
/** 仕訳・実績データ取込/ボタン/設定保存 */	
export const AIKADT006000001_BUTTON_ItemChgSave = '';
/** 仕訳・実績データ取込/入力/科目設定ステップタイトル */	
export const AIKADT006000001_INPUT_KBlanceConfTitle = '';
/** 仕訳・実績データ取込/入力/科目設定ステップ説明 */	
export const AIKADT006000001_INPUT_KBlanceConfDesc = '';
/** 仕訳・実績データ取込/入力/新規勘定科目の登録 */	
export const AIKADT006000001_INPUT_KBlanceAccount = '';
/** 仕訳・実績データ取込/入力/新規勘定科目の登録説明 */	
export const AIKADT006000001_INPUT_KBlanceAccDesc = '';
/** 仕訳・実績データ取込/入力/勘定科目の対応付け */	
export const AIKADT006000001_INPUT_KBlanceCorres = '';
/** 仕訳・実績データ取込/入力/勘定科目の対応付け説明 */	
export const AIKADT006000001_INPUT_KBlanceCorDesc = '';
/** 仕訳・実績データ取込/表/ヘッダ/ヘッダ：インポートされた勘定科目 */	
export const AIKADT006000001_GRID_HEADER_KBlHeadImpAcc = '';
/** 仕訳・実績データ取込/表/ヘッダ/ヘッダ：矢印マーク */	
export const AIKADT006000001_GRID_HEADER_KBlHeadArr = '';
/** 仕訳・実績データ取込/表/ヘッダ/ヘッダ：AI監査支援に登録する勘定科目 */	
export const AIKADT006000001_GRID_HEADER_KBlHeadDesAcc = '';
/** 仕訳・実績データ取込/表/ヘッダ/ヘッダ：期首残高 */	
export const AIKADT006000001_GRID_HEADER_KBlHeadZan = '';
/** 仕訳・実績データ取込/表/ヘッダ/ヘッダ：借方期首残高 */	
export const AIKADT006000001_GRID_HEADER_KBlHeadDZan = '';
/** 仕訳・実績データ取込/表/ヘッダ/ヘッダ：貸方期首残高 */	
export const AIKADT006000001_GRID_HEADER_KBlHeadCZan = '';
/** 仕訳・実績データ取込/ボタン/設定保存 */	
export const AIKADT006000001_BUTTON_KBISave = '';
/** 仕訳・実績データ取込/ボタン/残高インポート */	
export const AIKADT006000001_BUTTON_KBIImport = '';
/** 仕訳・実績データ取込/入力/補助設定ステップタイトル */	
export const AIKADT006000001_INPUT_HBlanceTitle = '';
/** 仕訳・実績データ取込/入力/補助設定ステップ説明 */	
export const AIKADT006000001_INPUT_HBlanceDesc = '';
/** 仕訳・実績データ取込/入力/補助科目の対応付け */	
export const AIKADT006000001_INPUT_HblanceCorres = '';
/** 仕訳・実績データ取込/表/ヘッダ/ヘッダ：インポートされた補助科目 */	
export const AIKADT006000001_GRID_HEADER_HblHeadImpHAcc = '';
/** 仕訳・実績データ取込/表/ヘッダ/ヘッダ：矢印マーク */	
export const AIKADT006000001_GRID_HEADER_HblHeadArros = '';
/** 仕訳・実績データ取込/表/ヘッダ/ヘッダ：AI監査支援での登録方法 */	
export const AIKADT006000001_GRID_HEADER_HblHeadDesAcc = '';
/** 仕訳・実績データ取込/表/ヘッダ/ヘッダ：期首残高 */	
export const AIKADT006000001_GRID_HEADER_HblHeadZan = '';
/** 仕訳・実績データ取込/表/ヘッダ/ヘッダ：借方期首残高 */	
export const AIKADT006000001_GRID_HEADER_HblHeadDZan = '';
/** 仕訳・実績データ取込/表/ヘッダ/ヘッダ：貸方期首残高 */	
export const AIKADT006000001_GRID_HEADER_HblHeadCZan = '';
/** 仕訳・実績データ取込/ボタン/設定保存 */	
export const AIKADT006000001_BUTTON_HBISave = '';
/** 仕訳・実績データ取込/ボタン/残高インポート */	
export const AIKADT006000001_BUTTON_HBIImport = '';
/** 仕訳・実績データ取込/入力/仕訳インポートステップタイトル */	
export const AIKADT006000001_INPUT_SwkImpTitle = '';
/** 仕訳・実績データ取込/入力/仕訳インポートステップ説明 */	
export const AIKADT006000001_INPUT_SwkImpDesc = '';
/** 仕訳・実績データ取込/入力/代替科目の設定 */	
export const AIKADT006000001_INPUT_SwkImpUnkAcc = '';
/** 仕訳・実績データ取込/入力/取込月の選択 */	
export const AIKADT006000001_INPUT_SwkImpSelMonth = '';
/** 仕訳・実績データ取込/表/ヘッダ/ヘッダ：仕訳の登録月 */	
export const AIKADT006000001_GRID_HEADER_SwkImpHeadMonth = '';
/** 仕訳・実績データ取込/表/ヘッダ/ヘッダ：仕訳件数 */	
export const AIKADT006000001_GRID_HEADER_SwkImpHeadSwkCount = '';
/** 仕訳・実績データ取込/表/ヘッダ/ヘッダ：エラー件数 */	
export const AIKADT006000001_GRID_HEADER_SwkImpHeadErrCount = '';
/** 仕訳・実績データ取込/表/フッタ/フッタ：合計 */	
export const AIKADT006000001_GRID_FOOTER_SwkImpFoodSumCount = '';
/** 仕訳・実績データ取込/表/フッタ/フッタ：仕訳件数 */	
export const AIKADT006000001_GRID_FOOTER_SwkImpFoodSekCount = '';
/** 仕訳・実績データ取込/表/フッタ/フッタ：エラー件数 */	
export const AIKADT006000001_GRID_FOOTER_SwkImpFoodErrCount = '';
/** 仕訳・実績データ取込/入力/仕訳インポートエラーログ */	
export const AIKADT006000001_INPUT_SwkImpErrLog = '';
/** 仕訳・実績データ取込/入力/行数 */	
export const AIKADT006000001_INPUT_SwkImpRow = '';
/** 仕訳・実績データ取込/ボタン/仕訳インポート */	
export const AIKADT006000001_BUTTON_SwkImportButton = '';
/** 仕訳・実績データ取込/ボタン/エラーログボタン */	
export const AIKADT006000001_BUTTON_SwkImportLogButton = '';
/** 仕訳・実績データ取込/入力/科目設定ステップタイトル */	
export const AIKADT006000001_INPUT_KSumConfTitle = '';
/** 仕訳・実績データ取込/入力/科目設定ステップ説明 */	
export const AIKADT006000001_INPUT_KSumConfDesc = '';
/** 仕訳・実績データ取込/入力/新規勘定科目の登録 */	
export const AIKADT006000001_INPUT_KSumAccount = '';
/** 仕訳・実績データ取込/入力/新規勘定科目の登録説明 */	
export const AIKADT006000001_INPUT_KSumAccDesc = '';
/** 仕訳・実績データ取込/入力/勘定科目の対応付け */	
export const AIKADT006000001_INPUT_KSumCorres = '';
/** 仕訳・実績データ取込/入力/勘定科目の対応付け説明 */	
export const AIKADT006000001_INPUT_KSumCorDesc = '';
/** 仕訳・実績データ取込/表/ヘッダ/ヘッダ：インポートされた勘定科目 */	
export const AIKADT006000001_GRID_HEADER_KSumHeadImpAcc = '';
/** 仕訳・実績データ取込/表/ヘッダ/ヘッダ：矢印マーク */	
export const AIKADT006000001_GRID_HEADER_KSumHeadArr = '';
/** 仕訳・実績データ取込/表/ヘッダ/ヘッダ：AI監査支援に登録する勘定科目 */	
export const AIKADT006000001_GRID_HEADER_KSumHeadDesAcc = '';
/** 仕訳・実績データ取込/表/ヘッダ/ヘッダ：期首残高 */	
export const AIKADT006000001_GRID_HEADER_KSumHeadZan = '';
/** 仕訳・実績データ取込/表/ヘッダ/ヘッダ：借方実績 */	
export const AIKADT006000001_GRID_HEADER_KSumHeadDZan = '';
/** 仕訳・実績データ取込/表/ヘッダ/ヘッダ：貸方実績 */	
export const AIKADT006000001_GRID_HEADER_KSumHeadCZan = '';
/** 仕訳・実績データ取込/表/ヘッダ/ヘッダ：年月 */	
export const AIKADT006000001_GRID_HEADER_KSumHeadYM = '';
/** 仕訳・実績データ取込/ボタン/設定保存 */	
export const AIKADT006000001_BUTTON_KSumSave = '';
/** 仕訳・実績データ取込/ボタン/残高インポート */	
export const AIKADT006000001_BUTTON_KSumImport = '';
/** 仕訳・実績データ取込/入力/補助設定ステップタイトル */	
export const AIKADT006000001_INPUT_HSumTitle = '';
/** 仕訳・実績データ取込/入力/補助設定ステップ説明 */	
export const AIKADT006000001_INPUT_HSumDesc = '';
/** 仕訳・実績データ取込/入力/補助科目の対応付け */	
export const AIKADT006000001_INPUT_HSumCorres = '';
/** 仕訳・実績データ取込/表/ヘッダ/ヘッダ：借方実績 */	
export const AIKADT006000001_GRID_HEADER_HSumHeadDZan = '';
/** 仕訳・実績データ取込/表/ヘッダ/ヘッダ：貸方実績 */	
export const AIKADT006000001_GRID_HEADER_HSumHeadCZan = '';
/** 仕訳・実績データ取込/表/ヘッダ/ヘッダ：年月 */	
export const AIKADT006000001_GRID_HEADER_HSumHeadYM = '';
/** 仕訳・実績データ取込/ボタン/設定保存 */	
export const AIKADT006000001_BUTTON_HSumSave = '';
/** 仕訳・実績データ取込/ボタン/残高インポート */	
export const AIKADT006000001_BUTTON_HSumImport = '';
/** 仕訳・実績データ取込/入力/完了ステップタイトル */	
export const AIKADT006000001_INPUT_DoneTitle = '';
/** 仕訳・実績データ取込/入力/完了ステップ説明 */	
export const AIKADT006000001_INPUT_DoneDesc = '';

/*
 * 会社データ作成
 */
/** 会社データ作成/機能/名称 */	
export const AIKCMN003000001_FUNCTION_NAME = '会社データ作成';
/** 会社データ作成/入力/会社コード */	
export const AIKCMN003000001_INPUT_ComCode = '会社コード';
/** 会社データ作成/入力/会社名 */	
export const AIKCMN003000001_INPUT_LComName = '会社名';
/** 会社データ作成/入力/フリガナ */	
export const AIKCMN003000001_INPUT_KanaComName = 'フリガナ';
/** 会社データ作成/入力/簡略会社名 */	
export const AIKCMN003000001_INPUT_SComName = '簡略会社名';
/** 会社データ作成/ボタン/会社名からコピー */	
export const AIKCMN003000001_BUTTON_COPY = '会社名からコピー';
/** 会社データ作成/入力/連想 */	
export const AIKCMN003000001_INPUT_RenChar = '連想';
/** 会社データ作成/入力/会計期間 */	
export const AIKCMN003000001_INPUT_KStDate = '会計期間';
/** 会社データ作成/入力/範囲 */	
export const AIKCMN003000001_INPUT_Hani = '～';
/** 会社データ作成/入力/月次開始日 */	
export const AIKCMN003000001_INPUT_MStDay = '月次開始日';
/** 会社データ作成/入力/日 */	
export const AIKCMN003000001_INPUT_Day = '日';
/** 会社データ作成/入力/科目体系 */	
export const AIKCMN003000001_INPUT_CorpKbn = '科目体系';
/** 会社データ作成/入力/中間決算区分 */	
export const AIKCMN003000001_INPUT_MiddleKbn = '中間決算区分';
/** 会社データ作成/入力/消費税区分 */	
export const AIKCMN003000001_INPUT_KaniKbn = '消費税区分';
/** 会社データ作成/入力/会計処理区分 */	
export const AIKCMN003000001_INPUT_AccProKbn = '会計処理区分';
/** 会社データ作成/入力/輸入取引区分 */	
export const AIKCMN003000001_INPUT_ImportKbn = '輸入取引区分';
/** 会社データ作成/入力/過年度データからマスタをコピーして作成する */	
export const AIKCMN003000001_INPUT_YearCopyKbn = '過年度データからマスタをコピーして作成する';

/*
 * 会社データ削除
 */
/** 会社データ削除/機能/名称 */	
export const AIKCMN004000001_FUNCTION_NAME = '会社データ削除';
/** 会社データ削除/入力/会社コード */	
export const AIKCMN004000001_INPUT_ComCode = '会社コード';
/** 会社データ削除/入力/会社名 */	
export const AIKCMN004000001_INPUT_LComName = '会社名';
/** 会社データ削除/入力/会計期間 */	
export const AIKCMN004000001_INPUT_KStDate = '会計期間';
/** 会社データ削除/入力/削除 */	
export const AIKCMN004000001_INPUT_DELETE = '削除';
/** 会社データ削除/入力/範囲 */	
export const AIKCMN004000001_INPUT_Hani = ' ～ ';

/*
 * パスワード変更
 */
/** パスワード変更/機能/名称 */	
export const AIKCMN001000001_FUNCTION_NAME = 'パスワード変更';
/** パスワード変更/ラベル/現在のパスワード */	
export const AIKCMN001000001_LABEL_NOWPASSWORD = '現在のパスワード';
/** パスワード変更/ラベル/新パスワード */	
export const AIKCMN001000001_LABEL_NEWPASSWORD = '新パスワード';
/** パスワード変更/ラベル/パスワード（確認用） */	
export const AIKCMN001000001_LABEL_PASSWORDKAKUNINERR = 'パスワード（確認用）';
/** パスワード変更/ラベル/パスワードを表示しながら入力する */	
export const AIKCMN001000001_LABEL_PASSWORDISP = 'パスワードを表示しながら入力する';
/** パスワード変更/ボタン/保存 */	
export const AIKCMN001000001_BUTTON_HOZON = '保存';
/** パスワード変更/ボタン/閉じる */	
export const AIKCMN001000001_BUTTON_CLOSE = '閉じる';

/*
 * 契約情報
 */
/** 契約情報/ラベル/採用可能会社数 */	
export const AIKCMN008000001_LABEL_SaiyouComp = '使用可能会社数：';
/** 契約情報/ラベル/作成済み会社数 */	
export const AIKCMN008000001_LABEL_MakeKaisyaCnt = '件（使用中　';
/** 契約情報/ラベル/契約会社数 */	
export const AIKCMN008000001_LABEL_LicenseCountPurchased = '件／最大 ';
/** 契約情報/ラベル/会社数 */	
export const AIKCMN008000001_LABEL_CompCnt = '件）';

/*
 * 監査対象制御
 */
/** 監査対象制御/ラベル/科目 */	
export const AIKCMN0010000013_LABEL_SUBJECTS = '科目';
/** 監査対象制御/ラベル/部門 */	
export const AIKCMN0010000013_LABEL_SECTION = '部門';
/** 監査対象制御/ラベル/範囲ラベル */	
export const AIKCMN0010000013_LABEL_SCOPE = '範囲';
/** 監査対象制御/ラベル/範囲 */	
export const AIKCMN0010000013_LABEL_Hani = '～';
/** 監査対象制御/ラベル/監査対象 */	
export const AIKCMN0010000013_LABEL_Target = '監査対象';

