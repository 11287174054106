import React from 'react';
import classnames from 'classnames';

const LowerActionLayout: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
  <div {...props} className={classnames(props.className, 'lower-action-layout')}>
    {props.children}
  </div>
);

const LowerActionLayoutHeader: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
  <div {...props} className={classnames(props.className, '-header')}>
    {props.children}
  </div>
);

const LowerActionLayoutContents: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
  <div {...props} className={classnames(props.className, '-content')}>
    {props.children}
  </div>
);

const LowerActionLayoutAction: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
  <div {...props} className={classnames(props.className, '-action')}>
    {props.children}
  </div>
);

export { LowerActionLayout, LowerActionLayoutHeader, LowerActionLayoutContents, LowerActionLayoutAction };
