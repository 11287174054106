/**
 * マスターデータ ViewModel クラス
 */
import { KeyValueData, RensouData } from '../../common/usefuls/utilities';
import { MasterSumKbn } from '../../core/constants/constant';
import { DataModelBase } from '../../core/models/dataModelBase';
export class HojyoMACacheViewModel extends DataModelBase implements KeyValueData, RensouData {
    // interface:KeyValueDataの実装
    public get key(): string { return this.code; }
    public get value(): string { return this.SimpleName; }

    // interface:RensouDataの実装
    public get code(): string { return this.GCode; }
    public get masterKbn(): number { return this.MasterKbn; }
    public get displayValue(): string { return this.SimpleName; }
    public get subNameValue(): string { return this.LongName; }
    public get rensou(): string { return this.RenChar; }
    public get simpleFlag(): boolean { return true; }
    public get detailFlag(): boolean { return true; }
    private _displayFlag!: boolean;
    public set displayFlag(val: boolean) { this._displayFlag = val; }
    public get displayFlag(): boolean { return this._displayFlag; }

    /*------------------------------*
        独自の関数群
    *------------------------------*/

    /**
     * 実在科目の判定
     */
    public get isRealKmk(): boolean {
        return this.SumKbn == MasterSumKbn.Real;
    }

    /**
     * 合計科目の判定
     */
    public get isTotalKmk(): boolean {
        return this.SumKbn == MasterSumKbn.Total;
    }

    /*------------------------------*
                項目群
        *------------------------------*/

    /** マスタ区分 */
    public MasterKbn!: number;

    /** 実在／合計区分 */
    public SumKbn!: number;

    /** レコード区分 */
    public RecordKbn!: number;

    /** 外部コード */
    public GCode!: string;

    /** 連想シンボル */
    public RenChar!: string;

    /** 正式名称 */
    public LongName!: string;

    /** 簡略名称 */
    public SimpleName!: string;

    /** 内部コード（ユニークKey） */
    public NCode!: number;

    /** 更新日時 */
    public UpdateTime!: Date;

    /** 特定部署コード/部門コード */
    public SectionCode!: string;

    /** 得意先区分/部門性格コード/担当者グループ属性 */
    public HojyoKbn1!: number;

    /** 仕入先区分/部門分類/未成振替区分/全社参照区分 */
    public HojyoKbn2!: number;

    /** 取引先分類/共通仕入部門区分/入力区分 */
    public BunCode!: number;

    /** 適用開始年月日 */
    public StartDate!: Date;

    /** 適用終了年月日 */
    public EndDate!: Date;

    /** Ｂ／Ｓ計上部門 */
    public BSBumon!: string;

    /** 個別原価率 */
    public CostRate!: BigJs.Big;

    /** 入力補助ｳｲﾝﾄﾞｳ非表示区分 */
    public BraCode!: number;

    /** 手形割引限度額/与信限度額 */
    public LimitSum!: number;

    /** 工事完成年月日 */
    public FinishDate!: Date;

    /** 翌期移送区分 */
    public MoveKbn!: number;

    /** 売上消費税コード */
    public STaxCode!: number;

    /** 売上消費税率 */
    public STaxRate!: number;

    /** 仕入消費税コード */
    public BTaxCode!: number;

    /** 仕入消費税率 */
    public BTaxRate!: number;

    /** （得意先）借方資金繰コード */
    public TDebitCash!: number;

    /** （得意先）貸方資金繰コード */
    public TCreditCash!: number;

    /** （仕入先）借方資金繰コード */
    public SDebitCash!: number;

    /** （仕入先）貸方資金繰コード */
    public SCreditCash!: number;

    /** 名寄せ区分 */
    public RootKbn!: number;

    /** 内部コード２ */
    public NCode2!: number;

    /** 分類コード１ */
    public BunruiCd1!: string;

    /** 分類コード２ */
    public BunruiCd2!: string;

    /** 分類コード３ */
    public BunruiCd3!: string;

    /** 分類コード４ */
    public BunruiCd4!: string;

    /** 分類コード５ */
    public BunruiCd5!: string;

}

