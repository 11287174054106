import { DataModelBase } from "../../core/models/dataModelBase";

/**
 * マスターデータ ViewModel クラス
 */
export class MasterInfoCacheViewModel extends DataModelBase {

    public ClientCode!: string;
    public ClientYear!: number;
    public MasterKbn!: number;
    public UpdateTime!: Date;
    public UseKbn!: number;
    public JHojyoName!: string;
    public GHojyoName!: string;
    public PrevYearKbn!: number;
    public CodeDigit!: number;
    public CodeAttr!: number;
    public MstrKbn1!: number;
    public MstrKbn2!: number;
    public MstrKbn3!: number;
    public MstrKbn4!: number;
    public MstrKbn5!: number;
    public MstrKbn6!: number;
    public MstrKbn7!: number;
    public MstrKbn8!: number;
    public MstrKbn9!: number;
    public MstrKbn10!: number;
    public MBudgetKbn!: number;
    public BudgetTani1!: number;
    public BudgetTani2!: number;
    public BudgetTani3!: number;
    public BudgetTani4!: number;
    public KBudgetTani!: number;
    public KmkCode1!: number;
    public KmkCode2!: number;
    public KmkCode3!: number;
    public KmkCode4!: number;
    public KmkCode5!: number;
    public KmkCode6!: number;
    public KmkCode7!: number;
    public KmkCode8!: number;
    public KmkCode9!: number;
    public KmkCode10!: number;
    public SpHojyoCd1!: string;
    public SphojyoCd2!: string;
    public BasePer!: number;
    public KojKbn1!: number;
    public KojKbn2!: number;
    public KojKbn3!: number;
    public KojKbn4!: number;
    public KojKbn5!: number;
    public KojKbn6!: number;
    public KojKbn7!: number;
    public KojKbn8!: number;
    public KojKbn9!: number;
    public KojKbn10!: number;
    public KojKbn11!: number;
    public KojKbn12!: number;
    public KojKbn13!: number;
    public KojKbn14!: number;
    public KojKbn15!: number;
    public KojKbn16!: string;
    public PrjUseKbn!: number;
    public KmkCode11!: number;
    public KmkCode12!: number;
    public KmkCode13!: number;
    public KmkCode14!: number;
    public KmkCode15!: number;
    public KmkCode16!: number;
    public MstrKbn11!: number;
    public MstrKbn12!: number;
    public MstrKbn13!: number;
    public MstrKbn14!: number;
    public MstrKbn15!: number;
    public MstrKbn16!: number;
    public MstrKbn17!: number;
    public MstrKbn18!: number;
    public MstrKbn19!: number;
    public MstrKbn20!: number;
}
