import React from 'react';
import Button from 'reactstrap/lib/Button';
import { compareObject } from '../../../utils/objectUtils';

/** プロパティ */
export type ListSelectButtonProps = {
  // リスト
  target: Array<any>;
  // ボタンクリック関数
  onClick: (selectValue: any) => void;
  // 選択アイテム
  value?: any;
  // disabled
  disabled?: boolean;
  // tabIndex
  tabIndex?: number;
};

/**
 * リスト選択ボタン
 * @param props
 */
const ListSelectButton: React.FC<ListSelectButtonProps> = props => {
  const { value, target, onClick, disabled } = props;
  // 選択インデックス
  const [index, setIndex] = React.useState(0);
  // 左ボタンクリック
  var leftClick = () => {
    // 通知
    onClick(target[index - 1]);
    // 選択インデックスを１つ前にする
    setIndex(index - 1);
  }
  // 右ボタンクリック
  var rightClick = () => {
    // 通知
    onClick(target[index + 1]);
    // 選択インデックスを１つ後にする
    setIndex(index + 1);
  }
  // 初期処理
  React.useEffect(() => {
    var cnt = -1;
    // 選択アイテムのインデックスを設定する
    if (value) {
      target.some(v => {
        cnt++;
        return compareObject(v, value);
      });
    }
    setIndex(cnt);
  }, []);
  // 選択アイテム変更時処理
  React.useEffect(() => {
    var cnt = -1;
    var flg = false;
    target.some(v => {
      cnt++;
      return flg = compareObject(v, value);
    });
    if (!flg) {
      cnt = -1;
    }
    setIndex(cnt);
  }, [value]);
  return (
    <div
      className="d-inline list-select-button"
    >
      <Button
        className='button-style'
        onClick={leftClick}
        disabled={disabled || index <= 0}
        tabIndex={props.tabIndex}
      >
        <span className='right'></span>
      </Button>
      <Button
        className='button-style space'
        onClick={rightClick}
        disabled={disabled || index == target.length - 1}
        tabIndex={props.tabIndex! + 1}
      >
        <span className='left'></span>
      </Button>
    </div>
  );
};
export default ListSelectButton;
