import React from 'react';
import Close from '../../../../../components/atoms/Icon/Close';
import IconButton from '../../../../../components/molecules/CMN/IconButton/IconButton';
const _ProcessMigrationAlertDialogPartial: React.FC<{}> = props => {
    return (
      <>
{/* (BUG 50549,50712）共通ダイアログクラスが修正されるまでの暫定対応 */}
<div className="bg-tertiary text-white d-flex align-items-center py-1 modal-header">
    <div className="dp_flex wp100">
        <div className="wp50">
            <h5 className="modal-title">環境設定</h5>
        </div>
        <div className="wp50">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{float: "right"}}>
                <IconButton className="text-white" icon={<Close className='icon-lg'/>} />
            </button>
        </div>
    </div>
</div>
<div className="modal-body">
    <p className="modal-alert-message"></p>
</div>
      </>
    )
};
export default _ProcessMigrationAlertDialogPartial;