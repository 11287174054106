import {BmnSepAuditInfoVO} from "./bmnSepAuditInfoVO";
import {MonthSelectVO} from "./monthSelectVO";
/**
 * 部門別監査状況確認ViewModel ViewModel クラス
 */

export class BmnSepAuditInfoViewModel {
    /** 部門別監査状況リストVO */
    public BmnSepAuditInfoList?:Array<BmnSepAuditInfoVO>;

    /** 月選択リストVO */
    public MonthSelectList?:Array<MonthSelectVO>;

}
