/**
 * 会社基本情報ViewModel ViewModel クラス
 */

export class FirmInfoViewModel {
    /** 会社コード */
    public ComCode!:number;

    /** 会社名 */
    public LComName?:string;

    /** フリガナ */
    public KanaComName?:string;

    /** 略式会社名 */
    public SComName?:string;

    /** 会計期間開始日 */
    public KStDate?:Date;

    /** 会計期間終了日 */
    public KEdDate?:Date;

    /** 月次開始日 */
    public MStDay?:number;

    /** 中間決算区分 */
    public MiddleKbn?:number;

    /** 中間決算区分の集計方法 */
    public MCalcKbn?:number;

    /** 科目体系 */
    public CorpKbn?:number;

    /** 科目体系名称 */
    public CorpKbnName?:string;

}
