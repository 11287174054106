import * as wjInput from 'wijmo/wijmo.input';
import * as wijmo from 'wijmo/wijmo';
import { InputBase } from './inputBase';
import { EventManager } from '../../../support/eventManager';
import { MjsInputtingStringEventArgs } from '../../../common/event/eventData';
import { Logger } from '../../../common/usefuls/logger';
import { MjsEventContainer } from '../../../common/usefuls/event';
import { MjsUtilities } from '../../../common/usefuls/utilities';
import { UserAgentUtil } from '../../../common/usefuls/useragentUtil';
import { CodeAttr } from '../../../core/constants/constant';
import { subItemUtil, hojoItemUtil, CodeRegexp } from '../../../common/Biz/masterUtil';
import { TextDisplayPosition, userControls } from '../abstractUserControl';
import { MjsEventArgs } from "../../../common/event/eventData";
import $ from 'jquery';
import "../../../common/usefuls/utilities";

export type IME_MODE = 'auto' | 'active' | 'inactive' | 'disabled';

/**
 * 文字入力コントロール
 */
export class StringInput extends InputBase {
    private static inputFilterRegexpXSS: RegExp = RegExp("[<>&\"'\x00-\x1f\x7f]+"); // <,>,&,",',制御文字(0x00～0x1f, 0x7f)は入力禁止
    public get checkInputFilterRegexpXSS(): RegExp { return StringInput.inputFilterRegexpXSS; }

    private static inputFilterRegexpXSSDell: RegExp = RegExp("^[^<>&\"'\x00-\x1f\x7f]*$"); // <,>,&,",',制御文字(0x00～0x1f, 0x7f)は入力禁止なので削除する
    public get deleteInputFilterRegexpXSS(): RegExp { return StringInput.inputFilterRegexpXSSDell; }

    private mInputWidth: number;

    private mMaxLength: number;
    public get maxLength(): number { return this.mMaxLength; }
    public set maxLength(value: number) { this.mMaxLength = value; }

    private mInputFilterRegexp!: RegExp;
    /** 入力文字種を制限するための正規表現を取得・設定する */
    public get inputFilterRegexp(): RegExp { return this.mInputFilterRegexp; }
    public set inputFilterRegexp(inputFilterRegexp: RegExp) { this.mInputFilterRegexp = inputFilterRegexp; }

    private mZeroFillMode!: boolean;
    /**
     * ゼロフィルモード（入力数値を自動0埋め補正するモード）の状態を取得・設定する
     * ・trueが設定された場合当該モードが有効となり、maxLengthで指定されている桁数となるように自動ゼロフィル補正が行われる
     * ・当該モードが有効な場合は必然的に数字しか入力できない制御となるため、外部からinputFilterRegexpによる入力文字制限を別途指定する必要はない
     */
    public get zeroFillMode(): boolean { return this.mZeroFillMode; }
    public set zeroFillMode(value: boolean) {
        this.mZeroFillMode = value;
        if (value) {
            this.inputFilterRegexp = /^[0-9]+$/;
        }
    }

    /**
     * 入力コントロール
     * （コンボボックス）
     */
    protected mInputControl!: wjInput.ComboBox;
    public get inputControl(): wjInput.ComboBox { return this.mInputControl; }

    // 独自イベントを設定するためのマネージャ
    protected mEventManager: EventManager;

    // 文字入力イベントのArgs
    protected stringEventArgs: MjsInputtingStringEventArgs;

    /**
     * コンストラクタ
     * @param baseTagId: コントロール配置対象要素のID
     * @param $scope
     * @param $compile
     * @param caption: 見出しラベルの表示文字列。Nullが指定された場合は見出し領域非表示。
     * @param maxLength: 入力可能な文字列長（s-jis換算したバイト数）
     * @param inputWidth: 入力領域の横幅
     * @param valueLabelWidth: 値ラベルの幅。Nullが指定された場合は領域非表示。
     */
    constructor(baseTagId: string, $scope: any, $compile: any, caption: string, maxLength?: number, inputWidth?: number, valueLabelWidth?: number);
    constructor(baseTagId: JQuery, $scope: any, $compile: any, caption: string, maxLength?: number, inputWidth?: number, valueLabelWidth?: number);
    constructor(baseTagId: any, $scope: any, $compile: any, caption: string, maxLength: number = null!, inputWidth: number = null!, valueLabelWidth: number = null!) {
        super(baseTagId, $scope, $compile, caption, valueLabelWidth);
        this.mMaxLength = maxLength;
        this.mInputWidth = inputWidth;

        // イベントマネージャインスタンスを作成
        this.mEventManager = new EventManager();
        this.stringEventArgs = new MjsInputtingStringEventArgs();
    }

    protected postInitialize(): void {
        this.mInputControl.textChanged.addHandler(this.textChangeHandler, this);
    }


    /**
     * 値を取得する
     */
    public getValue(): string {
        return this.mInputControl.text;
    }
    /**
     * 値を設定する
     * @param value
     */
    public setValue(value: string) {
        this.mInputControl.text = value;
    }

    /**
     * コントロールのEnable/Disableの制御を行う
     */
    public get enabled(): boolean {
        return !this.mInputControl.isDisabled;
    }
    public set enabled(flg: boolean) {
        super.setBaseTagEnable(flg);
        this.mInputControl.isDisabled = !flg;
    }

    /**
     * 入力コントロールにCssクラスを設定する
     * @param cssClass
     * 右寄せ時はal_right,左寄せ時はal_left,真ん中はal_centerを指定
     */
    public setTextPosition(dispPosition: TextDisplayPosition) {
        let $inpCtrl: JQuery = $(this.inputControl.inputElement);
        userControls.setTextDisplaytextPosition($inpCtrl, dispPosition);
    }

    /**
     * 入力コントロールのIMEモードを設定する（auto/active/inactive/disabled）
     * @param imeModeStr
     * auto:特に指定しません。
     * active:初期値が日本語入力モードになります。
     * inactive:初期値が英数字入力モードになります。
     * disabled:英数字入力モードになります。ユーザーの操作によるモードの変更はできません。
     */
    public setImeMode(imeModeStr: IME_MODE) {
        $(this.mInputControl.inputElement).css("ime-mode", imeModeStr);
    }

    /** コントロール（コンボボックス）のitemsSourceを取得・設定する */
    public get itemsSource(): any { return this.mInputControl.itemsSource; }
    public set itemsSource(itemsSource: any) { this.mInputControl.itemsSource = itemsSource; }

    /** コントロール（コンボボックス）のdisplayMemberPathを取得・設定する */
    public get displayMemberPath(): string { return this.mInputControl.displayMemberPath; }
    public set displayMemberPath(displayMemberPath: string) { this.mInputControl.displayMemberPath = displayMemberPath; }

    /** コントロール（コンボボックス）のselectedItemを取得・設定する */
    public get selectedItem(): any { return this.mInputControl.selectedItem; }
    public set selectedItem(selectedItem: any) { this.mInputControl.selectedItem = selectedItem; }

    /** コントロール（コンボボックス）のselectedValuePathを取得・設定する */
    public get selectedValuePath(): string { return this.mInputControl.selectedValuePath; }
    public set selectedValuePath(selectedValuePath: string) { this.mInputControl.selectedValuePath = selectedValuePath; }

    /** コントロール（コンボボックス）のselectedValueを取得・設定する */
    public get selectedValue(): any { return this.mInputControl.selectedValue; }
    public set selectedValue(selectedValue: any) { this.mInputControl.selectedValue = selectedValue; }


    /**
     * テキストが変更された際のイベントハンドラ
     * @param e
     */
    protected textChangeHandler(e: wijmo.EventArgs) {
        let newValue: string = this.mInputControl.text;
        this.setValueToViewModel(this.getCtrlList()[0], newValue);
    }

    protected createInputControl($owner: JQuery): any {
        this.mInputControl = new wjInput.ComboBox($owner, { isDroppedDown: false, isEditable: true });

        // キャレット移動をする/しないの設定
        $(this.mInputControl.inputElement).attr(
            InputBase.inputType,
            InputBase.typeStrInput
        );

        // ブラウザ側で入力を促すメッセージが表示されてしまうため、requiredを削除
        $(this.mInputControl.inputElement).removeAttr("required");

        if (this.mInputWidth != null) {
            $(this.mInputControl.hostElement).attr("style", "width:" + this.mInputWidth + "px;");
        }


        //this.setAngularDirective($(this.mInputControl.inputElement), "angular");
        this.createAngularDirectiveInfo($(this.mInputControl.inputElement), "angular");
        this.setAngularWatchEventHandler();

        $(this.mInputControl.inputElement).on("keypress", e => {
            if (!this.mZeroFillMode) return;

            if (["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].any((c: string) => c == e.key)) {
                var currentText = this.mInputControl.text;

                //ゼロ埋めモードで連想絞り込み時に数値を入力した時に入力値をゼロ埋めにするのを回避
                if (isNaN(Number(currentText))) {
                    return;
                }

                var selectionLength: number = this.mInputControl.inputElement.selectionEnd! - this.mInputControl.inputElement.selectionStart!;
                if (selectionLength == this.mMaxLength) {
                    // ゼロ埋め済みの画面入力テキストが全選択されていた場合
                    currentText = "";
                    // 後発のinputイベントにて先頭入力でなく末尾入力の扱いにするため、selectionを設定
                    this.mInputControl.inputElement.selectionStart = this.mInputControl.text.length;
                    this.mInputControl.inputElement.selectionEnd = this.mInputControl.text.length;
                }

                if (currentText.charAt(0) != "0") {
                    if (currentText.length == this.mMaxLength) {
                        // 既にフル桁で入力されていた場合
                        e.preventDefault();
                        //return false; // コード絞り込み時にinputイベントを発火させるため、return falseしない
                        return;
                    }
                }

                var tmpStr: string;
                if (currentText.length === this.mMaxLength) {
                    // 既に最大文字数分入力されていた場合、先頭文字を削除＆入力文字を末尾に付加
                    tmpStr = currentText.substr(1) + e.key;
                } else if (0 < currentText.length && currentText.length < this.mMaxLength) {
                    // 入力はされていたが、Del/BS等で最大文字数に満たなくなった場合
                    tmpStr = currentText + e.key;
                } else {
                    tmpStr = e.key;
                }

                // ゼロ埋め
                this.mInputControl.text = tmpStr.padLeft(this.mMaxLength, "0")

                //e.preventDefault();
                //return false; // コード絞り込み時にinputイベントを発火させるため、return falseしない
                return;
            }
        });

        this.mInputControl.textChanged.addHandler(this.input_change, this);
        this.mInputControl.lostFocus.addHandler(this.input_lostFocus, this);

        return this.mInputControl;
    }

    /**
     * AngularのCompile実行前に行いたい処理がある場合にここに記述
     */
    protected setAngularWatchEventHandler() {
        let ngModel: string = this.getNgModelValue(this.getCtrlList()[0]);
        if (ngModel) {
            this.$scope.$watch(ngModel, (newValue: { toString: () => string; }, oldValue: any) => {
                let val: string = newValue ? newValue.toString() : "";
                let actControlVal: string = this.mInputControl.text;
                if (val != actControlVal) {
                    this.mInputControl.deferUpdate(() => {
                        this.mInputControl.text = String(val);
                    });
                }
            });
        }
    }

    // override
    public setFocus(): void {
        this.mInputControl.inputElement.focus();
    }

    protected input_change(sender: any, args: wijmo.EventArgs) {

        // イベントによるテキスト編集中は処理をしない(犬飼テスト対応)
        if (StringInput.settingText) {
            Logger.info("input_change():Cancel");
            return;
        }

        // イベントによるテキストの編集中
        StringInput.settingText = true;
        // 画面入力内容
        let currentVal: string = this.getValue();
        // テキストボックスに入力(表示)可能な文字列に加工する
        let newVal = this.createFormattedText(currentVal);

        //Logger.info("settingValue():CurrentVal_____" + currentVal);
        //Logger.info("settingValue():newVal_____" + newVal);

        // 加工前/加工後の値の反映で、実際に表示する値を反映するかどうかを決める。
        StringInput.settingText = this.settingValue(currentVal, newVal);

        // 文字列入力中のイベントを発火
        this.stringEventArgs.setNewParams(this.maxLength, currentVal, newVal);
        this.mEventManager.fireEvent("_inputtingString", this, this.stringEventArgs);

    }

    /**
     * テキストを変更中のイベントを取得する
     * @param handler
     */
    public addInputChangeEventHandler(handler: MjsEventContainer<MjsInputtingStringEventArgs>): void {

        this.mEventManager.addEventHandler("_inputtingString", handler as MjsEventContainer<MjsEventArgs>);

    }

    /**
     * 現在の値と、表示用の加工された値から、イベント内で値の反映を行う。
     * @param currentVal
     * @param newVal
     */
    protected settingValue(currentVal: string, newVal: string): boolean {
        // currentValとnewValの値が同じであれば、処理をする必要がない。
        if (currentVal == newVal) {
            // テキスト設定中のイベント解除するため、falseを返す。
            return false;
        }

        // FireFoxでテキストがMaxまで入力された際、テキストそのものがすべてクリアされてしまう現象(Maxで区切られて表示するのが正解)
        // を解消するため、イベントが重複して実行しない様に、テキスト設定フラグ(StringInput.settingText)を使用することにより、
        // 重複を避けている。
        // 条件は、FireFoxかつ入力値がMaxLengthを超えている場合にsetTimeoutによる値の反映を行う。
        // また、Max値以外はその他のブラウザと共通して通るように修正する
        let strLength: number = MjsUtilities.getSjisByteCount(currentVal);
        if (UserAgentUtil.isFireFox() && strLength > this.maxLength) {

            //Logger.info("settingValue():" + strLength + "文字");

            setTimeout(() => {
                // 値の反映
                this.setValue(newVal);
                //テキスト設定中のイベント解除。setTimeoutを使用しているので、ここで行う必要がある。
                StringInput.settingText = false;
            });

            // setTimeOut内でStringInput.settingTextのフラグを解除するので、ここではtrueを返す。
            return true;

        } else {

            // 値の反映
            this.mInputControl._settingText = false;
            this.setValue(newVal);

            // テキスト設定中のイベントを解除するため、falseを返す。
            return false;
        }
    }

    /**
     * 引数:currentValをもとにテキストボックスに入力(表示)可能な文字列を作成して返す
     * @param currentVal
     */
    public createFormattedText(currentVal: string): string {
        let rtnVal = currentVal;

        // SJIS以外の文字を削除
        rtnVal = this.removeNotSjisChars(rtnVal);

        // 入力可能文字種制限が設定されていた場合
        if (this.mInputFilterRegexp) {
            // 入力可能文字種以外の文字を削除
            rtnVal = StringInput.removeInvalidChars(rtnVal, this.mInputFilterRegexp);
        }

        // 入力禁止文字が含まれていた場合
        if (rtnVal && rtnVal.match(StringInput.inputFilterRegexpXSS)) {
            // 入力禁止文字を削除
            rtnVal = StringInput.removeInvalidChars(rtnVal, StringInput.inputFilterRegexpXSSDell);
        }

        // 最大文字数より文字数が多い場合、入力内容を変更前の状態に戻す
        if (this.maxLength != null && this.maxLength > 0 && MjsUtilities.getSjisByteCount(rtnVal) > this.maxLength) {
            // 入力位置
            let caretPos = this.mInputControl.inputElement.selectionStart;
            // 変更前文字列の後半部分
            let lastStr = rtnVal.substr(caretPos!);
            // 変更前文字列の前半部分
            let maxLenForFirstStr = this.maxLength - MjsUtilities.getSjisByteCount(lastStr);
            let firstStr = MjsUtilities.cutString(rtnVal, maxLenForFirstStr);
            // 変更前文字列を復元
            rtnVal = firstStr + lastStr;
        }

        return rtnVal;
    }

    /**
     * 指定した文字列から無効な文字を削除して返す。
     * @param theString (無効な文字を削除したい)元の文字列
     * @param theRegExp 削除対象を示す正規表現(RegExp)
     */
    public static removeInvalidChars(theString: string, theRegExp: RegExp = StringInput.inputFilterRegexpXSSDell): string {
        let tmp: string[] = MjsUtilities.splitString(theString);
        for (let i: number = 0; i < tmp.length; i++) {
            if (tmp[i].match(theRegExp) == null) {
                tmp[i] = "";
            }
        }
        return tmp.join("");
    }

    private input_lostFocus(e: any) {
        this.lostFocus.raise(this, wijmo.EventArgs.empty);
    }

    public getCtrlList(): Array<JQuery> {
        return [$(this.mInputControl.inputElement)];
    }

    /**
     * 入力内容に対する検証を行う
     * @param showError: エラー情報を表示するならtrue
     */
    public validate(showError: boolean = false): boolean {
        return true;
    }

    /**
     * text_changeイベントにおける多重処理を防ぐため、フラグで実行を制御する
     */
    public static settingText: boolean;

    /**
     * masterKbnから入力スタイルを設定する
     * @param inputControl
     * @param masterKbn
     * @param isUseWindow
     */
    public static codeControlInitializeByMasterKbn(inputControl: StringInput, masterKbn: number, isUseWindow: boolean): void {

        // masterKbnからhojyoItemUtilの取得
        const hojyoitemUtil = subItemUtil.getHojyoItemUtil(masterKbn);

        // hojyoItemUtilから入力スタイルの設定
        StringInput.codeControlInitialize(inputControl, hojyoitemUtil, isUseWindow);
    }

    /**
     * 共通的にコードの入力、表示方法を設定する。コードに関しては文字種がいろいろと変わるので、StringInputを使用するが、
     * 選択Windowあり/なしでも動作がかわるため、とりあえず選択Windowありの制御を下記に記載。
     * @param inputControl
     * @param hojoItemUtil
     */
    public static codeControlInitialize(inputControl: StringInput, hojoItemUtil: hojoItemUtil, isUseWindow: boolean) {
        if (hojoItemUtil.canUse()) {
            // MaxLengthを指定する
            inputControl.maxLength = hojoItemUtil.getCodeLength();

            // コード属性により、振り分け
            var codeAttr: number = -1;
            if (hojoItemUtil.isFreeMode()) {
                // フリーモード
                codeAttr = CodeAttr.Free;
            } else if (hojoItemUtil.isNumberMode()) {
                // 数値モード
                codeAttr = CodeAttr.Number;
            } else if (hojoItemUtil.isZeroFillMode()) {
                // ZeroFillモード
                codeAttr = CodeAttr.NumberZeroFill;
            }

            // 属性/スタイルのセット
            StringInput.initializeForCodeInputStyle(inputControl, codeAttr, isUseWindow);
        } else {
            // 指定コントロールの採用がない場合は、取り合えずDisableとする。
            // コントロールによってはVisible=falseとなるので、呼び出し口で調整する。
            inputControl.enabled = false;
        }
    }

    /**
     * codeAttr値を元に、文字列コントロールに属性/スタイルをセットする。
     * @param inputControl
     * @param codeAttr
     * @param isUseWindow
     */
    public static initializeForCodeInputStyle(inputControl: StringInput, codeAttr: number, isUseWindow: boolean) {
        // 各設定を初期化
        // スタイル削除
        inputControl.getCtrlList()[0].removeClass("al_left");
        inputControl.getCtrlList()[0].removeClass("al_right");
        // 入力コード属性削除
        inputControl.getCtrlList()[0].removeAttr(InputBase.inputType);
        // ZeroFillモード設定解除
        inputControl.zeroFillMode = false;

        switch (codeAttr) {
            case CodeAttr.Free:
                // フリーモード
                StringInput.setFreeMode(inputControl);
                break;
            case CodeAttr.Number:
                // 数値モード
                StringInput.setNumericMode(inputControl, isUseWindow);
                break;
            case CodeAttr.NumberZeroFill:
                // ZeroFillモード
                StringInput.setZeroFillMode(inputControl, isUseWindow);
                break;
        }
    }

    /**
     * FreeModeに設定する
     * @param inputControl
     */
    private static setFreeMode(inputControl: StringInput) {
        // フリーモード
        inputControl.getCtrlList()[0].addClass("al_left");
        inputControl.inputFilterRegexp = CodeRegexp.getFreeRegexp();
        // 文字列コントロールとして動作
        inputControl.getCtrlList()[0].attr(InputBase.inputType, InputBase.typeStrInput);
    }

    /**
     * 数値モードとして機能する
     * @param inputControl
     */
    private static setNumericMode(inputControl: StringInput, isUseWindow: boolean) {
        // 数値モード
        inputControl.getCtrlList()[0].addClass("al_right");
        inputControl.inputFilterRegexp = CodeRegexp.getNumberRegexp(isUseWindow);
        // 数値コントロールとして動作
        inputControl.getCtrlList()[0].attr(InputBase.inputType, InputBase.typeNumInput);
    }

    private static setZeroFillMode(inputControl: StringInput, isUseWindow: boolean) {
        // ZeroFillモード
        inputControl.getCtrlList()[0].addClass("al_right");
        inputControl.zeroFillMode = true;
        inputControl.inputFilterRegexp = CodeRegexp.getZerofilRegexp(isUseWindow);
        // 数値コントロールとして動作
        inputControl.getCtrlList()[0].attr(InputBase.inputType, InputBase.typeNumInput);
    }

}
