import React from 'react';
import moment from 'moment';

import * as wj from 'wijmo/wijmo';
import * as wjGrid from 'wijmo/wijmo.grid';
import { FlexGrid } from 'wijmo/wijmo.react.grid';
import * as wjFilter from 'wijmo/wijmo.grid.filter';
import { FlexGridFilter } from 'wijmo/wijmo.react.grid.filter';
import { FormGroup, Label, Input } from 'reactstrap';

import * as labels from '../../../../constants/labels';
import { ConfStatusEnum } from '../../../../constants/enums';
import * as wijmoGridUtils from '../../../../utils/wijmoGridUtils';

import externallinkSvg from '../../../../images/icon/16_externallink.svg';

const htmlJournalLineLinkIcon = (SeqNo: number, RowNo: number, tabIndex?: number) =>
  `<button type="button" class="btn bg-svg-icon float-right my-auto p-2" style="background-image: url(${externallinkSvg})" onclick=window.handleJournalLineLinkClick(${SeqNo},${RowNo}) ${
    typeof tabIndex === 'undefined' ? '' : 'tabIndex=' + tabIndex
  } />`;

type AuditResultGridProp = {
  columns: any[];
  sources: any[];
  execDate?: Date;
  auditTarget?: number;
  groupDescriptions?: string[];
  gridClassName?: string;
  gridTabIndex?: number;
  radioTabIndex?: number;
  radioAllRef?: React.RefObject<any>;
  radioLatestRef?: React.RefObject<any>;
  innerRef?: React.RefObject<any>;
  gridRef?: React.RefObject<any>;
  journalLineLinkTabIndex?: number;
  onJournalLineLinkClick?: (SeqNo: number, RowNo: number) => void;
  onFilterChanged?: (filterJson: string) => void;
};
type AuditResultGridState = {
  itemsSource: wj.CollectionView;
  tooltip: wj.Tooltip;
};

declare global {
  interface Window {
    handleJournalLineLinkClick?: (SeqNo: number, RowNo: number) => void;
  }
}

const displayCountId = 'AuditResultGrid-displayCount';

class AuditResultGrid extends React.Component<AuditResultGridProp, AuditResultGridState> {
  grid?: wjGrid.FlexGrid;
  filter?: wjFilter.FlexGridFilter;

  constructor(props: AuditResultGridProp) {
    super(props);

    this.state = {
      itemsSource: this.initCollectionView(props),
      tooltip: new wj.Tooltip()
    };

    this.executeLatest = this.executeLatest.bind(this);
    this.initializedFilter = this.initializedFilter.bind(this);
    this.handleFilterApplied = this.handleFilterApplied.bind(this);
    this.initializedGrid = this.initializedGrid.bind(this);
    this.handleItemFormatter = this.handleItemFormatter.bind(this);
    this.handleCellOnkeydown = this.handleCellOnkeydown.bind(this);
    this.handleLatest = this.handleLatest.bind(this);
    this.handleAll = this.handleAll.bind(this);

    window.handleJournalLineLinkClick = props.onJournalLineLinkClick;
  }

  public executeLatest = () => {
    return this.props.execDate == null
      ? this.props.sources.map(item => item.ExecDate).reduce((prev, curr) => (prev > curr ? prev : curr))
      : moment(this.props.execDate).toDate();
  };

  // フィルターの初期化
  public initializedFilter = (flexFilter: wjFilter.FlexGridFilter) => {
    this.filter = flexFilter;
  };

  // フィルターが変更された時の処理
  public handleFilterApplied = (f: wjFilter.FlexGridFilter) => {
    if (typeof this.props.onFilterChanged === 'function') {
      this.props.onFilterChanged(f.filterDefinition);
    }
  };

  // Gridの初期化
  public initializedGrid = (flexGrid: wjGrid.FlexGrid) => {
    this.grid = flexGrid;
  };

  // ItemFormatter
  public handleItemFormatter = (panel: wjGrid.GridPanel, row: number, col: number, cell: HTMLElement) => {
    let tooltip = '';
    if (panel.cellType === wjGrid.CellType.ColumnHeader) {
      // ヘッダ
      cell.classList.add('d-flex');
      cell.classList.add('justify-content-center');
      cell.classList.add('align-items-center');
      cell.classList.add('font-weight-normal');

      let label = panel.grid.columnHeaders.columns[col].header;
      label = label.split('\n').join('<br />');
      const currentSort = wijmoGridUtils.getGridHeaderSortIcon(panel.grid.columns[col]);
      cell.innerHTML = label + currentSort;
      panel.grid.columnHeaders.rows.defaultSize = this.getDefaultHieght();
    } else if (panel.cellType === wjGrid.CellType.Cell) {
      // keyイベントの実装
      cell.onkeydown = (event: KeyboardEvent) => {
        this.handleCellOnkeydown(event, row, col);
      };

      const val = panel.grid.getCellData(row, col, false);
      const inputType = panel.grid.columns[col].inputType;

        const colItem = panel.columns[col];
        if (this.props.auditTarget === 1) {
            if (colItem.binding === 'HojyoCode') {
                colItem.header = labels.AIKADT002000007_GRID_HEADER_DEPARTMENTCODE;
            }
            if (colItem.binding === 'HojyoName') {
                colItem.header = labels.AIKADT002000007_GRID_HEADER_DEPARTMENTNAME;
            }
        }

      if (inputType === 'price') {
        // 金額表示
        if (val === 0) {
          // 0の場合はブランク
          cell.innerHTML = '';
        } else if (val < 0) {
          // マイナスの場合は赤字
          cell.classList.add('text-red');
        }
      } else if (inputType === 'rate') {
        // 著増減監査の増減率表示
        if (val == undefined || val === '') {
          // Nullの場合はブランクで背景色をグレー
          cell.classList.add('_grayout');
          cell.innerHTML = '';
        } else if (val < 0) {
          // マイナスの場合は符号を▲にして赤字
          cell.classList.add('text-red');
          cell.innerHTML = '▲' + (val * -1).toFixed(2) + '%';
        } else {
          // 符号を+にして青字
          cell.classList.add('text-blue');
          cell.innerHTML = '+' + val.toFixed(2) + '%';
        }
      } else if (inputType === 'difference') {
        // 著増減監査の差額表示
        if (val == undefined || val === '') {
          // Nullの場合はブランクで背景色をグレー
          cell.classList.add('_grayout');
          cell.innerHTML = '';
        } else if (val < 0) {
          // マイナスの場合は符号を▲にして赤字
          cell.classList.add('text-red');
          cell.innerHTML = '▲' + (val * -1).toLocaleString();
        } else {
          // 符号を+にして青字
          cell.classList.add('text-blue');
          cell.innerHTML = '+' + val.toLocaleString();
        }
      } else if (inputType === 'blankIfGray') {
        // 値がNullの場合に背景色をグレー
        if (val == undefined || val === '') {
          cell.classList.add('_grayout');
        }
      } else if (inputType === 'status') {
        // 確認ステータス表示
        switch (val) {
          // 確認ステータス毎に背景色を変更
          case ConfStatusEnum.Complete:
            cell.classList.add('_CompleteCell');
            break;
          case ConfStatusEnum.Admitted:
            cell.classList.add('_AdmittedCell');
            break;
          case ConfStatusEnum.Pending:
            cell.classList.add('_PendingCell');
            break;
        }
        // コメントのツールチップ表示
        const comment = panel.grid.rows[row].dataItem.Comment;
        const tooltipFlg = comment != null && comment != '';
        wj.toggleClass(cell, '_notes', tooltipFlg);
        tooltip = tooltipFlg ? `<span class='tooltip-comment'>${comment}</span>` : '';
      } else if (inputType === 'tooltip') {
        // 値をツールチップに表示
        tooltip = val;
      } else if (inputType === 'JournalLineLinkIcon') {
        // 仕訳明細ポップアップアイコン表示
        let icon = '';
        if (String(this.executeLatest()) === String(panel.grid.rows[row].dataItem.ExecDate)) {
          icon = htmlJournalLineLinkIcon(
            panel.grid.rows[row].dataItem.SeqNo,
            panel.grid.rows[row].dataItem.RowNo,
            this.props.journalLineLinkTabIndex
          );
        }
        cell.innerHTML = val + icon;
      }
    }
    this.state.tooltip.setTooltip(cell, tooltip);
  };

  public handleCellOnkeydown(event: KeyboardEvent, row: number, col: number) {
    if (event.keyCode === 13) {
      if (typeof this.props.onJournalLineLinkClick === 'function') {
        this.props.onJournalLineLinkClick(this.grid!.rows[row].dataItem.SeqNo, this.grid!.rows[row].dataItem.RowNo);
        event.preventDefault();
        event.stopPropagation();
        return;
      }
    }
  }

  // 最新のみ表示
  public handleLatest = () => {
    const latest = this.executeLatest();
    if (this.filter != null) {
      const executionDateFilter = this.filter.getColumnFilter('ExecDate');
      executionDateFilter.conditionFilter.condition1.value = latest;
      executionDateFilter.conditionFilter.condition1.operator = wjFilter.Operator.EQ;
      this.filter.apply();
    }
  };
  // 全件表示
  public handleAll = () => {
    if (this.filter != null) {
      this.filter.clear();
    }
  };

  public getDefaultHieght() {
    const criteriaHieght = 21;
    let defaultHieght = 0;
    this.props.columns.forEach(data => {
      const cnt = data.header.split('\n');
      let hieghtWk = criteriaHieght * cnt.length + 8;
      defaultHieght = defaultHieght < hieghtWk ? hieghtWk : defaultHieght;
    });
    return defaultHieght;
  }

  // CollectionViewの初期化
  public initCollectionView = (props: AuditResultGridProp) => {
    const { columns, sources, groupDescriptions } = props;
    wijmoGridUtils.convertItemsSourceToDateType(columns, sources);
    const gridCollectionView = new wj.CollectionView(sources, {
      collectionChanged: (collectionView: wj.CollectionView, g: wj.NotifyCollectionChangedEventArgs) => {
        this.dispCnt(wj.format('{length:n0}', collectionView.items));
      }
    });

    if (groupDescriptions) {
      groupDescriptions.forEach(str => {
        gridCollectionView.groupDescriptions.push(new wj.PropertyGroupDescription(str));
      });
    }

    if (
      columns.filter(column => {
        return column.binding === 'HojyoCode';
      }).length > 0
    ) {
      gridCollectionView.sourceCollection.forEach((item: any) => {
        if (item.HojyoCode == null) {
          item.HojyoCode = '';
        }
      });
    }

    return gridCollectionView;
  };

  // 表示件数の表示
  public dispCnt = (lbl: string) => {
    const displayCount = document.getElementById(displayCountId);
    if (displayCount) {
      displayCount.innerHTML = lbl;
    }
  };

  public componentDidMount() {
    this.handleLatest();
  }

  public render() {
    const {
      columns,
      gridTabIndex,
      radioTabIndex,
      radioAllRef,
      radioLatestRef,
      innerRef,
      gridRef,
      gridClassName
    } = this.props;

    return (
      <span className='audit-result-grid'>
        <div className='d-flex'>
          <div className='mr-auto'>
            <FormGroup check inline>
              <Label check>
                <Input
                  type='radio'
                  name='checkaction'
                  onClick={this.handleLatest}
                  defaultChecked={true}
                  tabIndex={radioTabIndex}
                  innerRef={radioLatestRef}
                />
                {labels.COMMON_INPUT_HEADER_LASTEXECUTION}
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                <Input
                  type='radio'
                  name='checkaction'
                  onClick={this.handleAll}
                  tabIndex={radioTabIndex}
                  innerRef={radioAllRef}
                />
                {labels.COMMON_INPUT_HEADER_ALLHISTORY}
              </Label>
            </FormGroup>
          </div>
          <div className='ml-auto'>
            <span id={displayCountId} />
            {labels.COMMON_FUNCTION_GRID_TOTALCOUNT}
          </div>
        </div>

        <span ref={innerRef}>
          <FlexGrid
            className={gridClassName}
            autoGenerateColumns={false}
            columns={columns}
            allowResizing={wjGrid.AllowResizing.None}
            allowDragging={wjGrid.AllowDragging.None}
            isReadOnly={true}
            selectionMode={wjGrid.SelectionMode.Row}
            headersVisibility={wjGrid.HeadersVisibility.Column}
            alternatingRowStep={0}
            itemsSource={this.state.itemsSource}
            initialized={this.initializedGrid}
            itemFormatter={this.handleItemFormatter}
            tabIndex={gridTabIndex}
            ref={gridRef}
          >
            <FlexGridFilter initialized={this.initializedFilter} filterApplied={this.handleFilterApplied} />
          </FlexGrid>
        </span>
      </span>
    );
  }
}
export default AuditResultGrid;
