import { MjsDelegateContainer } from "../../../common/usefuls/delegate";
import { Logger } from "../../../common/usefuls/logger";
import { AbstractButton } from "./abstractButton";
import $ from 'jquery';

export abstract class SimpleButton extends AbstractButton {
    constructor(selector: string, label?: string) {
        super(selector, label!);
    }

    /**
     * ボタンを有効化する
     */
    public enable(): void { }
    /*
     * ボタンを 無効化する
     */
    public disable(): void { }
    /**
     * ボタンの有効/無効をフラグで返す。
     * true:有効
     * false:無効
     */
    public isEnabled(): boolean { return true; }
}
/**
 * Buttonタグ用のクラス
 */
export class Button extends SimpleButton {

    private _handler!: MjsDelegateContainer<Function, any>;

    constructor(selector: string) {
        super(selector);
    }
    public setEventHandler(handler: MjsDelegateContainer<Function, any>): void {
        this._handler = handler;
    }

    public execute(): void {
        if (this._handler != null) {
            this._handler.invoke(null!);
        } else {
            Logger.debug("event handler is null");
        }

        // イベント伝播
        if (this.eventLinkNum() > 0) {
            this.executeEventLink(null!);
        }
    }
    /**
     * ボタンを有効化する
     */
    public enable(): void {
        $(this.getSelector()).removeClass(AbstractButton.disabledClass);
        $(this.getSelector()).removeAttr("disabled");
    }
    /*
     * ボタンを 無効化する
     */
    public disable(): void {
        $(this.getSelector()).addClass(AbstractButton.disabledClass);
        $(this.getSelector()).attr("disabled", "disabled");
    }
    /**
     * ボタンの有効/無効をフラグで返す。
     * true:有効
     * false:無効
     */
    public isEnabled(): boolean {
        return !$(this.getSelector()).hasClass(AbstractButton.disabledClass);
    }
}
