import React from 'react';
import classnames from 'classnames';
import { FormGroup, Label, Input } from 'reactstrap';

export type validInvalidRadioProps = React.HTMLAttributes<HTMLDivElement> & {
  name?: string;
  isValid?: boolean;
  validLabelClassName?: string;
  invalidLabelClassName?: string;
  validRef?: React.RefObject<any>;
  invalidRef?: React.RefObject<any>;
  onRadioChange?: (isValid: boolean) => void;
};

const ValidInvalidRadio: React.FC<validInvalidRadioProps> = props => {
  const {
    name,
    className,
    isValid,
    validLabelClassName,
    invalidLabelClassName,
    validRef,
    invalidRef,
    onRadioChange
  } = props;

  const [isValidState, setIsValidState] = React.useState(isValid);
  const handleOnRadioChange = (isValid: boolean) => {
    setIsValidState(isValid);
    onRadioChange!(isValid);
  };

  React.useEffect(() => {
    setIsValidState(isValid);
  }, [isValid]);

  // divタグに不要なpropsを除去
  const divProps = { ...props };
  delete divProps['name'];
  delete divProps['isValid'];
  delete divProps['validLabelClassName'];
  delete divProps['invalidLabelClassName'];
  delete divProps['validRef'];
  delete divProps['invalidRef'];
  delete divProps['onRadioChange'];

  return (
    <div {...divProps} className={classnames(className, 'd-flex')}>
      <FormGroup check className='mr-3'>
        <Label check>
          <Input
            type='radio'
            name={name}
            className={validLabelClassName}
            checked={isValidState === true}
            innerRef={validRef}
            onChange={() => handleOnRadioChange(true)}
          />
          有効
        </Label>
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input
            type='radio'
            name={name}
            className={invalidLabelClassName}
            checked={isValidState === false}
            innerRef={invalidRef}
            onChange={() => handleOnRadioChange(false)}
          />
          無効
        </Label>
      </FormGroup>
    </div>
  );
};
export default ValidInvalidRadio;
