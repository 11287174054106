import React from 'react';

import classnames from 'classnames';
import { Button } from 'reactstrap';

export type executingCancelProps = React.HTMLAttributes<HTMLDivElement> & {
  isBatchExec?: boolean;
  executeLabel?: string;
  executeDisabled?: boolean;
  executeTabIndex?: number;
  executeRef?: React.RefObject<any>;
  onExecuteClick?: () => void;
  cancelLabel?: string;
  cancelDisabled?: boolean;
  cancelTabIndex?: number;
  cancelRef?: React.RefObject<any>;
  onCancelClick?: () => void;
};

const ExecutingCancel: React.FC<executingCancelProps> = props => {
  const {
    executeLabel,
    executeDisabled,
    executeTabIndex,
    executeRef,
    onExecuteClick,
    cancelLabel,
    cancelDisabled,
    cancelTabIndex,
    cancelRef,
    onCancelClick,
    className
  } = props;

  // 監査実行ボタンの表示設定
  const ExecuteButton = props.isBatchExec ? (
    <>
    </>
  ) : (
    <Button
      color='primary'
      className='mr-1 A3-btn'
      onClick={onExecuteClick}
      disabled={executeDisabled}
      tabIndex={executeTabIndex}
      innerRef={executeRef}
    >
      {executeLabel}
    </Button>
  );
  // divタグに不要なpropsを除去
  const divProps = { ...props };
  delete divProps['executeLabel'];
  delete divProps['executeDisabled'];
  delete divProps['executeTabIndex'];
  delete divProps['executeRef'];
  delete divProps['onExecuteClick'];
  delete divProps['cancelLabel'];
  delete divProps['cancelDisabled'];
  delete divProps['cancelTabIndex'];
  delete divProps['cancelRef'];
  delete divProps['onCancelClick'];

  return (
    <div {...divProps} className={classnames(className, 'execut-cancel-btns')}>
      {ExecuteButton}
      <Button
        color='secondary'
        className='ml-1 A3-btn'
        onClick={onCancelClick}
        disabled={cancelDisabled}
        tabIndex={cancelTabIndex}
        innerRef={cancelRef}
      >
        {cancelLabel}
      </Button>
    </div>
  );
};
export default ExecutingCancel;
