/**
 * 対象仕訳区分
 */
export enum TargetJournalEnum {
    /** すべて */
    All = 0,
    /** 入力仕訳 */
    Manual = 1,
    /** 自動仕訳 */
    Automatic = 2,
}
/**
 * 実行対象月区分
 */
export enum TargetTMonthEnum {
    /** すべて */
    All = 0,
    /** 通常月 */
    Normal = 1,
    /** 決算月 */
    Account = 2,
    /** 個別指定 */
    Customize = 3,
}
/**
 * 有効無効
 */
export enum AbleEnum {
    /** 無効 */
    Disable = 0,
    /** 有効 */
    Enable = 1,
}
/**
 * ANDOR
 */
export enum AndOrEnum {
    /** AND */
    And = 0,
    /** OR */
    Or = 1,
}
/**
 * 条件（文字列）
 */
export enum CondiStringEnum {
    /** 一致 */
    Match = 0,
    /** 一致しない */
    NotMatch = 1,
    /** 含む */
    Contain = 2,
    /** 含まない */
    Without = 3,
    /** 始まる */
    Start = 4,
    /** 終わる */
    End = 5,
}
/**
 * 条件（数値）
 */
export enum CondiNumberEnum {
    /** 一致 */
    Match = 6,
    /** 一致しない */
    NotMatch = 7,
    /** より大きい */
    GreaterThan = 8,
    /** より小さい */
    SmallerThan = 9,
    /** 以上 */
    OrMore = 10,
    /** 以下 */
    OrLess = 11,
}
/**
 * 条件（日付）
 */
export enum CondiDateEnum {
    /** 一致 */
    Same = 12,
    /** より前 */
    Before = 13,
    /** より後 */
    After = 14,
    /** 以前 */
    Until = 15,
    /** 以降 */
    Since = 16,
}
/**
 * 種別区分
 */
export enum DataTypeEnum {
    /** システム */
    System = 0,
    /** ユーザー */
    User = 1,
}
/**
 * 確認ステータス
 */
export enum ConfStatusEnum {
    /** 未確認 */
    Auditable = 0,
    /** 確認NG */
    Admitted = 1,
    /** 確認OK */
    Complete = 2,
    /** 保留 */
    Pending = 3,
}
/**
 * 項目種別区分
 */
export enum ItemTypeEnum {
    /** テキスト */
    Text = 0,
    /** 数値 */
    Number = 1,
    /** 日付 */
    Date = 2,
}
/**
 * バッチステータス
 */
export enum BatStatusEnum {
    /** 処理待ち */
    Waiting = 0,
    /** 処理中 */
    Processing = 1,
    /** 正常終了 */
    Success = 2,
    /** 異常終了 */
    Abend = 9,
}
/**
 * 監査種類
 */
export enum AuditTypeEnum {
    /** 著増減監査 */
    RemarkIncDecAudit = 1,
    /** マイナス残高監査 */
    NegativeBalanceAudit = 2,
    /** 仕訳明細監査 */
    JournalLineAudit = 3,
    /** 仕訳重複監査 */
    JournalDoubleAudit = 4,
    /** 消費税区分監査 */
    TaxKubunAudit = 5,
}
/**
 * 比較対象年度区分
 */
export enum CmpYearEnum {
    /** 前年度 */
    Previous = 0,
    /** 同年度 */
    Current = 1,
}
/**
 * 変換用条件（文字列）
 */
export enum JokenStingEnum {
    /** と一致 */
    Match = 0,
    /** と一致しない */
    NotMatch = 1,
    /** を含む */
    Contain = 2,
    /** を含まない */
    Without = 3,
    /** から始まる */
    Start = 4,
    /** で終わる */
    End = 5,
}
/**
 * 変換用条件（数値）
 */
export enum JokenNumberEnum {
    /** と一致 */
    Match = 6,
    /** と一致しない */
    NotMatch = 7,
    /** より大きい */
    GreaterThan = 8,
    /** より小さい */
    SmallerThan = 9,
    /** 以上 */
    OrMore = 10,
    /** 以下 */
    OrLess = 11,
}
/**
 * 変換用条件（日付）
 */
export enum JokenDateEnum {
    /** と一致 */
    Same = 12,
    /** より前 */
    Before = 13,
    /** より後 */
    After = 14,
    /** 以前 */
    Until = 15,
    /** 以降 */
    Since = 16,
}
/**
 * 貸借区分
 */
export enum DCEnum {
    /** 貸方 */
    Credit = 0,
    /** 借方 */
    Debit = 1,
    /** 両方 */
    Both = 2,
}
/**
 * しきい値符号区分
 */
export enum SkiSignEnum {
    /** ± */
    PlusMinus = 0,
    /** ＋ */
    Plus = 1,
    /** － */
    Minus = 2,
    /** ＝ */
    Equal = 3,
}
/**
 * NG有無
 */
export enum AuditCaughtEnum {
    /** なし */
    Empty = 0,
    /** あり */
    Contains = 1,
}
/**
 * 表示モード
 */
export enum DisplayModeEnum {
    /** 新規作成 */
    New = 0,
    /** 更新 */
    Update = 1,
    /** コピー */
    Copy = 2,
}
/**
 * 売上仕入区分
 */
export enum SalesPurchaseEnum {
    /** なし */
    None = 0,
    /** 売上 */
    Sales = 1,
    /** 仕入 */
    Purchase = 2,
}
/**
 * 条件項目補助区分
 */
export enum JItemHojyoEnum {
    /** なし */
    None = 0,
    /** 科目別補助 */
    AccountSub = 1,
    /** 部門 */
    Dept = 2,
    /** 工事 */
    Construction = 3,
    /** セグメント1 */
    Segment1 = 4,
    /** セグメント2 */
    Segment2 = 5,
    /** セグメント3 */
    Segment3 = 6,
    /** セグメント4 */
    Segment4 = 7,
    /** セグメント5 */
    Segment5 = 8,
    /** 全セグメント */
    SegmentAll = 9,
    /** 第1補助 */
    Subaccount1 = 10,
    /** 第2補助 */
    Subaccount2 = 11,
    /** 全補助 */
    SubaccountAll = 12,
}
/**
 * 仕訳重複条件項目
 */
export enum DblJokenEnum {
    /** 伝票No */
    SlipNo = 0,
    /** 証憑No */
    VoucherNo = 1,
    /** 伝票日付 */
    SlipDate = 2,
    /** 勘定科目 */
    Account = 3,
    /** 部門 */
    Dept = 4,
    /** 工事 */
    Construction = 5,
    /** セグメント */
    Segment = 6,
    /** 補助 */
    Subaccount = 7,
    /** 金額 */
    Amount = 8,
    /** 消費税 */
    Tax = 9,
    /** 摘要 */
    Summary = 10,
    /** 仕入日 */
    PurchaseDate = 11,
    /** 仕入先 */
    Supplier = 12,
}
/**
 * 比較対象月度区分
 */
export enum CmpTMonthEnum {
    /** 前月 */
    PreviousMonth = 0,
    /** 前年同月 */
    YearAgoMonth = 1,
    /** 前半期同月 */
    LastHalfMonth = 2,
    /** 前四半期同月 */
    LastQuarterMonth = 3,
    /** 当月 */
    CurrentMonth = 4,
    /** 期首残 */
    BeginningBalance = 5,
}
/**
 * 結果ステータス区分
 */
export enum AuditStatusEnum {
    /** 未連携 */
    EmptyData = 0,
    /** 完了 */
    Completed = 1,
    /** 結果確認中 */
    Uncomplete = 2,
    /** 未実行 */
    Auditable = 3,
}
/**
 * ユーザ種別
 */
export enum UserTypeEnum {
    /** CSユーザー */
    Cs = 0,
    /** 一般ユーザー */
    General = 1,
}
/**
 * 中間決算区分
 */
export enum MidtermClosingEnum {
    /** なし */
    None = 0,
    /** 半期決算 */
    Half = 1,
    /** 四半期決算 */
    Quarter = 2,
}
/**
 * バッチID
 */
export enum BatIdEnum {
    /** 著増減監査 */
    RemarkIncDecAudit = 1,
    /** マイナス残高監査 */
    NegativeBalanceAudit = 2,
    /** 仕訳明細監査 */
    JournalLineAudit = 3,
    /** 仕訳重複監査 */
    JournalDoubleAudit = 4,
    /** 消費税区分監査 */
    TaxKubunAudit = 5,
    /** 顧問先インストール */
    ClientDataInstall = 6,
    /** 顧問先差分取込 */
    ClientDataDiffImport = 7,
    /** 顧問先削除 */
    ClientDataDelete = 8,
    /** エクセル出力 */
    ExcelOutput = 9,
    /** AI仕訳チェック */
    AIJournalCheck = 10,
    /** 監査ルールインポート */
    AuditRuleImport = 11,
    /** MigrateSwkの後処理 */
    MigrateSwkPostProcess = 12,
}
/**
 * データ書出し方向種別
 */
export enum DataWritingDirectionEnum {
    /** 縦 */
    Portrait = 0,
    /** 横 */
    Landscape = 1,
}
/**
 * 取込ルール区分
 */
export enum ImportRuleEnum {
    /** 仕訳明細ルール */
    JournalLineRule = 0,
    /** 月次残高ルール */
    MonthlyBalanceRule = 1,
}
/**
 * インポート方法区分
 */
export enum ImportMethodEnum {
    /** 追加 */
    Insert = 0,
    /** ルールを削除してからインポートする */
    DeleteInsert = 1,
}
/**
 * ＢＳ／ＰＬ区分
 */
export enum BPEnum {
    /** 全科目 */
    All = 9,
    /** B/S科目 */
    BS = 0,
    /** P/L科目 */
    PL = 1,
}
/**
 * ルール設定パターン区分
 */
export enum RulePatternEnum {
    /** パターン1 */
    RulePattern1 = 1,
    /** パターン2 */
    RulePattern2 = 2,
    /** パターン3 */
    RulePattern3 = 3,
    /** パターン4 */
    RulePattern4 = 4,
    /** パターン5 */
    RulePattern5 = 5,
    /** パターン6 */
    RulePattern6 = 6,
    /** パターン7 */
    RulePattern7 = 7,
    /** パターン8 */
    RulePattern8 = 8,
    /** パターン9 */
    RulePattern9 = 9,
    /** パターン10 */
    RulePattern10 = 10,
    /** パターン11 */
    RulePattern11 = 11,
    /** パターン12 */
    RulePattern12 = 12,
    /** パターン13 */
    RulePattern13 = 13,
    /** パターン14 */
    RulePattern14 = 14,
    /** パターン15 */
    RulePattern15 = 15,
}
/**
 * 比較値区分
 */
export enum CmpValueEnum {
    /** 残高 */
    Balance = 0,
    /** 実績 */
    Actual = 1,
    /** 実績（借方発生） */
    DebitActual = 2,
    /** 実績（貸方発生） */
    CreditActual = 3,
}
/**
 * 対象月区分
 */
export enum TargetMonthEnum {
    /** 実行月 */
    ExecMonth = 0,
    /** 比較対象月 */
    CompMonth = 1,
}
/**
 * 月次確定区分
 */
export enum MonthlyFixEnum {
    /** 解除 */
    Release = 0,
    /** 確定 */
    Fixed = 1,
}
/**
 * 敬称
 */
export enum HonorificEnum {
    /** なし */
    None = 0,
    /** 御中 */
    Messrs = 1,
    /** 様 */
    Mrs = 2,
    /** 殿 */
    Esquire = 3,
}
/**
 * 遷移先画面
 */
export enum ActivateScreenEnum {
    /** 一覧 */
    Result = 0,
    /** 明細 */
    JounalLine = 1,
}
/**
 * 連携区分
 */
export enum CooperationEnum {
    /** MJSシステム連携 */
    MJS = 0,
    /** 他社システム連携 */
    Other = 1,
}
/**
 * 権限区分
 */
export enum AuthorityEnum {
    /** 一般 */
    Ippan = 0,
    /** 管理者 */
    Kanri = 1,
    /** Bizsky */
    Bizsky = 2,
}
/**
 * 科目分類
 */
export enum KmkClassEnum {
    /** 資産科目 */
    AssetsKmk = 1,
    /** 負債・純資産科目 */
    LliabilitiesKmk = 2,
    /** 損益科目 */
    ProfitLossKmk = 3,
    /** 製造科目 */
    manufacKmk = 4,
}
/**
 * 中間決算区分集計方法
 */
export enum MidtermClosingCalcKbnEnum {
    /** 下期に含まない */
    Without = 0,
    /** 下期に含む */
    Contain = 1,
}
/**
 * 科目体系
 */
export enum CorpKbnEnum {
    /** 一般 */
    Corporation = 1,
    /** 医療 */
    Medical = 2,
    /** 工事Ｂ／Ｓ */
    KojiBS = 3,
    /** 工事Ｐ／Ｌ */
    KojiPL = 4,
}
/**
 * 正残区分
 */
export enum DCKbnEnum {
    /** 借方 */
    Debit = 0,
    /** 貸方 */
    Credit = 1,
}
/**
 * BS/PL区分
 */
export enum BPKbnEnum {
    /** Ｂ／Ｓ */
    Balance = 0,
    /** Ｐ／Ｌ */
    Profit = 1,
}
/**
 * 期首残区分
 */
export enum KisyuKbnEnum {
    /** なし */
    No = 0,
    /** あり */
    Ok = 1,
}
/**
 * 消費税科目区分
 */
export enum TaxKbnEnum {
    /** 対象外 */
    Nothing = 0,
    /** 売上 */
    Earnings = 1,
    /** 仕入 */
    Purchase = 2,
}
/**
 * 消費税率区分
 */
export enum TaxRateEnum {
    /** 標準税率 */
    Normal = 0,
    /** 3.0% */
    Three = 1,
    /** 5.0% */
    Five = 2,
    /** 8.0% */
    Eight = 3,
    /** 10.0% */
    Ten = 4,
    /** 8.0%軽 */
    EightK = 5,
}
/**
 * 業種コード
 */
export enum TypeCodeEnum {
    /** 基準業種 */
    Normal = 0,
    /** 第一種 */
    First = 1,
    /** 第二種 */
    Second = 2,
    /** 第三種 */
    Third = 3,
    /** 第四種 */
    Fourth = 4,
    /** 第五種 */
    Fifth = 5,
    /** 第六種 */
    Sixth = 6,
}
/**
 * 会計処理区分
 */
export enum AccProKbnEnum {
    /** 免税業者 */
    TaxFreeTrader = 0,
    /** 税込処理 */
    TaxIncluded = 1,
    /** 税抜処理 */
    TaxTreatment = 2,
}
/**
 * 簡易課税区分
 */
export enum KaniKbnEnum {
    /** 原則課税 */
    PrincipleTaxation = 0,
    /** 簡易課税 */
    SimplifiedTax = 1,
}
/**
 * 簡易課税基準業種
 */
export enum BasedTypeEnum {
    /** 卸売業 */
    WholesaleBusiness = 1,
    /** 小売業 */
    RetailTrade = 2,
    /** 製造業 */
    ManufacturingIndustry = 3,
    /** その他 */
    Others = 4,
    /** サービス業 */
    ServiceIndustry = 5,
    /** 不動産 */
    Estate = 6,
}
/**
 * 輸入取引区分
 */
export enum ImportKbnEnum {
    /** なし */
    Balance = 0,
    /** 税抜処理（消費税一括処理） */
    Actual = 1,
    /** 税抜処理（国税/地方税分割） */
    DebitActual = 2,
    /** 税込処理（消費税一括処理） */
    CreditActual = 3,
}
/**
 * サービス死活監視区分
 */
export enum ServiceAliveKbnEnum {
    /** 正常 */
    Normal = 0,
    /** データベース接続異常 */
    DBError = 11,
    /** Blob接続異常 */
    BlobError = 12,
    /** Queue接続異常 */
    QueueError = 13,
    /** Redis接続異常 */
    RedisError = 14,
    /** Redis排他ロック異常 */
    RedisExError = 15,
    /** バッチ異常 */
    BatchError = 20,
}
/**
 * 科目部門区分
 */
export enum KmkBmnKbn {
    /** 科目 */
    Kamoku = 1,
    /** 部門 */
    Bumon = 41,
}

