import { Logger } from "./logger";

/**
 * ユーザーエージェント判定ユーティリティクラス
 */
export class UserAgentUtil {

    public static isMsie(): boolean {
        var userAgent = window.navigator.userAgent.toLowerCase();
        Logger.info(userAgent);
        return userAgent.indexOf('msie') != -1
            || userAgent.indexOf('trident') != -1;  // Version11(Windows)
        //    || userAgent.indexOf('edge') != -1;
    }

    public static isMacChrome(): boolean {
        var userAgent = window.navigator.userAgent.toLocaleLowerCase();
        return (userAgent.indexOf('chrome') != -1) && (userAgent.indexOf('macintosh') != -1);
    }

    public static isIOSChrome(): boolean {
        var userAgent = window.navigator.userAgent.toLocaleLowerCase();
        return (userAgent.indexOf('crios') != -1);
    }

    public static isWindowsChrome(): boolean {
        var userAgent = window.navigator.userAgent.toLocaleLowerCase();
        return (userAgent.indexOf('chrome') != -1) && (userAgent.indexOf('windows') != -1);
    }

    public static isChrome(): boolean {
        var userAgent = window.navigator.userAgent.toLowerCase();
        return userAgent.indexOf('chrome') != -1;
    }

    public static isSafari(): boolean {
        var userAgent = window.navigator.userAgent.toLowerCase();
        return userAgent.indexOf('safari') != -1;
    }

    public static isOpera(): boolean {
        var userAgent = window.navigator.userAgent.toLowerCase();
        return userAgent.indexOf('opera') != -1;
    }

    public static isFireFox(): boolean {
        var userAgent = window.navigator.userAgent.toLowerCase();
        return userAgent.indexOf('firefox') != -1;
    }

    public static isEdge(): boolean {
        var userAgent = window.navigator.userAgent.toLowerCase();
        return userAgent.indexOf('edge') != -1;
    }
}
