import { MjsUtilities, Tuple } from "../../../common/usefuls/utilities";
import * as wijmo from 'wijmo/wijmo';
import $ from 'jquery';

interface AngularDirective {
    directiveName: string;
    value: string;
}
interface AngularDirectiveInfo {
    targetControl: JQuery;
    directiveList: Array<AngularDirective>;
}

/**
 *  [ラベル][WijmoControl] のセットで作成される共通のコントロールを管理するクラス
 */
export abstract class InputBase {

    // 文字の入力タイプを設定する。設定は共通コンポーネントで行い、
    // 属性設定時にNum/Str/Comboで変化する可能性がある。
    public static inputType = "inpType";
    public static typeNumInput = "typeNumInput";
    public static typeStrInput = "typeStrInput";
    public static typeComboInput = "typeComboInput";

    protected $scope: any;
    protected $compile: any;
    protected $baseTag: JQuery;
    //private $captionLabel: JQuery;
    protected $valueLabel!: JQuery;

    private mCaption: string;
    private mValueLabelWidth: number;
    private mInput!: Tuple<JQuery, any>;
    //private mTooltip: support.popUp.SimpleToolTip;
    // エラーメッセージ用ツールチップ
    private mErrTooltip!: wijmo.Tooltip;
    // 通知メッセージ用ツールチップ
    private mInfoTooltip!: wijmo.Tooltip;
    private mInfoTooltipNo: number = 0;

    private mAngularDirective: Array<AngularDirectiveInfo>;

    constructor(baseTagId: string, $scope: any, $compile: any, caption: string, valueLabelWidth?: number);
    constructor(baseTagId: JQuery, $scope: any, $compile: any, caption: string, valueLabelWidth?: number);
    constructor(baseTagId: any, $scope: any, $compile: any, caption: string, valueLabelWidth: number = null!) {
        this.$scope = $scope;
        this.$compile = $compile;
        this.mCaption = caption;

        this.mValueLabelWidth = valueLabelWidth;

        if (typeof (baseTagId) == "string") {
            this.$baseTag = $("#" + baseTagId);
        } else {
            this.$baseTag = baseTagId;
        }
        this.lostFocus = new wijmo.Event();
        this.gotFocus = new wijmo.Event();
        this.keyDown = new wijmo.Event();
        this.mAngularDirective = new Array<AngularDirectiveInfo>();
    }

    public lostFocus: wijmo.Event;
    public gotFocus: wijmo.Event;
    public keyDown: wijmo.Event;
    public abstract validate(showError: boolean): boolean;
    public abstract setFocus(): void;
    protected abstract createInputControl($owner: JQuery): any;
    protected postInitialize(): void { }

    /**
     * コントロールのイニシャライズを行う
     */
    public initialize(): InputBase {
        //this.$baseTag.html('');

        var $captionTag = $("<span>").addClass("captionLabel").html(this.mCaption);
        this.mInput = this.createInput();
        this.$baseTag.append($captionTag);
        this.$baseTag.append(this.mInput.Item1);

        // メッセージを表示するための準備
        this.initializeErrorMessage();

        if (this.mValueLabelWidth != null && this.mValueLabelWidth >= 0) {
            var $valueLabelTag = $("<input type='text' disabled='disabled'>").addClass("valueLabel").addClass("disabled").attr("style", "width:" + this.mValueLabelWidth + "px");
            this.setAngularDirective($valueLabelTag, "angular-Name");
            this.$baseTag.append($valueLabelTag);
            this.$valueLabel = $valueLabelTag;
        }
        this.postInitialize();
        return this;
    }

    private createInput(): Tuple<JQuery, any> {
        var $inputArea: JQuery = $("<div>").addClass("inputControl");
        var inputControl = this.createInputControl($inputArea);
        return new Tuple<JQuery, any>($inputArea, inputControl);
    }

    public set labelAreaEditable(editable: boolean) {
        if (editable) {
            $(".valueLabel", this.$baseTag).removeAttr("disabled");
            $(".valueLabel", this.$baseTag).removeClass("disabled");
        } else {
            $(".valueLabel", this.$baseTag).attr("disabled", "disabled");
            $(".valueLabel", this.$baseTag).addClass("disabled");
        }
    }

    /**
     * 値ラベル領域が編集可能状態かどうかを取得する
     */
    public get labelAreaEditable(): boolean {
        return !$(".valueLabel", this.$baseTag).attr("disabled");
    }

    /**
     * 値ラベル要素を取得する
     */
    public get labelElement(): JQuery {
        return $(".valueLabel", this.$baseTag);
    }

    /**
     * 見出しテキストを設定する
     * @param text
     */
    public setCaption(text: string) {
        $(".captionLabel", this.$baseTag).text(text);
    }

    /**
     * 見出しテキストを取得する
     */
    public getCaption(): string {
        return $(".captionLabel", this.$baseTag).text();
    }

    /**
     * 値ラベルテキストを設定する
     * @param text
     */
    public setLabelText(text: string) {
        $(".valueLabel", this.$baseTag).val(text);
    }

    /**
     * 値ラベルテキストを取得する
     */
    public getLabelText(): string {
        //return $(".valueLabel", this.$baseTag).text();
        return $(".valueLabel", this.$baseTag).val();
    }

    /**
     * エラーメッセージをToolTipで表示するための準備
     */
    protected initializeErrorMessage(): void {
    }

    /**
     * エラーメッセージを表示する
     * @param message: 表示メッセージ
     * @param element: 表示対象要素
     */
    public showError(message: string, $input: JQuery = null!) {
        let $errorInput: JQuery;

        // 前のメッセージが残っている場合は消す
        this.hideError();

        this.mErrTooltip = new wijmo.Tooltip();

        // フォーカスが外れたときにメッセージを消去する
        if (this.mInput.Item2 && this.mInput.Item2["inputElement"]) {
            $(this.mInput.Item2["inputElement"]).one("blur", (e: JQueryEventObject) => {
                this.hideError();
            });
        }
        // スタイル指定 
        this.mErrTooltip.cssClass = "wj-tooltip";

        // 引数に指定された場合は引数優先。
        if ($input) {
            $errorInput = $input;
        } else {
            $errorInput = $(this.mInput.Item2["inputElement"]);
        }

        this.mErrTooltip.showDelay = 10;
        this.mErrTooltip.show($errorInput, message);
        
    }

    /**
     * 通知メッセージを表示する
     * @param message
     * @param $input
     */
    public showInfo(message: string, e: JQueryEventObject, $input: JQuery = null!) {
        let $infoInput: JQuery;

        // 前のメッセージが残っている場合は消す
        this.hideError();

        this.mInfoTooltip = new wijmo.Tooltip();

        // フォーカスが外れたときにメッセージを消去する
        $(e.target).one("blur", (e: JQueryEventObject) => {
            this.hideError();
        });
        // 文字入力されたときにメッセージを消去する
        $(e.target).one("keydown", (e: JQueryEventObject) => {
            this.hideError();
        });

        // スタイル指定 mjswijmo.cssで設定
        this.mInfoTooltip.cssClass = "wj-info-tip";

        // 引数に指定された場合は引数優先。
        if ($input) {
            $infoInput = $input;
        } else {
            $infoInput = $(e.target);
        }

        this.mInfoTooltip.show($infoInput, message);

        this.mInfoTooltipNo++;
        let infoTooltipNo = this.mInfoTooltipNo;

        // 1秒後にメッセージを消す
        setTimeout(() => {
            if (this.mInfoTooltip != null) {
                if (this.mInfoTooltipNo == infoTooltipNo) {
                    this.mInfoTooltip.hide();
                    this.mInfoTooltipNo = 0;
                }
            }
        }, 1000);
    }

    /**
     * 表示されているメッセージを消去する
     */
    public hideError() {
        if (this.mErrTooltip != undefined) {
            if (this.mErrTooltip != null) {
                this.mErrTooltip.hide();
                this.mErrTooltip.cssClass = null!;
                this.mErrTooltip = null!;
            }
        }
        if (this.mInfoTooltip != undefined) {
            if (this.mInfoTooltip != null) {
                this.mInfoTooltip.hide();
                this.mInfoTooltip.cssClass = null!;
                this.mInfoTooltip = null!;
            }
        }
    }

    /**
     *  コントロールのVisible/Hiddenの制御を行う。
     * @param flg
     */
    public visible(flg: boolean): void {

    }

    /**
     * コントロールのEnable/Disable制御を行う。
     * @param flg
     */
    public set enabled(flg: boolean) {
        if (flg) {
            this.$baseTag.removeAttr("disabled");
        } else {
            this.$baseTag.attr("disabled", "disabled");
        }
    }

    /**
     * コントロールのEnable/Disable制御を取得する。
     *
     */
    public get enabled(): boolean {
        return !this.$baseTag.attr("disabled");
    }

    public setBaseTagEnable(enabled: boolean) {
        if (enabled) {
            this.$baseTag.removeAttr("disabled");
        } else {
            this.$baseTag.attr("disabled", "disabled");
        }
    }

    /**
     * コントロールを破棄する
     */
    public dispose(): void {
        this.lostFocus.removeAllHandlers();
        this.gotFocus.removeAllHandlers();
        this.keyDown.removeAllHandlers();
        this.$baseTag.empty();
    }

    /**
     * オーナータグに付加されたAngularディレクティブを指定されたタグに付加する。
     * @param $baseTag
     * @param $scope
     * @param $compile
     */
    protected setAngularDirective($target: JQuery, angularDirName: string) {
        //var dirString: string = this.$baseTag.attr(angularDirName);
        //if (dirString) {
        //    var directives: any = dirString.split(";");
        //    $.each(directives, (index, value: string) => {
        //        var values = value.split("=");
        //        if (values.length > 1) {
        //            var valString = values[1].replace(/"/g, "").replace(/'/g, "");
        //            $target.attr(values[0], valString);
        //            this.mAngularDirective[values[0]] = valString;
        //        } else {
        //            $target.attr(values[0]);
        //            this.mAngularDirective[values[0]] = "";
        //        }
        //    });
        //    this.doProcessBeforeCompile();
        //    this.$compile($target)(this.$scope);
        //}
        let directiveInfo: AngularDirectiveInfo = this.createAngularDirectiveInfo($target, angularDirName);
        if (directiveInfo) {
            $.each(directiveInfo.directiveList, (index, value: AngularDirective) => {
                if (value.value) {
                    $target.attr(value.directiveName, value.value);
                } else {
                    $target.attr(value.directiveName);
                }
            });
        }
        if (directiveInfo || this.doProcessBeforeCompile()) {
            this.$compile($target)(this.$scope);
        }
    }

    private getAngularDirectiveInfo($target: JQuery): AngularDirectiveInfo {
        let retControl: AngularDirectiveInfo = undefined!;
        $.each(this.mAngularDirective, (index, value: AngularDirectiveInfo) => {
            if (value.targetControl[0] == $target[0]) {
                retControl = value;
                return false;
            }
            return true;
        });
        return retControl;
    }

    protected createAngularDirectiveInfo($target: JQuery, angularDirName: string): AngularDirectiveInfo {
        let directiveInfo: AngularDirectiveInfo = this.getAngularDirectiveInfo($target);
        if (!directiveInfo) {
            let dirString: string = this.$baseTag.attr(angularDirName);
            if (dirString) {
                directiveInfo = <AngularDirectiveInfo>{ targetControl: $target, directiveList: new Array<AngularDirective>() };
                this.mAngularDirective.push(directiveInfo);

                var directives: any = dirString.split(";");
                $.each(directives, (index, value: string) => {
                    var values = value.split("=");
                    if (values.length > 1) {
                        var valString = values[1].replace(/"/g, "").replace(/'/g, "");
                        directiveInfo.directiveList.push(<AngularDirective>{ directiveName: values[0], value: valString });
                    } else {
                        directiveInfo.directiveList.push(<AngularDirective>{ directiveName: values[0], value: "" });
                    }
                });
            }
        }
        return directiveInfo;
    }

    /**
     * AngularのCompile実行前に行いたい処理がある場合にここに記述
     * @retunr: 変更があった場合はtrueを返す。
     */
    protected doProcessBeforeCompile(): boolean {
        // 各コントロールでOverrideして、不必要なng-***を取り除く
        return false;
    }

    protected setValueToViewModel($target: JQuery, newValue: any, raiseWatchEvent: boolean = true) {
        let ngModelValue: string = this.getNgModelValue($target);
        if (ngModelValue) {
            let ngModels: string[] = ngModelValue.split('.');
            let model: any = this.$scope;
            let lastProperty: string = undefined!;
            $.each(ngModels, (index, value) => {
                if ((ngModels.length - 1) != index) {
                    model = model[value];
                }
                lastProperty = value;
            });
            // AngularJSのDigest中かどうかの判断を行う。
            // Digest中にApplyを実行するとエラーとなってしまうため！
            if (this.$scope.$$phase) {
                model[lastProperty] = newValue;
            } else {
                // this.$scope.$apply(() => { model[lastProperty] = newValue; });
                model[lastProperty] = newValue;
            }
        }
    }
    protected getNgModelValue($target: JQuery): string {
        let modelValue: string = undefined!;
        let directiveInfo: AngularDirectiveInfo = this.getAngularDirectiveInfo($target);
        if (directiveInfo) {
            $.each(directiveInfo.directiveList, (index, value: AngularDirective) => {
                if (value.directiveName == "ng-model") {
                    modelValue = value.value;
                }
            });
        }
        return modelValue;
    }

    protected removeNotSjisChars(str: string): string {
        return MjsUtilities.removeNotSjisChars(str);
    }

}
