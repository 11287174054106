import React from 'react';
import Axios from 'axios';

import * as Labels from '../../../../constants/labels';
import { Label } from 'reactstrap';
import PageHeader from '../../../../components/molecules/CMN/PageHeader/PageHeader';
import { LowerActionLayoutContents, LowerActionLayoutHeader, LowerActionLayout } from '../../../../components/templates/LowerActionLayout';
import { ContractInfoViewModel } from '../../../../models/contractInfoViewModel';
import { format } from '../../../../utils/dateUtils';

//システム履歴
import { setAlertMessage, AlertKbnEnum } from '../../../../stores/AlertMessage';
import * as message from '../../../../constants/message';

/** サーバへアクセスする際のURL(ベース部分) */
const BaseURL = 'api/v1/ContractInfoService';
//** 初期表示時にサーバへアクセスする際のURL */
const InitialURL = [BaseURL, 'Initial'].join('/');
// 起動判定URL
const JudgeURL = 'api/v1/CommonStartJudgement/Judge';
// システム履歴記入
const WriteSysHistoryURL = 'api/v1/CommonSystemHistory/Write';


type ContractInfoProps = {
    /** 自画面を閉じる処理 */
    onClose: () => void;
};

const ContractInfo: React.FC<ContractInfoProps> = props => {
    const { onClose } = props;

    /** 閉じるボタン押下時の処理 */
    const handleOnCloseClick = () => {
        onClose();
    };

    React.useEffect(() => {
        // 処理判定
        async function JudgeOnPost(url: string, syoriId: string) {
            const status = await Axios.post<number>(url, { syoriId: syoriId });
            if (status.data == 0) {
                // 初期起動
                InitialOnGet(InitialURL);
                await Axios.post(WriteSysHistoryURL, { syoriId: syoriId, operation: '処理開始' });
            } else {
                if (status.data == -101) {
                    setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotUse });
                } else if (status.data == -102) {
                    setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractOver });
                } else if (status.data == -103) {
                    setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_Notauthorized });
                } else if (status.data == -104) {
                    setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotDataSelect });
                } else if (status.data == -105) {
                    setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractEqual });
                }
                props.onClose();
            }
        }
        JudgeOnPost(JudgeURL, 'AIKCMN008000001');
    }, [InitialURL]);

    const [viewModel, updateViewModel] = React.useState<ContractInfoViewModel>(
        new ContractInfoViewModel());
    const [saiyouComp, setSaiyouComp] = React.useState<number>();
    const [RiyouStrDate, setRiyouStrDate] = React.useState<string>();
    const [ContractDate, setContractDate] = React.useState<string>();

    const InitialOnGet = (url: string) => {
        Axios.get<ContractInfoViewModel>(url)
            .then(response => {
                let newViewModel = response.data;
                if (newViewModel.LicenseCountPurchased != undefined && newViewModel.MakeKaisyaCnt != undefined) {
                    setSaiyouComp((newViewModel.LicenseCountPurchased - newViewModel.MakeKaisyaCnt));
                }
                if (newViewModel.RiyouStrDate != undefined) {
                    var RiyouStrDate = format(new Date(newViewModel.RiyouStrDate), 'yyyy/MM/dd');
                    setRiyouStrDate(RiyouStrDate);
                }
                if (newViewModel.ContractDate != undefined) {
                    var ContractDate = format(new Date(newViewModel.ContractDate), 'yyyy/MM/dd');
                    setContractDate(ContractDate);
                }
                updateViewModel(newViewModel);
            })
    };

    return (
        <div className='contract-info'>
            <LowerActionLayout className='vw-100 h-100'>
                <LowerActionLayoutHeader>
                    <PageHeader
                        pageTitle={Labels.AIKCMN008000001_FUNCTION_NAME}
                        closeButtonTabIndex={0}
                        onCloseClick={handleOnCloseClick}
                    />
                </LowerActionLayoutHeader >

                <LowerActionLayoutContents className='vw-100 px-3 pt-3'>

                    <div className='border border-top'>
                        <div className='d-flex mt-2 ml-2'>
                            <div className='flex-auto'>
                                <div className='d-flex'>
                                    <table className=''>
                                        <tbody>
                                            <tr>
                                                <th className='table-th'><Label>{Labels.AIKCMN008000001_LABEL_KEIYAKUNO}</Label></th>
                                                <td className='table-td'>
                                                    <Label
                                                        id='ContractNo'
                                                        name='ContractNo'
                                                        className='KeiyakuNo'
                                                    >
                                                        {viewModel.ContractNo}
                                                    </Label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='table-th'><Label>{Labels.AIKCMN008000001_LABEL_KEIYAKUBI}</Label></th>
                                                <td className='table-td'>
                                                    <Label
                                                        id='ContractDate'
                                                        name='ContractDate'
                                                        className='ContractDate'

                                                    >
                                                        {ContractDate}
                                                    </Label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='table-th'><Label>{Labels.AIKCMN008000001_LABEL_RIYOUKAISIBI}</Label></th>
                                                <td className='table-td'>
                                                    <Label
                                                        id='RiyouStrDate'
                                                        name='RiyouStrDate'
                                                        defaultValue='0000'
                                                        className='RiyouStrDate'
                                                    >
                                                        {RiyouStrDate}
                                                    </Label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='table-th'><Label >{Labels.AIKCMN008000001_LABEL_SAIYOJYOHO}</Label></th>

                                                <td className='table-td'>
                                                    <div className='float-left'>
                                                        <Label>{Labels.AIKCMN008000001_LABEL_SaiyouComp}</Label>
                                                        <Label
                                                            id='saiyouComp'
                                                            name='saiyouComp'
                                                            className='saiyouComp'
                                                        >
                                                            {saiyouComp}
                                                        </Label>
                                                        <Label>{Labels.AIKCMN008000001_LABEL_MakeKaisyaCnt}&nbsp;</Label>
                                                        <Label
                                                            id='MakeKaisyaCnt'
                                                            name='MakeKaisyaCnt'
                                                            className='MakeKaisyaCnt'
                                                        >
                                                            {viewModel.MakeKaisyaCnt}
                                                        </Label>
                                                        <Label>{Labels.AIKCMN008000001_LABEL_LicenseCountPurchased}&nbsp;</Label>
                                                        <Label
                                                            id='LicenseCountPurchased'
                                                            name='LicenseCountPurchased'
                                                            className='LicenseCountPurchased'
                                                        >
                                                            {viewModel.LicenseCountPurchased}
                                                        </Label>
                                                        <Label>{Labels.AIKCMN008000001_LABEL_CompCnt}</Label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='table-th'><Label>{Labels.AIKCMN008000001_LABEL_AIURL}</Label></th>
                                                <td className='table-td'>
                                                    <Label
                                                        id='TenantURL'
                                                        name='TenantURL'
                                                        className='TenantURL'
                                                    >
                                                        <a target={'_blank'} href={new URL(document.URL).protocol + "//" + new URL(document.URL).host + viewModel.TenantURL}>{new URL(document.URL).protocol + "//" + new URL(document.URL).host + viewModel.TenantURL}</a>
                                                    </Label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </LowerActionLayoutContents>
            </LowerActionLayout>
        </div>
    );
}
export default ContractInfo;