import React, { useRef } from 'react';
import Axios from 'axios';

import _ProcessMigrationStepFileImportPartial from './stepFileImport/_ProcessMigrationStepFileImportPartial';
import _ProcessMigrationStepItemSettingPartial from './stepItemSetting/_ProcessMigrationStepItemSettingPartial';
import _ProcessMigrationConfirmDialogPartial from './dialog/_ProcessMigrationConfirmDialogPartial';
import _ProcessMigrationAlertDialogPartial from './dialog/_ProcessMigrationAlertDialogPartial';
import _ConfirmPartial from '../../../../components/molecules/A130/Modal/_ConfirmPartial';
import _ProcessMigrationHeaderPartial from '../../../../components/molecules/A130/_ProcessMigrationHeaderPartial';
import _AlertPartial from '../../../../components/molecules/A130/Modal/_AlertPartial';
import _ProgressConfirmPartial from '../../../../components/molecules/A130/Modal/_ProgressConfirmPartial';
import _ExplorerMigraionTaxKbnPartial from '../../../../components/molecules/A130/_ExplorerMigraionTaxKbnPartial';
import _ExplorerEasyInputAccountItemPartial from '../../../../components/molecules/A130/_ExplorerEasyInputAccountItemPartial';
import { ProcessMigration } from '../../../../VKZ/pages/process/processMigration';
import { MigrationInitialViewModel } from '../../../../VKZ/models/process/migrationInitialViewModel';
import { PageInitializeParameter } from '../../../../VKZ/pageController';
import { Mjs } from '../../../../VKZ/mjs';
import _ProcessMigrationStepKmkSettingPartial from './stepKmkSetting/_ProcessMigrationStepKmkSettingPartial';
import _ProcessMigrationStepHojyoSettingPartial from './stepHojyoSetting/_ProcessMigrationStepHojyoSettingPartial';
import _ProcessMigrationStepSwkImportPartial from './stepSwkImport/_ProcessMigrationStepSwkImportPartial';
import _ProcessMigrationStepFinishPartial from './stepFinish/_ProcessMigrationStepFinishPartial';
import { AlertKbnEnum, setAlertMessage } from '../../../../stores/AlertMessage';
import * as message from '../../../../constants/message';
import '../../../../styles/objects/containers/pages/A130/_Migration.scss';
import ExcelOutputsetting from '../../../organisms/A000/ExcelOutputsetting';
import * as constant from '../../../../constants/constant';

type MigrationProps = {
  onClose: () => void;
  fiscalYear: number;
};

/** 共通処理 */
// 処理起動判定
const JudgeURL = 'api/v1/CommonStartJudgement/Judge';
// システム履歴記入
const WriteSysHistoryURL = 'api/v1/CommonSystemHistory/Write';
// データ取込
export const processMigrationlUrl = '/api/v1/ProcessMigration/Index';

const Migration:  React.FC<MigrationProps> = props => {
  
  
  /** 初期表示時の処理 */
  const [view, setView] = React.useState<{viewBag: any, html:any, viewModel: MigrationInitialViewModel}>();
  const [scope, setScope] = React.useState<any>();
  const [keyChangeValue, setKeyChangeValue] = React.useState<number>(0);

  let progressMigration = new ProcessMigration();

  const init = () => {

    Axios.get<{ html: any, viewBag: any, viewModel: MigrationInitialViewModel }>(processMigrationlUrl)
      .then(response => {
        setView(response.data);
      })
      .catch(error => {
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.message, timeout: 100000  });
      });
  }

  /** 初期表示時のみ実行 */
  React.useEffect(() => {
    setKeyChangeValue(keyChangeValue + 1); // 強制的にReactコンポーネントを初期化
    // 処理判定
    async function JudgeOnPost(url: string, syoriId: string) {
      const status = await Axios.post<number>(url, {syoriId: syoriId});
      if (status.data == 0) {
        // 初期起動
        init();
        await Axios.post(WriteSysHistoryURL, {syoriId: syoriId, operation: '処理開始'});
      } else {
        if(status.data == -101) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotUse});
        } else if (status.data == -102) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractOver});
        } else if (status.data == -103) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_Notauthorized});
        } else if (status.data == -104) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotDataSelect});
        } else if (status.data == -105) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractEqual});
        }
        props.onClose();
      }
    }
    JudgeOnPost(JudgeURL, 'AIKADT006000001');
  }, [props.fiscalYear]);
  
  const kisyuGridControlRef = useRef<any>();
  const hojyoKisyuGridControlRef = useRef<any>();
  const bmnKisyuGridControlRef = useRef<any>();
  const swkGridControlRef = useRef<any>();
  const taxKbnExprolerRef = useRef<any>();
  const explorerRef = useRef<any>();

  React.useEffect(() => {
    if (view) {
      Mjs.clientCode = view.html.clientCode;
      Mjs.drillDownFlg = view.html.drillDownFlg;
      Mjs.clientYear = view.html.clientYear;
      Mjs.isPersonalPc = view.html.isPersonalPc;
      Mjs.isDemo = view.html.isDemo;
      Mjs.hsTokenValue = view.html.hsTokenValue;
      Mjs.isOfficeAdmin = view.html.isOfficeAdmin;
      Mjs.selectedSystemDiv = view.html.selectedSystemDiv;
      Mjs.isDispCreateCompanyData = view.html.isDispCreateCompanyData;
      Mjs.userDiv = view.html.userDiv;
      Mjs.selectedMenuCategoryId = view.html.selectedMenuCategoryId;
      Mjs.lastSelectedMenuCategoryId = view.html.lastSelectedMenuCategoryId;
      Mjs.clientFiscalYear = view.html.clientFiscalYear;
      Mjs.existVkzData = view.html.existVkzData;
      Mjs.existPayrollData = view.html.existPayrollData;

      view.viewModel.PageID = "ProcessMigration";
      const param = {
        $scope: {
          viewModel: view.viewModel,
          kisyuGridControl: kisyuGridControlRef,
          hojyoKisyuGridControl: hojyoKisyuGridControlRef,
          bmnKisyuGridControl: bmnKisyuGridControlRef,
          swkGridControl: swkGridControlRef,
          taxKbnExproler: taxKbnExprolerRef,
          explorer: explorerRef,
        },
        $timeout: (fn: () => void, timeout: number) => {
          setTimeout(() => { 
            fn();
            setScope({ ... param.$scope});
          }, timeout );
        },
        $window: window
      } as PageInitializeParameter;
      progressMigration.initialize(param);
      progressMigration.onClose = props.onClose;
      param.$scope.initialize();
    }
  }, [view]);

  return (
<div className="migration" id="migration-root" key={keyChangeValue}>
<div className="fa fa-question-circle-o dummyForPreloadFont"></div>
<div className="custom-icon custom-icon-close dummyForPreloadFont"></div>
<div id="Shared-_Layout-1" className="Contents">
<section className="mainHead">
    <_ProcessMigrationHeaderPartial viewBag={view && view.viewBag}/>
</section>
<section className="mainBody" id="migration-main-body">
    <_ProcessMigrationStepFileImportPartial scope={scope}/>
    <_ProcessMigrationStepItemSettingPartial scope={scope} kisyuGridControlRef={kisyuGridControlRef} hojyoKisyuGridControlRef={hojyoKisyuGridControlRef} bmnKisyuGridControlRef={bmnKisyuGridControlRef} swkGridControlRef={swkGridControlRef}/>
    <_ProcessMigrationStepKmkSettingPartial scope={scope} />
    <_ProcessMigrationStepHojyoSettingPartial scope={scope} />
    <_ProcessMigrationStepSwkImportPartial scope={scope} />
    <_ProcessMigrationStepFinishPartial scope={scope} />
</section>

<div className="modal fade hogemodal" id="confirmDialog_Close" tabIndex={-1}>
    <div className="modal-dialog" role="dialog">
        <div className="modal-content">
            <_ProcessMigrationConfirmDialogPartial/>
            <div className="modal-footer">
                <button type="button" id="" className="on-yes-confirmDialog_Close ml-1 A3-btn btn btn-primary">はい</button>
                <button type="button" className="on-no-confirmDialog_Close ml-1 A3-btn btn btn-secondary" data-dismiss="modal">いいえ</button>
            </div>
        </div>{/* /.modal-content */}
    </div>{/* /.modal-dialog */}
</div>{/* /.modal */}
<div className="modal fade hogemodal" id="confirmDialog_DeletePreSetting" tabIndex={-1}>
    <div className="modal-dialog" role="dialog">
        <div className="modal-content">
            <_ProcessMigrationConfirmDialogPartial />
            <div className="modal-footer">
                <button type="button" id="" className="on-yes-confirmDialog_DeletePreSetting ml-1 A3-btn btn btn-primary">はい</button>
                <button type="button" className="on-no-confirmDialog_DeletePreSetting ml-1 A3-btn btn btn-secondary" data-dismiss="modal">いいえ</button>
            </div>
        </div>{/* /.modal-content */}
    </div>{/* /.modal-dialog */}
</div>{/* /.modal */}
<div className="modal fade hogemodal" id="confirmDialog_DeleteItemSetting" tabIndex={-1}>
    <div className="modal-dialog" role="dialog">
        <div className="modal-content">
            <_ProcessMigrationConfirmDialogPartial/>
            <div className="modal-footer">
                <button type="button" id="" className="on-yes-confirmDialog_DeleteItemSetting ml-1 A3-btn btn btn-primary">はい</button>
                <button type="button" className="on-no-confirmDialog_DeleteItemSetting ml-1 A3-btn btn btn-secondary" data-dismiss="modal">いいえ</button>
            </div>
        </div>{/* /.modal-content */}
    </div>{/* /.modal-dialog */}
</div>{/* /.modal */}
<div className="modal fade hogemodal" id="confirmDialog_ConfirmTaxKbn" tabIndex={-1}>
    <div className="modal-dialog" role="dialog">
        <div className="modal-content">
            <_ProcessMigrationConfirmDialogPartial/>
            <div className="modal-footer">
                <button type="button" id="" className="on-yes-confirmDialog_ConfirmTaxKbn ml-1 A3-btn btn btn-primary">はい</button>
                <button type="button" className="on-no-confirmDialog_ConfirmTaxKbn ml-1 A3-btn btn btn-secondary" data-dismiss="modal">いいえ</button>
            </div>
        </div>{/* /.modal-content */}
    </div>{/* /.modal-dialog */}
</div>{/* /.modal */}
<div className="modal fade hogemodal" id="confirmDialog_ConfirmSetting" tabIndex={-1}>
    <div className="modal-dialog" role="dialog">
        <div className="modal-content">
            <_ProcessMigrationConfirmDialogPartial/>
            <div className="modal-footer">
                <button type="button" id="" className="on-yes-confirmDialog_ConfirmSetting ml-1 A3-btn btn btn-primary">はい</button>
                <button type="button" className="on-no-confirmDialog_ConfirmSetting ml-1 A3-btn btn btn-secondary" data-dismiss="modal">いいえ</button>
            </div>
        </div>{/* /.modal-content */}
    </div>{/* /.modal-dialog */}
</div>{/* /.modal */}
<div className="modal fade hogemodal" id="confirmDialog_ConfirmSwkImport" tabIndex={-1}>
    <div className="modal-dialog" role="dialog">
        <div className="modal-content">
            <_ProcessMigrationConfirmDialogPartial/>
            <div className="modal-footer">
                <button type="button" id="" className="on-yes-confirmDialog_ConfirmSwkImport ml-1 A3-btn btn btn-primary">はい</button>
                <button type="button" className="on-no-confirmDialog_ConfirmSwkImport ml-1 A3-btn btn btn-secondary" data-dismiss="modal">いいえ</button>
            </div>
        </div>{/* /.modal-content */}
    </div>{/* /.modal-dialog */}
</div>{/* /.modal */}
<div className="modal fade hogemodal" id="alertDialog_FileCntOver" tabIndex={-1}>
    <div className="modal-dialog" role="dialog">
        <div className="modal-content">
            <_ProcessMigrationAlertDialogPartial/>
            <div className="modal-footer">
                <button type="button" id="" className="on-ok-alertDialog_FileCntOver ml-1 A3-btn btn btn-primary">はい</button>
            </div>
        </div>{/* /.modal-content */}
    </div>{/* /.modal-dialog */}
</div>{/* /.modal */}
<div className="modal fade hogemodal" id="alertDialog_FileSizeOver" tabIndex={-1}>
    <div className="modal-dialog" role="dialog">
        <div className="modal-content">
            <_ProcessMigrationAlertDialogPartial/>
            <div className="modal-footer">
                <button type="button" id="" className="on-ok-alertDialog_FileSizeOver ml-1 A3-btn btn btn-primary">はい</button>
            </div>
        </div>{/* /.modal-content */}
    </div>{/* /.modal-dialog */}
</div>{/* /.modal */}
<div className="modal fade hogemodal" id="alertDialog_SwkImportErr" tabIndex={-1}>
    <div className="modal-dialog" role="dialog">
        <div className="modal-content">
            <_ProcessMigrationAlertDialogPartial/>
            <div className="modal-footer">
                <button type="button" id="" className="on-ok-alertDialog_SwkImportErr ml-1 A3-btn btn btn-primary">はい</button>
            </div>
        </div>{/* /.modal-content */}
    </div>{/* /.modal-dialog */}
</div>{/* /.modal */}

<_ConfirmPartial/>
<_AlertPartial/>
<_ProgressConfirmPartial/>
<_ExplorerMigraionTaxKbnPartial scope={scope} taxKbnExprolerRef={taxKbnExprolerRef}/>
<_ExplorerEasyInputAccountItemPartial scope={scope} explorerRef={explorerRef}/>

<ExcelOutputsetting
    activated={scope && scope.excelPopupActivatedHojyoZandakaCheck}
    templateId={constant.AIKADT006000001E02}
    onClose={() => {
        scope.OnCloseExcelPopupHojyoZandakaCheck();
    }}
    filters={""}
    headerLeft={props.fiscalYear.toString().substring(0, 4)}
    popupTitle="補助期首残高を確認する"
/>

<ExcelOutputsetting
    activated={scope && scope.excelPopupActivatedBmnZandakaCheck}
    templateId={constant.AIKADT006000001E01}
    onClose={() => {
        scope.OnCloseExcelPopupBmnZandakaCheck();
    }}
    filters={""}
    headerLeft={props.fiscalYear.toString().substring(0, 4)}
    popupTitle="部門期首残高を確認する"
/>
{/* 共通フッター */}
<footer className="Footer">
    <div id="functionPanel"></div>
</footer>
</div>
</div>
  );
}

export default Migration;
