import React from 'react';
import classnames from 'classnames';

/** CoreUIデザインに触発されたレイアウト */
export const CorePageWrapper: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
  <article {...props} className={classnames(props.className, 'core-page-wrapper')}>
    {props.children}
  </article>
);

export const CorePageHeader: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
  <header {...props} className={classnames(props.className, '-header')}>
    {props.children}
  </header>
);

export const CorePageSideMenu: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
  <nav {...props} className={classnames(props.className, '-sidemenu')}>
    {props.children}
  </nav>
);

export const CorePageOtherMenu: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
  <nav {...props} className={classnames(props.className, '-othermenu')}>
    {props.children}
  </nav>
);

export const CorePageMain: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
  <main {...props} className={classnames(props.className, '-main')}>
    {props.children}
  </main>
);
