import React from 'react';

type _ProcessMigrationStepFileImportPartialProps = {
  scope?: any
};

const _ProcessMigrationStepFileImportPartial: React.FC<_ProcessMigrationStepFileImportPartialProps> = props => {

    return (
    <>
<section id="stepFileImport" className="dp_none">
    <span className="step-number">{props.scope && props.scope.stepFileImportDispNo}</span>
    <div id="stepFileImport-title" className="step-title">インポートするファイルを指定してください。</div>
    <div id="stepFileImport-content" className="step-content">
        <label className="step-description-label">取込ファイルのエクスポート</label>
        <div id="stepFileImport-description-other" className="mb10">・各種会計ソフトや表計算ソフトから、各種データに指定された項目をCSV形式で出力してください。</div>
        <div id="stepFileImport-description" className="step-description">
            <div style={{fontWeight: "bold"}}>期首残高データ</div>
            &ensp;&ensp;必須項目：「勘定科目名称」、「残高金額」<br />
            &ensp;&ensp;ファイル名：期首残高<br />
            <div style={{fontWeight: "bold"}}>補助期首残高データ</div>
            &ensp;&ensp;必須項目：「勘定科目名称」、「補助種類」、「補助科目名称」、「残高金額」<br />
            &ensp;&ensp;ファイル名：補助期首残高<br />
            <div style={{fontWeight: "bold"}}>部門期首残高データ</div>
            &ensp;&ensp;必須項目：「勘定科目名称」、「部門名称」、「残高金額」<br />
            &ensp;&ensp;ファイル名：部門期首残高<br />
            <div style={{fontWeight: "bold"}}>仕訳データ</div>
            &ensp;&ensp;必須項目：「日付」、「借方科目」、「貸方科目」、「金額」<br />
            &ensp;&ensp;ファイル名：仕訳<br />
        </div>
        <div className="dp_none"><input id="files-select" type="file" multiple accept=".csv,.txt,.text" name="upfiles" /></div>
        <div id="file-upload" className="file-upload" onClick={props.scope && props.scope.onFileSelect}>ここにエクスポートしたファイルをドラッグ＆ドロップするか<br />このエリアをクリックしてファイルを選択してください。</div>
        <div id="file-import" className="dp_none mt10">
            <label className="step-description-label">取込ファイルの設定</label>
            <div>
                <div id="target-lack" className="dp_none"><span className="fa fa-info-circle migration-blue mr5"></span>{props.scope && props.scope.targetlack}ファイルの取り込みが可能です。</div>
            </div>
                <div className="targetTable migration-table-border">
                    <table className="migration-table">
                        <thead>
                            <tr className="migration-table-border-head">
                                <th style={{width: "1%"}}></th>
                                <th style={{width: "15%"}}>取り込まれたファイル</th>
                                <th style={{width: "1%"}}></th>
                                <th style={{width: "3%"}}></th>
                                <th style={{width: "15%"}}>取込対象</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.scope && props.scope.fileList && props.scope.fileList.map((file: any, i: number) => {
                                return (
                                    <tr>
                                        <td>
                                            <span className={!file.isErr ? 'fa fa-check-circle text-success' : ''}></span>
                                            <span className={file.isErr ? 'fa fa-times-circle red-icon' : ''}></span>
                                        </td>
                                        <td><div style={{whiteSpace: "nowrap"}}>{file.name}</div></td>
                                        <td><div id={`trash${i}`}>{!file.isErr && <span className="fa fa-trash-o red-icon" onClick={() => props.scope && props.scope.trashClick(i)} data-toggle="tooltip" data-html="true" data-placement="right" title="取り込まれたファイルを削除"></span>}</div></td>
                                        <td><span className="fa fa-arrow-right"></span></td>
                                        <td><label id={`labelImportTarget${i}`} className="label-import-target easy-input"></label></td>
                                        <td><div hidden={file.errMsg === ""} className="red-icon"><span className="fa fa-exclamation-circle ml5 mr5"></span>{file.errMsg}</div></td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            <div className="pb20">
                <button id="file-import-btn" type="button" className="btn btn-primary mt10 pb20 pr10 pl10 wd190">ファイルをインポートする</button>
                <div id="file-import-success" className="btn-success-msg" style={{visibility: 'hidden'}}><span className="fa fa-check-circle text-success btn-success-icon pl5 pr5"></span>ファイルのインポートが完了しました。</div>
            </div>
        </div>
    </div>
</section>

    </>
  )
};
export default _ProcessMigrationStepFileImportPartial;