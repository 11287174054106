/**
 * 担当者ログインViewModel ViewModel クラス
 */

export class TantoLoginViewModel {
    /** ログインID */
    public LoginID?:string;

    /** パスワード */
    public Password?:string;

    /** bizsky管理者ログインURL */
    public bizskyLoginURL?:string;

    /** エラーメッセージ */
    public ErrorMessage?:string;

    /** 暗号化されたMJS認証基盤ID */
    public EncryptedMjsAuthId?:string;

    /** AI監査のトークン */
    public AIAuditToken?:string;

}
