import {AuditResultJournalLineVO} from "./auditResultJournalLineVO";
import {AuditResultJournalLineSumVO} from "./auditResultJournalLineSumVO";
/**
 * 監査結果(仕訳明細)ViewModel ViewModel クラス
 */

export class AuditResultJournalLineViewModel {
    /** 監査結果(仕訳明細)VO */
    public Results?:Array<AuditResultJournalLineVO>;

    /** 監査結果(仕訳明細)集計VO */
    public SumCnts?:Array<AuditResultJournalLineSumVO>;

    /** 対象月 */
    public TMonth?:string;

    /** 最終実行日時 */
    public LastExecDate?:Date;

}
