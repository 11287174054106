import { PageInitializeParameter } from "../../pageController";

export abstract class HeaderBase {
    protected $scope: any;
    protected $compile: any;
    protected $window: any;

    constructor(parameter: PageInitializeParameter) {
        this.$scope = parameter.$scope;
        this.$compile = parameter.$compile;
        this.$window = parameter.$window;
    }
    public abstract initialize(): void;
}

