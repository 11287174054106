import React from 'react';

import { Card, CardImg, CardText } from 'reactstrap';
import { Nav, NavItem, NavLink } from 'reactstrap';

import CompanyInfoIcon from '../../../images/vkz/ico_menu_kaisya_kihon.png';
import KmkRegistrationIcon from '../../../images/vkz/ico_menu_kanjou_kamoku.png';

type IRegisterMenuProps = {
    onFirmInfoClick: () => void;
    onAccountRegister: () => void;
};

const headerClass = 'h6 text-lbl';
const navLinkClass = 'cursor-pointer p-0 mx-3 my-2';
const lblClass = 'small text-black d-block text-center';
const cardClass = 'border-0 justify-content-center align-items-center _item';
const cardTextClass = 'm-2';

const AuditAdminMenu = (props: IRegisterMenuProps) => {
  const companyInfoLbl: React.ReactElement[] = [];
  '会社基本情報'.split('\n').forEach((lbl, i) => {
    companyInfoLbl.push(
      <span className={lblClass} key={'companyInfoLbl' + i}>
        {lbl}
      </span>
    );
  });
  const kmkRegistrationLbl: React.ReactElement[] = [];
  '勘定科目登録'.split('\n').forEach((lbl, i) => {
    kmkRegistrationLbl.push(
      <span className={lblClass} key={'kmkRegistrationLbl' + i}>
        {lbl}
      </span>
    );
  });
  const aiWizardLbl: React.ReactElement[] = [];
  '導入ウィザード'.split('\n').forEach((lbl, i) => {
    aiWizardLbl.push(
      <span className={lblClass} key={'aiWizardLbl' + i}>
        {lbl}
      </span>
    );
  });
  return (
    <div className='app-menu'>
      <section>
        <h2 className={headerClass}>{'導入・登録処理'}</h2>
        <Nav>
          <NavItem>
            <NavLink
              className={navLinkClass}
              onClick={props.onFirmInfoClick}
              tabIndex={120}
              href='javascript:void(0);'
            >
              <Card className={cardClass}>
                <CardImg src={CompanyInfoIcon} />
                <CardText className={cardTextClass}>{companyInfoLbl}</CardText>
              </Card>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={navLinkClass}
              onClick={props.onAccountRegister}
              tabIndex={121}
              href='javascript:void(0);'
            >
              <Card className={cardClass}>
                <CardImg src={KmkRegistrationIcon} />
                <CardText className={cardTextClass}>{kmkRegistrationLbl}</CardText>
              </Card>
            </NavLink>
          </NavItem>
        </Nav>
      </section>
    </div>
  );
};
export default AuditAdminMenu;
