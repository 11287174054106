import React from 'react';
import Axios from 'axios';

import * as wj from 'wijmo/wijmo';
import * as wjGrid from 'wijmo/wijmo.grid';

import {
  DefaultLayout,
  DefaultLayoutHeader,
  DefaultLayoutContents
} from '../../../../components/templates/DefaultLayout';
import PageHeader from '../../../../components/molecules/CMN/PageHeader/PageHeader';
import CommonGrid, {
  sortLabel,
  setFilterDefinition,
  setSortCriteria
} from '../../../../components/molecules/CMN/Grid/CommonGrid';
import ConfirmationPopup from '../../../../components/molecules/CMN/ConfirmationPopup/ConfirmationPopup';
import ExcelOutputsetting from '../../../organisms/A000/ExcelOutputsetting';
import RuleImport from '../../A000/AIKCMN001000005/AuditRuleImport';
import RuleExport from '../../A000/AIKCMN001000005/AuditRuleExport';
import JournalLineRuleSetting from '../AIKADT001000002/JournalLineRuleSetting';
import { createWjDataMap } from '../../../../utils/dataMapsUtils';
import { bindValueToMessage } from '../../../../utils/messageUtils';
import * as labels from '../../../../constants/labels';
import * as constant from '../../../../constants/constant';
import * as message from '../../../../constants/message';
import { DataTypeEnum, DisplayModeEnum } from '../../../../constants/enums';
import {
  DataTypeEnum as dmDataTypeEnum,
  TargetJournalEnum as dmTargetJournalEnum,
  TargetTMonthEnum as dmTargetTMonthEnum
} from '../../../../constants/dataMaps';
import { JournalLineRuleSettingListViewModel } from '../../../../models/journalLineRuleSettingListViewModel';
import { JournalLineRuleItemVO } from '../../../../models/journalLineRuleItemVO';
import { setAlertMessage, AlertKbnEnum } from '../../../../stores/AlertMessage';

//通信処理のURL
const BaseUrl = '/api/v1/JournalLineRuleSettingList';
const BaseRuleUrl = [BaseUrl, 'Rule'].join('/');
export const initialUrl = [BaseUrl, 'Initial'].join('/');
export const existsUrl = (code: number) => [BaseRuleUrl, code.toString(), 'Exists'].join('/'); // 存在確認
export const enableUrl = (code: number, flg: boolean) => [BaseRuleUrl, code.toString(), 'Enable', flg].join('/'); // 有効化
export const deleteUrl = (code: number) => [BaseRuleUrl, code.toString()].join('/'); // 有効化
// 処理判定
const JudgeURL = 'api/v1/CommonStartJudgement/Judge';
// システム履歴記入
const WriteSysHistoryURL = 'api/v1/CommonSystemHistory/Write';

const defaultRulePopupProps = {
  activated: false,
  dispMode: DisplayModeEnum.New,
  code: -1,
  dataType: DataTypeEnum.System
};
type JournalLineRuleSettingListprops = {
  onClose: () => void;
  fiscalYear: number;
};

const JournalLineRuleSettingList: React.FC<JournalLineRuleSettingListprops> = props => {
  const [excelPopupActivated, setExcelPopupActivated] = React.useState(false);
  const [filters, setFilters] = React.useState('');

  const [itemVo, setItemVo] = React.useState<JournalLineRuleItemVO>();

  const [viewModel, updateViewModel] = React.useState<JournalLineRuleSettingListViewModel>({ RuleItems: [] });

  //削除メッセージ
  const [deleteMessage, setDeleteMessage] = React.useState('');

  //仕訳明細ルール設定ポップアップの表示ステータス
  const [rulePopupProps, setRulePopupProps] = React.useState(defaultRulePopupProps);

  //確認ポップアップの表示ステータス
  const [confirmPopupActivated, setConfirmPopupActivated] = React.useState(false);

  // 行選択する内部コード
  const [selectionNCode, setSelectionNCode] = React.useState<number>();

  // FlexGrid
  const [flexGrid, setFlexGrid] = React.useState<wjGrid.FlexGrid>();

  // 初期データ作成
  const initialOnGet = (url: string) => {
    Axios.get<JournalLineRuleSettingListViewModel>(url)
      .then(response => {
        updateViewModel(response.data);
      })
      .catch(error => {
        if (typeof error.response.data !== 'undefined') {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
        }
      });
  };

  // 通信処理(初期処理)の実行
  React.useEffect(() => {
    // 処理判定
    async function JudgeOnPost(url: string, syoriId: string) {
      const status = await Axios.post<number>(url, {syoriId: syoriId});
      if (status.data == 0) {
        // 初期起動
        initialOnGet(initialUrl);
        await Axios.post(WriteSysHistoryURL, {syoriId: syoriId, operation: '処理開始'});
      } else {
        if(status.data == -101) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotUse});
        } else if (status.data == -102) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractOver});
        } else if (status.data == -103) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_Notauthorized});
        } else if (status.data == -104) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_NotDataSelect});
        } else if (status.data == -105) {
          setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: message.Common_Error_StartCheck_ContractEqual});
        }
        props.onClose();
      }
    }
    JudgeOnPost(JudgeURL, 'AIKADT001000001');
  }, [initialUrl, props.fiscalYear]);

  // 存在確認処理
  const existsOnPost = (url: string, cItemVo: JournalLineRuleItemVO, displayMode: DisplayModeEnum) => {
    Axios.post(url, { dataType: cItemVo.DataTypeKbn })
      .then(response => {
        if (cItemVo.NCode != undefined && cItemVo.DataTypeKbn != undefined) {
          setRulePopupProps({
            activated: true,
            dispMode: displayMode,
            code: cItemVo.NCode,
            dataType: cItemVo.DataTypeKbn
          });
        }
      })
      .catch(error => {
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
      });
  };

  // 削除フラグ更新処理
  const deleteOnDelete = (url: string, cItemVo: JournalLineRuleItemVO) => {
    Axios.delete(url, { data: { dataType: cItemVo.DataTypeKbn } })
      .then(response => {
        // フィルタ条件を保持
        setFilterDefinition(filters);

        // ソート条件を保持
        if (typeof flexGrid !== 'undefined' && typeof flexGrid.collectionView.sortDescriptions[0] !== 'undefined') {
          const sortDesc = flexGrid.collectionView.sortDescriptions[0];
          setSortCriteria({ property: sortDesc.property, ascending: sortDesc.ascending });
        } else {
          setSortCriteria({ property: undefined, ascending: undefined });
        }

        // 選択行を設定
        setSelectionNCode(cItemVo.NCode);
        initialOnGet(initialUrl);
      })
      .catch(error => {
        setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
      });
  };

  // 有効フラグ更新処理
  const enableOnPost = (url: string, cItemVo: JournalLineRuleItemVO) => {
    Axios.post(url, { dataType: cItemVo.DataTypeKbn }).catch(error => {
      setAlertMessage({ alerted: true, alertKbn: AlertKbnEnum.danger, message: error.response.data.message });
    });
  };

  // 閉じる
  const handleOnCloseClick = () => {
    props.onClose();
  };

  //Excel出力
  const handleOnExcelClick = () => {
    setExcelPopupActivated(true);
  };
  //Excel出力ポップアップで閉じるボタン押下時
  const handleExcelOnClose = () => {
    setExcelPopupActivated(false);
  };
  //新規
  const handleOnCreateClick = () => {
    setRulePopupProps({ ...defaultRulePopupProps, activated: true });
  };
  //編集
  const handleOnGridDoubleClick = (cItemVo: JournalLineRuleItemVO) => {
    if (cItemVo.NCode != undefined) {
      existsOnPost(existsUrl(cItemVo.NCode), cItemVo, DisplayModeEnum.Update);
    }
  };
  //コピー
  const handleOnCopyClick = (cItemVo: JournalLineRuleItemVO) => {
    if (cItemVo.NCode != undefined) {
      existsOnPost(existsUrl(cItemVo.NCode), cItemVo, DisplayModeEnum.Copy);
    }
  };
  //仕訳明細ルール設定ポップアップで閉じるボタン押下時
  const handleRuleOnClose = (isRefresh: boolean, nCode?: number) => {
    setRulePopupProps({ ...defaultRulePopupProps });
    if (isRefresh) {
      // フィルタ条件を保持
      setFilterDefinition(filters);

      // ソート条件を保持
      if (typeof flexGrid !== 'undefined' && typeof flexGrid.collectionView.sortDescriptions[0] !== 'undefined') {
        const sortDesc = flexGrid.collectionView.sortDescriptions[0];
        setSortCriteria({ property: sortDesc.property, ascending: sortDesc.ascending });
      } else {
        setSortCriteria({ property: undefined, ascending: undefined });
      }

      // 選択行を設定
      setSelectionNCode(nCode);

      initialOnGet(initialUrl);
    }
  };

  //Gridのフィルター変更時
  const habdleOnFilterChanged = (filterJson: string) => {
    setFilters(filterJson);
  };

  // Gridの初期処理
  const handleInitializedGrid = (grid: wjGrid.FlexGrid) => {
    setFlexGrid(grid);
  };

  // Gridのセル選択処理
  const habdleSelectionGridCell = (grid: wjGrid.FlexGrid) => {
    const items = grid.collectionView.items as JournalLineRuleItemVO[];
    let selectionRow = items.indexOf(items.filter(item => item.NCode === selectionNCode)[0]);
    selectionRow = selectionRow > -1 ? selectionRow : 0;
    grid.selection = new wjGrid.CellRange(selectionRow > -1 ? selectionRow : 0);
    // スクロールの移動
    setTimeout(() => {
      const rc = grid.cells.getCellBoundingRect(selectionRow, 0, true);
      grid.scrollPosition = new wj.Point(0, -rc.top);
    }, 100);
  };

  // インポート
  const [ruleImportActivated, setRuleImportActivated] = React.useState(false);
  const handleOnImportClick = () => {
    setRuleImportActivated(true);
  };
  const handleOnRuleImportClose = () => {
    setRuleImportActivated(false);
    initialOnGet(initialUrl);
  };

  // エクスポート
  const [ruleExportActivated, setRuleExportActivated] = React.useState(false);
  const handleOnExportClick = () => {
    setRuleExportActivated(true);
  };
  const handleOnRuleExportClose = () => {
    setRuleExportActivated(false);
  };

  // 通信処理(削除処理)の実行
  const handleOnDeleteClick = (cItemVo: JournalLineRuleItemVO) => {
    if (cItemVo.DataTypeKbn != undefined) {
      setDeleteMessage(
        bindValueToMessage(message.Common_Confirmation_Delete, [
          dmDataTypeEnum[cItemVo.DataTypeKbn].value + '：' + cItemVo.RuleName
        ])
      );
    }
    setConfirmPopupActivated(true);
    setItemVo(cItemVo);
  };

  // 通信処理(有効フラグ更新処理)の実行
  const handleValidFlagClick = (cItemVo: JournalLineRuleItemVO) => {
    if (cItemVo.NCode != undefined && cItemVo.YukoFlg != undefined) {
      enableOnPost(enableUrl(cItemVo.NCode, cItemVo.YukoFlg), cItemVo);
    }
  };

  //確認ポップアップで閉じるボタン押下時
  const handleConfirmOnClose = () => {
    setConfirmPopupActivated(false);
  };
  //確認ポップアップで「はい」押下時
  const handleConfirmOnYesClick = () => {
    if (itemVo != undefined && itemVo.NCode != undefined) {
      deleteOnDelete(deleteUrl(itemVo.NCode), itemVo);
    }
    setConfirmPopupActivated(false);
  };
  //確認ポップアップで「いいえ」押下時
  const handleConfirmOnNoClick = () => {
    setConfirmPopupActivated(false);
  };

  const gridInnerRef = React.useRef<any>();
  const gridNextTabRef = React.useRef<any>();

  /** キーボード操作（キーダウン） */
  const handleGrigKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 9) {
      if (event.shiftKey === false) {
        // タブキー押下時
        gridNextTabRef.current.focus();
        event.preventDefault();
        event.stopPropagation();
      }
    }
  };

  React.useEffect(() => {
    if (viewModel.RuleItems != undefined && viewModel.RuleItems.length > 0) {
      // 画面表示完了後に実行
      setTimeout(() => {
        if (gridInnerRef.current) {
          // gridにキーダウンイベントのフック
          gridInnerRef.current.addEventListener('keydown', handleGrigKeyDown);
        }
      });
    }
  }, [viewModel.RuleItems]);

  const getRuleItems = (vm: JournalLineRuleSettingListViewModel) => (vm.RuleItems == undefined ? [] : vm.RuleItems);
  //レイアウト
  return (
    <div>
      <DefaultLayout className='vw-100 h-100'>
        <DefaultLayoutHeader>
          <PageHeader
            pageTitle={labels.AIKADT001000001_FUNCTION_NAME}
            createButtonTabIndex={6}
            excelButtonRef={gridNextTabRef}
            onCreateClick={handleOnCreateClick}
            excelButtonTabIndex={2}
            onExcelClick={handleOnExcelClick}
            importButtonTabIndex={3}
            onImportClick={handleOnImportClick}
            exportButtonTabIndex={4}
            onExportClick={handleOnExportClick}
            closeButtonTabIndex={5}
            onCloseClick={handleOnCloseClick}
          />
        </DefaultLayoutHeader>
        <DefaultLayoutContents className='vw-100 p-3'>
          <CommonGrid
            gridClassName='JournalLineRuleSettingList-grid'
            columns={gridColumns}
            sources={getRuleItems(viewModel)}
            frozenColumns={4}
            sortLabelMapList={sortLabelMapList}
            tabIndex={1}
            innerRef={gridInnerRef}
            onGridDoubleClick={handleOnGridDoubleClick}
            onCopyClick={handleOnCopyClick}
            onDeleteClick={handleOnDeleteClick}
            onValidFlagClick={handleValidFlagClick}
            onFilterChanged={habdleOnFilterChanged}
            initializedGrid={handleInitializedGrid}
            selectionGridCell={habdleSelectionGridCell}
          />
        </DefaultLayoutContents>
      </DefaultLayout>
      <ExcelOutputsetting
        activated={excelPopupActivated}
        templateId={constant.AIKADT001000001E01}
        onClose={handleExcelOnClose}
        filters={filters}
      />
      <ConfirmationPopup
        isOpen={confirmPopupActivated}
        onClose={handleConfirmOnClose}
        onYesClick={handleConfirmOnYesClick}
        onNoClick={handleConfirmOnNoClick}
      >
        {deleteMessage}
      </ConfirmationPopup>
      <JournalLineRuleSetting
        activated={rulePopupProps.activated}
        onClose={handleRuleOnClose}
        dispMode={rulePopupProps.dispMode}
        code={rulePopupProps.code}
        dataType={rulePopupProps.dataType}
      />
      <RuleImport activated={ruleImportActivated} onClose={handleOnRuleImportClose} />
      <RuleExport activated={ruleExportActivated} onClose={handleOnRuleExportClose} />
    </div>
  );
};

const sortLabelMapList: sortLabel[] = [
  {
    binding: 'YukoFlg',
    map: [
      { key: true, value: labels.AIKADT002000001_GRID_SORT_TRUE },
      { key: false, value: labels.AIKADT002000001_GRID_SORT_FALSE }
    ]
  }
];

const gridColumns = [
  {
    header: labels.AIKADT001000001_GRID_HEADER_NO,
    binding: 'No',
    align: 'center',
    isReadOnly: true,
    width: 60,
    format: 'd'
  },
  {
    header: labels.AIKADT001000001_GRID_HEADER_TYPE,
    binding: 'DataTypeKbn',
    align: 'center',
    isReadOnly: true,
    width: 80,
    dataMap: createWjDataMap(dmDataTypeEnum)
  },
  {
    header: labels.AIKADT001000001_GRID_HEADER_TARGET,
    binding: 'TargetKbn',
    align: 'center',
    isReadOnly: true,
    width: 80,
    dataMap: createWjDataMap(dmTargetJournalEnum)
  },
  {
    header: labels.AIKADT001000001_GRID_HEADER_NAME,
    binding: 'RuleName',
    align: 'left',
    isReadOnly: true,
    width: 200,
    inputType: 'tooltip'
  },
  {
    header: labels.AIKADT001000001_GRID_HEADER_TARGETMONTH,
    binding: 'TMonth',
    align: 'center',
    isReadOnly: true,
    width: 150,
    dataMap: createWjDataMap(dmTargetTMonthEnum),
    inputType: 'tooltip'
  },
  {
    header: labels.AIKADT001000001_GRID_HEADER_DESIGNATEDCRITERIA,
    binding: 'Joken',
    align: 'left',
    isReadOnly: true,
    minWidth: 250,
    width: '*',
    inputType: 'tooltip'
  },
  {
    header: labels.AIKADT001000001_GRID_HEADER_UPDATETIME,
    binding: 'UpdateDate',
    align: 'center',
    isReadOnly: true,
    format: 'yyyy/MM/dd HH:mm:ss',
    width: 160,
    dataType: 'Date'
  },
  {
    header: labels.AIKADT001000001_GRID_HEADER_VALID,
    binding: 'YukoFlg',
    align: 'center',
    isReadOnly: false,
    width: 60,
    inputType: 'validFlag'
  },
  {
    header: labels.AIKADT001000001_GRID_HEADER_COPY,
    name: 'CopyIcon',
    isReadOnly: true,
    align: 'center',
    cssClass: 'border-right-0',
    inputType: 'button',
    width: 25
  },
  {
    header: labels.AIKADT001000001_GRID_HEADER_COPY,
    name: 'DeleteIcon',
    isReadOnly: true,
    align: 'center',
    inputType: 'button',
    width: 25
  }
];

export default JournalLineRuleSettingList;
