import React from 'react';
import Close from '../../atoms/Icon/Close';
import IconButton from '../CMN/IconButton/IconButton';

type _ProcessMigrationHeaderPartialProps = {
  viewBag: any, 
};

const _ProcessMigrationHeaderPartial: React.FC<_ProcessMigrationHeaderPartialProps> = props => {
  return (
    <>
<div id="processMigrationHeader" style={{width: "100%"}} className="headerRoot">
    <div className="dp_flex">
        <div className="headerleft" style={{width: "135px"}}>
            <h1 className="ttl01 ml15 mr30 text-lbl" style={{minWidth:"115px"}}>{props.viewBag && props.viewBag.Title}</h1>
        </div>
        <div className="mt15 ml10" style={{flex: "auto"}}>
            <ul className="stepnavList">
                <li id="stepFileImportButton" className="dp_none"><a id="head-stepFileImport-button"><span className="stepnav">ファイルの取込</span></a></li>
                <li id="stepItemSettingButton" className="dp_none"><a id="head-stepItemSetting-button"><span className="stepnav">項目の設定</span></a></li>
                <li id="stepKmkSettingButton" className="dp_none"><a id="head-stepKmkSetting-button"><span className="stepnav">勘定科目、残高の登録</span></a></li>
                <li id="stepHojyoSettingButton" className="dp_none"><a id="head-stepHojyoSetting-button"><span className="stepnav">補助科目、残高の登録</span></a></li>
                <li id="stepSwkImportButton" className="dp_none"><a id="head-stepSwkImport-button"><span className="stepnav">仕訳のインポート</span></a></li>
                <li id="stepFinishButton" className="dp_none"><a id="head-stepFinish-button"><span className="stepnav">完了</span></a></li>
            </ul>
        </div>
        <div style={{marginLeft:"auto", marginTop: "8px"}}>
            <ul className="dp_flex">
                <li id="closeButton">
                    <a id="head-close-button" className="utilitylinkBtn">
                        <div><IconButton icon={<Close/>} /></div>
                        <div>閉じる</div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

   </>
  )
};
export default _ProcessMigrationHeaderPartial;