import React from 'react';
import classnames from 'classnames';

export type iconButtonProps = React.HTMLAttributes<HTMLAnchorElement> & {
  icon?: React.ReactElement;
  disabled?: boolean;
  innerRef?: React.RefObject<any>;
};

const defaultClassName = 'icon-button d-inline-block text-center text-decoration-none text-nowrap';

const IconButton: React.FC<iconButtonProps> = props => {
  const { icon, onClick, disabled } = props;
  const href = typeof props.tabIndex !== 'undefined' && disabled !== true ? 'javascript:void(0);' : undefined;
  const aClassName = classnames(defaultClassName, disabled === true ? '-disabled cursor-default' : 'cursor-pointer');
  const tabIndex = disabled === true ? undefined : props.tabIndex;

  const handleOnClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (disabled !== true) {
      if (typeof onClick === 'function') {
        onClick(event);
      }
    }
  };

  const aProps = { ...props };
  delete aProps['icon'];
  delete aProps['innerRef'];
  delete aProps['disabled'];
  return (
    <a
      {...aProps}
      onClick={handleOnClick}
      className={classnames(props.className, aClassName)}
      href={href}
      tabIndex={tabIndex}
      ref={props.innerRef}
    >
      {icon}
      <br />
      <span className='icon-button-label'>{props.children}</span>
    </a>
  );
};
export default IconButton;
